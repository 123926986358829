import _ from "underscore";
import React from "react";
import {Link} from "react-router";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";

import {OrganisationId} from "common/types/organisation";
import {ProjectId, Project} from "common/types/project";

interface Props {
  organisationId: OrganisationId;
  projectId: ProjectId;
  projects: Project[];
  selectProject: (projectId: ProjectId) => void;
}

export default function ProjectSelector({
  organisationId,
  projectId,
  projects,
  selectProject,
}: Props) {
  if (!Array.isArray(projects)) {
    return null;
  }
  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <FormControl variant="outlined">
        <InputLabel id="project-select-label">Project</InputLabel>
        <Select
          value={projectId}
          labelId="project-select-label"
          id="project-select"
          label="Project"
          onChange={event => selectProject(event.target.value as ProjectId)}
        >
          {_.sortBy(projects, project => project.name).map(
            (project: Project) => (
              <MenuItem value={project.id} key={project.id}>
                {project.name}
              </MenuItem>
            ),
          )}
        </Select>
      </FormControl>
      {projectId ? (
        <Link
          to={`/organisation/${organisationId}/project/${projectId}/document/list`}
        >
          <IconButton>
            <LinkIcon />
          </IconButton>
        </Link>
      ) : null}
    </div>
  );
}
