import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import StarRateIcon from "@material-ui/icons/StarRate";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import grey from "@material-ui/core/colors/grey";
import Tooltip from "@material-ui/core/Tooltip";
import copy from "copy-to-clipboard";

const styles = {
  main: {
    display: "flex",
    alignItems: "center",
  },
  deleteIcon: {
    cursor: "pointer",
    color: grey[700],
  },
  icon: {
    height: 20,
    width: 20,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "0 0 23px",
  },
  itemBlock: {
    width: "1rem",
    textAlign: "right",
    marginRight: "0.5rem",
    color: grey[700],
  },
};

function IssuesetItem({
  index,
  issueset,
  issuesets,
  onIssuesetUpdate,
  onIssuesetRemove,
}) {
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);
  const [isHovered, updateIsHovered] = useState(false);
  const [error, setError] = useState("");
  const [tooltipMessage, setTooltipMessage] = useState("");
  const clearError = () => setError("");
  const onHoverStart = () => updateIsHovered(true);
  const onHoverFinish = () => updateIsHovered(false);
  const showDeleteDialog = () => setIsDeleteDialogShown(true);
  const hideDeleteDialog = () => setIsDeleteDialogShown(false);
  const removeIssueset = () => onIssuesetRemove(issueset.id);
  const handleIssuesetRemoval = () => {
    if (issueset.issues.length > 0) {
      return showDeleteDialog();
    }
    removeIssueset();
  };
  useEffect(() => {
    setTooltipMessage(`ID: ${issueset.master_id}`);
  }, []);
  const onStarClick = e => {
    e.stopPropagation();
    onIssuesetUpdate(issueset.id, {
      is_star: !issueset.is_star,
      last_edited: issueset.last_edited,
    });
  };
  const updateIssuesetName = e => {
    const newName = e.target.value;
    if (newName === issueset.name) {
      return;
    }
    if (!newName) {
      return setError("Please provide the name");
    }
    const nameAlreadyExists = Boolean(
      issuesets.find(ig => ig.name === newName),
    );
    if (nameAlreadyExists) {
      return setError("Name already exists");
    }
    onIssuesetUpdate(issueset.id, {
      name: newName,
      last_edited: issueset.last_edited,
    });
  };
  const StarIcon = issueset.is_star
    ? StarRateIcon
    : isHovered ? StarBorderIcon : null;
  const onCopyClick = () => {
    copy(issueset.master_id || "");
    setTooltipMessage("ID copied!");
    setTimeout(() => setTooltipMessage(`ID: ${issueset.master_id}`), 1500);
  };

  return (
    <div
      key={issueset.name}
      style={styles.main}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverFinish}
    >
      <div style={styles.itemBlock}>{`${index + 1}.`}</div>
      <TextField
        style={{width: "90%"}}
        helperText={error}
        error={Boolean(error)}
        defaultValue={issueset.name}
        onBlur={updateIssuesetName}
        onFocus={clearError}
        disabled={issuesets.length === 1}
      />
      {issuesets.length > 1 && (
        <DeleteIcon
          onClick={handleIssuesetRemoval}
          style={styles.deleteIcon}
          fontSize="small"
        />
      )}
      <Tooltip title={tooltipMessage} placement="top" arrow>
        <InfoOutlinedIcon
          style={{...styles.icon, ...styles.deleteIcon}}
          onClick={onCopyClick}
        />
      </Tooltip>
      <div style={styles.iconContainer}>
        {issueset.is_hot ? (
          <WhatshotIcon
            style={{
              ...styles.icon,
              color: "#ef5350",
            }}
          />
        ) : null}
      </div>
      <div style={styles.iconContainer}>
        {StarIcon ? (
          <StarIcon
            style={{
              ...styles.icon,
              color: "#fbc02d",
              cursor: "pointer",
            }}
            onClick={onStarClick}
          />
        ) : null}
      </div>
      <Dialog fullWidth onClose={hideDeleteDialog} open={isDeleteDialogShown}>
        <DialogTitle>Confirm Issueset Deletion</DialogTitle>
        <DialogContentText style={{padding: "0 24px"}}>
          Are you sure you want to delete
          <span style={{fontWeight: 600}}>{` ${issueset.name} `}</span>
          issueset?
          <br />
          <span style={{fontWeight: 600}}>
            {`${issueset.issues ? issueset.issues.length : "n/a"} issues `}
          </span>
          exist for this Issueset.
        </DialogContentText>
        <DialogActions>
          <Button onClick={hideDeleteDialog}>Cancel</Button>
          <Button color="primary" onClick={removeIssueset}>
            Delete Issueset
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

IssuesetItem.propTypes = {
  issueset: PropTypes.object.isRequired,
  issuesets: PropTypes.array.isRequired,
  onIssuesetRemove: PropTypes.func.isRequired,
  onIssuesetUpdate: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default IssuesetItem;
