import _ from "lodash";
import styled from "styled-components";
import React from "react";

import TextCopier from "./text_copier";

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  > thead > tr > th,
  > tbody > tr > td {
    word-break: break-word;
    overflow: hidden;

    width: 5em;
    &:nth-of-type(2) {
      width: 100%;
    }
  }
`;

interface Props {
  json: Record<string, unknown>;
  small?: boolean;
}

export default function JSONPrinter({json, small}: Props) {
  if (!json || _.isEmpty(json)) {
    return null;
  }

  return (
    <div style={{display: "flex"}}>
      <Table>
        <tbody>
          {Object.entries(json).map(([key, item], index) => {
            return (
              <tr key={index}>
                <td
                  style={{
                    verticalAlign: "top",
                    width: "5em",
                    fontStyle: "italic",
                    ...(small ? {fontSize: "0.75em"} : {}),
                  }}
                >
                  {key.replace(/_/g, " ")}
                </td>
                <td key={key} style={{position: "relative"}}>
                  <TextCopier text={JSON.stringify(item, null, 2)} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
