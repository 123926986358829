import React from "react";
import {Link} from "react-router";
import PropTypes from "prop-types";
import _ from "underscore";

import LinkIcon from "material-ui/svg-icons/content/link";
import Warning from "material-ui/svg-icons/action/help-outline";
import {IssueIcon} from "constants/icons";
import findInstances from "../../utils/find_instances";
import calculateNodeLevel from "../../utils/calculate_node_level";
import keyedObjectPropType from "utils/keyed_object_prop_type";
import cleanReference from "utils/clauses/clean_reference";
import getTextUnderCursor from "../../utils/get_text_under_cursor";
import commonStyles from "../../utils/common_styles";
import getIssueNameWithIssuesets from "../../utils/get_issue_name_with_issuesets";

import SplitDialogue from "../split_dialogue";
import Topics from "../topics";

import getIssueTemplatedText from "common/utils/issues/get_issue_templated_text";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import {generalIssueClasses} from "modules/documents/constants/issue_classes";
import OptionsMenu from "./options_menu";

const styles = {
  row: {
    display: "flex",
    margin: "0 0 1em 0",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 5px",
    boxSizing: "border-box",
  },
  addedRow: {
    color: "#0f0",
  },
  deletedRow: {
    color: "#f00",
  },
  text: {
    width: "70%",
  },
  highlighted: {
    backgroundColor: commonStyles.selectedPath.background,
  },
  lackingTopics: {
    backgroundColor: "#fff8f8",
  },
  errorClassifying: {
    backgroundColor: "#faa",
  },
  selected: {
    border: commonStyles.selectedLevel.border,
    backgroundColor: commonStyles.selectedLevel.background,
    padding: "4px",
    borderRadius: "2px",
  },
  topicListShown: {
    border: "3px solid #43A047",
  },
  subheading: {
    fontSize: "18px",
    fontWeight: "600",
    paddingBottom: "8px",
  },
  issuesBlock: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "0 1em",
    marginLeft: "1em",
    marginRight: "1em",
    marginBottom: "1em",
  },
  issuesWrapper: {
    width: "100%",
    backgroundColor: "#fee",
    borderRadius: "4px",
    paddingBottom: "1px",
    marginBottom: "0.5em",
  },
};

function containsIfNodeExists(node, target) {
  return !node ? false : node.contains(target);
}

export function getOutsideClickHandler(handler) {
  return function handleClickOutsideUsage(event) {
    const itemWithSelectRef = document.getElementById(
      "row-with-topics-in-select",
    );
    const editParametersDialogNode = document.getElementsByClassName(
      "edit-parameters-dialog",
    )[0];

    // DIRTY HACK!!
    // WORKS ONLY WITH MENUS OF MATERIAL UI v0.20.2
    // SHOULD BE REWRITTEN IF USING v4 M-UI MENUS in TOPICPARAMETERS
    // Explanation:
    // When we have an atom in topicListShown state (green border around atom) we register a click listener
    // to listen for outside atom clicks: if we click outside the selected atom the green border
    // disappears and topic list is shown in base mode (without Select component). To register
    // outside click we get the event.target and if this target is outside of our itemWithSelectRef
    // the outside click is occurs (plese see the if block in the end of this function). BUT
    // when we have an open topicparameter menu and open some dropdown on this menu (e.g. currency
    // for monetary parameter or unit for duration) the dropdown menu is rendered differently.
    // M-UI renders such menus as a Popper component being the direct child of the document body
    // but not the child of the element that called it (our menu Textbox). The layout looks like this:
    // <body>
    //   <div>OUR APP MARKUP GOES HERE</div>
    //   <div style={{zIndex: 2000, ...otherStyleProps}}>  (X)
    //     <div role="presentation">                       (Y)
    //       <div>MenuItem1</div>
    //       <div>MenuItem2</div>
    //       ...
    //     </div>
    //   </div>
    // </body>
    // We need to handle 2 cases:
    // 1) When the dropdown is open and we click outside of its menu items - the click on (X) is
    // registered. To prevent closing our greenBorder menu we check if the target element has
    // a style property zIndex of 2000 and don't do anything if it does;
    // 2) When we click on the menu item of the dropdown - the click on (Y) is registered. Here
    // we check if the target is div and has role="presentation" and do nothing if it does;
    // !!!NOTE: Give current MUI architecture we can't assign an id or className to (X) or (Y) thus
    // have to select them given what we have - zIndex style property or role='presentation' attribute
    // Another possible solution to consider is to pass this event listener to the dialog which
    // contains these topicparameter dropdowns and unregister this listener on dialog open. Then
    // register it back on dialog close. But in our case this Dialog is used by several different types
    // of Select Item chips and passing additional listener prop to all of them will degrade
    // the single reponsibility principle. Hence the hack is used.
    const menuParent = document.querySelectorAll('div[role="presentation"]')[0];

    if (event && hasIgnoredElement(event.target)) {
      return;
    } else if (menuParent && menuParent.contains(event.target)) {
      return;
    }

    // END OF DIRTY HACK
    if (
      itemWithSelectRef &&
      !itemWithSelectRef.contains(event.target) &&
      !containsIfNodeExists(editParametersDialogNode, event.target)
    ) {
      document.removeEventListener("click", handleClickOutsideUsage);
      if (handler) {
        handler();
      }
    }
  };
}

function hasIgnoredElement(htmlElement) {
  return (
    htmlElement &&
    ((htmlElement.style && htmlElement.style.zIndex === "2000") ||
      hasIgnoredElement(htmlElement.parentNode))
  );
}

export class ClauseAtom extends React.Component {
  constructor(props) {
    super(props);
    this.textKey = 1;
    this.state = {showBasicTopicList: props.readOnlyModeOn};
  }

  componentWillUnmount() {
    document.removeEventListener(
      "click",
      getOutsideClickHandler(() =>
        this.setState(() => ({showBasicTopicList: true})),
      ),
    );
  }

  componentDidUpdate(prevProps) {
    const {readOnlyModeOn: readOnlyModeOnCurrent} = this.props;
    if (prevProps.readOnlyModeOn !== readOnlyModeOnCurrent) {
      if (readOnlyModeOnCurrent) {
        return this.setState(() => ({showBasicTopicList: true}));
      }
      this.setState(() => ({showBasicTopicList: false}));
      document.removeEventListener(
        "click",
        getOutsideClickHandler(() =>
          this.setState(() => ({showBasicTopicList: true})),
        ),
      );
    }
  }

  render() {
    const issues = this.getIssues();
    // const areIssuesPresent = issues.length > 0;
    const areIssuesPresent = false;
    return (
      <div
        className={areIssuesPresent ? "clause-atom-wrapper" : undefined}
        style={areIssuesPresent ? styles.issuesWrapper : {width: "100%"}}
      >
        <div style={areIssuesPresent ? styles.row : {}}>
          {this.renderClauseAtom()}
        </div>
        {areIssuesPresent && (
          <div style={styles.issuesBlock}>{this.renderIssues(issues)}</div>
        )}
      </div>
    );
  }

  renderClauseAtom() {
    const {clause, totalOffset, is_deletion: isDeletion} = this.props;
    const text =
      ("partial_text" in clause
        ? clause.partial_text.trim()
        : clause.text.trim()) || "";
    const formattedText = this.formatText(text, clause.qualifiers, totalOffset);
    const isAddition = this.isAddition();
    const isSelectedLevel = this.isSelectedLevel();
    const isSelectedPath = this.isSelectedPath();
    const isLackingTopics = this.isLackingTopics();
    const hasErrorClassifying = this.hasErrorClassifying();
    const classes = this.getClassName(
      isAddition,
      isDeletion,
      isSelectedLevel,
      isSelectedPath,
      isLackingTopics,
      hasErrorClassifying,
    );
    return (
      <div
        className={`clause-atom ${classes}`}
        id={
          this.state.showBasicTopicList
            ? undefined
            : "row-with-topics-in-select"
        }
        style={this.getStyles(
          isAddition,
          isDeletion,
          isSelectedLevel,
          isSelectedPath,
          isLackingTopics,
          hasErrorClassifying,
          this.state.showBasicTopicList,
          this.props.readOnlyModeOn,
        )}
        title={
          hasErrorClassifying ? "Error while classifying clause" : undefined
        }
      >
        <div className="text text-elements" style={styles.text}>
          {this.renderSubheading()}
          {formattedText}
        </div>
        <Topics
          {...this.props}
          onHover={this.props.onTopicsHover}
          onShowBasicTopicList={this.onShowBasicTopicList}
          showBasicTopicList={this.state.showBasicTopicList}
        />
        {!clause.is_conjunction && (
          <OptionsMenu
            clause={clause}
            clauseId={this.props.clauseId}
            refreshDocumentAndTopicsData={
              this.props.refreshDocumentAndTopicsData
            }
            updateClausepartIsBadlyParsed={
              this.props.updateClausepartIsBadlyParsed
            }
          />
        )}
        {this.renderSplitDialogue()}
      </div>
    );
  }

  getIssues() {
    const {clauseId, clause} = this.props;
    const {path} = clause;
    function hasClauseReason(reason) {
      if (reason.reason) {
        if (Array.isArray(reason.reason)) {
          return reason.reason.find(_reason =>
            hasClauseReason(_reason, clauseId),
          );
        }
        return hasClauseReason(reason.reason, clauseId);
      }
      return Array.isArray(reason)
        ? reason.find(_reason => hasClauseReason(_reason))
        : reason.clause_id === clauseId && reason.path === path;
    }
    return this.props.associatedIssues.filter(
      issue =>
        issue.reason && issue.reason.find(reason => hasClauseReason(reason)),
    );
  }

  renderIssues = issues => {
    const {
      documentClauses,
      topicsById,
      positiveReasonData,
      documentClauseparts,
      currentIssuesetItem,
      document,
    } = this.props;
    return issues.map(issue =>
      this.renderIssue(
        issue,
        currentIssuesetItem,
        documentClauses,
        topicsById,
        positiveReasonData,
        documentClauseparts,
        document?.parties || [],
      ),
    );
  };

  renderIssue(
    issue,
    issueset,
    clauses,
    topicsById,
    topicParameterData,
    documentClauseparts,
    parties,
  ) {
    const showReasonText =
      issue.reason_template && issue.reason_template.length < 1000;
    let reasonText = "";
    let showIssue = false;
    if (showReasonText) {
      reasonText = getIssueTemplatedText(
        issue,
        issueset,
        "reason_template",
        clauses,
        topicsById,
        topicParameterData,
        documentClauseparts,
        parties,
        false,
      );
      showIssue = reasonText.length > 0;
    } else {
      reasonText = "<Long summary>";
      showIssue = isIssueAlert(issue);
    }
    if (showIssue) {
      const isIssue = generalIssueClasses.indexOf(issue.issue_class_id) !== -1;
      return (
        <div
          key={issue.id}
          className={`issue ${isIssue ? "main" : "minor"}-issue`}
          style={{
            backgroundColor: isIssue ? "inherit" : "#ffe",
            display: "flex",
            alignItems: "center",
            margin: "0.5em 0",
          }}
        >
          {isIssue ? (
            <IssueIcon
              style={{
                verticalAlign: "middle",
                color: "#f00",
              }}
            />
          ) : (
            <Warning
              color="#aaa"
              style={{
                verticalAlign: "middle",
              }}
            />
          )}
          <div
            style={{paddingLeft: "4em", display: "flex", flexDirection: "row"}}
          >
            <Link
              to={{
                pathname: `/organisation/${this.props.organisationId}/issue/${issue.id}`,
              }}
              style={{textDecoration: "none"}}
              onlyActiveOnIndex
            >
              <LinkIcon style={{marginTop: "3px", marginRight: "10px"}} />
            </Link>
            <div>
              <div style={{fontSize: "12px", color: "gray"}}>
                {getIssueNameWithIssuesets(issue)}
              </div>
              <div>{reasonText}</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  renderSubheading = () => {
    if (
      this.props.clause.clausePartNumber === 1 &&
      this.props.clause.level > 1
    ) {
      const subheading = this.props.documentHeadings.find(
        heading =>
          this.props.sectionId === heading.section_id &&
          cleanReference(this.props.clause.reference) === heading.reference,
      );
      if (subheading && subheading.text) {
        return <div style={styles.subheading}>{subheading.text}</div>;
      }
    }
  };

  onShowBasicTopicList = () => {
    if (!this.props.readOnlyModeOn) {
      return;
    }
    const itemWithSelectRef = document.getElementById(
      "row-with-topics-in-select",
    );
    if (itemWithSelectRef) {
      return;
    }
    this.setState(() => ({showBasicTopicList: false}));
    document.addEventListener(
      "click",
      getOutsideClickHandler(() =>
        this.setState(() => ({showBasicTopicList: true})),
      ),
    );
  };

  getClassName(
    isAddition,
    isDeletion,
    isSelectedLevel,
    isSelectedPath,
    isLackingTopics,
    errorClassifying,
  ) {
    return [
      isAddition ? "added-clausepart" : "",
      isDeletion ? "deleted-clausepart" : "",
      isSelectedLevel ? "selected-node" : "",
      isSelectedPath ? "highlighted-node" : "",
      isLackingTopics ? "topicless-node" : "",
      errorClassifying ? "error-classifying" : "",
    ].join(" ");
  }

  getStyles(
    isAddition,
    isDeletion,
    isSelectedLevel,
    isSelectedPath,
    isLackingTopics,
    errorClassifying,
    showBasicTopicList,
    readOnlyModeOn,
  ) {
    return {
      ...styles.row,
      ...(isAddition ? styles.addedRow : {}),
      ...(isDeletion ? styles.deletedRow : {}),
      ...(isSelectedLevel ? styles.selected : {}),
      ...(isSelectedPath ? styles.highlighted : {}),
      ...(isLackingTopics ? styles.lackingTopics : {}),
      ...(!showBasicTopicList && readOnlyModeOn ? styles.topicListShown : {}),
      ...(errorClassifying ? styles.errorClassifying : {}),
    };
  }

  isAddition() {
    const {
      changes,
      clause: {id},
    } = this.props;
    return changes.find(
      change =>
        change.new_clausepart_id === id &&
        change.type === "clausepart_addition",
    );
  }

  isLackingTopics() {
    const {clause, lacksTopics} = this.props;
    return (
      lacksTopics &&
      (!clause.topics || clause.topics.length === 0) &&
      !clause.is_conjunction
    );
  }

  hasErrorClassifying() {
    const {clause} = this.props;
    return (clause.load_state & 8) > 0;
  }

  isSelectedLevel() {
    const {isInteractive, selectedLevel, clause} = this.props;
    return (
      isInteractive &&
      (selectedLevel === calculateNodeLevel(clause) ||
        (selectedLevel === -1 && clause.is_terminal)) &&
      !clause.is_conjunction
    );
  }
  isSelectedPath() {
    const {isInteractive, selectedPath, clause} = this.props;
    return isInteractive && selectedPath === clause.path;
  }

  renderSplitDialogue() {
    if (this.state.splitting && !this.props.is_deletion) {
      const {id, last_edited: lastEdited} = this.props.clause;
      return (
        <SplitDialogue
          {...this.state.splitting}
          onDismiss={this.dismissSplitDialogue}
          onSplitClause={this.onSplitClause(id, lastEdited)}
          onRemoveSplits={this.onRemoveSplits(id, lastEdited)}
        />
      );
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdate =
      this.props.clause.last_edited !== nextProps.clause.last_edited ||
      !_.isEqual(
        this.props.cleanDocumentIssues,
        nextProps.cleanDocumentIssues,
      ) ||
      this.props.selectedLevel !== nextProps.selectedLevel ||
      this.props.selectedPath !== nextProps.selectedPath ||
      this.props.isInteractive !== nextProps.isInteractive ||
      this.props.lacksTopics !== nextProps.lacksTopics ||
      this.props.showMasks !== nextProps.showMasks ||
      this.props.readOnlyModeOn !== nextProps.readOnlyModeOn ||
      !_.isEqual(this.props.clause.topics, nextProps.clause.topics) ||
      /* eslint-disable no-underscore-dangle */
      this.state._radiumStyleState !== nextState._radiumStyleState ||
      /* eslint-enable no-underscore-dangle */
      this.state.splitting !== nextState.splitting ||
      this.state.showBasicTopicList !== nextState.showBasicTopicList;
    return shouldUpdate;
  }

  formatText(text, qualifiers, totalOffset) {
    const instances = findInstances(
      text,
      this.props.definitions,
      qualifiers,
      this.props.changes,
      totalOffset,
    );
    const els = [];
    let last = 0;
    instances.forEach(instance => {
      if (instance.type === "definition") {
        // if (instance.start > last) {
        els.push(this.makeText(text.substring(last, instance.start)));
        // }
        els.push(
          this.makeText(
            text.substring(
              instance.start,
              instance.start + instance.term.length,
            ),
            instance.type,
            instance.definition,
          ),
        );
        last = instance.start + instance.term.length;
      }
      if (instance.type === "qualifier") {
        els.push(this.makeText(text.substring(last, instance.start)));
        els.push(
          this.makeText(
            text.substring(instance.start, instance.end),
            instance.type,
          ),
        );
        last = instance.end;
      }
      if (instance.type === "addition") {
        els.push(this.makeText(text.substring(last, instance.start)));
        els.push(
          this.makeText(
            text.substring(instance.start, instance.end),
            instance.type,
            instance,
          ),
        );
        last = instance.end;
      }
    });
    els.push(this.makeText(text.substring(last, text.length)));
    return els;
  }

  makeText(text, type, data) {
    let el;
    if (type === "definition") {
      el = (
        <a
          className="definition"
          title={data.meaning}
          style={{
            color: "#008",
            textDecoration: "underline",
          }}
        >
          {text}
        </a>
      );
    } else if (type === "qualifier") {
      el = (
        <a
          className="qualifier"
          style={{
            color: "#aaa",
          }}
        >
          {text}
        </a>
      );
    } else if (type === "addition") {
      el = (
        <span>
          <a
            className="change deletion"
            style={{
              color: "#f00",
            }}
          >
            {data.old_text}
          </a>
          <a
            className="change addition"
            style={{
              color: "#0f0",
            }}
          >
            {text}
          </a>
        </span>
      );
    } else {
      el = text;
    }
    this.textKey += 1;
    return (
      <span
        key={`clause-item-text-${this.textKey}`}
        onContextMenu={this.handleRightClick}
      >
        {el}
      </span>
    );
  }

  /* eslint-disable no-invalid-this */
  handleRightClick = (
    event,
    unknown,
    rawEvent,
    _getTextUnderCursor = getTextUnderCursor,
  ) => {
    try {
      const {textNode, offset} = _getTextUnderCursor(event);
      if (textNode) {
        const text = textNode.textContent;
        const parentNode = (_node => {
          let node = _node;
          while (!node.className || node.className.indexOf("text") === -1) {
            node = node.parentNode;
          }
          return node;
        })(textNode);

        const parentText = parentNode.textContent;
        const childTextStart = parentText.indexOf(text);

        const splitLocation = text.substring(0, offset).lastIndexOf(" ");
        const startText = parentText.substring(
          0,
          childTextStart + splitLocation,
        );
        const endText = parentText.substring(childTextStart + splitLocation);
        this.setState({splitting: {startText, endText}});
      }
    } finally {
      event.preventDefault();
    }
  };
  dismissSplitDialogue = () => {
    this.setState({splitting: null});
  };
  onSplitClause = _.memoize(
    clausepartId => (...args) => {
      this.props.onSplitClause(clausepartId, ...args);
      this.dismissSplitDialogue();
    },
    (...args) => JSON.stringify([...args]),
  );
  onRemoveSplits = _.memoize(
    clausepartId => () => {
      this.props.onRemoveSplits(clausepartId);
      this.dismissSplitDialogue();
    },
    (...args) => JSON.stringify([...args]),
  );
  /* eslint-enable no-invalid-this */
}

ClauseAtom.propTypes = {
  clause: PropTypes.shape({
    load_state: PropTypes.number,
    text: PropTypes.string.isRequired,
    last_edited: PropTypes.string,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        topic_id: PropTypes.number.isRequired,
        is_confirmed: PropTypes.bool.isRequired,
        topicparameters: PropTypes.arrayOf(
          PropTypes.shape({
            topicparameter_id: PropTypes.number.isRequired,
            values: PropTypes.array.isRequired,
          }),
        ),
      }),
    ),
  }),
  definitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      term: PropTypes.string.isRequired,
      meaning: PropTypes.string.isRequired,
    }),
  ),
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      new_section_id: PropTypes.number.isRequired,
      new_clause_id: PropTypes.number.isRequired,
      new_clausepart_id: PropTypes.number.isRequired,
    }),
  ),
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicsById: keyedObjectPropType(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategoriesById: keyedObjectPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  topicTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  lacksTopics: PropTypes.bool.isRequired,
  onExistingTopicAdded: PropTypes.func.isRequired,
  onNewTopicAdded: PropTypes.func.isRequired,
  onTopicRemoved: PropTypes.func.isRequired,
  onTopicConfirmed: PropTypes.func.isRequired,
  onTopicsReordered: PropTypes.func.isRequired,
  onUnconfirmedTopicsRemoved: PropTypes.func.isRequired,
  onSplitClause: PropTypes.func.isRequired,
  onRemoveSplits: PropTypes.func.isRequired,
};

export default ClauseAtom;
