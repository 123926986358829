import React from "react";

import sessionStorage from "utils/session_storage";

export default function makeComponentCollapsible(InputComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      const {collapseStateSessionName} = props;
      let isCollapsed = false;
      if (collapseStateSessionName) {
        isCollapsed = sessionStorage.getItem(collapseStateSessionName)
          ? sessionStorage.getItem(collapseStateSessionName)
          : false;
      }
      this.state = {isCollapsed};
    }
    render() {
      return (
        <InputComponent
          {...this.props}
          isCollapsed={this.state.isCollapsed}
          switchIsCollapsed={this.switchIsCollapsed}
        />
      );
    }

    switchIsCollapsed = () => {
      const {collapseStateSessionName} = this.props;
      const newValue = !this.state.isCollapsed;
      if (collapseStateSessionName) {
        sessionStorage.setItem(collapseStateSessionName, newValue);
      }
      this.setState(() => ({isCollapsed: newValue}));
    };
  };
}
