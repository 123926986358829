import React from "react";
import styled from "styled-components";
import {validate} from "email-validator";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import IpWhitelist from "./ip_whitelist";
import CompanyDetail from "./company_detail";

import EditableField from "common_components/editable_field";
import ApiTokenSettings from "common_components/api_token_settings";
import Permissioner from "utils/permissioner";
import {FormControlLabel, Switch} from "@material-ui/core";

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    overflow: "auto",
  },
};

const ApiTokenSettingsWrapper = styled.div`
  margin-top: 1rem;
`;

function hasPermission(user, permission) {
  return new Permissioner(user).hasPermission(permission);
}

type OrganisationUpdates = Partial<{
  valid_ips: unknown;
  company_detail: unknown;
  default_password_expiry: number;
  slack_update_channel: string;
  default_permission_group_id: string;
  name: string;
  api_token_user_id: number;
  /**
   * Unlike other fields of this type, this "update" does not provide a new
   * value, but only signals to the backend to generate a new API token itself
   */
  api_token_should_reset: boolean;
  use_ace: boolean;
}>;

interface Props {
  organisation: {
    users: Array<{id: number; username: string}>;
    api_token_user_id: number;
    api_token: string | null;
    use_ace: boolean;
  };
  onOrganisationUpdate: (updates: OrganisationUpdates) => void;
}

function ConfigDetailComponent({...props}: Props) {
  const {organisation, user} = props;

  function onIpWhitelistUpdate(valid_ips) {
    props.onOrganisationUpdate({valid_ips});
  }

  function onCompanyDetailUpdate(company_detail) {
    props.onOrganisationUpdate({company_detail});
  }

  function onDefaultPasswordExpiryUpdate(e) {
    const default_password_expiry = parseInt(e.target.value, 10);
    props.onOrganisationUpdate({default_password_expiry});
  }

  function onSlackUpdateChannelUpdate(e) {
    props.onOrganisationUpdate({slack_update_channel: e.target.value});
  }

  function onDefaultPermissionGroupUpdate(e) {
    props.onOrganisationUpdate({default_permission_group_id: e.target.value});
  }

  function onOrganisationNameUpdate(e) {
    const name = e.target.value;
    props.onOrganisationUpdate({name});
  }

  return (
    <div className="app-page">
      <AppBar className="appbar">
        <Toolbar className="app-toolbar-v4">
          <Typography
            className="app-toolbar-title"
            style={{marginRight: "1rem"}}
          >
            Organisation
          </Typography>
          <TextField
            type="text"
            defaultValue={organisation.name}
            onBlur={onOrganisationNameUpdate}
            style={{width: "100%", fontSize: 24}}
            disabled={!hasPermission(props.user, "update-organisation-details")}
            InputProps={{style: {fontSize: 24, fontWeight: 500}}}
          />
          {organisation.is_central_admin && <Chip label="Central admin" />}
          <div style={{display: "flex", flexGrow: 1}} />
        </Toolbar>
      </AppBar>
      <div style={styles.mainContainer}>
        <EditableField
          label="Upload Email"
          organisationId={`${organisation.id}`}
          text={organisation.upload_email}
          name="upload-email"
          updateFieldName="upload_email"
          onFieldUpdate={props.updateOrganisation}
          hasPermissionToEdit={user.is_admin}
          validator={{
            validateFunction: validate,
            errorMessage: "Please provide a valid email address",
          }}
          fitInputContent={true}
          containerStyles={{marginLeft: 0}}
          rootDivStyles={{display: "block"}}
        />
        <TextField
          label="Default password expiry (days)"
          type="number"
          defaultValue={organisation.default_password_expiry}
          inputProps={{
            min: "1",
          }}
          InputLabelProps={{style: {width: 240}}}
          style={{width: 220}}
          onBlur={onDefaultPasswordExpiryUpdate}
        />

        <TextField
          label="Slack Update Channel"
          defaultValue={organisation.slack_update_channel || ""}
          inputProps={{
            min: "1",
          }}
          InputLabelProps={{style: {width: 240}}}
          style={{width: 220, marginTop: 10}}
          onBlur={onSlackUpdateChannelUpdate}
        />

        <FormControl style={{width: 220, marginTop: 10}}>
          <InputLabel>Default Permission Group</InputLabel>
          <Select
            value={organisation.default_permission_group_id || ""}
            onChange={onDefaultPermissionGroupUpdate}
          >
            {props.permissionGroups.map(pg => (
              <MenuItem key={pg.id} value={pg.id}>
                {pg.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControlLabel
          style={{margin: "1rem 0rem", flexDirection: "row"}}
          control={
            <Switch
              color="primary"
              checked={organisation.use_ace}
              onChange={() =>
                props.onOrganisationUpdate({use_ace: !organisation.use_ace})
              }
            />
          }
          label="Use ACE for access control"
          labelPlacement="start"
        />

        <IpWhitelist
          validIps={props.organisation.valid_ips}
          user={props.user}
          onIpWhitelistUpdate={onIpWhitelistUpdate}
        />
        <CompanyDetail
          companyDetail={props.organisation.company_detail}
          onCompanyDetailUpdate={onCompanyDetailUpdate}
        />
        <ApiTokenSettingsWrapper>
          <ApiTokenSettings
            apiToken={organisation.api_token ?? ""}
            apiTokenUser={
              organisation.users.find(
                ({id}) => id === organisation.api_token_user_id,
              )?.username
            }
            users={organisation.users.map(user => user.username)}
            onChangeApiTokenUser={user =>
              props.onOrganisationUpdate({
                api_token_user_id: organisation.users.find(
                  ({username}) => username === user,
                )?.id,
              })
            }
            onClickResetToken={() =>
              props.onOrganisationUpdate({api_token_should_reset: true})
            }
          />
        </ApiTokenSettingsWrapper>
      </div>
    </div>
  );
}

export default ConfigDetailComponent;
