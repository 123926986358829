import React from "react";
import _ from "underscore";

import DocumentParseDifference from "./document_parse_difference";
import FlatButton from "material-ui/FlatButton";

export default class DocumentIssueList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showWhitespace: false};
  }
  render() {
    const {parse_diff: parseDiffs} = this.props.document;
    if (
      !this.props.document.parse_diff ||
      this.props.document.parse_diff.length === 0
    ) {
      return <div />;
    }
    const groupedDiffs = this.groupDiffs(this.state.showWhitespace, parseDiffs);
    return (
      <div
        style={{
          backgroundColor: "#fdd",
          padding: "1em",
          borderRadius: "0.25em",
          marginBottom: "1em",
        }}
      >
        <div
          className="title"
          style={{
            fontSize: "2em",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Parsing Differences
          <FlatButton
            className="show-whitespace"
            label={`${this.state.showWhitespace ? "Hide" : "Show"} Whitespace`}
            onClick={this.updateWhitespaceState}
            style={{
              float: "right",
              width: "6em",
            }}
          />
        </div>
        <div style={{fontWeight: "100"}}>
          {groupedDiffs.length === 0 ? (
            <span
              className="no-major-differences"
              style={{
                display: "block",
                marginRight: "14em",
                marginTop: "1em",
                marginBottom: "1em",
                textAlign: "center",
                fontStyle: "italic",
              }}
            >
              There are no non-whitespace differences to show
            </span>
          ) : (
            this.renderList(groupedDiffs)
          )}
        </div>
      </div>
    );
  }

  groupDiffs(showWhitespace, diffs) {
    const amountAround = 100;
    return _.chain(diffs)
      .filter(
        diff =>
          showWhitespace
            ? true
            : diff.value
                .replace(/\(\w+\)/g, "")
                .replace(/^\w{0,3}./g, "")
                .replace(/(\W|\d)+/g, "").length > 0,
      )
      .groupBy(diff => diff.offset)
      .sortBy(diffGroup => diffGroup[0].offset)
      .map(diffGroup =>
        diffGroup.reduce(
          (state, diff) => {
            if (diff.added) {
              state.added = _.omit(diff, "added", "removed", "offset");
            }
            if (diff.removed) {
              state.removed = _.omit(diff, "added", "removed", "offset");
            }
            return state;
          },
          {offset: diffGroup[0].offset},
        ),
      )
      .reduce(
        (state, diffGroup) => {
          if (diffGroup.offset - amountAround < state.lastOffset) {
            state.lastArray.push(diffGroup);
          } else {
            state.lastArray = [diffGroup];
            state.diffs.push(state.lastArray);
          }
          state.lastOffset = diffGroup.offset;
          return state;
        },
        {diffs: [], lastArray: null, lastOffset: -amountAround * 2},
      )
      .value().diffs;
  }

  renderList(diffGroups) {
    const plainText = this.props.document.plain_text.replace(
      /<\/?b>|#[A-Z_]{1,20}#/g,
      "",
    );
    return diffGroups.map((diffs, index) => (
      <DocumentParseDifference
        key={index}
        {...this.props}
        diffs={diffs}
        plainText={plainText}
      />
    ));
  }

  /* eslint-disable no-invalid-this */
  updateWhitespaceState = () => {
    this.setState({showWhitespace: !this.state.showWhitespace});
  };
  /* eslint-enable no-invalid-this */
}
