import React from "react";
import _ from "underscore";
import requestor from "requestor";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import RoleListComponent from "../components/role_list";

import addRoleAction from "modules/documents/actions/role/role_add";

import byId from "common/utils/by_id";

function RoleListContainer(props) {
  const shouldRenderContainer = isInitialised([
    props.roles,
    props.user,
    props.contractTypes,
  ]);
  if (!shouldRenderContainer || !props.user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const permissioner = new Permissioner(props.user);
  if (!permissioner.hasPermission("list-roles") || !permissioner.isAdmin()) {
    return permissioner.getNoPermissionMessage();
  }

  const {organisationId} = props.params;

  function addRole(data) {
    props.addRole(organisationId, data);
  }

  setTitle("Role List");
  return (
    <RoleListComponent
      organisationId={organisationId}
      roles={_.sortBy(props.roles, role => role.name)}
      contractTypesById={byId(props.contractTypes)}
      addRole={addRole}
      user={props.user}
    />
  );
}

function select(state, props) {
  return {
    params: props.params,
    organisation: state.organisation,
    router: state.router,
    user: state.user,
    roles: state.roles,
    contractTypes: state.contract_types,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        addRole: addRoleAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(RoleListContainer);
