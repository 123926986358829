import React, {useState, useCallback} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

import {DndProvider, useDrag, useDrop} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

const FILE_TYPE = "FILE";

const FileItem = ({file, index, moveFile}) => {
  const ref = React.useRef(null);

  const [, drop] = useDrop({
    accept: FILE_TYPE,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveFile(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{isDragging}, drag] = useDrag({
    type: FILE_TYPE,
    item: {type: FILE_TYPE, index},
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{
        padding: "8px",
        border: "1px solid #ccc",
        marginBottom: "4px",
        color: "#888",
        backgroundColor: isDragging ? "#86c5f9" : "white",
      }}
    >
      {file.name}
    </div>
  );
};

const FileList = ({files, onOrderChange}) => {
  const moveFile = useCallback(
    (fromIndex, toIndex) => {
      const updatedFiles = [...files];
      const [movedFile] = updatedFiles.splice(fromIndex, 1);
      updatedFiles.splice(toIndex, 0, movedFile);

      onOrderChange(updatedFiles);
    },
    [files, onOrderChange],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      {files.map((file, index) => (
        <FileItem
          key={file.name}
          file={file}
          index={index}
          moveFile={moveFile}
        />
      ))}
    </DndProvider>
  );
};

export default function(props) {
  const {files: initialFiles} = props;
  const [files, setFiles] = useState(initialFiles);
  const handleOrderChange = updatedFiles => {
    setFiles(updatedFiles);
  };

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Merge documents</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Would you like to analyse the {props.files?.length} documents
          together?
        </DialogContentText>
        <FileList files={files} onOrderChange={handleOrderChange} />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => props.onConfirm(files, true)}
          color="primary"
          size="small"
          variant="contained"
        >
          Together
        </Button>
        <Button
          onClick={() => props.onConfirm(files, false)}
          color="primary"
          size="small"
          variant="contained"
        >
          Separately
        </Button>
        <Button onClick={props.onClose} size="small" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
