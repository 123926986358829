import React from "react";
import ReactDOM from "react-dom";
import _ from "underscore";
import scrollIntoView from "scroll-into-view";
import {Link} from "react-router";

import TopicSelector from "common_components/topic_selector";
import TopicSummary from "common_components/topic_selector/topic_summary";
import {getOutsideClickHandler} from "../../document_clauses/components/clause_atom";

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import BugReportIcon from "@material-ui/icons/BugReport";

import getParameterValueStatusData from "common_components/topic_selector/utils/get_parameter_value_status_data";
import CheckBoxBasic from "common_components/inputs/checkbox_basic";
import uuid from "../../../utils/uuid";
import clausepartStyles, {
  greens,
} from "routes/topic_analysis/components/const/clausepart_styles";

const styles = {
  topicparameterNotFinishedUpdating: {
    backgroundColor: "#FFCDD2",
  },
  bugIconBlock: {
    height: "18px",
    width: "18px",
    cursor: "pointer",
    padding: "0 5px",
  },
  bugIcon: {
    height: "18px",
    width: "18px",
  },
  clauseBlock: {
    display: "flex",
    justifyItems: "center",
  },
  buttonBlock: {
    display: "flex",
    justifyContent: "center",
  },
  topicSummary: {
    backgroundColor: "rgb(216, 234, 253)",
    border: "1px solid #bbb",
    borderRadius: "1em",
  },
  regexWrapper: {
    borderTop: "1px dashed lightgrey",
    marginTop: "0.5rem",
    display: "inline-block",
    padding: "0.5rem 0",
    fontSize: "11px",
  },
  regexBlock: {
    textAlign: "right",
    marginRight: "0.5rem",
  },
};

const statsNames = {
  unconfirmed: "Unconfirmed",
  tp: "Correct",
  fp: "False Match",
  fn: "Missed",
  tn: "Empty",
  incorrect: "Incorrect",
  other: "Not set",
};

const greenColors = greens;

const mainGreen = greenColors[0];

export default class TopicUsage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isElementHighlighted: false,
      showBasicTopicList: true,
      showOtherTopics: !props.hideOtherTopics,
      updatedParameters: {},
      isBadlyParsed: props.usage.clausepart_is_badly_parsed,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.showUsageId !== prevProps.showUsageId &&
      this.props.showUsageId === null &&
      this.props.usage.id === prevProps.showUsageId
    ) {
      const el = ReactDOM.findDOMNode(this[`usage_${this.props.usage.id}`]);
      if (el) {
        scrollIntoView(el);
      }
      this.setState(
        () => ({isElementHighlighted: true}),
        () =>
          setTimeout(
            () => this.setState(() => ({isElementHighlighted: false})),
            2500,
          ),
      );
    }
    if (this.props.hideOtherTopics !== prevProps.hideOtherTopics) {
      this.setState(() => ({showOtherTopics: !this.props.hideOtherTopics}));
    }
    if (this.props.isBadlyParsedIds !== prevProps.isBadlyParsedIds) {
      if (this.props.isBadlyParsedIds.find(id => id === this.props.usage.id)) {
        this.setState({isBadlyParsed: true});
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.usage.clausepart_last_edited !==
        nextProps.usage.clausepart_last_edited ||
      !_.isEqual(
        this.props.showingParameterEditor,
        nextProps.showingParameterEditor,
      ) ||
      this.props.usage.usageScore !== nextProps.usage.usageScore ||
      this.props.showUsageId !== nextProps.showUsageId ||
      this.state.isElementHighlighted !== nextState.isElementHighlighted ||
      this.state.showBasicTopicList !== nextState.showBasicTopicList ||
      this.props.isDevModeOn !== nextProps.isDevModeOn ||
      this.state.showOtherTopics !== nextState.showOtherTopics ||
      !_.isEqual(this.state.updatedParameters, nextState.updatedParameters) ||
      this.props.hideOtherParameters !== nextProps.hideOtherParameters ||
      this.props.hideOtherTopics !== nextProps.hideOtherTopics ||
      !_.isEqual(this.props.unselectedUsageIds, nextProps.unselectedUsageIds) ||
      this.props.usesToSelect !== nextProps.usesToSelect ||
      !_.isEqual(this.props.selectedRegExp !== nextProps.selectedRegExp)
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "click",
      getOutsideClickHandler(() =>
        this.setState(() => ({showBasicTopicList: true})),
      ),
    );
  }

  onShowBasicTopicList = () => {
    if (!this.state.showOtherTopics) {
      return;
    }
    const itemWithSelectRef = document.getElementById(
      "row-with-topics-in-select",
    );
    if (itemWithSelectRef) {
      return;
    }
    this.setState(() => ({showBasicTopicList: false}));
    document.addEventListener(
      "click",
      getOutsideClickHandler(() =>
        this.setState(() => ({showBasicTopicList: true})),
      ),
    );
  };

  render() {
    const {
      topic,
      parameters,
      showingParameterEditor,
      usesToSelect,
      usage,
    } = this.props;
    const {
      clause_id: clauseId,
      clausepart_id: clausepartId,
      clause_reference: clauseReference,
      clausepart_reference: clausepartReference,
      clausepart_last_edited: clausepartLastEdited,
      document_id: documentId,
      document_name: documentName,
      project_id: projectId,
      project_name: projectName,
      parameter_values: _parameterValues,
      usageScore,
      is_confirmed: isConfirmed,
      roles,
      roles_out_of_date: rolesOutOfDate,
    } = usage;

    if (!documentId) {
      return null;
    }

    const parameterValues = getParameterValues(
      _parameterValues,
      this.props.isDevModeOn,
    );

    if (this.state.isBadlyParsed) {
      return null;
    }

    return (
      <tr
        id={
          this.state.showBasicTopicList
            ? undefined
            : "row-with-topics-in-select"
        }
        ref={this.createUsageRef(this.props.usage.id)}
        style={{
          borderBottom: "1px solid lightgray",
          ...(this.state.showBasicTopicList
            ? {}
            : {outline: "3px solid #43A047"}),
        }}
      >
        <td>
          <div>
            <span>Project: </span>
            <span>{projectName}</span>
          </div>
          <div>
            <span>Document: </span>
            <Link
              className="document-link"
              to={{pathname: this.getClauseLink()}}
              onlyActiveOnIndex
            >
              <span className="document-name">{documentName}</span>
            </Link>
          </div>
          <div>
            <span>Document Id: </span>
            <Link
              className="document-id"
              to={{pathname: this.getClauseLink()}}
              onlyActiveOnIndex
            />
            <span className="document-id">{documentId}</span>
            {showingParameterEditor?.parameter_type === "role" &&
            rolesOutOfDate ? (
              <span
                title="Roles require reprocessing"
                style={{marginLeft: "5px"}}
              >
                <Chip label="R" />
              </span>
            ) : (
              ""
            )}
          </div>
          <div style={styles.clauseBlock}>
            <span>Clause: </span>
            <Link
              className="clause-link"
              to={{
                pathname: this.getClauseLink(),
                search: `?clause=${clauseReference}`,
              }}
              onlyActiveOnIndex
            >
              <span className="clause-reference">{clausepartReference}</span>
            </Link>
            <span
              style={styles.bugIconBlock}
              onClick={this.markClauseIsBadlyParsed}
              title={"Mark clause as badly parsed"}
            >
              <BugReportIcon style={styles.bugIcon} />
            </span>
          </div>
        </td>
        {showingParameterEditor && <td>{statsNames[usageScore]}</td>}
        <td
          className="clause-text"
          style={{
            ...(this.state.isElementHighlighted
              ? {background: "#eeeeee"}
              : !this.hasParameterFinishedUpdating()
              ? styles.topicparameterNotFinishedUpdating
              : this.getUsageStyle(usageScore)),
            borderRadius: "4px",
            cursor: "pointer",
            lineHeight: "24px",
          }}
          onClick={this.onTextClick}
        >
          {this.renderClausepartText()}
        </td>
        <td>
          <div style={styles.topicSummary}>
            <TopicSummary
              topic={{
                name: topic.name,
                parameters: parameters.map(param =>
                  this.state.updatedParameters[param.id]
                    ? {...param, isUpdated: true}
                    : param,
                ),
                parameterValues,
                value: topic.id,
                is_confirmed: isConfirmed,
              }}
              clause={{
                id: clausepartId,
                clause_id: clauseId,
                document_id: documentId,
                project_id: projectId,
                last_edited: clausepartLastEdited,
                clausepart_id: clausepartId,
                roles,
              }}
              showParametersButton={true}
              onTopicparameterValuesUpdate={this.onUpdateTopicparameterValues}
              updatedParameters={this.state.updatedParameters}
              showingParameterId={
                showingParameterEditor && showingParameterEditor.id
              }
              hideOtherParameters={this.props.hideOtherParameters}
              roles={this.props.roles}
            />
          </div>
        </td>
        <td onClick={this.onShowBasicTopicList}>
          {this.state.showOtherTopics ? (
            <>
              {this.renderOtherTopicSelector()}

              {this.props.hideOtherTopics && (
                <div style={styles.buttonBlock}>
                  <Button onClick={this.onHideOtherTopics} variant="outlined">
                    Hide Other Topics
                  </Button>
                </div>
              )}
            </>
          ) : (
            this.props.hideOtherTopics && (
              <div style={styles.buttonBlock}>
                <Button
                  onClick={this.onShowOtherTopics}
                  variant="outlined"
                  color="primary"
                >
                  Show Other Topics
                </Button>
              </div>
            )
          )}
        </td>
        <td>
          {this.renderActionButtons(this.getActionButtons(parameterValues))}
        </td>
        {usesToSelect && usageScore === usesToSelect.toLowerCase() && (
          <td key="select-checkbox">
            <CheckBoxBasic
              containerStyles={{marginBottom: 6}}
              checked={!this.props.unselectedUsageIds[clausepartId]}
              onCheck={this.props.triggerUnselectUsage(clausepartId)}
            />
          </td>
        )}
      </tr>
    );
  }

  renderOtherTopicSelector() {
    const {
      section_id: sectionId,
      clause_id: clauseId,
      clausepart_id: clausepartId,
      clausepart_last_edited: clausepartLastEdited,
      document_id: documentId,
      project_id: projectId,
      other_topics: _otherTopics,
      roles,
    } = this.props.usage;
    const {topic} = this.props;

    const otherTopics = _otherTopics.map(item => ({
      ...item,
      topicparameters: getParameterValues(
        item.topicparameters,
        this.props.isDevModeOn,
      ),
      ...(item.topic_id === topic.id ? {hide: true} : {}),
    }));
    return (
      <TopicSelector
        showBasicTopicList={this.state.showBasicTopicList}
        organisationId={this.props.organisationId}
        clause={{
          id: clausepartId,
          clause_id: clauseId,
          document_id: documentId,
          project_id: projectId,
          last_edited: clausepartLastEdited,
          topics: otherTopics,
          roles,
        }}
        topics={this.props.topics}
        topicsById={this.props.topicsById}
        topicCategories={this.props.topicCategories}
        topicCategoriesById={this.props.topicCategoriesById}
        topicTags={this.props.topicTags}
        clausepartsTopicsUpdated={this.props.clausepartsTopicsUpdated}
        onExistingTopicAdded={this.onExistingTopicAdded(
          projectId,
          documentId,
          sectionId,
          clauseId,
        )}
        onNewTopicAdded={this.onNewTopicAdded(
          projectId,
          documentId,
          sectionId,
          clauseId,
        )}
        onTopicRemoved={this.onTopicRemoved(
          projectId,
          documentId,
          sectionId,
          clauseId,
        )}
        onTopicsReordered={this.onTopicsReordered(
          projectId,
          documentId,
          sectionId,
          clauseId,
        )}
        onUnconfirmedTopicsRemoved={this.onUnconfirmedTopicsRemoved(
          projectId,
          documentId,
          sectionId,
          clauseId,
        )}
        onTopicConfirmed={this.onTopicConfirmed(
          projectId,
          documentId,
          sectionId,
          clauseId,
        )}
        onTopicparameterValuesUpdate={this.props.onTopicparameterValuesUpdate}
        contractTypesById={this.props.contractTypesById}
        roles={this.props.roles}
      />
    );
  }

  getActionButtons(parameterValuesBase) {
    const {showingParameterEditor, usage, topic} = this.props;
    const {
      project_id: projectId,
      document_id: documentId,
      section_id: sectionId,
      clause_id: clauseId,
      clausepart_id: clausepartId,
      clausepart_last_edited: clausepartLastEdited,
    } = usage;
    const {id: topicId} = topic;

    const actions = [];
    if (showingParameterEditor) {
      // action buttons are related to parameters

      // 1) buttons related to all parameters
      const unconfirmedParameterValues = parameterValuesBase.filter(
        param =>
          param.values && param.values.length > 0 && !param.actual_values,
      );

      if (unconfirmedParameterValues.length > 1) {
        const topicparameterUpdates = unconfirmedParameterValues.reduce(
          (result, param) => {
            result[param.topicparameter_id] = {
              values: [...param.values],
              valuesChanged: true,
            };
            return result;
          },
          {},
        );

        const data = {
          topicparameterUpdates,
          clausepart_last_edited: clausepartLastEdited,
        };

        const handler = () =>
          this.onUpdateTopicparameterValues(
            projectId,
            documentId,
            clauseId,
            clausepartId,
            topicId,
            data,
          );
        actions.push({
          Icon: DoneAllIcon,
          label: "Confirm Parameter Values For All Parameters",
          handler,
        });
      }

      // 2) buttons related to the selected parameter
      const showingParameterValues = parameterValuesBase.find(
        value => value.topicparameter_id === showingParameterEditor.id,
      );
      if (!showingParameterValues) {
        return actions;
      }

      const {values, actual_values: actualValues} = showingParameterValues;
      const {
        confirmed,
        deleted,
        unconfirmed,
        added,
      } = getParameterValueStatusData(
        values,
        actualValues,
        showingParameterEditor.parameter_type,
      );

      if (unconfirmed.length > 0) {
        // creating confirm handler for current parameter
        const topicparameterUpdates = {
          [showingParameterEditor.id]: {
            values: [...values],
            valuesChanged: true,
          },
        };

        const data = {
          topicparameterUpdates,
          clausepart_last_edited: clausepartLastEdited,
        };

        const handler = () =>
          this.onUpdateTopicparameterValues(
            projectId,
            documentId,
            clauseId,
            clausepartId,
            topicId,
            data,
          );
        actions.push({
          Icon: DoneIcon,
          label: "Confirm Parameter Values",
          handler,
        });
      }

      const allExceptDeleted = [...confirmed, ...unconfirmed, ...added];
      if (
        deleted.length < allExceptDeleted.length ||
        (allExceptDeleted.length > 0 &&
          deleted.length > 0 &&
          !_.isEqual(deleted, allExceptDeleted))
      ) {
        // creating delete handler
        const topicparameterUpdates = {
          [showingParameterEditor.id]: {values: [], valuesChanged: true},
        };

        const data = {
          topicparameterUpdates,
          clausepart_last_edited: clausepartLastEdited,
        };

        const handler = () =>
          this.onUpdateTopicparameterValues(
            projectId,
            documentId,
            clauseId,
            clausepartId,
            topicId,
            data,
          );

        actions.push({
          Icon: DeleteIcon,
          label: "Remove Parameter Values",
          handler,
        });
      }
    } else {
      // else action buttons are related to the topic
      const {needsConfirming} = this.props;
      if (needsConfirming) {
        actions.push({
          Icon: DoneIcon,
          label: "Confirm Topic",
          handler: this.onUsageConfirmed(
            projectId,
            documentId,
            sectionId,
            clauseId,
            clausepartId,
            clausepartLastEdited,
          ),
        });
      }
      actions.push({
        Icon: DeleteIcon,
        label: "Remove Topic",
        handler: this.onUsageRemoved(
          projectId,
          documentId,
          sectionId,
          clauseId,
          clausepartId,
          clausepartLastEdited,
        ),
      });
    }
    return actions;
  }

  renderActionButtons = actions => (
    <div style={{display: "flex", flexDirection: "column"}}>
      {actions.map(({Icon, label, handler}, index) => (
        <div key={index} title={label}>
          <Icon style={{cursor: "pointer"}} onClick={handler} />
        </div>
      ))}
    </div>
  );

  getRegexMatchLocation(text, editorValues) {
    let matches = [];
    if (editorValues.length === 0) {
      return matches;
    }
    editorValues.forEach((editorValue, index) => {
      if (
        !editorValue ||
        !editorValue.pattern ||
        editorValue.pattern.length === 0
      ) {
        return;
      }
      matches = [
        ...matches,
        ...getRegexMatches(text, editorValue.pattern[0], index),
      ];
    });
    return matches;
  }

  getNonRegexMatchLocation(text, parameterValues, editorValues) {
    let matches = [];
    if (
      !parameterValues ||
      ((!parameterValues.actual_values ||
        parameterValues.actual_values.length === 0) &&
        !(
          parameterValues.dev_values ||
          parameterValues.locked_values ||
          parameterValues.values
        )) ||
      !editorValues ||
      editorValues.length === 0
    ) {
      return matches;
    }
    for (let index = 0; index < editorValues.length; index++) {
      const editorValue = editorValues[index];
      try {
        const match = text.match(
          new RegExp(`${editorValue.pre}(.*?)${editorValue.post}`),
        );
        if (match) {
          if (editorValue.pre && editorValue.post) {
            const matchedText = match[0];
            const preMatchStart = match.index;
            const postMatch = matchedText.match(new RegExp(editorValue.post));
            const postMatchStart = preMatchStart + postMatch.index;
            const textWithoutPost = text.slice(0, postMatchStart);
            let newMatches = [
              ...getRegexMatches(
                textWithoutPost,
                editorValue.pre,
                index,
                "pre",
              ),
              ...getRegexMatches(
                textWithoutPost,
                editorValue.post,
                index,
                "post",
              ),
            ];
            if (!newMatches.length) {
              newMatches = [
                ...getRegexMatches(text, editorValue.pre, index, "pre"),
                ...getRegexMatches(text, editorValue.post, index, "post"),
              ];
            }
            matches = matches.concat(newMatches);
          } else {
            if (editorValue.pre) {
              matches = matches.concat(
                getRegexMatches(text, editorValue.pre, index, "pre"),
              );
            }
            if (editorValue.post) {
              matches = matches.concat(
                getRegexMatches(text, editorValue.post, index, "post"),
              );
            }
          }
        }
      } catch {
        // skip error
      }
    }
    return matches;
  }

  getSelectedTopicparameterMatches = text => {
    const {showingParameterEditor} = this.props;
    let matches = [];
    if (showingParameterEditor) {
      const {
        parameter_type,
        dev_value: devValue = {},
        value = {},
      } = showingParameterEditor;
      if (parameter_type === "regex") {
        matches = this.getRegexMatchLocation(
          text,
          (devValue && devValue.values) || (value && value.values),
        );
      } else {
        const parameterValues = (this.props.usage.parameter_values || []).find(
          pv => pv.topicparameter_id === showingParameterEditor.id,
        );
        matches = this.getNonRegexMatchLocation(
          text,
          parameterValues,
          (devValue && devValue.whitelist) || (value && value.whitelist),
        );
      }
    }
    return matches;
  };

  renderRegex(fragmentsPatterns, showRegexes) {
    const patterns = Object.keys(fragmentsPatterns);
    const items = ["__header__", "__header__.", "__footer__", "__footer__."];
    const filteredPatterns = patterns.filter(
      pattern => !items.includes(pattern),
    );

    if (!filteredPatterns || filteredPatterns.length === 0 || !showRegexes) {
      return null;
    }

    return (
      <div style={styles.regexWrapper}>
        {filteredPatterns.map(pattern => (
          <div key={uuid()}>
            <span
              style={{
                ...styles.regexBlock,
                color: fragmentsPatterns[pattern]
                  ? fragmentsPatterns[pattern]
                  : "#9e9e9e",
              }}
            >
              {pattern}
            </span>
          </div>
        ))}
      </div>
    );
  }

  renderClausepartText() {
    const {clausepart_partial_text: partialText} = this.props.usage;
    const text = this.props.usage.clausepart_text.trim();
    const matches = this.getSelectedTopicparameterMatches(text);
    const headerAndFooterMatches = getHeaderAndFooterMatches(text, partialText);
    const allMatches = matches.concat(headerAndFooterMatches);
    const errorMatches = _.chain(allMatches)
      .filter(match => match.isError)
      .uniq(match => match.pattern)
      .value();
    const matchesColors = getMatchesColors(
      allMatches,
      this.props.selectedRegExp,
    );
    const fragmentsWithMatches = constructFragmentsWithMatches(
      text,
      allMatches,
    );

    const matchEls = fragmentsWithMatches.map((fragment, fragmentIndex) => (
      <span
        key={`${fragment.text}-${fragmentIndex}`}
        style={
          fragment.patterns.length > 0
            ? {
                ...clausepartStyles.common,
                borderBottomColor: matchesColors[fragment.patterns[0]],
              }
            : undefined
        }
        title={constructMatchTitle(fragment)}
      >
        {fragment.text}
      </span>
    ));

    if (errorMatches && errorMatches.length > 0) {
      return (
        <div>
          <div>{matchEls}</div>
          <div style={{color: "red", textDecoration: "underline"}}>
            Error matches patterns:
          </div>
          <div style={{color: "red"}}>
            {errorMatches.map(match => (
              <div key={match.pattern}>{match.pattern}</div>
            ))}
          </div>
        </div>
      );
    }

    return (
      <>
        <div>{matchEls}</div>
        {this.renderRegex(matchesColors, this.props.showMatchRegexes)}
      </>
    );
  }

  onShowOtherTopics = () => this.setState(() => ({showOtherTopics: true}));

  onHideOtherTopics = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(() => ({showOtherTopics: false}));
  };

  /* eslint-disable no-invalid-this */
  onUsageRemoved = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
  ) => () => {
    this.props.onUsageRemoved(
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clausepartLastEdited,
    );
  };

  onUsageConfirmed = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
  ) => () => {
    this.props.onUsageConfirmed(
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clausepartLastEdited,
    );
  };

  onExistingTopicAdded = _.memoize(
    (projectId, documentId, sectionId, clauseId) => (...args) => {
      return this.props.onExistingTopicAdded(
        projectId,
        documentId,
        sectionId,
        clauseId,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onNewTopicAdded = _.memoize(
    (projectId, documentId, sectionId, clauseId) => (...args) => {
      return this.props.onNewTopicAdded(
        projectId,
        documentId,
        sectionId,
        clauseId,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onTopicRemoved = _.memoize(
    (projectId, documentId, sectionId, clauseId) => (...args) => {
      return this.props.onTopicRemoved(
        projectId,
        documentId,
        sectionId,
        clauseId,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onUnconfirmedTopicsRemoved = _.memoize(
    (projectId, documentId, sectionId, clauseId) => (...args) => {
      return this.props.onUnconfirmedTopicsRemoved(
        projectId,
        documentId,
        sectionId,
        clauseId,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onTopicConfirmed = _.memoize(
    (projectId, documentId, sectionId, clauseId) => (...args) => {
      return this.props.onTopicConfirmed(
        projectId,
        documentId,
        sectionId,
        clauseId,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onTopicsReordered = _.memoize(
    (projectId, documentId, sectionId, clauseId) => (...args) => {
      return this.props.onTopicsReordered(
        projectId,
        documentId,
        sectionId,
        clauseId,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onUpdateTopicparameterValues = (
    project_id,
    document_id,
    clause_id,
    clausepartId,
    topicId,
    data = {},
  ) => {
    const {topicparameterUpdates = {}} = data;
    const updatedParameterIds = Object.keys(topicparameterUpdates);
    const newUpdatedParameters = {...this.state.updatedParameters};
    for (const parameterId of updatedParameterIds) {
      newUpdatedParameters[parameterId] = true;
    }

    this.setState(
      () => ({updatedParameters: newUpdatedParameters}),
      async () => {
        await this.props.onTopicparameterValuesUpdate(
          project_id,
          document_id,
          clause_id,
          clausepartId,
          topicId,
          data,
        );
        const newUpdatedParameters = {...this.state.updatedParameters};
        for (const parameterId of updatedParameterIds) {
          delete newUpdatedParameters[parameterId];
        }
        this.setState(() => ({updatedParameters: newUpdatedParameters}));
      },
    );
  };

  getClauseLink() {
    const {organisationId} = this.props;
    const {project_id: projectId, document_id: documentId} = this.props.usage;
    return (
      `/organisation/${organisationId}/project/${projectId}` +
      `/document/${documentId}`
    );
  }

  markClauseIsBadlyParsed = () => {
    const {
      clausepart_id: id,
      clause_id,
      document_id,
      project_id,
    } = this.props.usage;
    this.props.handleBadlyParsedModalOpen(
      id,
      clause_id,
      document_id,
      project_id,
    );
  };

  hasParameterFinishedUpdating = () => {
    if (!this.props.showingParameterEditor) {
      return true;
    }
    const {usage, showingParameterEditor} = this.props;
    if (!usage) {
      return false;
    }
    const usageHasEditedTopicparameter = (usage.parameter_values || []).find(
      pv => pv.topicparameter_id === showingParameterEditor.id,
    );
    if (!usageHasEditedTopicparameter) {
      return true;
    }
    const clausePartLastEdited = Date.parse(usage.clausepart_last_edited);
    const parameterLastEdited = Date.parse(showingParameterEditor.last_edited);
    return clausePartLastEdited > parameterLastEdited;
  };

  getUsageStyle(usageScore) {
    switch (usageScore) {
      case "fn":
        return {
          border: "1px solid #fdb056",
        };
      case "fp":
        return {
          backgroundColor: "rgba(224, 224, 224)",
        };
      case "unconfirmed":
        return {
          backgroundColor: "rgba(255, 224, 178, 0.3)",
        };
      case "tp":
        return {
          border: "1px solid #aaf",
        };
      default:
        return {};
    }
  }

  onTextClick = () => {
    this.props.triggerShowUsage(
      this.props.caption,
      this.props.usage.id,
      this.props.usage.usageScore,
    );
  };

  createUsageRef = usageId => node => (this[`usage_${usageId}`] = node);
}

function getRegexMatches(text, pattern, index, type) {
  const resultMatches = [];
  try {
    const textLimitLength = 2000;
    const limitedText = text.slice(0, textLimitLength);
    const regex = new RegExp(pattern, "g");
    {
      let match;
      let matchesCount = 0;
      while ((match = regex.exec(limitedText.replace(/\n/g, " ")))) {
        if (matchesCount === 10) {
          resultMatches.push({
            isError: true,
            pattern,
          });
          break;
        }
        matchesCount += 1;

        if (match[0].length > 0) {
          resultMatches.push({
            ...match,
            cIndex: index,
            pattern: type
              ? `${index + 1}-${type}. ${pattern}`
              : `${index + 1}. ${pattern}`,
            start: match.index,
            end: match.index + match[0].length - 1,
          });
        }
      }
    }
  } catch {
    // ignore bad regexes
  }
  return resultMatches;
}

function getHeaderAndFooterMatches(text, partialText) {
  const headerAndFooterMatches = [];
  const headerEnd = text.indexOf(partialText) - 1;
  if (headerEnd && headerEnd > 0) {
    headerAndFooterMatches.push({
      start: 0,
      end: headerEnd,
      pattern: "__header__",
    });
  }
  const footerStart = headerEnd + partialText.length;
  const footerEnd = text.length - 1;
  if (footerStart && footerEnd && footerEnd > footerStart) {
    headerAndFooterMatches.push({
      start: footerStart,
      end: footerEnd,
      pattern: "__footer__",
    });
  }
  return headerAndFooterMatches;
}

function constructFragmentsWithMatches(text, matches) {
  const baseResult = [];
  for (let i = 0; text && i < text.length; i++) {
    const item = {char: text[i], patterns: []};
    matches.forEach(match => {
      if (typeof match.start !== "number" || typeof match.end !== "number") {
        return;
      }
      if (i >= match.start && i <= match.end) {
        item.patterns.push(match.pattern);
      }
    });
    baseResult.push(item);
  }

  // merge alike results
  const fragments = [];
  if (baseResult.length === 0) {
    return fragments;
  }
  let lastBlockItem = baseResult[0];
  let blockFullText = baseResult[0].char;
  for (let i = 1; i < baseResult.length; i++) {
    const baseResultItem = baseResult[i];
    if (_.isEqual(baseResultItem.patterns, lastBlockItem.patterns)) {
      blockFullText += baseResultItem.char;
    } else {
      fragments.push({text: blockFullText, patterns: lastBlockItem.patterns});
      lastBlockItem = baseResultItem;
      blockFullText = baseResultItem.char;
    }
  }

  if (blockFullText) {
    fragments.push({text: blockFullText, patterns: lastBlockItem.patterns});
  }
  return fragments;
}

function getMatchesColors(allMatches, selectedRegExp) {
  const greenShades = [...greenColors];
  const colors = {};
  allMatches.forEach(match => {
    let color;
    if (selectedRegExp) {
      const test = new RegExp(selectedRegExp).test(match[0]);
      if (test) {
        color = mainGreen;
      }
    }
    if (!selectedRegExp) {
      if (match.pattern === "__header__" || match.pattern === "__footer__") {
        color = "#888";
      } else {
        color = greenShades.shift() || mainGreen;
      }
    }
    colors[match.pattern] = color;
  });
  return colors;
}

function constructMatchTitle(match) {
  return _.chain(match.patterns)
    .filter(pattern => pattern !== "__header__" && pattern !== "__footer__")
    .sortBy(item => item)
    .value()
    .join("\n");
}

function getParameterValues(baseValues, isDevModeOn) {
  if (!baseValues) {
    return baseValues;
  }
  if (isDevModeOn) {
    return baseValues.map(value => {
      return {
        ...value,
        values: value.dev_values || value.values,
      };
    });
  }
  return baseValues.map(value => {
    return {
      ...value,
      values: value.locked_values || value.values,
    };
  });
}
