import React from "react";
import PropTypes from "prop-types";
import radium from "radium";

import RightIcon from "material-ui/svg-icons/navigation/chevron-right";
import DoneIcon from "material-ui/svg-icons/action/done";
import ClearIcon from "material-ui/svg-icons/content/clear";

import IconButton from "material-ui/IconButton";
import {Link} from "react-router";

import TopicSummary from "./topic_summary";
import FixedTooltip from "common_components/fixed_tooltip";
import isCorrect from "./utils/is_correct";
import getHasMatchBorderStyle from "./utils/get_has_match_border_style";

const constants = {
  borderRadius: "2px",
  borderColor: "lightgray",
  singleBorderColor: "rgba(105, 105, 105, 0.12)",
  singleBorderWidth: "0.5px",
  backgroundColor: "#ffe0b2",
};
const baseChipStyle = {
  borderStyle: "solid",
  borderWidth: "2px",
  borderColor: constants.borderColor,
  backgroundColor: constants.backgroundColor,
};
const styles = {
  item: {
    display: "flex",
    margin: "3px",
  },
  note: {
    fontSize: "0.8em",
    backgroundColor: "rgba(255, 78, 0, 0.08)",
  },
  link: {
    ...baseChipStyle,
    borderLeftWidth: constants.singleBorderWidth,
    borderLeftColor: constants.singleBorderColor,
    verticalAlign: "middle",
    ":hover": {
      backgroundColor: "#d8eafd",
    },
    borderTopRightRadius: constants.borderRadius,
    borderBottomRightRadius: constants.borderRadius,
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  topicSummaryContainer: {
    ...baseChipStyle,
    borderRightColor: constants.singleBorderColor,
    borderTopLeftRadius: constants.borderRadius,
    borderBottomLeftRadius: constants.borderRadius,
    borderRightWidth: constants.singleBorderWidth,
    color: "rgb(128,128,128)",
    borderRight: "none",
  },
  iconButton: {
    height: "0.5em",
    width: "20px",
    padding: 0,
    marginTop: "-2px",
    verticalAlign: "top",
    zIndex: 0,
  },
  unconfirmedTopicActions: {
    ...baseChipStyle,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    borderRight: "none",
    borderLeft: "none",
  },
  actionButton: {
    height: "22px",
    width: "22px",
    cursor: "pointer",
  },
};

export function UnconfirmedItem(props) {
  const {option} = props;
  const isTopicCorrect = isCorrect(option.clauseTopic.has_match, false);
  const content = (
    <div style={{display: "inline-block"}}>
      <div
        style={{
          ...styles.item,
          ...(option.topic.startsWith("NOTE:") ? styles.note : {}),
          ...getHasMatchBorderStyle(isTopicCorrect, true),
        }}
      >
        <TopicSummary
          topic={{
            name: option.topic,
            ...option,
          }}
          clause={props.clause}
          isRemoved={true}
          topicSummaryContainerStyles={styles.topicSummaryContainer}
          onTopicparameterValuesUpdate={props.onTopicparameterValuesUpdate}
          showParametersButton={true}
          roles={props.roles}
        />
        <div style={styles.unconfirmedTopicActions}>
          <ClearIcon
            onClick={props.onTopicRemoved}
            style={styles.actionButton}
          />
          <DoneIcon
            onClick={props.onTopicConfirmed}
            style={styles.actionButton}
          />
        </div>
        <span style={styles.link}>
          {typeof option.value === "number" ? (
            <Link
              to={{
                pathname: `/organisation/${props.organisationId}/topic/${
                  option.value
                }/detail`,
              }}
              style={{textDecoration: "none"}}
            >
              <IconButton className="go-to-topic" style={styles.iconButton}>
                <RightIcon color="rgb(128,128,128)" />
              </IconButton>
            </Link>
          ) : null}
        </span>
      </div>
    </div>
  );
  return isTopicCorrect === undefined ? (
    content
  ) : (
    <FixedTooltip
      content={
        isTopicCorrect
          ? "Topic is correctly aplied"
          : "Topic has been incorrectly applied"
      }
    >
      {content}
    </FixedTooltip>
  );
}

UnconfirmedItem.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    topic: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }),
  organisationId: PropTypes.number.isRequired,
};

export default radium(UnconfirmedItem);
