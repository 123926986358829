import React from "react";
import _ from "underscore";
import VisibilitySensor from "react-visibility-sensor";
import PropTypes from "prop-types";

import TopicListItem from "./topic_list_item";

import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import CheckBoxIcon from "material-ui/svg-icons/toggle/check-box";
import CheckBoxBlankIcon from "material-ui/svg-icons/toggle/check-box-outline-blank";
import FindInPageIcon from "material-ui/svg-icons/action/find-in-page";
import DropDownMenu from "material-ui/DropDownMenu";
import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import {Toolbar, ToolbarGroup, ToolbarTitle} from "material-ui/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import localStorage from "utils/local_storage";
import {getFilteredTopics} from "../../topic_list/components/topic_list";

import ToolbarDropdown from "common_components/toolbar/dropdown";
import ToolbarDropdownItem from "common_components/toolbar/dropdown/item";

const baseTopicAmount = 20;
const extraTopicAmount = 20;

const styles = {
  root: {
    flexGrow: 1,
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  inner: {
    flexGrow: 1,
    overflow: "auto",
    padding: "1em 2em",
  },
  table: {
    width: "100%",
  },
};

class TopicAnalysisMatrix extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    const filterText = localStorage.getItem("topicFilterText") || "";
    const contractTypeFilteredItemsIds =
      JSON.parse(
        localStorage.getItem(`filteredContractTypeIds_${props.organisationId}`),
      ) || [];

    const filteredTopics = getFilteredTopics(
      filterText,
      contractTypeFilteredItemsIds,
      props,
    );
    this.state = {
      filter: filterText,
      filteredTopics,
      currentTopicsCount: baseTopicAmount,
      showLoadMoreButton: filteredTopics.length > baseTopicAmount,
      sensorVisibility: filteredTopics.length > baseTopicAmount,
      classifierVisibilityMenuOpen: false,
      classifierVisibilityMenuAnchorEl: null,
      contractTypeFilteredItemsIds,
      fetchTopicsRequestPending: false,
      prevContractTypeFilteredItemsIds: contractTypeFilteredItemsIds,
    };
  }

  componentDidMount() {
    this.filterInputRef.focus();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filter !== this.state.filter && this.state.filter === "") {
      this.setState({
        showLoadMoreButton: true,
        currentTopicsCount: extraTopicAmount,
      });
    }
    if (!_.isEqual(prevProps.topics, this.props.topics)) {
      this.updateFilter(this.state.filter, this.props.topics);
    }
  }

  render() {
    const {
      showLoadMoreButton,
      contractTypeFilteredItemsIds,
      fetchTopicsRequestPending,
    } = this.state;
    let filteredTopics = this.state.filteredTopics || this.props.topics;
    if (contractTypeFilteredItemsIds.length > 0) {
      filteredTopics = this.filterTopicsByContractType(
        filteredTopics,
        contractTypeFilteredItemsIds,
      );
    }

    const listItems = this.renderTopicListItems(filteredTopics);
    const filteredClassifiers = this.props.classifiers.filter(
      classifier => this.props.classifierVisibility[classifier.name],
    );
    const firstClassifier = filteredClassifiers[0] || this.props.classifiers[0];
    return (
      <div style={styles.root}>
        <Toolbar className="app-toolbar">
          <ToolbarGroup key={0}>
            <ToolbarTitle text="Topics" />
            <FlatButton
              className="toggle-view"
              label={"Topic List"}
              onClick={this.props.toggleView}
            />
            <FlatButton
              label={this.props.showRecent ? "Hide Recent" : "Show Recent"}
              onClick={this.props.toggleRecent}
            />
            <DropDownMenu
              value={this.props.sortValue}
              onChange={this.props.sortValueChanged}
              style={{height: "unset"}}
            >
              <MenuItem value="id" primaryText="Id" />
              <MenuItem value="name" primaryText="Name" />
              <MenuItem value="category" primaryText="Category & Name" />
              <MenuItem value="uses" primaryText="Uses" />
              <MenuItem
                value="unconfirmed_uses"
                primaryText="Unconfirmed Uses"
              />
              <MenuItem value="issue_count" primaryText="# Issues" />
              <MenuItem value="f1" primaryText={`${firstClassifier.name} F1`} />
              <MenuItem
                value="weight"
                primaryText={`${firstClassifier.name} Weighted Error`}
              />
            </DropDownMenu>
            {this.renderClassifierVisibilityMenuOpener()}
            <ToolbarDropdown
              leftIcon={<FindInPageIcon />}
              labelText="Contract Type"
              values={this.state.contractTypeFilteredItemsIds.map(id =>
                id.toString(),
              )}
              showSelectAndUnselectAllItems={true}
              showSelectedItemsList={true}
              showFilterTextField={true}
              itemHeaderStyle={{
                paddingLeft: 15,
                paddingRight: 15,
              }}
              handleChange={values =>
                this.onContractTypeFilterConfirm(
                  values.map(value => parseInt(value, 10)),
                )
              }
              disabled={fetchTopicsRequestPending}
              handleClose={() => this.onContractTypeFilterClose()}
            >
              {_.map(_.sortBy(this.props.contractTypesById, "name"), item => (
                <ToolbarDropdownItem
                  key={item.id}
                  value={item.id.toString()}
                  label={item.name}
                  withCheckbox={true}
                  labelStyle={{height: 22}}
                />
              ))}
            </ToolbarDropdown>
            <RefreshIcon
              style={{cursor: "pointer"}}
              onClick={this.onRefreshTopics}
            />
          </ToolbarGroup>
          <ToolbarGroup key={1}>
            <TextField
              className="filter-input"
              ref={this.createFilterInputRef}
              value={this.state.filter}
              style={{
                zoom: "0.75",
              }}
              floatingLabelText={this.getFilterLabelText(
                (this.state.filteredTopics || this.props.topics).length,
              )}
              onChange={this.onUpdateFilterChange}
            />
          </ToolbarGroup>
        </Toolbar>
        <div style={styles.inner}>
          <table style={styles.table}>
            <thead>
              <tr>
                {[
                  <th key="id" style={{paddingRight: "1.5em"}}>
                    Id
                  </th>,
                  <th key="star_hot" style={{width: "2em"}} />,
                  <th key="master_id" style={{paddingRight: "1.5em"}}>
                    Master Id
                  </th>,
                  <th key="name">Topic</th>,
                  <th key="category">Category</th>,
                  <th key="tags">Tags</th>,
                  <th key="contract-types">Contract Types</th>,
                  <th key="issue-count"># Issues</th>,
                  <th key="uses" style={{width: "4em"}}>
                    Uses
                  </th>,
                  <th key="unconfirmed_uses" style={{width: "4em"}}>
                    Unconfirmed Uses
                  </th>,
                  ...filteredClassifiers.map(classifier => (
                    <th
                      key={`classifier-${classifier.id}`}
                      colSpan={this.props.showRecent ? 9 : 8}
                      style={{backgroundColor: "#eef"}}
                    >
                      {classifier.name}
                      {classifier.id === 1 && (
                        <RefreshIcon
                          onClick={this.props.rerunRegexClassifier}
                          style={{
                            cursor: "pointer",
                            height: "18px",
                            width: "18px",
                            margin: "0 0.5rem",
                            position: "relative",
                            top: "4px",
                          }}
                        />
                      )}
                    </th>
                  )),
                ]}
              </tr>

              <tr>
                {[
                  <th key="spacer" colSpan={9} />,
                  ...[].concat(
                    ...filteredClassifiers.map(classifier => [
                      this.props.showRecent && (
                        <th
                          style={{
                            textAlign: "center",
                          }}
                          key={`${classifier.id}-current`}
                        >
                          Current Classifier
                        </th>
                      ),
                      <th
                        style={{
                          textAlign: "center",
                        }}
                        key={`${classifier.id}-locked`}
                      >
                        Deployed
                      </th>,
                      <th
                        style={{
                          textAlign: "center",
                        }}
                        key={`${classifier.id}-f1`}
                      >
                        F1
                      </th>,
                      <th
                        style={{
                          textAlign: "center",
                        }}
                        key={`${classifier.id}-tp`}
                      >
                        TP
                      </th>,
                      <th
                        style={{
                          textAlign: "center",
                        }}
                        key={`${classifier.id}-fn`}
                      >
                        FN
                      </th>,
                      <th
                        style={{
                          textAlign: "center",
                        }}
                        key={`${classifier.id}-fp`}
                      >
                        FP
                      </th>,
                      <th
                        style={{
                          textAlign: "center",
                        }}
                        key={`${classifier.id}-tn`}
                      >
                        TN
                      </th>,
                      <th
                        style={{
                          textAlign: "center",
                        }}
                        key={`${classifier.id}-we`}
                      >
                        Weighted Error
                      </th>,
                      <th
                        style={{
                          textAlign: "center",
                        }}
                        key={`${classifier.id}-rr`}
                      >
                        Rerun
                      </th>,
                    ]),
                  ),
                ]}
              </tr>
            </thead>
            {listItems}
          </table>
          {showLoadMoreButton && (
            <div style={{display: "flex", justifyContent: "center"}}>
              <RaisedButton
                className="load-more"
                label={"Load more topics"}
                style={{margin: "20px 10px"}}
                onClick={this.loadMoreItems(extraTopicAmount)}
                primary={true}
              />
              <RaisedButton
                className="load-all"
                label={"Load all topics"}
                style={{margin: "20px 10px"}}
                onClick={this.loadMoreItems(-1)}
                primary={true}
              />
            </div>
          )}
          <VisibilitySensor
            className="visibility-sensor"
            active={this.state.sensorVisibility}
            delayedCall={true}
            onChange={this.changeVisibility}
            partialVisibility={"bottom"}
            scrollCheck={true}
            scrollDelay={100}
            intervalDelay={100}
          />
        </div>
        {fetchTopicsRequestPending && (
          <div
            style={{
              width: "95%",
              height: "100%",
              position: "absolute",
              right: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(255,255,255, 0.7)",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }

  renderClassifierVisibilityMenuOpener = () => (
    <div>
      <RaisedButton
        onClick={this.onClassifierVisibilityMenuOpen}
        label="Classifier Visibility"
      />
      <Popover
        open={this.state.classifierVisibilityMenuOpen}
        anchorEl={this.state.classifierVisibilityMenuAnchorEl}
        anchorOrigin={{horizontal: "middle", vertical: "bottom"}}
        targetOrigin={{horizontal: "left", vertical: "top"}}
        onRequestClose={this.onClassifierVisibilityMenuClose}
      >
        <Menu>
          {this.props.classifiers.map(classifier => (
            <MenuItem
              key={`${classifier.name}-menu-item`}
              onClick={this.onClassifierVisibleClick(classifier.name)}
              primaryText={classifier.name}
              leftIcon={
                this.props.classifierVisibility[classifier.name] ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxBlankIcon />
                )
              }
            />
          ))}
        </Menu>
      </Popover>
    </div>
  );

  /* eslint-disable no-invalid-this */
  changeVisibility = isVisible => {
    if (isVisible) {
      this.loadMoreItems(extraTopicAmount)();
    }
    return false;
  };

  loadMoreItems = amount => () => {
    if (
      this.props.topics.length <
      this.state.currentTopicsCount + extraTopicAmount
    ) {
      this.setState({
        currentTopicsCount: this.props.topics.length,
        showLoadMoreButton: false,
        sensorVisibility: false,
      });
    } else {
      this.setState({
        currentTopicsCount:
          amount > 0
            ? this.state.currentTopicsCount + amount
            : this.props.topics.length,
        showLoadMoreButton: amount > 0,
        sensorVisibility: true,
      });
    }
  };
  /* eslint-enable no-invalid-this */

  renderTopicListItems(topics) {
    const {categoriesById, tagsById} = this.props;
    const {contractTypeFilteredItemsIds} = this.state;
    return _.chain(topics)
      .map(topic => ({
        ...topic,
        categoryName: categoriesById[topic.topiccategory_id].name,
        tagNames: topic.tags.map(tag => tagsById[tag.id].name),
        contract_types:
          !contractTypeFilteredItemsIds ||
          contractTypeFilteredItemsIds.length === 0
            ? topic.contract_types
            : topic.contract_types.filter(ct =>
                contractTypeFilteredItemsIds.includes(ct.contract_type_id),
              ),
      }))
      .filter((topic, index) => {
        if (index < this.state.currentTopicsCount) {
          return topic;
        }
        return false;
      })
      .map(topic => (
        <TopicListItem
          {...this.props}
          key={topic.id}
          topic={topic}
          organisationId={this.props.organisationId}
          classifierVisibility={this.props.classifierVisibility}
          onTopicUpdate={this.props.onTopicUpdate}
        />
      ))
      .value();
  }

  getFilterLabelText(matches) {
    if (matches === this.props.topics.length) {
      return `Filter - ${matches} topics in total`;
    }
    return `Filter - ${matches} match${matches !== 1 ? "es" : ""}`;
  }

  /* eslint-disable no-invalid-this */

  onUpdateFilterChange = event => this.updateFilter(event.target.value);

  updateFilter = (filterText, _topics) => {
    const topics = Array.isArray(_topics) ? _topics : this.props.topics;
    const filteredTopics = getFilteredTopics(
      filterText,
      this.state.contractTypeFilteredItemsIds,
      this.props,
      topics,
    );
    this.setState({
      filter: filterText,
      filteredTopics,
      showLoadMoreButton: filteredTopics.length > this.state.currentTopicsCount,
      sensorVisibility: filteredTopics.length > this.state.currentTopicsCount,
    });
    localStorage.setItem("topicFilterText", filterText);
  };

  onClassifierVisibilityMenuOpen = event => {
    event.preventDefault();
    this.setState({
      classifierVisibilityMenuOpen: true,
      classifierVisibilityMenuAnchorEl: event.currentTarget,
    });
  };

  onClassifierVisibilityMenuClose = () => {
    this.setState({
      classifierVisibilityMenuOpen: false,
    });
  };

  onClassifierVisibleClick = classifierName => () => {
    this.props.setClassifierVisibility({
      [classifierName]: !this.props.classifierVisibility[classifierName],
    });
  };

  onContractTypeFilterConfirm = contractTypeFilteredItemsIds => {
    this.setState(
      () => ({contractTypeFilteredItemsIds}),
      async () => {
        localStorage.setItem(
          `filteredContractTypeIds_${this.props.organisationId}`,
          JSON.stringify(contractTypeFilteredItemsIds),
        );
      },
    );
  };
  onContractTypeFilterClose = () => {
    if (
      !_.isEqual(
        this.state.contractTypeFilteredItemsIds,
        this.state.prevContractTypeFilteredItemsIds,
      )
    ) {
      this.onRefreshTopics();
    }
  };

  onRefreshTopics = () =>
    this.setState(
      () => ({
        fetchTopicsRequestPending: true,
      }),
      async () => {
        await this.props.fetchTopics({
          contractTypesUsagesFilter: this.state.contractTypeFilteredItemsIds,
        });
        this.setState(() => ({
          fetchTopicsRequestPending: false,
          prevContractTypeFilteredItemsIds: this.state
            .contractTypeFilteredItemsIds,
        }));
      },
    );

  filterTopicsByContractType = (topics, contractTypes) =>
    topics.filter(topic => {
      let showTopic = false;
      topic.contract_types.forEach(ct => {
        if (contractTypes.includes(ct.contract_type_id)) {
          showTopic = true;
        }
      });
      return showTopic;
    });

  createFilterInputRef = node => (this.filterInputRef = node);
}

TopicAnalysisMatrix.propTypes = {
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default TopicAnalysisMatrix;
export {TopicAnalysisMatrix};
