import React from "react";
import {FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";

import TypeSelector from "./type_selector";
import {WorkflowInputs} from "common/flowmaster/types/workflow";
import InputSelector from "common_components/flowmaster/input_selector";

import {
  SelectActionDefinitionArg,
  StringArgument,
} from "common/flowmaster/types/task_config";
import {
  DataLocation,
  InputDataLocationObject,
} from "common/flowmaster/types/data_location";

interface SelectValueEditorProps {
  argDefinition: SelectActionDefinitionArg;
  argValue: StringArgument;
  onUpdateItem: (value: StringArgument) => void;
  inputs: WorkflowInputs;
}

export const ModelSelectInputEditor: React.FC<SelectValueEditorProps> = ({
  argDefinition,
  argValue,
  onUpdateItem,
  inputs,
}) => {
  const stringArg = argValue?.value;
  const sourceType = stringArg?.source_type;

  const options = argDefinition.options;

  const handleChange = (event: React.ChangeEvent<{value: string}>) => {
    const selectedValue = event.target.value;
    const selectedOption = options?.find(
      option => option.key === selectedValue,
    );
    if (selectedOption) {
      onUpdateItem({
        ...argValue,
        value: {
          ...stringArg,
          value: selectedOption.key,
        } as DataLocation<string>,
      });
    }
  };

  return (
    <div style={{display: "flex", gap: "1em", width: "100%"}}>
      <TypeSelector
        type={sourceType ?? "none"}
        onSetType={type => {
          onUpdateItem({
            ...argValue,
            value: {...stringArg, source_type: type} as DataLocation<string>,
          });
        }}
      />
      {sourceType === "literal" ? (
        <FormControl variant="outlined" fullWidth>
          <InputLabel id={`${argDefinition.key}-selector-label`}>
            {argDefinition.label}
          </InputLabel>
          <Select
            labelId={`${argDefinition.key}-selector-label`}
            value={stringArg.value}
            onChange={handleChange}
            label={argDefinition.label}
            style={{flexGrow: 1}}
          >
            {options?.map(option => (
              <MenuItem value={option.key} key={option.key}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : sourceType === "input" ? (
        <InputSelector
          inputs={inputs}
          location={stringArg as InputDataLocationObject}
          onUpdateItem={(value: DataLocation<string>) =>
            onUpdateItem({...argValue, value})
          }
          type="llm_model"
        />
      ) : null}
    </div>
  );
};

const ActionArg = {
  Component: ModelSelectInputEditor,
  default: {value: {source_type: "none"}},
};

export default ActionArg;
