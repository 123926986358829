import React, {useEffect, useState} from "react";
import moment from "moment";
import _ from "lodash";
import * as jsDiff from "diff";
import copy from "copy-to-clipboard";

import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddCommentIcon from "@material-ui/icons/AddComment";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";

import {Table, Tr, Th, Td} from "common_components/table";
import CheckboxBasic from "common_components/inputs/checkbox_basic";
import CopyButton from "common_components/buttons/copy_button";

const styles = {
  circularProgress: {
    position: "absolute",
    top: "45%",
    left: "50%",
  },
  cellContainerStyle: {justifyContent: "normal"},
  list: {margin: 0},
};

function getInitialValues(reversedLogs) {
  const overrideValueDataValues = {};
  const initialValues = reversedLogs.reduce((keys, log) => {
    if (!log) {
      return keys;
    }
    const overrideValueData = _.get(log, "data.overrideValueData");
    if (
      overrideValueData &&
      overrideValueData.issuesetPath &&
      overrideValueData.fieldValue !== undefined
    ) {
      const value = {
        ..._.pick(log, ["id", "time"]),
        ...overrideValueData,
      };
      overrideValueDataValues[
        overrideValueData.issuesetPath
      ] = overrideValueDataValues[overrideValueData.issuesetPath]
        ? [...overrideValueDataValues[overrideValueData.issuesetPath], value]
        : [value];
      return keys;
    }
    return {...log, ...keys};
  }, {});
  return {initialValues, overrideValueDataValues};
}

function IssueDetailLogs(props) {
  const {logs, addLogComment, document} = props;
  const [showDiffs, updateShowDiffs] = React.useState(true);
  const onTriggerShowDiffs = () => updateShowDiffs(!showDiffs);

  useEffect(() => {
    props.fetchIssueLogs();
  }, []);
  const reversedLogs = []
    .concat(
      (logs || []).filter(log => {
        const {type} = log;
        if (
          document &&
          type === "ISSUES_FIND" &&
          log.data &&
          log.data.document_id === document.id
        ) {
          return true;
        }
        return type !== "ISSUES_FIND";
      }),
    )
    .reverse();
  const {initialValues, overrideValueDataValues} = getInitialValues(
    reversedLogs,
  );
  return (
    <div>
      {!logs ? (
        <CircularProgress style={styles.circularProgress} />
      ) : (
        <>
          <CheckboxBasic
            checked={showDiffs}
            onCheck={onTriggerShowDiffs}
            label="Show Diffs"
            labelOnTheLeft={true}
            containerStyles={{
              position: "absolute",
              top: 86,
              right: 36,
              zIndex: 9999,
            }}
          />
          <Table
            sortDirection="desc"
            sortColumnIndex={0}
            hasStickyHeader={true}
            withoutSidePadding={false}
          >
            <thead>
              <tr>
                <Th style={{width: "15%"}}>Date</Th>
                <Th style={{width: "20%"}}>User</Th>
                <Th style={{width: "10%"}}>Change Type</Th>
                <Th style={{width: "25%"}}>Data</Th>
                <Th style={{width: "10%"}}>Comments</Th>
              </tr>
            </thead>
            <tbody>
              {reversedLogs
                .map(log => {
                  const logData = {...log.data, time: log.time};
                  return (
                    <Tr key={log.id}>
                      <Td
                        containerStyle={styles.cellContainerStyle}
                        sortText={moment(log.time).format("x")}
                      >
                        {moment(log.time).format("DD/MM/YYYY - h:mm A")}
                      </Td>
                      <Td containerStyle={styles.cellContainerStyle}>
                        {log.username}
                      </Td>
                      <Td containerStyle={styles.cellContainerStyle}>
                        {log.type}
                      </Td>
                      <Td
                        containerStyle={{
                          ...styles.cellContainerStyle,
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {renderData(
                          logData,
                          initialValues,
                          overrideValueDataValues,
                          props.contractTypes,
                          log.type === "ISSUE_UPDATE" && showDiffs,
                        )}
                      </Td>
                      <Td>
                        <Comments
                          log={log}
                          addComment={text => addLogComment(log.id, text)}
                        />
                      </Td>
                    </Tr>
                  );
                })
                .reverse()}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
}

const ignoredKeys = {
  name: true,
  updated_document_id: true,
  id: true,
  last_edited: true,
  time: true,
};

function renderData(
  logData,
  initialValues,
  overrideValueDataValues,
  contractTypes = [],
  showDiffs,
) {
  if (logData.overrideValueData) {
    return renderOverrideValueData(
      logData,
      overrideValueDataValues,
      contractTypes,
      showDiffs,
    );
  }
  return Object.keys(logData)
    .filter(
      key =>
        !ignoredKeys[key] ||
        (key === "name" && logData.name !== initialValues.name),
    )
    .map(key => {
      function onCopyClick() {
        copy(JSON.stringify(logData[key]), {format: "text/plain"});
      }
      return (
        <div
          key={key}
          style={{display: "flex", justifyContent: "space-between"}}
        >
          <div style={{display: "flex"}}>
            <span
              style={{
                width: "116px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {key
                .split("_")
                .map(str => _.capitalize(str))
                .join(" ")}
            </span>
            <span>
              {renderDataValue(logData, key, initialValues, showDiffs)}
            </span>
          </div>
          <CopyButton hideCaption={true} onCopyClick={onCopyClick} />
        </div>
      );
    });
}

function renderDataValue(logData, key, initialValues, showDiffs) {
  const previousValue = initialValues[key];
  initialValues[key] = logData[key];
  if (
    showDiffs &&
    key !== "overrideValueData" &&
    !key.endsWith("_issuesets") &&
    previousValue !== undefined
  ) {
    return renderDiff(previousValue || "", logData[key] || "");
  }
  return JSON.stringify(logData[key], null, 4);
}

function renderDiff(prevValue, value) {
  const prevValueStr = getSortedJsonString(prevValue);
  const valueStr = getSortedJsonString(value);
  if (prevValueStr.length > 1000 || value.length > 1000) {
    return valueStr;
  }
  const diff = jsDiff.diffWords(prevValueStr, valueStr);
  return diff.map((item, index) => (
    <span
      key={index}
      style={{color: item.added ? "green" : item.removed ? "red" : "black"}}
    >
      {item.value}
    </span>
  ));
}

function renderOverrideValueData(
  logData,
  overrideValueDataValues,
  contractTypes,
  showDiffs,
) {
  const {issuesetPath, fieldName, fieldValue} = logData.overrideValueData;
  const {time} = logData;
  const issuesetName = getIssuesetName(issuesetPath, contractTypes);
  const itemType = ["overrideValueData", issuesetName, fieldName];
  let itemValue = [];
  if (issuesetPath) {
    const allIssuesetPathLogs = [
      ...overrideValueDataValues[issuesetPath],
    ].reverse();
    const prevLog = allIssuesetPathLogs.find(
      log => new Date(log.time) < new Date(time) && log.fieldName === fieldName,
    );
    if (
      showDiffs &&
      prevLog &&
      fieldValue !== undefined &&
      fieldValue !== null
    ) {
      itemValue = renderDiff(prevLog.fieldValue, fieldValue);
    } else {
      itemValue.push(
        JSON.stringify(
          fieldValue === undefined ? "Value Not Available" : fieldValue,
        ),
      );
    }
  } else {
    itemValue.push(JSON.stringify(fieldValue || "Value Not Available"));
  }

  const type = itemType
    .filter(item => item)
    .map((item, itemIndex) => <div key={`${item}-${itemIndex}`}>{item}</div>);

  function onCopyClick() {
    copy(
      typeof fieldValue === "string" ? fieldValue : JSON.stringify(fieldValue),
      {format: "text/plain"},
    );
  }

  return (
    <div style={{display: "flex", justifyContent: "space-between"}}>
      <div style={{display: "flex"}}>
        <div style={{width: 256, wordBreak: "break-all", flexShrink: 0}}>
          {type}
        </div>
        <div>{itemValue}</div>
      </div>
      <CopyButton hideCaption={true} onCopyClick={onCopyClick} />
    </div>
  );
}

function getIssuesetName(issuesetPath, contractTypes = []) {
  let usedIssueset;
  contractTypes.forEach(ct => {
    if (!usedIssueset) {
      usedIssueset = (ct.issuesets || []).find(
        issueset =>
          `${issueset.master_id}.${
            issueset.is_duplicate_on_master
              ? issueset.remote_client_id
              : "master"
          }` === issuesetPath,
      );
    }
  });
  return usedIssueset
    ? `${usedIssueset.name} @ ${
        usedIssueset.is_duplicate_on_master
          ? usedIssueset.remote_client_name
          : "master"
      }`
    : "";
}

function getSortedJsonString(obj) {
  const keys = getKeys(obj).sort();
  return JSON.stringify(obj, keys, 2);
}

function getKeys(obj) {
  if (!obj || typeof obj !== "object") {
    return [];
  }
  const keys = Object.keys(obj);
  const extraKeys = _.flatten(
    _.map(obj, value => {
      if (Array.isArray(value)) {
        return _.uniqBy(_.flatten(value.map(item => getKeys(item))));
      }
      if (typeof value === "object") {
        return getKeys(value);
      }
      return [];
    }),
  );
  return _.uniqBy(keys.concat(extraKeys));
}

function Comments({log, addComment}) {
  const [editing, setEditing] = useState(false);
  const [commentText, setCommentText] = useState("");
  return (
    <div>
      {(log.comments || []).map(renderComment)}
      {editing && (
        <TextField
          multiline
          value={commentText}
          onChange={event => setCommentText(event.target.value)}
        />
      )}
      {editing && (
        <>
          <Button
            onClick={() => {
              addComment(commentText);
              setCommentText("");
              setEditing(false);
            }}
          >
            <AddCircleOutlineIcon />
          </Button>
          <Button
            onClick={() => {
              setCommentText("");
              setEditing(false);
            }}
          >
            <CancelIcon />
          </Button>
        </>
      )}
      {!editing && (
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Button onClick={() => setEditing(true)}>
            <AddCommentIcon />
          </Button>
        </div>
      )}
    </div>
  );
}

function renderComment(comment, index) {
  return (
    <div style={{marginBottom: "1em"}} key={index}>
      <div>{comment.text}</div>
      <div style={{textAlign: "right", fontStyle: "italic", color: "#aaa"}}>
        {comment.username} @{" "}
        <span style={{whiteSpace: "pre"}}>
          {new Date(comment.time).toLocaleString()}
        </span>
      </div>
    </div>
  );
}

export default IssueDetailLogs;
