import React, {useState} from "react";

import Loading from "common_components/loading_circular";

import Settings from "./settings";
import TableCombined from "./table_combined";
import TableSeparate from "./table_separate";

import {SearchResult as SearchResultObj} from "../../types";
import EmbeddingTopic from "modules/searcher/types/embedding_topic";

interface ResultsProps {
  searchResults: SearchResultObj[];
  embeddingTopics: EmbeddingTopic[];
  algorithmsUsed: string[] | null;
  queryRunning: boolean;
}

export default function Results({
  searchResults,
  embeddingTopics,
  algorithmsUsed,
  queryRunning,
}: ResultsProps) {
  const [tableMode, setTableMode] = useState("separate");
  if (!searchResults || !Array.isArray(searchResults) || !algorithmsUsed) {
    return null;
  }
  if (queryRunning) {
    return (
      <div style={{height: "50vh", display: "flex"}}>
        <Loading />
      </div>
    );
  }
  const Table = tableMode === "combined" ? TableCombined : TableSeparate;
  return (
    <div>
      <Settings tableMode={tableMode} setTableMode={setTableMode} />
      <Table
        searchResults={searchResults}
        algorithms={algorithmsUsed}
        embeddingTopics={embeddingTopics}
      />
    </div>
  );
}
