import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import Chip from "material-ui/Chip";
import {List, ListItem} from "material-ui/List";
import Checkbox from "material-ui/Checkbox";
import FlatButton from "material-ui/FlatButton";

const styles = {
  dialogContent: {
    position: "absolute",
    left: "50%",
    top: "30%",
    transform: "translate(-50%, -30%)",
    maxWidth: 500,
  },
  dialogBody: {
    padding: 24,
  },
  title: {
    display: "flex",
    alignItems: "center",
    height: 76,
    boxSizing: "border-box",
  },
  titleH3: {
    margin: "0px 10px 0 0",
    whiteSpace: "nowrap",
  },
  chipLabel: {
    whiteSpace: "initial",
    lineHeight: 1.2,
    paddingTop: 7,
    paddingBottom: 7,
  },
  listItemCheckbox: {
    top: 8,
    left: -3,
  },
  listItem: {
    padding: "12px 16px 12px 28px",
    fontSize: 14,
  },
};

class IssueSetsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      issueSets: this.props.issueSets,
      selectedIssueSets: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isShown !== this.props.isShown) {
      this.setState(this.getInitialState());
    }
  }

  render() {
    return (
      <Dialog
        title={this.renderTitle()}
        onDismiss={this.props.onHide}
        open={this.props.isShown}
        actions={this.getActions()}
        contentStyle={styles.dialogContent}
        bodyStyle={styles.dialogBody}
        autoScrollBodyContent={true}
      >
        {this.renderContent()}
      </Dialog>
    );
  }

  renderTitle() {
    const {contractType} = this.props;
    return (
      <div style={styles.title}>
        <h3 style={styles.titleH3}>Choose Issue Sets</h3>
        <Chip labelStyle={styles.chipLabel}>
          {contractType ? contractType.name : ""}
        </Chip>
      </div>
    );
  }

  renderContent() {
    return (
      <List>
        {!this.state.issueSets.length
          ? "No unused Master Issue Sets found"
          : this.state.issueSets.map(issueSet => (
              <ListItem
                key={issueSet.master_id}
                primaryText={issueSet.name}
                style={styles.listItem}
                leftCheckbox={
                  <Checkbox
                    checked={this.isIssueSetSelected(issueSet.master_id)}
                    style={styles.listItemCheckbox}
                    onCheck={event => {
                      switch (event.target.checked) {
                        case true:
                          return this.selectIssueSet(issueSet);
                        case false:
                          return this.removeIssueSet(issueSet.master_id);
                      }
                    }}
                  />
                }
              />
            ))}
      </List>
    );
  }

  getActions = () => {
    return [
      <FlatButton
        label="Cancel"
        key="issuesets-dialog-cancel-button"
        secondary={true}
        onClick={this.props.onHide}
      />,
      <FlatButton
        label="Apply"
        key="issuesets-dialog-apply-button"
        primary={true}
        disabled={!this.state.selectedIssueSets.length}
        onClick={this.apply}
      />,
    ];
  };

  isIssueSetSelected = issueSetMasterId => {
    for (let i = 0; i < this.state.selectedIssueSets.length; i++) {
      if (this.state.selectedIssueSets[i].master_id === issueSetMasterId) {
        return true;
      }
    }
    return false;
  };

  selectIssueSet = issueSet => {
    if (!this.isIssueSetSelected(issueSet.master_id)) {
      this.setState({
        selectedIssueSets: [...this.state.selectedIssueSets, ...[issueSet]],
      });
    }
  };

  removeIssueSet = issueSetMasterId => {
    const updated = [...this.state.selectedIssueSets];
    for (let i = 0; i < updated.length; i++) {
      if (updated[i].master_id === issueSetMasterId) {
        updated.splice(i, 1);
      }
    }
    this.setState({selectedIssueSets: updated});
  };

  apply = () => {
    this.props.onConfirm(
      this.props.contractType.master_id,
      this.state.selectedIssueSets.map(issueSet => issueSet.master_id),
    );
    this.props.onHide();
  };
}

IssueSetsDialog.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  contractType: PropTypes.shape({
    name: PropTypes.string.isRequired,
    master_id: PropTypes.string.isRequired,
  }),
  issueSets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      master_id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default IssueSetsDialog;
