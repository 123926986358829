import React, {useState} from "react";
import ClauseBankItem from "../clause_bank_item";
import {clauseBankStyle} from "../style";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";

const ClauseBankGroup = ({clauseBank, amount}) => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(prev => !prev);
  };

  return (
    <>
      <div style={clauseBankStyle.clauseBankTitle}>
        <Button
          variant="outlined"
          size="small"
          style={{border: "none"}}
          startIcon={
            checked ? (
              <RemoveIcon fontSize="inherit" />
            ) : (
              <AddIcon fontSize="inherit" />
            )
          }
          onClick={handleChange}
        >
          {clauseBank[0].issue_name.replace(/\[.+\]/, "")}
        </Button>
        <p style={clauseBankStyle.clauseBankBadge}>{amount}</p>
      </div>
      <Collapse in={checked}>
        <div style={clauseBankStyle.clauseBankItem}>
          {clauseBank.map(
            ({
              id,
              name,
              username,
              language,
              is_public,
              description,
              issueset_name,
              creation_date,
              predefined_comment,
            }) => (
              <ClauseBankItem
                key={id}
                id={id}
                name={name}
                username={username}
                language={language}
                isPublic={is_public}
                description={description}
                creationDate={creation_date}
                issuesetName={issueset_name}
                predefinedComment={predefined_comment}
              />
            ),
          )}
        </div>
      </Collapse>
    </>
  );
};

export default ClauseBankGroup;
