import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import {
  LlmRunFilters,
  LlmRunFilterKeys,
  llmRunNumberFilterKeys,
} from "common/flowmaster/types/llm_run";

interface SelectorsProps {
  values: LlmRunFilters;
  updateValue: (key: LlmRunFilterKeys, value: number) => void;
}
export default function Selectors({values, updateValue}: SelectorsProps) {
  return (
    <div style={{display: "flex", flexDirection: "row"}}>
      {llmRunNumberFilterKeys.map(paramName => (
        <Field
          key={paramName + values[paramName]}
          label={`${paramName} Id`}
          value={values[paramName]}
          updator={value => updateValue(paramName, value)}
        />
      ))}
    </div>
  );
}

function Field({label, value, updator}) {
  const [currentValue, setValue] = useState((value ?? "").toString());
  return (
    <span
      style={{
        margin: "0.25em 1em",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
      }}
    >
      <TextField
        style={{width: "6em", textTransform: "capitalize"}}
        label={label}
        value={currentValue}
        onChange={event => setValue(event.target.value)}
        onBlur={() => updator(currentValue)}
      />
      {value && (
        <IconButton size="small" onClick={() => updator("")}>
          <Clear />
        </IconButton>
      )}
    </span>
  );
}
