import basePath from "base_path";

import ActionTypes from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";

import rateLimit, {getStandardTimeout} from "utils/rate_limit";
import {createChangeTimeWrapper} from "utils/ensure_fresh_update";

function doSave(requestor, organisationId, topicId, data, changeTime) {
  const {topiccategory_name} = data;
  return requestor
    .patch(
      `${basePath}/organisation/${organisationId}/topic/${topicId}`,
      data,
      {withCredentials: true},
    )
    .then(response => ({
      ...(data.topiccategory_name ? {topiccategory_name} : {}),
      ...createChangeTimeWrapper(changeTime),
      ...response.data,
    }));
}

const save = rateLimit(doSave, getStandardTimeout());

export default function updateTopic(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.TOPIC_UPDATE,
    (organisationId, topicId, data, changeTime) => {
      if (!data.last_edited) {
        throw new Error("data must contain a last_edited key");
      }
      return save(requestor, organisationId, topicId, data, changeTime);
    },
    (organisationId, topicId, data, changeTime) => ({
      ...createChangeTimeWrapper(changeTime),
      ...data,
    }),
  );

  return action;
}
