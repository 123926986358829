import _ from "underscore";
import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import requestor from "requestor";

import setTitle from "utils/set_title";
import Permissioner from "utils/permissioner";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import templateModuleCreateAction from "modules/template_modules/actions/module_create";
import updateTemplateModule from "modules/template_modules/actions/module_update";
import moduleTopicsFetch from "modules/template_modules/actions/module_topics_fetch";
import unmemoizedById from "common/utils/by_id";
import Modules from "../components/modules";

const byId = _.memoize(unmemoizedById, (...args) => JSON.stringify(args));

class ModulesContainer extends Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return (
      this.permissioner.isAdmin() ||
      this.permissioner.hasPermission("get-template-modules")
    );
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.templateModules,
      this.props.topicsById,
      this.props.user,
    ]);
  }

  renderComponent() {
    setTitle("Regex Modules");
    return (
      <Modules
        {...this.props}
        createModule={this.createModule}
        updateModule={this.updateModule}
        getModuleTopics={this.getModuleTopics}
      />
    );
  }

  createModule = data => {
    const {organisationId} = this.props.params;
    this.props.create({
      key: data.key,
      value: [data.value],
      organisationId,
    });
  };

  updateModule = data => {
    const {organisationId} = this.props.params;
    this.props.update({
      key: data.key,
      value: [data.value],
      organisationId,
      id: data.id,
    });
  };

  getModuleTopics = moduleId => {
    return this.props.moduleTopicsFetch(
      this.props.params.organisationId,
      moduleId,
    );
  };
}

const mapStateToProps = state => {
  const topicsById =
    state.topics && state.topics !== UNINITIALISED
      ? byId(state.topics)
      : UNINITIALISED;
  return {
    templateModules: state.templateModules,
    topics: state.topics,
    topicsById,
    user: state.user,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      create: templateModuleCreateAction(requestor),
      update: updateTemplateModule(requestor),
      moduleTopicsFetch: moduleTopicsFetch(requestor),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModulesContainer);
