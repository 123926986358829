import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function updateOrganisation(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.ORGANISATION_USER_UNLOCK,
    (organisationId, userId, lastEdited) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/user/${userId}/unlock`,
          {last_edited: lastEdited},
          {withCredentials: true},
        )
        .then(response => ({
          ...response.data,
          user_id: userId,
          organisation_id: organisationId,
        }));
    },
  );
  return action;
}
