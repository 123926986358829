import basePath from "base_path";
import ActionTypes from "../constants/action_types";
import {buildQuery} from "utils/uri";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function processTopicParameters(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPICPARAMETERS_PROCESS,
    (organisationId, topicId, params) => {
      return requestor
        .patch(
          `${basePath}/organisation/${organisationId}/topic/${topicId}/parameters${buildQuery(
            params,
          )}`,
          {},
          {withCredentials: true},
        )
        .then(response => response.data);
    },
  );
}
