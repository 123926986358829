import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import {DragSource as dragSource, DropTarget as dropTarget} from "react-dnd";

import statusOptions from "modules/tasks/constants/status_options";

import MoreIcon from "material-ui/svg-icons/navigation/more-vert";

import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";

class TaskListItemComponent extends React.Component {
  render() {
    const {
      task,
      onTaskSelected,
      onTaskDeleted,
      isHighlighted,
      isDragging,
      connectDragSource,
      connectDropTarget,
    } = this.props;

    const style = {
      display: "flex",
      justifyContent: "space-between",
      cursor: "grab; cursor: -webkit-grab; cursor: -moz-grab",
      padding: "0 1em",
    };
    const draggingStyle = {
      opacity: 0.5,
    };
    const highlightedStyle = {
      backgroundColor: "#b2dfdb",
    };
    return connectDragSource(
      connectDropTarget(
        <div>
          <div
            className="container"
            style={{
              ...style,
              ...(isDragging ? draggingStyle : {}),
              ...(isHighlighted ? highlightedStyle : {}),
            }}
          >
            <span
              className="main-text"
              onClick={onTaskSelected}
              style={{
                flexGrow: 1,
                height: "3em",
                lineHeight: "3em",
                verticalAlign: "middle",
              }}
            >
              <span className="name">{task.name}</span>
              <span
                className="clause-reference"
                style={{
                  fontSize: "0.7em",
                  marginLeft: "0.5em",
                  fontStyle: "italic",
                }}
              >
                {task.custom_values && task.custom_values.clause_reference}
              </span>
            </span>
            <span
              style={{
                verticalAlign: "middle",
              }}
            >
              <span
                className="due-date"
                onClick={onTaskSelected}
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  fontSize: "0.7em",
                  paddingBottom: "1em",
                }}
              >
                {task.due_date && task.due_date.toLocaleDateString()}
              </span>
              <span
                className="status"
                style={{
                  backgroundColor: "#eee",
                  borderRadius: "1em",
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginBottom: "1em",
                  marginLeft: "0.5em",
                  marginRight: "0.5em",
                  padding: "0.25em 0.5em",
                  fontSize: "0.8em",
                }}
              >
                {statusOptions.find(option => option.id === task.status).name}
              </span>
              <IconMenu
                className="menu"
                iconButtonElement={
                  <IconButton>
                    <MoreIcon />
                  </IconButton>
                }
              >
                <MenuItem
                  index={1}
                  className="delete-button"
                  onClick={onTaskDeleted}
                >
                  Delete
                </MenuItem>
              </IconMenu>
            </span>
            <br style={{clear: "both"}} />
          </div>
        </div>,
      ),
    );
  }
}

TaskListItemComponent.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    custom_values: PropTypes.shape({
      clause_reference: PropTypes.string,
    }),
  }).isRequired,
  isDragging: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  onTaskSelected: PropTypes.func.isRequired,
  onTaskDeleted: PropTypes.func.isRequired,
  onTasksReordered: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
};

export const taskSource = {
  beginDrag(props) {
    return {order: props.task.order};
  },
};

export const taskTarget = {
  drop(props, monitor) {
    const {order: sourceOrder} = monitor.getItem();
    const targetOrder = props.task.order;
    if (sourceOrder !== targetOrder) {
      props.onTasksReordered(sourceOrder, targetOrder);
    }
  },
};

export default _.compose(
  dropTarget("TASK_LIST_ITEM", taskTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  })),
  dragSource("TASK_LIST_ITEM", taskSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging() || false,
  })),
)(TaskListItemComponent);

export const TaskListItem = TaskListItemComponent;
