import basePath from "base_path";
import ActionTypes from "../../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function addRole(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.ROLE_ADD,
    (organisationId, newRoleData) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/role/new`,
          newRoleData,
          {withCredentials: true},
        )
        .then(response => ({
          ...response.data,
          ...newRoleData,
        }))
        .catch(err => err.data);
    },
  );
  return action;
}
