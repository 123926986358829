import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {replace} from "react-router-redux";

import loggedInRouteTransition from "modules/user/transitions/logged_in_route";

import UNINITIALISED from "utils/uninitialised";
import Permissioner from "utils/permissioner";
import logger from "common/utils/logger";

export class LoggedIn extends React.Component {
  constructor(props, context) {
    super(props);
    this.context = context;
  }

  render() {
    if (this.isBadOrganisation(this.props.organisation)) {
      return [
        ReactDOM.createPortal(
          this.renderBadOrganisationWarning(this.props.organisation),
          document.querySelector(".app-top-alert"),
        ),
        ...this.props.children,
      ];
    }
    return this.props.children;
  }

  isBadOrganisation(organisation) {
    if (!organisation || organisation === UNINITIALISED) {
      return false;
    }
    return (
      this.isNonClientOrganisation(organisation) ||
      this.isNonCentralAdminOrganisation(organisation)
    );
  }

  renderBadOrganisationWarning(organisation) {
    return (
      <div
        style={{
          height: "1.5em",
          backgroundColor: "#f00",
          color: "#fff",
          fontWeight: "bold",
          textAlign: "center",
          lineHeight: "1.5em",
        }}
      >
        {this.renderNonClientOrganisationWarningText(organisation)}
      </div>
    );
  }

  renderNonClientOrganisationWarningText(organisation) {
    if (this.isNonClientOrganisation(organisation)) {
      return (
        <span>
          Warning: You are not on the live client website. You should be{" "}
          <a
            href={`https://app.lexicallabs.com/organisation/${
              organisation?.client_db_id
            }`}
          >
            here
          </a>.
        </span>
      );
    }
    if (this.isNonCentralAdminOrganisation(organisation)) {
      return (
        <span>
          Warning: You are not on the admin website. You should be{" "}
          <a
            href={`https://internal.lexicallabs.com/organisation/${
              organisation?.id
            }`}
          >
            here
          </a>.
        </span>
      );
    }
  }

  isNonClientOrganisation(organisation) {
    return organisation?.client_db_id;
  }

  isNonCentralAdminOrganisation(organisation) {
    const isClientSite = window.location.hostname === "app.lexicallabs.com";
    return isClientSite && organisation.is_central_admin;
  }

  componentDidMount() {
    this.setupTransitions();
    this.setLogLevel();

    this.setUseAnalytics(this.props.user);
  }

  componentDidUpdate(prevProps) {
    this.setLogLevel();
    const {user} = this.props;
    this.setUseAnalytics(user, prevProps.user);
    if (!this.appcueUserIdentified && user.id && user.use_analytics) {
      window.Appcues && window.Appcues.identify(this.props.user.id);
      this.appcueUserIdentified = true;
    }
  }

  setUseAnalytics(user, prevUser) {
    if (
      window.localStorage &&
      user.use_analytics !== undefined &&
      (!prevUser || user.use_analytics !== prevUser.use_analytics)
    ) {
      window.localStorage.ll_use_analytics = user.use_analytics.toString();
    }
  }

  setLogLevel() {
    if (this.props.user && this.props.user.is_admin) {
      logger.level = 8;
    } else {
      logger.level = 1;
    }
  }

  setupTransitions() {
    this.setupProjectRouteTransition();
  }

  setupProjectRouteTransition() {
    loggedInRouteTransition(this.context.store).subscribe(props => {
      const url = props.router.locationBeforeTransitions.pathname;
      const nextUrl = this.hasGetProjectsPermission(props)
        ? `${url}${url.endsWith("/") ? "" : "/"}project/list`
        : url;
      this.props.dispatch(replace(nextUrl));
    });
  }

  hasGetProjectsPermission(props) {
    return new Permissioner(props.user).hasPermission("get-projects");
  }
}

function select(state) {
  return {
    user: state.user,
    organisation: state.organisation,
    projects: state.projects,
  };
}

export default connect(select)(LoggedIn);

LoggedIn.contextTypes = {
  store: PropTypes.object,
};

export const Component = LoggedIn;
