import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function permissionGroupPermissionAdd(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PERMISSION_GROUP_PERMISSION_ADD,
    (permissionGroupId, permissionId) =>
      requestor
        .post(
          `${basePath}/permission_group/${permissionGroupId}` +
            `/permission/${permissionId}`,
          {},
          {withCredentials: true},
        )
        .then(() => ({
          permissionGroupId,
          permissionId,
        }))
        .catch(err => err.data),
  );
}
