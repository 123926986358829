import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Permissioner from "utils/permissioner";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import Logs from "../components/logs_table";

import searchAction from "modules/search/actions/search";

class LogsContainer extends React.Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return (
      this.permissioner.isAdmin() || this.permissioner.hasPermission("get-logs")
    );
  }

  shouldRenderContainer() {
    return isInitialised([this.props.logs, this.props.user]);
  }

  renderComponent() {
    setTitle("Logs");
    return (
      <Logs
        logs={this.props.results || this.props.logs}
        processing={this.props.processing}
        search={this.search}
      />
    );
  }

  search = async query => {
    await this.props.search(this.props.params.organisationId, "logs", query);
  };
}

function mapStateToProps(state) {
  return {
    logs: state.logs,
    user: state.user,
    results:
      state.search.results === UNINITIALISED ? null : state.search.results,
    processing: state.search.processing,
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      search: searchAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LogsContainer);
