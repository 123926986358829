import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function templateModulesFetch(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TEMPLATE_MODULES_FETCH,
    organisationId =>
      requestor
        .get(`${basePath}/organisation/${organisationId}/template_module`, {
          withCredentials: true,
        })
        .then(response => {
          return response.data;
        }),
  );
}
