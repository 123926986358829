import requestor from "requestor";
import BaseRoute from "utils/base_route";

import clientsFetchAction from "modules/organisation/actions/clients_fetch";
import clientsClearAction from "modules/organisation/actions/clients_clear";
import permissionGroupsFetchAction from "modules/permissions/actions/permission_groups_fetch";
import permissionGroupsClearAction from "modules/permissions/actions/permission_groups_clear";
import contractTypesForClientsFetchAction from "modules/documents/actions/contract_types_for_clients_fetch";
import contractTypesClearAction from "modules/documents/actions/contract_types_clear";

import ClientOrganisations from "./containers/client_organisations";

export default class ClientOrganisationsRoute extends BaseRoute {
  constructor(store) {
    super("clients", store);
  }

  getComponents(location, cb) {
    cb(null, ClientOrganisations);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchClients(state);
    this.fetchPermissionGroups();
    this.fetchContractTypesForClients(state);
  }

  fetchClients(state) {
    const {organisationId} = state.params;
    this.store.dispatch(clientsFetchAction(requestor)(organisationId));
  }

  fetchPermissionGroups() {
    this.store.dispatch(permissionGroupsFetchAction(requestor)());
  }

  fetchContractTypesForClients(state) {
    const {organisationId} = state.params;
    this.store.dispatch(
      contractTypesForClientsFetchAction(requestor)(organisationId),
    );
  }

  clearActiveData() {
    this.store.dispatch(clientsClearAction());
    this.store.dispatch(permissionGroupsClearAction());
    this.store.dispatch(contractTypesClearAction());
  }
}
