import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function initDocument(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_DATA_FETCH,
    (organisationId, projectId, documentId, type) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}/project/${projectId}` +
            `/document/${documentId}/document_data?type=${type}`,
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
