import React from "react";
import styled from "styled-components";

import StringArgValue from "./string";

import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";
import {WorkflowInputValuesArgument} from "common/flowmaster/types/task_config";
import {Prompt} from "common/flowmaster/types/workflow";

interface Props {
  llmRun: EnhancedLlmRun;
  arg: WorkflowInputValuesArgument["value"];
  prompts: Prompt[];
}

const Table = styled.table`
  width: 100%;
  table-layout: fixed;

  th:nth-child(1),
  td:nth-child(1) {
    width: 5em;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 4em;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 100%;
  }
`;

export default function WorkflowInputValues({arg, llmRun, prompts}: Props) {
  return (
    <div>
      <div>Workflow: {arg.workflowId}</div>
      <fieldset>
        <legend>Inputs</legend>
        <Table>
          <thead>
            <th>Input</th>
            <th>Value</th>
          </thead>
          <tbody>
            {Object.entries(arg.inputValues).map(item => {
              return (
                <tr key={item[0]}>
                  <td>{item[0]}</td>
                  <td>
                    <StringArgValue
                      arg={item[1]}
                      llmRun={llmRun}
                      prompts={prompts}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </fieldset>
    </div>
  );
}
