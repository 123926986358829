import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function initProject(requestor) {
  return createReduxPromiseAction(ActionTypes.TASKROLES_FETCH, organisationId =>
    requestor
      .get(`${basePath}/organisation/${organisationId}/taskrole`, {
        withCredentials: true,
      })
      .then(response => response.data),
  );
}
