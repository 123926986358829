import _ from "underscore";
import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

function matchIds(requestData, responseData) {
  return responseData.map(topic => {
    const result = {
      ...requestData[topic.id],
      ...topic,
    };
    return _.omit(result, "added_contract_types", "removed_contract_types");
  });
}

export default function updateTopic(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.TOPICS_UPDATE,
    (organisationId, data) => {
      return requestor
        .patch(`${basePath}/organisation/${organisationId}/topic`, data, {
          withCredentials: true,
        })
        .then(response => matchIds(data, response.data));
    },
  );

  return action;
}
