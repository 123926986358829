import React, {Component} from "react";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";

const styles = {
  formWrap: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: 100,
  },
  keyTextField: {
    width: "30%",
    marginLeft: 20,
  },
  valueTextField: {
    width: "55%",
    marginLeft: 20,
  },
  buttons: {
    minWidth: 215,
    marginLeft: 20,
    position: "relative",
  },
  saveButton: {
    position: "absolute",
    top: 35,
    left: 10,
  },
  cancelButton: {
    position: "absolute",
    top: 35,
    right: 10,
  },
  errorText: {
    position: "absolute",
    top: 75,
    left: 10,
    fontSize: "12px",
    color: "red",
  },
};

class ModulesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: props.module ? props.module.key : "",
      value: props.module ? props.module.value[0] : "",
      error: "",
    };
  }

  onTextFieldChange = (e, name) => {
    this.setState({[name]: e.target.value});
  };

  onSave = () => {
    const error = this.validateForm();
    if (error) {
      return this.setState(() => ({error}));
    }
    const {key, value} = this.state;
    return this.setState(
      () => ({error: ""}),
      () => this.props.onSave({key, value}),
    );
  };

  validateForm = () => {
    const {key} = this.state;
    if (key.length === 0) {
      return "Key record can't be empty";
    } else if (
      this.props.templateModules.find(
        tm => tm.key === key && tm.id !== this.props.currentModuleId,
      )
    ) {
      return "Key already exists";
    }
    return "";
  };

  render() {
    return (
      <div style={Object.assign({}, styles.formWrap, this.props.style)}>
        <TextField
          hintText="Add Template Key"
          floatingLabelText="Key"
          style={styles.keyTextField}
          value={this.state.key}
          onChange={e => this.onTextFieldChange(e, "key")}
        />

        <TextField
          hintText="Add Template Value"
          floatingLabelText="Value"
          style={styles.valueTextField}
          value={this.state.value}
          onChange={e => this.onTextFieldChange(e, "value")}
        />

        <div style={styles.buttons}>
          <RaisedButton style={styles.saveButton} onClick={this.onSave}>
            Save
          </RaisedButton>

          <RaisedButton
            style={styles.cancelButton}
            onClick={this.props.onCancel}
          >
            Cancel
          </RaisedButton>
          {this.state.error && (
            <div style={styles.errorText}>{this.state.error}</div>
          )}
        </div>
      </div>
    );
  }
}

export default ModulesForm;
