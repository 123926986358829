import React, {useEffect, useState} from "react";
import styled from "styled-components";
import _ from "underscore";
import DatePicker from "react-datepicker";
import {Link} from "react-router";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import FilterIcon from "@material-ui/icons/FilterList";
import LinkIcon from "@material-ui/icons/Link";

import NumberTextField from "./numeric_text_field";
import Selectors from "./selectors";
import Summary from "./summary";
import MultiSelectFilter from "./select_filter";

import calculateDuration from "../../utils/calculate_duration";
import renderDuration from "../../utils/render_duration";
import calculateCounts from "../utils/calculate_counts";
import {LlmRunQueryParams} from "../utils/get_llm_run_query_params";
import getStatus from "routes/llm_run/utils/get_status";

import {
  LlmRunId,
  LlmRunInfo,
  LlmRunFilterKeys,
} from "common/flowmaster/types/llm_run";
import {OrganisationId} from "common/types/organisation";
import "react-datepicker/dist/react-datepicker.css";

const Container = styled.div`
  padding: 0 1em;
  width: calc(100% - 1em);
`;

const Table = styled.table`
  border-collapse: collapse;
  thead th {
    padding-bottom: 0.5em;
  }
  width: 100%;
  table-layout: fixed;
  td,
  th {
    width: 5em;
    &:nth-child(1) {
      width: 3em;
    }
    &:nth-child(6),
    &:nth-child(7) {
      width: 7em;
    }
  }
`;
const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #fcfcfc;
  }
  &:nth-child(odd) {
    background-color: #eef;
  }
  &:hover {
    background-color: #ffffdd;
  }
`;
const TableCell = styled.td`
  padding: 1em 0.5em;
  white-space: nowrap;
  border-top: 1px solid #aaa;
  text-align: center;
`;
const BigTableCell = styled(TableCell)`
  white-space: wrap;
  word-break: break-word;
  padding: 0 0.5em;
`;
const ListLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  pointer: cursor;
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
`;

type Props = {
  isLoading: boolean;
  organisationId: OrganisationId;
  llmRuns: LlmRunInfo[];
  values: LlmRunQueryParams;
  updateValue: (key: LlmRunFilterKeys, value: number) => void;
  goToRun: (id: LlmRunId) => void;
  maxRuns: number;
  setMaxRuns: (maxRuns: number) => void;
};

export default function llmRunListComponent({
  isLoading,
  organisationId,
  llmRuns,
  goToRun,
  values,
  updateValue,
  maxRuns,
  setMaxRuns,
}: Props) {
  const [showCostAnalysis, setShowCostAnalysis] = useState(false);
  const counts = calculateCounts(llmRuns);
  return (
    <div style={{display: "flex", width: "100%", flexDirection: "column"}}>
      {isLoading && (
        <Loading>
          <CircularProgress />
        </Loading>
      )}
      <Header
        {...{
          llmRuns,
          values: _.omit(values, ["reasons", "status"]),
          updateValue,
          showCostAnalysis,
          setShowCostAnalysis,
          maxRuns,
          setMaxRuns,
        }}
      />
      <LlmRunTable
        {...{
          organisationId,
          llmRuns,
          goToRun,
          updateValue,
          showCostAnalysis,
          counts,
          reasons: values.reasons,
          status: values.status,
        }}
      />
    </div>
  );
}

function Header({
  llmRuns,
  values,
  updateValue,
  showCostAnalysis,
  setShowCostAnalysis,
  maxRuns,
  setMaxRuns,
}) {
  const [dates, setDates] = useState({
    from: values.from ? new Date(values.from) : null,
    to: values.to ? new Date(values.to) : null,
  });

  useEffect(() => {
    setDates({
      from: values.from ? new Date(values.from) : null,
      to: values.to ? new Date(values.to) : null,
    });
  }, [values.from, values.to]);

  const handleDateChange = (field: "from" | "to", date: Date | null) => {
    if (field === "to" && date) {
      if (!dates.to) {
        date.setHours(23, 59, 59);
      } else {
        date.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
      }
    }
    setDates(prevDates => ({...prevDates, [field]: date}));
  };

  const handleDateBlur = (field: "from" | "to") => {
    const date = dates[field];
    if (date) {
      // Format the date in local time, but as ISO string without timezone info
      const formattedDate = formatDateToLocalISOString(date);
      updateValue(field, formattedDate);
    } else {
      updateValue(field, null);
    }
  };

  function formatDateToLocalISOString(date: Date): string {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16);
  }

  return (
    <div
      style={{
        backgroundColor: "#f6f6f6",
        padding: "0 1em",
        borderBottom: "1px solid #aaa",
        marginBottom: "1em",
      }}
    >
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <h1>LLM Runs</h1>

        <div style={{display: "flex", alignItems: "baseline"}}>
          <div
            style={{
              display: "flex",
              gap: "1em",
              zIndex: 10,
              marginRight: "2em",
            }}
          >
            <DatePicker
              selected={dates.from}
              onChange={date => handleDateChange("from", date)}
              onCalendarClose={() => handleDateBlur("from")}
              onBlur={() => handleDateBlur("from")}
              timeInputLabel="Time:"
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeInput
              isClearable
              placeholderText="Select start date"
              timeFormat="HH:mm"
              maxDate={dates.to}
            />
            <DatePicker
              selected={dates.to}
              onChange={date => handleDateChange("to", date)}
              onCalendarClose={() => handleDateBlur("to")}
              onBlur={() => handleDateBlur("to")}
              timeInputLabel="Time:"
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeInput
              isClearable
              placeholderText="Select end date"
              timeFormat="HH:mm"
              minDate={dates.from}
            />
          </div>
          <FormControlLabel
            control={
              <Switch
                onClick={() => setShowCostAnalysis(!showCostAnalysis)}
              ></Switch>
            }
            label="Show cost analysis"
          />
          <NumberTextField
            value={maxRuns}
            label="Max Results"
            step={100}
            onChange={value => setMaxRuns(value)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          paddingBottom: "1em",
        }}
      >
        <Summary llmRuns={llmRuns} />
        <Selectors values={values} updateValue={updateValue} />
      </div>
    </div>
  );
}

function LlmRunTable({
  organisationId,
  llmRuns,
  goToRun,
  updateValue,
  showCostAnalysis,
  counts,
  reasons,
  status,
}) {
  return (
    <Container>
      <Table>
        <thead collapse-columns>
          <tr
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#fff",
              zIndex: 1,
            }}
          >
            <th>Id</th>
            <th>Date</th>
            <th>Time</th>
            <th>Duration</th>
            <th>Active Duration</th>
            <th>
              <MultiSelectFilter
                id="reason-filter"
                title="Reason"
                value={reasons ?? []}
                menuItems={[
                  {
                    value: "classify_clausepart",
                    label: "Classify Clausepart",
                  },
                  {value: "document_upload", label: "Upload"},
                  {value: "remote_api", label: "Remote API"},
                  {value: "reprocess_issue", label: "Reprocess"},
                ]}
                setValue={value => updateValue("reasons", value)}
              />
            </th>
            <th>
              <MultiSelectFilter
                id="status-filter"
                title="Status"
                value={status ?? []}
                menuItems={[
                  {
                    value: "in_progress",
                    label: "In Progress",
                  },
                  {value: "complete", label: "Complete"},
                  {value: "failed", label: "Failed"},
                ]}
                setValue={value => updateValue("status", value)}
              />
            </th>
            <th>Retries</th>
            <th>Cost</th>
            {showCostAnalysis && (
              <>
                <th>Tokens</th>
                <th>Max Toks / Action</th>
                <th>Doc pages</th>
                <th>Doc words</th>
                <th>Doc chars</th>
              </>
            )}
            <th style={{width: "20%"}}>Workflow Name ({counts.workflow})</th>
            <th style={{width: "20%"}}>Proj Name ({counts.project})</th>
            <th style={{width: "20%"}}>Doc Name ({counts.document})</th>
            <th style={{width: "40%"}}>
              Extra info (
              <span style={{display: "inline-flex", gap: "0.5em"}}>
                {Boolean(counts.issue) && <span>Issues: {counts.issue}</span>}
                {Boolean(counts.clausepart) && (
                  <span>Clauseparts: {counts.clausepart}</span>
                )}
              </span>
              )
            </th>
          </tr>
        </thead>
        <tbody>
          {renderLlmRuns(
            organisationId,
            llmRuns,
            goToRun,
            updateValue,
            showCostAnalysis,
          )}
        </tbody>
      </Table>
    </Container>
  );
}

function renderLlmRuns(
  organisationId,
  llmRuns,
  goToRun,
  updateValue,
  showCostAnalysis,
) {
  return llmRuns.map(llmRun =>
    renderLlmRun(
      organisationId,
      llmRun,
      goToRun,
      updateValue,
      showCostAnalysis,
    ),
  );
}

const InfoSpan = styled.span`
  font-style: italic;
  color: #888;
  text-decoration: dotted underline #888;
`;

function formatTime(date) {
  return new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  }).format(date);
}

function renderLlmRun(
  organisationId,
  llmRun,
  goToRun,
  updateValue,
  showCostAnalysis,
) {
  const redirectToDetails = loc =>
    loc.pathname.replace("list", `${llmRun.id}/detail`);
  const workflowUrl = `/organisation/${organisationId}/workflow/${llmRun.workflow_id}/detail`;
  const projectUrl = `/organisation/${organisationId}/project/${llmRun.project_id}/document/list`;
  const documentUrl = `/organisation/${organisationId}/project/${llmRun.project_id}/document/${llmRun.document_id}/detail`;
  const issueUrl = `/organisation/${organisationId}/issue/${llmRun.issue_id}?document=${llmRun.document_id}&project=${llmRun.project_id}&open_issue_list=true`;

  const wordTokens = llmRun.doc_words * 1.3;
  const charTokens = llmRun.doc_chars / 4;
  const avgTokens = (wordTokens + charTokens) / 2;

  return (
    <TableRow>
      <TableCell style={{cursor: "pointer"}}>
        <ListLink to={redirectToDetails}>{llmRun.id}</ListLink>
      </TableCell>
      <TableCell style={{cursor: "pointer"}}>
        <ListLink to={redirectToDetails}>
          {new Date(llmRun.creation_date).toLocaleDateString()}
        </ListLink>
      </TableCell>
      <TableCell style={{cursor: "pointer"}}>
        <ListLink to={redirectToDetails}>
          {formatTime(new Date(llmRun.creation_date))}
        </ListLink>
      </TableCell>
      <TableCell style={{cursor: "pointer"}}>
        <ListLink to={redirectToDetails}>
          {renderDuration(
            calculateDuration(llmRun.creation_date, llmRun.last_run),
          )}{" "}
        </ListLink>
      </TableCell>
      <TableCell style={{cursor: "pointer"}}>
        <ListLink to={redirectToDetails}>
          {renderDuration(llmRun.active_duration)}
        </ListLink>
      </TableCell>
      <TableCell style={{cursor: "pointer"}}>
        <ListLink to={redirectToDetails}>
          {renderReason(llmRun.creation_reason)}
        </ListLink>
      </TableCell>
      <TableCell style={{cursor: "pointer"}}>
        <ListLink to={redirectToDetails}>{getStatus(llmRun)}</ListLink>
      </TableCell>
      <TableCell style={{cursor: "pointer", width: "2em"}}>
        <ListLink to={redirectToDetails}>{llmRun.retries}</ListLink>
      </TableCell>
      <TableCell style={{borderLeft: "1px solid #aaa"}}>
        ${(llmRun.cost ?? 0).toFixed(4)}
      </TableCell>
      {showCostAnalysis && (
        <>
          <TableCell>
            {llmRun.total_tokens ?? 0} (
            <InfoSpan title="% of doc tokens">
              {(((llmRun.total_tokens ?? 0) / avgTokens) * 100).toFixed(1)}%
            </InfoSpan>
            )
          </TableCell>
          <TableCell>
            {llmRun.max_tokens ?? 0} (
            <InfoSpan title="% of doc tokens">
              {(((llmRun.max_tokens ?? 0) / avgTokens) * 100).toFixed(1)}%
            </InfoSpan>
            )
          </TableCell>
          <TableCell>{llmRun.doc_pages}</TableCell>
          <TableCell>
            {(llmRun.doc_words ?? 0).toLocaleString()} (
            <InfoSpan title="est Tokens">
              {(Math.round(wordTokens) ?? 0).toLocaleString()} T
            </InfoSpan>
            )
          </TableCell>
          <TableCell>
            {(llmRun.doc_chars ?? 0).toLocaleString()} (
            <InfoSpan title="est Tokens">
              {(Math.round(charTokens) ?? 0).toLocaleString()} T
            </InfoSpan>
            )
          </TableCell>
        </>
      )}
      <BigTableCell style={{borderLeft: "1px solid #aaa"}}>
        <FilterableName
          name={llmRun.workflow_name}
          url={workflowUrl}
          filter={() => updateValue("workflow", llmRun.workflow_id)}
        />
      </BigTableCell>
      <BigTableCell>
        <FilterableName
          name={llmRun.project_name}
          url={projectUrl}
          filter={() => updateValue("project", llmRun.project_id)}
        />
      </BigTableCell>
      <BigTableCell>
        <FilterableName
          name={llmRun.document_name}
          url={documentUrl}
          filter={() => updateValue("document", llmRun.document_id)}
        />
      </BigTableCell>
      <BigTableCell>
        {llmRun.issue_name && (
          <FilterableName
            label="Issue"
            name={llmRun.issue_name}
            url={issueUrl}
            filter={() => updateValue("issue", llmRun.issue_id)}
          />
        )}
        {llmRun.clausepart_reference && (
          <FilterableName
            label="Clausepart"
            name={llmRun.clausepart_reference}
            url={documentUrl}
            filter={() => updateValue("clausepart", llmRun.clausepart_id)}
          />
        )}
      </BigTableCell>
    </TableRow>
  );
}

function renderReason(reason) {
  switch (reason) {
    case "reprocess_issue":
      return "Reprocess";
    case "document_upload":
      return "Upload";
  }
  return reason;
}

interface FilterableNameProps {
  label?: string;
  name: string;
  url: string;
  filter: () => void;
}

function FilterableName({label, name, url, filter}: FilterableNameProps) {
  return (
    <div>
      {label && (
        <div
          style={{
            textAlign: "left",
            fontStyle: "italic",
            fontSize: "0.75em",
            color: "#888",
          }}
        >
          {label}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{textAlign: "left"}}>{name}</div>
        <div>
          <IconButton
            size="small"
            onClick={() => filter()}
            style={{paddingTop: 0, paddingBottom: 0}}
          >
            <FilterIcon />
          </IconButton>
          <ListLink to={url}>
            <IconButton size="small" style={{paddingTop: 0, paddingBottom: 0}}>
              <LinkIcon />
            </IconButton>
          </ListLink>
        </div>
      </div>
    </div>
  );
}
