import React from "react";
import styled from "styled-components";
import {colors} from "../constants";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const RiskLevel = styled.div<{color: string}>`
  background-color: ${props => props.color};
  color: white;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Percentage = styled.span`
  flex: 1;
  text-align: center;
  max-width: 5em;
`;

const Label = styled.span`
  flex: 1;
  text-align: center;
  max-width: 5em;
`;

type LegendProps = {
  ragScoreThresholds: {red: number; amber: number};
};

const Legend = ({ragScoreThresholds}: LegendProps) => (
  <Container>
    <RiskLevel color={colors.red}>
      <Percentage>{`<${ragScoreThresholds.red * 100}%`}</Percentage>
      <Label>RED</Label>
    </RiskLevel>
    <RiskLevel color={colors.amber}>
      <Percentage>{`${ragScoreThresholds.red *
        100} - ${ragScoreThresholds.amber * 100}%`}</Percentage>
      <Label>AMBER</Label>
    </RiskLevel>
    <RiskLevel color={colors.green}>
      <Percentage>{ragScoreThresholds.amber * 100}% +</Percentage>
      <Label>GREEN</Label>
    </RiskLevel>
  </Container>
);
export default Legend;
