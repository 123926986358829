import React, {useEffect, useRef, useState} from "react";
import _ from "lodash";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/PlaylistAdd";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import usePrevious from "utils/hooks/use_previous";

const styles = {
  cardHeader: {
    height: "2.5rem",
    lineHeight: "2.4rem",
    paddingLeft: "2rem",
    background: "#fff",
    fontSize: "17px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "space-between",
  },
  addItemButton: {
    margin: "0.2rem",
    marginLeft: "1.5rem",
    marginTop: "1rem",
  },
  synonymContainer: {
    display: "flex",
    alignItems: "center",
    color: "#616161",
  },
  deleteIcon: {
    cursor: "pointer",
    marginLeft: 6,
  },
};

function Synonym(props) {
  const {synonym, definitionGroup} = props;

  const textInput = useRef(null);
  useEffect(() => {
    if (props.focusOnMount) {
      textInput.current.focus();
    }
  }, []);

  const [error, setError] = useState("");
  const clearError = () => setError("");

  function handleSynonymUpdate(e) {
    const newSynonym = e.target.value;
    if (newSynonym && newSynonym === synonym) {
      return;
    }
    if (!newSynonym) {
      return setError("Please provide synonym text");
    }

    const existingSynonymIndex = definitionGroup.synonyms.findIndex(
      dgSynonym =>
        dgSynonym.trim().toLowerCase() === newSynonym.trim().toLowerCase(),
    );

    if (existingSynonymIndex !== -1) {
      return setError(
        `Provided synonym already exists (index: ${existingSynonymIndex + 1})`,
      );
    }
    props.updateSynonym(newSynonym, props.index);
  }

  function handleSynonymRemove() {
    props.removeSynonym(props.index);
  }

  return (
    <div style={styles.synonymContainer}>
      <div
        style={{
          width: "1rem",
          textAlign: "right",
          marginRight: "0.5rem",
        }}
      >
        {`${props.index + 1}.`}
      </div>
      <TextField
        style={{width: "90%"}}
        inputRef={textInput}
        helperText={error}
        error={Boolean(error)}
        defaultValue={synonym}
        onBlur={handleSynonymUpdate}
        onFocus={clearError}
      />
      <DeleteIcon
        onClick={handleSynonymRemove}
        style={styles.deleteIcon}
        fontSize="small"
      />
    </div>
  );
}

function Synonyms(props) {
  const {synonyms, definitionGroup} = props;
  const [stateSynonyms, updateStateSynonyms] = useState([...synonyms]);

  const stateSynonymsPrev = usePrevious([...stateSynonyms]);

  useEffect(
    () => {
      if (
        stateSynonymsPrev &&
        stateSynonyms &&
        !_.isEqual(stateSynonyms, stateSynonymsPrev)
      ) {
        props.onSynonymsUpdate(stateSynonyms);
      }
    },
    [stateSynonyms],
  );

  const [isNewInputShown, updateIsNewInputShown] = useState(false);
  function showNewInput() {
    updateIsNewInputShown(true);
  }
  function hideNewInput() {
    if (isNewInputShown) {
      updateIsNewInputShown(false);
    }
  }

  function removeSynonym(index) {
    const newSynonyms = stateSynonyms.filter(
      (item, itemIndex) => itemIndex !== index,
    );
    updateStateSynonyms(newSynonyms);
  }

  function updateSynonym(newValue, newValueIndex) {
    const newSynonyms = stateSynonyms.map(
      (synonym, index) => (index === newValueIndex ? newValue : synonym),
    );
    updateStateSynonyms(newSynonyms);
  }

  function addSynonym(newValue) {
    const newSynonyms = [...stateSynonyms, newValue];
    updateStateSynonyms(newSynonyms);
    hideNewInput();
  }

  function updateUseLowercase() {
    props.toggleUseLowercase();
  }

  return (
    <Paper style={{width: "60%", borderRadius: 0}}>
      <div className="app-toolbar" style={styles.cardHeader}>
        <span style={{paddingRight: "1em"}}>Synonyms</span>
        <FormControlLabel
          control={
            <Checkbox
              checked={definitionGroup.use_lowercase}
              onChange={updateUseLowercase}
              name="checkedA"
            />
          }
          label="Use lowercase"
        />
      </div>
      <div style={{padding: "1rem 2rem"}}>
        {stateSynonyms.map((synonym, index) => (
          <Synonym
            key={`${synonym}-${index}`}
            index={index}
            synonym={synonym}
            definitionGroup={definitionGroup}
            removeSynonym={removeSynonym}
            updateSynonym={updateSynonym}
          />
        ))}
        {isNewInputShown ? (
          <Synonym
            index={stateSynonyms.length}
            synonym=""
            definitionGroup={definitionGroup}
            removeSynonym={hideNewInput}
            updateSynonym={addSynonym}
            focusOnMount={true}
          />
        ) : (
          <Button
            variant="contained"
            onClick={showNewInput}
            startIcon={<AddIcon />}
            style={styles.addItemButton}
          >
            Add Synonym
          </Button>
        )}
      </div>
    </Paper>
  );
}

export default Synonyms;
