import _ from "underscore";

export default function stripUiOrderId(issueRules) {
  if (Array.isArray(issueRules)) {
    return issueRules.map(issueRule => stripUiOrderId(issueRule));
  }
  if (Array.isArray(issueRules.issues)) {
    return {
      ...issueRules,
      issues: issueRules.issues.map(issue => stripUiOrderId(issue)),
    };
  }
  const issueWithoutId = _.omit(issueRules, "ui_order_id");
  if (
    issueRules.rules &&
    issueRules.rules.issues &&
    issueRules.rules.issues.length > 0
  ) {
    return {
      ...issueWithoutId,
      rules: {
        ...issueWithoutId.rules,
        issues: issueRules.rules.issues.map(stripUiOrderId),
      },
    };
  }
  return issueWithoutId;
}
