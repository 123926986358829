import basePath from "base_path";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes, {UsergroupActionTypes} from "../constants/action_types";
import {UsergroupUser} from "common/types/usergroup";

export type AddUserToUsergroupAction = {
  type: UsergroupActionTypes["USERGROUP_USER_ADD"]["SUCCESS"];
  payload: {usergroupId: number; user: UsergroupUser};
};

export default createReduxPromiseAction(
  ActionTypes.USERGROUP_USER_ADD,
  (
    organisationId: number,
    usergroupId: number,
    user: {id: number; name: string},
  ) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/usergroup/${usergroupId}/user`,
        {userId: user.id},
        {withCredentials: true},
      )
      .then(() => ({usergroupId, user})),
);
