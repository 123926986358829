import _ from "underscore";
import {cloneDeep} from "lodash";
import React, {useState, useEffect} from "react";

import * as colors from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ClearIcon from "@material-ui/icons/Clear";

import SettingsFieldset from "./util/settings_fieldset";
import InformationPopup from "../../../common_components/information_popup";

const styles = {
  clearIcon: {
    height: "18px",
    width: "18px",
    position: "absolute",
    top: "36px",
    right: "-20px",
    cursor: "pointer",
    color: "rgba(0, 0, 0, 0.54)",
  },
  infoTransform: {
    vertical: "top",
    horizontal: "right",
  },
  infoAnchor: {
    vertical: "top",
    horizontal: "left",
  },
  infoIcon: {
    position: "absolute",
    top: "32px",
    left: "-26px",
  },
  infoBlock: {
    minWidth: "320px",
    textAlign: "justify",
  },
};

const selectors = [
  {
    key: "match_alert_class",
    label: "Match Alert Class",
    items: [{name: "Yes", value: "yes"}, {name: "No", value: "no"}],
    helperText: (<div>When on, if a subissue is a note and is triggered (and no warning / alert subissue is triggered) then the parent becomes a note.<br/>
      Otherwise if its off, it stays its normal class.</div>),
  },
  {
    key: "set_state_and_display_text_from",
    label: "Set State & Display Text From",
    items: [
      {name: "Displayed Issues", value: "displayed_issues"},
      {name: "All Sub-issues", value: "all_sub_issues"},
    ],
    helperText: (<div>Displayed issues: Only issues that are visible subissues.<br/>
      All sub-issues: All issues regardless of whether they are hidden or not</div>),
  },
  {
    key: "display_text_alert",
    label: "Display Text (Alert)",
    items: [
      {name: "First Alert Issue", value: "first_alert_issue"},
      {name: "All Alert Issues", value: "all_alert_issues"},
      {name: "All Issues", value: "all_issues"},
      {name: "Set Manually", value: "set_manually"},
    ],
    helperText: (<div>First Alert Issue - Take text from first subissue in the list<br/>
      All Alert Issues - Take text from all issues that are triggered state<br/>
      All Issues - Take text from all issues regardless of their triggered state<br/>
      Set Manually - Ignore subissue text, take only from this issue's template</div>),
  },
  {
    key: "display_text_non_alert",
    label: "Display Text (Non-Alert)",
    items: [
      {name: "First Issue", value: "first_issue"},
      {name: "All Issues", value: "all_issues"},
      {name: "Set Manually", value: "set_manually"},
    ],
    helperText: (<div>First Alert Issue - Take text from first subissue in the list<br/>
      All Issues - Take text from all issues<br/>
      Set Manually - Ignore subissue text, take only from this issue's template</div>),
  },
  {
    key: "applicable_clauses",
    label: "Applicable Clauses",
    items: [
      {name: "Active Issues", value: "active_issues"},
      {name: "All Issues", value: "all_issues"},
      {name: "None", value: "none"},
    ],
    helperText: (<div>Active Issues - Show unique applicable clauses from all triggered sub-issues.<br/>
      All Issues - Show unique applicable clauses from all sub-issues<br/>
      None - Show no sub-issues</div>),
  },
  {
    key: "related_clauses",
    label: "Related Clauses",
    items: [
      {name: "Active Issues", value: "active_issues"},
      {name: "All Issues", value: "all_issues"},
      {name: "None", value: "none"},
    ],
    helperText: (<div>
      Active Issues - Show unique related clauses from all triggered sub-issues.<br/>
      All Issues - Show unique related clauses from all sub-issues<br/>
      None - Show no sub-issues</div>),
  },
];

function getSelectedItemColor(value, baseValue, isOverrideMode) {
  if (isOverrideMode) {
    return value !== baseValue ? colors.grey[800] : colors.grey[400];
  }
  return colors.grey[800];
}

function createSelectField(
  key,
  value,
  baseValue, // non-overridden value
  label,
  onChange,
  onClear,
  menuItems,
  parentStyles = {},
  isOverrideMode,
  renderOverridenIssuesets,
  helperText,
) {
  return (
    <div key={key}>
      <div style={{position: "relative"}}>
        {helperText && (
          <InformationPopup
            transformOrigin={styles.infoTransform}
            anchorOrigin={styles.infoAnchor}
            iconStyle={styles.infoIcon}
          >
            <div style={styles.infoBlock}>
              {helperText}
            </div>
          </InformationPopup>
        )}
        <FormControl
          style={{
            width: "18rem",
            borderRadius: "2px",
            ...parentStyles,
          }}
        >
          <InputLabel>{label}</InputLabel>
          <Select
            value={value}
            onChange={onChange}
            MenuProps={{PaperProps: {style: {maxHeight: "14rem"}}}}
            SelectDisplayProps={{
              style: {
                color: getSelectedItemColor(value, baseValue, isOverrideMode),
              },
            }}
          >
            {menuItems.map(item => (
              <MenuItem key={item.name} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isOverrideMode &&
          value !== baseValue && (
            <ClearIcon onClick={onClear} style={styles.clearIcon} />
          )}
      </div>
      {renderOverridenIssuesets(
        `parent_settings.${key}`,
        {
          containerStyle: {
            marginLeft: 0,
            marginRight: 0,
            maxWidth: "18rem",
          },
        },
        value =>
          (menuItems.find(menuItem => menuItem.value === value) || {}).name,
      )}
    </div>
  );
}

function ParentSettingsPanel(props) {
  const {
    parentSettings: propsParentSettings,
    parentSettingsBase,
    isOverrideMode,
  } = props;
  const [stateParentSettings, onStateParentSettingsUpdate] = useState(
    cloneDeep(propsParentSettings),
  );

  useEffect(
    () => {
      if (!_.isEqual(propsParentSettings, stateParentSettings)) {
        onStateParentSettingsUpdate(cloneDeep(propsParentSettings));
      }
    },
    [propsParentSettings],
  );

  function onParentSettingsKeyChange(key, value) {
    const newState = {
      ...stateParentSettings,
      [key]: value,
    };
    onStateParentSettingsUpdate(newState);
    props.onIssueUpdate(newState);
  }

  return (
    <SettingsFieldset legend="Parent Settings">
      <div
        style={{
          padding: "0.2rem 1.2rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {selectors.map(selector => {
          const onChange = e => {
            onParentSettingsKeyChange(selector.key, e.target.value);
          };
          const onClear = () => {
            onParentSettingsKeyChange(
              selector.key,
              parentSettingsBase[selector.key],
            );
          };
          return createSelectField(
            selector.key,
            stateParentSettings[selector.key] || "",
            parentSettingsBase[selector.key] || "",
            selector.label,
            onChange,
            onClear,
            selector.items,
            {marginTop: "10px", marginBottom: "4px"},
            isOverrideMode,
            props.renderOverridenIssuesets,
            selector.helperText,
          );
        })}
      </div>
    </SettingsFieldset>
  );
}

export default ParentSettingsPanel;
