import issuesetUtils from "common/utils/issues/issueset_utils";
import getIssuesetId from "./get_issueset_id";
import packetDocumentViewModes from "../constants/packet_document_view_modes";

function getStateOnViewModeChange(
  viewMode,
  availableIssuesets,
  projectDocumentTypeId,
  storedIssueset,
  defaultIssuesetId,
  contractTypesById,
) {
  const newStateItems = {viewMode};
  if (availableIssuesets.length > 0) {
    if (packetDocumentViewModes.includes(viewMode)) {
      const issuesetsById = issuesetUtils.getIssuesetsById(contractTypesById);
      const tsIssueset = availableIssuesets.find(isId => {
        const issuesetName = issuesetsById[isId].name.toLowerCase();
        const viewModeName = viewMode.replace("_", " ").toLowerCase();
        return (
          issuesetName === viewModeName ||
          (viewMode === "FORM" && issuesetName === "front page")
        );
      });
      newStateItems.currentIssueset = tsIssueset;
    } else {
      newStateItems.currentIssueset = getIssuesetId(
        viewMode,
        projectDocumentTypeId,
        defaultIssuesetId,
        storedIssueset,
      );
    }
  }
  return newStateItems;
}

export default getStateOnViewModeChange;
