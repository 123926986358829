import React from "react";
import requestor from "requestor";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import byId from "common/utils/by_id";
import {parseQuery, buildQuery} from "utils/uri";

import IssueList from "../components/issue_list";
import addIssueAction from "modules/documents/actions/issue_add";
import updateIssuesAction from "modules/documents/actions/issues_update";
import updateIssueAction from "modules/documents/actions/issue_update";
import documentIssuesUpdateRunAction from "modules/documents/actions/document_issues_update_run";

class IssuesList extends React.Component {
  componentDidMount() {
    const {pathname, search} = this.props.location;
    const searchParams = parseQuery(search);
    if (searchParams.filtered_issueset) {
      const organisationId = parseInt(this.props.params.organisationId, 10);
      const issuesetId = parseInt(searchParams.filtered_issueset, 10);
      localStorage.setItem(
        `filteredIssuesetIds_${organisationId}`,
        JSON.stringify([issuesetId]),
      );
      delete searchParams.filtered_issueset;
      const newSearch = buildQuery(searchParams);
      this.props.dispatch(push(pathname + newSearch));
    }
  }

  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return (
      this.permissioner.isAdmin() ||
      this.permissioner.hasPermission("list-issues")
    );
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.issues,
      this.props.topics,
      this.props.categories,
      this.props.tags,
      this.props.contractTypes,
      this.props.user,
      this.props.roles,
    ]);
  }

  renderComponent() {
    return (
      <React.Fragment>
        {this.renderIssuesList()}
        {this.props.children}
      </React.Fragment>
    );
  }

  renderIssuesList() {
    setTitle("Issue List");
    return (
      <IssueList
        issues={this.props.issues}
        topics={this.props.topics}
        topicsById={byId(this.props.topics)}
        categories={this.props.categories}
        tags={this.props.tags}
        organisationId={parseInt(this.props.params.organisationId, 10)}
        contractTypesById={byId(this.props.contractTypes)}
        addIssue={this.addIssue}
        updateIssues={this.updateIssues}
        updateIssue={this.updateIssue}
        updateDisplayLogic={this.updateDisplayLogic}
        roles={this.props.roles}
      />
    );
  }

  addIssue = newIssue => {
    const {organisationId} = this.props.params;
    return this.props.addIssueAction(organisationId, newIssue);
  };

  updateIssues = data => {
    const {organisationId} = this.props.params;
    this.props.updateIssuesAction(organisationId, data);
  };

  updateDisplayLogic = issueIds => {
    if (issueIds && issueIds.length > 0) {
      this.props.documentIssuesUpdateRun(
        this.props.params.organisationId,
        issueIds,
        {should_bypass_find_issues_api_call: true},
      );
    }
  };

  updateIssue = (issueId, issueLastEdited, data) => {
    const {organisationId} = this.props.params;

    return this.props.updateIssueAction(
      organisationId,
      issueId,
      issueLastEdited,
      data,
    );
  };
}

function select(state, props) {
  return {
    params: props.params,
    issues: state.issues,
    topics: state.topics,
    categories: state.topicCategories,
    tags: state.topicTags,
    contractTypes: state.contract_types,
    router: state.router,
    user: state.user,
    roles: state.roles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        addIssueAction: addIssueAction(requestor),
        updateIssuesAction: updateIssuesAction(requestor),
        updateIssueAction: updateIssueAction(requestor),
        documentIssuesUpdateRun: documentIssuesUpdateRunAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(IssuesList);
export const Component = IssuesList;
