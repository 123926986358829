import React from "react";
import {Link} from "react-router";
import styled from "styled-components";
import _ from "underscore";
import {ListSubheader, MenuItem, Select} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";

// This is a fairly arbitrary choice of format.
const formatDate = (date: Date) => date.toLocaleDateString();

const Container = styled.div`
  height: 100%;
  width: 100%;
  /**
    * Apparently, the Material UI Select component overflows its parent bounds
    * to the right. To prevent this causing noticeable overflow for parents
    * with overflow: scroll, we add this margin. Sad!
    */
  padding-right: 1rem;
  box-sizing: border-box;

  display: flex;
  column-gap: 0.5rem;
`;

const Dropdown = styled(Select)`
  height: 100%;
  width: 100%;
  .MuiSelect-selectMenu {
    white-space: normal;
  }
`;

const HorizontalBar = styled.hr`
  width: 75%;
  opacity: 0.1;
`;

const VerticalLinkIcon = styled(LinkIcon).attrs({
  style: {color: "#595959", height: "100%", width: "100%"},
})``;

const Date = styled.div`
  font-size: 0.6rem;
  color: #666666;
  font-family: monospace;
`;

type Document = {
  id: number;
  name: string;
  date: Date;
  /**
   * Optional ID used to group documents in the selector list, related to
   * the one selected.
   */
  group_id?: string;
};

type Props = {
  /** Array of documents to select from */
  documents: Document[];

  selectedDocument?: {
    id: number;
    /**
     * Optional link to document, displayed adjacent to selector if provided.
     */
    link?: string;

    /**
     * Optional ID used to group documents in the selector list, related to
     * the one selected.
     */
    group_id?: string;
  };

  /**
   * Optional callback invoked with the ID of a new document selected from
   * @property documents
   */
  onChange?: (newValue: Document["id"]) => void;
};

const DocumentSelector = ({documents, selectedDocument, onChange}: Props) => {
  const isGroupedWithSelected = (document: Document) =>
    document?.group_id && selectedDocument?.group_id
      ? document.group_id === selectedDocument.group_id
      : // If we lack the information to tell if a document is grouped
        // with the one selected, we assume it isn't.
        false;

  // For display, we partition documents in the dropdown list into those
  // belonging to the same group as the one selected, and those that aren't.
  const [inGroup, outsideGroup]: [Document[], Document[]] = _.partition(
    documents.sort((a, b) => b.date.getTime() - a.date.getTime()),
    isGroupedWithSelected,
  );

  return (
    <Container>
      <Dropdown
        value={selectedDocument?.id ?? ""}
        onChange={event => {
          if (onChange && typeof event.target.value === "number") {
            onChange(event.target.value);
          }
        }}
      >
        {inGroup.map(document => (
          <MenuItem
            key={document.id}
            value={document.id}
            style={{display: "block"}}
          >
            {document.name}
            <Date>{formatDate(document.date)}</Date>
          </MenuItem>
        ))}
        {inGroup.length > 0 && outsideGroup.length > 0 && (
          <ListSubheader>
            <HorizontalBar />
          </ListSubheader>
        )}
        {outsideGroup.map(document => (
          // I wish I could de-duplicate this without breaking the material UI
          // "magic" that allows values to be matched to these items. Very
          // annoying black box behaviour.
          <MenuItem
            key={document.id}
            value={document.id}
            style={{display: "block"}}
          >
            {document.name}
            <Date>{formatDate(document.date)}</Date>
          </MenuItem>
        ))}
      </Dropdown>

      {selectedDocument?.link !== undefined && (
        <Link
          style={{
            width: "2rem",
            display: "flex",
            alignItems: "center",
          }}
          to={{pathname: selectedDocument.link}}
        >
          <VerticalLinkIcon />
        </Link>
      )}
    </Container>
  );
};

export default DocumentSelector;
