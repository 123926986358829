import requestor from "requestor";

import BaseRoute from "utils/base_route";
import getRouteIds from "utils/get_route_ids";

import IssueDetailContainer from "./containers/issue_detail";

import issueInfoFetchAction from "modules/documents/actions/issue_info_fetch";
import issueClearAction from "modules/documents/actions/issue_clear";
import logsClearAction from "modules/logs/actions/logs_clear";

export default class IssueDetail extends BaseRoute {
  constructor(store) {
    super(":issueId", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, IssueDetailContainer);
  }

  fetchActiveData(state) {
    this.fetchIssue(state);
  }

  fetchIssue(state) {
    const {organisationId, issueId} = getRouteIds(state.router);
    this.store.dispatch(
      issueInfoFetchAction(requestor)(organisationId, issueId),
    );
  }

  clearActiveData() {
    // issue reducer is cleared in the reducer
    this.store.dispatch(logsClearAction());
  }

  clearIssue(state) {
    const {organisationId, issueId} = getRouteIds(state.router);
    this.store.dispatch(issueClearAction(organisationId, issueId));
  }
}
