import {get} from "lodash";

const refetchExistingRegexClassifierStatsGradually = async (
  classifiers,
  getTopicRegexClassifierStats,
  selectedContractTypeIds,
) => {
  const existingStats = get(classifiers, "classifiers[0].stats");
  if (existingStats) {
    for (const regex_type of Object.keys(existingStats)) {
      for (const regex of Object.keys(existingStats[regex_type])) {
        await getTopicRegexClassifierStats(
          selectedContractTypeIds,
          regex,
          regex_type,
        );
      }
    }
  }
};

export default refetchExistingRegexClassifierStatsGradually;
