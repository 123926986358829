import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {WorkflowId, WorkflowInputId} from "common/flowmaster/types/workflow";

export type WorkflowInputRemoveAction = {
  type: WorkflowsActionTypes["WORKFLOW_INPUT_REMOVE"]["SUCCESS"];
  payload: {id: WorkflowInputId; workflow_id: WorkflowId};
};

export default function removeWorkflowInput(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOW_INPUT_REMOVE,
    (
      organisationId: OrganisationId,
      workflowId: WorkflowId,
      workflowInputId: WorkflowInputId,
    ) => {
      return requestor
        .delete(
          `${basePath}/organisation/${organisationId}/workflow/${workflowId}/input/${workflowInputId}`,
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
