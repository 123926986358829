import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function generateDocumentLlmPrompt(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_GENERATE_PROMPT,
    (
      organisationId,
      projectId,
      documentId,
      prompt,
      conversationType,
      conversationId,
      previousPromptId,
    ) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}` +
            `/document/${documentId}` +
            "/generate_prompt",
          {
            conversation_type: conversationType,
            conversation_id: conversationId,
            previous_prompt_id: previousPromptId,
            new_prompt: prompt,
          },
          {withCredentials: true},
        )
        .then(response => ({
          id: conversationId,
          conversation_type: conversationType,
          ...response.data,
        })),
  );
}
