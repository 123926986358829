import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";

import {Link} from "react-router";

import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import LockIcon from "@material-ui/icons/Lock";
import UnlockIcon from "@material-ui/icons/LockOpen";

import HotAndStarInfoWidget from "common_components/hot_and_star_info_widget";

const styles = {
  name: {
    cursor: "pointer",
  },
  numberCell: {
    width: "3em",
    textAlign: "right",
  },
  confirmedCell: {
    background: "#eef",
  },
  recentCell: {
    background: "#fafaff",
  },
  nonconfirmedCell: {
    background: "#ffe",
  },
};

const recentMapping = {
  f1: "recentF1",
  tp: "recent_tp",
  fn: "recent_fn",
  fp: "recent_fp",
};

const confirmedMapping = {
  f1: "f1",
  tp: "tp",
  fn: "fn",
  fp: "fp",
  tn: "tn",
};

function StatefulLock(props) {
  const {isLocked} = props;
  const [isClickedLocked, updateIsLocked] = React.useState(false);

  function onUnlockHandler() {
    updateIsLocked(true);
    if (props.onLock) {
      props.onLock();
    }
  }
  return (
    <span title={isLocked ? "Deployed" : "Not Deployed"}>
      <IconButton disabled={isClickedLocked && !isLocked}>
        {isLocked ? (
          <LockIcon style={{color: "#777"}} />
        ) : (
          <UnlockIcon style={{cursor: "pointer"}} onClick={onUnlockHandler} />
        )}
      </IconButton>
    </span>
  );
}

class TopicListItem extends React.Component {
  render() {
    const {topic, organisationId} = this.props;
    return (
      <tbody>
        <tr>
          {[
            <td
              key="id"
              className="id"
              rowSpan="2"
              style={{
                paddingRight: "1.5em",
                textAlign: "right",
              }}
            >
              {topic.id}
            </td>,
            <td key="star_hot">
              <HotAndStarInfoWidget
                label="Topic"
                isHot={topic.is_hot}
                isStar={topic.is_star}
                updateIsStar={this.updateTopicIsStar(topic.id, topic.is_star)}
              />
            </td>,
            <td
              key="master-id"
              className="master-id"
              rowSpan="2"
              style={{
                paddingRight: "1.5em",
                textAlign: "right",
                fontFamily: "monospace",
              }}
              title={topic.master_id}
            >
              {topic.master_id.substring(0, 6)}…
            </td>,

            <td key="name" className="name" rowSpan="2" style={styles.name}>
              <Link
                to={{
                  pathname: `/organisation/${organisationId}/topic/${
                    topic.id
                  }/analysis`,
                }}
                style={{color: "#000", textDecoration: "none"}}
              >
                {topic.name}
              </Link>
            </td>,

            <td key="category" rowSpan="2" className="category">
              {topic.categoryName}
            </td>,

            <td key="tags" rowSpan="2" className="tags">
              {topic.tagNames.join(" ")}
            </td>,

            <td rowSpan="2" key="contract-types" className="contract-types">
              {topic.contract_types.map((ct, index) => (
                <div
                  key={index}
                  style={{fontSize: "12px", paddingLeft: "1rem"}}
                >
                  {ct.name}
                </div>
              ))}
            </td>,
            <td rowSpan="2" key="issue-count" style={{textAlign: "center"}}>
              {topic.referenced_issues.length}
            </td>,
            <td
              key="uses"
              rowSpan="2"
              className="uses"
              style={{...styles.numberCell, ...styles.confirmedCell}}
            >
              {topic.uses}
            </td>,

            <td
              key="unconfirmed-uses"
              rowSpan="2"
              className="unconfirmed-uses"
              style={{...styles.numberCell, ...styles.nonconfirmedCell}}
            >
              {topic.unconfirmed_uses}
            </td>,
            ...(this.props.showRecent
              ? this.renderScores(topic, styles.recentCell, recentMapping, true)
              : this.renderScores(
                  topic,
                  styles.confirmedCell,
                  confirmedMapping,
                  false,
                )),
          ]}
        </tr>
        <tr>
          {this.props.showRecent &&
            this.renderScores(
              topic,
              styles.confirmedCell,
              confirmedMapping,
              false,
            )}
        </tr>
      </tbody>
    );
  }

  getBestConfiguration(topic, classifier) {
    const stats = (topic.stats || []).find(
      stat => stat.classifier_id === classifier.id,
    );
    if (!stats) {
      return {tp: 0, fp: 0, fn: 0, tn: 0, f1: 0, up_to_date: null};
    }
    const best = stats.stats.reduce(
      (state, stat) => {
        const {
          tp: allTp,
          fp: allFp,
          fn: allFn,
          recent_tp: recentTp,
          recent_fp: recentFp,
          recent_fn: recentFn,
        } = stat;
        const allSum = allTp + allFp + allFn;
        const allF1 = allSum > 0 ? 2 * allTp / (2 * allTp + allFp + allFn) : 0;
        const recentSum = recentTp + recentFp + recentFn;
        const recentF1 = recentSum
          ? 2 * recentTp / (2 * recentTp + recentFp + recentFn)
          : 0;
        if ((recentF1 || allF1) >= state.bestScore) {
          return {
            bestScore: recentF1 || allF1,
            best: {f1: allF1, recentF1, ...stat},
          };
        }
        return state;
      },
      {bestScore: 0, best: null},
    ).best;
    return best;
  }

  renderScores(topic, style, keys, showRecent) {
    return [].concat(
      ...this.props.classifiers
        .filter(classifier => this.props.classifierVisibility[classifier.name])
        .map(classifier => {
          const stats = this.getBestConfiguration(topic, classifier);
          const baseStyle = {...(style || {}), ...styles.numberCell};
          return [
            showRecent ? this.renderRecent(stats) : null,
            <td
              key={`lock-${classifier.id}`}
              className={`lock-${classifier.id}`}
              style={{
                ...baseStyle,
                textAlign: "center",
              }}
            >
              <StatefulLock
                isLocked={stats.up_to_date}
                onLock={this.lockClassifier(
                  topic.id,
                  classifier.id,
                  stats.configuration_id,
                  stats.last_edited,
                )}
              />
            </td>,
            <td
              key={`f1-${classifier.id}`}
              className={`f1-${classifier.id}`}
              style={baseStyle}
              rowSpan={!showRecent ? "2" : "1"}
            >
              {(stats[keys.f1] * 100).toFixed(2)}%
            </td>,
            <td
              key={`tp-${classifier.id}`}
              className={`tp-${classifier.id}`}
              style={baseStyle}
              rowSpan={!showRecent ? "2" : "1"}
            >
              {stats[keys.tp]}
            </td>,
            <td
              key={`fn-${classifier.id}`}
              className={`fn-${classifier.id}`}
              style={baseStyle}
              rowSpan={!showRecent ? "2" : "1"}
            >
              {stats[keys.fn]}
            </td>,
            <td
              key={`fp-${classifier.id}`}
              className={`fp-${classifier.id}`}
              style={baseStyle}
              rowSpan={!showRecent ? "2" : "1"}
            >
              {stats[keys.fp]}
            </td>,
            <td
              key={`tn-${classifier.id}`}
              className={`tn-${classifier.id}`}
              style={baseStyle}
              rowSpan={!showRecent ? "2" : "1"}
            >
              {keys.tn ? stats[keys.tn] : "-"}
            </td>,
            <td
              key={`we-${classifier.id}`}
              className={`we-${classifier.id}`}
              style={baseStyle}
              rowSpan={!showRecent ? "2" : "1"}
            >
              {((1 - stats[keys.f1]) * topic.uses).toFixed(1)}
            </td>,
            <td key="rerunButton-${classifier.id}" rowSpan="2">
              <RefreshIcon
                onClick={this.rerunClassifier(classifier, stats)}
                style={{
                  cursor: "pointer",
                  height: "18px",
                  width: "18px",
                  margin: "0 0.5rem",
                  position: "relative",
                  top: "4px",
                }}
              />
            </td>,
          ];
        }),
    );
  }

  rerunClassifier = _.memoize(
    (classifier, stats) => () => {
      this.props[
        classifier.id === 1 ? "rerunRegexClassifier" : "rerunClassifier"
      ](this.props.topic.id, stats.configuration_id);
    },
    classifier => JSON.stringify([classifier.id]),
  );

  renderRecent(stats) {
    if (!stats || !stats.creation_date) {
      return <td rowSpan="2" />;
    }
    const date = new Date(stats.creation_date);
    return (
      <td key={`recent-stats-${stats.configuration_id}`} rowSpan="2">
        <div style={{whiteSpace: "nowrap", textAlign: "center"}}>
          {[
            date.getUTCFullYear(),
            (date.getUTCMonth() + 1).toString().padStart(2, "0"),
            date
              .getUTCDate()
              .toString()
              .padStart(2, "0"),
          ].join("-")}
        </div>
        <div style={{whiteSpace: "nowrap", textAlign: "center"}}>
          {[
            date
              .getUTCHours()
              .toString()
              .padStart(2, "0"),
            date
              .getUTCMinutes()
              .toString()
              .padStart(2, "0"),
          ].join(":")}
        </div>
        <div style={{textAlign: "center"}}>#{stats.clauses_used}</div>
      </td>
    );
  }

  getStatsUpdateKey(topic) {
    return _.sortBy(
      _.map(topic.stats, (stats, key) => [
        key,
        stats.stats.map(stat => stat.last_edited),
      ]),
      el => el[0],
    );
  }

  shouldComponentUpdate(nextProps /* , nextState */) {
    return (
      this.props.topic.selected !== nextProps.topic.selected ||
      this.props.topic.last_edited !== nextProps.topic.last_edited ||
      !_.isEqual(
        this.getStatsUpdateKey(this.props.topic),
        this.getStatsUpdateKey(nextProps.topic),
      ) ||
      this.props.showRecent !== nextProps.showRecent ||
      this.props.classifierVisibility !== nextProps.classifierVisibility ||
      !_.isEqual(
        this.props.topic.contract_types,
        nextProps.topic.contract_types,
      ) ||
      !_.isEqual(this.props.topic.stats, nextProps.topic.stats)
    );
  }

  lockClassifier = _.memoize(
    (topicId, classifierId, configurationId, lastEdited) => () =>
      this.props.lockClassifier(
        topicId,
        classifierId,
        configurationId,
        lastEdited,
      ),
    (...args) => JSON.stringify(...args),
  );

  updateTopicIsStar = (topicId, topicIsStar) => () => {
    this.props.onTopicUpdate(topicId, {is_star: !topicIsStar});
  };
}

TopicListItem.propTypes = {
  organisationId: PropTypes.number.isRequired,
  topic: PropTypes.shape({
    id: PropTypes.number.isRequired,
    uses: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    tagNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default TopicListItem;
export {TopicListItem};
