import * as colors from "material-ui/styles/colors";

const styles = {
  toolbarGroupBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: 8,
  },
  toolbarGroupIcon: {
    marginRight: 8,
    color: "#9aa0a7",
  },
  searchFieldContainer: {
    marginRight: 15,
  },
  searchField: {
    height: 24,
  },
  searchFieldMatchCount: {
    fontSize: 11,
    color: "#2096f3",
    borderTop: "1px solid #9aa0a7",
    paddingTop: 3,
  },
  circularProgress: {
    display: "block",
    margin: ".5em  auto",
  },
  noResultsFound: {
    fontSize: "13pt",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  contentBlock: {
    padding: "10px 0px",
  },
  projectHeading: {
    padding: "6px 12px",
    fontSize: 15,
    color: "#333",
    backgroundColor: "#abcfef",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  prioritisedProjectHeading: {
    backgroundColor: "#87bb4b",
  },
  projectTitle: {
    fontSize: 30,
    padding: 10,
  },
  document: {
    border: `1px solid ${colors.grey300}`,
    borderTop: "none",
  },
  documentHeading: {
    fontSize: 15,
    color: "#333",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${colors.grey300}`,
    marginBottom: 12,
    padding: "6px 12px",
    backgroundColor: "#cfe2f3",
    marginLeft: -1,
    marginRight: -1,
  },
  prioritisedDocumentHeading: {
    backgroundColor: colors.lightGreen400,
  },
  documentNumber: {
    border: "1px solid rgba(0,0,0,0.4)",
    borderRadius: "50%",
    marginRight: 10,
    fontSize: 12,
    color: "rgba(0,0,0,0.4)",
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 18,
    height: 18,
    marginTop: -1,
    marginBottom: -1,
  },
  documentTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  documentClauses: {
    padding: "6px 12px",
  },
  clause: {
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: `1px solid ${colors.grey300}`,
  },
  clausepart: {
    fontSize: 14,
    fontWeight: 100,
    marginBottom: 10,
  },
  clausepartInner: {
    display: "flex",
    justifyContent: "space-between",
  },
  clausepartContent: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  clausepartReference: {
    marginRight: 10,
    fontWeight: 400,
    flexBasis: 30,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  clausepartIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingRight: "3px",
    paddingLeft: 10,
  },
  clausepartGap: {
    letterSpacing: "4px",
    fontWeight: "bold",
    color: "#c16b6b",
    fontSize: 12,
    padding: "10px 0",
    marginTop: 7,
  },
  clauseChipsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    padding: "10px 0",
  },
  clauseChip: {
    margin: "0 0 5px 5px",
    height: 22,
  },
  clauseChipWithIcon: {
    margin: "0 0 5px 5px",
    height: 22,
    backgroundColor: "#fcfcfc",
    border: "1px solid #bbb",
  },
  clauseChipAvatar: {
    height: 22,
    width: 22,
    backgroundColor: "rgba(250,250,250,0)",
  },
  clauseChipAvatarIcon: {
    height: 30,
    width: "unset",
    margin: 0,
    marginBottom: 2,
  },
  clauseChipLabel: {
    fontSize: 12,
    lineHeight: "22px",
  },
  documentClausepartsAmount: {
    padding: "3px 6px",
    fontSize: 13,
    fontWeight: 100,
    color: "#333",
    textAlign: "center",
    borderTop: "1px solid #e0e0e0",
  },
  showMoreButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px 0",
  },
  showMoreButtonLabel: {
    fontSize: 12,
  },
  showMoreButtonOverlay: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  definitionsTable: {
    fontSize: "14px",
    fontWeight: 100,
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "0.5em",
    color: "#888",
  },
  definitionsTableHeader: {
    fontWeight: 400,
    border: "1px solid #eee",
    color: "#666",
  },
  definitionsTableCell: {
    border: "1px solid #eee",
  },
  definitionsTableTermCell: {
    paddingRight: "1em",
  },
  definitionsTableMeaningCell: {
    borderRight: "none",
  },
  definitionsTableCopyCell: {
    borderLeft: "none",
    width: "20px",
  },
  definitionsOpened: {
    marginTop: 10,
  },
  definitionsClosed: {
    fontSize: "14px",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  definitionsClosedText: {
    color: "#666",
  },
};

export default styles;
