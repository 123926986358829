import React, {useMemo} from "react";
import {Link} from "react-router";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Divider,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import StarRateIcon from "@material-ui/icons/StarRate";
import InputEditor from "./input_editor";

import {
  Workflow,
  WorkflowId,
  WorkflowContext,
  WorkflowInputs,
  WorkflowInput,
  Prompt,
} from "common/flowmaster/types/workflow";
import {
  WorkflowInputValuesArgument,
  WorkflowTask,
  ActionDefinition,
} from "common/flowmaster/types/task_config";
import {OrganisationId} from "common/types/organisation";

interface WorkflowInputValueEditorProps {
  argValue: WorkflowInputValuesArgument;
  workflows: Workflow[];
  onUpdateItem: (value: WorkflowInputValuesArgument) => void;

  priorTasks: WorkflowTask[];
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  organisationId: OrganisationId;
  useListItem?: boolean;
}

const WorkflowInputValueEditor: React.FC<WorkflowInputValueEditorProps> = ({
  argValue,
  workflows,
  onUpdateItem,
  priorTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  organisationId,
  useListItem = false,
}) => {
  const workflowInputs =
    workflows.find(workflow => workflow.id === argValue?.value?.workflowId)
      ?.inputs?.values ?? ([] as WorkflowInput[]);
  return (
    <div>
      <WorkflowSelector
        workflowId={argValue?.value?.workflowId}
        workflows={workflows}
        onUpdateItem={workflowId =>
          onUpdateItem({value: {workflowId, inputValues: {}}})
        }
        organisationId={organisationId}
      />
      <InputEditor
        workflowInputs={workflowInputs}
        values={argValue?.value?.inputValues}
        onUpdateItem={(key, value) =>
          onUpdateItem({
            ...argValue,
            value: {
              ...argValue.value,
              inputValues: {
                ...argValue.value.inputValues,
                [key]: value,
              },
            },
          })
        }
        priorTasks={priorTasks}
        context={context}
        inputs={inputs}
        prompts={prompts}
        actionDefinitions={actionDefinitions}
        organisationId={organisationId}
        useListItem={useListItem}
      />
    </div>
  );
};

interface WorkflowSelectorProps {
  workflowId: WorkflowId;
  workflows: Workflow[];
  onUpdateItem: (value: WorkflowId) => void;
  organisationId: OrganisationId;
}

const WorkflowSelector: React.FC<WorkflowSelectorProps> = ({
  workflowId,
  workflows,
  onUpdateItem,
  organisationId,
}) => {
  const filteredWorkflows = workflows.filter(item => item.context_id === 2);

  const [starredWorkflows, nonStarredWorkflows] = useMemo(() => {
    return filteredWorkflows.reduce(
      (partitionedWorkflows, workflow) => {
        if (workflow.is_star) {
          partitionedWorkflows[0].push(workflow);
        } else {
          partitionedWorkflows[1].push(workflow);
        }
        return partitionedWorkflows;
      },
      [[], []] as [Workflow[], Workflow[]],
    );
  }, [filteredWorkflows]);

  const workflowItems = [
    ...starredWorkflows.map(workflow => (
      <MenuItem value={workflow.id} key={workflow.id}>
        <StarRateIcon style={{marginRight: "8px", color: "#fbc02d"}} />
        {workflow.name}
      </MenuItem>
    )),
    ...(starredWorkflows.length > 0 && nonStarredWorkflows.length > 0
      ? [<Divider key="divider" style={{margin: "8px 0"}} />]
      : []),
    ...nonStarredWorkflows.map(workflow => (
      <MenuItem value={workflow.id} key={workflow.id}>
        {workflow.name}
      </MenuItem>
    )),
  ];

  const handleChange = (event: React.ChangeEvent<{value: WorkflowId}>) => {
    onUpdateItem(event.target.value);
  };

  const renderValue = (selectedId: WorkflowId) => {
    const foundWorkflow = workflows.find(
      workflow => workflow.id === selectedId,
    );
    return foundWorkflow ? foundWorkflow.name : "";
  };

  return (
    <div style={{display: "flex"}}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="workflow-selector-label">Workflow</InputLabel>
        <Select
          labelId="workflow-selector-label"
          value={workflowId}
          onChange={handleChange}
          label="Workflow"
          renderValue={renderValue}
        >
          {workflowItems}
        </Select>
      </FormControl>
      {workflowId && (
        <Link
          to={`/organisation/${organisationId}/workflow/${workflowId}/detail`}
        >
          <IconButton>
            <LinkIcon />
          </IconButton>
        </Link>
      )}
    </div>
  );
};

const WorkflowInputValueArg = {
  Component: WorkflowInputValueEditor,
  default: {workflow: null, inputs: []},
};

const WorkflowInputWithListItemValueArg = {
  Component: props => (
    <WorkflowInputValueEditor {...props} useListItem="true" />
  ),
  default: {workflow: null, inputs: []},
};

export {WorkflowInputValueArg, WorkflowInputWithListItemValueArg};
