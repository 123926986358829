import findActualValue from "./find_actual_value";

function constructParametersData(values, actualValues, parameterType) {
  const result = {
    confirmed: [],
    deleted: [],
    unconfirmed: [],
    added: [],
  };
  if (!values && !actualValues) {
    return result;
  }
  if (!values) {
    result.added = [...actualValues];
  }
  if (!actualValues) {
    result.unconfirmed = [...values];
  }
  if (values && actualValues) {
    const remainedActualValues = JSON.parse(JSON.stringify(actualValues));
    (values || []).forEach(value => {
      const foundActualValue = findActualValue(
        value,
        remainedActualValues,
        parameterType,
      );
      if (foundActualValue.value) {
        result.confirmed.push(foundActualValue.value);
        remainedActualValues.splice(foundActualValue.index, 1);
      } else {
        result.deleted.push(value);
      }
    });
    result.added = [...remainedActualValues];
  }
  return result;
}

export default constructParametersData;
