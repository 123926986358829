import React from "react";
import _ from "underscore";
import {cloneDeep} from "lodash";

import TextField from "material-ui/TextField";
import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";

import HelpIcon from "@material-ui/icons/Help";

import maxUsageUtils from "./utils/max_usages_utils";
import SearchByTextControl from "./search_by_text_control";

const styles = {
  showHideUsagesButtonRoot: {
    height: "20px",
    lineHeight: "20px",
    position: "relative",
    bottom: "3px",
    maxWidth: "4rem",
    minWidth: "unset",
    margin: "0 1px",
  },
  showHideUsagesButtonButton: {
    padding: "0 10px",
  },
  showHideUsagesButtonLabel: {
    paddingLeft: "unset",
    paddingRight: "unset",
    fontSize: "12px",
  },
  showLabel: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.3)",
    position: "relative",
    top: "12px",
  },
  dropdownRoot: {
    width: "256px",
    paddingBottom: "1rem",
  },
  dropdownUnderline: {
    margin: "unset",
  },
  dropdownButton: {
    right: "0px",
  },
  confirmedStatusContainer: {
    fontSize: "14px",
    color: "#616161",
  },
  confirmedStatusEl: {
    display: "inline-block",
  },
  fieldsetContainer: {
    marginLeft: 0,
    padding: "0px 0px 4px 4px",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    borderRadius: 0,
    position: "relative",
    top: "14px",
  },
  fieldsetLegend: {
    display: "flex",
    color: "#616161",
  },
  helpIcon: {
    height: 14,
    width: 14,
    marginLeft: 4,
    marginRight: 2,
    color: "#757575",
  },
  buttonStats: {
    textAlign: "center",
    color: "#757575",
    fontSize: 14,
  },
  totalButtonStats: {
    textAlign: "center",
    color: "#aaa",
    fontSize: 14,
  },
};

class MaxUsagesControl extends React.Component {
  render() {
    const {settings, usages} = this.props;
    const buttonLabels = _.omit(settings, ["maxUsages", "show"]);
    return (
      <div
        className="maxusagescontrol"
        style={{display: "flex", flexDirection: "column", maxWidth: "17rem"}}
      >
        {this.renderConfirmedStats(usages)}
        {this.renderParameterStats(this.props.parameterStats)}
        <SearchByTextControl
          onSearch={this.props.onUsagesTextFilterChange}
          allUsagesCount={this.props.allUsagesCount}
          searchUsagesCount={this.props.searchUsagesCount}
        />
        <TextField
          floatingLabelText={this.props.label || "Usages Per Page"}
          defaultValue={settings.maxUsages}
          type="number"
          min={0}
          onBlur={this.updateMaxUsagesCount}
          inputStyle={{paddingLeft: "24px"}}
        />
        <div style={styles.showLabel}>Show</div>
        <DropDownMenu
          value={settings.show}
          onChange={this.updateShow}
          style={styles.dropdownRoot}
          underlineStyle={styles.dropdownUnderline}
          iconStyle={styles.dropdownButton}
        >
          <MenuItem value={"all"} primaryText="All" />
          <MenuItem value={"confirmed"} primaryText="Confirmed" />
          <MenuItem value={"non_confirmed"} primaryText="Non Confirmed" />
        </DropDownMenu>
        <div style={{display: "flex"}}>
          {Object.keys(buttonLabels).map(label => (
            <div
              key={label}
              style={{
                flexShrink: 1,
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <RaisedButton
                label={label.toUpperCase()}
                onClick={this.updateButtonItem(label)}
                primary={settings[label]}
                style={styles.showHideUsagesButtonRoot}
                buttonStyle={styles.showHideUsagesButtonButton}
                labelStyle={styles.showHideUsagesButtonLabel}
              />
              {this.renderButtonStats(label)}
            </div>
          ))}
        </div>
        {this.props?.showButton ? (
          <div style={{padding: "6px"}}>
            {this.props.showButton ?
              <RaisedButton
                label={"Update usages"}
                labelStyle={styles.showHideUsagesButtonLabel}
                style={{width: "100%"}}
                onClick={this.props?.updateUsages}
                primary
              /> : null
            }
          </div>) : null}
      </div>
    );
  }

  renderConfirmedStats(usages) {
    const {isRequestPending} = this.props;
    const stats = (usages || []).reduce(
      (result, usage) => {
        if (usage.is_confirmed) {
          result.confirmed += 1;
        } else {
          result.unconfirmed += 1;
        }
        return result;
      },
      {confirmed: 0, unconfirmed: 0},
    );
    return (
      <fieldset style={styles.fieldsetContainer}>
        <legend style={styles.fieldsetLegend}>
          <div style={{fontSize: 14}}>Topics</div>
          <div title="Amount of clauses downloaded with the topic confirmed and unconfirmed">
            <HelpIcon style={styles.helpIcon} />
          </div>
        </legend>
        <div style={styles.confirmedStatusContainer}>
          <div style={styles.confirmedStatusEl}>
            Confirmed:{" "}
            <span style={{fontWeight: "600"}}>
              {isRequestPending ? "-" : stats.confirmed}
            </span>
          </div>
          <div style={{...styles.confirmedStatusEl, marginLeft: "1rem"}}>
            Unconfirmed:{" "}
            <span style={{fontWeight: "600"}}>
              {isRequestPending ? "-" : stats.unconfirmed}
            </span>
          </div>
        </div>
      </fieldset>
    );
  }

  renderParameterStats = parameterStats => {
    if (!parameterStats) {
      return null;
    }
    const {isRequestPending} = this.props;
    return (
      <fieldset style={{...styles.fieldsetContainer, marginTop: 8}}>
        <legend style={styles.fieldsetLegend}>
          <div style={{fontSize: 14}}>Parameters</div>
          <div
            title="Amount of clauses downloaded with the parameter having confirmed values,
            unconfirmed values or where the parameter is not used"
          >
            <HelpIcon style={styles.helpIcon} />
          </div>
        </legend>
        <div style={styles.confirmedStatusContainer}>
          <div style={styles.confirmedStatusEl}>
            Confirmed:{" "}
            <span style={{fontWeight: "600"}}>
              {isRequestPending ? "-" : parameterStats.confirmed}
            </span>
          </div>
          <div style={{...styles.confirmedStatusEl, marginLeft: "1rem"}}>
            Unconfirmed:{" "}
            <span style={{fontWeight: "600"}}>
              {isRequestPending ? "-" : parameterStats.unconfirmed}
            </span>
          </div>
          <div style={styles.confirmedStatusEl}>
            Non-Matching:{" "}
            <span style={{fontWeight: "600"}}>
              {isRequestPending ? "-" : parameterStats.nonMatching}
            </span>
          </div>
        </div>
      </fieldset>
    );
  };

  renderButtonStats = label => {
    const {stats} = this.props;
    if (stats) {
      // const fetchedValue = stats[`fetched_${label}_count`];
      const totalValue = stats[`total_${label}s`];
      return (
        totalValue !== undefined && (
          <div style={styles.buttonStats}>
            {totalValue}
          </div>
        )
      );
    }
    return null;
  };

  updateMaxUsagesCount = e => {
    const newMaxUsages = e.target.value;
    this.updateMaxUsagesSettings(
      "maxUsages",
      parseInt(newMaxUsages, 10) || 100,
    );
  };

  updateShow = (e, index, value) => {
    this.updateMaxUsagesSettings("show", value);
  };

  updateButtonItem = buttonLabel => () => {
    const newButtonValue = !this.props.settings[buttonLabel];
    this.updateMaxUsagesSettings(buttonLabel, newButtonValue);
  };

  updateMaxUsagesSettings = (item, newItemValue) => {
    const newMaxUsagesSettings = cloneDeep(this.props.settings);
    newMaxUsagesSettings[item] = newItemValue;
    maxUsageUtils.setMaxUsagesSettings(newMaxUsagesSettings);
    this.props.updateMaxUsagesSettings(newMaxUsagesSettings, item);
  };
}

export default MaxUsagesControl;
