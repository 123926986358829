import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Paper} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import PieChart from "./pie_chart";
import {colors} from "../reports_table";

const useStyles = makeStyles({
  wrapper: {
    margin: "2em 2em 0em 2em",
  },
  root: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      width: "50%",
      margin: "2em auto",
    },
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  left: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "5%",
  },
  right: {
    display: "flex",
    alignItems: "center",
    marginRight: "2em",
    borderLeft: "2px solid #F2F4F7",
  },
  title: {marginBottom: "2em"},
  total: {
    marginBottom: "1em",
    color: colors.lightGrey,
  },
  updated: {
    marginBottom: "1em",
    color: colors.lightGrey,
  },
  chart: {
    margin: "auto 10%",
  },
  legend: {
    "& > *": {
      marginBottom: ".5em",
    },
  },
  legendItem: {
    display: "flex",
    alignItems: "flex-start",
    "& > *": {
      marginRight: ".5em",
    },
  },
  legendCircle: {},
  lagandDescr: {},
  legendTitle: {},
  legendAmount: {
    fontSize: ".9em",
    color: colors.lightGrey,
  },
});

export default function ReportsSummary({data}) {
  const classes = useStyles();
  const chartData = [
    {
      angle: data.high,
      color: colors.red,
    },
    {
      angle: data.moderate,
      color: colors.amber,
    },
    {
      angle: data.low,
      color: colors.green,
    },
  ];
  return (
    <Paper className={classes.wrapper}>
      <div className={classes.root}>
        <div className={classes.left}>
          <h2 className={classes.title}>Risk Report Overview</h2>
        </div>
        <div className={classes.right}>
          <div className={classes.chart}>
            <PieChart data={chartData} />
          </div>
          <div className={classes.legend}>
            <div className={classes.legendItem}>
              <div className={classes.legendCircle}>
                <FiberManualRecordIcon htmlColor={colors.high} />
              </div>
              <div className={classes.legendDescr}>
                <div className={classes.legendTitle}>High Risk</div>
                <div className={classes.legendAmount}>
                  {data.high} contracts
                </div>
              </div>
            </div>

            <div className={classes.legendItem}>
              <div className={classes.legendCircle}>
                <FiberManualRecordIcon htmlColor={colors.moderate} />
              </div>
              <div className={classes.legendDescr}>
                <div className={classes.legendTitle}>Moderate Risk</div>
                <div className={classes.legendAmount}>
                  {data.moderate} contracts
                </div>
              </div>
            </div>

            <div className={classes.legendItem}>
              <div className={classes.legendCircle}>
                <FiberManualRecordIcon htmlColor={colors.low} />
              </div>
              <div className={classes.legendDescr}>
                <div className={classes.legendTitle}>Low risk</div>
                <div className={classes.legendAmount}>{data.low} contracts</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
}
