import React from "react";

import {RadialChart} from "react-vis";

export default function GradientPie({data}) {
  return (
    <RadialChart
      colorType="literal"
      data={data}
      showLabels
      style={{stroke: "#fff", strokeWidth: 5}}
      width={200}
      height={200}
      radius={100}
      innerRadius={50}
    />
  );
}
