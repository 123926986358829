import _ from "underscore";

function getRegexKeys(searchIn) {
  const matchArray = [];
  const regex = /\$\{\w+\}/gm;
  let m;
  while ((m = regex.exec(searchIn)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    m.forEach(match => {
      matchArray.push(match.substring(2, match.length - 1));
    });
  }
  return matchArray;
}

function getUsedRegexModules(modules, classifier) {
  const stringToLookIn = classifier?.value?.whitelist
    ? classifier.value.whitelist.concat(classifier.value.blacklist).join("")
    : [].concat(classifier?.value?.blacklist ?? []).join("");
  const keys = getRegexKeys(stringToLookIn);
  const modulesObject = {};
  modules.forEach(module => (modulesObject[module.key] = module));

  const usedModules = keys.map(key => {
    if (modulesObject[key]) {
      return modulesObject[key];
    }
    return {
      key,
      value: ["NO MODULE EXISTS"],
    };
  });
  return _.uniq(usedModules, module => module.key);
}

export default getUsedRegexModules;
