import React, {useState} from "react";
import PropTypes from "prop-types";
import _ from "underscore";

import Typography from "@material-ui/core/Typography";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import CTListItem from "./contract_type_list_item";
import CreateContractTypeDialog from "./create_contract_type_dialog";

const styles = {
  inner: {
    padding: "1em 2em",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
};

function ContractTypeList(props) {
  const [isCreateContractTypeDialogShown, setIsDialogShown] = useState(false);
  const onShowCreateDialog = () => setIsDialogShown(true);
  const onHideCreateDialog = () => setIsDialogShown(false);
  const listItemsSorted = _.sortBy(props.contractTypes, "name");
  return (
    <div className="app-page">
      <div className="app-toolbar">
        <Typography style={{flexGrow: 1}} color="textSecondary" variant="h6">
          Contract Types
        </Typography>
        <Fab size="small" color="primary" onClick={onShowCreateDialog}>
          <AddIcon />
        </Fab>
      </div>
      <div className="app-body" style={styles.inner}>
        <table style={styles.table}>
          <thead>
            <tr style={{height: "2rem"}}>
              <th style={{width: "2rem", textAlign: "left"}}>Id</th>
              <th style={{width: "15rem"}}>Name</th>
              <th style={{width: "15rem"}}>Creation Date</th>
              <th style={{width: "7rem"}}># Issuesets</th>
              <th style={{width: "4rem"}}>Visible</th>
            </tr>
          </thead>
          <tbody>
            {listItemsSorted.map((ct, index) => (
              <CTListItem
                key={ct.name}
                contractType={ct}
                showBottomSeparator={index !== listItemsSorted.length - 1}
              />
            ))}
          </tbody>
        </table>
      </div>
      <CreateContractTypeDialog
        contractTypes={props.contractTypes}
        onHideCreateDialog={onHideCreateDialog}
        createDialogShown={isCreateContractTypeDialogShown}
      />
    </div>
  );
}

ContractTypeList.propTypes = {
  contractTypes: PropTypes.array.isRequired,
};

export default ContractTypeList;
