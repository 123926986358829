import React from "react";
import requestor from "requestor";
import {isInitialised} from "utils/uninitialised";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import Permissioner from "utils/permissioner";
import setTitle from "utils/set_title";

import PromptDetailComponent from "../components/prompt_detail";

import updatePromptAction from "modules/prompts/actions/prompt_update";
import removePromptAction from "modules/prompts/actions/prompt_remove";

function PromptDetailContainer(props) {
  const shouldRenderContainer = isInitialised([props.prompts]);

  if (!shouldRenderContainer || !props.user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const permissioner = new Permissioner(props.user);

  if (!permissioner.hasPermission("update-prompt") || !permissioner.isAdmin()) {
    return permissioner.getNoPermissionMessage();
  }

  const {prompts, params} = props;
  const {organisationId} = props.params;
  const prompt = prompts.find(
    _prompt => _prompt.id === parseInt(params.promptId, 10),
  );
  function onPromptUpdate(data) {
    return props.updatePrompt(
      organisationId,
      prompt.id,
      {
        ...data,
        last_edited: prompt.last_edited,
      },
      prompt.last_edited,
    );
  }

  function redirectToPromptList() {
    props.dispatch(push(`/organisation/${organisationId}/prompt/list`));
  }

  function onPromptDelete() {
    props.removePrompt(organisationId, prompt.id, prompt.last_edited);
    redirectToPromptList();
  }

  setTitle("Prompt Detail");
  return (
    <PromptDetailComponent
      prompt={prompts.find(
        prompt => prompt.id === parseInt(params.promptId, 10),
      )}
      organisationId={organisationId}
      updatePrompt={onPromptUpdate}
      deletePrompt={onPromptDelete}
      redirectToPromptList={redirectToPromptList}
    />
  );
}

function select(state, props) {
  if (!state.roles) {
    return {};
  }
  return {
    prompts: state.prompts,
    params: props.params,
    router: state.router,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updatePrompt: updatePromptAction(requestor),
        removePrompt: removePromptAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(PromptDetailContainer);
