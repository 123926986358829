import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import {Th, Td} from "./index";
import styles from "./styles";

export default class Tr extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  render() {
    const props = _.omit(this.props, [
      ..._.keys(Tr.propTypes),
      "children",
      "style",
      "onMouseMove",
      "onMouseLeave",
      "groupId",
    ]);
    const style = {
      ...(() => {
        switch (this.props.parentType) {
          case "thead":
            return styles.headerRow;
          case "tbody":
            return styles.bodyRow;
        }
      })(),
      ...this.props.style,
      ...(this.state.isHovered && this.props.hoverStyle),
    };
    const children = React.Children.toArray(this.props.children)
      .filter(child => [Th, "th", Td, "td"].includes(child.type))
      .map(cell => {
        const cellProps = {};
        if ([Th, Td].includes(cell.type)) {
          cellProps.isRowHovered = this.state.isHovered;
        }
        if (
          this.props.linkPath &&
          cell.type === Td &&
          cell.props.linkPath === undefined
        ) {
          cellProps.linkPath = this.props.linkPath;
        }
        return React.cloneElement(cell, cellProps, cell.props.children);
      });
    return (
      <tr
        style={style}
        onMouseEnter={event => {
          if (_.isFunction(this.props.onMouseMove)) {
            this.props.onMouseMove(event);
          }
          if (!this.state.isHovered) {
            this.setState({isHovered: true});
          }
        }}
        onMouseLeave={event => {
          if (_.isFunction(this.props.onMouseLeave)) {
            this.props.onMouseLeave(event);
          }
          this.setState({isHovered: false});
        }}
        {...props}
      >
        {children}
      </tr>
    );
  }
}

Tr.defaultProps = {
  selectId: null,
  isSelected: false,
  hoverStyle: {},
};

Tr.propTypes = {
  selectId: PropTypes.number,
  isSelected: PropTypes.bool.isRequired,
  parentType: PropTypes.string,
  hoverStyle: PropTypes.object.isRequired,
  linkPath: PropTypes.string,
};
