import _ from "underscore";

/* eslint-disable no-unused-vars*/
import React from "react";
/* eslint-enable no-unused-vars*/
import PropTypes from "prop-types";

import TopicEditor from "common_components/topic_editor";

import sharedStart from "utils/shared_start";
import escapeRegex from "utils/escape_regex";

import TextField from "material-ui/TextField";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import {Toolbar, ToolbarTitle} from "material-ui/Toolbar";

const styles = {
  actions: {
    zIndex: 0,
  },
};

class BulkTopicEditor extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.topic = this.makeBulkEditorTopic();
    this.state = {
      name: this.topic.name,
      initialName: this.topic.name,
      showing: true,
    };
  }

  render() {
    const {categories, tags} = this.props;
    const actions = [
      <FlatButton
        key="buttonCancel"
        label="Cancel"
        secondary={true}
        onClick={this.props.onDismiss}
        style={styles.actions}
      />,
      <FlatButton
        key="buttonBulkEdit"
        label="Bulk Edit"
        primary={true}
        onClick={this.updateTopicsInBulkHandler}
        style={styles.actions}
      />,
    ];
    const toolbarText = `Bulk Editing ${this.props.topics.length} Topics`;
    return (
      <Dialog
        bodyStyle={{overflowY: "inherit"}}
        className="bulk-editor-dialog"
        open={true}
        actions={actions}
      >
        <Toolbar>
          <ToolbarTitle text={toolbarText} />
        </Toolbar>
        <div
          style={{
            height: "17rem",
          }}
        >
          <TextField
            className="topic-name"
            value={this.state.name}
            floatingLabelText={"Common Prefix"}
            onChange={this.changeTopicName}
            style={{width: "100%"}}
          />
          <TopicEditor
            ref={this.createBulkEditorRef}
            initialTopic={this.topic}
            topicCategories={categories}
            topicTags={tags}
            contractTypesById={this.props.contractTypesById}
          />
        </div>
      </Dialog>
    );
  }

  /* eslint-disable no-invalid-this */
  changeTopicName = event => this.setState({name: event.target.value});
  /* eslint-enable no-invalid-this */

  makeBulkEditorTopic() {
    const topics = this.props.topics;
    const commonCategoryId = this.getCommonCategoryId(topics);
    const commonTags = this.getCommonTags(topics);
    const commonNamePrefix = this.getCommonNamePrefix(topics);
    const commonContractTypes = this.getCommonContractTypes(topics);
    const commonIsSubstantive = this.getCommonIsSubstantive(topics);

    const topic = {
      id: -1,
      topiccategory_id: commonCategoryId,
      tags: commonTags,
      name: commonNamePrefix,
      contract_types: commonContractTypes,
      is_substantive: commonIsSubstantive,
    };
    return topic;
  }

  getCommonContractTypes(topics) {
    const contractTypes = topics.map(topic =>
      _.map(topic.contract_types, item => item.contract_type_id),
    );
    const commonContractTypes = _.intersection(...contractTypes);
    return topics[0].contract_types.filter(ct =>
      commonContractTypes.includes(ct.contract_type_id),
    );
  }

  getCommonCategoryId(topics) {
    const commonCategory = _.chain(topics)
      .map(topic => topic.topiccategory_id)
      .uniq()
      .value();

    return commonCategory.length === 1 ? commonCategory[0] : null;
  }

  getCommonTags(topics) {
    const tags = topics.map(topic => _.map(topic.tags, item => item.id));
    const commonTags = _.intersection(...tags);
    return commonTags.map(tagId => ({id: tagId}));
  }

  getCommonNamePrefix(topics) {
    const names = _.map(topics, item => item.name);
    return sharedStart(names);
  }

  getCommonIsSubstantive(topics) {
    const substantiveTopics = topics.filter(topic => topic.is_substantive);
    return topics.length === substantiveTopics.length;
  }

  /* eslint-disable no-invalid-this */
  updateTopicsInBulkHandler = () => {
    this.updateTopicsInBulk(this.props.topics, {
      ...this.bulkeditorRef.getValue(),
      name: this.state.name,
    });
  };

  updateTopicsInBulk(topics, newTopic) {
    const updates = this.calculateUpdates(topics, newTopic);
    if (
      _.find(
        updates,
        topic => Object.keys(_.omit(topic, ["id", "last_edited"])).length > 0,
      )
    ) {
      this.props.onTopicsUpdated(updates);
    }
    this.setState({showBulkEditorDialog: false});
  }
  /* eslint-enable no-invalid-this */

  calculateUpdates(topics, newTopic) {
    return _.object(
      topics.map(topic => {
        const data = {};
        if (newTopic.name !== this.state.initialName) {
          const name =
            topic.name &&
            topic.name.replace(
              new RegExp(`^${escapeRegex(this.state.initialName)}`),
              newTopic.name,
            );
          data.name = name;
        }

        if (newTopic.topiccategory_name) {
          data.topiccategory_name = newTopic.topiccategory_name;
        } else if (topic.topiccategory_id !== newTopic.topiccategory_id) {
          data.topiccategory_id = newTopic.topiccategory_id;
        }
        // tags processing
        const currentTagIds = _.map(topic.tags, item => item.id);
        const newTagIds = _.map(newTopic.tags, item => item.id);
        const newTags = (newTopic.tags || []).filter(
          tag => !_.contains(currentTagIds, tag.id),
        );

        const removedTags = this.getCommonTags(topics).filter(
          tag => !_.contains(newTagIds, tag.id),
        );
        if (newTags.length > 0) {
          data.new_tags = newTags;
        }
        if (removedTags.length > 0) {
          data.removed_tags = _.map(removedTags, item => item.id);
        }
        // contract types processing
        const currentContractTypeIds = _.map(
          topic.contract_types,
          item => item.contract_type_id,
        );
        const newContractTypeIds = _.map(
          newTopic.contract_types,
          item => item.contract_type_id,
        );
        const commonContractTypeIds = this.getCommonContractTypes(
          this.props.topics,
        ).map(ct => ct.contract_type_id);
        const removedContractTypeIds = _.difference(
          commonContractTypeIds,
          newContractTypeIds,
        );

        const newContractTypes = (newTopic.contract_types || []).filter(
          ct => !_.contains(currentContractTypeIds, ct.contract_type_id),
        );

        const removedContractTypes = (topic.contract_types || [])
          .filter(ct => removedContractTypeIds.includes(ct.contract_type_id))
          .map(filteredCt => ({
            ...filteredCt,
            name: this.props.contractTypesById[filteredCt.contract_type_id]
              .name,
          }));
        if (newContractTypes.length > 0) {
          data.added_contract_types = newContractTypes;
        }
        if (removedContractTypes.length > 0) {
          data.removed_contract_types = removedContractTypes;
        }
        // is_substantive processing
        if (topic.is_substantive !== newTopic.is_substantive) {
          data.is_substantive = newTopic.is_substantive;
        }

        data.last_edited = topic.last_edited;
        return [topic.id, data];
      }),
    );
  }

  createBulkEditorRef = node => (this.bulkeditorRef = node);
}

BulkTopicEditor.propTypes = {
  topic: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    topiccategory_id: PropTypes.number.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      }),
    ),
  }),
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onTopicsUpdated: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default BulkTopicEditor;
