import React from "react";
import _ from "underscore";
import Handlebars from "handlebars";
import logger from "common/utils/logger";

import registerHandlebarsHelpers from "common/utils/handlebars_helpers/register";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

import getIssueClauses from "common/utils/issues/reason/get_issue_clauses";
import calculateClauseReferences from "common/utils/issues/reason/calculate_clause_references";

registerHandlebarsHelpers();

export class EmailContents extends React.Component {
  render() {
    return (
      <div style={{fontWeight: 100}}>
        {new Date().toLocaleDateString()}
        <p>Dear sir / madam,</p>
        <p>
          We have reviewed contract "{this.props.document.name}" and would like
          to draw your attention to the following issues:
        </p>
        <ol>{this.getIssuesText()}</ol>
        <p>Yours sincerely</p>
      </div>
    );
  }

  getIssuesText() {
    const clauseIssues = this.props.documentIssues
      .filter(issue => issue.email_template)
      .map(issue => this.getIssueData(issue));

    return _.sortBy(clauseIssues, clauseIssue => clauseIssue.sortReference).map(
      (clauseIssue, index) => this.renderIssue(clauseIssue, index),
    );
  }

  getIssueData(issue) {
    const {documentClauses} = this.props;
    const clauses = getIssueClauses(issue, documentClauses);
    const clauseReferences = calculateClauseReferences(clauses);
    const sortReference =
      clauses.length > 0
        ? _.sortBy(clauses, clause => clause.clausepart_sort_reference)[0]
            .clausepart_sort_reference
        : "zzz";
    return {clauses, clauseReferences, sortReference, issue};
  }

  renderIssue(clauseIssue, index) {
    const {issue, clauseReferences, clauses} = clauseIssue;
    const {documentClauseparts} = this.props;
    /* eslint-disable no-console */
    logger.verbose("clauseReferences:", clauseReferences);
    logger.verbose("issue:", issue);
    const template = Handlebars.compile(issue.email_template);
    /* eslint-enable no-console */
    return (
      <li style={{marginBottom: "0.5em"}} key={index}>
        {template({
          issue,
          clause: clauses[0],
          clauses,
          clauseReferences,
          reasonData: documentClauseparts,
        })}
      </li>
    );
  }
}

export default class EmailDialog extends React.Component {
  render() {
    const actions = [
      <FlatButton
        key="email-dialog-close-button"
        label="Close"
        primary={false}
        onClick={this.props.handleClose}
      />,
      <FlatButton
        label="Send Email"
        key="email-dialog-send-button"
        primary={true}
        onClick={this.props.handleClose}
      />,
    ];

    return (
      <Dialog
        title="Client Email"
        actions={actions}
        modal={false}
        open={true}
        onRequestClose={this.props.handleClose}
        autoScrollBodyContent={true}
      >
        <EmailContents {...this.props} />
      </Dialog>
    );
  }
}
