/* eslint-disable one-var */
/* eslint-disable sort-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */

// Get the full word the cursor is over regardless of span breaks
export default function getFullWord(event, document = window.document) {
  let range, textNode, offset;

  // Internet Explorer
  // if (document.body.createTextRange) {
  //   try {
  //     range = document.body.createTextRange();
  //     range.moveToPoint(event.clientX, event.clientY);
  //     range.select();
  //     range = getTextRangeBoundaryPosition(range, true);
  //
  //     textNode = range.node;
  //     offset = range.offset;
  //   } catch (ex) {
  //     return {range: null, textNode: null, offset: null}; // Sigh, IE
  //   }
  //
  // } else

  // Firefox, Safari
  // REF: https://developer.mozilla.org/en-US/docs/Web/API/Document/caretPositionFromPoint
  if (event && event.clientX) {
    if (document.caretPositionFromPoint) {
      range = document.caretPositionFromPoint(event.clientX, event.clientY);
      textNode = range.offsetNode;
      offset = range.offset;

      // Chrome
      // REF: https://developer.mozilla.org/en-US/docs/Web/API/document/caretRangeFromPoint
    } else if (document.caretRangeFromPoint) {
      range = document.caretRangeFromPoint(event.clientX, event.clientY);
      textNode = range.startContainer;
      offset = range.startOffset;
    }
  }
  return {range, textNode, offset};
}

/*
// REF: http://stackoverflow.com/questions/3127369/how-to-get-selected-textnode-in-contenteditable-div-in-ie
function getChildIndex (_node) {
  let node = _node;
  let index = 0;
  while ((node = node.previousSibling)) {
    index += 1;
  }
  return index;
}

// All this code just to make this work with IE, OTL
// REF: http://stackoverflow.com/questions/3127369/how-to-get-selected-textnode-in-contenteditable-div-in-ie
function getTextRangeBoundaryPosition (textRange, isStart) {
  const workingRange = textRange.duplicate();
  workingRange.collapse(isStart);
  const containerElement = workingRange.parentElement();
  const workingNode = document.createElement("span");
  let comparison;
  const workingComparisonType = isStart ? "StartToStart" : "StartToEnd";

  let boundaryPosition;

  // Move the working range through the container's children, starting at
  // the end and working backwards, until the working range reaches or goes
  // past the boundary we're interested in
  do {
    containerElement.insertBefore(workingNode, workingNode.previousSibling);
    workingRange.moveToElementText(workingNode);
  } while ((comparison = workingRange.compareEndPoints(
    workingComparisonType, textRange)) > 0 && workingNode.previousSibling);

  // We've now reached or gone past the boundary of the text range we're
  // interested in so have identified the node we want
  const boundaryNode = workingNode.nextSibling;
  if (comparison === -1 && boundaryNode) {
    // This must be a data node (text, comment, cdata) since we've overshot.
    // The working range is collapsed at the start of the node containing
    // the text range's boundary, so we move the end of the working range
    // to the boundary point and measure the length of its text to get
    // the boundary's offset within the node
    workingRange.setEndPoint(isStart ? "EndToStart" : "EndToEnd", textRange);

    boundaryPosition = {
      node: boundaryNode,
      offset: workingRange.text.length
    };
  } else {
    // We've hit the boundary exactly, so this must be an element
    boundaryPosition = {
      node: containerElement,
      offset: getChildIndex(workingNode)
    };
  }

  // Clean up
  workingNode.parentNode.removeChild(workingNode);

  return boundaryPosition;
}
*/
