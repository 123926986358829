import ActionTypes, {ProjectActionTypes} from "../constants/action_types";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";

type Accesstag = {id: number};

export type RemoveAccesstagAction = {
  type: ProjectActionTypes["PROJECT_REMOVE_ACCESS_TAG"]["SUCCESS"];
  payload: Accesstag;
};

const removeAccesstag = requestor => (
  organisationId: number,
  projectId: number,
  accesstag: Accesstag,
) =>
  requestor
    .delete(
      `${basePath}/organisation/${organisationId}/project/${projectId}/accesstag/${accesstag.id}`,
      {withCredentials: true},
    )
    .then(() => accesstag);

export default function actionCreator(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PROJECT_REMOVE_ACCESS_TAG,
    removeAccesstag(requestor),
  );
}
