import _ from "lodash";
import React, {useState, useEffect, FC} from "react";
import {TextField, Container} from "@material-ui/core";
import PropertiesEditor from "./properties_editor";
import ContainedTextField from "../../contained_text_field";
import {Schema, SchemaProperty} from "common/flowmaster/types/schema";
import {
  ActionDefinition,
  WorkflowTask,
} from "common/flowmaster/types/task_config";
import {
  Prompt,
  WorkflowContext,
  WorkflowInputs,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

interface SchemaEditorProps {
  argValue: {value: Schema};
  onUpdateItem: (value: {value: Schema}) => void;
  priorTasks: WorkflowTask[];
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  organisationId: OrganisationId;
}

const initialSchema: Schema = {
  name: "",
  description: "",
  parameters: {
    type: "object",
    properties: {},
    required: [],
  },
};

const SchemaEditor: FC<SchemaEditorProps> = ({
  argValue,
  onUpdateItem,
  priorTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  organisationId,
}) => {
  const [schema, setSchema] = useState<Schema>(argValue.value);
  const [showPreview, setShowPreview] = useState(false);
  const [schemaNameError, setSchemaNameError] = useState<string>("");

  useEffect(() => {
    if (!_.isEqual(schema, argValue.value)) {
      onUpdateItem({value: schema});
    }
  }, [schema, argValue]);

  const handlePropertiesChange = (
    properties: Record<string, SchemaProperty>,
  ) => {
    const required = Object.keys(properties);
    setSchema(prevSchema => ({
      ...prevSchema,
      parameters: {
        ...prevSchema.parameters,
        properties,
        required,
      },
    }));
  };

  const handleInputChange = (key: string, value: string) => {
    if (key === "name" && !/^[a-zA-Z0-9_-]+$/.test(value)) {
      setSchemaNameError("Schema name contains invalid characters.");
      return;
    }
    setSchemaNameError("");
    setSchema({...schema, [key]: value});
  };

  return (
    <Container maxWidth="xl" style={{padding: "10px"}}>
      <div style={{marginBottom: "5px"}}>
        <ContainedTextField
          errorMessage={schemaNameError}
          label="Schema function name"
          initialValue={schema.name}
          onUpdate={name => handleInputChange("name", name)}
        />
      </div>
      <div style={{marginBottom: "5px"}}>
        <ContainedTextField
          label="Description"
          initialValue={schema.description}
          onUpdate={description =>
            handleInputChange("description", description)
          }
        />
      </div>
      <PropertiesEditor
        properties={schema.parameters.properties}
        onChange={handlePropertiesChange}
        priorTasks={priorTasks}
        context={context}
        inputs={inputs}
        prompts={prompts}
        actionDefinitions={actionDefinitions}
        organisationId={organisationId}
      />
      <fieldset style={{borderTop: "1px dashed #777"}}>
        <legend
          style={{
            border: "1px dashed #777",
            borderRadius: "0.5em",
            padding: "0.5em",
            cursor: "pointer",
          }}
          onClick={() => setShowPreview(!showPreview)}
        >
          {showPreview ? "Hide " : "Show "}Preview
        </legend>
        {showPreview && (
          <TextField
            fullWidth
            multiline
            variant="outlined"
            value={JSON.stringify(schema, null, 2)}
            InputProps={{
              readOnly: true,
              style: {
                fontFamily: "monospace",
                whiteSpace: "pre-wrap",
                overflowX: "auto",
              },
            }}
          />
        )}
      </fieldset>
    </Container>
  );
};

const ActionArg = {
  Component: SchemaEditor,
  default: {value: initialSchema},
};

export default ActionArg;
