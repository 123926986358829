import React from "react";
import AppBar from "material-ui/AppBar";

class Header extends React.Component {
  render() {
    return (
      <AppBar
        title={
          <span>
            LEXICAL <span style={{color: "#bbdefb"}}>LABS</span>
          </span>
        }
        iconElementLeft={<span />}
        iconElementRight={this.getHeaderContent()}
        iconStyleRight={{
          marginTop: 0,
        }}
        style={{
          backgroundColor: "#2096f3",
        }}
        titleStyle={{
          fontFamily: "Raleway",
          fontWeight: 800,
        }}
      />
    );
  }

  getHeaderContent() {
    return this.props.headerContent || <span />;
  }
}

export default Header;
