import _ from "underscore";
import requestor from "requestor";
import React, {useState, useEffect} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import LlmRunListComponent from "../components/llm_run_list";

import {parseQuery, buildQuery} from "utils/uri";

import llmRunsFetch, {
  GetLlmRunsAction,
} from "modules/flowmaster/actions/llm_runs_fetch";
import llmRunsClear from "modules/flowmaster/actions/llm_runs_clear";

import {RouterState, InjectedRouter} from "react-router/lib/Router";
import {
  LlmRunInfo,
  LlmRunFilters,
  llmRunFilterKeys,
  llmRunNumberFilterKeys,
  llmRunStringArrayFilterKeys,
} from "common/flowmaster/types/llm_run";
import {User} from "common/types/user";
import {getLlmRunQueryParams} from "../utils/get_llm_run_query_params";

type Props = {
  llmRuns: LlmRunInfo[];
  user: User;
  router: RouterState & InjectedRouter;
  location: Location;
  params: {
    organisationId: string;
  };
  llmRunsFetch: GetLlmRunsAction["action"];
  dispatch: (args: unknown) => unknown;
};

function getQueryParams(router): LlmRunFilters {
  const params = parseQuery(router.location.search);
  return {
    ...(Object.fromEntries(
      llmRunFilterKeys.map(key => [key, null]),
    ) as LlmRunFilters),
    ..._.chain(params)
      .pick(llmRunNumberFilterKeys)
      .mapObject(item => parseInt(item, 10))
      .value(),
    ..._.chain(params)
      .pick(llmRunStringArrayFilterKeys)
      .mapObject(item => item)
      .value(),
    from: params.from || null,
    to: params.to || null,
  };
}

function LlmRunListContainer(props: Props) {
  const [maxRuns, setMaxRuns] = useState(sessionStorage.maxLlmRuns ?? 100);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await props.llmRunsFetch(
        parseInt(props.params.organisationId, 10),
        getQueryParams(props.router),
        maxRuns,
      );
      setLoading(false);
    };

    fetchData();

    return () => {
      setLoading(false);
    };
  }, [JSON.stringify(getQueryParams(props.router)), maxRuns]);

  const updateMaxRuns = value => {
    sessionStorage.maxLlmRuns = value;
    setMaxRuns(value);
  };

  const updateUrlParams = (key, value) => {
    const {pathname, search} = props.location;
    const params = parseQuery(search);
    const newSearch = buildQuery(
      value ? {...params, [key]: value} : _.omit(params, key),
    );
    if (search !== newSearch) {
      props.dispatch(push(pathname + newSearch));
    }
  };

  const shouldRenderContainer = isInitialised([props.llmRuns, props.user]);
  if (!shouldRenderContainer || !props.user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const permissioner = new Permissioner(props.user);
  if (
    !(permissioner.hasPermission("get-workflows") || permissioner.isAdmin())
  ) {
    return permissioner.getNoPermissionMessage();
  }

  setTitle("LLM Run List");
  const organisationId = parseInt(props.router.params.organisationId, 10);
  const llmRunValues = getLlmRunQueryParams(getQueryParams(props.router));

  return (
    <LlmRunListComponent
      isLoading={isLoading}
      organisationId={organisationId}
      llmRuns={props.llmRuns}
      values={llmRunValues}
      updateValue={(key, value) => {
        updateUrlParams(key, value);
      }}
      goToRun={id => {
        props.router.push(
          `/organisation/${organisationId}/llm_run/${id}/detail`,
        );
      }}
      maxRuns={maxRuns}
      setMaxRuns={updateMaxRuns}
    />
  );
}

function select(state, props) {
  return {
    params: props.params,
    organisation: state.organisation,
    user: state.user,
    llmRuns: state.llmRuns,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        llmRunsFetch: llmRunsFetch(requestor),
        llmRunsClear,
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(LlmRunListContainer);
