import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import OverridablePortalsContext from "./context";

export default class OverridablePortals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      namedList: {},
    };
  }

  registerOverridablePortal = (name, instance) => {
    const namedList = {...this.state.namedList};
    if (!_.has(namedList, name)) {
      namedList[name] = [];
    }
    if (!namedList[name].includes(instance)) {
      namedList[name].push(instance);
      this.setState({namedList});
    }
  };

  unregisterOverridablePortal = (name, instance) => {
    const namedList = {...this.state.namedList};
    if (_.has(namedList, name) && namedList[name].includes(instance)) {
      const list = namedList[name];
      list.splice(list.indexOf(instance), 1);
      if (!list.length) {
        delete namedList[name];
      }
      this.setState({namedList});
    }
  };

  isPortalOverridden = name => {
    const list = this.state.namedList[name] || [];
    return list.find(element => element.props.overrideRole !== "overridable");
  };

  renderOverridablePortal = name => {
    const element = this.props[name];
    if (!React.isValidElement(element) || this.isPortalOverridden(name)) {
      return null;
    }
    return React.cloneElement(element, {overrideRole: "overridable"});
  };

  render() {
    return (
      <OverridablePortalsContext.Provider
        value={{
          registerOverridablePortal: this.registerOverridablePortal,
          unregisterOverridablePortal: this.unregisterOverridablePortal,
        }}
      >
        {this.props.children(this)}
      </OverridablePortalsContext.Provider>
    );
  }
}

OverridablePortals.propTypes = {
  children: PropTypes.func.isRequired,
};
