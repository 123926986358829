import React from "react";
import {Link} from "react-router";
import _ from "lodash";

import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import blue from "@material-ui/core/colors/blue";

import {Table, Tr, Th, Td} from "common_components/table";
import ProjectListToolbar from "./toolbar";
import IconMenu from "common_components/icon_menu";
import HotProjectChip from "common_components/project/hot_project_chip";
import ArchivedProjectChip from "common_components/project/archived_project_chip";

import localStorage from "utils/local_storage";
import Permissioner from "utils/permissioner";
import getSegmentedProjectName from "utils/projects/get_segmented_project_name";
import buildProjectsTree from "utils/projects/build_projects_tree";
import forEachProjectsTreeItem from "utils/projects/for_each_projects_tree_item";
import byId from "common/utils/by_id";
import issuesetUtils from "common/utils/issues/issueset_utils";

const levelWidth = 20;
const ADMIN_ONLY_COLOR = "rgba(33, 150, 243, .9)";
const ADMIN_ONLY_COLOR_LIGHT = "rgba(33, 150, 243, .75)";

const styles = {
  rowHover: {
    backgroundColor: "#efeff1",
  },
  nameParentPath: {
    color: "#798087",
    fontSize: 12,
  },
  segmentContainer: {
    display: "flex",
    alignItems: "center",
  },
  segmentName: {
    fontWeight: 600,
    color: "#9aa1a9",
    cursor: "pointer",
  },
  getLevelPadding: level => ({
    paddingLeft: level * levelWidth,
  }),
  getProjectName: (isHovered = false) => ({
    fontWeight: 600,
    color: isHovered ? blue[500] : "#424242",
    textDecoration: isHovered ? "underline" : "none",
  }),
  getLevelDot: level => ({
    position: "absolute",
    top: "50%",
    marginTop: -2,
    marginLeft: -12,
    left: level * levelWidth,
    width: 4,
    height: 4,
    borderRadius: "50%",
    backgroundColor: "#9aa1a9",
  }),
};

export default class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arePrivateProjectsShown: localStorage.getItem("arePrivateProjectsShown")
        ? localStorage.getItem("arePrivateProjectsShown") === "true"
        : true,
      areArchivedProjectsShown: false,
      sortColumnIndex: 0,
      sortDirection: "asc",
      collapsedPaths: [],
      isCollapsiblePaths: [],
      filteringText: "",
    };
    this.issuesetsById = byId(
      issuesetUtils.getIssuesetsPresentInContractTypes(props.contractTypes),
    );
  }

  onSwitchArePrivateProjectsShown = () => {
    const newValue = !this.state.arePrivateProjectsShown;
    localStorage.setItem("arePrivateProjectsShown", newValue);
    this.setState({arePrivateProjectsShown: newValue});
  };

  onSwitchAreArchivedProjectsShown = () =>
    this.setState(prevState => ({
      areArchivedProjectsShown: !prevState.areArchivedProjectsShown,
    }));

  getProjectBasePath = projectId => {
    return `/organisation/${this.props.organisationId}/project/${projectId}`;
  };

  getProjectDocumentListPath = projectId => {
    const basePath = this.getProjectBasePath(projectId);
    return `${basePath}/document/list`;
  };

  setFilteringText = filteringText => this.setState({filteringText});

  getProjectsToShown = projects => {
    return projects.filter(
      project =>
        (!project.is_private && !project.is_archived) ||
        (project.is_private && this.state.arePrivateProjectsShown) ||
        (project.is_archived && this.state.areArchivedProjectsShown),
    );
  };

  componentDidMount() {
    this.setCollapsiblePaths();
  }

  render() {
    const projectsToShow = this.getProjectsToShown(this.props.projects);

    return (
      <React.Fragment>
        <ProjectListToolbar
          collapseProjectsPaths={this.collapseProjectsPaths}
          expandProjectsPaths={this.expandProjectsPaths}
          projects={projectsToShow}
          user={this.props.user}
          hasAddProjectPermission={hasPermission(
            "add-project",
            this.props.user,
          )}
          createNewProject={this.props.createNewProject}
          onSwitchArePrivateProjectsShown={this.onSwitchArePrivateProjectsShown}
          arePrivateProjectsShown={this.state.arePrivateProjectsShown}
          filteringText={this.props.filteringText}
          setFilteringText={this.setFilteringText}
          areArchivedProjectsShown={this.state.areArchivedProjectsShown}
          onSwitchAreArchivedProjectsShown={
            this.onSwitchAreArchivedProjectsShown
          }
        />
        <div className="app-container">{this.renderTable(projectsToShow)}</div>
      </React.Fragment>
    );
  }

  renderTable(projectsToShow) {
    const {user} = this.props;
    const filteredProjects = this.state.filteringText
      ? projectsToShow.filter(
          project =>
            project.name &&
            project.name.match(new RegExp(this.state.filteringText, "i")),
        )
      : projectsToShow;
    return (
      <Table
        hasStickyHeader={true}
        onSort={(sortColumnIndex, sortDirection) => {
          this.setState({sortColumnIndex, sortDirection});
        }}
      >
        <thead>
          <tr>
            <Th style={{width: "38%"}}>Project Name</Th>
            {user.is_admin && <Th style={{width: "22%"}}>Contract Type</Th>}
            <Th style={{width: "25%"}}>Checklists</Th>
            <Th style={{width: "14%"}}>Documents</Th>
            <Th style={{width: "1%"}} />
          </tr>
        </thead>
        <tbody>
          {this.state.sortColumnIndex === 0
            ? this.renderSplitProjectRows(filteredProjects)
            : this.renderProjectRows(filteredProjects)}
        </tbody>
      </Table>
    );
  }

  renderSplitProjectRows(projectsToShow) {
    const isAdmin = this.props.user.is_admin;
    const tree = buildProjectsTree(
      projectsToShow,
      this.state.sortDirection === "desc",
    );
    const items = [];
    forEachProjectsTreeItem(
      tree,
      (itemData, itemName, dontAddChildren) => {
        const isCollapsible = _.keys(itemData.childProjects).length > 0;
        const isCollapsed =
          isCollapsible && this.state.collapsedPaths.includes(itemData.path);
        if (!itemData.project || !itemData.project.admin_only || isAdmin) {
          items.push({
            name: itemName,
            project: itemData.project,
            level: itemData.level,
            path: itemData.path,
            isCollapsible,
            isCollapsed,
          });
          if (isCollapsed) {
            dontAddChildren();
          }
        }
      },
      isAdmin,
    );
    return items.map((item, index) => {
      const rowProps = {
        ...(item.project && {
          hoverStyle: styles.rowHover,
          linkPath: this.getProjectDocumentListPath(item.project.id),
          style: {
            ...(item.project.admin_only ? {color: ADMIN_ONLY_COLOR_LIGHT} : {}),
            ...(item.project.is_archived ? {opacity: 0.5} : {}),
          },
        }),
      };
      return (
        <Tr key={index} {...rowProps}>
          {item.project
            ? this.renderSplitProjectCells(item)
            : this.renderProjectSegmentCell(item)}
        </Tr>
      );
    });
  }

  renderProjectRows(projectsToShow) {
    const {user} = this.props;
    const filteredProjects = user.is_admin
      ? projectsToShow
      : projectsToShow.filter(({admin_only: adminOnly}) => !adminOnly);
    return filteredProjects.map(project => {
      return (
        <Tr
          key={project.id}
          hoverStyle={styles.rowHover}
          linkPath={this.getProjectDocumentListPath(project.id)}
          style={{
            ...(project.admin_only ? {color: ADMIN_ONLY_COLOR_LIGHT} : {}),
            ...(project.is_archived ? {opacity: 0.5} : {}),
          }}
        >
          {this.renderProjectCells(project)}
        </Tr>
      );
    });
  }

  renderSplitProjectCells(item) {
    const {lastSegment} = getSegmentedProjectName(item.project.name);
    return [
      <Td
        key="project-name"
        sortText=""
        containerStyle={{position: "relative"}}
        handleChildren={(children, thisTd) => {
          return (
            <div
              style={{
                ...styles.getLevelPadding(item.level),
                ...styles.getProjectName(thisTd.props.isRowHovered),
                ...(item.project.admin_only ? {color: ADMIN_ONLY_COLOR} : {}),
              }}
            >
              {children}
            </div>
          );
        }}
      >
        {this.renderLevelDot(item)}
        <div style={{display: "flex", alignItems: "center"}}>
          {lastSegment}
          {this.hasPermissionToViewHotColdProjectChip() &&
            item.project.is_hot && <HotProjectChip />}
          {item.project.is_archived && <ArchivedProjectChip />}
        </div>
        {this.renderCollapseButton(item)}
      </Td>,
      ...this.renderCommonProjectCells(item.project),
    ];
  }

  hasPermissionToViewHotColdProjectChip = () =>
    this.props.organisation &&
    this.props.organisation.is_central_admin &&
    (this.props.user.is_admin ||
      hasPermission("can-view-hot-cold", this.props.user));

  renderProjectCells(project) {
    const {parentPath, lastSegment} = getSegmentedProjectName(project.name);
    return [
      <Td
        key="project-name"
        handleChildren={(children, thisTd) => {
          return (
            <React.Fragment>
              {children[0]}
              <div
                style={{
                  ...styles.getProjectName(thisTd.props.isRowHovered),
                  ...(project.admin_only ? {color: ADMIN_ONLY_COLOR} : {}),
                }}
              >
                {children[1]}
              </div>
            </React.Fragment>
          );
        }}
      >
        <div style={styles.nameParentPath}>{parentPath}</div>
        {lastSegment}
      </Td>,
      ...this.renderCommonProjectCells(project),
    ];
  }

  renderCommonProjectCells(project) {
    const {user} = this.props;
    const projectIssuesets = getProjectIssuesets(
      project.issuesets,
      this.issuesetsById,
    );
    const basePath = this.getProjectBasePath(project.id);
    return [
      user.is_admin && (
        <Td key="contract-type">
          {
            (
              this.props.contractTypes.find(
                ct =>
                  project.default_contract_type &&
                  ct.id === project.default_contract_type.id,
              ) || {name: "N/A"}
            ).name
          }
        </Td>
      ),
      <Td key="issuesets">
        {projectIssuesets.map(issueset => (
          <div key={issueset.id}>{issueset.name}</div>
        ))}
      </Td>,
      <Td key="documents">
        {project.documents_count !== undefined
          ? project.documents_count
          : "N/A"}
      </Td>,
      <Td key="dropdownmenu" linkPath={null} containerStyle={{paddingLeft: 0}}>
        <IconMenu
          buttonSize="small"
          iconStyle={project.admin_only ? {color: ADMIN_ONLY_COLOR_LIGHT} : {}}
          menuItems={[
            <MenuItem
              disabled={!hasPermission("update-project", this.props.user)}
              key="project-settings-link"
            >
              <Link
                to={{pathname: `${basePath}/detail`}}
                style={{
                  textDecoration: "inherit",
                  color: "inherit",
                }}
                onlyActiveOnIndex
              >
                Settings
              </Link>
            </MenuItem>,
          ]}
        />
      </Td>,
    ].filter(item => item);
  }

  renderProjectSegmentCell(item) {
    return [
      <Td
        key="segment"
        colSpan={5}
        sortText=""
        containerStyle={{position: "relative"}}
      >
        <div
          style={{
            ...styles.segmentContainer,
            ...styles.getLevelPadding(item.level),
          }}
        >
          <div
            style={styles.segmentName}
            onClick={() => this.toggleCollapsing(item)}
          >
            {item.name}
          </div>
          {this.renderCollapseButton(item)}
        </div>
      </Td>,
    ];
  }

  renderLevelDot(item) {
    if (item.level === 0) {
      return null;
    }
    return <div style={styles.getLevelDot(item.level)} />;
  }

  renderCollapseButton(item) {
    if (!item.isCollapsible) {
      return null;
    }
    const Icon = !item.isCollapsed ? ExpandMoreIcon : ChevronRightIcon;
    return (
      <IconButton
        size="small"
        onClick={event => {
          event.preventDefault();
          this.toggleCollapsing(item);
        }}
      >
        <Icon />
      </IconButton>
    );
  }

  toggleCollapsing = item => {
    if (!item.isCollapsed) {
      this.setState({
        collapsedPaths: [...this.state.collapsedPaths, item.path],
      });
    } else {
      const collapsedPaths = [...this.state.collapsedPaths];
      const index = collapsedPaths.indexOf(item.path);
      if (index > -1) {
        collapsedPaths.splice(index, 1);
        this.setState({collapsedPaths});
      }
    }
  };

  setCollapsiblePaths = () => {
    const canCollapsing = [];
    Object.values(
      buildProjectsTree(
        this.getProjectsToShown(this.props.projects),
        this.state.sortDirection === "desc",
      ),
    ).forEach(item => {
      if (Object.keys(item.childProjects).length) {
        canCollapsing.push(item.path);
      }
    });
    this.setState({
      isCollapsiblePaths: canCollapsing,
    });
  };

  collapseProjectsPaths = () => {
    this.setState({
      collapsedPaths: this.state.isCollapsiblePaths,
    });
  };

  expandProjectsPaths = () => {
    this.setState({
      collapsedPaths: [],
    });
  };
}

function hasPermission(permission, user) {
  return new Permissioner(user).hasPermission(permission);
}

function getProjectIssuesets(projectIssuesets = [], issuesetsById) {
  return projectIssuesets.reduce((accum, issuesetId) => {
    const issueset = issuesetsById[issuesetId];
    let issuesetName = "N/A";
    if (issueset) {
      issuesetName = issueset.remote_client_name
        ? `${issueset.name} - ${issueset.remote_client_name}`
        : issueset.name;
    }
    accum.push({id: issuesetId, name: issuesetName});
    return accum;
  }, []);
}
