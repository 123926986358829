/* eslint-disable no-unused-vars */
import React from "react";
/* eslint-enable no-unused-vars */

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";

import {push} from "react-router-redux";

import requestor from "requestor";

import updateTaskAction from "modules/tasks/actions/task_update";
import updateTaskRoleAction from "modules/tasks/actions/task_role_update";
import createCommentAction from "modules/tasks/actions/task_comment_create";
import taskDeleteAction from "modules/tasks/actions/task_delete";

import Task from "../components/task";
import UpdatingComponent from "utils/updating_component";

class TaskDetail extends UpdatingComponent {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return this.permissioner.hasPermission("add-task");
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.task,
      this.props.parties,
      this.props.taskroles,
      this.props.user,
    ]);
  }

  renderComponent() {
    return <div>{this.renderUpdatingComponent()}</div>;
  }

  renderUpdatingComponent() {
    setTitle(`${this.props.task.name}`);
    return (
      <Task
        onClose={this.closeTask}
        onUpdateTask={this.updateTask}
        onDeleteTask={this.deleteTask}
        onUpdatePartyRole={this.updateTaskPartyRole}
        onPartyChecked={this.updateTaskPartyRoleState}
        onChangeCustomValue={this.updateTaskCustomValue}
        onCommentCreated={this.createComment}
        {...this.props}
      />
    );
  }

  getUpdatingProp() {
    return this.props.task;
  }

  updateHandler(data) {
    const {organisationId, taskId} = this.props.params;
    this.props.updateTaskAction(organisationId, taskId, {
      last_edited: this.props.task.last_edited,
      ...data,
    });
  }

  /* eslint-disable no-invalid-this */
  closeTask = () => {
    const {organisationId, projectId} = this.props.params;

    this.props.dispatch(
      push(`/organisation/${organisationId}/project/${projectId}/task`),
    );
  };

  updateTask = data => {
    this.performUpdate(data);
  };

  updateTaskPartyRole = (roleId, partyId) => {
    const {organisationId, taskId} = this.props.params;
    const payload = this.getPayload(roleId);
    switch (payload.type) {
      case "single":
        payload.party_id = partyId;
        break;
      case "multi":
      case "multi-check":
        payload.party_data = partyId.map(id => ({id}));
        break;
    }
    this.props.updateTaskRoleAction(organisationId, taskId, roleId, payload);
  };

  updateTaskPartyRoleState = (roleId, partyId, data) => {
    const {organisationId, taskId} = this.props.params;
    const payload = this.getPayload(roleId);
    payload.party_data = this.getTaskRole(roleId).party_data.map(party => {
      if (party.id === partyId) {
        return {...party, value: data};
      }
      return party;
    });
    this.props.updateTaskRoleAction(organisationId, taskId, roleId, payload);
  };

  deleteTask = lastEdited => {
    const {organisationId, taskId} = this.props.params;
    this.props.taskDeleteAction(
      organisationId,
      parseInt(taskId, 10),
      lastEdited,
    );
  };
  /* eslint-enable no-invalid-this */

  getPayload(roleId) {
    const role = this.getRole(roleId);
    return {
      last_edited: this.props.task.last_edited,
      type: role.type,
    };
  }

  getRole(roleId) {
    return this.props.taskroles.find(role => role.id === roleId);
  }

  getTaskRole(roleId) {
    return this.props.task.task_roles.find(role => role.id === roleId);
  }

  /* eslint-disable no-invalid-this */
  updateTaskCustomValue = (field, value) => {
    const {organisationId, taskId} = this.props.params;
    const {task} = this.props;
    const newCustomValue = [];
    newCustomValue[field] = value;
    const payload = {
      last_edited: task.last_edited,
      custom_values: {...task.custom_values, ...newCustomValue},
    };
    this.props.updateTaskAction(organisationId, taskId, payload);
  };

  createComment = text => {
    const {organisationId, taskId} = this.props.params;
    const {username} = this.props.user;
    this.props.createCommentAction(organisationId, taskId, text, username);
  };
  /* eslint-enable no-invalid-this */
}

function select(state) {
  return {
    task: state.task,
    tasks: state.projectTasks,
    parties: state.project && state.project.parties,
    taskroles: state.taskroles,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateTaskAction: updateTaskAction(requestor),
        updateTaskRoleAction: updateTaskRoleAction(requestor),
        createCommentAction: createCommentAction(requestor),
        taskDeleteAction: taskDeleteAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(TaskDetail);
export const Component = TaskDetail;
