import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

const ConfirmationDialog = ({
  dialogData,
  close,
}) => {
  return (
    <Dialog open={dialogData.open} onClose={close}>
      <DialogTitle>{dialogData.title}</DialogTitle>
      <DialogActions>
        <Button onClick={close} color="secondary" autoFocus>
          Cancel
        </Button>
        <Button onClick={dialogData.action} color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
