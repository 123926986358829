import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";

import {PromptDataLocationObject} from "common/flowmaster/types/data_location";

import {Prompt} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

import {Link} from "react-router";
import LinkIcon from "material-ui/svg-icons/content/link";

interface ValueEditorProps {
  location: PromptDataLocationObject;
  prompts: Prompt[];
  onUpdateItem: (value: PromptDataLocationObject) => void;
  organisationId: OrganisationId;
}

const PromptValueEditor: React.FC<ValueEditorProps> = ({
  location,
  prompts,
  onUpdateItem,
  organisationId,
}) => {
  const selectedPrompt = prompts.find(prompt => prompt.id === location.key);

  const pathname = `/organisation/${organisationId}/prompt/${selectedPrompt?.id}/detail`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      }}
    >
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="prompt-selector-label">Key</InputLabel>
        <Select
          labelId="prompt-selector-label"
          value={location.key}
          onChange={event =>
            onUpdateItem({...location, key: event.target.value as number})
          }
          label="Value"
        >
          {prompts.map(prompt => (
            <MenuItem value={prompt.id} key={prompt.id}>
              <span title={prompt.text}>{prompt.name}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {Boolean(selectedPrompt) && (
        <Link to={{pathname}} target="_blank" style={{textDecoration: "none"}}>
          <IconButton>
            <LinkIcon />
          </IconButton>
        </Link>
      )}
    </div>
  );
};

export default PromptValueEditor;
