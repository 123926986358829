import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function updateTopicparameterValues(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.TOPICPARAMETER_ACTUAL_VALUES_UPDATE,
    (
      organisationId,
      projectId,
      documentId,
      clauseId,
      clausepartId,
      topicId,
      data,
    ) => {
      return requestor
        .put(
          `${basePath}/organisation/${organisationId}/project/${projectId}/document/${documentId}/clause/${clauseId}/clausepart/${clausepartId}/topic/${topicId}/topicparameter`,
          data,
          {withCredentials: true},
        )
        .then(response => response.data);
    },
  );
  return action;
}
