import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {
  WorkflowTask,
  WorkflowTaskId,
} from "common/flowmaster/types/task_config";
import {WorkflowId} from "common/flowmaster/types/workflow";

export type WorkflowTaskRemoveAction = {
  type: WorkflowsActionTypes["WORKFLOW_TASK_REMOVE"]["SUCCESS"];
  payload: {workflow_id: WorkflowId; tasks: WorkflowTask[]};
};

export default function removeWorkflowTask(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOW_TASK_REMOVE,
    (
      organisationId: OrganisationId,
      workflowId: WorkflowId,
      workflowTaskId: WorkflowTaskId,
    ) => {
      return requestor
        .delete(
          `${basePath}/organisation/${organisationId}/workflow/${workflowId}/task/${workflowTaskId}`,
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
