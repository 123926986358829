import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Dialog from "material-ui/Dialog";
import CircularProgress from "material-ui/CircularProgress";
import Chip from "material-ui/Chip";
import FlatButton from "material-ui/FlatButton";
import LinearProgress from "material-ui/LinearProgress";

import capitalize from "../../../utils/capitalize";

import * as colors from "material-ui/styles/colors";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  dialogContent: {
    position: "absolute",
    left: "50%",
    top: "30%",
    transform: "translate(-50%, -30%)",
    maxWidth: 770,
  },
  dialogBody: {
    padding: "18px 24px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    height: "auto",
    boxSizing: "border-box",
  },
  titleH3: {
    margin: "0px 10px 0 0",
    whiteSpace: "nowrap",
    fontSize: 21,
  },
  chipLabel: {
    whiteSpace: "initial",
    lineHeight: 1.2,
    paddingTop: 7,
    paddingBottom: 7,
  },
};

const displayStatus = {
  Added: "Add",
  Removed: "Remove",
  Updated: "Update",
};

class SyncClientIssuesetDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      status: "hidden",
      listOfChanges: null,
      errorText: null,
    };
  };

  canBeShown = () => !["hidden", "loading"].includes(this.state.status);

  componentDidUpdate(prevProps) {
    if (prevProps.isShown !== this.props.isShown) {
      if (this.props.isShown) {
        this.load();
      } else {
        this.setState(this.getInitialState());
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.status === "loading" && (
          <div style={styles.overlay}>
            <CircularProgress size={60} thickness={7} />
          </div>
        )}
        <Dialog
          title={this.renderTitle()}
          onDismiss={this.props.onHide}
          open={this.canBeShown()}
          actions={this.getActions()}
          contentStyle={styles.dialogContent}
          bodyStyle={styles.dialogBody}
          autoScrollBodyContent={true}
        >
          {this.renderContent()}
        </Dialog>
      </div>
    );
  }

  renderTitle() {
    const chipText =
      `${_.get(this.props.data, "clientName")} - ` +
      `${_.get(this.props.data, "contractType.name")} - ` +
      `${_.get(this.props.data, "issueSet.name")}`;
    const title = `Sync Client Issueset ${_.get(
      this.props.data,
      "syncTitle",
      "",
    )}`;
    return (
      <div style={styles.title}>
        <h3 style={styles.titleH3}>{title}</h3>
        <Chip labelStyle={styles.chipLabel}>{chipText}</Chip>
      </div>
    );
  }

  renderContent() {
    const {status, listOfChanges, errorText} = this.state;
    if (!this.canBeShown()) {
      return null;
    }
    if (listOfChanges && !Object.keys(listOfChanges).length) {
      return "No changes found";
    }
    switch (status) {
      case "confirmation":
        return this.renderListOfChanges();
      case "synchronization":
        return (
          <div style={{marginBottom: 10}}>
            <LinearProgress style={{backgroundColor: "#ccc"}} />
          </div>
        );
      case "completed":
        return <div>Synchronization has been completed!</div>;
      case "failed": {
        const defaultText = "Sync has failed. Please contact an adminstrator.";
        return (
          <div
            style={{color: colors.red600}}
            dangerouslySetInnerHTML={{__html: errorText || defaultText}}
          />
        );
      }
    }
  }

  renderListOfChanges() {
    const styles = {
      wrapper: {
        border: `1px solid ${colors.grey300}`,
        borderTop: 0,
        fontWeight: 300,
        color: colors.grey800,
        fontSize: 15,
      },
      tableTitle: {
        padding: "7px 10px",
        borderTop: `1px solid ${colors.grey300}`,
        borderBottom: `1px solid ${colors.grey300}`,
        fontWeight: 400,
        color: colors.grey900,
      },
      tableRows: {
        background: colors.grey100,
      },
      rowHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      rowStatus: {
        flexShrink: 0,
        width: 80,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      rowStatusBadge: {
        textAlign: "center",
        fontSize: 13,
        padding: "2px 4px",
        border: `1px solid ${colors.grey300}`,
        borderRadius: 3,
        background: "#fff",
        lineHeight: 1,
      },
      rowTitle: {
        flexGrow: 1,
        padding: "6px 8px",
        background: "#fff",
        fontWeight: 400,
        fontSize: 14,
        color: colors.grey700,
        borderTop: `1px solid ${colors.grey300}`,
        borderBottom: `1px solid ${colors.grey300}`,
        borderLeft: `1px solid ${colors.grey300}`,
      },
      rowUpdate: {
        marginLeft: 80,
        background: "#fff",
        padding: "0px 0px 5px",
        borderLeft: `1px solid ${colors.grey300}`,
      },
      updateTitle: {
        padding: "6px 8px",
        fontSize: 14,
        fontWeight: 400,
      },
      updateTitleSpan: {
        borderBottom: "1px solid #5085d8",
        color: "#5085d8",
      },
      diff: {
        background: "#fff",
        padding: "0 8px",
        fontWeight: 600,
        fontSize: 14,
        wordBreak: "break-all",
      },
    };
    return (
      <div style={styles.wrapper}>
        {_.map(this.state.listOfChanges, (tableList, tableTitle) => {
          return (
            <div key={tableTitle}>
              <div style={styles.tableTitle}>{tableTitle}</div>
              <div style={styles.tableRows}>
                {_.map(tableList, (row, index) => {
                  const {status} = row;
                  const statusText = displayStatus[status] || status;
                  return (
                    <div key={index}>
                      <div style={styles.rowHeader}>
                        <div style={styles.rowStatus}>
                          <span style={styles.rowStatusBadge}>
                            {statusText}
                          </span>
                        </div>
                        <div style={styles.rowTitle}>{row.title}</div>
                      </div>
                      {row.updates &&
                        _.map(row.updates, (values, title) => (
                          <div key={title} style={styles.rowUpdate}>
                            <div style={styles.updateTitle}>
                              <span style={styles.updateTitleSpan}>
                                {capitalize(title)}
                              </span>
                            </div>
                            <div style={styles.diff}>
                              {values.diff.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    color: part.added
                                      ? "#23d65b"
                                      : part.removed ? "#d63737" : "black",
                                  }}
                                >
                                  {part.value}
                                </span>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  getActions = () => {
    switch (this.state.status) {
      case "confirmation":
        if (!Object.keys(this.state.listOfChanges).length) {
          return [
            <FlatButton
              label="Close"
              key="sync-client-issueset-dialog-close-button"
              primary={true}
              onClick={this.props.onHide}
            />,
          ];
        }
        return [
          <FlatButton
            label="Cancel"
            key="sync-client-issueset-dialog-cancel-button"
            secondary={true}
            onClick={this.props.onHide}
          />,
          <FlatButton
            label={"Confirm Sync"}
            key="sync-client-issueset-dialog-confirm-button"
            primary={true}
            onClick={() => this.sync()}
          />,
        ];
      case "synchronization":
        return null;
      case "completed":
      case "failed":
        return [
          <FlatButton
            label="Close"
            key="sync-client-issueset-dialog-close-button"
            primary={true}
            onClick={this.props.onHide}
          />,
        ];
    }
  };

  setStatus = name => this.setState({status: name});

  load = () => {
    this.setStatus("loading");
    this.props.loadSyncListOfChanges(
      _.get(this.props.data, "clientId"),
      _.get(this.props.data, "issueSet.master_id"),
      _.get(this.props.data, "syncList"),
      data => {
        this.setState({listOfChanges: _.isObject(data) ? data : {}});
        this.setStatus("confirmation");
      },
      this.handleError,
    );
  };

  sync = () => {
    this.setStatus("synchronization");
    this.props.onSyncIssueset(
      _.get(this.props.data, "clientId"),
      _.get(this.props.data, "issueSet.master_id"),
      _.get(this.props.data, "syncList"),
      () => this.setStatus("completed"),
      this.handleError,
    );
  };

  handleError = err => {
    switch (err.status) {
      case 500: {
        const message = _.get(err, "data.message");
        if (message && message !== "An internal error occurred") {
          this.setState({errorText: message});
        }
        this.setStatus("failed");
        break;
      }
      default:
        this.props.onHide();
    }
  };
}

SyncClientIssuesetDialog.propTypes = {
  data: PropTypes.object,
  isShown: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSyncIssueset: PropTypes.func.isRequired,
  loadSyncListOfChanges: PropTypes.func.isRequired,
};

export default SyncClientIssuesetDialog;
