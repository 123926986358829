import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";

import Dialog from "material-ui/Dialog";
import Chip from "material-ui/Chip";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";

import * as colors from "material-ui/styles/colors";

import ContractTypesDialog from "./contract_types_dialog";
import IssueSetsDialog from "./issue_sets_dialog";
import ClientIssuesetItem from "./client_issueset_item";

import Checkbox from "common_components/checkbox";
import Permissioner from "utils/permissioner";

const styles = {
  dialogContent: {
    position: "absolute",
    left: "50%",
    top: "30%",
    transform: "translate(-50%, -30%)",
    maxWidth: 1050,
  },
  dialogBody: {
    paddingBottom: 0,
    overflowY: "scroll",
  },
  title: {
    display: "flex",
    alignItems: "center",
    height: 76,
    boxSizing: "border-box",
  },
  titleH3: {
    margin: "0px 10px 0 0",
  },
  section: {
    position: "relative",
    marginTop: 30,
    padding: 15,
    border: `1px solid ${colors.grey300}`,
  },
  sectionHeading: {
    position: "absolute",
    left: 10,
    top: -11,
    background: "rgb(255, 255, 255)",
    padding: "1px 5px",
    color: colors.grey500,
    fontSize: 15,
    fontWeight: 300,
  },
  addContractTypeButton: {
    marginTop: 15,
  },
  contractTypesMessage: {
    fontSize: 14,
    marginTop: 10,
  },
  contractTypeColumn: {
    whiteSpace: "initial",
    padding: "3px 16px 3px 24px",
    width: "40%",
    borderRight: `1px solid ${colors.grey300}`,
  },
  issueColumn: {
    whiteSpace: "initial",
    padding: "3px 24px 3px 16px",
  },
  issueColumnWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonLabel: {
    fontSize: 12,
    whiteSpace: "initial",
    lineHeight: 1.2,
    display: "inline-block",
    verticalAlign: "middle",
    padding: "0 8px",
  },
  buttonLabelContainer: {
    lineHeight: 1,
    height: 36,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "4px 0",
    boxSizing: "border-box",
    whiteSpace: "nowrap",
  },
  isArchivedLabel: {
    cursor: "pointer",
    fontSize: ".7em",
    fontWeight: "normal",
  },
  isArchivedCheckbox: {
    display: "flex",
    alignItems: "center",
    marginRight: "1rem",
  },
};

class ClientDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      clientData: _.cloneDeep(this.props.clientData),
      isNewClient: !this.props.clientData.id,
      nameError: "",
      isContractTypesDialogShown: false,
      issueSetsDialog: {
        isShown: false,
        contractTypeMasterId: null,
      },
    };
  }

  componentDidMount() {
    window.addEventListener("mousewheel", this.mouseWheelStopper, true);
  }

  componentWillUnmount() {
    window.removeEventListener("mousewheel", this.mouseWheelStopper, true);
  }

  mouseWheelStopper(event) {
    event.stopPropagation();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isShown !== this.props.isShown ||
      !_.isEqual(prevProps.clientData, this.props.clientData)
    ) {
      this.setState(this.getInitialState());
    }
  }

  render() {
    return (
      <Dialog
        title={this.renderTitle()}
        onDismiss={this.props.onHide}
        open={this.props.isShown}
        actions={this.getActions()}
        contentStyle={styles.dialogContent}
        bodyStyle={styles.dialogBody}
      >
        {this.renderContent()}
      </Dialog>
    );
  }

  renderTitle() {
    const {clientData} = this.state;

    if (this.state.isNewClient) {
      return "Create new client";
    }

    const onClientArchived = this.props.checkIsArchivedHandler(clientData);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={styles.title}>
          <h3 style={styles.titleH3}>Edit Client</h3>
          <Chip>{clientData.name}</Chip>
        </div>
        <div style={styles.isArchivedCheckbox}>
          <Checkbox
            checked={clientData.is_archived}
            onCheck={onClientArchived}
          />
          <div onClick={onClientArchived} style={styles.isArchivedLabel}>
            Is Archived
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    const {clientData} = this.state;
    const {contract_types} = clientData;

    const canChangePermissionGroups =
      new Permissioner(this.props.user).hasPermission(
        "can-update-user-permission-group",
      ) || this.props.user.is_admin;
    return (
      <div>
        {this.state.isNewClient && (
          <TextField
            type="text"
            floatingLabelText="Client Name"
            errorText={this.state.nameError}
            value={clientData.name}
            style={{width: "100%"}}
            onChange={this.onNameChange}
            autoFocus
          />
        )}
        <div style={{...styles.section, marginTop: 10}}>
          <div style={styles.sectionHeading}>Permissions</div>
          {canChangePermissionGroups && (
            <SelectField
              maxHeight={180}
              value={clientData.default_permission_group_id}
              floatingLabelText="Default Permission Group"
              onChange={this.onDefaultPermissionGroupIdChange}
            >
              {Permissioner.getPermissionGroups(
                this.props.user,
                this.props.permissionGroups,
              ).map(group => (
                <MenuItem
                  key={group.id}
                  value={group.id}
                  primaryText={group.name}
                />
              ))}
            </SelectField>
          )}
        </div>
        <div style={styles.section}>
          <div style={styles.sectionHeading}>Contract setup</div>
          {contract_types.length ? (
            <Table selectable={false}>
              <TableHeader
                adjustForCheckbox={false}
                displaySelectAll={false}
                enableSelectAll={false}
              >
                <TableRow>
                  <TableHeaderColumn style={{width: "40%"}}>
                    Contract Type Name
                  </TableHeaderColumn>
                  <TableHeaderColumn style={{textAlign: "center"}}>
                    Issue Sets
                  </TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody displayRowCheckbox={false}>
                {contract_types.map(contractType => (
                  <TableRow key={contractType.master_id}>
                    <TableRowColumn style={styles.contractTypeColumn}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>{contractType.name}</span>
                        {this.getLinkedIssueset(contractType) &&
                          this.renderSyncButton(
                            "topics",
                            contractType,
                            this.getLinkedIssueset(contractType),
                          )}
                      </div>
                    </TableRowColumn>
                    <TableRowColumn style={styles.issueColumn}>
                      <div style={styles.issueColumnWrap}>
                        <div style={{flexGrow: 1, paddingRight: 10}}>
                          {this.renderIssuesetList(contractType)}
                        </div>
                        <RaisedButton
                          label="add issue set from master"
                          labelStyle={styles.buttonLabel}
                          style={{width: 105}}
                          onClick={() =>
                            this.showIssueSetsDialog(contractType.master_id)
                          }
                        />
                      </div>
                    </TableRowColumn>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div style={styles.contractTypesMessage}>
              No Contract Types added
            </div>
          )}
          <RaisedButton
            label="Add Contract Type"
            style={styles.addContractTypeButton}
            onClick={this.showContractTypesDialog}
          />
          <ContractTypesDialog
            isShown={this.state.isContractTypesDialogShown}
            onHide={this.hideContractTypesDialog}
            onConfirm={this.addSelectedContractTypes}
            contractTypes={this.filterContractTypes()}
          />
          <IssueSetsDialog
            isShown={this.state.issueSetsDialog.isShown}
            onHide={this.hideIssueSetsDialog}
            contractType={this.getDialogIssueSetsContractType()}
            issueSets={this.getDialogIssueSets()}
            onConfirm={this.addSelectedIssueSetsToContractType}
          />
        </div>
      </div>
    );
  }

  renderIssuesetList(contractType) {
    if (!contractType.issuesets.length) {
      return <span style={{color: colors.grey500}}>No Issue Sets added</span>;
    }
    const {organisationId} = this.props;
    const {isNewClient, clientData} = this.state;
    return contractType.issuesets.map(issueSet => {
      return (
        <div
          key={issueSet.master_id}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "3px 0",
          }}
        >
          <ClientIssuesetItem
            isNewClient={isNewClient}
            issueSet={issueSet}
            showIsArchived={clientData.id === issueSet.organisation_id}
            organisationId={organisationId}
            onUpdateClientName={this.props.updateIssuesetName(issueSet)}
            onIsArchivedCheck={this.props.checkIssuesetIsArchived(issueSet)}
          />
          {this.renderSyncButton("whole", contractType, issueSet)}
          {this.renderSyncButton("workflows", contractType, issueSet)}
        </div>
      );
    });
  }

  renderSyncButton(syncList, contractType, issueSet) {
    const {isNewClient, clientData} = this.state;
    const syncButtons = {
      whole: {title: "data"},
      topics: {title: "topics"},
      workflows: {title: "workflows"},
    };
    const {title} = syncButtons[syncList];
    return (
      <RaisedButton
        label={
          <div style={styles.buttonLabelContainer}>
            <div>sync {title}</div>
            {_.get(issueSet.last_sync_dates, syncList) && (
              <div style={{color: "#a5a5a5", fontSize: 11}}>
                {moment(issueSet.last_sync_dates[syncList]).format(
                  "HH:mm, D MMM",
                )}
              </div>
            )}
          </div>
        }
        style={{marginLeft: 10}}
        labelStyle={styles.buttonLabel}
        disabled={
          isNewClient || contractType.isNew === true || issueSet.isNew === true
        }
        onClick={() =>
          this.props.onSyncDialogShow({
            syncList,
            syncTitle: title,
            clientId: clientData.id,
            clientName: clientData.name,
            contractType,
            issueSet,
          })
        }
      />
    );
  }

  getLinkedIssueset(contractType) {
    return contractType.issuesets.find(issueset => issueset.master_name);
  }

  getActions = () => {
    return [
      <FlatButton
        label="Cancel"
        key="client-dialog-cancel-button"
        secondary={true}
        onClick={this.props.onHide}
      />,
      <FlatButton
        label={this.state.isNewClient ? "Create" : "Save"}
        key="client-dialog-confirm-button"
        primary={true}
        onClick={this.submit}
      />,
    ];
  };

  filterContractTypes = () => {
    const {contractTypes: creatorContractTypes} = this.props;
    if (this.state.isNewClient) {
      return creatorContractTypes;
    }
    const {contract_types: clientContractTypes} = this.state.clientData;
    const masterIds = clientContractTypes.map(
      contractType => contractType.master_id,
    );
    if (!masterIds.length) {
      return creatorContractTypes;
    }
    return creatorContractTypes.filter(contractType => {
      return !masterIds.includes(contractType.master_id);
    });
  };

  getDialogIssueSetsContractType = () => {
    const {contractTypeMasterId} = this.state.issueSetsDialog;
    return this.getClientContractType(contractTypeMasterId);
  };

  getClientContractType = masterId =>
    this.state.clientData.contract_types.find(
      contractType => contractType.master_id === masterId,
    );

  getCreatorIssueSet = masterId => {
    const {contractTypes: creatorContractTypes} = this.props;
    for (let i = 0; i < creatorContractTypes.length; i++) {
      const {issuesets: creatorIssueSets} = creatorContractTypes[i];
      for (let k = 0; k < creatorIssueSets.length; k++) {
        if (creatorIssueSets[k].master_id === masterId) {
          return creatorIssueSets[k];
        }
      }
    }
  };

  getDialogIssueSets = () => {
    const {contractTypes: creatorContractTypes} = this.props;
    const {contractTypeMasterId} = this.state.issueSetsDialog;
    const creatorContractType = creatorContractTypes.find(contractType => {
      return contractType.master_id === contractTypeMasterId;
    });
    if (!creatorContractType) {
      return [];
    }
    const clientContractType = this.getClientContractType(contractTypeMasterId);
    const clientIssueSetMasterIds = clientContractType.issuesets.map(
      issueSet => issueSet.master_id,
    );
    return creatorContractType.issuesets.filter(issueSet => {
      return !clientIssueSetMasterIds.includes(issueSet.master_id);
    });
  };

  onNameChange = e =>
    this.setState({
      clientData: {
        ...this.state.clientData,
        name: e.target.value,
      },
      nameError: "",
    });

  onDefaultPermissionGroupIdChange = (event, index, value) =>
    this.setState(() => ({
      clientData: {
        ...this.state.clientData,
        default_permission_group_id: value,
      },
    }));

  validateName = () => {
    const {name} = this.state.clientData;
    if (!name.length) {
      this.setState({nameError: "This field is required"});
      return false;
    }
    if (this.isNameAlreadyUsed(name)) {
      this.setState({nameError: "This name is already used"});
      return false;
    }
    return true;
  };

  isNameAlreadyUsed = name => {
    return this.props.usedNames.includes(name);
  };

  showContractTypesDialog = () => {
    this.setState({isContractTypesDialogShown: true});
  };

  hideContractTypesDialog = () => {
    this.setState({isContractTypesDialogShown: false});
  };

  showIssueSetsDialog = contractTypeMasterId => {
    this.setState({
      issueSetsDialog: {
        isShown: true,
        contractTypeMasterId,
      },
    });
  };

  hideIssueSetsDialog = () => {
    this.setState({
      issueSetsDialog: {
        isShown: false,
        contractTypeMasterId: null,
      },
    });
  };

  addSelectedContractTypes = selected => {
    const updated = [...this.state.clientData.contract_types];
    selected.forEach(contractType => {
      const exists = Boolean(
        updated.find(({master_id}) => master_id === contractType.master_id),
      );
      if (!exists) {
        updated.push({
          isNew: true,
          master_id: contractType.master_id,
          name: contractType.name,
          issuesets: [],
        });
      }
    });
    this.setState({
      clientData: {
        ...this.state.clientData,
        contract_types: updated,
      },
    });
  };

  addSelectedIssueSetsToContractType = (
    contractTypeMasterId,
    issueSetMasterIds,
  ) => {
    const clientContractType = this.getClientContractType(contractTypeMasterId);

    issueSetMasterIds.forEach(issueSetMasterId =>
      clientContractType.issuesets.push({
        isNew: true,
        ...this.getCreatorIssueSet(issueSetMasterId),
      }),
    );

    this.setState({
      clientData: {
        ...this.state.clientData,
        contract_types: this.state.clientData.contract_types.map(
          contractType => {
            if (contractType.master_id === contractTypeMasterId) {
              return clientContractType;
            }
            return contractType;
          },
        ),
      },
    });
  };

  submit = () => {
    if (this.state.isNewClient && !this.validateName()) {
      return;
    }

    const {clientData} = this.state;
    const {contract_types} = clientData;

    let isUpdated = false;
    let sendData = {
      id: clientData.id,
    };

    if (this.state.isNewClient) {
      sendData = {
        name: clientData.name,
        default_permission_group_id: clientData.default_permission_group_id,
      };
      if (contract_types.length) {
        sendData.contractTypes = contract_types.map(contractType => {
          const newIssueSetMasterIds = contractType.issuesets.map(
            issueSet => issueSet.master_id,
          );
          return {
            isNew: true,
            masterId: contractType.master_id,
            ...(newIssueSetMasterIds.length ? {newIssueSetMasterIds} : null),
          };
        });
      }
      isUpdated = true;
    } else {
      if (
        clientData.default_permission_group_id !==
        this.props.clientData.default_permission_group_id
      ) {
        sendData.default_permission_group_id =
          clientData.default_permission_group_id;
        isUpdated = true;
      }

      const contractTypes = [];
      contract_types.forEach(contractType => {
        const propsContractType = this.props.clientData.contract_types.find(
          ({master_id}) => master_id === contractType.master_id,
        );
        const isNew = !propsContractType;
        const newIssueSetMasterIds = contractType.issuesets
          .filter(issueSet => {
            if (isNew) {
              return true;
            }
            return !propsContractType.issuesets.find(
              ({master_id}) => master_id === issueSet.master_id,
            );
          })
          .map(issueSet => issueSet.master_id);
        if (isNew || newIssueSetMasterIds.length) {
          contractTypes.push({
            isNew,
            masterId: contractType.master_id,
            ...(newIssueSetMasterIds.length ? {newIssueSetMasterIds} : null),
          });
        }
      });
      if (contractTypes.length) {
        sendData.contractTypes = contractTypes;
        isUpdated = true;
      }
    }

    if (isUpdated) {
      this.props.onSendClientData(sendData);
    }

    this.props.onHide();
  };
}

ClientDialog.propTypes = {
  clientData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    contract_types: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        master_id: PropTypes.string.isRequired,
        issuesets: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            master_id: PropTypes.string.isRequired,
            sample_issue_id: PropTypes.number,
            sample_project_id: PropTypes.number,
            sample_document_id: PropTypes.number,
          }),
        ).isRequired,
      }),
    ).isRequired,
  }),
  usedNames: PropTypes.arrayOf(PropTypes.string),
  permissionGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  contractTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      master_id: PropTypes.string.isRequired,
      issuesets: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          master_id: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  isShown: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSendClientData: PropTypes.func.isRequired,
  organisationId: PropTypes.number.isRequired,
};

export default ClientDialog;
