import requestor from "requestor";

import BaseRoute from "utils/base_route";

import workflowFetchAction from "modules/flowmaster/actions/workflow_fetch";
import workflowClearAction from "modules/flowmaster/actions/workflow_clear";
import WorkflowDetailContainer from "./containers/workflow_detail";
import actionDefinitionsFetchAction from "modules/flowmaster/actions/action_definitions_fetch";
import contextsFetchAction from "modules/flowmaster/actions/contexts_fetch";
import promptsFetchAction from "modules/prompts/actions/prompts_fetch";
import embeddingTopicsFetchAction from "modules/documents/actions/embedding_topics_fetch";
import getWorkflowPrerequisite from "modules/flowmaster/actions/workflow_prerequisite_fetch";

export default class WorkflowDetail extends BaseRoute {
  constructor(store) {
    super(":workflowId/detail", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, WorkflowDetailContainer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchWorkflow(state);
    this.fetchPrerequisiteWorkflows(state);
    this.fetchActionDefinitions(state);
    this.fetchContexts(state);
    this.fetchPrompts(state);
    this.fetchEmbeddingTopics(state);
  }

  fetchWorkflow(state) {
    const {organisationId, workflowId} = state.params;
    const {dispatch} = this.store;
    dispatch(workflowFetchAction(requestor)(organisationId, workflowId));
  }

  fetchPrerequisiteWorkflows(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(getWorkflowPrerequisite(requestor)(organisationId));
  }

  fetchActionDefinitions(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(actionDefinitionsFetchAction(requestor)(organisationId));
  }

  fetchPrompts(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(promptsFetchAction(requestor)(organisationId));
  }

  fetchContexts(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(contextsFetchAction(requestor)(organisationId));
  }

  fetchEmbeddingTopics(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(embeddingTopicsFetchAction(requestor)(organisationId));
  }

  clearActiveData() {
    const {dispatch} = this.store;
    dispatch(workflowClearAction());
  }
}
