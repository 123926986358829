import React from "react";

import Tooltip from "./tooltip";
import Selector from "./selector";

export class TopicSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoId: null,
    };
  }

  setInfoId = value => {
    this.setState({showInfoId: value});
  };

  render() {
    return (
      <span
        key="topic-selector"
        style={{
          position: "relative",
        }}
      >
        <Tooltip {...this.props} showInfoId={this.state.showInfoId} />
        <Selector
          {...this.props}
          setInfoId={this.setInfoId}
          showInfoId={this.state.showInfoId}
          showTopicParameterDialog={this.showTopicParameterDialog}
          onItemClicked={() => null}
        />
      </span>
    );
  }
}

export default TopicSelector;
