import Sidebar from "./containers/sidebar";
import LoggedInContainer from "./containers/logged_in";
import RealtimeData from "common_components/logged_in/containers/realtime";
import Errors from "common_components/logged_in/containers/errors";

import BaseRoute from "utils/base_route";
import getRouteIds from "utils/get_route_ids";

import BaseProjectRoute from "routes/base";
import TopicsBaseRoute from "routes/topics_base";
import IssuesBaseRoute from "routes/issues_base";
import TopicCategoriesBaseRoute from "routes/topiccategories_base";
import ConfigBaseRoute from "routes/config/config_base";
import LogsBaseRoute from "routes/logs";
import RegexModulesBaseRoute from "routes/regex_modules";
import ContractTypeBaseRoute from "routes/contract_type/contract_type_base";
import SearchRoute from "routes/search";
import PlaybooksRoute from "routes/playbooks";
import ClientsRoute from "routes/client";
import PermissionsRoute from "routes/permissions";
import DefinitionGroupBaseRoute from "routes/definition_group/definition_group_base";
import AnalyticsRoute from "routes/analytics";
import UserBaseRoute from "routes/user/user_base";
import RoleBaseRoute from "routes/role/role_base";
import PromptBaseRoute from "routes/prompt/prompt_base";
import WorkflowBaseRoute from "routes/workflow/workflow_base";
import LlmRunBaseRoute from "routes/llm_run/llm_run_base";
import SearcherRoute from "routes/searcher";
import ClauseBankRoute from "routes/clause_bank";
import UsergroupRoute from "routes/usergroup";
import AccessControlEntryRoute from "routes/accesscontrolentry";

import requestor from "requestor";

import organisationChangeTransition from "modules/user/transitions/organisation_change";

import organisationFetchAction from "modules/organisation/actions/organisation_fetch";
import organisationClearAction from "modules/organisation/actions/organisation_clear";
import projectsFetchAction from "modules/projects/actions/projects_fetch";
import partiesFetchAction from "modules/projects/actions/parties_fetch";
import projectRolesFetchAction from "modules/projects/actions/projectroles_fetch";
import * as Navigation from "routes/navigation";

export default class LoggedIn extends BaseRoute {
  constructor(store) {
    super("organisation/:organisationId", store);
  }

  setupTransitions() {
    this.setupProjectChangeTransition();
  }

  setupProjectChangeTransition() {
    organisationChangeTransition(this.store).subscribe(props => {
      this.fetchData(props);
    });
  }

  createChildRoutes() {
    return [
      new BaseProjectRoute(this.store),
      new TopicsBaseRoute(this.store),
      new IssuesBaseRoute(this.store),
      new TopicCategoriesBaseRoute(this.store),
      new ConfigBaseRoute(this.store),
      new LogsBaseRoute(this.store),
      new RegexModulesBaseRoute(this.store),
      new ContractTypeBaseRoute(this.store),
      new SearchRoute(this.store),
      new PlaybooksRoute(this.store),
      new ClientsRoute(this.store),
      new PermissionsRoute(this.store),
      new DefinitionGroupBaseRoute(this.store),
      new AnalyticsRoute(this.store),
      new UserBaseRoute(this.store),
      new RoleBaseRoute(this.store),
      new PromptBaseRoute(this.store),
      new WorkflowBaseRoute(this.store),
      new LlmRunBaseRoute(this.store),
      new SearcherRoute(this.store),
      new ClauseBankRoute(this.store),
      new UsergroupRoute(this.store),
      new AccessControlEntryRoute(this.store),
    ];
  }

  getComponents(location, cb) {
    const {CustomSidebar} = Navigation.getCurrentRoute(location) || {};
    cb(null, {
      sidebar: CustomSidebar || Sidebar,
      main: LoggedInContainer,
      realtimeData: RealtimeData,
      errors: Errors,
    });
  }

  fetchActiveData(state) {
    const {organisationId} = getRouteIds(state.router);
    if (organisationId) {
      this.fetchOrganisation(organisationId);
      this.fetchProjects(organisationId);
      this.fetchParties(organisationId);
      this.fetchProjectRoles(organisationId);
    }
  }

  fetchOrganisation(organisationId) {
    this.store.dispatch(organisationFetchAction(requestor)(organisationId));
  }

  fetchProjects(organisationId) {
    this.store.dispatch(projectsFetchAction(requestor)(organisationId));
  }

  fetchParties(organisationId) {
    this.store.dispatch(partiesFetchAction(requestor)(organisationId));
  }

  fetchProjectRoles(organisationId) {
    this.store.dispatch(projectRolesFetchAction(requestor)(organisationId));
  }

  clearActiveData() {
    this.store.dispatch(organisationClearAction());
  }
}
