import React from "react";
import styled from "styled-components";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import CircularProgress from "material-ui/CircularProgress";

import AccessControlEntries from "../components/AccessControlEntries";
import Permissioner from "utils/permissioner";
import {AccessControlEntry} from "common/types/accesscontrolentry";
import {isInitialised} from "utils/uninitialised";
import {Usergroup} from "common/types/usergroup";
import {Accesstag} from "modules/accesstags/actions/fetch";
import addAccessControlEntryAction from "modules/accesscontrolentry/actions/add";
import removeAccessControlEntryAction from "modules/accesscontrolentry/actions/remove";
import {MISSING} from "common/types/util";

export type PermissionGroup = {
  id: number;
  name: string;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

type AccessControlEntriesContainerProps = {
  accessControlEntries: AccessControlEntry[];
  params: Record<"organisationId", string>;
  user: unknown;
  accesstags: Accesstag[];
  permissionGroups: PermissionGroup[];
  usergroups: Usergroup[];
  addAccessControlEntry: (
    organisationId: number,
    ace: Omit<AccessControlEntry, "id">,
  ) => void;
  removeAccessControlEntry: (
    organisationId: number,
    ace: Pick<AccessControlEntry, "id">,
  ) => void;
};

class AccessControlEntriesContainer extends React.Component<AccessControlEntriesContainerProps> {
  permissioner: Permissioner;

  render() {
    if (!this.shouldRenderContainer()) {
      return (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    const {
      accessControlEntries,
      permissionGroups,
      usergroups,
      accesstags,
      addAccessControlEntry,
      removeAccessControlEntry,
      params,
    } = this.props;

    const organisationId = parseInt(params.organisationId, 10);

    return (
      <Container>
        <AccessControlEntries
          usergroups={usergroups}
          permissionGroups={permissionGroups}
          accesstags={accesstags}
          accessControlEntries={accessControlEntries.map(ace => ({
            id: ace.id,
            usergroup:
              usergroups.find(({id}) => id === ace.usergroup_id) ?? MISSING,
            permissionGroup:
              permissionGroups.find(({id}) => id === ace.permission_group_id) ??
              MISSING,
            ...(ace.global_scope
              ? {global_scope: true}
              : {
                  global_scope: false,
                  accesstags: accesstags.filter(({id}) =>
                    ace.accesstag_ids.includes(id),
                  ),
                }),
          }))}
          onAddAccessControlEntry={ace =>
            addAccessControlEntry(organisationId, ace)
          }
          onRemoveAccessControlEntry={ace =>
            removeAccessControlEntry(organisationId, ace)
          }
        />
      </Container>
    );
  }

  hasEnterPermission() {
    const permission = "view-access-control-entries";
    return this.permissioner.hasPermission(permission);
  }

  shouldRenderContainer() {
    const {
      accessControlEntries,
      permissionGroups,
      usergroups,
      accesstags,
    } = this.props;
    return isInitialised([
      accessControlEntries,
      permissionGroups,
      usergroups,
      accesstags,
    ]);
  }

  renderComponent() {}
}

function select({
  accesscontrolentry,
  accesstags,
  permission_groups,
  user,
  usergroups,
}) {
  return {
    accessControlEntries: accesscontrolentry,
    accesstags,
    permissionGroups: permission_groups,
    user,
    usergroups,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        addAccessControlEntry: addAccessControlEntryAction,
        removeAccessControlEntry: removeAccessControlEntryAction,
      },
      dispatch,
    ),
  };
}

export default connect(
  select,
  mapDispatchToProps,
)(AccessControlEntriesContainer);
export const Component = AccessControlEntriesContainer;
