import React from "react";
import styled from "styled-components";
import CircularProgress from "material-ui/CircularProgress";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Permissioner from "utils/permissioner";
import {MaybeWithUsers, Usergroup, WithUserCount} from "common/types/usergroup";
import {isInitialised} from "utils/uninitialised";
import Usergroups, {RegisteredUser} from "../components/Usergroups";
import createUsergroupAction from "modules/usergroups/actions/create";
import deleteUsergroupAction from "modules/usergroups/actions/delete";
import editUsergroupNameAction from "modules/usergroups/actions/edit";
import fetchUsergroupUsersAction from "modules/usergroups/actions/fetchUsers";
import addUsergroupUserAction from "modules/usergroups/actions/addUser";
import removeUsergroupUserAction from "modules/usergroups/actions/removeUser";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

type UsergroupContainerProps = {
  user: unknown;
  organisation: {users: Array<{username: string; id: number}>};
  usergroups: Array<MaybeWithUsers<WithUserCount<Usergroup>>>;
  params: Record<"organisationId", string>;
  createUsergroup: (organisationId: number, name: string) => void;
  deleteUsergroup: (organisationId: number, usergroupId: number) => void;
  editUsergroupName: (
    organisationId: number,
    usergroupId: number,
    newName: string,
  ) => void;
  fetchUsergroupUsers: (organisationId: number, usergroupId: number) => void;
  addUsergroupUser: (
    organisationId: number,
    usergroupId: number,
    user: RegisteredUser,
  ) => void;
  removeUsergroupUser: (
    organisationId: number,
    usergroupId: number,
    user: Pick<RegisteredUser, "id">,
  ) => void;
};

class UsergroupContainer extends React.Component<UsergroupContainerProps> {
  permissioner: Permissioner;

  render() {
    if (!this.shouldRenderContainer()) {
      return (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }

    const {
      usergroups,
      params,
      createUsergroup,
      deleteUsergroup,
      editUsergroupName,
      fetchUsergroupUsers,
      organisation: {users},
      addUsergroupUser,
      removeUsergroupUser,
    } = this.props;

    const organisationId = parseInt(params.organisationId, 10);
    return (
      <Container>
        <Usergroups
          organisationUsers={users}
          usergroups={usergroups}
          onAddUsergroup={name => createUsergroup(organisationId, name)}
          onDeleteUsergroup={id => deleteUsergroup(organisationId, id)}
          onEditUsergroupName={(usergroupId, newName) =>
            editUsergroupName(organisationId, usergroupId, newName)
          }
          onOpenEditUsergroupUsers={usergroupId => {
            fetchUsergroupUsers(organisationId, usergroupId);
          }}
          onAddUserToUsergroup={(usergroupId, user) =>
            addUsergroupUser(organisationId, usergroupId, user)
          }
          onRemoveUserFromUsergroup={(usergroupId, user) =>
            removeUsergroupUser(organisationId, usergroupId, user)
          }
        />
      </Container>
    );
  }

  hasEnterPermission() {
    const permission = "view-usergroups";
    return this.permissioner.hasPermission(permission);
  }

  shouldRenderContainer() {
    return isInitialised([this.props.usergroups]);
  }

  renderComponent() {}
}

function select(state) {
  return {
    user: state.user,
    usergroups: state.usergroups,
    organisation: state.organisation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        createUsergroup: createUsergroupAction,
        deleteUsergroup: deleteUsergroupAction,
        editUsergroupName: editUsergroupNameAction,

        fetchUsergroupUsers: fetchUsergroupUsersAction,

        addUsergroupUser: addUsergroupUserAction,
        removeUsergroupUser: removeUsergroupUserAction,
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(UsergroupContainer);
export const Component = UsergroupContainer;
