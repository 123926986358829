import React from "react";
import Column from "routes/permissions/components/Column";
import styled from "styled-components";

import * as colors from "material-ui/styles/colors";
import ActionDelete from "material-ui/svg-icons/action/delete";
import ContentAddCircle from "material-ui/svg-icons/content/add-circle";
import ListItem from "material-ui/List/ListItem";
import {IconButton} from "@material-ui/core";

import {RegisteredUser} from "./Usergroups";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const ColumnWrapper = styled.div`
  width: 50%;
  height: 100%;
`;

type UsergroupUsersEditorProps = {
  /* Users in and not-in the usergroup. */
  users: {
    insideGroup: RegisteredUser[];
    outsideGroup: RegisteredUser[];
  };

  /** Optional callback invoked when a user is removed from a usergroup. */
  onRemoveUserFromUsergroup?: (user: RegisteredUser) => void;

  /** Optional callback invoked when a user is added to a usergroup. */
  onAddUserToUsergroup?: (user: RegisteredUser) => void;
};

const UsergroupUsersEditor = ({
  users,
  onAddUserToUsergroup,
  onRemoveUserFromUsergroup,
}: UsergroupUsersEditorProps) => (
  <Container>
    <ColumnWrapper>
      <Column title="Available users">
        {users.outsideGroup.map(user => (
          <ListItem
            key={user.id}
            hoverColor="transparent"
            primaryText={user.username}
            rightIconButton={
              <IconButton onClick={() => onAddUserToUsergroup?.(user)}>
                <ContentAddCircle color={colors.grey400} />
              </IconButton>
            }
          />
        ))}
      </Column>
    </ColumnWrapper>
    <ColumnWrapper>
      <Column title="Users in group">
        {users.insideGroup.map(user => (
          <ListItem
            key={user.id}
            hoverColor="transparent"
            primaryText={user.username}
            rightIconButton={
              <IconButton onClick={() => onRemoveUserFromUsergroup?.(user)}>
                <ActionDelete color={colors.grey400} />
              </IconButton>
            }
          />
        ))}
      </Column>
    </ColumnWrapper>
  </Container>
);

export default UsergroupUsersEditor;
