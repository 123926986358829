import basePath from "base_path";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../../constants/action_types";

export default function addIssueset(requestor) {
  return createReduxPromiseAction(
    ActionTypes.ISSUESET_ADD,
    (organisationId, contractTypeId, newIssuesetData) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/contract_type/${contractTypeId}/issueset/new`,
          newIssuesetData,
          {withCredentials: true},
        )
        .then(response => response.data)
        .catch(err => err.data);
    },
  );
}
