import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TASK_COMMENT_CREATE,
    (organisationId, taskId, text, username) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/task/${taskId}/comment`,
          {text},
          {withCredentials: true},
        )
        .then(response => ({
          organisationId,
          taskId,
          text,
          username,
          creation_date: new Date(),
          ...response.data,
        }));
    },
  );
}
