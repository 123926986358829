import * as jsDiff from "diff";

function getRegexDiffs(baseValues, logValues) {
  let result;
  if (baseValues.length) {
    const eliminatedBaseValues =
      baseValues && baseValues.length ? [...baseValues] : [];
    result = logValues.map((logValueItem, index) => {
      let label = "";
      let value = [];
      if (eliminatedBaseValues.length) {
        const compareItem = getLeastDiffs(
          logValueItem,
          eliminatedBaseValues,
          index,
        );
        const removeIndex = eliminatedBaseValues.findIndex(
          item => item.pattern[0] === compareItem.pattern[0],
        );
        eliminatedBaseValues.splice(removeIndex, 1);
        label = compareItem.value;
        value = jsDiff.diffChars(
          logValueItem.pattern[0],
          compareItem.pattern[0],
        );
      } else {
        label = logValueItem.value;
        value = [{value: logValueItem.pattern[0], removed: true}];
      }
      return {label, value};
    });
    // adding rest of not eliminated base values (when originally baseValuesLength > logValuesLength)
    const remainingBaseItems = eliminatedBaseValues.map(item => ({
      label: item.value,
      value: [{value: item.pattern[0], added: true}],
    }));
    result = result.concat(remainingBaseItems);
  } else {
    result = logValues.map(logValueItem => ({
      label: logValueItem.value,
      value: [{value: logValueItem.pattern[0], removed: true}],
    }));
  }
  return result;
}

function getLeastDiffs(logItem, baseValues, index) {
  return baseValues.reduce((acc, currentBaseItem) => {
    if (!logItem.pattern || !logItem.pattern[0].length) {
      return {
        ...baseValues[index],
        diffCount: 0,
      };
    }
    const diffCount = getDiffsCount(logItem, currentBaseItem);
    if (acc.diffCount < diffCount) {
      return acc;
    }
    return {diffCount, ...currentBaseItem};
  }, {});
}

function getDiffsCount(logItem, currentBaseItem) {
  return jsDiff
    .diffChars(logItem.pattern[0], currentBaseItem.pattern[0])
    .reduce((amount, currentChar) => {
      if (currentChar.added || currentChar.removed) {
        return amount + 1;
      }
      return amount;
    }, 0);
}

export default getRegexDiffs;
