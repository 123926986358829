// import requestor from "requestor";
import BaseRoute from "utils/base_route";
// import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";
// import contractTypesClearAction from "modules/documents/actions/contract_types_clear";
// import searchClearAction from "modules/search/actions/search_clear";
import SignupContainer from "./containers/signup";

export default class extends BaseRoute {
  constructor(store) {
    super("signup", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, {main: SignupContainer});
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(/* state */) {
    // this.fetchContractTypes(state);
  }

  clearActiveData(/* state */) {
    // this.clearContractTypes(state);
    // this.clearSearch();
  }

  // fetchContractTypes(state) {
  //   const {organisationId} = state.params;
  //   this.store.dispatch(contractTypesFetchAction(requestor)(organisationId));
  // }

  // clearContractTypes(state) {
  //   const {organisationId} = state.params;
  //   this.store.dispatch(contractTypesClearAction(organisationId));
  // }

  // clearSearch() {
  //   this.store.dispatch(searchClearAction());
  // }
}
