/* eslint-disable jsdoc/require-returns */
/* eslint-disable jsdoc/require-param-type */
import {useState, useMemo} from "react";
import capitalize from "utils/capitalize";

// Believe it or not, keyof is a LOSSY operation in Typescript. For some
// reason, it turns string-only indices into (string | number). Sad!
type Key<T> = Extract<keyof T, string>;

type SetterName<Field extends string> = `set${Capitalize<Field>}`;
type SetterFor<T extends {[key: string]: unknown}> = {
  [K in Key<T> as SetterName<K>]: (newState: T[K]) => void;
};
const getSetterName = <T extends string>(key: T): SetterName<T> =>
  `set${capitalize(key)}`;

// Namespaces are kick-ass! The linter is a coward!
// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Dialog {
  // We require string indices to make it easier to generate setters. Not a
  // very serious reason - we could stringify.
  export type CustomState = {[key: string]: unknown};

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Open {
    export type State<T extends CustomState> = {isOpen: true} & {
      [key in Key<T>]: T[key];
    };
    export type API<T extends CustomState> = State<T> &
      SetterFor<T> & {close: () => void};
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Closed {
    export type State = {isOpen: false};
    export type API<T extends CustomState> = State & {
      open: (initialState: T) => void;
    };
  }

  export type State<T extends CustomState> = Open.State<T> | Closed.State;
  export type API<T extends CustomState> = Open.API<T> | Closed.API<T>;
}

/**
 * This hook attempts to provide a convenient, type-safe API to manage the
 * typical state management of a dialog. Really all it does it manage a boolean
 * flag (isOpen) and some state (with setters) that only exists when the flag
 * is true.
 *
 * TODO: It would be nice to support the provided custom state being OPTIONAL.
 * TODO: With hooks like these, it's also probably a good idea for the caller to
 * manage their input states locally and synchronise them here asynchronously.
 * As it stands, rapid changes to the input state at a high level component may
 * cause renders to become slow. IMO the solution is not to exclusively manage
 * state locally, but to introduce optimisations like this.
 *
 * @param customStateKeys The keys of the custom state.
 * It's a shame we even require this because it's slightly redundant in
 * combination with the provided type parameter. The type parameter tells us
 * what custom state fields exist, as well as their types. However, we also
 * require the key-names of those fields at runtime. I couldn't think of a more
 * elegant way to coordinate between the type and runtime specification of
 * these clearly related parameters.
 */
const useDialog = <T extends Dialog.CustomState>(
  customStateKeys: Array<keyof T>,
): Dialog.API<T> => {
  const [state, setState] = useState<Dialog.State<T>>({isOpen: false});

  // TODO: Could memoise. Before we bother doing this in various places, I
  // think we should install the React hooks linter rule.
  return useMemo(
    () =>
      state.isOpen
        ? {
            ...state,
            ...((customStateKeys.reduce<Partial<SetterFor<T>>>(
              (acc, key: Key<T>) => ({
                ...acc,
                [getSetterName(key)]: (newState: T[typeof key]) =>
                  setState(prev =>
                    prev.isOpen ? {...prev, [key]: newState} : prev,
                  ),
              }),
              {},
              // NOTE: I should look for a better way to safely convert a Partial
              // to a complete type, without too much boilerplate.
            ) as unknown) as SetterFor<T>),
            close: () => setState({isOpen: false}),
          }
        : {
            ...state,
            open: (initialState: T) =>
              setState({isOpen: true, ...initialState}),
          },
    [customStateKeys, state],
  );
};

export default useDialog;
