import React, {useState} from "react";
import {Link} from "react-router";
import moment from "moment";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreIcon from "@material-ui/icons/MoreVert";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import ListFilter from "common_components/list_filter";
import {Table, Tr, Th, Td} from "common_components/table";

import getListFilterRegexes from "utils/get_list_filter_regexes";
import getColorByBgColor from "utils/get_color_by_bg_color";
import CreateRoleDialog from "./create_role_dialog";

const styles = {
  inner: {
    padding: "1em 2em",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
};

const filterTypes = [
  {title: "All Types", useAllFields: true},
  {title: "Aliases", fieldName: "aliases", isDefault: true},
  {title: "Name", fieldName: "name"},
];

function getFilteredRoles(roles, filterString, filterType) {
  if (!filterString || filterString.length < 2) {
    return roles;
  }
  const filterTexts = getListFilterRegexes(`"${filterString}"`);
  if (!filterTexts) {
    return roles;
  }
  const filterTypeObj = filterTypes.find(ft => ft.title === filterType);
  const fieldNames = filterTypeObj.useAllFields
    ? filterTypes
        .filter(({fieldName}) => fieldName)
        .map(({fieldName}) => fieldName)
    : [filterTypeObj.fieldName];
  return roles.filter(role => {
    return filterTexts.every(filterText => {
      let fieldValues = [];
      fieldNames.forEach(fieldName => {
        if (fieldName === "aliases") {
          fieldValues = [...fieldValues, ...role.aliases];
        } else {
          fieldValues.push(role[fieldName]);
        }
      });

      for (const fieldValue of fieldValues) {
        if (filterText.test(fieldValue.toString().toLowerCase())) {
          return true;
        }
      }
      return false;
    });
  });
}

function RoleListComponent(props) {
  const {contractTypesById, roles} = props;

  // top right menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // show aliases
  const [shouldShowAliases, updateShouldShowAliases] = useState(false);
  function triggerShowAliases() {
    updateShouldShowAliases(!shouldShowAliases);
    handleClose();
  }

  // create dialog
  const [isCreateRoleDialogShown, setIsDialogShown] = useState(false);
  const onShowCreateDialog = () => setIsDialogShown(true);
  const onHideCreateDialog = () => setIsDialogShown(false);

  // filter
  const [filterString, updateFilterString] = useState("");
  function setFilterText(filterString) {
    updateFilterString(filterString);
  }

  const [filterType, updateFilterType] = useState("Aliases");
  function onFilterTypeChange(value) {
    updateFilterType(value);
  }

  const filteredRoles = getFilteredRoles(roles, filterString, filterType);
  return (
    <div className="app-page">
      <div className="app-toolbar">
        <Typography style={{flexGrow: 1}} color="textSecondary" variant="h6">
          Roles
        </Typography>
        <ListFilter
          filterString={filterString}
          onFilterStringChange={setFilterText}
          itemsCount={roles.length}
          matchesCount={filteredRoles.length}
          filterTypes={filterTypes}
          filterTypeTitle={filterType}
          onFilterTypeChange={onFilterTypeChange}
        />
        <Fab
          style={{marginLeft: 40}}
          size="small"
          color="primary"
          onClick={onShowCreateDialog}
        >
          <AddIcon />
        </Fab>
        <>
          <MoreIcon
            onClick={handleMenu}
            style={{marginLeft: 20, cursor: "pointer"}}
          />
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={triggerShowAliases}>
              <ListItemIcon>
                {shouldShowAliases ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )}
              </ListItemIcon>
              Show Aliases
            </MenuItem>
          </Menu>
        </>
      </div>
      <div className="app-body" style={styles.inner}>
        <Table sortColumnIndex={0} hasStickyHeader={true}>
          <thead>
            <tr>
              <Th
                style={{width: "2%"}}
                containerStyle={{justifyContent: "center"}}
              >
                Id
              </Th>
              <Th style={{width: "20%"}}>Name</Th>
              <Th style={{width: "15%"}}>Creation Date</Th>
              <Th style={{width: "5%"}}># Aliases</Th>
              <Th style={{width: shouldShowAliases ? "30%" : "60%"}}>
                Contract Types
              </Th>
              {shouldShowAliases && <Th style={{width: "30%"}}>Aliases</Th>}
            </tr>
          </thead>
          <tbody>
            {filteredRoles.map((role, index) => (
              <Tr key={`role-${index}`} style={{
                backgroundColor: role.color ? role.color : "none",
                color: role.color ? getColorByBgColor(role.color) : "#798087",
              }}>
                <Td containerStyle={{alignItems: "center"}}>{role.id}</Td>
                <Td
                  handleChildren={(children, thisTd) => {
                    const pathname = `/organisation/${
                      props.organisationId
                    }/role/${role.id}/detail`;
                    return (
                      <Link
                        to={{pathname}}
                        style={{
                          color: thisTd.props.isRowHovered
                            ? "#2196f3"
                            : role.color
                              ? getColorByBgColor(role.color)
                              : "#798087",
                          textDecoration: thisTd.props.isRowHovered
                            ? "underline"
                            : "none",
                        }}
                      >
                        {children}
                      </Link>
                    );
                  }}
                >
                  {role.name}
                </Td>
                <Td sortText={moment(role.creation_date).format("x")}>
                  {moment(role.creation_date).format("DD/MM/YYYY - h:mm A")}
                </Td>
                <Td containerStyle={{alignItems: "center"}}>
                  {role.aliases.length}
                </Td>
                <Td>
                  {role.contract_types.map(ctId => {
                    const ct = contractTypesById[ctId];
                    return <div key={ct.id}>{ct.name}</div>;
                  })}
                </Td>
                {shouldShowAliases && (
                  <Td>
                    {role.aliases.map((al, idx) => {
                      return <div key={`${al}-${idx}`}>{al}</div>;
                    })}
                  </Td>
                )}
              </Tr>
            ))}
          </tbody>
        </Table>
      </div>
      <CreateRoleDialog
        roles={props.roles}
        onHideCreateDialog={onHideCreateDialog}
        createDialogShown={isCreateRoleDialogShown}
        addRole={props.addRole}
      />
    </div>
  );
}

export default RoleListComponent;
