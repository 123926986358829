import React from "react";
import _ from "lodash";

import {Autocomplete} from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "material-ui/Paper";
import IconButton from "material-ui/IconButton";
import ClearIcon from "material-ui/svg-icons/content/clear";

import {Table, Tr, Th, Td} from "common_components/table";
import CheckboxBasic from "common_components/inputs/checkbox_basic";

import usePrevious from "utils/hooks/use_previous";

const styles = {
  iconButton: {
    cursor: "pointer",
    color: "#757575",
    height: 16,
    width: 16,
    marginRight: 4,
  },
};

function ParameterFilters(props) {
  const {topics, topicsById} = props;
  const [parameterFiltersState, updateParameterFiltersState] = React.useState(
    props.parameterFilters || [],
  );

  const prevParameterFilters = usePrevious(props.parameterFilters || []);

  React.useEffect(
    () => {
      if (!_.isEqual(prevParameterFilters, props.parameterFilters)) {
        updateParameterFiltersState(props.parameterFilters || []);
      }
    },
    [props.parameterFilters],
  );

  const [selectedTopicId, updateSelectedTopicId] = React.useState(null);
  const [selectedParameterId, updateSelectedParameterId] = React.useState(null);

  const selectedTopic = selectedTopicId ? topicsById[selectedTopicId] : null;

  function getAutocompleteTopics() {
    return topics.filter(topic => {
      if (topic && topic.parameters && topic.parameters.length > 0) {
        const selectedTopicState = parameterFiltersState.find(
          topicState => topicState.topic_id === topic.id,
        );
        if (selectedTopicState) {
          const areNonSelectedParametersLeft =
            topic.parameters.filter(
              param =>
                !selectedTopicState.parameters.find(
                  paramId => paramId === param.id,
                ),
            ).length > 0;
          return areNonSelectedParametersLeft;
        }
        return true;
      }
      return false;
    });
  }

  function getSelectTopicparameters() {
    if (selectedTopic) {
      const selectedTopicState = (parameterFiltersState || []).find(
        topic => topic.topic_id === selectedTopic.id,
      );
      if (selectedTopicState) {
        return (selectedTopic.parameters || []).filter(
          param =>
            !(selectedTopicState.parameters || []).find(
              paramId => param.id === paramId,
            ),
        );
      }
      return selectedTopic.parameters || [];
    }
    return [];
  }

  function renderInput(params) {
    return <TextField {...params} label="Topic" />;
  }

  function getOptionLabel(option) {
    return option.name || "";
  }

  function onTopicChange(e, option) {
    updateSelectedTopicId(option.id);
  }

  function onTopicparameterChange(e) {
    updateSelectedParameterId(e.target.value);
  }

  function triggerSearchWholeDocument(topicId) {
    return () => {
      const parameterFiltersStateCloned = _.cloneDeep(parameterFiltersState);
      const foundItem = parameterFiltersStateCloned.find(
        item => item.topic_id === topicId,
      );
      if (foundItem) {
        const newValue = !foundItem.search_whole_document;
        if (!newValue) {
          delete foundItem.search_whole_document;
        } else {
          foundItem.search_whole_document = newValue;
        }
        updateParameterFiltersState(parameterFiltersStateCloned);
      }
    };
  }

  function onClearSelection() {
    updateSelectedTopicId(null);
    updateSelectedParameterId(null);
  }

  function onSaveSelection() {
    const parameterFiltersStateCloned = _.cloneDeep(parameterFiltersState);

    const foundItem = parameterFiltersStateCloned.find(
      item => item.topic_id === selectedTopicId,
    );
    if (!foundItem) {
      parameterFiltersStateCloned.push({
        topic_id: selectedTopicId,
        parameters: selectedParameterId ? [selectedParameterId] : [],
      });
    } else {
      if (selectedParameterId) {
        const isParamAlreadyAdded = Boolean(
          foundItem.parameters.find(paramId => paramId === selectedParameterId),
        );
        if (!isParamAlreadyAdded) {
          foundItem.parameters.push(selectedParameterId);
        }
      }
    }
    updateParameterFiltersState(parameterFiltersStateCloned);
    updateSelectedParameterId(null);
  }

  function onTopicRemove(topicId) {
    return function removeTopicItem() {
      updateParameterFiltersState(
        parameterFiltersState.filter(item => item.topic_id !== topicId),
      );
    };
  }

  function onTopicparameterRemove(topicId, topicparameterId) {
    return function removeTopicparameterItem() {
      const parameterFiltersStateCloned = _.cloneDeep(parameterFiltersState);
      const foundItem = parameterFiltersStateCloned.find(
        item => item.topic_id === topicId,
      );
      if (foundItem) {
        foundItem.parameters = foundItem.parameters.filter(
          paramId => paramId !== topicparameterId,
        );
        updateParameterFiltersState(parameterFiltersStateCloned);
      }
    };
  }

  function onSaveParameterFilters() {
    onClearSelection();
    props.updateParameterFilters(parameterFiltersState);
  }

  function onClearParameterFilters() {
    updateParameterFiltersState(props.parameterFilters || []);
  }

  const areChangesPresent = !_.isEqual(
    props.parameterFilters,
    parameterFiltersState,
  );

  return (
    <Paper style={{margin: "2rem 2rem 1rem 2rem"}}>
      <div
        className="app-toolbar"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          fontWeight: "500",
        }}
      >
        Parameter Filters
        {props.editLevel !== "base" && (
          <IconButton {...props.clearProps || {}}>
            <ClearIcon />
          </IconButton>
        )}
      </div>
      <div
        style={{
          margin: `2rem 2rem ${props.overriddenValues ? "0" : "2"}rem 2rem`,
        }}
      >
        <Table isSortable={false} hasStickyHeader={true}>
          <thead>
            <tr>
              <Th style={{width: "47%"}}>Topic</Th>
              <Th style={{width: "47%"}}>Parameters</Th>
              <Th style={{width: "6%"}}>Search Whole Document</Th>
            </tr>
          </thead>
          <tbody>
            {parameterFiltersState.map(
              (
                {
                  topic_id: topicId,
                  parameters,
                  search_whole_document: searchWholeDocument = false,
                },
                itemIndex,
              ) => {
                const topic = topicsById[topicId];
                if (!topic) {
                  return null;
                }
                return (
                  <Tr key={itemIndex}>
                    <Td>
                      <div style={{display: "flex"}}>
                        <DeleteIcon
                          style={styles.iconButton}
                          onClick={onTopicRemove(topicId)}
                        />
                        <div>{topic.name}</div>
                      </div>
                    </Td>
                    <Td>
                      <div
                        style={{
                          display: "flex",
                          width: "52.5%",
                          flexDirection: "column",
                        }}
                      >
                        {parameters.map(parameterId => {
                          const param = (topic.parameters || []).find(
                            param => param.id === parameterId,
                          );
                          return (
                            <div
                              key={parameterId}
                              style={{display: "flex", alignItems: "center"}}
                            >
                              <DeleteIcon
                                style={styles.iconButton}
                                onClick={onTopicparameterRemove(
                                  topicId,
                                  parameterId,
                                )}
                              />
                              <div>{param ? param.name : ""}</div>
                            </div>
                          );
                        })}
                      </div>
                    </Td>

                    <Td containerStyle={{alignItems: "center"}}>
                      <CheckboxBasic
                        checked={searchWholeDocument}
                        onCheck={triggerSearchWholeDocument(topicId)}
                      />
                    </Td>
                  </Tr>
                );
              },
            )}
          </tbody>
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 6,
          }}
        >
          <Autocomplete
            disableClearable={true}
            blurOnSelect={true}
            freeSolo={true}
            forcePopupIcon={true}
            value={selectedTopic}
            options={getAutocompleteTopics()}
            renderInput={renderInput}
            getOptionLabel={getOptionLabel}
            onChange={onTopicChange}
            style={{width: "45%"}}
          />

          <FormControl style={{width: "45%"}}>
            <InputLabel>Parameter</InputLabel>
            <Select
              value={selectedParameterId || ""}
              onChange={onTopicparameterChange}
              disabled={
                !selectedTopicId ||
                !selectedTopic.parameters ||
                selectedTopic.parameters.length === 0
              }
            >
              {getSelectTopicparameters().map(param => (
                <MenuItem key={param.id} value={param.id}>
                  {param.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div
            style={{
              visibility:
                selectedTopicId && selectedParameterId ? "visible" : "hidden",
              paddingTop: 20,
              display: "flex",
            }}
          >
            <div title="Add Item To List">
              <AddIcon
                onClick={onSaveSelection}
                style={_.omit(styles.iconButton, [
                  "height",
                  "width",
                  "marginLeft",
                ])}
              />
            </div>
            <div title="Cancel Adding Item">
              <CloseIcon
                onClick={onClearSelection}
                style={_.omit(styles.iconButton, [
                  "height",
                  "width",
                  "marginLeft",
                ])}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "16px 0px",
            marginTop: 22,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={!areChangesPresent}
            style={{marginRight: 30}}
            onClick={onSaveParameterFilters}
          >
            Save
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!areChangesPresent}
            onClick={onClearParameterFilters}
          >
            Cancel
          </Button>
        </div>
      </div>
      {props.overriddenValues}
    </Paper>
  );
}

export default ParameterFilters;
