import React from "react";

import getUsageMatches from "utils/topic/get_usage_matches";

import styles from "../const/clausepart_styles";
import renderRegexList from "./render_regex_list";

const style = {
  color: "#888",
};
const headingsStyle = {
  color: "#ccc",
};
const definitionStyle = {
  color: "#ccc",
};

function constructClausepartDomElement(props, usage) {
  const {
    clausepart_partial_text: partialText,
    clausepartMatchData,
    regexList,
  } = usage;
  const {regexClassifier: classifier, showMatchRegexes} = props;
  const {
    end,
    endStr,
    startStr,
    definitionText,
    headingsText,
    text,
    matches,
  } = clausepartMatchData;
  const showHeadingsMatches = !classifier.omit_headings;
  const headingLength = showHeadingsMatches ? headingsText.length : 0;
  const headingsMatchesElements = showMatches(headingsText, [], 0, props);

  const definitionsMatchesElements = showMatches(
    definitionText,
    [],
    headingLength + text.length,
    props,
  );

  if (end === text.length) {
    const mainTextMatches = showMatches(text, matches, headingLength, props);
    return (
      <div>
        <div style={{textAlign: "justify"}}>
          <span className="headings-text" style={headingsStyle}>
            {headingsMatchesElements}
          </span>
          <span className="main-text">{mainTextMatches}</span>
          <span className="definition-text" style={definitionStyle}>
            {definitionsMatchesElements}
          </span>
        </div>
        {renderRegexList(regexList, showMatchRegexes)}
      </div>
    );
  }

  if (startStr.length === 0) {
    const mainTextMatches = showMatches(text, matches, headingLength, props);
    const postTextMatches = showHeadingsMatches
      ? []
      : showMatches(endStr, matches, headingLength + partialText.length, props);
    return (
      <div>
        <div style={{textAlign: "justify"}}>
          <span className="headings-text" style={headingsStyle}>
            {headingsMatchesElements}
          </span>
          <span className="main-text">{mainTextMatches}</span>
          <span className="post-text" style={style}>
            {postTextMatches}
          </span>
          <span className="definition-text" style={definitionStyle}>
            {definitionsMatchesElements}
          </span>
        </div>
        {renderRegexList(regexList, showMatchRegexes)}
      </div>
    );
  }

  if (endStr.length === 0) {
    const preTextMatches = showMatches(startStr, matches, headingLength, props);
    const mainTextMatches = showMatches(
      partialText,
      matches,
      headingLength + startStr.length,
      props,
    );
    return (
      <div>
        <div style={{textAlign: "justify"}}>
          <span className="headings-text" style={headingsStyle}>
            {headingsMatchesElements}
          </span>
          <span className="pre-text" style={style}>
            {preTextMatches}
          </span>
          <span className="main-text">{mainTextMatches}</span>
          <span className="definition-text" style={definitionStyle}>
            {definitionsMatchesElements}
          </span>
        </div>
        {renderRegexList(regexList, showMatchRegexes)}
      </div>
    );
  }

  const preTextMatches = showMatches(startStr, matches, headingLength, props);
  const mainTextMatches = showMatches(
    partialText,
    matches,
    headingLength + startStr.length,
    props,
  );
  const postTextMatches = showMatches(
    endStr,
    matches,
    headingLength + startStr.length + partialText.length,
    props,
  );
  return (
    <div>
      <div style={{textAlign: "justify"}}>
        <span className="headings-text" style={headingsStyle}>
          {headingsMatchesElements}
        </span>
        <span className="pre-text" style={style}>
          {preTextMatches}
        </span>
        <span className="main-text">{mainTextMatches}</span>
        <span className="post-text" style={style}>
          {postTextMatches}
        </span>
        <span className="definition-text" style={definitionStyle}>
          {definitionsMatchesElements}
        </span>
      </div>
      {renderRegexList(regexList, showMatchRegexes)}
    </div>
  );
}

function showMatches(text, matches, startOffset = 0, props) {
  const {regexClassifier: classifier} = props;
  if (!matches || matches.length === 0) {
    return <span>{text}</span>;
  }
  const listWithMatches = getUsageMatches(text, matches, startOffset);

  const result = [];

  listWithMatches.list.forEach(el => {
    if (el.match) {
      result.push(
        <span
          key={el.key}
          style={el.style && {...styles[el.style], ...styles.common}}
          title={`${el.match.cIndex + 1}. ${
            classifier.value[`${el.match.type}list`][el.match.cIndex]
          }`}
        >
          {el.text}
        </span>,
      );
    } else {
      result.push(
        <span key={el.key} style={el.style && styles[el.style]}>
          {el.text}
        </span>,
      );
    }
  });
  return result;
}

export default constructClausepartDomElement;
