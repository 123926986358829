import basePath from "base_path";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../constants/action_types";

export default createReduxPromiseAction(ActionTypes.ANALYTICS_FETCH, days =>
  requestor
    .get(`${basePath}/analytics/${days}`, {
      withCredentials: true,
    })
    .then(response => response.data),
);
