import React from "react";

import ArrowUpIcon from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import ArrowDownIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import * as colors from "material-ui/styles/colors";

const size = "2.2rem";

const styles = {
  rootDiv: isButtonHovered => ({
    background: isButtonHovered ? colors.grey100 : "unset",
    width: size,
    height: size,
    border: `1px solid ${colors.grey300}`,
    borderRadius: "4px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  }),
  iconStyles: {
    height: "28px",
    width: "28px",
    color: colors.grey500,
  },
};
class SquareIconButton extends React.Component {
  state = {
    isButtonHovered: false,
  };

  render() {
    return (
      <div
        onMouseEnter={this.onButtonEnter}
        onMouseLeave={this.onButtonLeave}
        style={{
          ...styles.rootDiv(this.state.isButtonHovered),
          ...(this.props.style ? this.props.style : {}),
        }}
        onClick={this.props.onButtonClick}
        title={this.props.title}
      >
        {this.getIcon()}
      </div>
    );
  }

  getIcon = () => {
    const Icon = this.props.open ? ArrowUpIcon : ArrowDownIcon;
    return (
      <Icon
        style={{
          ...styles.iconStyles,
          ...(this.props.iconStyles ? this.props.iconStyles : {}),
        }}
      />
    );
  };

  onButtonEnter = () => this.setState(() => ({isButtonHovered: true}));
  onButtonLeave = () => this.setState(() => ({isButtonHovered: false}));
}

export default SquareIconButton;
