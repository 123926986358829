import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function initDocument(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_UPLOAD_TO_API,
    (organisationId, projectId, documentId) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}/project/${projectId}` +
            `/document/${documentId}/upload_to_api`,
          {},
          {
            withCredentials: true,
          },
        )
        .then(response => response.body),
  );
}
