import requestor from "requestor";

import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../constants/action_types";

export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_ISSUES_CONFIRM_ALL,
  (organisationId, projectId, documentId) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/project/${projectId}/document/${documentId}/confirm-issues`,
        {withCredentials: true},
      )
      .then(response => response.data),
);
