import React from "react";

// import TextField from "material-ui/TextField";

import Backend from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

import TopicListItem from "./topic_list_item";

export class TopicList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    if (this.props.render === false) {
      return <div />;
    }
    return (
      <div
        className="topics"
        style={{
          width: "100%",
        }}
      >
        <DndProvider backend={Backend}>
          {this.props.topicOrder.map((clauseTopic, order) =>
            this.renderTopic(clauseTopic, order),
          )}
        </DndProvider>
      </div>
    );
  }

  renderTopic(clauseTopic, order) {
    const topic = this.props.topicsById[clauseTopic.topic_id];
    return (
      <TopicListItem
        clauseTopic={clauseTopic}
        topic={topic}
        order={order}
        key={clauseTopic.topic_id}
        onTopicsReordered={this.onTopicsReordered}
      />
    );
  }

  /* eslint-disable no-invalid-this */
  onTopicsReordered = (itemIndex, newPosition) => {
    const element = this.props.topicOrder[itemIndex];
    const topicOrder = [...this.props.topicOrder];
    topicOrder.splice(itemIndex, 1);
    topicOrder.splice(newPosition, 0, element);
    this.props.onChange(topicOrder);
  };
  /* eslint-enable no-invalid-this */
}

export default TopicList;
