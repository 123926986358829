import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";

const styles = {
  dialogContent: {
    position: "absolute",
    left: "50%",
    top: "30%",
    transform: "translate(-50%, -30%)",
  },
};

class CreatePermissionGroupDialog extends React.Component {
  state = {name: "", nameError: ""};
  render() {
    return (
      <Dialog
        title="Create new permission group"
        onDismiss={this.props.onHideCreateDialog}
        open={this.props.createDialogShown}
        actions={this.getActions()}
        contentStyle={styles.dialogContent}
      >
        <TextField
          type="text"
          floatingLabelText="Group Name"
          errorText={this.state.nameError}
          value={this.state.name}
          fullWidth={true}
          onChange={this.onNameChange}
          autoFocus
        />
      </Dialog>
    );
  }

  getActions = () => {
    return [
      <FlatButton
        label="Cancel"
        key="create-permission-group-dialog-cancel-button"
        secondary={true}
        onClick={this.props.onHideCreateDialog}
      />,
      <FlatButton
        label="Create"
        key="create-permission-group-dialog-create-button"
        primary={true}
        onClick={this.addNewGroupHandler}
      />,
    ];
  };

  onNameChange = e => this.setState({name: e.target.value, nameError: ""});

  addNewGroupHandler = () => {
    const {name} = this.state;
    if (!name.length) {
      return this.setState({nameError: "This field is required"});
    }
    if (this.nameAlreadyExists(name)) {
      return this.setState({nameError: "This name is already used"});
    }
    this.setState({name: "", nameError: ""});
    this.props.addPermissionGroup(name);
    this.props.onHideCreateDialog();
  };

  nameAlreadyExists = name => {
    return this.props.usedNames.includes(name);
  };
}

CreatePermissionGroupDialog.propTypes = {
  usedNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onHideCreateDialog: PropTypes.func.isRequired,
  createDialogShown: PropTypes.bool.isRequired,
  addPermissionGroup: PropTypes.func.isRequired,
};

export default CreatePermissionGroupDialog;
