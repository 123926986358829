import React from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

function IconMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = handler => () => {
    handleClose();
    if (handler) {
      handler();
    }
  };

  const Icon = props.icon || MoreVertIcon;
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-haspopup="true"
        onClick={handleClick}
        size={props.buttonSize}
      >
        <Icon style={{...(props.iconStyle ? props.iconStyle : {})}} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={props.MenuListProps}
      >
        {props.menuItems
          ? props.menuItems.map(menuItem =>
              React.cloneElement(menuItem, {
                onClick: onMenuItemClick(menuItem.props.onClick),
              }),
            )
          : (props.options || []).map((option, index) => (
              <MenuItem
                key={`${option.label}_${index}`}
                onClick={onMenuItemClick(option.handler)}
              >
                {option.label}
              </MenuItem>
            ))}
      </Menu>
    </div>
  );
}

IconMenu.propTypes = {
  options: PropTypes.array,
  menuItems: PropTypes.array,
  icon: PropTypes.elementType,
  iconStyle: PropTypes.object,
  buttonSize: PropTypes.string,
};

export default IconMenu;
