import React from "react";
import _ from "underscore";

import LockIcon from "@material-ui/icons/Lock";
import BarChartIcon from "@material-ui/icons/BarChart";
import UnlockIcon from "@material-ui/icons/LockOpen";
import RefreshIcon from "@material-ui/icons/Refresh";
import InfoIcon from "@material-ui/icons/Info";
import RedoIcon from "@material-ui/icons/Redo";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import TextField from "material-ui/TextField";
import Checkbox from "material-ui/Checkbox";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import Toggle from "material-ui/Toggle";
import * as colors from "material-ui/styles/colors";

import uuid from "../../../utils/uuid";
import ClauseTester from "./clause_tester";

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";

import getUsedRegexModules from "./utils/get_used_regex_modules";
import TextFieldClassifierOption from "./text_field_classifier_option";
import ClassifierStateLogs from "./classifier_state_logs";

const styles = {
  usedModulesHeaderColumn: {
    color: "#000",
    fontWeight: "600",
  },
  usedModulesRow: {
    height: "24px",
  },
  usedModulesColumn: {
    height: "inherit",
  },
};

export default class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredRegexIndex: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedConfigurationId === prevState.selectedConfigurationId
    ) {
      const currentSelectedClassifier = this.getSelectedClassifier();
      if (
        currentSelectedClassifier &&
        currentSelectedClassifier.configuration_name !==
          this.props.selectedConfigurationName
      ) {
        this.setSelectedConfigurationName(
          currentSelectedClassifier.configuration_name,
        );
      }
    }
  }

  render() {
    const {classifiers} = this.props;
    const {selectedClassifierId, selectedConfigurationName} = this.props;
    const selectedClassifier = this.getSelectedClassifier();
    const configurations = classifiers.filter(
      classifier => classifier.id === selectedClassifierId,
    );
    const selectedConfiguration =
      configurations.find(
        config => config.configuration_name === selectedConfigurationName,
      ) || configurations[0];
    const {classifier_state_logs: logs} = this.props.topic;
    const hasChange =
      !_.isEqual(
        {
          omit_definitions: selectedClassifier.omit_definitions,
          omit_definition_indicator:
            selectedClassifier.omit_definition_indicator,
          omit_headings: selectedClassifier.omit_headings,
          ..._.omit(selectedClassifier.value, ["whitelistRe", "blacklistRe"]),
        },
        _.omit(selectedClassifier.last_value, ["whitelistRe", "blacklistRe"]),
      ) || selectedClassifier.force_update;
    return (
      <div
        style={{
          border: "1px solid #888",
          padding: "1em",
          margin: "1em",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "top",
            }}
          >
            <h2
              style={{
                display: "inline-block",
                marginTop: 30,
              }}
            >
              Settings
            </h2>
            <SelectField
              style={{margin: "0 1em"}}
              floatingLabelText="Classifier"
              value={selectedClassifierId}
              onChange={this.setClassifier}
            >
              {_.uniq(classifiers, classifier => classifier.id).map(
                classifier => (
                  <MenuItem
                    key={classifier.id}
                    value={classifier.id}
                    primaryText={classifier.name}
                  />
                ),
              )}
            </SelectField>
            <SelectField
              style={{margin: "0 1em"}}
              floatingLabelText="Configuration"
              value={selectedConfiguration.configuration_name}
              onChange={this.setConfiguration}
            >
              {configurations
                .map(classifier => (
                  <MenuItem
                    key={classifier.configuration_name}
                    value={classifier.configuration_name}
                    primaryText={classifier.configuration_name}
                  />
                ))
                .concat([
                  <MenuItem
                    key="new"
                    value="new"
                    primaryText="Create New Configuration"
                  />,
                ])}
            </SelectField>
            <div
              style={{
                display: "flex",
                marginTop: "2rem",
              }}
            >
              <IconButton
                size="small"
                onClick={this.lockSettings}
                style={{height: "2rem"}}
              >
                {!hasChange ? <LockIcon /> : <UnlockIcon />}
              </IconButton>
              {this.renderJobStatus()}
              {this.renderRedeploy()}
              <Tooltip placement="top" title="Refresh using Live Classifier">
                <IconButton
                  onClick={this.onRefreshClick}
                  size="small"
                  style={{height: "2rem"}}
                  disabled={
                    !selectedConfiguration.creation_date ||
                    Boolean(this.props.classifierRefreshPending)
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {logs &&
            logs.length > 0 && (
              <ClassifierStateLogs
                logs={logs}
                submitRegex={this.addNewRegexOnTextClassifiedSettings(
                  selectedClassifier,
                )}
                classifier={selectedClassifier}
                hoveredRegexIndex={this.state.hoveredRegexIndex}
              />
            )}
        </div>
        <hr />
        <div
          style={{
            padding: "1em",
          }}
        >
          <TextField
            key={selectedClassifier.configuration_name}
            floatingLabelText="name"
            defaultValue={selectedClassifier.configuration_name}
            onBlur={this.updateConfigurationName}
          />
          <Toggle
            label="Use Definitions"
            toggled={!selectedClassifier.omit_definitions}
            style={{width: "14em"}}
            onToggle={this.toggleOmitDefinitions}
          />
          <Toggle
            label="Use Definition Indicators"
            toggled={!selectedClassifier.omit_definition_indicator}
            style={{width: "14em"}}
            onToggle={this.toggleOmitDefinitionIndicator}
          />
          <Toggle
            label="Use Headings"
            toggled={!selectedClassifier.omit_headings}
            style={{width: "14em"}}
            onToggle={this.toggleOmitHeadings}
          />

          {this.renderClassifierOptions(selectedClassifier)}
        </div>
        <ClauseTester
          testClause={this.testClause}
          topic={this.props.topic}
          selectedClassifier={selectedClassifier}
          getJobLogs={this.getJobLogs}
          fetchLogs={selectedClassifier.name === "sagemaker"}
        />
      </div>
    );
  }

  getJobLogs = () => {
    const selectedClassifier = this.getSelectedClassifier();
    this.props.getJobLogs(
      selectedClassifier.name,
      selectedClassifier.configuration_id,
      selectedClassifier.current_topic_trainingnode_id,
    );
  };

  renderJobStatus = () => {
    if (
      this.props.selectedClassifierName === "sagemaker" &&
      this.props.selectedTopicTrainingNodeId
    ) {
      const selectedClassifier = this.getSelectedClassifier();
      const getData = () =>
        this.props.getJobInformation(
          this.props.selectedClassifierName,
          this.props.selectedConfigurationId,
          this.props.selectedTopicTrainingNodeId,
        );
      if (!selectedClassifier.is_deployment_completed) {
        if (selectedClassifier.training_state) {
          return (
            <Tooltip title={JSON.stringify(selectedClassifier.training_state)}>
              <IconButton
                onClick={getData}
                style={{height: "2rem"}}
                size="small"
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          );
        }
        return (
          <Tooltip title="Click to get deployment status">
            <IconButton onClick={getData} style={{height: "2rem"}} size="small">
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        );
      }
    }
    return null;
  };

  renderRedeploy = () => {
    if (
      this.props.selectedClassifierName === "sagemaker" &&
      this.props.selectedTopicTrainingNodeId
    ) {
      const redeployClassifier = () =>
        this.props.onRedeployClassifier(
          this.props.selectedClassifierName,
          this.props.selectedConfigurationId,
          this.props.selectedTopicTrainingNodeId,
        );
      return (
        <Tooltip title="Redeploy classifier">
          <IconButton
            onClick={redeployClassifier}
            style={{height: "2rem"}}
            size="small"
          >
            <RedoIcon />
          </IconButton>
        </Tooltip>
      );
    }
    return null;
  };

  testClause = text => {
    this.props.testClause(
      this.props.selectedConfigurationId,
      this.props.selectedTopicTrainingNodeId,
      text,
    );
  };

  renderUsedRegexModules = classifier => {
    const usedModulesData = getUsedRegexModules(
      this.props.templateModules,
      classifier,
    );
    if (usedModulesData.length === 0) {
      return null;
    }
    return (
      <div>
        <h3 style={{marginBottom: 5}}>Used modules</h3>
        <Table selectable={false}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow
              style={{
                ...styles.usedModulesRow,
              }}
            >
              <TableHeaderColumn
                style={{
                  ...styles.usedModulesColumn,
                  ...styles.usedModulesHeaderColumn,
                  width: "15%",
                }}
              >
                Key
              </TableHeaderColumn>
              <TableHeaderColumn
                style={{
                  ...styles.usedModulesColumn,
                  ...styles.usedModulesHeaderColumn,
                }}
              >
                Value
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false} showRowHover={true}>
            {usedModulesData.map((usedModule, index) => (
              <TableRow
                key={index}
                style={{
                  ...styles.usedModulesRow,
                  backgroundColor: usedModule.id ? "unset" : colors.red50,
                }}
              >
                <TableRowColumn
                  style={{
                    ...styles.usedModulesColumn,
                    width: "15%",
                  }}
                >
                  {usedModule.key}
                </TableRowColumn>
                <TableRowColumn style={styles.usedModulesColumn}>
                  {usedModule.value.join(", ")}
                </TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  renderClassifierOptions(classifier) {
    const optionsLength = (classifier.options || []).length;
    const isRegexClassifier = classifier.name === "regex";
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isRegexClassifier && (
          <div title="Fetch Stats For All Regexes">
            <BarChartIcon
              onClick={this.props.getRegexClassifierStatsGradually}
              style={{
                cursor: "pointer",
                color: "#616161",
                position: "absolute",
                right: 0,
                top: 22,
                zIndex: 999,
              }}
            />
          </div>
        )}
        {isRegexClassifier && this.renderUsedRegexModules(classifier)}
        {!isRegexClassifier &&
          this.renderNumberField(
            {
              ...classifier,
              default_value: {negativeAmount: 10},
            },
            {
              name: "negativeAmount",
              description:
                "How many negative samples to use when training (compared to positive samples)",
            },
          )}
        {!isRegexClassifier &&
          this.renderBooleanField(
            {
              ...classifier,
              default_value: {omitRandomNegativeData: false},
            },
            {
              name: "omitNegativeTopicData",
              description:
                "Whether it should omit random negative data and only use specified negative topics",
            },
          )}
        {classifier.options.map((option, optionIndex) => {
          if (!classifier.value) {
            return null;
          }
          if (option.type === "regex_strings") {
            if (!classifier.value[this.getNameId(option.name)]) {
              this.updateClassifierSettings(
                classifier,
                option.name,
                classifier.value[option.name],
              );
            }
            const focusLastItem = optionIndex + 1 === optionsLength;
            return this.renderTextField(classifier, option, focusLastItem);
          }
          if (option.type === "number") {
            return this.renderNumberField(classifier, option);
          }
          if (option.type === "list") {
            return this.renderListField(classifier, option);
          }
          return null;
        })}
      </div>
    );
  }

  renderTextField(classifier, option, focusLastItem) {
    const {name} = option;
    return (
      <TextFieldClassifierOption
        key={name}
        classifier={classifier}
        option={option}
        focusLastItem={focusLastItem}
        hoveredRegexIndex={this.state.hoveredRegexIndex}
        templateModules={this.props.templateModules}
        isInFixMode={this.props.isInFixMode}
        selectedRegex={this.props.selectedRegex}
        processFpsRegex={this.props.processFpsRegex}
        selectRegex={this.props.selectRegex}
        unselectRegex={this.props.unselectRegex}
        onRegexItemHover={this.onRegexItemHover}
        onRegexItemHoverEnd={this.onRegexItemHoverEnd}
        removeTextClassifierSettingsRow={this.removeTextClassifierSettingsRow}
        addNewRowOnTextClassifiedSettings={
          this.addNewRowOnTextClassifiedSettings
        }
        updateClassifierSettings={this.updateClassifierSettings}
        classifierRefreshPending={this.props.classifierRefreshPending}
        onRegexChange={this.props.setRegexWasChanged}
        refreshTopicRegexClassifierStats={
          this.props.refreshTopicRegexClassifierStats
        }
      />
    );
  }

  onRegexItemHover = index => () =>
    this.setState(() => ({hoveredRegexIndex: index}));
  onRegexItemHoverEnd = () => this.setState(() => ({hoveredRegexIndex: null}));

  renderNumberField(classifier, option) {
    const {name, description} = option;
    const value = classifier?.value?.[name] ?? classifier.default_value[name];
    return (
      <div key={name}>
        <TextField
          floatingLabelText={this.tidyName(name)}
          defaultValue={value}
          onBlur={this.updateNumberClassifierSettings(classifier, name)}
          hintText={description}
          style={{width: "100%"}}
        />
      </div>
    );
  }

  renderBooleanField(classifier, option) {
    const {name, description} = option;
    const value = classifier?.value?.[name] ?? classifier.default_value[name];
    return (
      <div key={name}>
        <Checkbox
          label={this.tidyName(name)}
          checked={value}
          onCheck={this.updateBooleanClassifierSettings(classifier, name)}
          hintText={description}
          style={{width: "100%"}}
        />
      </div>
    );
  }

  renderListField(classifier, option) {
    const {name} = option;
    return (
      <div key={name}>
        <SelectField
          floatingLabelText={this.tidyName(name)}
          value={(classifier.value && classifier.value[name]) || ""}
          onChange={this.updateListClassifierSettings(classifier, name)}
          style={{width: "100%"}}
        >
          {option.options.map(value => (
            <MenuItem key={value} value={value} primaryText={value} />
          ))}
        </SelectField>
      </div>
    );
  }

  // change from aaaBbb to Aaa Bbb
  tidyName(name) {
    return name.replace(/([A-Z])/g, " $1").replace(/^./, x => x.toUpperCase());
  }

  getNameId = name =>
    name === "whitelist"
      ? "whitelist_ids"
      : name === "blacklist" ? "blacklist_ids" : false;

  getSelectedClassifier() {
    return this.props.getSelectedClassifier();
  }

  /* eslint-disable no-invalid-this */
  setConfiguration = (event, index, selectedConfigurationName) => {
    if (selectedConfigurationName === "new") {
      this.onCreateConfiguration();
    } else {
      const selectedClassifier = this.props.classifiers.find(
        classifier =>
          classifier.id === this.props.selectedClassifierId &&
          classifier.configuration_name === selectedConfigurationName,
      );

      this.setSelectedConfiguration(
        selectedClassifier.configuration_id,
        selectedConfigurationName,
      );
    }
  };

  onCreateConfiguration() {
    this.props.onTopicClassifierCreated(this.props.selectedClassifierId);
  }

  updateConfigurationName = event => {
    const {value} = event.target;
    const classifier = this.getSelectedClassifier();
    this.props.onTopicClassifierUpdated(classifier, {
      configuration_name: value,
    });
    // this.setState({selectedConfigurationName: value});
  };
  toggleOmitDefinitions = () => {
    const classifier = this.getSelectedClassifier();
    this.props.onTopicClassifierUpdated(classifier, {
      omit_definitions: !classifier.omit_definitions,
    });
  };
  toggleOmitDefinitionIndicator = () => {
    const classifier = this.getSelectedClassifier();
    this.props.onTopicClassifierUpdated(classifier, {
      omit_definition_indicator: !classifier.omit_definition_indicator,
    });
  };
  toggleOmitHeadings = () => {
    const classifier = this.getSelectedClassifier();
    this.props.onTopicClassifierUpdated(classifier, {
      omit_headings: !classifier.omit_headings,
    });
  };
  lockSettings = () => {
    const classifier = this.getSelectedClassifier();
    this.props.onLockSettings(
      classifier.id,
      classifier.configuration_id,
      classifier.last_edited,
    );
  };
  onRefreshClick = () => {
    this.props.rerunClassifier(
      this.props.selectedConfigurationId,
      this.props.selectedClassifierName,
      this.props.selectedTopicTrainingNodeId,
      false, // processSingleUsage
      true, // useLiveClassifier
    );
  };
  setClassifier = (event, index, selectedClassifierId) => {
    const selectedClassifier = this.props.classifiers.find(
      classifier => classifier.id === selectedClassifierId,
    );
    this.props.setSelectedClassifier(selectedClassifier);
  };

  removeTextClassifierSettingsRow = _.memoize(
    (classifier, parameterName, removeRegexIndex) => () => {
      return this.updateClassifierSettings(
        classifier,
        parameterName,
        classifier.value[parameterName].filter(
          (regexValue, index) => removeRegexIndex !== index,
        ),
        "remove",
        removeRegexIndex,
      );
    },
    (...args) =>
      JSON.stringify([_.pick(args[0], "id", "last_edited"), args[1], args[2]]),
  );

  addNewRowOnTextClassifiedSettings = (classifier, parameterName) => {
    return event => {
      const {value} = event.target;
      if (!value) {
        return;
      }
      this.updateClassifierSettings(
        classifier,
        parameterName,
        Array.isArray(classifier.value[parameterName])
          ? classifier.value[parameterName].concat(value)
          : [].concat(value),
        "add",
      );
      event.target.value = "";
    };
  };

  addNewRegexOnTextClassifiedSettings = _.memoize(
    classifier => {
      return (parameterName, value) =>
        this.updateClassifierSettings(
          classifier,
          parameterName,
          Array.isArray(classifier.value[parameterName])
            ? classifier.value[parameterName].concat(value)
            : [].concat(value),
        );
    },
    (...args) =>
      JSON.stringify([_.pick(args[0], "id", "last_edited"), args[1]]),
  );

  updateNumberClassifierSettings = _.memoize(
    (classifier, parameterName) => {
      return event => {
        const {value} = event.target;
        if (value === "") {
          return this.updateClassifierSettings(classifier, parameterName, null);
        }
        const floatValue = parseFloat(value);
        if (floatValue.toString() === value) {
          return this.updateClassifierSettings(
            classifier,
            parameterName,
            floatValue,
          );
        }
        return null;
      };
    },
    (...args) =>
      JSON.stringify([_.pick(args[0], "id", "last_edited"), args[1]]),
  );

  updateBooleanClassifierSettings = _.memoize(
    (classifier, parameterName) => {
      return event => {
        const {checked} = event.target;
        return this.updateClassifierSettings(
          classifier,
          parameterName,
          checked,
        );
      };
    },
    (...args) =>
      JSON.stringify([_.pick(args[0], "id", "last_edited"), args[1]]),
  );
  updateListClassifierSettings = _.memoize(
    (classifier, parameterName) => {
      return (event, index, value) =>
        this.updateClassifierSettings(classifier, parameterName, value);
    },
    (...args) =>
      JSON.stringify([_.pick(args[0], "id", "last_edited"), args[1]]),
  );
  /* eslint-enable no-invalid-this */
  updateClassifierSettings = (
    classifier,
    parameterName,
    value,
    type,
    index,
    returnedIds,
  ) => {
    const defaultKeys = _.object(
      classifier.options.map(option => [
        option.name,
        this.getDefautValue(option),
      ]),
    );
    const newObject = {
      ...defaultKeys,
      ...classifier.value,
      [parameterName]: value,
    };

    if (classifier.name === "regex") {
      newObject.whitelist_ids = this.addIdList(
        classifier.value?.whitelist_ids,
        classifier.value.whitelist,
      );
      newObject.blacklist_ids = this.addIdList(
        classifier.value?.blacklist_ids,
        classifier.value.blacklist,
      );
      if (type === "add") {
        if (parameterName === "whitelist") {
          newObject.whitelist_ids = [...newObject.whitelist_ids, uuid()];
        }
        if (parameterName === "blacklist") {
          newObject.blacklist_ids = [...newObject.blacklist_ids, uuid()];
        }
      }
      if (type === "remove") {
        if (parameterName === "whitelist") {
          newObject.whitelist_ids = this.filterIdList(
            newObject.whitelist_ids,
            index,
          );
        }
        if (parameterName === "blacklist") {
          newObject.blacklist_ids = this.filterIdList(
            newObject.blacklist_ids,
            index,
          );
        }
      }
      if (type === "return") {
        if (parameterName === "whitelist") {
          newObject.whitelist_ids = returnedIds;
        }
        if (parameterName === "blacklist") {
          newObject.blacklist_ids = returnedIds;
        }
      }
      delete newObject.whitelistRe;
      delete newObject.blacklistRe;
    }
    if (value === null) {
      delete newObject[parameterName];
    }
    return this.props.onTopicClassifierUpdated(classifier, {value: newObject});
  };

  filterIdList = (listIds, index) =>
    listIds.filter((regValue, regIndex) => index !== regIndex);

  addIdList = (listIds, list) =>
    listIds && listIds.length ? listIds : list.map(() => uuid());

  getDefautValue(option) {
    switch (option.type) {
      case "list":
        return [];
      case "regex_strings":
        return "";
      case "number":
        return 0;
    }
    return null;
  }
}
