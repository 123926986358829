import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";

import TaskListItem from "./task_list_item";

import Backend from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

import Paper from "material-ui/Paper";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import Dialog from "material-ui/Dialog";
import {Toolbar, ToolbarGroup, ToolbarTitle} from "material-ui/Toolbar";

class TaskList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showDeleteTaskDialogue: false};
  }

  render() {
    return (
      <Paper
        style={{
          width: "30em",
          margin: "0 1em",
        }}
      >
        <Toolbar className="app-toolbar">
          <ToolbarGroup key={0}>
            <ToolbarTitle text="Tasks" />
          </ToolbarGroup>
          <ToolbarGroup key={1}>
            <RaisedButton
              className="create-task"
              primary={true}
              onClick={this.props.onTaskCreated}
            >
              Add Task
            </RaisedButton>
          </ToolbarGroup>
        </Toolbar>
        <div
          style={{
            padding: "1em 0em",
          }}
        >
          <DndProvider backend={Backend}>
            {this.renderTaskListItems()}
          </DndProvider>
        </div>

        <Dialog
          title="Confirm task deletion"
          open={this.state.showDeleteTaskDialogue}
          actions={[
            <FlatButton
              label="Cancel"
              key="task-deletion-dialog-cancel-button"
              secondary={true}
              keyboardFocused={true}
              onClick={this.hideDeleteDialogue}
            />,
            <FlatButton
              label="Delete Task"
              key="tack-deletion-dialog-delete-button"
              primary={true}
              onClick={this.deleteTask}
            />,
          ]}
          modal={true}
        >
          Are you sure you want to delete this task?
        </Dialog>
      </Paper>
    );
  }

  renderTaskListItems() {
    const {tasks, highlightedTaskId} = this.props;
    return _.chain(tasks)
      .sortBy(task => task.order)
      .map(task => (
        <TaskListItem
          key={task.id}
          task={task}
          isHighlighted={task.id === highlightedTaskId}
          onTasksReordered={this.onTasksReordered}
          onTaskSelected={this.onTaskSelected(task.id)}
          onTaskDeleted={() => this.showDeleteTaskDialogue(task)}
        />
      ))
      .value();
  }

  showDeleteTaskDialogue(deletingTask) {
    this.setState({
      deletingTask,
      showDeleteTaskDialogue: true,
    });
  }

  /* eslint-disable no-invalid-this */
  hideDeleteDialogue = () => {
    this.setState({showDeleteTaskDialogue: false});
  };

  deleteTask = () => {
    const {id, last_edited: lastEdited} = this.state.deletingTask;
    this.setState({showDeleteTaskDialogue: true});
    this.props.onTaskDeleted(id, lastEdited);
  };

  onTasksReordered = (...args) => {
    this.props.onTasksReordered(...args);
  };

  onTaskSelected = _.memoize(taskId => (...args) => {
    this.props.onTaskSelected(taskId, ...args);
  });
  /* eslint-enable no-invalid-this */
}

TaskList.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      last_edited: PropTypes.string.isRequired,
      custom_values: PropTypes.shape({
        clause_reference: PropTypes.string,
      }),
    }),
  ).isRequired,
  highlightedTaskId: PropTypes.number,
  onTaskCreated: PropTypes.func.isRequired,
  onTaskSelected: PropTypes.func.isRequired,
  onTaskDeleted: PropTypes.func.isRequired,
  onTasksReordered: PropTypes.func.isRequired,
};

export default TaskList;
export {TaskList};
