export default function calculateTopicIdsFromMasks(rules) {
  if (typeof rules === "number") {
    return [rules];
  }
  if (Array.isArray(rules)) {
    return rules
      .map(rule => calculateTopicIdsFromMasks(rule))
      .reduce((a, b) => [...a, ...b], []);
  }
  if (typeof rules === "object") {
    return rules.rules
      .map(rule => calculateTopicIdsFromMasks(rule))
      .reduce((a, b) => [...a, ...b], []);
  }
  return [];
}
