import React from "react";
import PropTypes from "prop-types";

import keyedObjectPropType from "utils/keyed_object_prop_type";
import getInitials from "../utils/get_party_initials";

class RoleItem extends React.Component {
  render() {
    return (
      <span
        title={this.props.title}
        style={{
          display: "inline-block",
          backgroundColor: "#eee",
          margin: "0.5em",
          padding: "0.5em",
          borderRadius: "0.25em",
        }}
      >
        <span className="party-name">{this.props.partyString}</span>
        <span
          className="role-name"
          style={{
            backgroundColor: "#80cbc4",
            margin: "0.25em",
            padding: "0.1em 0.25em",
            borderRadius: "0.25em",
          }}
        >
          {getInitials(this.props.taskrole.name)}
        </span>
      </span>
    );
  }
}

class SingleRoleItem extends RoleItem {
  render() {
    const {taskrole, role, parties} = this.props;
    const partyName = parties[role.party_id].name;
    return (
      <RoleItem
        className="role-item"
        title={`${partyName} - ${taskrole.name}`}
        partyString={partyName}
        taskrole={taskrole}
      />
    );
  }
}

class MultiRoleItem extends React.Component {
  render() {
    const {taskrole, role, parties} = this.props;
    const partyString = role.party_data
      .map(partyData => parties[partyData.id].name)
      .join(", ");
    const title = `${partyString} - ${taskrole.name}`;
    return (
      <RoleItem
        className="role-item"
        title={title}
        partyString={partyString}
        taskrole={taskrole}
      />
    );
  }
}

class RoleSummary extends React.Component {
  render() {
    return <div className="icons">{this.renderRoleIcons()}</div>;
  }

  renderRoleIcons() {
    return this.props.activeRoles.map(role =>
      this.renderRoleIcon(this.props.taskroles[role.id], role),
    );
  }

  renderRoleIcon(taskrole, role) {
    switch (taskrole.type) {
      case "single":
        return (
          <SingleRoleItem
            className="icon"
            key={role.id}
            taskrole={taskrole}
            role={role}
            parties={this.props.parties}
          />
        );
      case "multi":
        return (
          <MultiRoleItem
            className="icon"
            key={role.id}
            taskrole={taskrole}
            role={role}
            parties={this.props.parties}
          />
        );
    }
    return null;
  }
}

RoleSummary.propTypes = {
  activeRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      party_id: PropTypes.number,
      party_data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  taskroles: keyedObjectPropType(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  parties: keyedObjectPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default RoleSummary;
