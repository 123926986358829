import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import LoadingCircular from "common_components/loading_circular";
import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import Component from "../components";

function AnalyticsContainer(props) {
  const propsWereLoaded = isInitialised([props.user, props.analytics]);
  if (!propsWereLoaded) {
    return <LoadingCircular />;
  }

  const permissioner = new Permissioner(props.user);
  if (!permissioner.isAdmin()) {
    return permissioner.getNoPermissionMessage();
  }

  return <Component {...props} />;
}

function select(state) {
  return {
    user: state.user,
    analytics: state.analytics,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(select, mapDispatchToProps)(AnalyticsContainer);
