import React from "react";
import {FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";

import {ValueType} from "common/flowmaster/types/data_location";

interface TypeSelectorProps {
  type: ValueType;
  onSetType: (value: ValueType) => void;
}
const TypeSelector: React.FC<TypeSelectorProps> = ({type, onSetType}) => {
  return (
    <FormControl variant="outlined" style={{minWidth: "7em"}}>
      <InputLabel id="type-selector-label">Source</InputLabel>
      <Select
        labelId="type-selector-label"
        value={type}
        onChange={event => onSetType(event.target.value as ValueType)}
        label="Input"
        style={{textTransform: "capitalize"}}
      >
        {["literal", "input", "none"].map(valueType => (
          <MenuItem
            value={valueType}
            key={valueType}
            style={{textTransform: "capitalize"}}
          >
            {valueType}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TypeSelector;
