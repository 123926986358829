import React from "react";
import requestor from "requestor";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";

import addPermissionGroup from "modules/permissions/actions/permission_group_add";
import updatePermissionGroup from "modules/permissions/actions/permission_group_update";
import addPermissionGroupPermission from "modules/permissions/actions/permission_group_permission_add";
import deletePermissionGroupPermission from "modules/permissions/actions/permission_group_permission_delete";

import PermissionsComponent from "../components/permissions";

class Permissions extends React.Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return this.permissioner.isAdmin();
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.user,
      this.props.permissions,
      this.props.permission_groups,
      this.props.permission_group_permissions,
    ]);
  }

  renderComponent() {
    setTitle("Permissions");
    return (
      <PermissionsComponent
        user={this.props.user}
        permissions={this.props.permissions}
        permission_groups={this.props.permission_groups}
        permission_group_permissions={this.props.permission_group_permissions}
        addPermissionGroup={this.props.addPermissionGroup}
        updatePermissionGroup={this.props.updatePermissionGroup}
        addPermissionGroupPermission={this.props.addPermissionGroupPermission}
        deletePermissionGroupPermission={
          this.props.deletePermissionGroupPermission
        }
      />
    );
  }
}

function select(state) {
  return {
    user: state.user,
    permissions: state.permissions,
    permission_groups: state.permission_groups,
    permission_group_permissions: state.permission_group_permissions,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addPermissionGroup: addPermissionGroup(requestor),
      updatePermissionGroup: updatePermissionGroup(requestor),
      addPermissionGroupPermission: addPermissionGroupPermission(requestor),
      deletePermissionGroupPermission: deletePermissionGroupPermission(
        requestor,
      ),
    },
    dispatch,
  );
}

export default connect(select, mapDispatchToProps)(Permissions);
export const Component = Permissions;
