import React from "react";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import setTitle from "utils/set_title";
import Permissioner from "utils/permissioner";

import requestor from "requestor";

import ContractTypeDetailComponent from "../components/contract_type_detail";

import updateContractTypeAction from "modules/documents/actions/contract_type_update";
import removeContractTypeAction from "modules/documents/actions/contract_type_remove";
import addIssuesetAction from "modules/documents/actions/issueset/issueset_add";
import updateIssuesetAction from "modules/documents/actions/issueset/issueset_update";
import removeIssuesetAction from "modules/documents/actions/issueset/issueset_remove";

class ContractTypeDetailContainer extends React.Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return (
      this.permissioner.isAdmin() ||
      this.permissioner.hasPermission("update-contract-type")
    );
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.contractTypes,
      this.props.user,
      this.props.contractType,
    ]);
  }

  renderComponent() {
    setTitle("Update Contract Type");
    return (
      <div className="app-container">
        <ContractTypeDetailComponent
          contractType={this.props.contractType}
          contractTypes={this.props.contractTypes}
          onContractTypeUpdate={this.onContractTypeUpdate}
          onContractTypeRemove={this.onContractTypeRemove}
          onIssuesetAdd={this.onIssuesetAdd}
          onIssuesetUpdate={this.onIssuesetUpdate}
          onIssuesetRemove={this.onIssuesetRemove}
          redirectToContractTypesList={this.redirectToContractTypesList}
        />
      </div>
    );
  }

  onContractTypeUpdate = data => {
    const {contractType} = this.props;
    this.props.updateContractType(
      this.props.params.organisationId,
      contractType.id,
      {
        ...data,
        last_edited: this.props.contractType.last_edited,
      },
      contractType.last_edited,
    );
  };

  onContractTypeRemove = () => {
    const {params, contractType} = this.props;
    this.props.removeContractType(
      params.organisationId,
      contractType.id,
      contractType.last_edited,
    );
    this.redirectToContractTypesList();
  };

  onIssuesetAdd = () => {
    const {contractType} = this.props;
    let maxNewIssuesetIndex = 0;
    contractType.issuesets.forEach(is => {
      if (is.name.startsWith("New Issueset")) {
        const newIssuesetIndex = parseInt(is.name.substr(13), 10);
        maxNewIssuesetIndex =
          newIssuesetIndex > maxNewIssuesetIndex
            ? newIssuesetIndex
            : maxNewIssuesetIndex;
      }
    });
    this.props.addIssueset(this.props.params.organisationId, contractType.id, {
      name: `New Issueset ${maxNewIssuesetIndex + 1}`,
    });
  };

  onIssuesetRemove = issuesetId => {
    this.props.removeIssueset(
      this.props.params.organisationId,
      this.props.contractType.id,
      issuesetId,
    );
  };

  onIssuesetUpdate = (issuesetId, data) => {
    this.props.updateIssueset(
      this.props.params.organisationId,
      this.props.contractType.id,
      issuesetId,
      data,
    );
  };

  redirectToContractTypesList = () => {
    this.props.dispatch(
      push(
        `/organisation/${this.props.params.organisationId}/contract_type/list`,
      ),
    );
  };
}

function select(state, props) {
  if (!state.contract_types) {
    return {};
  }
  const contractTypeId = parseInt(props.params.contractTypeId, 10);
  return {
    contractType:
      state.contract_types !== UNINITIALISED &&
      state.contract_types.find(
        contractType => contractType.id === contractTypeId,
      ),
    params: props.params,
    router: state.router,
    contractTypes: state.contract_types,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateContractType: updateContractTypeAction(requestor),
        removeContractType: removeContractTypeAction(requestor),
        addIssueset: addIssuesetAction(requestor),
        updateIssueset: updateIssuesetAction(requestor),
        removeIssueset: removeIssuesetAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(ContractTypeDetailContainer);
