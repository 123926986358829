import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function addTopicParameter(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPICPARAMETER_ADD,
    (
      organisationId,
      topicId,
      topicLastEdited,
      parameterName,
      type,
      value,
      username,
      description,
    ) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}/topic/${topicId}/parameter`,
          {
            name: parameterName,
            topic_last_edited: topicLastEdited,
            parameter_type: type,
            value,
            username,
            description,
          },
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
