import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import _ from "lodash";

import TextField from "material-ui/TextField";
import IconButton from "material-ui/IconButton";
import ArrowDropDownIcon from "material-ui/svg-icons/navigation/arrow-drop-down";
import ArrowDropUpIcon from "material-ui/svg-icons/navigation/arrow-drop-up";
import CloseIcon from "material-ui/svg-icons/navigation/close";

import upperFirst from "utils/upper_first";

import Item from "./item";

const styles = {
  root: {
    position: "relative",
    padding: "0 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 73,
    minWidth: 200,
    cursor: "pointer",
    userSelect: "none",
  },
  activeRoot: {
    background: "rgba(0, 0, 0, 0.05)",
  },
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  leftIcon: {
    marginRight: 8,
    transition: "none",
  },
  labelsContainer: {
    flexGrow: 1,
  },
  offsetedTopLabel: {
    fontSize: 13,
  },
  popover: {
    padding: "4px 0",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    maxHeight: 800,
    position: "fixed",
    top: 0,
    left: 0,
    color: "#333",
    backgroundColor: "#fff",
    borderRadius: 2,
    overflow: "hidden",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
    zIndex: 9999,
    transition:
      "transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, " +
      "opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    opacity: 0,
    transform: "scale(0, 0)",
    transformOrigin: "left top",
    width: "fit-content",
  },
  group: {
    padding: "6px 0",
    borderBottom: "1px solid #e0e0e0",
  },
  groupLabel: {
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 12,
    color: "#a8aeb5",
    marginTop: 2,
    marginBottom: 4,
    userSelect: "none",
  },
  selectUnselectGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterGroup: {
    position: "relative",
    padding: "6px 15px",
  },
  filterTextField: {
    height: 30,
    width: "100%",
  },
  filterTextFieldInput: {
    marginTop: 8,
    fontSize: 14,
  },
  filterTextFieldFloatingLabel: {
    top: 7,
    lineHeight: 1,
    color: "#a8aeb5",
  },
  filterTextFieldFloatingShrinkLabel: {
    top: 21,
  },
  filterTextFieldUnderline: {
    top: 38,
  },
  filterTextFieldUnderlineFocus: {
    top: 37,
    width: "120%",
    left: "-10%",
  },
  filterInfo: {
    position: "absolute",
    top: 7,
    right: 0,
    display: "flex",
    alignItems: "center",
    fontSize: 11,
    lineHeight: 1,
    width: "50%",
    paddingRight: 15,
    boxSizing: "border-box",
    justifyContent: "space-between",
    color: "#a8aeb5",
    userSelect: "none",
  },
  filterIconButton: {
    position: "absolute",
    top: 6,
    right: -3,
    transform: "scale(0.7)",
  },
  items: {
    padding: "8px 0",
    overflowY: "auto",
  },
};

export default class ToolbarDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.layer = null;
    this.state = {
      isPopoverShown: false,
      filterText: "",
    };
  }

  componentWillUnmount() {
    if (this.state.isPopoverShown) {
      this.hidePopover();
    }
  }

  componentDidUpdate() {
    if (this.state.isPopoverShown) {
      this.renderPopover();
      this.setPopoverPlacement();
    }
  }

  findChildElementByValue = (value, _children) => {
    const children = React.Children.toArray(_children || this.props.children);
    for (const child of children) {
      if (child.props.value === value) {
        return child;
      }
      if (child.props.children) {
        const foundChild = this.findChildElementByValue(
          value,
          child.props.children,
        );
        if (foundChild) {
          return foundChild;
        }
      }
    }
  };

  showPopover = () => {
    this.layer = document.createElement("div");
    document.body.appendChild(this.layer);
    this.renderPopover();
    this.setState({isPopoverShown: true});
    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("scroll", this.handleWindowScroll);
    setTimeout(() => {
      window.addEventListener("click", this.handleWindowClick);
      this.getPopoverNode().style.opacity = 1;
      this.getPopoverNode().style.transform = "scale(1, 1)";
    }, 0);
  };

  getPopoverNode = () => {
    return _.get(this, "layer.children[0]");
  };

  hidePopover = () => {
    ReactDOM.unmountComponentAtNode(this.layer);
    document.body.removeChild(this.layer);
    this.layer = null;
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("scroll", this.handleWindowScroll);
    window.removeEventListener("click", this.handleWindowClick);
    this.setState({isPopoverShown: false, filterText: ""});
    this.props.handleClose && this.props.handleClose();
  };

  handleWindowResize = () => {
    this.setPopoverPlacement();
  };

  handleWindowScroll = () => {
    this.setPopoverPlacement();
  };

  handleWindowClick = event => {
    if (!this.getPopoverNode().contains(event.target)) {
      this.hidePopover();
    }
  };

  render() {
    const {disabled} = this.props;
    const {isPopoverShown} = this.state;
    const primaryColor = "#333";
    const accentColor = !isPopoverShown ? "#9aa0a7" : "#444";
    const ArrowDrop = !isPopoverShown ? ArrowDropDownIcon : ArrowDropUpIcon;

    let topLabelText = "";
    let labelText = this.props.labelText;
    const topLevelSelectedValues = this.getTopLevelSelectedValues();

    if (topLevelSelectedValues.length) {
      labelText = `${this.props.labelText} (${topLevelSelectedValues.length})`;

      if (topLevelSelectedValues.length === 1) {
        const value = topLevelSelectedValues.shift();
        const element = this.findChildElementByValue(value);
        if (element) {
          topLabelText = this.props.labelText;
          labelText = element.props.label || stringToLabel(value);
        }
      }
    }

    return (
      <div
        ref={this.createRootNodeRef}
        style={{
          ...styles.root,
          ...(isPopoverShown && styles.activeRoot),
        }}
        {...!isPopoverShown && {
          onClick: disabled ? undefined : () => this.showPopover(),
        }}
      >
        <div style={styles.inner}>
          {(() => {
            if (!this.props.leftIcon) {
              return null;
            }
            return React.cloneElement(this.props.leftIcon, {
              style: {
                ...this.props.leftIcon.props.style,
                ...styles.leftIcon,
                color: accentColor,
              },
            });
          })()}
          <div style={styles.labelsContainer}>
            {topLabelText && (
              <div
                style={{
                  ...(labelText && styles.offsetedTopLabel),
                  color: accentColor,
                }}
              >
                {topLabelText}
              </div>
            )}
            <div style={{color: primaryColor}}>{labelText}</div>
          </div>
        </div>
        <ArrowDrop style={{color: accentColor}} />
      </div>
    );
  }

  renderPopover() {
    const element = (
      <div
        style={{
          ...styles.popover,
          ...this.props.popoverStyle,
        }}
        onMouseDown={event => event.preventDefault()}
      >
        {this.renderSelectedItemsList()}
        {this.renderFilterTextField()}
        {this.renderSelectAndUnselectAllItems()}
        {this.renderItems()}
      </div>
    );
    ReactDOM.unstable_renderSubtreeIntoContainer(this, element, this.layer);
  }

  setPopoverPlacement = () => {
    const popoverNode = this.getPopoverNode();
    const rect = this.rootNodeRef && this.rootNodeRef.getBoundingClientRect();
    const regexp = /^([\d]+)(px)*$/;
    const styleMaxHeight = Math.max.apply(
      null,
      [styles.popover.maxHeight, _.get(this.props, "popoverStyle.maxHeight")]
        .filter(value => (value || "").toString().match(regexp))
        .map(value => parseInt(value.toString().replace(regexp, "$1"), 10)),
    );
    const maxHeight = Math.min.apply(null, [
      styleMaxHeight,
      document.querySelector("html").clientHeight - rect.bottom - 15,
    ]);
    popoverNode.style.top = `${rect.bottom}px`;
    popoverNode.style.left = `${rect.left}px`;
    popoverNode.style.maxHeight = `${maxHeight}px`;
  };

  renderSelectAndUnselectAllItems() {
    if (!this.props.showSelectAndUnselectAllItems) {
      return null;
    }
    const filteredValues = this.getChildren().map(child => child.props.value);
    const areAllSelected = filteredValues.every(value =>
      this.props.values.includes(value),
    );
    const areAllUnselected = !filteredValues.find(value =>
      this.props.values.includes(value),
    );
    return (
      <div style={{...styles.group, ...styles.selectUnselectGroup}}>
        <Item
          value="select_all"
          label="Select All"
          isSelected={areAllSelected}
          withCheckbox={true}
          isHoverable={false}
          labelStyle={{height: 22}}
          headerStyle={this.props.itemHeaderStyle}
          handleSelect={() => {
            if (!areAllSelected && !this.props.disabled) {
              this.props.handleChange([
                ...this.props.values,
                ...filteredValues.filter(
                  value => !this.props.values.includes(value),
                ),
              ]);
            }
          }}
        />
        <Item
          value="unselect_all"
          label="Unselect All"
          isSelected={areAllUnselected}
          withCheckbox={true}
          isHoverable={false}
          labelStyle={{height: 22}}
          headerStyle={this.props.itemHeaderStyle}
          handleSelect={() => {
            if (!areAllUnselected && !this.props.disabled) {
              this.props.handleChange(
                this.props.values.filter(
                  value => !filteredValues.includes(value),
                ),
              );
            }
          }}
        />
      </div>
    );
  }

  getTopLevelSelectedValues = () => {
    const {values} = this.props;
    return values.filter(value => value.split(".").length === 1);
  };

  getAllPossibleValues = _children => {
    const children = _children || this.props.children;
    let allValues = [];
    React.Children.forEach(children, child => {
      if (child.type === Item) {
        allValues.push(child.props.value);
        if (child.props.children) {
          allValues = [
            ...allValues,
            ...this.getAllPossibleValues(child.props.children),
          ];
        }
      }
    });
    return allValues;
  };

  renderSelectedItemsList() {
    if (!this.props.showSelectedItemsList) {
      return null;
    }
    const areAllSelected = _.isEqual(
      this.props.values.sort(),
      this.getAllPossibleValues().sort(),
    );
    const selectedValues = this.getTopLevelSelectedValues();
    if (
      areAllSelected ||
      !selectedValues.length ||
      selectedValues.length > this.props.maxItemsInSelectedSection
    ) {
      return null;
    }
    let items = selectedValues.map(selectedValue => {
      const element = this.findChildElementByValue(selectedValue);
      const label = _.get(element, "props.label", stringToLabel(selectedValue));
      return {value: selectedValue, label};
    });
    items = _.sortBy(items, "label");
    return (
      <div style={styles.group}>
        <div
          style={{
            ...styles.groupLabel,
            ..._.pick(this.props.itemHeaderStyle, [
              "paddingLeft",
              "paddingRight",
            ]),
          }}
        >
          Selected values
        </div>
        {items.map(item => (
          <Item
            key={item.value}
            value={item.value}
            label={item.label}
            isSelected={true}
            withCheckbox={true}
            labelStyle={{height: 22}}
            headerStyle={this.props.itemHeaderStyle}
            handleSelect={() => {
              setTimeout(() => {
                this.unselectValue(item.value);
              }, 0);
            }}
          />
        ))}
      </div>
    );
  }

  renderFilterTextField() {
    if (!this.props.showFilterTextField) {
      return null;
    }
    const {filterText} = this.state;
    const {primary1Color} = this.context.muiTheme.palette;
    return (
      <div
        style={{
          ...styles.group,
          ...styles.filterGroup,
        }}
      >
        <TextField
          id="filter"
          floatingLabelText="Filter items"
          autoFocus
          style={styles.filterTextField}
          inputStyle={{
            ...styles.filterTextFieldInput,
            ...(filterText && {paddingRight: 25}),
          }}
          floatingLabelStyle={styles.filterTextFieldFloatingLabel}
          floatingLabelShrinkStyle={styles.filterTextFieldFloatingShrinkLabel}
          floatingLabelFocusStyle={{color: primary1Color}}
          underlineStyle={styles.filterTextFieldUnderline}
          underlineFocusStyle={styles.filterTextFieldUnderlineFocus}
          value={filterText}
          onChange={(event, newValue) => this.setState({filterText: newValue})}
        />
        {filterText &&
          (() => {
            const cloned = this.cloneChildren(this.props.children);
            const filtered = this.filterTopLevelChildren(cloned);
            const matchedNumber = filtered.length;
            const hiddenNumber = cloned.length - filtered.length;
            return (
              <div style={styles.filterInfo}>
                <div>{`${matchedNumber} matched`}</div>
                {hiddenNumber && <div>{`${hiddenNumber} hidden`}</div>}
              </div>
            );
          })()}
        {filterText && (
          <IconButton
            style={styles.filterIconButton}
            onMouseDown={event => event.preventDefault()}
            onClick={event => {
              event.stopPropagation();
              this.setState({filterText: ""});
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
    );
  }

  renderItems() {
    return <div style={styles.items}>{this.getChildren()}</div>;
  }

  getChildren = () => {
    return this.filterTopLevelChildren(this.cloneChildren(this.props.children));
  };

  filterTopLevelChildren = children => {
    const {filterText} = this.state;
    if (!filterText) {
      return children;
    }
    const cleanText = text =>
      text.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    const filterTexts = filterText
      .replace(/\s+/g, " ")
      .split(/\s/)
      .map(text => new RegExp(cleanText(text), "i"));
    return children.filter(child => {
      return filterTexts.every(_filterText => {
        return _filterText.test(child.props.label);
      });
    });
  };

  cloneChildren = (children, level = 0) => {
    return React.Children.map(children, child => {
      switch (child.type) {
        case Item:
          return React.cloneElement(
            child,
            {
              level,
              isSelected: this.isValueSelected(child.props.value),
              label: child.props.label || stringToLabel(child.props.value),
              headerStyle: this.props.itemHeaderStyle,
              hasUnselectedChildren:
                child.props.children &&
                Boolean(
                  this.getAllPossibleValues(child.props.children).filter(
                    _value => !this.isValueSelected(_value),
                  ).length,
                ),
              handleSelect: () =>
                this.selectValue(child.props.value, child.props.withCheckbox),
            },
            this.cloneChildren(child.props.children, level + 1),
          );
      }
      return child;
    });
  };

  isValueSelected = value => this.props.values.includes(value);

  selectValue = (newValue, withCheckbox) => {
    if (this.props.disabled) {
      return;
    }
    if (!this.isValueSelected(newValue)) {
      let values = [...this.props.values];
      const parts = newValue.split(".");
      parts.forEach((part, index) => {
        const currValue = parts.slice(0, index + 1).join(".");
        values = values.filter(_value => {
          const valueParts = _value.split(".");
          const regexp = new RegExp(`^${currValue}($|\\.(.+))`);
          if (
            index + 1 > valueParts.length ||
            _value.match(regexp) ||
            (withCheckbox &&
              parts.length === valueParts.length &&
              index === parts.length - 1 &&
              parts.slice(0, -1).join(".") ===
                valueParts.slice(0, -1).join("."))
          ) {
            return true;
          }
          return false;
        });
        values.push(currValue);
      });
      values = [
        ...values,
        ...this.getAllPossibleValues().filter(_value => {
          return _value.match(new RegExp(`^${newValue}\\.`));
        }),
      ];
      if (this.props.hidePopoverOnChange) {
        this.hidePopover();
      }
      this.props.handleChange(_.uniq(values));
    } else if (withCheckbox) {
      this.unselectValue(newValue);
    }
  };

  unselectValue = value => {
    if (this.props.disabled) {
      return;
    }
    const values = [...this.props.values];
    const index = values.indexOf(value);
    if (index > -1) {
      values.splice(index, 1);
      this.props.handleChange(values);
    }
  };

  createRootNodeRef = node => (this.rootNodeRef = node);
}

function stringToLabel(string) {
  return upperFirst(string.split(".").pop()).replace("_", " ");
}

ToolbarDropdown.defaultProps = {
  values: [],
  handleChange: () => {},
  showFilterTextField: false,
  showSelectAndUnselectAllItems: false,
  showSelectedItemsList: false,
  maxItemsInSelectedSection: 10,
  hidePopoverOnChange: false,
  itemHeaderStyle: {},
};

ToolbarDropdown.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string.isRequired),
  leftIcon: PropTypes.element,
  labelText: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  showSelectAndUnselectAllItems: PropTypes.bool.isRequired,
  showSelectedItemsList: PropTypes.bool.isRequired,
  showFilterTextField: PropTypes.bool.isRequired,
  maxItemsInSelectedSection: PropTypes.number.isRequired,
  hidePopoverOnChange: PropTypes.bool.isRequired,
  itemHeaderStyle: PropTypes.object.isRequired,
  popoverStyle: PropTypes.object,
};

ToolbarDropdown.contextTypes = {
  muiTheme: PropTypes.object.isRequired,
};
