import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loading from "common_components/loading_circular";

import {validate as validateEmail} from "email-validator";
import validatePassword, {
  getValidationMessage,
} from "common/utils/password_validator";

const styles = {
  paragraph: {
    textAlign: "center",
  },
  textfield: {
    width: "100%",
    margin: "0.5em 0",
  },
};

function getErrorMessage(email, password) {
  let errorMessage = "";
  if (!validateEmail(email)) {
    errorMessage = "Please provide a valid email address";
  } else if (!validatePassword(password)) {
    errorMessage = getValidationMessage(password);
  }
  return errorMessage;
}

export default function({signup, signupError}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [firstNameErrorText, setFirstNameErrorText] = useState("");
  const [lastNameErrorText, setLastNameErrorText] = useState("");
  const [companyNameErrorText, setCompanyNameErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [signUpComplete, setSignUpComplete] = useState(false);

  const createAccount = () => {
    let hasError = false;
    if (!firstName) {
      hasError = true;
      setFirstNameErrorText("Please enter your first name");
    } else {
      setFirstNameErrorText("");
    }
    if (!lastName) {
      hasError = true;
      setLastNameErrorText("Please enter your last name");
    } else {
      setLastNameErrorText("");
    }
    if (!companyName) {
      hasError = true;
      setCompanyNameErrorText("Please enter your company's name");
    } else {
      setCompanyNameErrorText("");
    }
    if (!email) {
      hasError = true;
      setEmailErrorText("Please enter your email");
    } else {
      setEmailErrorText("");
    }
    if (!password) {
      hasError = true;
      setPasswordErrorText("Please enter a password");
    } else {
      setPasswordErrorText("");
    }
    if (hasError) {
      return;
    }
    const newErrorMessage = getErrorMessage(email, password);
    if (newErrorMessage) {
      setErrorMessage(newErrorMessage);
    } else {
      setErrorMessage("");
      setSignUpComplete(true);
      signup(firstName, lastName, companyName, email, password);
    }
  };

  return (
    <div style={{maxWidth: "30em", margin: "1em auto"}}>
      <div
        style={{
          marginBottom: 30,
          fontSize: 24,
          textAlign: "center",
          fontFamily: "Raleway",
          fontWeight: 800,
          color: "#232e38",
        }}
      >
        CONTRACT<span style={{color: "#2096f3"}}>-REVIEW.COM</span>
      </div>
      <div>
        <p style={styles.paragraph}>Thank you for signing up.</p>
        <p style={styles.paragraph}>Let's complete your account setup.</p>
      </div>
      {(!signUpComplete || signupError) && (
        <div
          style={{
            marginTop: "2em",
            maxWidth: "20em",
          }}
        >
          <div>
            <div>
              <TextField
                variant="outlined"
                style={styles.textfield}
                label="First Name"
                value={firstName}
                error={firstNameErrorText}
                helperText={firstNameErrorText}
                onChange={e => setFirstName(e.target.value)}
              />
              <TextField
                variant="outlined"
                style={styles.textfield}
                label="Last Name"
                value={lastName}
                error={lastNameErrorText}
                helperText={lastNameErrorText}
                onChange={e => setLastName(e.target.value)}
              />
              <TextField
                variant="outlined"
                style={styles.textfield}
                label="Company Name"
                value={companyName}
                error={companyNameErrorText}
                helperText={companyNameErrorText}
                onChange={e => setCompanyName(e.target.value)}
              />
              <TextField
                variant="outlined"
                style={styles.textfield}
                label="Email Address"
                value={email}
                error={emailErrorText}
                helperText={emailErrorText}
                onChange={e => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                type="password"
                style={styles.textfield}
                label="Password"
                value={password}
                error={passwordErrorText}
                helperText={passwordErrorText}
                onChange={e => setPassword(e.target.value)}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    createAccount();
                  }
                }}
              />
            </div>
            <div style={{color: "red", paddingTop: "1em", textAlign: "center"}}>
              {errorMessage || signupError}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "1em",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={createAccount}
              >
                Create Account
              </Button>
            </div>
          </div>
        </div>
      )}
      {signUpComplete &&
        !signupError && (
          <div style={{padding: "1em"}}>
            <Loading />
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                margin: "2em",
              }}
            >
              Setting up your new account...
            </div>
          </div>
        )}
    </div>
  );
}
