import React, {Component} from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import moment from "moment";
import IconButton from "material-ui/IconButton";
import NoteAddIcon from "material-ui/svg-icons/action/note-add";
import ArrowDropdown from "material-ui/svg-icons/navigation/arrow-drop-down";
import ArrowDropup from "material-ui/svg-icons/navigation/arrow-drop-up";
import AddIcon from "material-ui/svg-icons/content/add";
import RemoveIcon from "material-ui/svg-icons/content/remove";
import FlatButton from "material-ui/FlatButton";

import localStorage from "utils/local_storage";
import {IS_SHOW_DIFFS} from "routes/topic_analysis/constants";

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";

import getRegexDiffs from "common/utils/diffs/get_regex_diffs";

const styles = {
  regexRow: {
    position: "relative",
    marginBottom: 10,
  },
  addRegexIconWrap: {
    position: "absolute",
    right: "-5px",
    padding: 0,
    top: "-15px",
  },
  addRegexIcon: {
    height: "16px",
    width: "16px",
  },
  regexText: {
    maxWidth: "95%",
    wordWrap: "break-word",
    whiteSpace: "normal",
  },
  dropdownArrow: {
    position: "absolute",
    right: 30,
    top: 4,
  },
  showDiffsButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  expandButton: {
    position: "absolute",
    top: "-8px",
    left: 0,
  },
  added: {
    backgroundColor: "#76ff76",
  },
  removed: {
    backgroundColor: "#ff8484",
  },
};

export class ClassifierStateLogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      isShowDiffs:
        (localStorage.getItem(IS_SHOW_DIFFS) &&
          localStorage.getItem(IS_SHOW_DIFFS) === "true") ||
        false,
      isBodyExpanded: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    return this.props.hoveredRegexIndex === nextProps.hoveredRegexIndex;
  }

  componentDidUpdate(prevProps, prevState) {
    // Here we collapse logs on every change of props. Otherwise diffs are recalculated with
    // every change and it's too expensive. We isolate changes of props by checking changes of
    // state as state structure is much simpler (i.e. much easier to check for changes).
    if (
      this.state.isShowDiffs !== prevState.isShowDiffs ||
      (_.difference(this.state.selected, prevState.selected).length !== 0 ||
        _.difference(prevState.selected, this.state.selected).length !== 0)
    ) {
      return;
    }
    if (this.state.selected.length > 0) {
      this.setState(() => ({selected: []}));
    }
  }

  render() {
    const {isBodyExpanded} = this.state;
    return (
      <div
        style={{
          width: "50%",
          textAlign: "right",
        }}
      >
        <Table
          height={isBodyExpanded ? "unset" : "165px"}
          fixedHeader
          selectable={false}
          multiSelectable={false}
          wrapperStyle={{
            width: "100%",
            display: "inline-block",
            border: "1px solid #888888",
            overflow: "hidden",
            background: "white",
          }}
          bodyStyle={{maxHeight: isBodyExpanded ? "800px" : "unset"}}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          >
            <TableRow style={{height: 30}}>
              <TableHeaderColumn
                colSpan="2"
                style={{
                  textAlign: "center",
                  color: "#000",
                  height: 30,
                  position: "relative",
                }}
              >
                <IconButton
                  style={styles.expandButton}
                  onClick={this.triggerExpandBody}
                >
                  {isBodyExpanded ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
                <div>Classifier/Configuration Changes</div>
                <div style={styles.showDiffsButton}>
                  {this.renderShowDiffsButton()}
                </div>
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {this.props.logs
              .sort((prevLog, nextLog) => {
                return new Date(nextLog.time) - new Date(prevLog.time);
              })
              .filter(
                log =>
                  log.data.configuration_id ===
                  this.props.classifier.configuration_id,
              )
              .reduce((acc, log, logIndex) => {
                const res = acc.concat(this.renderLogRow(log, logIndex === 0));

                if (this.isSelectedLog(log.id)) {
                  return res.concat(this.renderRegexInfoRow(log));
                }

                return res;
              }, [])}
          </TableBody>
        </Table>
      </div>
    );
  }

  triggerExpandBody = () =>
    this.setState(prevState => ({isBodyExpanded: !prevState.isBodyExpanded}));

  addRegex = (parameterName, value) => {
    this.props.submitRegex(parameterName, value);
  };

  getTheMostRecentLog = () => {
    return {data: this.props.classifier};
  };

  renderShowDiffsButton = () => {
    if (this.state.isShowDiffs) {
      return (
        <FlatButton
          label="Hide diffs"
          secondary={true}
          onClick={() => this.hideDiffs()}
        />
      );
    }

    return (
      <FlatButton
        label="Show diffs"
        primary={true}
        onClick={() => this.showDiffs()}
      />
    );
  };

  showDiffs = () => {
    localStorage.setItem(IS_SHOW_DIFFS, true);

    this.setState({
      isShowDiffs: true,
    });
  };

  hideDiffs = () => {
    localStorage.setItem(IS_SHOW_DIFFS, false);

    this.setState({
      isShowDiffs: false,
    });
  };

  renderRegexInfoRow = log => {
    if (!this.isContainLogData(log)) {
      return null;
    }

    return (
      <TableRow hoverable={false} key={`data-${log.id}`}>
        <TableRowColumn colSpan="2" style={{position: "relative"}}>
          {Object.keys(log.data.value)
            .filter(el => !el.endsWith("Re"))
            .map(key => {
              if (
                !Array.isArray(log.data.value[key]) &&
                !log.data.value[key].length
              ) {
                return null;
              }

              return (
                <div key={key}>
                  <h5
                    style={{
                      margin: "10px 0",
                      position: "relative",
                    }}
                  >
                    {key}
                  </h5>
                  {Array.isArray(log.data.value[key]) ? (
                    this.renderFormattedRegex(log, key)
                  ) : (
                    <div>{log.data.value[key]}</div>
                  )}
                </div>
              );
            })}
        </TableRowColumn>
      </TableRow>
    );
  };

  renderFormattedRegex = (log, key) => {
    if (!this.state.isShowDiffs) {
      return log.data.value[key].map((regex, index) =>
        this.renderRegex({
          regex,
          parameterName: key,
          log,
          index,
        }),
      );
    }

    const recentLog = this.getTheMostRecentLog();

    if (!this.isContainLogData(recentLog)) {
      return log.data.value[key].map((regex, index) =>
        this.renderRegex({
          regex,
          parameterName: key,
          log,
          index,
        }),
      );
    }
    const regexDiffData = getRegexDiffs(
      this.transformRegex(recentLog.data.value[key]),
      this.transformRegex(log.data.value[key]),
    );
    return regexDiffData.map((item, index) => {
      return this.renderRegex({
        regex: item.label,
        index,
        parameterName: key,
        regexNode: item.value.map((part, valueIndex) => {
          if (part.added) {
            return (
              <span
                key={`${part.value}-${valueIndex}-${key}`}
                style={styles.added}
              >
                {part.value}
              </span>
            );
          } else if (part.removed) {
            return (
              <span
                key={`${part.value}-${valueIndex}-${key}`}
                style={styles.removed}
              >
                {part.value}
              </span>
            );
          }
          return (
            <span key={`${part.value}-${valueIndex}-${key}`}>{part.value}</span>
          );
        }),
      });
    });
  };

  transformRegex(values) {
    return values.map(value => ({
      value,
      pattern: [value],
    }));
  }

  renderRegex = ({regex, parameterName, regexNode, index}) => (
    <div
      style={styles.regexRow}
      key={`${regex}-${parameterName}-${regexNode &&
        regexNode[0].key}-${index}`}
    >
      <div style={styles.regexText}>{regexNode ? regexNode : regex}</div>
      {this.state.isShowDiffs ? null : (
        <IconButton
          style={styles.addRegexIconWrap}
          onClick={() => this.addRegex(parameterName, regex)}
        >
          <NoteAddIcon
            color="rgb(128,128,128)"
            style={styles.addRegexIcon}
            hoverColor="rgb(33, 150, 243)"
          />
        </IconButton>
      )}
    </div>
  );

  renderLogRow = (log, isCurrent) => {
    return (
      <TableRow
        style={{
          height: 30,
          cursor: "pointer",
        }}
        onMouseUp={() => this.onRowClick(log.id)}
        key={log.id}
      >
        <TableRowColumn style={{height: 30}}>
          {moment(log.time).format("lll")}
          {isCurrent ? " (Current Classifier)" : null}
        </TableRowColumn>
        <TableRowColumn
          style={{
            height: 30,
            position: "relative",
          }}
        >
          {log.username}
          {this.renderRowIcon(log)}
        </TableRowColumn>
      </TableRow>
    );
  };

  renderRowIcon = log => {
    if (!this.isContainLogData(log)) {
      return null;
    }

    return (
      <div style={styles.dropdownArrow}>
        {this.isSelectedLog(log.id) ? (
          <ArrowDropup color="rgb(128,128,128)" />
        ) : (
          <ArrowDropdown color="rgb(128,128,128)" />
        )}
      </div>
    );
  };

  isContainLogData = log => {
    const {value} = log.data;

    return (
      Object.keys(value).length &&
      Object.keys(value).filter(key => Array.isArray(value[key])).length
    );
  };

  isSelectedLog = id => {
    return this.state.selected.find(selectedId => selectedId === id);
  };

  selectLog = id => {
    this.setState({selected: [...this.state.selected, id]});
  };

  deselectLog = id => {
    this.setState({
      selected: this.state.selected.filter(prevId => prevId !== id),
    });
  };

  onRowClick = id => {
    if (this.isSelectedLog(id)) {
      this.deselectLog(id);
    } else {
      this.selectLog(id);
    }
  };
}

ClassifierStateLogs.propTypes = {
  logs: PropTypes.array.isRequired,
  submitRegex: PropTypes.func.isRequired,
  classifier: PropTypes.object.isRequired,
  hoveredRegexIndex: PropTypes.string,
};

export default ClassifierStateLogs;
