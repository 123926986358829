import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

/* eslint-disable max-params */
export default function removeSplits(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_CLAUSE_REMOVE_SPLITS,
    (
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      documentLastEdited,
    ) =>
      requestor["delete"](
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/clause/${clauseId}/` +
          `clausepart/${clausepartId}/split`,
        {
          data: {
            clause_last_edited: clauseLastEdited,
            document_last_edited: documentLastEdited,
          },
          withCredentials: true,
        },
      ).then(response => ({
        clause_id: clauseId,
        clausepart_id: clausepartId,
        section_id: sectionId,
        ...response.data,
      })),
  );
}
