import _ from "underscore";
import localStorage from "utils/local_storage";

const defaultValue = {
  maxUsages: 100,
  show: "all",
  tp: true,
  fp: true,
  fn: true,
  tn: true,
  unset: true,
};

function getMaxUsagesSettings() {
  const maxUsagesSettingsRaw = localStorage.getItem("max_usages_settings");
  if (!maxUsagesSettingsRaw) {
    return defaultValue;
  }
  const maxUsagesSettings = {
    ...defaultValue,
    ..._.pick(JSON.parse(maxUsagesSettingsRaw), Object.keys(defaultValue)),
  };
  maxUsagesSettings.maxUsages =
    parseInt(maxUsagesSettings.maxUsages, 10) || 100;
  maxUsagesSettings.show = maxUsagesSettings.show || "all";
  return maxUsagesSettings;
}

function setMaxUsagesSettings(newMaxUsagesSettings) {
  localStorage.setItem(
    "max_usages_settings",
    JSON.stringify(newMaxUsagesSettings),
  );
}

export default {
  getMaxUsagesSettings,
  setMaxUsagesSettings,
};
