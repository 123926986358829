import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function updateOrganisation(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.ORGANISATION_UPDATE,
    (organisationId, data) => {
      return requestor
        .put(`${basePath}/organisation/${organisationId}/config`, data, {
          withCredentials: true,
        })
        .then(response => response.data)
        .catch(err => err.data);
    },
  );
  return action;
}
