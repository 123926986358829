import _ from "underscore";
import React, {useState} from "react";
import Button from "@material-ui/core/Button";

import TextMapEntryEditor from "./entry";

import {
  WorkflowTask,
  ActionDefinition,
  TextMapArgument,
} from "common/flowmaster/types/task_config";
import {DataLocation} from "common/flowmaster/types/data_location";
import {
  WorkflowContext,
  WorkflowInputs,
  Prompt,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

interface TextMapEditorProps {
  argValue: TextMapArgument;
  priorTasks: WorkflowTask[];
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  onUpdateItem: (value: TextMapArgument) => void;
  organisationId: OrganisationId;
}
type KeyUpdateResults = {
  order: Record<string, number>;
  deletions: Record<string, number>;
};

export function handleKeyUpdate(
  currentValues: TextMapArgument,
  newValues: TextMapArgument,
  previousOrder: Record<string, number>,
): KeyUpdateResults {
  const newKeys = Object.keys(newValues.value);
  const originalKeys = Object.keys(currentValues.value);

  const removedKeys = originalKeys.filter(key => !newKeys.includes(key));
  const addedKeys = newKeys.filter(key => !originalKeys.includes(key));

  const order = {...previousOrder};
  const deletions = {};

  if (removedKeys.length === 1 && addedKeys.length === 1) {
    const oldKey = removedKeys[0];
    const newKey = addedKeys[0];

    const oldIndex = previousOrder[oldKey];
    order[newKey] = oldIndex;
    delete order[oldKey];
    deletions[oldKey] = oldIndex;
  } else {
    addedKeys.forEach(key => {
      if (!(key in order)) {
        const maxIndex = Math.max(-1, ...Object.values(order)) + 1;
        order[key] = maxIndex;
      }
    });

    removedKeys.forEach(key => {
      deletions[key] = previousOrder[key];
      delete order[key];
    });
  }

  return {
    order,
    deletions,
  };
}

const TextMapEditor: React.FC<TextMapEditorProps> = ({
  argValue,
  priorTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  onUpdateItem,
  organisationId,
}) => {
  const [order, setOrder] = useState<Record<string, number>>(() => {
    const sortedEntries = _.sortBy(Object.keys(argValue.value), key => key);
    return Object.fromEntries(sortedEntries.map((key, index) => [key, index]));
  });
  const [pendingDeletions, setPendingDeletions] = useState<
    Record<string, number>
  >({});

  function handleUpdate(value: TextMapArgument) {
    const results = handleKeyUpdate(argValue, value, order);
    setOrder(results.order);
    setPendingDeletions(results.deletions);
  }

  return (
    <div>
      {_.sortBy(
        Object.entries(argValue.value),
        ([key]) => order[key] ?? pendingDeletions[key],
      ).map(entry => {
        return (
          <TextMapEntryEditor
            key={entry[0]}
            itemKey={entry[0]}
            itemValue={entry[1]}
            argValue={argValue}
            priorTasks={priorTasks}
            context={context}
            inputs={inputs}
            prompts={prompts}
            actionDefinitions={actionDefinitions}
            onUpdateItem={(value: TextMapArgument) => {
              handleUpdate(value);
              return onUpdateItem(value);
            }}
            organisationId={organisationId}
          />
        );
      })}
      <Button
        variant="contained"
        onClick={() => {
          const newValue = {
            ...argValue,
            value: {
              ...argValue.value,
              [getNextKey(argValue)]: {} as DataLocation<string>,
            },
          };
          handleUpdate(newValue);
          onUpdateItem(newValue);
        }}
      >
        Add
      </Button>
    </div>
  );
};

function getNextKey(argValue) {
  const keys = Object.keys(argValue.value);
  for (let counter = 0; counter < 100; counter += 1) {
    const key = `item${keys.length + counter + 1}`;
    if (!keys.find(item => item === key)) {
      return key;
    }
  }
  return "itemxxx";
}

const ActionArg = {
  Component: TextMapEditor,
  default: {value: {}},
};

export default ActionArg;
