import requestor from "requestor";

import BaseRoute from "utils/base_route";
import getRouteIds from "utils/get_route_ids";

import TaskDetail from "./containers/task";

import projectTaskChangeTransition from "modules/tasks/transitions/project_task_change";

import taskFetchAction from "modules/tasks/actions/task_fetch";

export default class ProjectTask extends BaseRoute {
  constructor(store) {
    super(":taskId", store);
  }

  setupTransitions() {
    projectTaskChangeTransition(this.store).subscribe(props =>
      this.fetchData(props),
    );
  }

  getComponents(location, cb) {
    cb(null, TaskDetail);
  }

  fetchActiveData(props) {
    this.fetchTask(props);
  }

  fetchTask(props) {
    const {organisationId, taskId} = getRouteIds(props.router);
    if (taskId) {
      this.store.dispatch(taskFetchAction(requestor)(organisationId, taskId));
    }
  }
}
