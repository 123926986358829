import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import getRouteIds from "utils/get_route_ids";

export function transition(prevState, nextState) {
  const params = getRouteIds(nextState.router);
  return {
    pathname: [
      "/organisation",
      params.organisationId,
      "project",
      params.projectId,
      "task",
    ].join("/"),
  };
}

export default function deleteTask(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TASK_DELETE,
    (organisationId, taskId, lastEdited) =>
      requestor["delete"](
        `${basePath}/organisation/${organisationId}/task/${taskId}`,
        {
          data: {last_edited: lastEdited},
          withCredentials: true,
        },
      ).then(response => ({id: taskId, ...response.data})),
    null,
    {transition},
  );
}
