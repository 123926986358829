import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function fetchIssuesInfo(requestor) {
  return createReduxPromiseAction(
    ActionTypes.ISSUES_INFO_FETCH,
    organisationId =>
      requestor
        .get(`${basePath}/organisation/${organisationId}/issue/info`, {
          withCredentials: true,
        })
        .then(response => response.data),
  );
}
