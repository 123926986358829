import React, {useEffect, useState} from "react";
import _ from "lodash";
import localStorage from "utils/local_storage";

import CheckBoxIconChecked from "material-ui/svg-icons/toggle/check-box";
import CheckBoxIconUnchecked from "material-ui/svg-icons/toggle/check-box-outline-blank";
import {Tooltip} from "@material-ui/core";
import * as colors from "material-ui/styles/colors";

const styles = {
  checkIcon: {
    width: "18px",
    height: "18px",
    fill: colors.grey700,
    cursor: "pointer",
  },
  tableWrapper: {
    borderCollapse: "collapse",
    margin: "5px",
    fontSize: "14px",
    color: colors.grey800,
  },
  thBlock: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    borderBottom: "1px solid lightgray",
  },
  checkBoxBlock: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};

const ContractTypes = ({
  topic,
  contractTypesById,
  selectedContractTypeIds,
  organisationId,
  updateSelectedContractTypes,
}) => {
  const [selectedContract, setSelectedContract] = useState(
    !selectedContractTypeIds || selectedContractTypeIds.length === 0,
  );
  const [openTooltip, setOpenTooltip] = useState(null);

  useEffect(
    () => {
      selectedContractTypeIds?.length === topic.contract_types?.length ||
      (!selectedContractTypeIds || selectedContractTypeIds.length === 0)
        ? setSelectedContract(true)
        : setSelectedContract(false);
    },
    [selectedContractTypeIds],
  );

  const {contract_types: contractTypes} = topic;
  const allContractTypesSelected =
    !selectedContractTypeIds || selectedContractTypeIds.length === 0;

  const areAllContractTypesSelected = selectedContractTypeIds => {
    const {contract_types: contractTypes} = topic;
    const allContractTypesSelected =
      !selectedContractTypeIds ||
      selectedContractTypeIds.length === 0 ||
      selectedContractTypeIds.length === contractTypes.length;
    return allContractTypesSelected;
  };

  const onOpenTooltip = id => {
    setOpenTooltip(id);
    setTimeout(() => {
      setOpenTooltip(null);
    }, 3000);
  };

  const onCtIdRemove = _.memoize(ctId => () => {
    const filteredCTsTitle = `filteredContractTypeIds_${organisationId}`;

    const allContractTypesSelected = areAllContractTypesSelected(
      selectedContractTypeIds,
    );
    if (allContractTypesSelected) {
      const {contract_types: contractTypes} = topic;
      const otherContractTypes = contractTypes
        .map(ct => ct.contract_type_id)
        .filter(id => id !== ctId);

      localStorage.setItem(
        filteredCTsTitle,
        JSON.stringify(otherContractTypes),
      );
      updateSelectedContractTypes(otherContractTypes);
    } else {
      if (
        selectedContractTypeIds.length === 1 &&
        selectedContractTypeIds[0] === ctId
      ) {
        onOpenTooltip(ctId);
      } else {
        const usagesFilter = JSON.parse(localStorage.getItem(filteredCTsTitle));

        localStorage.setItem(
          filteredCTsTitle,
          JSON.stringify(
            usagesFilter
              ? usagesFilter.filter(selectedCtId => selectedCtId !== ctId)
              : [],
          ),
        );

        updateSelectedContractTypes(
          selectedContractTypeIds.filter(selectedCtId => selectedCtId !== ctId),
        );
      }
    }
  });

  const onCtIdAdd = _.memoize(ctId => () => {
    const filteredCTsTitle = `filteredContractTypeIds_${organisationId}`;
    const usagesFilter = JSON.parse(localStorage.getItem(filteredCTsTitle));

    const withAdded = usagesFilter ? [...usagesFilter, ctId] : [ctId];
    const allContractTypesSelected = areAllContractTypesSelected(withAdded);

    localStorage.setItem(
      filteredCTsTitle,
      JSON.stringify(allContractTypesSelected ? [] : withAdded),
    );

    updateSelectedContractTypes([...selectedContractTypeIds, ctId]);
  });

  const addAllContractTypes = () => {
    const filteredCTsTitle = `filteredContractTypeIds_${organisationId}`;
    localStorage.setItem(filteredCTsTitle, JSON.stringify([]));
    updateSelectedContractTypes([]);
  };

  return (
    <table style={styles.tableWrapper}>
      <thead style={{color: colors.grey900}}>
        <tr>
          <th key="name" style={styles.thBlock}>
            <div style={styles.checkBoxHeaderBlock}>
              {!selectedContract ? (
                <CheckBoxIconUnchecked
                  style={styles.checkIcon}
                  onClick={addAllContractTypes}
                />
              ) : (
                <div style={{width: "18px"}} />
              )}
            </div>
            <div>Contract Type</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {_.orderBy(contractTypes, ["name"]).map((ct, index) => {
          const {contract_type_id: ctId} = ct;
          return (
            <tr key={index}>
              <td>
                <div
                  style={styles.checkBoxBlock}
                  onClick={
                    allContractTypesSelected ||
                    selectedContractTypeIds.includes(ctId)
                      ? onCtIdRemove(ctId)
                      : onCtIdAdd(ctId)
                  }
                >
                  <div style={{marginRight: "10px"}}>
                    {allContractTypesSelected ||
                    selectedContractTypeIds.includes(ctId) ? (
                      <Tooltip
                        title="One contract must be selected!"
                        placement="left"
                        open={ctId === openTooltip}
                        arrow
                      >
                        <CheckBoxIconChecked style={styles.checkIcon} />
                      </Tooltip>
                    ) : (
                      <CheckBoxIconUnchecked style={styles.checkIcon} />
                    )}
                  </div>
                  <div>{contractTypesById[ctId].name}</div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ContractTypes;
