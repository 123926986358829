import React from "react";

import * as TopicParameters from "plugins/topicparameters";

function ReportParameter(props) {
  const {parameter} = props;
  const {name, values, parameter_type: parameterType} = parameter;

  return (
    <div style={{paddingBottom: 4}}>
      {`${name}: ${constructValuesString(getValues(values), parameterType)}`}
    </div>
  );
}

function getValues(values) {
  const result = [];

  function getValuesInternal(items) {
    if (Array.isArray(items)) {
      items.forEach(item => getValuesInternal(item));
    } else {
      result.push(items);
    }
  }

  getValuesInternal(values);
  return result;
}

function constructValuesString(values, parameterType) {
  if (!values || values.length === 0) {
    return "Not found";
  }
  // eslint-disable-next-line import/namespace
  const topicParameter = TopicParameters[parameterType];

  return values
    .map(
      value =>
        value ? topicParameter.valueRenderer(null, value) : "Not found",
    )
    .join(", ");
}

export default ReportParameter;
