/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/require-returns */
import React, {Fragment, useState} from "react";
import styled from "styled-components";
import _ from "underscore";

import DocumentSelector from "./DocumentSelector";
import {colors} from "../constants";
import {LOADING, MaybeLoading} from "common/types/util";
import {CircularProgress} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import DonutChart from "./DonutChart";
import calculateDocumentRagState from "common/utils/issues/rag_state";
import {ReportIssue} from "common/types/issue";

import Legend from "./Legend";

export const Table = styled.div.attrs({role: "table"})`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: auto 1fr 1fr;

  /**
   * There are a known number of "special" rows with specific fixed heights,
   * containing the document selector and donut charts, respectively.
   */
  grid-template-rows: 4rem 10rem;
  /**
   * A variable number of rows (representing issues) follows those templated
   * above. They should each have the same, fixed height.
   */
  grid-auto-rows: 3rem;
  column-gap: 2rem;
  row-gap: 0.25rem;

  overflow-y: auto;

  :nth-child(3n + 2),
  :nth-child(3n + 3) {
    cursor: pointer;
  }
`;

export const Cell = styled.div.attrs({role: "cell"})`
  display: flex;
  align-items: center;
  justify-content: center;

  /**
   * For some reason, this prevents a "grid blowout" when items exceed their
   * bounds allocated in the template of the grid container. No idea why, but the
   * effect here is to enforce the simple rule that grid items should not break
   * the template by greedily taking up as much space as they like.
   */
  min-width: 0;
  min-height: 0;
`;

export const Header = styled(Cell).attrs({role: "columnheader"})``;

const LegendCell = styled(Cell)`
  width: min(80%, 14rem);
  height: 80%;
  justify-self: center;
  align-self: center;
  flex-direction: column;
`;

const IssueNameCell = styled(Cell)`
  justify-content: flex-end;
`;

const DonutCell = styled(Cell)`
  padding: 1.75rem 0rem;
  box-sizing: border-box;
`;

const SwitchHolder = styled.div`
  text-wrap: nowrap;
  font-size: 0.8em;
  color: #777;
`;

const RagStateCellEl = styled(Cell)<{color: string}>`
  display: flex;
  justify-content: right;
  color: white;
  padding-right: 1em;
  font-style: italic;
  background-color: ${props => props.color};
`;

function RagStateCell({
  color,
  showScore,
  ragScore,
  contribution,
  onClick,
  hoverText,
}) {
  const contributionText = `- ${Math.round(contribution * 100)}%`;
  const text = showScore ? `${ragScore} (${contributionText})` : "";
  return (
    <RagStateCellEl color={color} onClick={onClick} title={hoverText}>
      {text}
    </RagStateCellEl>
  );
}

export type RagState = "red" | "amber" | "green";

type Issue = {
  name: string;
  icon_color: string;
  rag_score: number;
};

type SelectedDocument = {
  id: number;
  name: string;
  issues: ReportIssue[];
  link?: string;
  group_id?: string;
};

type ComparisonSummaryProps = {
  /**
   * List of documents to display in selector for selection as a comparison
   * document.
   */
  documents: Array<{id: number; name: string; date: Date; group_id?: string}>;

  /** Selected "base" document for comparison. */
  baseDocument: SelectedDocument;

  /**
   * Selected "comparison" document. The undefined state here is distinct from
   * the "LOADING" state, and signifies that no selection has been made.
   */
  comparisonDocument?: MaybeLoading<SelectedDocument>;

  /**
   * Optional callback invoked with the ID of a new document selection for
   * either the base document or comparison document (crudely indicated by a
   * string key).
   */
  onChangeSelectedDocument?: (
    document: "base" | "comparison",
    newId: number,
  ) => void;

  /**
   * Conceptually, this is a little obscure at this level. What this
   * represents are the thresholds for a document's total rag score to be
   * considered to be in a particular RAG state, OVERALL. Basically, these
   * thresholds allow the aggregation of RAG states for a document to be
   * customised - maybe some documents are more sensitive to relatively few
   * issues, etc.
   */
  rag_score_thresholds: Record<RagState, number>;

  onClickColumn?: (documentId: number) => void;
};

const ComparisonSummary = ({
  baseDocument,
  comparisonDocument,
  documents,
  onChangeSelectedDocument,
  rag_score_thresholds,
  onClickColumn,
}: ComparisonSummaryProps) => {
  const [showContribution, setShowContribution] = useState(false);
  const baseDocumentRagState = calculateDocumentRagState(
    baseDocument.issues,
    rag_score_thresholds,
    false,
  );
  const comparisonDocumentLoaded =
    comparisonDocument !== undefined && comparisonDocument !== LOADING;
  const comparisonDocumentRagState = comparisonDocumentLoaded
    ? calculateDocumentRagState(comparisonDocument.issues, rag_score_thresholds)
    : null;
  return (
    <Table>
      <div />
      <Header>
        <DocumentSelector
          documents={documents}
          selectedDocument={baseDocument}
          onChange={id => onChangeSelectedDocument?.("base", id)}
        />
      </Header>
      <Header>
        <DocumentSelector
          documents={documents}
          selectedDocument={
            comparisonDocumentLoaded ? comparisonDocument : undefined
          }
          onChange={id => onChangeSelectedDocument?.("comparison", id)}
        />
      </Header>

      <LegendCell>
        <Legend ragScoreThresholds={rag_score_thresholds} />
        <SwitchHolder>
          <Switch
            checked={showContribution}
            onChange={() => setShowContribution(!showContribution)}
            color="primary"
          />
          Breakdown by issue
        </SwitchHolder>
      </LegendCell>
      {([
        {document: baseDocument, ragState: baseDocumentRagState},
        {document: comparisonDocument, ragState: comparisonDocumentRagState},
      ] as const).map(({document, ragState}, i) => (
        <DonutCell
          key={document !== undefined && document !== LOADING ? document.id : i}
          onClick={
            document !== undefined && document !== LOADING
              ? () => onClickColumn?.(document.id)
              : undefined
          }
        >
          {(() => {
            switch (document) {
              case undefined:
                return <div>Please select a document for comparison.</div>;
              case LOADING:
                return <CircularProgress />;

              default: {
                return ragState !== null ? (
                  <DonutChart
                    angle={ragState.score * 360}
                    color={colors[ragState.color]}
                    summaryPercentage={Math.floor(ragState.score * 100)}
                  />
                ) : null;
              }
            }
          })()}
        </DonutCell>
      ))}

      {
        /**
         * WARN: We take a slightly "shortcut" method here to join each row,
         * making the assumption that the contents and order of issues for both
         * documents are perfectly matched. To make this at least slightly more
         * robust, we could sort both arrays before zipping. Better still, we
         * could specifically group by name, or something like that. The
         * complexity of this mapping is a downside of structuring our original
         * data (issues) as separate collections, only implicitly related.
         */
        _.zip(
          baseDocument.issues,
          comparisonDocument !== undefined && comparisonDocument !== LOADING
            ? comparisonDocument.issues
            : new Array(baseDocument.issues.length).fill(null),
        ).map(
          /**
           * The ugliness here is another symptom of the shortcut described
           * above - we only assume that the zip process successfully
           * implemented the constraint that the first and second items in this
           * tuple have the same name (representing the same issue, etc).
           */
          ([baseIssue, comparisonIssue]: [Issue, Issue]) => (
            <Fragment key={baseIssue.name}>
              <IssueNameCell>{baseIssue.name}</IssueNameCell>
              <RagStateCell
                showScore={
                  showContribution && baseIssue.icon_color !== "#00c853"
                }
                ragScore={baseIssue.rag_score}
                contribution={
                  baseIssue.rag_score / baseDocumentRagState.totalMaxRagScore
                }
                color={baseIssue.icon_color}
                onClick={() => onClickColumn?.(baseDocument.id)}
                hoverText={baseIssue.summary_text}
              />

              {comparisonIssue !== null &&
              comparisonDocumentRagState !== null ? (
                <RagStateCell
                  showScore={
                    showContribution && comparisonIssue.icon_color !== "#00c853"
                  }
                  ragScore={comparisonIssue.rag_score}
                  contribution={
                    comparisonIssue.rag_score /
                    comparisonDocumentRagState.totalMaxRagScore
                  }
                  color={comparisonIssue.icon_color}
                  onClick={
                    comparisonDocument !== undefined &&
                    comparisonDocument !== LOADING
                      ? () => onClickColumn?.(comparisonDocument.id)
                      : undefined
                  }
                  hoverText={comparisonIssue.summary_text}
                />
              ) : (
                <div />
              )}
            </Fragment>
          ),
        )
      }
    </Table>
  );
};

export default ComparisonSummary;
