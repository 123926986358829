import IssueList from "./containers/issue_list";

export default class IssueListRoute {
  constructor() {
    this.path = "list";
  }
  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, IssueList);
  }
}
