import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function fetchProjectConversations(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PROJECT_CONVERSATIONS_FETCH,
    (organisationId, projectId) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}/conversation`,
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
