import React from "react";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import Checkbox from "material-ui/Checkbox";

const styles = {
  cell: {},
  checkBox: {
    marginRight: "auto",
    marginLeft: "auto",
  },
};

class TopicCategoryListItem extends React.Component {
  render() {
    const {topiccategory} = this.props;
    return (
      <tr>
        <td style={{...styles.cell, textAlign: "center"}} className="id">
          {topiccategory.id}
        </td>
        <td style={styles.cell}>
          <TextField
            className="name"
            name="name"
            value={topiccategory.name}
            onChange={this.onTopicCategoryUpdated}
            style={{width: "98%"}}
          />
        </td>
        <td style={styles.cell}>
          <TextField
            className="order"
            name="order"
            value={topiccategory.order}
            onChange={this.onTopicCategoryOrderUpdated}
            style={{width: "98%"}}
          />
        </td>
        <td>
          <Checkbox
            iconStyle={styles.checkBox}
            checked={topiccategory.hidden}
            onCheck={this.onTopicCategoryHiddenUpdated}
          />
        </td>
      </tr>
    );
  }
  onTopicCategoryHiddenUpdated = () => {
    this.props.onTopicCategoryHiddenUpdated(!this.props.topiccategory.hidden);
  };

  /* eslint-disable no-invalid-this */
  onTopicCategoryUpdated = event =>
    this.props.onTopicCategoryUpdated(event.target.value);
  onTopicCategoryOrderUpdated = event => {
    if (event.target.value.match(/[0-9]*/)) {
      const order = parseInt(event.target.value, 10);
      this.props.onTopicCategoryOrderUpdated(order);
    }
  };
  /* eslint-enable no-invalid-this */
}

TopicCategoryListItem.propTypes = {
  topiccategory: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onTopicCategoryUpdated: PropTypes.func.isRequired,
};

export default TopicCategoryListItem;
