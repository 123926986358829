import {WorkflowContext, WorkflowInfo} from "common/flowmaster/types/workflow";
import _ from "lodash";

interface ContextWithWorkflows extends WorkflowContext {
  workflows: WorkflowInfo[];
}

interface ContextMap {
  [key: number]: ContextWithWorkflows;
}

function groupWorkflowsByContext(
  workflows: WorkflowInfo[],
  contexts: WorkflowContext[],
): ContextWithWorkflows[] {
  const contextMap: ContextMap = contexts.reduce(
    (contextsWithWorkflows, context) => {
      contextsWithWorkflows[context.id] = {...context, workflows: []};
      return contextsWithWorkflows;
    },
    {},
  );

  workflows.forEach(workflow => {
    if (contextMap[workflow.context_id]) {
      contextMap[workflow.context_id].workflows.push(workflow);
    }
  });

  Object.values(contextMap).forEach(context => {
    context.workflows = _.sortBy(context.workflows, [
      (workflow: WorkflowInfo) => !workflow.is_star,
    ]);
  });

  return Object.values(contextMap);
}

export default groupWorkflowsByContext;
