import {RouterState} from "react-router/lib/Router";
import BaseRoute from "utils/base_route";

import AccessControlEntries from "./containers/accesscontrolentry";
import fetchAccessControlEntries from "modules/accesscontrolentry/actions/fetch";
import fetchOrganisationAccesstagsAction from "modules/accesstags/actions/fetch";
import fetchUsergroups from "modules/usergroups/actions/fetch";
import permissionGroupsFetchAction from "modules/permissions/actions/permission_groups_fetch";
import requestor from "requestor";

export default class AccessControlEntryRoute extends BaseRoute {
  constructor(store) {
    super("accesscontrolentry", store);
  }
  getComponents(_, cb) {
    cb(null, AccessControlEntries);
  }

  // TODO: Could annotate BaseRoute in Typescript soon...
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fetchActiveData(state: {router: RouterState}) {
    const {
      params: {organisationId},
    } = state.router;
    this.store.dispatch(fetchAccessControlEntries(organisationId));
    this.store.dispatch(permissionGroupsFetchAction(requestor)());
    this.store.dispatch(fetchUsergroups(organisationId));
    this.store.dispatch(fetchOrganisationAccesstagsAction(organisationId));
  }
}
