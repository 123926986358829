import React from "react";
import _ from "underscore";
import AutoComplete from "material-ui/AutoComplete";

import CloseIcon from "material-ui/svg-icons/navigation/close";

const styles = {
  closeIcon: {
    width: "18px",
    height: "18px",
    marginTop: "2.8rem",
    marginLeft: "0.4rem",
    cursor: "pointer",
    flexShrink: "0",
    color: "#424242",
  },
};

class AutocompleteClearable extends React.Component {
  state = {inputText: ""};
  render() {
    return (
      <div style={{display: "flex", ...this.props.style}}>
        <AutoComplete
          {..._.omit(this.props, [
            "postClearHandler",
            "style",
            "textFieldStyle",
            "onInputTextClear",
          ])}
          style={{width: "100%"}}
          textFieldStyle={{width: "100%"}}
          searchText={
            this.props.searchText ? this.props.searchText : this.state.inputText
          }
          onUpdateInput={
            this.props.onUpdateInput
              ? this.props.onUpdateInput
              : this.onUpdateInputText
          }
        />
        <CloseIcon
          style={styles.closeIcon}
          onClick={
            this.props.onInputTextClear
              ? this.props.onInputTextClear
              : this.onInputTextClear
          }
        />
      </div>
    );
  }

  onUpdateInputText = inputText => this.setState(() => ({inputText}));

  onInputTextClear = () =>
    this.setState(
      () => ({inputText: ""}),
      () => {
        if (this.props.postClearHandler) {
          this.props.postClearHandler();
        }
      },
    );
}

export default AutocompleteClearable;
