import React from "react";
import {Link} from "react-router";
import LinkIcon from "@material-ui/icons/Link";
import {Prompt} from "common/flowmaster/types/workflow";

interface PromptListProps {
  prompts: Prompt[];
  organisationId: string;
  addPrompt: () => void;
}

const styles = {
  link: {
    color: "inherit",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  },
  systemPromptBadge: {
    background: "yellow",
    border: "1px solid #CCCC00",
    padding: "2px 5px",
    borderRadius: "3px",
    fontSize: "0.75em",
    color: "#8B8000",
  },
};

const PromptList: React.FC<PromptListProps> = ({
  prompts,
  organisationId,
  addPrompt,
}) => {
  const truncateText = (text: string): string => {
    const maxLength = 100;
    return text.length > maxLength
      ? `${text.substring(0, maxLength - 3)}...`
      : text;
  };

  return (
    <div style={{padding: "1em"}}>
      <h1>PROMPTS</h1>
      <div>
        {prompts.map(prompt => {
          const pathname = `/organisation/${organisationId}/prompt/${prompt.id}/detail`;
          return (
            <div style={{marginBottom: "0.5em"}} key={prompt.id}>
              <Link to={{pathname}} style={styles.link}>
                <LinkIcon style={{marginRight: "0.5em"}} />
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <span style={{fontWeight: "bold", marginRight: "0.5em"}}>
                      {prompt.name}
                    </span>
                    {prompt.is_system && (
                      <span style={styles.systemPromptBadge}>
                        SYSTEM PROMPT
                      </span>
                    )}
                  </div>
                  <span style={{fontStyle: "italic"}}>
                    {truncateText(prompt.text)}
                  </span>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      <button onClick={addPrompt}>Add Prompt</button>
    </div>
  );
};

export default PromptList;
