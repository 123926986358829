import React from "react";
import ClauseBankGroup from "./clause_bank_group";
import uuid from "utils/uuid";
import group_clause_bank_items from "../utils/group_clause_bank_items";
import Toolbar from "common_components/toolbar";
import {clauseBankStyle} from "./style";
import ClauseBankIcon from "../../../icons/ClauseBankIcon";

const ClauseBankComponent = ({clauseBankData}) => {
  return (
    <>
      <Toolbar icon={<ClauseBankIcon />} title={"Clause Bank"} />
      <div style={clauseBankStyle.clauseBankContainer}>
        {group_clause_bank_items(clauseBankData).map(item => (
          <ClauseBankGroup
            key={uuid()}
            clauseBank={item}
            amount={item.length}
          />
        ))}
      </div>
    </>
  );
};

export default ClauseBankComponent;
