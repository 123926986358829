import _ from "underscore";
import React from "react";

import {DndProvider} from "react-dnd";
import Backend from "react-dnd-html5-backend";

import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import {RadioButton, RadioButtonGroup} from "material-ui/RadioButton";
import * as colors from "material-ui/styles/colors";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";

import Issues from "plugins/issues";
import Issue from "utils/issues/editor/multi_selector/issue";
import {getAboveIssueType} from "utils/issues/editor/multi_selector/utils";

import updateIssuePositions from "./utils/update_issue_positions";
import swapNodes from "./utils/swap_nodes";
import addUiOrderIds from "./utils/add_ui_order_ids";
import getIssueIndexes from "./utils/get_issue_indexes";
import IssuePositionsHelper from "./utils/issue_positions_helper";
import replaceUiOrderIds from "./utils/replace_ui_order_ids";
import stripUiOrderId from "./utils/strip_ui_order_id";

const availalbleIssueKeys = Object.keys(Issues).map(key => key.toUpperCase());
const styles = {
  cardHeader: {
    paddingLeft: "2rem",
    fontSize: "17px",
    fontWeight: 500,
    justifyContent: "normal",
  },
  cardSubHeader: {
    margin: "0.2rem 0",
    fontSize: "16px",
    fontWeight: 500,
  },
  buttonStyles: {
    margin: "0rem 1rem 1rem 1rem",
    zIndex: 0,
  },
  triggerDisplay: {
    margin: "1em 0",
  },
  collapseAll: {
    marginLeft: "1em",
  },
};

class TriggeringLogic extends React.Component {
  constructor(props) {
    super(props);
    this.issuePositionsHelper = new IssuePositionsHelper(props.masterIssue.id);
    this.state = {
      triggerDisplay: props.triggerDisplay,
      problems: null,
      ...this.getInitIssueState(props),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.triggerDisplay !== prevProps.triggerDisplay) {
      this.setState(() => ({triggerDisplay: this.props.triggerDisplay}));
    }

    if (!_.isEqual(this.props.issue, prevProps.issue)) {
      this.issuePositionsHelper.updateMasterIssueId(this.props.masterIssue.id);
      this.setState(() => this.getInitIssueState(this.props));
    }

    if (!_.isEqual(this.state.issue, prevState.issue)) {
      this.setState(({issue, issuePositions}) => ({
        issuePositions:
          this.issuePositionsHelper.getIssuePositionsFromMasterId() ||
          updateIssuePositions(issue, issuePositions),
        indexes: getIssueIndexes(issue),
      }));
    }

    if (!_.isEqual(this.state.issuePositions, prevState.issuePositions)) {
      this.issuePositionsHelper.issuePositions = this.state.issuePositions;
    }
  }

  render() {
    const {triggerDisplay, issue, problems} = this.state;
    const isRulesOverridden = !_.isEqual(this.props.masterIssue, issue);
    const overridableFieldsStyle =
      this.props.isOverrideMode && !isRulesOverridden ? {opacity: 0.5} : null;

    return (
      <Paper style={{margin: "0rem 2rem 1rem 2rem"}}>
        <div
          className="app-toolbar"
          style={{
            ...styles.cardHeader,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Triggering Logic</div>
          <IconButton {...this.props.clearProps || {disabled: true}}>
            <ClearIcon />
          </IconButton>
        </div>
        <div style={{padding: "1rem 2rem"}}>
          <div style={styles.cardSubHeader}>Trigger Display</div>
          <div style={styles.triggerDisplay}>
            <RadioButtonGroup
              name="trigger_display"
              valueSelected={triggerDisplay.toString()}
              onChange={this.changeTriggerDisplay}
            >
              <RadioButton
                value="true"
                label="Unacceptable Position (Alert if rules fire)"
                disabled={this.props.isOverrideMode || this.props.disabled}
              />
              <RadioButton
                value="false"
                label="Required Position (Alert if rules do not fire)"
                disabled={
                  this.props.isOverrideMode ||
                  this.props.disabled ||
                  issue.issue_type === "EMPTY_PARENT"
                }
              />
            </RadioButtonGroup>
          </div>
          <div style={styles.cardSubHeader}>Rules</div>
          <DndProvider backend={Backend}>
            <Issue
              issue={issue}
              topics={this.props.topics}
              filteredTopics={this.props.filteredTopics}
              allTopics={this.props.allTopics}
              topicsById={this.props.topicsById}
              disabled={this.props.disabled}
              overridableFieldsStyle={overridableFieldsStyle}
              parentIssueType={null}
              issuePositions={this.state.issuePositions}
              organisationId={this.props.organisationId}
              error={problems && problems.rulesError}
              availalbleIssueKeys={availalbleIssueKeys}
              updateRules={this.updateRules}
              updateType={this.changeType}
              addIssueAbove={this.addIssueAbove}
              toggleIssue={this.toggleIssue}
              toggleAllIssues={this.toggleAllIssues}
              swapIssues={this.swapIssues}
              getIndex={this.getIndex}
              editLevel={this.props.editLevel}
              isOverrideMode={this.props.isOverrideMode}
              roles={this.props.roles}
            />
          </DndProvider>
          {this.renderResetMessage()}
        </div>
        {this.renderActionButtons()}
      </Paper>
    );
  }

  renderResetMessage = () => {
    const {isOverrideMode, overriddenClients} = this.props;
    if (!this.hasChanged() || isOverrideMode || !overriddenClients) {
      return null;
    }
    return (
      <div
        style={{
          textAlign: "center",
          color: colors.red600,
          marginTop: 10,
        }}
      >
        {`${overriddenClients} client` +
          `${overriddenClients === 1 ? " has" : "s have"} ` +
          "issues that depend on this logic. If you change this issue, these " +
          "will reset"}
      </div>
    );
  };

  hasChanged = () => {
    return (
      (!_.isEqual(
        stripUiOrderId(this.props.issue.rules),
        stripUiOrderId(this.state.issue.rules),
      ) ||
        this.props.issue.issue_type !== this.state.issue.issue_type ||
        this.props.triggerDisplay !== this.state.triggerDisplay) &&
      !this.props.hideButtons
    );
  };

  renderActionButtons = () => {
    if (!this.hasChanged()) {
      return null;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "2rem",
        }}
      >
        <RaisedButton
          label="Save Triggering Logic"
          primary={true}
          onClick={this.onSave}
          style={styles.buttonStyles}
        />
        <RaisedButton
          label="Cancel"
          primary={false}
          onClick={this.onCancel}
          style={styles.buttonStyles}
        />
      </div>
    );
  };

  changeTriggerDisplay = (event, value) => {
    this.setState(() => ({triggerDisplay: value === "true"}));
  };

  changeType = (event, index, value) => {
    this.stopPropagation(event);
    const {issue} = this.state;
    const rules = this.getEditor(value).initialise(issue.rules);
    this.setState(({issue}) => ({
      issue: {
        ui_order_id: issue.ui_order_id,
        issue_type: value,
        problems: null,
        rules,
      },
      ...(value === "EMPTY_PARENT" ? {triggerDisplay: true} : {}),
    }));
  };

  addIssueAbove = e => {
    this.stopPropagation(e);
    this.setState(({issue}) => {
      const newIssue = replaceUiOrderIds(
        JSON.parse(JSON.stringify(issue)),
        "root",
        "0",
      );
      return {
        issue: {
          ...issue,
          issue_type: getAboveIssueType(issue.issue_type, null),
          rules: {
            issues: [_.pick(newIssue, "ui_order_id", "issue_type", "rules")],
          },
        },
      };
    });
  };

  getEditor = _.memoize(type => Issues[type.toLowerCase()].editor);

  updateRules = rules => {
    this.setState(({issue}) => ({issue: {...issue, rules}}));
  };

  onCancel = () => {
    this.setState(() => ({
      ...this.getInitIssueState(this.props),
      triggerDisplay: this.props.triggerDisplay,
    }));
  };

  onSave = () => {
    const {issue: {issue_type: issueType, rules}, triggerDisplay} = this.state;
    const problems = this.getEditor(issueType).validate({
      issue_type: issueType,
      rules,
    });
    if (problems) {
      return this.setState(() => ({problems}));
    }

    let updates;
    if (
      triggerDisplay !== this.props.triggerDisplay &&
      this.rulesSame(issueType, rules)
    ) {
      updates = {trigger_display: triggerDisplay};
    } else {
      updates = {
        issue_type: issueType,
        rules,
        trigger_display: triggerDisplay,
      };
      if (
        this.props.issue.issue_type !== issueType &&
        issueType !== "OR" &&
        !issueType.startsWith("AND_")
      ) {
        updates.clear_rules_changes = true;
      }
    }

    this.setState(
      () => ({problems: null}),
      () => {
        this.props.onIssueTypeUpdate(updates);
      },
    );
  };

  rulesSame(issueType, rules) {
    return (
      issueType === this.props.issue.issue_type &&
      _.isEqual(rules, this.props.issue.rules)
    );
  }

  updateData = () => {
    // used in other file through ref
    const {issue: {issue_type: issueType, rules}, triggerDisplay} = this.state;
    const problems = this.getEditor(issueType).validate({issueType, rules});
    if (problems) {
      this.setState(() => ({problems}));
      return problems;
    }
    this.setState(
      () => ({problems: ""}),
      () => {
        this.props.onIssueTypeUpdate({
          issue_type: issueType,
          rules,
          trigger_display: triggerDisplay,
        });
      },
    );
  };

  toggleIssue = ui_order_id => {
    this.setState(({issuePositions}) => ({
      issuePositions: {
        ...issuePositions,
        [ui_order_id]: !issuePositions[ui_order_id],
      },
    }));
  };

  toggleAllIssues = (ui_order_ids, wantedPosition) => {
    if (ui_order_ids && ui_order_ids.length > 0) {
      this.setState(({issuePositions}) => {
        const newIssuePositions = {...issuePositions};
        ui_order_ids.forEach(ui_order_id => {
          newIssuePositions[ui_order_id] = wantedPosition;
        });
        return {issuePositions: newIssuePositions};
      });
    }
  };

  swapIssues = (path, id1, id2) => {
    const index1 = this.getIndex(id1);
    const index2 = this.getIndex(id2);
    this.setState(({issue}) => ({
      issue: {
        ...issue,
        rules: swapNodes(issue.rules, path, index1, index2),
      },
    }));
  };

  getInitIssueState(props) {
    const identifiedIssue = addUiOrderIds(props.issue);
    const indexes = getIssueIndexes(identifiedIssue);
    const issuePositions = this.issuePositionsHelper.updateIfShould(
      props.masterIssue.id,
      identifiedIssue,
    );
    return {
      issue: identifiedIssue,
      indexes,
      issuePositions,
    };
  }

  getIndex = ui_order_id =>
    ui_order_id ? this.state.indexes[ui_order_id] : null;

  stopPropagation = e => {
    if (e && typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
  };
}

export default TriggeringLogic;
