import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function deleteDocument(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_DELETE,
    (organisationId, projectId, documentId, lastEdited) =>
      requestor["delete"](
        `${basePath}/organisation/${organisationId}` +
          `/project/${projectId}/document/${documentId}`,
        {
          data: {last_edited: lastEdited},
          withCredentials: true,
        },
      ).then(response => ({id: documentId, ...response.data})),
  );
}
