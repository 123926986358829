import React, {Component} from "react";
import _ from "lodash";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {isInitialised} from "utils/uninitialised";
import Permissioner from "utils/permissioner";
import setTitle from "utils/set_title";
import PlaybooksComponent from "../components/playbooks";
import updateIssueset from "modules/organisation/actions/issueset_update";

import byId from "common/utils/by_id";

class SearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    if (!this.hasPermission()) {
      return this.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  renderComponent() {
    setTitle("Playbooks");
    return (
      <PlaybooksComponent
        organisationId={this.props.params.organisationId}
        contractTypes={this.props.contractTypes}
        projects={this.props.projects}
        issuesById={byId(this.props.issues)}
        updateIssueset={this.updateIssueset}
      />
    );
  }

  shouldRenderContainer = () => {
    return this.havePropsBeenInitialisedForFilters(this.props);
  };

  havePropsBeenInitialisedForFilters = props => {
    return isInitialised(
      _.chain(["contractTypes", "issues"])
        .map(item => props[item])
        .value(),
    );
  };

  hasPermission = () => {
    return Boolean(
      new Permissioner(this.props.user).hasPermission("view-playbook"),
    );
  };

  getNoPermissionMessage = () => {
    return new Permissioner(this.props.user).getNoPermissionMessage();
  };
  updateIssueset = (contractTypeId, issuesetId, values) => {
    this.props.updateIssueset(
      this.props.params.organisationId,
      contractTypeId,
      issuesetId,
      values,
    );
  };
}

const mapStateToProps = state => ({
  user: state.user,
  contractTypes: state.contract_types,
  projects: state.projects,
  issues: state.issues,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateIssueset,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
