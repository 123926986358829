import React from "react";

import Divider from "@material-ui/core/Divider";

import ApplicableClauses from "./applicable_clauses";

import commonStyles from "./common_styles";

import renderTextSplits from "utils/render_text_splits";
import ragLevelsPerColor from "utils/reports/rag_levels_per_color";

const styles = {
  ...commonStyles,
  issueHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  issueName: {
    fontSize: 16,
    fontWeight: 600,
  },
  issueLevelContainer: {
    display: "flex",
    alignItems: "center",
  },
  issueLevelText: {
    textTransform: "capitalize",
    marginRight: 8,
    fontWeight: 600,
  },
  showReasonContainer: {
    color: "#232e38",
    opacity: 0.6,
    whiteSpace: "pre-line",
  },
  divider: {
    margin: "26px 0",
    background: "#e5e9ef",
  },
};

function ReportIssueItem(props) {
  const {isIssueInGroup = false, issue} = props;

  function getShownReason() {
    if (issue.trigger_type === "LLM") {
      return issue.llm_response?.issue_summary ?? "";
    }
    const reasonTextArray = issue.reason_detail;

    return reasonTextArray.map((reasonText, index) => (
      <div key={index}>
        {renderTextSplits(
          reasonText,
          index === 0,
          index === reasonTextArray.length - 1,
        )}
      </div>
    ));
  }

  const showCircles =
    !props.project.bulk_report_settings?.data_dashboard ||
    issue.icon_color !== "#aaa";

  return (
    <div
      style={{
        ...(isIssueInGroup ? {} : {padding: "0px 14px"}),
      }}
    >
      <div style={styles.issueHeaderContainer}>
        <div style={styles.issueName}>
          {issue.subissueData && issue.name.indexOf(" / ") !== -1
            ? issue.name.split(" / ")[1].trim()
            : issue.name}
        </div>
        <div style={styles.issueLevelContainer}>
          <div style={styles.issueLevelText}>
            {ragLevelsPerColor[issue.issueColor]}
          </div>
          {showCircles && (
            <div
              style={{
                ...styles.circle,
                background: issue.icon_color,
              }}
            />
          )}
        </div>
      </div>
      <div style={{color: "#232e38", opacity: 0.6, whiteSpace: "pre-line"}}>
        {getShownReason()}
      </div>
      <ApplicableClauses
        clauses={issue.applicable_clauses || []}
        organisationId={props.organisationId}
        projectId={props.projectId}
        documentId={props.documentId}
      />
      {!isIssueInGroup ? (
        <Divider style={{margin: "26px 0", background: "#e5e9ef"}} />
      ) : (
        <div style={{marginBottom: 20}} />
      )}
    </div>
  );
}

export default ReportIssueItem;
