function getPermittedDocumentTypes(documentTypes, permissioner) {
  return documentTypes.filter(docType => {
    switch (docType.id) {
      case 4: // Extract contract from packet
        return permissioner.hasPermission("read-packets");
      default:
        return true;
    }
  });
}

export default getPermittedDocumentTypes;
