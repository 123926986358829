import _ from "underscore";
import React, {useState} from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import IconMenu from "common_components/icon_menu";
import Issuesets from "./issuesets";

function filterMasterIssuesets(issuesets) {
  return _.sortBy(
    issuesets.filter(
      iset => iset.is_duplicate_on_master === false || !iset.remote_client_id,
    ),
    "name",
  );
}

function ContractTypeDetail(props) {
  const {contractType} = props;
  const [nameError, setNameError] = useState("");
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);

  const showDeleteDialog = () => setIsDeleteDialogShown(true);
  const hideDeleteDialog = () => setIsDeleteDialogShown(false);

  const onIsVisibleCheck = e => {
    const {checked} = e.target;
    props.onContractTypeUpdate({is_visible: checked});
  };

  const onNameUpdate = e => {
    const newName = e.target.value;
    if (newName === contractType.name) {
      return;
    }
    if (!newName) {
      return setNameError("You must enter a name");
    }
    const contractTypeNameExists = Boolean(
      props.contractTypes.find(
        ct => ct.name.toLowerCase() === newName.toLowerCase(),
      ),
    );
    if (contractTypeNameExists) {
      return setNameError("Name already exists");
    }
    setNameError("");
    props.onContractTypeUpdate({name: newName});
  };

  return (
    <Paper style={{margin: "0 auto", width: "50%"}}>
      <div className="app-toolbar">
        <TextField
          fullWidth
          helperText={nameError}
          error={Boolean(nameError)}
          defaultValue={contractType.name}
          onBlur={onNameUpdate}
        />
        <Button
          onClick={props.redirectToContractTypesList}
          style={{flexShrink: "0", margin: "0.5rem"}}
        >
          Contract Types
        </Button>
        <IconMenu options={[{label: "Delete", handler: showDeleteDialog}]} />
      </div>
      <div style={{padding: "1.5rem"}}>
        <FormControlLabel
          control={
            <Checkbox
              checked={contractType.is_visible}
              onChange={onIsVisibleCheck}
              color="primary"
            />
          }
          label="Visible"
        />
        <Issuesets
          issuesets={filterMasterIssuesets(contractType.issuesets || [])}
          onIssuesetAdd={props.onIssuesetAdd}
          onIssuesetUpdate={props.onIssuesetUpdate}
          onIssuesetRemove={props.onIssuesetRemove}
        />
      </div>
      <Dialog fullWidth onClose={hideDeleteDialog} open={isDeleteDialogShown}>
        <DialogTitle>Confirm contract type deletion</DialogTitle>
        <DialogContentText style={{padding: "0 24px"}}>
          Are you sure you want to delete this Contract Type?
        </DialogContentText>
        <DialogActions>
          <Button onClick={hideDeleteDialog}>Cancel</Button>
          <Button color="primary" onClick={props.onContractTypeRemove}>
            Delete Contract Type
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

ContractTypeDetail.propTypes = {
  contractType: PropTypes.object.isRequired,
  contractTypes: PropTypes.array.isRequired,
  onContractTypeUpdate: PropTypes.func.isRequired,
  onContractTypeRemove: PropTypes.func.isRequired,
  onIssuesetAdd: PropTypes.func.isRequired,
  onIssuesetUpdate: PropTypes.func.isRequired,
  onIssuesetRemove: PropTypes.func.isRequired,
  redirectToContractTypesList: PropTypes.func.isRequired,
};

export default ContractTypeDetail;
