/* eslint-disable no-unused-vars */
import React from "react";
/* eslint-enable no-unused-vars */

import ClauseAtom from "../components/clause_atom";
import ClauseNode from "../components/clause_node";
import ClauseList from "../components/clause_list";

/* eslint-disable complexity */
export default function renderNode(node, parentNode, props, reference) {
  if (node.type) {
    const type = node.type.substring(node.type.length - 4);
    const id = node.id || node.clauseId || reference;
    const path = props.path ? `${props.path}[${props.index}]` : "root";
    const key = [
      props.renderPath || "",
      id,
      type,
      node.level,
      node.listOrder,
      ...(props.index ? [props.index] : []),
      ...(node.clausePartNumber ? [node.clausePartNumber] : []),
    ].join("_");
    const allProps = {
      ...props,
      reference,
      clause: node,
      parent: parentNode,
      path,
    };
    switch (type) {
      case "Atom": {
        const changes = props.changes.filter(
          change => change.new_clausepart_id === node.id,
        );
        return (
          <ClauseAtom
            className="node"
            key={key}
            {...allProps}
            changes={changes}
          />
        );
      }
      case "Node":
        return (
          <ClauseNode
            className="node"
            key={key}
            {...allProps}
            renderPath={key}
          />
        );
      case "List":
        return (
          <ClauseList
            className="node"
            key={key}
            {...allProps}
            renderPath={key}
          />
        );
    }
  }
  return null;
}
