import React from "react";
import ReactDOM from "react-dom";
import _ from "underscore";
import scrollIntoView from "scroll-into-view";

import {Link} from "react-router";

import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import CrossIcon from "material-ui/svg-icons/content/clear";
import NothingIcon from "material-ui/svg-icons/action/highlight-off";
import InfoIcon from "material-ui/svg-icons/action/info-outline";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import AddIcon from "material-ui/svg-icons/content/add-circle";
import TopicEditIcon from "material-ui/svg-icons/av/library-add";
import MoreHorizIcon from "material-ui/svg-icons/navigation/more-horiz";
import ExpandLessIcon from "material-ui/svg-icons/navigation/expand-less";
import CheckIcon from "@material-ui/icons/Check";
import ConfirmIcon from "material-ui/svg-icons/action/done";
import BugReportIcon from "@material-ui/icons/BugReport";

import RaisedButton from "material-ui/RaisedButton";
import IconButton from "material-ui/IconButton";
import * as colors from "material-ui/styles/colors";
import TooltipUI from "@material-ui/core/Tooltip";

import CircularProgress from "@material-ui/core/CircularProgress";

import CheckBoxBasic from "common_components/inputs/checkbox_basic";
import Tooltip from "common_components/tooltip";

import TopicUsageDeletionDialog from "./topic_usage_deletion_dialog";
import constructClausepartDomElement from "./utils/construct_clausepart_dom_element";
import trainingModeColors from "./const/training_mode_colors";
import {ClickAwayListener} from "@material-ui/core";
import {CLAUSE_LOG_VALUES} from "../constants";
import moment from "moment";

const styles = {
  baseClassifierChipStyles: {
    cursor: "help",
    borderRadius: "1em",
    width: "4.5em",
    height: "3.25em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "3px",
    borderStyle: "solid",
  },
  constructClausepartDomElContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  hasTopicBottomIcon: {
    width: "12px",
    cursor: "pointer",
  },
  topicLogIcon: {
    fontSize: "14px",
    width: "1em",
    display: "inline-block",
  },
  tooltipContainerStyle: {
    right: "1rem",
    width: "20rem",
  },
  tooltipContentStyle: {
    fontSize: "12px",
    margin: "0 0.3rem",
    textAlign: "left",
  },
  scoresStyles: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bugIconBlock: {
    height: "18px",
    width: "18px",
    cursor: "pointer",
    padding: "0 5px",
  },
  bugIcon: {
    height: "18px",
    width: "18px",
  },
  clauseBlock: {
    display: "flex",
    justifyItems: "center",
  },
};

export default class TopicUsage extends React.Component {
  state = {
    isElementHighlighted: false,
    isDeleteUsageDialogShown: false,
    showChildrenUsages: false,
    clauseLogs: null,
    isOpenClauseLogs: false,
    isBadlyParsed: this.props.usage.clausepart_is_badly_parsed,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.showUsageClausepartId !== prevProps.showUsageClausepartId &&
      this.props.showUsageClausepartId === null &&
      this.props.usage.clausepart_id === prevProps.showUsageClausepartId
    ) {
      const el = ReactDOM.findDOMNode(this[`usage_${this.props.usage.id}`]);
      if (el) {
        scrollIntoView(el);
      }
      this.setState(
        () => ({isElementHighlighted: true}),
        () =>
          setTimeout(
            () => this.setState(() => ({isElementHighlighted: false})),
            2500,
          ),
      );
    }

    if (this.props.isBadlyParsedIds !== prevProps.isBadlyParsedIds) {
      if (this.props.isBadlyParsedIds.find(id => id === this.props.usage.id)) {
        this.setState({isBadlyParsed: true});
      }
    }
  }

  render() {
    const {usage, showUsageClausepartId, usesToSelect} = this.props;
    if (
      (showUsageClausepartId &&
        showUsageClausepartId !== usage.clausepart_id) ||
      this.state.isBadlyParsed
    ) {
      return null;
    }
    const {
      section_id: sectionId,
      clause_id: clauseId,
      clausepart_id: clausepartId,
      clausepart_last_edited: clausepartLastEdited,
      document_id: documentId,
      project_id: projectId,
      other_topics: otherTopics,
      topic_id: usageTopicId,
      status,
      is_confirmed: isConfirmed,
    } = usage;
    const editIconToolTipContent = otherTopics
      ? otherTopics
          .filter(topic => topic.topic_id !== usageTopicId)
          .map(topic => (
            <div key={topic.topic_id}>
              {this.props.topicsById[topic.topic_id].name}
            </div>
          ))
      : [];
    return (
      <tr
        ref={this.createUsageRef(usage.id)}
        style={{
          borderBottom: "1px solid lightgray",
          padding: "1rem 0",
          backgroundColor: usage.has_neg_topic ? "#fdd" : null,
        }}
      >
        {this.renderUsageData()}
        <td
          key="clause-text"
          className="clause-text"
          style={{
            cursor: "pointer",
            background: this.state.isElementHighlighted
              ? colors.grey200
              : "none",
            padding: "0.5rem",
            height: "1px",
          }}
          onDoubleClick={this.onTextClick}
        >
          <div style={styles.constructClausepartDomElContainer}>
            {constructClausepartDomElement(this.props, usage)}
            {this.renderChildrenUsages(usage)}
          </div>
        </td>
        <td key="topic-status-icon" align="center">
          {this.getHasTopicStatusIcon(usage)}
          <div>
            <ClickAwayListener onClickAway={this.handleTooltipClose}>
              <TooltipUI
                title={
                  <div>
                    {this.state.clauseLogs === "fetch" ? (
                      <p>Fetching logs</p>
                    ) : Array.isArray(this.state.clauseLogs) &&
                      this.state.clauseLogs.length ? (
                      this.state.clauseLogs.map(log => (
                        <p key={log.time}>
                          <span style={styles.topicLogIcon}>
                            {this.getTopicLogIcon(log.type)}
                          </span>
                          {` @ ${moment(log.time).format(
                            "YYYY-MM-DD HH:mm",
                          )} by ${log.username}`}
                        </p>
                      ))
                    ) : (
                      <p>No logs for this clause</p>
                    )}
                  </div>
                }
                placement="top-start"
                onClose={this.handleTooltipClose}
                open={this.state.isOpenClauseLogs}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <InfoIcon
                  style={styles.hasTopicBottomIcon}
                  onClick={this.getClauseLogs}
                />
              </TooltipUI>
            </ClickAwayListener>
          </div>
        </td>
        {this.renderClassifierDetails()}
        {this.props.viewMode === "detail" && (
          <td key="all-classifiers">
            {this.renderClassifierRefreshButton({id: "all"})}
          </td>
        )}
        <td key="buttons">
          {this.renderAddTopicButton(
            projectId,
            documentId,
            sectionId,
            clauseId,
            clausepartId,
            clausepartLastEdited,
            usage.childrenUsages,
          )}
          {this.renderAddNegTopicButton(
            projectId,
            documentId,
            sectionId,
            clauseId,
            clausepartId,
            clausepartLastEdited,
            usage.childrenUsages,
          )}
          {this.renderConfirmTopicButton(
            projectId,
            documentId,
            sectionId,
            clauseId,
            clausepartId,
            clausepartLastEdited,
            usage.childrenUsages,
          )}
          {this.renderRemoveTopicButton(
            projectId,
            documentId,
            sectionId,
            clauseId,
            clausepartId,
            clausepartLastEdited,
            usage,
          )}
        </td>
        <td className="edit" key="edit">
          <span style={{position: "relative"}}>
            <Tooltip
              tooltipContent={
                <div>
                  <div style={{fontWeight: 600}}>
                    {editIconToolTipContent.length > 0
                      ? "Other topics:"
                      : "No other topics"}
                  </div>
                  {editIconToolTipContent}
                </div>
              }
              tooltipContainerStyle={styles.tooltipContainerStyle}
              tooltipContentStyle={styles.tooltipContentStyle}
            >
              <TopicEditIcon
                onClick={this.selectUsage}
                style={{
                  cursor: "pointer",
                  color:
                    editIconToolTipContent.length > 0
                      ? colors.grey600
                      : colors.grey400,
                }}
              />
            </Tooltip>
          </span>
          {this.state.isDeleteUsageDialogShown && (
            <TopicUsageDeletionDialog
              usage={usage}
              onDialogClose={this.onDeleteUsageDialogClose}
              regexClassifier={this.props.regexClassifier}
              showMatchRegexes={this.props.showMatchRegexes}
              removeTopicFromClauses={this.props.removeTopicFromClauses}
            />
          )}
        </td>
        {usesToSelect && shouldShowSelector(status, isConfirmed, usesToSelect) && (
          <td key="select-checkbox">
            <CheckBoxBasic
              containerStyles={{marginBottom: 6}}
              checked={!this.props.unselectedUsageIds[clausepartId]}
              onCheck={this.props.triggerUnselectUsage(clausepartId)}
            />
          </td>
        )}
      </tr>
    );
  }

  selectUsage = () => this.props.setEditedUsageId(this.props.usage.id);

  renderUsageData = () => {
    const {
      clause_reference: clauseReference,
      clausepart_reference: clausepartReference,
      document_name: documentName,
      document_id: documentId,
      project_name: projectName,
      contract_type_name: contractTypeName,
    } = this.props.usage;
    const pathname = this.getClauseLink();
    return [
      <td key="topic_data">
        <div>
          <span>Project: </span>
          <span>{projectName}</span>
        </div>
        <div>
          <span>Document: </span>
          <Link className="document-link" to={{pathname}} onlyActiveOnIndex>
            <span className="document-name" style={{wordBreak: "break-all"}}>
              {documentName || "Link"}
            </span>
          </Link>
        </div>
        <div>
          <span>Document Id: </span>
          <Link className="document-id" to={{pathname}} onlyActiveOnIndex>
            <span className="document-span-id" style={{wordBreak: "break-all"}}>
              {documentId}
            </span>
          </Link>
        </div>
        <div style={styles.clauseBlock}>
          <span>Clause: </span>
          <Link
            className="clause-link"
            to={{pathname, search: `?clause=${clauseReference}`}}
            onlyActiveOnIndex
          >
            <span className="clause-reference">
              {clausepartReference || "Link"}
            </span>
          </Link>
          <span
            style={styles.bugIconBlock}
            onClick={this.markClauseIsBadlyParsed}
            title={"Mark clause as badly parsed"}
          >
            <BugReportIcon style={styles.bugIcon} />
          </span>
        </div>
        <div>
          <span>Contract type: </span>
          <span>{contractTypeName}</span>
        </div>
      </td>,
    ];
  };

  getClauseLogs = () => {
    this.setState({clauseLogs: "fetch"});
    this.props.getClauseLogs(this.props.usage.clausepart_id).then(data => {
      if (data.value) {
        this.setState({
          clauseLogs: data.value,
        });
      }
    });
    this.handleTooltipOpen();
  };

  handleTooltipClose = () => {
    this.setState({
      isOpenClauseLogs: false,
    });
  };

  handleTooltipOpen = () => {
    this.setState({
      isOpenClauseLogs: true,
    });
  };

  markClauseIsBadlyParsed = () => {
    const {
      clausepart_id: id,
      clause_id,
      document_id,
      project_id,
    } = this.props.usage;
    this.props.handleBadlyParsedModalOpen(
      id,
      clause_id,
      document_id,
      project_id,
    );
  };

  onTextClick = () => {
    this.props.triggerShowUsage(this.props.usage.clausepart_id);
  };

  getTopicLogIcon = type => {
    const icon = CLAUSE_LOG_VALUES.find(item => item.value === type);
    return icon.icon;
  };

  renderChildrenUsages = usage => {
    if (!usage.childrenUsages || usage.childrenUsages.length === 0) {
      return null;
    }
    const {showChildrenUsages} = this.state;
    const ExpandIcon = showChildrenUsages ? ExpandLessIcon : MoreHorizIcon;
    return (
      <div>
        {showChildrenUsages && (
          <div style={{opacity: "0.35", marginTop: "20px"}}>
            {usage.childrenUsages.map((childUsage, index) => (
              <div
                key={`${childUsage.clausepart_path}-${index}`}
                style={{marginBottom: "10px"}}
              >
                {childUsage.clausepart_partial_text}
              </div>
            ))}
          </div>
        )}
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <RaisedButton
            icon={<ExpandIcon />}
            style={{minWidth: "44px", marginRight: "14px"}}
            onClick={this.switchShowChildrenUsages}
          />
        </div>
      </div>
    );
  };

  renderClassifierDetails() {
    const {usage} = this.props;
    const {classifier_scores: classifierScores} = usage;
    return this.props.classifiers.map((classifier, index) => {
      const scores =
        classifierScores &&
        classifierScores.find(
          score => score.configuration_id === classifier.configuration_id,
        );
      const status =
        classifier.name === "regex"
          ? usage.regexStatus || [null, ""]
          : this.getStatus(classifier, scores, usage);
      return (
        <td
          key={`classifier-${usage.clausepart_id}-${classifier.configuration_id}-${index}`}
        >
          <div
            className="results"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="score">
              <div
                className="score-detail"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    ...styles.baseClassifierChipStyles,
                    borderColor:
                      (scores && trainingModeColors[scores.training_mode_id]) ||
                      "transparent",
                    backgroundColor:
                      status[0] && status[0].startsWith("F")
                        ? "#ff8181"
                        : status[0] && status[0].startsWith("T")
                        ? "#c4ffc4"
                        : "#ddd",
                  }}
                  title={status[1]}
                >
                  {status[1] === "__PENDING__" ? (
                    <CircularProgress size={28} />
                  ) : (
                    status[0] || <NothingIcon />
                  )}
                </div>
                {scores && (
                  <span style={styles.scoresStyles}>
                    <span style={{fontSize: "0.6em"}}>
                      {status[1] === "__PENDING__"
                        ? "n/a"
                        : (scores.score * 100).toFixed(1)}
                    </span>
                    <InfoIcon
                      style={{width: "12px"}}
                      onClick={this.showSelectedResult(
                        classifier.configuration_id,
                      )}
                    />
                  </span>
                )}
              </div>
            </div>
            <div className="refresh" style={{paddingBottom: "28px"}}>
              {this.renderClassifierRefreshButton(classifier)}
            </div>
          </div>
        </td>
      );
    });
  }

  getStatus(classifier, scores, usage) {
    if (!scores) {
      return [null, "Classifier not run yet"];
    } else if (scores.state !== 3) {
      // classifier is not finished, we show progress bar
      return [null, "__PENDING__"];
    } else if (classifier.name === "regex") {
      return usage.status || [null, ""];
    }
    const usageHasTopic = usage.has_topic;
    if (scores.is_classified && usageHasTopic) {
      return ["TP", "Classifier applied and topic applied to clause"];
    }
    if (scores.is_classified && !usageHasTopic) {
      return ["FP", "Classifier applied but topic not applied to clause"];
    }
    if (!scores.is_classified && usageHasTopic) {
      return ["FN", "Classifier is not applied but topic applied to clause"];
    }
    return ["TN", "Classifier not applied and topic is not applied to clause"];
  }

  renderClassifierRefreshButton(classifier) {
    return (
      <IconButton
        onClick={this.rerunClassifier(
          classifier.configuration_id,
          classifier.name,
          classifier.current_topic_trainingnode_id,
        )}
      >
        <RefreshIcon />
      </IconButton>
    );
  }

  renderRemoveTopicButton(
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
    usage,
  ) {
    const hasTopicChildrenIds = (usage.childrenUsages || []).reduce(
      (result, childUsage) => {
        if (childUsage.has_topic) {
          result.push(childUsage.clausepart_id);
        }
        return result;
      },
      [],
    );
    if (
      this.props.usage.has_topic ||
      (usage.childrenUsages &&
        usage.childrenUsages.length > 0 &&
        hasTopicChildrenIds.length > 0)
    ) {
      return (
        <IconButton
          className="remove-button"
          title="Remove topic from clause"
          onClick={this.onUsageRemoved(
            projectId,
            documentId,
            sectionId,
            clauseId,
            clausepartId,
            clausepartLastEdited,
            hasTopicChildrenIds,
          )}
          iconStyle={{color: colors.grey600}}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
  }

  renderAddTopicButton(
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
    childrenUsages = [],
  ) {
    const noTopicChildrenIds = childrenUsages.reduce((result, childUsage) => {
      if (!childUsage.has_topic) {
        result.push(childUsage.clausepart_id);
      }
      return result;
    }, []);
    if (
      !this.props.usage.has_topic ||
      (childrenUsages.length > 0 && noTopicChildrenIds.length > 0)
    ) {
      return (
        <IconButton
          className="add-button"
          title="Add topic to clause"
          onClick={this.onTopicAdded(
            projectId,
            documentId,
            sectionId,
            clauseId,
            clausepartId,
            clausepartLastEdited,
            noTopicChildrenIds,
          )}
          iconStyle={{color: colors.grey600}}
        >
          <AddIcon />
        </IconButton>
      );
    }
  }
  renderAddNegTopicButton(
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
    childrenUsages = [],
  ) {
    const noTopicChildrenIds = childrenUsages.reduce((result, childUsage) => {
      if (!childUsage.has_topic) {
        result.push(childUsage.clausepart_id);
      }
      return result;
    }, []);
    if (
      this.props.topic.main_negative_topic_id &&
      !this.props.usage.has_neg_topic &&
      (!this.props.usage.has_topic ||
        (childrenUsages.length > 0 && noTopicChildrenIds.length > 0))
    ) {
      return (
        <IconButton
          className="add-neg-button"
          title="Add negative topic to clause"
          onClick={this.onNegTopicAdded(
            projectId,
            documentId,
            sectionId,
            clauseId,
            clausepartId,
            clausepartLastEdited,
            noTopicChildrenIds,
          )}
          iconStyle={{color: "#faa"}}
        >
          <AddIcon />
        </IconButton>
      );
    }
  }

  renderConfirmTopicButton(
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
    childrenUsages = [],
  ) {
    let notConfirmedChildrenIds;
    if (childrenUsages.length > 0) {
      notConfirmedChildrenIds = childrenUsages.reduce((result, childUsage) => {
        if (childUsage.has_topic && !childUsage.is_confirmed) {
          result.push(childUsage.clausepart_id);
        }
        return result;
      }, []);
    }

    if (
      (this.props.usage.has_topic && !this.props.usage.is_confirmed) ||
      (notConfirmedChildrenIds && notConfirmedChildrenIds.length > 0)
    ) {
      return (
        <IconButton
          className="confirm-button"
          title="Confirm topic on clause"
          onClick={this.onTopicConfirmed(
            projectId,
            documentId,
            sectionId,
            clauseId,
            clausepartId,
            clausepartLastEdited,
            notConfirmedChildrenIds,
          )}
          iconStyle={{color: colors.grey600}}
        >
          <ConfirmIcon />
        </IconButton>
      );
    }
  }

  onUsageRemoved = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
    hasTopicChildrenIds,
  ) => () => {
    if (hasTopicChildrenIds.length > 0) {
      this.onDeleteUsageDialogShow();
    } else {
      this.props.onUsageRemoved(
        projectId,
        documentId,
        sectionId,
        clauseId,
        clausepartId,
        clausepartLastEdited,
      );
    }
  };

  onTopicAdded = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
    childrenIds,
  ) => () => {
    if (childrenIds.length > 0) {
      this.props.addTopicToClauses([clausepartId, ...childrenIds]);
    } else {
      this.props.onExistingTopicAdded(
        projectId,
        documentId,
        sectionId,
        clauseId,
        clausepartId,
        clausepartLastEdited,
        this.props.topic.id,
      );
    }
  };
  onNegTopicAdded = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
    childrenIds,
  ) => () => {
    if (childrenIds.length > 0) {
      this.props.addTopicToClauses(
        [clausepartId, ...childrenIds],
        this.props.topic.main_negative_topic_id,
      );
    } else {
      this.props.onExistingTopicAdded(
        projectId,
        documentId,
        sectionId,
        clauseId,
        clausepartId,
        clausepartLastEdited,
        this.props.topic.main_negative_topic_id,
      );
    }
  };

  onTopicConfirmed = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
    childrenIds,
  ) => () => {
    if (childrenIds && childrenIds.length > 0) {
      this.props.confirmTopicInClauses([clausepartId, ...childrenIds]);
    } else {
      this.props.onTopicConfirmed(
        projectId,
        documentId,
        sectionId,
        clauseId,
        clausepartId,
        clausepartLastEdited,
        this.props.topic.id,
      );
    }
  };

  showSelectedResult = _.memoize(configurationId => () => {
    const {
      clause_reference: clauseReference,
      document_name: documentName,
      clausepart_id: clausepartId,
    } = this.props.usage;

    const classifierUsage = this.props.usage.classifier_scores.find(
      score => score.configuration_id === configurationId,
    );
    const classifier = this.props.classifiers.find(
      _classifier => _classifier.configuration_id === configurationId,
    );
    this.props.showSelectedResult({
      ...classifierUsage,
      origin: `${documentName} ${clauseReference} - ${classifier.name} (${clausepartId})`,
      settings: classifierUsage.classifier_settings,
      date: classifierUsage.date,
      result: classifierUsage.classifier_result,
    });
  });

  rerunClassifier = _.memoize(
    (configurationId, configurationName, topicTrainingNodeId) => () => {
      const {
        section_id: sectionId,
        clause_id: clauseId,
        clausepart_id: clausepartId,
        document_id: documentId,
        project_id: projectId,
      } = this.props.usage;
      this.props.rerunClassifier(
        configurationId,
        configurationName,
        topicTrainingNodeId,
        true, // process single usage
        false, // use live classifier
        projectId,
        documentId,
        sectionId,
        clauseId,
        clausepartId,
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  getClauseLink() {
    const {organisationId} = this.props;
    const {project_id: projectId, document_id: documentId} = this.props.usage;
    return (
      `/organisation/${organisationId}/project/${projectId}` +
      `/document/${documentId}`
    );
  }

  getHasTopicStatusIcon(usage) {
    if (usage.has_topic && !usage.is_deleted) {
      if (usage.is_confirmed) {
        return (
          <TooltipUI title="Topic applied to clause" placement="top-start">
            <CheckIcon />
          </TooltipUI>
        );
      }
      return (
        <TooltipUI
          title="Topic applied to clause but not confirmed."
          placement="top-start"
        >
          <CheckIcon style={{color: colors.grey400}} />
        </TooltipUI>
      );
    }
    return (
      <TooltipUI title="Topic not applied to clause" placement="top-start">
        <CrossIcon />
      </TooltipUI>
    );
  }

  onDeleteUsageDialogClose = () =>
    this.setState(() => ({isDeleteUsageDialogShown: false}));
  onDeleteUsageDialogShow = () =>
    this.setState(() => ({isDeleteUsageDialogShown: true}));

  switchShowChildrenUsages = e => {
    e.stopPropagation();
    this.setState(prevState => ({
      showChildrenUsages: !prevState.showChildrenUsages,
    }));
  };

  createUsageRef = usageId => node => (this[`usage_${usageId}`] = node);
}

function shouldShowSelector(_status, isConfirmed, usesToSelect) {
  const status = _status ? _status[0] : null;
  return (
    (usesToSelect === "Unconfirmed" &&
      !isConfirmed &&
      status !== "FP" &&
      status !== "FN" &&
      status !== "NEGnc") ||
    (usesToSelect === "FP" && status === usesToSelect) ||
    (usesToSelect === "FN" && (status === "FN" || status === "NEGnc"))
  );
}
