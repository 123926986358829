import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router";
import Paper from "material-ui/Paper";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";

import validate, {getValidationMessage} from "common/utils/password_validator";

const styles = {
  button: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "3em",
  },
  paper: {
    marginTop: "4em",
    marginLeft: "auto",
    marginRight: "auto",
    width: "20em",
    padding: "1em 2em",
  },
  heading: {
    textAlign: "center",
    marginBottom: "0",
  },
  error: {
    marginTop: "0.6em",
    marginBottom: "0em",
    padding: "0",
    textAlign: "justify",
    color: "rgba(0, 0, 0, 0.4)",
    fontSize: "0.8em",
  },
};

function getErrorMessage(password, confirmPassword) {
  let errorMessage = "";
  if (password !== confirmPassword) {
    errorMessage = "Passwords do not match";
  } else if (!validate(password)) {
    errorMessage = getValidationMessage(password);
  }
  return errorMessage;
}

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      errorMessage: null,
    };
  }
  render() {
    return (
      <Paper style={styles.paper}>
        <h2 style={styles.heading}> Set new password</h2>
        <TextField
          className="password"
          type="password"
          floatingLabelText="Password"
          value={this.state.password}
          onChange={this.handlePasswordChange}
        />
        <TextField
          className="password_confirm"
          type="password"
          floatingLabelText="Confirm Password"
          value={this.state.confirmPassword}
          onChange={this.handleConfirmPasswordChange}
        />
        <div
          style={{
            marginTop: "1em",
            textAlign: "center",
          }}
        >
          <FlatButton
            label="Submit"
            primary={true}
            disabled={
              this.state.password.length === 0 ||
              this.state.confirmPassword.length === 0
            }
            className="confirm-button"
            onClick={this.onSubmit}
            style={styles.button}
          />
          <FlatButton
            label="Cancel"
            secondary={true}
            className="cancel-button"
            onClick={this.props.redirectToLogin}
            style={styles.button}
          />
          {this.state.errorMessage && this.state.errorMessage}
        </div>
      </Paper>
    );
  }

  handlePasswordChange = event => this.setState({password: event.target.value});
  handleConfirmPasswordChange = event =>
    this.setState({confirmPassword: event.target.value});

  onSubmit = () => {
    const {password, confirmPassword} = this.state;
    const errorMessage = getErrorMessage(password, confirmPassword);
    if (errorMessage) {
      return this.setState(() => ({
        errorMessage: <p style={styles.error}>{errorMessage}</p>,
      }));
    }
    this.setState(
      () => ({errorMessage: ""}),
      async () => {
        const result = await this.props.onPasswordReset(this.state.password);
        const message = result?.value?.data?.message;
        if (message === "password reset fail") {
          this.setState(() => ({
            errorMessage: (
              <p style={styles.error}>
                Your password reset failed. Your request could be out of date.
                Please go <Link to="/login">here</Link> and request a new
                password again.
              </p>
            ),
          }));
        } else if (message === "passwords are equal") {
          this.setState(() => ({
            errorMessage: (
              <p style={styles.error}>You must type in a new password.</p>
            ),
          }));
        } else if (result.value.status >= 400) {
          this.setState(() => ({
            errorMessage: (
              <p style={styles.error}>
                There was a problem resetting the password {message}.
              </p>
            ),
          }));
        } else {
          this.props.redirectToLogin();
        }
      },
    );
  };
}

ResetPassword.propTypes = {
  onPasswordReset: PropTypes.func.isRequired,
  redirectToLogin: PropTypes.func.isRequired,
};

export default ResetPassword;
