import React from "react";
import _ from "lodash";

import CheckBoxIconChecked from "material-ui/svg-icons/toggle/check-box";
import CheckBoxIconUnchecked from "material-ui/svg-icons/toggle/check-box-outline-blank";
import * as colors from "material-ui/styles/colors";

import localStorage from "utils/local_storage";
import {Tooltip} from "@material-ui/core";

const styles = {
  checkIcon: {
    width: "18px",
    height: "18px",
    fill: colors.grey700,
    cursor: "pointer",
  },
  tableWrapper: {
    borderCollapse: "collapse",
    margin: "0rem 1rem 1rem 2rem",
    fontSize: "14px",
    color: colors.grey800,
  },
  thBlock: {
    padding: "0 8px",
    borderBottom: "1px solid lightgray",
  },
  thBlockBorder: {
    borderBottom: "1px solid lightgray",
  },
  checkBoxHeaderBlock: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  checkBoxBlock: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};

class ContractTypesSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedContract:
        !this.props.selectedContractTypeIds ||
        this.props.selectedContractTypeIds.length === 0,
      openTooltip: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedContractTypeIds !== prevProps.selectedContractTypeIds
    ) {
      this.props.selectedContractTypeIds.length ===
        this.props.topic.contract_types.length ||
      (!this.props.selectedContractTypeIds ||
        this.props.selectedContractTypeIds.length === 0)
        ? this.setState({selectedContract: true})
        : this.setState({selectedContract: false});
    }
  }

  render() {
    const {
      selectedContractTypeIds,
      contractTypesById,
      realtimeScores,
      classifiers,
      isRequestPending,
    } = this.props;
    const {contract_types: contractTypes} = this.props.topic;
    const allContractTypesSelected =
      !selectedContractTypeIds || selectedContractTypeIds.length === 0;
    return (
      <table style={styles.tableWrapper}>
        <thead style={{color: colors.grey900}}>
          <tr>
            <th key="name" style={styles.thBlockBorder}>
              <div style={styles.checkBoxHeaderBlock}>
                <div>
                  {!this.state.selectedContract ? (
                    <Tooltip title="Select all contracts" placement="top" arrow>
                      <CheckBoxIconUnchecked
                        style={styles.checkIcon}
                        onClick={this.addAllContractTypes}
                      />
                    </Tooltip>
                  ) : (
                    <div style={{width: "18px"}} />
                  )}
                </div>
                <div>Contract Type</div>
              </div>
            </th>
            <th key="uses-number" style={styles.thBlock}>
              # Uses
            </th>
            <th key="unconfirmed-uses-number" style={styles.thBlock}>
              # Unconfirmed Uses
            </th>
            <th key="realtime-f1" style={styles.thBlock}>
              Realtime
            </th>
            {classifiers.map((config, configIndex) => (
              <th
                key={`${config.name}-f1-${configIndex}`}
                style={styles.thBlock}
              >
                {config.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {_.orderBy(contractTypes, ["name"]).map((ct, index) => {
            const {contract_type_id: ctId} = ct;
            const realTimeF1 = (
              2 *
              realtimeScores[ctId].ctp /
              (2 * realtimeScores[ctId].ctp +
                realtimeScores[ctId].cfp +
                realtimeScores[ctId].cfn) *
              100
            ).toFixed(2);
            const realtimeF1Result =
              !realTimeF1 || isNaN(realTimeF1) ? "n/a" : `${realTimeF1}%`;
            return (
              <tr key={index}>
                <td>
                  <div
                    style={styles.checkBoxBlock}
                    onClick={
                      allContractTypesSelected ||
                      selectedContractTypeIds.includes(ctId)
                        ? this.onCtIdRemove(ctId)
                        : this.onCtIdAdd(ctId)
                    }
                  >
                    <div style={{marginRight: "10px"}}>
                      {allContractTypesSelected ||
                      selectedContractTypeIds.includes(ctId) ? (
                        <Tooltip
                          title="One contract must be selected!"
                          placement="top"
                          open={ctId === this.state.openTooltip}
                          arrow
                        >
                          <CheckBoxIconChecked style={styles.checkIcon} />
                        </Tooltip>
                      ) : (
                        <CheckBoxIconUnchecked style={styles.checkIcon} />
                      )}
                    </div>
                    <div>{contractTypesById[ctId].name}</div>
                  </div>
                </td>
                <td style={{textAlign: "center"}}>
                  {isRequestPending
                    ? "-"
                    : realtimeScores[ctId].ctp +
                      realtimeScores[ctId].cfn +
                      realtimeScores[ctId].cfp}
                </td>
                <td style={{textAlign: "center"}}>
                  {isRequestPending ? "-" : realtimeScores[ctId].not_confirmed}
                </td>
                <td style={{textAlign: "center"}}>
                  {isRequestPending ? "-" : realtimeF1Result}
                </td>
                {classifiers.map((config, configIndex) => {
                  const scores = config.scoresByContractTypeId[ctId];
                  const f1 = (
                    2 *
                    scores.tp /
                    (2 * scores.tp + scores.fp + scores.fn) *
                    100
                  ).toFixed(2);
                  const result = !f1 || isNaN(f1) ? "n/a" : `${f1}%`;
                  return (
                    <td
                      style={{textAlign: "center"}}
                      key={`${config.name}-f1-result-${configIndex}`}
                    >
                      {isRequestPending ? "-" : result}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  addAllContractTypes = () => {
    const filteredCTsTitle = `filteredContractTypeIds_${
      this.props.organisationId
    }`;
    localStorage.setItem(filteredCTsTitle, JSON.stringify([]));
    this.props.updateSelectedContractTypes([]);
  };

  onCtIdAdd = _.memoize(ctId => () => {
    const filteredCTsTitle = `filteredContractTypeIds_${
      this.props.organisationId
    }`;
    const usagesFilter = JSON.parse(localStorage.getItem(filteredCTsTitle));
    const withAdded = usagesFilter ? [...usagesFilter, ctId] : [ctId];
    const allContractTypesSelected = this.areAllContractTypesSelected(
      withAdded,
    );

    localStorage.setItem(
      filteredCTsTitle,
      JSON.stringify(allContractTypesSelected ? [] : withAdded),
    );

    this.props.updateSelectedContractTypes([
      ...this.props.selectedContractTypeIds,
      ctId,
    ]);
  });

  onCtIdRemove = _.memoize(ctId => () => {
    const filteredCTsTitle = `filteredContractTypeIds_${
      this.props.organisationId
    }`;

    const allContractTypesSelected = this.areAllContractTypesSelected();
    if (allContractTypesSelected) {
      const {contract_types: contractTypes} = this.props.topic;
      const otherContractTypes = contractTypes
        .map(ct => ct.contract_type_id)
        .filter(id => id !== ctId);

      localStorage.setItem(
        filteredCTsTitle,
        JSON.stringify(otherContractTypes),
      );
      this.props.updateSelectedContractTypes(otherContractTypes);
    } else {
      if (
        this.props.selectedContractTypeIds.length === 1 &&
        this.props.selectedContractTypeIds[0] === ctId
      ) {
        this.openTooltip(ctId);
      } else {
        const usagesFilter = JSON.parse(localStorage.getItem(filteredCTsTitle));

        localStorage.setItem(
          filteredCTsTitle,
          JSON.stringify(
            usagesFilter
              ? usagesFilter.filter(selectedCtId => selectedCtId !== ctId)
              : [],
          ),
        );

        this.props.updateSelectedContractTypes(
          this.props.selectedContractTypeIds.filter(
            selectedCtId => selectedCtId !== ctId,
          ),
        );
      }
    }
  });

  openTooltip(id) {
    this.setState({openTooltip: id});
    setTimeout(() => {
      this.setState({openTooltip: null});
    }, 3000);
  }

  areAllContractTypesSelected(
    selectedContractTypeIds = this.props.selectedContractTypeIds,
  ) {
    const {contract_types: contractTypes} = this.props.topic;
    const allContractTypesSelected =
      !selectedContractTypeIds ||
      selectedContractTypeIds.length === 0 ||
      selectedContractTypeIds.length === contractTypes.length;
    return allContractTypesSelected;
  }
}

export default ContractTypesSummary;
