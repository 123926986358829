import React, {useState} from "react";
import copy from "copy-to-clipboard";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";

import CopyButton from "common_components/buttons/copy_button";

interface Props {
  text: string;
}

export default function TextCopier({text}: Props) {
  const [showCopyButton, setShowCopyButton] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div
      onMouseEnter={() => setShowCopyButton(true)}
      onMouseLeave={() => setShowCopyButton(false)}
      style={{
        position: "relative",
        ...(showCopyButton ? {minHeight: "3em"} : {}),
      }}
    >
      <div
        style={{
          display: "flex",
          maxHeight: "10em",
          overflowY: "auto",
        }}
      >
        <pre
          style={{
            whiteSpace: "pre-wrap",
            margin: 0,
          }}
        >
          {text}
        </pre>
      </div>
      {showCopyButton && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            top: "-0.5em",
            right: "0.5em",
            height: "100%",
          }}
        >
          <CopyButton onCopyClick={() => copy(text)} hideCaption={true} />
          <IconButton
            onClick={() => {
              setShowPopup(true);
            }}
          >
            <ZoomOutMapIcon />
          </IconButton>
        </div>
      )}
      {showPopup && (
        <TextPopout
          text={text}
          handleClose={() => {
            setShowPopup(false);
          }}
        />
      )}
    </div>
  );
}

interface TextPopoutProps extends Props {
  handleClose: () => void;
}

function TextPopout({text, handleClose}: TextPopoutProps) {
  const [format, setFormat] = useState(true);
  const value = format ? formatValue(text) : text;
  return (
    <Dialog
      fullWidth={false}
      maxWidth="xl"
      open={true}
      onClose={handleClose}
      style={{}}
    >
      <DialogContent>
        <DialogContentText>
          <pre
            style={{
              whiteSpace: "pre-wrap",
              margin: 0,
            }}
          >
            {value}
          </pre>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setFormat(!format)}>
          Turn formatting {format ? "off" : "on"}
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function formatValue(value: string): string {
  if (value.startsWith('"')) {
    try {
      return JSON.stringify(JSON.parse(JSON.parse(value)), null, 2);
    } catch {
      return value.split("\\n").join("\n");
    }
  }
  return value;
}
