import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";

import keyedObjectPropType from "utils/keyed_object_prop_type";

export class Icon extends React.Component {
  render() {
    const {field, value: rawValue} = this.props;
    const value = this.getValue(field, rawValue);

    return (
      <span
        style={{
          display: "inline-block",
          backgroundColor: "#eee",
          margin: "0.5em",
          padding: "0.5em",
          borderRadius: "0.25em",
        }}
      >
        <span className="field">{field.name}</span>
        <span
          className="value"
          style={{
            fontStyle: "italic",
            backgroundColor: "#80cbc4",
            margin: "0.25em",
            padding: "0.1em 0.25em",
            borderRadius: "0.25em",
          }}
        >
          {value}
        </span>
      </span>
    );
  }

  getValue(field, rawValue) {
    if (field.type === "taskref") {
      const task = this.props.tasks[rawValue];
      return task ? task.name : "None";
    }
    // return rawValue !== undefined ? rawValue :
    //   (field.defaultValue ? field.defaultValue :
    //     (field.options ? field.options[0] : "")
    //   );

    if (rawValue !== undefined) {
      return rawValue;
    }
    if (field.defaultValue) {
      return field.defaultValue;
    }
    if (field.options) {
      return field.options[0];
    }
    return "";
  }
}

class CustomValueSummary extends React.Component {
  render() {
    return <div className="icons">{this.renderIcons()}</div>;
  }

  renderIcons() {
    return _.chain(this.props.customValueFields)
      .filter(field => !field.header)
      .map(field => (
        <Icon
          key={field.key}
          className="icon"
          field={field}
          value={this.props.customValues[field.key]}
          {...this.props}
        />
      ))
      .value();
  }
}

CustomValueSummary.propTypes = {
  customValueFields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  customValues: PropTypes.object,
  tasks: keyedObjectPropType(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default CustomValueSummary;
