import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import {Link} from "react-router";
import {
  ActionDefinitionArg,
  TopicArgument,
} from "common/flowmaster/types/task_config";
import {OrganisationId} from "common/types/organisation";
import {Topic} from "common/types/topic";

interface SelectValueEditorProps {
  argDefinition: ActionDefinitionArg;
  argValue: TopicArgument;
  onUpdateItem: (value: TopicArgument) => void;
  organisationId: OrganisationId;
  embeddingTopics: Topic[];
}

const EmbeddingTopicEditor: React.FC<SelectValueEditorProps> = ({
  argDefinition,
  argValue,
  onUpdateItem,
  organisationId,
  embeddingTopics,
}) => {
  const handleChange = (event: React.ChangeEvent<{value: string}>) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      onUpdateItem({value: {id: parseInt(selectedValue, 10)}});
    }
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id={`${argDefinition.key}-selector-label`}>Topics</InputLabel>
      <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
        <Select
          labelId={`${argDefinition.key}-selector-label`}
          value={argValue?.value.id}
          onChange={handleChange}
          label={argDefinition.label}
          style={{flexGrow: 1}}
        >
          {embeddingTopics?.map(topic => (
            <MenuItem value={topic.id} key={topic.id}>
              {topic.name}
            </MenuItem>
          ))}
        </Select>
        <Link
          to={`/organisation/${organisationId}/topic/${argValue?.value.id}/detail`}
        >
          <IconButton>
            <LinkIcon />
          </IconButton>
        </Link>
      </div>
    </FormControl>
  );
};

const ActionArg = {
  Component: EmbeddingTopicEditor,
  default: {value: {key: ""}},
};

export default ActionArg;
