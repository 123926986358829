/* eslint-disable no-unused-vars*/
import React from "react";
/* eslint-enable no-unused-vars*/
import PropTypes from "prop-types";
import _ from "underscore";

import TopicCategoryListItem from "./topiccategory_list_item";

import {Toolbar, ToolbarGroup, ToolbarTitle} from "material-ui/Toolbar";

const styles = {
  inner: {
    padding: "1em 2em",
  },
  table: {
    width: "100%",
  },
};

class TopicCategoryList extends React.Component {
  render() {
    const listItems = this.renderTopicCategoryListItems();
    return (
      <div className="app-page">
        <Toolbar className="app-toolbar">
          <ToolbarGroup key={0}>
            <ToolbarTitle text="Topic Categories" />
          </ToolbarGroup>
        </Toolbar>
        <div className="app-body" style={styles.inner}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={{width: "2em"}}>Id</th>
                <th style={{width: "20em"}}>Name</th>
                <th style={{width: "20em"}}>Order</th>
                <th style={{width: "2em"}}>Hidden</th>
              </tr>
            </thead>
            <tbody>{listItems}</tbody>
          </table>
        </div>
      </div>
    );
  }

  renderTopicCategoryListItems() {
    return this.props.topiccategories.map(topiccategory => (
      <TopicCategoryListItem
        key={topiccategory.id}
        topiccategory={topiccategory}
        onTopicCategoryUpdated={this.onTopicCategoryUpdated(
          topiccategory.id,
          topiccategory.last_edited,
        )}
        onTopicCategoryOrderUpdated={this.onTopicCategoryOrderUpdated(
          topiccategory.id,
          topiccategory.last_edited,
        )}
        onTopicCategoryHiddenUpdated={this.onTopicCategoryHiddenUpdated(
          topiccategory.id,
          topiccategory.last_edited,
        )}
      />
    ));
  }

  /* eslint-disable no-invalid-this */
  onTopicCategoryUpdated = _.memoize(
    (id, lastEdited) => name => {
      this.props.onTopicCategoryUpdated(id, lastEdited, {name});
    },
    (...args) => JSON.stringify([...args]),
  );
  onTopicCategoryOrderUpdated = _.memoize(
    (id, lastEdited) => order => {
      this.props.onTopicCategoryUpdated(id, lastEdited, {order});
    },
    (...args) => JSON.stringify([...args]),
  );
  onTopicCategoryHiddenUpdated = _.memoize(
    (id, lastEdited) => hidden => {
      this.props.onTopicCategoryUpdated(id, lastEdited, {hidden});
    },
    (...args) => JSON.stringify([...args]),
  );
  /* eslint-enable no-invalid-this */
}

TopicCategoryList.propTypes = {
  topiccategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      last_edited: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onTopicCategoryUpdated: PropTypes.func.isRequired,
};

export default TopicCategoryList;
export {TopicCategoryList};
