import React from "react";
import CheckBoxBasic from "common_components/inputs/checkbox_basic";

import Button from "@material-ui/core/Button";

import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";

const styles = {
  headerCell: {
    textAlign: "center",
    fontWeight: 600,
    borderBottom: "1px solid #bdbdbd",
  },
};
/* eslint-disable prefer-template */
function getButtonLabel(entityLabel, label, usesToSelect) {
  if (label === "FP") {
    return `Add ${entityLabel} To ${
      usesToSelect === "FP" ? "Selected" : "All"
    } FPs`;
  } else if (label === "FN") {
    return `Remove ${entityLabel} From ${
      usesToSelect === "FN" ? "Selected" : "All"
    } FNs`;
  } else if (label === "Unconfirmed") {
    return `Confirm ${
      usesToSelect === "Unconfirmed" ? "Selected" : "All"
    } Unconfirmed ${entityLabel ? entityLabel + "s" : ""}`;
  }
  return "";
}

function BulkControlPanel(props) {
  const {
    usageBulkIds,
    onUsesToSelectChange,
    usesToSelect,
    entityLabel = "Topic",
  } = props;

  const [openConfirmationDialog, updateOpenConfirmationDialog] = React.useState(
    null,
  );
  const onShowConfirmDialog = (type, data) => () =>
    updateOpenConfirmationDialog({type, data});
  const onHideConfirmDialog = () => updateOpenConfirmationDialog(null);

  const rowsData = Object.keys(usageBulkIds).reduce((result, name) => {
    const {count, ids, label, dialogLabel} = usageBulkIds[name];
    if (count > 0) {
      result.push({
        label,
        count,
        buttonHandler: onShowConfirmDialog
          ? onShowConfirmDialog(dialogLabel, ids)
          : undefined,
        buttonLabel: getButtonLabel(entityLabel, label, usesToSelect),
        checkBoxHandler: () => onUsesToSelectChange(label, ids),
        isChecked: usesToSelect === label,
      });
    }
    return result;
  }, []);

  const renderConfirmationDialog = (title, message, handler) => (
    <Dialog
      title={title}
      onDismiss={onHideConfirmDialog}
      open={true}
      actions={[
        <FlatButton
          key="confirm-button"
          label="Confirm"
          secondary={true}
          onClick={handler}
        />,
        <FlatButton
          key="cancel-button"
          label="Cancel"
          secondary={true}
          onClick={onHideConfirmDialog}
        />,
      ]}
    >
      <div>{message}</div>
    </Dialog>
  );

  const renderOpenDialog = () => {
    if (!openConfirmationDialog) {
      return null;
    }
    const {data, type} = openConfirmationDialog;
    const {onRemoveFNs, onAddFPs, onConfirmUnconfirmed} = props;

    const dialogMap = {
      removeAllFNs: {
        title: `Remove ${entityLabel} From ${
          usesToSelect === "FN" ? "Selected" : "All"
        } FNs`,
        message: `Are you sure you want to remove ${
          data.length
        } ${entityLabel}s?`,
        handler: onRemoveFNs,
      },
      addAllFPs: {
        title: `Add ${entityLabel} To ${
          usesToSelect === "FP" ? "Selected" : "All"
        } FPs`,
        message: `Are you sure you want to add ${data.length} ${entityLabel}s?`,
        handler: onAddFPs,
      },
      confirmAllUnconfirmed: {
        title: `Confirm ${
          usesToSelect === "Unconfirmed" ? "Selected" : "All"
        } Unconfirmed ${entityLabel}s`,
        message: `Are you sure you want to confirm ${
          data.length
        } ${entityLabel}s?`,
        handler: onConfirmUnconfirmed,
      },
    };
    const dialogData = dialogMap[type];
    if (dialogData) {
      const confirmHandler = () => {
        dialogData.handler(data);
        onHideConfirmDialog();
      };
      return renderConfirmationDialog(
        dialogData.title,
        dialogData.message,
        confirmHandler,
      );
    }
  };

  if (rowsData.length === 0) {
    return null;
  }
  return (
    <>
      <table
        style={{
          width: 392,
          borderCollapse: "collapse",
          marginLeft: "auto",
          marginRight: 16,
          marginTop: 10,
        }}
      >
        <thead>
          <tr>
            <td style={styles.headerCell}>Show Selector</td>
            <td style={styles.headerCell}>Name</td>
            <td style={styles.headerCell}>Count</td>
            <td style={styles.headerCell}>Action</td>
          </tr>
        </thead>
        <tbody>
          {rowsData.map(row => (
            <tr key={row.label}>
              <td style={{width: 82}}>
                <CheckBoxBasic
                  checked={row.isChecked}
                  onCheck={row.checkBoxHandler}
                  containerStyles={{justifyContent: "center"}}
                />
              </td>
              <td style={{width: 42}}>
                <div onClick={row.checkBoxHandler} style={{cursor: "pointer"}}>
                  {row.label}
                </div>
              </td>
              <td style={{textAlign: "center"}}>{row.count}</td>
              <td>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={row.buttonHandler}
                  style={{borderRadius: 0, margin: 5, width: 284}}
                >
                  {row.buttonLabel}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {renderOpenDialog()}
    </>
  );
}

export default BulkControlPanel;
