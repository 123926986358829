import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";

import {withStyles} from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import issuesetUtils from "common/utils/issues/issueset_utils";
import {Table, Tr, Th, Td} from "common_components/table";

const selectorGroups = {
  Display: {
    display_name: {heading: "Name", isEditable: true},
    issue_class_id: {heading: "Class"},
    issue_order: {heading: "Order"},
    show_in_clause_buttons: {heading: "Show In Clause Buttonss"},
    styling: {heading: "Background Color"},
    open_subissues_when: {heading: "Subissues open"},
    show_if_triggered_only: {heading: "When to show"},
    hide_subissue_when_parent: {heading: "Hide subissues when parent"},
  },
  Topics: {
    display_topics: {heading: "Triggered Display Topics"},
    additional_applicable_clause_topics: {
      heading: "Triggered Additional Applicable Topics",
    },
    related_topics: {heading: "Triggered Related topics"},
    non_triggered_display_topics: {heading: "Non-Triggered Display Topics"},
    non_triggered_additional_applicable_clause_topics: {
      heading: "Non-Triggered Additional Applicable Topics",
    },
    non_triggered_related_topics: {heading: "Non-Triggered Related topics"},
  },
  Templates: {
    reason_template: {heading: "Triggered reason", isEditable: true},
    detailed_reason: {heading: "Triggered detailed reason", isEditable: true},
    positive_reason: {heading: "Non-triggered reason", isEditable: true},
    detailed_positive_reason: {
      heading: "Non-triggered detailed reason",
      isEditable: true,
    },
    email_template: {heading: "Email", isEditable: true},
    standard_language: {heading: "Standard Language", isEditable: true},
    description: {heading: "Report description", isEditable: true},
    guidance: {heading: "Triggered Guidance", isEditable: true},
    positive_guidance: {heading: "Non-triggered Guidance", isEditable: true},
    required_change: {heading: "Required Change", isEditable: true},
    required_change_not_triggered: {
      heading: "Required Change (Not Triggered)",
      isEditable: true,
    },
    about_description: {heading: "Checklist description", isEditable: true},
    common_definitions: {heading: "Common Definitions", isEditable: true},
  },
  Other: {
    correction_settings: {heading: "Corrections"},
    related_searches: {heading: "Searches"},
  },
};

const styles = {
  toolbar: {
    flexWrap: "nowrap",
    whiteSpace: "nowrap",
    "& .MuiFormControlLabel-root": {
      marginRight: 24,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 13,
    },
  },
  root: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  selectorTableWrapper: {
    position: "relative",
    zIndex: 4,
    paddingTop: 1,
    overflowX: "auto",
    flexShrink: 0,
  },
  selectorTable: {
    "& td:not(:first-child) > div > div": {
      flexGrow: 1,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      "& > h5": {
        alignSelf: "flex-start",
        fontWeight: "normal",
        fontSize: "12px",
        margin: 0,
      },
      "& > small": {
        whiteSpace: "nowrap",
        color: "#1f88e5",
        marginLeft: 4,
      },
    },
    "& td": {
      border: "1px solid #e6eaf0",
      color: "#333",
      fontSize: "13px !important",
      overflow: "visible !important",
      "& > div": {
        padding: "5px 8px !important",
      },
      "&.filter-item": {
        "&:hover": {
          cursor: "pointer",
        },
        "&:not(.filtered):hover": {
          backgroundColor: "rgba(94, 172, 79, 0.18)",
        },
        "&.filtered > div": {
          position: "relative",
          backgroundColor: "rgba(94, 172, 79, 0.18)",
        },
        "&.filtered > div:before": {
          content: "''",
          position: "absolute",
          top: -2,
          left: -2,
          bottom: -2,
          width: 3,
          backgroundColor: "#5eac4f",
        },
        "&:first-child > div:before": {
          left: -1,
        },
        "&.filtered > div:after": {
          content: "''",
          position: "absolute",
          top: -2,
          right: -2,
          bottom: -2,
          width: 3,
          backgroundColor: "#5eac4f",
        },
        "&:last-child > div:after": {
          right: -1,
        },
        "&.filtered > div > div:before": {
          content: "''",
          position: "absolute",
          top: -2,
          left: -2,
          right: -2,
          height: 3,
          backgroundColor: "#5eac4f",
        },
        "&.filtered > div > div:after": {
          content: "''",
          position: "absolute",
          left: -2,
          right: -2,
          bottom: -2,
          height: 3,
          backgroundColor: "#5eac4f",
        },
      },
    },
    "& tr.filtered-row": {
      "& td.filter-item": {
        "&:not(:first-child) > div:before, &:not(.last-item) > div:after": {
          content: "none !important",
        },
      },
    },
  },
  fieldsTableWrapper: {
    position: "relative",
    overflow: "auto",
    padding: "0 1px",
    "&:before": {
      position: "absolute",
      content: "''",
      top: 0,
      bottom: 0,
      left: 0,
      width: 1,
      background: "#fafbfd",
    },
  },
  fieldsTable: {
    "& th": {
      "&:first-child": {
        position: "sticky",
        left: 0,
        zIndex: "3 !important",
        borderLeft: "none",
      },
      border: "1px solid #e6eaf0",
      textTransform: "capitalize",
      "& > div": {
        boxShadow: "0px 2px 0px #e6eaf0 !important",
      },
      "&:last-child": {
        borderRight: "none",
      },
      "&:before": {
        position: "absolute",
        content: "''",
        top: 0,
        right: -1,
        width: 1,
        background: "#e6eaf0",
        bottom: -2,
      },
      "& > div:before": {
        position: "absolute",
        content: "''",
        top: -1,
        right: -1,
        left: 0,
        height: 1,
        background: "#e6eaf0",
      },
    },
    "& tr.contract-type-row td": {
      backgroundColor: "#484c52",
      color: "#fff",
      borderColor: "#484c52",
      "&:first-child > div:after": {
        backgroundColor: "#484c52",
      },
    },
    "& tr.issueset-row": {
      "&.base-value-row td:not(:first-child)": {
        color: "#333",
        cursor: "default",
      },
      "& td:not(:first-child)": {
        color: "#bdc3ca",
        cursor: "not-allowed",
        "&.editable": {
          color: "#333",
          cursor: "text",
        },
        "&.json": {
          whiteSpace: "pre",
        },
        "&.textual > div": {
          minWidth: "20em",
        },
      },
      "&.master-row td": {
        backgroundColor: "#b5b9bf",
        borderColor: "#a0a2a5",
        color: "#828488",
        "&:first-child": {
          color: "#232e38",
          "& > div:after": {
            backgroundColor: "#a0a2a5",
          },
        },
        "&.overridden-at-master": {
          backgroundColor: "#4893ce",
          color: "#c1cadc",
          borderColor: "#7fb1d8",
        },
      },
      "&.client-row td": {
        "&.overridden-at-master": {
          backgroundColor: "#86afda",
          borderColor: "#b3c9e8",
        },
        "&.overridden-at-client": {
          backgroundColor: "#39ce72",
          color: "#202923",
          borderColor: "#81d8a2",
        },
      },
    },
    "& td": {
      border: "1px solid #e6eaf0",
      "&:first-child": {
        whiteSpace: "nowrap",
        position: "sticky",
        zIndex: 1,
        left: 0,
        background: "#fff",
        color: "#232e38",
        overflow: "visible !important",
        borderLeft: "none",
      },
      "&:last-child": {
        borderRight: "none",
      },
    },
    "& th:first-child:after, & td:first-child:after": {
      content: "''",
      position: "absolute",
      top: -1,
      right: -9,
      bottom: 0,
      width: 8,
      background: "linear-gradient(90deg, rgba(0, 0, 0, 0.12) 0%, #0000 90%)",
      borderLeft: "1px solid rgba(0, 0, 0, 0.05)",
    },
    "& th:first-child:after": {
      bottom: -2,
    },
    "& th:first-child > div:before": {
      content: "''",
      position: "absolute",
      top: -1,
      bottom: -1,
      left: -1,
      width: 1,
      backgroundColor: "#fff",
      height: "100%",
    },
    "& td:first-child > div:before": {
      content: "''",
      position: "absolute",
      top: -1,
      bottom: -1,
      left: -1,
      width: 1,
      backgroundColor: "#fff",
    },
    "& td:first-child > div:after": {
      content: "''",
      position: "absolute",
      left: 0,
      right: 0,
      bottom: -1,
      height: 1,
      backgroundColor: "#e6eaf0",
    },
    "& .clearable-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiSvgIcon-root": {
        fontSize: 18,
      },
    },
  },
};

class IssueDetailOverrides extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredFields: [],
      showFilters: true,
      showFieldText: true,
      showOverridesOnly: false,
      countedSelectorGroups: this.getCountedSelectorGroups(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.issue.id !== this.props.issue.id ||
      prevProps.overridableFields !== this.props.overridableFields
    ) {
      setTimeout(() => {
        this.setState({countedSelectorGroups: this.getCountedSelectorGroups()});
      }, 0);
    }
  }

  getCountedSelectorGroups = _props => {
    const props = _props || this.props;
    const countedSelectorGroups = _.cloneDeep(selectorGroups);
    const fieldCounts = {};
    const fieldNames = _.flatten(
      _.map(countedSelectorGroups, fields => _.keys(fields)),
    );
    const {overridden} = props.overridableFields;
    const issuesetsById = issuesetUtils.getIssuesetsById(
      props.contractTypesById,
    );
    props.issue.contract_types.forEach(contractType => {
      const issuesets = contractType.issuesets.map(({id: issuesetId}) => {
        return issuesetsById[issuesetId];
      });
      _.forEach(issuesets, issueset => {
        if (_.has(overridden, issueset.master_id)) {
          const isMaster = !issueset.is_duplicate_on_master;
          const values =
            overridden[issueset.master_id][
              isMaster ? "master" : issueset.remote_client_id
            ];
          if (values) {
            _.forEach(values, (value, fieldName) => {
              if (fieldNames.includes(fieldName) && value !== null) {
                if (!_.has(fieldCounts, fieldName)) {
                  fieldCounts[fieldName] = {templateCount: 0, clientCount: 0};
                }
                const counterName = isMaster ? "templateCount" : "clientCount";
                fieldCounts[fieldName][counterName] += 1;
              }
            });
          }
        }
      });
    });
    _.forEach(countedSelectorGroups, fields => {
      _.forEach(fields, (field, fieldName) => {
        fields[fieldName] = {
          ...field,
          ...(_.has(fieldCounts, fieldName)
            ? fieldCounts[fieldName]
            : {templateCount: 0, clientCount: 0}),
        };
      });
    });
    return countedSelectorGroups;
  };

  isFieldFiltered = fieldName => {
    return this.state.filteredFields.includes(fieldName);
  };

  setFilteredFields = fieldNames => {
    const filteredFields = [...this.state.filteredFields];
    fieldNames.forEach(fieldName => {
      if (!this.isFieldFiltered(fieldName)) {
        filteredFields.push(fieldName);
      }
    });
    this.setState({filteredFields});
  };

  unsetFilteredFields = fieldNames => {
    const filteredFields = [...this.state.filteredFields];
    fieldNames.forEach(fieldName => {
      if (this.isFieldFiltered(fieldName)) {
        filteredFields.splice(filteredFields.indexOf(fieldName), 1);
      }
    });
    this.setState({filteredFields});
  };

  getFieldByName = _fieldName => {
    for (const fields of this.state.countedSelectorGroups) {
      for (const fieldName in fields) {
        if (fieldName === _fieldName) {
          return fields[fieldName];
        }
      }
    }
  };

  getFields = () => {
    const all = _.chain(this.state.countedSelectorGroups)
      .map(fields =>
        _.map(fields, (field, fieldName) => {
          return {
            ...field,
            name: fieldName,
          };
        }),
      )
      .flatten()
      .value();
    if (!this.state.filteredFields.length) {
      return all;
    }
    return all.filter(field => this.isFieldFiltered(field.name));
  };

  render() {
    return (
      <>
        {this.renderToolbar()}
        <div className={this.props.classes.root}>
          {this.renderSelectorTable()}
          {this.renderFieldsTable()}
        </div>
      </>
    );
  }

  renderToolbar() {
    const {showFilters, showFieldText, showOverridesOnly} = this.state;
    return ReactDOM.createPortal(
      <FormGroup className={this.props.classes.toolbar} row>
        <>
          <FormControlLabel
            control={
              <Switch
                checked={showFieldText}
                onChange={(event, value) =>
                  this.setState({
                    showFieldText: value,
                    showOverridesOnly: false,
                  })
                }
                color="primary"
                size="small"
                inputProps={{"aria-label": "primary checkbox"}}
              />
            }
            label="Show field text"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showOverridesOnly}
                disabled={!showFieldText}
                onChange={(event, value) =>
                  this.setState({showOverridesOnly: value})
                }
                color="primary"
                size="small"
                inputProps={{"aria-label": "primary checkbox"}}
              />
            }
            label="Show overrides only"
          />
        </>
        <FormControlLabel
          control={
            <Switch
              checked={showFilters}
              onChange={(event, value) => this.setState({showFilters: value})}
              color="primary"
              size="small"
              inputProps={{"aria-label": "primary checkbox"}}
            />
          }
          label="Show/hide filters"
        />
      </FormGroup>,
      document.querySelector(".tabs-toolbar"),
    );
  }

  renderSelectorTable() {
    if (!this.state.showFilters) {
      return null;
    }
    const {classes} = this.props;
    const maxCol = _.max(
      _.map(this.state.countedSelectorGroups, fields => _.keys(fields).length),
    );
    return (
      <div className={classes.selectorTableWrapper}>
        <Table
          className={classes.selectorTable}
          isSortable={false}
          withoutSidePadding={false}
        >
          <tbody>
            {_.map(this.state.countedSelectorGroups, (fields, groupName) => {
              const fieldNames = _.keys(fields);
              const areAllFiltered = fieldNames.every(fieldName =>
                this.isFieldFiltered(fieldName),
              );
              return (
                <Tr
                  key={groupName}
                  {...(areAllFiltered && {className: "filtered-row"})}
                >
                  <Td
                    className={`filter-item${
                      !areAllFiltered ? "" : " filtered"
                    }`}
                    onClick={() => {
                      if (!areAllFiltered) {
                        this.setFilteredFields(fieldNames);
                      } else {
                        this.unsetFilteredFields(fieldNames);
                      }
                    }}
                  >
                    <div>
                      <strong>{groupName}</strong>
                    </div>
                  </Td>
                  {_.map(fields, (field, fieldName) => {
                    const isLast =
                      _.keys(fields).indexOf(fieldName) ===
                      _.keys(fields).length - 1;
                    const isFiltered = this.isFieldFiltered(fieldName);
                    const onClick = !isFiltered
                      ? () => this.setFilteredFields([fieldName])
                      : () => this.unsetFilteredFields([fieldName]);
                    let className = "filter-item";
                    if (isFiltered) {
                      className += " filtered";
                    }
                    if (isLast) {
                      className += " last-item";
                    }
                    return (
                      <Td
                        key={fieldName}
                        className={className}
                        onClick={onClick}
                      >
                        <div>
                          <h5>{field.heading}</h5>
                          {Boolean(
                            field.templateCount || field.clientCount,
                          ) && (
                            <small
                              title={`Template overrides: ${field.templateCount}\nClient overrides: ${field.clientCount}`}
                            >
                              {`(${field.templateCount} / ${field.clientCount})`}
                            </small>
                          )}
                        </div>
                      </Td>
                    );
                  })}
                  {fieldNames.length < maxCol &&
                    [
                      ...Array(maxCol - fieldNames.length),
                    ].map((value, index) => <Td key={index} />)}
                </Tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  renderFieldsTable() {
    const {showFieldText} = this.state;
    const {classes, contractTypesById, issue, overridableFields} = this.props;
    const issuesetsById = issuesetUtils.getIssuesetsById(contractTypesById);
    const fields = this.getFields();
    const bodyRows = [];
    showFieldText &&
      bodyRows.push(
        <Tr key="base_value" className="issueset-row base-value-row">
          <Td key={0}>Base Value</Td>
          {fields.map(field => {
            let cellClassName = "issueset-cell";
            if (showFieldText) {
              cellClassName += " textual";
            }
            const baseValue = _.get(overridableFields.main, field.name);
            let value = null;
            if (showFieldText) {
              value = baseValue;
              if (
                value === null ||
                (typeof value === "string" && value.trim() === "")
              ) {
                value = "<BLANK>";
              }
            }
            if (_.isObject(value)) {
              value = JSON.stringify(value, null, 4);
              cellClassName += " json";
            }
            return (
              <Td key={field.name} className={cellClassName}>
                {value}
              </Td>
            );
          })}
        </Tr>,
      );
    issue.contract_types.forEach(contractType => {
      const {contract_type_id: id} = contractType;
      const issuesets = contractType.issuesets.map(({id: issuesetId}) => {
        return issuesetsById[issuesetId];
      });
      const groupedIssuesets = _.chain(issuesets)
        .groupBy("master_id")
        .map(list => {
          const group = {master: null, duplicates: []};
          list.forEach(_issueset => {
            if (!_issueset.is_duplicate_on_master) {
              group.master = _issueset;
            } else {
              group.duplicates.push(_issueset);
            }
          });
          return group;
        })
        .value();
      bodyRows.push(
        <Tr key={`contract_type_${id}`} className="contract-type-row">
          <Td key={0}>{contractType.name}</Td>
          {fields.map((field, index) => (
            <Td key={index + 1} />
          ))}
        </Tr>,
      );
      groupedIssuesets.forEach(({master, duplicates}) => {
        bodyRows.push(this.renderIssuesetRow(fields, master));
        duplicates.forEach(duplicate => {
          bodyRows.push(this.renderIssuesetRow(fields, duplicate));
        });
      });
    });
    return (
      <div className={classes.fieldsTableWrapper}>
        <Table
          className={classes.fieldsTable}
          isSortable={false}
          hasStickyHeader={true}
          withoutSidePadding={false}
        >
          <thead>
            <tr>
              <Th key="issueset_name">Issueset</Th>
              {fields.map(field => (
                <Th key={field.name}>{field.heading}</Th>
              ))}
            </tr>
          </thead>
          <tbody>{bodyRows}</tbody>
        </Table>
      </div>
    );
  }

  renderIssuesetRow(fields, issueset) {
    if (!issueset) {
      return null;
    }
    const {showFieldText, showOverridesOnly} = this.state;
    const isMaster = !issueset.is_duplicate_on_master;
    const {overridableFields} = this.props;
    const masterDataPath = `${issueset.master_id}.master`;
    const clientDataPath = `${issueset.master_id}.${issueset.remote_client_id}`;
    const dataPath = isMaster ? masterDataPath : clientDataPath;
    const dataPathShown = `${issueset.name} @@ ${
      isMaster ? "master" : issueset.remote_client_name
    }`;
    const values = _.get(overridableFields.overridden, dataPath, {});
    let rowClassName = "issueset-row";
    let name = issueset.name;
    if (isMaster) {
      rowClassName += " master-row";
    } else {
      rowClassName += " client-row";
      name =
        `${issueset.remote_client_name}: ` +
        `"${issueset.client_issueset_name}"`;
    }
    return (
      <Tr key={`issueset_${issueset.id}`} className={rowClassName}>
        <Td key="issueset_name">{name}</Td>
        {fields.map(({name: fieldName, isEditable = false}) => {
          const isCellEditable = isEditable && showFieldText;
          let cellClassName = "issueset-cell";
          if (showFieldText) {
            cellClassName += " textual";
          }
          if (isCellEditable) {
            cellClassName += " editable";
          }
          let isBlank = false;
          let isOverridden = false;
          const baseValue = _.get(overridableFields.main, fieldName);
          const defaultValue = !isMaster ? null : baseValue;
          let value = _.get(values, fieldName, defaultValue);
          if (isMaster && value && value !== baseValue) {
            isOverridden = true;
            cellClassName += " overridden-at-master";
          }
          const masterValues = _.get(
            overridableFields.overridden,
            masterDataPath,
            {},
          );
          const masterValue = _.get(masterValues, fieldName);
          if (!isMaster) {
            if (value === null && masterValue) {
              cellClassName += " overridden-at-master";
            } else if (value !== null) {
              isOverridden = true;
              cellClassName += " overridden-at-client";
            } else if (!showOverridesOnly) {
              value = baseValue;
            }
          }
          if (
            !showFieldText ||
            (showOverridesOnly && isMaster && value === baseValue)
          ) {
            value = null;
          }
          if (showFieldText && !showOverridesOnly) {
            if (value === null && !isMaster && masterValue) {
              value = masterValue;
            }
            if (typeof value === "string" && value.trim() === "") {
              value = "<BLANK>";
              isBlank = true;
            }
          }
          if (_.isObject(value)) {
            value = JSON.stringify(value, null, 4);
            cellClassName += " json";
          }
          return (
            <Td
              key={fieldName}
              className={cellClassName}
              title={
                cellClassName.indexOf("overridden-at-master") >= 0
                  ? "Overridden on template"
                  : cellClassName.indexOf("overridden-at-client") >= 0
                  ? "Overridden on client"
                  : ""
              }
              {...(isCellEditable && {
                isEditable: true,
                handleChildren: (children, {state}) =>
                  state.isEditModeEnabled ? (isBlank ? "" : value) : children,
                onEdit: fieldValue =>
                  this.props.onOverride(
                    dataPath,
                    fieldName,
                    fieldValue,
                    dataPathShown,
                  ),
              })}
            >
              <div className="clearable-container">
                <div>{value}</div>
                {isOverridden && (
                  <IconButton
                    size="small"
                    onClick={() => this.props.onClear(dataPath, fieldName)}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
            </Td>
          );
        })}
      </Tr>
    );
  }
}

export default withStyles(styles)(IssueDetailOverrides);
