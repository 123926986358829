import React, {useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import _ from "underscore";

type AccessTag = NewAccessTag | ExistingAccessTag;

type ExistingAccessTag = {name: string; id: number};
type NewAccessTag = {name: string};

type AccessTagSelectorProps = {
  /** Tags to display in the selector */
  tags: {available: ExistingAccessTag[]; selected: ExistingAccessTag[]};

  /**
   * Optional callback invoked when an existing tag is added to those selected.
   */
  onAddTag?: (tag: ExistingAccessTag) => void;

  /**
   * Optional callback invoked when a new tag is created (and added to those
   * selected).
   */
  onCreateTag?: (tag: NewAccessTag) => void;

  /**
   * Optional callback invoked when a selected tag is removed from those selected.
   */
  onRemoveTag?: (tag: ExistingAccessTag) => void;
};

const AccessTagSelector = ({
  tags,
  onAddTag,
  onCreateTag,
  onRemoveTag,
}: AccessTagSelectorProps) => {
  const [inputValue, setInputValue] = useState("");

  const canCreateTagFromInput =
    // If the input is not empty...
    inputValue.length > 0 &&
    // ...and the caller has given us a mechanism to create tags...
    onCreateTag &&
    // ...and the input does not collide with an existing tag name.
    tags.available.every(tag => tag.name !== inputValue) &&
    tags.selected.every(tag => tag.name !== inputValue);

  const isSelectorDisabled = [onAddTag, onCreateTag, onRemoveTag].every(
    handler => !handler,
  );

  return (
    <Autocomplete<AccessTag, true, true>
      // Could signify the implied permission here with an explicit prop. Might
      // need to in future for clarity. For now, inferring permissions from
      // provision of associated handler.
      disabled={isSelectorDisabled}
      value={tags.selected}
      onChange={(_event, updatedTags) => {
        const newTags = updatedTags.filter(
          tag => !(tags.selected as AccessTag[]).includes(tag),
        );
        const [[createdTag], addedTags] = _.partition(
          newTags,
          (tag: AccessTag) => tag.name === inputValue,
        );
        if (onCreateTag && createdTag) {
          onCreateTag(createdTag);
        }
        if (onAddTag) {
          addedTags.forEach(onAddTag);
        }
        if (onRemoveTag) {
          const removedTags = tags.selected.filter(
            (tag: AccessTag) => !updatedTags.includes(tag),
          );
          removedTags.forEach(onRemoveTag);
        }
      }}
      clearOnBlur={false}
      options={[
        ...tags.available,
        ...(canCreateTagFromInput ? [{name: inputValue}] : []),
      ]}
      getOptionLabel={option =>
        // WARN: Not sure if this derivation of the condition that this option
        // is truly new is foolproof.
        option.name === inputValue && canCreateTagFromInput
          ? `Create "${option.name}"`
          : option.name
      }
      getOptionSelected={(option, value) => option.name === value.name}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      renderInput={params => (
        <TextField
          {...params}
          label="Access Tags"
          placeholder="Search Access Tags"
        />
      )}
      multiple
      disableClearable
      filterSelectedOptions
    />
  );
};

export default AccessTagSelector;
