import IconButton from "@material-ui/core/IconButton";
import React from "react";

import WorkIcon from "@material-ui/icons/Work";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/Tune";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import UnfoldLessIcon from "@material-ui/icons/UnfoldLess";

import Toolbar from "common_components/toolbar";
import IconMenu from "common_components/icon_menu";

import searchStyles from "routes/search/components/styles";

const styles = {
  titleContainer: {
    display: "flex",
  },
  title: {
    marginRight: 10,
  },
  results: {
    color: "#90969b",
  },
};

export default class ProjectListToolbar extends React.Component {
  render() {
    return (
      <Toolbar
        icon={<WorkIcon />}
        title={this.renderTitle()}
        children={this.renderCreateButton()}
        rightChildren={this.renderMenu()}
        childrenStyles={{marginLeft: "auto"}}
      />
    );
  }

  renderTitle() {
    return (
      <div style={styles.titleContainer}>
        <div style={styles.title}>Projects</div>
        <div style={styles.results}>
          {`${this.props.projects.length} Results`}
        </div>
      </div>
    );
  }

  renderCreateButton() {
    return (
      <>
        <div style={searchStyles.toolbarGroupBlock}>
          <SearchIcon style={searchStyles.toolbarGroupIcon} />
          <div style={searchStyles.searchFieldContainer}>
            <TextField
              id="search"
              placeholder="Type to filter project"
              value={this.props.filteringText}
              onChange={this.onFilteringTextChange}
              autoComplete="off"
              autoFocus
              style={{
                ...searchStyles.searchField,
                marginBottom: "10px",
              }}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={this.props.createNewProject}
          disabled={!this.props.hasAddProjectPermission}
        >
          Create New
        </Button>
      </>
    );
  }

  renderMenu() {
    const {
      onSwitchArePrivateProjectsShown,
      arePrivateProjectsShown,
      onSwitchAreArchivedProjectsShown,
      areArchivedProjectsShown,
    } = this.props;
    if (!this.props.user.is_admin) {
      return null;
    }
    const hasManyProjects = this.props.projects.length > 10;
    return (
      <>
        {hasManyProjects && (
          <div style={{borderRight: "1px solid #ddd"}}>
            <IconButton onClick={this.props.collapseProjectsPaths}>
              <Tooltip title="Collapse all" placement="top">
                <UnfoldLessIcon />
              </Tooltip>
            </IconButton>
            <IconButton onClick={this.props.expandProjectsPaths}>
              <Tooltip title="Expand all" placement="top">
                <UnfoldMoreIcon />
              </Tooltip>
            </IconButton>
          </div>
        )}
        <IconMenu
          icon={SettingsIcon}
          menuItems={[
            <MenuItem key="show-hidden-projects">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={onSwitchArePrivateProjectsShown}
                    checked={arePrivateProjectsShown}
                    size="small"
                  />
                }
                label="Show Private Projects"
              />
            </MenuItem>,
            <MenuItem key="show-archived-projects">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={onSwitchAreArchivedProjectsShown}
                    checked={areArchivedProjectsShown}
                    size="small"
                  />
                }
                label="Show Archived Projects"
              />
            </MenuItem>,
          ]}
        />
      </>
    );
  }

  onFilteringTextChange = e => this.props.setFilteringText(e.target.value);
}
