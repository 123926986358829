import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function fetchDocumentConversationItems(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_CONVERSATION_ITEMS_FETCH,
    (
      organisationId,
      projectId,
      documentId,
      conversationId,
      conversationType,
      previousMessageId,
    ) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}` +
            `/document/${documentId}` +
            `/conversation/${conversationId}` +
            `/item/${previousMessageId ? previousMessageId : "start"}`,
          {withCredentials: true},
        )
        .then(response => ({
          conversation: {
            id: conversationId,
            items: response.data,
          },
          document_id: documentId,
          conversation_type: conversationType,
        })),
  );
}
