import _ from "lodash";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router";
import copy from "copy-to-clipboard";

import {Toolbar, ToolbarGroup} from "material-ui/Toolbar";
import TextField from "material-ui/TextField";
import SearchIcon from "material-ui/svg-icons/action/search";
import SortIcon from "material-ui/svg-icons/content/sort";
import CircularProgress from "material-ui/CircularProgress";
import FilterListIcon from "material-ui/svg-icons/content/filter-list";
import Chip from "material-ui/Chip";
import RaisedButton from "material-ui/RaisedButton";
import Avatar from "material-ui/Avatar";

import ArrowRightIcon from "material-ui/svg-icons/navigation/chevron-right";
import ArrowDownIcon from "material-ui/svg-icons/navigation/expand-more";

import {IssueIcon, NoIssueIcon} from "constants/icons";
import increaseReference from "common/utils/increase_reference";
import ToolbarDropdown from "common_components/toolbar/dropdown";
import ToolbarDropdownItem from "common_components/toolbar/dropdown/item";
import styles from "./styles";
import MarkupText from "./markup_text";
import CopyIcon from "./copy_icon";

import * as colors from "material-ui/styles/colors";

import escapeRegEx from "utils/escape_regex";
const badColor = "#C40233";
const goodColor = "green";

export default class SearchComponent extends Component {
  constructor(props) {
    super(props);
    const queryValue = props.queryValue || "";
    this.state = {
      queryValue,
      prevQueryValue: queryValue,
      completelyShown: this.getInitialCompletelyShownState(),
      openDefinitions: [],
    };
  }

  getInitialCompletelyShownState = () => {
    return {
      projects: [],
      documents: [],
    };
  };

  resetOpenDefinitionsState = () => {
    this.setState({openDefinitions: []});
  };

  resetCompletelyShownState = () => {
    this.setState({completelyShown: this.getInitialCompletelyShownState()});
  };

  resetTemporaryState = () => {
    this.resetOpenDefinitionsState();
    this.resetCompletelyShownState();
  };

  isProjectCompletelyShown = projectId => {
    return this.state.completelyShown.projects.includes(projectId);
  };

  isDocumentCompletelyShown = documentId => {
    return this.state.completelyShown.documents.includes(documentId);
  };

  setCompletelyShownProject = projectId => {
    const completelyShown = _.cloneDeep(this.state.completelyShown);
    completelyShown.projects.push(projectId);
    this.setState({completelyShown});
  };

  setCompletelyShownDocument = documentId => {
    const completelyShown = _.cloneDeep(this.state.completelyShown);
    completelyShown.documents.push(documentId);
    this.setState({completelyShown});
  };

  componentDidMount() {
    this.setsearchInputCursorAtEnd();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.queryValue !== this.props.queryValue) {
      const queryValue = this.props.queryValue || "";
      this.setState(
        () => ({
          queryValue,
          prevQueryValue: queryValue,
          completelyShown: this.getInitialCompletelyShownState(),
        }),
        this.setsearchInputCursorAtEnd,
      );
    }
  }

  setsearchInputCursorAtEnd = () => {
    const {input} = this.searchInput;
    input.selectionStart = input.selectionEnd = this.state.queryValue.length;
  };

  render() {
    const matchCountTitle = this.getMatchCountTitle();
    return (
      <div className="app-page">
        <Toolbar className="app-toolbar">
          <ToolbarGroup key={0}>
            <div style={styles.toolbarGroupBlock}>
              <SearchIcon style={styles.toolbarGroupIcon} />
              <div style={styles.searchFieldContainer}>
                <TextField
                  id="search"
                  placeholder="List of words to search"
                  value={this.state.queryValue}
                  onChange={this.onSearchChange}
                  onBlur={this.onSearchBlur}
                  onKeyPress={this.onSearchKeyPress}
                  underlineShow={false}
                  autoFocus
                  ref={input => {
                    this.searchInput = input;
                  }}
                  style={styles.searchField}
                />
                {matchCountTitle && (
                  <div style={styles.searchFieldMatchCount}>
                    {matchCountTitle}
                  </div>
                )}
              </div>
              {this.renderSearchTypesDropdown()}
              {this.renderContractTypeAndProjectDropdowns()}
            </div>
          </ToolbarGroup>
          <ToolbarGroup key={1}>{this.renderSortDropdown()}</ToolbarGroup>
        </Toolbar>
        <div className="app-body" style={{padding: "1em 2em"}}>
          {this.renderContent()}
        </div>
      </div>
    );
  }

  renderSearchTypesDropdown() {
    const values = [];
    const renderItems = (items, withCheckbox = false) => {
      return (items || []).map(item => {
        if (item.isSelected) {
          values.push(item.value);
        }
        return (
          <ToolbarDropdownItem
            key={item.value}
            value={item.value}
            withCheckbox={withCheckbox}
            children={renderItems(item.childItems, true)}
          />
        );
      });
    };
    const renderedItems = renderItems(this.props.searchTypesItems);
    return (
      <ToolbarDropdown
        leftIcon={<FilterListIcon style={styles.toolbarGroupIcon} />}
        labelText="Search type"
        values={values}
        handleChange={this.props.onSearchTypeItemSelect}
      >
        {renderedItems}
      </ToolbarDropdown>
    );
  }

  renderContractTypeAndProjectDropdowns() {
    return this.props.filters.map(filter => (
      <ToolbarDropdown
        key={filter.name}
        leftIcon={React.cloneElement(filter.leftIcon, {
          style: styles.toolbarGroupIcon,
        })}
        labelText={filter.label}
        values={filter.filteredItemIds.map(id => id.toString())}
        showSelectAndUnselectAllItems={true}
        showSelectedItemsList={true}
        showFilterTextField={true}
        itemHeaderStyle={{
          paddingLeft: 15,
          paddingRight: 15,
        }}
        handleChange={values => {
          this.resetTemporaryState();
          this.props.onFilter(filter.name, _.map(values, _.parseInt));
        }}
      >
        {_.map(filter.items, item => (
          <ToolbarDropdownItem
            key={item.id}
            value={item.id.toString()}
            label={item.name}
            withCheckbox={true}
            labelStyle={{height: 22}}
          />
        ))}
      </ToolbarDropdown>
    ));
  }

  renderSortDropdown() {
    const {sorts} = this.props;
    const defaultValue = sorts.find(sort => sort.isDefault).value;
    return (
      <ToolbarDropdown
        leftIcon={<SortIcon style={styles.toolbarGroupIcon} />}
        labelText="Sort by"
        values={[this.props.sortValue || defaultValue]}
        handleChange={values =>
          this.props.onSort(values.includes(defaultValue) ? "" : values.shift())
        }
        hidePopoverOnChange={true}
      >
        {sorts.map(sort => (
          <ToolbarDropdownItem
            key={sort.value}
            value={sort.value}
            label={sort.label}
          />
        ))}
      </ToolbarDropdown>
    );
  }

  getMatchCountTitle = () => {
    if (this.isProcessing() || _.isEmpty(this.props.queryValue)) {
      return "";
    }
    let isLimited = false;
    let clausepartAmount = 0;
    _.each(this.props.results, project => {
      const projectClausepartAmount = this.getProjectClausepartAmount(project);
      clausepartAmount += projectClausepartAmount;
      if (projectClausepartAmount >= 100) {
        isLimited = true;
      }
    });
    return `${clausepartAmount}${isLimited ? "+" : ""} Matches`;
  };

  getProjectClausepartAmount = project => {
    let amount = 0;
    _.each(project.documents, document => {
      amount += document.clauseparts.length;
    });
    return amount;
  };

  isProcessing = () => {
    return (
      this.props.processing ||
      (!_.isArray(this.props.results) && !_.isEmpty(this.props.queryValue))
    );
  };

  isNotEmptyResults = () => {
    return _.isArray(this.props.results) && !_.isEmpty(this.props.results);
  };

  getProjectList = () => {
    const projects = [];
    this.props.results.forEach(_project => {
      const projectDocuments = _project.documents.filter(
        doc => doc.clauseparts && doc.clauseparts.length > 0,
      );
      if (projectDocuments.length === 0) {
        return;
      }
      const project = {
        ..._.pick(_project, ["id", "name"]),
      };
      projects.push(project);
      project.holdsClauseTemplates = _project.holds_clause_templates;
      project.isCompletelyShown = this.isProjectCompletelyShown(_project.id);
      project.documents = _.cloneDeep(projectDocuments);
      project.moreDocumentsAmount = 0;
      if (!project.isCompletelyShown) {
        const limit = 5;
        project.documents = project.documents.splice(0, limit);
        const hiddenDocuments = _.cloneDeep(projectDocuments).splice(limit);
        project.moreDocumentsAmount = hiddenDocuments.length;
      }
      project.documents.forEach((_document, documentIndex) => {
        const document = {
          ..._.pick(_document, ["id", "name", "definitions"]),
        };
        project.documents[documentIndex] = document;
        document.contractTypeName = _document.contract_type_name;
        document.lastEdited = _document.last_edited.substring(0, 10);
        document.isCompletelyShown = this.isDocumentCompletelyShown(
          document.id,
        );
        const clauseNameProps = [
          "topic_names",
          "issue_names",
          "negative_match_issue_names",
        ];
        const clausesById = {};
        _document.clauseparts.forEach(clausepart => {
          let clause = clausesById[clausepart.clause_id];
          if (!clause) {
            clause = {
              id: clausepart.clause_id,
              reference: clausepart.clause_reference,
              clauseparts: [],
            };
            clausesById[clausepart.clause_id] = clause;
          }
          clause.clauseparts.push({
            ..._.pick(_.cloneDeep(clausepart), [
              ...clauseNameProps,
              "id",
              "reference",
              "highlighted_text",
              "sort_field",
            ]),
            hasGap: false,
          });
        });
        const allClauses = _.map(clausesById, clause => clause);
        const splitClauses = [];
        allClauses.forEach(sourceClause => {
          sourceClause.clauseparts.sort((a, b) => {
            if (a.sort_field < b.sort_field) {
              return -1;
            }
            if (a.sort_field > b.sort_field) {
              return 1;
            }
            return 0;
          });
          const minLevel = _.min(
            sourceClause.clauseparts
              .map(({reference}) => reference.split(".").length)
              .filter(length => length > 0),
          );
          let splitClause;
          const prepareSplit = () => {
            splitClause = {
              ..._.cloneDeep(sourceClause),
              clauseparts: [],
            };
            splitClauses.push(splitClause);
          };
          prepareSplit();
          sourceClause.clauseparts.forEach(_clausepart => {
            const clausepart = _.cloneDeep(_clausepart);
            const prevClausepart = splitClause.clauseparts.length
              ? splitClause.clauseparts[splitClause.clauseparts.length - 1]
              : null;
            const prevLevel = prevClausepart
              ? prevClausepart.reference.split(".").length
              : null;
            const currLevel = clausepart.reference.split(".").length;
            if (prevLevel && currLevel < prevLevel && currLevel === minLevel) {
              prepareSplit();
            } else if (
              prevClausepart &&
              prevClausepart.reference !== clausepart.reference
            ) {
              const increasedReference = increaseReference(
                prevClausepart.reference,
                true,
              );
              if (prevLevel === currLevel) {
                if (increasedReference !== clausepart.reference) {
                  prevClausepart.hasGap = true;
                }
              } else if (
                prevLevel < currLevel &&
                !clausepart.reference.match(
                  new RegExp(`^${escapeRegEx(prevClausepart.reference)}`),
                )
              ) {
                prevClausepart.hasGap = true;
              }
            }
            splitClause.clauseparts.push(clausepart);
          });
        });
        splitClauses.forEach(_clause => {
          _clause.clauseparts.forEach(_clausepart => {
            clauseNameProps.forEach(nameProp => {
              if (!_.has(_clause, nameProp)) {
                _clause[nameProp] = [];
              }
              if (_.isArray(_clausepart[nameProp])) {
                _clausepart[nameProp].forEach(highlightedName => {
                  if (!_clause[nameProp].includes(highlightedName)) {
                    _clause[nameProp].push(highlightedName);
                  }
                });
              }
            });
          });
        });
        document.clauses = splitClauses;
        document.moreClausesAmount = 0;
        if (!document.isCompletelyShown) {
          document.clauses = [splitClauses[0]];
          document.moreClausesAmount = splitClauses.length - 1;
        }
      });
    });
    return projects;
  };

  renderContent() {
    if (this.isProcessing()) {
      return <CircularProgress style={styles.circularProgress} />;
    }
    if (!this.isNotEmptyResults()) {
      return <div style={styles.noResultsFound}>No Results Found</div>;
    }
    return (
      <div style={styles.contentBlock}>
        {this.renderProjects(this.getProjectList())}
      </div>
    );
  }

  renderProjects(projects) {
    return projects.map((project, index) => (
      <div key={index}>
        <div
          style={{
            ...styles.projectHeading,
            ...(project.holdsClauseTemplates &&
              styles.prioritisedProjectHeading),
          }}
        >
          <div style={styles.projectTitle}>
            <div>{`Project: ${project.name}`}</div>
          </div>
        </div>
        <div>{this.renderProjectDocuments(project)}</div>
        {Boolean(!project.isCompletelyShown && project.moreDocumentsAmount) && (
          <div style={styles.showMoreButtonContainer}>
            <RaisedButton
              primary={true}
              label={this.buildShowMoreButtonLabel(
                "document",
                project.moreDocumentsAmount,
              )}
              labelStyle={styles.showMoreButtonLabel}
              overlayStyle={styles.showMoreButtonOverlay}
              onClick={() => {
                this.setCompletelyShownProject(project.id);
              }}
            />
          </div>
        )}
      </div>
    ));
  }

  renderProjectDocuments(project) {
    return project.documents.map((document, index) => (
      <div key={index} style={styles.document}>
        <div
          style={{
            ...styles.documentHeading,
            ...(project.holdsClauseTemplates &&
              styles.prioritisedDocumentHeading),
          }}
        >
          <div style={{display: "flex"}}>
            <div style={styles.documentNumber}>{index + 1}</div>
            <div style={styles.documentTitle}>
              <Link
                to={this.getClauseRoute(
                  project.id,
                  document.id,
                  document.clauses[0].reference,
                )}
                target="_blank"
                style={{color: "#333"}}
              >
                {document.name}
              </Link>
              <div style={{margin: "0 8px"}}>/</div>
              <div>{document.contractTypeName}</div>
            </div>
          </div>
          <div title="Upload Date">{document.lastEdited}</div>
        </div>
        <div style={styles.documentClauses}>
          {this.renderDocumentClauses(document)}
        </div>
        {Boolean(!document.isCompletelyShown && document.moreClausesAmount) && (
          <div
            style={{
              ...styles.showMoreButtonContainer,
              borderTop: "1px solid #e0e0e0",
            }}
          >
            <RaisedButton
              label={this.buildShowMoreButtonLabel(
                "clause",
                document.moreClausesAmount,
              )}
              labelStyle={styles.showMoreButtonLabel}
              overlayStyle={styles.showMoreButtonOverlay}
              onClick={() => {
                this.setCompletelyShownDocument(document.id);
              }}
            />
          </div>
        )}
      </div>
    ));
  }

  renderDocumentClauses(document) {
    return document.clauses.map((clause, index) => {
      const isLastClause = index === document.clauses.length - 1;
      return (
        <div
          key={index}
          style={{
            ...styles.clause,
            ...(isLastClause && {
              borderBottom: "none",
              marginBottom: 0,
            }),
          }}
        >
          <div>{this.renderClauseClauseparts(clause)}</div>
          {this.renderDocumentClauseDefinitions(document, clause)}
          {this.renderClauseChips(clause)}
        </div>
      );
    });
  }

  renderClauseClauseparts(clause) {
    return clause.clauseparts.map((clausepart, index) => {
      const isLastClausepart = index === clause.clauseparts.length - 1;
      const prevClauseparts = _.cloneDeep(clause.clauseparts).splice(0, index);
      const prevReferences = prevClauseparts.map(
        prevClausepart => prevClausepart.reference,
      );
      let level = 0;
      let isHiddenReference = false;
      let shownReference = clausepart.reference;
      const referenceParts = clausepart.reference.split(".");
      if (referenceParts.length > 1) {
        for (let index = 1; index < referenceParts.length; index += 1) {
          const part = referenceParts[index];
          const isLastPart = index === referenceParts.length - 1;
          const parentReference = [...referenceParts]
            .splice(0, index)
            .join(".");
          const isParentFound = Boolean(
            clause.clauseparts.find(
              _clausepart =>
                _clausepart !== clausepart &&
                _clausepart.reference === parentReference,
            ),
          );
          if (isParentFound) {
            level += 1;
            if (isLastPart) {
              const isMatched = Boolean(part.match(/^[a-z]+$/i));
              if (isMatched) {
                shownReference = part;
              }
            }
          }
        }
      }
      if (prevReferences.includes(clausepart.reference)) {
        isHiddenReference = true;
      }
      return (
        <div
          key={index}
          style={{
            ...styles.clausepart,
            ...(isLastClausepart && {
              marginBottom: 0,
            }),
            marginLeft: level * 40,
          }}
        >
          <div style={styles.clausepartInner}>
            <div style={styles.clausepartContent}>
              <div
                style={{
                  ...styles.clausepartReference,
                  ...(isHiddenReference && {opacity: 0}),
                }}
              >
                {shownReference}
              </div>
              <MarkupText
                text={clausepart.highlighted_text}
                rootStyle={{flexGrow: 1}}
                highlightedStyle={{
                  backgroundColor: "#ffde7a",
                }}
              />
            </div>
            <div style={styles.clausepartIcons}>
              <CopyIcon
                handleClick={() =>
                  this.stripHtmlAndCopyText(clausepart.highlighted_text)
                }
              />
            </div>
          </div>
          {clausepart.hasGap && <div style={styles.clausepartGap}>[...]</div>}
        </div>
      );
    });
  }

  stripHtmlAndCopyText = html => {
    const div = document.createElement("div");
    div.innerHTML = html;
    copy(div.innerText, {format: "text/plain"});
  };

  buildShowMoreButtonLabel = (word, number) => {
    return `Show ${number} more ${word}${number > 1 ? "s" : ""}`;
  };

  renderClauseChips(clause) {
    const {
      topic_names: topicNames,
      issue_names: issueNames,
      issue_trigger_displays: issueTriggerDisplays,
      negative_match_issue_names: negativeMatchIssueNames,
      negative_match_issue_trigger_displays: negativeMatchIssueTriggerDisplays,
    } = clause;
    if (
      !issueNames.length &&
      !topicNames.length &&
      !negativeMatchIssueNames.length
    ) {
      return null;
    }
    const list = [];
    const fillList = (
      nameList,
      triggerDisplayList,
      reverse,
      goodColor,
      badColor,
      goodIcon,
      badIcon,
    ) => {
      _.zip(nameList, triggerDisplayList).forEach(([name, triggerDisplay]) => {
        const isIssue =
          reverse === null
            ? null
            : triggerDisplay === undefined || triggerDisplay === true
              ? !reverse
              : reverse;
        list.push({
          name,
          isIssue,
          color: isIssue ? goodColor : badColor,
          icon: isIssue ? goodIcon : badIcon,
        });
      });
    };
    if (topicNames.length) {
      fillList(topicNames, null, null, colors.cyan200);
    }
    if (issueNames.length) {
      fillList(
        issueNames,
        issueTriggerDisplays,
        false,
        badColor,
        goodColor,
        IssueIcon,
        NoIssueIcon,
      );
    }
    if (negativeMatchIssueNames.length) {
      fillList(
        negativeMatchIssueNames,
        negativeMatchIssueTriggerDisplays,
        true,
        badColor,
        goodColor,
        IssueIcon,
        NoIssueIcon,
      );
    }
    const {searchTypesItems} = this.props;
    const context = searchTypesItems[2];
    const filteredList = list.filter(
      item =>
        item.isIssue === null ||
        searchTypesItems[0].isSelected ||
        (context.childItems[0].isSelected && item.isIssue) ||
        (context.childItems[1].isSelected && !item.isIssue),
    );
    return (
      <div style={styles.clauseChipsContainer}>
        {filteredList.map((data, index) => {
          const text = data.name
            .replace(/\[\*/, "[")
            .replace(/\[(.*)\]/g, " ($1)");
          return (
            <Chip
              key={index}
              backgroundColor={data.icon ? "#fdfdfd" : data.color}
              style={data.icon ? styles.clauseChipWithIcon : styles.clauseChip}
              labelStyle={styles.clauseChipLabel}
            >
              {data.icon && (
                <Avatar
                  style={styles.clauseChipAvatar}
                  color={data.color}
                  icon={<data.icon style={styles.clauseChipAvatarIcon} />}
                />
              )}
              <MarkupText text={text} highlightedStyle={{fontWeight: "bold"}} />
            </Chip>
          );
        })}
      </div>
    );
  }

  renderDocumentClauseDefinitions(document, clause) {
    const matchingDefinitions = (document.definitions || []).filter(
      definition => {
        for (let i = 0; i < clause.clauseparts.length; i += 1) {
          const clausepart = clause.clauseparts[i];
          if (clausepart.highlighted_text.indexOf(definition.term) >= 0) {
            return true;
          }
        }
        return false;
      },
    );
    if (!matchingDefinitions.length) {
      return null;
    }
    const definitions = _.sortBy(
      matchingDefinitions,
      definition => definition.term,
    );
    const clausepartIds = clause.clauseparts.map(({id}) => id).sort();
    const openDefinitionsId = `${clause.id}:${clausepartIds.join(",")}`;
    if (this.state.openDefinitions.includes(openDefinitionsId)) {
      return (
        <div style={styles.definitionsOpened}>
          <div onClick={this.onCloseDefinition(openDefinitionsId)}>
            <ArrowDownIcon />
          </div>
          <table style={styles.definitionsTable}>
            <thead>
              <tr>
                <th style={styles.definitionsTableHeader}>Term</th>
                <th style={styles.definitionsTableHeader} colSpan="2">
                  Meaning
                </th>
              </tr>
            </thead>
            <tbody>
              {definitions.map((definition, index) => (
                <tr key={index}>
                  <td
                    style={{
                      ...styles.definitionsTableCell,
                      ...styles.definitionsTableTermCell,
                    }}
                  >
                    {definition.term}
                  </td>
                  <td
                    style={{
                      ...styles.definitionsTableCell,
                      ...styles.definitionsTableMeaningCell,
                    }}
                  >
                    {definition.meaning}
                  </td>
                  <td
                    style={{
                      ...styles.definitionsTableCell,
                      ...styles.definitionsTableCopyCell,
                    }}
                  >
                    <CopyIcon
                      handleClick={() =>
                        this.stripHtmlAndCopyText(definition.meaning)
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return (
      <div
        style={styles.definitionsClosed}
        onClick={this.onOpenDefinition(openDefinitionsId)}
      >
        <ArrowRightIcon />
        <span style={styles.definitionsClosedText}>
          Definitions: {definitions.length}
        </span>
      </div>
    );
  }

  onOpenDefinition(openDefinitionsId) {
    return () => {
      const openDefinitions = [...this.state.openDefinitions];
      openDefinitions.push(openDefinitionsId);
      this.setState({openDefinitions});
    };
  }

  onCloseDefinition(openDefinitionsId) {
    return () => {
      const openDefinitions = [...this.state.openDefinitions];
      const index = openDefinitions.indexOf(openDefinitionsId);
      if (index > -1) {
        openDefinitions.splice(index, 1);
      }
      this.setState({openDefinitions});
    };
  }

  onSearchChange = (e, value) => this.setState({queryValue: value});

  onSearchBlur = () => {
    const {queryValue, prevQueryValue} = this.state;
    if (prevQueryValue !== queryValue) {
      this.props.onSearch(queryValue);
      this.setState({prevQueryValue: queryValue});
    }
  };

  onSearchKeyPress = e => {
    if (e.key === "Enter") {
      this.onSearchBlur();
    }
  };

  getClauseRoute = (projectId, documentId, clauseReference) => ({
    pathname: `/organisation/${
      this.props.organisationId
    }/project/${projectId}/document/${documentId}/detail`,
    search: `?clause=${clauseReference}`,
  });
}

SearchComponent.propTypes = {
  organisationId: PropTypes.string,
  results: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  processing: PropTypes.bool,
  onFilter: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  sorts: PropTypes.array.isRequired,
  queryValue: PropTypes.string,
  sortValue: PropTypes.string,
  searchTypesItems: PropTypes.array.isRequired,
  onSearchTypeItemSelect: PropTypes.func.isRequired,
};
