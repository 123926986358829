import _ from "underscore";

function filterTopicsByContractTypes(issueContractTypes, topics) {
  if (!topics) {
    return [];
  }
  if (!issueContractTypes || issueContractTypes.length === 0) {
    return topics;
  }
  const issueContractTypeIds = _.map(
    issueContractTypes,
    item => item.contract_type_id,
  );
  return topics.filter(topic => {
    const topicContractTypeIds = _.map(
      topic.contract_types,
      item => item.contract_type_id,
    );
    return (
      _.intersection(topicContractTypeIds, issueContractTypeIds).length ===
      issueContractTypes.length
    );
  });
}

export default filterTopicsByContractTypes;
