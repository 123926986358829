import React from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SubjectIcon from "@material-ui/icons/Subject";

export default function(props) {
  const [value, setValue] = React.useState("");
  const [areLogsVisible, showLogs] = React.useState(true);

  const handleChange = event => {
    if (event.nativeEvent.inputType === "insertLineBreak") {
      handleClick();
    } else {
      setValue(event.target.value);
    }
  };

  const handleClick = () => {
    props.testClause(value);
  };
  return (
    <>
      <fieldset style={{border: "1px solid #aaa"}}>
        <legend
          style={{
            border: "1px solid #666",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{margin: " 0 1em", fontStyle: "italic"}}>
            Clause tester
          </span>
          <Button onClick={handleClick} style={{backgroundColor: "#eee"}}>
            Run
          </Button>
          {props.fetchLogs && (
            <IconButton size="small" onClick={props.getJobLogs}>
              {<SubjectIcon />}
            </IconButton>
          )}
        </legend>
        <TextField
          label="Text to test"
          style={{width: "100%"}}
          multiline
          value={value}
          onChange={handleChange}
        />
        {showResult(props.topic.textTestResult, props.selectedClassifier)}
      </fieldset>
      {renderLogs(props.selectedClassifier.logs, areLogsVisible, showLogs)}
    </>
  );
}

function renderLogs(logs, areLogsVisible, showLogs) {
  if (!logs) {
    return null;
  }
  return (
    <div
      style={{
        border: "1px solid #888",
        margin: "2px",
        padding: "1em",
      }}
    >
      <div style={{fontWeight: "bold"}}>
        Training Logs{" "}
        <Button onClick={() => showLogs(!areLogsVisible)}>
          {areLogsVisible ? "Hide" : "Show"} Logs
        </Button>
      </div>
      {areLogsVisible && (
        <table
          style={{
            tableLayout: "fixed",
            fontFamily: "monospace",
            width: "100%",
          }}
        >
          {logs.map((log, index) => (
            <tr key={index}>
              <td style={{whiteSpace: "nowrap", color: "#888", width: "16em"}}>
                {new Date(log.timestamp).toISOString()}
              </td>
              <td style={{wordBreak: "break-all"}}>{log.message}</td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
}

function showResult(result, classifier) {
  if (result) {
    try {
      const topic = result.result.clauseparts[0].topics[0].scores;
      const value = topic[classifier.name][classifier.configuration_name];
      return (
        <table style={{marginTop: "1em"}}>
          <tr>
            <th>Classified</th>
            <th>Score</th>
            {value.label ? <th>Label</th> : null}
          </tr>
          <tr>
            <td>{JSON.stringify(value.is_classified)}</td>
            <td>{value.score}</td>
            {value.label ? <td>{value.label}</td> : null}
          </tr>
        </table>
      );
    } catch {
      return "";
    }
  }
  return "";
}
