import React from "react";

import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import sessionStorage from "utils/session_storage";

const styles = {
  spinner: {
    position: "absolute",
    top: 24,
    left: -38,
    width: 24,
    height: 24,
  },
};

const DEFAULT_LIMIT = 50;

export const getTopicUsagesFetchLimit = topicId => {
  const sessionStorageValue = sessionStorage.getItem("usagesFetchLimit") || {};
  return parseInt(sessionStorageValue[topicId], 10) || DEFAULT_LIMIT;
};

const setTopicUsagesFetchLimit = (topicId, value) => {
  const sessionStorageValue = sessionStorage.getItem("usagesFetchLimit") || {};
  const newSessionStorageValue = {
    ...sessionStorageValue,
    [topicId]: value,
  };
  sessionStorage.setItem("usagesFetchLimit", newSessionStorageValue);
};

function UsagesFetchLimitInput(props) {
  const {topicId, totalValuesCount} = props;

  const [value, updateValue] = React.useState(
    getTopicUsagesFetchLimit(topicId),
  );

  function onBlur() {
    if (value > 0) {
      setTopicUsagesFetchLimit(topicId, value);
    }
    if (props.getTopicUsages) {
      props.getTopicUsages();
    }
  }

  function onChange(e) {
    updateValue(e.target.value);
  }

  const totalValuesCountExist =
    totalValuesCount !== null && totalValuesCount !== undefined;

  return (
    <div style={{position: "relative", display: "flex", alignItems: "center"}}>
      {props.isRequestPending ? (
        <CircularProgress style={styles.spinner} />
      ) : null}
      <TextField
        label={`Usages Fetch Limit${
          totalValuesCountExist ? ` (Max: ${totalValuesCount})` : ""
        }`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type="number"
        inputProps={{min: 0}}
        InputLabelProps={{style: {minWidth: "14rem"}}}
        style={{
          maxWidth: 204,
          marginTop: 6,
        }}
      />
    </div>
  );
}

export default UsagesFetchLimitInput;
