import React from "react";

import {FormControlLabel, Checkbox} from "@material-ui/core";

interface Props {
  label: string;
  value: string;
  checked: boolean;
  disabled: boolean;
  onChange: (value: boolean) => void;
}

export default function AlgorithmSelector({
  label,
  value,
  checked,
  onChange,
  disabled,
}: Props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={disabled ? false : checked}
          onChange={event => onChange(event.target.checked)}
          name={value}
          color="primary"
          disabled={disabled}
        />
      }
      label={label}
    />
  );
}
