import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function removeUnconfirmedTopics(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_CLAUSE_UNCONFIRMED_TOPICS_REMOVE,
    (
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clausepartLastEdited,
    ) =>
      requestor["delete"](
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/clause/${clauseId}` +
          `/clausepart/${clausepartId}/unconfirmed_topic`,
        {withCredentials: true, data: {last_edited: clausepartLastEdited}},
      ).then(response => ({
        clause_id: clauseId,
        clausepart_id: clausepartId,
        section_id: sectionId,
        ...response.data,
      })),
  );
}
