import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {ActionDefinition} from "common/flowmaster/types/task_config";

export type ActionDefinitionsFetchAction = {
  type: WorkflowsActionTypes["ACTION_DEFINITION_FETCH"]["SUCCESS"];
  payload: ActionDefinition[];
};

export default function getActionDefinitions(requestor) {
  return createReduxPromiseAction(
    ActionTypes.ACTION_DEFINITION_FETCH,
    (organisationId: OrganisationId) => {
      return requestor
        .get(`${basePath}/organisation/${organisationId}/action_definition`, {
          withCredentials: true,
        })
        .then(response => {
          return response.data;
        });
    },
  );
}
