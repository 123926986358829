import React from "react";
import "react-vis/dist/style.css";

import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  MarkSeries,
  DiscreteColorLegend,
} from "react-vis";

const styles = {
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  legendContainer: {
    position: "relative",
    margin: "0 1em",
  },
  legend: {
    top: "30px",
    backgroundColor: "#fff",
    border: "1px solid #eee",
    opacity: "1",
    zIndex: "1",
  },
};

function Graph({data, tickValues, legendItems, width, height, axis}) {
  return (
    <div style={styles.root}>
      <div style={styles.legendContainer}>
        <DiscreteColorLegend
          items={legendItems}
          style={styles.legend}
          orientation="horizontal"
        />
      </div>
      <div>
        <XYPlot
          width={width}
          height={height}
          margin={{left: 80, bottom: 190, top: 20, right: 20}}
          colorType="literal"
        >
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis
            tickFormat={x => <tspan>{tickValues[x]}</tspan>}
            tickLabelAngle={-90}
            title="Time"
          />
          <YAxis left={0} title={axis.y} />
          <MarkSeries data={data} />
        </XYPlot>
      </div>
    </div>
  );
}

export default Graph;
