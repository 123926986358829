const commonStyles = {
  circle: {
    width: 21,
    height: 21,
    borderRadius: "100%",
    marginRight: 4,
  },
};

export default commonStyles;
