import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types";
import ContractTypeContext from "../containers/contract_type_context";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const initialState = {
  name: "New Contract Type",
  is_visible: true,
  nameError: "",
};

function nameAlreadyExists(name, contractTypes) {
  const foundCt = contractTypes.find(
    ct => ct.name.toLowerCase() === name.toLowerCase(),
  );
  return Boolean(foundCt);
}

function CreateContractTypeDialogNew(props) {
  const [{name, is_visible, nameError}, setState] = useState(initialState);

  const clearState = () => {
    setState({...initialState});
  };

  useEffect(
    () => {
      clearState();
    },
    [props.createDialogShown],
  );

  const setStateValue = (name, value) =>
    setState(prevState => ({...prevState, [name]: value}));

  const onNameChange = e => setStateValue("name", e.target.value);
  const validateName = e => {
    if (e.target.value.length === 0) {
      setStateValue("nameError", "You must set contract type name");
    } else {
      setStateValue("nameError", "");
    }
  };

  const onIsVisibleCheck = e => {
    const {checked} = e.target;
    setStateValue("is_visible", checked);
  };

  const {addContractType} = useContext(ContractTypeContext);

  const addNewContractTypeHandler = () => {
    if (
      name.toLowerCase() === "new contract type" ||
      nameAlreadyExists(name, props.contractTypes)
    ) {
      setStateValue("nameError", "Please specify different contract type name");
    } else {
      addContractType({name, is_visible});
      props.onHideCreateDialog();
    }
  };

  return (
    <Dialog
      fullWidth
      onClose={props.onHideCreateDialog}
      open={props.createDialogShown}
    >
      <DialogTitle>Create new contract type</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          label="Contract Type Name"
          helperText={nameError}
          error={Boolean(nameError)}
          value={name}
          onChange={onNameChange}
          onBlur={validateName}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={is_visible}
              onChange={onIsVisibleCheck}
              color="primary"
            />
          }
          label="Visible"
        />
      </DialogContent>
      <DialogActions>
        <Button
          key="create-contrat-type-dialog-cancel-button"
          onClick={props.onHideCreateDialog}
        >
          Cancel
        </Button>
        <Button
          key="create-contrat-type-dialog-create-button"
          color="primary"
          onClick={addNewContractTypeHandler}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateContractTypeDialogNew.propTypes = {
  contractTypes: PropTypes.array.isRequired,
  onHideCreateDialog: PropTypes.func.isRequired,
  createDialogShown: PropTypes.bool.isRequired,
};

export default CreateContractTypeDialogNew;
