import _ from "underscore";

function findReference(indexReferences, headingsByReference) {
  let foundRef = indexReferences.find(ref => headingsByReference[ref]);
  if (!foundRef) {
    foundRef = _.last(indexReferences);
  }
  return foundRef;
}

function setupParents(headingsByReference, heading) {
  const parents = [];
  let parent = headingsByReference[heading.parent];
  while (parent !== undefined) {
    parents.unshift(parent);
    parent = headingsByReference[parent.parent];
  }
  parents.push(heading);
  return parents;
}

function determineIndexReferences(reference = "") {
  let ref = reference;
  const result = [ref];
  while (ref.indexOf(".") > 0) {
    ref = ref.substring(0, ref.lastIndexOf("."));
    result.push(ref);
  }
  return result;
}

function groupClause(clausesByHeadings, headingsByReference, clause) {
  const {reference} = clause;
  const indexReferences = determineIndexReferences(reference);
  const foundRef = findReference(indexReferences, headingsByReference);
  const heading = headingsByReference[foundRef];

  if (heading) {
    const parents = setupParents(headingsByReference, heading);
    let currentObj = clausesByHeadings;
    parents.forEach(parentHeading => {
      const realHeadingRef = parentHeading.cleanReference;
      const lastClause = _.last(currentObj);
      if (!lastClause || lastClause.reference !== realHeadingRef) {
        currentObj.push({
          reference: realHeadingRef,
          clauses: [],
          heading: headingsByReference[realHeadingRef],
        });
      }
      currentObj = _.last(currentObj).clauses;
    });
    currentObj.push(clause);
  } else {
    const lastClause = _.last(clausesByHeadings);
    if (!lastClause || lastClause.reference !== foundRef) {
      clausesByHeadings.push({
        clauses: [],
        reference: foundRef,
        heading: headingsByReference[foundRef],
      });
    }
    _.last(clausesByHeadings).clauses.push(clause);
  }
}

export default function groupClauses(clauses, headings, sectionId) {
  if (!clauses) {
    return [];
  }
  const headingsByReference = {};
  headings
    .filter(heading => heading.section_id === sectionId)
    .forEach(heading => {
      let {reference} = heading;
      // if it ends with a ., remove it for purposes of grouping
      // todo: should be lastIndexOf here ??
      if (reference.indexOf(".") === reference.length - 1) {
        reference = reference.substring(0, reference.lastIndexOf("."));
      }
      headingsByReference[reference] = heading;
      headingsByReference[reference].cleanReference = reference;
      if (reference.indexOf(".") >= 0) {
        headingsByReference[reference].parent = reference.substring(
          0,
          reference.lastIndexOf("."),
        );
      }
    });

  const clausesByHeadings = [];
  clauses.forEach(clause =>
    groupClause(clausesByHeadings, headingsByReference, clause),
  );
  return clausesByHeadings;
}
