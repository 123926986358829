import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function updateClausepartIsBadlyParsed(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_CLAUSEPART_UPDATE,
    (organisationId, projectId, documentId, clauseId, id, isBadlyParsed) =>
      requestor
        .put(
          `${basePath}/organisation/${organisationId}/project/${projectId}` +
            `/document/${documentId}/clausepart`,
          {id, clause_id: clauseId, isBadlyParsed},
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
