import React from "react";

import ContainedTextField from "../../../contained_text_field";
import ContextValueEditor from "./context";
import PromptValueEditor from "./prompt";
import TaskValueEditor from "./task";
import InputSelector from "common_components/flowmaster/input_selector";

import {DataLocation} from "common/flowmaster/types/data_location";
import {
  WorkflowTask,
  ActionDefinition,
  StringArgument,
} from "common/flowmaster/types/task_config";
import {
  Prompt,
  WorkflowContext,
  WorkflowInputs,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

interface ValueEditorProps {
  argValue: StringArgument;
  priorTasks: WorkflowTask[];
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  onUpdateItem: (value: DataLocation<string>) => void;
  organisationId: OrganisationId;
}

const ValueEditor: React.FC<ValueEditorProps> = ({
  argValue,
  priorTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  onUpdateItem,
  organisationId,
}) => {
  const stringArg = argValue?.value;
  if (!stringArg || stringArg?.source_type === "none") {
    return null;
  }
  if (stringArg?.source_type === "context") {
    return (
      <ContextValueEditor
        location={stringArg}
        context={context}
        onUpdateItem={onUpdateItem}
      />
    );
  }
  if (stringArg?.source_type === "input") {
    return (
      <InputSelector
        location={stringArg}
        inputs={inputs}
        onUpdateItem={onUpdateItem}
        type="string"
      />
    );
  }
  if (stringArg?.source_type === "prompt") {
    return (
      <PromptValueEditor
        location={stringArg}
        prompts={prompts}
        onUpdateItem={onUpdateItem}
        organisationId={organisationId}
      />
    );
  }
  if (stringArg?.source_type === "task") {
    return (
      <TaskValueEditor
        location={stringArg}
        priorTasks={priorTasks}
        actionDefinitions={actionDefinitions}
        onUpdateItem={onUpdateItem}
      />
    );
  }
  return (
    <ContainedTextField
      initialValue={stringArg?.value as string}
      label="Text"
      multiline
      onUpdate={value => onUpdateItem({...stringArg, value})}
    />
  );
};

export default ValueEditor;
