import basePath from "base_path";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes, {UsergroupActionTypes} from "../constants/action_types";
import {UsergroupUser} from "common/types/usergroup";

export type RemoveUserFromUsergroupAction = {
  type: UsergroupActionTypes["USERGROUP_USER_REMOVE"]["SUCCESS"];
  payload: {usergroupId: number; user: Pick<UsergroupUser, "id">};
};

export default createReduxPromiseAction(
  ActionTypes.USERGROUP_USER_REMOVE,
  (
    organisationId: number,
    usergroupId: number,
    user: Pick<UsergroupUser, "id">,
  ) =>
    requestor
      .delete(
        `${basePath}/organisation/${organisationId}/usergroup/${usergroupId}/user/${user.id}`,
        {withCredentials: true},
      )
      .then(() => ({usergroupId, user})),
);
