import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import TabPanel from "./tab_panel";

const centeredRow = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const useStyles = makeStyles({
  appBar: {...centeredRow},
  tabs: {flexGrow: 1},
  tabsContainer: {...centeredRow, justifyContent: "center"},
  tabWrapper: {
    ...centeredRow,
    margin: "1em",
    "& *": {
      marginRight: ".5em",
    },
  },
});

function CustomTabs({labels, icons, panels, handleChange, value}) {
  const classes = useStyles();

  if (!labels || !labels.length || !panels || !panels.length) {
    return null;
  }

  const getIcon = (icons, index) => {
    if (icons && icons[index]) {
      const Icon = icons[index];
      return <Icon />;
    }
    return null;
  };

  return (
    <>
      <AppBar position="static" color="default" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
          classes={{
            flexContainer: classes.tabsContainer,
          }}
        >
          {labels.map((label, index) => {
            return (
              <Tab
                key={index}
                label={label}
                icon={getIcon(icons, index)}
                classes={{wrapper: classes.tabWrapper}}
              />
            );
          })}
        </Tabs>
        <div className="tabs-toolbar" />
      </AppBar>
      {panels.map((panel, index) => (
        <TabPanel value={value} index={index} key={index}>
          {panel}
        </TabPanel>
      ))}
    </>
  );
}

export default CustomTabs;
