import React from "react";
import {cloneDeep} from "lodash";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import ListItemHoverable from "common_components/project/project_settings/list_item_hoverable";
import StatefulTextField from "common_components/inputs/stateful_textfield";
import uuid from "utils/uuid";

import REPORT_TYPES from "constants/report_types";

import constants from "../constants";
import renderProjectSettings from "common_components/project/project_settings/render_project_settings";

function ProjectReportTab(props) {
  const {
    propsProject,
    stateProject,
    onProjectKeyChange,
    createSelectField,
  } = props;

  const [addedReportSettings, onAddedReportSettingsUpdate] = React.useState(
    null,
  );

  const [selectedReportId, onSelectedReportIdUpdate] = React.useState(
    stateProject.report_settings && stateProject.report_settings[0]
      ? stateProject.report_settings[0].id
      : null,
  );

  function renderReportTypeSelector() {
    if (!selectedReportId && !addedReportSettings) {
      return null;
    }
    let value;
    let onChange = () => null;

    const menuItems = Object.keys(REPORT_TYPES).map(reportType => ({
      id: reportType,
      name: REPORT_TYPES[reportType].label,
    }));

    if (selectedReportId) {
      value = (
        stateProject.report_settings.find(
          report => report.id === selectedReportId,
        ) || {}
      ).type;
    } else if (addedReportSettings) {
      value = addedReportSettings.type;
      onChange = e => {
        const newType = e.target.value;
        const newReport = cloneDeep(REPORT_TYPES[newType].default_settings);
        onAddedReportSettingsUpdate(newReport);
      };
    }

    if (!value) {
      return null;
    }

    return createSelectField(
      "Report Type",
      value,
      Boolean(selectedReportId), // disabled
      onChange,
      menuItems,
      false, // hasChanged
      {margin: "8px 0px", width: "100%", minWidth: 120},
    );
  }

  function renderReportLabelField() {
    if (!selectedReportId && !addedReportSettings) {
      return null;
    }
    let value;
    let onBlur;
    let hasChanges = false;
    if (selectedReportId) {
      const selectedReport =
        stateProject.report_settings.find(
          report => report.id === selectedReportId,
        ) || {};
      value = selectedReport.label;
      onBlur = newValue =>
        onSelectedReportSettingsUpdate({
          ...selectedReport,
          label: newValue,
        });
      const propsSelectedReport =
        propsProject.report_settings.find(
          report => report.id === selectedReportId,
        ) || {};
      hasChanges = propsSelectedReport.label !== value;
    } else if (addedReportSettings) {
      value = addedReportSettings.label;
      onBlur = newValue =>
        onAddedReportSettingsUpdate({...addedReportSettings, label: newValue});
      hasChanges = true;
    }
    if (!value) {
      return null;
    }
    return (
      <div style={{display: "flex", alignItems: "center"}}>
        <StatefulTextField
          value={value}
          label="Report Name"
          onBlur={onBlur}
          style={{
            width: "100%",
            ...(hasChanges ? {background: constants.changedKeyColor} : {}),
          }}
        />
        {selectedReportId && (
          <DeleteIcon
            onClick={removeReport(selectedReportId)}
            style={{color: "#424242", cursor: "pointer"}}
          />
        )}
      </div>
    );
  }

  const onStartReportAdd = () => {
    const newReport = cloneDeep(REPORT_TYPES["rag_report"].default_settings);
    onAddedReportSettingsUpdate(newReport);
    onSelectedReportIdUpdate(null);
  };

  const onFinishReportAdd = () => {
    const newReportId = uuid();
    const reportToSave = {
      id: newReportId,
      ...addedReportSettings,
    };

    const newReportSettings = [...stateProject.report_settings, reportToSave];
    onProjectKeyChange("report_settings", newReportSettings);
    onAddedReportSettingsUpdate(null);
    onSelectedReportIdUpdate(newReportId);
  };

  const onCancelReportAdd = () => onAddedReportSettingsUpdate(null);

  const removeReport = removedReportId => e => {
    e.stopPropagation();
    const {report_settings: stateReportSettings} = stateProject;
    const newReportSettings = stateReportSettings.filter(
      report => report.id !== removedReportId,
    );
    if (selectedReportId === removedReportId) {
      onSelectedReportIdUpdate(null);
    }
    onProjectKeyChange("report_settings", newReportSettings);
  };

  const shiftReportUp = reportId => () => {
    const {report_settings: stateReportSettings} = stateProject;
    const reportIndex = stateReportSettings.findIndex(
      report => report.id === reportId,
    );
    if (reportIndex !== 0) {
      const newReportSettings = cloneDeep(stateReportSettings);
      const temp = newReportSettings[reportIndex];
      newReportSettings[reportIndex] = newReportSettings[reportIndex - 1];
      newReportSettings[reportIndex - 1] = temp;
      onProjectKeyChange("report_settings", newReportSettings);
    }
  };

  const shiftReportDown = reportId => () => {
    const {report_settings: stateReportSettings} = stateProject;
    const reportIndex = stateReportSettings.findIndex(
      report => report.id === reportId,
    );
    if (reportIndex !== stateReportSettings.length - 1) {
      const newReportSettings = cloneDeep(stateReportSettings);
      const temp = newReportSettings[reportIndex];
      newReportSettings[reportIndex] = newReportSettings[reportIndex + 1];
      newReportSettings[reportIndex + 1] = temp;
      onProjectKeyChange("report_settings", newReportSettings);
    }
  };

  function onSelectedReportSettingsUpdate(newReport) {
    const newReportSettings = stateProject.report_settings.map(
      report => (report.id === selectedReportId ? newReport : report),
    );
    onProjectKeyChange("report_settings", newReportSettings);
  }

  function renderReportSettings() {
    if (!selectedReportId && !addedReportSettings) {
      return null;
    }

    let settings;
    let baseSettings;
    let selectedReportType = null;
    let onSettingsChange;
    if (addedReportSettings) {
      settings = {...addedReportSettings};
      baseSettings = cloneDeep(settings);
      selectedReportType = addedReportSettings.type;
      onSettingsChange = newReport => onAddedReportSettingsUpdate(newReport);
    } else if (selectedReportId) {
      settings = stateProject.report_settings.find(
        report => report.id === selectedReportId,
      ) || {fields: []};
      baseSettings = propsProject.report_settings.find(
        report => report.id === selectedReportId,
      ) || {fields: []};
      selectedReportType = settings.type;
      onSettingsChange = onSelectedReportSettingsUpdate;
    }
    const reportProps = {
      settings,
      baseSettings,
      onSettingsChange,
      issuesets: propsProject.issuesets,
      issues: props.issues,
      contractTypes: props.contractTypes,
      project: props.propsProject,
    };

    return renderProjectSettings(selectedReportType, reportProps);
  }

  const onReportSelect = selectedReportId => () => {
    onSelectedReportIdUpdate(selectedReportId);
    onAddedReportSettingsUpdate(null);
  };

  const renderReportsList = () => {
    return (
      <div
        style={{borderRight: "1px solid #9e9e9e", width: 210, flexShrink: 0}}
      >
        <List dense={true}>
          {stateProject.report_settings.map((report, reportIndex) => (
            <ListItemHoverable
              key={reportIndex}
              isFirstInList={reportIndex === 0}
              isLastInList={
                reportIndex === stateProject.report_settings.length - 1
              }
              onClick={onReportSelect(report.id)}
              selected={report.id === selectedReportId}
              onShiftUp={shiftReportUp(report.id)}
              onShiftDown={shiftReportDown(report.id)}
            >
              <ListItemText
                primary={report.label}
                secondary={REPORT_TYPES[report.type].default_settings.label}
                secondaryTypographyProps={{style: {fontSize: 12}}}
                style={{wordBreak: "break-all", marginLeft: 12}}
              />
            </ListItemHoverable>
          ))}
          <ListItem
            key="add-new-report-button"
            button
            onClick={onStartReportAdd}
            style={{justifyContent: "center"}}
          >
            <AddIcon />
          </ListItem>
        </List>
      </div>
    );
  };

  return (
    <div style={{padding: "2rem", display: "flex"}}>
      {renderReportsList()}
      <div style={{padding: "0px 16px"}}>
        {renderReportTypeSelector()}
        {renderReportLabelField()}
        {renderReportSettings()}
        {addedReportSettings && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<KeyboardBackspaceIcon />}
            style={{marginTop: 12}}
            onClick={onFinishReportAdd}
          >
            Add Report
          </Button>
        )}
        {addedReportSettings && (
          <Button
            variant="contained"
            color="primary"
            style={{marginTop: 12, marginLeft: 20}}
            onClick={onCancelReportAdd}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
}

export default ProjectReportTab;
