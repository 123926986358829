import React from "react";

import TextField from "material-ui/TextField";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import * as colors from "material-ui/styles/colors";

import Checkbox from "common_components/checkbox";
import RouterHoverLink from "../../../common_components/router_hover_link";

const styles = {
  item: {
    flexGrow: 1,
  },
  masterName: {
    color: colors.grey500,
    fontSize: 12,
  },
  clientContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 18,
  },
  clientName: {
    flexGrow: 1,
  },
  editIcon: {
    width: 18,
    height: 18,
    fill: colors.grey500,
    cursor: "pointer",
    position: "relative",
    top: -1,
  },
  field: {
    fontSize: 13,
    height: 38,
    position: "relative",
    marginTop: -11,
    marginBottom: -9,
  },
  isArchivedCheckbox: {
    display: "flex",
    alignItems: "center",
    marginRight: "1rem",
  },
  isArchivedLabel: {
    cursor: "pointer",
    color: colors.grey500,
  },
};

export default class ClientIssuesetItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
      isEditable: false,
    };
  }

  render() {
    const {isHovered, isEditable} = this.state;
    const {
      issueSet,
      isNewClient,
      showIsArchived,
      onIsArchivedCheck,
    } = this.props;
    const {sample_issueset_id: sampleIssuesetId} = issueSet;
    const hasLink = !isNewClient && issueSet.id && sampleIssuesetId;

    if (!hasLink) {
      return <div>{issueSet.name}</div>;
    }

    return (
      <div
        style={styles.item}
        onMouseEnter={this.onItemEnter}
        onMouseLeave={this.onItemLeave}
      >
        {issueSet.master_name !== issueSet.name && (
          <div style={styles.masterName}>{issueSet.master_name}</div>
        )}
        <div style={styles.clientContainer}>
          <div style={styles.clientName}>{this.renderClientName()}</div>
          {isHovered &&
            !isEditable && (
              <EditIcon
                style={styles.editIcon}
                onClick={() => this.setState({isEditable: true})}
              />
            )}
          {showIsArchived && (
            <div style={styles.isArchivedCheckbox}>
              <Checkbox
                checked={issueSet.is_archived}
                onCheck={onIsArchivedCheck}
              />
              <div onClick={onIsArchivedCheck} style={styles.isArchivedLabel}>
                Is Archived
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderClientName() {
    const {issueSet} = this.props;
    const {isEditable} = this.state;

    if (!isEditable) {
      return (
        <RouterHoverLink to={this.buildUrl()} target="_blank">
          {issueSet.name}
        </RouterHoverLink>
      );
    }

    const name = issueSet.name || issueSet.master_name || "";

    return (
      <TextField
        name="clientName"
        autoFocus
        defaultValue={name}
        fullWidth={true}
        onBlur={this.handleBlur}
        style={styles.field}
      />
    );
  }

  handleBlur = event => {
    const {issueSet} = this.props;
    const name = event.target.value.replace(/^\s{1,}|\s{1,}$/g, "");
    if (name.length && name !== issueSet.name) {
      this.props.onUpdateClientName(name);
    }
    this.setState({isEditable: false});
  };

  onItemEnter = () => this.setState(() => ({isHovered: true}));
  onItemLeave = () => this.setState(() => ({isHovered: false}));

  buildUrl = () => {
    const {
      sample_issueset_id: sampleIssuesetId,
      sample_issue_id: sampleIssueId,
      sample_project_id: sampleProjectId,
      sample_document_id: sampleDocumentId,
    } = this.props.issueSet;
    const {organisationId} = this.props;
    let url = `/organisation/${organisationId}/issue`;
    if (sampleIssueId && sampleProjectId && sampleDocumentId) {
      url +=
        `/${sampleIssueId}?open_issue_list=true&project=` +
        `${sampleProjectId}&document=${sampleDocumentId}&issueset=` +
        `${sampleIssuesetId}`;
    } else {
      url += `/list?filtered_issueset=${sampleIssuesetId}`;
    }
    return url;
  };
}
