import _ from "underscore";
import React from "react";

import {SearchResult as SearchResultObj} from "../../types";
import renderAlgorithmName from "../../util/render_algorithm_name";
import EmbeddingTopic from "modules/searcher/types/embedding_topic";

interface ResultsProps {
  searchResults: SearchResultObj[];
  algorithms: string[];
  embeddingTopics: EmbeddingTopic[];
}

const rightBorder = {borderRight: "1px solid #888"};

export default function Results({
  searchResults,
  embeddingTopics,
  algorithms,
}: ResultsProps) {
  const resultsPerAlgorithm = Object.fromEntries(
    algorithms.map(algorithm => [
      algorithm,
      _.sortBy(searchResults, value => -getScore(value, algorithm)),
    ]),
  );

  return (
    <table style={{borderCollapse: "collapse"}}>
      <thead style={{position: "sticky", top: 0, background: "#fff"}}>
        <tr>
          <th style={rightBorder} />
          {algorithms.map(algorithm => (
            <th
              colSpan={4}
              key={algorithm}
              style={{textTransform: "capitalize", ...rightBorder}}
            >
              {renderAlgorithmName(algorithm, embeddingTopics)}
            </th>
          ))}
        </tr>
        <tr>
          <th style={rightBorder}>#</th>
          {algorithms.map(() => (
            <>
              <th>Doc</th>
              <th>Cl ref</th>
              <th>Cl text</th>
              <th style={rightBorder}>Score</th>
            </>
          ))}
        </tr>
      </thead>
      <tbody>
        {searchResults.map((_a, index) => (
          <SearchResult
            key={index}
            index={index}
            resultsPerAlgorithm={resultsPerAlgorithm}
            algorithms={algorithms}
          />
        ))}
      </tbody>
    </table>
  );
}

interface Props {
  resultsPerAlgorithm: Record<string, SearchResultObj[]>;
  algorithms: string[];
  index: number;
}

function SearchResult({resultsPerAlgorithm, index}: Props) {
  return (
    <tr
      style={{
        border: "1px solid #ccc",
        padding: "1em 6px",
        margin: "1em",
      }}
    >
      <td style={{...rightBorder, textAlign: "center"}}>{index + 1}</td>
      {Object.keys(resultsPerAlgorithm).map(algorithm => {
        const searchResult = resultsPerAlgorithm[algorithm][index];
        return (
          <React.Fragment key={algorithm}>
            <td>{searchResult.document_id}</td>
            <td
              style={{
                textAlign: "right",
                maxWidth: "4em",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              title={searchResult.reference}
            >
              {searchResult.reference}
            </td>
            <td style={{padding: "0.25em 6px"}} title={searchResult.text}>
              {searchResult.partial_text}
            </td>
            <td style={{...rightBorder, fontFamily: "monospace"}}>
              {renderScore(searchResult, algorithm)}
            </td>
          </React.Fragment>
        );
      })}
    </tr>
  );
}

function getScore(searchResult, algorithm) {
  const algoKey = algorithm.match(/~/)
    ? Object.keys(searchResult.scores).find(key => key.startsWith(algorithm))
    : algorithm;
  if (algoKey) {
    return searchResult.scores[algoKey];
  }
  return null;
}

function renderScore(searchResult: SearchResultObj, algorithm: string) {
  const score = getScore(searchResult, algorithm);
  return score ? score?.toFixed(2) : score;
}
