import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function fetchClientContractTypeSyncInfo(requestor) {
  return createReduxPromiseAction(
    ActionTypes.CLIENT_FETCH_ISSUESET_SYNC_INFO,
    (
      organisationId,
      clientId,
      issuesetMasterId,
      syncList,
      onSuccess,
      onError,
    ) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}/client/${clientId}` +
            `/issuesetMasterId/${issuesetMasterId}/syncList/${syncList}`,
          {withCredentials: true},
        )
        .then(response => onSuccess(response.data))
        .catch(err => {
          onError(err);
          return err.data;
        }),
  );
}
