import _ from "underscore";
import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Permissioner from "utils/permissioner";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";

import requestor from "requestor";

import CircularProgress from "@material-ui/core/CircularProgress";

import TopicAnalysisMatrixComponent from "../components/topic_analysis_matrix";

import updateTopicsAction from "modules/documents/actions/topics_update";
import createRegexClassifierRunBatchAction from "modules/documents/actions/classifier_run_batch_create_regex";
import createClassifierRunBatchAction from "modules/documents/actions/classifier_run_batch_create";
import topicsScoresFetchAction from "modules/documents/actions/topics_scores_fetch";
import lockTopicClassifierAction from "modules/documents/actions/topic_classifier_lock";
import updateTopicAction from "modules/documents/actions/topic_update";

import localStorage from "utils/local_storage";
import byId from "common/utils/by_id";

class TopicAnalysisMatrix extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRecent: false,
      sortValue: localStorage.getItem("analysisMatrixSortValue") || "uses",
      classifierVisibility:
        JSON.parse(localStorage.getItem("topicMatrixClassifierVisibility")) ||
        props?.classifiers || props.classifiers !== UNINITIALISED ? (
          _.object(
            ((props.classifiers && props.classifiers.map) || []).map(
              classifier => [classifier.name, true]
            )
          )) : []
    };
  }

  render() {
    if (!this.shouldRenderContainer()) {
      return (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return (
      this.permissioner.isAdmin() ||
      this.permissioner.hasPermission("list-topics")
    );
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.topics,
      this.props.classifiers,
      this.props.trainingModes,
      this.props.datasetModes,
      this.props.categories,
      this.props.tags,
      this.props.contractTypes,
      this.props.user,
    ]);
  }

  renderComponent() {
    return (
      <React.Fragment>
        {this.renderTopicAnalysisMatrix()}
        {this.props.children}
      </React.Fragment>
    );
  }

  toggleView = () => {
    const path = this.props.router.location.pathname;
    const newPath = path.substring(0, path.lastIndexOf("/"));
    this.props.router.push(`${newPath}/list`);
  };

  toggleRecent = () => {
    this.setState({showRecent: !this.state.showRecent});
  };

  sortValueChanged = (e, index, sortValue) => {
    localStorage.setItem("analysisMatrixSortValue", sortValue);
    this.setState({sortValue});
  };

  renderTopicAnalysisMatrix() {
    setTitle("Topics Analysis Matrix");
    return (
      <TopicAnalysisMatrixComponent
        topics={this.sortTopics(this.props.topics)}
        classifiers={this.props.classifiers}
        trainingModes={this.props.trainingModes}
        datasetModes={this.props.datasetModes}
        categories={this.props.categories}
        categoriesById={byId(this.props.categories)}
        tags={this.props.tags}
        tagsById={byId(this.props.tags)}
        organisationId={parseInt(this.props.params.organisationId, 10)}
        toggleView={this.toggleView}
        rerunRegexClassifier={this.rerunRegexClassifier}
        rerunClassifier={this.rerunClassifier}
        showRecent={this.state.showRecent}
        toggleRecent={this.toggleRecent}
        sortValue={this.state.sortValue}
        sortValueChanged={this.sortValueChanged}
        contractTypes={this.props.contractTypes}
        contractTypesById={byId(this.props.contractTypes)}
        fetchTopics={this.fetchTopics}
        lockClassifier={this.lockClassifier}
        classifierVisibility={this.state.classifierVisibility}
        setClassifierVisibility={this.setClassifierVisibility}
        onTopicUpdate={this.onTopicUpdate}
      />
    );
  }

  /* eslint-disable no-invalid-this */
  updateTopics = data => {
    const {organisationId} = this.props.params;
    this.props.updateTopicsAction(organisationId, data);
  };
  /* eslint-enable no-invalid-this */

  rerunRegexClassifier = (topicId = null, configurationId = null) => {
    const {organisationId} = this.props.params;
    this.props.createRegexClassifierRunBatchAction(
      organisationId,
      1,
      1,
      topicId,
      configurationId,
      [],
    );
  };

  rerunClassifier = (topicId, configurationId) => {
    const {organisationId} = this.props.params;
    return this.props.createClassifierRunBatchAction(
      organisationId,
      2,
      4,
      topicId,
      configurationId,
      null,
    );
  };
  sortTopics = topics => {
    const {sortValue} = this.state;
    const filteredClassifiers = this.props.classifiers.filter(
      classifier => this.state.classifierVisibility[classifier.name],
    );
    const firstClassifier = filteredClassifiers[0] || this.props.classifiers[0];
    return _.sortBy(topics, topic => {
      switch (sortValue) {
        case "id":
          return topic.id;
        case "name":
          return topic.name;
        case "category":
          return `${topic.topiccategory_id} ${topic.name}`;
        case "uses":
          return -topic.uses;
        case "unconfirmed_uses":
          return -topic.unconfirmed_uses;
        case "f1": {
          const stats = (topic.stats || []).find(
            stat => stat.classifier_id === firstClassifier.id,
          );
          if (stats) {
            const s = stats.stats[0];
            return f1(s.tp, s.fp, s.fn);
          }
          return 0;
        }
        case "weight": {
          const stats = (topic.stats || []).find(
            stat => stat.classifier_id === firstClassifier.id,
          );
          if (stats) {
            const s = stats.stats[0];
            const ff = f1(s.tp, s.fp, s.fn);
            return -(1 - ff) * topic.uses;
          }
          return topic.uses;
        }
        case "issue_count": {
          return -topic.referenced_issues.length;
        }
        default:
          return null;
      }
    });
  };

  fetchTopics = data => {
    const {organisationId} = this.props.params;
    return this.props.topicsScoresFetchAction(organisationId, data);
  };

  lockClassifier = async (
    topicId,
    classifierId,
    configurationId,
    lastEdited,
  ) => {
    const {organisationId} = this.props.params;
    await this.props.lockTopicClassifierAction(
      organisationId,
      topicId,
      classifierId,
      configurationId,
      lastEdited,
    );

    await this.props.createClassifierRunBatchAction(
      organisationId,
      3, // Training Mode: Live Classifier
      5, // Dataset Mode: Positives and all FPs
      topicId,
      configurationId,
      undefined,
      {prevent_state_update_client_response: true},
    );
  };

  setClassifierVisibility = isVisibleObject => {
    this.setState(prevState => {
      const classifierVisibility = {
        ...prevState.classifierVisibility,
        ...isVisibleObject,
      };
      localStorage.setItem(
        "topicMatrixClassifierVisibility",
        JSON.stringify(classifierVisibility),
      );
      return {classifierVisibility};
    });
  };

  onTopicUpdate = (topicId, data) => {
    const topic = this.props.topics.find(topic => topic.id === topicId);
    const {organisationId} = this.props.params;
    if (topic) {
      this.props.updateTopicAction(organisationId, topicId, {
        ...data,
        last_edited: topic.last_edited,
      });
    }
  };
}

function f1(tp, fp, fn) {
  const total = 2 * tp + fp + fn;
  return total ? 2 * tp / total : 0;
}

function select(state) {
  return {
    topics: state.topics,
    classifiers: state.classifiers,
    trainingModes: state.trainingModes,
    datasetModes: state.datasetModes,
    categories: state.topicCategories,
    tags: state.topicTags,
    user: state.user,
    contractTypes: state.contract_types,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateTopicsAction: updateTopicsAction(requestor),
        createRegexClassifierRunBatchAction: createRegexClassifierRunBatchAction(
          requestor,
        ),
        createClassifierRunBatchAction: createClassifierRunBatchAction(
          requestor,
        ),
        topicsScoresFetchAction: topicsScoresFetchAction(requestor),
        lockTopicClassifierAction: lockTopicClassifierAction(requestor),
        updateTopicAction: updateTopicAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(TopicAnalysisMatrix);
export const Component = TopicAnalysisMatrix;
