import Tooltip from "@material-ui/core/Tooltip";
import _ from "underscore";
import {cloneDeep} from "lodash";
import React, {useState, useEffect} from "react";
import capitalize from "utils/capitalize";
import {hasPermission} from "utils/permissioner";

import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {LOADING, MaybeLoading} from "common/types/util";
import CircularProgress from "@material-ui/core/CircularProgress";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import HelpIcon from "@material-ui/icons/Help";

import ProjectTabs from "./project_tabs";
import GeneralTab from "./tabs/general";
import ReportTab from "./tabs/report";
import RolesTab from "./tabs/roles";

import constructProjectUploadEmail from "./utils/construct_project_upload_email";
import constants from "./constants";
import Wrapper from "./wrapper";
import SaveButtons from "./save_buttons";
import HorizontalRow from "./horizontal_row";
import AccessTagSelector from "./AccessTagSelector";

const reportChangeKeys = ["report_settings"];
const templateChangeKeys = ["template_settings"];
const rolesChangedKeys = ["roles"];

function DeleteProjectDialog(props) {
  return (
    <Dialog onClose={props.hideDeleteDialog} open={true}>
      <DialogTitle>Confirm Project Deletion</DialogTitle>
      <DialogContentText style={{padding: "0 24px"}}>
        Are you sure you want to delete this Project?
      </DialogContentText>
      <DialogActions>
        <Button onClick={props.hideDeleteDialog}>Cancel</Button>
        <Button color="primary" onClick={props.onConfirm}>
          Delete Project
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function areReportChangesPresent(changedKeys) {
  return _.intersection(reportChangeKeys, changedKeys).length > 0;
}

function areTemplateChangesPresent(changedKeys) {
  return _.intersection(templateChangeKeys, changedKeys).length > 0;
}

function areRolesChangesPresent(changedKeys) {
  return _.intersection(rolesChangedKeys, changedKeys).length > 0;
}

function areGeneralChangesPresent(changedKeys, userDefaultProjectChanged) {
  if (userDefaultProjectChanged) {
    return true;
  }
  const reportAndTemplatesKeys = [
    ...reportChangeKeys,
    ...templateChangeKeys,
    ...rolesChangedKeys,
  ];
  const generalChanges = _.difference(changedKeys, reportAndTemplatesKeys);
  return generalChanges.length > 0;
}

export function getUserDefaultProjectId(user, organisation) {
  const {organisation_values} = user;
  const {id: organisationId} = organisation;
  return (
    organisation_values[organisationId] &&
    organisation_values[organisationId].default_project_id
  );
}

type Accesstag = {name: string; id: number};

type ProjectProps = {
  organisationAccesstags: MaybeLoading<Accesstag[]>;
  /** Optional callback to signal navigation to the "Access" project settings tab */
  onEnterAccessTab?: () => void;

  /** Optional callback to add an access tag to the current project */
  addAccesstagToProject?: (accesstag: Accesstag) => void;

  /**
   * Optional callback to create a new access tag (and add it to the current
   * project)
   */
  createAccesstag?: (accesstag: Omit<Accesstag, "id">) => void;

  /** Optional callback to remove an access tag from the current project */
  removeAccesstag?: (accesstag: {id: number}) => void;
};

function Project(props: ProjectProps) {
  const {project: propsProject, organisation, user} = props;
  const canViewEverything =
    user.is_admin || hasPermission("create-project-admin", user);
  const [stateProject, onStateProjectUpdate] = useState(
    cloneDeep(propsProject),
  );
  const [userDefaultProjectId, onUserDefaultProjectIdUpdate] = useState(
    getUserDefaultProjectId(user, organisation),
  );
  const [isDeleteDialogShown, onIsDeleteDialogShownUpdate] = useState(false);
  const [isRequestPending, updateIsRequestPending] = useState(false);
  const [errors, updateErrors] = useState({});

  function updateErrorsValue(key, value) {
    const newErrors =
      value === undefined ? _.omit(errors, key) : {...errors, [key]: value};
    updateErrors(newErrors);
  }

  const areErrorsPresent = Object.keys(errors).length > 0;

  useEffect(() => {
    onStateProjectUpdate(cloneDeep(propsProject));
    updateIsRequestPending(false);
  }, [propsProject]);

  useEffect(() => {
    onUserDefaultProjectIdUpdate(getUserDefaultProjectId(user, organisation));
    updateIsRequestPending(false);
  }, [user.organisation_values]);

  function onProjectKeyChange(key, value) {
    onStateProjectUpdate({
      ...stateProject,
      [key]: value,
    });
  }

  function hasProjectItemChanged(key) {
    return !_.isEqual(propsProject[key], stateProject[key]);
  }

  function createField(key, hintText, validator, errorText) {
    const onFieldChange = e => onProjectKeyChange(key, e.target.value);
    return (
      <TextField
        label={hintText || capitalize(key)}
        multiline={true}
        onChange={onFieldChange}
        value={stateProject[key] || ""}
        onBlur={validator}
        style={{
          marginTop: "4px",
          width: "100%",
          borderRadius: "2px",
          background: hasProjectItemChanged(key)
            ? constants.changedKeyColor
            : "white",
        }}
        error={Boolean(errorText)}
        helperText={errorText}
      />
    );
  }

  function createCheckbox(label, key, disabled, title) {
    const onCheck = () => onProjectKeyChange(key, !stateProject[key]);
    return (
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            onChange={onCheck}
            checked={stateProject[key]}
            disabled={disabled}
            size="small"
          />
        }
        label={
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "20em",
            }}
          >
            {label}
            {title && (
              <Tooltip title={title} placement="top-start" arrow>
                <HelpIcon style={{marginLeft: "1em", color: "#aaa"}} />
              </Tooltip>
            )}
          </span>
        }
        style={{
          marginTop: "4px",
          marginLeft: "0px",
          background: hasProjectItemChanged(key)
            ? constants.changedKeyColor
            : "white",
          borderRadius: "2px",
          display: "block",
        }}
      />
    );
  }

  function createSelectField(
    label,
    value,
    disabled,
    onChange,
    menuItems,
    hasChanged,
    parentStyles = {},
  ) {
    return (
      <FormControl
        style={{
          width: "40%",
          borderRadius: "2px",
          background: hasChanged ? constants.changedKeyColor : "white",
          ...parentStyles,
        }}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          value={value || ""}
          disabled={disabled}
          onChange={onChange}
          MenuProps={{PaperProps: {style: {maxHeight: "14rem"}}}}
        >
          {menuItems.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function renderProjectEmail() {
    const {organisation} = props;
    const projectEmail = organisation.upload_email
      ? constructProjectUploadEmail(
          organisation.upload_email,
          propsProject.name,
        )
      : null;
    if (projectEmail) {
      return (
        <div style={{textAlign: "center", fontSize: "1.1em"}}>
          <span style={{fontWeight: 600, marginRight: "6px"}}>
            Project Email:
          </span>
          {projectEmail}
        </div>
      );
    }
  }

  function onDefaultContractTypeUpdate(e) {
    const newId = e.target.value;
    onStateProjectUpdate({
      ...stateProject,
      default_contract_type: {
        ...stateProject.default_contract_type,
        id: newId,
      },
      issuesets: [],
    });
  }

  function renderDefaultContractTypeSelector() {
    const hasChanged =
      stateProject.default_contract_type.id !==
      propsProject.default_contract_type.id;
    return createSelectField(
      "Default contract type",
      stateProject.default_contract_type.id,
      false,
      onDefaultContractTypeUpdate,
      props.contractTypes,
      hasChanged,
    );
  }

  // TEMPLATE TAB FUNCTIONS START
  function validateTemplateSettings(e) {
    try {
      JSON.parse(e.target.value);
      updateErrorsValue("template_settings", undefined);
    } catch {
      updateErrorsValue("template_settings", "Invalid JSON structure");
    }
  }

  const {organisationAccesstags} = props;

  const renderAccessTab = () => {
    const permission = "view-accesstags";
    if (!hasPermission(permission, user) && !user.is_admin) {
      return null;
    }

    const {addAccesstagToProject, createAccesstag, removeAccesstag} = props;
    return (
      <div style={{width: "20rem", padding: "2rem"}}>
        {organisationAccesstags !== LOADING ? (
          <>
            <AccessTagSelector
              tags={{
                available: organisationAccesstags,
                selected: stateProject.accesstags,
              }}
              onAddTag={
                hasPermission("add-accesstag-to-project", user) || user.is_admin
                  ? addAccesstagToProject
                  : undefined
              }
              onCreateTag={
                hasPermission("create-accesstag-on-project", user) ||
                user.is_admin
                  ? createAccesstag
                  : undefined
              }
              onRemoveTag={
                hasPermission("remove-accesstag-on-project", user) ||
                user.is_admin
                  ? removeAccesstag
                  : undefined
              }
            />
          </>
        ) : (
          <CircularProgress style={{height: 24, width: 24}} />
        )}
      </div>
    );
  };

  function renderTemplateTab() {
    const {document_type: documentType} = stateProject;
    if (!documentType || documentType.id !== 5) {
      return null;
    }
    const fieldName = "template_settings";
    return (
      <div style={{padding: "2rem"}}>
        {createField(
          fieldName,
          "Template Settings",
          validateTemplateSettings,
          errors[fieldName],
        )}
      </div>
    );
  }
  // TEMPLATE TAB FUNCTIONS END

  // DELETE DIALOG FUNCTIONS START
  function onShowDeleteDialog() {
    if (!propsProject || propsProject.is_default) {
      return;
    }
    onIsDeleteDialogShownUpdate(true);
  }

  function onHideDeleteDialog() {
    onIsDeleteDialogShownUpdate(false);
  }

  function deleteProject() {
    onHideDeleteDialog();
    if (_.isFunction(props.onProjectDeleted)) {
      props.onProjectDeleted();
    }
  }
  // DELETE DIALOG FUNCTIONS END

  function getProjectChangedKeysData() {
    const defaultProjectId = getUserDefaultProjectId(user, organisation);
    const userDefaultProjectChanged = userDefaultProjectId !== defaultProjectId;

    // PROJECT FIELDS LOGIC
    const projectChangedKeys = Object.keys(stateProject).reduce(
      (accum, key) => {
        if (
          !_.isEqual(stateProject[key], propsProject[key]) &&
          key !== "document_type_id"
        ) {
          // we omit document_type_id because project reducer has 2 same document_type id items, i.e.
          // project = {
          //   ...,
          //   document_type_id: 3,
          //   document_type: {id: 3, name: "Contract"}   <---- we deal only with this object
          // }
          accum.push(key);
        }
        return accum;
      },
      [],
    );
    return {
      userDefaultProjectChanged,
      projectChangedKeys,
    };
  }

  function onCancelProjectChanges() {
    onStateProjectUpdate(cloneDeep(propsProject));
    onUserDefaultProjectIdUpdate(getUserDefaultProjectId(user, organisation));
  }

  async function onSaveProjectChanges() {
    const {
      userDefaultProjectChanged,
      projectChangedKeys,
    } = getProjectChangedKeysData();
    if (userDefaultProjectChanged) {
      updateIsRequestPending(true);
      await props.onIsDefaultChanged();
    }
    if (projectChangedKeys.length > 0) {
      const changesObject = projectChangedKeys.reduce((accum, key) => {
        switch (key) {
          case "default_contract_type":
            accum.default_contract_type_id = stateProject[key].id;
            break;
          case "document_type":
            accum.document_type_id = stateProject[key].id;
            break;
          default:
            accum[key] = stateProject[key];
            break;
        }
        return accum;
      }, {});
      if (!isRequestPending) {
        updateIsRequestPending(true);
      }
      if (_.isFunction(props.onProjectAdd)) {
        return await props.onProjectAdd({
          ...changesObject,
          name: stateProject.name,
        });
      }
      await props.onProjectUpdate(changesObject);
    }
  }

  const triggerArchiveProject = () =>
    props.onProjectUpdate({is_archived: !propsProject.is_archived});

  // RENDER
  const {
    userDefaultProjectChanged,
    projectChangedKeys,
  } = getProjectChangedKeysData();
  const haveReportChanges = areReportChangesPresent(projectChangedKeys);
  const haveGeneralChanges = areGeneralChangesPresent(
    projectChangedKeys,
    userDefaultProjectChanged,
  );
  const haveTemplateChanges = areTemplateChangesPresent(projectChangedKeys);
  const haveRolesChanges = areRolesChangesPresent(projectChangedKeys);

  const tabs = [
    {
      label: "General",
      haveChanges: haveGeneralChanges,
      tab: (
        <GeneralTab
          propsProject={propsProject}
          stateProject={stateProject}
          canViewEverything={canViewEverything}
          user={user}
          organisation={organisation}
          documentTypes={props.documentTypes}
          contractTypes={props.contractTypes}
          userDefaultProjectId={userDefaultProjectId}
          onStateProjectUpdate={onStateProjectUpdate}
          onProjectKeyChange={onProjectKeyChange}
          onUserDefaultProjectIdUpdate={onUserDefaultProjectIdUpdate}
          hasProjectItemChanged={hasProjectItemChanged}
          renderProjectEmail={renderProjectEmail}
          renderDefaultContractTypeSelector={renderDefaultContractTypeSelector}
          createCheckbox={createCheckbox}
          createField={createField}
          createSelectField={createSelectField}
        />
      ),
    },
    {
      label: "Report",
      haveChanges: haveReportChanges,
      tab: (
        <ReportTab
          propsProject={propsProject}
          stateProject={stateProject}
          onProjectKeyChange={onProjectKeyChange}
          hasProjectItemChanged={hasProjectItemChanged}
          createSelectField={createSelectField}
          contractTypes={props.contractTypes}
          issues={props.issues}
        />
      ),
    },
    {
      label: "Roles",
      haveChanges: haveRolesChanges,
      tab: (
        <RolesTab
          roles={props.roles}
          projectRoles={stateProject.roles}
          onProjectKeyChange={onProjectKeyChange}
        />
      ),
    },
    {
      label: "Template",
      haveChanges: haveTemplateChanges,
      tab: renderTemplateTab(),
    },
    {
      label: "Access",
      haveChanges: haveTemplateChanges,
      tab: renderAccessTab(),
      hideButtons: true,
      onEnter: () => props.onEnterAccessTab?.(),
    },
  ].filter(item => item.tab);

  const isProjectAdded = _.isFunction(props.onProjectAdd);
  return (
    <div style={{overflow: "auto", padding: "2rem", height: "100%"}}>
      {canViewEverything ? (
        <>
          <ProjectTabs
            isProjectAdded={isProjectAdded}
            isArchived={propsProject.is_archived}
            triggerArchiveProject={triggerArchiveProject}
            onShowDeleteDialog={onShowDeleteDialog}
            isDeleteDisabled={propsProject.is_default || props.lastProject}
            onCancelProjectChanges={onCancelProjectChanges}
            onSaveProjectChanges={onSaveProjectChanges}
            tabs={tabs}
            areErrorsPresent={areErrorsPresent}
            documentsCount={propsProject.documents_count}
          />
        </>
      ) : (
        <Wrapper>
          <Paper>
            <div style={{padding: "2rem"}}>
              {renderProjectEmail()}
              {createField("name", "Project Name")}
              {createField("description")}
              <HorizontalRow>
                {renderDefaultContractTypeSelector()}
              </HorizontalRow>
              <SaveButtons
                isProjectAdded={isProjectAdded}
                isArchived={propsProject.is_archived}
                onSaveProjectChanges={onSaveProjectChanges}
                onCancelProjectChanges={onCancelProjectChanges}
                areButtonsDisabled={!haveGeneralChanges}
                styles={{paddingTop: "2rem"}}
                onShowDeleteDialog={onShowDeleteDialog}
                isDeleteDisabled={propsProject.is_default || props.lastProject}
                documentsCount={propsProject.documents_count}
                triggerArchiveProject={triggerArchiveProject}
              />
            </div>
          </Paper>
        </Wrapper>
      )}
      {isDeleteDialogShown && (
        <DeleteProjectDialog
          hideDeleteDialog={onHideDeleteDialog}
          onConfirm={deleteProject}
        />
      )}
    </div>
  );
}

export default Project;
