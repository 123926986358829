import React, {useState, useEffect} from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import IconButton from "@material-ui/core/IconButton";

interface ExpandoProps {
  children: React.ReactElement[];
  beginExpanded?: boolean;
  // input: WorkflowInput;
  // onUpdateItem: (input: Record<string, string>) => void;
  // onDeleteItem: (workflowInputId: WorkflowInputId) => void;
}

const Expando: React.FC<ExpandoProps> = ({children, beginExpanded}) => {
  const [isExpanded, setIsExpanded] = useState(beginExpanded ?? false);
  useEffect(() => {
    setIsExpanded(beginExpanded ?? false);
  }, [beginExpanded]);
  return (
    <div style={{display: "flex", justifyContent: "space-between"}}>
      {isExpanded ? children[1] : children[0]}
      <div>
        <IconButton onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
    </div>
  );
};

export default Expando;
