import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {WorkflowInfo, WorkflowId} from "common/flowmaster/types/workflow";

import rateLimit, {getStandardTimeout} from "utils/rate_limit";
import {createChangeTimeWrapper} from "utils/ensure_fresh_update";

function doSave(requestor, organisationId, workflowId, data, changeTime) {
  return requestor
    .patch(
      `${basePath}/organisation/${organisationId}/workflow/${workflowId}`,
      data,
      {
        withCredentials: true,
      },
    )
    .then(response => ({
      ...createChangeTimeWrapper(changeTime),
      ...data,
      ...response.data,
    }));
}

const save = rateLimit(doSave, getStandardTimeout());

export type WorkflowUpdateAction = {
  type: WorkflowsActionTypes["WORKFLOW_UPDATE"]["SUCCESS"];
  payload: WorkflowInfo;
};

export default function updateWorkflow(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.WORKFLOW_UPDATE,
    (
      organisationId: OrganisationId,
      workflowId: WorkflowId,
      data: Record<string, unknown>,
      changeTime: Date,
    ) => {
      if (!data.last_edited) {
        throw new Error("data must contain a last_edited key");
      }
      return save(requestor, organisationId, workflowId, data, changeTime);
    },
    (organisationId, workflowId, data, changeTime) => ({
      ...createChangeTimeWrapper(changeTime),
      ...data,
    }),
  );

  return action;
}
