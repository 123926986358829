import styled from "styled-components";
import React, {useState} from "react";
import {IconButton, MenuItem, Select} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";

type valueType = string;

const Button = styled(IconButton)`
  color: #bbb;
  position: absolute;
  right: 0;
`;

interface MultiSelectFilterProps {
  id: string;
  title: string;
  value: valueType[];
  menuItems: Array<{value: valueType; label: string}>;
  setValue: (value: valueType[]) => void;
}

const buttonStyles = {
  padding: "0",
};

export default function MultiSelectFilter({
  id,
  title,
  value,
  menuItems,
  setValue,
}: MultiSelectFilterProps) {
  const [showFilter, setShowFilter] = useState(value.length > 0);
  const handleClose = () => {
    setValue([]);
    setShowFilter(false);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <span style={{padding: "0 1.5em"}}>{title}</span>
        {showFilter ? (
          <Button onClick={handleClose} style={buttonStyles}>
            <CloseIcon />
          </Button>
        ) : (
          <Button onClick={() => setShowFilter(true)} style={buttonStyles}>
            <FilterIcon />
          </Button>
        )}
      </div>
      {showFilter && (
        <div style={{display: "flex", width: "full"}}>
          <Select
            style={{flexGrow: 1, color: "#999"}}
            id={id}
            value={value}
            onChange={({target}) => setValue(target.value as valueType[])}
            multiple
          >
            {menuItems.map(({value, label}, key) => (
              <MenuItem key={key} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </div>
  );
}
