import React from "react";

import WhatshotIcon from "@material-ui/icons/Whatshot";
import StarRateIcon from "@material-ui/icons/StarRate";
import StarBorderIcon from "@material-ui/icons/StarBorder";

function HotAndStarInfoWidget(props) {
  const {
    isHot,
    isStar,
    updateIsStar,
    label = "Item",
    ignoreNonStarIcon = false,
  } = props;

  const StarIcon = isStar ? StarRateIcon : StarBorderIcon;
  const title = isStar ? `${label} is starred` : `Star current ${label}`;

  return (
    <div style={{marginLeft: 2, display: "flex", alignItems: "center"}}>
      <div
        title={title}
        onClick={updateIsStar}
        style={{
          ...(updateIsStar ? {cursor: "pointer"} : {}),
        }}
      >
        <StarIcon
          style={{
            height: 20,
            width: 20,
            color: "#fbc02d",
            marginTop: 4,
            visibility: !isStar && ignoreNonStarIcon ? "hidden" : "visible",
          }}
        />
      </div>
      {isHot && (
        <WhatshotIcon
          style={{
            height: 16,
            width: 16,
            color: "#ef5350",
            marginLeft: 2,
          }}
        />
      )}
    </div>
  );
}

export default HotAndStarInfoWidget;
