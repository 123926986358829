import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function addPrompt(requestor) {
  return createReduxPromiseAction(ActionTypes.PROMPT_ADD, organisationId =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/prompt`,
        {name: "New prompt", text: "", is_system: false},
        {withCredentials: true},
      )
      .then(response => response.data),
  );
}
