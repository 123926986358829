import * as TopicParameters from "plugins/topicparameters";

// +Unconfirmed = actual_values is null
// -+FN, missed = value exists in actual_values but values === [] or values === null
// -+TP, correct = value exists in both values and actual_values
// -+FP, false match = value exists in values but actual_values === []
// +Incorrect = value (values) !== value (actual_values) though both exist

function getUsageScore(parameter, parameterType, isDevModeOn) {
  const {actual_values: baseActualValues} = parameter;
  // eslint-disable-next-line import/namespace
  const parameterModule = TopicParameters[parameterType];
  const baseValues =
    (isDevModeOn ? parameter.dev_values : null) ??
    parameter.locked_values ??
    parameter.values;
  const result = {
    fp: 0,
    fn: 0,
    tp: 0,
    tn: 0,
    incorrect: 0,
    unconfirmed: 0,
    unset: 0,
  };

  if ((baseValues?.length ?? 0) > 0 && !baseActualValues) {
    result.unconfirmed += 1;
    return result;
  }
  if (baseValues?.length === 0 && (baseActualValues ?? []).length === 0) {
    result.tn += 1;
    return result;
  }
  if (!baseValues && !baseActualValues) {
    result.unset += 1;
    return result;
  }

  const values = !baseValues || baseValues.length === 0 ? [null] : baseValues;

  const actualValues = !baseActualValues
    ? [null]
    : baseActualValues.length === 0
      ? ["__empty_actual_values__"]
      : baseActualValues;

  // find tp matches
  const valueIndexesFound = {};
  const actualValuesWithMatchesRemoved = [...actualValues];
  values.forEach((value, valueIdx) => {
    for (
      let actualValueIdx = 0;
      actualValueIdx < actualValuesWithMatchesRemoved.length;
      actualValueIdx += 1
    ) {
      const actualValue = actualValuesWithMatchesRemoved[actualValueIdx];
      if (parameterModule.isEqual(actualValue, value)) {
        result.tp += 1;
        actualValuesWithMatchesRemoved.splice(actualValueIdx, 1);
        valueIndexesFound[valueIdx] = true;
        break;
      }
    }
  });
  const valuesWithMatchesRemoved = values.filter(
    (item, idx) => !valueIndexesFound[idx],
  );
  if (valuesWithMatchesRemoved.length === 0) {
    result.incorrect += actualValuesWithMatchesRemoved.length > 0 ? 1 : 0;
  } else if (actualValuesWithMatchesRemoved.length === 0) {
    result.incorrect += valuesWithMatchesRemoved.length > 0 ? 1 : 0;
  }
  // find errors
  valuesWithMatchesRemoved.forEach(value => {
    actualValuesWithMatchesRemoved.forEach(actualValue => {
      if (value && actualValue && actualValue !== "__empty_actual_values__") {
        const otherMatchingValue = values.find(value =>
          parameterModule.isEqual(value, actualValue),
        );
        if (
          (values.length > 1 && !otherMatchingValue) ||
          (values.length <= 1 &&
            !parameterModule.isEqual(value, actualValue)) ||
          (actualValues.length > 1 &&
            !actualValues.find(actualValue =>
              parameterModule.isEqual(value, actualValue),
            )) ||
          (actualValues.length <= 1 &&
            !parameterModule.isEqual(value, actualValue))
        ) {
          result.incorrect += 1;
        }
      } else if (value && actualValue === "__empty_actual_values__") {
        result.fp += 1;
      } else if (
        value === null &&
        actualValue &&
        actualValue !== "__empty_actual_values__"
      ) {
        result.fn += 1;
      } else {
        result.unset += 1;
      }
    });
  });

  // this is done to be consistent with sql call
  if (result.incorrect > 0) {
    result.incorrect = 1;
  }

  return result;
}

export default getUsageScore;
