import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function deleteTopicParameter(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPICPARAMETER_DELETE,
    (organisationId, topicId, topicParameterId, lastEdited) =>
      requestor["delete"](
        `${basePath}/organisation/${organisationId}/topic/${topicId}/` +
          `parameter/${topicParameterId}`,
        {withCredentials: true, data: {last_edited: lastEdited}},
      ).then(response => response.data),
  );
}
