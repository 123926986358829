import React from "react";
import ipAddress from "ip-address";
import _ from "underscore";

import Paper from "material-ui/Paper";
import {Toolbar, ToolbarGroup, ToolbarTitle} from "material-ui/Toolbar";
import TextField from "material-ui/TextField";

import Permissioner from "utils/permissioner";

const styles = {
  mainPaper: {
    width: "85%",
  },
};

class IpWhitelist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldText: props.validIps ? props.validIps.join(", ") : "",
      errorText: "",
    };
  }

  render() {
    if (!this.hasPermission("view-client-ips")) {
      return null;
    }

    return (
      <Paper style={styles.mainPaper}>
        <Toolbar className="app-toolbar">
          <ToolbarGroup key={0}>
            <ToolbarTitle text="IP Whitelist" />
          </ToolbarGroup>
        </Toolbar>
        <div style={{padding: "1rem"}}>
          <TextField
            name="ip-whitelist"
            value={this.state.fieldText}
            onChange={this.handleChange}
            style={{width: "100%"}}
            onBlur={this.onUpdate}
            errorText={this.state.errorText}
            onFocus={this.onTextFieldFocus}
            spellCheck="false"
          />
        </div>
      </Paper>
    );
  }

  onUpdate = () => {
    const areAllValid = validate(this.state.fieldText);
    if (!areAllValid) {
      this.setState(() => ({errorText: "Invalid address provided"}));
    } else {
      if (!this.hasPermission("edit-client-ips")) {
        const baseValidIps = this.props.validIps;
        this.setState(() => ({
          fieldText: baseValidIps ? baseValidIps.join(", ") : "",
          errorText: "No Permission To Edit",
        }));
      } else {
        const ipsArray = _.chain((this.state.fieldText || "").split(","))
          .map(item => item.trim())
          .filter(item => item)
          .uniq()
          .value();
        this.setState(
          () => ({fieldText: ipsArray.join(", ")}),
          () => this.props.onIpWhitelistUpdate(ipsArray),
        );
      }
    }
  };

  onTextFieldFocus = e => {
    const t = e.target.value;
    e.target.value = "";
    e.target.value = t;
    this.setState(() => ({errorText: ""}));
  };

  handleChange = event => {
    this.setState({
      fieldText: event.target.value,
    });
  };

  setErrorText = errorText => this.setState(() => ({errorText}));

  hasPermission = permission => {
    return new Permissioner(this.props.user).hasPermission(permission);
  };
}

function validate(str) {
  if (str.trim().length === 0) {
    return true;
  }
  let areAllValid = true;
  str.split(",").forEach(entry => {
    const v4valid = new ipAddress.Address4(entry.trim()).isValid();
    const v6valid = new ipAddress.Address6(entry.trim()).isValid();
    areAllValid = v4valid || v6valid;
  });
  return areAllValid;
}

export default IpWhitelist;
