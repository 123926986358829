import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function moduleTopicsFetch(requestor) {
  return createReduxPromiseAction(
    ActionTypes.MODULE_TOPICS_FETCH,
    (organisationId, moduleId) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}/template_module/${moduleId}/topics`,
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        }),
  );
}
