import React from "react";
import _ from "underscore";

import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";

import {makeStyles} from "@material-ui/core/styles";
import * as colors from "material-ui/styles/colors";

const styles = {
  cell: {
    padding: "0 8px",
  },
  tableWrapper: {
    borderCollapse: "collapse",
    marginTop: "15px",
    fontSize: "14px",
    color: colors.grey800,
  },
  noClassifiersBlock: {
    fontSize: 24,
    textAlign: "center",
    fontWeight: 600,
    margin: "1em",
  },
  statsBlock: {
    cursor: "pointer",
    borderRight: "1px solid lightgray",
    fontStyle: "italic",
    color: "#666",
  },
  realtimeBlock: {
    cursor: "pointer",
    textAlign: "center",
    borderRight: "1px solid lightgray",
    padding: "0 0.5rem",
  },
  buttonBlock: {
    display: "flex",
    alignItems: "center",
  },
};

const useCheckboxStyles = makeStyles({
  root: {
    padding: "0",
  },
});

function IsInUseCheckbox(props) {
  const {configuration, onTopicClassifierUpdated} = props;
  const {is_in_use: isInUse = null} = configuration;
  function onIsInUseChange(configuration) {
    return () => {
      onTopicClassifierUpdated(configuration, {
        is_in_use: !configuration.is_in_use,
      });
    };
  }
  const checkboxClasses = useCheckboxStyles();
  return (
    <Checkbox
      color="primary"
      className={checkboxClasses.root}
      checked={isInUse === null ? false : isInUse}
      disabled={isInUse === null}
      onChange={onIsInUseChange(configuration)}
    />
  );
}

function DevModeCheckbox(props) {
  const {configuration, onTopicClassifierUpdated} = props;
  const {dev_mode: devMode} = configuration;
  function onIsInUseChange(configuration) {
    return () => {
      onTopicClassifierUpdated(configuration, {
        dev_mode: !configuration.dev_mode,
      });
    };
  }
  const checkboxClasses = useCheckboxStyles();
  return (
    <Checkbox
      color="primary"
      className={checkboxClasses.root}
      checked={devMode === null ? false : devMode}
      onChange={onIsInUseChange(configuration)}
    />
  );
}

export default class ScoresSummary extends React.Component {
  render() {
    const {classifiers} = this.props;
    this.updateStatsTimer();

    const hasInUseClassifier = Boolean(
      classifiers.find(classifier => classifier.is_in_use),
    );

    const classifierTotalStats = this.props.topic.classifier_stats;
    return (
      <div
        style={{
          ...(!hasInUseClassifier ? {backgroundColor: "#ffebee"} : {}),
        }}
      >
        <table style={styles.tableWrapper}>
          {this.renderClassifierHeaders()}
          {this.renderClassifierStats(classifierTotalStats)}
        </table>
        {!hasInUseClassifier && (
          <div style={styles.noClassifiersBlock}>
            No classifiers are currently in use. Turn one on for this classifier
            to work.
          </div>
        )}
      </div>
    );
  }

  renderClassifierHeaders() {
    return (
      <thead style={{color: colors.grey900}}>
        <tr style={{borderBottom: "1px solid lightgray"}}>
          <th
            style={{
              borderRight: "1px solid lightgray",
              textDecoration: "underline",
              fontSize: "16px",
              ...(this.props.groupUsagesByHeader
                ? {backgroundColor: "#f88"}
                : {}),
            }}
            colSpan={2}
            title={
              this.props.groupUsagesByHeader
                ? "Group usages by header is on, realtime stats may not match stored results."
                : ""
            }
          >
            Summary
          </th>
          <th>F1</th>
          <th>TP</th>
          <th>FP</th>
          <th>Pnc</th>
          <th>FN</th>
          <th>TN</th>
          <th>Nnc</th>
          {/* <th>TBC</th> */}
          <th style={{width: "2rem"}} />
          <th style={{...styles.cell, borderLeft: "1px solid grey"}}>
            In use?
          </th>
          <th style={{...styles.cell}}>Dev mode</th>
          <th>Last Generated</th>
          <th>Duration</th>
          <th style={{width: "4rem"}}>Clauses Used</th>
        </tr>
      </thead>
    );
  }
  renderClassifierStats(stats) {
    const {selectedContractTypeIds, topic, isRequestPending} = this.props;
    return (
      <tbody style={{textAlign: "right"}} key="classifier_stats">
        {[
          this.renderRealtimeStats(
            isRequestPending,
            topic,
            selectedContractTypeIds,
          ),
          ..._.flatten(
            this.props.classifiers
              .filter(
                configuration =>
                  configuration.configuration_name &&
                  configuration.current_topic_trainingnode_id,
              )
              .map(configuration => {
                const totalConfigStats = stats[configuration.configuration_id];
                const configStats = (
                  this.props.topic?.classifier_job_summary ?? []
                ).find(
                  item =>
                    item.configuration_id === configuration.configuration_id,
                );
                const nodes = [];
                if (totalConfigStats) {
                  nodes.push(
                    this.renderTotalStats(
                      configuration,
                      configStats,
                      isRequestPending,
                      totalConfigStats,
                    ),
                  );
                }
                nodes.push(
                  this.renderLocalStats(
                    configuration,
                    isRequestPending,
                    totalConfigStats,
                    selectedContractTypeIds,
                  ),
                );
                return nodes;
              }),
          ),
        ]}
      </tbody>
    );
  }

  renderTotalStats(configuration, configStats, isRequestPending, scores) {
    const f1 = (
      ((2 * scores.tp) / (2 * scores.tp + scores.fp + scores.fn)) *
      100
    ).toFixed(2);
    const result = !f1 || isNaN(f1) ? "n/a" : `${f1}%`;
    const key = `${configuration.configuration_id || configuration.id}_total`;
    return (
      <tr style={{color: "#333"}} key={key}>
        <td
          style={{
            padding: "0 0.5rem",
          }}
          rowSpan={2}
        >
          {`${configuration.name} ${configuration.configuration_name}`}
        </td>
        <td
          title="Stats for all stored results for this classifier"
          style={styles.statsBlock}
        >
          Total stats
        </td>
        <td style={{...styles.cell, textAlign: "center"}}>
          {isRequestPending ? "-" : result}
        </td>
        <td style={styles.cell}>{isRequestPending ? "-" : scores.tp}</td>
        <td style={styles.cell}>{isRequestPending ? "-" : scores.fp}</td>
        <td style={styles.cell}>{isRequestPending ? "-" : scores.pnc}</td>
        <td style={styles.cell}>{isRequestPending ? "-" : scores.fn}</td>
        <td style={{...styles.cell, textAlign: "center"}}>-</td>
        <td style={styles.cell}>{isRequestPending ? "-" : scores.nnc}</td>
        <td />
        {this.renderExtraConfigCells(configuration, configStats)}
      </tr>
    );
  }

  renderLocalStats(
    configuration,
    isRequestPending,
    hasTotalConfigStats,
    selectedContractTypeIds,
  ) {
    const scores = this.getScoresOfSelectedContractTypes(
      configuration.scoresByContractTypeId,
      selectedContractTypeIds,
      {tp: 0, fn: 0, fp: 0, tn: 0, pnc: 0, nnc: 0, tbc: 0},
    );
    const f1 = (
      ((2 * scores.tp) / (2 * scores.tp + scores.fp + scores.fn)) *
      100
    ).toFixed(2);
    const noScore = !f1 || isNaN(f1);
    const result = noScore ? "n/a" : `${f1}%`;
    const statsColor = hasTotalConfigStats || noScore ? "#aaa" : "inherit";
    const key = `${configuration.configuration_id || configuration.id}_local`;
    return (
      <tr key={key}>
        {!hasTotalConfigStats && (
          <td
            style={{
              padding: "0 0.5rem",
            }}
          >
            {`${configuration.name} ${configuration.configuration_name}`}
          </td>
        )}
        <td
          title="Stats for just the downloaded results for this classifier"
          style={styles.statsBlock}
        >
          Local stats
        </td>
        <td style={{...styles.cell, textAlign: "center", color: statsColor}}>
          {isRequestPending ? "-" : result}
        </td>
        <td style={{...styles.cell, color: statsColor}}>
          {isRequestPending ? "-" : scores.tp}
        </td>
        <td style={{...styles.cell, color: statsColor}}>
          {isRequestPending ? "-" : scores.fp}
        </td>
        <td style={{...styles.cell, color: statsColor}}>
          {isRequestPending ? "-" : scores.pnc}
        </td>
        <td style={{...styles.cell, color: statsColor}}>
          {isRequestPending ? "-" : scores.fn}
        </td>
        <td style={{...styles.cell, textAlign: "center", color: statsColor}}>
          -
        </td>
        <td style={{...styles.cell, color: statsColor}}>
          {isRequestPending ? "-" : scores.nnc}
        </td>
        {/* <td style={styles.cell}>{scores.tbc}</td> */}
        <td />
        {this.renderExtraConfigCells(configuration, null, !hasTotalConfigStats)}
      </tr>
    );
  }

  renderExtraConfigCells(configuration, configStats, shouldRender = true) {
    const progress = configStats
      ? configStats.docs_finished / configStats.document_count
      : null;
    const duration = configStats
      ? progress === 1
        ? `${Math.round(configStats.duration / 1000)} secs`
        : `${Math.round(progress * 100)}%`
      : null;
    const batchTime = configStats?.creation_date ?? null;
    return (
      <>
        <td
          style={{
            ...styles.cell,
            borderLeft: "1px solid grey",
            textAlign: "center",
          }}
        >
          {shouldRender && (
            <IsInUseCheckbox
              configuration={configuration}
              onTopicClassifierUpdated={this.props.onTopicClassifierUpdated}
            />
          )}
        </td>
        <td
          style={{
            ...styles.cell,
            textAlign: "center",
          }}
        >
          {shouldRender && (
            <DevModeCheckbox
              configuration={configuration}
              onTopicClassifierUpdated={this.props.onTopicClassifierUpdated}
            />
          )}
        </td>
        {shouldRender && (
          <>
            <td style={styles.cell}>{configuration.creation_date}</td>
            <td title={batchTime}>{duration}</td>
            <td style={{...styles.cell, textAlign: "center"}}>
              {configuration.clauses_used}
            </td>
          </>
        )}
      </>
    );
  }

  renderRealtimeStats(isRequestPending, topic, selectedContractTypeIds) {
    const realtimeScores = this.getScoresOfSelectedContractTypes(
      this.props.realtimeScoresByContractType,
      selectedContractTypeIds,
      {ctp: 0, cfn: 0, cfp: 0, ctn: 0, pnc: 0, fnc: 0},
    );
    const realtimeF1 = (
      ((2 * realtimeScores.ctp) /
        (2 * realtimeScores.ctp + realtimeScores.cfp + realtimeScores.cfn)) *
      100
    ).toFixed(2);
    const realtimeF1Result =
      !realtimeF1 || isNaN(realtimeF1) ? "n/a" : `${realtimeF1}%`;

    return (
      <tr key="crt">
        <td
          title="Regex Stats for all recent changes. Click Refresh to update stored stats."
          colSpan={2}
          style={styles.realtimeBlock}
        >
          Realtime
        </td>
        <td style={styles.cell}>{isRequestPending ? "-" : realtimeF1Result}</td>
        <td style={styles.cell}>
          {isRequestPending ? "-" : realtimeScores.ctp}
        </td>
        <td style={styles.cell}>
          {isRequestPending ? "-" : realtimeScores.cfp}
        </td>
        <td style={styles.cell}>
          {isRequestPending ? "-" : realtimeScores.pnc}
        </td>
        <td style={styles.cell}>
          {isRequestPending ? "-" : realtimeScores.cfn}
        </td>
        <td style={styles.cell}>
          {isRequestPending ? "-" : realtimeScores.ctn}
        </td>
        <td style={styles.cell}>
          {isRequestPending ? "-" : realtimeScores.fnc}
        </td>
        <td />
        <td style={{...styles.cell, borderLeft: "1px solid grey"}} />
        <td style={styles.cell}>{topic.last_fp_update_date}</td>
        <td style={styles.cell}>
          {topic.last_fp_duration && topic.last_fp_duration > 0 ? (
            topic.last_fp_duration / 1000 > 150 ? (
              `${Math.round(topic.last_fp_duration / 60 / 1000)} mins`
            ) : (
              `${Math.ceil(topic.last_fp_duration / 1000)} secs`
            )
          ) : topic.last_fp_update_date && topic.last_fp_duration === null ? (
            <span style={styles.buttonBlock}>
              In progress (
              {topic.last_fp_progress === null
                ? "..."
                : `${Math.round(topic.last_fp_progress * 100)}%`}
              )
              <IconButton
                aria-label="Update progress"
                onClick={() => this.refreshTopicStats()}
                disabled={topic.last_fp_progress === null}
              >
                <RefreshIcon />
              </IconButton>
            </span>
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  }

  updateStatsTimer() {
    const {topic} = this.props;
    if (
      !this.statsTimer &&
      ((topic.last_fp_duration === null && topic.last_fp_progress !== null) ||
        this.jobInProgress(topic.classifier_job_summary))
    ) {
      this.statsTimer = setTimeout(() => this.refreshTopicStats(), 5000);
    } else if (this.refreshingStats && topic.last_fp_duration) {
      setTimeout(() => {
        this.refreshingStats = false;
        this.refreshTopic();
      }, 10);
    }
  }

  jobInProgress(classifierJobs) {
    return (classifierJobs || []).find(
      item =>
        item.document_count > item.docs_finished &&
        new Date(item.creation_date).valueOf() >
          new Date().valueOf() - 60 * 60 * 1000,
    );
  }

  refreshTopicStats() {
    this.clearStatsTimer();
    this.refreshingStats = true;
    this.props.refetchTopicStats();
  }

  async refreshTopic() {
    this.clearStatsTimer();
    await this.props.refetchTopic();
  }

  clearStatsTimer() {
    if (this.statsTimer) {
      clearTimeout(this.statsTimer);
    }
    this.statsTimer = null;
  }

  getScoresOfSelectedContractTypes(
    scoresByCtId,
    selectedContractTypeIds,
    base,
  ) {
    return selectedContractTypeIds.reduce((result, ctId) => {
      const scores = scoresByCtId[ctId];
      Object.keys(result).forEach(resultScore => {
        result[resultScore] += scores[resultScore];
      });
      return result;
    }, base);
  }
}
