import requestor from "requestor";
import BaseRoute from "utils/base_route";

import TopicParameters from "./containers/topic_parameters";

import topicParametersFetchAction from "modules/documents/actions/topic_parameters_fetch";
import topicParametersFetchClear from "modules/documents/actions/topic_parameters_clear";

export default class TopicParametersRoute extends BaseRoute {
  constructor(store) {
    super("parameters", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, TopicParameters);
  }

  fetchActiveData(state) {
    this.fetchTopics(state);
  }

  fetchTopics(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(topicParametersFetchAction(requestor)(organisationId));
  }

  clearActiveData(state) {
    const {organisationId} = state.params;
    this.store.dispatch(topicParametersFetchClear(organisationId));
  }
}
