import {deserialiseAlgorithm} from "./serialise_algorithm";

export default function renderAlgorithmName(
  algorithmString: string,
  embeddingTopics,
): string {
  const algorithm = deserialiseAlgorithm(algorithmString);
  if (!algorithm.options || Object.keys(algorithm.options).length === 0) {
    return algorithm.type;
  }
  const topicId = parseInt(algorithm.options.topic_id as string, 10);
  const embeddingTopicName = embeddingTopics.find(topic => topic.id === topicId)
    ?.name;
  return `${algorithm.type} - ${embeddingTopicName}`;
}
