import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import getRouteIds from "utils/get_route_ids";

export function transition(prevState, nextState, action) {
  if (action.type === ActionTypes.PROJECT_DELETE.SUCCESS) {
    const {organisationId} = getRouteIds(nextState.router);
    return {
      pathname: `/organisation/${organisationId}/project/list`,
    };
  }
  return null;
}

export default function deleteProject(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PROJECT_DELETE,
    (organisationId, projectId, lastEdited) =>
      requestor["delete"](
        `${basePath}/organisation/${organisationId}/project/${projectId}`,
        {
          data: {last_edited: lastEdited},
          withCredentials: true,
        },
      ).then((/* response */) => ({id: parseInt(projectId, 10)})),
    null,
    {transition},
  );
}
