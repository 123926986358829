import {LlmRunFilters} from "common/flowmaster/types/llm_run";

const FILTER_KEYS = ["reasons", "status"] as const;
type FilterKey = typeof FILTER_KEYS[number];

const DATE_KEYS = ["from", "to"] as const;
type DateKey = typeof DATE_KEYS[number];

export interface LlmRunQueryParams extends LlmRunFilters {
  reasons: string[];
  status: string[];
  from: string;
  to: string;
}

export function getLlmRunQueryParams(
  queryParams: LlmRunFilters,
): LlmRunQueryParams {
  return {
    ...queryParams,
    ...addLists(queryParams, FILTER_KEYS),
    ...addDates(queryParams, DATE_KEYS),
  } as LlmRunQueryParams;
}

function addLists(
  queryParams: LlmRunFilters,
  keys: readonly FilterKey[],
): Record<FilterKey, string[]> {
  return Object.fromEntries(
    keys.map(key => [key, addList(queryParams, key)]),
  ) as Record<FilterKey, string[]>;
}
function addDates(
  queryParams: LlmRunFilters,
  keys: readonly DateKey[],
): Partial<Record<DateKey, string>> {
  return Object.fromEntries(
    keys
      .map(key => [key, queryParams[key]])
      .filter(([, value]) => value !== undefined),
  ) as Partial<Record<DateKey, string>>;
}
function addList(queryParams: LlmRunFilters, key: FilterKey): string[] {
  const item = queryParams[key];
  return item ? (Array.isArray(item) ? (item as string[]) : [item]) : [];
}
