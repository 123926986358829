export default function(str) {
  return str
    .split(" ")
    .map(substr => {
      if (substr.toLowerCase() === "responsible") {
        return "Rsp";
      }
      if (substr.toLowerCase() === "party") {
        return "";
      }
      return substr.substring(0, 1).toUpperCase();
    })
    .join("");
}
