import _ from "lodash";
import React from "react";
import Select from "react-select";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import IconButton from "material-ui/IconButton";
import ClearIcon from "material-ui/svg-icons/content/clear";
import {Checkbox as ChecboxV4, Switch} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "material-ui/Paper";

import TriggeringLogic from "./triggering_logic";
import ReactSelectLabel from "common_components/react_select_label";
import issueClasses from "modules/documents/constants/issue_classes";
import AddIssuesetDialog from "./components/add_issueset_dialog";

import filterTopicsByContractTypes from "./utils/filter_topics_by_contract_types";
import byId from "common/utils/by_id";
import issuesetUtils from "common/utils/issues/issueset_utils";
import OverridableIssueFields from "utils/overridable_issue_fields";
import {UpdateIssueData} from "modules/documents/types";
import {Issueset} from "common/types/issueset";
import ConfirmDialog from "common_components/confirm_dialog";
import WorkflowEditor from "./components/workflow_editor";
import {Issue} from "common/types/issue";

const formatOverride = field => {
  const underscoresReplaced = field.replaceAll("_", " ");
  return underscoresReplaced[0].toUpperCase() + underscoresReplaced.slice(1);
};

const removeIssueGroupPrefix = (issueFullName: string) =>
  issueFullName.split(" / ").slice(1).join(" / ");

type Props = {
  onIssueUpdate: (data: Omit<UpdateIssueData, "id" | "last_edited">) => void;
  onIssuesUpdate: (
    data: Array<Omit<UpdateIssueData, "id" | "last_edited">>,
  ) => void;
} & {
  [key: string]: unknown;
};
type State = {
  confirmIssuesetRemovalDialogState:
    | undefined
    | {issuesetsAfterRemoval: Issueset[]};
} & {
  [key: string]: unknown;
};

const styles: Record<string, React.CSSProperties> = {
  cardHeader: {
    paddingLeft: "2rem",
    fontSize: "17px",
    fontWeight: 500,
    justifyContent: "normal",
  },
  warningContainer: {
    margin: "0 2rem 1rem",
    backgroundColor: "red",
    color: "white",
    padding: "10px",
    fontWeight: "bold",
  },
};

class IssueEditor extends OverridableIssueFields<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      problems: {},
      selectedIssueset: props.selectedIssueset,
      addIssuesetsDialogState: undefined,
      confirmIssuesetRemovalDialogState: undefined,
    };
    if (this.useState()) {
      this.state.issue = {
        trigger_type: "Classic",
        ...this.props.initialIssue,
      };
    }
    this.state.overridableFields = this.getOverridableFields();
  }

  useState() {
    return this.props.initialIssue || !this.props.issue;
  }

  getOverridableFields = () => {
    const issue = this.getOverridableIssue();
    return {
      main: {
        issue: _.omit(issue, ["usage"]),
        display_name: issue.display_name,
        issue_order: issue.issue_order,
        issue_class_id: issue.issue_class_id,
        rag_score: issue.rag_score,
        show_in_clause_buttons: issue.show_in_clause_buttons,
        styling: issue.styling,
        show_parties: issue.show_parties,
        report_only: issue.report_only,
        llm_settings: issue.llm_settings,
      },
      overridden: issue.override_values,
    };
  };

  getOverridableIssue() {
    return this.useState() ? this.state.issue : this.props.issue;
  }

  onOverridableIssueFieldUpdate(newData) {
    if (!this.useState()) {
      this.props.onIssueUpdate(newData);
    } else {
      this.setState({issue: {...this.state.issue, ...newData}});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.useState() && this.getUsedInputs().triggering_logic) {
      const currentIssuesets = issuesetUtils.getIssuesetIdsPresentInContractTypes(
        this.state.issue.contract_types,
      );
      const prevIssuesets = issuesetUtils.getIssuesetIdsPresentInContractTypes(
        prevState.issue.contract_types,
      );
      if (
        currentIssuesets.length !== prevIssuesets.length &&
        currentIssuesets.length !== 0
      ) {
        this.setState(({problems}) => ({
          problems: _.omit(problems, "issuesetError"),
        }));
      }
    }

    const {issue: prevIssue} = prevProps;
    const {issue: currentIssue} = this.props;
    const isIssueSwitched =
      prevIssue &&
      currentIssue &&
      prevIssue.id &&
      currentIssue.id &&
      prevIssue.id !== currentIssue.id;
    const isSelectedIssuesetSwitched =
      prevProps.selectedIssueset !== this.props.selectedIssueset;

    if (
      isIssueSwitched ||
      isSelectedIssuesetSwitched ||
      this.state.updateFields
    ) {
      if (isSelectedIssuesetSwitched) {
        this.state.selectedIssueset = this.props.selectedIssueset;
      }
      this.setState(() => ({
        problems: {},
        overridableFields: this.getOverridableFields(),
        updateFields: null,
      }));
    }
  }

  render() {
    const issue = this.getOverridableIssue();
    const usedInputs = this.getUsedInputs();
    const {problems} = this.state;
    const topics = filterTopicsByContractTypes(
      issue.contract_types,
      this.props.topics,
    ).map(topic => ({
      ...topic,
      name: `${topic.name} — (id: ${topic.id})`,
      parameters: topic.parameters.map(parameter => ({
        ...parameter,
        name: `${parameter.name} — (id: ${parameter.id})`,
      })),
    }));
    const issueFieldData = this.getOverridableFieldData("CustomField", "issue");

    const updateLlmSettings = (newSettings: Partial<Issue["llm_settings"]>) => {
      const updatedSettings = {
        ...(issue as Issue).llm_settings,
        ...newSettings,
      };
      // trigger update with merged settings
      this.onOverridableIssueFieldUpdate({
        llm_settings: updatedSettings,
      });
    };

    const selectedIssueset = this.props.currentIssueset
      ? {
          ...this.props.currentIssueset,
          contract_type_name: this.props.contractTypesById[
            this.props.currentIssueset.contract_type_id
          ].name,
          contract_type_last_edited: this.props.contractTypesById[
            this.props.currentIssueset.contract_type_id
          ].last_edited,
        }
      : undefined;

    return (
      <div>
        {this.renderDisplayName()}
        {this.renderOverridenIssuesets("display_name")}
        {usedInputs.contract_type && !this.hasSelectedDuplicateIssueset() && (
          <div style={{margin: "0.5rem 2rem 2rem 2rem"}}>
            <ReactSelectLabel>Issuesets</ReactSelectLabel>
            <SelectMemo
              value={this.getIssuesetValues()}
              options={this.getIssuesetOptions()}
              onChange={this.onIssuesetUpdate}
              onValueClick={this.onIssuesetClicked}
              disabled={this.props.disabled}
            />

            {this.state.confirmIssuesetRemovalDialogState !== undefined && (
              <ConfirmDialog
                open
                title="Confirm issueset removal"
                description="Are you sure you want to remove the issueset?"
                cancelButtonCaption="Cancel"
                onClose={() =>
                  this.setState({confirmIssuesetRemovalDialogState: undefined})
                }
                okButtonCaption="Remove"
                onSuccess={() => {
                  if (!this.state.confirmIssuesetRemovalDialogState) {
                    return;
                  }
                  this.onIssuesetUpdate(
                    this.state.confirmIssuesetRemovalDialogState
                      .issuesetsAfterRemoval,
                    true,
                  );
                  this.setState({confirmIssuesetRemovalDialogState: undefined});
                }}
              />
            )}

            {this.state.addIssuesetsDialogState !== undefined && (
              <AddIssuesetDialog
                selectedIssueset={
                  selectedIssueset
                    ? {
                        name: issuesetUtils.constructIssuesetName(
                          selectedIssueset,
                          false,
                        ),
                      }
                    : undefined
                }
                newIssueset={{
                  name: issuesetUtils.constructIssuesetName(
                    this.state.addIssuesetsDialogState.newIssueset,
                    false,
                  ),
                }}
                relatedIssues={this.state.addIssuesetsDialogState.relatedIssues}
                selectedIssue={{
                  name: removeIssueGroupPrefix(issue.name),
                  overrides: _.uniq(
                    [
                      issue.override_values?.[selectedIssueset.master_id]
                        ?.master,
                      issue.override_values?.[selectedIssueset.master_id]?.[
                        selectedIssueset.remote_client_id
                      ],
                    ]
                      .filter(override => override)
                      .map(Object.keys)
                      .flat(),
                  ).map(formatOverride),
                }}
                onClickSelectAllRelatedIssues={() =>
                  this.setState(prev =>
                    prev.addIssuesetsDialogState.relatedIssues !== undefined
                      ? {
                          addIssuesetsDialogState: {
                            ...prev.addIssuesetsDialogState,
                            relatedIssues: prev.addIssuesetsDialogState.relatedIssues.map(
                              relatedIssue => ({
                                ...relatedIssue,
                                selected: !prev.addIssuesetsDialogState.relatedIssues.every(
                                  relatedIssue => relatedIssue.selected,
                                ),
                              }),
                            ),
                          },
                        }
                      : prev,
                  )
                }
                onClickSelectRelatedIssue={issue =>
                  this.setState(prev =>
                    prev.addIssuesetsDialogState.relatedIssues !== undefined
                      ? {
                          addIssuesetsDialogState: {
                            ...prev.addIssuesetsDialogState,
                            relatedIssues: prev.addIssuesetsDialogState.relatedIssues.map(
                              relatedIssue =>
                                relatedIssue.name === issue.name
                                  ? {
                                      ...relatedIssue,
                                      selected: !relatedIssue.selected,
                                    }
                                  : relatedIssue,
                            ),
                          },
                        }
                      : undefined,
                  )
                }
                onClickCancel={() =>
                  this.setState({addIssuesetsDialogState: undefined})
                }
                onClickCopyOverrides={() => {
                  const {
                    newIssueset,
                    relatedIssues,
                  } = this.state.addIssuesetsDialogState;
                  if (!relatedIssues) {
                    this.props.onIssueUpdate({
                      added_issuesets: [newIssueset],
                      template_issueset: this.props.currentIssueset,
                    });
                  } else {
                    this.props.onIssuesUpdate(
                      [
                        issue,
                        ...relatedIssues.filter(issue => issue.selected),
                      ].map(relatedIssue => ({
                        id: relatedIssue.id,
                        added_issuesets: [newIssueset],
                        template_issueset: this.props.currentIssueset,
                      })),
                    );
                  }
                  this.setState({addIssuesetsDialogState: undefined});
                }}
                // TODO: Deduplicate
                onClickConfirm={() => {
                  const {
                    newIssueset,
                    relatedIssues,
                  } = this.state.addIssuesetsDialogState;
                  if (!relatedIssues) {
                    this.props.onIssueUpdate({
                      added_issuesets: [newIssueset],
                    });
                  } else {
                    this.props.onIssuesUpdate(
                      [
                        issue,
                        ...relatedIssues.filter(issue => issue.selected),
                      ].map(relatedIssue => ({
                        id: relatedIssue.id,
                        added_issuesets: [newIssueset],
                      })),
                    );
                  }
                  this.setState({addIssuesetsDialogState: undefined});
                }}
              />
            )}

            <div
              style={{
                color: "rgb(244, 67, 54)",
                fontSize: "12px",
                marginTop: "4px",
              }}
            >
              {this.state.problems.issuesetError}
            </div>
          </div>
        )}

        <FormControlLabel
          style={{margin: "0rem 2rem 1rem"}}
          control={
            <Switch
              color="primary"
              checked={issue.trigger_type === "LLM"}
              onChange={() =>
                // 1. Add a toggle to the the triggering logic section of the issue editor
                this.onOverridableIssueFieldUpdate({
                  trigger_type:
                    issue.trigger_type === "Classic" ? "LLM" : "Classic",
                })
              }
            />
          }
          label="Use LLM triggering logic"
          labelPlacement="start"
        />
        {issue.trigger_type === "LLM" && (
          <FormControlLabel
            style={{margin: "0rem 2rem 1rem"}}
            control={
              <Switch
                color="primary"
                checked={issue.llm_settings?.use_production_mode !== true} // will be checked if not set (default) or use_production_mode is false
                onChange={event =>
                  updateLlmSettings({
                    use_production_mode: !event.target.checked,
                  })
                }
              />
            }
            label="Use dev mode"
            labelPlacement="start"
          />
        )}

        {!this.props.llmsEnabled && issue.trigger_type === "LLM" && (
          <div style={styles.warningContainer}>
            LLMs are not enabled in the project settings.
          </div>
        )}
        {issue.trigger_type === "LLM" &&
          issue.llm_settings.use_production_mode && (
            <WorkflowEditor
              organisationId={this.props.organisationId}
              updateLlmSettings={updateLlmSettings}
              llm_settings={issue.llm_settings}
            />
          )}
        {this.renderTriggeringLogic(issue, usedInputs, issueFieldData, topics)}
        <div
          style={{
            padding: "0em 2em",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {usedInputs.class && (
            <div
              style={{
                width: "20rem",
                marginLeft: "2rem",
                marginRight: "2rem",
              }}
            >
              <SelectField
                floatingLabelText="Issue class"
                className="issue-class"
                iconStyle={{zIndex: 0}}
                floatingLabelStyle={{zIndex: 0}}
                {...this.getOverridableFieldData(
                  "SelectField",
                  "issue_class_id",
                  {
                    handleLoadedValue: value => value.toString(),
                    handleChangedValue: value => parseInt(value, 10),
                  },
                ).props}
                disabled={this.props.disabled}
              >
                {Object.keys(issueClasses).map(issueClassId => (
                  <MenuItem
                    value={issueClassId.toString()}
                    primaryText={issueClasses[issueClassId].name}
                    key={issueClassId}
                  />
                ))}
              </SelectField>
              {this.renderOverridenIssuesets(
                "issue_class_id",
                {
                  containerStyle: {
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                  },
                },
                value => issueClasses[value].name,
              )}
              <div style={{display: "flex", justifyContent: "space-between"}}>
                {this.renderRagScore()}
              </div>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                {this.renderShowParties()}
              </div>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                {this.renderReportOnly()}
              </div>
            </div>
          )}
          <div style={{marginRight: "2rem"}}>
            {this.renderIssueOrder(usedInputs, problems)}
            {this.renderShowClauseButtons()}
          </div>
        </div>
        {this.renderBackgroundColour(usedInputs, problems)}
      </div>
    );
  }

  renderTriggeringLogic(issue, usedInputs, issueFieldData, topics) {
    if (usedInputs.triggering_logic) {
      if (issue.trigger_type === "Classic") {
        return this.renderClassicTriggeringLogic(issue, issueFieldData, topics);
      }
      if (
        issue.trigger_type === "LLM" &&
        !issue.llm_settings.use_production_mode
      ) {
        return this.renderLlmTriggeringLogic();
      }
    }
    return null;
  }

  renderClassicTriggeringLogic(issue, issueFieldData, topics) {
    const topicsById = byId(topics);
    return (
      <div>
        <TriggeringLogic
          ref={this.createTriggeringLogicRef}
          triggerDisplay={issue.trigger_display}
          issue={issueFieldData.value}
          masterIssue={issue}
          topics={topics}
          filteredTopics={topics}
          allTopics={this.props.topics}
          topicsById={topicsById}
          onIssueTypeUpdate={this.onIssueTypeUpdate(issueFieldData)}
          hideButtons={this.props.hideTriggeringLogicButtons}
          isOverrideMode={this.isOverrideModeActive()}
          overriddenClients={
            _.chain(issue.override_values).map(_.keys).flatten().uniq().value()
              .length
          }
          disabled={this.props.disabled}
          issuePositions={this.props.issuePositions}
          editLevel={this.props.editLevel}
          organisationId={this.props.organisationId}
          clearProps={issueFieldData && issueFieldData.clearProps}
          roles={this.props.roles}
        />
        {this.renderOverridenIssuesets("issue")}
      </div>
    );
  }

  renderLlmTriggeringLogic() {
    const promptFieldData = this.getOverridableFieldData(
      "TextField",
      "llm_settings.prompt",
    );
    const textRetrievalMethodFieldData = this.getOverridableFieldData(
      "SelectField",
      "llm_settings.text_retrieval_method",
    );
    return (
      <Paper style={{margin: "0rem 2rem 1rem 2rem"}}>
        <div
          className="app-toolbar"
          style={{
            ...styles.cardHeader,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>LLM Settings</div>
        </div>
        <div style={{padding: "2em"}}>
          <div>
            <TextField
              variant="outlined"
              floatingLabelText="Prompt"
              multiLine={true}
              style={{width: "100%"}}
              {...promptFieldData.props}
            />
            <IconButton {...promptFieldData.clearProps} style={{zIndex: 0}}>
              <ClearIcon />
            </IconButton>
          </div>
          <div style={{display: "flex", alignItems: "center"}}>
            <SelectField
              floatingLabelText="Text Retrieval"
              className="issue-class"
              iconStyle={{zIndex: 0}}
              floatingLabelStyle={{zIndex: 0}}
              {...textRetrievalMethodFieldData.props}
              value={textRetrievalMethodFieldData.props.value || "rag"}
            >
              <MenuItem value={"rag"} primaryText="RAG" />
              <MenuItem value={"allText"} primaryText="All text" />
              <MenuItem value={"assistants"} primaryText="Assistants API" />
            </SelectField>
            <IconButton {...promptFieldData.clearProps} style={{zIndex: 0}}>
              <ClearIcon />
            </IconButton>
          </div>
        </div>
      </Paper>
    );
  }

  renderRagScore() {
    const fieldData = this.getOverridableFieldData("TextField", "rag_score");
    return (
      <div>
        <div style={{display: "flex", alignItems: "baseline"}}>
          <TextField
            floatingLabelText="Rag Score"
            type="number"
            {...fieldData.props}
            disabled={this.props.disabled}
          />
          {this.props.editLevel !== "base" && (
            <IconButton {...fieldData.clearProps} style={{zIndex: 0}}>
              <ClearIcon />
            </IconButton>
          )}
        </div>
        {this.renderOverridenIssuesets("rag_score", {
          containerStyle: {
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 20,
          },
        })}
      </div>
    );
  }

  renderShowParties() {
    const fieldData = this.getOverridableFieldData("Checkbox", "show_parties");
    return (
      <div style={{display: "flex", alignItems: "center"}}>
        <FormControlLabel
          control={
            <ChecboxV4
              checked={fieldData.props.value}
              onChange={fieldData.props.onChange}
              color="primary"
              disabled={this.props.disabled}
              style={{width: "auto"}}
            />
          }
          label={"Show Parties"}
        />
        {this.props.editLevel !== "base" && (
          <IconButton {...fieldData.clearProps} style={{zIndex: 0}}>
            <ClearIcon />
          </IconButton>
        )}
        {this.renderOverridenIssuesets("show_parties", {
          containerStyle: {
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 20,
          },
        })}
      </div>
    );
  }
  renderReportOnly() {
    const fieldData = this.getOverridableFieldData("Checkbox", "report_only");
    return (
      <div style={{display: "flex", alignItems: "center"}}>
        <FormControlLabel
          control={
            <ChecboxV4
              checked={fieldData.props.value}
              onChange={fieldData.props.onChange}
              color="primary"
              disabled={this.props.disabled}
              style={{width: "auto"}}
            />
          }
          label={"Report Only"}
        />
        {this.props.editLevel !== "base" && (
          <IconButton {...fieldData.clearProps} style={{zIndex: 0}}>
            <ClearIcon />
          </IconButton>
        )}
        {this.renderOverridenIssuesets("report_only", {
          containerStyle: {
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 20,
          },
        })}
      </div>
    );
  }

  renderShowClauseButtons() {
    const fieldData = this.getOverridableFieldData(
      "SelectField",
      "show_in_clause_buttons",
      {
        handleLoadedValue: value => value.toString(),
        handleChangedValue: value =>
          value === null || value === undefined ? value : value === "true",
      },
    );
    return (
      <div>
        <div style={{display: "flex", alignItems: "flex-end"}}>
          <SelectField
            floatingLabelText="Show In Clause Buttons"
            className="issue-class"
            iconStyle={{zIndex: 0}}
            floatingLabelStyle={{zIndex: 0}}
            {...fieldData.props}
            disabled={this.props.disabled}
          >
            <MenuItem value={"true"} primaryText="True" />
            <MenuItem value={"false"} primaryText={"False"} />
          </SelectField>
          {this.props.editLevel !== "base" && (
            <IconButton {...fieldData.clearProps} style={{zIndex: 0}}>
              <ClearIcon />
            </IconButton>
          )}
        </div>
        {this.renderOverridenIssuesets("show_in_clause_buttons", {
          containerStyle: {
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 20,
          },
        })}
      </div>
    );
  }

  renderDisplayName() {
    const fieldData = this.getOverridableFieldData("TextField", "display_name");
    return (
      <div style={{display: "flex"}}>
        <div
          style={{display: "flex", alignItems: "flex-end", flex: "1 1 auto"}}
        >
          <TextField
            type="text"
            className="display-name"
            style={{
              width: "60%",
              display: "block",
              marginLeft: "36px",
              marginRight: "36px",
              zIndex: 0,
            }}
            floatingLabelText="Display name"
            {...fieldData.props}
            disabled={this.props.disabled}
          />
          {this.props.editLevel !== "base" && (
            <IconButton {...fieldData.clearProps}>
              <ClearIcon />
            </IconButton>
          )}
        </div>
        {this.props.doNotSyncHandler
          ? this.props.doNotSyncHandler("do_not_sync", "Do not sync")
          : null}
      </div>
    );
  }

  renderIssueOrder(usedInputs, problems) {
    if (!usedInputs.order) {
      return null;
    }
    const fieldData = this.getOverridableFieldData("TextField", "issue_order", {
      validate: this.validateIssueOrder,
    });
    return (
      <div>
        <div style={{display: "flex", alignItems: "flex-end"}}>
          <TextField
            type="text"
            floatingLabelText="Issue order"
            errorText={problems && problems.issueOrderError}
            floatingLabelStyle={{zIndex: 0}}
            {...fieldData.props}
            disabled={this.props.disabled}
          />
          {this.props.editLevel !== "base" && (
            <IconButton {...fieldData.clearProps} style={{zIndex: 0}}>
              <ClearIcon />
            </IconButton>
          )}
        </div>
        {this.renderOverridenIssuesets("issue_order", {
          containerStyle: {
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 20,
          },
        })}
      </div>
    );
  }

  renderBackgroundColour(usedInputs, problems) {
    if (!usedInputs.styling) {
      return null;
    }
    const fieldData = this.getOverridableFieldData(
      "TextField",
      "styling.summary.backgroundColor",
      {validate: this.validateBackgroundColor},
    );
    return (
      <div
        style={{
          padding: "0em 4em",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{display: "flex", alignItems: "center"}}>
          <TextField
            type="text"
            floatingLabelText="Background color"
            errorText={problems && problems.backgroundColorError}
            floatingLabelStyle={{zIndex: 0}}
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "0",
            }}
            {...fieldData.props}
            disabled={this.props.disabled}
          />
          {this.props.editLevel !== "base" && (
            <IconButton {...fieldData.clearProps} style={{zIndex: 0}}>
              <ClearIcon />
            </IconButton>
          )}
        </div>
        {this.renderOverridenIssuesets("styling.summary.backgroundColor", {
          containerStyle: {
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 20,
          },
        })}
      </div>
    );
  }

  updateIssueStateValue = data => {
    this.setState(prevState => ({
      issue: {
        ...prevState.issue,
        ...data,
      },
    }));
  };

  getUsedInputs = _.memoize(() => {
    const inputs = {
      class: true,
      order: true,
      triggering_logic: true,
      contract_type: true,
      display_name: true,
      styling: true,
    };
    if (!this.props.inputsToUse) {
      return inputs;
    }
    return _.mapValues(inputs, (val, key) => {
      return Boolean(this.props.inputsToUse.includes(key));
    });
  });

  hasSelectedDuplicateIssueset = () =>
    _.get(this.state.selectedIssueset, "is_duplicate_on_master") === true;

  getDuplicateIssuesetIds = (issuesetsById, masterIssuesetId) => {
    return _.chain(issuesetsById)
      .values()
      .filter(issueset => issueset.master_issueset_id === masterIssuesetId)
      .map(issueset => issueset.id)
      .value();
  };

  getIssuesetValues() {
    const {contractTypesById, editLevel, selectedIssueset} = this.props;
    const issuesetsById = issuesetUtils.getIssuesetsById(contractTypesById);
    const currentContractTypes = this.useState()
      ? this.state.issue.contract_types
      : this.props.issue.contract_types;
    let currentIssueSets = issuesetUtils.getIssuesetIdsPresentInContractTypes(
      currentContractTypes,
    );
    if (
      editLevel !== "base" &&
      _.get(selectedIssueset, "is_duplicate_on_master") === false
    ) {
      const duplicateIssuesetIds = this.getDuplicateIssuesetIds(
        issuesetsById,
        selectedIssueset.id,
      );
      currentIssueSets = _.intersection(duplicateIssuesetIds, currentIssueSets);
    }
    return issuesetUtils.constructIssuesetSelectorItems(
      currentIssueSets,
      issuesetsById,
    );
  }

  getIssuesetOptions() {
    const {contractTypesById, editLevel, selectedIssueset} = this.props;
    const issuesetsById = issuesetUtils.getIssuesetsById(contractTypesById);
    const currentContractTypes = this.useState()
      ? this.state.issue.contract_types
      : this.props.issue.contract_types;
    const currentIssueSets = issuesetUtils.getIssuesetIdsPresentInContractTypes(
      currentContractTypes,
    );
    const allIssuesets = issuesetUtils.getIssuesetIdsPresentInContractTypes(
      Object.keys(contractTypesById).map(ctId => contractTypesById[ctId]),
    );
    let notSelectedIssuesets;
    if (
      editLevel !== "base" &&
      _.get(selectedIssueset, "is_duplicate_on_master") === false
    ) {
      const duplicateIssuesetIds = this.getDuplicateIssuesetIds(
        issuesetsById,
        selectedIssueset.id,
      );
      notSelectedIssuesets = _.difference(
        duplicateIssuesetIds,
        currentIssueSets,
      );
    } else {
      notSelectedIssuesets = _.difference(allIssuesets, currentIssueSets);
    }
    return issuesetUtils.constructIssuesetSelectorItems(
      notSelectedIssuesets,
      issuesetsById,
    );
  }

  onIssuesetClicked = ({id}) => {
    this.props.pushLocationSearchParams({issueset: id});
  };

  onIssuesetUpdate = (newValues, confirmRemovals = false) => {
    const existingValues = this.getIssuesetValues();

    // If an issueset has been removed (and we have not already received
    // confirmation via the confirm dialog)...
    if (newValues.length < existingValues.length && !confirmRemovals) {
      // ...open a dialog to confirm the removal.
      this.setState(prevState => ({
        ...prevState,
        confirmIssuesetRemovalDialogState: {issuesetsAfterRemoval: newValues},
      }));
      return;
    }

    const newIssuesetIds = newValues.map(newIssueset => newIssueset.id);
    const issuesetsById = issuesetUtils.getIssuesetsById(
      this.props.contractTypesById,
    );
    if (this.useState()) {
      this.setState(prevState => ({
        issue: {
          ...prevState.issue,
          contract_types: issuesetUtils.constructContractTypesByIssuesets(
            newIssuesetIds,
            issuesetsById,
          ),
        },
      }));
    }
    const {onIssueUpdate} = this.props;
    if (!onIssueUpdate) {
      return null;
    }
    const newIssuesets = _.chain(newValues)
      .filter(
        newValue =>
          !existingValues.find(
            existingValue => existingValue.id === newValue.id,
          ),
      )
      .map(newIssueset => {
        const issueset = issuesetsById[newIssueset.id];
        return {
          ...newIssueset,
          contract_type_id: issueset.contract_type_id,
          contract_type_name: issueset.contract_type_name,
          master_id: issueset.master_id,
          name: issueset.name,
          remote_client_id: issueset.remote_client_id,
        };
      })
      .value();
    const removedIssuesets = _.chain(existingValues)
      .filter(
        existingValue =>
          !newValues.find(newValue => newValue.id === existingValue.id),
      )
      .map(newIssueset => {
        const issueset = issuesetsById[newIssueset.id];
        return {
          ...newIssueset,
          contract_type_id: issueset.contract_type_id,
          contract_type_name: issueset.contract_type_name,
        };
      })
      .value();
    if (newIssuesets.length > 0) {
      // Assumes only one issueset is actually added at a time
      const newIssueset = newIssuesets[0];

      if (
        this.props.currentIssueset &&
        // If the new issueset is not derived from the selected issueset...
        newIssueset.master_id !== this.props.currentIssueset.master_id &&
        // ...and the selected issueset has overrides...
        this.props.issue.override_values[this.props.currentIssueset.master_id]
      ) {
        // ...open a dialog offering the opportunity to copy overrides...
        this.setState({
          addIssuesetsDialogState: {
            newIssueset,
            relatedIssues: this.props.relatedIssues?.map(issue => ({
              id: issue.id,
              name: removeIssueGroupPrefix(issue.display_name),
              selected: false,
            })),
          },
        });
      } else {
        // ...otherwise, just add the issueset without copying overrides
        this.props.onIssueUpdate({added_issuesets: newIssuesets});
      }
    } else if (removedIssuesets.length > 0) {
      this.props.onIssueUpdate({removed_issuesets: removedIssuesets});
    }
  };

  async getValue() {
    if (!this.props.isBulk && this.state.issue.contract_types.length === 0) {
      return this.setState(prevState => ({
        problems: {
          ...prevState.problems,
          issuesetError: "Issue should have at least one issueset",
        },
      }));
    }
    const problems = await (this.triggeringLogicRef &&
      this.triggeringLogicRef.updateData());
    if (!problems) {
      return this.state;
    }
    return {
      issue: this.state.issue,
      problems: {
        ...this.state.problems,
        ...problems,
      },
    };
  }

  validateIssueOrder = value => {
    const floatRegexp = /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    if (
      value !== undefined &&
      value !== null &&
      value !== "" &&
      !floatRegexp.test(value)
    ) {
      this.setState(prevState => ({
        problems: {
          ...prevState.problems,
          issueOrderError: "Issue order should be float or empty",
        },
      }));
      return false;
    }
    this.setState(prevState => {
      delete prevState.problems.issueOrderError;
      return {problems: prevState.problems};
    });
    return true;
  };

  validateBackgroundColor = value => {
    if (value !== undefined && value !== "" && !isCssColorValid(value)) {
      this.setState(prevState => ({
        problems: {
          ...prevState.problems,
          backgroundColorError: "Invalid color",
        },
      }));
      return false;
    }

    this.setState(prevState => {
      delete prevState.problems.backgroundColorError;
      return {problems: prevState.problems};
    });
    return true;
  };

  createTriggeringLogicRef = node => (this.triggeringLogicRef = node);

  onIssueTypeUpdate = _.memoize(
    issueFieldData => issue => {
      if (this.useState()) {
        return this.updateIssueStateValue(issue);
      }
      if (this.props.editLevel === "base") {
        this.props
          .onIssueUpdate(issue)
          .then(this.setState({updateFields: true}));
      } else {
        issueFieldData.props.onChange(issue);
      }
    },
    (...argv) => JSON.stringify(argv),
  );
}

function getHash(itemArr) {
  return (itemArr || []).map(val => val.id).join("_");
}

class SelectMemo extends React.Component {
  // this component is used to prevent select input clear
  // when the component rerenders - e.g. when the fresh page
  // loads and we start typing the input value gets cleared
  // after all data is fetched from the server

  shouldComponentUpdate(nextProps) {
    const valueHash = getHash(this.props.value);
    const valueHashNext = getHash(nextProps.value);
    const optionsHash = getHash(this.props.options);
    const optionsHashNext = getHash(nextProps.options);
    return valueHash !== valueHashNext || optionsHash !== optionsHashNext;
  }
  render() {
    const {value, options, onChange, onValueClick, disabled} = this.props;
    return (
      <Select
        className="issuesets"
        valueKey="id"
        labelKey="name"
        multi={true}
        removeSelected={true}
        clearable={false}
        backspaceRemoves={false}
        deleteRemoves={false}
        allowCreate={false}
        menuContainerStyle={{position: "unset"}}
        value={value}
        options={options}
        onChange={onChange}
        onValueClick={onValueClick}
        disabled={disabled}
      />
    );
  }
}

function isCssColorValid(stringToTest) {
  if (stringToTest === "") {
    return false;
  }
  if (stringToTest === "inherit") {
    return false;
  }
  if (stringToTest === "transparent") {
    return false;
  }

  const image = document.createElement("img");
  image.style.color = "rgb(0, 0, 0)";
  image.style.color = stringToTest;
  if (image.style.color !== "rgb(0, 0, 0)") {
    return true;
  }
  image.style.color = "rgb(255, 255, 255)";
  image.style.color = stringToTest;
  return image.style.color !== "rgb(255, 255, 255)";
}

export default IssueEditor;
