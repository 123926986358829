import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import BugReportIcon from "@material-ui/icons/BugReport";
import DoneIcon from "@material-ui/icons/Done";

import {Link} from "react-router";

const styles = {
  linkCell: {
    textDecoration: "none",
    color: "inherit",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 18,
    height: 18,
    marginRight: 4,
    flexShrink: 0,
  },
  textFieldIcon: {
    cursor: "pointer",
    color: "#888",
  },
  textBlock: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

const stripExtension = (name, extension) =>
  extension && name.endsWith(`.${extension}`)
    ? name.substring(0, name.length - extension.length - 1)
    : name;

const wrapCellContentIntoLink = (content, pathname) => {
  if (!content || !pathname) {
    return content;
  }
  return (
    <Link to={{pathname}} style={styles.linkCell} onlyActiveOnIndex>
      {content}
    </Link>
  );
};

function EditableTextfield(props) {
  const {fileExtension, isBadlyParsed} = props;

  const [isEditing, updateIsEditing] = React.useState(false);
  const onEditingStart = () => updateIsEditing(true);
  const onEditingFinish = () => updateIsEditing(false);

  const [isHovered, updateIsHovered] = React.useState(false);
  const onHoverStart = () => updateIsHovered(true);
  const onHoverEnd = () => updateIsHovered(false);

  const [value, updateValue] = React.useState(
    stripExtension(props.value, fileExtension) || "",
  );
  const onValueChange = e => updateValue(e.target.value);

  const onUpdateValue = () => {
    if (value && value !== stripExtension(props.value, fileExtension)) {
      const ending = fileExtension ? `.${fileExtension}` : "";
      props.onValueUpdate(`${value}${ending}`);
    }
  };

  const onRevertValue = () => {
    updateValue(stripExtension(props.value, fileExtension) || "");
    onHoverEnd();
    onEditingFinish();
  };

  const onConfirmEdit = () => {
    onUpdateValue();
    onEditingFinish();
  };

  return isEditing ? (
    <ClickAwayListener onClickAway={onRevertValue}>
      <TextField
        value={value}
        onChange={onValueChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {stripExtension(props.value, fileExtension) !== value && (
                <DoneIcon
                  style={styles.textFieldIcon}
                  onClick={onConfirmEdit}
                />
              )}
              <CloseIcon style={styles.textFieldIcon} onClick={onRevertValue} />
            </InputAdornment>
          ),
        }}
        fullWidth={true}
        autoFocus={true}
      />
    </ClickAwayListener>
  ) : (
    <div
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverEnd}
      style={styles.textBlock}
    >
      {wrapCellContentIntoLink(props.nonEditingEl, props.linkPath)}
      {isHovered && !isEditing ? (
        <EditIcon
          style={{
            ...styles.icon,
            cursor: "pointer",
          }}
          onClick={onEditingStart}
        />
      ) : null}
      {isBadlyParsed ? (
        <Tooltip title={"Badly parsed"} placement="top" arrow>
          <BugReportIcon
            style={{
              ...styles.icon,
              color: "#424242",
            }}
          />
        </Tooltip>
      ) : null}
    </div>
  );
}

export default EditableTextfield;
