import React from "react";
import PropTypes from "prop-types";

import ExpandMore from "material-ui/svg-icons/navigation/expand-more";
import ExpandLess from "material-ui/svg-icons/navigation/expand-less";
import Checkbox from "material-ui/Checkbox";

const styles = {
  root: {
    fontSize: 14,
    lineHeight: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    userSelect: "none",
  },
  header: {
    paddingLeft: 24,
    paddingRight: 24,
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerHovered: {
    background: "rgba(0, 0, 0, 0.05)",
  },
  label: {
    height: 30,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
  },
  expand: {
    marginTop: -2,
    marginLeft: 10,
    width: 20,
    height: 20,
  },
  expandHovered: {
    borderRadius: "50%",
    background: "rgba(0, 0, 0, 0.05)",
    transition: "none",
  },
  checkbox: {
    display: "block",
    width: 28,
    marginTop: -2,
    marginLeft: -4,
    transform: "scale(0.85)",
    cursor: "inherit",
  },
  children: {
    padding: 0,
  },
};

export default class ToolbarDropdownItem extends React.Component {
  constructor(props) {
    super(props);

    const hasChildren = Boolean(React.Children.count(props.children));
    const areAllChildsSelected = hasChildren && !props.hasUnselectedChildren;

    this.state = {
      isHovered: false,
      isExpanded: props.isSelected && !areAllChildsSelected,
    };
  }

  render() {
    const {
      level,
      label,
      withCheckbox,
      isHoverable,
      isSelected,
      handleSelect,
      labelStyle,
      headerStyle,
      children,
    } = this.props;
    const {isHovered, isExpanded} = this.state;
    const hasChildren = Boolean(React.Children.count(children));
    const {primary1Color} = this.context.muiTheme.palette;
    const headerStyleCompounded = {
      ...styles.header,
      ...headerStyle,
    };
    const levelPaddingLeftStep =
      parseInt(headerStyleCompounded.paddingLeft, 10) * 0.8;
    return (
      <div style={styles.root}>
        <div
          style={{
            ...headerStyleCompounded,
            ...(level && {
              paddingLeft:
                headerStyleCompounded.paddingLeft +
                level * levelPaddingLeftStep,
            }),
            ...(isHovered && styles.headerHovered),
          }}
          {...isHoverable && {
            onMouseEnter: () => this.setState({isHovered: true}),
            onMouseLeave: () => this.setState({isHovered: false}),
          }}
          onClick={() => handleSelect()}
        >
          {withCheckbox && (
            <Checkbox checked={isSelected} style={styles.checkbox} />
          )}
          <div
            style={{
              ...styles.label,
              ...labelStyle,
              ...(isSelected && {color: primary1Color}),
            }}
          >
            {label}
          </div>
          {hasChildren &&
            (() => {
              const Expand = !isExpanded ? ExpandMore : ExpandLess;
              return (
                <Expand
                  style={{
                    ...styles.expand,
                    ...(isSelected && {color: primary1Color}),
                    ...(isHovered && styles.expandHovered),
                  }}
                  onClick={event => {
                    event.stopPropagation();
                    this.setState({isExpanded: !isExpanded});
                  }}
                />
              );
            })()}
        </div>
        {Boolean(hasChildren && isExpanded) && (
          <div style={styles.children}>{children}</div>
        )}
      </div>
    );
  }
}

ToolbarDropdownItem.defaultProps = {
  level: 0,
  withCheckbox: false,
  isHoverable: true,
  isSelected: false,
  hasUnselectedChildren: false,
  handleSelect: () => {},
  labelStyle: {},
  headerStyle: {},
};

ToolbarDropdownItem.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  level: PropTypes.number.isRequired,
  withCheckbox: PropTypes.bool.isRequired,
  isHoverable: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  hasUnselectedChildren: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  labelStyle: PropTypes.object.isRequired,
  headerStyle: PropTypes.object.isRequired,
};

ToolbarDropdownItem.contextTypes = {
  muiTheme: PropTypes.object.isRequired,
};
