import _ from "underscore";
import basePath from "base_path";
import ActionTypes from "../constants/action_types";
import FileSaver from "file-saver";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function initDocument(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_DOWNLOAD,
    (
      organisationId,
      projectId,
      documentId,
      fileIndex,
      documentName,
      withChanges = false,
      withMarkup = false,
      withComments = false,
      withoutExtension = false,
    ) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}/project/${projectId}` +
            `/document/${documentId}/index/${fileIndex ?? "all"}/download/${
              withChanges
                ? `${
                    withMarkup
                      ? "with-markup"
                      : withComments
                      ? "with-comments"
                      : "with-changes"
                  }`
                : withoutExtension
                ? "without-extension"
                : ""
            }`,
          {withCredentials: true},
        )
        .then(response => {
          if (response && response.data && response.data.fileData) {
            const {data: responseData} = response;
            // eslint-disable-next-line node/no-deprecated-api
            const fileData = new Buffer(responseData.fileData, "base64");
            const downloadedDocumentBlob = new Blob([fileData], {
              type: "application/octet-binary",
            });
            FileSaver.saveAs(
              downloadedDocumentBlob,
              withoutExtension ? documentName : getDocumentName(documentName),
            );
            return _.omit(responseData, "fileData");
          }
        }),
  );
}

function getDocumentName(baseName) {
  if (baseName.endsWith(".doc") || baseName.endsWith(".rtf")) {
    const nameArray = baseName.split(".");
    nameArray[nameArray.length - 1] = "docx";
    return nameArray.join(".");
  }
  return baseName;
}
