import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import rateLimit, {getStandardTimeout} from "utils/rate_limit";
import {createChangeTimeWrapper} from "utils/ensure_fresh_update";
import removeTimezoneOffset from "utils/remove_timezone_offset";

import ActionTypes from "../constants/action_types";
import readTask from "../utils/read_task";

function doSave(requestor, organisationId, taskId, data, changeTime) {
  return requestor
    .patch(`${basePath}/organisation/${organisationId}/task/${taskId}`, data, {
      withCredentials: true,
    })
    .then(response => ({
      ...createChangeTimeWrapper(changeTime),
      ...readTask(response.data),
    }));
}

const save = rateLimit(doSave, getStandardTimeout());

export default function updateTask(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.TASK_UPDATE,
    (organisationId, taskId, data, changeTime) => {
      if (!data.last_edited) {
        throw new Error("data must contain a last_edited key");
      }
      if (data.due_date) {
        data.due_date = removeTimezoneOffset(data.due_date);
      }
      return save(requestor, organisationId, taskId, data, changeTime);
    },
    (organisationId, taskId, data, changeTime) => ({
      ...createChangeTimeWrapper(changeTime),
      ...data,
    }),
  );

  return action;
}
