export const IS_SHOW_DIFFS = "topic_analyser.show-diffs";

export const CLAUSE_LOG_VALUES = [
  {
    value: "DOCUMENT_CLAUSE_TOPIC_ADD",
    icon: "+",
  },
  {
    value: "DOCUMENT_CLAUSE_TOPIC_REMOVE",
    icon: "–",
  },
  {
    value: "DOCUMENT_CLAUSE_TOPIC_CONFIRM",
    icon: "✓",
  },
];
