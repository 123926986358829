import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {WorkflowId} from "common/flowmaster/types/workflow";

export type WorkflowRemoveAction = {
  type: WorkflowsActionTypes["WORKFLOW_REMOVE"]["SUCCESS"];
  payload: {id: WorkflowId};
};

export default function removeWorkflow(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.WORKFLOW_REMOVE,
    (organisationId: OrganisationId, workflowId: WorkflowId) => {
      return requestor
        .delete(
          `${basePath}/organisation/${organisationId}/workflow/${workflowId}`,
          {
            withCredentials: true,
          },
        )
        .then(response => response.data);
    },
  );
  return action;
}
