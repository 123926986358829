export default function keyedObjectPropType(valueTest) {
  return (props, propName, componentName, location, ...args) => {
    const value = props[propName];
    const keys = Object.keys(value);
    const keyLength = keys.length;
    for (let index = 0; index < keyLength; index += 1) {
      const key = keys[index];
      if (parseInt(key, 10).toString() !== key) {
        return new Error("Keys must be numbers");
      }
    }
    if (valueTest) {
      for (let index = 0; index < keyLength; index += 1) {
        const error = valueTest(value, index, componentName, location, ...args);
        if (error) {
          return error;
        }
      }
    }
    return null;
  };
}
