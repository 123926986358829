import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";

import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import ReplayIcon from "@material-ui/icons/Replay";
import DeleteIcon from "@material-ui/icons/Delete";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import FixedTooltip from "common_components/fixed_tooltip";
import * as TopicParameters from "plugins/topicparameters";

import getParameterValueStatusData from "./utils/get_parameter_value_status_data";
import findActualValue from "./utils/find_actual_value";

const styles = {
  parameterValueStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "4px",
  },
  iconStyles: {
    height: "12px",
    width: "12px",
    cursor: "pointer",
    flexShrink: "0",
  },
};

function getEligibleParameterValues(parameterValues) {
  return (parameterValues || []).filter(
    param =>
      param &&
      ((param.values && param.values.length > 0) ||
        (param.actual_values && param.actual_values.length > 0) ||
        (param.locked_values && param.locked_values.length > 0)),
  );
}

function initState(props) {
  const {parameterValues, showingParameterId, hideOtherParameters} = props;
  const goodParameterValues = getEligibleParameterValues(parameterValues);

  return {
    hideOtherParameters:
      showingParameterId &&
      goodParameterValues.length > 1 &&
      hideOtherParameters !== undefined
        ? hideOtherParameters
        : false,
  };
}

export class Parameters extends React.Component {
  constructor(props) {
    super(props);
    this.state = initState(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.hideOtherParameters !== prevProps.hideOtherParameters) {
      this.setState(() => initState(this.props));
    }
  }

  render() {
    const {parameterValues, showingParameterId} = this.props;
    const goodParameterValues = getEligibleParameterValues(parameterValues);

    const shownParameterValues = goodParameterValues.filter(
      param =>
        !(
          showingParameterId &&
          this.state.hideOtherParameters &&
          param.topicparameter_id !== showingParameterId
        ),
    );

    return (
      <div
        className="parameters"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {this.renderParameters(shownParameterValues)}
        {this.renderExpandButton(goodParameterValues)}
      </div>
    );
  }

  renderParameters(parameterValues) {
    const {parameters} = this.props;
    const parametersById = _.groupBy(parameters, "id");

    return parameterValues.map(parameterValue => {
      const parameter = parametersById[parameterValue.topicparameter_id][0];
      const {values, actual_values: actualValues} = parameterValue;

      const parameterValuesByStatus = getParameterValueStatusData(
        values,
        actualValues,
        parameter.parameter_type,
      );
      return (
        <div
          key={parameter.id}
          className="parameter"
          style={{
            borderRadius: "1em",
            border: "1px solid #aaa",
            fontSize: "0.6em",
            display: "inline-block",
            margin: "0.1em",
            padding: "0.2em 0.4em",
          }}
        >
          <div style={{display: "flex"}}>
            <FixedTooltip content={`Parameter id: ${parameter.id}`}>
              <>
                {parameter.isUpdated && (
                  <HourglassEmptyIcon style={styles.iconStyles} />
                )}
                <span
                  className="parameter-name"
                  style={{
                    fontStyle: "italic",
                    width: "100%",
                    textAlign: "center",
                    display: "inline-block",
                    padding: "0 1em",
                    boxSizing: "border-box",
                    cursor: "pointer",
                  }}
                  onClick={this.props.onParameterClick}
                >
                  {parameter.name}
                </span>
              </>
            </FixedTooltip>
            {this.props.updateTopicparameterActualValues && actualValues ? (
              <ReplayIcon
                onClick={this.onParameterValuesRevert(parameter.id)}
                style={styles.iconStyles}
              />
            ) : (
              <ClearIcon
                onClick={this.onParameterValuesDelete(parameter.id)}
                style={styles.iconStyles}
              />
            )}
          </div>
          <div
            className="values"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {this.renderParameterValues(
              parameterValuesByStatus,
              parameter,
              actualValues,
            )}
          </div>
        </div>
      );
    });
  }

  renderParameterValues(parameterValuesByStatus, parameter, actualValues) {
    const result = [];
    _.each(parameterValuesByStatus, (parameterValues, status) => {
      parameterValues.forEach((value, idx) => {
        result.push(
          <div
            key={`${parameter.id}_${status}_${idx}`}
            className="value"
            style={{
              ...styles.parameterValueStyles,
              ...this.getParameterValueStyle(status),
            }}
          >
            <span style={{padding: "0.2em"}}>
              {this.renderParameterValue(parameter, value)}
            </span>
            {this.renderActionButton(status, value, parameter, actualValues)}
          </div>,
        );
      });
    });
    return result;
  }

  renderActionButton = (status, value, parameter, actualValues) => {
    const {updateTopicparameterActualValues} = this.props;
    if (!updateTopicparameterActualValues) {
      return null;
    }
    switch (status) {
      case "confirmed":
      case "added":
        return (
          <DeleteIcon
            onClick={this.onParameterValueDelete(
              value,
              actualValues,
              parameter,
            )}
            style={styles.iconStyles}
          />
        );
      case "unconfirmed":
        return (
          <DoneIcon
            onClick={this.onParameterValueAdd(value, actualValues, parameter)}
            style={styles.iconStyles}
          />
        );
      case "deleted":
        return (
          <AddIcon
            onClick={this.onParameterValueAdd(value, actualValues, parameter)}
            style={styles.iconStyles}
          />
        );
    }
  };

  getParameterValueStyle(status) {
    switch (status) {
      case "confirmed":
        return {
          border: "1px solid #aaf",
        };
      case "unconfirmed":
        return {
          backgroundColor: "#ffe0b2",
        };
      case "added":
        return {
          border: "1px solid #fdb056",
        };
      case "deleted":
        return {
          backgroundColor: "#E0E0E0",
        };
      default:
        return {};
    }
  }

  renderParameterValue(parameter, parameterValue) {
    // eslint-disable-next-line import/namespace
    const topicParameter = TopicParameters[parameter.parameter_type];
    if (topicParameter) {
      return topicParameter.valueRenderer(parameter, parameterValue);
    }
    return JSON.stringify(parameterValue);
  }

  renderExpandButton(parameterValues) {
    const {showingParameterId} = this.props;
    if (parameterValues.length < 2 || !showingParameterId) {
      return null;
    }
    const iconStyle = {cursor: "pointer"};
    const {hideOtherParameters} = this.state;
    return (
      <div
        title={
          hideOtherParameters
            ? "Show Other Parameters"
            : "Hide Other Parameters"
        }
        style={{display: "flex", justifyContent: "center"}}
      >
        {hideOtherParameters ? (
          <ExpandMoreIcon
            onClick={this.onShowOtherParameters}
            style={iconStyle}
          />
        ) : (
          <ExpandLessIcon
            onClick={this.onHideOtherParameters}
            style={iconStyle}
          />
        )}
      </div>
    );
  }

  onHideOtherParameters = () =>
    this.setState(() => ({hideOtherParameters: true}));
  onShowOtherParameters = () =>
    this.setState(() => ({hideOtherParameters: false}));

  onParameterValueDelete = (value, actualValues, parameter) => () => {
    const foundActualValue = findActualValue(
      value,
      actualValues,
      parameter.parameter_type,
    );
    if (foundActualValue.value) {
      actualValues.splice(foundActualValue.index, 1);
      const topicparameterUpdates = {
        [parameter.id]: {
          values: actualValues,
          valuesChanged: true,
        },
      };
      this.updateTopicparameterActualValues(topicparameterUpdates);
    }
  };

  onParameterValueAdd = (value, actualValues, parameter) => () => {
    const topicparameterUpdates = {
      [parameter.id]: {
        values: [...(actualValues ? actualValues : []), value],
        valuesChanged: true,
      },
    };
    this.updateTopicparameterActualValues(topicparameterUpdates);
  };

  onParameterValuesRevert = parameterId => () => {
    const topicparameterUpdates = {
      [parameterId]: {
        values: null,
        valuesChanged: true,
      },
    };
    this.updateTopicparameterActualValues(topicparameterUpdates);
  };

  onParameterValuesDelete = parameterId => () => {
    const topicparameterUpdates = {
      [parameterId]: {
        values: [],
        valuesChanged: true,
      },
    };
    this.updateTopicparameterActualValues(topicparameterUpdates);
  };

  updateTopicparameterActualValues = topicparameterUpdates => {
    const {clause} = this.props;
    const data = {
      topicparameterUpdates,
    };
    data.clausepart_last_edited = clause.last_edited;
    this.props.updateTopicparameterActualValues(this.props.topic.value, data);
  };
}

Parameters.propTypes = {
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  parameterValues: PropTypes.arrayOf(
    PropTypes.shape({
      topicparameter_id: PropTypes.number.isRequired,
      values: PropTypes.array,
    }),
  ),
};

export default Parameters;
