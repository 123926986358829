import {WorkflowTaskId} from "./task_config";

interface BaseDataLocationObject {
  key: number | string;
}

export const valueTypes = [
  "none",
  "literal",
  "input",
  "context",
  "prompt",
  "task",
  "listItem",
] as const;
export type ValueType = typeof valueTypes[number];

export interface ContextDataLocationObject extends BaseDataLocationObject {
  source_type: "context";
  key: number;
}

export interface InputDataLocationObject extends BaseDataLocationObject {
  source_type: "input";
  key: number;
}

export interface TaskDataLocationObject extends BaseDataLocationObject {
  source_type: "task";
  // Id of the task
  key: string;
  task: WorkflowTaskId;
  // Accessor into the task to get nested values if task returns an object
  value: string;
}
export interface PromptDataLocationObject extends BaseDataLocationObject {
  source_type: "prompt";
}

export interface LiteralDataLocationObject<T> {
  source_type: "literal";
  value: T;
}

export interface EmptyDataLocationObject {
  source_type: "none";
}

export type KeyedDataLocationObject =
  | ContextDataLocationObject
  | InputDataLocationObject
  | TaskDataLocationObject
  | PromptDataLocationObject;

export type DataLocation<T> =
  | LiteralDataLocationObject<T>
  | KeyedDataLocationObject
  | EmptyDataLocationObject;
