import basePath from "base_path";
import ActionTypes from "../../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function removeRole(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.ROLE_REMOVE,
    (organisationId, roleId) => {
      return requestor
        .delete(`${basePath}/organisation/${organisationId}/role/${roleId}`, {
          withCredentials: true,
        })
        .then(response => response.data)
        .catch(err => err.data);
    },
  );
  return action;
}
