import BaseRoute from "utils/base_route";

import makeLoginRoute from "common_components/login";
import LoggedInRoute from "routes/logged_in";
import SignupRoute from "routes/signup";
import ResetPasswordRoute from "routes/reset_password";

import AppContainer from "./containers/app";

class AppRoute extends BaseRoute {
  constructor(store) {
    super("/", store);
    this.component = AppContainer;
  }

  createChildRoutes() {
    const LoginRoute = makeLoginRoute(true);
    return [
      new LoginRoute(this.store),
      new SignupRoute(this.store),
      new LoggedInRoute(this.store),
      new ResetPasswordRoute(this.store),
    ];
  }
}

export default AppRoute;
