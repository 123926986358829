import moment from "moment";

export default function getDataFromAnalytics(analytics) {
  const colorMaker = new ColorMaker();
  const reversedAnalytics = [...analytics].reverse();
  const data = reversedAnalytics
    .map(
      (
        {
          regex_per_sec: regexPerSec,
          classification_time: classificationTime,
          contract_type_id: ctId,
          organisation_name: orgName,
          contract_type_name: ctName,
        },
        index,
      ) => ({
        x: index,
        ySpeed: regexPerSec || 0,
        yTime: classificationTime || 0,
        color: colorMaker.getColor(ctId, orgName, ctName),
      }),
    )
    .filter(
      item =>
        item.ySpeed > 0 &&
        item.ySpeed < 50000 &&
        item.yTime > 0 &&
        item.yTime < 20 * 60,
    );
  const tickValues = reversedAnalytics.map(({creation_date: date}) =>
    moment(date).format("DD/MM/YYYY - h:mm A"),
  );
  return {data, tickValues, legendItems: colorMaker.legendItems};
}

function ColorMaker() {
  const colors = [
    "#332288",
    "#88ccee",
    "#44aa99",
    "#117733",
    "#999933",
    "#ddcc77",
    "#cc6677",
    "#882255",
    "#aa4499",
    "#dddddd",
  ];
  const ctIds = [];
  this.legendItems = [];
  const getColorNumber = (ctId, ctOrgName, ctName) => {
    const index = ctIds.findIndex(_id => _id === ctId);
    if (index > -1) {
      return index;
    }
    ctIds.push(ctId);
    const lastIndex = ctIds.length - 1;
    this.legendItems.push({
      color: colors[lastIndex % colors.length],
      title: `${ctName} - ${ctOrgName}`,
    });
    return lastIndex;
  };
  this.getColor = function getColor(ctId, orgName, ctName) {
    const index = getColorNumber(ctId, orgName, ctName);
    return colors[index % colors.length];
  };
}
