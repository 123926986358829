import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import {Link} from "react-router";
import ToolbarDropdown from "common_components/toolbar/dropdown";
import ToolbarDropdownItem from "common_components/toolbar/dropdown/item";
import ListFilter from "common_components/list_filter";
import CheckboxBasic from "common_components/inputs/checkbox_basic";
import {Table, Tr, Th, Td} from "common_components/table";
import HotAndStarInfoWidget from "common_components/hot_and_star_info_widget";

import Button from "@material-ui/core/Button";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import RefreshIcon from "@material-ui/icons/Refresh";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import Tooltip from "@material-ui/core/Tooltip";

import {Toolbar, ToolbarGroup, ToolbarTitle} from "material-ui/Toolbar";

import getListFilterRegexes from "utils/get_list_filter_regexes";

const styles = {
  headerButton: {
    margin: 0,
    flexShrink: 0,
  },
  inner: {
    padding: "0em 1em 0em 2em",
  },
};

const filterTypes = [
  {title: "All Types", useAllFields: true, isDefault: true},
  {title: "Parameter", fieldName: "name"},
  {title: "Topic Name", fieldName: "topic_name"},
];

export default class TopicParameterList extends React.Component {
  constructor(props) {
    super(props);
    const contractTypeFilteredItemsIds = JSON.parse(
      localStorage.getItem(`filteredContractTypeIds_${props.organisationId}`),
    );
    const filterText = localStorage.getItem("topicparameterFilterText");
    const filterTypeTitle = localStorage.getItem(
      "topicparameterFilterTypeTitle",
    );
    this.state = {
      contractTypeFilteredItemsIds: contractTypeFilteredItemsIds
        ? contractTypeFilteredItemsIds
        : [],
      filterText:
        filterText === "null" || filterText === "undefined" ? "" : filterText,
      filterTypeTitle,
      showIssuesNotProcessed: false,
    };
  }

  render() {
    const {topicParameters = []} = this.props;
    const baseTopicparameters = this.getBaseTopicparameters(topicParameters);
    const filteredTopicparameters = this.getFilteredTopicparameters(
      baseTopicparameters,
    );
    return (
      <div className="app-page">
        <Toolbar className="app-toolbar">
          <ToolbarGroup key={0}>
            <ToolbarTitle text="Topic parameters" />
            <Button onClick={this.onTopicListClick}>TOPIC LIST</Button>
            <ToolbarDropdown
              leftIcon={<FindInPageIcon />}
              labelText="Contract Type"
              values={this.state.contractTypeFilteredItemsIds.map(id =>
                id.toString(),
              )}
              showSelectAndUnselectAllItems={true}
              showSelectedItemsList={true}
              showFilterTextField={true}
              itemHeaderStyle={{
                paddingLeft: 15,
                paddingRight: 15,
              }}
              handleChange={values =>
                this.onContractTypeFilterConfirm(
                  values.map(value => parseInt(value, 10)),
                )
              }
            >
              {_.map(_.sortBy(this.props.contractTypesById, "name"), item => (
                <ToolbarDropdownItem
                  key={item.id}
                  value={item.id.toString()}
                  label={item.name}
                  withCheckbox={true}
                  labelStyle={{height: 22}}
                />
              ))}
            </ToolbarDropdown>
            <Button
              onClick={this.props.fetchTopicparameters}
              startIcon={<RefreshIcon />}
              style={styles.headerButton}
            >
              Refresh Topicparameters
            </Button>

            <CheckboxBasic
              checked={this.state.showIssuesNotProcessed}
              onCheck={this.triggerShowIssuesNotProcessed}
              label="Show Issues Not Processed"
            />
          </ToolbarGroup>
          <ToolbarGroup key={1}>
            <ListFilter
              filterString={this.state.filterText}
              onFilterStringChange={this.setFilterText}
              itemsCount={baseTopicparameters.length}
              matchesCount={filteredTopicparameters.length}
              filterTypes={filterTypes}
              filterTypeTitle={this.getFilterType().title}
              onFilterTypeChange={this.onFilterTypeChange}
            />
          </ToolbarGroup>
        </Toolbar>
        <div className="app-body" style={styles.inner}>
          <Table sortColumnIndex={1} hasStickyHeader={true}>
            <thead>
              <tr>
                <Th style={{width: "2rem"}} />
                <Th style={{width: "34rem"}}>Topic</Th>
                <Th style={{width: "6rem"}}>Parameter</Th>
                <Th style={{width: "12rem"}}>Contract Types</Th>
                <Th
                  style={{width: "7rem"}}
                  containerStyle={{justifyContent: "center"}}
                >
                  Related Issues
                </Th>
              </tr>
            </thead>
            <tbody>
              {filteredTopicparameters.map((topicParameter, index) => {
                const {organisationId} = this.props;
                const pathname = `/organisation/${organisationId}/topic/${topicParameter.topic_id}/detail`;
                const search = `?topicparameter_id=${topicParameter.topicparameter_id}`;
                const {primary1Color} = this.context.muiTheme.palette;
                return (
                  <Tr key={index}>
                    <Td>
                      <HotAndStarInfoWidget
                        label="Topicparameter"
                        isHot={topicParameter.is_hot}
                        isStar={topicParameter.is_star}
                        updateIsStar={this.updateTopicparameterIsStar(
                          topicParameter.topicparameter_id,
                          topicParameter.is_star,
                        )}
                      />
                    </Td>
                    <Td
                      style={{textAlign: "left", overflow: "visible"}}
                      handleChildren={(children, thisTd) => (
                        <Link
                          to={{pathname, search}}
                          style={{
                            color: thisTd.props.isRowHovered
                              ? primary1Color
                              : "#111",
                            marginLeft: topicParameter.issues_paused ? -24 : 0,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {children}
                        </Link>
                      )}
                    >
                      {topicParameter.issues_paused && (
                        <Tooltip
                          enterDelay={500}
                          placement="top"
                          title={"Issues not processed"}
                        >
                          <PriorityHighIcon />
                        </Tooltip>
                      )}
                      {topicParameter.topic_name}
                    </Td>
                    <Td>{topicParameter.name}</Td>
                    <Td>
                      {topicParameter.contract_types.map((ct, index) => (
                        <div key={index} style={{fontSize: "14px"}}>
                          {ct.name}
                        </div>
                      ))}
                    </Td>
                    <Td style={{textAlign: "center"}}>
                      {topicParameter.related_issues_count}
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }

  getBaseTopicparameters = topicParameters => {
    const {contractTypeFilteredItemsIds} = this.state;
    if (contractTypeFilteredItemsIds.length === 0) {
      return topicParameters;
    }
    return topicParameters
      .filter(parameter => {
        let isParameterFilteredOut = false;
        parameter.contract_types.forEach(ct => {
          if (contractTypeFilteredItemsIds.includes(ct.contract_type_id)) {
            isParameterFilteredOut = true;
          }
        });
        return isParameterFilteredOut;
      })
      .map(parameter => ({
        ...parameter,
        contract_types: parameter.contract_types.filter(ct =>
          contractTypeFilteredItemsIds.includes(ct.contract_type_id),
        ),
      }));
  };

  getFilteredTopicparameters = baseTopicparameters => {
    const filterRegexes = getListFilterRegexes(this.state.filterText);
    let result = baseTopicparameters;
    if (filterRegexes) {
      const filterType = this.getFilterType();
      result = baseTopicparameters.filter(tp =>
        filterRegexes.every(filterText => {
          const fieldNames = filterType.useAllFields
            ? filterTypes
                .filter(({fieldName}) => fieldName)
                .map(({fieldName}) => fieldName)
            : [filterType.fieldName];
          const fieldValues = fieldNames.map(fieldName => {
            let fieldValue = tp[fieldName];
            if (_.isNumber(fieldValue)) {
              fieldValue = fieldValue.toString();
            }
            if (_.isString(fieldValue)) {
              fieldValue = fieldValue.toLowerCase();
            }
            return fieldValue;
          });
          for (const fieldValue of fieldValues) {
            if (filterText.test(fieldValue)) {
              return true;
            }
          }
          return false;
        }),
      );
    }
    return result.filter(tp =>
      this.state.showIssuesNotProcessed ? tp.issues_paused : true,
    );
  };

  setFilterText = filterText => {
    localStorage.setItem("topicparameterFilterText", filterText);
    this.setState(() => ({filterText}));
  };

  getFilterType = () => {
    if (this.state.filterTypeTitle) {
      for (const filterType of filterTypes) {
        if (filterType.title === this.state.filterTypeTitle) {
          return filterType;
        }
      }
    }
    return this.getDefaultFilterType();
  };

  getDefaultFilterType = () => {
    return filterTypes.find(filterType => filterType.isDefault);
  };

  onFilterTypeChange = value => {
    if (value !== this.getDefaultFilterType().title) {
      localStorage.setItem("topicparameterFilterTypeTitle", value);
    } else {
      localStorage.removeItem("topicparameterFilterTypeTitle");
    }
    this.setState(() => ({filterTypeTitle: value}));
  };

  onTopicListClick = () => this.props.toggleView("list");

  onContractTypeFilterConfirm = contractTypeFilteredItemsIds => {
    this.setState(
      () => ({contractTypeFilteredItemsIds}),
      () => {
        localStorage.setItem(
          `filteredContractTypeIds_${this.props.organisationId}`,
          JSON.stringify(contractTypeFilteredItemsIds),
        );
      },
    );
  };

  triggerShowIssuesNotProcessed = () =>
    this.setState(prevState => ({
      showIssuesNotProcessed: !prevState.showIssuesNotProcessed,
    }));

  updateTopicparameterIsStar = (topicparameterId, topicIsStar) => () => {
    this.props.onTopicparameterUpdate(topicparameterId, {
      is_star: !topicIsStar,
    });
  };
}

TopicParameterList.contextTypes = {
  muiTheme: PropTypes.object.isRequired,
};
