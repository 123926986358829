import React from "react";
import styled from "styled-components";

import Fieldset from "./styled/fieldset";
import Legend from "./styled/legend";
import HeadingCell from "./styled/heading_cell";

import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";

interface Props {
  llmRun: EnhancedLlmRun;
}

const InputRow = styled.tr`
  & th,
  & td {
    padding: 0.25em 1em;
  }
  & th:not(:last-child),
  & td:not(:last-child) {
    border-right: 1px solid #ddd;
  }
`;

const InputCell = styled.td`
  :not(:last-child) {
    border-right: 1px solid #ddd;
    padding-right: 1em;
  }
`;

export default function Inputs({llmRun}: Props) {
  return (
    <Fieldset>
      <Legend>Inputs</Legend>
      <table>
        <thead>
          <InputRow>
            <HeadingCell>key</HeadingCell>
            <HeadingCell>name</HeadingCell>
            <HeadingCell>value</HeadingCell>
          </InputRow>
        </thead>
        <tbody>
          {Object.entries(llmRun.data.data_store.input).map(([key, item]) => {
            const inputId = parseInt(key, 10);
            const input = llmRun.inputs.find(input => input.id === inputId);
            return (
              <InputRow key={key}>
                <InputCell>{key}</InputCell>
                <InputCell>{input?.name}</InputCell>
                <InputCell>{item}</InputCell>
              </InputRow>
            );
          })}
        </tbody>
      </table>
    </Fieldset>
  );
}
