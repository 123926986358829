import React from "react";
import {cloneDeep, isEqual} from "lodash";

import Paper from "material-ui/Paper";
import TextField from "material-ui/TextField";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import * as colors from "material-ui/styles/colors";

const styles = {
  cardHeader: {
    height: "2.5rem",
    lineHeight: "2.4rem",
    paddingLeft: "2rem",
    background: colors.yellow600,
    fontSize: "17px",
    fontWeight: 500,
  },
  itemIndex: {
    marginTop: "2px",
    fontSize: "16px",
    width: "1.5rem",
    textAlign: "right",
    color: colors.grey600,
  },
  textFieldRoot: {
    width: "48%",
    height: "32px",
    margin: "0rem 1rem 0.6rem 1rem",
  },
  textareaStyle: {
    marginBottom: 0,
    marginTop: 0,
  },
  textFieldHint: {
    bottom: "unset",
  },
  deleteIcon: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
    transition: "none",
    flexShrink: "0",
  },
};

class IssueRelatedSearches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedSearches: props.relatedSearches,
    };
  }

  componentDidUpdate(prevProps) {
    const {relatedSearches: prevRelatedSearches} = prevProps;
    const {relatedSearches: currentRealatedSearches} = this.props;
    if (!isEqual(currentRealatedSearches, prevRelatedSearches)) {
      this.setState(() => ({relatedSearches: currentRealatedSearches}));
    }
  }

  render() {
    const {relatedSearches} = this.state;
    return (
      <Paper style={{margin: "2rem 2rem 1rem 2rem"}}>
        <div
          className="app-toolbar"
          style={{
            ...styles.cardHeader,
            justifyContent: "normal",
          }}
        >
          Related Searches
        </div>
        <div style={{padding: "1rem 2rem"}}>
          {relatedSearches.map((relatedSearchItem, relatedSearchIndex) =>
            this.renderRelatedSearchItem(
              relatedSearchItem,
              relatedSearchIndex,
              this.onRelatedSearchItemChange(relatedSearchIndex),
              true,
            ),
          )}
          {this.renderRelatedSearchItem(
            {name: "", search_text: ""},
            null,
            this.onRelatedSearchItemAdd(relatedSearches.length),
            false,
          )}
        </div>
      </Paper>
    );
  }

  renderRelatedSearchItem = (item, itemIndex, onChangeHandler, saveOnBlur) => (
    <div
      key={itemIndex}
      style={{display: "flex", justifyContent: "space-between"}}
    >
      <div style={styles.itemIndex}>
        {itemIndex !== null ? `${itemIndex + 1}.` : ""}
      </div>
      <TextField
        ref={this.createNameRef(itemIndex)}
        hintText="Name"
        onChange={onChangeHandler("name")}
        onBlur={saveOnBlur ? this.onRelatedSearchItemBlur : undefined}
        value={item.name}
        name="name"
        multiLine={true}
        style={styles.textFieldRoot}
        textareaStyle={styles.textareaStyle}
        hintStyle={styles.textFieldHint}
        rows={parseInt(item.name.length / 60, 10) + 1}
        disabled={this.props.disabled}
      />
      <TextField
        ref={this.createSearchTextRef(itemIndex)}
        hintText="Search Text"
        onChange={onChangeHandler("search_text")}
        onBlur={saveOnBlur ? this.onRelatedSearchItemBlur : undefined}
        value={item.search_text}
        name="search_text"
        multiLine={true}
        style={styles.textFieldRoot}
        textareaStyle={styles.textareaStyle}
        hintStyle={styles.textFieldHint}
        rows={parseInt(item.search_text.length / 60, 10) + 1}
        disabled={this.props.disabled}
      />
      {itemIndex !== null ? (
        <DeleteIcon
          onClick={this.onRelatedSearchItemDelete(itemIndex)}
          color={colors.grey700}
          style={styles.deleteIcon}
          disabled={this.props.disabled}
        />
      ) : (
        <div style={{width: styles.deleteIcon.width}} />
      )}
    </div>
  );

  onRelatedSearchItemChange = itemIndex => fieldName => e => {
    const newRelatedSearches = cloneDeep(this.state.relatedSearches);
    newRelatedSearches[itemIndex][fieldName] = e.target.value;
    this.setState(() => ({relatedSearches: newRelatedSearches}));
  };

  onRelatedSearchItemBlur = () => {
    if (!isEqual(this.props.relatedSearches, this.state.relatedSearches)) {
      this.props.onChange(this.state.relatedSearches);
    }
  };

  onRelatedSearchItemAdd = itemIndex => fieldName => e => {
    const fieldValue = e.target.value;
    if (fieldValue.trim() === "") {
      return;
    }
    const newRelatedSearches = cloneDeep(this.state.relatedSearches);
    newRelatedSearches.push({
      [fieldName]: fieldValue,
      [fieldName === "name" ? "search_text" : "name"]: "",
    });
    this.setState(
      () => ({relatedSearches: newRelatedSearches}),
      () => this[`${itemIndex}_${fieldName}`].focus(),
    );
    e.target.value = null;
  };

  onRelatedSearchItemDelete = itemIndex => () => {
    const newRelatedSearches = cloneDeep(this.state.relatedSearches);
    newRelatedSearches.splice(itemIndex, 1);
    this.setState(
      () => ({relatedSearches: newRelatedSearches}),
      () => this.props.onChange(newRelatedSearches),
    );
  };

  createNameRef = index => node => (this[`${index}_name`] = node);
  createSearchTextRef = index => node => (this[`${index}_search_text`] = node);
}

export default IssueRelatedSearches;
