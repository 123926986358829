import React from "react";
import TextField from "material-ui/TextField";
import shouldHighlightRegexWithSpaces from "../../../utils/should_highlight_regex_with_spaces";
import * as colors from "@material-ui/core/colors";
import TemplatedText from "./templated_text";

const styles = {
  liTextField: {
    width: "100%",
    paddingTop: 5,
    zoom: 1,
    height: "auto",
  },
  textAreaStyle: {
    fontFamily: "monospace",
    fontWeight: 200,
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
  },
};

const ClassifierOptionTextField = ({
  classifier,
  dataValue,
  name,
  regexIndex,
  regexValue,
  errorText,
  notDeleted,
  notNew,
  isChanged,
  selectedRegex,
  isSelected,
  usesModules,
  templatedText,
  isHovered,
  onRegexBlur,
  focusRegexField,
}) => {
  const addColorStatus = (old, changed) =>
    changed ? "#fff9c4" : old ? "#fff" : "#e8f5e9";

  return (
    <div
      style={{
        width: classifier.value[name].length > 1 ? "95%" : "100%",
        marginRight: "0.5rem",
      }}
    >
      {notDeleted ? (
        <TextField
          key={`${regexIndex}-${regexValue}`}
          name={name}
          defaultValue={regexValue}
          errorText={errorText}
          style={{
            ...styles.liTextField,
            backgroundColor: addColorStatus(notNew, isChanged),
          }}
          underlineStyle={{bottom: 2}}
          textareaStyle={{
            ...shouldHighlightRegexWithSpaces.getStyle(regexValue),
            ...styles.textAreaStyle,
            color: !selectedRegex || isSelected ? "inherit" : colors.grey[400],
          }}
          multiLine={true}
          onBlur={onRegexBlur(
            {
              classifier,
              name,
              dataValue,
            },
            !notNew,
          )}
          onFocus={e =>
            focusRegexField(e, {
              regexIndex,
              name,
            })
          }
          underlineShow={!usesModules}
        />
      ) : (
        <TextField
          key={`${regexIndex}-${regexValue}`}
          name={name}
          value={regexValue}
          style={{
            ...styles.liTextField,
            backgroundColor: "#ffebee",
          }}
          underlineStyle={{bottom: 2}}
          textareaStyle={{
            ...shouldHighlightRegexWithSpaces.getStyle(regexValue),
            ...styles.textAreaStyle,
            color: !selectedRegex || isSelected ? "inherit" : colors.grey[400],
          }}
          multiLine={true}
          onFocus={e =>
            focusRegexField(e, {
              regexIndex,
              name,
            })
          }
          underlineShow={!usesModules}
        />
      )}
      {usesModules && (
        <TemplatedText text={templatedText} isParentHovered={isHovered} />
      )}
    </div>
  );
};

export default ClassifierOptionTextField;
