import {RouterState} from "react-router/lib/Router";
import BaseRoute from "utils/base_route";
import Usergroup from "./containers/usergroup";
import fetchUsergroups from "modules/usergroups/actions/fetch";

export default class UsergroupRoute extends BaseRoute {
  constructor(store) {
    super("usergroup", store);
  }
  getComponents(_, cb) {
    cb(null, Usergroup);
  }

  // TODO: Could annotate BaseRoute in Typescript soon...
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fetchActiveData(state: {router: RouterState}) {
    const {
      params: {organisationId},
    } = state.router;
    // TODO: Fetch usergroup data?
    this.store.dispatch(fetchUsergroups(organisationId));
  }
}
