import React from "react";

function HorizontalRow({children}) {
  if (!children) {
    return null;
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "8px",
      }}
    >
      {children}
    </div>
  );
}

export default HorizontalRow;
