import Tooltip from "@material-ui/core/Tooltip";
import React, {useState} from "react";

import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RefreshIcon from "@material-ui/icons/Refresh";
import BugReportIcon from "@material-ui/icons/BugReport";
import {MenuItem} from "material-ui";
import IconButton from "material-ui/IconButton";

const styles = {
  menuButton: {
    padding: "0",
    height: "28px",
    width: "28px",
    marginTop: "2px",
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxHeight: "44px",
    width: "150px",
  },
  menuWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

const OptionsMenu = ({
  refreshDocumentAndTopicsData,
  clause: clausepart,
  clauseId,
  updateClausepartIsBadlyParsed,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isBadlyParsed, setIsBadlyParsed] = useState(
    clausepart.is_badly_parsed,
  );
  const handleOpen = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleRefresh = () => {
    refreshDocumentAndTopicsData();
    handleClose();
  };
  const handleBadlyParsed = () => {
    const flagIsBadlyParsed = !isBadlyParsed;
    setIsBadlyParsed(flagIsBadlyParsed);
    updateClausepartIsBadlyParsed(clauseId, clausepart.id, flagIsBadlyParsed);
    handleClose();
  };

  return (
    <div style={styles.menuWrapper}>
      <IconButton onClick={handleOpen} style={styles.menuButton}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleRefresh}>
          <div style={styles.menuItem}>
            <RefreshIcon />
            <p>Refresh</p>
          </div>
        </MenuItem>
        <MenuItem onClick={handleBadlyParsed}>
          <div style={styles.menuItem}>
            <BugReportIcon />
            <p>Is badly parsed</p>
          </div>
        </MenuItem>
      </Menu>
      <div>
        {isBadlyParsed ? (
          <Tooltip title="Badly parsed" arrow>
            <BugReportIcon style={{color: "red"}} />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

export default OptionsMenu;
