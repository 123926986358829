import React, {useState} from "react";
import {Link} from "react-router";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import StarRateIcon from "@material-ui/icons/StarRate";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import ConfirmDialog from "common_components/confirm_dialog";

import Context from "./context";
import Inputs from "./inputs";
import Tasks from "./tasks";
import Prerequistes from "./prerequisites";

import {OrganisationId} from "common/types/organisation";
import {Topic} from "common/types/topic";
import {
  Workflow,
  WorkflowInput,
  WorkflowInputId,
  Prompt,
  WorkflowContext,
  ContextId,
  WorkflowInstanceId,
  WorkflowInstanceDetail,
} from "common/flowmaster/types/workflow";
import {
  WorkflowTaskId,
  WorkflowTask,
  ActionDefinition,
} from "common/flowmaster/types/task_config";
import {Chip} from "@material-ui/core";

interface WorkflowDetailProps {
  organisationId: OrganisationId;
  workflow: Workflow;
  updateWorkflow: (WorkflowInfo) => void;
  duplicateWorkflow: () => void;
  deleteWorkflow: () => void;

  addWorkflowInput: (name) => void;
  updateWorkflowContext: (workflowContextId: ContextId) => void;
  updateWorkflowInput: (
    workflowInputId: WorkflowInputId,
    data: WorkflowInput,
  ) => void;
  removeWorkflowInput: (workflowInputId: WorkflowInputId) => void;
  reorderWorkflowInputs: (
    workflowInputId: WorkflowInputId,
    order: number,
  ) => void;

  addWorkflowTask: (name) => void;
  updateWorkflowTask: (
    workflowTaskId: WorkflowTaskId,
    data: WorkflowTask,
  ) => void;
  removeWorkflowTask: (workflowInputId: WorkflowInputId) => void;
  reorderWorkflowTasks: (
    id: WorkflowTaskId,
    movedItemId: WorkflowTaskId,
  ) => void;
  addPrerequisite: () => void;
  updatePrerequisiteInputValue: (
    id: WorkflowInstanceId,
    inputId: WorkflowInputId,
    value: string,
  ) => void;
  removePrerequisite: (id: WorkflowInstanceId) => void;
  updateWorkflowInstanceName: (id: WorkflowInstanceId, name: string) => void;
  actionDefinitions: ActionDefinition[];
  workflows: Workflow[];
  prompts: Prompt[];
  contexts: WorkflowContext[];
  embeddingTopics: Topic[];
  existingPrerequisites: WorkflowInstanceDetail[];
}

const WorkflowDetail: React.FC<WorkflowDetailProps> = ({
  organisationId,
  workflow,
  updateWorkflow,
  duplicateWorkflow,
  deleteWorkflow,
  updateWorkflowContext,
  addWorkflowInput,
  updateWorkflowInput,
  removeWorkflowInput,
  reorderWorkflowInputs,
  addWorkflowTask,
  updateWorkflowTask,
  removeWorkflowTask,
  reorderWorkflowTasks,
  addPrerequisite,
  updatePrerequisiteInputValue,
  removePrerequisite,
  updateWorkflowInstanceName,
  actionDefinitions,
  workflows,
  existingPrerequisites,
  prompts,
  contexts,
  embeddingTopics,
}) => {
  const selectedContext = contexts.find(
    ctx => ctx.id === workflow.context_id,
  ) as WorkflowContext;

  const [name, setName] = useState<string>("");
  const [showingDeleteDialog, showDeleteDialog] = useState<boolean>(false);
  const [showingDuplicateDialog, showDuplicateDialog] = useState<boolean>(
    false,
  );

  const setNameValue = event => setName(event.target.value);

  const save = async () => {
    if (name.length > 0) {
      await updateWorkflow({name});
      setName("");
    }
  };

  const onToggleUpdate = async (property: "is_star" | "is_archived") => {
    await updateWorkflow({[property]: !workflow[property]});
  };

  const onDelete = () => {
    deleteWorkflow();
  };

  const StarIcon = workflow.is_star ? StarRateIcon : StarBorderIcon;
  const ArchiveActionIcon = workflow.is_archived ? UnarchiveIcon : ArchiveIcon;

  return (
    <div
      style={{
        padding: "1em",
        display: "flex",
        alignItems: "flex-start",
        width: "calc(100% - 2em)",
      }}
    >
      <div style={{width: "100%"}}>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <h1>Workflow editor</h1>
          <TopMenu
            organisationId={organisationId}
            workflow={workflow}
            showDuplicateDialog={showDuplicateDialog}
          />
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: "1em"}}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                label="Name"
                value={name || workflow.name}
                onChange={setNameValue}
                onBlur={() => save()}
                style={{width: "30em"}}
              />
              <div
                style={{cursor: "pointer", marginRight: "1em"}}
                onClick={() => onToggleUpdate("is_star")}
              >
                <StarIcon
                  style={{
                    height: 40,
                    width: 40,
                    color: "#fbc02d",
                  }}
                />
              </div>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => onToggleUpdate("is_archived")}
              >
                <ArchiveActionIcon
                  style={{
                    height: 40,
                    width: 40,
                    color: "#3d3d3d",
                  }}
                />
              </div>
              {workflow.is_archived && (
                <Chip
                  label="Archived"
                  color="primary"
                  style={{marginRight: "1em"}}
                />
              )}
            </div>

            <Button variant="contained" onClick={() => showDeleteDialog(true)}>
              Delete
            </Button>
          </div>
          <Context
            selectedContext={workflow.context_id}
            items={contexts}
            onUpdate={updateWorkflowContext}
          />
          {selectedContext.prerequisite_workflow_contexts.length > 0 && (
            <Prerequistes
              organisationId={organisationId}
              existingPrerequisites={existingPrerequisites}
              prerequisites={workflow.prerequisites}
              workflows={workflows.filter(({context_id}) =>
                selectedContext.prerequisite_workflow_contexts.includes(
                  context_id,
                ),
              )}
              addPrerequisite={addPrerequisite}
              removePrerequisite={removePrerequisite}
              updateWorkflowInstanceName={updateWorkflowInstanceName}
              updatePrerequisiteInputValue={updatePrerequisiteInputValue}
            />
          )}
          <Inputs
            items={workflow.inputs.values}
            onAdd={addWorkflowInput}
            onUpdate={updateWorkflowInput}
            onDelete={removeWorkflowInput}
            onReorder={reorderWorkflowInputs}
          />
          <Tasks
            items={workflow.tasks.values}
            onAdd={addWorkflowTask}
            onUpdateItem={updateWorkflowTask}
            onDeleteItem={removeWorkflowTask}
            onReorder={reorderWorkflowTasks}
            workflows={workflows}
            actionDefinitions={actionDefinitions}
            context={selectedContext}
            inputs={workflow.inputs}
            prompts={prompts}
            organisationId={organisationId}
            embeddingTopics={embeddingTopics}
          />
        </div>
      </div>

      <ConfirmDialog
        open={showingDeleteDialog}
        onSuccess={onDelete}
        onClose={() => showDeleteDialog(false)}
        title="Delete workflow"
        description="Are you sure you want to delete this workflow?"
        okButtonCaption="Yes"
        cancelButtonCaption="No"
      />
      <ConfirmDialog
        open={showingDuplicateDialog}
        onSuccess={duplicateWorkflow}
        onClose={() => showDuplicateDialog(false)}
        title="Duplicate workflow"
        description="Are you sure you want to duplicate this workflow?"
        okButtonCaption="Yes"
        cancelButtonCaption="No"
      />
    </div>
  );
};

function TopMenu({organisationId, workflow, showDuplicateDialog}) {
  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <Button
        style={{marginRight: "2em"}}
        variant="contained"
        onClick={() => showDuplicateDialog(true)}
      >
        Duplicate
      </Button>
      <Link
        style={{
          fontWeight: "bold",
          width: "5em",
          flexShrink: 0,
          color: "inherit",
          textDecoration: "none",
          marginRight: "1em",
        }}
        to={`/organisation/${organisationId}/llm_run/list?workflow=${workflow.id}`}
      >
        Runs
      </Link>
      <Link
        style={{
          fontWeight: "bold",
          width: "5em",
          flexShrink: 0,
          color: "inherit",
          textDecoration: "none",
        }}
        to={`/organisation/${organisationId}/workflow/list`}
      >
        Back
      </Link>
    </div>
  );
}

export default WorkflowDetail;
