import React from "react";
import {Link} from "react-router";
import _ from "underscore";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import LinkIcon from "@material-ui/icons/Link";

import TextField from "../contained_text_field";
import Expando from "../expando";

import {
  Workflow,
  WorkflowInstanceDetail,
  WorkflowInputId,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

interface PrerequisteProps {
  organisationId: OrganisationId;
  prerequisite: WorkflowInstanceDetail;
  workflows: Workflow[];
  updatePrerequisiteInputValue: (
    inputId: WorkflowInputId,
    value: string,
  ) => void;
  removePrerequisite: () => void;
  updatWorkflowInstanceName: (workflowName: string) => void;
}

const Id = styled.span`
  border: 1px solid #999;
  background-color: #eee;
  border-radius: 0.5em;
  font-size: 0.75em;
  margin: 0.5em;
  padding: 1em;
`;
const Container = styled.div`
  border: 1px solid #aaa;
  margin: 0.5em;
  padding: 1em;
`;
const EditorContainer = styled.div`
  margin-top: 1em;
`;

const Prerequiste: React.FC<PrerequisteProps> = props => {
  return (
    <Container>
      {props.prerequisite.inputs.length ? (
        <PrerequisteWithInputs {...props} />
      ) : (
        <PrerequisiteSummary {...props} />
      )}
    </Container>
  );
};

const PrerequisteWithInputs: React.FC<PrerequisteProps> = props => {
  return (
    <Expando beginExpanded={true}>
      <PrerequisiteSummary {...props} />
      <PrerequisiteEditor {...props} />
    </Expando>
  );
};

const PrerequisiteSummary: React.FC<PrerequisteProps> = ({
  organisationId,
  prerequisite,
  workflows,
  removePrerequisite,
  updatWorkflowInstanceName,
}) => {
  const workflow = workflows.find(
    workflow => workflow.id === prerequisite.workflow_id,
  );

  return (
    <div style={{display: "flex", alignItems: "center", gap: "1em"}}>
      <Id>{prerequisite.id}</Id>
      <div
        style={{display: "flex", flexDirection: "row", alignItems: "center"}}
      >
        <div style={{fontWeight: "bold", width: "20em"}}>{workflow?.name}</div>
        <Link
          to={`/organisation/${organisationId}/workflow/${prerequisite.workflow_id}/detail`}
        >
          <IconButton onClick={() => removePrerequisite()}>
            <LinkIcon />
          </IconButton>
        </Link>
      </div>

      {prerequisite.inputs.length > 0 && (
        <TextField
          label="Prerequisite Name"
          initialValue={prerequisite.name || ""}
          onUpdate={updatWorkflowInstanceName}
          inputPropsStyle={{width: "20em"}}
        />
      )}
      <IconButton onClick={() => removePrerequisite()}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

const PrerequisiteEditor: React.FC<PrerequisteProps> = props => {
  return (
    <div>
      <PrerequisiteSummary {...props} />
      <EditorContainer>
        {_.sortBy(props.prerequisite.inputs, "order").map(input => (
          <TextField
            key={input.id}
            label={input.name}
            initialValue={
              props.prerequisite.config?.inputs?.values?.[input.id] ?? ""
            }
            onUpdate={value =>
              props.updatePrerequisiteInputValue(input.id, value)
            }
          />
        ))}
      </EditorContainer>
    </div>
  );
};

export default Prerequiste;
