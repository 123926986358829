import requestor from "requestor";
import BaseRoute from "utils/base_route";

import issuesInfoFetchAction from "modules/documents/actions/issues_info_fetch";
import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";
import contractTypesClearAction from "modules/documents/actions/contract_types_clear";

import PlaybooksContainer from "./containers/playbooks";

export default class extends BaseRoute {
  constructor(store) {
    super("playbooks", store);
  }

  getComponents(location, cb) {
    cb(null, PlaybooksContainer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchContractTypes(state);
    this.fetchIssues(state);
  }

  clearActiveData(state) {
    this.clearContractTypes(state);
  }

  fetchContractTypes(state) {
    const {organisationId} = state.params;
    this.store.dispatch(
      contractTypesFetchAction(requestor)(
        organisationId,
        true, // useHiddenIssues
      ),
    );
  }

  fetchIssues(state) {
    const {organisationId} = state.params;
    this.store.dispatch(issuesInfoFetchAction(requestor)(organisationId));
  }

  clearContractTypes(state) {
    const {organisationId} = state.params;
    this.store.dispatch(contractTypesClearAction(organisationId));
  }
}
