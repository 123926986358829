import React from "react";
import styled from "styled-components";

import {List as DefaultList} from "material-ui/List";
import Subheader from "material-ui/Subheader";
import * as colors from "material-ui/styles/colors";

const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid ${colors.grey300};

  display: flex;
  flex-direction: column;
`;

const Header = styled(Subheader)`
  height: 48px;
  border-bottom: 1px solid ${colors.grey300};
`;

const List = styled(DefaultList)`
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
`;

type ColumnProps = {
  title: string;
  children: React.ReactNode;
};

const Column = ({children, title}: ColumnProps) => (
  <Container>
    <Header>{title}</Header>
    <List>{children}</List>
  </Container>
);

export default Column;
