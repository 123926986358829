import React from "react";
import styled from "styled-components";

import Fieldset from "./styled/fieldset";
import Legend from "./styled/legend";

import IconButton from "@material-ui/core/IconButton";
import {Link as LinkIcon} from "@material-ui/icons";
import {Link} from "react-router";

import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";
import {OrganisationId} from "common/types/organisation";

const ContextKeyCell = styled.td`
  text-transform: capitalize;
  font-weight: bold;
  border-right: 1px solid #ddd;
  padding: 0 1em;
`;
const ValueCell = styled.td`
  padding-left: 1em;
  text-align: right;
`;

interface Props {
  organisationId: OrganisationId;
  llmRun: EnhancedLlmRun;
}

export default function Context(props: Props) {
  return (
    <Fieldset>
      <Legend>Context</Legend>
      <div style={{display: "flex"}}>
        <ContextTable {...props} />
        <LinksTable {...props} />
      </div>
    </Fieldset>
  );
}

function ContextTable({organisationId, llmRun}: Props) {
  return (
    <table>
      <tbody>
        {Object.entries(llmRun.data.data_store.context).map(([key, item]) => (
          <tr key={key}>
            <ContextKeyCell>{key.replace(/_/g, " ")}</ContextKeyCell>
            <ValueCell>{item}</ValueCell>
            <td>
              {renderLink(organisationId, key, llmRun.data.data_store.context)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function LinksTable({organisationId, llmRun}: Props) {
  return (
    <table>
      <tbody>
        {Object.entries(llmRun.links ?? {})
          .filter(link => !llmRun.data.data_store.context[link[0]])
          .map(([key, item]) => (
            <tr key={key}>
              <ContextKeyCell>{key.replace(/_/g, " ")}</ContextKeyCell>
              <ValueCell>{item}</ValueCell>
              <td>
                {renderLink(organisationId, key, {
                  ...llmRun.links,
                  ...llmRun.data.data_store.context,
                })}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

function renderLink(organisationId, key, context) {
  const href = getHref(organisationId, key, context);
  if (href) {
    return (
      <Link to={href}>
        <IconButton size="small" style={{padding: 0}}>
          <LinkIcon />
        </IconButton>
      </Link>
    );
  }
}

function getHref(organisationId, key, context) {
  if (key === "project_id") {
    return `/organisation/${organisationId}/project/${context.project_id}/document/list`;
  }
  if (key === "document_id") {
    return `/organisation/${organisationId}/project/${context.project_id}/document/${context.document_id}/detail`;
  }
  if (key === "issue_id") {
    return `/organisation/${organisationId}/issue/${context.issue_id}?open_issue_list=true&project=${context.project_id}&document=${context.document_id}`;
  }
  if (key === "parent_run_id") {
    return `/organisation/${organisationId}/llm_run/${context.parent_run_id}/detail`;
  }
  return null;
}
