import React from "react";
import copy from "copy-to-clipboard";

import CopyIcon from "material-ui/svg-icons/content/content-copy";
import DoneIcon from "material-ui/svg-icons/action/done";
import * as colors from "material-ui/styles/colors";

const styles = {
  iconButton: {
    height: "14px",
    width: "14px",
    fill: "green",
    position: "absolute",
    left: "-21px",
  },
  textDiv: {
    fontSize: "12px",
    color: colors.grey600,
    marginRight: "0.5rem",
    lineHeight: "16px",
  },
};

class TemplatedText extends React.Component {
  state = {
    textCopied: false,
  };

  render() {
    return (
      <div style={{position: "relative"}}>
        {this.renderCopyToClipboardButton()}
        <div style={styles.textDiv}>{this.props.text}</div>
      </div>
    );
  }

  renderCopyToClipboardButton = () => {
    if (this.props.isParentHovered) {
      if (this.state.textCopied) {
        return (
          <DoneIcon
            style={{...styles.iconButton, fill: colors.lightGreen600}}
          />
        );
      }
      return (
        <CopyIcon
          style={{
            ...styles.iconButton,
            cursor: "pointer",
            fill: colors.blue800,
          }}
          onClick={this.onCopyClick}
        />
      );
    }
    return null;
  };

  onCopyClick = () => {
    copy(this.props.text, {format: "text/plain"});
    const {setState} = this;
    this.setState(
      () => ({textCopied: true}),
      () => setTimeout(() => setState(() => ({textCopied: false})), 1000),
    );
  };
}

export default TemplatedText;
