import createTransition from "utils/create_transition";
import getRouteIds from "utils/get_route_ids";

const transition = createTransition(stateObserver => {
  return stateObserver.distinctUntilChanged(
    state => getRouteIds(state.router).taskId,
  );
});

export default transition.getTransition;
export const reset = transition.resetAll;
