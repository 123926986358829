import React, {useEffect, useState} from "react";
import {Button, Paper} from "@material-ui/core";
import _ from "underscore";

import WorkflowSelector from "./workflow_selector";
import getCopiedWorkflowInputValues from "../utils/get_copied_workflow_input_values";
import * as InputEditor from "common_components/flowmaster/input_editors";

import {
  Workflow,
  WorkflowId,
  WorkflowInputs as WorkflowInputsType,
} from "common/flowmaster/types/workflow";
import {Issue} from "common/types/issue";
import {OrganisationId} from "common/types/organisation";

interface WorkflowInputsProps {
  inputs: WorkflowInputsType;
  selectedWorkflow: Workflow;
  selectedWorkflowId: WorkflowId;
  updateWorkflowSettings: (updatedSettings: WorkflowInputsType) => void;
  organisationId: OrganisationId;
  workflows: Workflow[];
  workflowSettings: Issue["llm_settings"]["workflow_settings"];
}

const styles: Record<string, React.CSSProperties> = {
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  cardHeader: {
    paddingLeft: "2rem",
    fontSize: "17px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  placeholder: {
    padding: "2rem",
    background: "rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
  },
};

const WorkflowInputs = ({
  inputs,
  selectedWorkflow,
  selectedWorkflowId,
  updateWorkflowSettings,
  organisationId,
  workflows,
  workflowSettings,
}: WorkflowInputsProps) => {
  const [workflowToCopy, setWorkflowToCopy] = useState<WorkflowId>(() => {
    const savedWorkflowToCopy = sessionStorage.getItem("workflowToCopy");
    return savedWorkflowToCopy ? Number(savedWorkflowToCopy) : -1;
  });
  const [tempInputs, setTempInputs] = useState(inputs);

  useEffect(() => {
    // sync new values when switching issues
    setTempInputs(inputs);
  }, [inputs]);

  const handleInputChange = (name, newValue) => {
    const newTempInputs = {...tempInputs, [name]: newValue};
    setTempInputs(newTempInputs);
  };

  const handleInputBlur = () => {
    if (!_.isEqual(inputs, tempInputs)) {
      updateWorkflowSettings(tempInputs);
    }
  };

  const handleSelectCopyWorkflowInputs = workflowId => {
    sessionStorage.setItem("workflowToCopy", workflowId.toString());
    setWorkflowToCopy(workflowId);
  };

  const handleCopyInputs = () => {
    if (workflowToCopy === -1) {
      return;
    }

    const inputsToCopy =
      workflowSettings?.config?.[workflowToCopy]?.inputs.values ?? {};

    const updates = getCopiedWorkflowInputValues(
      workflows,
      workflowToCopy,
      inputs,
      inputsToCopy,
      selectedWorkflow,
    );

    if (!_.isEqual(inputs, updates)) {
      updateWorkflowSettings(updates);
    }
  };

  const currentWorkflowInputNames = selectedWorkflow?.inputs?.values?.map(
    input => input.name,
  );

  const copyInputWorkflowOptions = workflows.filter(
    wf =>
      wf.id !== selectedWorkflowId &&
      wf.inputs?.values?.some(input =>
        currentWorkflowInputNames?.includes(input.name),
      ),
  );

  return (
    <Paper style={styles.paper}>
      <div className="app-toolbar" style={styles.cardHeader}>
        <div>Workflow Inputs</div>
        {copyInputWorkflowOptions.length > 0 &&
          selectedWorkflow?.inputs.values.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <WorkflowSelector
                workflows={copyInputWorkflowOptions}
                selectedWorkflowId={workflowToCopy}
                organisationId={organisationId}
                updateselectedWorkflowId={handleSelectCopyWorkflowInputs}
                contextTypeId={1}
                label="Copy values from"
              />

              <Button
                style={{marginLeft: "1rem"}}
                variant="contained"
                onClick={handleCopyInputs}
              >
                Copy
              </Button>
            </div>
          )}
      </div>
      <div style={{padding: "2rem", display: "flex", flexDirection: "column"}}>
        {selectedWorkflow?.inputs.values.map(input => {
          const Component = InputEditor[input.type].component;
          return (
            <Component
              key={input.id}
              input={input}
              value={tempInputs[input.id] || ""}
              handleInputChange={(e: React.ChangeEvent<{value: string}>) =>
                handleInputChange(input.id, e.target.value)
              }
              handleInputBlur={handleInputBlur}
              style={{marginBottom: "1em"}}
            />
          );
        })}
      </div>
    </Paper>
  );
};

export default WorkflowInputs;
