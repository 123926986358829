import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function addOrganisationUser(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.ORGANISATION_USER_ADD,
    (organisationId, newOrganisationUserId, newOrganisationUserData) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/config/users/new`,
          newOrganisationUserData,
          {withCredentials: true},
        )
        .then(response => ({...response.data, ...newOrganisationUserData}));
    },
  );
  return action;
}
