import _ from "underscore";
import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import requestor from "requestor";

import {replace} from "react-router-redux";

import projectTaskCreateAction from "modules/tasks/actions/project_task_create";
import projectTaskReorderAction from "modules/tasks/actions/project_tasks_reorder";
import taskDeleteAction from "modules/tasks/actions/task_delete";

import TaskList from "../components/task_list";

class ProjectTasks extends React.Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return this.permissioner.hasPermission("get-tasks");
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.tasks,
      this.props.project,
      this.props.user,
    ]);
  }

  renderComponent() {
    return (
      <div className="app-container">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {this.renderProjectTasks()}
          {this.props.children}
        </div>
      </div>
    );
  }

  renderProjectTasks() {
    setTitle(`${this.props.project.name} Tasks`);
    const highlightedTaskId = parseInt(this.props.params.taskId, 10);
    return (
      <TaskList
        tasks={this.getTasks()}
        highlightedTaskId={highlightedTaskId}
        onTasksReordered={this.reorderTasks}
        onTaskSelected={this.selectTask}
        onTaskCreated={this.createTask}
        onTaskDeleted={this.deleteTask}
      />
    );
  }

  getTasks() {
    if (this.taskOrderSame(this.props.tasks)) {
      return this.props.tasks.map((task, index) => {
        return {...task, order: index + 1};
      });
    }
    return this.props.tasks;
  }

  taskOrderSame(tasks) {
    const taskOrders = {};
    const tasksLength = tasks.length;
    for (let index = 0; index < tasksLength; index += 1) {
      const task = tasks[index];
      if (taskOrders[task.order]) {
        return true;
      }
      taskOrders[task.order] = 1;
    }
    return false;
  }

  /* eslint-disable no-invalid-this */
  selectTask = taskId => {
    const {organisationId, projectId} = this.props.params;

    this.props.dispatch(
      replace(
        `/organisation/${organisationId}/project/${projectId}/task/${taskId}`,
      ),
    );
  };

  createTask = () => {
    const {organisationId, projectId} = this.props.params;
    this.props.projectTaskCreateAction(
      organisationId,
      projectId,
      this.props.tasks.length,
    );
  };

  deleteTask = (taskId, lastEdited) => {
    const {organisationId} = this.props.params;
    this.props.taskDeleteAction(organisationId, taskId, lastEdited);
  };

  reorderTasks = (sourceOrder, targetOrder) => {
    const {organisationId, projectId} = this.props.params;
    const data = _.chain(this.props.tasks)
      .sortBy(task => task.order)
      .map(task => [task.id, _.pick(task, ["order", "last_edited"])])
      .value();

    data.splice(targetOrder - 1, 0, data.splice(sourceOrder - 1, 1)[0]);
    data.forEach((value, index) => {
      value[1].order = index + 1;
    });

    this.props.projectTaskReorderAction(
      organisationId,
      projectId,
      _.object(data),
    );
  };
  /* eslint-enable no-invalid-this */
}

function select(state, props) {
  return {
    params: props.params,
    project: state.project,
    tasks: state.projectTasks,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        projectTaskReorderAction: projectTaskReorderAction(requestor),
        projectTaskCreateAction: projectTaskCreateAction(requestor),
        taskDeleteAction: taskDeleteAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(ProjectTasks);
export const Component = ProjectTasks;
