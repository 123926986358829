import React, {useState, useEffect} from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import capitalize from "utils/capitalize";

const styles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    padding: "0em 1em",
    display: "flex",
    flexDirection: "column",
  },
  cell: {
    padding: "12px 0px",
  },
  row: {
    borderBottom: "1px solid #e6eaf0",
  },
  errorIcon: {
    width: "20px",
    height: "20px",
    color: "#ef5350",
  },
};

function constructBaseDocumentValues(fields) {
  return fields.reduce((result, field) => {
    result[field.key] = undefined;
    return result;
  }, {});
}

function createField(label, value, onFieldChange) {
  return (
    <TextField
      name={label}
      label={label}
      onChange={onFieldChange}
      value={value || ""}
      style={{
        borderRadius: "2px",
        width: "100%",
      }}
    />
  );
}

function createRadioButtonsGroup(value, onChange) {
  const onYesCheck = e => {
    if (value === undefined && e.target.checked) {
      onChange(true);
    } else {
      onChange(!value);
    }
  };

  const onNoCheck = e => {
    if (value === undefined && e.target.checked) {
      onChange(false);
    } else {
      onChange(!value);
    }
  };

  return (
    <RadioGroup row>
      <FormControlLabel
        checked={value === undefined ? false : value}
        control={<Radio onChange={onYesCheck} color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        checked={value === undefined ? false : !value}
        control={<Radio onChange={onNoCheck} color="primary" />}
        label="No"
      />
    </RadioGroup>
  );
}

function createSelectField(label, value, values, onChange) {
  return (
    <FormControl
      style={{
        minWidth: "12rem",
        borderRadius: "2px",
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value || ""}
        onChange={onChange}
        MenuProps={{PaperProps: {style: {maxHeight: "14rem"}}}}
      >
        {values.map(value => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function DocumentCreate(props) {
  const {documents, project} = props;
  const {template_settings: templateSettingsRaw = "{}"} = project;
  const templateSettings = JSON.parse(templateSettingsRaw);
  const templateDocument = documents.find(doc => doc.is_template);
  const {fields: fieldsRaw = []} = templateSettings;
  const fields = fieldsRaw.filter(
    field => field.type && field.key && field.text,
  );

  const [documentName, onDocumentNameUpdate] = useState(
    constructDocumentName(project, documents.length, templateDocument),
  );
  const [errors, onErrorsUpdate] = useState({});
  const [documentValues, onDocumentValuesUpdate] = useState(
    constructBaseDocumentValues(fields),
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(
    () => {
      if (Object.keys(errors).length === 0 && isSubmitting) {
        const data = {
          name: documentName,
          template_values: {values: documentValues},
          template_document_id: templateDocument.id,
        };
        props.createDocument(data);
      } else if (isSubmitting) {
        setIsSubmitting(false);
      }
    },
    [errors],
  );

  function onDocumentNameChange(e) {
    const {value} = e.target;
    if (errors.documentName && value !== "" && value !== undefined) {
      const newErrors = {...errors};
      delete newErrors.documentName;
      onErrorsUpdate(newErrors);
    }
    onDocumentNameUpdate(e.target.value);
  }

  function onDocumentValueChange(key, value) {
    if (errors[key] && value !== "" && value !== undefined && value !== null) {
      const newErrors = {...errors};
      delete newErrors[key];
      onErrorsUpdate(newErrors);
    }
    onDocumentValuesUpdate({
      ...documentValues,
      [key]: value,
    });
  }

  if (fields.length === 0) {
    return (
      <div className="blank-message">
        PLEASE FILL TEMPLATE SETTINGS ON PROJECT PAGE TO BEGIN
      </div>
    );
  } else if (!templateDocument) {
    return (
      <div className="blank-message">
        PLEASE UPLOAD TEMPLATE DOCUMENT ON DOCUMENTS LIST PAGE TO BEGIN
      </div>
    );
  }

  function getField(field) {
    const {key, name, type, values} = field;
    const label = name || capitalize(key);
    const value = documentValues[key];
    switch (type) {
      case "text": {
        const onFieldChange = e => onDocumentValueChange(key, e.target.value);
        return createField(label, value, onFieldChange);
      }
      case "boolean": {
        const onCheck = value => onDocumentValueChange(key, value);
        return createRadioButtonsGroup(value, onCheck);
      }
      case "enum": {
        const onChange = e => onDocumentValueChange(key, e.target.value);
        return createSelectField(label, value, values, onChange);
      }
      default:
        return null;
    }
  }

  function renderField(field, fieldIndex) {
    return (
      <tr style={styles.row} key={fieldIndex}>
        <td style={styles.cell}>
          {errors[field.key] ? (
            <ErrorOutlineIcon style={styles.errorIcon} />
          ) : null}
        </td>
        <td style={styles.cell}>{field.text}</td>
        <td style={styles.cell}>{getField(field)}</td>
      </tr>
    );
  }

  function validateBeforeCreation() {
    const newErrors = {};
    Object.keys(documentValues).forEach(fieldKey => {
      const value = documentValues[fieldKey];
      if (value === undefined || value === "") {
        newErrors[fieldKey] = true;
      }
    });
    if (!documentName) {
      newErrors.documentName = true;
    }
    onErrorsUpdate(newErrors);
  }

  function onDocumentCreate() {
    validateBeforeCreation();
    setIsSubmitting(true);
  }

  return (
    <div className="app-page">
      <AppBar className="appbar">
        <Toolbar className="app-toolbar-v4">
          <Typography className="app-toolbar-title">Create Contract</Typography>
          <div style={{display: "flex", flexGrow: 1}} />
          <Button
            variant="contained"
            color="primary"
            onClick={onDocumentCreate}
          >
            Create
          </Button>
        </Toolbar>
      </AppBar>
      <div className="app-body" style={styles.container}>
        <table style={{width: "62rem", borderCollapse: "collapse"}}>
          <thead>
            <tr style={{height: "2rem"}}>
              <th style={{width: "2rem", textAlign: "left"}} />
              <th style={{width: "30rem", textAlign: "left"}}>Purpose</th>
              <th style={{width: "30rem", textAlign: "left"}}>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr style={styles.row} key="create-contract-name">
              <td style={styles.cell}>
                {errors["documentName"] ? (
                  <ErrorOutlineIcon style={styles.errorIcon} />
                ) : null}
              </td>
              <td style={styles.cell}>Contract Name</td>
              <td style={styles.cell}>
                {createField("Name", documentName, onDocumentNameChange)}
              </td>
            </tr>
            {fields.map((field, fieldIndex) => renderField(field, fieldIndex))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function constructDocumentName(
  project,
  documentsLength,
  templateDocument = {},
) {
  const docNumber = documentsLength === 0 ? 0 : documentsLength - 1;
  const extension = templateDocument.file_extension
    ? `.${templateDocument.file_extension}`
    : "";
  return `${project.name}_document_${docNumber}${extension}`;
}

export default DocumentCreate;
