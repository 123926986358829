import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function initClassifiers(requestor) {
  return createReduxPromiseAction(
    ActionTypes.CLASSIFIERS_FETCH,
    organisationId =>
      requestor
        .get(`${basePath}/organisation/${organisationId}/classifier`, {
          withCredentials: true,
        })
        .then(response => [...response.data]),
  );
}
