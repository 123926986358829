import React from "react";
import TaskSelector from "../string/task_selector";
import {
  WorkflowTask,
  ActionDefinition,
  TaskSelectorArgument,
} from "common/flowmaster/types/task_config";

interface TaskArgEditorProps {
  argValue: TaskSelectorArgument;
  priorTasks: WorkflowTask[];
  futureTasks: WorkflowTask[];
  actionDefinitions: ActionDefinition[];
  onUpdateItem: (value: TaskSelectorArgument) => void;
  mode: "past" | "future";
}

export const TaskArgEditor: React.FC<TaskArgEditorProps> = ({
  argValue,
  priorTasks,
  futureTasks,
  mode,
  onUpdateItem,
}) => {
  const taskArg = argValue?.value;
  const tasks = mode === "past" ? priorTasks : futureTasks;
  return (
    <div style={{display: "flex", gap: "1em", width: "100%"}}>
      <TaskSelector
        task={taskArg}
        tasks={tasks}
        onSetTask={task => onUpdateItem({...argValue, value: task})}
      />
    </div>
  );
};

const PastTaskArg = {
  Component: props => <TaskArgEditor {...props} mode="past" />,
  default: {value: -1},
};

const FutureTaskArg = {
  Component: props => <TaskArgEditor {...props} mode="future" />,
  default: {value: -1},
};

export {PastTaskArg, FutureTaskArg};
