import React from "react";
import _ from "lodash";

import * as jsDiff from "diff";

import deleteNestedObjectsIfEmpty from "common/utils/deleteNestedObjectsIfEmpty";
import setNestedObjects from "common/utils/setNestedObjects";

const commonMethods = {
  validate: () => true,
  handleLoadedValue: value => value,
  handleChangedValue: value => value,
  getValueFrom: (fieldName, fields) => {
    return _.get(fields, fieldName);
  },
  setValueTo: (fieldName, fields, value) => {
    _.set(fields, fieldName, value);
  },
  deleteValueFrom: (fieldName, fields, isOverride = false) => {
    if (!isOverride) {
      fields[fieldName] = null;
    } else {
      delete fields[fieldName];
    }
  },
};

const GREY_COLOR_FOR_OVERRIDDEN = "#bdbdbd";

// TODO: Annotate types for props and state
export default class OverridableIssueFields<
  P = unknown,
  S = unknown
> extends React.Component<P, S> {
  getOverridableIssue() {
    return this.props.issue;
  }

  static getOverrideModeData(selectedIssueset /* , issue*/) {
    const data = {};
    data.isActive = Boolean(selectedIssueset);
    if (data.isActive) {
      const {
        master_id: masterId,
        remote_client_id: clientId,
      } = selectedIssueset;
      const clientPath = `${masterId}.${clientId}`;
      const masterIssuesetPath = `${masterId}.master`;
      if (selectedIssueset.is_duplicate_on_master) {
        data.dataPath = clientPath;
        data.parentDataPaths = [masterIssuesetPath];
      } else {
        data.dataPath = masterIssuesetPath;
        data.parentDataPaths = [];
      }
    }
    return data;
  }

  getOverrideModeData() {
    return this.constructor.getOverrideModeData(
      !this.getIsBaseIssue() && this.state.selectedIssueset,
      this.getOverridableIssue(),
    );
  }

  isOverrideModeActive() {
    return !this.getIsBaseIssue() && this.getOverrideModeData().isActive;
  }

  getOverridableFieldValue(fieldName, fieldMethods) {
    return this.getOverridableFieldData(null, fieldName, fieldMethods).value;
  }

  getDataValue(object, fieldName, paths) {
    for (const path of paths) {
      const dataset = _.get(object, path);
      if (dataset) {
        const value = _.get(dataset, fieldName);
        if (value !== null && value !== undefined) {
          return value;
        }
      }
    }
    return null;
  }

  getBaseValue(
    getValueFrom,
    fieldName,
    parentDataPaths,
    shouldUseProposedChanges,
  ) {
    const fieldsToUse = shouldUseProposedChanges
      ? this.state.proposedChanges
      : this.state.overridableFields;
    let value = getValueFrom(fieldsToUse.main);
    if (parentDataPaths) {
      const parentValue = this.getDataValue(
        fieldsToUse.overridden,
        fieldName,
        parentDataPaths,
      );
      if (!_.isNull(parentValue) && !_.isUndefined(parentValue)) {
        value = parentValue;
      }
    }
    return value;
  }

  getProposedFieldValue(fieldName, fieldMethods = {}, emptyValue) {
    const methods = {...commonMethods, ...fieldMethods};
    const getValueFrom = methods.getValueFrom.bind(null, fieldName);

    const {isActive: isOverrideMode, dataPath} = this.getOverrideModeData();

    const {proposedChanges} = this.state;
    let value;
    if (isOverrideMode) {
      const proposedOverriddenValue = this.getDataValue(
        proposedChanges.overridden,
        fieldName,
        [dataPath],
      );

      if (
        proposedOverriddenValue !== null &&
        proposedOverriddenValue !== undefined
      ) {
        value = proposedOverriddenValue;
      } else if (
        (proposedOverriddenValue === null ||
          proposedOverriddenValue === undefined) &&
        emptyValue !== undefined
      ) {
        value = emptyValue;
      }
    } else {
      value = getValueFrom(proposedChanges.main);
    }

    let isProposedTakenFromOriginal = false;
    if (!value) {
      value = this.getFieldValue(fieldName, fieldMethods, emptyValue).value;
      isProposedTakenFromOriginal = true;
    }

    return {value, isProposedTakenFromOriginal};
  }

  getFieldValue(fieldName, fieldMethods = {}, emptyValue) {
    const methods = {...commonMethods, ...fieldMethods};
    const getValueFrom = methods.getValueFrom.bind(null, fieldName);
    const {
      isActive: isOverrideMode,
      dataPath,
      parentDataPaths,
    } = this.getOverrideModeData();

    let value = this.getBaseValue(getValueFrom, fieldName, parentDataPaths);
    let isOverridden = false;

    if (isOverrideMode) {
      const overriddenValue = this.getDataValue(
        this.state.overridableFields.overridden,
        fieldName,
        [dataPath],
      );
      if (overriddenValue !== null && overriddenValue !== undefined) {
        isOverridden = true;
        value = overriddenValue;
      } else if (
        (overriddenValue === null || overriddenValue === undefined) &&
        emptyValue !== undefined
      ) {
        isOverridden = true;
        value = emptyValue;
      }
    }
    return {value, isOverridden};
  }

  getOverridableFieldData(
    fieldType,
    fieldName,
    fieldMethods,
    emptyValue,
    useProposedChanges,
  ) {
    const methods = {...commonMethods, ...fieldMethods};
    const {validate, handleLoadedValue, handleChangedValue} = methods;
    const getValueFrom = _.curry(methods.getValueFrom)(fieldName);
    const setValueTo = _.curry(methods.setValueTo)(fieldName);
    const deleteValueFrom = _.curry(methods.deleteValueFrom)(fieldName);

    const shouldUseProposedChanges =
      useProposedChanges && this.state.shouldUseProposedChanges;
    const {
      isActive: isOverrideMode,
      dataPath,
      parentDataPaths,
    } = this.getOverrideModeData();

    const baseValue = this.getBaseValue(
      getValueFrom,
      fieldName,
      parentDataPaths,
      shouldUseProposedChanges,
    );

    const updateState = (val, callback) => {
      const fieldsToUse = shouldUseProposedChanges
        ? this.state.proposedChanges
        : this.state.overridableFields;
      const overridableFields = _.cloneDeep(fieldsToUse);

      if (shouldUseProposedChanges) {
        if (!isOverrideMode) {
          if (!overridableFields.main) {
            overridableFields.main = {};
          }
          setValueTo(overridableFields.main, val);
        } else {
          if (!_.has(overridableFields.overridden, dataPath)) {
            setNestedObjects(overridableFields.overridden, dataPath);
          }
          setValueTo(_.get(overridableFields.overridden, dataPath), val);
        }
      } else {
        if (!isOverrideMode) {
          setValueTo(overridableFields.main, val);
        } else if (
          !_.isEqual(
            val,
            this.getBaseValue(
              getValueFrom,
              fieldName,
              parentDataPaths,
              useProposedChanges,
            ),
          )
        ) {
          if (!_.has(overridableFields.overridden, dataPath)) {
            setNestedObjects(overridableFields.overridden, dataPath);
          }
          setValueTo(_.get(overridableFields.overridden, dataPath), val);
        } else if (_.has(overridableFields.overridden, dataPath)) {
          deleteValueFrom(_.get(overridableFields.overridden, dataPath), true);
        }
      }

      this.setState(
        () =>
          shouldUseProposedChanges
            ? {proposedChanges: overridableFields}
            : {overridableFields},
        callback,
      );
      return overridableFields;
    };

    const storeUpdate = (
      _overridableFieldsBase,
      storeToOverridesForcefully,
    ) => {
      const overridableFieldsBase = _overridableFieldsBase
        ? _overridableFieldsBase
        : shouldUseProposedChanges
        ? this.state.proposedChanges
        : this.state.overridableFields;
      let value;
      const overridableFields = _.cloneDeep(overridableFieldsBase);
      const stateUpdates = {};
      if (!isOverrideMode) {
        value = getValueFrom(overridableFields.main);
        if (_.isString(value) && !value.trim().length) {
          value = null;
          deleteValueFrom(overridableFields.main);
        }
        const overridableIssue = this.getOverridableIssue();
        if (
          !_.isEqual(
            value,
            getValueFrom(
              shouldUseProposedChanges
                ? shouldUseProposedChanges.proposed_changes
                : overridableIssue,
            ),
          ) &&
          validate(value)
        ) {
          let updateValue = {};
          if (shouldUseProposedChanges && !storeToOverridesForcefully) {
            updateValue.proposed_changes = this.constructDbProposedChanges(
              overridableFields,
            );
          } else {
            updateValue = _.set({}, fieldName, value);
          }
          if (shouldUseProposedChanges && storeToOverridesForcefully) {
            // case when click on confirm button when NOT in override mode and suggest changes = ON
            const proposedChanges = this.constructDbProposedChanges();
            delete proposedChanges.main[fieldName];
            updateValue.proposed_changes = proposedChanges;
            stateUpdates.proposedChanges = this.constructStateProposedChanges(
              proposedChanges,
            );
          }

          this.onOverridableIssueFieldUpdate(updateValue);
        }
      } else {
        value = getValueFrom({
          ..._.get(overridableFields.overridden, dataPath),
        });

        if (_.isString(value)) {
          if (value === baseValue && !shouldUseProposedChanges) {
            value = undefined;
            deleteValueFrom(
              _.get(overridableFields.overridden, dataPath),
              true,
            );
          }
        }
        deleteNestedObjectsIfEmpty(overridableFields.overridden, dataPath);
        const overridableIssue = this.getOverridableIssue();
        const issueValue = getValueFrom({
          ..._.get(
            shouldUseProposedChanges
              ? shouldUseProposedChanges.proposed_changes
              : overridableIssue.override_values,
            dataPath,
          ),
        });
        if (!_.isEqual(value, issueValue) && validate(value)) {
          let updateValue;
          if (shouldUseProposedChanges && !storeToOverridesForcefully) {
            updateValue = {
              proposed_changes: this.constructDbProposedChanges(
                overridableFields,
              ),
            };
          } else {
            updateValue = {
              overrideValueData: {
                dataPath,
                fieldName,
                fieldValue: getValueFrom(
                  {..._.get(overridableFields.overridden, dataPath)},
                  true,
                ),
              },
            };
          }

          if (shouldUseProposedChanges && storeToOverridesForcefully) {
            // case when click on confirm button when in override mode and suggest changes = ON
            const proposedChanges = this.constructDbProposedChanges();
            deleteValueFrom(_.get(proposedChanges, dataPath));
            updateValue.proposed_changes = proposedChanges;
            stateUpdates.proposedChanges = this.constructStateProposedChanges(
              proposedChanges,
            );
          }

          this.onOverridableIssueFieldUpdate(updateValue);
        }
      }

      if (shouldUseProposedChanges && !storeToOverridesForcefully) {
        stateUpdates.proposedChanges = overridableFields;
      } else {
        stateUpdates.overridableFields = overridableFields;
      }

      this.setState(() => stateUpdates);
    };

    const fieldValueArgs = [fieldName, fieldMethods, emptyValue];
    const fieldValues = shouldUseProposedChanges
      ? this.getProposedFieldValue(...fieldValueArgs)
      : this.getFieldValue(...fieldValueArgs);

    let {value} = fieldValues;
    const {isOverridden} = fieldValues;

    // clearProps
    const clearProps = {};
    clearProps.onClick = () => {
      const overridableFields = updateState(handleChangedValue(null));
      storeUpdate(overridableFields);
    };

    clearProps.disabled = shouldUseProposedChanges
      ? fieldValues.isProposedTakenFromOriginal || !value
      : !(isOverrideMode && isOverridden);

    // confirmProps
    const confirmProps = {};
    let overrideValue;
    if (shouldUseProposedChanges) {
      overrideValue = this.getFieldValue(fieldName).value;
      confirmProps.onClick = () => storeUpdate(null, true);
      confirmProps.disabled = fieldValues.isProposedTakenFromOriginal || !value;
    }

    // general props
    const props = {};
    const color = shouldUseProposedChanges
      ? fieldValues.isProposedTakenFromOriginal
        ? GREY_COLOR_FOR_OVERRIDDEN
        : "black"
      : !isOverrideMode || isOverridden
      ? "black"
      : GREY_COLOR_FOR_OVERRIDDEN;

    switch (fieldType) {
      case "TextField":
        props.textareaStyle = {color};
        props.inputStyle = {color};
        props.className = shouldUseProposedChanges
          ? fieldValues.isProposedTakenFromOriginal
            ? "notoverridden"
            : ""
          : !isOverrideMode || isOverridden
          ? ""
          : "notoverridden";
        props.onChange = event => {
          updateState(handleChangedValue(event.target.value));
        };
        props.onBlur = () => storeUpdate();
        break;

      case "SelectField":
        props.labelStyle = {color};
        props.onChange = (event, index, val) => {
          updateState(handleChangedValue(val), storeUpdate);
        };
        break;
      case "SelectFieldV4":
        props.onChange = event => {
          updateState(handleChangedValue(event.target.value), storeUpdate);
        };
        props.SelectDisplayProps = {style: {color}};
        break;
      case "CustomField":
        props.onChange = val => {
          updateState(handleChangedValue(val), storeUpdate);
        };
        break;
      case "Checkbox":
      case "Switch":
        // this is Material-UI v4 signature
        props.checked = value;
        props.onChange = event => {
          updateState(handleChangedValue(event.target.checked), storeUpdate);
        };
        break;
    }

    if (value === null || value === undefined) {
      value = "";
    }

    value = handleLoadedValue(value);
    return {
      value,
      baseValue,
      isOverrideMode,
      props: {...props, value},
      clearProps,
      confirmProps,
      overrideValue,
    };
  }

  renderOverridenIssuesets(fieldName, props = {}, handleHoverValue) {
    const isBaseIssue = this.getIsBaseIssue();
    const overriddenIssuesets = isBaseIssue
      ? this.calculateOverridenMasterIssuesets(fieldName)
      : this.calculateOverridenClientIssuesets(fieldName);
    if (!overriddenIssuesets || !overriddenIssuesets.length) {
      return null;
    }
    return this.renderOverrideChips(
      overriddenIssuesets,
      fieldName,
      props,
      handleHoverValue,
      false,
    );
  }

  renderOverrideChips = (
    items,
    fieldName,
    _props = {},
    handleHoverValue,
    useProposedChanges,
  ) => {
    const isBaseIssue = this.getIsBaseIssue();
    const props = {..._props};
    const styles = {
      container: {
        position: "relative",
        zIndex: 1,
        marginLeft: "2rem",
        marginRight: "2rem",
        display: "flex",
        flexWrap: "wrap",
        ...props.containerStyle,
      },
      item: {
        cursor: "pointer",
        background: useProposedChanges ? "green" : "#e06b6b",
        color: "#fff",
        lineHeight: 1.2,
        padding: "1px 4px",
        borderRadius: 3,
        margin: 2,
        fontSize: 11,
        fontWeight: 500,
        ...props.itemStyle,
      },
    };

    return (
      <div
        style={styles.container}
        key={`${
          useProposedChanges ? "proposed_changes" : "overrides"
        }-${fieldName}`}
      >
        {items.map(({id, title, value}, index) => {
          let hoverValue;
          if (_.isFunction(handleHoverValue)) {
            hoverValue = handleHoverValue(value);
          } else {
            hoverValue =
              !_.isString(value) && !_.isNumber(value)
                ? JSON.stringify(value)
                : value;
          }
          return (
            <span
              key={index}
              style={styles.item}
              title={hoverValue}
              onClick={
                id
                  ? () =>
                      this.props.pushLocationSearchParams({
                        issueset: id,
                        edit_level: isBaseIssue ? "template" : "client",
                      })
                  : undefined
              }
            >
              {title}
            </span>
          );
        })}
      </div>
    );
  };

  getIsBaseIssue() {
    const editLevel =
      (this.state && this.state.editLevel) || this.props.editLevel;
    return editLevel === "base";
  }

  calculateOverridenMasterIssuesets(fieldPath) {
    const isIssueOverridesHidden = Boolean(
      JSON.parse(localStorage.getItem("isIssueOverridesHidden")),
    );
    if (isIssueOverridesHidden) {
      return null;
    }

    const masterIssuesets = _.chain(this.props.contractTypesById)
      .map(({issuesets, name}) =>
        issuesets
          .filter(issueset => !issueset.is_duplicate_on_master)
          .map(issueset => ({...issueset, contract_type: name})),
      )
      .flatten()
      .value();

    const overriddenIssuesets = [];

    _.each(this.state.overridableFields.overridden, (clients, masterId) => {
      _.each(clients, values => {
        if (_.has(values, fieldPath)) {
          const overriddenIssueset = masterIssuesets.find(
            issueset => issueset.master_id === masterId,
          );
          if (overriddenIssueset) {
            const {id, name, contract_type} = overriddenIssueset;
            overriddenIssuesets.push({
              id,
              title: [contract_type, name].join(" - "),
              value: _.get(values, fieldPath),
            });
          }
        }
      });
    });
    return _.uniqBy(overriddenIssuesets, false, issueset => issueset.id);
  }

  calculateOverridenClientIssuesets(fieldPath) {
    const isIssueOverridesHidden = Boolean(
      JSON.parse(localStorage.getItem("isIssueOverridesHidden")),
    );
    const {
      selectedIssueset: selectedIssuesetState,
      actualIssueset: actualIssuesetState,
    } = this.state;
    const {
      selectedIssueset: selectedIssuesetProps,
      actualIssueset: actualIssuesetProps,
    } = this.props;
    const usedIssueset =
      selectedIssuesetState ||
      actualIssuesetState ||
      selectedIssuesetProps ||
      actualIssuesetProps;
    const isDuplicateOnMaster = _.get(
      usedIssueset,
      "is_duplicate_on_master",
      false,
    );

    if (isIssueOverridesHidden || !usedIssueset || isDuplicateOnMaster) {
      return null;
    }

    const duplicateIssuesets = _.chain(this.props.contractTypesById)
      .map(({issuesets}) =>
        issuesets.filter(
          issueset =>
            issueset.is_duplicate_on_master === true &&
            issueset.master_id === usedIssueset.master_id,
        ),
      )
      .flatten()
      .value();

    const overriddenIssuesets = [];
    const issue = this.getOverridableIssue();
    _.each(this.state.overridableFields.overridden, (clients, masterId) => {
      _.each(clients, (values, clientId) => {
        if (_.has(values, fieldPath)) {
          const overriddenIssueset = duplicateIssuesets.find(
            issueset =>
              issueset.master_id === masterId &&
              issueset.remote_client_id === parseInt(clientId, 10),
          );
          if (
            overriddenIssueset &&
            overriddenIssueset.issues.find(issueId => issueId === issue.id)
          ) {
            const {
              id,
              remote_client_name,
              client_issueset_name,
            } = overriddenIssueset;
            overriddenIssuesets.push({
              id,
              title: `${remote_client_name} - ${client_issueset_name}`,
              value: _.get(values, fieldPath),
            });
          }
        }
      });
    });
    return _.uniqBy(overriddenIssuesets, issueset => issueset.id);
  }

  renderValuesDiff(_baseValue, overrideValue) {
    if (!overrideValue) {
      return null;
    }
    const baseValue = _baseValue || "";
    const shouldUseNewLineSymbol =
      baseValue.length > 500 || overrideValue.length > 500;
    const diff = jsDiff.diffWords(baseValue, overrideValue);
    return (
      <div style={{marginLeft: 60, marginTop: 10}}>
        {diff
          .map(item => {
            if (shouldUseNewLineSymbol && item.value.indexOf("\n") !== -1) {
              const newItemValue = item.value.split("\n").join("↵");
              return {...item, value: newItemValue};
            }
            return item;
          })
          .map((item, index) => (
            <span
              key={index}
              style={{
                color: item.added ? "green" : item.removed ? "red" : "black",
                whiteSpace: "pre",
              }}
            >
              {item.value}
            </span>
          ))}
      </div>
    );
  }
}
