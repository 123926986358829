import _ from "underscore";
import React from "react";
import styled from "styled-components";

import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";
import {
  ActionDefinitionWithType,
  TaskArgument,
} from "common/flowmaster/types/task_config";
import {Prompt} from "common/flowmaster/types/workflow";

import Fieldset from "../styled/fieldset";
import Legend from "../styled/legend";
import HeadingCell from "../styled/heading_cell";

import JSONPrinter from "./json_printer";
import Arguments from "./arguments";
import applyWorkflowTaskOverrides from "routes/llm_run/utils/apply_workflow_task_overrides";

const TableRow = styled.tr`
  vertical-align: top;
  border-bottom: 1px solid #ccc;
  & th,
  & td {
    padding: 0.25em 1em;
  }
  & th:not(:last-child),
  & td:not(:last-child) {
    border-right: 1px solid #ddd;
  }
`;
const ActionHeaderRow = styled(TableRow)`
  position: sticky;
  top: 0;
  background-color: white;
`;
const ActionRow = styled(TableRow)`
  &:nth-child(even) {
    background-color: #fcfcfc;
  }
  &:nth-child(odd) {
    background-color: #eef;
  }
`;

const BaseTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  > thead > tr > th,
  > tbody > tr > td {
    padding: 0.5em 0.25em;
    word-break: break-word;
    overflow: hidden;
    width: 4em;
  }
`;

const DetailTable = styled(BaseTable)`
  > thead > tr > th,
  > tbody > tr > td {
    &:nth-of-type(1) {
      width: 2em;
    }
    &:nth-of-type(8) {
      width: 15%;
    }
    &:nth-of-type(9) {
      width: 30%;
    }
    &:nth-of-type(10) {
      width: 55%;
    }
  }
`;

const SummaryTable = styled(BaseTable)`
  > thead > tr > th,
  > tbody > tr > td {
    &:nth-of-type(1) {
      width: 6em;
    }
    &:nth-of-type(2) {
      width: 3em;
    }
    &:nth-of-type(3) {
      width: 50%;
    }
    &:nth-of-type(4) {
      width: 50%;
    }
  }
`;

const LowImpact = styled.span`
  font-family: monospace;
  color: #888;
  font-size: 0.9em;
`;

interface Props {
  llmRun: EnhancedLlmRun;
  actionDefinitions: ActionDefinitionWithType[];
  prompts: Prompt[];
  showInternalActions: boolean;
  showActionDetail: boolean;
}

function formatTime(date) {
  return new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  }).format(date);
}

export default function Actions({
  llmRun,
  actionDefinitions,
  prompts,
  showInternalActions,
  showActionDetail,
}: Props) {
  const tasks = !_.isEmpty(llmRun.overrides)
    ? applyWorkflowTaskOverrides(llmRun.tasks, llmRun.overrides)
    : llmRun.tasks;

  const Table = showActionDetail ? DetailTable : SummaryTable;

  const combinedData = llmRun.data.states_performed.map(
    (statePerformed, index) => ({
      ...statePerformed,
      runInfo: llmRun.data.data_store.task.data[index],
    }),
  );

  const statesPerformed = combinedData.filter(item => {
    const action = actionDefinitions.find(action => action.key === item.action);
    return (
      showInternalActions ||
      (item.action !== "start" &&
        action !== undefined &&
        action.type !== "internal")
    );
  });

  return (
    <Fieldset>
      <Legend>Actions ({statesPerformed.length})</Legend>
      <Table style={{}}>
        <thead>
          <ActionHeaderRow>
            {showActionDetail && <HeadingCell>key</HeadingCell>}
            <HeadingCell>name</HeadingCell>
            {showActionDetail && <HeadingCell>start</HeadingCell>}
            {showActionDetail && <HeadingCell>end</HeadingCell>}
            {showActionDetail && <HeadingCell>action</HeadingCell>}
            <HeadingCell>cost</HeadingCell>
            {showActionDetail && <HeadingCell>tokens</HeadingCell>}
            {showActionDetail && <HeadingCell>meta</HeadingCell>}
            <HeadingCell>output</HeadingCell>
            <HeadingCell>args</HeadingCell>
          </ActionHeaderRow>
        </thead>
        <tbody>
          {statesPerformed.map(item => {
            const task = tasks.find(task => task.id === item.id);
            const action = actionDefinitions.find(
              action => action.key === item.action,
            );
            const meta = item.runInfo?.meta as Record<string, unknown>;
            if (
              !action ||
              (action?.type === "internal" && !showInternalActions)
            ) {
              return null;
            }

            return (
              <ActionRow key={item.id}>
                {showActionDetail && (
                  <td>
                    <LowImpact>{task?.id}</LowImpact>
                  </td>
                )}
                <td>{task?.name}</td>
                {showActionDetail && (
                  <td title={item?.start_time}>
                    <LowImpact>
                      {formatTime(new Date(item?.start_time))}
                    </LowImpact>
                  </td>
                )}
                {showActionDetail && (
                  <td title={item?.end_time}>
                    <LowImpact>
                      {formatTime(new Date(item?.end_time))}
                    </LowImpact>
                  </td>
                )}
                {showActionDetail && (
                  <td>
                    <LowImpact>{item.action}</LowImpact>
                  </td>
                )}
                <td>{meta?.cost}</td>
                {showActionDetail && (
                  <td>
                    <LowImpact>{meta?.total_tokens}</LowImpact>
                  </td>
                )}
                {showActionDetail && (
                  <td>
                    <JSONPrinter json={meta} small />
                  </td>
                )}
                <td>
                  <JSONPrinter json={_.omit(item.runInfo, "meta")} />
                </td>
                <td style={{width: "100%"}}>
                  <Arguments
                    action={action}
                    args={task?.args as TaskArgument[]}
                    llmRun={llmRun}
                    prompts={prompts}
                    showInternalActions={showInternalActions}
                  />
                </td>
              </ActionRow>
            );
          })}
        </tbody>
      </Table>
    </Fieldset>
  );
}
