import React from "react";
import {Tooltip, IconButton} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";

type IconToggleProps = {
  onToggle: () => void;
  enabled: boolean;
  tooltip: string;
  Icon: React.ComponentType<{style: React.CSSProperties}>;
};

const IconToggle = ({enabled, onToggle, tooltip, Icon}: IconToggleProps) => (
  <Tooltip
    title={tooltip}
    // For some reason, Material UI tooltips are assigned a Z index that is too
    // low to show over Material UI Dialog components, by default. Embarassing.
    PopperProps={{style: {zIndex: 99999999}}}
  >
    <IconButton onClick={onToggle}>
      <Icon style={{color: enabled ? blue[500] : "rgba(0, 0, 0, 0.2)"}} />
    </IconButton>
  </Tooltip>
);

export default IconToggle;
