import string from "./string";
import text_map from "./text_map";
import select from "./select";
import model_select from "./model_select";
import schema from "./schema";
import embedding_topic from "./embedding_topic";
import {
  WorkflowInputValueArg,
  WorkflowInputWithListItemValueArg,
} from "./workflow_input_values";
import {PastTaskArg, FutureTaskArg} from "./task";

const editors = {
  string,
  text_map,
  int: string,
  object: string,
  string_array: string,
  object_array: string,
  json_schema: schema,
  workflow_input_values: WorkflowInputValueArg,
  workflow_input_with_list_item_values: WorkflowInputWithListItemValueArg,
  embedding_topic,
  embedding: string,
  select,
  model_select,
  input_connector: text_map,
  task_select: PastTaskArg,
  future_task_select: FutureTaskArg,
};

export default editors;
