import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import {List, ListItem} from "material-ui/List";
import Subheader from "material-ui/Subheader";
import Checkbox from "material-ui/Checkbox";
import ActionSearch from "material-ui/svg-icons/action/search";
import FlatButton from "material-ui/FlatButton";

import * as colors from "material-ui/styles/colors";

const styles = {
  dialogContent: {
    position: "absolute",
    left: "50%",
    top: "30%",
    transform: "translate(-50%, -30%)",
    maxWidth: 500,
  },
  dialogBody: {
    padding: 24,
  },
  searchContainer: {},
  searchInputContainer: {
    display: "flex",
    alignItems: "center",
    background: colors.grey100,
    border: `1px solid ${colors.grey300}`,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
  },
  searchInputIcon: {
    marginRight: 10,
    height: 24,
  },
  searchInput: {
    flexGrow: 1,
    border: `1px solid ${colors.grey300}`,
    padding: "4px 7px",
  },
  searchResultList: {
    marginTop: 10,
  },
  searchResultSubheader: {
    padding: 0,
    lineHeight: "36px",
    fontWeight: 600,
    marginBottom: 8,
    borderBottom: `1px solid ${colors.grey300}`,
  },
  searchResultListCheckbox: {
    top: 8,
    left: -3,
  },
  searchResultListItem: {
    padding: "12px 16px 12px 28px",
    fontSize: 14,
  },
};

class ContractTypesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      query: "",
      results: this.filterContractTypesByQuery(""),
      selectedContractTypes: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isShown !== this.props.isShown) {
      this.setState(this.getInitialState());
    }
  }

  render() {
    return (
      <Dialog
        title="Choose Contract Types"
        onDismiss={this.props.onHide}
        open={this.props.isShown}
        actions={this.getActions()}
        contentStyle={styles.dialogContent}
        bodyStyle={styles.dialogBody}
        autoScrollBodyContent={true}
      >
        <div style={styles.searchContainer}>
          <div style={styles.searchInputContainer}>
            <div style={styles.searchInputIcon}>
              <ActionSearch color={colors.grey400} />
            </div>
            <input
              type="text"
              style={styles.searchInput}
              placeholder="Search for contract types"
              value={this.state.query}
              onChange={this.handleInputChange}
            />
          </div>
          {Boolean(this.state.results.length) && (
            <List style={styles.searchResultList}>
              <Subheader style={styles.searchResultSubheader}>
                {this.state.results.length} Results
              </Subheader>
              {this.state.results.map(contractType => (
                <ListItem
                  key={contractType.master_id}
                  leftCheckbox={
                    <Checkbox
                      checked={this.isContractTypeSelected(contractType)}
                      style={styles.searchResultListCheckbox}
                      onCheck={event => {
                        switch (event.target.checked) {
                          case true:
                            return this.chooseContractType(contractType);
                          case false:
                            return this.removeContractType(contractType);
                        }
                      }}
                    />
                  }
                  primaryText={contractType.name}
                  style={styles.searchResultListItem}
                />
              ))}
            </List>
          )}
          {Boolean(this.state.selectedContractTypes.length) && (
            <Subheader
              style={{...styles.searchResultSubheader, border: "none"}}
            >
              {`${
                this.state.selectedContractTypes.length
              } Selected Contract Type` +
                `${this.state.selectedContractTypes.length > 1 ? "s" : ""}`}
            </Subheader>
          )}
        </div>
      </Dialog>
    );
  }

  getActions = () => {
    return [
      <FlatButton
        label="Cancel"
        key="contract-types-dialog-cancel-button"
        secondary={true}
        onClick={this.props.onHide}
      />,
      <FlatButton
        label="Apply"
        key="contract-types-dialog-apply-button"
        primary={true}
        onClick={this.apply}
      />,
    ];
  };

  handleInputChange = event => {
    const query = event.target.value;
    this.setState({
      query,
      results: this.filterContractTypesByQuery(query),
    });
  };

  filterContractTypesByQuery = query => {
    if (!query.length) {
      return this.props.contractTypes;
    }
    return this.props.contractTypes.filter(contractType => {
      return Boolean(contractType.name.match(new RegExp(query, "i")));
    });
  };

  isContractTypeSelected = contractType => {
    for (let i = 0; i < this.state.selectedContractTypes.length; i++) {
      if (
        this.state.selectedContractTypes[i].master_id === contractType.master_id
      ) {
        return true;
      }
    }
    return false;
  };

  chooseContractType = contractType => {
    if (!this.isContractTypeSelected(contractType)) {
      this.setState({
        selectedContractTypes: [
          ...this.state.selectedContractTypes,
          ...[contractType],
        ],
      });
    }
  };

  removeContractType = contractType => {
    const updated = [...this.state.selectedContractTypes];
    for (let i = 0; i < updated.length; i++) {
      if (updated[i].master_id === contractType.master_id) {
        updated.splice(i, 1);
      }
    }
    this.setState({selectedContractTypes: updated});
  };

  apply = () => {
    if (this.state.selectedContractTypes.length) {
      this.props.onConfirm(this.state.selectedContractTypes);
    }
    this.props.onHide();
  };
}

ContractTypesDialog.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contractTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      master_id: PropTypes.string.isRequired,
      issuesets: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          master_id: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default ContractTypesDialog;
