import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {Link} from "react-router";

import {TableRowColumn, TableRow} from "material-ui/Table";

class LogRow extends PureComponent {
  render() {
    const {log} = this.props;

    return (
      <TableRow key={log.id}>
        <TableRowColumn>{moment(log.time).format("lll")}</TableRowColumn>
        <TableRowColumn>{log.type}</TableRowColumn>
        <TableRowColumn>{log.username}</TableRowColumn>
        <TableRowColumn>
          <Link
            to={`/organisation/${log.organisation_id}/project/${
              log.data.project_id
            }/document/${log.data.document_id}`}
          >
            {log.data.document_name}
          </Link>
        </TableRowColumn>
        <TableRowColumn>
          {Object.keys(log.data).map((key, i) => (
            <div key={`${key}-${i}`}>
              <strong>{key}: </strong>
              <span>{`${log.data[key]}`}</span>
            </div>
          ))}
        </TableRowColumn>
      </TableRow>
    );
  }
}

LogRow.propTypes = {
  log: PropTypes.object.isRequired,
};

export default LogRow;
