import _ from "underscore";
import radium from "radium";
import React from "react";

import cleanReference from "utils/clauses/clean_reference";

const backgroundColor = "#eef";
const borderOn = `1px solid ${backgroundColor}`;
const borderOff = "none";
const borderRadius = "1em";

const styles = {
  main: {
    display: "flex",
    width: "100%",
  },
  shiftLeft: {
    marginLeft: "-4em",
    width: "calc(100% + 4em)",
  },
  number: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "3.5em",
    marginRight: "0.5em",
    backgroundColor,
    borderLeft: borderOn,
    borderRight: borderOn,
    borderTop: borderOff,
    borderBottom: borderOff,
  },
  start: {
    borderTop: borderOn,
    borderTopRightRadius: borderRadius,
    borderTopLeftRadius: borderRadius,
  },
  end: {
    borderBottom: borderOn,
    borderBottomRightRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
  },
};

class NumberedItem extends React.Component {
  render() {
    const {node, clause, number, shiftLeft, start, end} = this.props;
    const conjunctionText = this.renderConjugationText();
    return (
      <div
        className="clause-list numbered-item"
        key={clause.id}
        style={[styles.main, shiftLeft ? styles.shiftLeft : {}]}
      >
        <span
          className="number"
          style={[
            styles.number,
            start ? styles.start : {},
            end ? styles.end : {},
          ]}
          onClick={this.onClick(clause.path)}
        >
          <span style={{display: "block"}}>{cleanReference(number)}</span>
          {conjunctionText}
        </span>
        {node}
      </div>
    );
  }

  renderConjugationText() {
    const {end, conjunctionType} = this.props;
    if (!end && conjunctionType !== "NONE") {
      return (
        <span
          className="conjugation-text"
          style={{
            fontStyle: "italic",
            color: "#ccc",
            flexGrow: "2",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {conjunctionType === "AND_OR" ? "AND /\u00A0OR" : conjunctionType}
        </span>
      );
    }
    return null;
  }

  /* eslint-disable no-invalid-this */
  onClick = _.memoize(
    path => () => {
      this.props.onClick(path);
    },
    (...args) => JSON.stringify([...args]),
  );
  /* eslint-enable no-invalid-this */
}

export default radium(NumberedItem);
