import React from "react";

const renderRegexList = (fullRegexList, showMatchRegexes) => {
  if (!fullRegexList || fullRegexList.length === 0 || !showMatchRegexes) {
    return null;
  }
  return (
    <div
      style={{
        borderTop: "1px dashed lightgrey",
        marginTop: "0.5rem",
        display: "inline-block",
        padding: "0.5rem 0",
        fontSize: "11px",
      }}
    >
      {fullRegexList.map((regex, index) => (
        <div key={`${regex.index}_${index}`} style={regex.style}>
          <span
            style={{
              width: "1.7rem",
              display: "inline-block",
              textAlign: "right",
              marginRight: "0.5rem",
            }}
          >
            {regex.index + 1}.
          </span>
          <span>{regex.regexText}</span>
        </div>
      ))}
    </div>
  );
};

export default renderRegexList;
