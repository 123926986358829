import React from "react";
import _ from "underscore";

import BulkTopicControlPanel from "common_components/bulk_topic_control_panel";
import {TopicAnalysisUsageInfoCard} from "common_components/information_cards";

import InformationPopup from "common_components/information_popup";

import IconButton from "material-ui/IconButton";
import ShowAllIcon from "material-ui/svg-icons/alert/error";
import PrevPageIcon from "material-ui/svg-icons/navigation/chevron-left";
import NextPageIcon from "material-ui/svg-icons/navigation/chevron-right";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import MaxUsagesControl from "./max_usages_control";

import TopicUsage from "./topic_usage";
import TopicUsageBadlyParsedDialog from "./topic_usage_badly_parsed_dialog";

const styles = {
  pageIcon: {
    transition: "unset",
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  tableWrapper: {
    borderCollapse: "collapse",
    margin: "1rem",
  },
  pageControl: {
    textDecoration: "underline",
    fontWeight: 600,
    margin: "0 auto",
  },
  pageControlWrapper: {
    display: "flex",
    alignItems: "center",
    margin: "0 auto",
  },
  searchControlWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchControlBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 1em 0 calc(256px + 10em)",
  },
  searchControlContainer: {
    display: "flex",
    justifyContent: "center",
  },
  classifierHeadings: {
    display: "flex",
    alignItems: "center",
  },
};

export default class TopicUsageList extends React.Component {
  state = {
    unselectedUsageIds: {},
    usesToSelect: null,
    isOpenBadlyParsedModal: false,
    badlyParsedData: null,
    isBadlyParsedIds: [],
  };

  shouldComponentUpdate(newProps) {
    return (
      !_.isEqual(this.props.topic.usage, newProps.topic.usage) ||
      !_.isEqual(this.props.regexClassifier, newProps.regexClassifier) ||
      this.props.showUsageClausepartId !== newProps.showUsageClausepartId ||
      this.props.viewMode !== newProps.viewMode ||
      this.props.classifierRefreshPending !==
        newProps.classifierRefreshPending ||
      !_.isEqual(this.props.maxUsagesSettings !== newProps.maxUsagesSettings) ||
      this.props.currentPage !== newProps.currentPage ||
      this.props.areAllUsagesShown !== newProps.areAllUsagesShown
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {usesToSelect: usesToSelectPrev} = prevState;
    const {usesToSelect} = this.state;
    if (usesToSelect !== usesToSelectPrev) {
      this.setState(() => ({unselectedUsageIds: {}}));
    }
  }

  render() {
    const {viewMode, usages} = this.props;
    const classifiers = this.getClassifiersByViewMode();
    const shownUsages = this.getShownUsages(usages);
    return (
      <div style={styles.wrapper}>
        {this.renderPagesAndSearchControls(shownUsages)}
        {this.renderTopicControlButtons(shownUsages)}
        <table style={styles.tableWrapper}>
          <thead>
            <tr>
              {[
                <th key="topic_data" style={{width: "12rem"}}>
                  Topic Data
                </th>,
                <th key="clause-text">Text</th>,
                <th key="has-topic">Has Topic</th>,
                ...this.renderClassifierHeadings(classifiers),
                viewMode === "detail" ? (
                  <th key="all-classifiers">All</th>
                ) : null,
                <th key="buttons-header" />,
                <th key="edit-button-header" />,
                this.state.usesToSelect ? (
                  <th key="uses-to-select-checkbox" />
                ) : null,
              ].filter(item => item)}
            </tr>
          </thead>
          <tbody
            style={{opacity: this.props.classifierRefreshPending ? "0.2" : "1"}}
          >
            {shownUsages.map((usage, index) => (
              <TopicUsage
                key={`${usage.clausepart_id}-${usage.clausepart_path}-${index}`}
                usage={usage}
                classifiers={classifiers}
                organisationId={this.props.organisationId}
                topic={this.props.topic}
                showUsageClausepartId={this.props.showUsageClausepartId}
                topicsById={this.props.topicsById}
                viewMode={this.props.viewMode}
                regexClassifier={this.props.regexClassifier}
                showMatchRegexes={this.props.showMatchRegexes}
                removeTopicFromClauses={this.props.removeTopicFromClauses}
                setEditedUsageId={this.props.setEditedUsageId}
                triggerShowUsage={this.props.triggerShowUsage}
                onUsageRemoved={this.props.onUsageRemoved}
                addTopicToClauses={this.props.addTopicToClauses}
                onExistingTopicAdded={this.props.onExistingTopicAdded}
                confirmTopicInClauses={this.props.confirmTopicInClauses}
                onTopicConfirmed={this.props.onTopicConfirmed}
                showSelectedResult={this.props.showSelectedResult}
                rerunClassifier={this.props.rerunClassifier}
                usesToSelect={this.state.usesToSelect}
                triggerUnselectUsage={this.triggerUnselectUsage}
                unselectedUsageIds={this.state.unselectedUsageIds}
                getClauseLogs={this.props.getClauseLogs}
                handleBadlyParsedModalOpen={this.handleBadlyParsedModalOpen}
                isBadlyParsedIds={this.state.isBadlyParsedIds}
              />
            ))}
          </tbody>
        </table>
        {this.renderPagesControl()}
        <TopicUsageBadlyParsedDialog
          isOpen={this.state.isOpenBadlyParsedModal}
          modalClose={this.handleBadlyParsedModalClose}
          notConfirm={this.handleBadlyParsedModalClose}
          confirm={this.markClauseIsBadlyParsed}
        />
      </div>
    );
  }

  renderTopicControlButtons = usages => {
    return (
      <BulkTopicControlPanel
        usageBulkIds={getUsageBulkIds(usages, this.state.unselectedUsageIds)}
        usesToSelect={this.state.usesToSelect}
        onUsesToSelectChange={this.onUsesToSelectChange}
        onAddFPs={this.props.addTopicToClauses}
        onRemoveFNs={this.props.removeTopicFromClauses}
        onConfirmUnconfirmed={this.props.confirmTopicInClauses}
      />
    );
  };

  onUsesToSelectChange = submittedValue => {
    const oldValue = this.state.usesToSelect;
    const newValue = oldValue === submittedValue ? null : submittedValue;
    this.setState(() => ({usesToSelect: newValue}));
  };

  triggerUnselectUsage = clausepartId => () => {
    this.setState(prevState => {
      const prevValue = Boolean(prevState.unselectedUsageIds[clausepartId]);
      return {
        unselectedUsageIds: {
          ...prevState.unselectedUsageIds,
          [clausepartId]: !prevValue,
        },
      };
    });
  };

  handleBadlyParsedModalOpen = (id, clause_id, document_id, project_id) => {
    this.setState({
      isOpenBadlyParsedModal: true,
      badlyParsedData: {id, clause_id, document_id, project_id},
    });
  };

  handleBadlyParsedModalClose = () => {
    this.setState({
      isOpenBadlyParsedModal: false,
    });
  };

  markClauseIsBadlyParsed = () => {
    if (this.state.badlyParsedData) {
      const {
        id,
        clause_id,
        document_id,
        project_id,
      } = this.state.badlyParsedData;
      this.setState({
        isBadlyParsedIds: [...this.state.isBadlyParsedIds, id],
      });
      this.props.updateClausepartIsBadlyParsed(
        project_id,
        document_id,
        clause_id,
        id,
        true,
      );
    }
    this.handleBadlyParsedModalClose();
  };

  renderPagesControl = () => {
    const {
      currentPage,
      usages,
      areAllUsagesShown,
      showUsageClausepartId,
    } = this.props;
    if (usages.length === 0) {
      return null;
    }
    if (showUsageClausepartId) {
      return <div style={styles.pageControl}>SINGLE USAGE SELECTED</div>;
    }
    const totalPages = this.getTotalPages();
    return (
      <div style={styles.pageControlWrapper}>
        <PrevPageIcon
          onClick={this.showPreviousUsagesPage}
          style={{
            ...styles.pageIcon,
            visibility:
              currentPage !== 1 && !areAllUsagesShown ? "visible" : "hidden",
          }}
        />
        <div style={{textDecoration: "underline", fontWeight: 600}}>
          {areAllUsagesShown
            ? "ALL PROBLEMS SHOWN"
            : `PAGE ${currentPage} OF ${totalPages}`}
        </div>
        <NextPageIcon
          onClick={this.showNextUsagesPage}
          style={{
            ...styles.pageIcon,
            visibility:
              currentPage < totalPages && !areAllUsagesShown
                ? "visible"
                : "hidden",
          }}
        />
        {
          <IconButton
            tooltip={
              areAllUsagesShown ? "Show Problems in Pages" : "Show All Problems"
            }
            tooltipPosition="top-right"
            onClick={
              areAllUsagesShown
                ? this.props.onAbortShowAllUsages
                : this.props.onShowAllUsages
            }
            iconStyle={{color: areAllUsagesShown ? "#424242" : "#9E9E9E"}}
            style={{
              transition: "unset",
            }}
          >
            <ShowAllIcon />
          </IconButton>
        }
      </div>
    );
  };

  renderPagesAndSearchControls() {
    const {groupedUsages} = this.props;
    return (
      <div style={styles.searchControlWrapper}>
        <div />
        <div style={styles.searchControlBlock}>
          <div style={styles.searchControlContainer}>
            <div style={{fontSize: "32px"}}>Topic usage list</div>
            <InformationPopup
              popupContainerStyle={{top: "-32px", left: "26px"}}
            >
              <TopicAnalysisUsageInfoCard />
            </InformationPopup>
          </div>
          {this.renderPagesControl()}
        </div>
        <div style={{marginRight: "1em"}}>
          <MaxUsagesControl
            stats={
              this.props.topic.classifier_stats[
                this.props.regexClassifier.configuration_id
              ]
            }
            usages={groupedUsages}
            settings={this.props.maxUsagesSettings}
            updateMaxUsagesSettings={this.props.updateMaxUsagesSettings}
            onUsagesTextFilterChange={this.props.onUsagesTextFilterChange}
            isRequestPending={this.props.isRequestPending}
          />
        </div>
      </div>
    );
  }

  showNextUsagesPage = () => {
    const totalPages = this.getTotalPages();
    if (this.props.currentPage === totalPages) {
      return;
    }
    this.props.changeShownUsagesPage(+1);
  };

  showPreviousUsagesPage = () => this.props.changeShownUsagesPage(-1);

  getTotalPages = () => {
    const usagesLength = this.props.usages.length;
    const {maxUsages} = this.props.maxUsagesSettings;
    const totalPages =
      !maxUsages || !usagesLength || usagesLength < maxUsages
        ? 1
        : Math.ceil(usagesLength / maxUsages); // (total usages / usages per page)
    return totalPages;
  };

  getClassifiersByViewMode = () => {
    const {classifiers} = this.props;
    return this.props.viewMode === "focus"
      ? classifiers.filter(classifier => classifier.name === "regex")
      : classifiers;
  };

  renderClassifierHeadings(classifiers) {
    return classifiers.map((classifier, index) => (
      <th key={`classifier_${classifier.configuration_id}_${index}`}>
        <div style={styles.classifierHeadings}>
          <span>
            {classifier.name} {classifier.configuration_name}
          </span>
          {this.renderClassifierRefreshButton(classifier)}
        </div>
      </th>
    ));
  }

  renderClassifierRefreshButton(classifier) {
    return (
      <IconButton
        onClick={this.rerunClassifier(
          classifier.configuration_id,
          classifier.name,
          classifier.current_topic_trainingnode_id,
        )}
        disabled={Boolean(this.props.classifierRefreshPending)}
      >
        <RefreshIcon />
      </IconButton>
    );
  }

  rerunClassifier = _.memoize(
    (classifierId, classifierName, topicTrainingNodeId) => () => {
      this.props.rerunClassifier(
        classifierId,
        classifierName,
        topicTrainingNodeId,
        false, // process single usage
        false, // use live classifier
      );
    },
  );

  getShownUsages = usages => {
    const {areAllUsagesShown, currentPage} = this.props;
    const {maxUsages} = this.props.maxUsagesSettings;
    if (areAllUsagesShown) {
      return usages;
    }
    const maxIndex = currentPage * maxUsages - 1;
    const minIndex = maxIndex - maxUsages + 1;
    return usages.slice(minIndex, maxIndex + 1);
  };
}

function getUsageBulkIds(usages, unselectedUsageIds) {
  return usages.reduce(
    (accum, usage) => {
      const {clausepart_id: clausepartId} = usage;
      const currentStatus = usage.status ? usage.status[0] : null;
      const isSelected = !unselectedUsageIds[clausepartId];
      if (currentStatus === "FP") {
        accum.fp.count += 1;
        if (isSelected) {
          accum.fp.ids.push(clausepartId);
        }
      } else if (currentStatus === "FN" || currentStatus === "NEGnc") {
        accum.fn.count += 1;
        if (isSelected) {
          accum.fn.ids.push(clausepartId);
        }
      } else if (!usage.is_confirmed) {
        accum.unconfirmed.count += 1;
        if (isSelected) {
          accum.unconfirmed.ids.push(clausepartId);
        }
      }
      return accum;
    },
    {
      fp: {label: "FP", count: 0, dialogLabel: "addAllFPs", ids: []},
      fn: {label: "FN", count: 0, dialogLabel: "removeAllFNs", ids: []},
      unconfirmed: {
        label: "Unconfirmed",
        count: 0,
        dialogLabel: "confirmAllUnconfirmed",
        ids: [],
      },
    },
  );
}
