import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {
  WorkflowId,
  WorkflowInstanceId,
  WorkflowInstance,
} from "common/flowmaster/types/workflow";

export type WorkflowInstanceUpdateNameAction = {
  type: WorkflowsActionTypes["WORKFLOW_INSTANCE_UPDATE_NAME"]["SUCCESS"];
  payload: {workflow_id: WorkflowId; prerequisites: WorkflowInstance[]};
};

export default function updateWorkflowInstanceName(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOW_INSTANCE_UPDATE_NAME,
    (
      organisationId: OrganisationId,
      workflowId: WorkflowId,
      workflowInstanceId: WorkflowInstanceId,
      name: string,
    ) => {
      return requestor
        .patch(
          `${basePath}/organisation/${organisationId}/workflow/${workflowId}/workflow_instance/${workflowInstanceId}/name`,
          {workflow_instance_name: name},
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
