export default function getDocumentPathName(
  organisationId,
  projectId,
  user,
  document,
) {
  if (document.status && document.status !== "OK") {
    return "";
  }
  const commonPathName = `/organisation/${organisationId}/project/${projectId}/document`;
  if (
    (document.concat_load_state ?? []).some(loadState => loadState !== 3) &&
    !user.is_admin
  ) {
    return `${commonPathName}/list`;
  }
  return `${commonPathName}/${document.id}/detail`;
}
