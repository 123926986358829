import HEADINGS_SEPARATOR from "common/headings_separator";

function getText(text, settings) {
  if (!settings || !text) {
    return text;
  }
  let resultText = text;

  const omitDefinitions =
    typeof settings.omit_definitions === "boolean"
      ? settings.omit_definitions
      : true;

  const omitDefinitionIndicator =
    typeof settings.omit_definition_indicator === "boolean"
      ? settings.omit_definition_indicator
      : true;

  const omitHeadings =
    typeof settings.omit_headings === "boolean" ? settings.omit_headings : true;

  if (omitDefinitions) {
    let indicatedDefinitions = "";
    if (!omitDefinitionIndicator) {
      const indicatorRegex = /\|\|[^|]+?>>@@/g;
      indicatedDefinitions = (resultText.match(indicatorRegex) || []).join("");
    }
    const index = resultText.indexOf("||");
    if (index > 0) {
      resultText = resultText.substring(0, index) + indicatedDefinitions;
    }
  }

  if (omitHeadings) {
    const doubleExclamationIndex = resultText.lastIndexOf(HEADINGS_SEPARATOR);
    if (doubleExclamationIndex !== -1) {
      resultText = resultText.substring(doubleExclamationIndex + 2);
    }
  }
  return resultText;
}

export default getText;
