import styled from "styled-components";

const Legend = styled.legend`
  border: 1px solid #ccc;
  padding: 0.5em;
  margin-left: 0.5em;
  border-radius: 5px;
  font-weight: bold;
`;

export default Legend;
