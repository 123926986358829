import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function confirmTopicInClauses(requestor) {
  return createReduxPromiseAction(
    ActionTypes.CLAUSES_TOPIC_CONFIRM,
    (organisationId, topicId, data) =>
      requestor
        .put(
          `${basePath}/organisation/${organisationId}/topic/${topicId}/confirm-clauses`,
          data,
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
