import React from "react";

import InputValue from "./input_value";

import {
  WorkflowTask,
  ActionDefinition,
} from "common/flowmaster/types/task_config";
import {DataLocation} from "common/flowmaster/types/data_location";
import {
  WorkflowContext,
  WorkflowInputs,
  WorkflowInput,
  WorkflowInputId,
  Prompt,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

interface TextMapEditorProps {
  workflowInputs: WorkflowInput[];
  values: Record<WorkflowInputId, DataLocation<string>>;
  onUpdateItem: (inputId: WorkflowInputId, value: DataLocation<string>) => void;
  priorTasks: WorkflowTask[];
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  organisationId: OrganisationId;
  useListItem: boolean;
}

const TextMapEditor: React.FC<TextMapEditorProps> = ({
  workflowInputs,
  values,
  onUpdateItem,
  priorTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  organisationId,
  useListItem,
}) => {
  return (
    <div>
      {workflowInputs.map(input => {
        return (
          <InputValue
            key={input.id}
            input={input}
            value={values[input.id]}
            onUpdateItem={(value: DataLocation<string>) =>
              onUpdateItem(input.id, value)
            }
            priorTasks={priorTasks}
            context={context}
            inputs={inputs}
            prompts={prompts}
            actionDefinitions={actionDefinitions}
            organisationId={organisationId}
            useListItem={useListItem}
          />
        );
      })}
    </div>
  );
};

export default TextMapEditor;
