import ActionTypes from "../constants/action_types";

import basePath from "base_path";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function userPasswordReset(requestor) {
  return createReduxPromiseAction(
    ActionTypes.USER_PASSWORD_RESET,
    (password, token) =>
      requestor
        .post(
          `${basePath}/auth/set-new-password`,
          {password, token},
          {withCredentials: true},
        )
        .then(response => response.data)
        .catch(err => err),
  );
}
