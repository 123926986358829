import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function fetchDocumentConversations(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_CONVERSATIONS_FETCH,
    (organisationId, projectId, documentId) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}` +
            `/document/${documentId}` +
            "/conversation",
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
