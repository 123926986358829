import React from "react";
import requestor from "requestor";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import {isInitialised} from "utils/uninitialised";
import Permissioner from "utils/permissioner";
import setTitle from "utils/set_title";

import WorkflowDetailComponent from "../components/workflow_detail";

import updateWorkflowAction from "modules/flowmaster/actions/workflow_update";
import removeWorkflowAction from "modules/flowmaster/actions/workflow_remove";

import addWorkflowInputAction from "modules/flowmaster/actions/workflow_input_add";
import updateWorkflowInputAction from "modules/flowmaster/actions/workflow_input_update";
import removeWorkflowInputAction from "modules/flowmaster/actions/workflow_input_remove";
import reorderWorkflowInputsAction from "modules/flowmaster/actions/workflow_input_reorder";

import addWorkflowTaskAction from "modules/flowmaster/actions/workflow_task_add";
import updateWorkflowTaskAction from "modules/flowmaster/actions/workflow_task_update";
import duplicateWorkflowAction from "modules/flowmaster/actions/workflow_duplicate";
import removeWorkflowTaskAction from "modules/flowmaster/actions/workflow_task_remove";
import reorderWorkflowTasksAction from "modules/flowmaster/actions/workflow_task_reorder";

import addPrerequisiteAction from "modules/flowmaster/actions/workflow_prerequisite_add";
import removePrerequisiteAction from "modules/flowmaster/actions/workflow_prerequisite_remove";
import updatePrerequisiteInputValueAction from "modules/flowmaster/actions/workflow_prerequisite_update_input_value";
import updateWorkflowInstanceNameAction from "modules/flowmaster/actions/workflow_instance_update_name";
import {
  ContextId,
  WorkflowId,
  WorkflowInput,
  WorkflowInputId,
  WorkflowInstanceId,
} from "common/flowmaster/types/workflow";
import {
  WorkflowTask,
  WorkflowTaskId,
} from "common/flowmaster/types/task_config";

function WorkflowDetailContainer(props) {
  const shouldRenderContainer = isInitialised([
    props.workflow,
    props.actionDefinitions,
    props.prompts,
    props.contexts,
    props.workflows,
    props.existingPrerequisites,
  ]);

  if (!shouldRenderContainer || !props.user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const permissioner = new Permissioner(props.user);

  if (
    !permissioner.hasPermission("update-workflow") ||
    !permissioner.isAdmin()
  ) {
    return permissioner.getNoPermissionMessage();
  }

  const {workflow, params} = props;
  const {organisationId} = params;
  function onWorkflowUpdate(data) {
    return props.updateWorkflow(
      organisationId,
      workflow.id,
      {
        ...data,
        last_edited: workflow.last_edited,
      },
      workflow.last_edited,
    );
  }

  function redirectToWorkflowList() {
    props.dispatch(push(`/organisation/${organisationId}/workflow/list`));
  }

  function onWorkflowDelete() {
    props.removeWorkflow(organisationId, workflow.id, workflow.last_edited);
    redirectToWorkflowList();
  }

  function onWorkflowContextUpdate(contextId: ContextId) {
    props.updateWorkflow(organisationId, workflow.id, {
      context_id: contextId,
      prerequisite_workflows: null,
      last_edited: workflow.last_edited,
    });
  }
  function onWorkflowInputAdd(name: string) {
    props.addWorkflowInput(organisationId, workflow.id, name);
  }

  function onWorkflowTaskAdd(name: string) {
    props.addWorkflowTask(organisationId, workflow.id, name);
  }

  function onWorkflowInputUpdate(
    workflowInputId: WorkflowInputId,
    data: WorkflowInput,
  ) {
    props.updateWorkflowInput(
      organisationId,
      workflow.id,
      workflowInputId,
      data,
    );
  }

  function onWorkflowTaskUpdate(
    workflowTaskId: WorkflowTaskId,
    data: WorkflowTask,
  ) {
    props.updateWorkflowTask(organisationId, workflow.id, workflowTaskId, data);
  }

  function onWorkflowInputRemove(workflowInputId: WorkflowInputId) {
    props.removeWorkflowInput(organisationId, workflow.id, workflowInputId);
  }

  function onWorkflowTaskRemove(workflowTaskId: WorkflowTaskId) {
    props.removeWorkflowTask(organisationId, workflow.id, workflowTaskId);
  }

  function onWorkflowInputsReorder(
    workflowInputId: WorkflowInputId,
    order: number,
  ) {
    props.reorderWorkflowInputs(
      organisationId,
      workflow.id,
      workflowInputId,
      order,
    );
  }

  function duplicateWorkflow() {
    props.duplicateWorkflow(organisationId, workflow.id).then(res => {
      window.open(
        `/organisation/${organisationId}/workflow/${res.value.id}/detail`,
        "_blank",
      );
    });
  }

  function onWorkflowTasksReorder(
    id: WorkflowTaskId,
    movedItemId: WorkflowTaskId,
  ) {
    props.reorderWorkflowTasks(organisationId, workflow.id, id, movedItemId);
  }
  function onAddPrerequisite(
    selectedWorkflowId?: WorkflowId,
    workflowInstanceName?: string,
    workflowInstanceId?: WorkflowInstanceId,
  ) {
    props.addPrerequisite(
      organisationId,
      workflow.id,
      workflowInstanceName,
      selectedWorkflowId,
      workflowInstanceId,
    );
  }

  function onUpdateWorkflowInstanceName(id: WorkflowInstanceId, name: string) {
    props.updateWorkflowInstanceName(organisationId, workflow.id, id, name);
  }

  function onUpdatePrerequisiteInputValue(
    id: WorkflowInstanceId,
    inputId: WorkflowInputId,
    value: string,
  ) {
    props.updatePrerequisiteInputValue(
      organisationId,
      workflow.id,
      id,
      inputId,
      value,
    );
  }
  function onRemovePrerequisite(id: WorkflowInstanceId) {
    props.removePrerequisite(organisationId, workflow.id, id);
  }

  setTitle("Workflow Detail");
  return (
    <WorkflowDetailComponent
      workflow={workflow}
      workflows={props.workflows}
      actionDefinitions={props.actionDefinitions}
      prompts={props.prompts}
      contexts={props.contexts}
      embeddingTopics={props.embeddingTopics}
      organisationId={organisationId}
      updateWorkflow={onWorkflowUpdate}
      duplicateWorkflow={duplicateWorkflow}
      deleteWorkflow={onWorkflowDelete}
      updateWorkflowContext={onWorkflowContextUpdate}
      addWorkflowInput={onWorkflowInputAdd}
      updateWorkflowInput={onWorkflowInputUpdate}
      removeWorkflowInput={onWorkflowInputRemove}
      reorderWorkflowInputs={onWorkflowInputsReorder}
      addWorkflowTask={onWorkflowTaskAdd}
      updateWorkflowTask={onWorkflowTaskUpdate}
      removeWorkflowTask={onWorkflowTaskRemove}
      reorderWorkflowTasks={onWorkflowTasksReorder}
      existingPrerequisites={props.existingPrerequisites}
      addPrerequisite={onAddPrerequisite}
      updatePrerequisiteInputValue={onUpdatePrerequisiteInputValue}
      removePrerequisite={onRemovePrerequisite}
      updateWorkflowInstanceName={onUpdateWorkflowInstanceName}
    />
  );
}

function select(state, props) {
  if (!state.roles) {
    return {};
  }
  return {
    workflow: state.workflow,
    workflows: state.workflows,
    actionDefinitions: state.actionDefinitions,
    prompts: state.prompts,
    contexts: state.contexts,
    params: props.params,
    router: state.router,
    user: state.user,
    embeddingTopics: state.embeddingTopics,
    existingPrerequisites: state.existingPrerequisites,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateWorkflow: updateWorkflowAction(requestor),
        duplicateWorkflow: duplicateWorkflowAction(requestor),
        removeWorkflow: removeWorkflowAction(requestor),

        addWorkflowInput: addWorkflowInputAction(requestor),
        updateWorkflowInput: updateWorkflowInputAction(requestor),
        removeWorkflowInput: removeWorkflowInputAction(requestor),
        reorderWorkflowInputs: reorderWorkflowInputsAction(requestor),

        addWorkflowTask: addWorkflowTaskAction(requestor),
        updateWorkflowTask: updateWorkflowTaskAction(requestor),
        removeWorkflowTask: removeWorkflowTaskAction(requestor),
        reorderWorkflowTasks: reorderWorkflowTasksAction(requestor),

        addPrerequisite: addPrerequisiteAction(requestor),
        removePrerequisite: removePrerequisiteAction(requestor),
        updatePrerequisiteInputValue: updatePrerequisiteInputValueAction(
          requestor,
        ),
        updateWorkflowInstanceName: updateWorkflowInstanceNameAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(WorkflowDetailContainer);
