import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../constants/action_types";

/* eslint-disable max-params, @typescript-eslint/no-unused-vars */
export default createReduxPromiseAction(ActionTypes.DOCUMENT_CLAUSE_DELETE, (
  organisationId,
  projectId,
  documentId,
  sectionId,
  clauseId,
  lastEdited,
  deletionChange, // used in action request
) =>
  requestor
    .delete(
      `${basePath}/organisation/${organisationId}/project/${projectId}` +
        `/document/${documentId}/section/${sectionId}/clause/${clauseId}/`,
      {
        withCredentials: true,
        data: {
          last_edited: lastEdited,
        },
      },
    )
    .then(response => ({...response.data, sectionId, clauseId})),
);
