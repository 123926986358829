import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function clientSettingsUpdate(requestor) {
  return createReduxPromiseAction(
    ActionTypes.CLIENT_UPDATE,
    (organisationId, clientId, data) =>
      requestor
        .put(
          `${basePath}/organisation/${organisationId}/client/${clientId}`,
          data,
          {withCredentials: true},
        )
        .then(response => response.data)
        .catch(err => err.data),
  );
}
