import basePath from "base_path";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes, {
  AccessControlEntryActionTypes,
} from "../constants/action_types";
import {AccessControlEntry} from "common/types/accesscontrolentry";

export type AddAccessControlEntryAction = {
  type: AccessControlEntryActionTypes["ACCESS_CONTROL_ENTRY_ADD"]["SUCCESS"];
  payload: AccessControlEntry;
};

export default createReduxPromiseAction(
  ActionTypes.ACCESS_CONTROL_ENTRY_ADD,
  (organisationId: number, ace: Omit<AccessControlEntry, "id">) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/accesscontrolentry`,
        ace,
        {withCredentials: true},
      )
      .then(({data: {id}}) => ({...ace, id})),
);
