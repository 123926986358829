import requestor from "requestor";
import basePath from "base_path";
import ActionTypes from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";

export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_ISSUES_RESET,
  (organisationId, issueId, updates) =>
    requestor
      .patch(
        `${basePath}/organisation/${organisationId}/issue/${issueId}/document_issues`,
        updates,
        {withCredentials: true},
      )
      .then(response => response.data),
);
