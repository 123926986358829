import React from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import red from "@material-ui/core/colors/red";

function SaveButtons({
  isProjectAdded,
  isArchived,
  onSaveProjectChanges,
  onCancelProjectChanges,
  onShowDeleteDialog,
  areButtonsDisabled,
  isDeleteDisabled,
  documentsCount,
  styles,
  triggerArchiveProject,
}) {
  const button = (
    <Button
      color="default"
      variant="contained"
      style={{margin: "0 12px", color: red[400]}}
      onClick={onShowDeleteDialog}
      disabled={isDeleteDisabled || documentsCount > 0}
    >
      DELETE
    </Button>
  );
  const saveCaption = isProjectAdded ? "ADD" : "SAVE";
  return (
    <div style={{display: "flex", justifyContent: "center", ...(styles || {})}}>
      <Button
        color="primary"
        variant="contained"
        style={{margin: "0 12px"}}
        onClick={onSaveProjectChanges}
        disabled={areButtonsDisabled}
      >
        {saveCaption}
      </Button>
      <Button
        color="secondary"
        variant="contained"
        style={{margin: "0 12px"}}
        onClick={onCancelProjectChanges}
        disabled={areButtonsDisabled}
      >
        CANCEL
      </Button>
      {documentsCount > 0 ? (
        <Tooltip title="Only empty projects can be deleted">
          <span>{button}</span>
        </Tooltip>
      ) : (
        button
      )}
      {!isProjectAdded && (
        <Button
          color="primary"
          variant="contained"
          style={{margin: "0 12px"}}
          onClick={triggerArchiveProject}
        >
          {isArchived ? "UNARCHIVE" : "ARCHIVE"}
        </Button>
      )}
    </div>
  );
}

export default SaveButtons;
