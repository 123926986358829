import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function fetchTopicParameters(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPIC_PARAMETERS_FETCH,
    organisationId =>
      requestor
        .get(`${basePath}/organisation/${organisationId}/topicparameters`, {
          withCredentials: true,
        })
        .then(response => response.data),
  );
}
