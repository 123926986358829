import _ from "underscore";

function clearParameterValue(value) {
  if (value && Array.isArray(value.values)) {
    return {
      ...value,
      values: value.values.map(value =>
        _.omit(value, [
          "applications",
          "matchesCount",
          "live_applications",
          "live_matchesCount",
        ]),
      ),
    };
  }
  return value;
}

export default clearParameterValue;
