import React from "react";
import _ from "lodash";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import RefreshIssuesButton from "common_components/document/refresh_issues_button";

import Permissioner from "utils/permissioner";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    color: "#8e959b",
  },
};

export default class ActionMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shownDialog: null,
      projectId: props.project.id,
      isMenuOpen: false,
      isLoadStateMenuOpen: false,
    };
    this.menuButtonRef = React.createRef();
    this.loadStateItemRef = React.createRef();
  }

  render() {
    const {document, project} = this.props;
    return (
      <React.Fragment>
        <div style={styles.container}>
          {this.props.user.is_admin && (
            <RefreshIssuesButton
              document={document}
              project={project}
              runDocumentIssuesFind={this.props.runDocumentIssuesFind}
              reprocessDocumentRoles={this.props.reprocessDocumentRoles}
              styles={{marginBottom: 2, marginRight: 4}}
              renderEmptyDivIfNull={true}
            />
          )}
          {this.hasPermission("download-document") && (
            <IconButton
              size="small"
              style={styles.iconButton}
              onClick={
                document.file_extension === "pdf"
                  ? this.onDocumentDownloaded(
                      document.id,
                      document.name,
                      false,
                      false,
                      false,
                      true,
                    )
                  : this.onDocumentDownloaded(document.id, document.name)
              }
            >
              <GetAppIcon />
            </IconButton>
          )}
          <Tooltip title="Add New Revision">
            <IconButton
              size="small"
              style={styles.iconButton}
              onClick={this.props.showRevisionDialog}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            ref={this.menuButtonRef}
            size="small"
            style={styles.iconButton}
            onClick={() => this.setState({isMenuOpen: true})}
          >
            <MoreVertIcon />
          </IconButton>
          {this.renderMenu()}
        </div>
        {this.renderDialog("delete")}
        {this.renderDialog("changeProject")}
      </React.Fragment>
    );
  }

  hideMenu = () => {
    this.setState({
      isMenuOpen: false,
      isLoadStateMenuOpen: false,
    });
  };

  renderMenu() {
    const {user, document} = this.props;
    const {concat_load_state: loadState} = document;
    return (
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{horizontal: "right", vertical: "bottom"}}
        transformOrigin={{horizontal: "right", vertical: "top"}}
        anchorEl={this.menuButtonRef.current}
        open={this.state.isMenuOpen}
        onClick={() => this.hideMenu()}
        onClose={() => this.hideMenu()}
        onExit={() => this.hideMenu()}
      >
        {user.is_admin && (
          <MenuItem
            className="analyst-view"
            onClick={() => {
              this.props.toggleAnalystView(document.id);
            }}
          >
            Analyst view
          </MenuItem>
        )}
        {user.is_admin && <Divider />}
        {this.hasPermission("show-comparison-summary") && (
          <MenuItem
            className="view-summary"
            onClick={() => {
              this.props.viewSummaryPage(document.id);
            }}
          >
            View Summary
          </MenuItem>
        )}
        {this.hasPermission("email-report") && (
          <MenuItem
            className="email-button"
            onClick={this.onEmailReport(document.id)}
          >
            Email Report
          </MenuItem>
        )}
        {this.hasPermission("reclassify-document") && (
          <MenuItem
            className="reclassify"
            onClick={this.handleDocumentReclassified(
              document.id,
              document.last_edited,
              document.contract_type,
            )}
          >
            Reclassify
          </MenuItem>
        )}
        {user.is_admin && (
          <MenuItem
            className="regen-embeddings"
            onClick={this.regenerateDocumentEmbeddings(document.id)}
          >
            Regenerate Embeddings
          </MenuItem>
        )}
        <MenuItem className="delete-button" onClick={this.showDeleteDialogue}>
          Delete
        </MenuItem>
        <MenuItem onClick={this.showChangeProjectDialogue}>
          Change Project
        </MenuItem>
        {user.is_admin && (
          <MenuItem
            onClick={() => this.props.updateDocumentLoadState(document.id)}
          >
            Update load state
          </MenuItem>
        )}
        {user.is_admin && this.renderStateChangeMenu(loadState)}
        {user.is_admin && (
          <MenuItem className="show-logs" onClick={this.props.showDocumentLogs}>
            Show Logs
          </MenuItem>
        )}
        {user.is_admin && (
          <MenuItem onClick={this.showDocumentUploadLogs}>
            Show Upload Logs
          </MenuItem>
        )}
        {/* {document.file_extension === "docx" && (
          <MenuItem
            className="document-preview"
            onClick={this.props.onDocumentReview}
          >
            Document Preview
          </MenuItem>
        )} */}
      </Menu>
    );
  }

  renderStateChangeMenu(loadState) {
    let menuItems;
    switch (Math.max(...loadState)) {
      case 0:
        return null;
      case 1:
      case 9:
        menuItems = [
          <MenuItem
            key="classified-menuitem"
            onClick={this.setDocumentState(2)}
          >
            Classified
          </MenuItem>,
          <MenuItem key="approved-menuitem" onClick={this.setDocumentState(3)}>
            Approved
          </MenuItem>,
        ];
        break;
      case 2:
        menuItems = [
          <MenuItem key="approved-menuitem" onClick={this.setDocumentState(3)}>
            Approved
          </MenuItem>,
        ];
        break;
      case 3:
        menuItems = [
          <MenuItem
            key="classified-menuitem"
            onClick={this.setDocumentState(2)}
          >
            Classified
          </MenuItem>,
        ];
        break;
      default:
        break;
    }
    return (
      <MenuItem
        ref={this.loadStateItemRef}
        onClick={event => {
          event.stopPropagation();
          this.setState({isLoadStateMenuOpen: true});
        }}
      >
        <div>Set load state</div>
        <ArrowRightIcon style={{color: "#757575"}} />
        <Menu
          getContentAnchorEl={null}
          anchorOrigin={{horizontal: "left", vertical: "bottom"}}
          transformOrigin={{horizontal: "right", vertical: "center"}}
          anchorEl={this.loadStateItemRef.current}
          open={this.state.isLoadStateMenuOpen}
          onClick={() => this.hideMenu()}
          onClose={() => this.hideMenu()}
        >
          {menuItems}
        </Menu>
      </MenuItem>
    );
  }

  renderDialog(dialogType) {
    const dialogs = {
      delete: {
        className: "delete-dialogue",
        title: "Confirm document deletion",
        onConfirm: this.handleDocumentDelete,
        confirmButtonLabel: "Delete Document",
        open: this.state.shownDialog === "delete",
        component: <div>Are you sure you want to delete this document?</div>,
      },
      changeProject: {
        className: "change-project-dialogue",
        title: "Select new project",
        onConfirm: this.handleProjectChange,
        confirmButtonLabel: "Update",
        open: this.state.shownDialog === "changeProject",
        component: this.renderChangeProjectComponent(),
      },
    };
    return (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        className={dialogs[dialogType].className}
        open={dialogs[dialogType].open}
        fullWidth={true}
      >
        <DialogTitle>{dialogs[dialogType].title}</DialogTitle>
        <DialogContent>{dialogs[dialogType].component}</DialogContent>
        <DialogActions>
          <Button onClick={this.hideDialog} color="secondary" autoFocus>
            Cancel
          </Button>
          <Button onClick={dialogs[dialogType].onConfirm} color="primary">
            {dialogs[dialogType].confirmButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderChangeProjectComponent = () => {
    return (
      <Select
        value={this.state.projectId}
        onChange={this.onProjectDropdownChange}
        MenuProps={{
          style: {
            maxHeight: 300,
          },
        }}
      >
        {_.sortBy(this.props.projects, p => p.name.toLowerCase()).map(
          project => (
            <MenuItem key={project.id} value={project.id}>
              {project.name}
            </MenuItem>
          ),
        )}
      </Select>
    );
  };

  onProjectDropdownChange = event =>
    this.setState(() => ({projectId: event.target.value}));

  handleProjectChange = () => {
    if (this.state.projectId === this.props.project.id) {
      return null;
    }
    const {document} = this.props;
    this.props.updateDocument(document.id, {
      last_edited: document.last_edited,
      new_project_id: this.state.projectId,
    });
    this.hideDialog();
  };

  showDeleteDialogue = () => {
    this.showDialog("delete");
  };

  showChangeProjectDialogue = () => {
    this.showDialog("changeProject");
  };

  handleDocumentDelete = () => {
    const {document} = this.props;
    this.onDocumentDeleted(document.id, document.last_edited)();
    this.hideDialog();
  };

  showDialog = shownDialog => {
    this.setState(() => ({shownDialog}));
  };

  hideDialog = () => this.setState(() => ({shownDialog: null}));

  setDocumentState = load_state => () => {
    const {document} = this.props;
    this.props.updateDocument(document.id, {
      last_edited: document.last_edited,
      load_state,
    });
  };

  hasPermission = permission => {
    return new Permissioner(this.props.user).hasPermission(permission);
  };

  onDocumentDownloaded = _.memoize(
    (
      documentId,
      documentName,
      withChanges,
      withMarkup,
      withComments,
      withoutExtension,
    ) => () =>
      this.props.onDocumentDownloaded(
        documentId,
        documentName,
        withChanges,
        withMarkup,
        withComments,
        withoutExtension,
      ),
  );

  onEmailReport = _.memoize(documentId => () =>
    this.props.onEmailReport(documentId),
  );

  handleDocumentReclassified = _.memoize(
    (documentId, lastEdited, contractType) => (...args) => {
      this.props.handleDocumentReclassified(
        documentId,
        lastEdited,
        contractType,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );
  regenerateDocumentEmbeddings = _.memoize(
    documentId => () => {
      this.props.regenerateDocumentEmbeddings(documentId);
    },
    (...args) => JSON.stringify([...args]),
  );

  onDocumentDeleted = _.memoize((documentId, lastEdited) => (...args) =>
    this.props.onDocumentDeleted(documentId, lastEdited, ...args),
  );

  showDocumentUploadLogs = () =>
    this.props.showDocumentUploadLogs(this.props.document.id);
}
