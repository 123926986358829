import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router";
import LinkIcon from "@material-ui/icons/Link";
import StarRateIcon from "@material-ui/icons/StarRate";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import WorkflowCreator from "./workflow_creator";

import {
  WorkflowContext,
  WorkflowId,
  WorkflowInfo,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";
import groupWorkflowsByContext from "utils/group_workflows_by_context";
import {Button, Checkbox, FormControlLabel} from "@material-ui/core";
import FlushAssistantsDialog from "./flush_assistants_dialog";
import {FlushAssistantsResponse} from "../containers/workflow_list";

type Props = {
  workflows: WorkflowInfo[];
  contexts: WorkflowContext[];
  organisationId: OrganisationId;
  addWorkflow: (name: string) => void;
  updateWorkflow: (
    workflowId: WorkflowId,
    lastEdited: Date,
    data: {is_star?: boolean; is_archived?: boolean},
  ) => void;
  flushAssistants: () => Promise<FlushAssistantsResponse>;
};

export default function WorkflowListComponent({
  workflows,
  contexts,
  organisationId,
  addWorkflow,
  updateWorkflow,
  flushAssistants,
}: Props) {
  const [
    showingFlushAssistantsDialog,
    setShowingFlushAssistantsDialog,
  ] = useState(false);
  const [showArchived, setShowArchived] = useState(() => {
    const savedValue = sessionStorage.getItem("showArchivedWorkflows");
    return savedValue ? JSON.parse(savedValue) : false;
  });

  useEffect(() => {
    sessionStorage.setItem(
      "showArchivedWorkflows",
      JSON.stringify(showArchived),
    );
  }, [showArchived]);

  const filteredWorkflows = useMemo(() => {
    return workflows.filter(workflow => !workflow.is_archived || showArchived);
  }, [workflows, showArchived]);

  const groupedWorkflows = useMemo(() => {
    return groupWorkflowsByContext(filteredWorkflows, contexts).filter(
      group => group.workflows.length > 0,
    );
  }, [filteredWorkflows, contexts]);

  const handleToggle = (
    workflow: WorkflowInfo,
    property: "is_star" | "is_archived",
  ) => {
    updateWorkflow(workflow.id, workflow.last_edited, {
      [property]: !workflow[property],
    });
  };

  return (
    <div style={{padding: "1em", position: "relative"}}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>WORKFLOWS</h1>
        <div>
          <Button
            style={{marginRight: "2em"}}
            variant="contained"
            onClick={() => setShowingFlushAssistantsDialog(true)}
          >
            FLUSH ALL ASSISTANTS
          </Button>
          <FormControlLabel
            control={
              <Checkbox
                checked={showArchived}
                onChange={e => setShowArchived(e.target.checked)}
                color="primary"
              />
            }
            label="Show archived"
          />
        </div>
      </div>
      {groupedWorkflows.length > 0 &&
        groupedWorkflows.map(group => (
          <div key={group.id}>
            <h2>{group.name}</h2>
            {group.workflows.map(workflow => (
              <div
                style={{
                  marginBottom: "0.5em",
                  display: "flex",
                  alignItems: "center",
                  opacity: workflow.is_archived ? 0.5 : 1,
                }}
                key={workflow.id}
              >
                <div
                  style={{cursor: "pointer", marginRight: "0.5em"}}
                  onClick={() => handleToggle(workflow, "is_star")}
                >
                  {workflow.is_star ? (
                    <StarRateIcon style={{color: "#fbc02d"}} />
                  ) : (
                    <StarBorderIcon style={{color: "#fbc02d"}} />
                  )}
                </div>
                <div
                  style={{cursor: "pointer", marginRight: "0.5em"}}
                  onClick={() => handleToggle(workflow, "is_archived")}
                >
                  {workflow.is_archived ? (
                    <UnarchiveIcon style={{color: "#3d3d3d"}} />
                  ) : (
                    <ArchiveIcon style={{color: "#3d3d3d"}} />
                  )}
                </div>
                <Link
                  to={`/organisation/${organisationId}/workflow/${workflow.id}/detail`}
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LinkIcon style={{marginRight: "0.5em"}} />
                  {workflow.name}
                </Link>
              </div>
            ))}
          </div>
        ))}
      <div style={{marginTop: "1em"}}>
        <WorkflowCreator addWorkflow={addWorkflow} />
      </div>
      <FlushAssistantsDialog
        open={showingFlushAssistantsDialog}
        onClose={() => setShowingFlushAssistantsDialog(false)}
        onFlushAssistants={flushAssistants}
      />
    </div>
  );
}
