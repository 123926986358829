/* eslint-disable no-unused-vars */
import React from "react";
/* eslint-enable no-unused-vars */
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import requestor from "requestor";

import {isInitialised} from "utils/uninitialised";
import Permissioner from "utils/permissioner";
import setTitle from "utils/set_title";

import ConfigDetailComponent from "../components/config_detail";

import updateOrganisationAction from "modules/organisation/actions/organisation_update";

class ConfigBase extends React.Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return this.permissioner.hasPermission("organisation-admin");
  }

  shouldRenderContainer() {
    const {props} = this;
    return isInitialised([
      props.organisation,
      props.user,
      props.projects,
      props.permissionGroups,
    ]);
  }

  renderComponent() {
    setTitle("Config");
    const {props} = this;
    return (
      <ConfigDetailComponent
        organisation={props.organisation}
        user={props.user}
        projects={props.projects}
        permissionGroups={props.permissionGroups}
        onOrganisationUpdate={this.onOrganisationUpdate}
        updateOrganisation={this.props.updateOrganisation} // TODO: remove
      />
    );
  }

  hasPermission = permission => {
    return this.permissioner.hasPermission(permission);
  };
  onOrganisationUpdate = updates => {
    const {organisationId} = this.props.params;
    this.props.updateOrganisation(organisationId, updates);
  };
}

function select(state, props) {
  return {
    params: props.params,
    organisation: state.organisation,
    router: state.router,
    user: state.user,
    projects: state.projects,
    permissionGroups: state.permission_groups,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateOrganisation: updateOrganisationAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(ConfigBase);
export const Component = ConfigBase;
