import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function addTopicParameter(requestor) {
  return createReduxPromiseAction(
    ActionTypes.ISSUE_ADD,
    (organisationId, newIssue) =>
      requestor
        .post(`${basePath}/organisation/${organisationId}/issue`, newIssue, {
          withCredentials: true,
        })
        .then(response => {
          if (Array.isArray(response.data)) {
            if (response.data.length === 1) {
              return [
                {
                  ...response.data[0],
                  ...newIssue,
                },
              ];
            }
            return response.data;
          }
          return {
            ...response.data,
            ...newIssue,
          };
        }),
  );
}
