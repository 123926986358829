import requestor from "requestor";

import BaseRoute from "utils/base_route";
import getRouteIds from "utils/get_route_ids";

import DocumentClausesContainer from "./containers/document_clauses";

import documentFetchAction from "modules/documents/actions/document_fetch";
import documentTopicsFetchAction from "modules/documents/actions/document_topics_fetch";
import topicCategoriesFetchAction from "modules/documents/actions/topic_categories_fetch";
import topicTagsFetchAction from "modules/documents/actions/topic_tags_fetch";
import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";

import documentClearAction from "modules/documents/actions/document_clear";
import topicsClearAction from "modules/documents/actions/topics_clear";
import topicCategoriesClearAction from "modules/documents/actions/topic_categories_clear";
import topicTagsClearAction from "modules/documents/actions/topic_tags_clear";
import contractTypesClearAction from "modules/documents/actions/contract_types_clear";
import documentsFetchAction from "modules/documents/actions/documents_fetch";

export default class DocumentClauses extends BaseRoute {
  constructor(store) {
    super(":documentId", store);
  }

  getComponents(location, cb) {
    cb(null, DocumentClausesContainer);
  }

  fetchActiveData(props) {
    this.fetchDocumentClauses(props);
    this.fetchTopics(props);
    this.fetchTopicCategories(props);
    this.fetchTopicTags(props);
    this.fetchContractTypes(props);
    this.fetchDocuments(props);
  }

  fetchDocumentClauses(props) {
    const {organisationId, projectId, documentId} = getRouteIds(props.router);
    if (documentId) {
      this.store.dispatch(
        documentFetchAction(requestor)(organisationId, projectId, documentId, {
          fetch_document_plain_text: true,
        }),
      );
    }
  }

  fetchTopics(props) {
    const {organisationId, projectId, documentId} = getRouteIds(props.router);
    this.store.dispatch(
      documentTopicsFetchAction(requestor)(
        organisationId,
        projectId,
        documentId,
      ),
    );
  }

  fetchTopicCategories(props) {
    const {organisationId} = getRouteIds(props.router);
    this.store.dispatch(topicCategoriesFetchAction(requestor)(organisationId));
  }

  fetchTopicTags(props) {
    const {organisationId} = getRouteIds(props.router);
    this.store.dispatch(topicTagsFetchAction(requestor)(organisationId));
  }

  fetchContractTypes(props) {
    const {organisationId} = getRouteIds(props.router);
    this.store.dispatch(contractTypesFetchAction(requestor)(organisationId));
  }

  fetchDocuments(props) {
    const {organisationId, projectId} = getRouteIds(props.router);
    if (projectId) {
      this.store.dispatch(
        documentsFetchAction(requestor)(organisationId, projectId),
      );
    }
  }

  clearActiveData(props) {
    this.clearDocumentClauses(props);
    this.clearTopics(props);
    this.clearTopicCategories(props);
    this.clearTopicTags(props);
    this.clearContractTypes(props);
  }

  clearDocumentClauses(props) {
    const {organisationId, projectId, documentId} = getRouteIds(props.router);
    this.store.dispatch(
      documentClearAction(organisationId, projectId, documentId),
    );
  }

  clearTopics(props) {
    const {organisationId} = getRouteIds(props.router);
    this.store.dispatch(topicsClearAction(organisationId));
  }

  clearTopicCategories(props) {
    const {organisationId} = getRouteIds(props.router);
    this.store.dispatch(topicCategoriesClearAction(organisationId));
  }

  clearTopicTags(props) {
    const {organisationId} = getRouteIds(props.router);
    this.store.dispatch(topicTagsClearAction(organisationId));
  }

  clearContractTypes(props) {
    const {organisationId} = getRouteIds(props.router);
    this.store.dispatch(contractTypesClearAction(organisationId));
  }
}
