import React, {useState} from "react";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";

function TabPanel(props) {
  const {children, value, index} = props;
  return (
    <Paper
      style={{
        flexGrow: value === index ? 1 : 0,
        overflow: "auto",
      }}
      elevation={1}
      square
    >
      {value === index && children}
    </Paper>
  );
}

function IssueDetailTabs(props) {
  const {location} = props;

  const [value, setValue] = useState(0);
  React.useEffect(() => {
    if (
      location &&
      (isOverridesTab(location.search) || isOverridesTab(location.pathname))
    ) {
      setValue(3);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar
        position="static"
        color="default"
        style={{display: "flex", flexDirection: "row", alignItems: "center"}}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          style={{flexGrow: 1}}
        >
          <Tab label="General" />
          <Tab label="Display" />
          <Tab label="Usage" />
          <Tab label="Overrides" />
          <Tab label="Logs" />
        </Tabs>
        <div className="tabs-toolbar" />
      </AppBar>
      <TabPanel value={value} index={0}>
        {props.generalTab}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {props.displayTab}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {props.usageTab}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {props.overridesTab}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {props.logsTab}
      </TabPanel>
    </>
  );
}

function isOverridesTab(path) {
  return path && path.indexOf("tab=overrides") !== -1;
}

export default IssueDetailTabs;
