import requestor from "requestor";

import ChildRenderer from "common_components/child_renderer";

import BaseRoute from "utils/base_route";

import permissionGroupsFetchAction from "modules/permissions/actions/permission_groups_fetch";

import UserList from "routes/user/user_list";
import UserDetail from "routes/user/user_detail";

export default class UserBase extends BaseRoute {
  constructor(store) {
    super("user", store);
  }

  createChildRoutes() {
    return [new UserList(this.store), new UserDetail(this.store)];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData() {
    this.fetchPermissionGroups();
  }

  fetchPermissionGroups() {
    this.store.dispatch(permissionGroupsFetchAction(requestor)());
  }
}
