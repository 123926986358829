import basePath from "base_path";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes, {AccesstagActionTypes} from "../constants/action_types";

export type Accesstag = {id: number; name: string};

export type FetchAccesstagsAction = {
  type: AccesstagActionTypes["ACCESSTAGS_FETCH"]["SUCCESS"];
  payload: Accesstag[];
};

export default createReduxPromiseAction(
  // TODO: Fix types for createReduxPromiseAction. Difficult because it mutates
  // arguments.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ActionTypes.ACCESSTAGS_FETCH,
  (organisationId: number) =>
    requestor
      .get(`${basePath}/organisation/${organisationId}/accesstags`, {
        withCredentials: true,
      })
      .then(response => response.data),
);
