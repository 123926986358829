import requestor from "requestor";

import ChildRenderer from "common_components/child_renderer";

import BaseRoute from "utils/base_route";

import promptsFetchAction from "modules/prompts/actions/prompts_fetch";
import promptsClearAction from "modules/prompts/actions/prompts_clear";

import PromptGroupList from "routes/prompt/prompt_list";
import PromptDetail from "routes/prompt/prompt_detail";

export default class promptsBase extends BaseRoute {
  constructor(store) {
    super("prompt", store);
  }

  createChildRoutes() {
    return [new PromptGroupList(this.store), new PromptDetail(this.store)];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchPrompts(state);
  }

  fetchPrompts(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(promptsFetchAction(requestor)(organisationId));
  }

  clearActiveData(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(promptsClearAction(organisationId));
  }
}
