const styles = {
  table: {
    borderCollapse: "collapse",
    width: "100%",
    height: "1px",
    fontFamily: "Roboto, sans-serif",
    color: "#798087",
  },
  headerRow: {},
  headerCell: {
    padding: 0,
    whiteSpace: "nowrap",
    backgroundColor: "#fafbfd",
    fontSize: 13,
    color: "#232e38",
    height: "100%",
  },
  stickyHeaderCell: {
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  headerCellContainer: {
    display: "flex",
    alignItems: "center",
    padding: "14px 8px",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    boxShadow: "0px 2px 0px #8d949a",
  },
  headerCellInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerCellInnerSortable: {
    cursor: "pointer",
    userSelect: "none",
  },
  bodyRow: {
    borderBottom: "1px solid #e6eaf0",
  },
  lastBodyRow: {
    borderBottom: "none",
  },
  bodyCell: {
    padding: 0,
    fontSize: 14,
    overflow: "hidden",
  },
  bodyCellContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "10px 8px",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
  },
  loadMoreButtonListContainer: {
    paddingTop: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadMoreButtonList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0px",
  },
  loadMoreButton: {
    margin: "0 10px",
  },
  linkCell: {
    textDecoration: "none",
    color: "inherit",
    height: "100%",
    width: "100%",
    display: "block",
  },
};

export default styles;
