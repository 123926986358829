const styles = {
  selectedLevel: {
    border: "1px solid #ddf",
    background: "#fcfcff",
  },
  selectedPath: {
    background: "#f8fff8",
  },
};
export default styles;
