import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../../constants/action_types";

/* eslint-disable max-params */
export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_HEADING_ADD,
  (organisationId, projectId, documentId, sectionId, reference, text) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/section/${sectionId}/heading/add`,
        {reference, text},
        {withCredentials: true},
      )
      .then(response => response.data),
);
