const clauseChangeConstructor = {
  clausepart_deletion: (documentId, sectionId, clauseId, clausepart) => ({
    id: null,
    creation_date: null,
    type: "clausepart_deletion",
    old_document_id: documentId,
    new_document_id: documentId,
    old_section_id: sectionId,
    new_section_id: sectionId,
    old_clause_id: clauseId,
    new_clause_id: clauseId,
    old_clausepart_id: clausepart.id,
    new_clausepart_id: clausepart.id,
    new_start_offset: 0,
    new_end_offset: 0,
    old_start_offset: 0,
    old_end_offset: clausepart.partial_text.length - 1,
    old_text: clausepart.partial_text,
    new_text: "",
    old_reference: clausepart.reference,
    new_reference: "",
    old_clausepart_order: clausepart.order,
    old_path: clausepart.path,
  }),
  definition_deletion: (documentId, documentClause, term) => ({
    id: null,
    creation_date: null,
    type: "definition_deletion",
    old_document_id: documentId,
    new_document_id: documentId,
    old_section_id: documentClause.section_id,
    new_section_id: documentClause.section_id,
    old_clause_id: documentClause.id,
    new_clause_id: documentClause.id,
    old_reference: term,
    new_reference: term,
  }),
  clauseheading_deletion: (documentId, heading) => ({
    id: null,
    creation_date: null,
    type: "clauseheading_deletion",
    old_document_id: documentId,
    new_document_id: documentId,
    old_section_id: heading.section_id,
    new_section_id: heading.section_id,
    new_start_offset: 0,
    new_end_offset: 0,
    old_start_offset: 0,
    old_end_offset: heading.text.length - 1,
    old_text: heading.text,
    new_text: "",
    old_heading_id: heading.id,
    new_heading_id: heading.id,
  }),
  clause_deletion: (documentId, documentClause) => ({
    id: null,
    creation_date: null,
    type: "clause_deletion",
    old_document_id: documentId,
    new_document_id: documentId,
    old_section_id: documentClause.section_id,
    new_section_id: documentClause.section_id,
    old_clause_id: documentClause.id,
    new_clause_id: documentClause.id,
    old_reference: documentClause.full_reference,
    new_reference: documentClause.full_reference,
    old_clause_data: documentClause,
  }),
};

export default clauseChangeConstructor;
