import BaseRoute from "utils/base_route";

import RoleDetailContainer from "./containers/role_detail";

export default class RoleDetail extends BaseRoute {
  constructor(store) {
    super(":roleId/detail", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, RoleDetailContainer);
  }
}
