import React from "react";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

import Checkbox from "common_components/checkbox";

import constructClausepartDomElement from "./utils/construct_clausepart_dom_element";

const styles = {
  dialogContent: {
    width: "80%",
    maxWidth: "none",
  },
};

function getAllUsageIds(usage) {
  const childrenIds = (usage.childrenUsages || []).reduce(
    (result, childUsage) => {
      if (childUsage.has_topic) {
        result.push(childUsage.clausepart_id);
      }
      return result;
    },
    [],
  );
  return [usage.clausepart_id, ...childrenIds];
}

class TopicUsageDeletionDialog extends React.Component {
  constructor(props) {
    super(props);
    const usageIds = getAllUsageIds(props.usage);
    this.state = {selectedIds: usageIds};
    this.totalUsagesCount = usageIds.length;
  }
  render() {
    const {usage} = this.props;
    const {selectedIds} = this.state;
    const partiallySelected =
      selectedIds.length !== 0 && this.totalUsagesCount !== selectedIds.length;
    return (
      <Dialog
        title="Delete Topic From Clauses"
        open={true}
        onDismiss={this.props.onDialogClose}
        actions={this.getActionButtons()}
        contentStyle={styles.dialogContent}
        autoScrollBodyContent={true}
      >
        <table style={{borderCollapse: "collapse"}}>
          <thead>
            <tr style={{height: "2rem"}}>
              <th style={{width: "2em", textAlign: "left"}}>
                <Checkbox
                  checked={this.totalUsagesCount === selectedIds.length}
                  partiallyChecked={partiallySelected}
                  onCheck={
                    partiallySelected || selectedIds.length === 0
                      ? this.checkAll
                      : this.uncheckAll
                  }
                />
              </th>
              <th style={{width: "30em"}}>Text</th>
            </tr>
          </thead>
          <tbody>
            {this.renderUsageItem(usage, true)}
            {usage.childrenUsages.reduce((result, childUsage) => {
              if (childUsage.has_topic) {
                result.push(this.renderUsageItem(childUsage, false));
              }
              return result;
            }, [])}
          </tbody>
        </table>
      </Dialog>
    );
  }

  getActionButtons = () => {
    return [
      <FlatButton
        key="confirm-button"
        label="Confirm"
        onClick={this.onDialogConfirm}
      />,
      <FlatButton
        key="cancel-button"
        label="Cancel"
        secondary={true}
        onClick={this.props.onDialogClose}
      />,
    ];
  };

  renderUsageItem = (usage, renderFullText) => {
    const isChecked = Boolean(
      this.state.selectedIds.find(
        selectedId => selectedId === usage.clausepart_id,
      ),
    );
    const onItemCheck = this.onUsageChecked(usage.clausepart_id, isChecked);
    return (
      <tr
        key={`${usage.clausepart_path}`}
        style={{
          cursor: "pointer",
          borderBottom: renderFullText ? "1px solid #E0E0E0" : "none",
        }}
        onClick={onItemCheck}
      >
        <td style={{verticalAlign: "top"}}>
          <Checkbox checked={isChecked} onCheck={onItemCheck} />
        </td>
        <td>
          {renderFullText
            ? constructClausepartDomElement(
                {
                  regexClassifier: this.props.regexClassifier,
                  showMatchRegexes: this.props.showMatchRegexes,
                },
                usage,
              )
            : usage.clausepart_partial_text}
        </td>
      </tr>
    );
  };

  checkAll = () =>
    this.setState(() => ({selectedIds: getAllUsageIds(this.props.usage)}));

  uncheckAll = () => this.setState(() => ({selectedIds: []}));

  onUsageChecked = (usageId, isChecked) => () => {
    const {selectedIds} = this.state;
    const newSelectedIds = isChecked
      ? selectedIds.filter(selectedId => selectedId !== usageId)
      : [...selectedIds, usageId];
    this.setState(() => ({selectedIds: newSelectedIds}));
  };

  onDialogConfirm = () => {
    const {selectedIds} = this.state;
    if (selectedIds.length > 0) {
      this.props.removeTopicFromClauses(selectedIds);
    }
    this.props.onDialogClose();
  };
}

export default TopicUsageDeletionDialog;
