import React from "react";
import {bindActionCreators} from "redux";

import Permissioner from "utils/permissioner";
import {connect} from "react-redux";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";

import projectCreateAction from "modules/projects/actions/project_create";
import updateOrganisationUserAction from "modules/organisation/actions/organisation_user_update";

import ProjectComponent from "../components/project";
import defaultProject from "../constants/default_project";
import requestor from "requestor";

import byId from "common/utils/by_id";
import getPermittedDocumentTypes from "common/utils/get_permitted_document_types";

class Project extends React.Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return this.permissioner.hasPermission("get-project-detail");
  }

  shouldRenderContainer() {
    return (
      isInitialised([
        this.props.projectroles,
        this.props.organisation,
        this.props.contractTypes,
        this.props.documentTypes,
        this.props.user,
      ]) && this.props.user["logged-in"] !== "unknown"
    );
  }

  renderComponent() {
    return this.renderProjectt();
  }

  renderProjectt() {
    const project = {
      ...defaultProject,
      name: `Project ${this.props.lastProject + 1}`,
    };
    setTitle(`${project.name}`);
    return (
      <ProjectComponent
        project={project}
        projectroles={this.props.projectroles}
        lastProject={this.props.lastProject}
        onIsDefaultChanged={this.updateUserDefaultProject}
        onProjectAdd={this.onProjectAdd}
        user={this.props.user}
        organisation={this.props.organisation}
        contractTypesById={byId(this.props.contractTypes)}
        contractTypes={this.props.contractTypes}
        documentTypes={getPermittedDocumentTypes(
          this.props.documentTypes,
          this.permissioner,
        )}
      />
    );
  }

  updateUserDefaultProject = async () => {
    await this.props.updateOrganisationUser(
      this.props.organisation.id,
      this.props.user.id,
      {default_project_id: parseInt(this.props.params.projectId, 10)},
    );
  };

  onProjectAdd = data => {
    const {organisationId} = this.props.params;
    return this.props.projectCreate(organisationId, data);
  };
}

function select(state) {
  return {
    lastProject: (state.projects && state.projects.length) || 0,
    projectroles: state.projectroles,
    router: state.router,
    user: state.user,
    organisation: state.organisation,
    contractTypes: state.contract_types,
    documentTypes: state.document_types,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateOrganisationUser: updateOrganisationUserAction(requestor),
      projectCreate: projectCreateAction(requestor),
    },
    dispatch,
  );
}

export default connect(select, mapDispatchToProps)(Project);
export const Component = Project;
