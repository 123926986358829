import React from "react";

function TopicAnalysisUsageInfoCard() {
  const logicData = [
    {
      term: "TP",
      subterm: "true positive",
      definition: "Classifier applied and topic applied to clause",
    },
    {
      term: "FP",
      subterm: "false positive",
      definition: "Classifier applied but topic not applied to clause",
    },
    {
      term: "FN",
      subterm: "false negative",
      definition: "Classifier is not applied but topic applied to clause",
    },
    {
      term: "TN",
      subterm: "true negative",
      definition: "Classifier not applied and topic is not applied to clause",
    },
  ];

  const textColorDataTop = [
    {
      text: "whitelist regex match",
      color: "#76b54a",
    },
    {
      text: "blacklist regex match",
      color: "#f00",
    },
  ];
  const textColorDataBottom = [
    {
      text: "partial text",
      color: "black",
    },
    {
      text: "not partial text",
      color: "#888888",
    },
    {
      text: "definition",
      color: "#aaf",
    },
  ];

  const logicDataItems = logicData.map((infoItem, index) => (
    <div key={`${infoItem.term}-${index}`}>
      <span style={{fontWeight: "800"}}>{infoItem.term}</span>
      {infoItem.subterm && <span>{` (${infoItem.subterm})`}</span>}
      <span>: </span>
      <span>{infoItem.definition}</span>
    </div>
  ));
  return (
    <div>
      {logicDataItems}
      <div style={{fontWeight: "600"}}>Text colors legend:</div>
      <div>{renderTextColorItems(textColorDataTop)}</div>
      <div>{renderTextColorItems(textColorDataBottom)}</div>
    </div>
  );
}

function renderTextColorItems(textColorData) {
  return textColorData.map((textColorItem, index) => (
    <span
      key={`${textColorItem.text}-${index}`}
      style={{color: textColorItem.color, marginRight: "10px"}}
    >
      {textColorItem.text}
    </span>
  ));
}

export default TopicAnalysisUsageInfoCard;
