export const clauseBankStyle = {
  clauseBankContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    margin: "20px",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  clauseBankItem: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    margin: "10px",
  },
  clauseBankTitle: {
    display: "flex",
  },
  clauseBankBadge: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    borderRadius: "50%",
    backgroundColor: "#1f88e5",
    width: "20px",
    height: "20px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "500",
    lineHeight: "1.75",
    fontSize: "0.8125rem",
    marginLeft: "10px",
  },
};

export const clauseItemStyle = {
  clauseContainer: {
    border: "2px solid #dee3ed",
    borderRadius: "5px",
    backgroundColor: "#f8f9fc",
    boxShadow: "none",
  },
  clauseItemContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  summary: {
    width: "100%",
    flexDirection: "row-reverse",
    padding: "0px",
  },
  titleContainer: {
    marginLeft: "15px",
  },
  buttonsContainer: {
    marginRight: "15px",
  },
  title: {
    fontSize: "20px",
    margin: 0,
  },
  description: {
    margin: 0,
    marginTop: "10px",
  },
  detailContainer: {
    display: "flex",
    borderTop: "1px solid #dee3ed",
    padding: 0,
  },
  headerDescription: {
    display: "flex",
    flexDirection: "column",
  },
  headerDescriptionText: {
    margin: 0,
  },
  preventCommentText: {
    margin: 0,
    textOverflow: "ellipsis",
  },
  details: {
    width: "100%",
  },
  detailsDescription: {
    padding: "20px",
    borderLeft: "1px solid #dee3ed",
    flexBasis: "300px",
  },
  detailsDescriptionTitle: {
    margin: "0 0 10px 0",
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    color: "#8f96a3",
  },
  detailsDescriptionText: {
    margin: "0 0 20px 0",
    fontSize: "18px",
  },
  detailsItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  detailsItemText: {
    padding: "20px",
  },
  detailsItemButton: {
    flex: "0 0 120px",
    marginTop: "20px",
    height: "100%",
  },
};
