import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export function transition(prevState, nextState, action) {
  if (action.type === ActionTypes.PROJECT_TASK_CREATE.SUCCESS) {
    const {organisationId, projectId, id: taskId} = action.payload;
    return {
      pathname:
        `/organisation/${organisationId}/project/${projectId}` +
        `/task/${taskId}`,
    };
  }
  return null;
}

export default function(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PROJECT_TASK_CREATE,
    (organisationId, projectId, order) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/project/${projectId}/task`,
          {
            name: "New Task",
          },
          {withCredentials: true},
        )
        .then(response => ({
          organisationId,
          projectId,
          order,
          ...response.data,
        }));
    },
    null,
    {transition},
  );
}
