import React from "react";

export default class Heading extends React.Component {
  render() {
    const {heading, isBackground} = this.props;
    const ref = heading.reference;
    const level = ref.substring(0, ref.length - 1).split(".").length - 1;

    const style = {
      fontSize: `${(1.5 * (1 - 0.2 * level)).toFixed(2)}em`,
      lineHeight: `${(2 * (1 - 0.1 * level)).toFixed(2)}em`,
    };
    return (
      <div
        style={{
          fontWeight: "bold",
        }}
      >
        <span
          style={{
            display: "inline-block",
          }}
        >
          <span className="reference" style={style}>
            {isBackground ? "" : heading.reference}
          </span>
        </span>
        <span className="text" style={style}>
          {heading.text}
        </span>
      </div>
    );
  }
}
