import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function emailDocumentReport(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_EMAIL_REPORT,
    (organisationId, projectId, documentId, issuesetId, type = "default", data = {}) =>
      requestor["post"](
        `${basePath}/organisation/${organisationId}` +
          `/project/${projectId}/document/${documentId}/issueset/${issuesetId}/email/${type}`,
        data,
        {withCredentials: true},
      ).then(response => response.data),
  );
}
