import React from "react";
import _ from "underscore";

import NumberedItem from "./numbered_item";
import Topics from "./topics";

import renderNode from "../utils/render_node";
import renderNumber from "../utils/render_number";
import doesLevelMatch from "../utils/does_level_match";
import commonStyles from "../utils/common_styles";

export default class ClauseList extends React.Component {
  render() {
    const {
      path,
      clause,
      parent,
      changes,
      isInteractive,
      selectedLevel,
      selectedPath,
      onTopicsHover,
    } = this.props;
    const {length: nodeCount} = clause.clauseNodes;
    const deletions = changes.filter(
      change => change.type === "clausepart_deletion",
    );
    let remainingDeletions = deletions;
    const isMatchedLevel = doesLevelMatch(selectedLevel, clause, parent);
    const isSelectedLevel = isInteractive && isMatchedLevel;
    const isSelectedPath = isInteractive && selectedPath === clause.path;
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          border: isSelectedLevel ? commonStyles.selectedLevel.border : "none",
          /* eslint-disable no-nested-ternary */
          backgroundColor: isSelectedLevel
            ? commonStyles.selectedLevel.background
            : isSelectedPath ? commonStyles.selectedPath.background : "inherit",
          /* eslint-enable no-nested-ternary */
        }}
        className={[
          isSelectedLevel ? "selected-node" : "",
          isSelectedPath ? "highlighted-node" : "",
        ].join(" ")}
      >
        <div
          className="items"
          style={{
            width: isMatchedLevel ? "70%" : "100%",
          }}
        >
          {_.flatten(
            clause.clauseNodes.map((child, index) => {
              const item = this.renderClauseItem(
                child,
                index,
                index === 0,
                index === nodeCount - 1,
              );
              const matchingDeletions = deletions.filter(
                change =>
                  change.old_path === `${path}[${index}]` ||
                  (change.old_path.indexOf(`${path}[${index}]`) === 0 &&
                    child.type.endsWith("Atom")),
              );
              remainingDeletions = _.without(
                remainingDeletions,
                ...matchingDeletions,
              );
              if (matchingDeletions.length > 0) {
                const deletedItems = matchingDeletions.map(deletion =>
                  this.renderDeletion(deletion, index, nodeCount),
                );
                return [item, ...deletedItems];
              }
              return item;
            }),
          )}
          {remainingDeletions.map(deletion =>
            this.renderDeletion(deletion, nodeCount, nodeCount),
          )}
        </div>
        <Topics {...this.props} onHover={onTopicsHover} />
      </div>
    );
  }

  renderDeletion(deletion, index, nodeCount) {
    const deletedClause = {
      id: deletion.old_path,
      type: "Atom",
      text: deletion.old_text,
      load_state: 7,
      topics: deletion.old_clausepart_topics,
      last_edited: "",
      changes: [],
    };
    return this.renderClauseItem(
      deletedClause,
      index,
      false,
      index === nodeCount - 1,
      true,
    );
  }

  renderClauseItem(clause, index, start, end, isDeletion = false) {
    const {counterType, type, conjunctionType, topics} = this.props.clause;
    const isUnnumbered = !type || type.substring(0, 11) === "ClausePartU";
    const number = renderNumber(counterType, index, this.props.reference);
    const reference = this.getReference(number);
    const changes = this.props.changes.filter(
      change => change.type !== "clausepart_deletion",
    );
    const args = [
      clause,
      this.props.clause,
      {
        ...this.props,
        index,
        changes,
        is_deletion: isDeletion,
        lacksTopics: this.props.lacksTopics && (!topics || topics.length === 0),
      },
      reference,
    ];
    if (isUnnumbered) {
      const node = renderNode(...args);
      return node;
    }
    const node = renderNode(...args);
    return (
      <NumberedItem
        className="numbered-item"
        clause={clause}
        node={node}
        parent={this.props.clause}
        key={`${clause.id}_${reference}_${index}`}
        number={reference}
        start={start}
        end={end}
        conjunctionType={conjunctionType}
        shiftLeft={counterType === "DECIMAL"}
        onClick={this.props.onReferenceClick}
      />
    );
  }

  getReference(number) {
    const {counterType} = this.props.clause;
    const {reference} = this.props;
    if (counterType === "DECIMAL") {
      return `${reference}.${number}`;
    } else if (!counterType) {
      return reference;
    }
    return number;
  }
}
