import requestor from "requestor";
import BaseRoute from "utils/base_route";

import TopicList from "./containers/topic_list";

import topicsFetchAction from "modules/documents/actions/topics_fetch";
import topicsClearAction from "modules/documents/actions/topics_clear";

export default class TopicListRoute extends BaseRoute {
  constructor(store) {
    super("list", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, TopicList);
  }

  fetchActiveData(state) {
    this.fetchTopics(state);
  }

  fetchTopics(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(topicsFetchAction(requestor)(organisationId));
  }

  clearActiveData(state) {
    const {organisationId} = state.params;
    this.store.dispatch(topicsClearAction(organisationId));
  }
}
