import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";

import byId from "common/utils/by_id";

import customValueFields from "../utils/custom_value_fields";

import CustomValueSummary from "./task_custom_value_summary";
import CustomValueSelector from "./task_custom_value_selector";
import RoleSummary from "./task_role_summary";
import RolePartyChecker from "./task_role_party_checker";
import RoleSelector from "./task_role_selector";
import CommentView from "./comments";
import statusOptions from "modules/tasks/constants/status_options";

import CloseIcon from "material-ui/svg-icons/navigation/close";
import MoreIcon from "material-ui/svg-icons/navigation/more-vert";
import ExpandIcon from "material-ui/svg-icons/navigation/expand-more";

import Paper from "material-ui/Paper";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import DatePicker from "material-ui/DatePicker";
import IconButton from "material-ui/IconButton";
import Card from "material-ui/Card";
import {Toolbar, ToolbarGroup} from "material-ui/Toolbar";

const cardStyle = {
  padding: "1em",
  margin: "2em 0",
  position: "relative",
  zIndex: null,
};

function rolesExist(taskRoles) {
  return taskRoles.length > 0;
}

function customValuesExist(customValues) {
  return Object.keys(customValues).length > 0;
}

class Task extends React.Component {
  render() {
    return (
      <Paper
        style={{
          width: "40em",
          margin: "0 1em",
        }}
      >
        {this.renderToolbar()}
        <div
          style={{
            padding: "1em 2em",
          }}
        >
          {this.renderFields()}
          {this.renderRoleViews()}
          {this.renderCustomValueViews()}
          {this.renderComments()}
        </div>
      </Paper>
    );
  }

  renderToolbar() {
    const {task} = this.props;
    const {last_edited: lastEdited} = this.props.task;

    return (
      <Toolbar className="app-toolbar">
        <ToolbarGroup key={0}>
          <TextField
            type="text"
            className="name"
            ref={this.createNameRef}
            name="name"
            value={task.name}
            onChange={this.updateName}
            onKeyDown={this.onNameKeyDown}
            style={{
              marginTop: "0.25em",
            }}
          />
        </ToolbarGroup>
        <ToolbarGroup
          key={1}
          style={{
            marginTop: "0.25em",
            marginRight: "-1em",
          }}
        >
          <DatePicker
            className="due-date"
            hintText="Due Date"
            autoOk={true}
            style={{
              display: "inline-block",
              verticalAlign: "top",
            }}
            textFieldStyle={{
              width: "5em",
            }}
            formatDate={date => date.toLocaleDateString()}
            value={task.due_date}
            onChange={(event, value) =>
              this.props.onUpdateTask({due_date: value})
            }
          />
          <IconMenu
            className="menu"
            iconButtonElement={
              <IconButton>
                <MoreIcon />
              </IconButton>
            }
          >
            <MenuItem index={1} onClick={this.onDeleteTask(lastEdited)}>
              Delete
            </MenuItem>
          </IconMenu>
          <IconButton
            className="close-button"
            tooltip="Close Task"
            touch={true}
            onClick={() => this.props.onClose()}
          >
            <CloseIcon />
          </IconButton>
        </ToolbarGroup>
      </Toolbar>
    );
  }

  renderFields() {
    const {task, tasks, onChangeCustomValue} = this.props;
    const customValues = task.custom_values;

    return (
      <div>
        <CustomValueSelector
          className="header-custom-value-selector"
          ref={this.createHeaderCustomValueSelectorRef}
          customValueFields={customValueFields}
          customValues={customValues}
          taskId={task.id}
          filters={{header: true}}
          tasks={tasks}
          onChangeCustomValue={onChangeCustomValue}
        />
        <TextField
          className="description"
          floatingLabelText="Description"
          multiLine={true}
          value={task.description}
          style={{
            display: "block",
            width: "100%",
          }}
          onChange={this.updateDescription}
        />
        <SelectField
          className="status"
          floatingLabelText="Status"
          value={task.status}
          style={{
            display: "block",
            width: "100%",
          }}
          onChange={this.updateStatus}
        >
          {statusOptions.map(option => (
            <MenuItem
              value={option.id}
              key={option.id}
              primaryText={option.name}
            />
          ))}
        </SelectField>
      </div>
    );
  }

  renderRoleViews() {
    return (
      <Card className="role-card" style={cardStyle}>
        {this.shouldShowRoleSummary()
          ? this.renderRoleSummaryViews()
          : this.renderRoleSelectorViews()}
      </Card>
    );
  }

  renderRoleSummaryViews() {
    const {task, parties, taskroles, onPartyChecked} = this.props;
    const taskrolesById = byId(taskroles);
    const partiesById = byId(parties);
    return [
      <div
        className="role-summary-container"
        key="roleSummaryContainer"
        onClick={this.showRoleSelector}
      >
        <IconButton
          style={{
            float: "right",
          }}
        >
          <ExpandIcon />
        </IconButton>
        <RoleSummary
          className="role-summary"
          activeRoles={task.task_roles}
          taskroles={taskrolesById}
          parties={partiesById}
        />
      </div>,
      <RolePartyChecker
        className="role-party-checker"
        key="rolePartyChecker"
        activeRoles={task.task_roles}
        taskroles={taskrolesById}
        parties={partiesById}
        onPartyChecked={onPartyChecked}
      />,
    ];
  }

  renderRoleSelectorViews() {
    const {task, parties, taskroles, onUpdatePartyRole} = this.props;

    const elements = [];

    if (rolesExist(task.task_roles)) {
      elements.push(
        <IconButton
          className="hide-role-selector-button"
          key="hideRoleSelectorButton"
          onClick={this.hideRoleSelector}
          style={{
            position: "absolute",
            right: "5px",
            top: "5px",
          }}
        >
          <CloseIcon />
        </IconButton>,
      );
    }
    elements.push(
      <RoleSelector
        className="role-selector"
        key="roleSelector"
        activeRoles={task.task_roles}
        taskroles={taskroles}
        parties={parties}
        onUpdatePartyRole={onUpdatePartyRole}
      />,
    );

    return elements;
  }

  shouldShowRoleSummary() {
    const {task} = this.props;
    const showRoleSelectorState = this.state && this.state.showRoleSelector;

    return (
      showRoleSelectorState === false ||
      (showRoleSelectorState === undefined && rolesExist(task.task_roles))
    );
  }

  /* eslint-disable no-invalid-this */
  onDeleteTask = _.memoize(lastEdited => () =>
    this.props.onDeleteTask(lastEdited),
  );

  showRoleSelector = () => {
    this.setState({showRoleSelector: true});
  };

  hideRoleSelector = event => {
    this.setState({showRoleSelector: false});
    event.stopPropagation();
  };
  /* eslint-enable no-invalid-this */

  renderCustomValueViews() {
    const {task} = this.props;

    const showCustomValueSelector =
      this.state && this.state.showCustomValueSelector;
    const showCustomValueSummary =
      showCustomValueSelector === false ||
      (showCustomValueSelector === undefined &&
        customValuesExist(task.custom_values));

    return (
      <Card className="custom-value-card" style={cardStyle}>
        {showCustomValueSummary
          ? this.renderCustomValueSummary()
          : this.renderCustomValueSelector()}
      </Card>
    );
  }

  renderCustomValueSummary() {
    const {task, tasks} = this.props;
    const tasksById = byId(tasks);

    const customValues = task.custom_values;

    return (
      <div
        key="customValueSummary"
        className="custom-value-summary-container"
        onClick={this.showCustomValueSelector}
      >
        <CustomValueSummary
          className="custom-value-summary"
          customValueFields={customValueFields}
          customValues={customValues}
          tasks={tasksById}
        />
      </div>
    );
  }

  renderCustomValueSelector() {
    const {task, tasks, onChangeCustomValue} = this.props;
    const customValues = task.custom_values;

    const elements = [];

    if (customValuesExist(customValues)) {
      elements.push(
        <IconButton
          className="hide-custom-value-selector-button"
          key="hideCustomValueSelectorButton"
          onClick={this.hideCustomValueSelector}
          style={{
            position: "absolute",
            right: "5px",
            top: "5px",
          }}
        >
          <CloseIcon />
        </IconButton>,
      );
    }

    elements.push(
      <CustomValueSelector
        className="custom-value-selector"
        key="customValueSelector"
        customValueFields={customValueFields}
        customValues={customValues}
        taskId={task.id}
        filters={{header: false}}
        tasks={tasks}
        onChangeCustomValue={onChangeCustomValue}
      />,
    );

    return elements;
  }

  /* eslint-disable no-invalid-this */
  showCustomValueSelector = () => {
    this.setState({showCustomValueSelector: true});
  };

  hideCustomValueSelector = () => {
    this.setState({showCustomValueSelector: false});
  };
  /* eslint-enable no-invalid-this */

  renderComments() {
    return (
      <CommentView
        className="comments"
        comments={this.props.task.comments}
        onCommentCreated={this.props.onCommentCreated}
      />
    );
  }

  /* eslint-disable no-invalid-this */
  updateName = event => {
    this.props.onUpdateTask({name: event.target.value});
  };

  onNameKeyDown = event => {
    if (event.keyCode === 9) {
      this.headerCustomValueSelectorRef.focus();
      event.stopPropagation();
      event.preventDefault();
    }
  };

  updateDescription = event => {
    this.props.onUpdateTask({description: event.target.value});
  };

  updateStatus = (event, key, value) => {
    this.props.onUpdateTask({status: value});
  };
  /* eslint-enable no-invalid-this */

  componentDidMount() {
    this.checkChangeTask();
  }

  componentDidUpdate() {
    this.checkChangeTask();
  }

  checkChangeTask() {
    if (!this.state || this.state.taskId !== this.props.task.id) {
      this.setState({taskId: this.props.task.id});
      this.focusField();
      this.updateViewState();
    }
  }

  focusField() {
    this.nameRef.focus();
  }

  updateViewState() {
    this.setState({
      showRoleSelector: !rolesExist(this.props.task.task_roles)
        ? true
        : undefined,
      showCustomValueSelector: !customValuesExist(this.props.task.custom_values)
        ? true
        : undefined,
    });
  }

  createNameRef = node => (this.nameRef = node);
  createHeaderCustomValueSelectorRef = node =>
    (this.headerCustomValueSelectorRef = node);
}

Task.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    last_edited: PropTypes.string.isRequired,
    due_date: PropTypes.instanceOf(Date),
    customValueFields: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
    task_roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        party_id: PropTypes.number,
        party_data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
          }),
        ),
      }),
    ).isRequired,
  }).isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  taskroles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  parties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onClose: PropTypes.func.isRequired,
  onUpdateTask: PropTypes.func.isRequired,
  onDeleteTask: PropTypes.func.isRequired,
  onUpdatePartyRole: PropTypes.func.isRequired,
  onPartyChecked: PropTypes.func.isRequired,
  onCommentCreated: PropTypes.func.isRequired,
};

export default Task;
