import ConfigDetail from "./containers/config_detail";

export default class ConfigDetailRoute {
  constructor() {
    this.path = "detail";
  }
  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, ConfigDetail);
  }
}
