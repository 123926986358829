import React from "react";
import _ from "underscore";

import {UPDATE_IN_PROGRESS} from "./ensure_fresh_update";

export default class UpdatingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.updateTypes = ["UPDATE"];
    this.state = {};
  }

  componentDidUpdate() {
    this.updateTypes.forEach(type => {
      if (
        this.state &&
        this.state.updatedWhileSaving &&
        this.state.updatedWhileSaving[type] &&
        !this.getUpdatingProp(type, this.state.updatedWhileSaving[type])[
          UPDATE_IN_PROGRESS
        ]
      ) {
        this.performUpdate(this.state.updatedWhileSaving[type], type);
        this.setState({updatedWhileSaving: null});
      }
    });
  }

  // Needs to be implemented by child class.
  // getUpdatingProp () {
  //   return {};
  // }

  // TODO: this can fail when two calls happen very quickly
  // and the update handler uses a stale last_edited
  // You can fix this by making sure the last_edited in updateHandler
  // is taken from props and not data.
  performUpdate(data, _type) {
    const type = _type || this.updateTypes[0];
    if (this.getUpdatingProp(type, data)[UPDATE_IN_PROGRESS]) {
      const newState = {
        ...(this.state.updatedWhileSaving || {}),
        ..._.object([type], [data]),
      };
      this.setState({updatedWhileSaving: newState});
    }
    return this.updateHandler(data, type);
  }
}
