import React from "react";

import Settings from "./settings";
import Results from "./results";

import {OrganisationId} from "common/types/organisation";
import {ProjectId, Project} from "common/types/project";
import {DocumentId} from "common/types/document";

import {SearchResult, Document} from "../types";
import EmbeddingTopic from "modules/searcher/types/embedding_topic";

interface Props {
  organisationId: OrganisationId;
  projectId: ProjectId;
  projects: Project[];
  selectProject: (projectId: ProjectId) => void;

  documentIds: null | DocumentId[];
  documents: Document[];
  selectDocuments: (documentId: DocumentId[]) => void;

  query: string;
  runQuery: () => void;
  setQuery: (query: string) => void;

  embeddingTopics: EmbeddingTopic[];
  algorithmState: Record<string, boolean>;
  setAlgorithmState: (algorithm: string, value: boolean) => void;

  searchResults: SearchResult[];
  algorithmsUsed: string[] | null;
  queryRunning: boolean;
}

export default function Searcher({
  organisationId,
  projectId,
  projects,
  selectProject,
  documentIds,
  documents,
  selectDocuments,
  embeddingTopics,
  algorithmState,
  setAlgorithmState,
  query,
  setQuery,
  runQuery,
  searchResults,
  algorithmsUsed,
  queryRunning,
}: Props) {
  return (
    <div>
      <h1
        style={{
          padding: "0.5em 1em",
          margin: 0,
          backgroundColor: "#eee",
          borderBottom: "1px solid #ccc",
        }}
      >
        Search
      </h1>
      <Settings
        organisationId={organisationId}
        projectId={projectId}
        projects={projects}
        selectProject={selectProject}
        documentIds={documentIds}
        documents={documents}
        selectDocuments={selectDocuments}
        query={query}
        setQuery={setQuery}
        runQuery={runQuery}
        embeddingTopics={embeddingTopics}
        algorithmState={algorithmState}
        setAlgorithmState={setAlgorithmState}
      />
      <Results
        searchResults={searchResults}
        embeddingTopics={embeddingTopics}
        algorithmsUsed={algorithmsUsed}
        queryRunning={queryRunning}
      />
    </div>
  );
}
