import requestor from "requestor";
import BaseRoute from "utils/base_route";
import ClauseBankContainer from "./containers/clause_bank";
import clauseBankFetch from "modules/clause_bank/actions/clause_bank_fetch";

export default class ClauseBank extends BaseRoute {
  constructor(store) {
    super("clause-bank", store);
  }

  getComponents(location, cb) {
    cb(null, ClauseBankContainer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchClauseBank(state);
  }

  fetchClauseBank(state) {
    const {organisationId} = state.params;
    this.store.dispatch(clauseBankFetch(requestor)(organisationId));
  }
}
