import requestor from "requestor";
import basePath from "base_path";
import ActionTypes from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";

export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_FILE_FETCH,
  (organisationId, projectId, documentId) =>
    requestor
      .get(
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/file`,
        {withCredentials: true},
      )
      .then(({data}) => ({...data})),
);
