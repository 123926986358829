import styled from "styled-components";
import React from "react";

import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";
import {
  TaskArgument,
  ActionDefinitionArg,
} from "common/flowmaster/types/task_config";
import {TaskDataLocationObject} from "common/flowmaster/types/data_location";
import {Prompt} from "common/flowmaster/types/workflow";
import ArgumentValue from "./argument_value";
import {DataStore} from "common/flowmaster/types/data_store";
import getValueBasedOnActionsVisible from "routes/llm_run/utils/get_value_based_on_actions_visible";

interface Props {
  index: number;
  actionArg: ActionDefinitionArg;
  llmRun: EnhancedLlmRun;
  arg: TaskArgument;
  prompts: Prompt[];
  showInternalActions: boolean;
}

const Tr = styled.tr`
  max-height: 10em;
  overflow-y: auto;

  &:not(:last-child) {
    border-bottom: 1px solid #bbb;
  }
`;

export default function Argument({
  index,
  actionArg,
  arg,
  llmRun,
  prompts,
  showInternalActions,
}: Props) {
  if (!actionArg) {
    return null;
  }

  const displayArg = getValueBasedOnActionsVisible(
    arg,
    prompts,
    llmRun.data.data_store as DataStore,
    showInternalActions,
  );

  return (
    <Tr>
      <td style={{width: "1em", color: "#888"}}>{index}</td>
      <td style={{width: "7em", maxWidth: "10em"}}>{actionArg.label}</td>
      <td
        style={{
          width: "4em",
          maxWidth: "5em",
          color: "#888",
          fontStyle: "italic",
        }}
      >
        {actionArg.type === "string" ||
        actionArg.type === "int" ||
        actionArg.type === "model_select"
          ? (arg?.value as TaskDataLocationObject)?.source_type ?? "unknown"
          : ""}
      </td>
      <td>
        <ArgumentValue
          actionArg={actionArg}
          arg={displayArg}
          llmRun={llmRun}
          prompts={prompts}
        />
      </td>
    </Tr>
  );
}
