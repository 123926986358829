import ActionTypes, {ProjectActionTypes} from "../constants/action_types";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";

type Accesstag = {id: number; name: string};

type NewAccesstag = Omit<Accesstag, "id">;

export type CreateAccesstagAction = {
  type: ProjectActionTypes["PROJECT_CREATE_ACCESS_TAG"]["SUCCESS"];
  payload: Accesstag;
};

// Returns ID for newly created tag
type CreateAccesstagResponse = {data: {id: number}};

const createAccesstag = requestor => (
  organisationId: number,
  projectId: number,
  accesstag: NewAccesstag,
) =>
  requestor
    .post(
      `${basePath}/organisation/${organisationId}/project/${projectId}/accesstag`,
      accesstag,
      {withCredentials: true},
    )
    .then(({data}: CreateAccesstagResponse) => ({...accesstag, id: data.id}));

export default function actionCreator(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PROJECT_CREATE_ACCESS_TAG,
    createAccesstag(requestor),
  );
}
