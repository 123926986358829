import React from "react";

import Button from "@material-ui/core/Button";
import PeopleIcon from "@material-ui/icons/People";
import AddIcon from "@material-ui/icons/Add";

import Toolbar from "common_components/toolbar";

function UserListToolbar(props) {
  function renderCreateButton() {
    if (!props.hasPermissionToAdd) {
      return null;
    }
    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={props.onUserAdd}
        style={{display: "flex", marginLeft: "auto"}}
      >
        Create New User
      </Button>
    );
  }

  return (
    <Toolbar
      icon={<PeopleIcon />}
      title="Users"
      children={renderCreateButton()}
      childrenStyles={{marginLeft: "auto"}}
    />
  );
}

export default UserListToolbar;
