import React, {useState} from "react";
import {useDrag, useDrop, DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import Button from "@material-ui/core/Button";

import AddItem from "./add_item";
import Task from "./task";

import Fieldset from "./styled/fieldset";
import Legend from "./styled/legend";

import {
  WorkflowTask,
  WorkflowTaskId,
  ActionDefinition,
} from "common/flowmaster/types/task_config";
import {
  Workflow,
  WorkflowContext,
  WorkflowInputs,
  Prompt,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";
import {Topic} from "common/types/topic";

interface TasksProps {
  items: Record<string, WorkflowTask>;
  onAdd: (name: string) => void;
  onUpdateItem: (id: WorkflowTaskId, data: WorkflowTask) => void;
  onDeleteItem: (id: WorkflowTaskId) => void;
  onReorder: (taskId: WorkflowTaskId, prevTaskId: WorkflowTaskId) => void;
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  workflows: Workflow[];
  organisationId: OrganisationId;
  embeddingTopics: Topic[];
}

const ItemTypes = {
  TASK: "task",
};

const Tasks: React.FC<TasksProps> = ({
  items,
  onAdd,
  onUpdateItem,
  onDeleteItem,
  onReorder,
  context,
  inputs,
  prompts,
  actionDefinitions,
  workflows,
  organisationId,
  embeddingTopics,
}) => {
  const [forceOpen, setForceOpen] = useState(false);
  const taskList = getTaskList(items);
  const priorTasks: WorkflowTask[] = [];

  const toggleAllTasks = () => setForceOpen(open => !open);

  const showMissingFinaliseWarning =
    taskList.length > 0 &&
    !(
      taskList.at(-1)?.action === "finalise" ||
      taskList.at(-1)?.action === "finalise_map"
    );

  return (
    <DndProvider backend={HTML5Backend}>
      <Fieldset>
        <Legend>
          <span style={{marginRight: "1em"}}>Tasks</span>{" "}
          <Button variant="outlined" onClick={toggleAllTasks}>
            {forceOpen ? "Close All" : "Open All"}
          </Button>
        </Legend>
        {showMissingFinaliseWarning && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#FFECB3",
              color: "#FF6F00",
              padding: "20px",
              margin: "0 10px 10px 10px",
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "1.1em",
            }}
          >
            <div>Workflow does not end with a finalise task</div>
          </div>
        )}
        <div>
          {taskList.map((task, index) => {
            if (task && task.id) {
              const item = (
                <DraggableTask
                  key={task.id}
                  task={task}
                  onUpdateItem={onUpdateItem}
                  onDeleteItem={onDeleteItem}
                  onReorder={onReorder}
                  actionDefinitions={actionDefinitions}
                  priorTasks={[...priorTasks]}
                  futureTasks={taskList.slice(index + 1)}
                  context={context}
                  inputs={inputs}
                  prompts={prompts}
                  workflows={workflows}
                  organisationId={organisationId}
                  embeddingTopics={embeddingTopics}
                  forceOpen={forceOpen}
                />
              );
              priorTasks.push(task);
              return item;
            }
            return null;
          })}
        </div>
        <AddItem onAdd={onAdd} label="Task Name" />
      </Fieldset>
    </DndProvider>
  );
};

function getTaskList(items: Record<string, WorkflowTask>): WorkflowTask[] {
  const tasks: WorkflowTask[] = [];
  const startTask = Object.values(items).find(item => item.name === "start");
  if (!startTask) {
    throw new Error("No start task found");
  }
  tasks.push(startTask);
  let counter = 0;
  while (tasks[tasks.length - 1]?.next && counter < 1000) {
    const next = tasks[tasks.length - 1].next;
    if (next !== null) {
      tasks.push(items[next]);
      counter += 1;
    }
  }
  return tasks.slice(1);
}

interface DraggedTask {
  id: number;
  type: string;
}

const DraggableTask = ({
  task,
  onUpdateItem,
  onDeleteItem,
  onReorder,
  actionDefinitions,
  priorTasks,
  futureTasks,
  context,
  inputs,
  prompts,
  workflows,
  organisationId,
  embeddingTopics,
  forceOpen,
}) => {
  const [draggedTask, drag] = useDrag({
    item: {id: task.id, type: ItemTypes.TASK},
    collect: monitor => ({
      id: task.id,
      isDragging: Boolean(monitor.isDragging()),
    }),
  });
  const [, drop] = useDrop({
    accept: ItemTypes.TASK,
    drop: (droppedTask: DraggedTask) => {
      if (droppedTask.id !== task.id) {
        onReorder(droppedTask.id, task.id);
      }
    },
  });

  return (
    <div
      ref={node => drag(drop(node))}
      style={{
        opacity: draggedTask.isDragging ? 0.5 : 1,
        margin: "10px",
        padding: "10px",
        backgroundColor: "lightgray",
        cursor: "move",
      }}
    >
      <Task
        task={task}
        onUpdateItem={(data: Record<string, unknown>) =>
          onUpdateItem(task.id, data)
        }
        onDeleteItem={() => onDeleteItem(task.id)}
        actionDefinitions={actionDefinitions}
        priorTasks={priorTasks}
        futureTasks={futureTasks}
        context={context}
        inputs={inputs}
        prompts={prompts}
        workflows={workflows}
        organisationId={organisationId}
        embeddingTopics={embeddingTopics}
        forceOpen={forceOpen}
      />
    </div>
  );
};

export default Tasks;
