import basePath from "base_path";
import ActionTypes from "../../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function regenerateDocumentEmbeddings(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_EMBEDDINGS_REGENERATE,
    (organisationId, projectId, documentId) =>
      requestor
        .patch(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}/document/${documentId}/embeddings`,
          {},
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
