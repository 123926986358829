import _ from "underscore";
import React, {useState} from "react";
/* eslint-enable no-unused-vars*/
import PropTypes from "prop-types";
import {Link} from "react-router";
import CreateTopicDialog from "../../../common_components/create_topic_dialog";

import BulkTopicEditor from "./bulk_topic_editor";
import localStorage from "utils/local_storage";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import FindInPageIcon from "material-ui/svg-icons/action/find-in-page";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import Tooltip from "@material-ui/core/Tooltip";
import ToolbarDropdown from "common_components/toolbar/dropdown";
import ToolbarDropdownItem from "common_components/toolbar/dropdown/item";
import {Table, Tr, Th, Td} from "common_components/table";
import CheckboxBasic from "common_components/inputs/checkbox_basic";
import HotAndStarInfoWidget from "common_components/hot_and_star_info_widget";

import {Toolbar, ToolbarGroup, ToolbarTitle} from "material-ui/Toolbar";
import * as colors from "material-ui/styles/colors";

import getListFilterRegexes from "utils/get_list_filter_regexes";

const styles = {
  inner: {
    padding: "0em 1em",
    flexGrow: 1,
    overflow: "auto",
  },
  stripedColumn: {
    backgroundColor: "rgb(251, 251, 237)",
  },
  toolbarBtnBlock: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginRight: "20px",
  },
};

export function getFilteredTopics(
  filterText,
  contractTypeFilteredItemsIds,
  props,
  _topics,
) {
  const {categoriesById, tagsById} = props;
  const filterRegexes = getListFilterRegexes(filterText);
  return (_topics || props.topics || [])
    .map(topic => ({
      ...topic,
      categoryName: categoriesById[topic.topiccategory_id].name,
      tagNames: (topic.tags || []).map(tag => tagsById[tag.id].name),
    }))
    .filter(topic => {
      if (
        contractTypeFilteredItemsIds &&
        contractTypeFilteredItemsIds.length > 0
      ) {
        let isTopicFilteredOut = true;
        topic.contract_types.forEach(ct => {
          if (contractTypeFilteredItemsIds.includes(ct.contract_type_id)) {
            isTopicFilteredOut = false;
          }
        });
        if (isTopicFilteredOut) {
          return false;
        }
      }
      if (filterRegexes) {
        return filterRegexes.every(
          filterRegex =>
            filterRegex.test(topic.id) ||
            filterRegex.test(topic.name.toLowerCase()) ||
            filterRegex.test(topic.master_id) ||
            filterRegex.test(topic.categoryName.toLowerCase()) ||
            topic.tagNames.find(tagName =>
              filterRegex.test(tagName.toLowerCase()),
            ),
        );
      }
      return true;
    });
}

function TopicContractTypesList(props) {
  const {contractTypesById, topicContractTypes} = props;
  const [showAll, updateShowAll] = useState(false);
  const allContractTypes = topicContractTypes.map(
    ct => contractTypesById[ct.contract_type_id].name,
  );
  const contractTypesToShow =
    !showAll && allContractTypes.length > 3
      ? allContractTypes.slice(0, 3)
      : allContractTypes;

  function onShowAllTrigger() {
    updateShowAll(!showAll);
  }

  const Icon = showAll ? ExpandLessIcon : MoreHorizIcon;
  return (
    <div style={{fontSize: "12px"}}>
      {contractTypesToShow.map((ct, index) => (
        <div key={index}>{ct}</div>
      ))}
      {allContractTypes.length > 3 ? (
        <Icon onClick={onShowAllTrigger} style={{cursor: "pointer"}} />
      ) : null}
    </div>
  );
}

class TopicList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    const filterText = localStorage.getItem("topicFilterText")
      ? localStorage.getItem("topicFilterText")
      : "";
    const contractTypeFilteredItemsIds =
      JSON.parse(
        localStorage.getItem(`filteredContractTypeIds_${props.organisationId}`),
      ) || [];
    const filteredTopics = getFilteredTopics(
      filterText,
      contractTypeFilteredItemsIds,
      props,
    );
    this.state = {
      filter: filterText,
      filteredTopics,
      selectedTopicIds: props.initialSelectedTopicIds || [],
      showBulkEditorDialog: props.initialShowBulkEditorDialog || false,
      listView: localStorage.getItem("topicListView")
        ? localStorage.getItem("topicListView")
        : "summary",
      contractTypeFilteredItemsIds,
      showIssuesNotProcessed: false,
      showNewTopicDialog: false,
    };
  }

  componentDidMount() {
    this.filterInputRef.focus();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.topics, this.props.topics)) {
      this.updateFilter(this.state.filter);
    }
  }

  render() {
    const {
      selectedTopicIds,
      showBulkEditorDialog,
      listView,
      showNewTopicDialog,
    } = this.state;
    const {categories, tags} = this.props;
    const listItems = this.renderTopicListItems(
      this.state.filteredTopics || this.props.topics,
    );
    return (
      <div className="app-page">
        <Toolbar className="app-toolbar">
          <ToolbarGroup key={0}>
            <ToolbarTitle text="Topics" />
            <FlatButton
              className="toggle-view"
              label={"Analysis Matrix"}
              onClick={this.onTopicAnalysisMatrix}
            />
            <FlatButton
              className="topic-parameters"
              label={"Parameters"}
              onClick={this.onTopicParameters}
            />
            <FlatButton
              className="toggle-view"
              label={
                this.state.listView === "summary" ? "Text mode" : "Summary mode"
              }
              onClick={this.toggleListView}
            />
            <ToolbarDropdown
              leftIcon={<FindInPageIcon />}
              labelText="Contract Type"
              values={this.state.contractTypeFilteredItemsIds.map(id =>
                id.toString(),
              )}
              showSelectAndUnselectAllItems={true}
              showSelectedItemsList={true}
              showFilterTextField={true}
              itemHeaderStyle={{
                paddingLeft: 15,
                paddingRight: 15,
              }}
              handleChange={values =>
                this.onContractTypeFilterConfirm(
                  values.map(value => parseInt(value, 10)),
                )
              }
            >
              {_.map(_.sortBy(this.props.contractTypesById, "name"), item => (
                <ToolbarDropdownItem
                  key={item.id}
                  value={item.id.toString()}
                  label={item.name}
                  withCheckbox={true}
                  labelStyle={{height: 22}}
                />
              ))}
            </ToolbarDropdown>
            <CheckboxBasic
              checked={this.state.showIssuesNotProcessed}
              onCheck={this.triggerShowIssuesNotProcessed}
              label="Show Issues Not Processed"
              containerStyles={{
                marginLeft: -8,
              }}
            />
          </ToolbarGroup>
          <ToolbarGroup key={1}>
            <div style={styles.toolbarBtnBlock}>
              <Tooltip title="Add topic" placement="top" arrow>
                <Fab
                  size="small"
                  color="primary"
                  onClick={this.topicDialogHandler}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
              <RaisedButton
                className="bulk-edit-button"
                secondary={true}
                disabled={selectedTopicIds.length === 0}
                onClick={this.showBulkEditor}
              >
                Bulk Edit
              </RaisedButton>
            </div>

            <TextField
              className="filter-input"
              ref={this.createFilterInputRef}
              value={this.state.filter}
              style={{
                zoom: "0.75",
              }}
              floatingLabelText={this.getFilterLabelText(
                (this.state.filteredTopics || this.props.topics).length,
              )}
              onChange={this.onUpdateFilterChange}
            />
            <RaisedButton onClick={() => this.props.fetchTopicsInfo()}>
              Fetch stats
            </RaisedButton>
          </ToolbarGroup>
        </Toolbar>
        <div className="app-body" style={styles.inner}>
          <Table
            isSelectable={true}
            sortColumnIndex={1}
            onSelectedRowsChange={selectedRows => {
              this.setState({selectedTopicIds: selectedRows});
            }}
            useLoadMoreButtons={true}
            loadMoreButtonText="Load more topics"
            loadAllButtonText="Load all topics"
            hasStickyHeader={true}
          >
            <thead>
              {listView === "summary"
                ? this.renderSummaryListViewHeader()
                : this.renderTextListViewHeader()}
            </thead>
            <tbody>{listItems}</tbody>
          </Table>
        </div>
        {showBulkEditorDialog ? (
          <BulkTopicEditor
            topics={this.getSelectedTopics()}
            categories={categories}
            tags={tags}
            onDismiss={this.onDialogDismissed}
            onTopicsUpdated={this.onTopicsUpdated}
            contractTypesById={this.props.contractTypesById}
          />
        ) : null}
        {showNewTopicDialog ? (
          <CreateTopicDialog
            topics={this.props.topics}
            key="topic-creator"
            className="newTopicDialog"
            initialName=""
            topicCategories={categories}
            contractTypesById={this.props.contractTypesById}
            onNewTopicAdded={this.addTopic}
            onDismiss={this.topicDialogHandler}
          />
        ) : null}
      </div>
    );
  }

  addTopic = arg => {
    this.topicDialogHandler();
    return this.props.addNewTopic(
      arg.name,
      arg.is_substantive,
      arg.is_embedding,
      arg.topiccategory_id,
      arg.contract_types,
    );
  };

  renderSummaryListViewHeader = () => {
    return (
      <tr>
        <Th
          style={{width: "2em"}}
          containerStyle={{justifyContent: "center"}}
        />
        <Th containerStyle={{justifyContent: "center"}}>Id</Th>
        <Th containerStyle={{justifyContent: "center"}}>Master Id</Th>
        <Th>Topic</Th>
        <Th containerStyle={{justifyContent: "center"}}>Category</Th>
        <Th style={{width: "4em"}}>Tags</Th>
        <Th style={{width: "12em"}} containerStyle={{justifyContent: "center"}}>
          Contract Types
        </Th>
        <Th style={{width: "3em"}} containerStyle={{justifyContent: "center"}}>
          # Issues
        </Th>
        <Th style={{width: "2em"}} containerStyle={{justifyContent: "center"}}>
          # Parameters
        </Th>
        <Th style={{width: "4em"}} containerStyle={{justifyContent: "center"}}>
          Uses
        </Th>
        <Th style={{width: "12em"}} containerStyle={{justifyContent: "center"}}>
          Unconfirmed Uses
        </Th>
        <Th
          isSortable={false}
          style={{width: "4em"}}
          containerStyle={{justifyContent: "center"}}
        >
          Substantive
        </Th>
        <Th isSortable={false} style={{width: "3em"}} />
      </tr>
    );
  };

  renderTextListViewHeader = () => {
    return (
      <tr>
        <Th containerStyle={styles.stripedColumn}>Topic Name</Th>
        <Th>Category</Th>
        <Th containerStyle={styles.stripedColumn}>Contract Type</Th>
        <Th>Help</Th>
        <Th containerStyle={styles.stripedColumn}>Description</Th>
        <Th>Related Topics</Th>
        <Th containerStyle={styles.stripedColumn}>Examples</Th>
        <Th>Counter Examples</Th>
      </tr>
    );
  };

  onTopicAnalysisMatrix = () => this.props.toggleView("analysis_matrix");
  onTopicParameters = () => this.props.toggleView("parameters");

  /* eslint-disable no-invalid-this */
  toggleListView = () => {
    const {listView} = this.state;
    const newListView = listView === "summary" ? "text" : "summary";

    localStorage.setItem("topicListView", newListView);
    this.setState({listView: newListView});
  };

  showBulkEditor = () => {
    this.setState({showBulkEditorDialog: true});
  };

  onDialogDismissed = () => {
    this.setState({showBulkEditorDialog: false});
  };

  topicDialogHandler = () => {
    this.setState({showNewTopicDialog: !this.state.showNewTopicDialog});
  };

  onTopicsUpdated = (...args) => {
    this.props.onTopicsUpdated(...args);
    this.setState({showBulkEditorDialog: false});
  };

  renderTopicListItems(topics) {
    const {categoriesById, tagsById} = this.props;
    const {listView, contractTypeFilteredItemsIds} = this.state;

    return _.chain(topics)
      .reduce((result, topic) => {
        const finalTopic = {
          ..._.pick(topic, [
            "id",
            "master_id",
            "name",
            "uses",
            "unconfirmed_uses",
            "last_edited",
            "help",
            "description",
            "parameters",
            "referenced_issues",
            "topiccategory_id",
            "is_substantive",
            "is_star",
            "is_hot",
          ]),
          contract_types:
            !contractTypeFilteredItemsIds ||
            contractTypeFilteredItemsIds.length === 0
              ? topic.contract_types
              : topic.contract_types.filter(ct =>
                  contractTypeFilteredItemsIds.includes(ct.contract_type_id),
                ),
          categoryName: categoriesById[topic.topiccategory_id].name,
          tagNames: (topic.tags || []).map(tag => tagsById[tag.id].name),
        };
        let shouldShowTopic = true;
        const topicHasIssuesPausedParams = topicHasParameterWithIssuesPaused(
          finalTopic,
        );
        if (this.state.showIssuesNotProcessed && !topicHasIssuesPausedParams) {
          shouldShowTopic = false;
        }

        if (shouldShowTopic) {
          result.push(
            listView === "summary"
              ? this.renderSummaryListViewItemRow(
                  finalTopic,
                  topicHasIssuesPausedParams,
                )
              : this.renderTextListViewItemRow(
                  finalTopic,
                  topicHasIssuesPausedParams,
                ),
          );
        }
        return result;
      }, [])
      .value();
  }

  renderSummaryListViewItemRow(topic, topicHasIssuesPausedParams) {
    const {organisationId} = this.props;
    const pathname = `/organisation/${organisationId}/topic/${topic.id}/detail`;
    return (
      <Tr key={topic.id} selectId={topic.id}>
        <Td>
          <HotAndStarInfoWidget
            label="Topic"
            isHot={topic.is_hot}
            isStar={topic.is_star}
            updateIsStar={this.updateTopicIsStar(topic.id, topic.is_star)}
          />
        </Td>
        <Td>{topic.id}</Td>
        <Td
          style={{fontFamily: "monospace"}}
          title={topic.master_id}
          sortText={topic.master_id}
        >
          {topic.master_id.substring(0, 6)}…
        </Td>
        <Td
          style={{textAlign: "left", overflow: "visible"}}
          handleChildren={(children, thisTd) => (
            <Link
              to={{pathname}}
              style={{
                color: thisTd.props.isRowHovered ? colors.blue500 : "#000",
                textDecoration: thisTd.props.isRowHovered
                  ? "underline"
                  : "none",
                marginLeft: topicHasIssuesPausedParams ? -24 : 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              {children}
            </Link>
          )}
        >
          {this.renderIssuesPausedIcon(topicHasIssuesPausedParams)}
          {topic.name}
        </Td>
        <Td style={{textAlign: "center"}}>{topic.categoryName}</Td>
        <Td>{topic.tagNames.join(" ")}</Td>
        <Td style={{textAlign: "center"}}>
          <TopicContractTypesList
            topicContractTypes={topic.contract_types}
            contractTypesById={this.props.contractTypesById}
          />
        </Td>
        <Td style={{textAlign: "center"}}>
          {topic.referenced_issues ? topic.referenced_issues.length : "-"}
        </Td>
        <Td style={{textAlign: "center"}}>{topic.parameters.length}</Td>
        <Td style={{textAlign: "center"}}>
          {topic.uses !== undefined ? topic.uses : "-"}
        </Td>
        <Td style={{textAlign: "center"}}>
          {topic.unconfirmed_uses !== undefined ? topic.unconfirmed_uses : "-"}
        </Td>
        <Td style={{cursor: "default", textAlign: "center"}}>
          {topic.is_substantive && "+"}
        </Td>
        <Td
          handleChildren={(children, thisTd) => {
            return thisTd.props.isRowHovered ? children : null;
          }}
        >
          <Link to={{pathname}} style={{color: "#000", textDecoration: "none"}}>
            <EditIcon
              style={{width: "20px", height: "20px", fill: colors.grey700}}
            />
          </Link>
        </Td>
      </Tr>
    );
  }

  renderTextListViewItemRow(topic, topicHasIssuesPausedParams) {
    const {organisationId} = this.props;
    const pathname = `/organisation/${organisationId}/topic/${topic.id}/detail`;
    return (
      <Tr key={topic.id} selectId={topic.id}>
        <Td
          style={{
            ...styles.stripedColumn,
            textAlign: "left",
          }}
          containerStyle={{minHeight: 44}}
          handleChildren={(children, thisTd) => (
            <Link
              to={{pathname}}
              style={{
                color: thisTd.props.isRowHovered ? colors.blue500 : "#000",
                textDecoration: thisTd.props.isRowHovered
                  ? "underline"
                  : "none",
              }}
              onlyActiveOnIndex
            >
              {children}
            </Link>
          )}
        >
          {this.renderIssuesPausedIcon(topicHasIssuesPausedParams)}
          {topic.name}
        </Td>
        <Td>{topic.categoryName}</Td>
        <Td style={styles.stripedColumn}>
          {topic.contract_types.map((ct, index) => (
            <div key={index} style={{fontSize: "14px"}}>
              {this.props.contractTypesById[ct.contract_type_id].name}
            </div>
          ))}
        </Td>
        <Td>{topic.help ? topic.help : "—"}</Td>
        <Td style={styles.stripedColumn}>
          {topic.description.description ? topic.description.description : "—"}
        </Td>
        <Td>{this.renderTextListViewRelatedTopics(topic)}</Td>
        <Td style={styles.stripedColumn}>
          {topic.description.examples ? topic.description.examples : "—"}
        </Td>
        <Td>
          {topic.description.counter_examples
            ? topic.description.counter_examples
            : "—"}
        </Td>
      </Tr>
    );
  }

  renderIssuesPausedIcon = shouldRenderIcon => {
    if (shouldRenderIcon) {
      return (
        <Tooltip
          enterDelay={500}
          placement="top"
          title={"Issues not processed"}
        >
          <PriorityHighIcon />
        </Tooltip>
      );
    }
  };

  renderTextListViewRelatedTopics = topic => {
    const {topicsById} = this.props;
    const relatedTopics = (topic.description.related_topics || []).filter(
      topicId => topicId,
    );

    if (!relatedTopics.length) {
      return "—";
    }

    return relatedTopics.map(relatedTopicId => (
      <div key={`related-topic-${topic.id}-${relatedTopicId}`}>
        {topicsById[relatedTopicId].name}
      </div>
    ));
  };

  getFilterLabelText(matches) {
    if (matches === this.props.topics.length) {
      return `Filter - ${matches} topics in total`;
    }
    return `Filter - ${matches} match${matches !== 1 ? "es" : ""}`;
  }

  /* eslint-disable no-invalid-this */
  onUpdateFilterChange = event => this.updateFilter(event.target.value);

  updateFilter = filterText => {
    const filteredTopics = getFilteredTopics(
      filterText,
      this.state.contractTypeFilteredItemsIds,
      this.props,
    );
    const newState = {
      filter: filterText,
      filteredTopics,
    };
    this.setState(
      () => newState,
      () => localStorage.setItem("topicFilterText", filterText),
    );
  };

  getSelectedTopics() {
    return this.state.selectedTopicIds.map(topicId =>
      this.props.topics.find(topic => topic.id === topicId),
    );
  }

  onContractTypeFilterConfirm = contractTypeFilteredItemsIds => {
    this.setState(
      () => ({contractTypeFilteredItemsIds}),
      () => {
        localStorage.setItem(
          `filteredContractTypeIds_${this.props.organisationId}`,
          JSON.stringify(contractTypeFilteredItemsIds),
        );
        this.updateFilter(this.state.filter);
      },
    );
  };

  createFilterInputRef = node => (this.filterInputRef = node);

  triggerShowIssuesNotProcessed = () =>
    this.setState(prevState => ({
      showIssuesNotProcessed: !prevState.showIssuesNotProcessed,
    }));

  updateTopicIsStar = (topicId, topicIsStar) => () => {
    this.props.onTopicUpdate(topicId, {is_star: !topicIsStar});
  };
}

function topicHasParameterWithIssuesPaused(topic) {
  const {parameters = []} = topic;
  return Boolean(parameters.find(param => param.issues_paused));
}

TopicList.propTypes = {
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onTopicsUpdated: PropTypes.func.isRequired,
  topicsById: PropTypes.object.isRequired,
};

export default TopicList;
export {TopicList};
