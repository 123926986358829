import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";

import DropZone from "react-dropzone";

import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import SearchIcon from "@material-ui/icons/Search";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import UploadIcon from "material-ui/svg-icons/file/cloud-upload";
import * as colors from "material-ui/styles/colors";

import {isInitialised} from "utils/uninitialised";
import byId from "common/utils/by_id";
import PreUploadDialog from "./upload_document_components/pre_upload_dialog";
import SelectChecklistDialog from "./select_checklist_dialog";
import UploadLogsTable from "./upload_logs_table";
import {PENDING_DOCUMENT, REVIEWED_DOCUMENT} from "../constants";
import DocumentListToolbar from "./toolbar";
import DocumentListTable from "./table";
import Tabs from "common_components/tabs";
import Reports from "./reports";
import Prompts from "./prompts";
import ReportDetail from "./report_detail";
import DocumentLogs from "common_components/document_logs";

import JoinUploadsPrompt from "./join_uploads_prompt";

// import DocumentReview from "common_components/document_review";

import getProjectIssuesets from "utils/issuesets/get_project_issuesets";

const styles = {
  uploadArea: {
    height: "6rem",
    margin: "0.5rem",
    textAlign: "center",
    padding: "3em",
    backgroundColor: colors.blue50,
    cursor: "pointer",
    borderWidth: "2px",
    borderStyle: "dashed",
    borderColor: colors.grey400,
    boxShadow: "0px 0px 0px 0.5rem #E3F2FD",
  },
};

export default class DocumentList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      formTemplateId: props.formTemplates.length && props.formTemplates[0].id,
      shownDialogueName: "",
      documentUploadLogs: null,
      uploaderToShow: "",
      documentType: null,
      fileToUpload: null,
      documentToRevise: null,
      isRevisionOpen: {},
      shouldShowLogs: false,
      reviewedDocumentId: null,
      reportDetailDocumentId: null,
      showDocumentJoinerPrompt: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.project.document_type.id !==
        this.props.project.document_type.id ||
      prevProps.project.default_contract_type.id !==
        this.props.project.default_contract_type.id
    ) {
      this.setState(
        () => ({shownDialogueName: ""}),
        () => this.onAddDocumentClick(),
      );
    }

    if (
      (prevState.reportDetailDocumentId &&
        this.state.reportDetailDocumentId &&
        prevState.reportDetailDocumentId !==
          this.state.reportDetailDocumentId) ||
      (prevState.reportDetailDocumentId && !this.state.reportDetailDocumentId)
    ) {
      this.props.clearDocument();
    }
  }

  dismissDialog = () =>
    this.setState(() => ({shownDialogueName: "", fileToUpload: null}));
  showPreUploadDialog = shownDialogueName =>
    this.setState(() => ({shownDialogueName}));

  render() {
    const documents = this.getDocuments();
    const showBlankMessage =
      !this.state.uploaderToShow &&
      Array.isArray(this.props.documents) &&
      this.props.documents.length === 0;
    const {
      searchValue,
      onChangeSearchText,
      onlyShowMyDocuments,
      onToggleOnlyShowMyDocuments,
    } = this.props;
    return (
      <React.Fragment>
        <DocumentListToolbar
          organisation={this.props.organisation}
          hasUploadPermission={this.props.hasUploadPermission}
          pagination={
            // TODO: Tabs should be given human readable identifiers...
            this.props.activeTab === 1
              ? undefined
              : {
                  documentsCount: this.props.documentsCount,
                  page: this.props.page,
                  onClickPageNext: () =>
                    this.props.onNavigate(this.props.page + 1),
                  onClickPagePrevious: () =>
                    this.props.onNavigate(this.props.page - 1),
                }
          }
          page={this.props.page}
          onAddDocumentClick={this.onAddDocumentClick}
          organisationId={this.props.organisationId}
          projectId={this.props.projectId}
          user={this.props.user}
          project={this.props.project}
          toggleDocumentCreateView={this.props.toggleDocumentCreateView}
          uploaderToShow={this.state.uploaderToShow}
          closeUploaded={this.closeUploaded}
          searchText={searchValue}
          onChangeSearchText={onChangeSearchText}
          onlyShowMyDocuments={onlyShowMyDocuments}
          onToggleOnlyShowMyDocuments={onToggleOnlyShowMyDocuments}
        />
        {this.renderUploader()}
        {this.renderUploadJoinPrompt()}
        {showBlankMessage
          ? this.renderBlankMessage()
          : this.renderDocumentListBody(documents)}

        {this.renderDialog()}
        {this.renderRevisionDialog()}
        {/* <DocumentReview
          document={
            this.state.reviewedDocumentId && this.props.documentToReview
          }
          handleClose={this.onDocumentReview(null)}
        /> */}
      </React.Fragment>
    );
  }

  renderDocumentListBody(documents) {
    const documentList = this.renderDocumentList(documents);
    const showBulkReports = this.shouldShowBulkReports();
    const showProjectQueries = this.shouldShowProjectQueries();
    if (showBulkReports || showProjectQueries) {
      const labels = ["Files"];
      const icons = [InsertDriveFileIcon];
      const panels = [documentList];

      if (showBulkReports) {
        labels.push("Reports");
        icons.push(DataUsageIcon);
        panels.push(this.renderReports());
      }
      if (showProjectQueries) {
        labels.push("Queries");
        icons.push(SearchIcon);
        panels.push(this.renderPrompts());
      }

      return (
        <Tabs
          value={this.props.activeTab}
          handleChange={(_, newValue) => {
            this.props.onChangeTab(newValue);
            // TODO: Tabs should be given human readable identifiers...
            if (newValue === 1) {
              this.closeUploaded();
            }
          }}
          labels={labels}
          icons={icons}
          panels={panels}
        />
      );
    }
    return documentList;
  }

  shouldShowBulkReports() {
    const {
      project: {bulk_report_settings: bulkReportSettings},
    } = this.props;
    return bulkReportSettings;
  }

  shouldShowProjectQueries() {
    return this.props.organisation.use_llm;
  }

  renderDocumentList(documents) {
    const {props, state} = this;
    return (
      <>
        <DocumentListTable
          page={props.page}
          documents={documents}
          projects={props.projects}
          organisationId={props.organisationId}
          projectId={props.projectId}
          project={props.project}
          user={props.user}
          roles={props.roles}
          searchValue={props.searchValue}
          isRevisionOpen={this.state.isRevisionOpen}
          documentStates={this.props.documentStates}
          toggleAnalystView={props.toggleAnalystView}
          viewSummaryPage={props.viewSummaryPage}
          onDocumentDownloaded={props.onDocumentDownloaded}
          onEmailReport={props.onEmailReport}
          onDocumentReclassified={props.onDocumentReclassified}
          updateDocumentLoadState={props.updateDocumentLoadState}
          onDocumentDeleted={props.onDocumentDeleted}
          updateDocument={props.updateDocument}
          showRevisionDialog={this.showRevisionDialog}
          toggleRevisionVisability={this.toggleRevisionVisability}
          showDocumentLogs={this.showDocumentLogs}
          parties={this.props.parties}
          onDocumentReview={this.onDocumentReview}
          runDocumentIssuesFind={this.props.runDocumentIssuesFind}
          runDocumentsReprocess={this.props.runDocumentsReprocess}
          showDocumentUploadLogs={this.showDocumentUploadLogs}
          reprocessDocumentRoles={this.props.reprocessDocumentRoles}
          regenerateDocumentEmbeddings={this.props.regenerateDocumentEmbeddings}
          deleteDocumentsRoles={this.props.deleteDocumentsRoles}
          documentsCount={this.props.documentsCount}
          fetchDocuments={this.props.fetchDocuments}
          onNavigate={this.props.onNavigate}
        />
        {state.shouldShowLogs && (
          <DocumentLogs
            logs={props.documentLogs}
            clear={this.hideDocumentLogs}
          />
        )}
      </>
    );
  }

  renderPrompts() {
    const {props} = this;

    return (
      <>
        <Prompts
          project={props.project}
          generateProjectLlmPrompt={props.generateProjectLlmPrompt}
          fetchConversations={props.fetchConversations}
          user={props.user}
        />
      </>
    );
  }

  renderReports() {
    const {props} = this;
    const {reportDetailDocumentId} = this.state;
    const usedReport = reportDetailDocumentId
      ? (props.reports.reports || []).find(
          document => document.id === reportDetailDocumentId,
        )
      : {};
    const issueset = this.getReportIssueset();

    return (
      <>
        <Reports
          reports={props.reports}
          projectId={props.projectId}
          fetchReports={props.fetchReports}
          organisationId={props.organisationId}
          user={props.user}
          showReportDetail={this.showReportDetail}
          issueset={issueset}
          showRagScore={this.props.project.show_rag_score}
        />
        {reportDetailDocumentId && (
          <ReportDetail
            settings={props.reports.settings}
            // TODO: need to get selectedReport from settings
            selectedReport="rag_report"
            documentName={usedReport.name}
            parameters={usedReport.parameters}
            documentIssues={usedReport.report_issues}
            ragScoreLevels={usedReport.rag_score_levels}
            closeReportDetail={this.closeReportDetail}
            selectedDocumentId={reportDetailDocumentId}
            documentClauses={this.props.documentClauses}
            topicsById={this.props.topicsById}
            project={this.props.project}
            contractTypesById={this.props.contractTypesById}
            issueset={issueset}
            documentId={usedReport.id}
            projectId={this.props.project.id}
            organisationId={this.props.organisationId}
            document={usedReport}
          />
        )}
      </>
    );
  }

  getReportIssueset() {
    const {contractTypesById} = this.props;
    const issuesetMasterId = this.props.reports?.settings
      ?.used_issueset_master_id;
    let reportIssueset = null;
    Object.keys(contractTypesById).find(ctId => {
      const foundIssueset = contractTypesById[ctId].issuesets.find(
        issueset => issueset.master_id === issuesetMasterId,
      );
      if (foundIssueset) {
        reportIssueset = foundIssueset;
        return true;
      }
      return false;
    });
    return reportIssueset;
  }

  selectDocumentType = documentType => {
    this.setState({documentType});
  };

  onAddDocumentClick = () => {
    const {id: documentTypeId} = this.props.project.document_type;
    if (!documentTypeId) {
      return this.showPreUploadDialog("set_document_type");
    } else if (!this.props.project.default_contract_type.id) {
      return this.showPreUploadDialog("set_default_contract_type");
    }
    switch (documentTypeId) {
      case 1: // contract
      case 4: // extract contract from packet
        return this.showContractUploader();
      case 2: {
        // contract (compare to template)
        if (!this.hasDocuments()) {
          return this.showContractTemplateUploader();
        }
        return this.showCompareWithTemplateUploader();
      }
      case 3: {
        // form (compare to template)
        if (!this.hasDocuments()) {
          return this.showOutputTemplateUploader();
        } else if (this.hasTemplates() && this.hasOnlyOutputTemplate()) {
          return this.showFormTemplateUploader();
        }
        return this.showPreUploadDialog("form");
      }
      case 5: {
        // templated contract
        if (!this.hasDocuments()) {
          return this.showContractTemplateUploader();
        }
      }
    }
  };

  getTemplates() {
    return this.props.documents.filter(document => document.is_template);
  }
  hasDocuments() {
    return this.props.documents.length > 0;
  }
  hasTemplates() {
    return this.getTemplates().length > 0;
  }
  hasOnlyOutputTemplate() {
    const templates = this.getTemplates();
    return (
      templates.length === 1 &&
      templates[0].is_output_template &&
      this.props.formTemplates.length === 0
    );
  }
  showReportDetail = reportDetailDocumentId => {
    this.props.fetchDocumentReportDetail(
      this.props.organisationId,
      this.props.projectId,
      reportDetailDocumentId,
    );
    this.setState(() => ({reportDetailDocumentId}));
  };
  closeReportDetail = () =>
    this.setState(() => ({reportDetailDocumentId: null}));
  showRenderUploader = name =>
    this.setState(() => ({uploaderToShow: name, shownDialogueName: ""}));
  showContractUploader = () => this.showRenderUploader("contract");
  showContractTemplateUploader = () =>
    this.showRenderUploader("contract_template");
  showCompareWithTemplateUploader = () =>
    this.showRenderUploader("contract_compare_with_template");
  showFormUploader = () =>
    this.showRenderUploader("form_compare_with_template");
  showFormTemplateUploader = () => this.showRenderUploader("form_template");
  showOutputTemplateUploader = () =>
    this.showRenderUploader("form_output_template");
  closeUploaded = () => this.setState(() => ({uploaderToShow: ""}));

  renderUploader = () => {
    switch (this.state.uploaderToShow) {
      case "contract":
        return this.renderDocumentUploader();
      case "contract_template":
        return this.renderTemplateUploader();
      case "contract_compare_with_template":
        return this.renderCompareUploader();
      case "form_compare_with_template":
        return this.renderCompareToFormUploader();
      case "form_template":
        return this.renderFormTemplateUploader();
      case "form_output_template":
        return this.renderOutputTemplateUploader();
      default:
        return null;
    }
  };
  renderDocumentUploader() {
    return (
      <DropZone
        className="upload-area"
        key="upload-area"
        multiple={true}
        onDrop={this.selectDocumentForUpload}
      >
        {this.renderDropArea("Upload a document")}
      </DropZone>
    );
  }
  renderTemplateUploader() {
    return (
      <DropZone
        className="upload-template-area"
        key="upload-template-area"
        multiple={false}
        onDrop={this.uploadTemplate}
      >
        {this.renderDropArea("Upload a template")}
      </DropZone>
    );
  }
  renderOutputTemplateUploader() {
    return (
      <DropZone
        className="upload-output-template-area"
        key="upload-output-template-area"
        multiple={false}
        onDrop={this.uploadOutputTemplate}
      >
        {this.renderDropArea("Upload an output template")}
      </DropZone>
    );
  }
  renderCompareUploader() {
    return (
      <DropZone
        className="upload-compare-area"
        key="upload-compare-area"
        multiple={true}
        onDrop={this.uploadCompare}
      >
        {this.renderDropArea("Compare document to template")}
      </DropZone>
    );
  }
  renderCompareToFormUploader() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <DropZone
          className="upload-compare-area"
          key="upload-compare-area"
          multiple={true}
          onDrop={this.uploadCompareToFormTemplate}
          style={{
            width: "100%",
          }}
        >
          {this.renderDropArea("Compare form to template")}
        </DropZone>
      </div>
    );
  }
  renderFormTemplateUploader() {
    return (
      <DropZone
        className="upload-compare-area"
        key="upload-form-template-area"
        multiple={false}
        onDrop={this.uploadFormTemplate}
      >
        {this.renderDropArea("Add form template")}
      </DropZone>
    );
  }

  renderDropArea(text) {
    return (
      <div key={text} style={styles.uploadArea}>
        <UploadIcon
          style={{height: "42px", width: "42px", fill: colors.grey500}}
        />
        <div style={{fontSize: "24px", color: colors.grey500}}>{text}</div>
        <div style={{fontSize: "14px", color: colors.grey500}}>
          <span style={{textDecoration: "underline", fontWeight: 600}}>
            Choose a file
          </span>
          <span> or drag it here</span>
        </div>
      </div>
    );
  }
  renderUploadJoinPrompt() {
    if (this.state.showDocumentJoinerPrompt) {
      return (
        <JoinUploadsPrompt
          files={this.state.uploadFiles}
          onConfirm={(files, joinFiles) => {
            this.props.onDocumentUploaded(
              files,
              false,
              null,
              false,
              null,
              null,
              joinFiles,
            );
            this.setState({
              showDocumentJoinerPrompt: false,
              uploaderToShow: false,
            });
          }}
          onClose={() =>
            this.setState({
              showDocumentJoinerPrompt: false,
              uploaderToShow: false,
            })
          }
        />
      );
    }
  }

  /* eslint-disable no-invalid-this */
  onFormTemplateSelected = (event, index, formTemplateId) => {
    this.setState({formTemplateId});
  };
  selectDocumentForUpload = files => {
    const {project, contractTypesById} = this.props;
    const {document_type: documentType} = project;
    if (project.select_checklist_on_upload && documentType.id === 1) {
      const dialogIssuesets = getProjectIssuesets(project, contractTypesById);
      if (dialogIssuesets.length > 1) {
        this.setState({
          uploaderToShow: "",
          shownDialogueName: "select_checklist",
          fileToUpload: files,
        });
      } else {
        const issuesets = dialogIssuesets.map(is => is.id);
        this.setState(
          () => ({uploaderToShow: ""}),
          () =>
            this.props.onDocumentUploaded(files, false, null, false, issuesets),
        );
      }
    } else {
      if (files.length > 1) {
        this.setState({showDocumentJoinerPrompt: true, uploadFiles: files});
      } else {
        this.setState(
          () => ({uploaderToShow: ""}),
          () => this.props.onDocumentUploaded(files, false),
        );
      }
    }
  };
  uploadDocumentSavedInState = issuesets => {
    const files = this.state.fileToUpload;
    this.setState(
      () => ({
        shownDialogueName: "",
        fileToUpload: null,
      }),
      () => this.props.onDocumentUploaded(files, false, null, false, issuesets),
    );
  };
  uploadTemplate = files => {
    this.props.onDocumentUploaded(files, true, null, false);
    this.setState({uploaderToShow: ""});
  };
  uploadOutputTemplate = files => {
    this.props.onDocumentUploaded(files, true, null, true);
    this.setState({uploaderToShow: ""});
  };
  uploadCompare = files => {
    const template = this.getTemplate();
    this.props.onDocumentUploaded(
      files,
      false,
      template.id,
      template.is_output_template,
    );
    this.setState({uploaderToShow: ""});
  };
  uploadCompareToFormTemplate = files => {
    const template = this.getTemplate();
    this.props.onCompareToFormTemplate(
      files,
      template.id,
      this.state.formTemplateId,
    );
    this.setState({uploaderToShow: ""});
  };
  uploadFormTemplate = file => {
    const template = this.getTemplate();
    this.props.onFormTemplateUploaded(file[0], template.id);
    this.setState({uploaderToShow: ""});
  };

  getTemplate() {
    return this.props.documents.find(document => document.is_template);
  }

  getDocuments = () => {
    const {documentType} = this.state;

    // TODO: Unify null/UNINITIALISED state?
    if (
      this.props.documents === null ||
      !isInitialised([this.props.documents])
    ) {
      return [];
    }

    return this.props.documents.filter(document => {
      const isReviewed = document.state_id === 3 || document.state_id === 4;
      if (documentType === PENDING_DOCUMENT) {
        return !isReviewed;
      } else if (documentType === REVIEWED_DOCUMENT) {
        return isReviewed;
      }

      return true;
    });
  };

  getDocumentParties = () => {
    const {documents, parties} = this.props;
    const partiesById = byId(parties);

    const documentPartiesById = documents.reduce(
      (documentParties, document) => {
        document.parties.map(documentParty => {
          documentParties[documentParty.id] = partiesById[documentParty.id];
          return documentParty;
        });

        return documentParties;
      },
      {},
    );

    return Object.keys(documentPartiesById).reduce((documentParties, key) => {
      if (!documentPartiesById[key]) {
        return documentParties;
      }
      return documentParties.concat(documentPartiesById[key]);
    }, []);
  };

  renderBlankMessage = () => {
    const organisation = this.props.user.organisations.find(
      org => org.id === this.props.organisationId,
    );
    if (organisation && organisation.is_beta && organisation.upload_email) {
      return (
        <div className="blank-message">
          <span>
            Please email your contracts to{" "}
            <a
              href={`mailto: ${organisation.upload_email}`}
              style={{color: colors.blue200}}
            >
              {organisation.upload_email}
            </a>{" "}
            to begin
          </span>
        </div>
      );
    }
    return (
      <div className="blank-message">
        {this.props.searchValue || this.props.onlyShowMyDocuments
          ? "No documents found"
          : "Please start uploading documents to begin"}
      </div>
    );
  };

  renderDialog = () => {
    if (!this.state.shownDialogueName) {
      return null;
    }
    if (this.state.shownDialogueName === "select_checklist") {
      return (
        <SelectChecklistDialog
          onDismiss={this.dismissDialog}
          project={this.props.project}
          contractTypesById={this.props.contractTypesById}
          onFinish={this.uploadDocumentSavedInState}
        />
      );
    }
    if (this.state.shownDialogueName === "document_upload_logs") {
      return (
        <DocumentLogs
          title="Document Upload Logs"
          logs={this.state.documentUploadLogs}
          clear={this.onHideDocumentUploadLogsDialog}
          LogsComponent={UploadLogsTable}
        />
      );
    }
    return (
      <PreUploadDialog
        onDismiss={this.dismissDialog}
        project={this.props.project}
        dialogType={this.state.shownDialogueName}
        documentTypes={this.props.documentTypes}
        setDocumentType={this.props.setDocumentType}
        contractTypes={this.props.contractTypes}
        setContractType={this.props.setContractType}
        showFormUploader={this.showFormUploader}
        showFormTemplateUploader={this.showFormTemplateUploader}
        onFormTemplateSelected={this.onFormTemplateSelected}
        formTemplates={this.props.formTemplates}
        selectedFormTemplateId={this.state.formTemplateId}
      />
    );
  };

  showRevisionDialog = _.memoize(
    documentToRevise => () => this.setState({documentToRevise}),
    documentToRevise => JSON.stringify(documentToRevise),
  );

  renderRevisionDialog = () => {
    const {state} = this;
    const shouldShowRevisionDialog = Boolean(state.documentToRevise);
    return (
      <Dialog
        onClose={this.showRevisionDialog(null)}
        aria-labelledby="add-new-revision-to-document"
        open={shouldShowRevisionDialog}
      >
        <DialogTitle id="simple-dialog-title">Upload New Revision</DialogTitle>
        <DropZone
          className="upload-revision-area"
          key="upload-revision-area"
          multiple={true}
          onDrop={this.updloadRevisionDocument}
        >
          {this.renderDropArea("Upload a document revision")}
        </DropZone>
      </Dialog>
    );
  };

  updloadRevisionDocument = files => {
    const {documentToRevise} = this.state;
    const {group_id: groupId} = documentToRevise;
    if (documentToRevise) {
      this.setState(
        state => ({
          documentToRevise: null,
          isRevisionOpen: {
            ...state.isRevisionOpen,
            [groupId]: true,
          },
        }),
        () => {
          this.props.onDocumentUploaded(
            files,
            false,
            null,
            false,
            null,
            groupId,
          );
        },
      );
    }
  };

  toggleRevisionVisability = _.memoize(groupId => () => {
    this.setState(({isRevisionOpen}) => ({
      isRevisionOpen: {
        ...isRevisionOpen,
        [groupId]: !isRevisionOpen[groupId],
      },
    }));
  });

  showDocumentLogs = documentId =>
    this.setState(
      () => ({shouldShowLogs: true}),
      () => this.props.showDocumentLogs(documentId),
    );

  hideDocumentLogs = () =>
    this.setState(
      () => ({shouldShowLogs: false}),
      () => this.props.logsClear(),
    );

  showDocumentUploadLogs = async documentId => {
    const logs = (await this.props.documentUploadLogsFetch(documentId)).value;
    if (logs && Array.isArray(logs) && logs.length > 0) {
      this.setState(() => ({
        documentUploadLogs: logs,
        shownDialogueName: "document_upload_logs",
      }));
    }
  };

  onHideDocumentUploadLogsDialog = () =>
    this.setState(() => ({documentUploadLogs: null, shownDialogueName: ""}));

  // !!! To use this please fetch document.data in get_document_by_id.sql
  // onDocumentReview = _.memoize(documentId => () =>
  //   this.setState(
  //     () => ({reviewedDocumentId: documentId}),
  //     () => {
  //       const {documentToReview} = this.props;
  //       if (
  //         documentId &&
  //         (!documentToReview || documentId !== documentToReview.id)
  //       ) {
  //         this.props.fetchDocumentFile(documentId);
  //       }
  //     },
  //   ),
  // );
}

DocumentList.propTypes = {
  initialShowDocumentUploadForm: PropTypes.bool,
  organisationId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      last_edited: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onDocumentDeleted: PropTypes.func.isRequired,
  onDocumentUploaded: PropTypes.func.isRequired,
  parties: PropTypes.array.isRequired,
};
