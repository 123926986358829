import React from "react";

import renderAlgorithmName from "../../util/render_algorithm_name";

import EmbeddingTopic from "modules/searcher/types/embedding_topic";
import {SearchResult as SearchResultObj} from "../../types";

interface ResultsProps {
  searchResults: SearchResultObj[];
  algorithms: string[];
  embeddingTopics: EmbeddingTopic[];
}

export default function TableCombined({
  searchResults,
  algorithms,
  embeddingTopics,
}: ResultsProps) {
  return (
    <table>
      <thead>
        <th>Document Id</th>
        <th>Clause Ref</th>
        <th>Clause text</th>
        <th>Score</th>
        {algorithms.map(algorithm => (
          <th key={algorithm} style={{textTransform: "capitalize"}}>
            {renderAlgorithmName(algorithm, embeddingTopics)}
          </th>
        ))}
      </thead>
      <tbody>
        {searchResults.map((searchResult, index) => (
          <SearchResult
            key={index}
            searchResult={searchResult}
            algorithms={algorithms}
          />
        ))}
      </tbody>
    </table>
  );
}

interface Props {
  searchResult: SearchResultObj;
  algorithms: string[];
}

const styles = {
  score: {
    minWidth: "4em",
    fontFamily: "monospace",
  },
};

function SearchResult({searchResult, algorithms}: Props) {
  return (
    <tr
      style={{
        border: "1px solid black",
        borderRadius: "0.5em",
        padding: "1em",
        margin: "1em",
      }}
    >
      <td>{searchResult.document_id}</td>
      <td style={{textAlign: "right"}}>{searchResult.reference}</td>
      <td>{searchResult.partial_text}</td>
      <td style={styles.score}>{searchResult?.score?.toFixed(2)}</td>
      {algorithms.map(algorithm => (
        <td key={algorithm} style={styles.score}>
          {renderScore(searchResult, algorithm)}
        </td>
      ))}
    </tr>
  );
}

function renderScore(searchResult: SearchResultObj, algorithm: string) {
  const algoKey = algorithm.match(/~/)
    ? Object.keys(searchResult.scores).find(key => key.startsWith(algorithm))
    : algorithm;
  if (algoKey) {
    const score = searchResult.scores[algoKey]?.toFixed(2);
    const normalisedScore = searchResult.normalised_scores[algoKey]?.toFixed(2);
    return <span title={`Normalised: ${normalisedScore}`}>{score}</span>;
  }
  return null;
}
