export default function newlineToBreak(str, is_xhtml = true) {
  if (typeof str === "undefined" || str === null) {
    return "";
  }
  const breakTag = is_xhtml ? "<br />" : "<br>";
  return String(str).replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    `$1${breakTag}$2`,
  );
}
