import React from "react";

import UploadMenuItem from "./upload_menu_item";

function SetContractTypeMenu(props) {
  return (
    <div>
      {props.contractTypes.map(ct => (
        <UploadMenuItem
          key={ct.id}
          id={ct.id}
          selectedItemId={props.selectedItemId}
          selectItem={props.selectItem}
        >
          {ct.name}
        </UploadMenuItem>
      ))}
    </div>
  );
}

export default SetContractTypeMenu;
