import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import getRouteIds from "utils/get_route_ids";

export function transition(prevState, nextState, action) {
  if (action.type === ActionTypes.TOPIC_DELETE.SUCCESS) {
    const {organisationId} = getRouteIds(nextState.router);
    return {
      pathname: `/organisation/${organisationId}/topic/list`,
    };
  }
  return null;
}

export default function deleteTopic(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPIC_DELETE,
    (organisationId, topicId, lastEdited) =>
      requestor["delete"](
        `${basePath}/organisation/${organisationId}/topic/${topicId}/`,
        {withCredentials: true, data: {last_edited: lastEdited}},
      ).then(response => response.data),
    null,
    {transition},
  );
}
