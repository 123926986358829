import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {Link} from "react-router";

import blue from "@material-ui/core/colors/blue";
import HelpIcon from "@material-ui/icons/Help";
import OpenIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/KeyboardArrowUp";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CompareIcon from "@material-ui/icons/Compare";
import MuiTooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {Pagination} from "@material-ui/lab";

import {Table, Tr, Th, Td} from "common_components/table";
import Tooltip from "common_components/tooltip";
import StateSelect from "common_components/document/state_select";
import renderProcessingDocumentStatus from "common_components/render_processing_document_status";
import RowActionMenu from "./row_action_menu";
import getDocumentPathName from "../utils/get_document_path_name";
import ErrorIcon from "../error_icon";
import EditableField from "./editable_field";
import PartyList from "common_components/party_list";
import LoadingCircular from "common_components/loading_circular";

import getDocumentIssuesOutOfDateStatus from "common/utils/document/get_document_issues_out_of_date_status";
import ConfirmationDialog from "./confirmation_dialog";
import Permissioner from "utils/permissioner";

const paginationBlockHeight = "4rem";

const styles = {
  rowHover: {
    backgroundColor: "#efeff1",
  },
  documentName: {
    fontWeight: 500,
  },
  uploadUsername: {
    color: "#222",
    fontWeight: 500,
  },
  stateContainerStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: "1rem",
  },
  revisionIcon: {
    color: "#8e959b",
  },
  documentNameInput: {
    fontSize: "14px",
    fontWeight: "500",
  },
  documentTypeName: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.5)",
  },
  resetManualRolesTooltip: {
    position: "absolute",
    top: "50px",
    right: "-35px",
    background: "#000",
    padding: "5px",
    borderRadius: "5px",
    color: "#fff",
  },
  menuButton: {
    height: 18,
    width: 18,
    marginRight: "3px",
  },
  tableWrapper: {
    width: "100%",
    height: `calc(100% - ${paginationBlockHeight})`,
    overflow: "auto",
    position: "relative", // So loadingWrapper can be relatively positioned
  },
  loadingWrapper: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paginationBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "3px solid grey",
    boxSizing: "border-box",
    color: "rgb(35, 46, 56)",
    width: "100%",
    height: paginationBlockHeight,
  },
};

function getLatestOutOfDateDocument(documents, project) {
  let latestOutOfDateDocument;
  let latestDate;
  documents.forEach(doc => {
    if (getDocumentIssuesOutOfDateStatus(doc, project) === "out_of_date") {
      if (!latestDate) {
        latestOutOfDateDocument = doc;
        latestDate = new Date(doc.issues_last_kicked_off);
      } else {
        const docIssuesLastKickedOffDate = new Date(doc.issues_last_kicked_off);
        if (docIssuesLastKickedOffDate > latestDate) {
          latestOutOfDateDocument = doc;
          latestDate = docIssuesLastKickedOffDate;
        }
      }
    }
  });
  return latestOutOfDateDocument;
}

export default class DocumentListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      isShowTooltip: false,
      dialogData: {
        open: false,
        title: null,
        action: null,
      },
      reclassifiedDocumentData: {
        documentId: null,
        lastEdited: null,
        contractType: null,
      },
    };
  }

  createIssueRootRef = node => (this.issueRootRef = node);

  render() {
    const {organisationId, documents, project, user} = this.props;
    const {showExtraColumns} = this.props;
    return (
      <>
        <div style={styles.tableWrapper}>
          <Table
            sortDirection="desc"
            sortColumnIndex={user.is_admin ? 4 : 3}
            hasStickyHeader={true}
            keepGroups={true}
          >
            <thead style={{color: "rgb(35, 46, 56)"}}>
              <tr>
                <Th style={{width: "25%"}}>
                  <span style={{paddingLeft: "15px"}}>Document Name</span>
                </Th>
                {user.is_admin ? <Th style={{width: "1rem"}} /> : null}
                <Th style={{width: "10%"}}>Contract Type</Th>
                <Th style={{width: "25%"}}>Parties</Th>
                {showExtraColumns ? <Th>Issues</Th> : null}
                {showExtraColumns ? <Th>Clauses</Th> : null}
                <Th style={{width: "15%"}}>Uploaded</Th>
                {project.verify_issues ? (
                  <Th style={{width: "10%"}}>Reviewed</Th>
                ) : null}
                <Th style={{width: "15%"}}>Status</Th>
                {user.is_admin ? (
                  <Th
                    isSortable={false}
                    style={{width: "1%"}}
                    headerCellStyle={{padding: 0}}
                  >
                    {this.shouldRenderRefreshButton() && (
                      <RefreshIcon
                        onClick={this.props.runDocumentsReprocess}
                        style={{height: 18, width: 18, cursor: "pointer"}}
                      />
                    )}
                  </Th>
                ) : null}
                {user.is_admin ? (
                  <Th
                    isSortable={false}
                    style={{width: "1%"}}
                    headerCellStyle={{justifyContent: "end", padding: 0}}
                  >
                    {
                      <>
                        {this.state.isShowTooltip ? (
                          <div style={styles.resetManualRolesTooltip}>
                            When clicked, removes all roles from documents in
                            the project that have been manually set
                          </div>
                        ) : null}
                        <IconButton
                          aria-controls="long-menu"
                          ref={this.createIssueRootRef}
                          style={styles.menuButton}
                          onClick={this.handleMenu}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={this.issueRootRef}
                          open={this.state.isMenuOpen}
                          onClose={this.handleMenu}
                        >
                          <MenuItem
                            key={"reset_manual_roles"}
                            onClick={this.handleResetManualRoles}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            Reset manual roles
                            <HelpIcon
                              style={{marginLeft: "1em", color: "#aaa"}}
                              onMouseEnter={this.setTooltipIsShown}
                              onMouseLeave={this.setTooltipIsHide}
                            />
                          </MenuItem>
                          <MenuItem
                            key={"llmRuns"}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                            }}
                          >
                            <Link
                              style={{color: "black", textDecoration: "none"}}
                              to={`/organisation/${organisationId}/llm_run/list?project=${project.id}`}
                            >
                              View LLM Runs
                            </Link>
                          </MenuItem>
                        </Menu>
                      </>
                    }
                  </Th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {documents && documents.length
                ? documents.reduce(
                    (acc, document, index) => [
                      ...acc,
                      ...this.renderDocumentRows(document, index),
                    ],
                    [],
                  )
                : null}
            </tbody>
          </Table>

          {!(documents && documents.length) ? (
            <div style={styles.loadingWrapper}>
              <LoadingCircular />
            </div>
          ) : null}
        </div>

        {this.props.onNavigate &&
        this.props?.documentsCount &&
        this.props.documentsCount > 50 ? (
          <div style={styles.paginationBlock}>
            <Pagination
              onChange={(_, page) => this.props.onNavigate(page)}
              page={this.props.page}
              count={Math.ceil(this.props.documentsCount / 50)}
              variant="outlined"
              shape="rounded"
            />
          </div>
        ) : null}

        <ConfirmationDialog
          dialogData={this.state.dialogData}
          close={this.hideDialog}
        />
      </>
    );
  }

  renderDocumentRows = (document, documentIndex) => {
    const rows = [];
    const isRevision = document.revisions && document.isGroupParent;
    if (isRevision) {
      rows.push(this.renderRevisionTitleRow(document));
    }
    rows.push(this.renderDocumentRow(document, documentIndex));
    if (isRevision) {
      if (this.props.isRevisionOpen[document.group_id]) {
        rows.push(
          ...document.revisions.map((doc, index) =>
            this.renderDocumentRow(doc, index, true),
          ),
        );
      }
      rows.push(this.renderToggleRevisionRow(document));
    }
    return rows;
  };

  renderRevisionTitleRow(document) {
    return (
      <Tr
        key={`${document.id}-revision-title`}
        linkPath={""}
        groupId={document.group_id}
      >
        <Td
          key="revision-title"
          linkPath={""}
          colSpan="11"
          style={{
            fontWeight: "bold",
            color: "rgb(66, 66, 66)",
          }}
          sortText={moment(document.creation_date).format("x")}
        >
          <div
            style={{
              marginTop: ".5em",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0rem 2rem 0rem 1rem",
              columnGap: "1rem",
            }}
          >
            {this.renderDocumentName(document.name, [])}
            {new Permissioner(this.props.user).hasPermission(
              "show-comparison-summary",
            ) &&
              this.renderDocumentComparisonSummaryLink(
                document.revisions.length > 0
                  ? document.revisions[document.revisions.length - 1]?.id
                  : document.id,
                document.revisions.length > 0 ? document.id : undefined,
              )}
          </div>
        </Td>
      </Tr>
    );
  }

  renderToggleRevisionRow(document) {
    const isOpened = Boolean(this.props.isRevisionOpen[document.group_id]);
    const toggleRevisionVisability = this.props.toggleRevisionVisability(
      document.group_id,
    );
    return (
      <Tr
        key={`${document.id}-group-toggle`}
        linkPath={""}
        groupId={document.group_id}
        style={{
          border: "1px solid rgb(230, 234, 240)",
          borderRight: "none",
          backgroundColor: "#fff",
        }}
      >
        <Td
          key="revision"
          linkPath={""}
          colSpan="11"
          onClick={toggleRevisionVisability}
          style={{
            paddingLeft: "1rem",
            width: "calc(100% - 1px)",
            cursor: "pointer",
            borderRight: "1px solid rgb(230, 234, 240)",
            backgroundColor: "#fafafa",
          }}
        >
          <span style={{display: "flex", alignItems: "center"}}>
            {isOpened ? <CloseIcon /> : <OpenIcon />}
            {`${isOpened ? "Hide" : "Show"} ${
              document.revisions.length
            } previous revisions`}
          </span>
        </Td>
      </Tr>
    );
  }

  renderDocumentRow = (document, documentIndex, isRevision = false) => {
    return (
      <Tr
        key={`${document.id}-${documentIndex}`}
        hoverStyle={styles.rowHover}
        style={{
          ...(isRevision || document.isGroupParent
            ? {
                border: "1px solid rgb(230, 234, 240)",
                borderRight: "none",
                backgroundColor: isRevision ? "#fafafa" : "#fff",
              }
            : {}),
        }}
        groupId={document.group_id}
      >
        {[
          this.renderDocumentNameTd(
            document,
            isRevision || document.isGroupParent,
          ),
          ...this.renderErrorIconTd(document),
          ...this.renderState(document),
          <Td
            key="action_menu"
            linkPath={""}
            containerStyle={{
              paddingLeft: 0,
            }}
            style={{
              borderRight:
                isRevision || document.isGroupParent
                  ? "1px solid rgb(230, 234, 240)"
                  : "none",
            }}
            colSpan={"2"}
          >
            <RowActionMenu
              user={this.props.user}
              document={document}
              project={this.props.project}
              projects={this.props.projects}
              toggleAnalystView={this.props.toggleAnalystView}
              viewSummaryPage={this.props.viewSummaryPage}
              onDocumentDownloaded={this.props.onDocumentDownloaded}
              onEmailReport={this.props.onEmailReport}
              handleDocumentReclassified={this.handleDocumentReclassified}
              updateDocumentLoadState={this.props.updateDocumentLoadState}
              onDocumentDeleted={this.props.onDocumentDeleted}
              updateDocument={this.props.updateDocument}
              showRevisionDialog={this.props.showRevisionDialog(document)}
              showDocumentLogs={this.showDocumentLogs(document.id)}
              documentStates={this.props.documentStates}
              runDocumentIssuesFind={this.props.runDocumentIssuesFind}
              reprocessDocumentRoles={this.props.reprocessDocumentRoles}
              regenerateDocumentEmbeddings={
                this.props.regenerateDocumentEmbeddings
              }
              showDocumentUploadLogs={this.props.showDocumentUploadLogs}
              // onDocumentReview={this.props.onDocumentReview(document.id)}
            />
          </Td>,
        ]}
      </Tr>
    );
  };

  renderErrorIconTd(document) {
    const {user} = this.props;
    if (!user.is_admin) {
      return [];
    }
    return [
      <Td key="error-icon">
        <ErrorIcon user={user} document={document} />
      </Td>,
    ];
  }

  renderDocumentNameTd = (document, isRevision) => {
    const {user, project} = this.props;
    const showContractTypeName =
      user.is_admin &&
      this.shouldShowContractTypeName(
        project.default_contract_type.name,
        document.contract_type.name,
      );
    const {concat_load_state: loadState} = document;
    const canBeOpened = (loadState ?? []).every(item => item === 3);
    const linkPath = this.getDocumentPathName(document);

    return (
      <Td
        key="name"
        className="name"
        containerStyle={{padding: 0}}
        handleChildren={(children, thisTd) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "15px",
                color: !canBeOpened
                  ? "#888"
                  : thisTd.props.isRowHovered
                  ? blue[500]
                  : "#424242",
                textDecoration:
                  canBeOpened && thisTd.props.isRowHovered
                    ? "underline"
                    : "none",
                marginLeft: isRevision ? "1em" : "0",
              }}
            >
              <EditableField
                value={document.name}
                fileExtension={document.file_extension}
                linkPath={
                  new Permissioner(this.props.user).hasPermission(
                    "get-document-detail",
                  )
                    ? linkPath
                    : undefined
                }
                onValueUpdate={this.updateDocumentName(document)}
                nonEditingEl={
                  <div style={styles.documentName}>
                    {this.renderDocumentName(
                      document.name,
                      document.concat_filenames,
                    )}
                    {showContractTypeName && <span>{" - "}</span>}
                    {showContractTypeName && (
                      <span style={styles.documentTypeName}>
                        {document.contract_type.name}
                      </span>
                    )}
                  </div>
                }
                isBadlyParsed={Boolean(
                  document.is_badly_parsed && user.is_admin,
                )}
              />
              {children}
            </div>
          );
        }}
      />
    );
  };

  shouldRenderRefreshButton = () => {
    const {documents, project} = this.props;
    if (project.should_reprocess_issues) {
      return true;
    }
    const {last_issues_run: projectLastIssuesRun} = project;
    const latestOutOfDateDocument = getLatestOutOfDateDocument(
      documents,
      project,
    );
    if (latestOutOfDateDocument) {
      if (!projectLastIssuesRun) {
        return true;
      }
      const projectLastIssuesRunDate = new Date(projectLastIssuesRun);

      const documentIssuesLastChangedDate = new Date(
        latestOutOfDateDocument.issues_last_changed,
      );
      const documentIssuesLastProcessedDate = new Date(
        latestOutOfDateDocument.issues_last_processed,
      );
      const documentClassificationLastChangedDate = new Date(
        latestOutOfDateDocument.last_classification_changed,
      );

      const documentIssuesLastKickedOffDate = new Date(
        latestOutOfDateDocument.issues_last_kicked_off,
      );
      const documentRolesLastKickedOffDate = new Date(
        latestOutOfDateDocument.roles_last_kicked_off,
      );
      const documentRolesLastProcessedDate = new Date(
        latestOutOfDateDocument.roles_last_processed,
      );

      const classificationChangedRecently =
        documentClassificationLastChangedDate > documentIssuesLastChangedDate;
      const issuesChangedRecently =
        documentIssuesLastChangedDate > documentIssuesLastProcessedDate;
      const issuesNotKickedOffYet =
        projectLastIssuesRunDate < documentIssuesLastKickedOffDate ||
        documentIssuesLastChangedDate > documentIssuesLastKickedOffDate;

      const rolesChangedRecently = latestOutOfDateDocument.roles_out_of_date;
      const rolesNotKickedOffYet =
        projectLastIssuesRunDate > documentRolesLastKickedOffDate ||
        documentRolesLastKickedOffDate < documentRolesLastProcessedDate;

      const projectOutOfDate =
        ((classificationChangedRecently || issuesChangedRecently) &&
          issuesNotKickedOffYet) ||
        (rolesChangedRecently && rolesNotKickedOffYet);

      return projectOutOfDate;
    }
    return false;
  };

  shouldShowContractTypeName = (projectCTName, documentCTName) => {
    return !(
      !documentCTName ||
      !projectCTName ||
      projectCTName === documentCTName
    );
  };

  renderDocumentName = (name, filenames = []) => {
    // const searchValue = this.props.searchValue.toLowerCase();
    // const startIndex = name.toLowerCase().indexOf(searchValue);
    // const endIndex = startIndex + searchValue.length;

    // if (startIndex !== -1) {
    //   return (
    //     <span>
    //       {name.slice(0, startIndex)}
    //       <span style={{color: "rgb(32, 150, 243)"}}>
    //         {name.slice(startIndex, endIndex)}
    //       </span>
    //       {name.slice(endIndex)}
    //     </span>
    //   );
    // }

    const fileCount = filenames?.length ?? 0;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {name}
        {fileCount > 1 && (
          <MuiTooltip
            title={
              <div>
                {filenames.map((filename, index) => (
                  <div key={index}>{filename}</div>
                ))}
              </div>
            }
          >
            <Chip
              label={`${fileCount} files`}
              size="small"
              style={{marginLeft: "0.5em"}}
            />
          </MuiTooltip>
        )}
      </div>
    );
  };

  renderDocumentComparisonSummaryLink = (
    baseDocumentId,
    comparisonDocumentId,
  ) => {
    const {organisationId, projectId} = this.props;

    return (
      <Link
        style={{color: "rgb(142, 149, 155)"}}
        to={{
          pathname: `/organisation/${organisationId}/project/${projectId}/document/${baseDocumentId}/comparison`,
          search:
            comparisonDocumentId !== undefined
              ? `?comparison_document=${comparisonDocumentId}`
              : undefined,
        }}
      >
        <CompareIcon />
      </Link>
    );
  };

  renderState(document) {
    const {project} = this.props;
    const notCompletedProcessingDocumentStatus = renderProcessingDocumentStatus(
      document,
    );
    if (notCompletedProcessingDocumentStatus) {
      const tableItems = [
        <Td key="state_td_contract_type" />,
        <Td key="state_td_parties" />,
        <Td
          key="state_td_uploaded"
          sortText={moment(document.creation_date).format("x")}
        >
          {this.renderDateAndUserInfo(
            document.creation_date,
            document.first_name,
            document.last_name,
            document.user_email,
          )}
        </Td>,
      ];
      if (project.verify_issues) {
        tableItems.push(<Td key="state_td_reviewed" />);
      }
      tableItems.push(
        <Td key="state_td_status">{notCompletedProcessingDocumentStatus}</Td>,
      );
      return tableItems;
    }

    // if document has been successfully processed show full info:
    // FYI: when fetching many documents getting this stats slows down
    // perfomance. Please uncomment relevant code in db_templates/documents/get_documents
    // const titleText = [
    //   `Clauses with only confirmed topics: ${document.topic_count}`,
    //   `Clauses with unconfirmed topics: ${document.unconfirmed_topic_count}`,
    //   `Clauses with no topics: ${document.no_topic_count}`,
    // ].join("\n");
    const {
      contract_type,
      creation_date,
      first_name,
      last_name,
      user_email,
      review_data,
      contract_type_id: contractTypeId,
    } = document;

    const {roles: projectRoles} = project;

    const roles = contractTypeId
      ? this.props.roles.filter(
          role => role.contract_types.indexOf(contractTypeId) !== -1,
        )
      : this.props.roles;
    const stateFields = [
      <Td key="state_td_0" className="contract-type">
        {contract_type && contract_type.name ? contract_type.name : "—"}
      </Td>,
      <Td key="state_td_1" className="parties">
        <PartyList
          organisationParties={this.props.parties}
          documentParties={document.parties}
          onUpdate={this.updateDocumentParties(document)}
          roles={roles}
          projectRoles={projectRoles}
          hideTerm={true}
          user={this.props.user}
          updateCount={document.partyUpdateCount}
          areRolesConfirmed={true}
          forcePartyConfirmation={false}
        />
      </Td>,
    ];
    if (this.props.showExtraColumns) {
      stateFields.push(
        <Td key="state_td_2" className="issue-count">
          {document.issue_count === 0 ? null : document.issue_count}
        </Td>,
      );
      stateFields.push(
        <Td key="state_td_3" className="clause-count">
          {document.clause_count}
        </Td>,
      );
    }
    stateFields.push(
      <Td
        key="state_td_5"
        className="uploaded"
        sortText={moment(creation_date).format("x")}
      >
        {this.renderDateAndUserInfo(
          creation_date,
          first_name,
          last_name,
          user_email,
        )}
      </Td>,
    );
    if (project.verify_issues) {
      stateFields.push(
        <Td key="state_td_6" className="uploaded">
          {review_data &&
            this.renderDateAndUserInfo(
              review_data.review_date,
              review_data.first_name,
              review_data.last_name,
              review_data.user_email,
            )}
        </Td>,
      );
    }
    stateFields.push(
      <Td
        linkPath={null}
        key="state_td_status"
        className="document-state"
        // title={!document.has_classifier_errors && titleText}
      >
        <StateSelect
          states={this.props.documentStates}
          stateId={document.state_id}
          updateState={this.onDocumentStateChange(
            document.id,
            document.last_edited,
          )}
        />
        {document.has_classifier_errors && (
          <span style={{fontSize: 9, fontStyle: "italic", display: "flex"}}>
            <Tooltip tooltipContent="Some clauses were unable to be classified.">
              <HelpIcon style={{width: 12, height: 12}} />
            </Tooltip>
            <span style={{paddingLeft: "0l.5m"}}>
              Classification incomplete
            </span>
          </span>
        )}
      </Td>,
    );
    return stateFields;
  }

  handleMenu = () =>
    this.setState(() => ({isMenuOpen: !this.state.isMenuOpen}));
  setTooltipIsShown = () => this.setState(() => ({isShowTooltip: true}));
  setTooltipIsHide = () => this.setState(() => ({isShowTooltip: false}));

  hideDialog = () => {
    this.setState(() => ({
      dialogData: {
        open: false,
        title: null,
        action: null,
      },
    }));
  };

  handleResetManualRoles = () => {
    this.setTooltipIsHide();
    this.handleMenu();
    this.setState(() => ({
      dialogData: {
        open: true,
        title:
          "Are you sure you want to remove all manually assigned roles on documents in this project?",
        action: this.onResetManualRoles,
      },
    }));
  };

  onResetManualRoles = () => {
    this.hideDialog();
    return this.props.deleteDocumentsRoles();
  };

  handleDocumentReclassified = (documentId, lastEdited, contractType) => {
    this.setState(() => ({
      dialogData: {
        open: true,
        title: "Are you sure you want to reclassify document?",
        action: this.onDocumentReclassified,
      },
      reclassifiedDocumentData: {documentId, lastEdited, contractType},
    }));
  };

  onDocumentReclassified = () => {
    const {
      documentId,
      lastEdited,
      contractType,
    } = this.state.reclassifiedDocumentData;
    this.hideDialog();
    return this.props.onDocumentReclassified(
      documentId,
      lastEdited,
      contractType,
    );
  };

  getDocumentPathName = document => {
    const {organisationId, projectId, user} = this.props;
    return getDocumentPathName(organisationId, projectId, user, document);
  };

  renderDateAndUserInfo(creationDate, firstName, lastName, userEmail) {
    const hoursElapsed = (Date.now() - creationDate) / (1000 * 60 * 60);
    const uploadTime =
      hoursElapsed < 4
        ? moment(creationDate).fromNow()
        : moment(creationDate).format("DD/MM/YYYY - h:mm A");

    const author = `${firstName ? firstName : ""} ${
      lastName ? lastName : ""
    }`.trim();
    return (
      <div>
        <div style={{whiteSpace: "nowrap", marginBottom: "0.3em"}}>
          {uploadTime}
        </div>
        <div style={styles.uploadUsername}>
          <Tooltip
            tooltipContent={userEmail}
            onClick={event => event.stopPropagation()}
          >
            {author || userEmail}
          </Tooltip>
        </div>
      </div>
    );
  }

  updateDocumentName = _.memoize(
    document => name =>
      this.props.updateDocument(document.id, {
        name,
        last_edited: document.last_edited,
      }),
    document => `${document.id}-${document.last_edited}`,
  );

  updateDocumentParties = document => updates => {
    this.props.updateDocument(document.id, {
      ...updates,
      last_edited: document.last_edited,
    });
  };

  showDocumentLogs = _.memoize(documentId => () =>
    this.props.showDocumentLogs(documentId),
  );

  onDocumentStateChange = _.memoize(
    (documentId, documentLastEdited) => newStateId => {
      const {props} = this;
      props.updateDocument(documentId, {
        state_id: newStateId,
        last_edited: documentLastEdited,
      });
    },
    (...args) => JSON.stringify([...args]),
  );
}

DocumentListTable.defaultProps = {
  showExtraColumns: false,
};

DocumentListTable.propTypes = {
  documents: PropTypes.array.isRequired,
};
