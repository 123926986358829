import isIssueAlert from "common/utils/issues/is_issue_alert";
import {getIssueManualCorrections} from "utils/manual_corrections_utils";

function getIssueColor(issue, issueset, selectedReport) {
  const manualCorrections = getIssueManualCorrections(issue, issueset, selectedReport);

  if (manualCorrections.alert_color) {
    return manualCorrections.alert_color;
  }

  const {issue_class_id: issueClassId} = issue;
  const isAlert = isIssueAlert(issue);

  return isAlert ? (issueClassId === 3 ? "amber" : "red") : "green";
}

export default getIssueColor;
