import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function permissionsFetch(requestor) {
  return createReduxPromiseAction(ActionTypes.PERMISSIONS_FETCH, () =>
    requestor
      .get(`${basePath}/permission`, {
        withCredentials: true,
      })
      .then(response => response.data),
  );
}
