import React from "react";

export default class DocumentParty extends React.Component {
  render() {
    const {party} = this.props;
    return (
      <div>
        <span
          className="name"
          style={{
            fontWeight: "bold",
            fontSize: "1.2em",
          }}
        >
          {party.name}
        </span>
        {this.renderTerm(party.term)}
        {this.renderAddress(party.address)}
      </div>
    );
  }

  renderTerm(term) {
    if (term && term.length > 0) {
      return (
        <span>
          (<span className="term">{term}</span>)
        </span>
      );
    }
    return null;
  }

  renderAddress(address) {
    if (address && address.length > 0) {
      return (
        <span>
          (<span className="address">{address}</span>)
        </span>
      );
    }
    return null;
  }
}
