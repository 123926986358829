import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../constants/action_types";

/* eslint-disable max-params */
export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_CLAUSEPARTS_REMOVE,
  (
    organisationId,
    projectId,
    documentId,
    sectionId,
    clauseId,
    paths,
    lastEdited,
    // used in action request
    deletionChanges,
    headingData,
  ) =>
    requestor
      .delete(
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/section/${sectionId}/clause/${clauseId}/` +
          "clauseparts",
        {
          withCredentials: true,
          data: {
            last_edited: lastEdited,
            paths,
            ...(headingData && {
              heading_id: headingData.id,
              heading_last_edited: headingData.last_edited,
            }),
          },
        },
      )
      .then(response => response.data),
);
