import basePath from "base_path";
import ActionTypes from "../../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function removeIssueset(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.ISSUESET_REMOVE,
    (organisationId, contractTypeId, issuesetId) => {
      return requestor
        .delete(
          `${basePath}/organisation/${organisationId}/contract_type/${contractTypeId}/issueset/${issuesetId}`,
          {withCredentials: true},
        )
        .then(response => response.data)
        .catch(err => err.data);
    },
  );
  return action;
}
