import React from "react";
import ContractTypeContext from "./contract_type_context";
import _ from "underscore";
import requestor from "requestor";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import ContractTypeList from "../components/contract_type_list";

import addContractTypeAction from "modules/documents/actions/contract_type_add";
import updateContractTypeAction from "modules/documents/actions/contract_type_update";

class ContractTypes extends React.Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return (
      this.permissioner.isAdmin() ||
      this.permissioner.hasPermission("list-contract-types")
    );
  }

  shouldRenderContainer() {
    return isInitialised([this.props.contractTypes, this.props.user]);
  }

  renderComponent() {
    setTitle("Contract Types List");
    return (
      <ContractTypeContext.Provider
        value={{
          addContractType: this.addContractType,
          updateContractType: this.updateContractType,
          onContractTypeDetail: this.onContractTypeDetail,
          user: this.props.user,
        }}
      >
        <ContractTypeList
          contractTypes={_.sortBy(this.props.contractTypes, ct => ct.name)}
        />
      </ContractTypeContext.Provider>
    );
  }

  onContractTypeDetail = contractTypeId => () => {
    if (this.hasPermission("update-contract-type")) {
      this.props.dispatch(
        push(
          `/organisation/${
            this.props.params.organisationId
          }/contract_type/detail/${contractTypeId}`,
        ),
      );
    }
  };
  addContractType = data => {
    this.props.addContractType(this.props.params.organisationId, data);
  };
  updateContractType = (contractTypeId, data) => {
    const {organisationId} = this.props.params;
    this.props.updateContractType(organisationId, contractTypeId, data);
  };
  hasPermission = permission => {
    return this.permissioner.hasPermission(permission);
  };
}

function select(state, props) {
  return {
    params: props.params,
    organisation: state.organisation,
    router: state.router,
    user: state.user,
    contractTypes: state.contract_types,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateContractType: updateContractTypeAction(requestor),
        addContractType: addContractTypeAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(ContractTypes);
