import React from "react";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import {clauseItemStyle} from "../style";
// import Button from "@material-ui/core/Button";
// import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import LanguageText from "common_components/issue_detail/panels/components/language_text";
import moment from "moment";

const ClauseBankItem = ({
  name,
  isPublic,
  username,
  language,
  description,
  issuesetName,
  creationDate,
  predefinedComment,
}) => {
  const languages = language || [];
  return (
    <Accordion style={clauseItemStyle.clauseContainer}>
      <AccordionSummary
        style={clauseItemStyle.summary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div style={clauseItemStyle.clauseItemContainer}>
          <div style={clauseItemStyle.titleContainer}>
            <p style={clauseItemStyle.title}>{name}</p>
            {description && (
              <p style={clauseItemStyle.description}>{description}</p>
            )}
          </div>
          <div style={clauseItemStyle.buttonsContainer}>
            <div style={clauseItemStyle.headerDescription}>
              <p style={clauseItemStyle.detailsDescriptionTitle}>View Type</p>
              <p style={clauseItemStyle.headerDescriptionText}>
                {isPublic ? "Public" : "Private"}
              </p>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={clauseItemStyle.detailContainer}>
        <div style={clauseItemStyle.details}>
          <div style={clauseItemStyle.detailsItem}>
            <div style={clauseItemStyle.detailsItemText}>
              <p style={clauseItemStyle.detailsDescriptionTitle}>Clause Text</p>
              {languages.length ? (
                <LanguageText language={languages} limit={Infinity} />
              ) : (
                <p style={clauseItemStyle.preventCommentText}>
                  {predefinedComment}
                </p>
              )}
            </div>
            {/* <div style={clauseItemStyle.detailsItemButton}> */}
            {/*   <Button */}
            {/*     size="small" */}
            {/*     startIcon={<FileCopyOutlinedIcon/>} */}
            {/*     color="primary" */}
            {/*   > */}
            {/*     Copy text */}
            {/*   </Button> */}
            {/* </div> */}
          </div>
        </div>
        <div style={clauseItemStyle.detailsDescription}>
          <p style={clauseItemStyle.detailsDescriptionTitle}>Added by</p>
          <p style={clauseItemStyle.detailsDescriptionText}>{username}</p>
          <p style={clauseItemStyle.detailsDescriptionTitle}>Checklist</p>
          <p style={clauseItemStyle.detailsDescriptionText}>{issuesetName}</p>
          <p style={clauseItemStyle.detailsDescriptionTitle}>Date Added</p>
          <p style={clauseItemStyle.detailsDescriptionText}>
            {moment(creationDate).format("DD/MM/YYYY HH:mm")}
          </p>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ClauseBankItem;
