import requestor from "requestor";

import ChildRenderer from "common_components/child_renderer";

import BaseRoute from "utils/base_route";

import workflowsFetchAction from "modules/flowmaster/actions/workflows_fetch";
import workflowsClearAction from "modules/flowmaster/actions/workflows_clear";
import contextsFetchAction from "modules/flowmaster/actions/contexts_fetch";

import WorkflowList from "routes/workflow/workflow_list";
import WorkflowDetail from "routes/workflow/workflow_detail";

export default class WorkflowBase extends BaseRoute {
  constructor(store) {
    super("workflow", store);
  }

  createChildRoutes() {
    return [new WorkflowList(this.store), new WorkflowDetail(this.store)];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchWorkflows(state);
    this.fetchContexts(state);
  }

  fetchWorkflows(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(workflowsFetchAction(requestor)(organisationId));
  }

  fetchContexts(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(contextsFetchAction(requestor)(organisationId));
  }

  clearActiveData() {
    const {dispatch} = this.store;
    dispatch(workflowsClearAction());
  }
}
