import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import {get} from "lodash";

import keyedObjectPropType from "utils/keyed_object_prop_type";
import getClausepartData from "./utils/get_clausepart_data";
import getRealTimeScoresByContractType from "./utils/get_rt_scores_by_contract_type";
import addScoresToClassifiers from "./utils/add_scores_to_classifiers";
import maxUsageUtils from "./utils/max_usages_utils";

import TopicAnalysisTabs from "./topic_analysis_tabs";
import TopicLogs from "common_components/topic_logs";
import TopicUsageList from "./topic_usage_list";
import ScoresSummary from "./scores_summary";
import Settings from "./settings";
import SelectedResult from "./selected_result";
import TopicEditorDialog from "./topic_editor_dialog";
import ContractTypesSummary from "./contract_types_summary";
import NegativeTopicSelector from "./negative_topic_selector";
import UsagesFetchLimitInput, {
  getTopicUsagesFetchLimit,
} from "common_components/usages_fetch_limit_input";
import HotProjectSelector from "common_components/project/hot_project_selector";
import HotAndStarInfoWidget from "common_components/hot_and_star_info_widget";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import {Toolbar, ToolbarGroup} from "material-ui/Toolbar";
import RaisedButton from "material-ui/RaisedButton";
import Toggle from "material-ui/Toggle";
import IconMenu from "material-ui/IconMenu";
import IconButton from "material-ui/IconButton";
import MoreIcon from "material-ui/svg-icons/navigation/more-vert";
import {ListItem} from "material-ui/List";
import Checkbox from "material-ui/Checkbox";

import localStorage from "utils/local_storage";
import getText from "utils/clauses/get_text";
import getHotProjectsParams from "utils/get_hot_projects_params";
import trainingModeColors from "./const/training_mode_colors";
import refetchExistingRegexClassifierStatsGradually from "./utils/refetch_existing_regex_classifier_stats_gradually";
import getRegexWithModules from "./utils/get_regex_with_modules";
import getRegexClassifier from "./utils/get_regex_classifier";

const statusData = {
  // [statusLabel, help, status, isConfirmed]
  tp: ["TP", "Regex matches and topic on clause", "tp", true],
  fp: ["FP", "Regex Matches, but topic not on clause", "fp", true],
  fn: ["FN", "Clause has topic, but regex doesn't match", "fn", true],
  tn: ["TN", "Topic not added and regex doesn't match", "tn", true],
  unset: ["unset", "Topic classifier has not run", "unset", true],
};

const styles = {
  fixModeNotification: {
    fontSize: "32px",
    fontWeight: "500",
    textAlign: "center",
    margin: "2rem",
    marginBottom: "0rem",
    color: "#E57373",
  },
  linkToNormalMode: {
    textAlign: "center",
    color: "#E57373",
  },
  contractTypesBlock: {
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    width: "100%",
    marginLeft: 8,
  },
  summaryBlock: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 1rem",
  },
};

function getUsageStatus(usage, selectedClassifier) {
  if (!selectedClassifier || selectedClassifier.name === "regex") {
    let status;
    if (usage.hasRegexMatch && usage.has_topic) {
      status = statusData.tp;
    } else if (usage.hasRegexMatch && !usage.has_topic) {
      status = statusData.fp;
    } else if (usage.has_topic) {
      status = statusData.fn;
    } else {
      status = statusData.tn;
    }
    return status;
  } else {
    let status = [];
    const classifierResult = (usage.classifier_scores ?? []).find(
      result => result.configuration_id === selectedClassifier.configuration_id,
    );
    if (!classifierResult) {
      return statusData.unset;
    } else if (classifierResult.is_classified && usage.has_topic) {
      status = statusData.tp;
    } else if (classifierResult.is_classified && !usage.has_topic) {
      status = statusData.fp;
    } else if (usage.has_topic) {
      status = statusData.fn;
    } else {
      status = statusData.tn;
    }
    return status;
  }
}

const selectedClassifierKey = "topic-analyser-selected-classifier";

class TopicAnalysis extends React.Component {
  constructor(props) {
    super(props);
    const usagesFilterRaw = localStorage.getItem(
      `filteredContractTypeIds_${props.organisationId}`,
    );
    const usagesFilter = usagesFilterRaw
      ? JSON.parse(usagesFilterRaw)
      : usagesFilterRaw;
    const topicContractTypeIds = props.topic.contract_types.map(
      ct => ct.contract_type_id,
    );

    const selectedContractTypeIds = usagesFilter
      ? _.intersection(usagesFilter, topicContractTypeIds)
      : topicContractTypeIds;

    const trainingModeValueRaw = localStorage.getItem(
      "topicAnalysisTrainingMode",
    );
    const trainingModeValue = trainingModeValueRaw
      ? JSON.parse(trainingModeValueRaw)
      : props.trainingModes[3].id;

    const datasetModeValueRaw = localStorage.getItem(
      "topicAnalysisDatasetMode",
    );
    const datasetModeValue = datasetModeValueRaw
      ? JSON.parse(datasetModeValueRaw)
      : props.datasetModes[3].id;

    const sessionSelectedClassifier = sessionStorage.getItem(
      selectedClassifierKey,
    );
    const selectedClassifier =
      (sessionSelectedClassifier
        ? props.classifiers.find(
            classifier =>
              classifier.configuration_id ===
              parseInt(sessionSelectedClassifier, 10),
          )
        : props.classifiers.find(classifier => classifier.is_in_use)) ||
      props.classifiers[0];

    this.state = {
      selectedConfigurationId: selectedClassifier.configuration_id,
      selectedClassifierId: selectedClassifier.id,
      selectedConfigurationName: selectedClassifier.configuration_name,
      selectedClassifierName: selectedClassifier.name,
      selectedTopicTrainingNodeId:
        selectedClassifier.current_topic_trainingnode_id,
      trainingModeValue,
      datasetModeValue,
      showDeleteDialogue: false,
      viewMode: localStorage.getItem("topicAnalysisViewMode") || "detail",
      showMatchRegexes:
        localStorage.getItem("topicAnalysisShowMatchRegexes") === "true" ||
        false,
      selectedRegex: "",
      classifierRefreshPending: false,
      editedUsageId: null,
      showUsageClausepartId: null,
      selectedContractTypeIds,
      maxUsagesSettings: maxUsageUtils.getMaxUsagesSettings(),
      usagesTextFilter: "",
      shownUsagesPage: 1,
      showAllUsages: false,
      isRequestPending: false,
      groupUsagesByHeader:
        localStorage.getItem("topicAnalysisGroupUsagesByHeader") === "true" ||
        false,
    };
  }

  updateMaxUsagesSettings = maxUsagesSettings =>
    this.setState(() => ({
      maxUsagesSettings,
      shownUsagesPage: 1,
    }));

  updateSelectedContractTypes = selectedContractTypeIds => {
    this.setState(() => ({selectedContractTypeIds}));
    this.refetchTopic();
  };

  shouldComponentUpdate(props, state) {
    return (
      !_.isEqual(props.topic, this.props.topic) ||
      props.templateModules !== this.props.templateModules ||
      (props.classifiers !== this.props.classifiers &&
        this.classifiersHaveUpdated(
          props.classifiers,
          this.props.classifiers,
        )) ||
      this.state.viewMode !== state.viewMode ||
      this.state.selectedResult !== state.selectedResult ||
      this.state.showMatchRegexes !== state.showMatchRegexes ||
      this.state.selectedRegex !== state.selectedRegex ||
      this.state.datasetModeValue !== state.datasetModeValue ||
      this.state.trainingModeValue !== state.trainingModeValue ||
      this.state.classifierRefreshPending !== state.classifierRefreshPending ||
      this.state.editedUsageId !== state.editedUsageId ||
      this.state.showUsageClausepartId !== state.showUsageClausepartId ||
      this.state.selectedContractTypeIds !== state.selectedContractTypeIds ||
      !_.isEqual(this.state.maxUsagesSettings, state.maxUsagesSettings) ||
      this.state.usagesTextFilter !== state.usagesTextFilter ||
      this.state.shownUsagesPage !== state.shownUsagesPage ||
      this.state.showAllUsages !== state.showAllUsages ||
      this.state.groupUsagesByHeader !== state.groupUsagesByHeader ||
      !_.isEqual(this.props.logs, props.logs) ||
      this.state.isRequestPending !== state.isRequestPending ||
      this.state.selectedConfigurationId !== state.selectedConfigurationId ||
      this.state.selectedClassifierId !== state.selectedClassifierId ||
      this.state.selectedConfigurationName !==
        state.selectedConfigurationName ||
      this.state.selectedClassifierName !== state.selectedClassifierName ||
      this.state.selectedTopicTrainingNodeId !==
        state.selectedTopicTrainingNodeId
    );
  }

  classifiersHaveUpdated(newClassifiers, oldClassifiers) {
    if (newClassifiers.length !== oldClassifiers.length) {
      return true;
    }

    let statsUpdated = false;
    newClassifiers.forEach(newClassifier => {
      if (statsUpdated) {
        return;
      }
      const oldClassifier = oldClassifiers.find(
        oldClassifier => oldClassifier.name === newClassifier.name,
      );
      if (
        oldClassifier &&
        !_.isEqual(newClassifier.stats, oldClassifier.stats)
      ) {
        statsUpdated = true;
      }
    });

    if (statsUpdated) {
      return true;
    }

    return Boolean(
      newClassifiers.find((newClassifier, index) => {
        const oldClassifier = oldClassifiers[index];
        return (
          newClassifier.id !== oldClassifier.id ||
          newClassifier.is_in_use !== oldClassifier.is_in_use ||
          newClassifier.last_edited !== oldClassifier.last_edited ||
          newClassifier.omit_headings !== oldClassifier.omit_headings ||
          newClassifier.training_state !== oldClassifier.training_state ||
          newClassifier.logs !== oldClassifier.logs
        );
      }),
    );
  }

  componentDidMount() {
    this.refetchTopic();
  }

  render() {
    const {topic} = this.props;
    return (
      <div className="app-page">
        <Toolbar className="app-toolbar">
          <ToolbarGroup key={0} style={{width: "100%"}}>
            <HotAndStarInfoWidget
              label="Topic"
              isHot={topic.is_hot}
              isStar={topic.is_star}
              updateIsStar={this.updateTopicIsStar}
            />
            <TextField
              type="text"
              className="name"
              defaultValue={topic.name}
              onBlur={this.updateName}
              style={styles.textField}
              name="topic-name"
            />
            <span className="master-id" style={{whiteSpace: "nowrap"}}>
              {topic.master_id}
            </span>
            <RaisedButton onClick={this.goBack}>Topic List</RaisedButton>
            <RaisedButton onClick={this.showSettings}>Details</RaisedButton>
            <RaisedButton onClick={this.showRegexModules}>Modules</RaisedButton>
          </ToolbarGroup>
          <IconMenu
            iconButtonElement={
              <IconButton style={{top: "3px"}}>
                <MoreIcon />
              </IconButton>
            }
            iconStyle={{padding: 0}}
            style={{width: "2rem"}}
          >
            <ListItem
              primaryText="Group Usages By Header"
              leftCheckbox={
                <Checkbox
                  checked={this.state.groupUsagesByHeader}
                  onCheck={this.onGroupUsagesByHeader}
                />
              }
            />
          </IconMenu>
        </Toolbar>
        <TopicAnalysisTabs
          classifiersTab={this.renderClassifiersTab()}
          logsTab={this.renderLogsTab()}
          clearLogs={this.props.clearLogs}
        />
      </div>
    );
  }

  renderClassifiersTab = () => {
    const {topic} = this.props;
    const {shownUsagesPage, showAllUsages, groupUsagesByHeader} = this.state;
    const isInFixMode = this.props.location.search.match(/\?fix/);
    const regexClassifier = isInFixMode
      ? {value: {}}
      : getRegexClassifier(this.props);

    const baseUsages = this.getBaseUsages(regexClassifier);
    const groupedUsages = groupUsagesByHeader
      ? groupUsages(baseUsages)
      : baseUsages;
    const eligibleUsages = this.getEligibleUsages(groupedUsages);
    const filteredUsages = this.getFilteredUsages(
      eligibleUsages,
      regexClassifier,
    );
    const usages = sortUsages(
      filteredUsages,
      this.state.selectedClassifierName,
      this.state.selectedConfigurationId,
    );

    const {rtScoresByContractType, classifiersWithScores} = this.getScoresData(
      groupedUsages,
    );
    return (
      <div className="app-body app-body--white">
        <HotProjectSelector
          projects={this.props.projects.filter(project =>
            project.used_contract_type_ids.find(ctId =>
              topic.contract_types.find(ct => ct.contract_type_id === ctId),
            ),
          )}
          onCloseHandler={this.refetchTopic}
          initSelectedContractTypeIds={this.state.selectedContractTypeIds}
        />
        {this.renderFixModePanel(isInFixMode)}
        <div style={styles.summaryBlock}>
          <ScoresSummary
            {...this.props}
            realtimeScoresByContractType={rtScoresByContractType}
            classifiers={classifiersWithScores}
            usages={groupedUsages}
            groupUsagesByHeader={groupUsagesByHeader}
            selectedContractTypeIds={this.state.selectedContractTypeIds}
            refetchTopic={this.refetchTopic}
            refetchTopicStats={this.refetchTopicStats}
            isRequestPending={this.state.isRequestPending}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SelectField
                floatingLabelText="Dataset Mode"
                value={this.state.datasetModeValue}
                onChange={this.handleDatasetModeChange}
                style={{
                  width: "10rem",
                  fontSize: "0.8em",
                  marginRight: "1rem",
                }}
                menuStyle={{width: "24rem"}}
              >
                {this.props.datasetModes.map(item => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    primaryText={item.name}
                  />
                ))}
              </SelectField>
              <SelectField
                floatingLabelText="Training Mode"
                value={this.state.trainingModeValue}
                onChange={this.handleTrainingModeChange}
                style={{
                  fontSize: "0.8em",
                  marginRight: "1rem",
                  width: "8rem",
                }}
              >
                {this.props.trainingModes.map(item => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    primaryText={item.name}
                    style={{
                      color: trainingModeColors[item.id],
                      fontWeight: "bold",
                    }}
                  />
                ))}
              </SelectField>
              {this.renderViewModeButton()}
            </div>

            <Toggle
              label="Show matching regexes"
              toggled={this.state.showMatchRegexes}
              onToggle={this.onShowMatchRegexes}
              style={{width: "14rem"}}
            />
            <UsagesFetchLimitInput
              getTopicUsages={this.refetchTopic}
              isRequestPending={this.state.isRequestPending}
              topicId={topic.id}
              totalValuesCount={topic.stats && topic.stats.total_count}
            />
          </div>
        </div>
        <div style={styles.contractTypesBlock}>
          <ContractTypesSummary
            topic={topic}
            contractTypesById={this.props.contractTypesById}
            selectedContractTypeIds={this.state.selectedContractTypeIds}
            updateSelectedContractTypes={this.updateSelectedContractTypes}
            realtimeScores={rtScoresByContractType}
            classifiers={classifiersWithScores}
            organisationId={this.props.organisationId}
            isRequestPending={this.state.isRequestPending}
          />
          <NegativeTopicSelector {...this.props} />
        </div>
        <Settings
          {...this.props}
          selectedRegex={this.state.selectedRegex}
          selectRegex={this.selectRegex}
          unselectRegex={this.unselectRegex}
          processFpsRegex={this.processFpsRegex}
          usages={eligibleUsages}
          isInFixMode={isInFixMode}
          rerunClassifier={this.rerunClassifier}
          classifierRefreshPending={this.state.classifierRefreshPending}
          getRegexClassifierStatsGradually={
            this.getRegexClassifierStatsGradually
          }
          refreshTopicRegexClassifierStats={
            this.refreshTopicRegexClassifierStats
          }
          selectedConfigurationId={this.state.selectedConfigurationId}
          selectedClassifierId={this.state.selectedClassifierId}
          selectedConfigurationName={this.state.selectedConfigurationName}
          selectedClassifierName={this.state.selectedClassifierName}
          selectedTopicTrainingNodeId={this.state.selectedTopicTrainingNodeId}
          setSelectedConfigurationId={this.setSelectedConfigurationId}
          setSelectedClassifier={this.setSelectedClassifier}
          setSelectedConfigurationName={this.setSelectedConfigurationName}
          getSelectedClassifier={() => this.getSelectedClassifier()}
        />

        {this.state.selectedResult && (
          <SelectedResult
            result={this.state.selectedResult}
            trainingModes={this.props.trainingModes}
            datasetModes={this.props.datasetModes}
          />
        )}

        {!isInFixMode && (
          <TopicUsageList
            {...this.props}
            selectedClassifier={this.state.selectedClassifier}
            regexClassifier={regexClassifier}
            rerunClassifier={this.rerunClassifier}
            groupedUsages={groupedUsages}
            filteredUsages={filteredUsages}
            usages={usages}
            showSelectedResult={this.showSelectedResult}
            viewMode={this.state.viewMode}
            classifierRefreshPending={this.state.classifierRefreshPending}
            setEditedUsageId={this.setEditedUsageId}
            showUsageClausepartId={this.state.showUsageClausepartId}
            triggerShowUsage={this.triggerShowUsage}
            maxUsagesSettings={this.state.maxUsagesSettings}
            currentPage={shownUsagesPage}
            changeShownUsagesPage={this.changeShownUsagesPage}
            onShowAllUsages={this.onShowAllUsages}
            onAbortShowAllUsages={this.onAbortShowAllUsages}
            areAllUsagesShown={showAllUsages}
            addTopicToClauses={this.addTopicToClauses}
            removeTopicFromClauses={this.removeTopicFromClauses}
            confirmTopicInClauses={this.confirmTopicInClauses}
            updateMaxUsagesSettings={this.updateMaxUsagesSettings}
            onUsagesTextFilterChange={this.onUsagesTextFilterChange}
            showMatchRegexes={this.state.showMatchRegexes}
            isRequestPending={this.state.isRequestPending}
            getClauseLogs={this.props.getClauseLogs}
          />
        )}
        <TopicEditorDialog
          topic={topic}
          editedUsageId={this.state.editedUsageId}
          onTopicEditorDialogDismiss={this.onTopicEditorDialogDismiss}
          organisationId={this.props.organisationId}
          topics={this.props.topics}
          topicsById={this.props.topicsById}
          topicCategories={this.props.topicCategories}
          topicCategoriesById={this.props.topicCategoriesById}
          topicTags={this.props.topicTags}
          contractTypesById={this.props.contractTypesById}
          onExistingTopicAdded={this.props.onExistingTopicAdded}
          onNewTopicAdded={this.props.onNewTopicAdded}
          onTopicRemoved={this.props.onTopicRemoved}
          onUnconfirmedTopicsRemoved={this.props.onUnconfirmedTopicsRemoved}
          onTopicConfirmed={this.props.onTopicConfirmed}
          onTopicsReordered={this.props.onTopicsReordered}
          onTopicparameterValuesUpdate={this.props.onTopicparameterValuesUpdate}
          clausepartsTopicsUpdated={this.props.clausepartsTopicsUpdated}
        />
      </div>
    );
  };

  setSelectedClassifier = selectedClassifier => {
    this.setState({
      selectedClassifierId: selectedClassifier.id,
      selectedClassifierName: selectedClassifier.name,
      selectedConfigurationId: selectedClassifier.configuration_id,
      selectedConfigurationName: selectedClassifier.configuration_name,
      selectedTopicTrainingNodeId:
        selectedClassifier.current_topic_trainingnode_id,
    });
    sessionStorage.setItem(
      selectedClassifierKey,
      selectedClassifier.configuration_id,
    );
    this.refetchTopic();
  };
  setSelectedConfiguration = (
    selectedConfigurationId,
    selectedConfigurationName,
  ) => {
    this.setState({
      selectedConfigurationId,
      selectedConfigurationName,
    });
  };
  setSelectedConfigurationName = value => {
    this.setState({selectedConfigurationName: value});
  };

  renderLogsTab = () => (
    <TopicLogs
      topic={this.props.topic}
      organisationId={this.props.organisationId}
      logs={this.props.logs}
      fetchLogs={this.props.fetchTopicAnalysisLogs}
      onTopicAdd={this.props.onExistingTopicAdded}
      onTopicRemove={this.props.onTopicRemoved}
    />
  );

  renderFixModePanel = isInFixMode => {
    if (!isInFixMode) {
      return null;
    }
    const {pathname} = this.props.location;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={styles.fixModeNotification}>FIX MODE</div>
        <a href={pathname} style={styles.linkToNormalMode}>
          Go back to normal mode?
        </a>
      </div>
    );
  };

  onTopicEditorDialogDismiss = () =>
    this.setState(() => ({editedUsageId: null}));
  setEditedUsageId = editedUsageId => this.setState(() => ({editedUsageId}));

  renderViewModeButton = () => {
    let label;
    let action;
    if (this.state.viewMode === "detail") {
      label = "Focus mode";
      action = () => this.setViewMode("focus");
    } else if (this.state.viewMode === "focus") {
      label = "Detail mode";
      action = () => this.setViewMode("detail");
    }
    return <RaisedButton onClick={action} primary={true} label={label} />;
  };

  removeDefinitionIndicator(text, regexClassifier) {
    if (
      regexClassifier.omit_definition_indicator &&
      !regexClassifier.omit_definitions
    ) {
      return text.replace("@@", "");
    }
    if (
      !regexClassifier.omit_definition_indicator &&
      regexClassifier.omit_definitions
    ) {
      const index = text.indexOf("||");
      if (index > 0) {
        const indicatorRegex = /\|\|[^|]+?>>@@/g;
        const indicatedDefinitions = (text.match(indicatorRegex) || []).join(
          "",
        );
        return text.substring(0, index) + indicatedDefinitions;
      }
    }
    return text;
  }

  getBaseUsages = regexClassifier => {
    const {topic} = this.props;
    // 1) prepare classifier data
    const regexClassifierHash = this.getHash(regexClassifier);
    // 2) get non FP usages
    const filteredUsages = topic.fp.length
      ? topic.usage.filter(use => use.has_topic || use.is_confirmed)
      : topic.usage;

    const usages = filteredUsages.map(use => ({
      ...use,
      originalDefinitionAndHeadingsText: use.definitionAndHeadingsText,
      definitionAndHeadingsText: this.removeDefinitionIndicator(
        use.definitionAndHeadingsText || "",
        regexClassifier,
      ),
      hasRegexMatch: this.regexMatches(
        use,
        regexClassifier,
        regexClassifierHash,
      ),
    }));
    // 3) get FP usages
    const fps = topic.fp.map(use => ({
      ...use,
      is_false_negative: true,
      has_neg_topic:
        (use.other_topics || []).find(
          ({topic_id}) => topic_id === topic.main_negative_topic_id,
        ) || false,
      has_topic: use.has_topic || false,
      is_confirmed: use.is_confirmed || false,
      originalDefinitionAndHeadingsText: use.definitionAndHeadingsText || "",
      definitionAndHeadingsText: this.removeDefinitionIndicator(
        use.definitionAndHeadingsText || "",
        regexClassifier,
      ),
      hasRegexMatch: this.regexMatches(
        use,
        regexClassifier,
        regexClassifierHash,
      ),
    }));
    // 4) base usages are FP + nonFP non filtered usages
    return [].concat(usages, fps);
  };

  getEligibleUsages = usages => {
    const {selectedContractTypeIds} = this.state;
    // (1) usage is eligible (see below)
    // (2) usage is of selected contract type

    return usages.reduce((accum, usage) => {
      // (1)
      const isUsageEligible =
        usage.has_topic ||
        usage.hasRegexMatch ||
        (usage.classifier_scores &&
          usage.classifier_scores.find(score => score.is_classified));

      // (2)
      const isUsageOfSelectedContractType = (
        selectedContractTypeIds || []
      ).includes(usage.contract_type_id);
      if (!(isUsageEligible && isUsageOfSelectedContractType)) {
        return accum;
      }
      accum.push(usage);
      return accum;
    }, []);
  };

  getFilteredUsages = (usages, regexClassifier) => {
    // filters:
    // (1) usage is eligible (see below)
    // (2) usage is of selected contract type
    // (3) filter by selected regex (eye icon in regex settings list)
    // (4) filter by confirmed/not confirmed (max usages control element)
    // (5) filter by usage status (bottom buttons in max usages control element)
    const {maxUsagesSettings, selectedRegex} = this.state;

    const selectedRegexWithTemplates = selectedRegex
      ? getRegexWithModules(selectedRegex, this.props.templateModules)
      : "";

    const selectedClassifier = this.getSelectedClassifier();

    return usages.reduce((accum, usage) => {
      const status = getUsageStatus(usage, selectedClassifier);
      const regexStatus = getUsageStatus(usage);
      const props = {
        ...this.props,
        regexClassifier,
      };
      const clausepartData = getClausepartData(props, usage, selectedRegex);

      // (3) if we select a regex (eye icon in the regex list) we check
      // if the usage has this regex, if no we filter out the usage
      if (
        selectedRegex &&
        !clausepartData.regexList.find(
          regexEl =>
            regexEl.regexText === selectedRegexWithTemplates ||
            regexEl.regexText === selectedRegex,
        ) &&
        status !== statusData.fn
      ) {
        return accum;
      }

      // (4) & (5)
      const usageStatus = status[2];
      const useWithStatusAndClausepartData = {
        ...usage,
        status,
        regexStatus,
        ...clausepartData,
      };
      const {show} = maxUsagesSettings;
      const isUsageConfirmed = usage.is_confirmed;
      if (
        (show === "confirmed" &&
          isUsageConfirmed &&
          maxUsagesSettings[usageStatus]) ||
        (show === "non_confirmed" &&
          !isUsageConfirmed &&
          maxUsagesSettings[usageStatus]) ||
        (show === "all" && maxUsagesSettings[usageStatus])
      ) {
        accum.push(useWithStatusAndClausepartData);
      }
      return accum;
    }, []);
  };

  getSelectedClassifier() {
    const {classifiers} = this.props;
    const {
      selectedClassifierId,
      selectedConfigurationId,
      selectedConfigurationName,
    } = this.state;
    const classifier = classifiers.find(classifier => {
      if (selectedClassifierId === 1) {
        return (
          classifier.id === selectedClassifierId &&
          classifier.configuration_id === selectedConfigurationId
        );
      }
      return (
        classifier.id === selectedClassifierId &&
        classifier.configuration_name === selectedConfigurationName
      );
    });
    if (!classifier) {
      return classifiers.find(
        classifier => classifier.id === selectedClassifierId,
      );
    }
    return classifier;
  }

  getScoresData = usages => {
    const topicContractTypeIds = this.props.topic.contract_types.map(
      ct => ct.contract_type_id,
    );
    const rtScoresByContractType = getRealTimeScoresByContractType(
      usages,
      topicContractTypeIds,
    );
    const classifiersWithScores = addScoresToClassifiers(
      usages,
      this.props.classifiers,
      topicContractTypeIds,
    );
    return {
      rtScoresByContractType,
      classifiersWithScores,
    };
  };

  getHash(classifier) {
    const settings = `${classifier.omit_headings}_${
      classifier.omit_definitions
    }_`;
    return (
      settings +
      (classifier?.value?.whitelist && classifier.value.whitelist.join(",")) +
      (classifier?.value?.blacklist && classifier.value.blacklist.join(","))
    );
  }

  regexMatches(usage, regexClassifier, classifierHash) {
    const {definitionAndHeadingsText, clausepart_id: clausepartId} = usage;

    if (this.lastResult && this.lastResult.classifierHash === classifierHash) {
      const result = this.lastResult.results[clausepartId];
      if (result !== undefined) {
        return result;
      }
    } else {
      this.lastResult = {
        results: {},
        classifierHash,
      };
    }

    if (
      !regexClassifier ||
      !regexClassifier.value ||
      !regexClassifier.value.whitelist
    ) {
      return false;
    }

    const text = getText(definitionAndHeadingsText, regexClassifier);

    const matchCount = regexClassifier.value.whitelistRe.filter(regex => {
      try {
        if (text.replace(/\n/g, " ").match(regex)) {
          return true;
        }
      } catch {
        // ignore bad regexes
      }
      return false;
    }).length;
    let result;
    if (matchCount) {
      const blacklist = regexClassifier.value.blacklist;
      if (blacklist) {
        const hasBlackMatch = regexClassifier.value.blacklistRe.find(regex => {
          try {
            if (text.replace(/\n/g, " ").match(regex)) {
              return true;
            }
          } catch {
            // ignore bad regexes
          }
          return false;
        });
        result = !hasBlackMatch ? matchCount > 0 : false;
      } else {
        result = matchCount > 0;
      }
    } else {
      result = matchCount > 0;
    }
    this.lastResult.results[clausepartId] = Boolean(result);
    return result;
  }

  goBack = () => {
    const path = this.props.router.location.pathname;
    this.props.router.push(
      path.replace(/\/topic\/.*$/, "/topic/analysis_matrix"),
    );
  };
  showSettings = () => {
    const path = this.props.router.location.pathname;
    this.props.router.push(path.replace(/\/analysis$/, "/detail"));
  };

  showRegexModules = () => {
    this.props.router.push(
      `/organisation/${this.props.organisationId}/modules`,
    );
  };

  handleTrainingModeChange = (event, index, trainingModeValue) => {
    this.setState({trainingModeValue});
    localStorage.setItem("topicAnalysisTrainingMode", trainingModeValue);
  };

  handleDatasetModeChange = (event, index, datasetModeValue) => {
    this.setState({datasetModeValue});
    localStorage.setItem("topicAnalysisDatasetMode", datasetModeValue);
  };

  rerunClassifier = (
    configurationId,
    configurationName,
    topicTrainingNodeId,
    processSingleUsage,
    useLiveClassifier,
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
  ) => {
    const {
      trainingModeValue: baseTrainingModeValue,
      datasetModeValue,
    } = this.state;
    let trainingModeValue = baseTrainingModeValue;
    if (trainingModeValue === -1 && !useLiveClassifier) {
      // skip-one = 1, half-half = 2
      trainingModeValue = processSingleUsage ? 1 : 2;
    } else if (useLiveClassifier && !processSingleUsage) {
      trainingModeValue = 3;
    }
    this.setState(
      () => ({classifierRefreshPending: true}),
      async () => {
        await this.props.rerunClassifier(
          this.state.selectedRegex,
          trainingModeValue,
          datasetModeValue,
          configurationId,
          configurationName,
          topicTrainingNodeId,
          this.state.selectedContractTypeIds,
          projectId,
          documentId,
          sectionId,
          clauseId,
          clausepartId,
        );
        this.setState(() => ({classifierRefreshPending: false}));
      },
    );
  };
  showSelectedResult = selectedResult => {
    this.setState({selectedResult});
  };

  setViewMode = viewMode => {
    localStorage.setItem("topicAnalysisViewMode", viewMode);
    this.setState(() => ({viewMode}));
  };

  onShowMatchRegexes = (e, showMatchRegexes) => {
    localStorage.setItem("topicAnalysisShowMatchRegexes", showMatchRegexes);
    this.setState(() => ({showMatchRegexes}));
  };

  processFpsRegex = selectedRegex => {
    this.setState({selectedRegex});

    const {
      trainingModeValue: baseTrainingModeValue,
      datasetModeValue,
    } = this.state;
    let trainingModeValue = baseTrainingModeValue;
    if (trainingModeValue === -1) {
      trainingModeValue = 2;
    }
    const classifier = getRegexClassifier(this.props);
    this.setState(
      () => ({classifierRefreshPending: true}),
      async () => {
        await this.props.rerunClassifier(
          this.state.selectedRegex,
          trainingModeValue,
          datasetModeValue,
          classifier.configuration_id,
          classifier.name,
          classifier.current_topic_trainingnode_id,
          this.state.selectedContractTypeIds,
        );
        this.setState(() => ({classifierRefreshPending: false}));
      },
    );
  };

  selectRegex = selectedRegex =>
    this.setState(
      () => ({
        selectedRegex,
        showUsageClausepartId: null,
        shownUsagesPage: 1,
      }),
      () => this.refetchTopic(),
    );

  unselectRegex = () =>
    this.setState(() => ({selectedRegex: ""}), () => this.refetchTopic());

  triggerShowUsage = showUsageClausepartId =>
    this.setState(
      prevState =>
        prevState.showUsageClausepartId
          ? {showUsageClausepartId: null}
          : {showUsageClausepartId},
    );

  onUsagesTextFilterChange = newValue =>
    this.setState(
      () => ({usagesTextFilter: newValue}),
      () => this.refetchTopic(),
    );

  updateName = e => {
    const newName = e.target.value;
    if (!newName) {
      return;
    }
    this.props.onTopicUpdated({name: newName});
  };

  updateTopicIsStar = () => {
    this.props.onTopicUpdated({is_star: !this.props.topic.is_star});
  };

  changeShownUsagesPage = changeValue => {
    const newValue = this.state.shownUsagesPage + changeValue;
    if (newValue < 1) {
      return;
    }
    this.setState(() => ({shownUsagesPage: newValue}));
  };

  onShowAllUsages = () => {
    this.setState(prevState => ({
      showAllUsages: true,
      maxUsagesSettings: {
        ...prevState.maxUsagesSettings,
        fp: true,
        fn: true,
        tp: false,
        tn: false,
      },
    }));
  };

  onAbortShowAllUsages = () => {
    this.setState(prevState => ({
      showAllUsages: false,
      maxUsagesSettings: {
        ...prevState.maxUsagesSettings,
        fp: true,
        fn: true,
        tp: true,
        tn: true,
      },
    }));
  };

  addTopicToClauses = async (clauseIds, topicId) => {
    await this.setState(() => ({classifierRefreshPending: true}));
    await this.props.addTopicToClauses(clauseIds, topicId);
    await this.setState(() => ({classifierRefreshPending: false}));
  };

  removeTopicFromClauses = async clauseIds => {
    await this.setState(() => ({classifierRefreshPending: true}));
    await this.props.removeTopicFromClauses(clauseIds);
    await this.setState(() => ({classifierRefreshPending: false}));
  };

  confirmTopicInClauses = async clauseIds => {
    await this.setState(() => ({classifierRefreshPending: true}));
    await this.props.confirmTopicInClauses(clauseIds);
    await this.setState(() => ({classifierRefreshPending: false}));
  };

  onGroupUsagesByHeader = (e, groupUsagesByHeader) => {
    localStorage.setItem(
      "topicAnalysisGroupUsagesByHeader",
      groupUsagesByHeader,
    );
    this.setState(() => ({groupUsagesByHeader}));
  };

  refetchTopic = async () => {
    const topicId = this.props.topic.id;
    this.loadCounter = (this.loadCounter ?? 0) + 1;
    const loadCounter = this.loadCounter;
    this.loadTimes = {
      ...this.loadTimes,
      [loadCounter]: new Date().valueOf(),
    };
    this.setState(
      () => ({isRequestPending: true}),
      async () => {
        await this.props.setEmptyTopicUses();
        const params = {
          usages_fetch_limit: getTopicUsagesFetchLimit(topicId),
          text_search: this.state.usagesTextFilter,
          omit_param_stats: true,
          ...getHotProjectsParams(),
          contract_types: JSON.stringify(this.state.selectedContractTypeIds),
          selected_configuration_id: this.state.selectedConfigurationId,
        };
        if (this.state.selectedRegex) {
          const selectedRegexWithTemplates = getRegexWithModules(
            this.state.selectedRegex,
            this.props.templateModules,
          );
          params.regex_search = encodeURIComponent(selectedRegexWithTemplates);
        }

        await this.props.topicFetch(this.props.organisationId, topicId, params);
        this.setState(
          () => ({isRequestPending: false}),
          () => {
            const startTime = this.loadTimes[loadCounter];
            const endTime = new Date().valueOf();
            this.props.logPageLoadTime(
              this.props.organisationId,
              "topic_analysis",
              startTime,
              endTime,
              {
                topic_id: topicId,
                usages_fetch_limit: getTopicUsagesFetchLimit(topicId),
                ...params,
              },
            );
          },
        );
        refetchExistingRegexClassifierStatsGradually(
          this.props.classifiders,
          this.props.getTopicRegexClassifierStats,
          this.state.selectedContractTypeIds,
        );
      },
    );
  };
  refetchTopicStats = async () => {
    const topicId = this.props.topic.id;
    this.setState(
      () => ({isStatsRequestPending: true}),
      async () => {
        const params = {
          ...getHotProjectsParams(),
          contract_types: JSON.stringify(this.state.selectedContractTypeIds),
        };
        await this.props.topicStatsFetch(
          this.props.organisationId,
          topicId,
          params,
        );
        this.setState(() => ({isStatsRequestPending: false}));
      },
    );
  };

  getRegexClassifierStatsGradually = async () => {
    await this.props.clearTopicRegexClassifierStats();
    const whitelistRegexes = get(this.props, "classifiers[0].value.whitelist");
    if (whitelistRegexes) {
      for (const regex of whitelistRegexes) {
        await this.props.getTopicRegexClassifierStats(
          this.state.selectedContractTypeIds,
          regex,
          "whitelist",
        );
      }
    }

    const blacklistRegexes = get(this.props, "classifiers[0].value.blacklist");
    if (blacklistRegexes) {
      for (const regex of blacklistRegexes) {
        await this.props.getTopicRegexClassifierStats(
          this.state.selectedContractTypeIds,
          regex,
          "blacklist",
        );
      }
    }
  };

  refreshTopicRegexClassifierStats = (regex, regexType) => {
    this.props.clearTopicRegexClassifierStats(regex, regexType);
    this.props.getTopicRegexClassifierStats(
      this.state.selectedContractTypeIds,
      regex,
      regexType,
    );
  };
}

function groupUsages(usages) {
  // With deepely nested usages sometimes header usage might be a child
  // usage of other header usage - thus we should consider this effect.
  // This also means that we can have same usage twice: as a header and
  // as a child;

  // 1) get header usages
  const headerUsages = [];
  usages.forEach(usage => {
    if (usage.clausepart_is_terminal === false) {
      headerUsages.push({
        ...usage,
        childrenUsages: [],
      });
      usage.isUsageAHeader = true;
    }
  });
  if (headerUsages.length === 0) {
    return usages;
  }

  // 2) fill in header usages and get nonChildUsages
  const nonChildUsages = [];
  usages.forEach(usage => {
    let isUsageAChild = false;
    headerUsages.forEach(headerUsage => {
      const headerPathStripped = headerUsage.clausepart_path.endsWith("[0]")
        ? headerUsage.clausepart_path &&
          headerUsage.clausepart_path.slice(0, -3)
        : headerUsage.clausepart_path;
      if (
        usage.document_id === headerUsage.document_id &&
        usage.clause_id === headerUsage.clause_id &&
        usage.clausepart_path &&
        headerPathStripped &&
        usage.clausepart_path !== headerUsage.clausepart_path &&
        usage.clausepart_path.startsWith(headerPathStripped)
      ) {
        headerUsage.childrenUsages.push(usage);
        isUsageAChild = true;
      }
    });
    if (!isUsageAChild && !usage.isUsageAHeader) {
      nonChildUsages.push(usage);
    }
  });
  return headerUsages.concat(nonChildUsages);
}

function sortUsages(usages, selectedClassifierName, selectedConfigurationId) {
  return _.sortBy(usages, usage => {
    const {
      classifier_scores: classifierScores,
      is_confirmed: isConfirmed,
      has_topic: hasTopic,
      hasRegexMatch,
    } = usage;

    const classificationResult = (classifierScores || []).find(
      item => item.configuration_id === selectedConfigurationId,
    );
    const isClassified =
      selectedClassifierName === "regex"
        ? hasRegexMatch
        : classificationResult?.is_classified ?? false;
    const isFn = !isClassified && hasTopic && isConfirmed;
    const isFnNc = !isClassified && hasTopic && !isConfirmed;
    const isFp = isClassified && !hasTopic;
    const isTn = !isClassified && !hasTopic;
    const isTp = isClassified && hasTopic && isConfirmed;
    const isTpNc = isClassified && hasTopic && !isConfirmed;
    return [
      isFn ? 0 : 1,
      isFnNc ? 0 : 1,
      isFp ? 0 : 1,
      isTpNc ? 0 : 1,
      isTp ? 0 : 1,
      isTn ? 0 : 1,
      usage.exact_match_id || usage.original_exact_match_id || "",
      usage.project_name,
      usage.document_name,
      usage.clausepart_reference,
      usage.clausepart_text,
      usage.project_id,
      usage.document_id,
    ].join("_");
  });
}

TopicAnalysis.propTypes = {
  organisationId: PropTypes.number.isRequired,
  topic: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    master_id: PropTypes.string.isRequired,
    topiccategory_id: PropTypes.number.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    usage: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        clause_id: PropTypes.number.isRequired,
        clausepart_id: PropTypes.number.isRequired,
        clause_reference: PropTypes.string.isRequired,
        clausepart_text: PropTypes.string.isRequired,
        project_id: PropTypes.number.isRequired,
        project_name: PropTypes.string.isRequired,
        document_id: PropTypes.number.isRequired,
        document_name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicsById: keyedObjectPropType(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategoriesById: keyedObjectPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  topicTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default TopicAnalysis;
