import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import UNINITIALISED from "utils/uninitialised";
import {
  Workflow,
  WorkflowId,
  WorkflowInput,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";
import {Issue} from "common/types/issue";
import WorkflowSelector from "./workflow_selector";
import workflowFetchAction from "modules/flowmaster/actions/workflow_fetch";
import requestor from "requestor";
import WorkflowInputs from "./workflow_inputs";
import {isEmpty} from "underscore";

function getDefaultValueForInput(input) {
  return input.has_default ? input.defaultValue : "";
}

function getInitialValueForWorkflow(inputs) {
  const initialValueObject = inputs.reduce((acc, input) => {
    acc[input.id] = getDefaultValueForInput(input);
    return acc;
  }, {});
  return initialValueObject;
}

interface WorkflowsState {
  workflows: Workflow[] | typeof UNINITIALISED;
  workflow: Workflow | typeof UNINITIALISED;
}

interface WorkflowEditorProps {
  organisationId: OrganisationId;
  workflows: Workflow[];
  getWorkflow: (
    organisationId: OrganisationId,
    workflowId: WorkflowId,
  ) => {value: {inputs: WorkflowInput[]}};
  selectedWorkflowId: WorkflowId;
  llm_settings: Issue["llm_settings"];
  updateLlmSettings: (settings: Partial<Issue["llm_settings"]>) => void;
  workflow: Workflow;
}

const WorkflowEditor = ({
  organisationId,
  workflows,
  getWorkflow,
  llm_settings,
  updateLlmSettings,
  workflow,
}: WorkflowEditorProps) => {
  const [selectedWorkflowId, setSelectedWorkflowId] = useState(
    llm_settings.workflow_settings?.selected_workflow ?? -1,
  );

  useEffect(() => {
    const selectedWorkflow = llm_settings.workflow_settings?.selected_workflow;
    if (selectedWorkflow && selectedWorkflow > 0) {
      setSelectedWorkflowId(selectedWorkflow);
    }
  }, [llm_settings]);

  useEffect(() => {
    async function fetchAndUpdateWorkflow() {
      let updatedSettingsForSelectedWorkflow: Partial<
        Issue["llm_settings"]
      > = {};

      // always update selected_workflow when selectedWorkflowId changes
      if (
        selectedWorkflowId > -1 &&
        selectedWorkflowId !==
          llm_settings?.workflow_settings?.selected_workflow
      ) {
        updatedSettingsForSelectedWorkflow = {
          ...llm_settings,
          workflow_settings: {
            ...llm_settings.workflow_settings,
            selected_workflow: selectedWorkflowId,
          },
        };
      }

      if (selectedWorkflowId !== -1) {
        try {
          const response = await getWorkflow(
            organisationId,
            selectedWorkflowId,
          );
          const value = response.value;

          // check if we need to fetch workflow config data
          if (!llm_settings?.workflow_settings?.config?.[selectedWorkflowId]) {
            const workflowData = value.inputs
              ? getInitialValueForWorkflow(value.inputs.values)
              : [];

            // update workflow config data
            const updatedSettingsForConfig = {
              ...updatedSettingsForSelectedWorkflow,
              workflow_settings: {
                ...updatedSettingsForSelectedWorkflow.workflow_settings,
                config: {
                  ...llm_settings.workflow_settings?.config,
                  [selectedWorkflowId]: {
                    inputs: {
                      values: workflowData,
                    },
                  },
                },
              },
            };
            updateLlmSettings(updatedSettingsForConfig);
          } else if (!isEmpty(updatedSettingsForSelectedWorkflow)) {
            updateLlmSettings(updatedSettingsForSelectedWorkflow);
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    }

    fetchAndUpdateWorkflow();
  }, [selectedWorkflowId]);

  const updateWorkflowSettings = updatedInputs => {
    const currentWorkflowSettings =
      llm_settings.workflow_settings?.config?.[selectedWorkflowId];

    const updatedWorkflowSettings = {
      ...currentWorkflowSettings,
      inputs: {
        ...currentWorkflowSettings?.inputs,
        values: updatedInputs,
      },
    };

    const updatedLLMSettings = {
      ...llm_settings,
      workflow_settings: {
        ...llm_settings.workflow_settings,
        config: {
          ...llm_settings?.workflow_settings?.config,
          [selectedWorkflowId]: updatedWorkflowSettings,
        },
      },
    };

    updateLlmSettings(updatedLLMSettings);
  };

  const workflowInputs =
    llm_settings.workflow_settings?.config?.[selectedWorkflowId]?.inputs
      .values ?? {};

  return (
    <>
      <div
        style={{
          padding: "0em 2em 1em 2em",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <WorkflowSelector
          organisationId={organisationId}
          contextTypeId={1}
          selectedWorkflowId={selectedWorkflowId}
          updateselectedWorkflowId={setSelectedWorkflowId}
          workflows={workflows}
          showStarredWorkflows={true}
        />
      </div>
      <div
        style={{
          padding: "0em 2em 1em 2em",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <WorkflowInputs
          organisationId={organisationId}
          workflows={workflows}
          selectedWorkflow={workflow}
          inputs={workflowInputs}
          updateWorkflowSettings={updateWorkflowSettings}
          selectedWorkflowId={selectedWorkflowId}
          workflowSettings={llm_settings.workflow_settings}
        />
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getWorkflow: workflowFetchAction(requestor),
      },
      dispatch,
    ),
  };
}

const mapStateToProps = (state: WorkflowsState) => ({
  workflows: state.workflows === UNINITIALISED ? [] : state.workflows,
  workflow: state.workflow === UNINITIALISED ? null : state.workflow,
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowEditor);
