import React from "react";
import _ from "lodash";

import Paper from "material-ui/Paper";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";

import usePrevious from "utils/hooks/use_previous";

const styles = {
  iconButton: {
    cursor: "pointer",
    color: "#757575",
    height: 16,
    width: 16,
    marginRight: 4,
  },
};

function InternalNotes(props) {
  const [internalNotesState, updateInternalNotesState] = React.useState(
    props.internalNotes || {},
  );

  const prevInternalNotes = usePrevious(props.internalNotes || {});
  React.useEffect(
    () => {
      if (!_.isEqual(prevInternalNotes, props.internalNotes)) {
        updateInternalNotesState(props.internalNotes || []);
      }
    },
    [props.internalNotes],
  );

  function onNoteChange(e) {
    const newValue = {
      ...internalNotesState,
      note: e.target.value,
    };
    updateInternalNotesState(newValue);
  }

  function onNoteSave() {
    props.updateInternalNotes(internalNotesState);
  }

  return (
    <Paper style={{margin: "2rem 2rem 1rem 2rem"}}>
      <div
        className="app-toolbar"
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          fontWeight: "500",
        }}
      >
        Internal Notes
        <CloseIcon
          onClick={
            props.onClear && !props.isClearDisabled ? props.onClear : () => null
          }
          style={_.omit(styles.iconButton, ["height", "width", "marginLeft"])}
        />
      </div>
      <div
        style={{
          margin: `2rem 2rem ${props.overriddenValues ? "0" : "2"}rem 2rem`,
          paddingBottom: "2rem",
        }}
      >
        <TextField
          label="Note"
          multiline
          rowsMax={12}
          value={internalNotesState.note || ""}
          onChange={onNoteChange}
          onBlur={onNoteSave}
          style={{width: "100%"}}
        />
      </div>
      {props.overriddenValues}
    </Paper>
  );
}

export default InternalNotes;
