import React from "react";
import requestor from "requestor";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import Permissioner from "utils/permissioner";
import setTitle from "utils/set_title";

import RoleDetailComponent from "../components/role_detail";

import updateRoleAction from "modules/documents/actions/role/role_update";
import removeRoleAction from "modules/documents/actions/role/role_remove";

function RoleDetailContainer(props) {
  const shouldRenderContainer = isInitialised([
    props.roles,
    props.role,
    props.user,
    props.contractTypes,
  ]);

  if (!shouldRenderContainer || !props.user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const permissioner = new Permissioner(props.user);

  if (!permissioner.hasPermission("update-role") || !permissioner.isAdmin()) {
    return permissioner.getNoPermissionMessage();
  }

  const {role, roles} = props;
  const {organisationId} = props.params;

  function onRoleUpdate(data) {
    props.updateRole(
      organisationId,
      role.id,
      {
        ...data,
        last_edited: role.last_edited,
      },
      role.last_edited,
    );
  }

  function redirectToRoleList() {
    props.dispatch(push(`/organisation/${organisationId}/role/list`));
  }

  function onRoleRemove() {
    props.removeRole(organisationId, role.id, role.last_edited);
    redirectToRoleList();
  }

  setTitle("Role Detail");
  return (
    <RoleDetailComponent
      role={role}
      roles={roles}
      onRoleUpdate={onRoleUpdate}
      onRoleRemove={onRoleRemove}
      redirectToRoleList={redirectToRoleList}
      contractTypes={props.contractTypes}
    />
  );
}

function select(state, props) {
  if (!state.roles) {
    return {};
  }
  const roleId = parseInt(props.params.roleId, 10);
  return {
    role:
      state.roles !== UNINITIALISED &&
      state.roles.find(role => role.id === roleId),
    params: props.params,
    router: state.router,
    roles: state.roles,
    user: state.user,
    contractTypes: state.contract_types,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateRole: updateRoleAction(requestor),
        removeRole: removeRoleAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(RoleDetailContainer);
