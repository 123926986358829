import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import {DragSource as dragSource, DropTarget as dropTarget} from "react-dnd";

class TopicListItemComponent extends React.Component {
  render() {
    const {topic, connectDragSource, connectDropTarget} = this.props;
    return connectDragSource(
      connectDropTarget(
        <div
          className="topic"
          style={{
            margin: "1em",
            padding: "1em",
            border: "1px solid #aaa",
            borderRadius: "1em",
            textAlign: "center",
            cursor: "-webkit-grab",
          }}
        >
          {topic.name}
        </div>,
      ),
    );
  }
}

TopicListItemComponent.propTypes = {
  topic: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
};

export const topicSource = {
  beginDrag(props) {
    return {order: props.order};
  },
};

export const topicTarget = {
  drop(props, monitor) {
    const {order: sourceOrder} = monitor.getItem();
    const targetOrder = props.order;
    if (sourceOrder !== targetOrder) {
      props.onTopicsReordered(sourceOrder, targetOrder);
    }
  },
};

export default _.compose(
  dropTarget("TOPIC_LIST_ITEM", topicTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  })),
  dragSource("TOPIC_LIST_ITEM", topicSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging() || false,
  })),
)(TopicListItemComponent);

export const TopicListItem = TopicListItemComponent;
