import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";

import InfoIcon from "@material-ui/icons/Info";

const styles = {
  infoIcon: {
    height: "18px",
    width: "18px",
    color: "#9e9e9e",
  },
  popupHeader: {
    height: "2rem",
    lineHeight: "2rem",
    padding: "0 10px",
    background: "#fdd835",
    fontWeight: 600,
    fontSize: "16px",
  },
  popupBody: {
    padding: "10px",
  },
};

const useStyles = makeStyles(() => ({
  popover: {
    // this is used to prevent onMouseLeave firing immediately afte mouseEnter
    pointerEvents: "none",
  },
  paper: {
    borderRadius: 0,
  },
}));

function InformationPopup(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <InfoIcon
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{
          ...styles.infoIcon,
          ...props.iconStyle,
        }}
      />
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={props.anchorOrigin}
        transformOrigin={props.transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div
          style={{
            ...styles.popupHeader,
            ...props.popupHeaderStyle,
          }}
        >
          {props.headerContent}
        </div>
        <div
          style={{
            ...styles.popupBody,
            ...props.popupBodyStyle,
          }}
        >
          {props.children}
        </div>
      </Popover>
    </div>
  );
}

InformationPopup.defaultProps = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  iconStyle: {},
  popupHeaderStyle: {},
  popupBodyStyle: {},
  headerContent: "Information",
};

export default InformationPopup;
