import React, {useState} from "react";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";

import CircularProgress from "@material-ui/core/CircularProgress";

import constants from "./constants";
import Wrapper from "./wrapper";
import SaveButtons from "./save_buttons";

function TabPanel(props) {
  const {children, value, index} = props;
  return (
    <Paper elevation={1} square>
      {value === index && children}
    </Paper>
  );
}

function ProjectTabs(props) {
  const {tabs} = props;
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    tabs[newValue].onEnter?.();
  };

  const areButtonsDisabled =
    props.areErrorsPresent || !tabs.some(tab => tab.haveChanges);

  return (
    <Wrapper>
      <AppBar
        position="static"
        color="default"
        style={{display: "flex", flexDirection: "row", alignItems: "center"}}
      >
        {props.isRequestPending ? (
          <CircularProgress style={{marginLeft: "16px"}} size={24} />
        ) : (
          <div style={{width: "40px"}} />
        )}
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          style={{flexGrow: 1}}
        >
          {tabs.map((tabItem, tabItemIndex) => (
            <Tab
              key={`tabItem-${tabItemIndex}`}
              style={{
                background: tabItem.haveChanges
                  ? constants.changedKeyColor
                  : "inherit",
              }}
              label={tabItem.label}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tabItem, tabItemIndex) => (
        <TabPanel key={tabItemIndex} value={value} index={tabItemIndex}>
          {tabItem.tab}
          {!tabItem.hideButtons && (
            <SaveButtons
              isProjectAdded={props.isProjectAdded}
              isArchived={props.isArchived}
              triggerArchiveProject={props.triggerArchiveProject}
              onSaveProjectChanges={props.onSaveProjectChanges}
              onCancelProjectChanges={props.onCancelProjectChanges}
              areButtonsDisabled={areButtonsDisabled}
              styles={{paddingBottom: "2rem"}}
              onShowDeleteDialog={props.onShowDeleteDialog}
              isDeleteDisabled={props.isDeleteDisabled}
              documentsCount={props.documentsCount}
            />
          )}
        </TabPanel>
      ))}
    </Wrapper>
  );
}

export default ProjectTabs;
