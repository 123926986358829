import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {Workflow, WorkflowId} from "common/flowmaster/types/workflow";

export type WorkflowFetchAction = {
  type: WorkflowsActionTypes["WORKFLOW_FETCH"]["SUCCESS"];
  payload: Workflow;
};

export default function getWorkflow(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOW_FETCH,
    (organisationId: OrganisationId, workflowId: WorkflowId) => {
      return requestor
        .get(
          `${basePath}/organisation/${organisationId}/workflow/${workflowId}`,
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
