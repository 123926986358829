import React from "react";
import {get} from "lodash";

import getDocumentClausesTopics from "utils/topic/get_document_clauses_topics";
import sessionStorage from "utils/session_storage";
import getPositiveReasonData from "common/utils/issues/reason/get_positive_reason_data";
import getCurrentIssuesetItem from "utils/issuesets/get_current_issueset_item";
import getIssuesData from "utils/issues/get_issues_data";
import createIssuesetOverrideData from "utils/issues/create_issueset_override_data";
import ReportView from "./report_view/report_view";
import {initialFiltersState} from "../../../common_components/filters/constants";
import Sidebar from "../../logged_in/containers/sidebar";
import getSelectedReportStateValues from "../../document_detail/utils/get_selected_report_state_values";
import initSelectedReportState from "utils/reports/init_selected_report_state";
import {parseQuery} from "utils/uri";

const defaultState = {
  selectedHeading: "",
  selectedIssue: "",
};

export default class DocumentReport extends React.Component {
  constructor(props) {
    super(props);
    this.nextVisibilityTimeout = {};
    this.nextVisibilityState = {};
    const selectedHeading = this.props.highlightedClause || "";

    const clientMode = sessionStorage.getItem("clientModeOn");
    const clientModeOn =
      clientMode !== null && clientMode !== undefined
        ? clientMode.toString() === "true"
        : false;
    const editPlaybookModeValue = sessionStorage.getItem("editPlaybookMode");
    const editPlaybookMode =
      editPlaybookModeValue !== null && editPlaybookModeValue !== undefined
        ? editPlaybookModeValue.toString() === "true"
        : false;
    const currentReport = parseQuery(props.router.location.search).report ?? "";
    const reportId =
      props.project.report_settings.find(item => item.id === currentReport)
        ?.id ??
      props.project.report_settings?.[0]?.id ??
      null;
    this.state = {
      ...defaultState,
      selectedHeading,
      areIssuesCollapsed: true,
      responseAddedToReport: false,
      currentIssueset: props.currentIssueset,
      editableClausepart: null,
      clauseToHighlight: null,
      editModeOn: undefined,
      isReportPanelShown: true,
      ...initSelectedReportState(this.props.project),
      clientModeOn,
      editPlaybookMode,
      reportId,
    };

    this.clauseToHighlightTimerId = null;
    this.correctDocumentIssueManually = correctDocumentIssueManually.bind(this);
  }

  componentDidMount() {
    window.onkeydown = e => {
      if (e.keyCode === 27) {
        // Esc
        if (this.state.editableClausepart) {
          return this.setState(() => ({editableClausepart: null}));
        }
      }
    };
    const clausepartIdQueryParam = get(this.props, "queryParams.clausepart_id");
    if (clausepartIdQueryParam) {
      this.scrollToClause({id: parseInt(clausepartIdQueryParam, 10)});
    }
    this.onSelectedReportChange(this.state.reportId.id);
  }

  componentWillUnmount() {
    delete window.onkeydown;
  }

  render() {
    const {props} = this;
    const {groupedIssues, issues, currentIssuesetItem} = getIssuesData(
      props,
      this.props.currentIssueset,
      this.state.areIssuesCollapsed,
      false, // dontFilterIssuesByIssuesets
      null,
      initialFiltersState,
    );
    this.documentTopics = getDocumentClausesTopics(
      props.documentClauses,
      props.topicMasks,
      props.topicsById,
      props.topicMasksById,
    );
    const positiveReasonData = getPositiveReasonData(props.documentClauses);

    return (
      <>
        <Sidebar
          overrideRole={props.overrideRole}
          router={props.router}
          isCollapsedLight={true}
        />
        <ReportView
          {...this.props}
          issues={issues}
          positiveReasonData={positiveReasonData}
          revertIssueActionState={this.revertIssueActionState}
          panelData={null}
          groupedIssues={groupedIssues}
          showIssuesInChecklist={props.project.show_issues_in_checklist}
          updateIssueDetail={this.updateIssueDetail}
          scrollToClause={this.scrollToClause}
          editModeOn={this.state.editModeOn}
          currentIssuesetItem={currentIssuesetItem}
          selectedReportId={this.state.selectedReportId}
          correctDocumentIssueManually={this.correctDocumentIssueManually}
          removeIssueFromReport={this.removeIssueFromReport}
          addIssueToReport={this.addIssueToReport}
          selectedChecklistSectionId={null}
          updateIssueReviewState={this.updateIssueReviewState}
          currentIssueset={this.state.currentIssueset}
          reportSettings={props.project.report_settings}
          isReportPanelShown={this.state.isReportPanelShown}
          toggleReportPanelVisibility={this.toggleReportPanelVisibility}
          reportId={this.state.reportId.id}
          onSelectedReportChange={this.onSelectedReportChange}
          reviewTrackingOn={this.getReviewTrackingOn()}
        />
      </>
    );
  }

  getReviewTrackingOn = () => {
    return this.props.document.review_tracking_on === null
      ? this.props.project.review_tracking_on
      : this.props.document.review_tracking_on;
  };

  getLinkPath = reportId =>
    `/organisation/${this.props.organisationId}/project/${this.props.project.id}/document/${this.props.document.id}/report?id=${reportId}`;

  redirectToCurrentReport = reportId =>
    this.props.router.push(this.getLinkPath(reportId));

  toggleReportPanelVisibility = () =>
    this.setState(prevState => ({
      isReportPanelShown: prevState.isReportPanelShown,
    }));

  onSelectedReportChange = selectedReportId => {
    this.setState(() =>
      getSelectedReportStateValues(this.props.project, selectedReportId),
    );
    this.redirectToCurrentReport(selectedReportId);
  };

  updateIssueReviewState = (issue, item) => {
    const options = {merge_update_object: true};
    const currentIssuesetItem = getCurrentIssuesetItem(
      this.state.currentIssueset,
      this.props.document,
      this.props.contractTypesById,
    );

    if (currentIssuesetItem) {
      if (options && options.merge_update_object) {
        options.data_path = createIssuesetOverrideData(currentIssuesetItem);
        options.update_override_document_issue_values = true;
      }
    }
    this.updateDocumentIssue(issue, {review_state: item}, options);
  };

  updateIssueDetail = (issue, clearAddClausesState) => {
    const updates = this.constructor.getUpdatedIssueDetailState(
      this.state,
      this.props,
      issue,
      clearAddClausesState,
    );
    if (updates) {
      this.setState(updates);
    }
  };

  updateDocumentIssue = (issue, updates, _options = {}) => {
    let options = _options;
    const currentIssuesetItem = getCurrentIssuesetItem(
      this.props.currentIssueset,
      this.props.document,
      this.props.contractTypesById,
    );

    if (currentIssuesetItem) {
      createIssuesetOverrideData(currentIssuesetItem, options, issue, updates);
    }

    if (issue.underlyingIssues && issue.underlyingIssues.length > 0) {
      const issuesToUpdate = issue.underlyingIssues.map(issue => ({
        id: issue.document_issue_id,
        last_edited: issue.last_edited,
      }));
      options = {
        ...options,
        underlyingIssuesUpdate: true,
      };
      this.props.updateDocumentIssues(issuesToUpdate, updates, options);
    } else {
      this.props.updateDocumentIssue(issue, updates, options);
    }
  };

  addIssueToReport = async (issue, reportId) => {
    const result = await this.updateDocumentIssueActionState(
      issue,
      reportId,
      1,
    );
    const that = this;
    this.setState(
      () => ({responseAddedToReport: true}),
      () => {
        setTimeout(
          () => that.setState(() => ({responseAddedToReport: false})),
          2000,
        );
      },
    );
    return result;
  };
  removeIssueFromReport = (issue, reportId) =>
    this.updateDocumentIssueActionState(issue, reportId, 0);
  revertIssueActionState = (issue, reportId) =>
    this.updateDocumentIssueActionState(issue, reportId, null);
  updateDocumentIssueActionState = (issue, reportId, newValue) =>
    this.updateDocumentIssue(
      issue,
      {action_state: {[reportId]: newValue}},
      {merge_update_object: true},
    );
}

export async function correctDocumentIssueManually(
  issue,
  updates,
  options = {},
) {
  const currentIssuesetItem = getCurrentIssuesetItem(
    this.props.currentIssueset,
    this.props.document,
    this.props.contractTypesById,
  );
  const dataPath = currentIssuesetItem
    ? `${currentIssuesetItem.master_id}.${
        currentIssuesetItem.remote_client_id
          ? currentIssuesetItem.remote_client_id
          : "master"
      }`
    : "";

  if (options && options.merge_update_object) {
    options.update_override_document_issue_values = true;
    options.data_path = dataPath;
  } else if (dataPath) {
    updates.data_path = dataPath;
  }

  await this.props.correctDocumentIssueManually(issue, updates, options);
}
