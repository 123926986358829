import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../constants/action_types";

/* eslint-disable max-params */
export default createReduxPromiseAction(
  ActionTypes.CLAUSEPART_COMMENT_ADD,
  (
    organisationId,
    projectId,
    documentId,
    clauseId,
    clausepartId,
    text,
    isExternal,
  ) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/clause/${clauseId}/` +
          `clausepart/${clausepartId}/comment`,
        {
          text,
          is_external: isExternal,
        },
        {withCredentials: true},
      )
      .then(response => response.data),
);
