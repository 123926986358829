import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import {get, set} from "lodash";

import TopicEditor from "common_components/topic_editor";
import keyedObjectPropType from "utils/keyed_object_prop_type";

import ReferencedIssues from "./referenced_issues";
import TopicUsageList from "./topic_usage_list";
import TopicParameters from "./topic_parameters";
import TopicParameterEditor from "./topic_parameter_editor";
import TopicDescription from "./topic_description";
import MaxUsagesControl from "../../topic_analysis/components/max_usages_control";
import UsagesFetchLimitInput, {
  getTopicUsagesFetchLimit,
} from "common_components/usages_fetch_limit_input";
import TopicLogs from "common_components/topic_logs";

import TextField from "material-ui/TextField";
import {Toolbar, ToolbarGroup} from "material-ui/Toolbar";
import Dialog from "material-ui/Dialog";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";
import FlatButton from "material-ui/FlatButton";
import MoreIcon from "material-ui/svg-icons/navigation/more-vert";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import LinkButton from "common_components/buttons/link_button";
import BulkTopicControlPanel from "common_components/bulk_topic_control_panel";
import HotAndStarInfoWidget from "common_components/hot_and_star_info_widget";
import getUsageScoreStats from "./utils/get_usage_score_stats";
import HotProjectSelector from "common_components/project/hot_project_selector";
import areValuesEqual from "./utils/are_values_equal";
import maxUsagesUtils from "../../topic_analysis/components/utils/max_usages_utils";
import byId from "common/utils/by_id";
import getHotProjectsParams from "../../../utils/get_hot_projects_params";
import Toggle from "material-ui/Toggle";
import localStorage from "utils/local_storage";

const styles = {
  textField: {
    width: "100%",
  },
  fixModeNotification: {
    fontSize: "32px",
    fontWeight: "500",
    textAlign: "center",
    margin: "2rem",
    marginBottom: "0rem",
    color: "#E57373",
  },
  linkToNormalMode: {
    textAlign: "center",
    color: "#E57373",
  },
  appBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

const DEV_MODE_WIDTH = 150;

class TopicDetail extends React.Component {
  constructor(props) {
    super(props);
    let showingParameterEditor = null;
    const {search} = props.location;
    const queryStringTopicparameterId = parseInt(search.substr(19), 10);
    if (queryStringTopicparameterId) {
      const foundParameter = props.topic.parameters.find(
        parameter => parameter.id === queryStringTopicparameterId,
      );
      showingParameterEditor = foundParameter || null;
    }
    this.state = {
      tabIndex: queryStringTopicparameterId ? 1 : 0,
      showDeleteDialogue: false,
      help: props.topic.help,
      name: props.topic.name,
      showingParameterEditor,
      usages: getSortedUsageIdsAndScores(
        props.topic.usage,
        showingParameterEditor,
      ),
      showUsageId: null,
      maxUsagesSettings: maxUsagesUtils.getMaxUsagesSettings(),
      initUsagesSettings: maxUsagesUtils.getMaxUsagesSettings(),
      usagesTextFilter: "",
      shownUsagesPages: 1,
      showAllUsages: false,
      isTopicDataRequestPending: false,
      issuesPausedByTopicparameter: props.topic.parameters.reduce(
        (result, param) => {
          result[param.id] = param.issues_paused;
          return result;
        },
        {},
      ),
      selectedRegex: null,
      unselectedUsageIds: {},
      usesToSelect: null,
      selectedRegExp: null,
      selectedContractTypeIds: null,
      showMatchRegexes:
        localStorage.getItem("topicAnalysisShowMatchRegexes") === "true" ||
        false,
    };
  }

  componentDidMount() {
    if (this.state.tabIndex === 1) {
      if (this.state.showingParameterEditor) {
        this.fetchUsageDataIfNotFetched(this.state.showingParameterEditor.id);
      } else {
        this.fetchUsageDataIfNotFetched(null);
      }
    }

    const usagesFilterRaw = localStorage.getItem(
      `filteredContractTypeIds_${this.props.organisationId}`,
    );
    const usagesFilter = usagesFilterRaw
      ? JSON.parse(usagesFilterRaw)
      : usagesFilterRaw;
    const topicContractTypeIds = this.props.topic.contract_types.map(
      ct => ct.contract_type_id,
    );
    const finalContaractTypesIds = usagesFilter
      ? _.intersection(usagesFilter, topicContractTypeIds)
      : topicContractTypeIds;
    this.setState({selectedContractTypeIds: finalContaractTypesIds});
  }

  componentDidUpdate(prevProps, prevState) {
    const {showingParameterEditor: currentShowingParameterEditor} = this.state;
    const {
      showingParameterEditor: prevShowingParameterEditor,
      isTopicDataRequestPending,
    } = prevState;
    if (
      !(
        currentShowingParameterEditor === null &&
        prevShowingParameterEditor === null
      ) &&
      ((currentShowingParameterEditor === null &&
        prevShowingParameterEditor !== null) ||
        (currentShowingParameterEditor !== null &&
          prevShowingParameterEditor === null) ||
        currentShowingParameterEditor.id !== prevShowingParameterEditor.id) &&
      !isTopicDataRequestPending
    ) {
      this.setState(() => ({
        isTopicDataRequestPending: true,
        selectedRegex: null,
        usages: [],
        selectedRegExp: null,
      }));
      this.getTopicUsages();
    }
    if (currentShowingParameterEditor) {
      const parameterInUse = this.props.topic.parameters.find(
        parameter => parameter.id === currentShowingParameterEditor.id,
      );
      if (
        parameterInUse &&
        parameterInUse.last_edited !== currentShowingParameterEditor.last_edited
      ) {
        this.setState(() => ({
          showingParameterEditor: {
            ...currentShowingParameterEditor,
            ...parameterInUse,
          },
        }));
      }
    }

    const {usesToSelect: usesToSelectPrev} = prevState;
    const {usesToSelect} = this.state;
    if (usesToSelect !== usesToSelectPrev) {
      this.setState(() => ({unselectedUsageIds: {}}));
    }

    this.fetchUsageDataIfNotFetched(null);
  }

  onShowParameterEditor = parameterId => {
    let showingParameterEditor = {};
    if (parameterId) {
      this.props.addTopicparameterIdToQueryString(parameterId);
      showingParameterEditor = this.props.topic.parameters.find(
        parameter => parameter.id === parameterId,
      );
    } else if (this.props.location.search) {
      this.props.addTopicparameterIdToQueryString();
    }
    this.setState(() => ({showingParameterEditor}));
    this.getTopicParameterStatsById(parameterId);
  };

  onHideParameterEditor = () => {
    this.setState(
      prevState => ({
        showingParameterEditor: null,
        selectedRegex: null,
        selectedRegExp: null,
        maxUsagesSettings: {
          ...prevState.maxUsagesSettings,
          fp: true,
          fn: true,
          tp: true,
          tn: false,
        },
      }),
      () => {
        if (this.props.location.search) {
          this.props.addTopicparameterIdToQueryString();
        }
      },
    );
    this.getTopicParameterStatsById(null);
  };

  render() {
    const {organisationId, topic, topicFetch} = this.props;
    const handleChange = (event, tabIndex) => {
      this.setState({tabIndex});
    };

    const refetchTopic = async contractIds => {
      const params = {
        usages_fetch_limit: getTopicUsagesFetchLimit(topic.id),
        text_search: "",
        omit_param_stats: true,
        ...getHotProjectsParams(),
        contract_types: JSON.stringify(contractIds),
      };

      await topicFetch(organisationId, topic.id, params);
    };

    return (
      <div className="app-page">
        <Toolbar className="app-toolbar" style={{overflow: "hidden"}}>
          <ToolbarGroup key={0} style={{width: "100%"}}>
            <HotAndStarInfoWidget
              label="Topic"
              isHot={topic.is_hot}
              isStar={topic.is_star}
              updateIsStar={this.updateTopicIsStar}
            />
            <TextField
              type="text"
              className="name"
              value={this.state.name}
              onChange={this.onTextFieldChange("name")}
              onBlur={this.updateName}
              style={{
                width: "100%",
                marginLeft: 8,
              }}
              name="topic-name"
              multiLine={true}
            />
            <span className="master-id" style={{whiteSpace: "nowrap"}}>
              {topic.master_id}
            </span>
            <LinkButton
              pathname={`/organisation/${organisationId}/topic/list`}
              buttonProps={{style: {background: "white"}}}
              linkProps={{style: {margin: "0 1rem"}}}
            >
              Topic List
            </LinkButton>
            <LinkButton
              pathname={`/organisation/${organisationId}/topic/${topic.id}/analysis`}
              buttonProps={{style: {background: "white"}}}
            >
              Analysis
            </LinkButton>
            <IconMenu
              className="topicDetailMenu"
              iconButtonElement={
                <IconButton>
                  <MoreIcon />
                </IconButton>
              }
              iconStyle={{padding: 0}}
              style={{width: "1.5em"}}
            >
              <MenuItem
                className="delete-button"
                onClick={this.showDeleteDialogue}
              >
                Delete
              </MenuItem>
            </IconMenu>
          </ToolbarGroup>
        </Toolbar>
        <AppBar position="static" color="default" style={styles.appBar}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={this.state.tabIndex}
            onChange={handleChange}
          >
            <Tab key="details" label="Details" />
            <Tab key="parameters" label="Usages & Parameters" />
            <Tab key="issues" label="Issues" />
            <Tab key="logs" label="Logs" />
          </Tabs>
        </AppBar>
        <div
          className="app-body app-body--white"
          style={{
            padding: this.state.tabIndex === 3 ? "0em" : "1em",
          }}
        >
          {this.state.tabIndex === 1 && (
            <HotProjectSelector
              {..._.pick(this.props, [
                "topic",
                "contractTypesById",
                "organisationId",
              ])}
              projects={this.props.projects.filter(project =>
                project.used_contract_type_ids.find(ctId =>
                  topic.contract_types.find(ct => ct.contract_type_id === ctId),
                ),
              )}
              onCloseHandler={this.refetchUsageData}
              refetchTopic={refetchTopic}
              initSelectedContractTypeIds={this.state.selectedContractTypeIds}
              showContractType
            />
          )}
          {this.renderActiveTab(this.state.tabIndex)}
          <Dialog
            title="Confirm topic deletion"
            open={this.state.showDeleteDialogue}
            actions={[
              <FlatButton
                key="cancelButton"
                label="Cancel"
                secondary={true}
                keyboardFocused={true}
                onClick={this.hideDeleteDialogue}
              />,
              <FlatButton
                key="deleteTopicButton"
                label="Delete Topic"
                primary={true}
                onClick={this.deleteTopic}
              />,
            ]}
            modal={true}
          >
            Are you sure you want to delete this topic?
          </Dialog>
        </div>
      </div>
    );
  }

  renderActiveTab(tabIndex) {
    if (tabIndex === 0) {
      return this.renderDetails();
    }
    if (tabIndex === 1) {
      return this.renderParameters();
    }
    if (tabIndex === 2) {
      return this.renderReferencedIssues();
    }
    if (tabIndex === 3) {
      return this.renderTopicLogs();
    }
  }

  renderDetails() {
    const {
      topic,
      topics,
      topicsById,
      topicCategories,
      topicTags,
      contractTypesById,
    } = this.props;
    const hasConfirming =
      topic.usage.filter(usage => usage.is_confirmed === false).length > 0;
    return (
      <>
        <TopicEditor
          topic={topic}
          topics={topics}
          topicsById={topicsById}
          topicCategories={topicCategories}
          topicTags={topicTags}
          onNewCategorySet={this.newCategorySet}
          onExistingCategorySet={this.existingCategorySet}
          onNewTagAdded={this.newTagAdded}
          onExistingTagAdded={this.existingTagAdded}
          onTagRemoved={this.tagRemoved}
          contractTypesById={contractTypesById}
          onContractTypesAdded={this.contractTypesAdded}
          onContractTypesRemoved={this.contractTypesRemoved}
          updateIsSubstantive={this.updateIsSubstantive}
          updateIsEmbedding={this.updateIsEmbedding}
        />

        <TextField
          hintText="Add Help"
          floatingLabelText="Help"
          multiLine={true}
          rowsMax={4}
          style={styles.textField}
          value={this.state.help}
          onBlur={this.onHelpSubmit}
          onChange={this.onTextFieldChange("help")}
          floatingLabelStyle={{zIndex: 0}}
        />

        <TopicDescription
          usages={topic.usage}
          parameters={topic.parameters}
          hasConfirming={hasConfirming}
          onUpdateTopicValue={this.onUpdateTopicValue}
          {...this.props}
        />
      </>
    );
  }

  renderTopicLogs() {
    return (
      <TopicLogs
        topic={this.props.topic}
        organisationId={this.props.organisationId}
        logs={this.props.logs}
        fetchLogs={this.props.fetchTopicAnalysisLogs}
        onTopicAdd={this.props.onExistingTopicAdded}
        onTopicRemove={this.props.onTopicRemoved}
      />
    );
  }

  renderReferencedIssues() {
    const {organisationId, topic, contractTypesById, issues} = this.props;
    if (!this.fetchedIssues) {
      this.props.getIssues();
      this.fetchedIssues = true;
    }
    return (
      <ReferencedIssues
        mainEntity={{
          id: topic.id,
          referenced_issues: topic.referenced_issues,
        }}
        topic={topic}
        organisationId={organisationId}
        contractTypesById={contractTypesById}
        issues={issues}
        collapseStateSessionName="topicDetailReferencedIssuesIsCollapsed"
      />
    );
  }

  renderParameters() {
    const {
      showingParameterEditor,
      issuesPausedByTopicparameter,
      maxUsagesSettings,
      initUsagesSettings,
    } = this.state;
    const isInFixMode = this.props.location.search.indexOf("&fix") !== -1;
    const {topic} = this.props;
    const hasConfirming =
      topic.usage.filter(usage => usage.is_confirmed === false).length > 0;

    const partiallyProcessedParameters = (topic.parameters || []).reduce(
      (result, tp) => {
        if (tp.partially_processed) {
          result[tp.id] = true;
        }
        return result;
      },
      {},
    );
    const processedErrorsOnly =
      Object.keys(partiallyProcessedParameters).length > 0;
    const isParameterPaused =
      showingParameterEditor &&
      issuesPausedByTopicparameter[showingParameterEditor.id];
    const stats = this.constructStats();
    const shownUsages = this.calculateUsages();
    return (
      <div style={{overflow: "auto"}}>
        <div style={processedErrorsOnly ? {background: "#fffde7"} : {}}>
          {this.renderFixModePanel(isInFixMode)}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{width: "100%"}}>
              {!showingParameterEditor &&
                this.renderTopicParameters(
                  topic.parameters,
                  isInFixMode,
                  partiallyProcessedParameters,
                  false,
                )}
              {this.renderTopicParameterEditor(
                isInFixMode,
                partiallyProcessedParameters,
              )}
            </div>
            <div style={{marginRight: "40px"}}>
              {processedErrorsOnly &&
                this.renderToolbarChip(
                  "Processed Errors Only",
                  (!isParameterPaused ? 24 : 232) + DEV_MODE_WIDTH,
                )}
              {showingParameterEditor &&
                issuesPausedByTopicparameter[showingParameterEditor.id] &&
                this.renderToolbarChip(
                  "Issue Processing Paused",
                  24 + DEV_MODE_WIDTH,
                )}
              {/* {showingParameterEditor && (
                <CheckboxBasic
                  checked={
                    issuesPausedByTopicparameter[showingParameterEditor.id]
                  }
                  onCheck={this.triggerTopicparameterIssuesPaused}
                  label="Pause Issue Processing"
                  containerStyles={{
                    marginLeft: -8,
                  }}
                  disabled={showingParameter && showingParameter.issues_paused}
                />
              )} */}
              <Toggle
                label="Show matching regexes"
                toggled={this.state.showMatchRegexes}
                onToggle={this.onShowMatchRegexes}
                style={{width: "14rem"}}
              />
              <UsagesFetchLimitInput
                getTopicUsages={this.getTopicUsages}
                isRequestPending={this.state.isTopicDataRequestPending}
                topicId={topic.id}
                totalValuesCount={stats && stats.total_count}
              />
              <MaxUsagesControl
                label="Max Usages Per Section"
                usages={topic.usage}
                settings={maxUsagesSettings}
                updateMaxUsagesSettings={this.updateMaxUsagesSettings}
                onUsagesTextFilterChange={this.onUsagesTextFilterChange}
                isRequestPending={this.state.isTopicDataRequestPending}
                stats={stats}
                showButton={!_.isEqual(maxUsagesSettings, initUsagesSettings)}
                updateUsages={this.updateUsages}
              />
              <BulkTopicControlPanel
                entityLabel="Parameter"
                usageBulkIds={getUsageBulkIds(
                  getBulkUsages(shownUsages, this.state.selectedRegex),
                  this.state.unselectedUsageIds,
                )}
                usesToSelect={this.state.usesToSelect}
                onUsesToSelectChange={this.onUsesToSelectChange}
                onConfirmUnconfirmed={this.confirmTopicparameterInClauses}
                onAddFPs={this.addTopicparametersToFPs}
                onRemoveFNs={this.removeTopicparametersFromFNs}
              />
            </div>
          </div>
          {!isInFixMode &&
            this.renderTopicTextTable(true, hasConfirming, shownUsages)}
        </div>
      </div>
    );
  }
  renderTopicParameters(
    parameters,
    isInFixMode,
    partiallyProcessedParameters,
    condensedView,
  ) {
    const {topic, organisationId, contractTypesById, issuesById} = this.props;
    return (
      <TopicParameters
        parameters={parameters}
        isInFixMode={isInFixMode}
        contractTypesById={contractTypesById}
        issuesById={issuesById}
        onShowParameterEditor={this.onShowParameterEditor}
        organisationId={organisationId}
        refreshTopicData={this.refreshTopicData}
        lastTopicparameterBulkClassification={
          topic.last_topicparameter_bulk_classification
        }
        bulkClassifiedTopicparameters={
          topic.bulkClassifiedTopicparameters || []
        }
        usages={topic.usage}
        isTopicDataRequestPending={this.state.isTopicDataRequestPending}
        topic={topic}
        partiallyProcessedParameters={partiallyProcessedParameters}
        processTopicParameters={this.processTopicParameters}
        onTopicParameterUpdated={this.onTopicParameterUpdated}
        condensedView={condensedView}
        getTopicParameterStatsById={this.getTopicParameterStatsById}
      />
    );
  }

  renderTopicParameterEditor(isInFixMode, partiallyProcessedParameters) {
    if (this.state.showingParameterEditor) {
      const {topic, organisationId, contractTypesById, issuesById} = this.props;
      const parameter =
        topic.parameters.find(
          parameter => parameter.id === this.state.showingParameterEditor.id,
        ) || {};
      const {id, last_edited: lastEdited} = parameter;
      const logs = topic.parameter_logs;
      return (
        <TopicParameterEditor
          statsTable={this.renderTopicParameters(
            [parameter],
            isInFixMode,
            partiallyProcessedParameters,
            true,
          )}
          onDismiss={this.onHideParameterEditor}
          parameters={(this.props.topic || {}).parameters}
          parameter={parameter}
          onTopicParameterAdded={this.onTopicParameterAdded}
          onTopicParameterDeleted={this.onTopicParameterDeleted(id, lastEdited)}
          onTopicParameterUpdated={this.onTopicParameterUpdated(id, lastEdited)}
          logs={logs && logs[id]}
          organisationId={organisationId}
          usages={topic.usage}
          isInFixMode={isInFixMode}
          contractTypesById={contractTypesById}
          issuesById={issuesById}
          issues={this.props.issues}
          isTopicDataRequestPending={this.state.isTopicDataRequestPending}
          partiallyProcessedParameters={partiallyProcessedParameters}
          refreshTopicData={this.refreshTopicData}
          selectedRegex={this.state.selectedRegex}
          onRegexSelect={this.onRegexSelect}
        />
      );
    }
    return null;
  }

  updateUsages = () => {
    this.setState({initUsagesSettings: this.state.maxUsagesSettings});
    this.getTopicUsages();
  };

  getFetchUsagesParams = () => {
    const {
      showingParameterEditor,
      usagesTextFilter,
      maxUsagesSettings,
    } = this.state;

    return {
      ...(showingParameterEditor
        ? {topicparameter_id: showingParameterEditor.id}
        : {}),
      ...(usagesTextFilter && usagesTextFilter.length > 3
        ? {text_search: usagesTextFilter}
        : {}),
      ...(showingParameterEditor && maxUsagesSettings && maxUsagesSettings.show
        ? {confirmed_state_to_show: maxUsagesSettings.show}
        : {}),
      match_states: _.chain(maxUsagesSettings)
        .omit(["maxUsages", "show"])
        .omit((value, key) => !maxUsagesSettings[key])
        .map((value, key) => key)
        .value()
        .join(";"),
    };
  };

  onShowMatchRegexes = (e, showMatchRegexes) => {
    localStorage.setItem("topicAnalysisShowMatchRegexes", showMatchRegexes);
    this.setState(() => ({showMatchRegexes}));
  };

  getTopicUsages = async () => {
    this.loadCounter = (this.loadCounter ?? 0) + 1;
    const loadCounter = this.loadCounter;
    this.loadTimes = {
      ...this.loadTimes,
      [loadCounter]: new Date().valueOf(),
    };
    const {showingParameterEditor} = this.state;
    const topicId = this.props.topic.id;
    const maxUsagesSettings = {...this.state.maxUsagesSettings};
    this.setState(
      () => ({isTopicDataRequestPending: true}),
      async () => {
        await this.props.setEmptyTopicUses();
        const params = this.getFetchUsagesParams();
        const usages = await this.props.getTopicUsages(params);
        if (!usages) {
          return;
        }
        this.setState(
          () => ({
            isTopicDataRequestPending: false,
            usages: getSortedUsageIdsAndScores(usages, showingParameterEditor),
          }),
          () => {
            const startTime = this.loadTimes[loadCounter];
            const endTime = new Date().valueOf();
            this.props.logPageLoadTime(
              this.props.organisationId,
              "topic_detail",
              startTime,
              endTime,
              {
                topic_id: this.props.topic.id,
                fetch_limit: getTopicUsagesFetchLimit(topicId),
                max_usages: maxUsagesSettings?.maxUsages,
                ...params,
              },
            );
          },
        );
      },
    );
  };
  onTopicParameterDeleted = _.memoize(
    (id, lastEdited) => () => {
      this.props.onTopicParameterDeleted(id, lastEdited);
      this.onHideParameterEditor();
    },
    (...args) => JSON.stringify([...args]),
  );

  onTopicParameterUpdated = _.memoize(
    (id, lastEdited) => (
      data,
      shouldUseProblemClausepartsOnly,
      ignoreIssuesPaused,
    ) => {
      const updateData = {...data};
      const {
        usages,
        showingParameterEditor,
        issuesPausedByTopicparameter,
      } = this.state;
      if (shouldUseProblemClausepartsOnly) {
        const clauseparts = usages;
        const clausepartIds = clauseparts.reduce((result, item) => {
          if (
            item &&
            ["tp", "tn", "unconfirmed"].indexOf(item.usageScore) === -1
          ) {
            result.push(item.clausepart_id);
          }
          return result;
        }, []);
        updateData.clauseparts = clausepartIds;
      }

      if (!ignoreIssuesPaused) {
        const currentTpIssuesPaused = showingParameterEditor.issues_paused;
        const stateTpIssuesPaused = issuesPausedByTopicparameter[id];
        if (currentTpIssuesPaused !== stateTpIssuesPaused) {
          updateData.issues_paused = stateTpIssuesPaused;
        }
      }

      return this.props.onTopicParameterUpdated(id, {
        ...updateData,
        last_edited: lastEdited,
      });
    },
    (...args) => JSON.stringify([...args]),
  );

  renderFixModePanel = isInFixMode => {
    if (!isInFixMode) {
      return null;
    }
    const {pathname, search} = this.props.location;
    const searchWithoutFix = search.replace("&fix", "");
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={styles.fixModeNotification}>FIX MODE</div>
        <a
          href={`${pathname}${searchWithoutFix}`}
          style={styles.linkToNormalMode}
        >
          Go back to normal mode?
        </a>
      </div>
    );
  };

  calculateUsages() {
    const {usage: baseUsages} = this.props.topic;
    const {
      showingParameterEditor,
      maxUsagesSettings,
      selectedRegex,
    } = this.state;
    const selectedRegexObj = selectedRegex ? new RegExp(selectedRegex) : null;
    const usagesById = byId(baseUsages);
    const {show: sectionsToShow, maxUsages} = maxUsagesSettings;

    const usages = (this.state.usages || [])
      .map(usage => ({
        ...usage,
        ...usagesById[usage.id],
      }))
      .filter(usage =>
        selectedRegexObj
          ? selectedRegexObj.test(
              usage.definitionAndHeadingsText || usage.clausepart_text,
            )
          : true,
      )
      .filter(usage => {
        if (showingParameterEditor) {
          if (!usage.usageScore && sectionsToShow === "all") {
            return true;
          }
          if (usage.usageScore === "unconfirmed") {
            return (
              sectionsToShow === "all" ||
              (sectionsToShow === "non_confirmed" && maxUsagesSettings.tp)
            );
          }
          if (usage.usageScore === "other") {
            return true;
          }
          return maxUsagesSettings[
            usage.usageScore === "incorrect" ? "fn" : usage.usageScore
          ];
        }
        return true;
      });

    const usagesLength = usages.length;
    const totalPages =
      !maxUsages || !usagesLength || usagesLength < maxUsages
        ? 1
        : Math.ceil(usagesLength / maxUsages); // (total usages / usages per page)
    const currentPage = this.state.shownUsagesPages;
    const areAllUsagesShown = this.state.showAllUsages;
    const shownUsages = this.getShownUsages(
      [...usages],
      maxUsages,
      currentPage,
      areAllUsagesShown,
    );
    return {
      shownUsages,
      totalPages,
      currentPage,
      areAllUsagesShown,
    };
  }

  renderTopicTextTable(
    isConfirmed,
    hasConfirming,
    {shownUsages, totalPages, currentPage, areAllUsagesShown},
  ) {
    const {parameters} = this.props.topic;
    const {showingParameterEditor, showMatchRegexes} = this.state;

    const collapseStateSessionName = "topicDetail_IsCollapsed";
    return (
      <TopicUsageList
        usages={shownUsages}
        parameters={parameters}
        className="usage-group"
        collapseStateSessionName={collapseStateSessionName}
        isConfirmed={isConfirmed}
        hasConfirming={hasConfirming}
        showingParameterEditor={showingParameterEditor}
        triggerShowUsage={this.triggerShowUsage}
        showUsageId={this.state.showUsageId}
        refreshTopicData={this.refreshTopicData}
        currentPage={currentPage}
        totalPages={totalPages}
        showNextUsagesPage={this.showNextUsagesPage(totalPages)}
        showPreviousUsagesPage={this.showPreviousUsagesPage()}
        onShowAllUsages={this.onShowAllUsages()}
        onAbortShowAllUsages={this.onAbortShowAllUsages()}
        areAllUsagesShown={areAllUsagesShown}
        isTopicDataRequestPending={this.state.isTopicDataRequestPending}
        showMatchRegexes={showMatchRegexes}
        {...this.props}
        onUsageRemoved={(usageId, ...args) => {
          const {usages} = this.state;
          for (const usage of usages) {
            if (usage.id === usageId) {
              usages.splice(usages.indexOf(usage), 1);
              break;
            }
          }
          this.setState({usages});
          this.props.onUsageRemoved(...args);
        }}
        isDevModeOn={isDevModeOn(showingParameterEditor)}
        onTopicparameterValuesUpdate={this.onTopicparameterValuesUpdate}
        usesToSelect={this.state.usesToSelect}
        unselectedUsageIds={this.state.unselectedUsageIds}
        triggerUnselectUsage={this.triggerUnselectUsage}
        selectedRegExp={this.state.selectedRegExp}
      />
    );
  }

  renderToolbarChip = (title, right) => (
    <div
      style={{
        position: "absolute",
        top: 78,
        zIndex: "9999",
        padding: 8,
        background: "#ffb74d",
        ...(right ? {right} : {}),
      }}
    >
      {title}
    </div>
  );

  getShownUsages = (usages, pageLength, currentPage, areAllUsagesShown) => {
    if (areAllUsagesShown) {
      return usages;
    }
    const maxIndex = currentPage * pageLength - 1;
    const minIndex = maxIndex - pageLength + 1;
    return usages.slice(minIndex, maxIndex + 1);
  };

  onUsesToSelectChange = submittedValue => {
    const oldValue = this.state.usesToSelect;
    const newValue = oldValue === submittedValue ? null : submittedValue;
    this.setState(() => ({usesToSelect: newValue}));
  };

  triggerUnselectUsage = clausepartId => () => {
    this.setState(prevState => {
      const prevValue = Boolean(prevState.unselectedUsageIds[clausepartId]);
      return {
        unselectedUsageIds: {
          ...prevState.unselectedUsageIds,
          [clausepartId]: !prevValue,
        },
      };
    });
  };

  changeShownUsagesPage = changeValue => {
    const newValue = this.state.shownUsagesPages + changeValue;
    if (newValue < 1) {
      return;
    }
    this.setState(() => ({
      shownUsagesPages: newValue,
    }));
  };

  showNextUsagesPage = totalPages => () => {
    if (this.state.shownUsagesPages === totalPages) {
      return;
    }
    this.changeShownUsagesPage(1);
  };

  showPreviousUsagesPage = () => () => {
    this.changeShownUsagesPage(-1);
  };

  onShowAllUsages = caption => () => {
    this.setState(prevState => ({
      showAllUsages: {
        ...prevState.showAllUsages,
        [caption]: true,
      },
      maxUsagesSettings: {
        ...prevState.maxUsagesSettings,
        fp: true,
        fn: true,
        tp: false,
        tn: false,
      },
    }));
  };

  onAbortShowAllUsages = () => () => {
    this.setState(prevState => ({
      showAllUsages: false,
      maxUsagesSettings: {
        ...prevState.maxUsagesSettings,
        fp: true,
        fn: true,
        tp: true,
        tn: false,
      },
    }));
  };

  onTextFieldChange = name => e => {
    this.setState({[name]: e.target.value});
  };

  onUpdateTopicValue = (itemPath, rawNewValue, oldUpdatedObject) => {
    if (!itemPath || itemPath.length === 0) {
      return null;
    }
    const oldValue = get(this.props.topic, itemPath) || "";
    const newValue =
      typeof rawNewValue === "string" ? rawNewValue.trim() : rawNewValue;
    if (newValue !== oldValue) {
      const objectWithUpdate = set(
        oldUpdatedObject ? oldUpdatedObject : {},
        itemPath,
        newValue,
      );
      this.props.onTopicUpdated(objectWithUpdate);
    }
    return null;
  };

  updateName = () => this.onUpdateTopicValue(["name"], this.state.name);

  onHelpSubmit = () => this.onUpdateTopicValue(["help"], this.state.help);

  newCategorySet = categoryName => {
    this.props.onTopicUpdated({topiccategory_name: categoryName});
  };

  existingCategorySet = categoryId => {
    this.onUpdateTopicValue(["topiccategory_id"], categoryId);
  };

  newTagAdded = tagName => {
    this.props.onTopicUpdated({new_tags: [{name: tagName}]});
  };

  existingTagAdded = tagId => {
    this.props.onTopicUpdated({new_tags: [{id: tagId}]});
  };

  tagRemoved = tagId => {
    this.props.onTopicUpdated({removed_tags: [tagId]});
  };

  contractTypesAdded = contractTypes => {
    this.props.onTopicUpdated({added_contract_types: contractTypes});
  };

  contractTypesRemoved = contractTypes => {
    this.props.onTopicUpdated({removed_contract_types: contractTypes});
  };

  updateIsSubstantive = () => {
    this.props.onTopicUpdated({
      is_substantive: !this.props.topic.is_substantive,
    });
  };

  updateIsEmbedding = () => {
    this.props.onTopicUpdated({
      is_embedding: !this.props.topic.is_embedding,
    });
  };

  showDeleteDialogue = () => this.setState({showDeleteDialogue: true});
  hideDeleteDialogue = () => this.setState({showDeleteDialogue: false});
  deleteTopic = () => {
    this.setState({showDeleteDialogue: false});
    this.props.onTopicDeleted();
  };

  refreshTopicData = async () => {
    const {showingParameterEditor} = this.state;
    if (!showingParameterEditor) {
      return;
    }
    const {usage} = this.props.topic;
    await this.setState(() => ({isTopicDataRequestPending: true}));
    const result = await this.props.getTopicData(this.getFetchUsagesParams());
    this.setState(() => ({
      isTopicDataRequestPending: false,
      usages: getSortedUsageIdsAndScores(
        result.usages || usage,
        showingParameterEditor,
      ),
    }));
  };

  onTopicParameterAdded = async (
    topicParameterName,
    type,
    values,
    description,
  ) => {
    const result = await this.props.onTopicParameterAdded(
      topicParameterName,
      type,
      values,
      description,
    );
    if (result && result.value && result.value.id) {
      this.onShowParameterEditor(result.value.id);
    }
  };

  triggerShowUsage = (sectionName, usageId, usageScore) => {
    const {showingParameterEditor} = this.state;
    if (!showingParameterEditor) {
      return;
    }
    const selectedText = getSelectedText();
    if (selectedText) {
      return;
    }
    this.setState(
      (prevState, props) => {
        if (!prevState.showUsageId) {
          return {
            showUsageId: usageId,
            usages: [
              {
                id: usageId,
                usageScore,
              },
            ],
          };
        }
        return {
          showUsageId: null,
          usages: getSortedUsageIdsAndScores(
            props.topic.usage,
            prevState.showingParameterEditor,
          ),
        };
      },
      () => {
        if (showingParameterEditor && !this.state.showUsageId) {
          this.props.getTopicData(this.getFetchUsagesParams());
        }
      },
    );
  };

  updateMaxUsagesSettings = (maxUsagesSettings, updateType) => {
    this.setState(
      () => ({maxUsagesSettings}),
      () => {
        if (this.state.showingParameterEditor && updateType === "show") {
          this.getTopicUsages();
        }
      },
    );
  };

  onUsagesTextFilterChange = newValue => {
    this.setState(
      () => ({usagesTextFilter: newValue}),
      () => {
        this.getTopicUsages();
      },
    );
  };

  // triggerTopicparameterIssuesPaused = () => {
  //   if (this.state.showingParameterEditor) {
  //     const showingParameterId = this.state.showingParameterEditor.id;
  //
  //     const newValue = {
  //       ...this.state.issuesPausedByTopicparameter,
  //       [showingParameterId]: !this.state.issuesPausedByTopicparameter[
  //         showingParameterId
  //       ],
  //     };
  //
  //     this.setState(() => ({
  //       issuesPausedByTopicparameter: newValue,
  //     }));
  //   }
  // };

  processTopicParameters = data => {
    if (!data.keep_issues_paused) {
      this.setState(() => ({
        issuesPausedByTopicparameter: this.props.topic.parameters.reduce(
          (result, param) => {
            result[param.id] = false;
            return result;
          },
          {},
        ),
      }));
    }

    this.props.processTopicParameters(data);
  };
  getTopicParameterStatsById = id => this.props.getTopicParameterStats(id);
  fetchUsageDataIfNotFetched = (id, fetchForcefully) => {
    if (!this.fetchedUsages || fetchForcefully) {
      this.fetchedUsages = true;
      this.setState(
        () => ({isTopicDataRequestPending: true}),
        () => {
          this.getTopicUsages();
          this.props.getTopicParameterStats(id);
        },
      );
    }
    if (!this.fetchedIssues) {
      this.props.getIssues();
      this.fetchedIssues = true;
    }
  };

  onRegexSelect = (selectedRegex, selectedRegExp) =>
    this.setState(prevState => ({
      selectedRegex:
        prevState.selectedRegex === selectedRegex ? null : selectedRegex,
      selectedRegExp,
    }));

  refetchUsageData = () => {
    this.fetchUsageDataIfNotFetched(null, true);
  };

  onTopicparameterValuesUpdate = async (
    projectId,
    documentId,
    clauseId,
    clausepartId,
    topicId,
    data,
  ) => {
    const result = await this.props.onTopicparameterValuesUpdate(
      projectId,
      documentId,
      clauseId,
      clausepartId,
      topicId,
      data,
    );
    const {value} = result;
    const {usages, showingParameterEditor} = this.state;
    if (value && showingParameterEditor) {
      const usage = this.props.topic.usage.find(
        item => item.clausepart_id === value.clausepart_id,
      );
      if (!usage) {
        return;
      }
      const param = (usage.parameter_values || []).find(
        param => param.topicparameter_id === showingParameterEditor.id,
      );
      if (!param) {
        return;
      }

      const newUsageStats = getUsageScoreStats(
        param,
        showingParameterEditor.parameter_type,
      );
      const newUsageScore = getUsageScoreFromStats(newUsageStats);
      this.setState(() => ({
        usages: usages.map(item => {
          if (item.clausepart_id === value.clausepart_id) {
            return {
              ...item,
              usageScore: newUsageScore,
              usageScoreStats: newUsageStats,
            };
          }
          return item;
        }),
      }));
    }
  };

  constructStats = () => {
    const {topic} = this.props;
    const {stats} = topic;
    const {usages, showingParameterEditor} = this.state;
    if (showingParameterEditor && stats) {
      const fetchedStats = {
        fetched_fp_count: 0,
        fetched_tp_count: 0,
        fetched_fn_count: 0,
      };

      for (const usage of usages) {
        const {usageScore} = usage;
        if (usageScore === "fp") {
          fetchedStats.fetched_fp_count++;
        } else if (usageScore === "tp") {
          fetchedStats.fetched_tp_count++;
        } else if (usageScore === "fn") {
          fetchedStats.fetched_fn_count++;
        }
      }
      return {...stats, ...fetchedStats};
    } else if (!showingParameterEditor && stats) {
      return {total_count: stats.total_count};
    }
    return null;
  };

  confirmTopicparameterInClauses = data =>
    this.onUpdateTopicparameterActualValuesInClauses(
      data,
      "confirm_unconfirmed",
    );

  addTopicparametersToFPs = data =>
    this.onUpdateTopicparameterActualValuesInClauses(data, "add_to_fps");

  removeTopicparametersFromFNs = data =>
    this.onUpdateTopicparameterActualValuesInClauses(data, "remove_from_fns");

  onUpdateTopicparameterActualValuesInClauses = async (data, type) => {
    if (this.state.showingParameterEditor) {
      await this.props.updateTopicparameterActualValuesInClauses(
        this.state.showingParameterEditor.id,
        data,
        type,
      );

      this.setState(() => ({
        // isTopicDataRequestPending: false,
        usages: getSortedUsageIdsAndScores(
          this.props.topic.usage,
          this.state.showingParameterEditor,
        ),
      }));
    }
  };

  updateTopicIsStar = () => {
    this.props.onTopicUpdated({is_star: !this.props.topic.is_star});
  };
}

function isDevModeOn(showingParameterEditor) {
  if (showingParameterEditor) {
    if (
      showingParameterEditor.parameter_type &&
      showingParameterEditor.dev_value &&
      !areValuesEqual(
        showingParameterEditor.value,
        showingParameterEditor.dev_value,
      )
    ) {
      return true;
    }
  }
  return false;
}

function getSortedUsageIdsAndScores(usages, showingParameterEditor) {
  // fyi: we get scores only when showingParameterEditor is given
  const isDevMode = isDevModeOn(showingParameterEditor);

  const sortedUsages = getSortedUsages(
    usages,
    showingParameterEditor,
    isDevMode,
  );
  return sortedUsages.map(usage => ({
    id: usage.id,
    usageScore: usage.usageScore,
    clausepart_id: usage.clausepart_id,
    isEditing: usage.isEditing,
    usageScoreStats: usage.usageScoreStats,
    clausepartText: usage.clausepart_text,
  }));
}

function getSortedUsages(baseUsages, showingParameterEditor, isDevModeOn) {
  const editingParameterUsages = [];
  let otherUsages = [];
  if (showingParameterEditor && showingParameterEditor.id) {
    baseUsages.forEach(usage => {
      const editingParameter =
        usage.parameter_values &&
        usage.parameter_values.find(
          pv => pv.topicparameter_id === showingParameterEditor.id,
        );
      if (editingParameter) {
        const usageScoreStats = getUsageScoreStats(
          editingParameter,
          showingParameterEditor.parameter_type,
          isDevModeOn,
        );
        const usageScore = getUsageScoreFromStats(usageScoreStats);

        editingParameterUsages.push({
          ...usage,
          usageScore,
          usageScoreStats,
          isEditing: true,
        });
      } else {
        editingParameterUsages.push({
          ...usage,
          usageScoreStats: {
            fp: 0,
            fn: 0,
            tp: 0,
            incorrect: 0,
            unconfirmed: 0,
            other: 0,
            tn: 1,
          },
          usageScore: "tn",
        });
      }
    });
  } else {
    otherUsages = otherUsages.concat(baseUsages);
  }
  return [
    ..._.sortBy(editingParameterUsages, editingParameterUsagesSortingFunction),
    ..._.sortBy(otherUsages, otherUsagesSortingFunction),
  ];
}

function editingParameterUsagesSortingFunction(usage) {
  const scores = {
    fp: 0,
    fn: 1,
    incorrect: 2,
    unconfirmed: 3,
    tp: 4,
  };
  return scores[usage.usageScore] ?? 5;
}

function getUsageScoreFromStats(usageScoreStats) {
  if (usageScoreStats.fp > 0) {
    return "fp";
  } else if (usageScoreStats.fn > 0) {
    return "fn";
  } else if (usageScoreStats.incorrect > 0) {
    return "incorrect";
  } else if (usageScoreStats.unconfirmed > 0) {
    return "unconfirmed";
  } else if (usageScoreStats.tp > 0) {
    return "tp";
  } else if (usageScoreStats.tn > 0) {
    return "tn";
  }
  return "other";
}

function otherUsagesSortingFunction(usage) {
  return [
    usage.exact_match_id || usage.original_exact_match_id || "",
    usage.project_name,
    usage.document_name,
    usage.clausepart_reference,
    usage.clausepart_text,
    usage.project_id,
    usage.document_id,
  ].join("_");
}

function getSelectedText() {
  let text = "";
  if (typeof window.getSelection !== "undefined") {
    text = window.getSelection().toString();
  } else if (
    typeof document.selection !== "undefined" &&
    document.selection.type === "Text"
  ) {
    text = document.selection.createRange().text;
  }
  return text;
}

function getBulkUsages(shownUsages, selectedRegex) {
  const selectedRegexObj = selectedRegex ? new RegExp(selectedRegex) : null;
  const result = [];

  const sectionUsages = selectedRegex
    ? shownUsages.shownUsages.filter(usage =>
        selectedRegexObj ? selectedRegexObj.test(usage.clausepartText) : true,
      )
    : shownUsages.shownUsages;
  for (const usage of sectionUsages) {
    if (usage.isEditing) {
      result.push(usage);
    }
  }
  return result;
}

function getUsageBulkIds(usages, unselectedUsageIds) {
  return usages.reduce(
    (accum, usage) => {
      const {clausepart_id: clausepartId} = usage;
      const {usageScore} = usage;
      if (accum[usageScore]) {
        accum[usageScore].count += 1;
        if (!unselectedUsageIds[clausepartId]) {
          accum[usageScore].ids.push(clausepartId);
        }
      }
      return accum;
    },
    {
      fp: {
        label: "FP",
        count: 0,
        dialogLabel: "addAllFPs",
        ids: [],
      },
      fn: {
        label: "FN",
        count: 0,
        dialogLabel: "removeAllFNs",
        ids: [],
      },
      unconfirmed: {
        label: "Unconfirmed",
        count: 0,
        dialogLabel: "confirmAllUnconfirmed",
        ids: [],
      },
    },
  );
}

TopicDetail.propTypes = {
  organisationId: PropTypes.number.isRequired,
  topic: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    master_id: PropTypes.string.isRequired,
    topiccategory_id: PropTypes.number.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    usage: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        clause_id: PropTypes.number.isRequired,
        clausepart_id: PropTypes.number.isRequired,
        clause_reference: PropTypes.string.isRequired,
        clausepart_text: PropTypes.string.isRequired,
        project_id: PropTypes.number.isRequired,
        project_name: PropTypes.string.isRequired,
        document_id: PropTypes.number.isRequired,
        document_name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    parameters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        values: PropTypes.arrayOf(PropTypes.string),
      }),
    ).isRequired,
  }).isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicsById: keyedObjectPropType(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategoriesById: keyedObjectPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  topicTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onUsageRemoved: PropTypes.func.isRequired,
  onUsageConfirmed: PropTypes.func.isRequired,
  onExistingTopicAdded: PropTypes.func.isRequired,
  onNewTopicAdded: PropTypes.func.isRequired,
  onTopicRemoved: PropTypes.func.isRequired,
  onTopicUpdated: PropTypes.func.isRequired,
  onTopicConfirmed: PropTypes.func.isRequired,
  onTopicsReordered: PropTypes.func.isRequired,
  onTopicParameterAdded: PropTypes.func.isRequired,
  onTopicParameterUpdated: PropTypes.func.isRequired,
  onTopicParameterDeleted: PropTypes.func.isRequired,
};

export default TopicDetail;
