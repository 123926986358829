import React from "react";

import NumberedItem from "./numbered_item";
import Topics from "./topics";

import renderNode from "../utils/render_node";
import renderNumber from "../utils/render_number";
import doesLevelMatch from "../utils/does_level_match";
import commonStyles from "../utils/common_styles";

export default class ClauseNode extends React.Component {
  render() {
    const {
      isInteractive,
      selectedPath,
      selectedLevel,
      clause,
      parent,
      onTopicsHover,
    } = this.props;
    let totalOffset = 0;
    const isMatchedLevel = doesLevelMatch(selectedLevel, clause, parent);
    const isSelectedLevel = isInteractive && isMatchedLevel;
    const isSelectedPath = isInteractive && selectedPath === clause.path;

    const lacksTopics = this.doesNodeLackTopics();
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          marginBottom: "1em",
          border: isSelectedLevel ? commonStyles.selectedLevel.border : "none",
          /* eslint-disable no-nested-ternary */
          backgroundColor: isSelectedLevel
            ? commonStyles.selectedLevel.background
            : isSelectedPath ? commonStyles.selectedPath.background : "inherit",
          /* eslint-enable no-nested-ternary */
          padding: "1px",
        }}
        className={[
          isSelectedLevel ? "selected-node" : "",
          isSelectedPath ? "highlighted-node" : "",
        ].join(" ")}
      >
        <div
          className="clause-node"
          style={{
            width: isMatchedLevel ? "70%" : "100%",
          }}
        >
          {this.props.clause.clauseNodes.map((child, index) => {
            const node = this.renderNode(
              child,
              index,
              totalOffset,
              lacksTopics,
            );
            if (child.text) {
              totalOffset = child.text.length + 1;
            }
            return node;
          })}
        </div>
        <Topics {...this.props} onHover={onTopicsHover} />
      </div>
    );
  }
  doesNodeLackTopics() {
    const {clause, lacksTopics} = this.props;
    return (
      lacksTopics &&
      (!clause.topics || clause.topics.length === 0) &&
      this.childrenLackTopics(clause.clauseNodes[0])
    );
  }
  childrenLackTopics(clause) {
    const hasTopics =
      (clause && clause.topics && clause.topics.length > 0) ||
      (clause &&
        clause.clauseNodes &&
        clause.clauseNodes.find(node => !this.childrenLackTopics(node)));
    return !hasTopics;
  }
  renderNode(clause, index, totalOffset, nodeLacksTopics) {
    const otherNodesLackTopics = this.childrenLackTopics(
      this.props.clause.clauseNodes[1],
    );
    const lacksTopics =
      index === 1 ? nodeLacksTopics : nodeLacksTopics && otherNodesLackTopics;
    const props = {
      ...this.props,
      totalOffset,
      index,
      lacksTopics,
    };
    const node = renderNode(
      clause,
      this.props.clause,
      props,
      this.props.reference,
    );
    const {counterType} = clause;
    if (clause.type !== "ClausePartNumberedList" && counterType) {
      const number = renderNumber(counterType, 0, this.props.reference);
      const reference =
        counterType === "DECIMAL"
          ? `${this.props.reference}.${number}`
          : number;

      return (
        <NumberedItem
          clause={clause}
          node={node}
          className="numbered-item"
          key={`${clause.id}_${reference}_${index}`}
          number={reference}
          shiftLeft={clause.counterType === "DECIMAL"}
          onClick={this.props.onReferenceClick}
        />
      );
    }
    return node;
  }
}
