import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ReplayIcon from "@material-ui/icons/Replay";
import SquareIconButton from "common_components/buttons/square_icon_button";
import HotAndStarInfoWidget from "common_components/hot_and_star_info_widget";
import {cloneDeep} from "lodash";
import IconButton from "material-ui/IconButton";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";

import TextField from "material-ui/TextField";
import Toggle from "material-ui/Toggle";

import * as TopicParameters from "plugins/topicparameters";
import React from "react";
import _ from "underscore";

import ReferencedIssues from "./referenced_issues";
import TopicParameterLogs from "./topic_parameter_logs";

import areValuesEqual from "./utils/are_values_equal";
import clearParameterValue from "./utils/clear_parameter_value";
import getDialogCaption from "./utils/get_dialog_caption";

const styles = {
  mainContainer: {
    padding: "2rem",
    margin: "1rem auto",
    maxWidth: "90rem",
    border: "1px solid lightgray",
  },
  header: {
    fontSize: "20px",
    fontWeight: "500",
    marginLeft: 8,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  },
  actionButton: {
    margin: "0 1rem",
  },
  closeIconButton: {
    color: "gray",
    cursor: "pointer",
  },
  lockIcon: {
    cursor: "pointer",
    background: "inherit",
    height: 22,
    width: 22,
  },
  devChipTopBar: {
    padding: "0px 2px",
    marginRight: 12,
    position: "absolute",
    top: 79,
    right: 10,
    zIndex: 9999,
  },
  devChipEditor: {
    marginRight: 12,
  },
  flex: {
    display: "flex",
  },
};

const chipStyles = {
  root: {
    "& .MuiChip-label": {
      padding: "0px 5px",
      margin: "0 10px",
      borderLeft: "1px solid white",
      borderRight: "1px solid white",
    },
  },
};

const useDevChipStyles = makeStyles(chipStyles);

function DevChip(props) {
  const classes = useDevChipStyles();
  return (
    <Chip
      classes={classes}
      color="primary"
      label="DEV MODE"
      style={props.style || {}}
      avatar={<LockOpenIcon style={styles.lockIcon} onClick={props.onLock} />}
      deleteIcon={<ReplayIcon />}
      onDelete={props.onRevert}
    />
  );
}

function getParameterStats(
  parameterId,
  parameterValues,
  usages,
  isInFixMode,
  useDevValues,
) {
  const stats = parameterValues.reduce((acc, value) => {
    acc[value.pattern[0]] = {
      totalMatches: 0,
      applications: 0,
    };
    return acc;
  }, {});
  if (isInFixMode) {
    return stats;
  }
  usages.forEach(usage => {
    for (let i = 0; i < parameterValues.length; i++) {
      const parameterRegex = new RegExp(parameterValues[i].pattern[0]);
      if (parameterRegex.test(usage.clausepart_text)) {
        stats[parameterValues[i].pattern[0]].totalMatches++;

        const parameterValue = usage.parameter_values.find(
          pv => pv.topicparameter_id === parameterId,
        );
        if (parameterValue) {
          const {actual_values: actualValues} = parameterValue;
          const values = useDevValues
            ? parameterValue.dev_values
            : parameterValue.values;
          if (
            values &&
            values.length > 0 &&
            actualValues &&
            actualValues.length > 0
          ) {
            const valuesNotInActualValues = values.filter(
              value => !actualValues.includes(value),
            );
            if (valuesNotInActualValues.length === 0) {
              stats[parameterValues[i].pattern[0]].applications++;
            }
          }
        }
      }
    }
  });
  return stats;
}

// In order to disable Save buttons we need to detect changes in values.
// Currently values are updated on each textfield change but detecting
// changes on each keypress means comparing big objects every time which
// slows down perfomance. Hence we implement debouce logic to do a comparison
// in some time after last value change.
// To do that we need this.updateValueCountTimeout variable that keeps timeout id
// AND a state variable updateValueCount which resets every time the timeout
// expires. The variable needs to be in state because we need to trigger
// a rerender which will do the comparison in componentDidUpdate

class TopicParameterEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initialiseState(props.parameter),
      areIssuesShown: false,
      updateValueCount: 0,
      topicparameterChanges: null,
      shownDialog: null,
      isCollapsed: false,
    };

    this.updateValueCountTimeout = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      parameter: newParameter,
      isTopicDataRequestPending: newIsTopicDataRequestPending,
    } = this.props;
    const {
      parameter: oldParameter,
      isTopicDataRequestPending: oldIsTopicDataRequestPending,
    } = prevProps;
    if (
      (newParameter &&
        oldParameter &&
        (newParameter.id !== oldParameter.id ||
          !areValuesEqual(newParameter.value, oldParameter.value))) ||
      !areValuesEqual(newParameter.dev_value, oldParameter.dev_value) ||
      (newIsTopicDataRequestPending !== oldIsTopicDataRequestPending &&
        // This case crudely protects the various changes the user can make on
        // this screen from being clobbered by any arbitrary changes coming
        // from higher-level state updates (API calls, results of asynchronous
        // processing, etc). Without knowing too much about those conditions,
        // the intent here is simply to err on the side of caution by
        // preserving local changes as long as any exist that are unsaved.
        // This may be too strict if there exist any real-time updates this
        // component SHOULD reflect as a priority over potential loss of user
        // inputs. In the meantime, we assume there aren't.
        !this.state.hasUnsavedChanges)
    ) {
      if (this.updateValueCountTimeout) {
        clearTimeout(this.updateValueCountTimeout);
      }
      this.updateTopicparameterChanges(null);
      this.setState(() => this.initialiseState(newParameter));
    }

    if (
      (this.state.updateValueCount === 0 && prevState.updateValueCount > 0) ||
      (newParameter &&
        oldParameter &&
        newParameter.id === oldParameter.id &&
        (this.state.name !== prevState.name ||
          this.state.description !== prevState.description))
    ) {
      const changes = this.getChanges();
      if (!_.isEqual(changes, this.state.topicparameterChanges)) {
        this.updateTopicparameterChanges(changes);
      }
    }
  }

  componentWillUnmount() {
    if (this.updateValueCountTimeout) {
      clearTimeout(this.updateValueCountTimeout);
    }
  }

  initialiseState(parameter) {
    const {parameter_type, dev_value: devValue} = parameter;
    const parameterExists = Boolean(parameter_type);
    const parameterType = parameterExists
      ? parameter_type
      : Object.keys(TopicParameters)[0];

    let value;
    let diffValue;
    let isDevModeOn = false;
    if (parameterExists) {
      diffValue = cloneDeep(parameter.diff_value);
      if (devValue && !areValuesEqual(parameter.value, devValue)) {
        isDevModeOn = true;
        value = cloneDeep(clearParameterValue(devValue));
      } else {
        value = cloneDeep(clearParameterValue(parameter.value));
      }
      if (parameterType === "regex") {
        if (diffValue) {
          const {values: parameterValues} = diffValue;
          const {id: parameterId} = parameter;

          // added and deleted diff values don't participate in stats calculation
          // hence filter them out
          const nonAddedNonDeletedValues = parameterValues.filter(
            pv => !pv.type || pv.type === "updated",
          );
          const stats = getParameterStats(
            parameterId,
            nonAddedNonDeletedValues,
            this.props.usages,
            this.props.isInFixMode,
            isDevModeOn,
          );

          const liveParameterValues = nonAddedNonDeletedValues.map(pv => ({
            ...pv,
            pattern: pv.live_pattern || pv.pattern,
          }));
          const liveStats = getParameterStats(
            parameterId,
            liveParameterValues,
            this.props.usages,
            this.props.isInFixMode,
            false,
          );
          nonAddedNonDeletedValues.forEach(parameterValue => {
            const {totalMatches, applications} = stats[
              parameterValue &&
                parameterValue.pattern &&
                parameterValue.pattern[0]
            ];
            parameterValue.matchesCount = totalMatches;
            parameterValue.applications = applications;

            const {
              totalMatches: live_totalMatches,
              applications: live_applications,
            } = liveStats[
              parameterValue &&
                ((parameterValue.live_pattern &&
                  parameterValue.live_pattern[0]) ||
                  (parameterValue.pattern && parameterValue.pattern[0]))
            ];

            parameterValue.live_matchesCount = live_totalMatches;
            parameterValue.live_applications = live_applications;
          });
        } else {
          const {values: parameterValues} = value;
          const {id: parameterId} = parameter;
          const stats = getParameterStats(
            parameterId,
            parameterValues,
            this.props.usages,
            this.props.isInFixMode,
            isDevModeOn,
          );
          parameterValues.forEach(parameterValue => {
            const {totalMatches, applications} = stats[
              parameterValue &&
                parameterValue.pattern &&
                parameterValue.pattern[0]
            ];
            parameterValue.matchesCount = totalMatches;
            parameterValue.applications = applications;
          });
        }
      }
    } else {
      // eslint-disable-next-line import/namespace
      value = TopicParameters[parameterType].editor.initialise();
      diffValue = null;
    }

    return {
      name: parameter.name,
      description: parameter.description,
      is_star: Boolean(parameter.is_star),
      parameter_type: parameterType,
      showIssues: false,
      value,
      isDevModeOn,
      diffValue,
    };
  }

  render() {
    const {parameter} = this.props;
    const {showIssues} = this.state;
    const isTopicparameterEdited = this.isEditing();
    return (
      <div style={styles.mainContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <SquareIconButton
              onButtonClick={this.triggerIsCollapsed}
              open={this.state.isCollapsed}
            />
            <div
              style={{
                display: "flex",
                marginTop: 6,
                marginLeft: 6,
              }}
            >
              {isTopicparameterEdited && (
                <HotAndStarInfoWidget
                  label="Topicparameter"
                  isStar={parameter.is_star}
                  isHot={parameter.is_hot}
                  updateIsStar={this.updateIsStar}
                />
              )}

              <div style={styles.header}>
                {isTopicparameterEdited
                  ? `#${parameter.id} ${parameter.name}`
                  : "Create a New Topic Parameter"}
              </div>
            </div>
          </div>

          <div>{this.props.statsTable}</div>

          <div style={styles.flex}>
            {isTopicparameterEdited && this.state.isDevModeOn && (
              <>
                <DevChip
                  onLock={this.onShowLockDialog}
                  onRevert={this.onShowRevertDialog}
                  style={styles.devChipTopBar}
                />
                <DevChip
                  onLock={this.onShowLockDialog}
                  onRevert={this.onShowRevertDialog}
                  style={styles.devChipEditor}
                />
              </>
            )}
            <CloseIcon onClick={this.close} style={styles.closeIconButton} />
          </div>
        </div>
        {!this.state.isCollapsed && (
          <div>
            <div style={styles.flex}>
              <div>
                <div>
                  <TextField
                    className="name"
                    onChange={this.updateTextValue}
                    floatingLabelText="Parameter Name"
                    errorText={this.state && this.state.nameError}
                    value={this.state.name || ""}
                    name="name"
                  />
                  {isTopicparameterEdited ? (
                    <IconButton
                      className="delete"
                      onClick={this.onShowDeleteDialog}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                </div>
                <SelectField
                  className="type"
                  floatingLabelText="Parameter Type"
                  errorText={this.state && this.state.typeError}
                  value={this.state.parameter_type}
                  onChange={this.updateType}
                  disabled={isTopicparameterEdited}
                >
                  {_.map(TopicParameters, (renderer, key) => (
                    <MenuItem
                      value={key}
                      key={key}
                      primaryText={key.charAt(0).toUpperCase() + key.slice(1)}
                    />
                  ))}
                </SelectField>
                <div>
                  <TextField
                    className="name"
                    onChange={this.updateTextValue}
                    floatingLabelText="Description"
                    errorText={this.state && this.state.descriptionError}
                    value={this.state.description || ""}
                    name="description"
                  />
                </div>
              </div>
              <TopicParameterLogs
                logs={this.props.logs}
                parameter={
                  !parameter || Object.keys(parameter).length === 0
                    ? {}
                    : {
                        parameter_type: parameter.parameter_type,
                        value: parameter.value.values
                          ? parameter.value.values
                          : parameter.value,
                      }
                }
              />
            </div>
            {this.renderParameterValues()}
            {this.state.valueError && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: 8,
                  color: "red",
                }}
              >
                {this.state.valueError}
              </div>
            )}
            <div style={styles.buttonsContainer}>
              <Button
                color="secondary"
                variant="contained"
                onClick={this.props.onDismiss}
                style={styles.actionButton}
              >
                CANCEL
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={this.onSaveReprocessAll}
                style={styles.actionButton}
                disabled={
                  isTopicparameterEdited &&
                  !this.state.topicparameterChanges &&
                  !this.props.partiallyProcessedParameters[
                    this.props.parameter.id
                  ]
                }
              >
                {isTopicparameterEdited
                  ? "SAVE + REPROCESS ALL"
                  : "Create Topicparameter"}
              </Button>

              {isTopicparameterEdited ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.onSaveReprocessErorrs}
                  style={{
                    ...styles.actionButton,
                    color: "#bdbdbd",
                  }}
                  disabled={!this.state.topicparameterChanges}
                >
                  SAVE + REPROCESS ERRORS
                </Button>
              ) : null}
            </div>
            {parameter.related_issues && parameter.related_issues.length ? (
              <Toggle label="Show Related Issues" onClick={this.toggleIssues} />
            ) : null}
            {parameter.related_issues && parameter.related_issues.length
              ? showIssues && (
                  <ReferencedIssues
                    mainEntity={{
                      id: parameter.id,
                      referenced_issues: parameter.related_issues,
                    }}
                    organisationId={this.props.organisationId}
                    contractTypesById={this.props.contractTypesById}
                    issues={this.props.issues}
                    rootHeaderStyles={{justifyContent: "left"}}
                  />
                )
              : null}
            {this.renderDialog()}
          </div>
        )}
      </div>
    );
  }

  toggleIssues = () => {
    this.setState({showIssues: !this.state.showIssues});
  };

  renderParameterValues() {
    const {parameter_type: type, value, diffValue} = this.state;
    if (type) {
      // eslint-disable-next-line import/namespace
      const Value = TopicParameters[type.toLowerCase()].editor.renderer;
      return (
        <Value
          value={value}
          onChange={this.updateValue}
          diffValue={diffValue}
          selectedRegex={this.props.selectedRegex}
          onRegexSelect={this.props.onRegexSelect}
          isDevModeOn={this.state.isDevModeOn}
        />
      );
    }
    return null;
  }

  renderDialog() {
    const {shownDialog} = this.state;
    if (!shownDialog) {
      return null;
    }
    const handler = this.getDialogHandler(shownDialog);
    const {caption, heading} = getDialogCaption(shownDialog, this.state.name);

    return (
      <Dialog open={true} onClose={this.onHideDialog} fullWidth={true}>
        <DialogTitle>{heading}</DialogTitle>
        <DialogContent>{caption}</DialogContent>
        <DialogActions>
          <Button onClick={this.onHideDialog}>Cancel</Button>
          <Button onClick={handler}>OK</Button>
        </DialogActions>
      </Dialog>
    );
  }

  triggerIsCollapsed = () =>
    this.setState(prevState => ({isCollapsed: !prevState.isCollapsed}));

  isEditing() {
    return Boolean(this.props.parameter.id) && this.props.parameter.id > 0;
  }

  updateTextValue = event => {
    this.setState({
      [event.target.name]: event.target.value,
      hasUnsavedChanges: true,
    });
  };
  updateType = (event, index, type) => {
    this.setState({
      parameter_type: type,
      // eslint-disable-next-line import/namespace
      value: TopicParameters[type].editor.initialise(),
      hasUnsavedChanges: true,
    });
  };
  updateIsStar = () => {
    this.props.onTopicParameterUpdated({
      is_star: !this.props.parameter.is_star,
    });
  };

  resetUpdateValueCount = () => {
    this.setState(() => ({updateValueCount: 0}));
  };

  updateValue = (value, postHandler, diffValue) => {
    if (this.updateValueCountTimeout) {
      clearTimeout(this.updateValueCountTimeout);
      this.updateValueCountTimeout = setTimeout(
        this.resetUpdateValueCount.bind(this),
        600,
      );
    } else {
      this.updateValueCountTimeout = setTimeout(
        this.resetUpdateValueCount.bind(this),
        600,
      );
    }

    this.setState(
      prevState => ({
        ...(value
          ? {
              value,
              updateValueCount: prevState.updateValueCount + 1,
              hasUnsavedChanges: true,
            }
          : {}),
        ...(diffValue ? {diffValue, hasUnsavedChanges: true} : {}),
      }),
      () => {
        if (postHandler) {
          postHandler();
        }
      },
    );
  };

  onSaveReprocessErorrs = () => {
    this.onSave(true);
  };

  onSaveReprocessAll = () => {
    this.onSave(false);
  };

  onSave = shouldUseProblemClausepartsOnly => {
    const {state} = this;
    if (this.validate()) {
      if (this.state.valueError) {
        this.setState(() => ({valueError: ""}));
      }

      if (this.isEditing()) {
        const changes = this.getChanges();
        if (changes) {
          if (this.state.diffValue) {
            changes.diff_value =
              changes.dev_value === null ? null : this.state.diffValue;
          }
          if (changes.diff_value) {
            changes.diff_value = clearParameterValue(changes.diff_value);
          }
          if (changes.diff_value === null && changes.dev_value === null) {
            changes.revert_topicparameter = true;
          }
          this.props.onTopicParameterUpdated(
            changes,
            shouldUseProblemClausepartsOnly,
          );
        } else if (
          this.props.partiallyProcessedParameters[this.props.parameter.id]
        ) {
          this.props.onTopicParameterUpdated({has_important_change: true});
        }
      } else {
        this.props.onTopicParameterAdded(
          state.name,
          state.parameter_type,
          state.value,
          state.description,
        );
      }
      this.setState({hasUnsavedChanges: false});
    }
  };

  getChanges = () => {
    const {state} = this;
    const {parameter} = this.props;
    if (this.isEditing()) {
      const changes = {};
      if (state.name !== parameter.name) {
        changes.name = state.name;
      }
      if (state.parameter_type !== parameter.parameter_type) {
        changes.parameter_type = state.parameter_type;
      }
      if (state.description !== parameter.description) {
        changes.description = state.description;
      }

      if (
        !areValuesEqual(
          state.value,
          parameter.dev_value ? parameter.dev_value : parameter.value,
        )
      ) {
        changes.dev_value = clearParameterValue(state.value);
      }

      if (parameter.dev_value && areValuesEqual(state.value, parameter.value)) {
        changes.dev_value = null;
      }

      if (Object.keys(changes).length > 0) {
        return changes;
      }
    }
    return null;
  };

  updateTopicparameterChanges = topicparameterChanges =>
    this.setState(() => ({
      topicparameterChanges,
    }));

  validate = () => {
    const {state} = this;
    const existingParameterNames = (this.props.parameters || []).map(param =>
      param.name.toLowerCase().trim(),
    );
    const changes = this.getChanges();
    if (!state.name || state.name.length === 0) {
      this.setState({nameError: "You must enter a name."});
      return false;
    } else if (
      (!this.isEditing() || (this.isEditing() && changes.name)) &&
      existingParameterNames.find(
        name => name === state.name.toLowerCase().trim(),
      )
    ) {
      this.setState({valueError: "A parameter with this name already exists"});
      return false;
    } else if (
      // eslint-disable-next-line import/namespace
      !TopicParameters[state.parameter_type].editor.validate(state.value)
    ) {
      this.setState({valueError: "You must enter a valid parameter value."});
      return false;
    }
    return true;
  };

  close = () => {
    setTimeout(() => {
      this.props.onDismiss();
    }, 1);
  };

  lockTopicparameterValue = async () => {
    await this.props.onTopicParameterUpdated(
      {
        value: clearParameterValue(this.state.value),
        diff_value: null,
        lock_topicparameter: true,
      },
      false,
    );
    this.props.refreshTopicData();
  };

  revertTopicparameterValues = async () => {
    await this.props.onTopicParameterUpdated(
      {
        dev_value: null,
        diff_value: null,
        revert_topicparameter: true,
      },
      false,
    );
    this.props.refreshTopicData();
  };

  getDialogHandler = dialogType => {
    let handler;
    switch (dialogType) {
      case "deleteTopicparameter":
        handler = this.props.onTopicParameterDeleted;
        break;
      case "lockTopicparameter":
        handler = this.lockTopicparameterValue;
        break;
      case "revertTopicparameter":
        handler = this.revertTopicparameterValues;
        break;
      default:
        break;
    }
    return () => {
      this.setState(
        () => ({
          shownDialog: null,
        }),
        () => handler(),
      );
    };
  };

  onShowDialog = shownDialog => this.setState(() => ({shownDialog}));
  onHideDialog = () => this.onShowDialog(null);

  onShowDeleteDialog = () => this.onShowDialog("deleteTopicparameter");
  onShowLockDialog = () => this.onShowDialog("lockTopicparameter");
  onShowRevertDialog = () => this.onShowDialog("revertTopicparameter");

  triggerShowIssues = () =>
    this.setState(prevState => ({areIssuesShown: !prevState.areIssuesShown}));
}

export default TopicParameterEditor;
