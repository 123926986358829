import React from "react";

import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import FilterListIcon from "material-ui/svg-icons/content/filter-list";

const styles = {
  filterRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 3,
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
    height: 44,
  },
  filterIcon: {
    margin: "0px 2px 0 7px",
  },
  filterTextFieldContainer: {
    height: "100%",
    width: 200,
    paddingLeft: 5,
    paddingRight: 5,
  },
  filterTextField: {
    fontSize: 14,
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  filterTextFieldFloatingLabel: {
    color: "#2096f3",
    fontSize: 13,
    top: 18,
  },
  filterTextFieldFloatingLabelShrink: {
    lineHeight: 1,
    top: 28,
  },
  filterTextFieldInput: {
    marginTop: 13,
    height: 24,
    color: "#333",
  },
  filterSelectFieldContainer: {
    backgroundColor: "#e6e9ec",
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    boxSizing: "border-box",
    padding: "4px 8px",
    height: 44,
  },
  filterSelectField: {
    width: 105,
    height: "100%",
    fontSize: 13,
  },
  filterSelectFieldFloatingLabel: {
    top: 24,
    lineHeight: 1,
  },
  filterSelectFieldLabel: {
    height: 26,
    lineHeight: "26px",
    paddingRight: 21,
    top: -2,
  },
  filterSelectFieldIcon: {
    padding: 0,
    width: 24,
    height: 24,
    top: -2,
    right: -3,
    fill: "#bcbcbc",
  },
  filterSelectFieldUnderline: {
    border: "none",
  },
  filterSelectFieldMenu: {
    height: 22,
  },
  filterSelectFieldMenuItem: {
    fontSize: 13,
    marginLeft: -13,
  },
};

class ListFilter extends React.Component {
  render() {
    return (
      <div style={styles.filterRoot}>
        <FilterListIcon style={styles.filterIcon} />
        <div style={styles.filterTextFieldContainer}>
          <TextField
            className="list-filter-input"
            value={this.props.filterString}
            style={styles.filterTextField}
            floatingLabelStyle={styles.filterTextFieldFloatingLabel}
            floatingLabelShrinkStyle={styles.filterTextFieldFloatingLabelShrink}
            inputStyle={styles.filterTextFieldInput}
            underlineShow={false}
            floatingLabelFixed={true}
            placeholder="Type here to filter"
            floatingLabelText={this.getFilterLabelText()}
            onChange={this.onFilterStringChange}
          />
        </div>
        <div style={styles.filterSelectFieldContainer}>
          <SelectField
            floatingLabelText="Filter by"
            value={this.props.filterTypeTitle}
            onChange={this.onFilterTypeChange}
            style={styles.filterSelectField}
            floatingLabelStyle={styles.filterSelectFieldFloatingLabel}
            labelStyle={styles.filterSelectFieldLabel}
            iconStyle={styles.filterSelectFieldIcon}
            underlineStyle={styles.filterSelectFieldUnderline}
            menuStyle={styles.filterSelectFieldMenu}
            menuItemStyle={styles.filterSelectFieldMenuItem}
          >
            {this.props.filterTypes.map((filter, index) => (
              <MenuItem
                key={index}
                value={filter.title}
                primaryText={filter.title}
              />
            ))}
          </SelectField>
        </div>
      </div>
    );
  }

  getFilterLabelText = () => {
    const {matchesCount, itemsCount} = this.props;
    if (matchesCount === itemsCount) {
      return `${itemsCount} items in total`;
    }
    return `${matchesCount} match${matchesCount !== 1 ? "es" : ""}`;
  };

  onFilterStringChange = e => this.props.onFilterStringChange(e.target.value);

  onFilterTypeChange = (event, index, value) => {
    this.props.onFilterTypeChange(value);
  };
}

export default ListFilter;
