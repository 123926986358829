import requestor from "requestor";

import BaseRoute from "utils/base_route";
import getRouteIds from "utils/get_route_ids";

import templateModulesFetchAction from "modules/template_modules/actions/modules_fetch";
import topicsFetchAction from "modules/documents/actions/topics_fetch";

import ModulesContainer from "./containers/modules_container";

export default class TopicListRoute extends BaseRoute {
  constructor(store) {
    super("modules", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, ModulesContainer);
  }

  fetchActiveData(props) {
    this.fetchModules(props);
    this.fetchTopics(props);
  }

  fetchModules(props) {
    const {organisationId} = getRouteIds(props.router);
    if (organisationId) {
      this.store.dispatch(
        templateModulesFetchAction(requestor)(organisationId),
      );
    }
  }

  fetchTopics(props) {
    const {organisationId} = getRouteIds(props.router);
    this.store.dispatch(topicsFetchAction(requestor)(organisationId));
  }
}
