import React from "react";
import _ from "underscore";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "material-ui/Snackbar";

import requestor from "requestor";
import basePath from "base_path";
import path from "common/utils/path";

import RefreshButtonIndicator from "./refresh_button_indicator";
import TopicParameter from "./topic_parameter";
import areValuesEqual from "./utils/are_values_equal";
import clearParameterValue from "./utils/clear_parameter_value";
import getDialogCaption from "./utils/get_dialog_caption";

const UPDATE_JOB_STATS_INTERVAL = 3000;

export default class TopicParameters extends React.Component {
  state = {
    isSnackbarShown: false,
    jobStats: {},
    areJobStatsLoading: false,
    shownDialog: null,
  };

  componentDidMount() {
    this.updateJobStats();
    this.updateJobStatsInterval = setInterval(
      this.updateJobStats,
      UPDATE_JOB_STATS_INTERVAL,
    );
  }

  componentWillUnmount() {
    clearInterval(this.updateJobStatsInterval);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.lastTopicparameterBulkClassification &&
      prevProps.lastTopicparameterBulkClassification !==
        this.props.lastTopicparameterBulkClassification
    ) {
      this.setState(() => ({isSnackbarShown: true}));
    }

    const hadJobsInPreviousUpdate = Object.keys(prevState.jobStats).length > 0;
    const hasNoJobsInThisUpdate = Object.keys(this.state.jobStats).length === 0;
    if (hadJobsInPreviousUpdate && hasNoJobsInThisUpdate) {
      this.props.refreshTopicData();
    }
  };

  render() {
    const {parameters, condensedView} = this.props;
    return (
      <div
        style={{
          width: "100%",
          ...(condensedView ? {display: "flex", alignItems: "center"} : {}),
        }}
      >
        {condensedView && this.renderReloadClassificationData()}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <table
            style={{
              borderCollapse: "collapse",
              margin: "0 auto",
              fontSize: "16px",
              color: "#424242",
            }}
          >
            <thead>
              <tr style={{borderBottom: "1px solid lightgray"}}>
                {!condensedView && (
                  <>
                    <th style={{textAlign: "center", width: "2rem"}} />
                    <th style={{textAlign: "center", width: "2rem"}}>Id</th>
                    <th style={{textAlign: "center", width: "12rem"}}>Name</th>
                  </>
                )}
                <th style={{textAlign: "center", width: "4rem"}}>Type</th>
                <th style={{textAlign: "center", width: "3rem"}}>
                  Related Issues
                </th>
                <th style={{textAlign: "center", width: "5rem"}}>Mode</th>
                <th style={{textAlign: "center", width: "4rem"}}>Uses</th>
                <th style={{textAlign: "center", width: "6rem"}}>
                  Unconfirmed
                </th>
                <th style={{textAlign: "center", width: "4rem"}}>Correct</th>
                <th style={{textAlign: "center", width: "4rem"}}>
                  False Match
                </th>
                <th style={{textAlign: "center", width: "4rem"}}>Missed</th>
                <th style={{textAlign: "center", width: "4rem"}}>Incorrect</th>
                <th style={{textAlign: "center", width: "4rem"}}>
                  Partially Processed
                </th>
                <th style={{width: "4rem"}} />
                <th style={{textAlign: "center", width: "6rem"}}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>Jobs</span>{" "}
                    <div title="Refresh outstanding job count">
                      <RefreshButtonIndicator
                        requestPending={this.state.areJobStatsLoading}
                        clickHandler={this.updateJobStatsManual}
                        color="#757575"
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="parameters">
              {parameters.map(parameter =>
                this.renderTopicParameter(parameter, condensedView),
              )}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!condensedView && (
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={this.onCreate}
                style={{display: "block", width: "10rem", margin: "1rem"}}
              >
                ADD PARAMETER
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={() => this.props.processTopicParameters({})}
                style={{display: "block", width: "10rem", margin: "1rem"}}
                // disabled={Object.keys(this.props.partiallyProcessedParameters).length === 0}
              >
                REPROCESS ALL{" "}
                {this.haveIssuesPausedParameters() ? "(UNPAUSE ISSUES)" : ""}
              </Button>
            </>
          )}
          {this.haveIssuesPausedParameters() &&
            !condensedView && (
              <Button
                color="secondary"
                variant="contained"
                onClick={this.processWithIssuesPausedOnly}
                style={{display: "block", width: "12rem", margin: "1rem"}}
              >
                REPROCESS ALL{" "}
                <span style={{whiteSpace: "nowrap"}}>(KEEP ISSUES PAUSED)</span>
              </Button>
            )}
          {!condensedView && this.renderReloadClassificationData()}
        </div>
        {this.renderSnackBar()}
        {this.renderDialog()}
      </div>
    );
  }

  renderReloadClassificationData = () => (
    <div title="Reload parameter classification data">
      <RefreshButtonIndicator
        requestPending={this.props.isTopicDataRequestPending}
        clickHandler={this.props.refreshTopicData}
      />
    </div>
  );

  processWithIssuesPausedOnly = () => {
    this.props.processTopicParameters({
      keep_issues_paused: true,
    });
  };

  renderTopicParameter(parameter, condensedView) {
    const parameterWithShownStats = getParameterWithShownStats(parameter);
    return (
      <TopicParameter
        parameter={parameterWithShownStats}
        key={parameter.id}
        onShowParameterEditor={this.props.onShowParameterEditor}
        jobStats={this.state.jobStats[parameter.id] || 0}
        isRequestPending={this.props.isTopicDataRequestPending}
        onLock={this.onShowLockDialog}
        onRevert={this.onShowRevertDialog}
        condensedView={condensedView}
      />
    );
  }

  renderSnackBar = () => {
    const names = this.props.bulkClassifiedTopicparameters.map(tp => tp.name);
    return (
      <div
        style={{
          fontFamily: "Roboto, sans-serif",
        }}
      >
        <Snackbar
          message={`${names.join(", ")} classification completed`}
          autoHideDuration={15000}
          open={this.state.isSnackbarShown}
          onRequestClose={this.closeSnackbar}
        />
      </div>
    );
  };

  renderDialog() {
    const {shownDialog} = this.state;
    if (!shownDialog) {
      return null;
    }

    const {type: dialogType, topicparameter_id: topicparameterId} = shownDialog;
    const parameter = this.props.parameters.find(
      param => param.id === topicparameterId,
    );

    const handler = this.getDialogHandler(dialogType, parameter);
    const {caption, heading} = getDialogCaption(dialogType, parameter.name);

    return (
      <Dialog open={true} onClose={this.onHideDialog} fullWidth={true}>
        <DialogTitle>{heading}</DialogTitle>
        <DialogContent>{caption}</DialogContent>
        <DialogActions>
          <Button onClick={this.onHideDialog}>Cancel</Button>
          <Button onClick={handler}>OK</Button>
        </DialogActions>
      </Dialog>
    );
  }

  onCreate = () => {
    this.props.onShowParameterEditor(null);
  };

  closeSnackbar = () => this.setState(() => ({isSnackbarShown: false}));

  updateJobStatsManual = () => {
    this.props.getTopicParameterStatsById(null);
    this.updateJobStats();
  };

  updateJobStats = async () => {
    const {organisationId, topic} = this.props;
    await this.setState({areJobStatsLoading: true});
    const jobStats = await requestor
      .get(
        `${basePath}/organisation/${organisationId}/topic/${
          topic.id
        }/jobs_amount`,
        {withCredentials: true},
      )
      .then(response => path(response, ["data"]));
    const stateUpdates = {areJobStatsLoading: false};
    if (!_.isEqual(jobStats, this.state.jobStats)) {
      stateUpdates.jobStats = jobStats;
    }
    await this.setState(stateUpdates);
  };

  haveIssuesPausedParameters = () =>
    Boolean(this.props.parameters.find(param => param.issues_paused));

  onShowLockDialog = parameterId =>
    this.onShowDialog({
      topicparameter_id: parameterId,
      type: "lockTopicparameter",
    });

  onShowRevertDialog = parameterId =>
    this.onShowDialog({
      topicparameter_id: parameterId,
      type: "revertTopicparameter",
    });

  onShowDialog = data => this.setState(() => ({shownDialog: data}));
  onHideDialog = () => this.onShowDialog(null);

  getDialogHandler = (dialogType, parameter) => {
    let handler;

    const onTopicParameterUpdated = this.props.onTopicParameterUpdated(
      parameter.id,
      parameter.last_edited,
    );

    switch (dialogType) {
      case "lockTopicparameter":
        handler = () =>
          onTopicParameterUpdated(
            {
              value: clearParameterValue(parameter.dev_value),
              lock_topicparameter: true,
            },
            false,
            true,
          );
        break;
      case "revertTopicparameter":
        handler = () =>
          onTopicParameterUpdated(
            {
              dev_value: parameter.value,
              revert_topicparameter: true,
              diff_value: null,
            },
            false,
            true,
          );
        break;
      default:
        break;
    }
    const dialogHandler = () => {
      this.setState(
        () => ({
          shownDialog: null,
        }),
        () => handler(),
      );
    };
    return dialogHandler;
  };
}

function getParameterWithShownStats(parameter) {
  const {
    stats,
    stats_dev: statsDev = {},
    uses = "",
    uses_dev: usesDev = "",
    dev_value: devValue,
    value,
  } = parameter;
  let newStats = stats;
  let newUses = uses;

  const isInDevMode = devValue && !areValuesEqual(value, devValue);

  if (isInDevMode) {
    newStats = Object.keys(stats || {}).reduce((result, key) => {
      result[key] = `${statsDev[key]}\n${stats[key]}`;
      return result;
    }, {});
    newUses = `${usesDev}\n${uses}`;
  }

  return {
    ...parameter,
    stats: newStats,
    uses: newUses,
    isInDevMode,
  };
}
