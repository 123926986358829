import React from "react";
import IconButton from "material-ui/IconButton";

import CheckBoxIconChecked from "material-ui/svg-icons/toggle/check-box";
import CheckBoxIconUnchecked from "material-ui/svg-icons/toggle/check-box-outline-blank";
import CheckBoxIconPartiallyChecked from "material-ui/svg-icons/toggle/indeterminate-check-box";

import * as colors from "material-ui/styles/colors";

const styles = {
  checkboxIcon: {
    height: "20px",
    width: "20px",
  },
  checkboxButton: {
    width: "30px",
    height: "24px",
    padding: 0,
    zIndex: 0,
  },
};

class Checkbox extends React.Component {
  render() {
    return (
      <IconButton
        iconStyle={styles.checkboxIcon}
        style={styles.checkboxButton}
        onClick={this.onIconClick}
      >
        {this.getIcon()}
      </IconButton>
    );
  }

  onIconClick = e => {
    e.stopPropagation();
    this.props.onCheck();
  };

  getIcon = () => {
    const {checked, partiallyChecked} = this.props;
    if (partiallyChecked) {
      return <CheckBoxIconPartiallyChecked color={colors.grey600} />;
    } else if (checked) {
      return <CheckBoxIconChecked color={colors.grey600} />;
    }
    return <CheckBoxIconUnchecked color={colors.grey600} />;
  };
}

export default Checkbox;
