import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";

const styles = {
  tableHeadItem: {
    color: "#000",
    fontWeight: "bold",
  },
};

class TopicScores extends PureComponent {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#fdd",
          padding: "1em",
          borderRadius: "0.25em",
          marginBottom: "1em",
        }}
      >
        <div
          className="title"
          style={{
            fontSize: "2em",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Accuracy
        </div>
        <div style={{textAlign: "center", marginTop: "1em"}}>
          <div style={{display: "inline-block"}}>
            {this.renderScoresTable()}
            {this.renderIssuesTable()}
          </div>
        </div>
      </div>
    );
  }

  getClauseTopics = () => {
    const {documentClauses} = this.props;

    return Object.keys(documentClauses)
      .reduce((acc, key) => {
        const documentClauseTopics = documentClauses[key].reduce(
          (topics, clause) => {
            return topics.concat(this.parseNode(clause.nodes));
          },
          [],
        );

        return acc.concat(documentClauseTopics);
      }, [])
      .filter(topic => topic);
  };

  parseNodes = nodes => {
    return nodes.reduce((topics, node) => {
      return topics.concat(this.parseNode(node));
    }, []);
  };

  parseNode = node => {
    let topics = [];
    if (node.clauseNodes) {
      topics = topics.concat(this.parseNodes(node.clauseNodes));
    }

    if (node.topics) {
      topics = topics.concat(node.topics);
    }

    return topics;
  };

  renderScoresTable = () => {
    const topics = this.getClauseTopics();
    const tp = topics.filter(
      topic =>
        !topic.is_deleted &&
        (topic.match_source_id === 2 || topic.match_source_id === 3),
    ).length;
    const fn = topics.filter(
      topic => !topic.is_deleted && topic.match_source_id === 1,
    ).length;
    const fp = topics.filter(
      topic =>
        topic.is_deleted &&
        (topic.match_source_id === 2 || topic.match_source_id === 3),
    ).length;
    const f1 = tp + fn + fp === 0 ? 0 : 100 * 2 * tp / (2 * tp + fn + fp);

    return (
      <Table wrapperStyle={{width: "25em", display: "inline-block"}}>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn
              colSpan="4"
              style={{color: "#000", fontSize: 20, fontWeight: "bold"}}
            >
              Topic
            </TableHeaderColumn>
          </TableRow>
          <TableRow>
            <TableHeaderColumn style={styles.tableHeadItem}>
              F1
            </TableHeaderColumn>
            <TableHeaderColumn style={styles.tableHeadItem}>
              TP
            </TableHeaderColumn>
            <TableHeaderColumn style={styles.tableHeadItem}>
              FP
            </TableHeaderColumn>
            <TableHeaderColumn style={styles.tableHeadItem}>
              FN
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false} showRowHover>
          <TableRow>
            <TableRowColumn>{f1.toFixed(2)}%</TableRowColumn>
            <TableRowColumn>{tp}</TableRowColumn>
            <TableRowColumn>{fp}</TableRowColumn>
            <TableRowColumn>{fn}</TableRowColumn>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  renderIssuesTable = () => {
    const {documentIssues} = this.props;
    const tp = documentIssues.filter(
      issue => issue.is_initial_issue && issue.reason && issue.reason.length,
    ).length;
    const tn = documentIssues.filter(
      issue =>
        !issue.is_initial_issue && (!issue.reason || !issue.reason.length),
    ).length;
    const fp = documentIssues.filter(
      issue =>
        issue.is_initial_issue && (!issue.reason || !issue.reason.length),
    ).length;
    const fn = documentIssues.filter(
      issue => !issue.is_initial_issue && issue.reason && issue.reason.length,
    ).length;
    const score =
      tp + tn + fn + fp === 0 ? 0 : 100 * (tp + tn) / (tp + tn + fn + fp);

    return (
      <Table
        wrapperStyle={{width: "30em", display: "inline-block", marginLeft: 50}}
      >
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn
              colSpan="5"
              style={{color: "#000", fontSize: 20, fontWeight: "bold"}}
            >
              Issues
            </TableHeaderColumn>
          </TableRow>
          <TableRow>
            <TableHeaderColumn style={styles.tableHeadItem}>
              Score
            </TableHeaderColumn>
            <TableHeaderColumn style={styles.tableHeadItem}>
              TP
            </TableHeaderColumn>
            <TableHeaderColumn style={styles.tableHeadItem}>
              TN
            </TableHeaderColumn>
            <TableHeaderColumn style={styles.tableHeadItem}>
              FP
            </TableHeaderColumn>
            <TableHeaderColumn style={styles.tableHeadItem}>
              FN
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false} showRowHover>
          <TableRow>
            <TableRowColumn>{score.toFixed(2)}%</TableRowColumn>
            <TableRowColumn>{tp}</TableRowColumn>
            <TableRowColumn>{tn}</TableRowColumn>
            <TableRowColumn>{fp}</TableRowColumn>
            <TableRowColumn>{fn}</TableRowColumn>
          </TableRow>
        </TableBody>
      </Table>
    );
  };
}

TopicScores.propTypes = {
  documentClauses: PropTypes.object.isRequired,
  documentIssues: PropTypes.array.isRequired,
};

export default TopicScores;
