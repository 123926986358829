import React, {useState, useEffect, useRef} from "react";
import Graph from "./graph";
import getDataFromAnalytics from "./utils/get_data_from_analytics";

export default function AnalyticsComponent({analytics}) {
  const rootRef = useRef(null);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (rootRef.current) {
      setWidth(rootRef.current.clientWidth);
    }
  });
  const {data, tickValues, legendItems} = getDataFromAnalytics(analytics);
  const dataSpeed = data.map(({x, ySpeed, color}) => ({x, y: ySpeed, color}));
  const dataTime = data.map(({x, yTime, color}) => ({x, y: yTime, color}));
  return (
    <div ref={rootRef} style={{width: "100%", height: "100%"}}>
      <h2>Document Classification Speed</h2>
      <Graph
        data={dataSpeed}
        tickValues={tickValues}
        legendItems={legendItems}
        width={width}
        height={600}
        axis={{y: "Classifications per second"}}
      />
      <h2>Document Classification Time</h2>
      <Graph
        data={dataTime}
        tickValues={tickValues}
        legendItems={legendItems}
        width={width}
        height={600}
        axis={{y: "Classification time (secs)"}}
      />
    </div>
  );
}
