import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function initProject(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PROJECT_TASKS_REORDER,
    (organisationId, projectId, data) =>
      requestor
        .put(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}/task_order`,
          data,
          {withCredentials: true},
        )
        .then(response => ({order: data, ...response.data})),
  );
}
