import basePath from "base_path";
import ActionTypes from "../../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

function deleteDocumentsRolesAction(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENTS_ROLES_DELETE,
    (organisationId, projectId) =>
      requestor
        .delete(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}/document/roles`,
          {
            withCredentials: true,
          },
        )
        .then(response => response.data)
        .catch(err => err.data),
  );
}

export default deleteDocumentsRolesAction;
