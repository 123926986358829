import BaseRoute from "utils/base_route";

import PromptDetailContainer from "./containers/prompt_detail";

export default class PromptDetail extends BaseRoute {
  constructor(store) {
    super(":promptId/detail", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, PromptDetailContainer);
  }
}
