import React from "react";
import Checkbox from "../checkbox";

class ToolbarCheckbox extends React.Component {
  render() {
    const {props} = this;
    return (
      <div style={{display: "flex", alignItems: "center", marginRight: "1rem"}}>
        <Checkbox checked={props.checked} onCheck={props.onCheck} />
        <div
          onClick={props.onCheck}
          style={{color: "#424242", cursor: "pointer"}}
        >
          {props.label}
        </div>
      </div>
    );
  }
}

export default ToolbarCheckbox;
