import React from "react";

import TypeSelector from "./type_selector";
import TaskSelector from "./task_selector";
import ValueEditor from "./value_editor";
import TaskValueEditor from "./task_value_editor";

import {DataLocation} from "common/flowmaster/types/data_location";
import {
  WorkflowTask,
  ActionDefinition,
  StringArgument,
} from "common/flowmaster/types/task_config";
import {
  Prompt,
  WorkflowContext,
  WorkflowInputs,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

interface StringArgEditorProps {
  argValue: StringArgument;
  priorTasks: WorkflowTask[];
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  onUpdateItem: (value: StringArgument) => void;
  organisationId: OrganisationId;
  useListItem?: boolean;
}

export const StringArgEditor: React.FC<StringArgEditorProps> = ({
  argValue,
  priorTasks,
  onUpdateItem,
  context,
  inputs,
  prompts,
  actionDefinitions,
  organisationId,
  useListItem = false,
}) => {
  const stringArg = argValue?.value;
  const sourceType = stringArg?.source_type;

  return (
    <div style={{display: "flex", gap: "1em", width: "100%"}}>
      <TypeSelector
        type={sourceType}
        useListItem={useListItem}
        onSetType={type => {
          onUpdateItem({
            ...argValue,
            value: {...stringArg, source_type: type} as DataLocation<string>,
          });
        }}
      />
      {sourceType === "task" ? (
        <TaskSelector
          task={stringArg.task}
          tasks={priorTasks}
          onSetTask={task =>
            onUpdateItem({...argValue, value: {...stringArg, task}})
          }
        />
      ) : null}
      {sourceType !== "none" && (
        <ValueEditor
          argValue={argValue}
          onUpdateItem={(value: DataLocation<string>) =>
            onUpdateItem({...argValue, value})
          }
          priorTasks={priorTasks}
          actionDefinitions={actionDefinitions}
          context={context}
          inputs={inputs}
          prompts={prompts}
          organisationId={organisationId}
        />
      )}
      {sourceType === "task" ? (
        <TaskValueEditor
          location={stringArg}
          onUpdateItem={value =>
            onUpdateItem({...argValue, value: {...stringArg, value}})
          }
        />
      ) : null}
    </div>
  );
};

const ActionArg = {
  Component: StringArgEditor,
  default: {value: {source_type: "none"}},
};

export default ActionArg;
