function addScoresToClassifiers(usages, classifiers, topicContractTypeIds) {
  return classifiers.map(configuration => {
    const scoresByContractTypeId = usages.reduce(
      (state, use) => {
        const score = (use.classifier_scores || []).find(
          _score =>
            _score &&
            _score.configuration_id === configuration.configuration_id,
        );
        if (!state[use.contract_type_id]) {
          state[use.contract_type_id] = {
            tp: 0,
            fn: 0,
            fp: 0,
            tn: 0,
            pnc: 0,
            nnc: 0,
            tbc: 0,
          };
        }

        if (score) {
          if (score.is_classified) {
            if (use.has_topic && use.is_confirmed) {
              state[use.contract_type_id].tp += 1;
              state["_all"].tp += 1;
            } else if (use.has_topic && !use.is_confirmed) {
              state[use.contract_type_id].pnc += 1;
              state["_all"].pnc += 1;
            } else {
              state[use.contract_type_id].fp += 1;
              state["_all"].fp += 1;
            }
          } else {
            if (use.has_topic && use.is_confirmed) {
              state[use.contract_type_id].fn += 1;
              state["_all"].fn += 1;
            } else if (use.has_topic && !use.is_confirmed) {
              state[use.contract_type_id].nnc += 1;
              state["_all"].nnc += 1;
            } else {
              state[use.contract_type_id].tn += 1;
              state["_all"].tn += 1;
            }
          }
        } else {
          state[use.contract_type_id].tbc += 1;
          state["_all"].tbc += 1;
        }
        return state;
      },
      {_all: {tp: 0, fn: 0, fp: 0, tn: 0, pnc: 0, nnc: 0, tbc: 0}},
    );
    topicContractTypeIds.forEach(ctId => {
      if (!scoresByContractTypeId[ctId]) {
        scoresByContractTypeId[ctId] = {
          tp: 0,
          fn: 0,
          fp: 0,
          tn: 0,
          pnc: 0,
          nnc: 0,
          tbc: 0,
        };
      }
    });
    return {
      ...configuration,
      scoresByContractTypeId,
    };
  });
}

export default addScoresToClassifiers;
