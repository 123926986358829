import requestor from "requestor";

import BaseRoute from "utils/base_route";
import SearcherContainer from "./containers/searcher";

import embeddingTopicsFetchAction from "modules/searcher/actions/embedding_topics_fetch";

export default class extends BaseRoute {
  constructor(store) {
    super("searcher", store);
  }

  getComponents(location, cb) {
    cb(null, SearcherContainer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchEmbeddingTopics(state);
  }

  fetchEmbeddingTopics(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(embeddingTopicsFetchAction(requestor)(organisationId));
  }

  clearActiveData(/* state */) {
    // this.clearContractTypes(state);
    // this.clearSearch();
  }
}
