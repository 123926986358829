import basePath from "base_path";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes, {UsergroupActionTypes} from "../constants/action_types";
import {WithUserCount, Usergroup} from "common/types/usergroup";

export type FetchUsergroupsAction = {
  type: UsergroupActionTypes["USERGROUPS_FETCH"]["SUCCESS"];
  payload: Array<WithUserCount<Usergroup>>;
};

export default createReduxPromiseAction(
  ActionTypes.USERGROUPS_FETCH,
  (organisationId: number) =>
    requestor
      .get(`${basePath}/organisation/${organisationId}/usergroups`, {
        withCredentials: true,
      })
      .then(response => response.data),
);
