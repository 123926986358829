import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import {validate} from "email-validator";

import TextField from "material-ui/TextField";
import {Card} from "material-ui/Card";
import RaisedButton from "material-ui/RaisedButton";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextFieldV2 from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core/styles";

import UserDetailToolbar from "./user_detail_toolbar";
import Permissioner from "utils/permissioner";

const styles = {
  cardStyles: {
    width: "30em",
    padding: "1em",
    margin: "0 auto",
    paddingBottom: "1em",
  },
  buttonStyles: {
    margin: "1em",
  },
  emailTemplateCardButtons: {
    display: "flex",
    justifyContent: "center",
  },
  errorStyles: {
    fontSize: "0.9em",
    color: "rgba(0, 0, 0, 0.4)",
    textAlign: "center",
    margin: "0px",
  },
};
const muiStyles = {
  underline: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgb(33,150,243)",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#ddd",
    },
    "& .MuiInput-underline:hover": {
      borderBottomColor: "#ddd",
    },
  },
  popupIndicator: {
    color: "#ddd", // Change the color to red
    marginRight: "10px", // Adjust the margin to move it further in
  },
};

class UserDetail extends React.Component {
  // getDefaultProjectName = () => {
  //   const {projects, params, default_project_id} = this.props;
  //   if (params.userId === "new") {
  //     return projects.find(project => project.is_default).name;
  //   }
  //   const foundProject = projects.find(
  //     project => project.id === default_project_id,
  //   );
  //   return foundProject ? foundProject.name : projects[0].name;
  // };

  state = {
    username: this.props.username,
    first_name: this.props.first_name,
    last_name: this.props.last_name,
    default_project_id: this.props.default_project_id,
    permission_group_id:
      this.props.params.userId === "new"
        ? this.props.defaultPermissionGroupId
        : this.props.permission_group_id,
    isRequestPending: false,
    is_locked: this.props.is_locked,
    error: "",
  };

  componentDidMount() {
    window.addEventListener("mousewheel", this.mouseWheelStopper, true);
  }

  componentWillUnmount() {
    window.removeEventListener("mousewheel", this.mouseWheelStopper, true);
  }

  mouseWheelStopper(event) {
    event.stopPropagation();
  }

  render() {
    const {
      onOrganisationUserUpdate,
      onOrganisationUserAdd,
      projects,
    } = this.props;
    const isUserNew = this.props.params.userId === "new";

    const areButtonsDisabled =
      this.state.isRequestPending ||
      (this.props.username === this.state.username &&
        this.props.first_name === this.state.first_name &&
        this.props.last_name === this.state.last_name &&
        this.props.is_locked === this.state.is_locked &&
        this.props.default_project_id === this.state.default_project_id &&
        this.props.permission_group_id === this.state.permission_group_id);

    return (
      <>
        <UserDetailToolbar isUserNew={isUserNew} />
        <Card style={styles.cardStyles}>
          <TextField
            floatingLabelText="Email"
            fullWidth={true}
            onChange={this.onFieldChange("username")}
            value={this.state.username}
          />

          <TextField
            floatingLabelText="First Name"
            fullWidth={true}
            onChange={this.onFieldChange("first_name")}
            value={this.state.first_name}
          />

          <TextField
            floatingLabelText="Last Name"
            fullWidth={true}
            onChange={this.onFieldChange("last_name")}
            value={this.state.last_name}
          />

          <Autocomplete
            options={projects}
            value={projects.find(
              project => project.id === this.state.default_project_id,
            )}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextFieldV2
                {...params}
                className={this.props.classes.underline}
                label="Default Project"
              />
            )}
            classes={{
              popupIndicator: this.props.classes.popupIndicator,
            }}
            onChange={(event, value) => this.onDefaultProjectChange(value.id)}
            getOptionSelected={option => option.id}
            disableClearable
          />

          {this.hasPermission("can-update-user-permission-group") && (
            <SelectField
              maxHeight={180}
              fullWidth={true}
              value={this.state.permission_group_id}
              floatingLabelText="Permission Group"
              onChange={(event, index, value) =>
                this.onPermissionGroupChange(value)
              }
            >
              {(
                Permissioner.getPermissionGroups(
                  this.props.user,
                  this.props.permissionGroups,
                ) ?? []
              ).map(group => (
                <MenuItem
                  key={group.id}
                  value={group.id}
                  primaryText={group.name}
                />
              ))}
            </SelectField>
          )}

          <div
            className="organisation-user-card-buttons"
            style={styles.emailTemplateCardButtons}
          >
            {!isUserNew &&
              this.hasPermission("unlock-user") &&
              this.props.is_locked && (
                <RaisedButton
                  label="Unlock user"
                  style={styles.buttonStyles}
                  onClick={this.props.onUnlockUser}
                />
              )}
            <RaisedButton
              label={isUserNew ? "Create User" : "Save"}
              primary={true}
              style={styles.buttonStyles}
              disabled={areButtonsDisabled}
              onClick={
                isUserNew
                  ? this.onButtonClick(onOrganisationUserAdd)
                  : this.onButtonClick(onOrganisationUserUpdate)
              }
            />
            <RaisedButton
              label="Cancel"
              secondary={true}
              style={styles.buttonStyles}
              onClick={this.onCancelClick}
            />
          </div>
          {this.state.error && (
            <p style={styles.errorStyles}>{this.state.error}</p>
          )}
        </Card>
      </>
    );
  }
  onDefaultProjectChange = projectId => {
    this.setState(() => ({default_project_id: projectId}));
  };

  onPermissionGroupChange = value => {
    this.setState(() => ({permission_group_id: value}));
  };

  onCheckboxChange = _.memoize(fieldName => (e, value) => {
    this.setState(() => ({[fieldName]: value}));
  });

  onFieldChange = _.memoize(fieldName => e => {
    let value = e.target.value;
    if (fieldName === "username") {
      value = value.trim();
    }
    this.setState(() => ({[fieldName]: value}));
  });

  onCancelClick = () => {
    this.props.onRedirectBack();
  };

  onButtonClick = confirmButtonHandler => () => {
    if (!validate(this.state.username)) {
      this.setState(() => ({
        error: "Please provide a valid email address",
      }));
      return;
    }

    const notChangedItems = [];
    _.each(this.state, (value, key) => {
      if (value === this.props[key]) {
        notChangedItems.push(key);
      }
    });

    const data = _.omit(this.state, [
      ...notChangedItems,
      "isRequestPending",
      "error",
      "default_project_name",
      "permission_group_id",
    ]);

    data.permission_group_id =
      !this.hasPermission("can-set-role") &&
      !this.props.user.is_admin &&
      this.props.organisation.default_permission_group_id
        ? this.props.organisation.default_permission_group_id
        : this.state.permission_group_id;

    const {organisationId, userId} = this.props.params;
    this.setState(
      () => ({isRequestPending: true, error: ""}),
      async () => {
        const result = await confirmButtonHandler(organisationId, userId, data);
        if (result && result.value && result.value.id) {
          this.setState(() => ({isRequestPending: false}));
          this.props.onRedirectBack();
        } else {
          const isUserNew = this.props.params.userId === "new";
          this.setState(() => ({
            isRequestPending: false,
            error: "Saving failed. Please try again later",
            username: isUserNew ? this.state.username : this.props.username,
            first_name: isUserNew
              ? this.state.first_name
              : this.props.first_name,
            last_name: isUserNew ? this.state.last_name : this.props.last_name,
          }));
        }
      },
    );
  };

  hasPermission = permission => {
    return new Permissioner(this.props.user).hasPermission(permission);
  };
}

UserDetail.propTypes = {
  params: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  onRedirectBack: PropTypes.func.isRequired,
  onOrganisationUserUpdate: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  default_project_id: PropTypes.number,
  permission_group_id: PropTypes.number,
  user: PropTypes.object.isRequired,
  permissionGroups: PropTypes.array.isRequired,
  defaultPermissionGroupId: PropTypes.number.isRequired,
};

export default withStyles(muiStyles)(UserDetail);
