import {getIssueManualCorrections} from "utils/manual_corrections_utils";
import globalOverrideKey from "common/utils/issues/global_override_key";

function isIssueAlert(issue) {
  return issue.icon_color === "#ff8800" || issue.icon_color === "#ff0000";
}

export default function getDocumentRagScore(issues, issueset) {
  return issues.reduce((sum, issue) => {
    const manualCorrections = getIssueManualCorrections(
      issue,
      issueset,
      globalOverrideKey,
    );
    const {alert_color: alertColor} = manualCorrections;
    const isCorrectedToAlert = alertColor === "red" || alertColor === "amber";
    const extra =
      isIssueAlert(issue) || isCorrectedToAlert
        ? parseInt(issue.rag_score, 10)
        : 0;
    return sum + extra;
  }, 0);
}
