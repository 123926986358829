import React, {Component} from "react";
import {get} from "lodash";
import _ from "underscore";

import TextField from "material-ui/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowRightIcon from "@material-ui/icons/ChevronRight";
import ArrowDownIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import * as colors from "@material-ui/core/colors";

import generateTemplate from "utils/generate_template_string";
import sessionStorage from "utils/session_storage";
import isBadRegex from "utils/bad_regexes";
import DeleteModal from "./delete_modal";
import ClassifierOptionStatusBar from "./classifier_option_status_bar";
import Tooltip from "@material-ui/core/Tooltip";
import ReplayIcon from "@material-ui/icons/Replay";
import ClassifierOptionTextField from "./classifier_option_text_field";

const styles = {
  expandContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginRight: 20,
  },
  headerName: {
    marginBottom: 5,
    userSelect: "none",
  },
  regexVisibilityIcon: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    height: "20px",
    width: "20px",
    cursor: "pointer",
    position: "relative",
    top: "2px",
    transition: "none",
  },
  expandIcon: {
    marginTop: "14px",
  },
  classifierOpLiContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  liNumber: {
    width: "1.5rem",
    lineHeight: "32px",
    marginRight: "0.5rem",
    textAlign: "right",
    color: colors.grey[500],
  },
  classifierOpLiHeader: {
    position: "absolute",
    right: 0,
    top: "28px",
    fontSize: "14px",
    display: "flex",
  },
};

class TextFieldClassifierOption extends Component {
  constructor(props) {
    super(props);
    const item = sessionStorage.getItem(`${props.option.name}IsExpanded`);
    const isExpanded =
      item !== undefined && item !== null ? item.toString() === "true" : true;
    this.state = {
      isExpanded,
      invalidRegexes: {},
      deleteDialogIsOpen: false,
      deleteModalData: {
        id: null,
        value: null,
      },
    };
  }

  render() {
    const {isExpanded} = this.state;
    const {
      option,
      classifier,
      isInFixMode,
      selectedRegex,
      unselectRegex,
      templateModules,
      onRegexItemHover,
      hoveredRegexIndex,
      onRegexItemHoverEnd,
    } = this.props;
    const {name} = option;
    const modulesValues = templateModules.reduce((acc, module) => {
      acc[module.key] = module.value[0];
      return acc;
    }, {});
    const stats = get(classifier, ["stats", option.name], {});
    const nameId = this.getNameId(name);

    const objValues = this.setObjArray(
      classifier.value[name],
      classifier.value[nameId],
    );
    const objLastValues =
      classifier?.last_value?.[name] && classifier?.last_value?.[nameId]
        ? this.setObjArray(
            classifier.last_value[name],
            classifier.last_value[nameId],
          )
        : [];
    const classifierValues =
      this.setClassifierValues(
        objValues,
        objLastValues,
        classifier.value[nameId],
      ) || [];

    return (
      <div key={name} style={{position: "relative"}}>
        <div onClick={this.onExpandSwitch} style={styles.expandContainer}>
          {this.changeExpandedIcon(isExpanded)}
          <h3 style={styles.headerName}>{name}</h3>
        </div>
        {isExpanded && (
          <div style={styles.classifierOpLiHeader}>
            <div style={{marginRight: "12px"}}>All</div>
            <div>Conf&nbsp;</div>
            <div>Unconf&nbsp;</div>
            <div title="Regex matches and topic not present">FP&nbsp;</div>
            <div
              style={{marginRight: "24px"}}
              title="Regex matches and topic not present but ruled out by blacklist"
            >
              FM&nbsp;
            </div>
          </div>
        )}
        {isExpanded &&
          classifierValues.map((dataValue, regexIndex) => {
            const regexValue = dataValue.value;
            const regexId = dataValue.id;
            const errorText = this.validateRegexField(regexValue, regexIndex);
            const templatedText = generateTemplate(regexValue, modulesValues);
            const counters = isInFixMode ? undefined : stats[regexValue];
            const usesModules = templatedText !== regexValue;
            const isSelected = selectedRegex === regexValue;
            const isHovered = hoveredRegexIndex === `${name}-${regexIndex}`;
            const getStatsHandler = () =>
              this.props.refreshTopicRegexClassifierStats(regexValue, name);
            const isLoadingStats = counters?.isLoading ?? false;
            const hideStats = counters === undefined || isLoadingStats;
            const notDeleted = classifier.value[nameId].includes(regexId);
            const notNew = classifier?.last_value?.[nameId]
              ? classifier.last_value[nameId].includes(regexId)
              : true;
            const isChanged = objLastValues.find(
              item => item.id === regexId && item.value !== regexValue,
            );

            return (
              <div
                key={`classifier-tf-${regexIndex}`}
                style={styles.classifierOpLiContainer}
                onMouseEnter={onRegexItemHover(`${name}-${regexIndex}`)}
                onMouseLeave={onRegexItemHoverEnd}
              >
                <div style={styles.liNumber}>{regexIndex + 1}.</div>
                <ClassifierOptionTextField
                  classifier={classifier}
                  dataValue={dataValue}
                  name={name}
                  regexIndex={regexIndex}
                  regexValue={regexValue}
                  errorText={errorText}
                  notDeleted={notDeleted}
                  notNew={notNew}
                  isChanged={isChanged}
                  selectedRegex={selectedRegex}
                  isSelected={isSelected}
                  usesModules={usesModules}
                  templatedText={templatedText}
                  isHovered={isHovered}
                  onRegexBlur={this.onRegexBlur}
                  focusRegexField={this.focusRegexField}
                />
                {(!notDeleted || isChanged) &&
                classifier.value[name].length > 1 ? (
                  <Tooltip placement="top" title="Revert changes">
                    <ReplayIcon
                      style={{
                        ...styles.regexVisibilityIcon,
                        color: colors.grey[700],
                      }}
                      onClick={() =>
                        this.revertChanges(
                          classifier,
                          name,
                          dataValue,
                          isChanged,
                          !notDeleted,
                        )
                      }
                    />
                  </Tooltip>
                ) : null}
                <VisibilityIcon
                  style={{
                    ...styles.regexVisibilityIcon,
                    color: isSelected
                      ? colors.grey[700]
                      : isHovered ? colors.grey[400] : "inherit",
                    visibility: isSelected || isHovered ? "visible" : "hidden",
                  }}
                  onClick={
                    isSelected
                      ? unselectRegex
                      : isHovered ? this.onIsVisibleOn(regexValue) : () => null
                  }
                />
                <IconButton
                  size="small"
                  disabled={Boolean(this.props.classifierRefreshPending)}
                  onClick={this.processFpsRegex(regexValue)}
                >
                  <RefreshIcon
                    style={{
                      ...styles.regexVisibilityIcon,
                      color: isSelected
                        ? colors.grey[700]
                        : isHovered ? colors.grey[400] : "inherit",
                      visibility:
                        isSelected || isHovered ? "visible" : "hidden",
                    }}
                  />
                </IconButton>
                {classifier.value[name].length > 1 &&
                  (!notDeleted ? (
                    <Tooltip placement="top" title="Disabled">
                      <DeleteIcon
                        style={{
                          ...styles.regexVisibilityIcon,
                          color: colors.grey[400],
                          cursor: "default",
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" title="Delete changes">
                      <DeleteIcon
                        onClick={() => this.handleDeleteModalOpen(dataValue)}
                        style={{
                          ...styles.regexVisibilityIcon,
                          color: colors.grey[700],
                        }}
                      />
                    </Tooltip>
                  ))}
                <ClassifierOptionStatusBar
                  name={name}
                  hideStats={hideStats}
                  counters={counters}
                  isLoadingStats={isLoadingStats}
                  isHovered={isHovered}
                  getStatsHandler={getStatsHandler}
                />
              </div>
            );
          })}
        {isExpanded && (
          <TextField
            name={name}
            defaultValue=""
            onBlur={this.props.addNewRowOnTextClassifiedSettings(
              classifier,
              name,
            )}
            style={{
              width: "100%",
              height: 32,
            }}
            underlineStyle={{
              bottom: 2,
            }}
            multiLine={false}
          />
        )}
        <DeleteModal
          isOpen={this.state.deleteDialogIsOpen}
          text={this.state.deleteModalData.value}
          handleClose={this.handleClose}
          handleDelete={this.props.removeTextClassifierSettingsRow(
            classifier,
            name,
            classifier.value[this.getNameId(name)].indexOf(
              this.state.deleteModalData.id,
            ),
          )}
        />
      </div>
    );
  }

  setClassifierValues = (objValues, objLastValues, valuesIds) => {
    const obArr = [...objValues];
    let count = 0;
    objLastValues.forEach((item, index) => {
      const isInclude = Boolean(valuesIds.includes(item?.id));
      if (objValues[index] && !isInclude) {
        obArr.splice(index + count, 0, item);
        count++;
      } else if (!objValues[index] && !isInclude) {
        obArr.push(item);
      }
    });
    return obArr;
  };

  setObjArray = (values, ids) => {
    return _.zip(values, ids).map(item => ({
      value: item[0],
      id: item[1],
    }));
  };

  changeExpandedIcon = isExpanded => {
    return isExpanded ? (
      <ArrowDownIcon style={styles.expandIcon} />
    ) : (
      <ArrowRightIcon style={styles.expandIcon} />
    );
  };

  getNameId = name =>
    name === "whitelist"
      ? "whitelist_ids"
      : name === "blacklist" ? "blacklist_ids" : false;

  isValidRegex = regex => {
    try {
      new RegExp(regex);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("Regex is invalid - Regex", regex, err);
      return false;
    }
    const badRegex = isBadRegex(regex);
    if (badRegex) {
      // eslint-disable-next-line no-console
      console.log(
        "Regex is invalid because it had a potential problem in it - Regex:",
        regex,
        "Problem: matches",
        badRegex,
      );
      return false;
    }
    return true;
  };

  handleDeleteModalOpen = data => {
    this.setState(() => ({
      deleteDialogIsOpen: true,
      deleteModalData: {...data},
    }));
  };

  handleClose = () => {
    this.setState(() => ({deleteDialogIsOpen: false}));
  };

  validateRegexField = (regexValue, regexIndex) => {
    if (
      !this.isValidRegex(regexValue) ||
      this.state.invalidRegexes[regexIndex]
    ) {
      return "regex is invalid";
    }
    return "";
  };

  onIsVisibleOn = regex => () => this.props.selectRegex(regex);
  processFpsRegex = regex => () => this.props.processFpsRegex(regex);

  moveCaretAtEnd = e => {
    const tempValue = e.target.value;
    e.target.value = "";
    e.target.value = tempValue;
  };

  focusRegexField = e => {
    this.moveCaretAtEnd(e);
  };

  revertChanges = (classifier, parameterName, data, isChanged, isDeleted) => {
    const regexIndex = classifier.value[this.getNameId(parameterName)].indexOf(
      data.id,
    );
    if (isDeleted && regexIndex === -1) {
      this.addDeletedValue(
        classifier,
        parameterName,
        classifier.last_value[this.getNameId(parameterName)].indexOf(data.id),
        data,
      );
      return;
    }
    if (isChanged && regexIndex) {
      this.updateTextRow(
        classifier,
        parameterName,
        regexIndex,
        isChanged.value,
      );
    }
  };

  onRegexBlur = meta => e => {
    const {value} = e.target;
    const {classifier, name: parameterName, dataValue: data, isNew} = meta;
    const regexIndex = classifier.value[this.getNameId(parameterName)].indexOf(
      data.id,
    );
    if (!isNew && value.length === 0) {
      this.removeTextRowIfEmpty(classifier, parameterName, regexIndex);
      return;
    }
    if (isNew && value.length === 0) {
      this.removeTextRowIfEmpty(classifier, parameterName, regexIndex);
    } else {
      const oldValue = classifier.value[parameterName][regexIndex];
      const isInvalid = !this.isValidRegex(value);
      this.setState({
        invalidRegexes: {
          ...this.state.invalidRegexes,
          [regexIndex.toString()]: isInvalid,
        },
      });
      if (isInvalid || oldValue === value) {
        return;
      }
      this.updateTextRow(classifier, parameterName, regexIndex, value);
      // if we have selected regex (eye icon on) and we change it's value
      // then we also need to update this selected regex to
      // reflect changes in value
      if (this.props.selectedRegex && this.props.selectedRegex === oldValue) {
        this.props.selectRegex(value);
      }
    }
  };
  addDeletedValue = (classifier, parameterName, index, data) => {
    this.props.onRegexChange();
    this.props.updateClassifierSettings(
      classifier,
      parameterName,
      this.updateClassifiersValues(
        classifier,
        parameterName,
        index,
        data.value,
      ),
      "return",
      index,
      this.updateClassifiersValues(
        classifier,
        this.getNameId(parameterName),
        index,
        data.id,
      ),
    );
  };
  updateTextRow = (classifier, parameterName, regexIndex, value) => {
    this.props.onRegexChange();
    this.props.updateClassifierSettings(
      classifier,
      parameterName,
      classifier.value[parameterName].map(
        (regexValue, index) => (regexIndex === index ? value : regexValue),
      ),
    );
  };
  removeTextRowIfEmpty = (classifier, parameterName, regexIndex) => {
    this.props.updateClassifierSettings(
      classifier,
      parameterName,
      classifier.value[parameterName].filter(
        (regexValue, index) => regexIndex !== index,
      ),
      "remove",
      regexIndex,
    );
  };
  updateClassifiersValues = (classifier, parameterName, index, data) => {
    const updated = [...classifier.value[parameterName]];
    if (updated[index]) {
      updated.splice(index, 0, data);
    } else {
      updated.push(data);
    }
    return updated;
  };
  onExpandSwitch = () => {
    const newValue = !this.state.isExpanded;
    sessionStorage.setItem(`${this.props.option.name}IsExpanded`, newValue);
    this.setState(() => ({
      isExpanded: newValue,
      invalidRegexes: {},
    }));
  };
}

export default TextFieldClassifierOption;
