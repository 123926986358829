import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function uploadFormTemplate(requestor) {
  return createReduxPromiseAction(
    ActionTypes.FORM_TEMPLATE_UPLOAD,
    (organisationId, projectId, file, connectionId, templateDocumentId) =>
      requestor
        .postFile(
          `${basePath}/organisation/${organisationId}/project/${projectId}` +
            "/form_template/upload",
          file,
          {
            connection_id: connectionId,
            template_document_id: templateDocumentId,
          },
        )
        .then(response => response.body),
  );
}
