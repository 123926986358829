import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function fetchContractTypesForClients(requestor) {
  return createReduxPromiseAction(
    ActionTypes.CONTRACT_TYPES_FOR_CLIENTS_FETCH,
    organisationId =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}/clients/contract_type`,
          {
            withCredentials: true,
          },
        )
        .then(response => response.data),
  );
}
