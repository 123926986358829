import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {EnhancedLlmRun, LlmRunId} from "common/flowmaster/types/llm_run";

export type GetLlmRunAction = {
  type: WorkflowsActionTypes["LLM_RUN_FETCH"]["SUCCESS"];
  payload: EnhancedLlmRun;
};

export default function getLlmRuns(requestor) {
  return createReduxPromiseAction(
    ActionTypes.LLM_RUN_FETCH,
    (organisationId: OrganisationId, llmRunId: LlmRunId) => {
      return requestor
        .get(
          `${basePath}/organisation/${organisationId}/llm_run${
            llmRunId.toString().match(/^\d+$/) ? "" : "_id"
          }/${llmRunId}`,
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
