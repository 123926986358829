import React, {FC, useState, CSSProperties} from "react";
import TextField from "@material-ui/core/TextField";

interface ContainedTextFieldProps {
  initialValue: string;
  label: string;
  onUpdate: (value: string) => void;
  errorMessage?: string;
  multiline?: boolean;
  type?: "number" | "text";
  variant?: "filled" | "outlined" | "standard";
  fullWidth?: boolean;
  inputPropsStyle?: CSSProperties;
}

const ContainedTextField: FC<ContainedTextFieldProps> = ({
  initialValue,
  label,
  onUpdate,
  errorMessage,
  multiline = false,
  type = "text",
  variant = "standard",
  fullWidth = false,
  inputPropsStyle = {},
}) => {
  const [value, setValue] = useState<string>(initialValue);
  return (
    <TextField
      type={type}
      label={label}
      value={value}
      variant={variant}
      fullWidth={fullWidth}
      onChange={event => setValue(event.target.value)}
      onBlur={() => onUpdate(value)}
      helperText={errorMessage}
      error={Boolean(errorMessage)}
      style={{width: "100%"}}
      InputProps={{
        style: inputPropsStyle,
      }}
      multiline={multiline}
    />
  );
};

export default ContainedTextField;
