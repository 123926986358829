import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {WorkflowId} from "common/flowmaster/types/workflow";

export type WorkflowInputAddAction = {
  type: WorkflowsActionTypes["WORKFLOW_INPUT_ADD"]["SUCCESS"];
  payload: {name: string};
};

export default function addWorkflowInput(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOW_INPUT_ADD,
    (organisationId: OrganisationId, workflowId: WorkflowId, name: string) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/workflow/${workflowId}/input`,
          {name},
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
