function getListFilterRegexes(filterText) {
  if (!filterText) {
    return null;
  }
  const isExactSearch = /^".*"$/.test(filterText);
  const searchItemsArray = isExactSearch
    ? [filterText.slice(1, -1).toLowerCase()]
    : filterText.split(/\s/);
  return searchItemsArray.map(text => new RegExp(cleanText(text), "i"));
}

function cleanText(text) {
  return text.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

export default getListFilterRegexes;
