function getDialogCaption(dialogType, name) {
  let heading = "";
  let caption = "";
  switch (dialogType) {
    case "deleteTopicparameter":
      caption = `Are you sure you want to delete parameter ${name}?`;
      heading = "Confirm topicparameter deletion";
      break;
    case "lockTopicparameter":
      caption = `Are you sure you want to LOCK parameter ${name}?`;
      heading = "Confirm topicparameter lock";
      break;
    case "revertTopicparameter":
      caption = `Are you sure you want to REVERT parameter ${name}?`;
      heading = "Confirm topicparameter revert";
      break;
    default:
      break;
  }
  return {heading, caption};
}

export default getDialogCaption;
