import _ from "underscore";
import React from "react";
import {Link} from "react-router";

import * as colors from "material-ui/styles/colors";

const styles = {
  static: {
    color: colors.black,
    textDecoration: "none",
    cursor: "default",
  },
  hover: {
    color: colors.blue500,
    textDecoration: "underline",
    cursor: "pointer",
  },
};

class RouterHoverLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isHovered !== this.props.isHovered) {
      this.setState(this.getInitialState());
    }
  }

  getInitialState = () => ({isHovered: Boolean(this.props.isHovered)});

  onEnter = () => this.setState({isHovered: true});
  onLeave = () => this.setState({isHovered: false});

  render() {
    const staticStyle = {...styles.static, ...this.props.staticStyle};
    const hoverStyle = {...styles.hover, ...this.props.hoverStyle};
    return (
      <Link
        {..._.omit(this.props, ["isHovered", "staticStyle", "hoverStyle"])}
        onMouseEnter={this.onEnter}
        onMouseLeave={this.onLeave}
        style={{
          ...this.props.style,
          ...(this.state.isHovered ? hoverStyle : staticStyle),
        }}
      >
        {this.props.children}
      </Link>
    );
  }
}

export default RouterHoverLink;
