function getUsageMatches(text, matches, startOffset) {
  return matches.reduce(
    (state, match, index) => {
      const matchEnd = match.index + match[0].length - startOffset;
      const offsetMatchIndex = match.index - startOffset;
      if (state.last === offsetMatchIndex) {
        state.list.push({
          text: match[0],
          offset: offsetMatchIndex,
          key: `${index}-m1`,
          style: `match_${match.type}_${match.cIndex % 8}`,
          index: match.cIndex,
          regexText: match.regexText,
          match,
        });
        state.last = matchEnd;
      } else if (
        state.last > offsetMatchIndex &&
        matchEnd > 0 &&
        offsetMatchIndex > 0
      ) {
        const popped = [];
        {
          let last = state.list[state.list.length - 1];
          while (last && last.offset + last.text.length > offsetMatchIndex) {
            popped.push(state.list.pop());
            last = state.list[state.list.length - 1];
          }
        }

        const last = popped.pop();
        if (!last) {
          return state;
        }
        const lastText = last.text;
        const newLastLength = offsetMatchIndex - last.offset;
        state.list.push({
          ...last,
          text: lastText.substring(0, newLastLength),
        });
        state.list.push({
          // if this match is longer than the text,
          text:
            match[0].length + offsetMatchIndex > text.length + startOffset
              ? // then limit its length
                match[0].substring(
                  0,
                  match[0].length -
                    (match[0].length +
                      offsetMatchIndex -
                      (text.length + startOffset)),
                )
              : match[0],
          offset: offsetMatchIndex,
          key: `${index}-m2`,
          style: `match_${match.type}_${match.cIndex % 8}`,
          index: match.cIndex,
          regexText: match.regexText,
          match,
        });
        if (lastText.length > newLastLength + match[0].length) {
          state.list.push({
            ...last,
            key: `${last.key}-r`,
            offset: matchEnd,
            text: lastText.substring(newLastLength + match[0].length),
          });
        }
        while (popped.length > 0) {
          const next = popped.pop();
          if (next.offset + next.text.length > matchEnd) {
            state.list.push({
              ...next,
              offset: matchEnd > next.offset ? matchEnd : next.offset,
              text:
                matchEnd > next.offset
                  ? next.text.substring(matchEnd - next.offset)
                  : next.text,
            });
          }
        }

        if (state.last < matchEnd) {
          state.last = matchEnd;
        }
      } else if (offsetMatchIndex < text.length) {
        const matchedText =
          // if this match is longer than the text,
          match[0].length + offsetMatchIndex > text.length + startOffset
            ? // then limit its length
              match[0].substring(
                0,
                match[0].length -
                  (match[0].length +
                    offsetMatchIndex -
                    (text.length + startOffset)),
              )
            : // if it has overlapped a previous match
              offsetMatchIndex < state.last
              ? // then just show the remainder
                match[0].substring(state.last - offsetMatchIndex)
              : // if it starts half way through,
                offsetMatchIndex < 0
                ? // then adjust so that it starts from the middle
                  match[0].substring(-offsetMatchIndex)
                : // else show the whole match
                  match[0];
        if (matchedText.length > 0) {
          if (offsetMatchIndex > state.last) {
            state.list.push({
              text: text.substring(state.last, offsetMatchIndex),
              offset: state.last,
              key: `${index}-t`,
            });
          }
          state.list.push({
            text: matchedText,
            offset: offsetMatchIndex,
            key: `${index}-m3`,
            style: `match_${match.type}_${match.cIndex % 8}`,
            match,
            index: match.cIndex,
            regexText: match.regexText,
          });
          state.last = matchEnd;
        }
      }
      if (index === matches.length - 1) {
        const lastText = text.substring(state.last);
        if (lastText.length > 0) {
          state.list.push({
            text: lastText,
            offset: state.last,
            key: `${index}-l`,
          });
        }
      }
      return state;
    },
    {last: 0, list: []},
  );
}

export default getUsageMatches;
