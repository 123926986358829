import BaseRoute from "utils/base_route";

import ContractTypeDetailContainer from "./containers/contract_type_detail";

export default class ContractTypeDetail extends BaseRoute {
  constructor(store) {
    super("detail/:contractTypeId", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, ContractTypeDetailContainer);
  }
}
