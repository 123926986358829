import basePath from "base_path";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {createChangeTimeWrapper} from "utils/ensure_fresh_update";

import rateLimit, {getStandardTimeout} from "utils/rate_limit";
import ActionTypes from "../constants/action_types";

function doSave(requestor, organisationId, contractTypeId, data, changeTime) {
  return requestor
    .patch(
      `${basePath}/organisation/${organisationId}/contract_type/${contractTypeId}`,
      data,
      {withCredentials: true},
    )
    .then(response => ({
      ...createChangeTimeWrapper(changeTime),
      ...data,
      ...response.data,
    }));
}

const save = rateLimit(doSave, getStandardTimeout());

export default function updateContractType(requestor) {
  return createReduxPromiseAction(
    ActionTypes.CONTRACT_TYPE_UPDATE,
    (organisationId, contractTypeId, data, changeTime) => {
      if (!data.last_edited) {
        throw new Error("data must contain a last_edited key");
      }
      return save(requestor, organisationId, contractTypeId, data, changeTime);
    },
    (organisationId, contractTypeId, data, changeTime) => ({
      ...createChangeTimeWrapper(changeTime),
      ...data,
    }),
  );
}
