import React from "react";
import {Link} from "react-router";

import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import LinkIcon from "@material-ui/icons/Link";

import {OrganisationId} from "common/types/organisation";
import {ProjectId} from "common/types/project";
import {DocumentId} from "common/types/document";
import {Document} from "../../../types";

interface Props {
  organisationId: OrganisationId;
  projectId: ProjectId;
  documentIds: null | DocumentId[];
  documents: Document[];
  selectDocuments: (documentId: DocumentId[]) => void;
}

export default function DocumentSelector({
  organisationId,
  projectId,
  documentIds,
  documents,
  selectDocuments,
}: Props) {
  if (
    !documents ||
    !Array.isArray(documents) ||
    documentIds === null ||
    documentIds === undefined
  ) {
    return (
      <FormControl variant="outlined">
        <InputLabel>Loading</InputLabel>
      </FormControl>
    );
  }

  if (Array.isArray(documents) && documents.length === 0) {
    return <div>"No documents"</div>;
  }
  const documentsById = documents.reduce((obj, doc) => {
    obj[doc.id] = doc;
    return obj;
  }, {});
  const filterOptions = createFilterOptions({
    stringify: documentId => {
      const document = documentsById[documentId as DocumentId];
      return `${document.name} ${document.id}`;
    },
  });
  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <Autocomplete
        multiple
        filterOptions={filterOptions}
        value={documentIds}
        id="document-select"
        onChange={(event, newValue) =>
          selectDocuments(newValue as DocumentId[])
        }
        style={{width: "30em"}}
        renderInput={(...params) =>
          params.map((param, index) => (
            <TextField
              {...param}
              key={index}
              label="Document"
              variant="outlined"
              InputProps={{...param.InputProps, type: "search"}}
            />
          ))
        }
        getOptionLabel={documentId =>
          documentsById[documentId as DocumentId]?.name ?? "unknown doc"
        }
        options={documents.map(document => document.id)}
        renderOption={documentId => {
          const document = documentsById[documentId as DocumentId];
          return (
            <MenuItem key={document.id} value={document.id}>
              <div style={{width: "100%"}}>
                <div
                  style={{
                    display: "flex",
                    gap: "1em",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {document.name.substring(0, 30)}
                    <span style={{fontSize: "0.5em", marginLeft: "1em"}}>
                      (#{document.id})
                    </span>
                  </span>
                  <span style={{fontSize: "0.75em"}}>
                    (Embedding topics: {document?.embedding_topics?.length})
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "1em",
                    justifyContent: "space-between",
                    fontStyle: "italic",
                    color: "#666",
                  }}
                >
                  <span style={{fontSize: "0.75em"}}>
                    Uploaded: {JSON.stringify(document.creation_date)}
                  </span>
                  <span style={{fontSize: "0.75em"}}>
                    By: {document.username}
                  </span>
                </div>
              </div>
            </MenuItem>
          );
        }}
      />
      {documentIds ? (
        <Link
          to={`/organisation/${organisationId}/project/${projectId}/document/${documentIds[0]}/detail`}
        >
          <IconButton>
            <LinkIcon />
          </IconButton>
        </Link>
      ) : null}
    </div>
  );
}
