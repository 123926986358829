import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {WorkflowId} from "common/flowmaster/types/workflow";
import {
  WorkflowTask,
  WorkflowTaskId,
} from "common/flowmaster/types/task_config";

export type WorkflowTaskReorderAction = {
  type: WorkflowsActionTypes["WORKFLOW_TASK_REORDER"]["SUCCESS"];
  payload: WorkflowTask[];
};

export default function reorderWorkflowTask(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOW_TASK_REORDER,
    (
      organisationId: OrganisationId,
      workflowId: WorkflowId,
      workflowTaskId: WorkflowTaskId,
      movedItemId: WorkflowTaskId,
    ) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}` +
            `/workflow/${workflowId}/task/${workflowTaskId}/reorder`,
          {moved_item_id: movedItemId},
          {withCredentials: true},
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
