import React from "react";
import styled from "styled-components";

const Svg = styled.svg`
  width: 100%;
  height: 100%;
`;

const SummaryPercentage = styled.text`
  font-weight: bold;
`;

type Point = Record<"x" | "y", number>;

function drawSector(
  /** Center point of the sector */
  center: Point,
  /** Radius of the sector */
  r: number,
  /** Start angle of the sector, measured from the top of the circle */
  α: number,
  /** Angle of the sector */
  θ: number,
): string {
  const startRadians = (Math.PI / 180) * (α - 90);
  const startX = center.x + r * Math.cos(startRadians);
  const startY = center.y + r * Math.sin(startRadians);

  const endRadians = startRadians + (Math.PI / 180) * θ;
  const x = center.x + r * Math.cos(endRadians);
  const y = center.y + r * Math.sin(endRadians);

  const largeArcFlag = θ > 180 ? 1 : 0;

  return `M ${startX} ${startY} A ${r} ${r} 0 ${largeArcFlag} 1 ${x} ${y} L ${center.x} ${center.y} Z`;
}

type Props = {
  /**
   * Optional prop representing the radius of "donut hole". Should be
   * normalised in the range 0-1, where 1 would be all hole, no donut. (Sad!)
   */
  innerRadius?: number;

  /** Angle of donut arc, in degrees. */
  angle: number;

  /** Color of donut arc. */
  color: string;

  /**
   * Optional number representing a percentage rendered in the center of the
   * donut hole. Should be in the range 0-100.
   */
  summaryPercentage?: number;
};

const DonutChart = ({
  angle,
  color,
  innerRadius = 0.5,
  summaryPercentage,
}: Props) => {
  /** This number is arbitrary. All other units are derived from it. */
  const viewBoxSize = 100;

  /** Unique element IDs are needed to avoid collisions between component instances. */
  const donutHoleMaskId = `donutHole-${innerRadius.toString()}`;

  /** Center point of chart, for convenience. */
  const center = {x: viewBoxSize / 2, y: viewBoxSize / 2};

  return (
    <Svg
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      preserveAspectRatio="xMidYMid meet"
    >
      {angle < 360 ? (
        <path
          d={drawSector(
            center,
            viewBoxSize / 2,
            0,
            Math.min(Math.max(angle, 1), 359),
          )}
          mask={`url(#${donutHoleMaskId})`}
          fill={color}
          key={color}
        />
      ) : (
        /**
         * Unfortunately, SVG doesn't draw arcs at all if their start and
         * end coordinates are identical, so we use a different method to
         * handle full circles.
         * WARN: The discontinuity of these conditions may become a problem
         * in future if we ever animate transitions in this geometry. Maybe
         * then we'd need to switch to a more robust "path-only" method
         * (e.g. maybe drawing two paths) instead of allowing this edge
         * case to exist.
         */
        <circle
          cx={center.x}
          cy={center.y}
          r={viewBoxSize / 2}
          mask={`url(#${donutHoleMaskId})`}
          fill={color}
          key={color}
        />
      )}

      {summaryPercentage !== undefined && (
        <SummaryPercentage
          textAnchor="middle"
          x="50%"
          y="50%"
          // MAGIC: 0.35em is a magic number. Regrettable, but I found no better
          // method to vertically center SVG text. I presume this depends on
          // parameters of the typeface, so may need adjustment in future.
          dy="0.35em"
        >
          {summaryPercentage}%
        </SummaryPercentage>
      )}

      <defs>
        <mask id={donutHoleMaskId}>
          {/** Allow all geometry... */}
          <rect
            x="0"
            y="0"
            width={viewBoxSize}
            height={viewBoxSize}
            fill="white"
          />
          {/** ...that isn't masked by the "donut hole" */}
          <circle
            cx="50%"
            cy="50%"
            r={(viewBoxSize / 2) * innerRadius}
            fill="black"
          />
        </mask>
      </defs>
    </Svg>
  );
};

export default DonutChart;
