import React from "react";

import styled from "styled-components";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const RelatedIssuesList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 1rem 1rem 0;
`;

const Header = styled.li`
  border-bottom: 0.1rem solid #6666663d;
`;

const OverridesList = styled.ol`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  margin: 0;
  padding: 1rem 0 0 3rem;
`;

const Visualisation = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  font-weight: bold;
  color: #3c3c3c;

  padding: 0.5rem 0;
`;

const Text = styled.span<{bold?: boolean}>`
  color: #666666;
  font-weight: ${props => (props.bold ? 500 : "unset")};
`;

interface Props {
  onClickCancel?: () => void;
  onClickConfirm?: () => void;
  onClickCopyOverrides?: () => void;
  onClickSelectAllRelatedIssues?: () => void;
  onClickSelectRelatedIssue?: (issue: {name: string}) => void;
  selectedIssueset: {name: string};
  newIssueset: {name: string};
  relatedIssues?: Array<{name: string; selected: boolean}>;
  selectedIssue: {name: string; overrides: string[]};
}

const AddIssuesetDialog = ({
  onClickCancel,
  onClickConfirm,
  onClickCopyOverrides,
  onClickSelectAllRelatedIssues,
  onClickSelectRelatedIssue,
  selectedIssueset,
  newIssueset,
  relatedIssues,
  selectedIssue,
}: Props) => (
  <Dialog onClose={onClickCancel} aria-labelledby="copy-overrides-dialog" open>
    <DialogTitle>Confirm issueset addition</DialogTitle>
    <DialogContent
      style={{
        fontFamily: "Roboto",
        display: "flex",
        flexDirection: "column",
        rowGap: "1rem",
      }}
    >
      {selectedIssueset ? (
        <>
          <Text>Copy templates from selected checklist?</Text>

          <Visualisation>
            {selectedIssueset.name}
            <ArrowRightAltIcon style={{opacity: 0.5}} />
            {newIssueset.name}
          </Visualisation>

          {relatedIssues !== undefined && relatedIssues.length > 0 ? (
            <div>
              <Text>Issueset is part of a family.</Text>
              <RelatedIssuesList>
                <Header>
                  <Checkbox
                    checked={relatedIssues.every(issue => issue.selected)}
                    onClick={onClickSelectAllRelatedIssues}
                  />
                  <Text bold>Issue Name</Text>
                </Header>

                {relatedIssues.map(issue => (
                  <li key={issue.name}>
                    <Checkbox
                      checked={issue.selected}
                      onClick={() => onClickSelectRelatedIssue?.(issue)}
                    />
                    <Text>{issue.name}</Text>
                  </li>
                ))}

                <li key={selectedIssue.name}>
                  <Checkbox checked disabled />
                  <Text>{selectedIssue.name}</Text>
                </li>
              </RelatedIssuesList>
            </div>
          ) : (
            <div>
              <Text>Fields that will be copied:</Text>
              <OverridesList>
                {selectedIssue.overrides.map(template => (
                  <Text as="li" key={template}>
                    {template}
                  </Text>
                ))}
              </OverridesList>
            </div>
          )}
        </>
      ) : (
        "Select a checklist to copy templates over"
      )}
    </DialogContent>

    <DialogActions>
      <Button onClick={onClickCancel} color="secondary">
        Cancel
      </Button>
      <Button
        onClick={onClickCopyOverrides}
        color="primary"
        disabled={!selectedIssueset}
      >
        Copy selected templates
      </Button>
      <Button onClick={onClickConfirm} color="primary">
        Confirm without copy
      </Button>
    </DialogActions>
  </Dialog>
);

export default AddIssuesetDialog;
