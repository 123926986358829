import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";

import keyedObjectPropType from "utils/keyed_object_prop_type";

import groupClauses from "../utils/group_clauses";

import DocumentParty from "./document_party";
import DocumentParseDifferences from "./document_parse_differences";
import DocumentIssueList from "./document_issue_list";
import DocumentClauseItem from "./document_clause_item";
import DefinitionClauseItem from "./definition_clause_item";
import TopicScores from "./topic_scores";
import Heading from "./heading";
import ConfirmDialog from "common_components/confirm_dialog";
import InformationPopup from "common_components/information_popup";
import SquareIconButton from "common_components/buttons/square_icon_button.js";

import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import Checkbox from "material-ui/Checkbox";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import {Toolbar, ToolbarGroup} from "material-ui/Toolbar";
import CircularProgress from "material-ui/CircularProgress";
import getPositiveReasonData from "common/utils/issues/reason/get_positive_reason_data";
import isIssueAlert from "common/utils/issues/is_issue_alert";

import sessionStorage from "utils/session_storage";
import localStorage from "utils/local_storage";

const styles = {
  headerButton: {
    margin: 0,
    flexShrink: 0,
  },
  testClassificationContainer: {
    marginTop: "1.6rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  popup: {
    vertical: "top",
    horizontal: "right",
  },
  infoIcon: {
    position: "relative",
    top: "-16px",
    left: "-16px",
  },
};

function getDocumentSectionCollapseState(documentId) {
  const documentClausesSectionCollapseState = sessionStorage.getItem(
    "documentClausesSectionCollapseState",
  );
  if (documentClausesSectionCollapseState) {
    return documentClausesSectionCollapseState[documentId];
  }
}

function setSectionCollapseStateForAll(valueForAll, documentSections) {
  return documentSections.reduce((accum, section) => {
    accum[section.reference] = valueForAll;
    return accum;
  }, {});
}

const showIssues = false;

class DocumentClauses extends React.Component {
  constructor(props) {
    super(props);
    const clauseCount = props.document.clausepart_load_total || 1;
    const closeClauses = clauseCount > 500;
    this.state = {
      selectedClause: "",
      showMasks: false,
      documentName: props.document.name,
      showConfirmAllIssues: false,
      showConfirmAllTopics: false,
      isTestClassificationRequestPending: false,
      // readOnlyMode shows topic selector in static mode
      readOnlyModeOn: Boolean(
        localStorage.getItem("documentClausesReadOnlyModeOn"),
      ),
      sectionCollapseState:
        getDocumentSectionCollapseState(props.document.id) ||
        setSectionCollapseStateForAll(closeClauses, props.documentSections),
      closedClauses: {},
      closeClauses,
    };
  }

  render() {
    const {document} = this.props;
    const {isTestClassificationRequestPending} = this.state;

    this.positiveReasonData = getPositiveReasonData(this.props.documentClauses);
    const allSectionsCollapsed = this.areAllSectionsCollapsed();

    return (
      <div className="app-page">
        <Toolbar className="app-toolbar">
          <ToolbarGroup
            key={0}
            style={{width: "100%", justifyContent: "start"}}
          >
            <TextField
              type="text"
              className="name"
              name="name"
              value={this.state.documentName}
              onChange={this.onDocumentNameChange}
              onBlur={this.updateDocumentName}
              style={{width: "70%"}}
            />
            {this.props.user.is_admin && (
              <Checkbox
                label="Public"
                checked={document.is_public}
                style={{
                  width: "80px",
                  display: "inline-block",
                  marginLeft: "2rem",
                }}
                labelStyle={{left: "-12px"}}
                onCheck={this.updateIsPublic}
              />
            )}
            <FlatButton
              className="toggle-masks"
              label={this.state.showMasks ? "Hide Masks" : "Show Masks"}
              onClick={this.updateMaskState}
              style={styles.headerButton}
            />
            <FlatButton
              className="toggle-view"
              label={"Lawyer View"}
              onClick={this.props.toggleView}
              style={styles.headerButton}
            />
            <FlatButton
              className="confirm-all-topics"
              label="Confirm all topics"
              onClick={this.setStateVariable("showConfirmAllTopics", true)}
              style={{
                visibility: !this.state.showMasks ? "visible" : "hidden",
                ...styles.headerButton,
              }}
            />
            <FlatButton
              className="confirm-all-issues"
              label="Confirm All Issues"
              onClick={this.setStateVariable("showConfirmAllIssues", true)}
              style={styles.headerButton}
            />
            <FlatButton
              label="Refresh Issues"
              onClick={this.props.onDocumentIssuesFindRun}
              icon={<RefreshIcon />}
              style={styles.headerButton}
            />
          </ToolbarGroup>
        </Toolbar>
        <div className="app-body app-body--white" style={{padding: "1rem"}}>
          <TopicScores
            documentClauses={this.props.documentClauses}
            documentIssues={this.props.documentIssues}
          />
          {!this.state.showMasks && (
            <DocumentParseDifferences {...this.props} />
          )}
          {showIssues && (
            <DocumentIssueList
              {...this.props}
              positiveReasonData={this.positiveReasonData}
            />
          )}
          <div style={styles.testClassificationContainer}>
            <Checkbox
              label="Read Only Mode"
              checked={this.state.readOnlyModeOn}
              style={{width: "12rem"}}
              labelStyle={{left: "-12px"}}
              onCheck={this.onReadOnlyModeChange}
            />
            <RaisedButton
              label={allSectionsCollapsed ? "Expand All" : "Collapse All"}
              primary={true}
              onClick={
                allSectionsCollapsed
                  ? this.onCollapseAllSections
                  : this.onExpandAllSections
              }
              style={{marginRight: "1rem"}}
            />
            <RaisedButton
              label="Test Classification"
              primary={true}
              onClick={this.onTestClassificationClick}
              style={{marginRight: "1rem"}}
              disabled={isTestClassificationRequestPending}
            />
            <InformationPopup
              transformOrigin={styles.popup}
              anchorOrigin={styles.popup}
              iconStyle={styles.infoIcon}
            >
              <div style={{width: "240px", textAlign: "justify"}}>
                Tests clauses against current classifiers. Puts a green / red
                border on each topic to indicate whether it has been correctly
                applied with respect to the current state of the classifiers.
              </div>
            </InformationPopup>
            {isTestClassificationRequestPending ? (
              <CircularProgress size={30} thickness={3} />
            ) : null}
          </div>
          <h1 className="document-title">{document.title}</h1>
          {this.renderParties(document.parties)}
          <div className="clauses">{this.renderSections()}</div>
        </div>
        {this.renderConfirmAllTopicsDialog()}
        {this.renderCnfirmAllIssuesDialog()}
      </div>
    );
  }

  confirmAllTopics = () => {
    this.props.confirmAllTopics();
  };

  renderParties(parties) {
    if (parties.length > 0) {
      const partiesList = parties
        .map(party => this.renderParty(party))
        .reduce((htmlList, partyHtml, index) => {
          htmlList.push(
            <div style={{margin: "1em"}} key={`party-${index}`}>
              {partyHtml}
            </div>,
            <span key={`and-${index}`}>and</span>,
          );
          return htmlList;
        }, []);
      partiesList.pop();
      return (
        <div>
          <span>Between</span>
          {partiesList}
        </div>
      );
    }
    return null;
  }

  renderParty(party) {
    return <DocumentParty key={party.id} party={party} />;
  }

  cleanReason(reason) {
    return _.omit(reason, ["id", "last_edited"]);
  }

  cleanDocumentIssues(issues) {
    return issues.map(issue => ({
      ...issue,
      reason: Array.isArray(issue.reason)
        ? issue.reason.map(_reason => this.cleanReason(_reason))
        : this.cleanReason(issue.reason),
    }));
  }

  renderSections() {
    const {
      documentClauses: clauseGroups,
      documentSections: sections,
    } = this.props;
    _.sortBy(sections, "order");
    const unnamedSections = {main: false, background: false};
    return _.map(sections, section =>
      this.renderSection(
        section,
        clauseGroups[section.id],
        unnamedSections,
        this.cleanDocumentIssues(this.props.documentIssues),
      ),
    );
  }

  renderSection(section, clauses, unnamedSections, cleanDocumentIssues) {
    const {id: sectionId, reference: sectionReference} = section;
    const isSectionCollapsed = this.state.sectionCollapseState[
      sectionReference
    ];

    const {
      highlightedClause,
      documentDefinitions: definitions,
      documentHeadings: headings,
      documentChanges: changes,
    } = this.props;

    const definitionsByClause = _.groupBy(definitions, "reference");
    const clausesByHeadings = groupClauses(clauses, headings, sectionId);
    const isBackground = section.reference === "background";
    const sectionChanges = changes.filter(
      change =>
        change.new_section_id === sectionId ||
        (change.old_reference &&
          change.old_reference.startsWith(section.reference) &&
          change.type === "clause_deletion"),
    );

    this.unmatchedChanges = sectionChanges.filter(
      change => change.type === "clause_deletion",
    );
    return (
      <div key={sectionId} className="section">
        <div style={{display: "flex", alignItems: "center"}}>
          <SquareIconButton
            onButtonClick={this.onSectionCollapse(sectionReference)}
            open={isSectionCollapsed}
            style={{border: "1px solid #616161", marginBottom: "4px"}}
            title={sectionReference}
          />
          {!unnamedSections[sectionReference] && (
            <div
              className="header"
              style={{
                fontSize: "2em",
                fontWeight: "bold",
                lineHeight: "2em",
                marginLeft: "10px",
              }}
            >
              {sectionReference}
              {section.name ? ` - ${section.name}` : ""}
            </div>
          )}
        </div>
        {!isSectionCollapsed &&
          this.renderClauses(
            sectionId,
            "top",
            highlightedClause,
            definitionsByClause,
            clausesByHeadings,
            sectionChanges,
            clausesByHeadings.heading,
            isBackground,
            cleanDocumentIssues,
          )}
        {!isSectionCollapsed &&
          this.unmatchedChanges.map(change =>
            this.renderChange(
              sectionId,
              "top",
              clausesByHeadings.heading,
              change,
            ),
          )}
      </div>
    );
  }

  renderClauses(
    sectionId,
    headerId,
    highlightedClause,
    definitionsByClause,
    clauses,
    changes,
    heading,
    isBackground,
    cleanDocumentIssues,
  ) {
    const {selectedClause} = this.state;
    function hasClauseReason(reason, clauseId) {
      if (reason.reason) {
        if (Array.isArray(reason.reason)) {
          return reason.reason.find(_reason =>
            hasClauseReason(_reason, clauseId),
          );
        }
        return hasClauseReason(reason.reason, clauseId);
      }
      return Array.isArray(reason)
        ? reason.find(_reason => hasClauseReason(_reason, clauseId))
        : reason.clause_id === clauseId;
    }
    return clauses.map(clause => {
      if (clause.clauses) {
        return this.renderClauseGroup(
          sectionId,
          headerId,
          highlightedClause,
          definitionsByClause,
          clause,
          changes,
          isBackground,
          cleanDocumentIssues,
        );
      }
      const {
        id: clauseId,
        last_edited: lastEdited,
        reference: clauseReference,
      } = clause;
      const key = `${sectionId}_${headerId}_${clauseId}`;
      const associatedIssues = this.props.documentIssues.filter(
        issue =>
          isIssueAlert(issue) &&
          issue.reason &&
          issue.reason.find(reason => hasClauseReason(reason, clauseId)),
      );
      const params = {
        key,
        ...this.props,
        sectionId,
        readOnlyModeOn: this.state.readOnlyModeOn,
        showMasks: Boolean(this.state.showMasks),
        clause,
        associatedIssues,
        changes: changes.filter(change => change.new_clause_id === clauseId),
        heading,
        headings: this.props.documentHeadings,
        highlightedClause,
        onExistingTopicAdded: this.onExistingTopicAdded(sectionId, clauseId),
        onNewTopicAdded: this.onNewTopicAdded(sectionId, clauseId),
        clausepartsTopicsUpdated: this.props.clausepartsTopicsUpdated,
        onTopicConfirmed: this.onTopicConfirmed(sectionId, clauseId),
        onUnconfirmedTopicsRemoved: this.onUnconfirmedTopicsRemoved(
          sectionId,
          clauseId,
        ),
        onTopicRemoved: this.onTopicRemoved(sectionId, clauseId),
        onSplitClause: this.onSplitClause(sectionId, clauseId, lastEdited),
        onRemoveSplits: this.onRemoveSplits(sectionId, clauseId, lastEdited),
        onTopicsReordered: this.onTopicsReordered(sectionId, clauseId),
        onToggleExactMatch: this.onToggleExactMatch(sectionId, clauseId),
      };
      const clauseDefinitions = definitionsByClause[clauseReference];
      const isDefinitionClause =
        clauseDefinitions &&
        clauseDefinitions.find(
          definition => definition.type === "InDefinitionSection",
        );
      let clauseItem;
      if (isDefinitionClause) {
        clauseItem = (
          <DefinitionClauseItem
            {...params}
            definitions={clauseDefinitions}
            onHover={this.onClauseHovered(key)}
            isInteractive={key === selectedClause}
            positiveReasonData={this.positiveReasonData}
            cleanDocumentIssues={cleanDocumentIssues}
          />
        );
      } else {
        const close = this.state.closeClauses
          ? this.state.closedClauses[clause.id] !== false
          : this.state.closedClauses[clause.id];
        clauseItem = (
          <DocumentClauseItem
            {...params}
            definitions={this.props.documentDefinitions}
            onHover={this.onClauseHovered(key)}
            isInteractive={key === selectedClause}
            positiveReasonData={this.positiveReasonData}
            cleanDocumentIssues={cleanDocumentIssues}
            closed={close}
            closeClause={this.closeClause}
            openClause={this.openClause}
            closeOtherClauses={this.closeOtherClauses}
            closeAllClauses={this.closeOtherClauses}
            openAllClauses={this.openAllClauses}
            sectionId={sectionId}
          />
        );
      }
      const matchingChange = this.findMatchingChange(clause, changes);
      this.unmatchedChanges = _.without(this.unmatchedChanges, matchingChange);
      if (matchingChange) {
        return (
          <div key={`${key}_group`}>
            {this.renderChange(sectionId, headerId, heading, matchingChange)}
            {clauseItem}
          </div>
        );
      }
      return clauseItem;
    });
  }
  closeClause = clauseId => {
    this.setState({
      closedClauses: {...this.state.closedClauses, [clauseId]: true},
    });
  };
  openClause = clauseId => {
    this.setState({
      closedClauses: {...this.state.closedClauses, [clauseId]: false},
    });
  };
  openAllClauses = sectionId => {
    const closedClauses = {...this.state.closedClauses};
    this.props.documentClauses[sectionId].forEach(clause => {
      closedClauses[clause.id] = false;
    });
    this.setState({closedClauses});
  };
  closeOtherClauses = (sectionId, clauseId) => {
    const closedClauses = {...this.state.closedClauses};
    this.props.documentClauses[sectionId].forEach(clause => {
      if (clause.id !== clauseId) {
        closedClauses[clause.id] = true;
      }
    });
    this.setState({closedClauses});
  };
  closeAllClauses = sectionId => {
    const closedClauses = {...this.state.closedClauses};
    this.props.documentClauses[sectionId].forEach(clause => {
      closedClauses[clause.id] = true;
    });
    this.setState({closedClauses});
  };

  findMatchingChange(clause, changes) {
    return changes.find(
      change =>
        change.type === "clause_deletion" &&
        change.old_reference === clause.full_reference,
    );
  }

  renderChange(sectionId, headerId, heading, change) {
    const clause = change.old_clause_data;
    function noOp() {}
    const params = {
      ...this.props,
      clause,
      associatedIssues: [],
      key: `${sectionId}_${headerId}_${clause.id}_deleted`,
      is_deletion: true,
      changes: [],
      heading,
      highlightedClause: null,
      onExistingTopicAdded: noOp,
      onNewTopicAdded: noOp,
      onTopicConfirmed: noOp,
      onUnconfirmedTopicsRemoved: noOp,
      onTopicRemoved: noOp,
      onSplitClause: noOp,
      onRemoveSplits: noOp,
      onTopicsReordered: noOp,
      onToggleExactMatch: noOp,
    };
    return (
      <DocumentClauseItem
        className="deleted-clause"
        {...params}
        definitions={this.props.documentDefinitions}
        key={`${sectionId}_${headerId}_${clause.id}_deleted`}
      />
    );
  }

  renderClauseGroup(
    sectionId,
    headerId,
    highlightedClause,
    definitionsByClause,
    clauseGroup,
    changes,
    isBackground,
    cleanDocumentIssues,
  ) {
    return (
      <div key={`${sectionId}_${clauseGroup.reference}`}>
        {this.renderHeading(clauseGroup.heading, isBackground)}
        {this.renderClauses(
          sectionId,
          clauseGroup.reference,
          highlightedClause,
          definitionsByClause,
          clauseGroup.clauses,
          changes,
          clauseGroup.heading,
          isBackground,
          cleanDocumentIssues,
        )}
      </div>
    );
  }

  renderHeading(heading, isBackground) {
    if (!heading) {
      return null;
    }
    return (
      <Heading
        className="heading"
        heading={heading}
        isBackground={isBackground}
        key={heading.reference}
      />
    );
  }

  renderConfirmAllTopicsDialog() {
    return (
      <ConfirmDialog
        open={this.state.showConfirmAllTopics}
        onClose={this.setStateVariable("showConfirmAllTopics", false)}
        onSuccess={this.confirmAllTopics}
        title="Confirm All Topics"
        description="Are you sure you want to confirm all topics?"
        okButtonCaption="Confirm"
      />
    );
  }

  renderCnfirmAllIssuesDialog() {
    return (
      <ConfirmDialog
        open={this.state.showConfirmAllIssues}
        onClose={this.setStateVariable("showConfirmAllIssues", false)}
        onSuccess={this.props.confirmAllIssues}
        title="Confirm All Issues"
        description="Are you sure you want to confirm all issues?"
        okButtonCaption="Confirm"
      />
    );
  }

  /* eslint-disable no-invalid-this */
  updateMaskState = () => {
    this.setState({showMasks: !this.state.showMasks});
  };

  onExistingTopicAdded = _.memoize(
    (sectionId, clauseId) => (...args) =>
      this.props.onExistingTopicAdded(sectionId, clauseId, ...args),
    (...args) => JSON.stringify([...args]),
  );

  onNewTopicAdded = _.memoize(
    (sectionId, clauseId) => (...args) =>
      this.props.onNewTopicAdded(sectionId, clauseId, ...args),
    (...args) => JSON.stringify([...args]),
  );

  onTopicConfirmed = _.memoize(
    (sectionId, clauseId) => (...args) =>
      this.props.onTopicConfirmed(sectionId, clauseId, ...args),
    (...args) => JSON.stringify([...args]),
  );

  onUnconfirmedTopicsRemoved = _.memoize(
    (sectionId, clauseId) => (...args) =>
      this.props.onUnconfirmedTopicsRemoved(sectionId, clauseId, ...args),
    (...args) => JSON.stringify([...args]),
  );

  onTopicRemoved = _.memoize(
    (sectionId, clauseId) => (...args) =>
      this.props.onTopicRemoved(sectionId, clauseId, ...args),
    (...args) => JSON.stringify([...args]),
  );

  onSplitClause = _.memoize(
    (sectionId, clauseId, clauseLastEdited) => (...args) =>
      this.props.onSplitClause(sectionId, clauseId, clauseLastEdited, ...args),
    (...args) => JSON.stringify([...args]),
  );

  onRemoveSplits = _.memoize(
    (sectionId, clauseId, clauseLastEdited) => (...args) =>
      this.props.onRemoveSplits(sectionId, clauseId, clauseLastEdited, ...args),
    (...args) => JSON.stringify([...args]),
  );

  onTopicsReordered = _.memoize(
    (sectionId, clauseId) => (...args) =>
      this.props.onTopicsReordered(sectionId, clauseId, ...args),
    (...args) => JSON.stringify([...args]),
  );

  onToggleExactMatch = _.memoize(
    (sectionId, clauseId) => (...args) =>
      this.props.onToggleExactMatch(sectionId, clauseId, ...args),
    (...args) => JSON.stringify([...args]),
  );

  onClauseHovered = _.memoize(
    clauseKey => () => this.setState({selectedClause: clauseKey}),
    (...args) => JSON.stringify([...args]),
  );

  updateDocumentName = () => {
    this.props.onDocumentUpdated({name: this.state.documentName});
  };

  onDocumentNameChange = e => {
    const documentName = e.target.value;
    this.setState(() => ({documentName}));
  };

  updateIsPublic = () => {
    this.props.onDocumentUpdated({is_public: !this.props.document.is_public});
  };

  setStateVariable = _.memoize(
    (name, value) => () => this.setState({[name]: value}),
    (...args) => JSON.stringify([...args]),
  );

  onTestClassificationClick = async () => {
    await this.startTestClassificationRequest();
    await this.props.documentClausepartsRegexMatchesFetch();
    this.finishTestClassificationRequest();
  };

  startTestClassificationRequest = () =>
    this.setState(() => ({isTestClassificationRequestPending: true}));
  finishTestClassificationRequest = () =>
    this.setState(() => ({isTestClassificationRequestPending: false}));
  onReadOnlyModeChange = () =>
    this.setState(
      prevState => ({readOnlyModeOn: !prevState.readOnlyModeOn}),
      () =>
        localStorage.setItem(
          "documentClausesReadOnlyModeOn",
          this.state.readOnlyModeOn,
        ),
    );

  areAllSectionsCollapsed = () => {
    const {sectionCollapseState = []} = this.state;
    const allSections = (this.props.documentSections || []).map(
      section => section.reference,
    );
    const collapsedSections = Object.keys(sectionCollapseState).filter(
      sectionReference => sectionCollapseState[sectionReference],
    );
    return _.difference(allSections, collapsedSections).length === 0;
  };

  onSectionCollapseStateChange = sectionCollapseState => {
    this.setState(
      () => ({sectionCollapseState}),
      () => {
        const documentClausesSectionCollapseState =
          sessionStorage.getItem("documentClausesSectionCollapseState") || {};
        sessionStorage.setItem("documentClausesSectionCollapseState", {
          ...documentClausesSectionCollapseState,
          [this.props.document.id]: sectionCollapseState,
        });
      },
    );
  };

  onSectionCollapse = sectionReference => () => {
    const {sectionCollapseState} = this.state;
    const newSectionCollapseState = {
      ...sectionCollapseState,
      [sectionReference]: !sectionCollapseState[sectionReference],
    };
    this.onSectionCollapseStateChange(newSectionCollapseState);
  };

  onAllSectionCollapseStateChange = valueForAll =>
    this.onSectionCollapseStateChange(
      setSectionCollapseStateForAll(valueForAll, this.props.documentSections),
    );

  onCollapseAllSections = () => this.onAllSectionCollapseStateChange(false);
  onExpandAllSections = () => this.onAllSectionCollapseStateChange(true);
  /* eslint-enable no-invalid-this */
}

DocumentClauses.propTypes = {
  organisationId: PropTypes.number.isRequired,
  document: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    parties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        term: PropTypes.string,
        address: PropTypes.string,
      }),
    ),
  }).isRequired,
  highlightedClause: PropTypes.string,
  documentClauses: keyedObjectPropType(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        reference: PropTypes.string.isRequired,
        clauseParts: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            text: PropTypes.string.isRequired,
            load_state: PropTypes.number.isRequired,
            last_edited: PropTypes.string.isRequired,
            topics: PropTypes.arrayOf(
              PropTypes.shape({
                topic_id: PropTypes.number.isRequired,
                is_confirmed: PropTypes.bool.isRequired,
                is_deleted: PropTypes.bool.isRequired,
                topicparameters: PropTypes.arrayOf(
                  PropTypes.shape({
                    topicparameter_id: PropTypes.number.isRequired,
                    values: PropTypes.array.isRequired,
                  }),
                ),
              }),
            ),
          }),
        ),
      }),
    ),
  ),
  documentDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      term: PropTypes.string.isRequired,
      meaning: PropTypes.string.isRequired,
    }),
  ),
  documentChanges: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      new_section_id: PropTypes.number,
    }),
  ),
  documentIssues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicsById: keyedObjectPropType(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategoriesById: keyedObjectPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  topicTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onDocumentUpdated: PropTypes.func.isRequired,
  onExistingTopicAdded: PropTypes.func.isRequired,
  onNewTopicAdded: PropTypes.func.isRequired,
  onTopicRemoved: PropTypes.func.isRequired,
  onUnconfirmedTopicsRemoved: PropTypes.func.isRequired,
  onToggleExactMatch: PropTypes.func.isRequired,
  onSplitClause: PropTypes.func.isRequired,
  onRemoveSplits: PropTypes.func.isRequired,
  onTopicsReordered: PropTypes.func.isRequired,
};

export default DocumentClauses;
