import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {
  WorkflowId,
  WorkflowInstanceId,
  WorkflowInstance,
  WorkflowInputId,
} from "common/flowmaster/types/workflow";

export type WorkflowPrerequisiteUpdateInputValueAction = {
  type: WorkflowsActionTypes["WORKFLOW_PREREQUISITE_UPDATE_INPUT_VALUE"]["SUCCESS"];
  payload: {workflow_id: WorkflowId; prerequisites: WorkflowInstance[]};
};

export default function updateWorkflowPrerequisiteInputValue(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOW_PREREQUISITE_UPDATE_INPUT_VALUE,
    (
      organisationId: OrganisationId,
      workflowId: WorkflowId,
      workflowInstanceId: WorkflowInstanceId,
      inputId: WorkflowInputId,
      value: string,
    ) => {
      return requestor
        .patch(
          `${basePath}/organisation/${organisationId}/workflow/${workflowId}/prerequisite/${workflowInstanceId}/config`,
          {input_id: inputId, value},
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
