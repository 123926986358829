import ProjectContainer from "./containers/project";
import requestor from "requestor";

import projectFetchAction from "modules/projects/actions/project_fetch";
import issuesFetchAction from "modules/documents/actions/issues_info_fetch";

import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";
import contractTypesClearAction from "modules/documents/actions/contract_types_clear";

import documentTypesFetchAction from "modules/documents/actions/document_types_fetch";
import documentTypesClearAction from "modules/documents/actions/document_types_clear";

import rolesFetchAction from "modules/documents/actions/role/roles_fetch";

import BaseRoute from "utils/base_route";

export default class Project extends BaseRoute {
  constructor(store) {
    super(":projectId/detail", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, ProjectContainer);
  }

  fetchData(state) {
    this.fetchProject(state);
    this.fetchContractTypes(state);
    this.fetchDocumentTypes(state);
    this.fetchRoles(state);
    this.fetchIssues(state);
  }

  fetchProject(state) {
    const {organisationId, projectId} = state.router.params;
    if (projectId) {
      this.store.dispatch(
        projectFetchAction(requestor)(organisationId, projectId),
      );
    }
  }

  fetchContractTypes(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(contractTypesFetchAction(requestor)(organisationId));
  }

  fetchDocumentTypes(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(documentTypesFetchAction(requestor)(organisationId));
  }

  fetchRoles(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(rolesFetchAction(requestor)(organisationId));
  }

  fetchIssues(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(issuesFetchAction(requestor)(organisationId));
  }

  clearActiveData() {
    this.store.dispatch(contractTypesClearAction());
    this.store.dispatch(documentTypesClearAction());
  }
}
