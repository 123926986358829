import DocumentCreate from "./containers/document_create.js";
import requestor from "requestor";

import documentsFetchAction from "modules/documents/actions/documents_fetch";
import documentsClearAction from "modules/documents/actions/documents_clear";

import BaseRoute from "utils/base_route";

export default class DocumentsRoute extends BaseRoute {
  constructor(store) {
    super("create", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, DocumentCreate);
  }

  fetchActiveData(state) {
    this.fetchDocuments(state);
  }

  fetchDocuments(state) {
    const {organisationId, projectId} = state.router.params;
    if (projectId) {
      this.store.dispatch(
        documentsFetchAction(requestor)(organisationId, projectId),
      );
    }
  }

  clearActiveData() {
    this.store.dispatch(documentsClearAction());
  }
}
