import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

import {WorkflowInput} from "common/flowmaster/types/workflow";
import {llmModels} from "modules/flowmaster/constants/models";

interface LlmInputSelectorProps {
  input: WorkflowInput;
  value: string;
  handleInputChange: (e: React.ChangeEvent<{value: string}>) => void;
}
const LlmInputSelector: React.FC<LlmInputSelectorProps> = ({
  input,
  value,
  handleInputChange,
}) => {
  return (
    <FormControl key={input.id} style={{width: "30em", marginBottom: "1rem"}}>
      <InputLabel>{input.label || input.name}</InputLabel>
      <Select
        value={value}
        onChange={handleInputChange}
        style={{width: "20em"}}
        displayEmpty
      >
        {llmModels.map(model => (
          <MenuItem key={model.key} value={model.key}>
            {model.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default {
  label: "LLM Model",
  component: LlmInputSelector,
};
