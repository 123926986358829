import styled from "styled-components";
import React from "react";

import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";
import {
  TaskArgument,
  ActionDefinition,
} from "common/flowmaster/types/task_config";
import {Prompt} from "common/flowmaster/types/workflow";

import Argument from "./argument";

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  > thead tr th,
  > tbody tr td {
    word-break: break-word;
    overflow: hidden;

    width: 4em;
    &:nth-of-type(4) {
      width: 100%;
    }
  }
`;
interface Props {
  action: ActionDefinition;
  llmRun: EnhancedLlmRun;
  args: TaskArgument[];
  prompts: Prompt[];
  showInternalActions: boolean;
}

export default function ArgRenderer({
  action,
  llmRun,
  args,
  prompts,
  showInternalActions,
}: Props) {
  if (!args) {
    return null;
  }
  return (
    <Table>
      <tbody>
        {args?.map((arg, index) => (
          <Argument
            key={index}
            index={index}
            actionArg={action?.args[index]}
            arg={arg}
            llmRun={llmRun}
            prompts={prompts}
            showInternalActions={showInternalActions}
          />
        ))}
      </tbody>
    </Table>
  );
}
