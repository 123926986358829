import React, {Component} from "react";
import {connect} from "react-redux";
import ClauseBankComponent from "../components";
import {isInitialised} from "utils/uninitialised";

import CircularProgress from "@material-ui/core/CircularProgress";

class ClauseBankContainer extends Component {
  render() {
    if (!this.isInitialised()) {
      return (
        <CircularProgress
          style={{
            position: "absolute",
            top: "45%",
            left: "50%",
          }}
        />
      );
    }
    return <ClauseBankComponent clauseBankData={this.props.clauseBank} />;
  }
  isInitialised() {
    return isInitialised([this.props.clauseBank]);
  }
}

function mapStateToProps(state) {
  return {
    clauseBank: state.clause_bank,
  };
}

export default connect(mapStateToProps, null)(ClauseBankContainer);
