import React, {useState} from "react";
import {Link} from "react-router";
import _ from "lodash";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import CheckBoxBasic from "common_components/inputs/checkbox_basic";
import makeComponentHoverable from "common_components/hocs/make_component_hoverable";
import issuesetUtils from "common/utils/issues/issueset_utils";

const styles = {
  link: {
    textDecoration: "none",
    display: "table",
    color: "inherit",
    height: "100%",
    width: "100%",
  },
  headerCell: {
    padding: 0,
    whiteSpace: "nowrap",
    fontSize: 13,
    color: "#232e38",
  },
  checkboxIcon: {
    width: "16px",
    height: "16px",
  },
};

function IssuesetList(props) {
  const {issuesets} = props;
  const [showAll, updateShowAll] = React.useState(false);
  const issuesetsToShow =
    !showAll && issuesets.length > 3 ? issuesets.slice(0, 3) : issuesets;
  function onShowAllTrigger() {
    updateShowAll(!showAll);
  }
  const Icon = showAll ? ExpandLessIcon : MoreHorizIcon;
  return (
    <div style={{fontSize: "12px"}}>
      {issuesetsToShow.map((issueset, index) => (
        <div key={index}>{issueset.name}</div>
      ))}
      {issuesets.length > 3 ? (
        <Icon onClick={onShowAllTrigger} style={{cursor: "pointer"}} />
      ) : null}
    </div>
  );
}

function IssueListItem(props) {
  const {issue, linkPath} = props;
  return (
    <tr
      onMouseEnter={props.onHoverStart}
      onMouseLeave={props.onHoverFinish}
      style={props.isEven ? {backgroundColor: "#fafaff"} : {}}
    >
      <td style={{textAlign: "center"}}>{issue.id}</td>
      <td
        style={{
          textDecoration: props.isHovered ? "underline" : "inherit",
          color: props.isHovered ? "#2196F3" : "inherit",
        }}
      >
        {wrapCellContentIntoLink(issue.name, linkPath)}
      </td>
      <td style={{paddingLeft: "6px"}}>
        {issue.display_name ? issue.display_name : "-"}
      </td>
      <td>
        {issue.contract_types.map((ct, ctIndex) => (
          <div key={`${issue.id}-contractType-${ctIndex}`}>{ct.name}</div>
        ))}
      </td>
      <td>
        <IssuesetList issuesets={issue.issuesets} />
      </td>
      <td>
        {(issue.used_in || []).map(
          (item, fieldIndex) =>
            item.isOverride ? (
              <div style={{color: "#2196F3"}} key={fieldIndex}>
                {wrapCellContentIntoLink(
                  item.field,
                  `${linkPath}?tab=overrides`,
                )}
              </div>
            ) : (
              <div key={fieldIndex}>{item.field}</div>
            ),
        )}
      </td>
    </tr>
  );
}

const IssueListItemHoverable = makeComponentHoverable(IssueListItem);

function IssuesList(props) {
  const {label, issues} = props;
  const [isShown, updateIsShown] = React.useState(issues.length > 0);

  function triggerIsShown() {
    updateIsShown(!isShown);
  }

  function renderBody() {
    if (isShown) {
      return (
        <>
          {issues.length === 0 ? (
            <div
              style={{
                marginLeft: 24,
                color: "#798087",
              }}
            >
              No Issues Found
            </div>
          ) : (
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead>
                <tr style={{borderBottom: "1px solid lightgray"}}>
                  <th
                    style={{
                      ...styles.headerCell,
                      textAlign: "center",
                      width: "4%",
                    }}
                  >
                    Id
                  </th>
                  <th
                    style={{
                      ...styles.headerCell,
                      textAlign: "left",
                      width: "24%",
                    }}
                  >
                    Name
                  </th>
                  <th
                    style={{
                      ...styles.headerCell,
                      textAlign: "left",
                      width: "24%",
                      paddingLeft: "6px",
                    }}
                  >
                    Display Name
                  </th>
                  <th
                    style={{
                      ...styles.headerCell,
                      textAlign: "left",
                      width: "14%",
                    }}
                  >
                    Contract Types
                  </th>
                  <th
                    style={{
                      ...styles.headerCell,
                      textAlign: "left",
                      width: "15%",
                    }}
                  >
                    Issuesets
                  </th>
                  <th
                    style={{
                      ...styles.headerCell,
                      textAlign: "left",
                      width: "19%",
                    }}
                  >
                    Used In
                  </th>
                </tr>
              </thead>
              <tbody style={{color: "#798087", fontSize: 14}}>
                {issues.map((issue, index) => (
                  <IssueListItemHoverable
                    key={`${issue.name}-${index}`}
                    isEven={index % 2 === 0}
                    issue={issue}
                    linkPath={`/organisation/${props.organisationId}/issue/${
                      issue.id
                    }`}
                  />
                ))}
              </tbody>
            </table>
          )}
        </>
      );
    }
  }

  const ExpandIcon = isShown ? ExpandMoreIcon : KeyboardArrowRightIcon;
  return (
    <div style={{marginBottom: 10}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          fontWeight: 600,
          marginBottom: 8,
        }}
        onClick={triggerIsShown}
      >
        <ExpandIcon />
        <div>{`${label} (${issues.length})`}</div>
      </div>
      {renderBody()}
    </div>
  );
}

const groups = ["triggering_logic", "additional_clauses", "template_text"];

function ReferencedIssues(props) {
  const {mainEntity} = props; // topic or topicparameter
  const [showArchived, onShowArchivedChange] = useState(false);
  const onShowArchived = () => onShowArchivedChange(!showArchived);

  const groupedIssuesBySource = groupByReferenceSource(
    mainEntity,
    props.issues,
    props.contractTypesById,
    showArchived,
  );

  return (
    <div style={{overflow: "auto"}}>
      <CheckBoxBasic
        checked={showArchived}
        onCheck={onShowArchived}
        label="Show Archived"
        containerStyles={{marginBottom: "1rem"}}
      />
      {groups.map(source => (
        <IssuesList
          key={source}
          organisationId={props.organisationId}
          {...groupedIssuesBySource[source]}
        />
      ))}
    </div>
  );
}

function wrapCellContentIntoLink(cell, pathname) {
  if (!pathname) {
    return cell;
  }
  return (
    <Link to={{pathname}} style={styles.link} target="_new">
      {cell}
    </Link>
  );
}

const checkFields = [
  "additional_applicable_clause_topics",
  "non_triggered_additional_applicable_clause_topics",
  "reason_template",
  "positive_reason",
  "detailed_reason",
  "detailed_positive_reason",
  "about_description",
  "guidance",
  "positive_guidance",
  "standard_language",
  "email_template",
  "fallback_guidance",
];

function getIssueUsedFieldsMap(issue, mainId, issuesetsById) {
  const resultMap = checkFields.reduce((accum, item) => {
    accum[item] = [];
    return accum;
  }, {});

  populateResultMap(resultMap, issue, mainId, issuesetsById, "");

  const issuesets = Object.values(issuesetsById || {}) || [];
  const {override_values: overrideValues} = issue;

  Object.keys(overrideValues || {}).forEach(masterId => {
    const masterValue = overrideValues[masterId];
    Object.keys(masterValue || {}).forEach(clientIdStr => {
      const clientId =
        clientIdStr === "master" ? "master" : parseInt(clientIdStr, 10);
      const overrides = masterValue[clientId] || {};
      const overriddenIssue = {
        ..._.pick(issue, ["id", "name", "display_name", "contract_types"]),
        ...overrides,
      };

      const issueset = issuesets.find(
        is =>
          clientId === "master"
            ? is.master_id === masterId && !is.is_duplicate_on_master
            : is.master_id === masterId && is.remote_client_id === clientId,
      );

      let prefix = "";
      if (issueset) {
        prefix = !issueset.is_duplicate_on_master
          ? issueset.name
          : `${issueset.remote_client_name}: "${
              issueset.client_issueset_name
            }"`;
        prefix = `${prefix} - `;
      }
      populateResultMap(
        resultMap,
        overriddenIssue,
        mainId,
        issuesetsById,
        prefix,
      );
    });
  });
  return resultMap;
}

function populateResultMap(
  resultMap,
  issue,
  mainId,
  issuesetsById,
  fieldPrefix,
) {
  checkFields.forEach(fieldName => {
    if (checkValue(issue, mainId, fieldName)) {
      resultMap[fieldName].push({
        field: `${fieldPrefix}${fieldName}`,
        // if prefix provided the field comes from override
        isOverride: Boolean(fieldPrefix),
      });
    }
  });
}

function groupByReferenceSource(
  mainEntity,
  allIssues,
  contractTypesById,
  showArchived,
) {
  const {id: mainId, referenced_issues: referencedIssues} = mainEntity;
  const issuesetsById = issuesetUtils.getIssuesetsById(contractTypesById);

  const result = {
    triggering_logic: {
      issues: [],
      label: "Triggering Logic",
    },
    additional_clauses: {
      issues: [],
      label: "Additional Clauses",
    },
    template_text: {
      issues: [],
      label: "Template Text",
    },
  };

  (allIssues || []).forEach(issue => {
    const {contract_types: issueContractTypes, id: issueId} = issue;
    const visibleIssueContractTypes = issueContractTypes.filter(
      iCt => contractTypesById[iCt.contract_type_id].is_visible,
    );

    if (
      (issue.is_archived || visibleIssueContractTypes.length === 0) &&
      !showArchived
    ) {
      return;
    }

    const issueUsedFieldsMap = getIssueUsedFieldsMap(
      issue,
      mainId,
      issuesetsById,
    );

    const usedIn = Object.values(issueUsedFieldsMap).reduce(
      (accum, usedInArray) => accum.concat(usedInArray),
      [],
    );

    const resultItem = getIssueResultingItem(issue, issuesetsById, usedIn);

    if (
      (referencedIssues || []).find(
        referencedIssueId => referencedIssueId === issueId,
      )
    ) {
      result.triggering_logic.issues.push(resultItem);
    } else if (
      issueUsedFieldsMap["additional_applicable_clause_topics"].length > 0 ||
      issueUsedFieldsMap["non_triggered_additional_applicable_clause_topics"]
        .length > 0
    ) {
      result.additional_clauses.issues.push(resultItem);
    } else if (usedIn.length > 0) {
      result.template_text.issues.push(resultItem);
    }
  });
  return result;
}

function getIssueResultingItem(issue, issuesetsById, usedIn) {
  const issuesetIds = issuesetUtils.getIssuesetIdsPresentInContractTypes(
    issue.contract_types,
  );
  const resultItem = {
    ..._.pick(issue, ["id", "name", "display_name", "contract_types"]),
    issuesets: issuesetUtils.constructIssuesetSelectorItems(
      issuesetIds,
      issuesetsById,
      true,
    ),
    used_in: usedIn,
  };
  return resultItem;
}

function checkValue(issue, topicId, fieldName) {
  if (!fieldName || !issue || !topicId) {
    return null;
  }
  const topicIdInt = parseInt(topicId, 10);

  const field = issue[fieldName];
  if (!field) {
    return null;
  }

  if (
    (Array.isArray(field) && field.find(id => id === topicIdInt)) ||
    (typeof field === "string" && field.indexOf(`[${topicId}]`) !== -1)
  ) {
    return fieldName;
  }
}

export default ReferencedIssues;
