import BaseRoute from "utils/base_route";

import DefinitionGroupList from "./containers/definition_group_list";

export default class DefinitionGroupListRoute extends BaseRoute {
  constructor(store) {
    super("list", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, DefinitionGroupList);
  }
}
