import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {WorkflowContext} from "common/flowmaster/types/workflow";

export type ContextsFetchAction = {
  type: WorkflowsActionTypes["CONTEXTS_FETCH"]["SUCCESS"];
  payload: WorkflowContext[];
};

export default function getContexts(requestor) {
  return createReduxPromiseAction(
    ActionTypes.CONTEXTS_FETCH,
    (organisationId: OrganisationId) => {
      return requestor
        .get(`${basePath}/organisation/${organisationId}/context`, {
          withCredentials: true,
        })
        .then(response => {
          return response.data;
        });
    },
  );
}
