import basePath from "base_path";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes, {UsergroupActionTypes} from "../constants/action_types";
import {Usergroup} from "common/types/usergroup";

export type EditUsergroupAction = {
  type: UsergroupActionTypes["USERGROUP_EDIT"]["SUCCESS"];
  payload: Usergroup;
};

export default createReduxPromiseAction(
  ActionTypes.USERGROUP_EDIT,
  (organisationId: number, usergroupId: number, newName: string) =>
    requestor
      .patch(
        `${basePath}/organisation/${organisationId}/usergroup/${usergroupId}`,
        {name: newName},
        {withCredentials: true},
      )
      .then(() => ({id: usergroupId, name: newName})),
);
