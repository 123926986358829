import React from "react";
import styled from "styled-components";

import StringArgValue from "./string";

import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";
import {TextMapArgument} from "common/flowmaster/types/task_config";
import {Prompt} from "common/flowmaster/types/workflow";

interface Props {
  llmRun: EnhancedLlmRun;
  arg: TextMapArgument["value"];
  prompts: Prompt[];
}

const Table = styled.table`
  width: 100%;
  table-layout: fixed;

  th:nth-child(1),
  td:nth-child(1) {
    width: 5em;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 4em;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 100%;
  }
`;

export default function TextMap({arg, llmRun, prompts}: Props) {
  return (
    <Table>
      <thead>
        <th>Key</th>
        <th>Type</th>
        <th>Value</th>
      </thead>
      <tbody>
        {Object.entries(arg).map(([key, value]) => (
          <tr key={key}>
            <td>{key.replaceAll(/_/g, " ")}</td>
            <td>{value?.source_type ?? "unknown"}</td>
            <td>
              <StringArgValue arg={value} llmRun={llmRun} prompts={prompts} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
