export function getHasMatchBorderStyle(isCorrect, useRadius) {
  if (isCorrect === undefined) {
    return {};
  }

  return {
    borderTop: `2px solid ${isCorrect ? "limegreen" : "red"}`,
    borderRadius: useRadius ? "4px" : "0px",
  };
}

export default getHasMatchBorderStyle;
