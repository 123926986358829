import React from "react";

import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";
import {
  DataLocation,
  InputDataLocationObject,
} from "common/flowmaster/types/data_location";
import {Prompt} from "common/flowmaster/types/workflow";
import TextCopier from "../../text_copier";

interface Props {
  llmRun: EnhancedLlmRun;
  arg: DataLocation<string> | DataLocation<number>;
  prompts: Prompt[];
}

export default function StringArgValue({arg, llmRun, prompts}: Props) {
  switch (arg?.source_type) {
    case "context": {
      return <div>{arg.key}</div>;
    }
    case "input": {
      return (
        <div>
          {(llmRun?.inputs ?? []).find(
            input =>
              input.id ===
              parseInt((arg as InputDataLocationObject).key.toString(), 10),
          )?.name ?? arg.key}
        </div>
      );
    }
    case "task": {
      return <div>{renderTask(llmRun, arg)}</div>;
    }
    case "prompt": {
      return (
        <div>
          {prompts.find(prompt => prompt.id === arg.key)?.name ?? arg.key}
        </div>
      );
    }
    case "literal": {
      const value = (arg.value ?? "").toString();
      return <TextCopier text={value} />;
    }
  }
  return (
    <TextCopier
      text={
        typeof arg === "string" || typeof arg === "number"
          ? arg
          : JSON.stringify(arg, null, 2)
      }
    />
  );

  function renderTask(llmRun, arg) {
    const task = llmRun.tasks.find(task => task.id === arg.task);
    if (!task) {
      return "task not found";
    }
    return `${task?.name ?? arg.task}${task.value ? `.${task.value}` : ""}`;
  }
}
