import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "sockjs-client/lib/transport/xdr-streaming.js";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "react-select/dist/react-select.css";

import {initSentry} from "utils/sentry_utils";
import {unregister} from "./registerServiceWorker";
unregister();

initSentry("web");

ReactDOM.render(<App />, document.getElementById("root"));
