import basePath from "base_path";
import ActionTypes from "../../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function removeDefinitionGroup(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.DEFINITION_GROUP_REMOVE,
    (organisationId, definitionGroupId, last_edited) => {
      return requestor
        .delete(
          `${basePath}/organisation/${organisationId}/definition_group/${definitionGroupId}`,
          {
            data: {last_edited},
            withCredentials: true,
          },
        )
        .then(response => response.data)
        .catch(err => err.data);
    },
  );
  return action;
}
