import React from "react";
import _ from "underscore";
import requestor from "requestor";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import WorkflowListComponent from "../components/workflow_list";

import addWorkflowAction from "modules/flowmaster/actions/workflow_add";

import {
  WorkflowContext,
  WorkflowId,
  WorkflowInfo,
} from "common/flowmaster/types/workflow";
import {User} from "common/types/user";
import {OrganisationId} from "common/types/organisation";
import flushAssistantsAction from "modules/flowmaster/actions/flush_assistants";
import updateWorkflowAction from "modules/flowmaster/actions/workflow_update";

export interface FlushAssistantsResponse {
  value: {
    success: boolean;
    deleted_assistants: number;
    deleted_files: number;
    deleted_vector_stores: number;
    errors: string[];
  };
}

type Props = {
  workflows: WorkflowInfo[];
  contexts: WorkflowContext[];
  user: User;
  params: {
    organisationId: string;
  };
  addWorkflow: (organisationId: OrganisationId, data: {name: string}) => void;
  updateWorkflow: (
    organisationId: OrganisationId,
    workflowId: WorkflowId,
    data: {is_star?: boolean; is_archived?: boolean; last_edited: Date},
  ) => void;
  flushAssistants: (organisationId: OrganisationId) => FlushAssistantsResponse;
};

function WorkflowListContainer(props: Props) {
  const shouldRenderContainer = isInitialised([
    props.workflows,
    props.user,
    props.contexts,
  ]);
  if (!shouldRenderContainer || !props.user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const permissioner = new Permissioner(props.user);
  if (!permissioner.hasPermission("get-workflows") || !permissioner.isAdmin()) {
    return permissioner.getNoPermissionMessage();
  }

  const {organisationId: organisationIdStr} = props.params;
  const organisationId = parseInt(organisationIdStr, 10);

  function addWorkflow(name: string) {
    props.addWorkflow(organisationId, {name});
  }

  async function flushAssistants() {
    return props.flushAssistants(organisationId);
  }

  function updateWorkflow(
    workflowId: WorkflowId,
    lastEdited: Date,
    data: {is_star?: boolean; is_archived?: boolean},
  ) {
    return props.updateWorkflow(organisationId, workflowId, {
      last_edited: lastEdited,
      ...data,
    });
  }

  setTitle("Workflow List");
  return (
    <WorkflowListComponent
      organisationId={organisationId}
      workflows={_.sortBy(props.workflows, workflow => workflow.name)}
      addWorkflow={addWorkflow}
      updateWorkflow={updateWorkflow}
      contexts={props.contexts}
      flushAssistants={flushAssistants}
    />
  );
}

function select(state, props) {
  return {
    params: props.params,
    organisation: state.organisation,
    router: state.router,
    user: state.user,
    workflows: state.workflows,
    contexts: state.contexts,
    contractTypes: state.contract_types,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        addWorkflow: addWorkflowAction(requestor),
        updateWorkflow: updateWorkflowAction(requestor),
        flushAssistants: flushAssistantsAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(WorkflowListContainer);
