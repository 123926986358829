import React from "react";

import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";

import searchStyles from "routes/search/components/styles";
import usePartiallyControlledState from "utils/hooks/use_partially_controlled_state";

type SearchFieldProps = {
  searchText: string;
  onChangeSearchText: (newValue: string) => void;

  title: string;
  textFieldStyle?: {[key: string]: string};
  autoFocus?: boolean;
};

const SearchField = ({
  searchText,
  onChangeSearchText,
  textFieldStyle = {},
  title,
  autoFocus,
}: SearchFieldProps) => {
  // To keep the text input responsive, we store its content in a local state
  // and only synchronise with the parent on blur.
  const [
    localSearchText,
    setLocalSearchText,
    synchronise,
  ] = usePartiallyControlledState(searchText, onChangeSearchText);

  return (
    <div style={searchStyles.toolbarGroupBlock}>
      <SearchIcon style={searchStyles.toolbarGroupIcon} />
      <div style={searchStyles.searchFieldContainer}>
        <TextField
          id="search"
          placeholder={title}
          value={localSearchText}
          onKeyDown={e => {
            if (e.key === "Enter") {
              synchronise();
            }
          }}
          onChange={e => setLocalSearchText(e.target.value)}
          onBlur={synchronise}
          autoComplete="off"
          autoFocus={autoFocus}
          style={{
            height: 24,
            marginBottom: "5px",
            ...textFieldStyle,
          }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </div>
    </div>
  );
};

export default SearchField;
