import React from "react";
import {FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";

import {InputDataLocationObject} from "common/flowmaster/types/data_location";
import {WorkflowInput, WorkflowInputs} from "common/flowmaster/types/workflow";

interface InputSelectorProps {
  location: InputDataLocationObject;
  inputs: WorkflowInputs;
  onUpdateItem: (value: InputDataLocationObject) => void;
  type: WorkflowInput["type"];
}

const InputSelector: React.FC<InputSelectorProps> = ({
  location,
  inputs,
  onUpdateItem,
  type,
}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="input-value-selector-label">Key</InputLabel>
      <Select
        labelId="input-value-selector-label"
        value={location.key}
        onChange={event =>
          onUpdateItem({...location, key: event.target.value as number})
        }
        label="Value"
      >
        {inputs.values
          .filter(input => input.type === type)
          .map(item => (
            <MenuItem value={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default InputSelector;
