import requestor from "requestor";

import ChildRenderer from "common_components/child_renderer";

import BaseRoute from "utils/base_route";

import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";
import contractTypesClearAction from "modules/documents/actions/contract_types_clear";

import ContractTypeList from "routes/contract_type/contract_type_list";
import ContractTypeDetail from "routes/contract_type/contract_type_detail";

export default class ContractTypeBase extends BaseRoute {
  constructor(store) {
    super("contract_type", store);
  }

  createChildRoutes() {
    return [
      new ContractTypeList(this.store),
      new ContractTypeDetail(this.store),
    ];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchContractTypes(state);
  }

  fetchContractTypes(state) {
    const {organisationId} = state.params;
    this.store.dispatch(contractTypesFetchAction(requestor)(organisationId));
  }

  clearActiveData(state) {
    const {organisationId} = state.params;
    this.store.dispatch(contractTypesClearAction(organisationId));
  }
}
