import getRegexWithModules from "./get_regex_with_modules";

const getRegexClassifier = (props) => {
  const regexClassifier = {
    ...props.classifiers.find(classifier => classifier.name === "regex"),
  };

  if (!regexClassifier) {
    return null;
  }

  const {templateModules} = props;
  const modulesValues = templateModules.reduce((acc, module) => {
    acc[module.key] = module.value[0];
    return acc;
  }, {});

  if (regexClassifier.value) {
    regexClassifier.value.whitelistRe = (
      regexClassifier?.value.whitelist || []
    ).map(reText => {
      try {
        return new RegExp(
          getRegexWithModules(reText, null, modulesValues),
          "g",
        );
      } catch {
        return /XXX_MATCH_NOTHING/g;
      }
    });

    regexClassifier.value.blacklistRe = (
      regexClassifier?.value.blacklist || []
    ).map(reText => {
      try {
        return new RegExp(
          getRegexWithModules(reText, null, modulesValues),
          "g",
        );
      } catch {
        return /XXX_MATCH_NOTHING/g;
      }
    });
  }

  return regexClassifier;
};

export default getRegexClassifier;
