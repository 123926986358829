import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function revertAllDocumentReasonStates(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_STATE_REVERT,
    (organisationId, projectId, documentId, revertOnlyChanges, lastEdited) =>
      requestor["delete"](
        `${basePath}/organisation/${organisationId}` +
          `/project/${projectId}/document/${documentId}/revert`,
        {
          data: {last_edited: lastEdited, revertOnlyChanges},
          withCredentials: true,
        },
      ).then(response => ({
        id: documentId,
        revertOnlyChanges,
        ...response.data,
      })),
  );
}
