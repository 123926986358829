import {WorkflowTask} from "common/flowmaster/types/task_config";
import {WorkflowMetaOverrides} from "common/flowmaster/types/workflow";

function applyWorkflowTaskOverrides(
  tasks: WorkflowTask[],
  overrides: WorkflowMetaOverrides,
) {
  return tasks.map(task => {
    const taskOverride = overrides[task.id] || overrides[task.name];
    if (taskOverride) {
      task.args = task.args.map((arg, index) => {
        if (taskOverride[index]) {
          return {
            value: {
              value: taskOverride[index],
              source_type: "literal",
            },
          };
        }
        return arg;
      });
    }
    return task;
  });
}

export default applyWorkflowTaskOverrides;
