import _ from "underscore";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router";

import Switch from "@material-ui/core/Switch";

import Typography from "@material-ui/core/Typography";
import LinkIcon from "@material-ui/icons/Link";
import ArchiveIcon from "@material-ui/icons/Archive";
import StarRateIcon from "@material-ui/icons/StarRate";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import PlaybookIcon from "@material-ui/icons/AssignmentTurnedIn";

import groupIssuesForChecklist from "common/utils/issues/group_issues_for_checklist";

import eliminateSimilarIssues from "common/utils/issues/eliminate_similar_issues";
import calculateIssueOverrides from "utils/issues/calculate_issue_overrides";

import PlaybookEditor from "common_components/issue_detail/playbook_editor";
import ToolbarCheckbox from "common_components/toolbar/checkbox";
import ArchivedChip from "common_components/project/archived_project_chip";
import WhatshotIcon from "@material-ui/icons/Whatshot";

const getTemplateIssueset = (clientIssueset, issuesets) => {
  if (!clientIssueset.remote_client_id) {
    return null;
  }
  return issuesets.find(
    issueset =>
      !issueset.remote_client_id &&
      issueset.master_id === clientIssueset.master_id,
  );
};

export default class PlaybooksComponent extends Component {
  constructor(props) {
    super(props);
    const firstContractType = props.contractTypes.find(ct =>
      (ct.issuesets || []).find(issueset => !issueset.is_archived),
    );
    const firstIssueset = this.getIssuesets(firstContractType)[0];
    this.state = {
      selectedContractType: firstContractType,
      selectedIssueset: firstIssueset,
      issueState: {},
      showArchivedIssuesets: false,
      showArchivedIssues: false,
    };
  }

  render() {
    const {selectedIssue} = this.state;

    return (
      <div className="app-page">
        <div className="app-toolbar" style={{justifyContent: "flex-start"}}>
          <PlaybookIcon style={{marginRight: 8, color: "#1f88e5"}} />
          <Typography
            style={{marginRight: 18}}
            color="textSecondary"
            variant="h6"
          >
            Playbooks
          </Typography>
          {this.renderCheckboxes()}
        </div>
        <div
          className="app-body"
          style={{display: "flex", overflowY: "hidden"}}
        >
          {this.renderContractTypes()}
          {this.renderIssues()}
          <div
            style={{
              flexGrow: 1,
              maxWidth: "30em",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {selectedIssue && (
              <PlaybookEditor issue={selectedIssue} normalDisplay={true} />
            )}
          </div>
        </div>
      </div>
    );
  }

  renderCheckboxes = () => {
    const checkboxesDataArray = [
      {
        name: "showArchivedIssuesets",
        label: "Show Archived Issuesets",
        handler: this.triggerShowArchivedIssuesets,
      },
      {
        name: "showArchivedIssues",
        label: "Show Archived Issues",
        handler: this.triggerShowArchivedIssues,
      },
    ];
    return checkboxesDataArray.map(item => (
      <ToolbarCheckbox
        key={item.name}
        checked={this.state[item.name]}
        label={item.label}
        onCheck={item.handler}
      />
    ));
  };

  renderContractTypes() {
    const {contractTypes} = this.props;
    return (
      <div style={{width: "30em", paddingTop: "1rem", overflowY: "scroll"}}>
        {contractTypes.map(contractType => (
          <div value={contractType.id} key={contractType.id}>
            <div
              style={{
                fontWeight: "bold",
                padding: "1rem",
                margin: "0 1rem",
                border: "1px solid #444",
                backgroundColor: "#ccc",
              }}
            >
              {contractType.name}
            </div>
            <div>{this.renderIssuesetList(contractType)}</div>
          </div>
        ))}
      </div>
    );
  }

  renderIssuesetList(contractType) {
    const issuesets = this.getIssuesets(contractType);
    const {selectedIssueset} = this.state;
    return (
      <div
        style={{
          margin: "0 1rem",
        }}
        key="issueset-list"
      >
        {issuesets.map(issueset => (
          <IssuesetItem
            key={issueset.id}
            issueset={issueset}
            templateIssueset={getTemplateIssueset(issueset, issuesets)}
            isSelected={issueset.id === selectedIssueset.id}
            onSelectIssueset={this.selectIssueset(contractType, issueset)}
            onStarIssueset={this.starIssueset(
              contractType.id,
              issueset.id,
              issueset.is_star,
            )}
          />
        ))}
      </div>
    );
  }

  renderIssues() {
    const {issuesById} = this.props;
    const {selectedContractType, selectedIssueset} = this.state;
    const contractType = this.props.contractTypes.find(
      ct => ct.id === selectedContractType.id,
    );
    const issueset = this.getIssuesets(contractType).find(
      iset => iset.id === selectedIssueset.id,
    );
    const {issues: issueIds} = selectedIssueset;
    const issues = issueIds.reduce((accum, issueId) => {
      const issue = issuesById[issueId];
      if (issue.is_archived && !this.state.showArchivedIssues) {
        return accum;
      }
      accum.push({...issue, name: issue.display_name || issue.name});
      return accum;
    }, []);

    const issuesWithOverridees = calculateIssueOverrides(
      issues,
      selectedIssueset,
      false,
    );
    const collapsedIssues = eliminateSimilarIssues(issuesWithOverridees);
    const groupedIssues = groupIssuesForChecklist(collapsedIssues);
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            top: 0,
            backgroundColor: "white",
            fontWeight: "bold",
            color: "black",
            padding: "1em",
            zIndex: 1,
            display: "flex",
            justifyContent: "space-between",
            height: 30,
          }}
        >
          <div />
          {selectedContractType.name} - {issueset.name}
          <div
            title={`${issueset.is_archived ? "Unarchive" : "Archive"} Issueset`}
          >
            <ArchiveIcon
              style={{
                cursor: "pointer",
                color: !issueset.is_archived ? "#424242" : "#aaa",
              }}
              onClick={() =>
                this.archiveIssueset(
                  selectedContractType.id,
                  issueset.id,
                  issueset.is_archived,
                )
              }
            />
          </div>
        </div>
        <div
          style={{
            padding: "10px 0px",
            backgroundColor: "#232e38",
            color: "#fff",
            height: "calc(100vh - 125px - 30px)",
            overflow: "auto",
          }}
        >
          {groupedIssues.map((issueGroup, index) => {
            return (
              <div key={`${issueGroup.name}-${index}`}>
                <div
                  style={{
                    fontWeight: "bold",
                    padding: "1rem",
                    margin: "0 1rem",
                    border: "1px solid white",
                  }}
                >
                  {issueGroup.name}
                </div>
                <div
                  style={{
                    backgroundColor: "#353f48",
                    margin: "0 1rem",
                  }}
                >
                  {issueGroup.item.map(issue => this.renderIssue(issue))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderIssueLink(issue) {
    const issuesetId = this.state.selectedIssueset.id;
    const {projects} = this.props;
    const projectId =
      this.state.selectedContractType.projects.find(projectId => {
        const project = projects.find(_project => _project.id === projectId);
        if (project) {
          return project.issuesets.find(
            projIssuesetId => issuesetId === projIssuesetId,
          );
        }
        return null;
      }) || this.state.selectedContractType.projects[0];

    const path = `/organisation/${this.props.organisationId}/issue/${issue.id}`;
    const query = `?open_issue_list=true&issueset=${issuesetId}&project=${projectId}&auto_select_first_document=true`;
    const uri = `${path}${query}`;
    return (
      <Link
        to={uri}
        style={{
          color: "#ccc",
          textDecoration: "none",
          cursor: "pointer",
        }}
      >
        <LinkIcon />
      </Link>
    );
  }

  renderIssue(issue) {
    const {selectedIssue, issueState} = this.state;
    const isSelected = selectedIssue && issue.id === selectedIssue.id;
    const isOn = issueState[issue.id] !== false;
    return (
      <div
        key={issue.id}
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
          border: "1px solid #eee",
          alignItems: "baseline",
          cursor: "pointer",
          ...(isSelected
            ? {
                backgroundColor: "white",
                color: "black",
              }
            : {}),
        }}
        onClick={this.selectIssue(issue)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {this.renderIssueLink(issue)}
          <span style={{marginLeft: ".7em"}}>{issue.name}</span>
          {issue.is_archived && <ArchivedChip />}
        </div>
        <Switch
          checked={isOn}
          color="primary"
          onChange={() => this.toggleIssue(issue.id)}
        />
      </div>
    );
  }
  archiveIssueset(contractTypeId, issuesetId, isArchived) {
    const newValue = !isArchived;
    if (newValue) {
      const issuesetsOfSelectedContractType = this.getIssuesets(
        this.state.selectedContractType,
      ).filter(issueset => !issueset.is_archived && issueset.id !== issuesetId);
      const updates = {};
      if (issuesetsOfSelectedContractType.length > 0) {
        updates.selectedIssueset = issuesetsOfSelectedContractType[0];
      } else {
        const contractTypeWithNonArchivedIssuesets = this.props.contractTypes.find(
          ct =>
            ct.id !== contractTypeId &&
            ct.issuesets.find(issueset => !issueset.is_archived),
        );
        const firstNonArchivedIssueset = this.getIssuesets(
          contractTypeWithNonArchivedIssuesets,
        ).find(issueset => !issueset.is_archived);
        updates.selectedContractType = contractTypeWithNonArchivedIssuesets;
        updates.selectedIssueset = firstNonArchivedIssueset;
      }
      this.setState(() => updates);
    }

    this.props.updateIssueset(contractTypeId, issuesetId, {
      is_archived: newValue,
    });
  }

  starIssueset = (contractTypeId, issuesetId, isStar) => () => {
    this.props.updateIssueset(contractTypeId, issuesetId, {
      is_star: !isStar,
    });
  };

  toggleIssue(issueId) {
    this.setState({
      issueState: {
        ...this.state.issueState,
        [issueId]: this.state.issueState[issueId] === false,
      },
    });
  }

  getIssuesets = contractType => {
    const showArchivedIssuesets = this.state
      ? this.state.showArchivedIssuesets
      : false;
    return _.chain(contractType.issuesets)
      .filter(issueset => (issueset.is_archived ? showArchivedIssuesets : true))
      .sortBy(
        issueset =>
          issueset.name +
          (issueset.remote_client_name ? issueset.remote_client_name : ""),
      )
      .value();
  };

  selectIssueset = _.memoize(
    (selectedContractType, selectedIssueset) => () => {
      this.setState({selectedContractType, selectedIssueset});
    },
    (selectedContractType, selectedIssueset) => [
      selectedContractType.id,
      selectedIssueset.id,
    ],
  );

  selectIssue = _.memoize(
    selectedIssue => () => {
      this.setState({selectedIssue});
    },
    selectedIssue => selectedIssue.id,
  );

  onTriggerShowCheckbox = stateName => () => {
    this.setState(prevState => {
      const newCheckboxValue = !prevState[stateName];
      return {[stateName]: newCheckboxValue};
    });
  };

  triggerShowArchivedIssuesets = () => {
    const newValue = !this.state.showArchivedIssuesets;
    const updates = {showArchivedIssuesets: newValue};
    if (!newValue && this.state.selectedIssueset.is_archived) {
      const firstNonArchivedIssueset = this.getIssuesets(
        this.state.selectedContractType,
      ).find(issueset => !issueset.is_archived);
      if (firstNonArchivedIssueset) {
        updates.selectedIssueset = firstNonArchivedIssueset;
      } else {
        const contractTypeWithNonArchivedIssuesets = this.props.contractTypes.find(
          ct => ct.issuesets.find(issueset => !issueset.is_archived),
        );
        const firstNonArchivedIssueset = this.getIssuesets(
          contractTypeWithNonArchivedIssuesets,
        ).find(issueset => !issueset.is_archived);
        updates.selectedContractType = contractTypeWithNonArchivedIssuesets;
        updates.selectedIssueset = firstNonArchivedIssueset;
      }
    }
    this.setState(() => updates);
  };

  triggerShowArchivedIssues = () =>
    this.setState(prevState => ({
      showArchivedIssues: !prevState.showArchivedIssues,
    }));
}

function IssuesetItem(props) {
  const {
    issueset,
    templateIssueset,
    isSelected,
    onSelectIssueset,
    onStarIssueset,
  } = props;
  const [isHovered, updateIsHovered] = React.useState(false);

  const onHoverStart = () => updateIsHovered(true);
  const onHoverFinish = () => updateIsHovered(false);
  const onStarClick = e => {
    e.stopPropagation();
    onStarIssueset();
  };
  const StarIcon = issueset.is_star
    ? StarRateIcon
    : isHovered ? StarBorderIcon : null;
  return (
    <div
      className="issuesetItem"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem",
        border: "1px solid #777",
        ...(isSelected ? {backgroundColor: "#eef"} : {}),
        cursor: "pointer",
      }}
      onClick={onSelectIssueset}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverFinish}
    >
      <div style={{display: "flex", alignItems: "center"}}>
        <div style={issueset.is_archived ? {opacity: 0.4} : {}}>
          {issueset.remote_client_id && templateIssueset
            ? templateIssueset.name
            : issueset.name}
          {issueset.remote_client_name && ` (${issueset.remote_client_name})`}
        </div>
        {issueset.is_archived && <ArchivedChip />}
        {issueset.is_hot && (
          <WhatshotIcon style={{height: 18, width: 18, color: "#ef5350"}} />
        )}
      </div>
      {StarIcon ? (
        <StarIcon
          onClick={onStarClick}
          style={{height: 26, width: 26, color: "#fbc02d"}}
        />
      ) : (
        <div style={{width: 26, height: 26}} />
      )}
    </div>
  );
}

PlaybooksComponent.propTypes = {
  organisationId: PropTypes.string,
};
