import React from "react";
import styled from "styled-components";

import Fieldset from "./styled/fieldset";
import Legend from "./styled/legend";
import HeadingCell from "./styled/heading_cell";

import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";

interface Props {
  llmRun: EnhancedLlmRun;
}

const OutputRow = styled.tr`
  & th,
  & td {
    padding: 0.25em 1em;
  }
  & th:not(:last-child),
  & td:not(:last-child) {
    border-right: 1px solid #ddd;
  }
`;

const OutputCell = styled.td`
  :not(:last-child) {
    border-right: 1px solid #ddd;
    padding-right: 1em;
  }
`;

const OutputCellValue = styled.div`
  max-height: 10em;
  overflow: auto;
`;

export default function Output({llmRun}: Props) {
  return (
    <Fieldset>
      <Legend>Output</Legend>
      <table>
        <thead>
          <OutputRow>
            <HeadingCell>key</HeadingCell>
            <HeadingCell>value</HeadingCell>
          </OutputRow>
        </thead>
        <tbody>
          {Object.entries(llmRun.data.data_store.output).map(([key, item]) => {
            return (
              <OutputRow key={key}>
                <OutputCell>{key}</OutputCell>
                <OutputCell>
                  <OutputCellValue>
                    {typeof item === "object" ? (
                      <pre>{JSON.stringify(item, null, 2)}</pre>
                    ) : (
                      item
                    )}
                  </OutputCellValue>
                </OutputCell>
              </OutputRow>
            );
          })}
        </tbody>
      </table>
    </Fieldset>
  );
}
