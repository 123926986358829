import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function addTopic(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPIC_LIST_TOPIC_ADD,
    (
      organisationId,
      name,
      isSubstantive,
      isEmbedding,
      topiccategoryId,
      contractTypeIds,
    ) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}/topic`,
          {
            organisation_id: organisationId,
            name,
            is_substantive: isSubstantive,
            is_embedding: isEmbedding,
            topiccategory_id: topiccategoryId,
            contract_type_ids: contractTypeIds,
          },
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
