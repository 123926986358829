import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function permissionGroupAdd(requestor) {
  return createReduxPromiseAction(ActionTypes.PERMISSION_GROUP_ADD, name =>
    requestor
      .post(`${basePath}/permission_group/`, {name}, {withCredentials: true})
      .then(response => response.data)
      .catch(err => err.data),
  );
}
