import React from "react";
import {IconButton, Grid, Box} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ContainedTextField from "../../contained_text_field";
import AddItem from "../../add_item";
import {SchemaEnums} from "common/flowmaster/types/schema";

interface EnumEditorProps {
  type: "string" | "number";
  values: SchemaEnums;
  onUpdate: (values: Array<string | number>) => void;
}

const EnumEditor: React.FC<EnumEditorProps> = ({type, values, onUpdate}) => {
  const handleAddValue = (value: string) => {
    if (!value.trim()) {
      return;
    }
    const newValue = type === "number" ? parseFloat(value) : value;
    if (!values.includes(newValue)) {
      const updatedValues = [...values, newValue];
      onUpdate(updatedValues);
    }
  };

  const handleRemoveValue = (value: number | string) => {
    const updatedValues = values.filter(val => val !== value);
    onUpdate(updatedValues);
  };

  const handleChangeValue = (index: number, value: string) => {
    const updatedValue = type === "number" ? parseFloat(value) : value;
    if (!values.includes(updatedValue) || values[index] === updatedValue) {
      const updatedValues = [...values];
      updatedValues[index] = updatedValue;
      onUpdate(updatedValues);
    }
  };

  return (
    <Grid
      item
      style={{display: "flex", flexDirection: "column", width: "35em"}}
    >
      {values.length > 0 && (
        <Box component={"fieldset"} style={{maxWidth: "16em", flexGrow: 1}}>
          <legend>Options</legend>
          {values.map((value, index) => (
            <div key={value} style={{marginTop: "0.2em"}}>
              <div style={{display: "flex", alignItems: "center"}}>
                <ContainedTextField
                  type={type === "number" ? "number" : "text"}
                  label={""}
                  initialValue={value as string}
                  onUpdate={val => {
                    handleChangeValue(index, val);
                  }}
                />
                <IconButton onClick={() => handleRemoveValue(value)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </Box>
      )}
      <Grid item style={{marginTop: "10px"}}>
        <AddItem
          onAdd={val => handleAddValue(val)}
          label="New enum option"
          inputType={type === "string" ? "text" : "number"}
        />
      </Grid>
    </Grid>
  );
};

export default EnumEditor;
