import React from "react";

import {TextField, Button} from "@material-ui/core";

import AlgorithmSelectors from "./algorithm_selectors";

import {TopicId} from "common/types/document";
import EmbeddingTopic from "modules/searcher/types/embedding_topic";

interface Props {
  query: string;
  setQuery: (query: string) => void;
  runQuery: () => void;
  embeddingTopics: EmbeddingTopic[];
  algorithmState: Record<string, boolean>;
  setAlgorithmState: (algorithm: string, value: boolean) => void;
  availableEmbeddings?: TopicId[];
}

export default function QueryBuilder({
  query,
  setQuery,
  runQuery,
  embeddingTopics,
  availableEmbeddings,
  algorithmState,
  setAlgorithmState,
}: Props) {
  return (
    <div style={{display: "flex", gap: "1em"}}>
      <TextField
        value={query}
        label="Query"
        onChange={event => setQuery(event.target.value)}
        style={{width: "100%"}}
      />
      <AlgorithmSelectors
        embeddingTopics={embeddingTopics}
        algorithmState={algorithmState}
        setAlgorithmState={setAlgorithmState}
        availableEmbeddings={availableEmbeddings}
      />
      <Button
        disabled={(query?.length ?? 0) === 0}
        onClick={() => runQuery()}
        variant="contained"
        color="primary"
        style={{minWidth: "7em", flexShrink: 0}}
      >
        Run
      </Button>
    </div>
  );
}
