import requestor from "requestor";

import basePath from "base_path";
import ActionTypes from "../../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_DEFINITION_ADDITION_REVERT,
  (
    organisationId,
    projectId,
    documentId,
    sectionId,
    clauseId,
    termClausepartId,
    meaningClausepartId,
    lastEdited,
  ) =>
    requestor
      .delete(
        `${basePath}/organisation/${organisationId}` +
          `/project/${projectId}` +
          `/document/${documentId}` +
          `/section/${sectionId}` +
          `/clause/${clauseId}` +
          "/definition/addition",
        {
          withCredentials: true,
          data: {
            last_edited: lastEdited,
            term_clausepart_id: termClausepartId,
            meaning_clausepart_id: meaningClausepartId,
          },
        },
      )
      .then(response => response.data),
);
