import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ClearIcon from "@material-ui/icons/Clear";

import SettingsFieldset from "./util/settings_fieldset";
import InformationPopup from "common_components/information_popup";

const styles = {
  clearIcon: {
    height: "18px",
    width: "18px",
    position: "absolute",
    top: "36px",
    right: "-20px",
    cursor: "pointer",
    color: "rgba(0, 0, 0, 0.54)",
  },
};

function getSelectorItems(issue) {
  const selectors = [
    {
      key: "open_subissues_when",
      label: "Subissues should be open when",
      items: [
        {name: "Always", value: "always"},
        {name: "Alert", value: "issue_is_triggered"},
        {name: "Non-Alert", value: "issue_is_not_triggered"},
        {name: "Never", value: "never"},
      ],
    },
    {
      key: "show_if_triggered_only",
      label: "When to show",
      items: [
        {name: "Always", value: "always"},
        {name: "Alert", value: "ifTriggered"},
        {name: "Non-Alert", value: "ifNotTriggered"},
        {name: "Never", value: "never"},
      ],
      helperText: `
        By default, any triggered issue in a Collapsible Group
        (the group) is shown in priority to any non-triggered
        issue when the issue-set is in Collapsed View. However,
        any triggered issues that are set to 'When to Show =
        Non-Alert' will be ignored if ANY issue in that group is
        triggered. If ALL issues in the group are set to 'When to
        Show = Non-Alert', the group issue will be hidden in
        Collapsed View if any issue in the group has been triggered
      `,
    },
    {
      key: "hide_subissue_when_parent",
      label: "Hide subissue when parent",
      items: [
        {name: "Never", value: "never"},
        {name: "Alert", value: "ifTriggered"},
        {name: "Non-Alert", value: "ifNotTriggered"},
      ],
      helperText:
        "This setting will only affect a child issue (setting it on the parent will do nothing!)",
    },
  ];
  if (issue.issue_type === "EMPTY_PARENT") {
    selectors.push({
      key: "empty_parent_applicable_clauses",
      label: "Empty Parent Applicable Clauses",
      items: [
        {name: "All Clauses", value: "all_clauses"},
        {name: "Triggered Clauses Only", value: "triggered_clauses_only"},
      ],
    });
  }
  return selectors;
}

function createSelectField(
  key,
  label,
  menuItems,
  helperText,
  getOverridableFieldData,
  renderOverridenIssuesets,
  canEdit,
) {
  const overrides = getOverridableFieldData("SelectFieldV4", key);

  return (
    <div key={key}>
      <div style={{position: "relative"}}>
        {helperText && (
          <InformationPopup
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            iconStyle={{
              position: "absolute",
              top: "32px",
              left: "-26px",
            }}
          >
            <div style={{width: "320px", textAlign: "justify"}}>
              {helperText}
            </div>
          </InformationPopup>
        )}
        <FormControl
          disable={(!canEdit).toString()}
          style={{
            width: "18rem",
            borderRadius: "2px",
            marginTop: "10px",
            marginBottom: "4px",
          }}
        >
          <InputLabel>{label}</InputLabel>
          <Select
            {...overrides.props}
            MenuProps={{PaperProps: {style: {maxHeight: "14rem"}}}}
          >
            {menuItems.map(item => (
              <MenuItem key={item.name} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {overrides &&
          overrides.clearProps &&
          overrides.isOverrideMode &&
          overrides.value !== overrides.baseValue && (
            <ClearIcon
              onClick={overrides.clearProps.onClick}
              style={styles.clearIcon}
            />
          )}
      </div>
      {renderOverridenIssuesets(
        key,
        {
          containerStyle: {
            marginLeft: 0,
            marginRight: 0,
            maxWidth: "18rem",
          },
        },
        value =>
          (menuItems.find(menuItem => menuItem.value === value) || {}).name,
      )}
    </div>
  );
}

function ChildSettingsPanel(props) {
  const {
    issue,
    getOverridableFieldData,
    renderOverridenIssuesets,
    canEdit,
  } = props;
  return (
    <SettingsFieldset legend="Child Settings">
      <div
        style={{
          padding: "0.2rem 1.2rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {getSelectorItems(issue).map(selector =>
          createSelectField(
            selector.key,
            selector.label,
            selector.items,
            selector.helperText,
            getOverridableFieldData,
            renderOverridenIssuesets,
            canEdit,
          ),
        )}
      </div>
    </SettingsFieldset>
  );
}

export default ChildSettingsPanel;
