import _ from "lodash";
import {
  DataLocation,
  KeyedDataLocationObject,
  TaskDataLocationObject,
} from "common/flowmaster/types/data_location";
import {
  DataStore,
  DataStoreParameterValues,
  TaskDataStoreParameterValues,
} from "common/flowmaster/types/data_store";
import {ActionOutput} from "common/flowmaster/types/workflow";

export default function getValue<T>(
  location: DataLocation<T>,
  dataStore: DataStore,
): T {
  if (location.source_type === "none") {
    return null as T;
  }
  if (location.source_type === "literal") {
    return location.value;
  }
  if (location.key === null || location.key?.toString().length === 0) {
    throw new Error("Location key is empty");
  }

  const value: T = determineValue(location, dataStore);
  return value;
}

function determineValue<T>(location: DataLocation<T>, dataStore: DataStore): T {
  const source = dataStore[location.source_type];
  switch (location.source_type) {
    case "prompt":
    case "context":
    case "input": {
      return getDataLocation(
        location,
        source as DataStoreParameterValues<string>,
      ) as T;
    }
    case "task": {
      return getTaskDataLocation(
        location,
        source as TaskDataStoreParameterValues<ActionOutput>,
      ) as T;
    }
    default:
      throw new Error("Source type does not match context/input/task/prompt");
  }
}

function getDataLocation(
  location: KeyedDataLocationObject,
  source: DataStoreParameterValues<string>,
) {
  return source[location.key];
}

export function getTaskData(
  taskKey: number,
  source: TaskDataStoreParameterValues<ActionOutput>,
) {
  const taskIndex = source.indexes[taskKey];
  return source.data[taskIndex];
}

export function getTaskDataLocation(
  location: TaskDataLocationObject,
  source: TaskDataStoreParameterValues<ActionOutput>,
) {
  const taskKey = location.task;
  const task = getTaskData(taskKey, source);

  if (task === undefined || task === null) {
    throw new Error(`Task ${taskKey} does not exist in the task data store.`);
  }
  const taskValue = task[location.key];
  if (taskValue === undefined) {
    throw new Error(`Key ${location.key} does not exist on task ${taskKey}`);
  }
  return typeof location?.value === "string" &&
    location.value.length > 0 &&
    typeof taskValue === "object"
    ? _.get(taskValue, location.value)
    : taskValue;
}
