import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

const styles = {
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  buttonsBlock: {
    display: "flex",
    gap: "10px",
  },
};

const TopicUsageBadlyParsedDialog = ({
  isOpen,
  modalClose,
  confirm,
  notConfirm,
}) => {
  return (
    <Dialog open={isOpen} onClose={modalClose}>
      <DialogTitle>
        {"Are you sure you want to mark clause as badly parsed ?"}
      </DialogTitle>
      <div style={styles.buttonsWrapper}>
        <div style={styles.buttonsBlock}>
          <Button
            onClick={notConfirm}
            variant="outlined"
            color="secondary"
            size="small"
          >
            No
          </Button>
          <Button
            onClick={confirm}
            variant="outlined"
            color="primary"
            size="small"
          >
            Yes
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default TopicUsageBadlyParsedDialog;
