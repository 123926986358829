import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ConfirmDialog from "common_components/confirm_dialog";

import {Link} from "react-router";

const styles = {fieldWrapper: {marginBottom: "1em"}};

export default function({organisationId, prompt, updatePrompt, deletePrompt}) {
  const [text, setText] = useState(null);
  const [name, setName] = useState(null);
  const [showingDeleteDialog, showDeleteDialog] = useState(false);

  const setNameValue = event => setName(event.target.value);
  const setTextValue = event => setText(event.target.value);
  const setIsSystemValue = async event =>
    save({is_system: event.target.checked});

  const save = async newValues => {
    if (text || name || Object.keys(newValues || {}).length > 0) {
      await updatePrompt({text, name, ...newValues});
      setName(null);
      setText(null);
    }
  };

  const onDelete = () => {
    deletePrompt();
  };

  return (
    <div style={{padding: "1em", display: "flex"}}>
      <div style={{width: "100%"}}>
        <h1>Prompt editor</h1>
        <div style={styles.fieldWrapper}>
          <TextField
            label="Name"
            value={name || prompt.name}
            onChange={setNameValue}
            onBlur={() => save()}
          />
        </div>
        <div style={styles.fieldWrapper}>
          <TextField
            label="Text"
            multiline={true}
            value={text || prompt.text}
            onChange={setTextValue}
            onBlur={() => save()}
            style={{width: "100%", maxWidth: "50em"}}
          />
        </div>
        <div style={styles.fieldWrapper}>
          <FormControlLabel
            control={
              <Checkbox
                checked={prompt.is_system}
                onChange={setIsSystemValue}
              />
            }
            label="Is system prompt"
          />
        </div>
        <Button variant="contained" onClick={() => showDeleteDialog(true)}>
          Delete
        </Button>
      </div>
      <Link
        style={{
          fontWeight: "bold",
          width: "5em",
          flexShrink: 0,
          color: "inherit",
          textDecoration: "none",
        }}
        to={`/organisation/${organisationId}/prompt/list`}
      >
        Back
      </Link>

      <ConfirmDialog
        open={showingDeleteDialog}
        onSuccess={onDelete}
        onClose={() => showDeleteDialog(false)}
        title="Delete prompt"
        description="Are you sure you want to delete this prompt?"
        okButtonCaption="Yes"
        cancelButtonCaption="No"
      />
    </div>
  );
}
