import _ from "underscore";

export default function getTopicParameters(reports) {
  return reports.reduce((acc, report) => {
    const {parameters} = report;
    if (parameters && parameters.length > 0) {
      const newParams = [];
      parameters.forEach(param => {
        if (param && !acc.find(el => el.id === param.id)) {
          newParams.push(_.omit(param, "values"));
        }
      });
      return [...acc, ...newParams];
    }
    return acc;
  }, []);
}
