import React, {useEffect, useRef, useState} from "react";
import _ from "lodash";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/PlaylistAdd";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import usePrevious from "utils/hooks/use_previous";

const styles = {
  cardHeader: {
    height: "2.5rem",
    lineHeight: "2.4rem",
    paddingLeft: "2rem",
    background: "#fff",
    fontSize: "17px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "space-between",
  },
  addItemButton: {
    margin: "0.2rem",
    marginLeft: "1.5rem",
    marginTop: "1rem",
  },
  aliasContainer: {
    display: "flex",
    alignItems: "center",
    color: "#616161",
  },
  deleteIcon: {
    cursor: "pointer",
    marginLeft: 6,
  },
};

function Alias(props) {
  const {alias, baseAliases} = props;

  const textInput = useRef(null);
  useEffect(() => {
    if (props.focusOnMount) {
      textInput.current.focus();
    }
  }, []);

  const [error, setError] = useState("");
  const clearError = () => setError("");

  function handleAliasUpdate(e) {
    const newAlias = e.target.value;
    if (newAlias && newAlias === alias) {
      return;
    }
    if (!newAlias) {
      return setError("Please provide alias text");
    }

    const existingAliasIndex = baseAliases.findIndex(
      roleAlias =>
        roleAlias.trim().toLowerCase() === newAlias.trim().toLowerCase(),
    );

    if (existingAliasIndex !== -1) {
      return setError(
        `Provided alias already exists (index: ${existingAliasIndex + 1})`,
      );
    }
    props.updateAlias(newAlias, props.index);
  }

  function handleAliasRemove() {
    props.removeAlias(props.index);
  }

  return (
    <div style={styles.aliasContainer}>
      <div
        style={{
          width: "1rem",
          textAlign: "right",
          marginRight: "0.5rem",
        }}
      >
        {`${props.index + 1}.`}
      </div>
      <TextField
        style={{width: "90%"}}
        inputRef={textInput}
        helperText={error}
        error={Boolean(error)}
        defaultValue={alias}
        onBlur={handleAliasUpdate}
        onFocus={clearError}
      />
      <DeleteIcon
        onClick={handleAliasRemove}
        style={styles.deleteIcon}
        fontSize="small"
      />
    </div>
  );
}

function Aliases(props) {
  return (
    <Paper style={{width: "60%", borderRadius: 0, margin: "0 auto"}}>
      <div className="app-toolbar" style={styles.cardHeader}>
        <span style={{paddingRight: "1em"}}>Aliases</span>
        {props.toggleIsMutual && (
          <FormControlLabel
            control={
              <Checkbox
                checked={props.isMutual}
                onChange={props.toggleIsMutual}
                name="checkedA"
              />
            }
            label="Is Mutual"
          />
        )}
      </div>
      <AliasesList
        aliases={props.aliases}
        onAliasesUpdate={props.onAliasesUpdate}
      />
    </Paper>
  );
}

export function AliasesList(props) {
  const {aliases: propsAliases} = props;
  const [stateAliases, updateStateAliases] = useState([...propsAliases]);

  const stateAliasesPrev = usePrevious([...stateAliases]);

  useEffect(
    () => {
      if (
        stateAliasesPrev &&
        stateAliases &&
        !_.isEqual(stateAliases, stateAliasesPrev)
      ) {
        props.onAliasesUpdate(stateAliases);
      }
    },
    [stateAliases],
  );

  useEffect(
    () => {
      if (!_.isEqual(stateAliases, propsAliases)) {
        updateStateAliases(propsAliases);
      }
    },
    [propsAliases],
  );

  const [isNewInputShown, updateIsNewInputShown] = useState(false);
  function showNewInput() {
    updateIsNewInputShown(true);
  }
  function hideNewInput() {
    if (isNewInputShown) {
      updateIsNewInputShown(false);
    }
  }

  function removeAlias(index) {
    const newAliases = stateAliases.filter(
      (item, itemIndex) => itemIndex !== index,
    );
    updateStateAliases(newAliases);
  }

  function updateAlias(newValue, newValueIndex) {
    const newAliases = stateAliases.map(
      (alias, index) => (index === newValueIndex ? newValue : alias),
    );
    updateStateAliases(newAliases);
  }

  function addAlias(newValue) {
    const newAliases = [...stateAliases, newValue];
    updateStateAliases(newAliases);
    hideNewInput();
  }

  return (
    <div style={{padding: "1rem 2rem"}}>
      {stateAliases.map((alias, index) => (
        <Alias
          key={`${alias}-${index}`}
          index={index}
          alias={alias}
          baseAliases={propsAliases}
          removeAlias={removeAlias}
          updateAlias={updateAlias}
        />
      ))}
      {isNewInputShown ? (
        <Alias
          index={stateAliases.length}
          alias=""
          baseAliases={propsAliases}
          removeAlias={hideNewInput}
          updateAlias={addAlias}
          focusOnMount={true}
        />
      ) : (
        <Button
          variant="contained"
          onClick={showNewInput}
          startIcon={<AddIcon />}
          style={styles.addItemButton}
        >
          Add Alias
        </Button>
      )}
    </div>
  );
}

export default Aliases;
