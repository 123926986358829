import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";

import Select from "react-select";
import ReactSelectLabel from "common_components/react_select_label";

import Checkbox from "common_components/checkbox";

import {
  filterOptions,
  updateSelectionSingle,
  updateSelection,
} from "common_components/select_handlers";

class TopicEditor extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.props = props;
    if (this.useState()) {
      const {initialTopic} = props;
      this.state = this.createState(initialTopic || {tags: []});
    }
  }

  render() {
    return (
      <div>
        <div style={{marginBottom: "1em"}}>
          <ReactSelectLabel>Category</ReactSelectLabel>
          <Select
            className="category"
            value={this.getTopicCategoryValue()}
            allowCreate={true}
            filterOptions={filterOptions}
            options={this.getTopicCategoryOptions()}
            onChange={this.updateCategory}
          />
        </div>
        {this.props.topicTags ? (
          <div style={{marginBottom: "1em"}}>
            <ReactSelectLabel>Tags</ReactSelectLabel>
            <Select
              className="tags"
              multi={true}
              value={this.getTopicTagValue()}
              allowCreate={true}
              filterOptions={filterOptions}
              options={this.getTopicTagOptions()}
              onChange={this.updateTags}
            />
          </div>
        ) : null}
        <div style={{marginBottom: "1em"}}>
          <ReactSelectLabel>Contract Type</ReactSelectLabel>
          <Select
            className="contract-types"
            valueKey="contract_type_id"
            labelKey="name"
            multi={true}
            removeSelected={true}
            clearable={false}
            backspaceRemoves={false}
            deleteRemoves={false}
            value={this.getContractTypeValues()}
            allowCreate={false}
            options={this.getContractTypeOptions()}
            onChange={this.onContractTypeChange}
          />
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
          <Checkbox
            checked={
              this.useState()
                ? this.state.is_substantive
                : this.props.topic.is_substantive
            }
            onCheck={this.onIsSubstantiveCheck}
          />
          <span>Is Substantive</span>
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
          <Checkbox
            checked={
              this.useState()
                ? this.state.is_embedding
                : this.props.topic.is_embedding
            }
            onCheck={this.onIsEmbeddingCheck}
          />
          <span>Is Embedding</span>
        </div>
      </div>
    );
  }

  onContractTypeChange = newValues => {
    if (this.useState()) {
      this.setState({
        contract_types: newValues,
      });
    }
    const {onContractTypesAdded, onContractTypesRemoved} = this.props;
    if (!(onContractTypesAdded || onContractTypesRemoved)) {
      return null;
    }
    const existingValues = this.getContractTypeValues();
    const newContractTypes = _.chain(newValues)
      .filter(
        newValue =>
          !existingValues.find(
            existingValue =>
              existingValue.contract_type_id === newValue.contract_type_id,
          ),
      )
      .value();
    const removedContractTypes = _.chain(existingValues)
      .filter(
        existingValue =>
          !newValues.find(
            newValue =>
              newValue.contract_type_id === existingValue.contract_type_id,
          ),
      )
      .value();
    if (newContractTypes.length > 0) {
      this.props.onContractTypesAdded(newContractTypes);
    }
    if (removedContractTypes.length > 0) {
      this.props.onContractTypesRemoved(removedContractTypes);
    }
  };

  getTopicCategoryValue() {
    let category;
    if (this.state) {
      category = this.state.topiccategory_id || this.state.topiccategory_name;
    } else {
      category = this.props.topic.topiccategory_id;
    }
    return category;
  }

  getTopicTagValue() {
    let tags;
    if (this.state) {
      tags = this.state.tags;
    } else {
      tags = this.props.topic.tags;
    }
    return tags.map(tag => tag.id || tag.name).join(",");
  }

  getContractTypeValues() {
    const {contractTypesById} = this.props;
    let contractTypes;
    if (this.state) {
      contractTypes = this.state.contract_types;
    } else {
      contractTypes = this.props.topic.contract_types;
    }
    return contractTypes.map(ct => ({
      contract_type_id: ct.contract_type_id,
      name: contractTypesById[ct.contract_type_id].name,
      last_edited: ct.last_edited ? ct.last_edited : null,
    }));
  }

  getTopicCategoryOptions() {
    return this.props.topicCategories
      .map(category => ({
        value: category.id,
        label: category.name,
      }))
      .concat(
        this.state && this.state.topiccategory_name
          ? [
              {
                value: this.state.topiccategory_name,
                label: this.state.topiccategory_name,
              },
            ]
          : [],
      );
  }

  getTopicTagOptions() {
    return this.props.topicTags
      .map(tag => ({
        value: tag.id,
        label: tag.name,
      }))
      .concat(
        this.state && this.state.tags
          ? this.state.tags
              .filter(tag => tag.name)
              .map(tag => ({
                value: tag.name,
                label: tag.name,
              }))
          : [],
      );
  }
  /* eslint-disable no-unneeded-ternary */
  getContractTypeOptions() {
    const {contractTypesById} = this.props;
    const currentContractTypes = this.state
      ? this.state.contract_types
      : this.props.topic.contract_types;
    return Object.keys(contractTypesById)
      .filter(id => {
        // check if topic has specified contract type
        return !currentContractTypes.find(
          ct => ct.contract_type_id === Number(id),
        );
      })
      .map(id => ({
        contract_type_id: Number(id),
        name: contractTypesById[id].name,
      }));
  }
  /* eslint-enable no-unneeded-ternary */

  /* eslint-disable no-invalid-this */
  updateCategory = _value => {
    const value = updateSelectionSingle(_value);
    const {onExistingCategorySet, onNewCategorySet} = this.props;
    if (this.useState()) {
      if (_.isNumber(value.value)) {
        this.setState({
          topiccategory_id: value.value,
          topiccategory_name: null,
        });
      } else {
        this.setState({
          topiccategory_id: null,
          topiccategory_name: value.label,
        });
      }
    }
    return _.isNumber(value.value)
      ? onExistingCategorySet && onExistingCategorySet(value.value)
      : onNewCategorySet && onNewCategorySet(value.value);
  };

  updateTags = _valueArray => {
    const valueArray = updateSelection(_valueArray);
    const {onExistingTagAdded, onNewTagAdded, onTagRemoved} = this.props;
    if (this.useState()) {
      this.setState({
        tags: valueArray.map(value =>
          _.isNumber(value.value) ? {id: value.value} : {name: value.value},
        ),
      });
    }
    if (!(onExistingTagAdded || onNewTagAdded || onTagRemoved)) {
      return null;
    }

    const existingTags = (this.props.initialTopic || this.props.topic).tags;
    const newTags = _.chain(valueArray)
      .filter(value => !existingTags.find(tag => tag.id === value.value))
      .map(value =>
        _.isNumber(value.value) ? {id: value.value} : {name: value.value},
      )
      .value();

    const removedTags = _.chain(existingTags)
      .filter(tag => !valueArray.find(value => tag.id === value.value))
      .map(item => item.id)
      .value();

    if (newTags.length > 0) {
      if (newTags[0].id) {
        return onExistingTagAdded && onExistingTagAdded(newTags[0].id);
      }
      return onNewTagAdded && onNewTagAdded(newTags[0].name);
    }
    if (removedTags.length > 0) {
      return onTagRemoved && onTagRemoved(removedTags[0]);
    }
    return null;
  };

  onIsSubstantiveCheck = () => {
    if (this.useState()) {
      this.setState(prevState => ({is_substantive: !prevState.is_substantive}));
      return;
    }
    this.props.updateIsSubstantive();
  };

  onIsEmbeddingCheck = () => {
    if (this.useState()) {
      this.setState(prevState => ({is_embedding: !prevState.is_embedding}));
      return;
    }
    this.props.updateIsEmbedding();
  };

  useState() {
    return this.props.initialTopic || !this.props.topic;
  }

  componentDidUpdate() {
    if (
      this.state &&
      this.props.initialTopic &&
      this.state.topicId !== this.props.initialTopic.id
    ) {
      this.setState(this.createState(this.props.initialTopic));
    }
  }

  createState(initialTopic) {
    return {
      topicId: initialTopic.id,
      tags: initialTopic.tags,
      ...(initialTopic.topiccategory_id
        ? {topiccategory_id: initialTopic.topiccategory_id}
        : {}),
      ...(initialTopic && initialTopic.topiccategory_name
        ? {topiccategory_name: initialTopic.topiccategory_name}
        : {}),
      contract_types:
        initialTopic && initialTopic.contract_types
          ? initialTopic.contract_types
          : null,
      is_substantive:
        initialTopic.is_substantive !== undefined
          ? initialTopic.is_substantive
          : true,
      is_embedding:
        initialTopic.is_embedding !== undefined
          ? initialTopic.is_embedding
          : true,
    };
  }

  getValue() {
    return this.state;
  }
}

TopicEditor.propTypes = {
  topic: PropTypes.shape({
    id: PropTypes.number.isRequired,
    topiccategory_id: PropTypes.number.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      }),
    ),
  }),
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onNewCategorySet: PropTypes.func,
  onExistingCategorySet: PropTypes.func,
  onNewTagAdded: PropTypes.func,
  onExistingTagAdded: PropTypes.func,
  onTagRemoved: PropTypes.func,
};

export default TopicEditor;
