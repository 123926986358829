import React, {Component} from "react";
import _ from "lodash";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import FindInPageIcon from "material-ui/svg-icons/action/find-in-page";
import PageViewIcon from "material-ui/svg-icons/action/pageview";

import {isInitialised} from "utils/uninitialised";
import Permissioner from "utils/permissioner";
import {parseQuery} from "utils/uri";
import setTitle from "utils/set_title";
import searchAction from "modules/search/actions/search";
import SearchComponent from "../components/search";

const filters = {
  contract_types: {
    label: "Contract Type",
    propName: "contractTypes",
    paramName: "contract_type",
    leftIcon: <FindInPageIcon />,
  },
  projects: {
    label: "Project",
    propName: "projects",
    paramName: "project",
    leftIcon: <PageViewIcon />,
    filterSource: list => list.filter(project => !project.hide_from_search),
  },
};

const sorts = {
  upload_date: {
    label: "Upload Date",
    isDefault: true,
  },
  document_name: {
    label: "Document Name",
  },
};

const searchTypes = {
  all: {paramValues: []},
  text: {paramValues: ["clause"]},
  contextual: {paramValues: ["topic", "issue", "not_issue"]},
};

class SearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {isInitialUrlApplied: !this.getQueryValue()};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.applyUrl();
    } else if (
      !this.state.isInitialUrlApplied &&
      this.hasPermission() &&
      this.getQueryValue() &&
      this.havePropsBeenInitialisedForFilters(this.props) &&
      !prevProps.processing &&
      !this.props.processing
    ) {
      this.setState({isInitialUrlApplied: true});
      this.applyUrl();
    }
  }

  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    if (!this.hasPermission()) {
      return this.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  renderComponent() {
    const queryValue = this.getQueryValue();
    setTitle(`Search${queryValue ? ` - ${queryValue}` : ""}`);
    return (
      <SearchComponent
        organisationId={this.props.params.organisationId}
        queryValue={queryValue}
        results={this.props.results}
        processing={this.props.processing}
        onFilter={this.onFilter}
        onSearch={this.onSearch}
        onSort={this.onSort}
        sortValue={this.getSortValue()}
        filters={this.getComponentFilters()}
        sorts={this.getComponentSorts()}
        searchTypesItems={this.getComponentSearchTypesItems()}
        onSearchTypeItemSelect={this.onSearchTypeItemSelect}
      />
    );
  }

  applyUrl = () => {
    const locationSearchParams = parseQuery(this.props.location.search);
    this.doSearch(locationSearchParams);
  };

  shouldRenderContainer = () => {
    return this.havePropsBeenInitialisedForFilters(this.props);
  };

  havePropsBeenInitialisedForFilters = props => {
    return isInitialised(
      _.chain(filters)
        .map(filter => props[filter.propName])
        .value(),
    );
  };

  hasPermission = () => {
    return Boolean(new Permissioner(this.props.user).hasPermission("search"));
  };

  getNoPermissionMessage = () => {
    return new Permissioner(this.props.user).getNoPermissionMessage();
  };

  getQueryValue = () => {
    return parseQuery(this.props.location.search).query || "";
  };

  getSortValue = () => {
    return parseQuery(this.props.location.search).sort || "";
  };

  getComponentFilters = () => {
    return _.map(filters, (data, name) => {
      const {propName, filterSource} = filters[name];
      const list = !_.isFunction(filterSource)
        ? this.props[propName]
        : filterSource(this.props[propName]);
      return {
        name,
        ..._.pick(data, "label", "leftIcon"),
        items: _.sortBy(list, "name"),
        filteredItemIds: this.getFilterItemIds(name),
      };
    });
  };

  getComponentSorts = () => {
    return _.map(sorts, (data, name) => {
      return {
        value: name,
        ...data,
      };
    });
  };

  getSearchTypesList = () => {
    return this.parseUrlParamValueList("search_type");
  };

  getComponentSearchTypesItems = () => {
    const searchTypesList = this.getSearchTypesList();
    const allParamValues = _.flatten(
      _.map(searchTypes, ({paramValues}) => paramValues),
    );
    const areAllSelected = _.isEqual(
      searchTypesList.sort(),
      allParamValues.sort(),
    );
    return _.map(searchTypes, ({paramValues}, type) => {
      let isTypeSelected = false;
      if (areAllSelected) {
        if (!paramValues.length) {
          isTypeSelected = true;
        }
      } else if (
        (!paramValues.length && !searchTypesList.length) ||
        (paramValues.length &&
          paramValues.find(paramValue => searchTypesList.includes(paramValue)))
      ) {
        isTypeSelected = true;
      }
      return {
        value: type,
        isSelected: isTypeSelected,
        childItems:
          paramValues.length < 2
            ? []
            : paramValues.sort().map(paramValue => {
                return {
                  value: `${type}.${paramValue}`,
                  isSelected:
                    isTypeSelected && searchTypesList.includes(paramValue),
                };
              }),
      };
    });
  };

  onSearchTypeItemSelect = values => {
    const searchTypeName = values.find(value => value.split(".").length === 1);
    const searchType = searchTypes[searchTypeName];
    if (searchType) {
      if (searchType.paramValues.length < 2) {
        this.doRedirect(
          this.setLocationSearchParam("search_type", searchType.paramValues),
        );
      } else {
        const selectedParamValues = values
          .filter(value => value.match(new RegExp(`^${searchTypeName}\.`)))
          .map(value =>
            value
              .split(".")
              .slice(1)
              .join("."),
          )
          .filter(value => searchType.paramValues.includes(value))
          .sort();
        let paramValues;
        if (selectedParamValues.length) {
          paramValues = selectedParamValues;
        } else {
          paramValues = [...searchType.paramValues];
          const searchTypesList = this.getSearchTypesList();
          const prevSelected = searchType.paramValues.filter(value =>
            searchTypesList.includes(value),
          );
          if (prevSelected.length === 1) {
            paramValues.splice(paramValues.indexOf(prevSelected.shift()), 1);
          }
        }
        this.doRedirect(
          this.setLocationSearchParam("search_type", paramValues),
        );
      }
    }
  };

  getFilterURLParamNames = () => {
    return _.chain(filters)
      .map(filter => filter.paramName)
      .value();
  };

  parseUrlFilterValues = filterName => {
    const {paramName} = filters[filterName];
    return this.parseUrlParamValueList(paramName);
  };

  parseUrlParamValueList = paramName => {
    const parsedValue = parseQuery(this.props.location.search)[paramName];
    if (!parsedValue) {
      return [];
    }
    return _.isArray(parsedValue) ? parsedValue : [parsedValue];
  };

  getFilterItemIds = filterName => {
    const {propName} = filters[filterName];
    if (!_.isArray(this.props[propName])) {
      return [];
    }
    const list = [];
    const values = this.parseUrlFilterValues(filterName);
    _.forEach(values, value => {
      const foundItem = this.props[propName].find(item => {
        return (
          item.name.trim().toLowerCase() ===
          decodeURI(value)
            .trim()
            .toLowerCase()
        );
      });
      if (foundItem) {
        list.push(foundItem.id);
      }
    });
    return list;
  };

  setLocationSearchParam = (paramName, paramValue) => {
    const locationSearchParams = parseQuery(this.props.location.search);
    if (_.has(locationSearchParams, paramName)) {
      delete locationSearchParams[paramName];
    }
    if (_.isString(paramValue) && paramValue) {
      locationSearchParams[paramName] = paramValue;
    } else if (_.isArray(paramValue) && paramValue.length) {
      locationSearchParams[paramName] =
        paramValue.length === 1 ? paramValue[0] : paramValue;
    }
    return locationSearchParams;
  };

  getFilterItemValuesByValues = (
    filterName,
    values,
    sourceKey = "id",
    targetKey = "name",
  ) => {
    const {propName} = filters[filterName];
    const list = [];
    _.forEach(values, value => {
      const foundItem = this.props[propName].find(
        item => item[sourceKey] === value,
      );
      if (foundItem && foundItem[targetKey]) {
        list.push(foundItem[targetKey]);
      }
    });
    return list;
  };

  buildLocationSearchString = locationSearchParams => {
    let string;
    const parts = [];
    _.forEach(locationSearchParams, (paramValue, paramName) => {
      if (_.isString(paramValue)) {
        parts.push(`${paramName}=${encodeURIComponent(paramValue)}`);
      } else if (_.isArray(paramValue)) {
        _.forEach(paramValue, value => {
          parts.push(`${paramName}=${encodeURIComponent(value)}`);
        });
      }
    });
    if (parts.length) {
      string = `?${parts.join("&")}`;
    }
    return string;
  };

  getFilterNameByParamName = paramName => {
    for (const name in filters) {
      if (filters[name].paramName === paramName) {
        return name;
      }
    }
    return null;
  };

  onSearch = queryValue => {
    const locationSearchParams = this.setLocationSearchParam(
      "query",
      queryValue,
    );
    this.doRedirect(locationSearchParams);
  };

  onFilter = (filterName, filteredItemIds) => {
    const locationSearchParams = this.setLocationSearchParam(
      filters[filterName].paramName,
      this.getFilterItemValuesByValues(filterName, filteredItemIds),
    );
    this.doRedirect(locationSearchParams);
  };

  onSort = sortValue => {
    const locationSearchParams = this.setLocationSearchParam("sort", sortValue);
    this.doRedirect(locationSearchParams);
  };

  doRedirect = locationSearchParams => {
    const route = {
      pathname: this.props.location.pathname,
    };
    const search = this.buildLocationSearchString(locationSearchParams);
    if (search) {
      route.search = search;
    }
    this.props.router.push(route);
  };

  doSearch = _locationSearchParams => {
    const locationSearchParams = _.omit(_locationSearchParams, "query");
    _.forEach(locationSearchParams, (value, name) => {
      if (this.getFilterURLParamNames().includes(name)) {
        locationSearchParams[name] = this.getFilterItemValuesByValues(
          this.getFilterNameByParamName(name),
          _.isArray(value) ? value : [value],
          "name",
          "id",
        );
      }
    });
    const search = this.buildLocationSearchString(locationSearchParams);
    this.props.search(
      this.props.params.organisationId,
      "text",
      this.getQueryValue(),
      search ? search.substring(1) : undefined,
    );
  };
}

const mapStateToProps = state => ({
  results: state.search.results,
  processing: state.search.processing,
  user: state.user,
  contractTypes: state.contract_types,
  projects: state.projects,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      search: searchAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
