import React from "react";
import ContainedTextField from "../../contained_text_field";

import {TaskDataLocationObject} from "common/flowmaster/types/data_location";

interface TaskValueEditorProps {
  location: TaskDataLocationObject;
  onUpdateItem: (value: string) => void;
}

const TaskValueEditor: React.FC<TaskValueEditorProps> = ({
  location,
  onUpdateItem,
}) => {
  return (
    <ContainedTextField
      initialValue={location.value ?? ""}
      label="Object Accessor"
      onUpdate={value => onUpdateItem(value)}
    />
  );
};

export default TaskValueEditor;
