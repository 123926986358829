import BaseRoute from "utils/base_route";

import PromptList from "./containers/prompt_list";

export default class PromptListRoute extends BaseRoute {
  constructor(store) {
    super("list", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, PromptList);
  }
}
