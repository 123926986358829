import blue from "@material-ui/core/colors/blue";
import React from "react";
import styled from "styled-components";

const Container = styled.tr`
  height: 100%;
  border-bottom: 1px solid #e0e0e0;
`;

const Cell = styled.td<{onClick?: () => void}>`
  ${props =>
    props.onClick &&
    `:hover {
        color: ${blue[500]};
        textdecoration: underline;
        cursor: pointer;
     }
  `}
  color: #000;
`;

export type CellData = {
  data: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: () => void;
};

type RowProps = {
  cells: CellData[];

  /**
   * Optional classname to allow styled-components to be used to style this
   * component
   */
  className?: string;
};

const Row = ({cells, className}: RowProps) => (
  <Container className={className}>
    {cells.map((cell, i) => (
      // WARN: If we ever re-order cells, we should replace this "index" key.
      <Cell key={i} style={cell.style} onClick={cell.onClick}>
        {cell.data}
      </Cell>
    ))}
  </Container>
);

export default Row;
