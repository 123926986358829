import _ from "underscore";
import {LlmRunInfo} from "common/flowmaster/types/llm_run";

export default function calculateCounts(
  llmRuns: LlmRunInfo[],
): Record<string, number> {
  const counts: Record<string, number> = {};
  counts.workflow = Object.keys(_.groupBy(llmRuns, "workflow_id")).length;
  counts.project = Object.keys(_.groupBy(llmRuns, "project_id")).length;
  counts.document = Object.keys(_.groupBy(llmRuns, "document_id")).length;
  counts.issue = Object.keys(_.groupBy(llmRuns, "issue_id")).filter(
    item => item !== "null",
  ).length;
  counts.clausepart = Object.keys(_.groupBy(llmRuns, "clausepart_id")).filter(
    item => item !== "null",
  ).length;
  return counts;
}
