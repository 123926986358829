import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";

import keyedObjectPropType from "utils/keyed_object_prop_type";

import capitalize from "utils/capitalize";

export class PartyChecker extends React.Component {
  render() {
    const {party} = this.props;
    return (
      <label
        style={{
          fontSize: "0.8em",
          color: "#333",
          lineHeight: "1.25em",
          display: "block",
          margin: "0.5em 0em",
        }}
      >
        <span
          style={{
            display: "inline-block",
            width: "10em",
          }}
        >
          {this.props.parties[party.id].name}
        </span>
        <input
          type="checkbox"
          key={party.id}
          checked={Boolean(party.value && party.value.checked)}
          onChange={this.checkboxChecked}
          style={{
            display: "inline-block",
          }}
        />
      </label>
    );
  }

  /* eslint-disable no-invalid-this */
  checkboxChecked = event => {
    this.props.onPartyChecked(this.props.role.id, this.props.party.id, {
      checked: event.target.checked,
    });
  };
  /* eslint-enable no-invalid-this */
}

export class PartyGroup extends React.Component {
  render() {
    const roleName = this.props.taskroles[this.props.role.id].name;
    return (
      <div
        style={{
          margin: "1em 0em",
        }}
      >
        <label>{capitalize(roleName)}</label>
        <div className="parties">{this.renderPartyCheckers()}</div>
      </div>
    );
  }

  renderPartyCheckers() {
    return _.chain(this.props.role.party_data)
      .sortBy(party => this.props.parties[party.id].name)
      .map(party => (
        <PartyChecker
          className="party"
          key={party.id}
          party={party}
          {...this.props}
        />
      ))
      .value();
  }
}

class RolePartyChecker extends React.Component {
  render() {
    return (
      <div
        className="party-checker-groups"
        style={{
          margin: "0 1em",
        }}
      >
        {this.renderPartyCheckerGroups()}
      </div>
    );
  }

  renderPartyCheckerGroups() {
    return _.chain(this.props.activeRoles)
      .filter(role => role.type === "multi-check")
      .map(role => (
        <PartyGroup
          className="party-group"
          key={role.id}
          role={role}
          {...this.props}
        />
      ))
      .value();
  }
}

RolePartyChecker.propTypes = {
  activeRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      party_id: PropTypes.number,
      party_data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          value: PropTypes.shape({
            checked: PropTypes.bool,
          }),
        }),
      ),
    }),
  ).isRequired,
  taskroles: keyedObjectPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  parties: keyedObjectPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  onPartyChecked: PropTypes.func.isRequired,
};

export default RolePartyChecker;
