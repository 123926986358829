import React from "react";
import {FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";

import {
  WorkflowTask,
  WorkflowTaskId,
} from "common/flowmaster/types/task_config";

interface TaskSelectorProps {
  task: WorkflowTaskId;
  tasks: WorkflowTask[];
  onSetTask: (value: WorkflowTaskId) => void;
}
const TaskSelector: React.FC<TaskSelectorProps> = ({
  task,
  tasks,
  onSetTask,
}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="task-selector-label">Action</InputLabel>
      <Select
        labelId="task-selector-label"
        value={task}
        onChange={event => onSetTask(event.target.value as WorkflowTaskId)}
        label="Task"
      >
        {(tasks ?? []).map(_task => (
          <MenuItem value={_task.id} key={_task.id}>
            {_task.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TaskSelector;
