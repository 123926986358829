import React from "react";
import styled from "styled-components";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PeopleIcon from "@material-ui/icons/People";
import {ButtonGroup, CircularProgress, IconButton} from "@material-ui/core";

import ConfirmDialog from "common_components/confirm_dialog";
import Dialog from "./Dialog/Dialog";
import InputDialog from "./Dialog/InputDialog";
import Table from "./Table";
import Toolbar from "./Toolbar";
import UsergroupUsersEditor from "./UsergroupUsersEditor";
import logger from "common/utils/logger";
import useDialog from "../util/useDialog";
import {MaybeWithUsers, Usergroup, WithUserCount} from "common/types/usergroup";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const TableWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
`;

const UsergroupUsersEditorWrapper = styled.div`
  height: 30rem;
  width: min(80vw, 50rem);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type RegisteredUser = {id: number; username: string};

interface UsergroupsProps {
  usergroups: Array<MaybeWithUsers<WithUserCount<Usergroup>>>;

  /** All users in the current organisation */
  organisationUsers: RegisteredUser[];

  /**
   * Optional callback invoked when a new usergroup is submitted via the
   * dialog in this component.
   */
  onAddUsergroup?: (name: string) => void;

  /**
   * Optional callback invoked when a usergroup is selected and confirmed for
   * deletion.
   */
  onDeleteUsergroup?: (id: number) => void;

  /** Optional callback invoked when a usergroup's name is edited. */
  onEditUsergroupName?: (id: number, name: string) => void;

  /** Optional callback invoked when a user is removed from a usergroup. */
  onRemoveUserFromUsergroup?: (
    usergroupId: number,
    user: RegisteredUser,
  ) => void;

  /** Optional callback invoked when a user is added to a usergroup. */
  onAddUserToUsergroup?: (usergroupId: number, user: RegisteredUser) => void;

  /**
   * Optional callback invoked when a usergroup's users are edited.
   * Actually, this is hardly optional. And it's terrible. What this callback
   * really represents is implicit coupling to an API call. When we call this,
   * we're really relying on the parent to update @prop usergroups so that the
   * usergroup referred to by @param id has its "users" field set.
   * This wouldn't be necessary if we could just manage the dialog state at the
   * same level we're able to make API calls. Since the API call and resultant
   * dialog state change actually are coupled in principle, we can do no better
   * than to handle them explicitly in the same scope.
   */
  onOpenEditUsergroupUsers?: (usergroupId: number) => void;
}

const Usergroups = ({
  organisationUsers,
  usergroups,
  onAddUsergroup,
  onDeleteUsergroup,
  onEditUsergroupName,
  onOpenEditUsergroupUsers,
  onRemoveUserFromUsergroup,
  onAddUserToUsergroup,
}: UsergroupsProps) => {
  const addUsergroupDialog = useDialog<{name: string}>(["name"]);
  const deleteUsergroupDialog = useDialog<{usergroup: Usergroup}>([
    "usergroup",
  ]);
  const editUsergroupDialog = useDialog<{id: number; name: string}>([
    "id",
    "name",
  ]);

  const editUsergroupUsersDialog = useDialog<{
    usergroupId: number;
  }>(["usergroupId"]);

  return (
    <Container>
      <Toolbar
        title="User groups"
        onClickAddIcon={
          addUsergroupDialog.isOpen
            ? undefined
            : () => addUsergroupDialog.open({name: ""})
        }
      />

      <TableWrapper>
        <Table
          headers={[
            {title: "ID", style: {textAlign: "left"}},
            {title: "Name", style: {textAlign: "left"}},
            {title: "# Users", style: {textAlign: "right"}},
          ]}
          rows={usergroups.map(usergroup => [
            {data: usergroup.id},
            {data: usergroup.name},
            {data: usergroup.user_count, style: {textAlign: "right"}},
            {
              style: {width: "4rem", paddingLeft: "1rem"},
              data: (
                <ButtonGroup variant="text" size="small">
                  <IconButton
                    onClick={() =>
                      !deleteUsergroupDialog.isOpen &&
                      deleteUsergroupDialog.open({
                        usergroup,
                      })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    onClick={
                      !editUsergroupUsersDialog.isOpen
                        ? () => {
                            editUsergroupUsersDialog.open({
                              usergroupId: usergroup.id,
                            });

                            onOpenEditUsergroupUsers?.(usergroup.id);
                          }
                        : undefined
                    }
                  >
                    <PeopleIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      !editUsergroupDialog.isOpen &&
                      editUsergroupDialog.open({
                        id: usergroup.id,
                        name: usergroup.name,
                      })
                    }
                  >
                    <EditIcon />
                  </IconButton>
                </ButtonGroup>
              ),
            },
          ])}
        />
      </TableWrapper>

      {addUsergroupDialog.isOpen && (
        <InputDialog
          title="Add user group"
          input={{
            label: "Name",
            value: addUsergroupDialog.name,
            onChange: addUsergroupDialog.setName,
          }}
          onSave={() => {
            onAddUsergroup?.(addUsergroupDialog.name);
            addUsergroupDialog.close();
          }}
          onClose={addUsergroupDialog.close}
        />
      )}

      {deleteUsergroupDialog.isOpen && (
        <ConfirmDialog
          open
          title="Delete user group?"
          cancelButtonCaption="Cancel"
          description={
            `This action will delete user group "${deleteUsergroupDialog.usergroup.name}". ` +
            "This action cannot be undone."
          }
          okButtonCaption="Confirm"
          onClose={deleteUsergroupDialog.close}
          onSuccess={() => {
            onDeleteUsergroup?.(deleteUsergroupDialog.usergroup.id);
            deleteUsergroupDialog.close();
          }}
        />
      )}

      {editUsergroupDialog.isOpen && (
        <InputDialog
          title="Edit user group name"
          input={{
            label: "Name",
            value: editUsergroupDialog.name,
            onChange: editUsergroupDialog.setName,
          }}
          onSave={() => {
            onEditUsergroupName?.(
              editUsergroupDialog.id,
              editUsergroupDialog.name,
            );
            editUsergroupDialog.close();
          }}
          onClose={editUsergroupDialog.close}
        />
      )}

      {editUsergroupUsersDialog.isOpen &&
        (() => {
          const usergroup = usergroups.find(
            ({id}) => id === editUsergroupUsersDialog.usergroupId,
          );

          // Let's hope this doesn't happen. Ideally, we'd control the dialog
          // state fully without praying that Redux is in a concordant state.
          if (!usergroup) {
            logger.warn(
              "Selected usergroup for editing, but not found in store!",
            );
            return null;
          }

          return (
            <Dialog
              onClose={editUsergroupUsersDialog.close}
              title="Edit users in user group"
              buttons={[
                {text: "Close", onClick: editUsergroupUsersDialog.close},
              ]}
            >
              <UsergroupUsersEditorWrapper>
                {"users" in usergroup ? (
                  <UsergroupUsersEditor
                    users={{
                      outsideGroup: organisationUsers.filter(user =>
                        usergroup.users.every(({id}) => id !== user.id),
                      ),
                      insideGroup: usergroup.users,
                    }}
                    onAddUserToUsergroup={user =>
                      onAddUserToUsergroup?.(usergroup.id, user)
                    }
                    onRemoveUserFromUsergroup={user =>
                      onRemoveUserFromUsergroup?.(usergroup.id, user)
                    }
                  />
                ) : (
                  <CircularProgress />
                )}
              </UsergroupUsersEditorWrapper>
            </Dialog>
          );
        })()}
    </Container>
  );
};

export default Usergroups;
