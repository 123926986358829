import AlgorithmSetting from "modules/searcher/types/algorithm_setting";

const keyValueSeparator = "-";
const typeOptionSeparator = "~";
const optionSeparator = "|";

export function serialiseAlgorithm(setting: AlgorithmSetting): string {
  const optionString = Object.entries(setting.options)
    .map(([key, value]) => `${key}${keyValueSeparator}${value}`)
    .join(optionSeparator);
  return optionString
    ? `${setting.type}${typeOptionSeparator}${optionString}`
    : setting.type;
}

export function deserialiseAlgorithm(text: string): AlgorithmSetting {
  if (text.indexOf(typeOptionSeparator) > 1) {
    const [type, optionString] = text.split(typeOptionSeparator);
    const options = Object.fromEntries(
      optionString
        .split(optionSeparator)
        .map(option => option.split(keyValueSeparator)),
    );
    return {type, options};
  }
  return {type: text, options: {}};
}
