import requestor from "requestor";

import BaseRoute from "utils/base_route";
import getRouteIds from "utils/get_route_ids";

import TopicDetailContainer from "./containers/topic_analysis";

import topicsFetchAction from "modules/documents/actions/topics_fetch";
import topicClassifiersFetchAction from "modules/documents/actions/topic_classifiers_fetch";
import trainingModesFetchAction from "modules/documents/actions/training_modes_fetch";
import datasetModesFetchAction from "modules/documents/actions/dataset_modes_fetch";
import templateModulesFetchAction from "modules/template_modules/actions/modules_fetch";
import rolesFetchAction from "modules/documents/actions/role/roles_fetch";

import topicClearAction from "modules/documents/actions/topic_clear";
import topicsClearAction from "modules/documents/actions/topics_clear";
import logsClearAction from "modules/logs/actions/logs_clear";
import rolesClearAction from "modules/documents/actions/role/roles_clear";

export default class TopicAnalysis extends BaseRoute {
  constructor(store) {
    super(":topicId/analysis", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, TopicDetailContainer);
  }

  fetchActiveData(state) {
    this.fetchTopics(state);
    this.fetchClassifiers(state);
    this.fetchTrainingModes(state);
    this.fetchDatasetModes(state);
    this.fetchModules(state);
    this.fetchRoles(state);
  }

  fetchTopics(state) {
    const {organisationId} = getRouteIds(state.router);
    this.store.dispatch(topicsFetchAction(requestor)(organisationId));
  }

  fetchClassifiers(state) {
    const {organisationId, topicId} = getRouteIds(state.router);
    this.store.dispatch(
      topicClassifiersFetchAction(requestor)(organisationId, topicId),
    );
  }

  fetchTrainingModes(state) {
    const {organisationId} = getRouteIds(state.router);
    this.store.dispatch(trainingModesFetchAction(requestor)(organisationId));
  }

  fetchDatasetModes(state) {
    const {organisationId} = getRouteIds(state.router);
    this.store.dispatch(datasetModesFetchAction(requestor)(organisationId));
  }

  fetchRoles(state) {
    const {organisationId} = getRouteIds(state.router);
    this.store.dispatch(rolesFetchAction(requestor)(organisationId));
  }

  clearActiveData(state) {
    this.clearTopic(state);
    this.clearTopics(state);
    this.clearLogs();
    this.clearRoles();
  }

  fetchModules(props) {
    const {organisationId} = getRouteIds(props.router);
    if (organisationId) {
      this.store.dispatch(
        templateModulesFetchAction(requestor)(organisationId),
      );
    }
  }

  clearTopic(state) {
    const {organisationId, topicId} = getRouteIds(state.router);
    this.store.dispatch(topicClearAction(organisationId, topicId));
  }

  clearTopics(state) {
    const {organisationId} = getRouteIds(state.router);
    this.store.dispatch(topicsClearAction(organisationId));
  }

  clearLogs() {
    this.store.dispatch(logsClearAction());
  }

  clearRoles() {
    this.store.dispatch(rolesClearAction());
  }
}
