import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Permissioner from "utils/permissioner";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import requestor from "requestor";

import CircularProgress from "material-ui/CircularProgress";

import documentsFetchAction from "modules/documents/actions/documents_fetch";
import documentRefreshAction from "modules/documents/actions/document_refresh";
import clearDocumentsAction from "modules/documents/actions/documents_clear";
import downloadDocumentAction from "modules/documents/actions/document_download";
import uploadDocumentAction from "modules/documents/actions/document_upload";
import uploadFormTemplateAction from "modules/documents/actions/form_template_upload";
import deleteDocumentAction from "modules/documents/actions/document_delete";
import emailReportAction from "modules/documents/actions/document_email_report";
import reprocessDocumentAction from "modules/documents/actions/document_reprocess";
import reprocessDocumentRolesAction from "modules/documents/actions/role/document_roles_reprocess";
import regenerateDocumentEmbeddingsAction from "modules/documents/actions/embeddings/document_regenerate";
import reclassifyDocumentAction from "modules/documents/actions/document_reclassify_start";
import updateDocumentLoadStateAction from "modules/documents/actions/document_update_load_state";
import projectUpdateAction from "modules/projects/actions/project_update";
import documentUpdateAction from "modules/documents/actions/document_update";
import documentUploadUpdateAction from "modules/documents/actions/document_upload_update";
import logsFetchAction from "modules/logs/actions/logs_fetch";
import logsClearAction from "modules/logs/actions/logs_clear";
import documentClearAction from "modules/documents/actions/document_clear";
import fetchDocumentFileAction from "modules/documents/actions/document_file_fetch";
import errorClearAction from "modules/user/actions/error_clear";
import documentIssuesFindRunAction from "modules/documents/actions/document_issues_find_run";
import documentsReprocessAction from "modules/documents/actions/documents_reprocess";
import deleteDocumentsRolesAction from "modules/documents/actions/role/documents_roles_delete";
import generateProjectLlmPromptAction from "modules/documents/actions/project_generate_prompt";
import conversationsFetchAction from "modules/documents/actions/project_conversations_fetch";

import reportsFetchAction from "modules/reports/actions/fetch";
import documentReportDetailFetchAction from "modules/reports/actions/document_report_fetch";

import DocumentList from "../components/document_list";

import {set} from "utils/organisation_store";

import getDocumentIssuesOutOfDateStatus from "common/utils/document/get_document_issues_out_of_date_status";
import getPermittedDocumentTypes from "common/utils/get_permitted_document_types";
import byId from "common/utils/by_id";
import groupDocuments from "common/utils/document/group";

function shouldRefreshDocument(doc) {
  const creationDate =
    typeof doc.creation_date === "string"
      ? new Date(doc.creation_date)
      : doc.creation_date;
  const updateDate =
    typeof doc.last_edited === "string"
      ? new Date(doc.last_edited)
      : doc.last_edited;
  const isUploadDateLessThanAnHour = new Date() - creationDate < 3600000;
  const isUpdateDateLessThanAnHour = new Date() - updateDate < 3600000;
  const finishedStates = {2: true, 3: true};
  return (
    (doc?.concat_load_state?.some(item => !finishedStates[item]) ?? true) && // saving clauses
    (isUploadDateLessThanAnHour || isUpdateDateLessThanAnHour) &&
    doc.status !== "PARSE_ERROR" &&
    doc.status !== "UNRECOGNISED_FORMAT"
  );
}

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.documentsFetchIntervalId = null;
    this.state = {
      page: 1,
      onlyShowMyDocuments: false,
      searchValue: "",
      activeTab: 0,
    };
  }

  componentDidMount() {
    const {
      params: {organisationId, projectId},
    } = this.props;
    this.fetchDocuments(1);
    set(organisationId, "projectId", projectId);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      params: {organisationId, projectId},
      documents,
    } = this.props;
    // When the current project changes...
    if (prevProps.params.projectId !== projectId) {
      // Refresh the documents list
      this.fetchDocuments(1);
    }

    // When documents list changes...
    if (
      prevProps.documents !== documents &&
      // ...and the list is not empty...
      documents &&
      documents !== UNINITIALISED
    ) {
      // ...we should cleanup timers set in previous updates...
      // NOTE: This method updates documents on a timer that is effectively
      // restarted whenever the documents list changes. We could make the
      // timer independent of state changes using an interval, but then we'd
      // need to provide the latest state to the interval via a ref. This
      // wasn't a problem before because the interval was not a function of
      // state. Now, the interval is dependent on the latest list of
      // documents in order to find those which are uploading (and therefore
      // require updates, etc).
      if (this.refreshUploadingDocumentsTimeout) {
        clearTimeout(this.refreshUploadingDocumentsTimeout);
      }

      const refreshDocs = docs => {
        docs.forEach(doc =>
          this.props.documentRefreshAction(organisationId, projectId, doc.id),
        );
      };

      // ...then, we should check for "uploading" documents...
      const docsToRefresh = documents.filter(shouldRefreshDocument);
      //
      // ...and if there are any, set a timer to update them!
      if (docsToRefresh.length) {
        this.refreshUploadingDocumentsTimeout = setTimeout(
          () => refreshDocs(docsToRefresh),
          10000,
        );
      }
    }
    if (
      prevState.searchValue !== this.state.searchValue ||
      prevState.onlyShowMyDocuments !== this.state.onlyShowMyDocuments
    ) {
      if (this.state.activeTab === 0) {
        this.onNavigate(1);
      } else if (this.state.activeTab === 1) {
        this.fetchReports();
      }
    }
  }

  componentWillUnmount() {
    if (this.documentsFetchIntervalId) {
      clearInterval(this.documentsFetchIntervalId);
    }
    if (this.issuesRelatedFetchDocumentsTimeout) {
      clearTimeout(this.issuesRelatedFetchDocumentsTimeout);
    }
  }

  render() {
    if (!this.shouldRenderContainer()) {
      if (this.props.error) {
        return this.renderError();
      }
      return (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return (
      this.permissioner.hasPermission("get-documents") &&
      this.permissioner.canViewProject(this.props.project)
    );
  }

  hasUploadPermission() {
    return this.permissioner.hasPermission("upload-document-ui");
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.formTemplates,
      this.props.project,
      this.props.projects,
      this.props.documentTypes,
      this.props.contractTypes,
      this.props.parties,
      this.props.roles,
    ]);
  }

  renderError() {
    const {props} = this;
    const fetchHandlers = {
      DOCUMENTS_FETCH: this.fetchDocuments,
    };
    const fetchHandler = fetchHandlers[props.error.type];

    let errorMessage = [
      <span key="error-0">Documents could not be loaded.&nbsp;</span>,
    ];

    if (fetchHandler) {
      /* eslint-disable no-inner-declarations */
      async function onRetry() {
        await props.clearError();
        fetchHandler();
      }

      errorMessage = [
        ...errorMessage,
        <span
          key="error-1"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={onRetry}
        >
          Try again?
        </span>,
      ];
    }

    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Roboto, sans-serif",
          color: "#424242",
        }}
      >
        {errorMessage}
      </div>
    );
  }

  renderComponent() {
    return (
      <React.Fragment>
        {this.renderDocuments()}
        {this.props.children}
      </React.Fragment>
    );
  }

  renderDocuments() {
    setTitle(`${this.props.project.name} Documents`);
    return (
      <DocumentList
        organisationId={parseInt(this.props.params.organisationId, 10)}
        projectId={parseInt(this.props.params.projectId, 10)}
        organisation={this.props.organisation}
        project={this.props.project}
        projects={this.props.projects}
        documents={this.props.documents}
        documentsCount={this.props.documentsCount}
        roles={this.props.roles}
        formTemplates={this.props.formTemplates}
        onDocumentUploaded={this.uploadDocument}
        onCompareToFormTemplate={this.onCompareToFormTemplate}
        onFormTemplateUploaded={this.onFormTemplateUploaded}
        onDocumentDeleted={this.deleteDocument}
        onDocumentReprocessed={this.reprocessDocument}
        onDocumentReclassified={this.reclassifyDocument}
        updateDocumentLoadState={this.updateDocumentLoadState}
        onDocumentDownloaded={this.downloadDocument}
        onEmailReport={this.emailReport}
        user={this.props.user}
        documentTypes={getPermittedDocumentTypes(
          this.props.documentTypes,
          this.permissioner,
        )}
        setDocumentType={this.updateProjectDocumentType}
        contractTypes={this.props.contractTypes}
        contractTypesById={byId(this.props.contractTypes)}
        setContractType={this.updateProjectDefaultContractType}
        parties={this.props.parties}
        updateDocument={this.updateDocument}
        toggleAnalystView={this.toggleAnalystView}
        viewSummaryPage={this.viewSummaryPage}
        toggleDocumentCreateView={this.toggleDocumentCreateView}
        hasUploadPermission={this.hasUploadPermission()}
        reports={this.props.reports}
        documentStates={this.props.documentStates}
        documentLogs={this.props.documentLogs}
        showDocumentLogs={this.logsFetch}
        documentUploadLogsFetch={this.documentUploadLogsFetch}
        logsClear={this.props.logsClear}
        documentToReview={this.props.document}
        fetchDocumentFile={this.fetchDocumentFile}
        fetchReports={this.fetchReports}
        document={this.props.document}
        topicsById={this.props.topicsById}
        documentIssues={this.props.documentIssues}
        documentClauses={this.props.documentClauses}
        fetchDocumentReportDetail={this.props.fetchDocumentReportDetail}
        clearDocument={this.props.clearDocument}
        runDocumentIssuesFind={this.runDocumentIssuesFind}
        runDocumentsReprocess={this.runDocumentsReprocess}
        reprocessDocumentRoles={this.reprocessDocumentRoles}
        regenerateDocumentEmbeddings={this.regenerateDocumentEmbeddings}
        deleteDocumentsRoles={this.deleteDocumentsRoles}
        generateProjectLlmPrompt={this.generateProjectLlmPrompt}
        fetchConversations={this.fetchConversations}
        onNavigate={this.onNavigate}
        page={this.state.page}
        searchValue={this.state.searchValue}
        onChangeSearchText={newValue =>
          this.setState(() => ({searchValue: newValue}))
        }
        onlyShowMyDocuments={this.state.onlyShowMyDocuments}
        onToggleOnlyShowMyDocuments={() =>
          this.setState(prev => ({
            onlyShowMyDocuments: !prev.onlyShowMyDocuments,
          }))
        }
        activeTab={this.state.activeTab}
        onChangeTab={newTab => {
          this.setState({activeTab: newTab});
          // This is only strictly necessary if the search value has been
          // changed on the reports tab
          if (newTab === 0) {
            this.onNavigate(1);
          }
        }}
      />
    );
  }

  onNavigate = page => {
    this.fetchDocuments(page, {
      searchValue: this.state.searchValue,
      onlyShowMyDocuments: this.state.onlyShowMyDocuments,
    }).then(() => this.setState({page}));
  };

  uploadDocument = async (
    files,
    isTemplate,
    templateDocumentId,
    isOutputTemplate,
    issuesets,
    groupId,
    concatenateFiles,
  ) => {
    const {organisationId, projectId} = this.props.params;
    const {connection_id: connectionId} = this.props.websocket;

    await this.props.uploadDocumentAction(
      organisationId,
      projectId,
      files,
      "website",
      isTemplate,
      isOutputTemplate,
      connectionId,
      templateDocumentId,
      null,
      issuesets,
      groupId,
      concatenateFiles,
    );

    if (this.state.page !== 1) {
      this.onNavigate(1);
    }
  };
  onCompareToFormTemplate = (files, templateDocumentId, formTemplateId) => {
    const {organisationId, projectId} = this.props.params;
    const {connection_id: connectionId} = this.props.websocket;
    this.props.uploadDocumentAction(
      organisationId,
      projectId,
      files,
      "website",
      false,
      false,
      connectionId,
      templateDocumentId,
      formTemplateId,
      null,
    );
  };
  onFormTemplateUploaded = (file, templateDocumentId) => {
    const {organisationId, projectId} = this.props.params;
    const {connection_id: connectionId} = this.props.websocket;
    this.props.uploadFormTemplateAction(
      organisationId,
      projectId,
      file,
      connectionId,
      templateDocumentId,
    );
  };

  deleteDocumentsRoles = () => {
    const {organisationId, projectId} = this.props.params;
    this.props.deleteDocumentsRolesAction(organisationId, projectId);
  };

  generateProjectLlmPrompt = (prompt, conversationId, previousItemId) => {
    const {organisationId, projectId} = this.props.params;
    this.props.generateProjectLlmPrompt(
      organisationId,
      projectId,
      prompt,
      conversationId,
      previousItemId,
    );
  };

  fetchConversations = () => {
    const {organisationId, projectId} = this.props.params;
    this.props.fetchConversations(organisationId, projectId);
  };

  deleteDocument = (documentId, lastEdited) => {
    const {organisationId, projectId} = this.props.params;
    this.props.deleteDocumentAction(
      organisationId,
      projectId,
      documentId,
      lastEdited,
    );
  };
  reprocessDocument = (documentId, lastEdited, contractType) => {
    const {organisationId, projectId} = this.props.params;
    this.props.reprocessDocumentAction(
      organisationId,
      projectId,
      documentId,
      lastEdited,
      contractType,
    );
  };
  reprocessDocumentRoles = documentId => {
    const {organisationId, projectId} = this.props.params;
    this.props.reprocessDocumentRolesAction(
      organisationId,
      projectId,
      documentId,
      false,
    );
  };
  regenerateDocumentEmbeddings = documentId => {
    const {organisationId, projectId} = this.props.params;
    this.props.regenerateDocumentEmbeddingsAction(
      organisationId,
      projectId,
      documentId,
    );
  };
  reclassifyDocument = (documentId, lastEdited, contractType) => {
    const {organisationId, projectId} = this.props.params;
    return this.props.reclassifyDocumentAction(
      organisationId,
      projectId,
      documentId,
      lastEdited,
      contractType,
    );
  };
  updateDocumentLoadState = documentId => {
    const {organisationId, projectId} = this.props.params;
    return this.props.updateDocumentLoadStateAction(
      organisationId,
      projectId,
      documentId,
    );
  };
  downloadDocument = (
    documentId,
    documentName,
    withChanges = false,
    withMarkup = false,
    withComments = false,
    withoutExtension = false,
  ) => {
    const {organisationId, projectId} = this.props.params;
    this.props.downloadDocumentAction(
      organisationId,
      projectId,
      documentId,
      0,
      documentName,
      withChanges,
      withMarkup,
      withComments,
      withoutExtension,
    );
  };
  emailReport = (documentId, issuesetId) => {
    const {organisationId, projectId} = this.props.params;
    this.props.emailReportAction(
      organisationId,
      projectId,
      documentId,
      issuesetId,
    );
  };

  updateProject(data) {
    const {organisationId, projectId} = this.props.params;
    return this.props.projectUpdateAction(organisationId, projectId, {
      ...data,
      last_edited: this.props.project.last_edited,
    });
  }

  updateProjectDocumentType = document_type_id => {
    return this.updateProject({document_type_id});
  };

  updateProjectDefaultContractType = default_contract_type_id => {
    return this.updateProject({default_contract_type_id});
  };

  updateDocument = (documentId, data) => {
    const {organisationId, projectId} = this.props.params;
    this.props.documentUpdateAction(
      organisationId,
      projectId,
      documentId,
      data,
      false,
    );
  };

  toggleAnalystView = documentId => {
    const path = this.props.router.location.pathname;
    this.props.router.push(path.replace("list", documentId));
  };

  viewSummaryPage = documentId => {
    const path = this.props.router.location.pathname;
    this.props.router.push(path.replace("list", `${documentId}/comparison`));
  };

  toggleDocumentCreateView = () => {
    const path = this.props.router.location.pathname;
    this.props.router.push(path.replace("list", "create"));
  };

  fetchDocuments = (page = 1, filterOptions) => {
    const {organisationId, projectId} = this.props.params;
    return this.props.documentsFetchAction(
      organisationId,
      projectId,
      page,
      filterOptions
        ? {
            search_term:
              "searchValue" in filterOptions &&
              filterOptions.searchValue.length > 0
                ? filterOptions.searchValue
                : undefined,
            user_id: filterOptions.onlyShowMyDocuments
              ? this.props.user.id
              : undefined,
          }
        : undefined,
    );
  };

  logsFetch = documentId => {
    const {organisationId} = this.props.params;
    const query = `document_id=${documentId}`;
    this.props.logsFetch(organisationId, query);
  };

  documentUploadLogsFetch = documentId => {
    const {organisationId} = this.props.params;
    return this.props.logsFetch(
      organisationId,
      `document_upload_logs=true&document_id=${documentId}`,
    );
  };

  fetchDocumentFile = documentId => {
    const {organisationId, projectId} = this.props.params;
    this.props.fetchDocumentFile(organisationId, projectId, documentId);
  };

  fetchDocument = documentId => {
    const {organisationId, projectId} = this.props.params;
    this.props.fetchDocument(organisationId, projectId, documentId);
  };

  fetchReports = () => {
    const {searchValue, onlyShowMyDocuments} = this.state;
    const {organisationId, projectId} = this.props.params;
    this.props.fetchReports(organisationId, projectId, {
      search_term: searchValue.length > 0 ? searchValue : undefined,
      user_id: onlyShowMyDocuments ? this.props.user.id : undefined,
    });
  };

  runDocumentIssuesFind = documentId => {
    const {organisationId, projectId} = this.props.params;
    this.props.documentIssuesFindRun(organisationId, projectId, documentId);
  };

  runDocumentsReprocess = () => {
    const {organisationId, projectId} = this.props.params;
    this.props.documentsReprocess(organisationId, {project_id: projectId});
    this.refreshDocumentsWithTimeout();
  };

  refreshDocumentsWithTimeout = () => {
    const that = this;
    this.issuesRelatedFetchDocumentsTimeout = setTimeout(async () => {
      const documents = (await that.fetchDocuments()).value;
      const outOfDateDocument = documents.find(
        doc =>
          getDocumentIssuesOutOfDateStatus(doc, this.props.project) ===
          "refresh_pending",
      );
      if (outOfDateDocument) {
        that.refreshDocumentsWithTimeout();
      }
    }, 10000);
  };
}

function select(state) {
  const topicsById =
    state.topics && state.topics !== UNINITIALISED
      ? byId(state.topics)
      : UNINITIALISED;

  return {
    documents:
      state.documents === UNINITIALISED
        ? null
        : groupDocuments(state.documents),
    formTemplates: state.formTemplates,
    organisation: state.organisation,
    project: state.project,
    projects: state.projects,
    websocket: state.websocket,
    user: state.user,
    documentTypes: state.document_types,
    contractTypes: state.contract_types,
    parties: state.parties,
    reports: state.reports,
    documentStates:
      state.documentStates === UNINITIALISED ? [] : state.documentStates,
    documentLogs: state.logs === UNINITIALISED ? null : state.logs,
    document: state.document === UNINITIALISED ? null : state.document,
    topicsById,
    documentIssues: state.documentIssues,
    documentClauses: state.documentClauses.clauses
      ? state.documentClauses.clauses
      : UNINITIALISED,
    error: state.error === UNINITIALISED ? null : state.error,
    roles: state.roles,
    documentsCount:
      state.project?.filtered_documents_count ?? state.project?.documents_count,
  };
}

function mapDispatchToProps(dispatch) {
  const boundUpdater = bindActionCreators(
    {update: documentUploadUpdateAction()},
    dispatch,
  );
  const {update} = boundUpdater;
  return {
    dispatch,
    ...bindActionCreators(
      {
        downloadDocumentAction: downloadDocumentAction(requestor),
        uploadDocumentAction: uploadDocumentAction(requestor, update),
        uploadFormTemplateAction: uploadFormTemplateAction(requestor),
        deleteDocumentAction: deleteDocumentAction(requestor),
        emailReportAction: emailReportAction(requestor),
        reprocessDocumentAction: reprocessDocumentAction(requestor),
        reprocessDocumentRolesAction: reprocessDocumentRolesAction(requestor),
        regenerateDocumentEmbeddingsAction: regenerateDocumentEmbeddingsAction(
          requestor,
        ),
        reclassifyDocumentAction: reclassifyDocumentAction(requestor),
        updateDocumentLoadStateAction: updateDocumentLoadStateAction(requestor),
        projectUpdateAction: projectUpdateAction(requestor),
        documentUpdateAction: documentUpdateAction(requestor),
        documentsFetchAction: documentsFetchAction(requestor),
        documentRefreshAction: documentRefreshAction(requestor),
        logsFetch: logsFetchAction(requestor),
        logsClear: logsClearAction,
        fetchDocumentFile: fetchDocumentFileAction,
        fetchReports: reportsFetchAction,

        fetchDocumentReportDetail: documentReportDetailFetchAction(requestor),
        clearDocument: documentClearAction,
        clearDocuments: clearDocumentsAction,
        clearError: errorClearAction,
        documentIssuesFindRun: documentIssuesFindRunAction(requestor),
        documentsReprocess: documentsReprocessAction(requestor),
        deleteDocumentsRolesAction: deleteDocumentsRolesAction(requestor),
        generateProjectLlmPrompt: generateProjectLlmPromptAction(requestor),
        fetchConversations: conversationsFetchAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(Documents);
export const Component = Documents;
