import basePath from "base_path";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes, {
  AccessControlEntryActionTypes,
} from "../constants/action_types";
import {AccessControlEntry} from "common/types/accesscontrolentry";

export type RemoveAccessControlEntryAction = {
  type: AccessControlEntryActionTypes["ACCESS_CONTROL_ENTRY_REMOVE"]["SUCCESS"];
  payload: Pick<AccessControlEntry, "id">;
};

export default createReduxPromiseAction(
  ActionTypes.ACCESS_CONTROL_ENTRY_REMOVE,
  (organisationId: number, ace: Pick<AccessControlEntry, "id">) =>
    requestor
      .delete(
        `${basePath}/organisation/${organisationId}/accesscontrolentry/${ace.id}`,
        {withCredentials: true},
      )
      .then(() => ace),
);
