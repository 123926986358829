import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import TopicEditor from "common_components/topic_editor";

import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";

const styles = {
  actions: {
    zIndex: 0,
  },
};

export class CreateTopicDialog extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      name: this.props.initialName,
      showing: true,
      nameError: this.props.topics.some(
        topic => topic.name === this.props.initialName,
      )
        ? "This topic name is already in use"
        : undefined,
    };
  }

  render() {
    const {
      topicCategories,
      topicTags,
      onDismiss,
      contractTypesById,
      inititalContractTypes,
    } = this.props;
    const errorMessage = this.state.topicCategoryError ? (
      <div
        className="error-message"
        style={{
          color: "red",
        }}
      >
        {this.state.topicCategoryError}
      </div>
    ) : null;

    const actions = [
      <FlatButton
        label="Cancel"
        key="create-topic-dialog-cancel-button"
        secondary={true}
        onClick={onDismiss}
        style={styles.actions}
      />,
      <FlatButton
        label="Create Topic"
        key="create-topic-dialog-confirm-button"
        primary={true}
        onClick={this.saveNewTopicHandler}
        className="save-button"
        style={styles.actions}
      />,
    ];

    return (
      <Dialog
        title="Create new topic"
        className="dialog"
        onDismiss={onDismiss}
        open={true}
        actions={actions}
        bodyStyle={{overflowY: "inherit", zIndex: 2000}}
      >
        <div>
          <TextField
            className="topic-name"
            ref={this.createTopicNameRef}
            floatingLabelText="Topic Name"
            errorText={this.state.nameError}
            value={this.state.name}
            style={{width: "100%"}}
            inputStyle={{padding: "0 0.5em"}}
            onChange={this.changeTopicName}
          />
          <TopicEditor
            className="topic-editor"
            ref={this.createTopicEditorRef}
            floatingLabelText={"Topic Name"}
            topicCategories={topicCategories}
            topicTags={topicTags}
            contractTypesById={contractTypesById}
            initialTopic={{
              id: -1,
              topiccategory_id: null,
              tags: [],
              name: "New Topic",
              contract_types: inititalContractTypes
                ? [inititalContractTypes]
                : [],
            }}
          />
          {errorMessage}
        </div>
      </Dialog>
    );
  }

  componentDidMount() {
    _.defer(() => this.focusField());
  }

  componentDidUpdate() {
    this.focusField();
  }

  focusField() {
    if (this.topicNameRef && this.topicNameRef.focus) {
      this.topicNameRef.focus();
    } else {
      setTimeout(() => this.focusField, 100);
    }
  }

  changeTopicName = event =>
    this.setState({
      name: event.target.value,
      nameError: this.props.topics.some(
        topic => topic.name === event.target.value,
      )
        ? "This topic name is already in use"
        : undefined,
    });

  saveNewTopicHandler = () => {
    this.saveNewTopic(this.state.name, this.topicEditorRef.getValue());
  };

  saveNewTopic(name, topicValues) {
    if (name.length === 0) {
      this.setState({nameError: "You must set the topic name"});
    } else if (
      !topicValues.topiccategory_id &&
      !topicValues.topiccategory_name
    ) {
      this.setState({topicCategoryError: "You must set a topic category"});
    } else {
      this.props.onNewTopicAdded({name, tags: [], ...topicValues});
      this.setState({showing: false});
    }
  }

  createTopicNameRef = node => (this.topicNameRef = node);
  createTopicEditorRef = node => (this.topicEditorRef = node);
}

CreateTopicDialog.propTypes = {
  initialName: PropTypes.string.isRequired,
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onDismiss: PropTypes.func.isRequired,
  onNewTopicAdded: PropTypes.func.isRequired,
};

export default CreateTopicDialog;
