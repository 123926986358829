import React, {useState} from "react";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";

interface AddItemProps {
  label: string;
  onAdd: (name: string) => void;
  inputType?: "text" | "number";
}

const AddItem: React.FC<AddItemProps> = ({
  onAdd,
  label,
  inputType = "text",
}) => {
  const [isAddingNew, setIsAddingNew] = useState<boolean>(false);
  const [newInputName, setNewInputName] = useState<string>("");

  return !isAddingNew ? (
    <IconButton onClick={() => setIsAddingNew(true)}>
      <AddIcon />
    </IconButton>
  ) : (
    <div>
      <TextField
        type={inputType}
        style={{width: "30em"}}
        value={newInputName}
        label={label}
        focused
        onChange={event => setNewInputName(event.target.value)}
      />
      <IconButton
        onClick={() => {
          onAdd(newInputName);
          setNewInputName("");
          setIsAddingNew(false);
        }}
      >
        <SaveIcon />
      </IconButton>
    </div>
  );
};

export default AddItem;
