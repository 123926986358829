import React from "react";
import _ from "underscore";

import ReportIssueItem from "./report_issue_item";

import getIssueColorValue from "utils/issues/get_issue_color_value";
import getIssueColor from "./get_issue_color";
import commonStyles from "./common_styles";

const styles = {
  ...commonStyles,
  issueGroup: {
    fontSize: 15,
    fontWeight: 400,
  },
  group: {
    marginBottom: 24,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bdbdbd",
    paddingTop: "1rem",
  },
  groupLabel: {
    display: "flex",
  },
  groupName: {
    fontSize: 18,
  },
};

function constructIssuesColorsData(issues) {
  const groupStats = {
    green: 0,
    amber: 0,
    red: 0,
  };

  const issuesWithColor = issues.map(issue => {
    const issueColor = getIssueColor(issue);
    groupStats[issueColor] += 1;
    return {
      ...issue,
      issueColor,
    };
  });

  let groupColor = "green";
  if (groupStats.red) {
    groupColor = "red";
  } else if (!groupStats.red && groupStats.amber) {
    groupColor = "amber";
  }

  return {groupColor, issues: issuesWithColor};
}

function ReportIssueGroup(props) {
  const {name: groupName, item: groupIssues = []} = props.group || {};
  const {issues, groupColor} = constructIssuesColorsData(groupIssues);

  return (
    <fieldset key={groupName} style={styles.group}>
      <legend>
        <div style={styles.groupLabel}>
          <div
            style={{
              ...styles.circle,
              background: getIssueColorValue(groupColor),
            }}
          />
          <span style={styles.groupName}>{groupName}</span>
        </div>
      </legend>
      <div>
        {issues.map((issue, index) => (
          <ReportIssueItem
            key={index}
            issue={issue}
            isIssueInGroup={true}
            {..._.omit(props, ["group"])}
          />
        ))}
      </div>
    </fieldset>
  );
}

export default ReportIssueGroup;
