import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function confirmTopicparameterInClauses(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPICPARAMETER_ACTUAL_VALUES_IN_CLAUSES_UPDATE,
    (organisationId, topicId, topicparameterId, data) =>
      requestor
        .put(
          `${basePath}/organisation/${organisationId}/topic/${topicId}/topicparameter/${topicparameterId}/topicparameter-actual-values-in-clauses`,
          data,
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
