import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {
  WorkflowId,
  WorkflowInput,
  WorkflowInputId,
} from "common/flowmaster/types/workflow";

export type WorkflowInputUpdateAction = {
  type: WorkflowsActionTypes["WORKFLOW_INPUT_UPDATE"]["SUCCESS"];
  payload: WorkflowInput;
};

export default function updateWorkflowInput(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOW_INPUT_UPDATE,
    (
      organisationId: OrganisationId,
      workflowId: WorkflowId,
      workflowInputId: WorkflowInputId,
      data: WorkflowInput,
    ) => {
      return requestor
        .patch(
          `${basePath}/organisation/${organisationId}` +
            `/workflow/${workflowId}/input/${workflowInputId}`,
          data,
          {withCredentials: true},
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
