import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function addContractType(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.CONTRACT_TYPE_ADD,
    (organisationId, newContractTypeData) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/contract_type/new`,
          newContractTypeData,
          {withCredentials: true},
        )
        .then(response => ({
          ...response.data,
          ...newContractTypeData,
        }))
        .catch(err => err.data);
    },
  );
  return action;
}
