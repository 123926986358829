import requestor from "requestor";

import ChildRenderer from "common_components/child_renderer";

import BaseRoute from "utils/base_route";

import definitionGroupsFetchAction from "modules/documents/actions/definition_group/definition_groups_fetch";
import definitionGroupsClearAction from "modules/documents/actions/definition_group/definition_groups_clear";

import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";
import contractTypesClearAction from "modules/documents/actions/contract_types_clear";

import DefinitionGroupList from "routes/definition_group/definition_group_list";
import DefinitionGroupDetail from "routes/definition_group/definition_group_detail";

export default class DefinitionGroupBase extends BaseRoute {
  constructor(store) {
    super("definition_group", store);
  }

  createChildRoutes() {
    return [
      new DefinitionGroupList(this.store),
      new DefinitionGroupDetail(this.store),
    ];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchContractTypes(state);
  }

  fetchContractTypes(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(definitionGroupsFetchAction(requestor)(organisationId));
    dispatch(contractTypesFetchAction(requestor)(organisationId));
  }

  clearActiveData(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(definitionGroupsClearAction(organisationId));
    dispatch(contractTypesClearAction(organisationId));
  }
}
