import React from "react";

import StringArgValue from "./values/string";
import TextMapArgValue from "./values/text_map";
import WorkflowInputValues from "./values/workflow_input_values";

import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";
import {
  ActionDefinitionArg,
  SelectValuesArgument,
  StringArgument,
  TaskArgument,
  TextMapArgument,
  WorkflowInputValuesArgument,
} from "common/flowmaster/types/task_config";
import {Prompt} from "common/flowmaster/types/workflow";

interface Props {
  actionArg: ActionDefinitionArg;
  llmRun: EnhancedLlmRun;
  arg: TaskArgument;
  prompts: Prompt[];
}

export default function ArgumentValue({
  actionArg,
  arg,
  llmRun,
  prompts,
}: Props) {
  if (
    actionArg.type === "string" ||
    actionArg.type === "int" ||
    actionArg.type === "model_select"
  ) {
    return (
      <StringArgValue
        arg={(arg as StringArgument)?.value}
        llmRun={llmRun}
        prompts={prompts}
      />
    );
  } else if (actionArg.type === "select") {
    return <div>{(arg as SelectValuesArgument).value.key}</div>;
  } else if (actionArg.type === "text_map") {
    return (
      <TextMapArgValue
        arg={(arg as TextMapArgument)?.value}
        llmRun={llmRun}
        prompts={prompts}
      />
    );
  } else if (actionArg.type === "workflow_input_values") {
    return (
      <WorkflowInputValues
        arg={(arg as WorkflowInputValuesArgument)?.value}
        llmRun={llmRun}
        prompts={prompts}
      />
    );
  } else if (actionArg.type === "json_schema") {
    return <span>"see output"</span>;
  } else {
    return (
      <div>
        unknown type: ${actionArg.type} ${JSON.stringify(arg)}
      </div>
    );
  }
}
