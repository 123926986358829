import React from "react";
import styled from "styled-components";

import DeleteIcon from "@material-ui/icons/Delete";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ButtonGroup,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import Dialog from "routes/usergroup/components/Dialog/Dialog";
import Table from "routes/usergroup/components/Table";
import Toolbar from "routes/usergroup/components/Toolbar";
import useDialog from "routes/usergroup/util/useDialog";
import {
  AccessControlEntry,
  RawAccessControlEntry,
} from "common/types/accesscontrolentry";
import {Accesstag} from "modules/accesstags/actions/fetch";
import {PermissionGroup} from "../containers/accesscontrolentry";
import {Usergroup} from "common/types/usergroup";
import ConfirmDialog from "common_components/confirm_dialog";
import AccessTagSelector from "routes/project/project_detail/components/AccessTagSelector";
import {MISSING, MaybeMissing} from "common/types/util";

/**
 * Dereferences the IDs of an access control entry. Arguably, we may want to
 * handle these pretty much everywhere in the frontend. Maybe we should just do
 * this further up?
 */
export type Dereferenced<ACE extends AccessControlEntry> = {
  id: ACE["id"];
  usergroup: Usergroup;
  permissionGroup: PermissionGroup;
} & ({global_scope: true} | {accesstags: Accesstag[]; global_scope: false});

export type MaybeDereferenced<ACE extends AccessControlEntry> = {
  id: ACE["id"];
  usergroup: MaybeMissing<Usergroup>;
  permissionGroup: MaybeMissing<PermissionGroup>;
} & ({global_scope: true} | {accesstags: Accesstag[]; global_scope: false});

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const TableWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
`;

const Red = styled.span`
  color: red;
  font-style: italic;
`;

const Italic = styled.span`
  font-style: italic;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

type AccessControlEntriesProps = {
  accessControlEntries: Array<MaybeDereferenced<AccessControlEntry>>;
  accesstags: Accesstag[];
  permissionGroups: PermissionGroup[];
  usergroups: Usergroup[];

  /**
   * Optional callback invoked when an access control entry is added via a
   * dialog managed in this component.
   */
  onAddAccessControlEntry?: (
    accessControlEntry: Omit<RawAccessControlEntry, "id">,
  ) => void;

  /** Optional callback invoked when an access control entry is removed */
  onRemoveAccessControlEntry?: (
    accessControlEntry: Pick<AccessControlEntry, "id">,
  ) => void;
};

const AccessControlEntries = ({
  accessControlEntries,
  accesstags,
  permissionGroups,
  usergroups,
  onAddAccessControlEntry,
  onRemoveAccessControlEntry,
}: AccessControlEntriesProps) => {
  const dialog = useDialog<
    Partial<{
      usergroup_id: number;
      accesstag_ids: number[];
      permission_group_id: number;
      global_scope: boolean;
    }>
  >(["usergroup_id", "accesstag_ids", "permission_group_id", "global_scope"]);

  const deleteDialog = useDialog<{ace: Pick<AccessControlEntry, "id">}>([
    "ace",
  ]);

  return (
    <Container>
      <Toolbar
        title="Access Control Entries"
        onClickAddIcon={dialog.isOpen ? undefined : () => dialog.open({})}
      />

      <TableWrapper>
        <Table
          headers={[
            {title: "ID", style: {textAlign: "left"}},
            {title: "Usergroup", style: {textAlign: "left"}},
            {title: "Permission group", style: {textAlign: "left"}},
            {title: "Accesstag", style: {textAlign: "right"}},
            {title: ""},
          ]}
          rows={accessControlEntries
            .sort((a, b) => {
              // Sort missing accesstags and usergroups to the bottom.
              if (a.usergroup === MISSING || a.permissionGroup === MISSING) {
                return 1;
              } else if (
                b.usergroup === MISSING ||
                b.permissionGroup === MISSING
              ) {
                return -1;
              }
              return (
                // Sort by usergroup name...
                a.usergroup.name.localeCompare(b.usergroup.name, undefined, {
                  sensitivity: "accent",
                }) ||
                // ...then by permission group name
                a.permissionGroup.name.localeCompare(
                  b.permissionGroup.name,
                  undefined,
                  {sensitivity: "accent"},
                )
              );
            })
            .map(ace => [
              {data: ace.id},
              {
                data:
                  ace.usergroup !== MISSING ? (
                    ace.usergroup.name
                  ) : (
                    <Red>Missing</Red>
                  ),
              },
              {
                data:
                  ace.permissionGroup !== MISSING ? (
                    ace.permissionGroup.name
                  ) : (
                    <Red>Missing</Red>
                  ),
              },
              {
                data: ace.global_scope ? (
                  <Italic>ALL</Italic>
                ) : (
                  ace.accesstags.map(tag => tag.name).join(", ")
                ),
                style: {textAlign: "right"},
              },
              {
                style: {width: "4rem", paddingLeft: "1rem"},
                data:
                  ace.permissionGroup !== MISSING &&
                  ace.usergroup !== MISSING ? (
                    <ButtonGroup variant="text" size="small">
                      <IconButton
                        onClick={() =>
                          !deleteDialog.isOpen && deleteDialog.open({ace})
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ButtonGroup>
                  ) : null,
              },
            ])}
        />
      </TableWrapper>

      {dialog.isOpen &&
        (() => {
          const {
            accesstag_ids,
            setAccesstag_ids,
            usergroup_id,
            setUsergroup_id,
            permission_group_id,
            setPermission_group_id,
            global_scope,
            setGlobal_scope,
          } = dialog;

          const inputComplete =
            ((accesstag_ids && accesstag_ids.length > 0) || global_scope) &&
            permission_group_id !== undefined &&
            usergroup_id !== undefined;

          return (
            <Dialog
              title="Add Access Control Entry"
              buttons={[
                {text: "Cancel", onClick: dialog.close},
                {
                  text: "Save",
                  disabled: !inputComplete,
                  onClick: () => {
                    if (inputComplete) {
                      onAddAccessControlEntry?.({
                        accesstag_ids: accesstag_ids ?? [],
                        permission_group_id,
                        usergroup_id,
                        global_scope: global_scope ?? false,
                      });
                    }
                    dialog.close();
                  },
                },
              ]}
            >
              <DialogContent>
                {[
                  {
                    label: "Usergroup",
                    value: usergroup_id,
                    onChange: setUsergroup_id,
                    options: usergroups,
                  },
                  {
                    label: "Permission group",
                    value: permission_group_id,
                    onChange: setPermission_group_id,
                    options: permissionGroups,
                  },
                ].map(({value, options, label, onChange}) => (
                  <FormControl key={label}>
                    <InputLabel id={label}>{label}</InputLabel>
                    <Select
                      value={value ?? ""}
                      onChange={({target: {value}}) =>
                        onChange(value as number | undefined)
                      }
                    >
                      {options.map(({id, name}) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ))}

                {!global_scope && (
                  <AccessTagSelector
                    tags={{
                      available: accesstags,
                      selected: accesstags.filter(tag =>
                        dialog.accesstag_ids?.includes(tag.id),
                      ),
                    }}
                    onAddTag={tag =>
                      setAccesstag_ids((accesstag_ids ?? []).concat(tag.id))
                    }
                    onRemoveTag={tag =>
                      setAccesstag_ids(
                        (accesstag_ids ?? []).filter(id => id !== tag.id),
                      )
                    }
                  />
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={global_scope}
                      onChange={({target: {checked}}) =>
                        setGlobal_scope(checked)
                      }
                    />
                  }
                  label="Global scope"
                />
              </DialogContent>
            </Dialog>
          );
        })()}

      {deleteDialog.isOpen && (
        <ConfirmDialog
          open
          title="Delete access control entry?"
          cancelButtonCaption="Cancel"
          description={
            `This action will delete the access control entry with id #${deleteDialog.ace.id}. ` +
            "This action cannot be undone."
          }
          okButtonCaption="Confirm"
          onClose={deleteDialog.close}
          onSuccess={() => {
            onRemoveAccessControlEntry?.(deleteDialog.ace);
            deleteDialog.close();
          }}
        />
      )}
    </Container>
  );
};

export default AccessControlEntries;
