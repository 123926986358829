import TopicCategoryList from "./containers/topiccategory_list";

export default class TopicCategoryListRoute {
  constructor() {
    this.path = "list";
  }
  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, TopicCategoryList);
  }
}
