import {Id, Uuid} from "../../types";
import {WorkflowState} from "./workflow_state";
import {WorkflowInput, WorkflowMetaOverrides} from "./workflow";
import {WorkflowTask} from "./task_config";
export type LlmRunId = Id;
export type RunId = Uuid;

export interface LlmRunInfo {
  id: LlmRunId;
  run_id: RunId;
}

export interface LlmRun extends LlmRunInfo {
  data: WorkflowState;
  has_failed: boolean;
  is_complete: boolean;
}

export interface EnhancedLlmRun extends LlmRun {
  workflow_name: string;
  inputs: WorkflowInput[];
  tasks: WorkflowTask[];
  overrides: WorkflowMetaOverrides;
  links: Record<string, unknown>;
  creation_reason: string;
  creation_date: Date;
}

export const llmRunNumberFilterKeys = [
  "workflow",
  "project",
  "document",
  "issue",
  "clausepart",
] as const;
export const llmRunStringArrayFilterKeys = ["reasons", "status"] as const;
export const llmRunDateFilterKeys = ["from", "to"] as const;

export const llmRunFilterKeys = [
  ...llmRunNumberFilterKeys,
  ...llmRunStringArrayFilterKeys,
  ...llmRunDateFilterKeys,
];

export type LlmRunNumberFilterKeys = typeof llmRunNumberFilterKeys[number];
export type LlmRunStringArrayFilterKeys = typeof llmRunStringArrayFilterKeys[number];
export type LlmRunDateFilterKeys = typeof llmRunDateFilterKeys[number];

export type LlmRunFilterKeys =
  | LlmRunNumberFilterKeys
  | LlmRunStringArrayFilterKeys
  | LlmRunDateFilterKeys;
export type LlmRunNumberFilters = {
  [key in LlmRunNumberFilterKeys]: number | null;
};
export type LlmRunStringArrayFilters = {
  // When URL queries are parsed, arrays with a single value are treated as strings.
  [key in LlmRunStringArrayFilterKeys]: string | string[] | null;
};
export type LlmRunDateFilters = {
  [key in LlmRunDateFilterKeys]: Date | string | null;
};
export type LlmRunFilters = LlmRunNumberFilters &
  LlmRunStringArrayFilters &
  LlmRunDateFilters;
