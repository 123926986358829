import React, {ChangeEvent} from "react";
import {Select, MenuItem, FormControl, InputLabel} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Link as LinkIcon, Add as AddIcon} from "@material-ui/icons";
import {Link} from "react-router";
import {
  WorkflowId,
  WorkflowInstanceDetail,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

interface WorkflowSelectorProps {
  organisationId: OrganisationId;
  workflows: WorkflowInstanceDetail[];
  selectedWorkflowId: WorkflowId;
  updateselectedWorkflowId: (workflowId: WorkflowId) => void;
  onAddNew: () => void;
  label?: string;
}

const PrerequisiteSelector = ({
  organisationId,
  workflows,
  selectedWorkflowId,
  updateselectedWorkflowId,
  onAddNew,
  label = "Prerequisite",
}: WorkflowSelectorProps) => {
  const handleChange = (event: ChangeEvent<{value: unknown}>) => {
    const value = event.target.value as number | string;
    if (value === "add_new") {
      onAddNew();
    } else {
      updateselectedWorkflowId(value as number);
    }
  };

  const workflowItems = [
    ...(workflows.length > 0
      ? workflows.map(workflow => (
          <MenuItem key={workflow.id} value={workflow.id}>
            {workflow.name}
          </MenuItem>
        ))
      : [
          <MenuItem key="no_workflows" disabled value="">
            No prerequisite workflows to select
          </MenuItem>,
        ]),
    <MenuItem key="add_new" value="add_new">
      <AddIcon fontSize="small" style={{marginRight: 8}} />
      Add new
    </MenuItem>,
  ];

  const renderValue = (selectedId: number | string) => {
    if (selectedId === "add_new") {
      return "Add new";
    }
    const foundWorkflow = workflows.find(
      workflow => workflow.id === selectedId,
    );
    return foundWorkflow ? foundWorkflow.name : "";
  };

  return (
    <div style={{display: "flex", alignItems: "baseline"}}>
      <FormControl style={{width: "30em"}}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={selectedWorkflowId === -1 ? "" : selectedWorkflowId}
          onChange={handleChange}
          renderValue={renderValue}
        >
          {workflowItems}
        </Select>
      </FormControl>
      {selectedWorkflowId > 0 && (
        <Link
          to={`/organisation/${organisationId}/workflow/${selectedWorkflowId}/detail`}
        >
          <IconButton size="small" style={{padding: 0}}>
            <LinkIcon />
          </IconButton>
        </Link>
      )}
    </div>
  );
};

export default PrerequisiteSelector;
