import React from "react";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import ProjectListComponent from "../components/project_list";

import byId from "common/utils/by_id";
import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import {Project} from "common/types/project";

interface ProjectListProps {
  projects: Project[];
}

function ProjectList(props: ProjectListProps) {
  const {organisation, projects, contractTypes, user} = props;
  const shouldRenderContainer = isInitialised([projects, contractTypes, user]);

  if (!shouldRenderContainer || !user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const permissioner = new Permissioner(user);
  if (!permissioner.hasPermission("get-projects")) {
    return permissioner.getNoPermissionMessage();
  }

  const organisationId = parseInt(props.params.organisationId, 10);

  function createNewProject() {
    props.push(`/organisation/${organisationId}/project/add`);
  }

  setTitle("Projects");
  return (
    <ProjectListComponent
      organisation={organisation}
      contractTypes={contractTypes}
      contractTypesById={byId(contractTypes)}
      organisationId={organisationId}
      projects={projects.filter(p => permissioner.canViewProject(p))}
      createNewProject={createNewProject}
      user={user}
    />
  );
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        push,
      },
      dispatch,
    ),
  };
}

function select(state) {
  return {
    organisation: state.organisation,
    projects: state.projects,
    user: state.user,
    contractTypes: state.contract_types,
  };
}

export default connect(select, mapDispatchToProps)(ProjectList);
