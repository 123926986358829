import _ from "underscore";
import React from "react";
import requestor from "requestor";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";

import addClientAction from "modules/organisation/actions/client_add.js";
import updateClientAction from "modules/organisation/actions/client_update.js";
import fetchClientIssuesetSyncInfoAction from "modules/organisation/actions/client_fetch_issueset_sync_info.js";
import syncClientIssuesetAction from "modules/organisation/actions/client_sync_issueset.js";
import updateIssueset from "modules/organisation/actions/issueset_update";

import ClientOrganisationsComponent from "../components/client_organisations";

class ClientOrganisations extends React.Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return this.permissioner.isAdmin();
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.clients,
      this.props.user,
      this.props.permission_groups,
      this.props.contract_types,
      this.props.organisation,
    ]);
  }

  renderComponent() {
    return (
      <React.Fragment>
        {this.renderClientOrganisations()}
        {this.props.children}
      </React.Fragment>
    );
  }

  renderClientOrganisations() {
    setTitle("Client Organisations");
    return (
      <ClientOrganisationsComponent
        organisation={this.props.organisation}
        user={this.props.user}
        clients={this.props.clients}
        permissionGroups={this.props.permission_groups}
        contractTypes={this.props.contract_types}
        addClient={this.addClient}
        updateClient={this.updateClient}
        organisationId={parseInt(this.props.params.organisationId, 10)}
        onSyncClientIssueset={this.syncClientIssueset}
        loadSyncListOfChanges={this.loadSyncListOfChanges}
        checkIsArchivedHandler={this.checkIsArchivedHandler}
        checkIssuesetIsArchived={this.checkIssuesetIsArchived}
        updateIssuesetName={this.updateIssuesetName}
      />
    );
  }

  addClient = data => {
    this.props.addClient(this.props.params.organisationId, data);
  };

  updateClient = (clientId, data) => {
    this.props.updateClient(this.props.params.organisationId, clientId, data);
  };

  syncClientIssueset = (
    clientId,
    issuesetMasterId,
    syncList,
    onSuccess,
    onError,
  ) => {
    this.props.syncClientIssueset(
      this.props.params.organisationId,
      clientId,
      issuesetMasterId,
      syncList,
      onSuccess,
      onError,
    );
  };

  loadSyncListOfChanges = (
    clientId,
    issuesetMasterId,
    syncList,
    onSuccess,
    onError,
  ) => {
    this.props.fetchClientIssuesetSyncInfo(
      this.props.params.organisationId,
      clientId,
      issuesetMasterId,
      syncList,
      onSuccess,
      onError,
    );
  };

  checkIsArchivedHandler = _.memoize(
    client => () =>
      this.updateClient(client.id, {is_archived: !client.is_archived}),
    client => JSON.stringify(client),
  );

  checkIssuesetIsArchived = _.memoize(
    issueset => () => {
      this.props.updateIssueset(
        issueset.organisation_id,
        issueset.contract_type_id,
        issueset.id,
        {is_archived: !issueset.is_archived},
      );
    },
    issueset => JSON.stringify(issueset),
  );

  updateIssuesetName = _.memoize(
    issueset => name => {
      this.props.updateIssueset(
        issueset.organisation_id,
        issueset.contract_type_id,
        issueset.id,
        {name},
      );
    },
    issueset => JSON.stringify(issueset),
  );
}

function select(state) {
  return {
    organisation: state.organisation,
    clients: state.clients,
    user: state.user,
    permission_groups: state.permission_groups,
    contract_types: state.contract_types,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addClient: addClientAction(requestor),
      updateClient: updateClientAction(requestor),
      fetchClientIssuesetSyncInfo: fetchClientIssuesetSyncInfoAction(requestor),
      syncClientIssueset: syncClientIssuesetAction(requestor),
      updateIssueset,
    },
    dispatch,
  );
}

export default connect(select, mapDispatchToProps)(ClientOrganisations);
export const Component = ClientOrganisations;
