import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

import {buildQuery} from "utils/uri";

export default function createClassifierRunBatch(requestor) {
  return createReduxPromiseAction(
    ActionTypes.CLASSIFIER_RUN_BATCH_ADD,
    (
      organisationId,
      trainingModeId,
      datasetModeId,
      topicId,
      configurationId,
      topicTrainingNodeId,
      clausepartIds,
      params = {},
      contractTypeIds,
    ) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/classifier/run/batch${buildQuery(
            params,
          )}`,
          {
            training_mode_id: trainingModeId,
            dataset_mode_id: datasetModeId,
            topic_id: topicId,
            configuration_id: configurationId,
            topic_trainingnode_id: topicTrainingNodeId,
            ...(typeof clausepartIds === "string"
              ? {text: clausepartIds}
              : {clausepart_ids: clausepartIds}),
            contract_type_ids: contractTypeIds ?? null,
          },
          {withCredentials: true},
        )
        .then(response => response.data);
    },
  );
}
