import React from "react";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";
import requestor from "requestor";

import CircularProgress from "@material-ui/core/CircularProgress";

import createDocumentAction from "modules/documents/actions/document_create";
import DocumentCreateComponent from "../components/document_create";
import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";

function DocumentCreate(props) {
  const {documents, project, user} = props;
  const shouldRenderContainer = isInitialised([project, documents, user]);

  if (!shouldRenderContainer || !user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const permissioner = new Permissioner(user);
  if (!permissioner.hasPermission("create-document")) {
    return permissioner.getNoPermissionMessage();
  }

  async function createDocument(data) {
    const {organisationId, projectId} = props.params;
    await props.createDocumentAction(organisationId, projectId, data);
    props.dispatch(
      push(
        `/organisation/${organisationId}/project/${projectId}/document/list`,
      ),
    );
  }

  setTitle("Create Document");
  return (
    <DocumentCreateComponent
      project={project}
      documents={documents}
      createDocument={createDocument}
    />
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        createDocumentAction: createDocumentAction(requestor),
      },
      dispatch,
    ),
  };
}

function select(state) {
  return {
    project: state.project,
    documents: state.documents,
    user: state.user,
  };
}

export default connect(select, mapDispatchToProps)(DocumentCreate);
