import React, {useState} from "react";
import _ from "lodash";

import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import ClauseListItem from "./clause_list_item";

const styles = {
  dialogContent: {
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 14px 45px, rgba(0, 0, 0, 0.22) 0px 10px 18px",
    backgroundColor: "white",
    boxSizing: "border-box",
    padding: "12px 28px",
    zIndex: 99999,
    color: "#616161",
    width: "70%",
    display: "flex",
    flexDirection: "column",
    maxHeight: "75%",
  },
  dialogActionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "12px",
  },
  emText: {
    fontWeight: 600,
    color: "#000",
  },
};

function SimilarClausesDialog(props) {
  const {
    organisationId,
    clauses,
    action,
    actionData,
    originalClause,
    topicsById,
    topicCategoriesById,
    topicMasksById,
    topicMasks,
    onDialogClose,
  } = props;

  const [whichToPopulate, updateWhichToPopulate] = useState("current"); // or all
  function onWhichToPopulateChange(e) {
    updateWhichToPopulate(e.target.value);
  }

  const [selectedClauses, updateSelectedClauses] = useState(selectAll(clauses));
  function triggerSelectClause(id) {
    updateSelectedClauses({
      ...selectedClauses,
      [id]: !selectedClauses[id],
    });
  }

  function onSave() {
    const originalClauseTopics = originalClause.topics.map(
      topic => topic.topic_id,
    );

    const clauseparts = clauses
      .filter(clause => selectedClauses[clause.clausepart_id])
      .map(clause => {
        const currentClauseTopics = clause.topics.map(topic => topic.topic_id);
        const finalData = {
          organisation_id: organisationId,
          ..._.pick(clause, [
            "project_id",
            "document_id",
            "clause_id",
            "clausepart_id",
          ]),
          added: [],
          removed: [],
        };
        if (whichToPopulate === "current") {
          finalData.added =
            action === "topic_add"
              ? _.difference(actionData, currentClauseTopics)
              : [];
          finalData.removed =
            action === "topic_remove"
              ? _.intersection(currentClauseTopics, actionData)
              : [];
        } else if (whichToPopulate === "add_all") {
          finalData.added = _.difference(
            originalClauseTopics,
            currentClauseTopics,
          );
          finalData.removed =
            action === "topic_remove"
              ? _.intersection(currentClauseTopics, actionData)
              : [];
        } else {
          finalData.added = _.difference(
            originalClauseTopics,
            currentClauseTopics,
          );
          finalData.removed = _.difference(
            currentClauseTopics,
            originalClauseTopics,
          );
        }
        return finalData;
      })
      .filter(clause => clause.added.length || clause.removed.length);
    props.clausepartsTopicsUpdated(clauseparts);
    onDialogClose();
  }

  function renderHeader() {
    return (
      <div>
        <div style={{fontSize: 24, marginBottom: 20}}>
          <span>Topic&nbsp;</span>
          <span style={styles.emText}>
            {topicsById[actionData[0]].name}&nbsp;
          </span>
          <span>{`(id: ${actionData[0]}) was`}&nbsp;</span>
          <span style={styles.emText}>
            {action === "topic_add" ? "added" : "removed"}&nbsp;
          </span>
          <span>{`${action === "topic_add" ? "to" : "from"} clausepart (id: ${
            originalClause.id
          })`}</span>
        </div>
        <div style={{marginBottom: 6}}>
          <span style={styles.emText}>Clausepart text:&nbsp;</span>
          <span>{originalClause.partial_text}</span>
        </div>

        <div style={{display: "flex", alignItems: "center"}}>
          <div style={{fontSize: 18}}>On other clauses,</div>
          <FormControl
            style={{
              width: 396,
              marginLeft: 12,
              marginBottom: 14,
            }}
          >
            <InputLabel>Topics to populate</InputLabel>
            <Select
              value={whichToPopulate}
              onChange={onWhichToPopulateChange}
              MenuProps={{style: {zIndex: 100000}}}
            >
              <MenuItem value="current">{`${
                action === "topic_add" ? "add" : "remove"
              } same topic`}</MenuItem>
              <MenuItem value="all">
                replace all topics with those from amended clause
              </MenuItem>
              <MenuItem value="add_all">
                copy all missing topics from amended clause
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }

  function renderContent() {
    return (
      <div style={{overflow: "auto"}}>
        <table style={{borderCollapse: "collapse", width: "100%"}}>
          <thead>
            <tr style={{height: "2rem"}}>
              <th style={{width: "2%"}} />
              <th style={{width: "10%"}}>Project Name</th>
              <th style={{width: "20%"}}>Document Name</th>
              <th style={{width: "34%"}}>Existing Values</th>
              <th style={{width: "34%"}}>Proposed Values</th>
            </tr>
          </thead>
          <tbody>
            {clauses.map(clause => (
              <ClauseListItem
                key={clause.clausepart_id}
                organisationId={organisationId}
                action={action}
                actionData={actionData}
                clause={clause}
                originalClause={originalClause}
                whichToPopulate={whichToPopulate}
                topicsById={topicsById}
                topicCategoriesById={topicCategoriesById}
                topicMasksById={topicMasksById}
                topicMasks={topicMasks}
                triggerSelectClause={triggerSelectClause}
                isSelected={selectedClauses[clause.clausepart_id]}
                shouldLog={props.shouldLog}
                roles={props.roles}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  function renderActionButtons() {
    return (
      <div style={styles.dialogActionsContainer}>
        <Button onMouseDown={onDialogClose} color="primary">
          CANCEL
        </Button>

        <Button onMouseDown={onSave} color="primary">
          SAVE
        </Button>
      </div>
    );
  }

  return (
    <Popper
      className="similar-clauses-dialog"
      open={true}
      style={styles.dialogContent}
      onClick={e => e.stopPropagation()}
    >
      {renderHeader()}
      {renderContent()}
      {renderActionButtons()}
    </Popper>
  );
}

function selectAll(clauses) {
  return clauses.reduce((result, clause) => {
    result[clause.clausepart_id] = true;
    return result;
  }, {});
}

export default SimilarClausesDialog;
