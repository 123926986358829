import React from "react";
import styled from "styled-components";

import Button from "@material-ui/core/Button";
import CopyIcon from "@material-ui/icons/FileCopy";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

const Container = styled(Paper)`
  width: 100%;
  height: 100%;
`;

const Toolbar = styled.div.attrs({
  className: "app-toolbar",
})`
  font-weight: 600;
  justify-content: flex-start;
  padding-left: 2rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  padding: 1rem;
`;

const ApiUserDropdown = styled(Select)`
  min-width: 10rem;
`;

const TokenDetails = styled.div`
  display: flex;
  width: 100%;
  column-gap: 1rem;
  align-items: center;
`;

const TokenInput = styled(TextField)`
  width: 20rem;
`;

const CopyIconButton = styled(IconButton)`
  padding-top: 1rem;
`;

type Props = {
  /** Current value of organisation's API token */
  apiToken: string;

  /** User associated with organisation's API token */
  apiTokenUser: string;

  /** Optional callback invoked when API token user is changed */
  onChangeApiTokenUser?: (user: string) => void;

  /** Optional callback invoked when "Reset token" button is clicked */
  onClickResetToken?: () => void;

  /** List of users selectable as the user associated with the organisation's API token  */
  users: string[];
};

const ApiTokenSettings = ({
  apiToken,
  apiTokenUser,
  onChangeApiTokenUser,
  onClickResetToken,
  users,
}: Props) => (
  <Container elevation={1} square>
    <Toolbar>API Token</Toolbar>

    <Content>
      <TokenDetails>
        <TokenInput label="API Token" disabled value={apiToken} />
        <CopyIconButton>
          <CopyIcon onClick={() => navigator.clipboard.writeText(apiToken)} />
        </CopyIconButton>

        <Button
          color="secondary"
          variant="contained"
          onClick={onClickResetToken}
        >
          Reset token
        </Button>
      </TokenDetails>

      <FormControl>
        <InputLabel id="api-user-label">API user</InputLabel>
        <ApiUserDropdown
          labelId="api-user-label"
          value={apiTokenUser}
          onChange={event => onChangeApiTokenUser?.(event.target.value)}
        >
          {users.map(user => (
            <MenuItem key={user} value={user}>
              {user}
            </MenuItem>
          ))}
        </ApiUserDropdown>
      </FormControl>
    </Content>
  </Container>
);

export default ApiTokenSettings;
