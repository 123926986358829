import React, {FC} from "react";
import {Grid, Checkbox, FormControlLabel} from "@material-ui/core";

import TypeSelector from "./type_selector";
import TypeDetail from "./type_detail";
import isPossibleEnumType from "./util/is_possible_enum_type";

import {SchemaProperty} from "common/flowmaster/types/schema";
import {
  ActionDefinition,
  WorkflowTask,
} from "common/flowmaster/types/task_config";
import {
  Prompt,
  WorkflowContext,
  WorkflowInputs,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

interface ItemsEditorProps {
  items: SchemaProperty;
  onUpdateItems: (items: SchemaProperty) => void;
  priorTasks: WorkflowTask[];
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  organisationId: OrganisationId;
}

const ItemsEditor: FC<ItemsEditorProps> = ({
  items,
  onUpdateItems,
  priorTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  organisationId,
}) => {
  const isEnum = Boolean(items.enum);

  const handleTypeChange = (type: SchemaProperty["type"]) => {
    const updatedItems: SchemaProperty = {...items, type};

    if (type === "array") {
      updatedItems.items = updatedItems.items || {type: "string"};
    } else {
      delete updatedItems.items;
    }

    if (type === "object") {
      updatedItems.properties = items.properties || {};
    } else {
      delete updatedItems.properties;
    }

    if (!isPossibleEnumType(type)) {
      delete updatedItems.enum;
    } else if (isEnum) {
      updatedItems.enum = [];
    }

    onUpdateItems(updatedItems);
  };

  const toggleEnum = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedItems = {...items};
    if (event.target.checked && !updatedItems.enum) {
      updatedItems.enum = [];
    } else {
      delete updatedItems.enum;
    }
    onUpdateItems(updatedItems);
  };

  const onEnumUpdate = (enumValues: SchemaProperty["enum"]) => {
    onUpdateItems({...items, enum: enumValues});
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div style={{display: "flex", alignItems: "center", width: "20em"}}>
          <TypeSelector value={items.type} onChange={handleTypeChange} />
          {isPossibleEnumType(items.type) && (
            <FormControlLabel
              control={<Checkbox checked={isEnum} onChange={toggleEnum} />}
              label="Enum"
            />
          )}
        </div>
      </Grid>
      <TypeDetail
        property={items}
        isEnum={isEnum}
        onUpdate={(name, updatedItems) => onUpdateItems(updatedItems)}
        onEnumUpdate={onEnumUpdate}
        priorTasks={priorTasks}
        context={context}
        inputs={inputs}
        prompts={prompts}
        actionDefinitions={actionDefinitions}
        organisationId={organisationId}
      />
    </Grid>
  );
};

export default ItemsEditor;
