import React from "react";

import AppContainerBase from "common_components/app_container_base";

import OverridablePortals from "../overridable_portals";
import LocalStorage from "../local_storage";

function App(props) {
  return (
    <AppContainerBase {...props} appType="web">
      <LocalStorage>
        <OverridablePortals {...props}>
          {self => (
            <div className="app-wrapper">
              <div className="app-top-alert" />
              <div className="app-main">
                <div className="app-sidebar-container">
                  {self.renderOverridablePortal("sidebar")}
                </div>
                <div className="app-middle">
                  <div className="app-toolbar-container" />
                  <div className="app-content">
                    {props.main}
                    {props.realtimeData}
                    {props.errors}
                  </div>
                </div>
              </div>
            </div>
          )}
        </OverridablePortals>
      </LocalStorage>
    </AppContainerBase>
  );
}

export default App;
