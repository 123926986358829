import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import moment from "moment";

const useStyles = makeStyles({
  tbl: {
    margin: "0 1em 1em",
    borderCollapse: "collapse",
    fontSize: ".9em",
  },
  row: {
    borderBottom: "1px solid rgb(224, 224, 224)",
  },
  cell: {
    padding: "6px",
    verticalAlign: "top",
  },
});

export default function ReportsTable({logs}) {
  const {tbl, row, cell} = useStyles();
  return (
    <table className={tbl}>
      <thead>
        <tr>
          <th className={cell} style={{width: "10rem"}}>
            Time
          </th>
          <th className={cell}>Text</th>
        </tr>
      </thead>
      <tbody>
        {logs.map(({id, time, text}, index) => (
          <tr key={id || index} className={row}>
            <td className={cell}>
              <span>{moment(time).format("DD/MM/YYYY - h:mm A")}</span>
            </td>
            <td className={cell}>
              <div style={{wordBreak: "break-all"}}>{text}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
