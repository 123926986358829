import React from "react";
import _ from "underscore";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import Permissioner from "utils/permissioner";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";

import requestor from "requestor";

import TopicDetailComponent from "../components/topic_detail";

import updateTopicAction from "modules/documents/actions/topic_update";
import deleteTopicAction from "modules/documents/actions/topic_delete";
import topicparametersByTopicIdFetchAction from "modules/documents/actions/topic_parameters_by_topic_id_fetch";
import topicUsesByTopicIdFetchAction from "modules/documents/actions/topic_uses_by_topic_id_fetch";
import topicUsesSetEmptyAction from "modules/documents/actions/topic_uses_set_empty";
import issuesFetchAction from "modules/documents/actions/issues_info_fetch";
import documentClauseTopicAdd from "modules/documents/actions/document_clause_topic_add";
import documentClauseTopicRemove from "modules/documents/actions/document_clause_topic_remove";
import documentsClausepartsTopicsUpdate from "modules/documents/actions/documents_clauseparts_topics_update";
import documentClauseUnconfirmedTopicRemove from "modules/documents/actions/document_clause_unconfirmed_topics_remove";
import documentClauseTopicConfirm from "modules/documents/actions/document_clause_topic_confirm";
import documentClauseTopicsReorder from "modules/documents/actions/document_clause_topics_reorder";
import topicParameterStatsFetch from "modules/documents/actions/topic_parameter_stats_fetch";
import topicParameterAdd from "modules/documents/actions/topicparameter_add";
import topicParameterUpdate from "modules/documents/actions/topicparameter_update";
import topicParameterDelete from "modules/documents/actions/topicparameter_delete";
import topicParameterValuesUpdate from "modules/documents/actions/topicparameter_actual_values_update";
import topicParametersProcessAction from "modules/documents/actions/topicparameters_process";
import topicFetchAction from "modules/documents/actions/topic_fetch";
import logsFetchAction from "modules/logs/actions/logs_fetch";
import topicparameterActualValuesInClausesUpdateAction from "modules/documents/actions/topicparameter_actual_values_in_clauses_update";
import logPageLoadTime from "modules/logs/actions/page_load_time_log";
import documentsClausepartUpdate from "modules/documents/actions/document_clausepart_update";

import byId from "common/utils/by_id";
import UpdatingComponent from "utils/updating_component";
import getHotProjectsParams from "utils/get_hot_projects_params";

import {getTopicUsagesFetchLimit} from "common_components/usages_fetch_limit_input";

function getSelectedContractTypes(organisationId) {
  const usagesFilterRaw = localStorage.getItem(
    `filteredContractTypeIds_${organisationId}`,
  );
  const usagesFilter = usagesFilterRaw ? JSON.parse(usagesFilterRaw) : [];
  return usagesFilter;
}
class TopicDetailContainer extends UpdatingComponent {
  constructor(props) {
    super(props);
    this.updateTypes = ["update_topic", "update_parameter"];
  }

  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return (
      this.permissioner.isAdmin() ||
      this.permissioner.hasPermission("view-topic-detail")
    );
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.topic,
      this.props.topics,
      this.props.topicCategories,
      this.props.topicTags,
      this.props.contractTypes,
      this.props.topicUpdates,
      this.props.templateModules,
      this.props.user,
      this.props.projects,
      this.props.roles,
      this.props.classifiers,
    ]);
  }

  renderComponent() {
    setTitle(`Topic ${this.props.topic.name}`);
    const topicsById = byId(this.props.topics);
    const categoriesById = byId(this.props.topicCategories);
    const contractTypesById = byId(this.props.contractTypes);
    const issuesById = byId(this.props.issues);
    return (
      <TopicDetailComponent
        {...this.props}
        topicCategoriesById={categoriesById}
        organisationId={this.props.params.organisationId}
        onTopicUpdated={this.onTopicUpdated}
        onTopicDeleted={this.deleteTopic}
        onUsageRemoved={this.removeUsage}
        onUsageConfirmed={this.confirmUsage}
        onExistingTopicAdded={this.existingTopicAdded}
        onNewTopicAdded={this.newTopicAdded}
        clausepartsTopicsUpdated={this.clausepartsTopicsUpdated}
        onTopicRemoved={this.topicRemoved}
        onUnconfirmedTopicsRemoved={this.unconfirmedTopicsRemoved}
        onTopicConfirmed={this.topicConfirmed}
        onTopicsReordered={this.topicsReordered}
        onTopicParameterAdded={this.topicParameterAdded}
        onTopicParameterUpdated={this.topicParameterUpdated}
        onTopicParameterDeleted={this.topicParameterDeleted}
        onTopicparameterValuesUpdate={this.onTopicparameterValuesUpdate}
        topicsById={topicsById}
        contractTypesById={contractTypesById}
        issuesById={issuesById}
        getTopicData={this.getTopicData}
        getIssues={this.getIssues}
        getTopicUsages={this.getTopicUsages}
        getTopicParameterStats={this.getTopicParameterStats}
        addTopicparameterIdToQueryString={this.addTopicparameterIdToQueryString}
        processTopicParameters={this.processTopicParameters}
        fetchTopicAnalysisLogs={this.fetchTopicAnalysisLogs}
        confirmTopicparameterInClauses={this.confirmTopicparameterInClauses}
        updateTopicparameterActualValuesInClauses={
          this.updateTopicparameterActualValuesInClauses
        }
        updateClausepartIsBadlyParsed={this.updateClausepartIsBadlyParsed}
      />
    );
  }

  updateClausepartIsBadlyParsed = (
    projectId,
    documentId,
    clauseId,
    id,
    isBadlyParsed,
  ) => {
    const {organisationId} = this.props.params;
    this.props.documentsClausepartUpdate(
      organisationId,
      projectId,
      documentId,
      clauseId,
      id,
      isBadlyParsed,
    );
  };

  getTopicData = async callParams => {
    const {organisationId, topicId} = this.props.params;
    const result = {};
    const callResult = await this.fetchTopicUsesByTopicId(
      organisationId,
      topicId,
      callParams,
    );
    result.usages = callResult && callResult.value && callResult.value.usages;

    const parameters = await this.props.getTopicparametersByTopicId(
      organisationId,
      topicId,
      getHotProjectsParams(),
    );
    result.parameters = parameters.value;
    return result;
  };

  getUpdatingProp(type, data) {
    let prop = null;
    if (type === "update_topic") {
      prop = this.props.topic;
    }
    if (type === "update_parameter") {
      prop = this.getParameter(data.topicParameterId);
    }
    return prop;
  }

  updateHandler(data, type) {
    const {organisationId, topicId} = this.props.params;
    const {topic} = this.props;

    const topicIdInt = parseInt(topicId, 10);

    if (type === "update_topic") {
      this.props.updateTopicAction(organisationId, topicIdInt, {
        last_edited: topic.last_edited,
        ...data,
      });
    }
    if (type === "update_parameter") {
      return this.props.topicParameterUpdate(
        organisationId,
        topicIdInt,
        data.topicParameterId,
        {
          ...data.data,
          last_edited: this.getParameter(data.topicParameterId).last_edited,
        },
      );
    }
  }

  getParameter(topicParameterId) {
    return _.find(
      this.props.topic.parameters,
      param => param.id === topicParameterId,
    );
  }

  getTopicUsages = async params => {
    const {organisationId, topicId} = this.props.params;

    const callParams = {
      ...(params || {}),
    };

    const result = await this.fetchTopicUsesByTopicId(
      organisationId,
      topicId,
      callParams,
    );
    if (
      this.props.topic.fetchTopicUsesTime &&
      this.props.topic.fetchTopicUsesTime > result.value.fetchTopicUsesTime
    ) {
      return null;
    }
    return result.value.usages;
  };

  fetchTopicUsesByTopicId = (organisationId, topicId, callParams) => {
    const params = {
      ...(callParams || {}),
      ...getHotProjectsParams(),
      usages_fetch_limit: getTopicUsagesFetchLimit(topicId),
    };
    const storageContractTypes = localStorage.getItem(
      `filteredContractTypeIds_${organisationId}`,
    );

    if (storageContractTypes) {
      params.contract_types = JSON.parse(storageContractTypes);
    }
    return this.props.getTopicUsesByTopicId(organisationId, topicId, params);
  };

  getTopicParameterStats = topicParameterId => {
    const parameters = topicParameterId
      ? {
          ...getHotProjectsParams(),
          topicparameter_id: topicParameterId,
        }
      : getHotProjectsParams();
    const {organisationId, topicId} = this.props.params;
    this.props.getTopicParameterStats(organisationId, topicId, parameters);
  };

  getIssues = () => {
    const {organisationId} = this.props.params;
    this.props.getIssues(organisationId);
  };

  updateTopic = data => {
    this.performUpdate(data, "update_topic");
  };

  deleteTopic = () => {
    const {organisationId, topicId} = this.props.params;
    this.props.deleteTopicAction(
      organisationId,
      topicId,
      this.props.topic.last_edited,
    );
  };

  removeUsage = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
  ) => {
    const {organisationId, topicId} = this.props.params;
    this.props.removeUsageAction(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clausepartLastEdited,
      parseInt(topicId, 10),
    );
  };

  confirmUsage = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
  ) => {
    const {organisationId, topicId} = this.props.params;
    this.props.documentClauseTopicConfirm(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clausepartLastEdited,
      parseInt(topicId, 10),
    );
  };

  clausepartsTopicsUpdated = data => {
    if (data) {
      const {organisationId} = this.props.params;
      this.props.documentsClausepartsTopicsUpdate(organisationId, data);
    }
  };

  existingTopicAdded = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicId,
  ) => {
    const {organisationId} = this.props.params;
    return this.props.documentClauseTopicAdd(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      {id: topicId},
    );
  };

  onTopicUpdated = data => {
    const {organisationId, topicId} = this.props.params;
    const topicIdInt = parseInt(topicId, 10);

    this.props.updateTopicAction(organisationId, topicIdInt, {
      ...data,
      last_edited: this.props.topic.last_edited,
    });
  };

  newTopicAdded = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicData,
  ) => {
    const {organisationId} = this.props.params;
    return this.props.documentClauseTopicAdd(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topicData,
    );
  };

  topicRemoved = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicId,
  ) => {
    const {organisationId} = this.props.params;
    return this.props.documentClauseTopicRemove(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topicId,
    );
  };

  unconfirmedTopicsRemoved = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
  ) => {
    const {organisationId} = this.props.params;
    this.props.documentClauseUnconfirmedTopicRemove(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
    );
  };

  topicConfirmed = async (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicId,
  ) => {
    const {organisationId} = this.props.params;
    await this.props.documentClauseTopicConfirm(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topicId,
    );
  };

  topicsReordered = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topics,
  ) => {
    const {organisationId} = this.props.params;
    this.props.documentClauseTopicsReorder(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topics,
    );
  };

  topicParameterAdded = (topicParameterName, type, values, description) => {
    const {organisationId} = this.props.params;
    const {id: topicId, last_edited: topicLastEdited} = this.props.topic;
    const {username} = this.props.user;
    return this.props.topicParameterAdd(
      organisationId,
      topicId,
      topicLastEdited,
      topicParameterName,
      type,
      values,
      username,
      description,
    );
  };

  topicParameterUpdated = (topicParameterId, data) => {
    const {username} = this.props.user;
    return this.performUpdate(
      {
        topicParameterId,
        data: {
          ...data,
          username,
          ...getHotProjectsParams(),
        },
      },
      "update_parameter",
    );
  };

  topicParameterDeleted = (topicParameterId, lastEdited) => {
    const {organisationId} = this.props.params;
    const {id: topicId} = this.props.topic;
    return this.props.topicParameterDelete(
      organisationId,
      topicId,
      topicParameterId,
      lastEdited,
    );
  };

  onTopicparameterValuesUpdate = (
    projectId,
    documentId,
    clauseId,
    clausepartId,
    topicId,
    data,
  ) => {
    const {organisationId} = this.props.params;
    return this.props.onTopicparameterValuesUpdate(
      organisationId,
      projectId,
      documentId,
      clauseId,
      clausepartId,
      topicId,
      data,
    );
  };

  processTopicParameters = data => {
    const {organisationId} = this.props.params;
    const {id: topicId} = this.props.topic;
    this.props.topicParametersProcess(organisationId, topicId, {
      ...data,
      ...getHotProjectsParams(),
    });
  };

  addTopicparameterIdToQueryString = topicparameterId => {
    const {organisationId, topicId} = this.props.params;
    const tpQuery = topicparameterId
      ? `?topicparameter_id=${topicparameterId}`
      : "";
    this.props.dispatch(
      push(`/organisation/${organisationId}/topic/${topicId}/detail${tpQuery}`),
    );
  };

  fetchTopicAnalysisLogs = () => {
    const {organisationId, topicId} = this.props.params;
    this.props.fetchLogs(organisationId, `topic_id=${topicId}&&analysis=true`);
  };

  fetchTopic() {
    const {organisationId, topicId} = this.props.params;
    const params = {
      usages_fetch_limit: 0,
      omit_param_stats: true,
      ...getHotProjectsParams(),
      contract_types: JSON.stringify(getSelectedContractTypes(organisationId)),
    };
    this.initialFetchParams = params;
    this.props.topicFetch(organisationId, topicId, params);
  }

  updateTopicparameterActualValuesInClauses = (
    topicparameterId,
    clausepartIds,
    updateType,
  ) => {
    const {organisationId, topicId} = this.props.params;
    return this.props.updateTopicparameterActualValuesInClauses(
      organisationId,
      topicId,
      topicparameterId,
      {
        clausepart_ids: clausepartIds,
        update_type: updateType,
      },
    );
  };
}

function select(state, props) {
  return {
    topics: state.topics,
    projects: state.projects,
    roles: state.roles,
    classifiers: state.classifiers,
    params: _.mapObject(props.params, key => parseInt(key, 10)),
    topic: state.topic,
    topicCategories: state.topicCategories,
    topicTags: state.topicTags,
    contractTypes: state.contract_types,
    topicUpdates: state.topicUpdates,
    user: state.user,
    issues: state.issues === UNINITIALISED ? [] : state.issues,
    logs: state.logs === UNINITIALISED ? null : state.logs,
    templateModules: state.templateModules,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateTopicAction: updateTopicAction(requestor),
        deleteTopicAction: deleteTopicAction(requestor),
        removeUsageAction: documentClauseTopicRemove(requestor),
        documentClauseTopicAdd: documentClauseTopicAdd(requestor),
        documentClauseTopicRemove: documentClauseTopicRemove(requestor),
        documentsClausepartsTopicsUpdate: documentsClausepartsTopicsUpdate(
          requestor,
        ),
        documentClauseUnconfirmedTopicRemove: documentClauseUnconfirmedTopicRemove(
          requestor,
        ),
        documentsClausepartUpdate: documentsClausepartUpdate(requestor),
        documentClauseTopicConfirm: documentClauseTopicConfirm(requestor),
        documentClauseTopicsReorder: documentClauseTopicsReorder(requestor),
        topicParameterAdd: topicParameterAdd(requestor),
        topicParameterUpdate: topicParameterUpdate(requestor),
        topicParameterDelete: topicParameterDelete(requestor),
        topicFetch: topicFetchAction(requestor),
        onTopicparameterValuesUpdate: topicParameterValuesUpdate(requestor),
        getTopicparametersByTopicId: topicparametersByTopicIdFetchAction(
          requestor,
        ),
        getTopicUsesByTopicId: topicUsesByTopicIdFetchAction(requestor),
        setEmptyTopicUses: topicUsesSetEmptyAction,
        getTopicParameterStats: topicParameterStatsFetch(requestor),
        getIssues: issuesFetchAction(requestor),
        topicParametersProcess: topicParametersProcessAction(requestor),
        fetchLogs: logsFetchAction(requestor),
        logPageLoadTime: logPageLoadTime(requestor),
        updateTopicparameterActualValuesInClauses: topicparameterActualValuesInClausesUpdateAction(
          requestor,
        ),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(TopicDetailContainer);
export const Component = TopicDetailContainer;
