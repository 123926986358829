import React from "react";

import LockOpenIcon from "@material-ui/icons/LockOpen";
import ReplayIcon from "@material-ui/icons/Replay";
import HotAndStarInfoWidget from "common_components/hot_and_star_info_widget";

const styles = {
  baseCell: {
    textAlign: "center",
    whiteSpace: "pre-wrap",
  },
  devControlIcon: {
    height: 18,
    width: 18,
    cursor: "pointer",
  },
  devControlDivider: {
    borderLeft: "1px solid gray",
    paddingLeft: "5px",
    marginLeft: "8px",
  },
};

export default class TopicParameter extends React.Component {
  state = {isHovered: false};
  render() {
    const {parameter, isRequestPending, condensedView} = this.props;
    const {isHovered} = this.state;
    return (
      <tr
        onMouseEnter={condensedView ? undefined : this.onHoverStart}
        onMouseLeave={condensedView ? undefined : this.onHoverFinish}
        style={{
          background: isHovered ? "#E0E0E0" : "inherit",
          cursor: "pointer",
          borderBottom: "1px solid #eeeeee",
        }}
        onClick={this.onEditTopicParameter}
      >
        {!condensedView && (
          <>
            <td>
              <HotAndStarInfoWidget
                label="Topicparameter"
                isHot={parameter.is_hot}
                isStar={parameter.is_star}
                ignoreNonStarIcon={true}
              />
            </td>
            <td style={styles.baseCell} className="id">
              {parameter.id}
            </td>
            <td style={styles.baseCell} className="name">
              {parameter.name}
            </td>
          </>
        )}
        <td style={styles.baseCell} className="type">
          {parameter.parameter_type}
        </td>
        <td style={styles.baseCell} className="related-issues-count">
          {isRequestPending ? "-" : parameter.related_issues_count}
        </td>
        <td style={styles.baseCell} className="related-issues-count">
          {styleIfNewlineInText(parameter.isInDevMode ? "Dev\nLive" : "Live")}
        </td>
        <td style={styles.baseCell} className="uses">
          {isRequestPending ? "-" : styleIfNewlineInText(parameter.uses)}
        </td>
        <td style={styles.baseCell}>
          {isRequestPending || !parameter.stats
            ? "-"
            : styleIfNewlineInText(parameter.stats.unconfirmed)}
        </td>
        <td style={styles.baseCell}>
          {isRequestPending || !parameter.stats
            ? "-"
            : styleIfNewlineInText(parameter.stats.tp)}
        </td>
        <td style={styles.baseCell}>
          {isRequestPending || !parameter.stats
            ? "-"
            : styleIfNewlineInText(parameter.stats.fp)}
        </td>
        <td style={styles.baseCell}>
          {isRequestPending || !parameter.stats
            ? "-"
            : styleIfNewlineInText(parameter.stats.fn)}
        </td>
        <td style={styles.baseCell}>
          {isRequestPending || !parameter.stats
            ? "-"
            : styleIfNewlineInText(parameter.stats.incorrect)}
        </td>
        <td style={styles.baseCell}>
          {parameter.partially_processed || parameter.issues_paused
            ? "X"
            : null}
        </td>
        <td style={styles.baseCell}>
          {parameter.isInDevMode ? this.renderDevModeControl() : null}
        </td>
        <td style={styles.baseCell}>{this.props.jobStats}</td>
      </tr>
    );
  }

  renderDevModeControl = () => (
    <div style={{display: "flex", justifyContent: "center"}}>
      <LockOpenIcon
        style={styles.devControlIcon}
        onClick={this.onLockTopicparameter}
      />
      <div style={styles.devControlDivider} />
      <ReplayIcon
        style={styles.devControlIcon}
        onClick={this.onRevertTopicparameter}
      />
    </div>
  );

  onHoverStart = () => this.setState(() => ({isHovered: true}));
  onHoverFinish = () => this.setState(() => ({isHovered: false}));

  onEditTopicParameter = () =>
    this.props.onShowParameterEditor(this.props.parameter.id);

  onLockTopicparameter = e => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onLock(this.props.parameter.id);
  };

  onRevertTopicparameter = e => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onRevert(this.props.parameter.id);
  };
}

function styleIfNewlineInText(_text) {
  if (!_text) {
    return _text;
  }
  const text = typeof _text === "number" ? _text.toString() : _text;
  const textArr = text.split("\n");
  if (textArr.length < 2) {
    return text;
  }
  return (
    <>
      <div style={{color: "#bdbdbd"}}>{textArr[0]}</div>
      <div>{textArr[1]}</div>
    </>
  );
}
