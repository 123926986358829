import BaseRoute from "utils/base_route";

import DefinitionGroupDetailContainer from "./containers/definition_group_detail";

export default class DefinitionGroupDetail extends BaseRoute {
  constructor(store) {
    super(":definitionGroupId/detail", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, DefinitionGroupDetailContainer);
  }
}
