import basePath from "base_path";
import ActionTypes from "../../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

import rateLimit, {getStandardTimeout} from "utils/rate_limit";
import {createChangeTimeWrapper} from "utils/ensure_fresh_update";

function doSave(
  requestor,
  organisationId,
  definitionGroupId,
  data,
  changeTime,
) {
  return requestor
    .patch(
      `${basePath}/organisation/${organisationId}/definition_group/${definitionGroupId}`,
      data,
      {withCredentials: true},
    )
    .then(response => ({
      ...createChangeTimeWrapper(changeTime),
      ...data,
      ...response.data,
    }));
}

const save = rateLimit(doSave, getStandardTimeout());

export default function updateDefinitionGroup(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.DEFINITION_GROUP_UPDATE,
    (organisationId, definitionGroupId, data, changeTime) => {
      if (!data.last_edited) {
        throw new Error("data must contain a last_edited key");
      }
      return save(
        requestor,
        organisationId,
        definitionGroupId,
        data,
        changeTime,
      );
    },
    (organisationId, definitionGroupId, data, changeTime) => ({
      ...createChangeTimeWrapper(changeTime),
      ...data,
    }),
  );

  return action;
}
