import requestor from "requestor";

import ChildRenderer from "common_components/child_renderer";

import BaseRoute from "utils/base_route";

import topicsFetchAction from "modules/documents/actions/topics_fetch";
import topicCategoriesFetchAction from "modules/documents/actions/topic_categories_fetch";
import topicTagsFetchAction from "modules/documents/actions/topic_tags_fetch";
import topicsClearAction from "modules/documents/actions/topics_clear";
import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";
import contractTypesClearAction from "modules/documents/actions/contract_types_clear";

import TopicList from "routes/topic_list";
import TopicDetail from "routes/topic_detail";
import TopicAnalysisMatrix from "routes/topic_analysis_matrix";
import TopicAnalysis from "routes/topic_analysis";
import TopicParameters from "routes/topic_parameters";

export default class TopicsRoute extends BaseRoute {
  constructor(store) {
    super("topic", store);
  }

  createChildRoutes() {
    return [
      new TopicList(this.store),
      new TopicDetail(this.store),
      new TopicAnalysisMatrix(this.store),
      new TopicAnalysis(this.store),
      new TopicParameters(this.store),
    ];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchTopicCategories(state);
    this.fetchTopicTags(state);
    this.fetchContractTypes(state);
  }

  fetchTopics(state) {
    const {organisationId} = state.params;
    this.store.dispatch(topicsFetchAction(requestor)(organisationId));
  }

  fetchTopicCategories(state) {
    const {organisationId} = state.params;
    this.store.dispatch(topicCategoriesFetchAction(requestor)(organisationId));
  }

  fetchTopicTags(state) {
    const {organisationId} = state.params;
    this.store.dispatch(topicTagsFetchAction(requestor)(organisationId));
  }

  fetchContractTypes(state) {
    const {organisationId} = state.params;
    this.store.dispatch(contractTypesFetchAction(requestor)(organisationId));
  }

  clearActiveData(state) {
    const {organisationId} = state.params;
    this.store.dispatch(topicsClearAction(organisationId));
    this.store.dispatch(contractTypesClearAction(organisationId));
  }
}
