import React, {useEffect, useRef, useState} from "react";
import _ from "lodash";
import {Link} from "react-router";
import moment from "moment";

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ExpandLessIcon from "@material-ui/icons/KeyboardArrowDown";
import ExpandMoreIcon from "@material-ui/icons/KeyboardArrowRight";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {Table, Td, Th, Tr} from "common_components/table";
import addDefinitionsAndHeadings from "common/utils/add_definitions_and_headings";
import HEADINGS_SEPARATOR from "common/headings_separator";

const styles = {
  circularProgress: {
    position: "absolute",
    top: "45%",
    left: "50%",
  },
  cellContainerStyle: {justifyContent: "normal"},
  list: {margin: 0},
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function TopicLogs(props) {
  useEffect(() => {
    props.fetchLogs();
  }, []);

  const [shownLogsState, onShownLogsStateUpdate] = useState({});

  function triggerShownItem(id) {
    const newValues = {
      ...shownLogsState,
      [id]: !shownLogsState[id],
    };
    onShownLogsStateUpdate(newValues);
  }

  const [areLogsGrouped, onAreLogsGroupedUpdate] = useState(true);
  function triggerGroupLogs() {
    onAreLogsGroupedUpdate(!areLogsGrouped);
  }

  const [showLastMonthLogs, onShowLastMonthLogUpdate] = useState(true);
  function triggerShowLastMonthLogs() {
    onShowLastMonthLogUpdate(!showLastMonthLogs);
  }

  const {
    logClausepartIds,
    groupedLogsByClausepartId,
    otherLogs,
  } = getGroupLogsData(props.logs, showLastMonthLogs);
  const [clausepartIdsOrdered, onClausepartIdsOrderedUpdate] = useState([]);
  const prevLogs = usePrevious(props.logs);
  const prevShowLastMonthLogs = usePrevious(showLastMonthLogs);

  useEffect(
    () => {
      if (
        (!prevLogs && props.logs) ||
        showLastMonthLogs !== prevShowLastMonthLogs
      ) {
        onClausepartIdsOrderedUpdate(logClausepartIds);
      }
    },
    [props.logs, showLastMonthLogs],
  );

  const shownLogs = getShownLogs(
    props.logs,
    clausepartIdsOrdered,
    groupedLogsByClausepartId,
    shownLogsState,
    areLogsGrouped,
    showLastMonthLogs,
    otherLogs,
  );
  return (
    <div>
      {!props.logs ? (
        <CircularProgress style={styles.circularProgress} />
      ) : (
        <div>
          <div style={{position: "absolute", zIndex: 9999, right: "10px"}}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={areLogsGrouped}
                  onChange={triggerGroupLogs}
                />
              }
              label="Group Logs"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={showLastMonthLogs}
                  onChange={triggerShowLastMonthLogs}
                />
              }
              label="Recent changes only"
            />
          </div>
          <Table
            isInitiallySorted={false}
            hasStickyHeader={true}
            withoutSidePadding={false}
            isSortable={!areLogsGrouped}
          >
            <thead>
              <tr>
                <Th style={{width: "2%"}} />
                <Th style={{width: "10%"}}>Change Type</Th>
                <Th style={{width: "10%"}}>User</Th>
                <Th style={{width: "10%"}}>Date</Th>
                <Th style={{width: "15%"}}>Data</Th>
                <Th style={{width: "50%"}}>Clause text/Updates</Th>
                <Th style={{width: "3%"}} />
              </tr>
            </thead>
            <tbody>
              {shownLogs.map((log, logIndex) => (
                <Tr
                  key={`${log ? log.id : "log"}_${logIndex}`}
                  style={{opacity: log.isParent || !areLogsGrouped ? 1 : 0.4}}
                >
                  <Td>
                    {log.isParent &&
                      log.children.length > 0 && (
                        <ExpandButton
                          clausepartId={log.clausepart_id}
                          isOpen={shownLogsState[log.clausepart_id]}
                          onOpen={triggerShownItem}
                        />
                      )}
                  </Td>
                  <Td containerStyle={styles.cellContainerStyle}>{log.type}</Td>
                  <Td containerStyle={styles.cellContainerStyle}>
                    {log.username}
                  </Td>
                  <Td
                    containerStyle={styles.cellContainerStyle}
                    sortText={moment(log.time).format("x")}
                  >
                    {moment(log.time).format("DD/MM/YYYY - h:mm A")}
                  </Td>
                  <Td containerStyle={styles.cellContainerStyle}>
                    {renderLogData(log, props.organisationId)}
                  </Td>
                  <Td containerStyle={styles.cellContainerStyle}>
                    {log.type.startsWith("TOPICPARAMETER") &&
                    log.type !== "TOPICPARAMETER_CLASSIFY"
                      ? renderTopicparameterUpdates(log)
                      : renderClausepartText(log)}
                  </Td>
                  <Td>
                    <ActionButton
                      log={log}
                      topicId={props.topic.id}
                      onTopicAdd={props.onTopicAdd}
                      onTopicRemove={props.onTopicRemove}
                    />
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}

function renderLogData(log, organisationId) {
  if (log.type === "TOPICPARAMETER_CLASSIFY") {
    const mode = log.updates && log.updates.mode;
    return (
      <>
        {mode !== "all" && (
          <div>{`topicparameter_id: ${log.updates &&
            log.updates.topicparameter_id}`}</div>
        )}
        {mode && <div>{`Mode: ${log.updates && log.updates.mode}`}</div>}
      </>
    );
  }
  const {
    project_id: projectId,
    document_id: documentId,
    document_name: documentName,
  } = log;
  const pathname =
    `/organisation/${organisationId}/project/${projectId}` +
    `/document/${documentId}/detail`;

  return (
    <React.Fragment>
      <div>
        <span>Project: </span>
        <span>{log.project_name}</span>
      </div>
      <div>
        <span>Document: </span>
        <Link className="document-link" to={{pathname}} onlyActiveOnIndex>
          <span className="document-name" style={{wordBreak: "break-all"}}>
            {documentName || "Link"}
          </span>
        </Link>
      </div>
      <div>
        <span>Clause: </span>
        <span>{log.clausepart_reference}</span>
      </div>
    </React.Fragment>
  );
}

function renderClausepartText(log) {
  const baseText = addDefinitionsAndHeadings(
    log.clausepart_text,
    log.clausepart_partial_text,
    log.definitions,
    log.headings,
    log.section_id,
    log.clausepart_reference,
  );
  const headingsSeparatorIndex = baseText.lastIndexOf(HEADINGS_SEPARATOR);
  const definitionsSeparatorIndex = baseText.indexOf("||");

  const headingText =
    headingsSeparatorIndex > -1
      ? baseText.slice(0, headingsSeparatorIndex + 2)
      : "";
  const definitionText =
    definitionsSeparatorIndex > -1
      ? baseText.slice(definitionsSeparatorIndex)
      : "";

  return (
    <div>
      <span style={{color: "#ffcc80"}}>{headingText}</span>
      <span>{log.clausepart_text}</span>
      <span style={{color: "#aaf"}}>{definitionText}</span>
    </div>
  );
}

function renderTopicparameterUpdates(log) {
  const updates = Object.keys(log.updates).reduce((result, tpId) => {
    result[tpId] = log.updates[tpId].actual_values;
    return result;
  }, {});

  return (
    <div style={{whiteSpace: "pre"}}>{JSON.stringify(updates, null, 2)}</div>
  );
}

function ActionButton(props) {
  const {log, topicId, onTopicAdd, onTopicRemove} = props;
  if (!log.isParent) {
    return null;
  }

  const args = [
    log.project_id,
    log.document_id,
    log.section_id,
    log.clause_id,
    log.clausepart_id,
    log.clausepart_last_edited,
    topicId,
  ];

  let Icon = AddIcon;
  let handler = () => {
    onTopicAdd(...args);
  };

  if (log.is_topic_in_clause) {
    Icon = RemoveIcon;
    handler = () => {
      onTopicRemove(...args);
    };
  }
  return (
    <IconButton onClick={handler}>
      <Icon />
    </IconButton>
  );
}

function ExpandButton(props) {
  const Icon = props.isOpen ? ExpandLessIcon : ExpandMoreIcon;
  const handler = () => props.onOpen(props.clausepartId);
  return <Icon onClick={handler} style={{cursor: "pointer"}} />;
}

function getDaysFromNow(logTime) {
  const dateTo = moment();
  const dateFrom = moment(logTime);
  return dateTo.diff(dateFrom, "days");
}

function getGroupLogsData(logsRaw, showLastMonthLogs) {
  const logs = logsRaw || [];
  // 1) - stratify data by clausepart_id
  //    - collect initial parentClausepartIds order
  const otherLogs = [];
  const logClausepartIds = [];
  const logsByClausepartId = _.chain(logs)
    .sortBy(["time"])
    .reduce((accum, log) => {
      if (showLastMonthLogs && getDaysFromNow(log.time) > 31) {
        if (!log.clausepart_id) {
          otherLogs.push(log);
          return accum;
        }

        if (accum[log.clausepart_id]) {
          accum[log.clausepart_id].push(log);
        }
        return accum;
      }

      if (!log.clausepart_id) {
        otherLogs.push(log);
        return accum;
      }

      if (!accum[log.clausepart_id]) {
        logClausepartIds.push(log.clausepart_id);
        accum[log.clausepart_id] = [log];
      } else {
        accum[log.clausepart_id].push(log);
      }
      return accum;
    }, {})
    .value();

  // 2) construct parents with children
  const groupedLogsByClausepartId = {};
  logClausepartIds.forEach(clausepartId => {
    const clausepartLogsArray = logsByClausepartId[clausepartId];
    const parentItem = clausepartLogsArray[0];
    parentItem.children =
      clausepartLogsArray.length > 1 ? clausepartLogsArray.slice(1) : [];
    parentItem.isParent = true;
    groupedLogsByClausepartId[clausepartId] = parentItem;
  });
  return {logClausepartIds, groupedLogsByClausepartId, otherLogs};
}

function getShownLogs(
  baseLogs,
  clausepartIds,
  groupedLogsByClausepartId,
  shownLogsState,
  areLogsGrouped,
  showLastMonthLogs,
  otherLogs,
) {
  if (!areLogsGrouped) {
    return showLastMonthLogs
      ? baseLogs.filter(baseLog => getDaysFromNow(baseLog.time) < 32)
      : baseLogs;
  }
  const groupedLogs = clausepartIds.reduce((accum, clausepartId) => {
    const parentLog = groupedLogsByClausepartId[clausepartId];
    if (parentLog) {
      return [
        ...accum,
        parentLog,
        ...(shownLogsState[clausepartId] ? parentLog.children : []),
      ];
    }
    return accum;
  }, []);
  const finalLogs = [...groupedLogs, ...otherLogs];

  const result = _.sortBy(finalLogs, item => item.time);
  result.reverse();
  return result;
}

export default TopicLogs;
