import React from "react";

import Popover from "material-ui/Popover";
import ContentCopy from "material-ui/svg-icons/content/content-copy";

import styles from "./styles";

export default class CopyIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnimated: false,
    };
  }

  render() {
    return (
      <div ref={this.createContainerRef} style={styles.clausepartIconContainer}>
        <ContentCopy
          style={{
            ...styles.clausepartIcon,
            ...styles.clausepartCopyIcon,
            ...(this.state.isAnimated && {color: "#333"}),
          }}
          onClick={this.handleClick}
        />
        <Popover
          open={this.state.isAnimated}
          anchorEl={this.containerRef}
          anchorOrigin={{horizontal: "middle", vertical: "bottom"}}
          targetOrigin={{horizontal: "middle", vertical: "top"}}
          useLayerForClickAway={false}
          style={styles.clausepartCopyIconPopover}
        >
          Copied
        </Popover>
      </div>
    );
  }

  handleClick = () => {
    this.setState(
      () => ({isAnimated: true}),
      () => {
        setTimeout(() => {
          this.setState({isAnimated: false});
        }, 1100);
      },
    );
    this.props.handleClick();
  };

  createContainerRef = node => (this.containerRef = node);
}
