import React from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/PlaylistAdd";
import uuid from "utils/uuid";

import IssuesetItem from "./issueset_item";

const styles = {
  cardHeader: {
    height: "2.5rem",
    lineHeight: "2.4rem",
    paddingLeft: "2rem",
    background: "#fff",
    fontSize: "17px",
    fontWeight: 500,
  },
  addItemButton: {
    margin: "0.2rem",
    marginLeft: "1.5rem",
    marginTop: "1rem",
    width: "90%",
  },
};

function Issuesets({
  issuesets,
  onIssuesetAdd,
  onIssuesetRemove,
  onIssuesetUpdate,
}) {
  return (
    <Paper
      style={{
        margin: "1rem auto",
        width: "60%",
      }}
    >
      <div className="app-toolbar" style={styles.cardHeader}>
        Issue Sets
      </div>
      <div style={{padding: "1rem 2rem"}}>
        {issuesets.map((issueset, index) => (
          <IssuesetItem
            key={uuid()}
            issueset={issueset}
            issuesets={issuesets}
            onIssuesetRemove={onIssuesetRemove}
            onIssuesetUpdate={onIssuesetUpdate}
            index={index}
          />
        ))}
        <Button
          style={styles.addItemButton}
          variant="contained"
          onClick={onIssuesetAdd}
          startIcon={<AddIcon />}
        >
          Add Issueset
        </Button>
      </div>
    </Paper>
  );
}

Issuesets.propTypes = {
  issuesets: PropTypes.array.isRequired,
  onIssuesetAdd: PropTypes.func.isRequired,
  onIssuesetUpdate: PropTypes.func.isRequired,
  onIssuesetRemove: PropTypes.func.isRequired,
};

export default Issuesets;
