import ActionTypes, {SearcherActionTypes} from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";

export type QueryRunAction = {
  type: SearcherActionTypes["SEARCHER_QUERY_CLEAR"]["SUCCESS"];
  payload: void;
};

export default function initDocument() {
  return createReduxPromiseAction(ActionTypes.SEARCHER_QUERY_RUN, () => {});
}
