export default function sharedStart(array) {
  const sortedItems = array.concat().sort();
  if (array.length === 0) {
    return "";
  }
  const first = sortedItems[0] || "";
  const last = sortedItems[sortedItems.length - 1] || "";
  const firstLength = first.length;
  let pos = 0;
  while (pos < firstLength && first.charAt(pos) === last.charAt(pos)) {
    pos += 1;
  }
  return first.substring(0, pos);
}
