import React from "react";
import Select from "react-select";

const styles = {
  wrapper: {
    maxWidth: "40em",
    marginRight: "15px",
  },
  selectWrapper: {
    width: "100%",
  },
};

export default function(props) {
  const saveMainTopic = topic => {
    props.onTopicUpdated({main_negative_topic_id: topic ? topic.id : null});
  };
  const saveTopics = topics => {
    props.onTopicUpdated({negative_topics: topics.map(topic => topic.id)});
  };

  return (
    <div style={styles.wrapper}>
      <div>Main Negative Topic</div>
      <Select
        valueKey="id"
        labelKey="name"
        value={props.topic.main_negative_topic_id}
        options={props.topics}
        multi={false}
        removeSelected={true}
        clearable={true}
        backspaceRemoves={false}
        deleteRemoves={false}
        closeOnSelect={false}
        allowCreate={false}
        onChange={saveMainTopic}
        wrapperStyle={styles.selectWrapper}
        menuContainerStyle={{zIndex: 99}}
      />
      <div>Negative topic selector</div>
      <Select
        valueKey="id"
        labelKey="name"
        value={props.topic.negative_topics}
        options={props.topics}
        multi={true}
        removeSelected={true}
        clearable={false}
        backspaceRemoves={false}
        deleteRemoves={false}
        closeOnSelect={false}
        allowCreate={false}
        onChange={saveTopics}
        wrapperStyle={styles.selectWrapper}
        menuContainerStyle={{zIndex: 99}}
      />
    </div>
  );
}
