import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import RaisedButton from "material-ui/RaisedButton";

import Checkbox from "common_components/checkbox";
import getElementInnerText from "utils/get_element_inner_text";

import {Tr, Th, Td} from "./index";
import styles from "./styles";

export default class Table extends React.Component {
  constructor(props) {
    super(props);
    this.body = null;
    this.state = {};
    if (props.isSelectable) {
      this.state.selectedRows = [];
    }
    if (props.isSortable && props.isInitiallySorted) {
      this.state.sortColumnIndex = this.getInitialSortColumnIndex(props);
      this.state.sortDirection = props.sortDirection;
    }
    if (props.useLoadMoreButtons) {
      this.loadMoreInterval = null;
      this.isLoadMoreVisible = false;
      this.state.loadMoreCounter = 1;
      this.state.loadAll = false;
      this.isLoadMoreCounterResetFrozen = false;
    }
    this.loadMoreButtonListRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.useLoadMoreButtons) {
      this.loadMoreInterval = setInterval(() => {
        const node = this.loadMoreButtonListRef.current;
        if (!node) {
          this.isLoadMoreVisible = false;
        } else {
          const {bottom} = node.getBoundingClientRect();
          const isLoadMoreVisible = bottom <= window.innerHeight;
          if (isLoadMoreVisible && !this.isLoadMoreVisible) {
            this.incrementLoadMoreCounter();
          }
          this.isLoadMoreVisible = isLoadMoreVisible;
        }
      }, 100);
    }
  }

  componentWillUnmount() {
    if (this.props.useLoadMoreButtons) {
      clearInterval(this.loadMoreInterval);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.useLoadMoreButtons &&
      this.props.resetLoadMoreCounterOnChildrenChange &&
      (this.state.loadMoreCounter > 1 || this.state.loadAll)
    ) {
      if (prevProps.children === this.props.children) {
        if (
          this.props.isSelectable &&
          !_.isEqual(
            prevState.selectedRows.sort(),
            this.state.selectedRows.sort(),
          )
        ) {
          this.isLoadMoreCounterResetFrozen = true;
          setTimeout(() => {
            this.isLoadMoreCounterResetFrozen = false;
          }, 0);
        }
      } else if (!this.isLoadMoreCounterResetFrozen) {
        this.setState({loadMoreCounter: 1, loadAll: false});
      }
    }
    if (
      React.isValidElement(this.body) &&
      this.props.isSelectable &&
      this.state.selectedRows.length &&
      _.isEqual(prevState.selectedRows.sort(), this.state.selectedRows.sort())
    ) {
      const hasBeenSorted = () =>
        this.props.isSortable &&
        (prevState.sortColumnIndex !== this.state.sortColumnIndex ||
          prevState.sortDirection !== this.state.sortDirection);
      if (prevProps.children !== this.props.children || hasBeenSorted()) {
        const filteredSelectedRows = _.intersection(
          this.state.selectedRows,
          this.getBodyRowSelectIds(this.body),
        );
        if (
          !_.isEqual(
            filteredSelectedRows.sort(),
            this.state.selectedRows.sort(),
          )
        ) {
          this.selectRows(filteredSelectedRows);
        }
      }
    }
  }

  getInitialSortColumnIndex = propsArg => {
    const props = propsArg || this.props;
    const header = findElement(this.props.children, "thead");
    if (header) {
      const row = findElement(header.props.children, Tr, "tr");
      if (row) {
        const columns = filterElements(row.props.children, Th, "th");
        for (const indexStr in columns) {
          const index = parseInt(indexStr, 10);
          if (index >= props.sortColumnIndex) {
            const column = columns[index];
            if (column.type === Th && column.props.isSortable !== false) {
              return index;
            }
          }
        }
      }
    }
    return null;
  };

  getColumnsNumber = () => {
    const header = findElement(this.props.children, "thead");
    if (header) {
      const row = findElement(header.props.children, Tr, "tr");
      if (row) {
        const columns = filterElements(row.props.children, Th, "th");
        if (this.props.isSelectable) {
          return columns.length + 1;
        }
        return columns.length;
      }
    }
    return 0;
  };

  incrementLoadMoreCounter = () => {
    this.setState({loadMoreCounter: this.state.loadMoreCounter + 1});
  };

  selectRows = selectedRows => {
    this.setState(
      () => ({selectedRows}),
      () => this.props.onSelectedRowsChange(selectedRows),
    );
  };

  getBodyRowsLimitData = rows => {
    const {useLoadMoreButtons: shouldLimit} = this.props;
    const allNumber = _.get(rows, "length", 0);
    let stepNumber = allNumber;
    let shouldRenderLoadMoreButtons = false;
    if (shouldLimit) {
      if (!this.state.loadAll) {
        stepNumber = this.props.loadMoreStep * this.state.loadMoreCounter;
      }
      shouldRenderLoadMoreButtons = allNumber > stepNumber;
    }
    return {shouldLimit, stepNumber, shouldRenderLoadMoreButtons};
  };

  getBodyRowSelectIds = body => {
    return React.Children.toArray(body.props.children)
      .filter(row => _.isNumber(row.props.selectId))
      .map(row => row.props.selectId);
  };

  sort = (columnIndex, dir) => {
    this.setState({
      sortColumnIndex: columnIndex,
      sortDirection: dir,
    });
    this.props.onSort(columnIndex, dir);
  };

  render() {
    const props = _.omit(this.props, [
      ..._.keys(Table.propTypes),
      "children",
      "style",
      "keepGroups",
    ]);
    const style = {
      ...styles.table,
      ...this.props.style,
    };
    this.body = this.renderBody();
    return (
      <table {...props} style={style}>
        {this.renderHeader(this.body)}
        {this.body}
      </table>
    );
  }

  sortRows = rows => {
    const {keepGroups} = this.props;
    const {sortColumnIndex: index, sortDirection: direction} = this.state;
    const getText = element => {
      const firstElement = keepGroups ? element[0] : element;
      const hasMissingCell =
        !firstElement.props.children.length ||
        firstElement.props.children.length < index;
      const cell = hasMissingCell
        ? Array.isArray(_.get(firstElement, "props.children"))
          ? _.get(firstElement, "props.children[0]")
          : _.get(firstElement, "props.children")
        : _.get(firstElement, `props.children[${index}]`);
      let text = "";
      if (React.isValidElement(cell)) {
        if (cell.props.sortText !== null && cell.props.sortText !== undefined) {
          // note: sortText may be empty string (means sort in given order)
          text = cell.props.sortText;
        } else {
          text = getElementInnerText(cell);
        }
        return _.trim(text.toLowerCase());
      }
      return "";
    };
    let newRows = keepGroups
      ? Object.values(_.groupBy(rows, row => _.get(row, "props.groupId")))
      : rows;
    if (this.props.isSortable) {
      newRows.sort((firstEl, secondEl) => {
        let firstText = getText(firstEl);
        let secondText = getText(secondEl);
        if ([firstText, secondText].every(text => /^\d+$/.test(text))) {
          firstText = parseInt(firstText, 10);
          secondText = parseInt(secondText, 10);
        }
        const conditions = [firstText < secondText, firstText > secondText];
        if (direction === "desc") {
          conditions.reverse();
        }
        return conditions[0] ? -1 : conditions[1] ? 1 : 0;
      });
    }
    if (keepGroups) {
      newRows = newRows.reduce((acc, arr) => [...acc, ...arr], []);
    }
    return newRows;
  };

  renderBody = () => {
    const body = findElementOrCreate(this.props.children, "tbody");

    let rows = React.Children.toArray(body.props.children).filter(child =>
      [Tr, "tr"].includes(child.type),
    );

    rows = this.sortRows(rows);

    const {
      shouldLimit,
      stepNumber,
      shouldRenderLoadMoreButtons,
    } = this.getBodyRowsLimitData(rows);

    if (shouldLimit) {
      rows = rows.slice(0, stepNumber);
    }

    rows = rows.map((row, index) => {
      let style = {};
      if (row.type === "tr") {
        style = styles.bodyRow;
      }
      const isLast = index === rows.length - 1;
      style = {
        ...style,
        ...row.props.style,
        ...(isLast && {
          ...styles.lastBodyRow,
          ...this.props.lastBodyRowStyle,
        }),
      };
      const props = {};
      if (row.type === Tr) {
        props.parentType = "tbody";
      }
      let columns = React.Children.toArray(row.props.children).filter(child =>
        [Td, "td"].includes(child.type),
      );
      if (this.props.isSelectable) {
        props.isSelected = _.isNumber(row.props.selectId)
          ? this.state.selectedRows.includes(row.props.selectId)
          : false;
        columns.unshift(
          <Td
            key="data-cell-checkbox"
            style={{padding: 0}}
            handleChildren={(children, thisTd) => {
              if (!thisTd.props.isRowHovered && !props.isSelected) {
                return null;
              }
              return children;
            }}
          >
            {_.isNumber(row.props.selectId) && (
              <Checkbox
                checked={props.isSelected}
                onCheck={() => {
                  const selectedRows = [...this.state.selectedRows];
                  if (props.isSelected) {
                    const index = selectedRows.indexOf(row.props.selectId);
                    selectedRows.splice(index, 1);
                  } else {
                    selectedRows.push(row.props.selectId);
                  }
                  this.selectRows(selectedRows);
                }}
              />
            )}
          </Td>,
        );
      }
      if (this.props.withoutSidePadding) {
        columns = removeSidePadding(columns);
      }
      return React.cloneElement(row, {...props, style}, columns);
    });

    if (shouldRenderLoadMoreButtons) {
      rows.push(
        <tr key="show_more_buttons">
          <td colSpan={this.getColumnsNumber()}>
            <div style={styles.loadMoreButtonListContainer}>
              <div
                ref={this.loadMoreButtonListRef}
                style={styles.loadMoreButtonList}
              >
                <RaisedButton
                  label={this.props.loadMoreButtonText}
                  style={styles.loadMoreButton}
                  primary={true}
                  onClick={() => this.incrementLoadMoreCounter()}
                />
                <RaisedButton
                  label={this.props.loadAllButtonText}
                  style={styles.loadMoreButton}
                  primary={true}
                  onClick={() => this.setState({loadAll: true})}
                />
              </div>
            </div>
          </td>
        </tr>,
      );
    }

    return React.cloneElement(body, null, rows);
  };

  renderHeader = body => {
    const header = findElementOrCreate(this.props.children, "thead");

    const rows = React.Children.toArray(header.props.children)
      .filter(child => [Tr, "tr"].includes(child.type))
      .map((row, rowIndex) => {
        let columns = React.Children.toArray(row.props.children)
          .filter(child => [Th, "th"].includes(child.type))
          .map((column, index) => {
            let props = {
              isSticky: this.props.hasStickyHeader,
              style: {
                ...this.props.headerCellStyle,
                ...column.props.style,
              },
            };
            if (
              this.props.isSortable &&
              column.type === Th &&
              column.props.isSortable !== false
            ) {
              const {sortDirection: propsDir} = this.props;
              const {
                sortDirection: stateDir,
                sortColumnIndex: stateIndex,
              } = this.state;
              const isSorted = _.isNumber(stateIndex) && index === stateIndex;
              props = {
                ...props,
                isSortable: true,
                isSorted,
                sortDirection: stateDir,
                onSort: () => {
                  if (!isSorted) {
                    this.sort(index, propsDir);
                  } else if (stateDir === propsDir) {
                    this.sort(stateIndex, stateDir === "asc" ? "desc" : "asc");
                  } else {
                    this.sort(this.getInitialSortColumnIndex(), propsDir);
                  }
                },
              };
            }
            return React.cloneElement(column, props, column.props.children);
          });
        let props = null;
        if (row.type === Tr) {
          props = {parentType: "thead"};
        }
        if (this.props.isSelectable && rowIndex === 0) {
          const bodyRowSelectIds = this.getBodyRowSelectIds(body);
          const areAllSelected = _.isEqual(
            this.state.selectedRows.sort(),
            bodyRowSelectIds.sort(),
          );
          const isPartiallySelected =
            !areAllSelected && Boolean(this.state.selectedRows.length);
          columns.unshift(
            <Th
              key="header-cell-checkbox"
              isSortable={false}
              isSticky={this.props.hasStickyHeader}
              style={{
                width: 33,
                paddingRight: 0,
                paddingLeft: 0,
                ...this.props.headerCellStyle,
              }}
            >
              <Checkbox
                checked={areAllSelected}
                partiallyChecked={isPartiallySelected}
                onCheck={() => {
                  let selectedRows;
                  if (isPartiallySelected || !this.state.selectedRows.length) {
                    selectedRows = _.uniq([
                      ...this.state.selectedRows,
                      ...bodyRowSelectIds,
                    ]);
                  } else {
                    selectedRows = _.difference(
                      this.state.selectedRows,
                      bodyRowSelectIds,
                    );
                  }
                  this.selectRows(selectedRows);
                }}
              />
            </Th>,
          );
        }
        if (this.props.withoutSidePadding) {
          columns = removeSidePadding(columns);
        }
        return React.cloneElement(row, props, columns);
      });

    return React.cloneElement(header, null, rows);
  };
}

function findElementOrCreate(children, type) {
  return findElement(children, type) || React.createElement(type);
}

function findElement(children, ...types) {
  return React.Children.toArray(children).find(child =>
    types.includes(child.type),
  );
}

function filterElements(children, ...types) {
  return React.Children.toArray(children).filter(child =>
    types.includes(child.type),
  );
}

function removeSidePadding(columns) {
  return columns.map((column, index) => {
    const columnProps = {
      ...column.props,
    };
    const isFirst = index === 0;
    const isLast = index === columns.length - 1;
    if (isFirst || isLast) {
      if (!_.has(columnProps, "containerStyle")) {
        columnProps.containerStyle = {};
      }
      if (isFirst) {
        columnProps.containerStyle.paddingLeft = 0;
      }
      if (isLast) {
        columnProps.containerStyle.paddingRight = 0;
      }
    }
    return React.cloneElement(column, columnProps, column.props.children);
  });
}

Table.defaultProps = {
  isSelectable: false,
  onSelectedRowsChange: () => {},
  onSort: () => {},
  isSortable: true,
  isInitiallySorted: true,
  sortColumnIndex: 0,
  sortDirection: "asc",
  lastBodyRowStyle: {},
  headerCellStyle: {},
  useLoadMoreButtons: false,
  resetLoadMoreCounterOnChildrenChange: true,
  loadMoreStep: 20,
  loadMoreButtonText: "Load more",
  loadAllButtonText: "Load all",
  hasStickyHeader: false,
  withoutSidePadding: true,
};

Table.propTypes = {
  isSelectable: PropTypes.bool.isRequired,
  onSelectedRowsChange: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  isSortable: PropTypes.bool.isRequired,
  isInitiallySorted: PropTypes.bool.isRequired,
  sortColumnIndex: PropTypes.number.isRequired,
  sortDirection: PropTypes.oneOf(["asc", "desc"]),
  lastBodyRowStyle: PropTypes.object.isRequired,
  headerCellStyle: PropTypes.object.isRequired,
  useLoadMoreButtons: PropTypes.bool.isRequired,
  resetLoadMoreCounterOnChildrenChange: PropTypes.bool.isRequired,
  loadMoreStep: PropTypes.number.isRequired,
  loadMoreButtonText: PropTypes.string.isRequired,
  loadAllButtonText: PropTypes.string.isRequired,
  hasStickyHeader: PropTypes.bool.isRequired,
  withoutSidePadding: PropTypes.bool.isRequired,
};
