export const statusOptions = [
  {id: 1, name: "Pending other tasks"},
  {id: 2, name: "Ready to process"},
  {id: 3, name: "In progress"},
  {id: 4, name: "Waiting for approval"},
  {id: 5, name: "Complete - satisfied"},
  {id: 6, name: "Complete - waived"},
];

export default statusOptions;
