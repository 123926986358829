import React, {Component} from "react";

import {Toolbar, ToolbarGroup, ToolbarTitle} from "material-ui/Toolbar";
import RaisedButton from "material-ui/RaisedButton";

import ModulesForm from "./modules_form";
import ModulesList from "./modules_list";

class Modules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      editingTemplate: null,
    };
  }

  moveHistoryBackward = () => {
    this.props.router.goBack();
  };

  triggerCreateTemplate = () => {
    this.setState({isCreating: true});
  };

  triggerCreateFinish = () => {
    this.setState({isCreating: false});
  };

  onCreateFinish = data => {
    this.props.createModule(data);
    this.triggerCreateFinish();
  };

  editTemplate = id => {
    this.setState({editingTemplate: id});
  };

  finishEditTemplate = () => {
    this.setState({editingTemplate: null});
  };

  onEditFinish = data => {
    this.props.updateModule(data);
    this.finishEditTemplate();
  };

  render() {
    return (
      <div className="app-page">
        <Toolbar className="app-toolbar">
          <ToolbarGroup key={0} style={{width: "100%"}}>
            <ToolbarTitle text="Modules" />
            <RaisedButton
              style={{float: "right"}}
              onClick={this.moveHistoryBackward}
            >
              Back
            </RaisedButton>
          </ToolbarGroup>
        </Toolbar>
        <div className="app-body" style={{padding: "0 3em"}}>
          {this.state.isCreating ? (
            <ModulesForm
              onSave={this.onCreateFinish}
              onCancel={this.triggerCreateFinish}
              templateModules={this.props.templateModules}
            />
          ) : (
            <div style={{display: "flex"}}>
              <RaisedButton
                style={{margin: "20px 0", marginLeft: "auto"}}
                onClick={this.triggerCreateTemplate}
              >
                Create
              </RaisedButton>
            </div>
          )}
          <ModulesList
            templateModules={this.props.templateModules}
            triggerEdit={this.editTemplate}
            onCancel={this.finishEditTemplate}
            onSave={this.onEditFinish}
            editId={this.state.editingTemplate}
            getModuleTopics={this.props.getModuleTopics}
            topicsById={this.props.topicsById}
            params={this.props.params}
          />
        </div>
      </div>
    );
  }
}

export default Modules;
