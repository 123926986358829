import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import {
  SelectActionDefinitionArg,
  SelectValuesArgument,
} from "common/flowmaster/types/task_config";
import CloseIcon from "@material-ui/icons/Close";

interface SelectValueEditorProps {
  argDefinition: SelectActionDefinitionArg;
  argValue: SelectValuesArgument;
  onUpdateItem: (value: SelectValuesArgument) => void;
}

const SelectInputEditor: React.FC<SelectValueEditorProps> = ({
  argDefinition,
  argValue,
  onUpdateItem,
}) => {
  const options = argDefinition.options;

  const handleChange = (event: React.ChangeEvent<{value: string}>) => {
    const selectedValue = event.target.value;
    const selectedOption = options?.find(
      option => option.key === selectedValue,
    );
    if (selectedOption) {
      onUpdateItem({value: selectedOption});
    }
  };

  const handleClear = () => {
    onUpdateItem({value: {key: ""}});
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id={`${argDefinition.key}-selector-label`}>
        {argDefinition.label}
      </InputLabel>
      <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
        <Select
          labelId={`${argDefinition.key}-selector-label`}
          value={argValue?.value.key}
          onChange={handleChange}
          label={argDefinition.label}
          style={{flexGrow: 1}}
        >
          {options?.map(option => (
            <MenuItem value={option.key} key={option.key}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {argValue?.value.key && (
          <IconButton onClick={handleClear}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </FormControl>
  );
};

const ActionArg = {
  Component: SelectInputEditor,
  default: {value: {key: ""}},
};

export default ActionArg;
