import React from "react";

const amountAround = 100;

export default class DocumentParseDifference extends React.Component {
  render() {
    const {plainText: text} = this.props;
    const lastIndex = this.props.diffs.length - 1;
    return (
      <pre
        style={{
          marginBottom: "1em",
          backgroundColor: "#fee",
          padding: "1em",
          whiteSpace: "pre-wrap",
        }}
      >
        {
          this.props.diffs.reduce(
            (state, diff, index) => {
              state.list.push(
                ...this.renderDiff(
                  diff,
                  text,
                  index === 0,
                  index === lastIndex,
                  state.prevOffset,
                ),
              );
              state.prevOffset =
                diff.offset + (diff.removed ? diff.removed.value.length : 0);
              return state;
            },
            {list: [], prevOffset: 0},
          ).list
        }
      </pre>
    );
  }

  renderDiff(diff, text, isStart, isEnd, prevOffset) {
    const {offset} = diff;

    const spans = [];

    const preText = text.substring(
      isStart ? offset - amountAround : prevOffset,
      offset,
    );
    spans.push(
      <span key={`${offset}_pre)`} className="original" style={{}}>
        {isStart && offset > 0 ? "…" : ""}
        {preText}
      </span>,
    );
    spans.push(
      <span
        key={`${offset}_added)`}
        className="added"
        title="This text exists in the document, but not the original"
        style={{
          color: "green",
          backgroundColor: "white",
        }}
      >
        {this.getValue(diff.added)}
      </span>,
    );
    spans.push(
      <span
        key={`${offset}_removed)`}
        className="removed"
        title="This text exists in the original, but not in this document"
        style={{
          color: "red",
          backgroundColor: "white",
        }}
      >
        {this.getValue(diff.removed)}
      </span>,
    );
    if (isEnd) {
      const endOffset = offset + (diff.removed ? diff.removed.value.length : 0);
      const postText = text.substring(endOffset, endOffset + amountAround);
      spans.push(
        <span key={`${offset}_post)`} className="original">
          {postText}…
        </span>,
      );
    }
    return spans;
  }

  getValue(diff) {
    if (diff) {
      return diff.value
        .replace(/^\n+|\n$/g, "\n<<new line>>")
        .replace(/(^[\t ]+)|([\t ]+$)/g, string => string.replace(/ /g, "␣"))
        .replace(/\t/g, "⇥");
    }
    return "";
  }
}
