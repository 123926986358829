import _ from "underscore";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/KeyboardArrowUp";
import OpenIcon from "@material-ui/icons/KeyboardArrowDown";

import {Table, Td, Th, Tr} from "common_components/table";

import * as TopicParameters from "plugins/topicparameters";
import random from "utils/random";
import calculateDocumentRagLevelColor from "utils/reports/calculate_document_rag_level_color";

import calculateDocumentRagScore from "utils/reports/calculate_document_rag_score";
import ragLevelsPerColor from "utils/reports/rag_levels_per_color";
import upperFirst from "utils/upper_first";
import getDocumentPathName from "../utils/get_document_path_name";

import getIssueLevel from "../utils/reports/get_issue_level";
import getTopicParameterValues from "../utils/reports/get_topic_parameter_values";
import getTopicParameters from "../utils/reports/get_topic_parameters";
import randomiseIssueLevel from "../utils/reports/randomise_issue_level";
import renderTextSplits from "utils/render_text_splits";

const useStyles = makeStyles({
  documentName: {
    fontWeight: "500",
    color: "#000",
    margin: ".5em 0",
    paddingLeft: "10px",
  },
  parameter: {
    display: "flex",
    alignItems: "baseLine",
    flexWrap: "wrap",
    margin: "1em",
  },
  separator: {
    marginRight: "1em",
  },
  circle: {
    width: "0.8rem",
    height: "0.8rem",
    borderRadius: "100%",
    marginRight: "4px",
  },
  smallCircle: {
    width: "0.6rem",
    height: "0.6rem",
    borderRadius: "100%",
    marginRight: "4px",
  },
  overall: {
    fontWeight: "bold",
    color: "#333",
  },
});

const styles = {
  tableHeading: {
    fontSize: "22px",
  },
  tableHeader: {
    fontSize: "18px",
    fontWeight: 500,
  },
  tableHead: {
    position: "sticky",
    top: "0px",
    zIndex: 2,
    backgroundColor: "#fafafa",
  },
  overallBlock: {
    backgroundColor: "#edeff3",
  },
  tdContainer: {
    display: "flex",
    alignItems: "center",
  },
  trContainer: {
    cursor: "pointer",
    position: "relative",
  },
  trGroupContainer: {
    width: "8px",
    height: "100%",
    position: "absolute",
    backgroundColor: "#e0e0e0",
  },
};

export const colors = {
  high: "#D50000",
  red: "#D50000",
  moderate: "#F8A825",
  amber: "#F8A825",
  low: "#3DC853",
  green: "#3DC853",
  grey: "#aaa",
  lightGrey: "rgb(121, 128, 135)",
};

const overrides = {
  14927: {
    "group_Term & Termination": {
      level: "green",
    },
    group_Payment: {
      level: "amber",
    },
    "group_Supply Risk": {
      level: "amber",
    },
    "group_Contract Renewal": {
      level: "amber",
    },
  },
  14949: {
    "group_Term & Termination": {
      level: "green",
    },
    group_Payment: {
      level: "amber",
    },
    "group_Supply Risk": {
      level: "amber",
    },
    "group_Contract Renewal": {
      level: "green",
    },
  },
  14861: {
    "group_Term & Termination": {
      level: "green",
    },
    group_Payment: {
      level: "green",
    },
    "group_Supply Risk": {
      level: "amber",
    },
    "group_Contract Renewal": {
      level: "green",
    },
  },
  14860: {
    "group_Term & Termination": {
      level: "red",
    },
    group_Payment: {
      level: "red",
    },
    "group_Supply Risk": {
      level: "amber",
    },
    "group_Contract Renewal": {
      level: "green",
    },
  },
};

export default function ReportsTable({
  organisationId,
  projectId,
  user,
  reports,
  showReportDetail,
  issueset,
  showRagScore,
}) {
  const [showReportGroup, setShowReportGroup] = useState([]);
  const {reports: documents, settings} = reports;
  if (!documents) {
    return null;
  }
  const {hide_overall: hideOverall} = settings;
  const groupedReports = getGroupReports(documents);

  // getting max document issues
  let documentIssues = (documents[0] || []).issues || [];
  documents.forEach(doc => {
    if (doc.issues && doc.issues.length > documentIssues.length) {
      documentIssues = doc.issues;
    }
  });

  const topicParameters = getTopicParameters(documents);
  const showOverall = !hideOverall;

  const {
    row1: headerRow1,
    row2: headerRow2,
    headingList,
  } = getHeaderColumnsData(
    settings,
    documentIssues,
    topicParameters,
    showRagScore,
  );

  const colWidth =
    90 /
    (topicParameters.length +
      headingList.length +
      (settings.customColumns || []).length +
      (showOverall ? 2 : 1));

  const classes = useStyles();

  const renderTableRow = document => {
    const documentRagScore = calculateDocumentRagScore(
      document.issues,
      issueset,
    );
    const documentRagLevelColor = calculateDocumentRagLevelColor(
      documentRagScore,
      document.rag_score_levels,
    );
    const documentTextRagLevel =
      projectId === 267
        ? overrides[document.id]
          ? "green"
          : randomiseIssueLevel(document, {id: 16924})
        : ragLevelsPerColor[documentRagLevelColor];
    // Disable showing detail on reports till its in non-demo state.
    const showDetail = () => showReportDetail(document.id);
    // const showDetail = () => {};

    return (showReportGroup.find(item => item === document.group_id) &&
      document?.item_type === "hidden_group_row") ||
      !document?.item_type ||
      document?.item_type === "group_header_row" ? (
      <Tr key={document.id} onClick={showDetail} style={styles.trContainer}>
        <Td
          linkPath={getDocumentPathName(
            organisationId,
            projectId,
            user,
            document,
          )}
        >
          {document?.item_type === "hidden_group_row" ? (
            <div style={styles.trGroupContainer} />
          ) : null}
          <div className={classes.documentName}>{document.name}</div>
        </Td>
        {topicParameters.map(topicParameter => (
          <Td key={topicParameter.id} onClick={showDetail}>
            <div className={classes.parameter}>
              {renderParameterValues(document, topicParameter, classes)}
            </div>
          </Td>
        ))}
        {renderExtraColumns(document, settings)}
        {renderOverall(
          showOverall,
          user,
          showDetail,
          classes,
          documentTextRagLevel,
          documentRagScore,
        )}
        {renderRagScore(showRagScore, document.score, document.color, classes)}

        {renderIssueValues(headingList, document, issueset, settings, classes)}
      </Tr>
    ) : null;
  };

  const renderShowReportRow = (settings, document) => {
    const onShowReportGroup = () =>
      setShowReportGroup([...showReportGroup, document.group_id]);
    return showReportGroup.find(item => item === document.group_id) ? null : (
      <Tr
        key={`${document.group_id}_show_report`}
        onClick={onShowReportGroup}
        style={styles.trContainer}
      >
        <Td
          key={"start_show_report"}
          bgcolor={"#e0e0e0"}
          colSpan="6"
          containerStyle={{
            padding: 0,
          }}
        >
          <div style={styles.tdContainer}>
            <OpenIcon />
            <span>{`Show ${document?.items_count} ${getDocGroupText(
              settings,
            )}`}</span>
          </div>
        </Td>
        <Td key={"end_show_report"} bgcolor={"#e0e0e0"} />
      </Tr>
    );
  };

  const renderHideReportRow = (settings, document) => {
    const hideReportGroup = () => {
      const newState = [...showReportGroup];
      const index = newState.indexOf(`${document.group_id}`);
      if (index !== -1) {
        newState.splice(index, 1);
      }
      setShowReportGroup(newState);
    };

    return showReportGroup.find(item => item === document.group_id) ? (
      <Tr
        key={`${document.group_id}_hide_report`}
        onClick={hideReportGroup}
        style={styles.trContainer}
      >
        <Td
          key={"start_hide_report"}
          colSpan="6"
          bgcolor={"#e0e0e0"}
          containerStyle={{
            padding: 0,
          }}
        >
          <div style={styles.tdContainer}>
            <CloseIcon />
            <span>{`Hide ${document?.items_count} ${getDocGroupText(
              settings,
            )}`}</span>
          </div>
        </Td>
        <Td key={"end_hide_report"} bgcolor={"#e0e0e0"} />
      </Tr>
    ) : null;
  };

  const getDocGroupText = settings => {
    return settings.data_dashboard ? "sub-contracts" : "previous revisions";
  };

  const renderTablesRows = (settings, document) => {
    if (document?.item_type === "show_button") {
      return renderShowReportRow(settings, document);
    } else if (document?.item_type === "hide_button") {
      return renderHideReportRow(settings, document);
    } else {
      return renderTableRow(document);
    }
  };

  return (
    <div style={{overflow: "auto"}}>
      <Table sortDirection="desc" sortColumnIndex={3}>
        <thead style={styles.tableHead}>
          <tr key="header-title" style={{borderBottom: "1px solid #798087"}}>
            <Th
              style={styles.tableHeader}
              colSpan={topicParameters.length + 2}
              headerCellStyle={{justifyContent: "center"}}
            >
              {settings.data_dashboard ? "" : "Overview"}
            </Th>
            <Th
              style={styles.tableHeader}
              colSpan={documentIssues.length}
              headerCellStyle={{justifyContent: "center"}}
            >
              {settings.data_dashboard
                ? "Contract Portfolio: Key Data Summary"
                : "Issue Analysis"}
            </Th>
          </tr>
          {renderHeaderRow(headerRow1, colWidth)}
          {renderHeaderRow(headerRow2, colWidth)}
        </thead>
        <tbody style={{backgroundColor: "#fff"}}>
          {groupedReports.map(document => renderTablesRows(settings, document))}
          ;
        </tbody>
      </Table>
    </div>
  );
}

function renderOverall(
  showOverall,
  user,
  showDetail,
  classes,
  documentTextRagLevel,
  documentRagScore,
) {
  if (!showOverall) {
    return null;
  }
  return (
    <Td key="overall" style={styles.overallBlock} onClick={showDetail}>
      <div style={{...styles.tdContainer, justifyContent: "center"}}>
        <div
          className={classes.circle}
          style={{background: colors[documentTextRagLevel]}}
        />
        <span
          className={classes.overall}
          title={
            user.is_admin &&
            `Doc score: ${documentRagScore}, levels: ${JSON.stringify(
              document.rag_score_levels,
            )}`
          }
        >
          {upperFirst(documentTextRagLevel)}
        </span>
      </div>
    </Td>
  );
}

function renderRagScore(showRagScore, score, color, classes) {
  if (!showRagScore) {
    return null;
  }
  return (
    <Td key="rag_score" style={styles.overallBlock}>
      <div style={{...styles.tdContainer, justifyContent: "center"}}>
        <div className={classes.circle} style={{background: colors[color]}} />
        <span>{Math.round(score * 100)}% </span>
      </div>
    </Td>
  );
}

function renderHeaderRow(items, colWidth) {
  if (!items || items.length === 0) {
    return null;
  }
  return (
    <tr>
      {items.map(item => (
        <Th
          key={item.key}
          colSpan={item.colSpan}
          rowSpan={item.rowSpan}
          style={{
            width: `${
              item.type === "document_name" ? colWidth + 10 : colWidth
            }%`,
            ...(item.type === "overall_risk" || item.type === "rag_score"
              ? {backgroundColor: "#edeff3"}
              : {}),
            ...(item.style || {}),
          }}
          headerCellStyle={{justifyContent: "center"}}
        >
          {item.text}
        </Th>
      ))}
    </tr>
  );
}

function getHeaderColumnsData(settings, issues, topicParameters, showRagScore) {
  const {
    groupByHeadings,
    showIssueGroupHeadings,
    hide_overall: hideOverall,
    customColumns,
  } = settings;
  const showOverall = !hideOverall;
  let headingList = issues;
  let rowCount = 1;
  let groupedIssues = [];
  if (groupByHeadings || showIssueGroupHeadings) {
    groupedIssues = issues.reduce((list, issue, index) => {
      const {name} = issue;
      const slashLocation = name.indexOf("/");
      const heading =
        slashLocation > 0
          ? name.substring(0, slashLocation).trim()
          : name.trim();
      const item = list.find(el => el.name === heading);
      if (!item) {
        list.push({
          true_name: heading,
          name: heading,
          indexes: [index],
          issues: [issue],
        });
      } else {
        item.indexes.push(index);
        item.issues.push(issue);
      }
      return list;
    }, []);

    if (groupByHeadings) {
      headingList = groupedIssues;
    } else if (showIssueGroupHeadings) {
      rowCount = 2;
    }
  }

  const data = {
    row1: [],
    row2: [],
    headingList,
  };

  // 1) Document Name header cell
  data.row1.push({
    key: "Document Name",
    type: "document_name",
    text: "Document Name",
    rowSpan: rowCount,
  });

  // 2) Topicparameter header cells
  topicParameters.forEach(tp => {
    data.row1.push({
      key: tp.id,
      type: "topicparameter",
      text: tp.name,
      rowSpan: rowCount,
    });
  });

  // 3) Custom columns header cells
  if (customColumns) {
    customColumns.forEach((customColumn, index) => {
      data.row1.push({
        key: `${customColumn.name}-${index}`,
        type: "custom_column",
        text: customColumn.name,
        rowSpan: rowCount,
      });
    });
  }

  // 4) Overall Risk header cell
  if (showOverall) {
    data.row1.push({
      key: "Overall Risk",
      type: "overall_risk",
      text: "Overall Risk",
      rowSpan: rowCount,
    });
  }

  if (showRagScore) {
    data.row1.push({
      key: "Rag Score",
      type: "rag_score",
      text: "Rag Score",
      rowSpan: rowCount,
    });
  }

  // 5) Issues header cells
  if (rowCount === 2) {
    groupedIssues.forEach(group => {
      data.row1.push({
        key: group.name,
        type: "issue",
        text: group.name,
        colSpan: group.issues.length,
      });

      group.issues.forEach(issue => {
        data.row2.push({
          key: issue.name,
          type: "issue",
          text: issue.name,
        });
      });
    });
  } else {
    headingList.forEach(issue => {
      data.row1.push({
        key: issue.name,
        type: "issue",
        text: issue.name,
      });
    });
  }
  return data;
}

function renderExtraColumns(document, settings) {
  return (settings.customColumns || []).map((column, index) => {
    const valueUnrounded =
      random(document.id + index + 1) * (column.range[1] - column.range[0]) +
      column.range[0];
    const baseValue = Math.round(valueUnrounded);
    const overrides = {
      14922: {value: "$(USD) 126,000,000"}, // fa 1
      14956: {value: "£100,000.000"}, // fa 2
      14960: {value: "£15 million"}, // fa 5
      14883: {value: "£2,000,000"}, // fa 6
      14961: {value: "£50,000"}, // fa 7
    };

    const value = overrides[document.id]
      ? overrides[document.id].value
      : `${column.prefix}${baseValue}${column.suffix}`;
    return (
      <Td key={`${column.name}-${index}`}>
        <span style={{paddingLeft: "5px"}}>{value}</span>
      </Td>
    );
  });
}

function renderIssueValues(headingList, document, issueset, settings, classes) {
  if (settings.groupByHeadings) {
    return headingList.map((issueGroup, index) => {
      const levels = issueGroup.indexes.map(issueIndex => {
        return getIssueLevel(document.issues[issueIndex], issueset).issueLevel;
      });
      const issueLevelReal = levels.reduce((prev, next) => {
        if (prev === "red" || next === "red") {
          return "red";
        }
        if (prev === "amber" || next === "amber") {
          return "amber";
        }
        return "green";
      }, "green");
      const override =
        overrides[document.id] &&
        overrides[document.id][`group_${issueGroup.name}`];
      const issueLevel = override ? override.level : issueLevelReal;
      const name = upperFirst(issueLevel);
      return (
        <Td key={`${issueGroup.true_name}-${index}`}>
          <div
            style={{
              ...styles.tdContainer,
              ...(index === 0 ? {paddingLeft: "5px"} : {}),
            }}
          >
            <div
              className={classes.smallCircle}
              style={{background: colors[issueLevel]}}
            />
            {name}
          </div>
        </Td>
      );
    });
  }
  return renderUngroupedIssueValues(document, issueset, settings, classes);
}

function renderUngroupedIssueValues(document, issueset, settings, classes) {
  return document.issues.map((issue, index) => {
    const field = settings.issue_fields?.[index];
    const key = `${issue.true_name}-${index}`;
    if (!field || field.value === "alert_state") {
      const {issueLevel, issueText} = getIssueLevel(issue);
      return (
        <Td key={key}>
          <div
            style={{
              ...styles.tdContainer,
              justifyContent: "center",
              ...(index === 0 ? {paddingLeft: "5px"} : {}),
            }}
            title={issue.summary_text}
          >
            <div
              className={classes.smallCircle}
              style={{background: colors[issueLevel]}}
            />
            {upperFirst(issueText || "")}
          </div>
        </Td>
      );
    }
    return (
      <Td key={key}>
        {renderTextSplits(
          Array.isArray(issue.display_text)
            ? issue.display_text.join("\n")
            : issue.display_text || "",
        )}
      </Td>
    );
  });
}

function getGroupReports(documents) {
  const rows = [];
  _.forEach(_.groupBy(documents, "group_id"), document => {
    if (document.length > 1) {
      document.forEach((item, index) => {
        if (index === 0) {
          rows.push(
            {...item, group_id: item.group_id, item_type: "group_header_row"},
            {
              group_id: item.group_id,
              item_type: "show_button",
              items_count: document.length - 1,
            },
          );
        } else if (index === document.length - 1) {
          rows.push(
            {...item, item_type: "hidden_group_row", group_id: item.group_id},
            {
              group_id: item.group_id,
              item_type: "hide_button",
              items_count: document.length - 1,
            },
          );
        } else {
          rows.push({
            ...item,
            item_type: "hidden_group_row",
            group_id: item.group_id,
          });
        }
      });
    } else {
      rows.push(...document);
    }
  });

  return rows;
}

function renderParameterValues(report, topicParameter, classes) {
  const values = getTopicParameterValues(report, topicParameter.id);
  const renderedValues = values.map(value =>
    renderParameterValue(topicParameter, value),
  );
  const uniqueValues = _.uniq(renderedValues);
  return (
    <React.Fragment>
      {uniqueValues.map((value, index) => (
        <React.Fragment key={index}>
          <span>
            {value}
            {index < uniqueValues.length - 1 && (
              <span className={classes.separator}>,</span>
            )}
          </span>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

function renderParameterValue(parameter, parameterValue) {
  // eslint-disable-next-line import/namespace
  const topicParameter = TopicParameters[parameter.parameter_type];
  if (topicParameter) {
    return topicParameter.valueRenderer(parameter, parameterValue);
  }
  return JSON.stringify(parameterValue);
}
