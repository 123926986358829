import _ from "underscore";
import React from "react";

import {Link} from "react-router";
import ThumbUp from "material-ui/svg-icons/action/thumb-up";
import ThumbDown from "material-ui/svg-icons/action/thumb-down";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import RaisedButton from "material-ui/RaisedButton";
import Edit from "material-ui/svg-icons/image/edit";
import Cancel from "material-ui/svg-icons/navigation/cancel";
import Assignment from "material-ui/svg-icons/action/assignment";

import {IssueIcon, NoIssueIcon} from "constants/icons";
import TickCrossCheckbox from "common_components/tick_cross_checkbox";
import * as TopicParameters from "plugins/topicparameters";
import newlineToBreak from "utils/newline_to_break";
import ConfirmDialog from "common_components/confirm_dialog";

import isIssueAlert from "common/utils/issues/is_issue_alert";

const styles = {
  usagesHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
  },
  issueDetailLink: {
    cursor: "pointer",
    color: "#757575",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "2rem",
  },
  titleIcon: {
    zIndex: 0,
    cursor: "pointer",
    width: "18px",
    height: "18px",
    marginTop: "2px",
    marginLeft: "0.5rem",
  },
  resetButton: {
    margin: "auto .5em",
  },
  showHideUsagesButtonRoot: {
    height: "20px",
    lineHeight: "20px",
    position: "relative",
    bottom: "3px",
    width: "3.4rem",
    minWidth: "unset",
    margin: "0 0.5rem",
  },
  showHideUsagesButtonButton: {
    width: "3.4rem",
  },
  showHideUsagesButtonLabel: {
    paddingLeft: "unset",
    paddingRight: "unset",
    fontSize: "12px",
  },
};

const orderUsages = usages =>
  _.chain(usages).pairs().sortBy(markFirstClause).map(_.first).value();

const markFirstClause = ([, /* documentIssueId */ clauses]) => {
  if (!_.isArray(clauses) || _.isEmpty(clauses)) {
    return 0;
  }
  const {
    should_be_issue: shouldBeIssue,
    is_correctly_applied: isCorrectlyApplied,
    reason,
  } = _.first(clauses);
  const isIssue = _.isArray(reason) && !_.isEmpty(reason);
  if (shouldBeIssue === null) {
    return -6;
  }
  if (isCorrectlyApplied === null) {
    return -5;
  }
  if (shouldBeIssue !== isIssue) {
    return -4;
  }
  if (isCorrectlyApplied === false) {
    return -3;
  }
  if (
    shouldBeIssue === true &&
    isCorrectlyApplied === true &&
    isIssue === true
  ) {
    return -2;
  }
  if (shouldBeIssue === false && isIssue === false) {
    return -1;
  }
  return 0;
};

const getClausesByDocumentIssueIds = (usages, documentIssueIds) =>
  _.map(documentIssueIds, documentIssueId => [
    documentIssueId,
    usages[documentIssueId],
  ]);

function checkClauseUpdate(issueImportantLastEdited, issuesLastEdited) {
  const issueImportantLastEditedDate = new Date(issueImportantLastEdited);
  const issuesLastEditedDate = new Date(issuesLastEdited);
  return issuesLastEditedDate > issueImportantLastEditedDate;
}

export default class IssueUsage extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initState(props);
  }
  async componentDidMount() {
    await this.props.fetchIssueUsages();
    this.setState({haveToUpdateUsages: true});
  }
  initState = props => ({
    documentIssueIds: orderUsages(props.issue.usage),
    haveToUpdateUsages: false,
    showResetShouldBeIssueConfirmDialog: false,
    showResetIsCorrectlyAppliedConfirmDialog: false,
    editableClause: null,
    editableAll: false,
  });

  componentDidUpdate(prevProps) {
    if (
      this.state.haveToUpdateUsages &&
      this.props.issue.usage &&
      Object.keys(this.props.issue.usage).length
    ) {
      this.setState({
        documentIssueIds: orderUsages(this.props.issue.usage),
        haveToUpdateUsages: false,
      });
    }
    const {issue: prevIssue} = prevProps;
    const {issue: currentIssue} = this.props;
    if (
      prevIssue &&
      currentIssue &&
      prevIssue.id &&
      currentIssue.id &&
      prevIssue.id !== currentIssue.id
    ) {
      this.setState(() => this.initState(this.props));
    }
  }

  render() {
    const EditAll = this.state.editableAll ? Cancel : Edit;
    return (
      <div
        style={{
          marginTop: "16px",
        }}
      >
        {this.renderStats()}
        <div>
          <div style={styles.buttonsContainer}>
            <RaisedButton
              style={styles.resetButton}
              label={'Reset "Should be issue"'}
              onClick={this.setStateVariable(
                "showResetShouldBeIssueConfirmDialog",
                true,
              )}
            />
            <RaisedButton
              style={styles.resetButton}
              label={'Reset "Is correctly applied"'}
              onClick={this.setStateVariable(
                "showResetIsCorrectlyAppliedConfirmDialog",
                true,
              )}
            />
            <EditAll style={styles.titleIcon} onClick={this.clickEditAll} />
            <RefreshIcon
              style={styles.titleIcon}
              onClick={this.setStateVariable("haveToUpdateUsages", true)}
            />
          </div>
        </div>
        <div>{this.renderIssues()}</div>
        {this.renderResetShouldBeIssueConfirmDialog()}
        {this.renderResetIsCorrectlyAppliedConfirmDialog()}
      </div>
    );
  }

  renderStats = () => {
    const documentIssues = this.props.issue.usage;
    const stats = Object.keys(documentIssues).reduce(
      (result, documentIssueId) => {
        const usages = documentIssues[documentIssueId];
        const usage = usages[0];
        const {
          reason,
          should_be_issue: shouldBeIssue,
          is_correctly_applied: isCorrectlyApplied,
        } = usage;
        if (reason && shouldBeIssue === true) {
          if (isCorrectlyApplied === false) {
            result.incorrect += 1;
          } else {
            result.tp += 1;
          }
        }
        if (!reason && shouldBeIssue === true) {
          result.fn += 1;
        }
        if (reason && shouldBeIssue === false) {
          result.fp += 1;
        }
        if (reason && shouldBeIssue === null) {
          result.unconfirmed += 1;
        }
        return result;
      },
      {tp: 0, fn: 0, fp: 0, unconfirmed: 0, incorrect: 0},
    );
    return (
      <table
        style={{
          borderCollapse: "collapse",
          margin: "1rem auto",
          fontSize: "16px",
        }}
      >
        <thead>
          <tr style={{borderBottom: "1px solid lightgray"}}>
            <th style={{textAlign: "center", width: "5rem"}}>Unconfirmed</th>
            <th style={{textAlign: "center", width: "5rem"}}>TP</th>
            <th style={{textAlign: "center", width: "5rem"}}>FN</th>
            <th style={{textAlign: "center", width: "5rem"}}>FP</th>
            <th style={{textAlign: "center", width: "5rem"}}>Incorrect</th>
          </tr>
        </thead>
        <tbody className="parameters">
          <tr>
            <td style={{textAlign: "center"}}>{stats.unconfirmed}</td>
            <td style={{textAlign: "center"}}>{stats.tp}</td>
            <td style={{textAlign: "center"}}>{stats.fn}</td>
            <td style={{textAlign: "center"}}>{stats.fp}</td>
            <td style={{textAlign: "center"}}>{stats.incorrect}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  renderIssues() {
    const usages = getClausesByDocumentIssueIds(
      this.props.issue.usage,
      this.state.documentIssueIds,
    );
    return _.map(usages || [], ([documentIssueId, clauses]) => {
      return (
        <table
          className="document-issue"
          key={documentIssueId}
          style={{width: "100%", borderSpacing: "0"}}
        >
          <thead>
            <tr>
              <th style={{width: "3em"}}>Detail</th>
              <th style={{width: "4em"}}>Issue identified</th>
              <th style={{width: "5em"}}>Should be issue</th>
              <th style={{width: "6em"}}>Is correctly applied</th>
              <th style={{width: "4em"}}>Client status</th>
              <th style={{width: "8em"}}>Project</th>
              <th style={{width: "8em"}}>Document</th>
              <th style={{width: "8em"}}>Clause</th>
              <th>Text</th>
              <th style={{width: "10em"}}>Topics</th>
              <th style={{width: "15em"}}>Reject Reason</th>
            </tr>
          </thead>
          <tbody>{this.renderClauses(clauses)}</tbody>
        </table>
      );
    });
  }

  renderClauses(clauses) {
    return (clauses || []).map(clause => this.renderClause(clause));
  }

  renderClause(clause) {
    const {
      // section_id: sectionId,
      // clause_id: clauseId,
      clausepart_id: clausepartId,
      clause_reference: clauseReference,
      clausepart_reference: clausepartReference,
      clausepart_text: clausepartText,
      // clausepart_last_edited: clausepartLastEdited,
      document_id: documentId,
      document_issue_id: documentIssueId,
      document_name: documentName,
      project_id: projectId,
      project_name: projectName,
      topics,
      // parameter_values: parameterValues
      should_be_issue: shouldBeIssue,
      is_correctly_applied: isCorrectlyApplied,
      user_verified: userVerified,
      reason,
      reject_reason: rejectReason,
      issues_last_edited: issuesLastEdited,
    } = clause;
    const {important_last_edited: issueImportantLastEdited} = this.props.issue;

    const isRowEditable =
      documentIssueId === this.state.editableClause || this.state.editableAll;
    const borderValue = isRowEditable ? "2px solid #bbf" : "none";
    const tdStyle = {
      margin: "0 1px",
      borderTop: borderValue,
      borderBottom: borderValue,
    };
    const isClauseUpToDate = checkClauseUpdate(
      issueImportantLastEdited,
      issuesLastEdited,
    );
    return (
      <tr
        className="clause"
        key={clausepartId}
        style={{
          backgroundColor: isClauseUpToDate ? "#FAFAFF" : "#FFCDD2",
        }}
        onDoubleClick={this.setEditableClause(documentIssueId)}
      >
        <td style={{...tdStyle, borderLeft: borderValue, textAlign: "center"}}>
          <Assignment
            style={styles.issueDetailLink}
            onClick={this.getIssueDetailLinkHandler(projectId, documentId)}
          />
        </td>
        <td className="client-status" style={{textAlign: "center", ...tdStyle}}>
          {this.renderStatusIcon({
            reason,
            trigger_display: this.props.issue.trigger_display,
          })}
        </td>
        <td
          onClick={
            isRowEditable
              ? this.onCheckboxCellClick("should_be_issue", clause)
              : undefined
          }
          className="should-be-issue"
          style={{
            ...tdStyle,
            textAlign: "center",
            cursor: isRowEditable ? "pointer" : "default",
          }}
        >
          {this.renderShouldBeIssueIconButton(
            clause,
            isRowEditable,
            shouldBeIssue,
            this.props.issue.trigger_display,
          )}
        </td>
        <td
          onClick={
            isRowEditable
              ? this.onCheckboxCellClick("is_correctly_applied", clause)
              : undefined
          }
          className="is_correctly_applied"
          style={{
            ...tdStyle,
            textAlign: "center",
            cursor: isRowEditable ? "pointer" : "default",
          }}
        >
          <TickCrossCheckbox
            paramValue={isCorrectlyApplied}
            onClick={
              isRowEditable
                ? this.onCheckboxCheck("is_correctly_applied", clause)
                : undefined
            }
          />
        </td>
        <td
          className="issue-identified"
          style={{textAlign: "center", ...tdStyle}}
        >
          {userVerified === true && <ThumbUp />}
          {userVerified === false && <ThumbDown />}
        </td>
        <td className="project-name" style={{textAlign: "center", ...tdStyle}}>
          {projectName}
        </td>
        <td style={{textAlign: "center", ...tdStyle}}>
          <div style={{width: "8em", overflow: "hidden"}}>
            <Link
              className="document-link"
              to={{pathname: this.getClauseLink(clause)}}
            >
              <span className="document-name">{documentName}</span>
            </Link>
          </div>
        </td>
        <td style={{textAlign: "center", ...tdStyle}}>
          <Link
            className="clause-link"
            to={{
              pathname: this.getClauseLink(clause),
              query: {clause: clauseReference},
            }}
          >
            <span className="clause-reference">{clausepartReference}</span>
          </Link>
        </td>
        <td className="clause-text" style={tdStyle}>
          {clausepartText}
        </td>
        <td className="topics" style={tdStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {this.renderTopics(
              (topics || []).filter(topic => topic.is_confirmed),
              true,
            )}
            {this.renderTopics(
              (topics || []).filter(topic => !topic.is_confirmed),
              false,
            )}
          </div>
        </td>
        <td
          className="reject-reason"
          dangerouslySetInnerHTML={{
            __html: newlineToBreak(rejectReason?.reason ?? ""),
          }}
          style={{...tdStyle, borderRight: borderValue}}
        />
      </tr>
    );
  }

  getClauseLink(clause) {
    const {organisationId} = this.props;
    const {project_id: projectId, document_id: documentId} = clause;
    const link =
      `/organisation/${organisationId}/project/${projectId}` +
      `/document/${documentId}`;
    return link;
  }

  renderStatusIcon(issue) {
    const isTriggered = isIssueAlert(issue);
    const Icon = isTriggered ? IssueIcon : NoIssueIcon;
    return (
      <Icon
        style={{
          width: "22px",
          height: "22px",
          color: isTriggered ? "#C40233" : "green",
        }}
      />
    );
  }

  renderShouldBeIssueIconButton(clause, isRowEditable, shouldBeIssue) {
    return (
      <TickCrossCheckbox
        paramValue={shouldBeIssue}
        positiveIcon={IssueIcon}
        negativeIcon={NoIssueIcon}
        color={
          shouldBeIssue !== null
            ? shouldBeIssue
              ? "#C40233"
              : "green"
            : "black"
        }
        onClick={
          isRowEditable
            ? this.onCheckboxCheck("should_be_issue", clause)
            : undefined
        }
      />
    );
  }

  renderTopics(topics) {
    return topics.map(topic => this.renderTopic(topic));
  }

  renderTopic(clauseTopic) {
    const {topic_id: id} = clauseTopic;
    const topic = this.props.topicsById[id];
    const {organisationId} = this.props;
    return (
      <div
        className="topic"
        key={topic.id}
        style={{
          display: "inline-block",
          fontSize: "0.7em",
          border: "1px solid #aaa",
          borderRadius: "1em",
          padding: "1em",
          margin: "0.25em 0",
          backgroundColor: clauseTopic.is_confirmed ? "#dfd" : "#ffd",
        }}
      >
        <Link
          to={{
            pathname: `/organisation/${organisationId}/topic/${id}/detail`,
          }}
        >
          {topic.name}
        </Link>
        <div className="parameters">
          {this.renderParameters(topic, clauseTopic)}
        </div>
      </div>
    );
  }

  renderParameters(topic, clauseTopic) {
    return clauseTopic.topicparameters.map(topicparameter =>
      this.renderParameter(topic, clauseTopic, topicparameter),
    );
  }

  renderParameter(topic, clauseTopic, topicparameter) {
    const {topicparameter_id: parameterId} = topicparameter;
    const parameter = topic.parameters.find(param => param.id === parameterId);
    if (!parameter) {
      return null;
    }
    return (
      <div
        className="parameter"
        key={parameterId}
        style={{
          display: "inline-block",
          margin: "0.5em 0",
          padding: "0.5em",
          border: "1px solid #ccc",
          borderRadius: "1em",
          backgroundColor: clauseTopic.is_confirmed ? "#efe" : "#ffe",
        }}
      >
        <div
          className="name"
          style={{textAlign: "center", fontStyle: "italic"}}
        >
          {parameter.name}
        </div>
        <div className="parameter-values">
          {this.renderParameterValues(parameter, topicparameter.values)}
        </div>
      </div>
    );
  }

  renderResetShouldBeIssueConfirmDialog() {
    return (
      <ConfirmDialog
        open={this.state.showResetShouldBeIssueConfirmDialog}
        onClose={this.setStateVariable(
          "showResetShouldBeIssueConfirmDialog",
          false,
        )}
        onSuccess={this.props.resetShouldBeIssue}
        title={'Reset "Should Be Issues"'}
        description={
          'Are you sure you want to reset all "Should Be Issues" values?'
        }
        okButtonCaption="Confirm"
      />
    );
  }

  renderResetIsCorrectlyAppliedConfirmDialog() {
    return (
      <ConfirmDialog
        open={this.state.showResetIsCorrectlyAppliedConfirmDialog}
        onClose={this.setStateVariable(
          "showResetIsCorrectlyAppliedConfirmDialog",
          false,
        )}
        onSuccess={this.props.resetIsCorrectlyApplied}
        title={'Reset "Is Correctly Applied"'}
        description={
          'Are you sure you want to reset all "Is Correctly Applied" values?'
        }
        okButtonCaption="Confirm"
      />
    );
  }

  renderParameterValues(parameter, parameterValues) {
    return (parameterValues || []).map(parameterValue =>
      this.renderParameterValue(parameter, parameterValue),
    );
  }

  renderParameterValue(parameter, parameterValue) {
    // eslint-disable-next-line import/namespace
    const topicParameter = TopicParameters[parameter.parameter_type];
    if (topicParameter) {
      return topicParameter.valueRenderer(parameter, parameterValue);
    }
    return JSON.stringify(parameterValue);
  }

  onCheckboxCheck = _.memoize(
    (fieldName, clause) => event => {
      event.stopPropagation();
      let updates;
      if (clause[fieldName] === null) {
        updates = {[fieldName]: true};
      }
      if (clause[fieldName] === true) {
        updates = {[fieldName]: false};
      }
      if (clause[fieldName] === false) {
        updates = {[fieldName]: null};
      }
      this.props.updateDocumentIssue(clause, updates);
    },
    (...args) => JSON.stringify(args),
  );

  onCheckboxCellClick = _.memoize(
    (fieldName, clause) => event => {
      event.stopPropagation();
      let updates;
      if (clause[fieldName] === null) {
        updates = {[fieldName]: false};
      }
      if (clause[fieldName] === true) {
        updates = {[fieldName]: null};
      }
      if (clause[fieldName] === false) {
        updates = {[fieldName]: true};
      }
      this.props.updateDocumentIssue(clause, updates);
    },
    (...args) => JSON.stringify(args),
  );

  setStateVariable = _.memoize(
    (name, value) => () => this.setState({[name]: value}),
    (...args) => JSON.stringify([...args]),
  );

  clickEditAll = () =>
    this.setState({editableAll: !this.state.editableAll, editableClause: null});

  setEditableClause = _.memoize(documentIssueId => () =>
    this.setState({
      editableClause:
        documentIssueId === this.state.editableClause ? null : documentIssueId,
    }),
  );

  getIssueDetailLinkHandler = _.memoize(
    (projectId, documentId) => () => {
      const {selectedIssueset} = this.props;
      let search = `?open_issue_list=true&project=${projectId}&document=${documentId}&open_issue_detail=true`;
      if (selectedIssueset) {
        search += `&issueset=${selectedIssueset.id}`;
      }
      this.props.showDocumentIssue({search});
    },
    (...args) => JSON.stringify([...args]),
  );
}
