import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {
  WorkflowTask,
  WorkflowTaskId,
} from "common/flowmaster/types/task_config";
import {WorkflowId} from "common/flowmaster/types/workflow";

export type WorkflowTaskUpdateAction = {
  type: WorkflowsActionTypes["WORKFLOW_TASK_UPDATE"]["SUCCESS"];
  payload: WorkflowTask;
};

export default function updateWorkflowTask(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOW_TASK_UPDATE,
    (
      organisationId: OrganisationId,
      workflowId: WorkflowId,
      workflowTaskId: WorkflowTaskId,
      data: WorkflowTask,
    ) => {
      return requestor
        .patch(
          `${basePath}/organisation/${organisationId}` +
            `/workflow/${workflowId}/task/${workflowTaskId}`,
          data,
          {withCredentials: true},
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
