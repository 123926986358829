import basePath from "base_path";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {createChangeTimeWrapper} from "utils/ensure_fresh_update";

import rateLimit, {getStandardTimeout} from "utils/rate_limit";
import ActionTypes from "../../constants/action_types";

function doSave(
  requestor,
  organisationId,
  contractTypeId,
  issuesetId,
  data,
  changeTime,
) {
  return requestor
    .patch(
      `${basePath}/organisation/${organisationId}/contract_type/${contractTypeId}/issueset/${issuesetId}`,
      data,
      {withCredentials: true},
    )
    .then(response => ({
      ...createChangeTimeWrapper(changeTime),
      ...data,
      ...response.data,
    }));
}

const save = rateLimit(doSave, getStandardTimeout());

export default function updateIssueset(requestor) {
  return createReduxPromiseAction(
    ActionTypes.ISSUESET_UPDATE,
    (organisationId, contractTypeId, issuesetId, data, changeTime) => {
      if (!data.last_edited) {
        throw new Error("data must contain a last_edited key");
      }
      return save(
        requestor,
        organisationId,
        contractTypeId,
        issuesetId,
        data,
        changeTime,
      );
    },
    (organisationId, contractTypeId, issuesetId, data, changeTime) => ({
      ...createChangeTimeWrapper(changeTime),
      ...data,
    }),
  );
}
