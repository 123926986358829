import React, {useState, useContext} from "react";
import PropTypes from "prop-types";
import ContractTypeContext from "../containers/contract_type_context";
import moment from "moment";

import Permissioner from "utils/permissioner";

import VisibilityIcon from "@material-ui/icons/Visibility";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";

const styles = {
  cell: {
    paddingBottom: "0.3rem",
    paddingTop: "0.4rem",
    verticalAlign: "top",
  },
};

function hasPermission(user, permission) {
  return new Permissioner(user).hasPermission(permission);
}

function ContractTypeListItem(props) {
  const {updateContractType, onContractTypeDetail, user} = useContext(
    ContractTypeContext,
  );
  const {contractType} = props;

  const [isItemHovered, setIsItemHovered] = useState(false);

  const issuesets = (contractType.issuesets || []).filter(
    iset => iset.is_duplicate_on_master === false,
  );

  const onItemEnter = () => setIsItemHovered(true);
  const onItemLeave = () => setIsItemHovered(false);

  const onContractTypeUpdate = data => {
    const {contractType} = props;
    updateContractType(contractType.id, {
      last_edited: contractType.last_edited,
      ...data,
    });
  };

  const onIsVisibleOn = () => onContractTypeUpdate({is_visible: true});
  const onIsVisibleOff = () => onContractTypeUpdate({is_visible: false});

  return (
    <tr
      style={{
        borderBottom: props.showBottomSeparator ? "1px solid #E0E0E0" : "none",
      }}
      onMouseEnter={onItemEnter}
      onMouseLeave={onItemLeave}
    >
      <td style={styles.cell}>{contractType.id}</td>
      <td
        style={styles.cell}
        onClick={
          hasPermission(user, "update-contract-type")
            ? onContractTypeDetail(contractType.id)
            : undefined
        }
      >
        <div
          style={{
            color: isItemHovered ? blue[500] : "#000",
            textDecoration: isItemHovered ? "underline" : "none",
            cursor: "pointer",
            paddingBottom: "8px",
          }}
        >
          {contractType.name}
        </div>
      </td>
      <td
        style={{
          ...styles.cell,
          textAlign: "center",
        }}
      >
        {moment(contractType.creation_date).format("D MMM YYYY, HH:mm:ss ")}
      </td>
      <td
        style={{
          ...styles.cell,
          verticalAlign: "inherit",
          textAlign: "center",
        }}
      >
        {issuesets.length}
      </td>
      <td
        style={{
          ...styles.cell,
          verticalAlign: "inherit",
          textAlign: "center",
        }}
      >
        {contractType.is_visible ? (
          <VisibilityIcon
            style={{
              height: "20px",
              width: "20px",
              cursor: "pointer",
              color: grey[700],
            }}
            onClick={onIsVisibleOff}
          />
        ) : isItemHovered ? (
          <VisibilityIcon
            style={{
              height: "20px",
              width: "20px",
              cursor: "pointer",
              color: grey[400],
            }}
            onClick={onIsVisibleOn}
          />
        ) : null}
      </td>
    </tr>
  );
}

ContractTypeListItem.propTypes = {
  contractType: PropTypes.object.isRequired,
  showBottomSeparator: PropTypes.bool.isRequired,
};

export default ContractTypeListItem;
