import _ from "underscore";
import {get} from "lodash";
import React from "react";
import PropTypes from "prop-types";
import keyedObjectPropType from "utils/keyed_object_prop_type";
import Select from "react-select";

import CreateTopicDialog from "common_components/create_topic_dialog";
import TopicSelectItem from "./topic_select_item";
import UnconfirmedItem from "./unconfirmed_item";
import ConfirmedItem from "./confirmed_item";
import TopicSorter from "./sort_topics_dialog";
import SimilarClausesDialog from "./similar_clauses_dialog/";

import {filterOptions} from "common_components/select_handlers";

import RaisedButton from "material-ui/RaisedButton";
import IconButton from "material-ui/IconButton";

import CircularProgress from "@material-ui/core/CircularProgress";
import ListIcon from "@material-ui/icons/List";
import StarRateIcon from "@material-ui/icons/StarRate";

import byId from "common/utils/by_id";

const styles = {
  fieldsetContainer: {
    backgroundColor: "white",
    border: "1px solid lightgray",
    borderRadius: "4px",
    minHeight: "2.4rem",
    margin: 0,
    padding: 0,
  },
  fieldsetContainerLegend: {
    fontSize: "0.65em",
    color: "gray",
    marginLeft: "10px",
  },
  progressContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

function FieldsetContainer(props) {
  return (
    <fieldset style={styles.fieldsetContainer}>
      {props.legend && (
        <legend style={styles.fieldsetContainerLegend}>{props.legend}</legend>
      )}
      {props.children}
    </fieldset>
  );
}

export class Selector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createNewTopic: false,
      showSorter: false,
      isRequestPending: false,
      similarClausesDialogData: null,
    };
  }

  componentDidUpdate() {
    this.updateHeight();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.showInfoId !== this.props.showInfoId) {
      return false;
    }

    return true;
  }

  shouldFilterTopicMask = topic => {
    return topic.name.match(/Blank|Spare/);
  };

  getConfirmedClauseTopics = () => {
    const {topicMasksById = {}, topicsById = {}} = this.props;
    return _.chain(this.props.clause.topics || [])
      .filter(topic => {
        if (!topic.is_confirmed || topic.hide) {
          return false;
        }
        const topicMaskId = topic.topic_mask_id;
        if (topicMaskId) {
          return !this.shouldFilterTopicMask(topicMasksById[topicMaskId]);
        }
        return true;
      })
      .sortBy(clauseTopic => {
        let score = clauseTopic.topic_order || 0;
        if (score === 0) {
          score += 100;
        }
        const {topic_id: topicId, topic_mask_id: topicMaskId} = clauseTopic;
        const topic = topicsById[topicId] || topicMasksById[topicMaskId];
        if (topic && topic.name.startsWith("NOTE:")) {
          score += 1000;
        }
        return score;
      })
      .value();
  };

  render() {
    const {isRequestPending} = this.state;
    const confirmedClauseTopics = this.getConfirmedClauseTopics();
    return (
      <span
        className="topicSelector"
        style={{
          display: "inline-block",
          minWidth: "15em",
          width: "100%",
          ...this.props.style,
        }}
      >
        <div style={{position: "relative"}}>
          {this.props.showBasicTopicList
            ? this.renderBasicConfirmedTopics(confirmedClauseTopics)
            : this.renderTopicsInSelect(confirmedClauseTopics)}
          {!this.props.hideSortButton && (
            <IconButton
              className="sort"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                verticalAlign: "middle",
                height: "100%",
                zIndex: 0,
              }}
              disabled={
                this.props.disabled ||
                this.props.clause.topics.filter(topic => topic.is_confirmed)
                  .length < 2
              }
              onClick={this.sortButtonClicked}
            >
              <ListIcon />
            </IconButton>
          )}
          {isRequestPending && (
            <div style={styles.progressContainer}>
              <CircularProgress style={{width: 16, height: 16}} />
            </div>
          )}
        </div>
        <div className="unconfirmedTopics">
          {this.renderUnconfirmedTopics()}
        </div>
        {this.renderUnconfirmedTopicsRemover()}
        {this.renderTopicCreator()}
        {this.renderTopicSorter()}
        {this.renderSimilarClausesDialog()}
      </span>
    );
  }

  renderBasicConfirmedTopics = confirmedClauseTopics => {
    const topics = confirmedClauseTopics
      .filter(clauseTopic => this.props.topicsById[clauseTopic.topic_id])
      .map(clauseTopic => ({
        organisationId: this.props.organisationId,
        option: {
          ...constructBaseTopicData(
            this.props.topicsById[clauseTopic.topic_id],
            clauseTopic,
            this.props.topicCategoriesById,
          ),
          is_confirmed: true,
        },
      }));
    return (
      <FieldsetContainer>
        {topics.map(topic => (
          <ConfirmedItem
            key={topic.option.value}
            organisationId={this.props.organisationId}
            option={topic.option}
            clause={this.props.clause}
            onTopicparameterValuesUpdate={
              this.props.onTopicparameterValuesUpdate
            }
            roles={this.props.roles}
          />
        ))}
      </FieldsetContainer>
    );
  };

  getSelectorOptions = () => {
    const {
      clause,
      topics,
      topicMasks,
      topicCategoriesById,
      disabled,
    } = this.props;
    const {confirmedTopics, unconfirmedTopics} = (clause.topics || []).reduce(
      (accum, topic) => {
        if (topic.is_confirmed) {
          accum.confirmedTopics.push({
            ...topic,
            combined_id: topic.topic_id || `m_${topic.topic_mask_id}`,
          });
        } else {
          accum.unconfirmedTopics.push(topic);
        }
        return accum;
      },
      {confirmedTopics: [], unconfirmedTopics: []},
    );
    const confirmedTopicsById = byId(confirmedTopics, "combined_id");
    const unconfirmedTopicsById = byId(unconfirmedTopics, "topic_id");
    const filteredTopics = topics.filter(topic => {
      const topicId = topic.id;
      return (
        !unconfirmedTopicsById[topicId] &&
        (!disabled ||
          (confirmedTopicsById[topicId] && !confirmedTopicsById[topicId].hide))
      );
    });
    const filteredMasks = (topicMasks || []).filter(mask => {
      const isMask = Boolean(
        confirmedTopics.find(topic => topic.topic_mask_id === mask.id),
      );
      if (!isMask) {
        return false;
      }
      return !this.shouldFilterTopicMask(mask);
    });

    return filteredTopics
      .map(topic => {
        const clauseTopic = confirmedTopicsById[topic.id];
        return {
          ...constructBaseTopicData(topic, clauseTopic, topicCategoriesById),
          className: [
            clauseTopic && `matchsource-${clauseTopic.match_source_id}`,
            clauseTopic && clauseTopic.exact_match_id ? "exact-match" : "",
          ]
            .join(" ")
            .trim(),
        };
      })
      .concat(
        filteredMasks.map(mask => {
          const clauseTopic = confirmedTopicsById[`m_${mask.id}`];
          return {
            value: `m_${mask.id}`,
            label: mask.name,
            topic: mask.name,
            category: topicCategoriesById[mask.topiccategory_id].name,
            parameters: mask.parameters,
            parameterValues: (clauseTopic && clauseTopic.topicparameters) || [],
          };
        }),
      );
  };

  renderTopicsInSelect = confirmedClauseTopics => {
    const selectorValue = confirmedClauseTopics.map(
      clauseTopic => clauseTopic.topic_id || `m_${clauseTopic.topic_mask_id}`,
    );
    return (
      <Select
        className="topics"
        ref={this.createTopicsRef}
        multi={true}
        allowCreate={true}
        backspaceRemoves={false}
        value={selectorValue}
        options={this.getSelectorOptions()}
        filterOptions={filterOptions}
        onInputChange={this.updateHeight}
        optionClassName="testcn"
        menuContainerStyle={{
          zIndex: 99999,
        }}
        valueRenderer={option => (
          <TopicSelectItem
            organisationId={this.props.organisationId}
            option={option}
            onClick={this.props.onItemClicked}
            clause={this.props.clause}
            onTopicparameterValuesUpdate={
              this.props.onTopicparameterValuesUpdate
            }
            roles={this.props.roles}
          />
        )}
        optionRenderer={option => (
          <div style={{paddingBottom: 5}}>
            <div style={{display: "flex", alignItems: "center"}}>
              <span>{option.label}</span>
              {option.is_star && (
                <StarRateIcon
                  style={{height: 18, width: 18, color: "#fbc02d"}}
                />
              )}
            </div>
            {this.renderDescription(option.description, option)}
          </div>
        )}
        onChange={this.selectTopic}
        style={{
          paddingRight: "48px",
          opacity: this.state.isRequestPending ? 0.3 : 1,
        }}
      />
    );
  };

  onHelpMouseEnter = value => {
    this.props.setInfoId(value);
  };

  onHelpMouseLeave = () => {
    this.props.setInfoId(null);
  };

  renderDescription = (description, option) => {
    if (!description || !Object.keys(description).length) {
      return null;
    }

    return (
      <div>
        <div
          style={{display: "inline-block"}}
          onMouseEnter={() => this.onHelpMouseEnter(option.value)}
          onMouseLeave={this.onHelpMouseLeave}
        >
          ?
        </div>
        <i
          style={{
            fontSize: 12,
            display: "inline-block",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: 350,
            marginLeft: 10,
          }}
        >
          {description.description}
        </i>
      </div>
    );
  };

  getUnconfirmedTopics() {
    return this.props.clause.topics.filter(topic => !topic.is_confirmed);
  }

  renderTopicCreator() {
    if (this.state && this.state.createNewTopic) {
      const {
        topicCategories,
        topicTags,
        contractTypesById,
        document,
      } = this.props;
      const {id, last_edited: lastEdited} = this.props.clause;
      const inititalContractTypes = document && {
        contract_type_id: document.contract_type.id,
        name: document.contract_type.name,
      };
      return (
        <CreateTopicDialog
          key="topic-creator"
          className="newTopicDialog"
          initialName={this.state.createNewTopic}
          topicCategories={topicCategories}
          topicTags={topicTags}
          onNewTopicAdded={this.onNewTopicAdded(id, lastEdited)}
          onDismiss={this.dismissDialog}
          contractTypesById={contractTypesById}
          inititalContractTypes={inititalContractTypes}
          topics={Object.values(this.props.topicsById)}
        />
      );
    }
    return null;
  }

  renderTopicSorter() {
    if (this.state && this.state.showSorter) {
      const {id, last_edited: lastEdited} = this.props.clause;
      return (
        <TopicSorter
          className="topic-sorter"
          onDismiss={this.dismissDialog}
          onSave={this.onTopicsReordered(id, lastEdited)}
          clauseTopics={this.props.clause.topics.filter(
            topic => topic.topic_id,
          )}
          topicsById={this.props.topicsById}
          topicMasksById={this.props.topicMasksById}
        />
      );
    }
    return null;
  }

  createTopic(name) {
    this.setState({createNewTopic: name});
  }

  renderUnconfirmedTopics() {
    const {
      topicCategoriesById, // , topicMasksById
      topics,
    } = this.props;

    const unconfirmedTopicsById = byId(this.getUnconfirmedTopics(), "topic_id");
    const unconfirmedTopics = topics.reduce((accum, topic) => {
      const clauseTopic = unconfirmedTopicsById[topic.id];
      if (clauseTopic && !clauseTopic.hide) {
        accum.push({
          organisationId: this.props.organisationId,
          option: {
            ...constructBaseTopicData(topic, clauseTopic, topicCategoriesById),
            is_confirmed: false,
          },
        });
      }
      return accum;
    }, []);
    if (!unconfirmedTopics || unconfirmedTopics.length === 0) {
      return null;
    }

    const {id, last_edited: lastEdited} = this.props.clause;
    return (
      <FieldsetContainer legend="Unconfirmed Topics">
        {unconfirmedTopics.map(unconfirmedTopic => {
          const onTopicConfirmed = this.onTopicConfirmed(
            id,
            lastEdited,
            unconfirmedTopic.option.value,
          );
          return (
            <UnconfirmedItem
              key={unconfirmedTopic.option.value}
              organisationId={unconfirmedTopic.organisationId}
              option={unconfirmedTopic.option}
              clause={this.props.clause}
              onTopicparameterValuesUpdate={this.onTopicparameterValuesUpdateUnconfirmed(
                onTopicConfirmed,
              )}
              onTopicRemoved={this.onTopicRemoved(
                id,
                lastEdited,
                unconfirmedTopic.option.value,
              )}
              onTopicConfirmed={onTopicConfirmed}
              roles={this.props.roles}
            />
          );
        })}
      </FieldsetContainer>
    );
  }

  onTopicparameterValuesUpdateUnconfirmed = onTopicConfirmed => async (
    ...args
  ) => {
    await onTopicConfirmed();
    this.props.onTopicparameterValuesUpdate(...args);
  };

  renderUnconfirmedTopicsRemover() {
    const {id, last_edited: lastEdited} = this.props.clause;
    if (
      this.getUnconfirmedTopics().length > 0 &&
      this.props.onUnconfirmedTopicsRemoved
    ) {
      return (
        <RaisedButton
          className="remove-all"
          style={{padding: "0 1em"}}
          onClick={this.onUnconfirmedTopicsRemoved(id, lastEdited)}
          buttonStyle={{zIndex: 0}}
        >
          Remove unconfirmed
        </RaisedButton>
      );
    }
    return null;
  }

  /* eslint-disable no-invalid-this */

  sortButtonClicked = e => {
    e.stopPropagation();
    this.setState({showSorter: true});
  };

  onNewTopicAdded = _.memoize(
    (id, lastEdited) => async (...args) => {
      this.setState({createNewTopic: null});
      this.topicsRef && this.topicsRef.focus();
      const result = await this.props.onNewTopicAdded(id, lastEdited, ...args);
      this.showSimilarDialog(result, "topic_add", [get(result, "value.id")]);
    },
    (...args) => JSON.stringify([...args]),
  );
  onTopicRemoved = _.memoize(
    (clausepartId, clausepartLastEdited, topicId) => (...args) =>
      this.props.onTopicRemoved(
        clausepartId,
        clausepartLastEdited,
        topicId,
        ...args,
      ),
    (...args) => JSON.stringify([...args]),
  );
  onUnconfirmedTopicsRemoved = _.memoize(
    (clausepartId, clausepartLastEdited) => (...args) => {
      if (this.props.onUnconfirmedTopicsRemoved) {
        this.props.onUnconfirmedTopicsRemoved(
          clausepartId,
          clausepartLastEdited,
          ...args,
        );
      }
    },
    (...args) => JSON.stringify([...args]),
  );

  onTopicConfirmed = _.memoize(
    (clausepartId, clausepartLastEdited, topicId) => (...args) =>
      this.props.onTopicConfirmed(
        clausepartId,
        clausepartLastEdited,
        topicId,
        ...args,
      ),
    (...args) => JSON.stringify([...args]),
  );

  onTopicsReordered = _.memoize(
    (clausepartId, clausepartLastEdited) => (...args) => {
      this.setState({showSorter: false});
      this.props.onTopicsReordered(clausepartId, clausepartLastEdited, ...args);
    },
    (...args) => JSON.stringify([...args]),
  );

  selectTopic = async valueArray => {
    if (this.props.disabled) {
      return;
    }
    const topicIds = (this.props.clause.topics || []).reduce((accum, topic) => {
      if (topic.is_confirmed && !topic.hide && !topic.topic_mask_id) {
        accum.push(topic.topic_id);
      }
      return accum;
    }, []);
    const newTopics = valueArray.filter(
      value => !topicIds.find(topicId => value.value === topicId),
    );
    const {id, last_edited: lastEdited} = this.props.clause;
    const newValue = newTopics[0] && newTopics[0].value;
    this.setState(() => ({isRequestPending: true}));
    let action;
    let actionData;
    let result;
    if (newValue) {
      if (_.isNumber(newValue)) {
        action = "topic_add";
        actionData = [newValue]; // id of new topic
        result = await this.props.onExistingTopicAdded(
          id,
          lastEdited,
          newValue,
        );
      } else {
        await this.createTopic(newValue);
      }
    } else {
      const removedTopics = topicIds.filter(
        topicId => !valueArray.find(value => value.value === topicId),
      );
      action = "topic_remove";
      actionData = removedTopics; // id of removed topic
      result = await this.props.onTopicRemoved(
        id,
        lastEdited,
        ...removedTopics,
      );
    }
    this.setState(() => ({isRequestPending: false}));
    this.topicsRef.focus();
    this.showSimilarDialog(result, action, actionData);
  };

  showSimilarDialog = (result, action, actionData) => {
    const similarClauses = get(result, "value.similarClauses", []);
    if (similarClauses.length > 0) {
      this.setState(() => ({
        similarClausesDialogData: {
          clauses: similarClauses,
          action,
          actionData,
          originalClause: this.props.clause,
        },
      }));
    }
  };

  dismissDialog = () => {
    this.setState({createNewTopic: null, showSorter: false});
  };

  updateHeight = () => {
    setTimeout(() => {
      if (this.topicsRef) {
        const parent = this.topicsRef.refs.wrapper;
        if (parent) {
          const child = parent.querySelectorAll(".Select-control")[0];
          const height = child.clientHeight;
          parent.style.height = `${height + 1}px`;
        }
      }
    });
  };

  renderSimilarClausesDialog = () => {
    if (this.state.similarClausesDialogData) {
      return (
        <SimilarClausesDialog
          {...this.state.similarClausesDialogData}
          clausepartsTopicsUpdated={this.props.clausepartsTopicsUpdated}
          organisationId={this.props.organisationId}
          topicsById={this.props.topicsById}
          topicCategoriesById={this.props.topicCategoriesById}
          topicMasksById={this.props.topicMasksById}
          onDialogClose={this.clearSimilarClausesDialogData}
          topicMasks={this.props.topicMasks}
        />
      );
    }
  };

  clearSimilarClausesDialogData = () =>
    this.setState(() => ({similarClausesDialogData: null}));

  createTopicsRef = node => (this.topicsRef = node);
  /* eslint-enable no-invalid-this */
}

function constructBaseTopicData(topic, clauseTopic, topicCategoriesById) {
  return {
    value: topic.id,
    label: `${topicCategoriesById[topic.topiccategory_id].name} - ${
      topic.name
    }`,
    topic: topic.name,
    is_star: topic.is_star,
    clauseTopic,
    category: topicCategoriesById[topic.topiccategory_id].name,
    parameters: topic.parameters,
    parameterValues: (clauseTopic && clauseTopic.topicparameters) || [],
    description: topic.description,
  };
}

Selector.propTypes = {
  organisationId: PropTypes.number,
  clause: PropTypes.shape({
    id: PropTypes.number,
    last_edited: PropTypes.string,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        topic_id: PropTypes.number,
        topic_mask_id: PropTypes.number,
        is_confirmed: PropTypes.bool.isRequired,
        topicparameters: PropTypes.arrayOf(
          PropTypes.shape({
            topicparameter_id: PropTypes.number.isRequired,
            values: PropTypes.array,
          }),
        ),
      }),
    ),
  }),
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicsById: keyedObjectPropType(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategoriesById: keyedObjectPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  topicTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onExistingTopicAdded: PropTypes.func,
  onNewTopicAdded: PropTypes.func,
  onUnconfirmedTopicsRemoved: PropTypes.func,
  onTopicRemoved: PropTypes.func,
  onTopicsReordered: PropTypes.func,
};

export default Selector;
