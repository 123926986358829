import React from "react";
import _ from "lodash";

import LinkIcon from "@material-ui/icons/Link";

import CheckboxBasic from "common_components/inputs/checkbox_basic";
import Selector from "common_components/topic_selector/selector";

const styles = {
  cell: {
    padding: "6px 0px 0px 10px",
    borderBottom: "1px solid #212121",
  },
};

function ClauseListItem(props) {
  const {
    organisationId,
    clause,
    originalClause,
    whichToPopulate,
    action,
    actionData,
    isSelected,
    triggerSelectClause,
  } = props;

  function onTriggerSelect() {
    triggerSelectClause(clause.clausepart_id);
  }

  const resultingTopics = getResultingTopics(
    originalClause,
    clause,
    whichToPopulate,
    action,
    actionData,
  );
  // console.log('resultingTopics: ', resultingTopics);
  const baseProps = {
    ..._.pick(props, [
      "organisationId",
      "topicsById",
      "topicCategoriesById",
      "topicMasksById",
      "topicMasks",
    ]),
    topics: [], // ??????????????????????
    disabled: true,
    hideSortButton: true,
    showBasicTopicList: true,
    showInfoId: false,
    style: {},
    onItemClicked: () => null,
  };

  const existingProps = {
    ...baseProps,
    clause,
  };

  const proposedProps = {
    ...baseProps,
    clause: {
      ...clause,
      topics: resultingTopics,
    },
  };

  const topicsAreSame = areTopicsSame(
    existingProps.clause.topics,
    proposedProps.clause.topics,
    props.shouldLog,
  );

  const href = `/organisation/${organisationId}/project/${
    clause.project_id
  }/document/${clause.document_id}`;

  return (
    <tr style={styles.clauseRow}>
      <td style={styles.cell}>
        <CheckboxBasic checked={isSelected} onCheck={onTriggerSelect} />
      </td>
      <td style={styles.cell}>{clause.project_name}</td>
      <td style={styles.cell}>
        <a
          style={{textDecoration: "none", color: "inherit"}}
          target="_blank"
          href={href}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#42a5f5",
            }}
          >
            <LinkIcon />
            <div style={{marginLeft: 4}}>{clause.document_name}</div>
          </div>
        </a>
      </td>
      <td style={styles.cell}>
        <Selector {...existingProps} />
      </td>
      <td style={styles.cell}>
        {topicsAreSame ? (
          <div style={{fontStyle: "italic", textAlign: "center"}}>
            No Changes
          </div>
        ) : (
          <Selector {...proposedProps} />
        )}
      </td>
    </tr>
  );
}

function getResultingTopics(
  originalClause,
  clause,
  whichToPopulate,
  action,
  actionData,
) {
  if (whichToPopulate === "all") {
    return originalClause.topics;
  } else if (whichToPopulate === "add_all") {
    const originalTopicIds = originalClause.topics.map(topic => topic.topic_id);
    const currentTopicIds = clause.topics.map(topic => topic.topic_id);

    const presentInOriginalButAbsentInCurrent = originalTopicIds.filter(
      originalTopicId =>
        !currentTopicIds.find(
          curentTopicId => curentTopicId === originalTopicId,
        ),
    );
    const addedTopics = presentInOriginalButAbsentInCurrent.map(topicId =>
      originalClause.topics.find(topic => topic.topic_id === topicId),
    );

    const finalTopics = [...clause.topics, ...addedTopics];
    if (action === "topic_remove") {
      return finalTopics.filter(
        topic =>
          !actionData.find(
            actionDataTopicId => actionDataTopicId === topic.topic_id,
          ),
      );
    }
    return finalTopics;
  }

  // whichToPopulate === "current" logic
  let topics = [];
  if (action === "topic_add") {
    const addedTopics = originalClause.topics.filter(topic =>
      actionData.find(
        actionDataTopicId => actionDataTopicId === topic.topic_id,
      ),
    );
    topics = [...clause.topics, ...addedTopics];
  } else {
    topics = clause.topics.filter(
      topic =>
        !actionData.find(
          actionDataTopicId => actionDataTopicId === topic.topic_id,
        ),
    );
  }
  return _.uniqBy(topics, topic => topic.topic_id);
}

function areTopicsSame(existingTopics = [], proposedTopics = []) {
  const existingIds = existingTopics.map(topic => topic.topic_id);
  const proposedIds = proposedTopics.map(topic => topic.topic_id);
  return (
    _.intersection(existingIds, proposedIds).length === proposedIds.length &&
    existingIds.length === proposedIds.length
  );
}

export default ClauseListItem;
