import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import {Link} from "react-router";

import "./style.css";

import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import MoreIcon from "material-ui/svg-icons/navigation/more-vert";
import IconButton from "material-ui/IconButton";
import FilterList from "material-ui/svg-icons/content/filter-list";
import {Toolbar, ToolbarGroup, ToolbarTitle} from "material-ui/Toolbar";
import RaisedButton from "material-ui/RaisedButton";
import SelectField from "material-ui/SelectField";
import * as colors from "material-ui/styles/colors";

import IssuesetInfoWidget from "common_components/issueset_info_widget";
import ToolbarCheckbox from "common_components/toolbar/checkbox";
import ToolbarDropdown from "common_components/toolbar/dropdown";
import ToolbarDropdownItem from "common_components/toolbar/dropdown/item";
import ListFilter from "common_components/list_filter";
import {Table, Tr, Th, Td} from "common_components/table";

import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import CreateIssueDialog from "./create_issue_dialog";
import BulkEditorDialog from "./bulk_issue_editor_dialog";
import DuplicateIssueDialog from "./duplicate_issue_dialog";

import localStorage from "utils/local_storage";
import getListFilterRegexes from "utils/get_list_filter_regexes";
import Issues from "common/plugins/issues";
import issuesetUtils from "common/utils/issues/issueset_utils";
import calculateIssueOverrides from "utils/issues/calculate_issue_overrides";
import issueClasses from "modules/documents/constants/issue_classes";

const showTopPanelCheckboxStateToLocalStorageMap = {
  issueListShowAllIssuesets: "showAllIssuesets",
  issueListShowArchivedIssues: "showArchivedIssues",
  issueListShowArchivedIssuesets: "showArchivedIssuesets",
  issueListShowClientIssuesets: "showClientIssuesets",
};

const filterTypes = [
  {title: "All Types", useAllFields: true, isDefault: true},
  {title: "Name", fieldName: "name"},
  {title: "Display Name", fieldName: "display_name"},
  {title: "ID", fieldName: "id"},
  {title: "Type", fieldName: "issue_type"},
  {title: "Condition", fieldName: "description"},
];

const styles = {
  inner: {
    padding: "0em 1em",
  },
  addButton: {
    position: "relative",
    top: "-2px",
    marginLeft: "1rem",
  },
};

class IssueList extends React.Component {
  constructor(props) {
    super(props);
    const filterTypeTitle = localStorage.getItem("issueFilterTypeTitle");
    const issuesetFilteredItemsIds = JSON.parse(
      localStorage.getItem(`filteredIssuesetIds_${props.organisationId}`),
    );

    this.state = {
      addedIssuesIds: {},
      filter: localStorage.getItem("issueFilterText") || "",
      filteredIssues: [],
      filterTypeTitle,
      selectedIssueIds: [],
      shownDialogueName: "",
      issuesetFilteredItemsIds: issuesetFilteredItemsIds
        ? issuesetFilteredItemsIds
        : [],
      showAllIssuesets: Boolean(
        localStorage.getItem("issueListShowAllIssuesets") === "true",
      ),
      showArchivedIssues: Boolean(
        localStorage.getItem("issueListShowArchivedIssues") === "true",
      ),
      showArchivedIssuesets: Boolean(
        localStorage.getItem("issueListShowArchivedIssuesets") === "true",
      ),
      showClientIssuesets: Boolean(
        localStorage.getItem("issueListShowClientIssuesets") === "true",
      ),
      displayedIssuesetId: null,
    };
  }

  componentDidMount() {
    this.applyStateFilterText();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(prevProps.issues, this.props.issues) ||
      this.state.displayedIssuesetId !== prevState.displayedIssuesetId
    ) {
      this.applyStateFilterText(true);
    }
    const {
      issuesetFilteredItemsIds: currentIssuesetFilteredItemsIds,
      displayedIssuesetId,
    } = this.state;
    const {issuesetFilteredItemsIds: prevIssuesetFilteredItemsIds} = prevState;
    if (
      !_.isEqual(
        currentIssuesetFilteredItemsIds,
        prevIssuesetFilteredItemsIds,
      ) &&
      displayedIssuesetId &&
      currentIssuesetFilteredItemsIds.length > 0 &&
      !currentIssuesetFilteredItemsIds.includes(displayedIssuesetId)
    ) {
      return this.setState(() => ({displayedIssuesetId: null}));
    }
  }

  dismissDialog = () => this.setState(() => ({shownDialogueName: ""}));
  showCreateIssueDialog = () =>
    this.setState(() => ({shownDialogueName: "create_issue"}));
  showBulkEditorDialog = () =>
    this.setState(() => ({shownDialogueName: "bulk_edit"}));
  showDuplicateDialog = () =>
    this.setState(() => ({shownDialogueName: "duplicate"}));

  render() {
    const {selectedIssueIds, showClientIssuesets} = this.state;
    let {issuesetFilteredItemsIds} = this.state;
    let issuesetsById = issuesetUtils.getIssuesetsById(
      this.props.contractTypesById,
    );
    if (!showClientIssuesets) {
      issuesetsById = filterOutClientIssuesets(issuesetsById);
      issuesetFilteredItemsIds = issuesetFilteredItemsIds.filter(id =>
        _.has(issuesetsById, id),
      );
    }

    const listItems = this.renderIssueListItems(issuesetsById);
    const issuesetsByIdWithProperNames = _.mapObject(
      issuesetsById,
      constructIssuesetItemWithProperName,
    );
    return (
      <div className="app-page-scroll">
        <Toolbar className="app-toolbar">
          <ToolbarGroup key={0}>
            <ToolbarTitle style={{paddingRight: "56px"}} text="Issues" />
            <ToolbarDropdown
              leftIcon={<FilterList />}
              labelText="Issueset"
              values={issuesetFilteredItemsIds.map(id => id.toString())}
              showSelectAndUnselectAllItems={true}
              showSelectedItemsList={true}
              showFilterTextField={true}
              itemHeaderStyle={{
                paddingLeft: 15,
                paddingRight: 15,
              }}
              handleChange={values =>
                this.onIssuesetFilterConfirm(
                  values.map(value => parseInt(value, 10)),
                )
              }
            >
              {_.map(_.sortBy(issuesetsByIdWithProperNames, "name"), item => (
                <ToolbarDropdownItem
                  key={item.id}
                  value={item.id.toString()}
                  label={item.name}
                  withCheckbox={true}
                  labelStyle={{height: 22}}
                />
              ))}
            </ToolbarDropdown>
            {this.renderFilterCheckboxes()}
            {this.renderDisplayedIssuesetSelector(
              issuesetFilteredItemsIds,
              Object.values(issuesetsByIdWithProperNames),
              issuesetsById,
            )}
          </ToolbarGroup>
          <ToolbarGroup key={1}>
            <ListFilter
              filterString={this.state.filter}
              onFilterStringChange={this.setFilterText}
              itemsCount={this.props.issues.length}
              matchesCount={
                (this.state.filteredIssues || this.props.issues).length
              }
              filterTypes={filterTypes}
              filterTypeTitle={this.getFilterType().title}
              onFilterTypeChange={this.onFilterTypeChange}
            />
            <RaisedButton
              secondary={true}
              onClick={this.showBulkEditorDialog}
              disabled={selectedIssueIds.length === 0}
            >
              Bulk Edit
            </RaisedButton>

            <FloatingActionButton
              className="add"
              mini={true}
              onClick={this.showCreateIssueDialog}
              style={styles.addButton}
            >
              <ContentAdd />
            </FloatingActionButton>
            <IconMenu
              iconButtonElement={
                <IconButton>
                  <MoreIcon />
                </IconButton>
              }
              iconStyle={{padding: 0}}
              style={{width: "2rem", marginLeft: "0.5rem"}}
            >
              <MenuItem
                disabled={!selectedIssueIds.length}
                onClick={this.showDuplicateDialog}
              >
                Duplicate Selected Issues
              </MenuItem>
              <MenuItem onClick={this.updateIssuesDisplayLogic}>
                {`Update ${
                  selectedIssueIds.length === 0 ? "ALL" : "selected"
                } issues display logic`}
              </MenuItem>
            </IconMenu>
          </ToolbarGroup>
        </Toolbar>
        {this.renderCreateIssueDialog()}
        {this.renderBulkEditorDialog()}
        {this.renderDuplicateDialog()}
        <div className="app-body-scroll" style={styles.inner}>
          <Table
            sortColumnIndex={1}
            isSelectable={true}
            onSelectedRowsChange={selectedRows => {
              this.setState({selectedIssueIds: selectedRows});
            }}
            useLoadMoreButtons={true}
            loadMoreButtonText="Load more issues"
            loadAllButtonText="Load all issues"
            hasStickyHeader={true}
          >
            <thead>
              <tr style={{height: "2rem"}}>
                <Th
                  title="Is Hot"
                  style={{width: "2em"}}
                  containerStyle={{justifyContent: "center"}}
                />
                <Th
                  style={{width: "4em"}}
                  containerStyle={{justifyContent: "center"}}
                >
                  Id
                </Th>
                <Th
                  style={{width: "6em"}}
                  containerStyle={{justifyContent: "center"}}
                >
                  Master Id
                </Th>
                <Th style={{width: "20em"}}>Name</Th>
                <Th
                  style={{width: "8em"}}
                  containerStyle={{justifyContent: "center"}}
                >
                  Display Name
                </Th>
                <Th
                  style={{width: "4em"}}
                  containerStyle={{justifyContent: "center"}}
                >
                  Class
                </Th>
                <Th style={{width: "14em"}}>Type</Th>
                <Th style={{width: "30em"}}>Condition</Th>
                <Th
                  style={{width: "5em"}}
                  containerStyle={{justifyContent: "center"}}
                >
                  Order
                </Th>
                <Th style={{width: "12em"}}>Contract Types</Th>
                <Th
                  style={{width: "3em"}}
                  containerStyle={{justifyContent: "center"}}
                >
                  Unconfirmed
                </Th>
                <Th
                  style={{width: "3em"}}
                  containerStyle={{justifyContent: "center"}}
                >
                  TP
                </Th>
                <Th
                  style={{width: "3em"}}
                  containerStyle={{justifyContent: "center"}}
                >
                  FN
                </Th>
                <Th
                  style={{width: "3em"}}
                  containerStyle={{justifyContent: "center"}}
                >
                  FP
                </Th>
                <Th
                  style={{width: "3em"}}
                  containerStyle={{justifyContent: "center"}}
                >
                  Incorrect
                </Th>
              </tr>
            </thead>
            <tbody>{listItems}</tbody>
          </Table>
        </div>
      </div>
    );
  }

  renderFilterCheckboxes = () => {
    const checkboxesDataArray = [
      {
        name: "issueListShowAllIssuesets",
        label: "Show All Issuesets",
        checked: this.state.showAllIssuesets,
      },
      {
        name: "issueListShowArchivedIssues",
        label: "Show Archived Issues",
        checked: this.state.showArchivedIssues,
      },
      {
        name: "issueListShowArchivedIssuesets",
        label: "Show Archived Issuesets",
        checked: this.state.showArchivedIssuesets,
      },
      {
        name: "issueListShowClientIssuesets",
        label: "Show Client Issuesets",
        checked: this.state.showClientIssuesets,
      },
    ];
    return checkboxesDataArray.map(item => (
      <ToolbarCheckbox
        key={item.name}
        checked={item.checked}
        label={item.label}
        onCheck={this.onTriggerShowCheckbox(item.name)}
      />
    ));
  };

  renderDisplayedIssuesetSelector = (
    issuesetFilteredItemsIds,
    issuesetsWithProperNames,
    issuesetsById,
  ) => {
    if (issuesetFilteredItemsIds && issuesetFilteredItemsIds.length === 1) {
      return null;
    }
    const selectorItems =
      !issuesetFilteredItemsIds || issuesetFilteredItemsIds.length === 0
        ? issuesetsWithProperNames
        : issuesetFilteredItemsIds.map(issuesetId =>
            constructIssuesetItemWithProperName(issuesetsById[issuesetId]),
          );
    return (
      <SelectField
        hintText="Display Issueset"
        maxHeight={300}
        value={this.state.displayedIssuesetId}
        onChange={this.onDisplayIssuesetChange}
        style={{width: "16rem"}}
      >
        <MenuItem key="null-value-item" value={null} primaryText="" />
        {selectorItems.map(item => (
          <MenuItem
            key={`issueset-${item.id}`}
            value={item.id}
            primaryText={item.name}
          />
        ))}
      </SelectField>
    );
  };

  onDisplayIssuesetChange = (event, index, value) =>
    this.setState(() => ({displayedIssuesetId: value}));

  getFilterType = () => {
    if (this.state.filterTypeTitle) {
      for (const filterType of filterTypes) {
        if (filterType.title === this.state.filterTypeTitle) {
          return filterType;
        }
      }
    }
    return this.getDefaultFilterType();
  };

  getDefaultFilterType = () => {
    return filterTypes.find(filterType => filterType.isDefault);
  };

  onFilterTypeChange = value => {
    if (value !== this.getDefaultFilterType().title) {
      localStorage.setItem("issueFilterTypeTitle", value);
    } else {
      localStorage.removeItem("issueFilterTypeTitle");
    }
    this.setState(() => ({filterTypeTitle: value}), this.applyStateFilterText);
  };

  renderCreateIssueDialog = () => {
    if (this.state.shownDialogueName !== "create_issue") {
      return null;
    }
    return (
      <CreateIssueDialog
        onDismiss={this.dismissDialog}
        topics={this.props.topics}
        topicsById={this.props.topicsById}
        contractTypesById={this.props.contractTypesById}
        addIssue={this.addIssue}
        issues={this.props.issues}
        organisationId={this.props.organisationId}
        roles={this.props.roles}
      />
    );
  };

  renderBulkEditorDialog = () => {
    if (this.state.shownDialogueName !== "bulk_edit") {
      return null;
    }
    return (
      <BulkEditorDialog
        onDismiss={this.dismissDialog}
        issues={this.getSelectedIssues()}
        contractTypesById={this.props.contractTypesById}
        updateIssues={this.props.updateIssues}
        organisationId={this.props.organisationId}
      />
    );
  };

  renderDuplicateDialog = () => {
    if (this.state.shownDialogueName !== "duplicate") {
      return null;
    }
    return (
      <DuplicateIssueDialog
        onDismiss={this.dismissDialog}
        issues={this.getSelectedIssues()}
        contractTypesById={this.props.contractTypesById}
        addIssue={this.addIssue}
        issuesetFilteredItemsIds={this.state.issuesetFilteredItemsIds}
        onIssuesetFilterConfirm={this.onIssuesetFilterConfirm}
      />
    );
  };

  renderIssueListItems(issuesetsById) {
    const {filteredIssues: issues, displayedIssuesetId} = this.state;
    const currentIssuesetItem = issuesetsById[displayedIssuesetId];
    return issues.map(_issue => {
      const issue = {
        ...calculateIssueOverrides(_issue, currentIssuesetItem, true),
        description: this.getIssueDescription(_issue),
        contract_types: this.getFilteredContractTypes(_issue, issuesetsById),
      };
      const issueClass = issueClasses[issue.issue_class_id] || {
        name: "Unknown",
      };
      const cellStyles = {
        start: {
          justifyContent: "flex-start",
        },
        center: {
          alignItems: "center",
        },
        startCenter: {
          justifyContent: "flex-start",
          alignItems: "center",
        },
      };
      const isInHotIssueset = issue.contract_types.find(ct =>
        ct.issuesets.find(is => is.is_hot),
      );

      const isInStarIssueset = issue.contract_types.find(ct =>
        ct.issuesets.find(is => is.is_star),
      );
      return (
        <Tr
          key={issue.id}
          selectId={issue.id}
          style={{
            backgroundColor: issue.description ? "unset" : colors.red100,
            ...(issue.is_archived && {color: colors.grey400}),
          }}
        >
          <Td containerStyle={{...cellStyles.center, flexDirection: "row"}}>
            <IssuesetInfoWidget
              isInStarIssueset={isInStarIssueset}
              isHot={isInHotIssueset}
              isStarIssue={issue.is_star}
              updateIssueIsStar={this.updateIssueIsStar(
                issue.id,
                issue.last_edited,
                issue.is_star,
              )}
            />
          </Td>
          <Td containerStyle={cellStyles.center}>{issue.id}</Td>
          <Td
            containerStyle={{...cellStyles.center, fontFamily: "monospace"}}
            title={issue.master_id}
            sortText={issue.master_id}
          >
            {issue.master_id.substring(0, 6)}…
          </Td>
          <Td
            containerStyle={cellStyles.start}
            handleChildren={(children, thisTd) => {
              const {organisationId} = this.props;
              const pathname = `/organisation/${organisationId}/issue/${
                issue.id
              }`;
              return (
                <Link
                  to={{pathname}}
                  style={{
                    color: thisTd.props.isRowHovered
                      ? colors.blue500
                      : issue.is_archived ? colors.grey400 : "#000",
                    textDecoration: thisTd.props.isRowHovered
                      ? "underline"
                      : "none",
                  }}
                >
                  {children}
                </Link>
              );
            }}
          >
            {issue.name}
          </Td>
          <Td containerStyle={cellStyles.startCenter}>{issue.display_name}</Td>
          <Td containerStyle={cellStyles.startCenter}>{issueClass.name}</Td>
          <Td
            containerStyle={{
              ...cellStyles.startCenter,
              textTransform: "capitalize",
            }}
          >
            {issue.issue_type.toLowerCase().replace(/_/g, " ")}
          </Td>
          <Td containerStyle={cellStyles.start}>
            {issue.description ? issue.description : "Problem with issue logic"}
          </Td>
          <Td containerStyle={cellStyles.startCenter}>
            {issue.issue_order || "-"}
          </Td>
          <Td containerStyle={cellStyles.start}>
            {this.renderItemContractTypes(
              issue.contract_types,
              this.props.contractTypesById,
            )}
          </Td>
          <Td containerStyle={cellStyles.startCenter}>
            {Number(issue.unconfirmed)}
          </Td>
          <Td containerStyle={cellStyles.startCenter}>{Number(issue.tp)}</Td>
          <Td containerStyle={cellStyles.startCenter}>{Number(issue.fn)}</Td>
          <Td containerStyle={cellStyles.startCenter}>{Number(issue.fp)}</Td>
          <Td containerStyle={cellStyles.startCenter}>
            {Number(issue.incorrect)}
          </Td>
        </Tr>
      );
    });
  }

  renderItemContractTypes = (contractTypes, contractTypesById) => {
    const {showArchivedIssuesets} = this.state;
    return contractTypes.map((issueContractType, index) => {
      const mainContractType =
        contractTypesById[issueContractType.contract_type_id];
      const mainIssuesets = _.chain(issueContractType.issuesets)
        .map(issueIssueset =>
          mainContractType.issuesets.find(({id}) => id === issueIssueset.id),
        )
        .filter(issueset => showArchivedIssuesets || !issueset.is_archived)
        .groupBy("master_id")
        .map(items =>
          items.sort((a, b) => {
            if (a.is_duplicate_on_master === b.is_duplicate_on_master) {
              return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            }
            return !a.is_duplicate_on_master ? -1 : 1;
          }),
        )
        .flatten()
        .value();
      return (
        <div key={index} style={{fontSize: "12px"}}>
          <div style={{fontWeight: 600}}>{`<${mainContractType.name}>`}</div>
          {mainIssuesets.map((mainIssueset, isIndex) => {
            let text = mainIssueset.name;
            if (mainIssueset.is_duplicate_on_master) {
              text += ` - <${mainIssueset.remote_client_name}>`;
            }
            return (
              <div
                key={isIndex}
                style={{
                  paddingLeft: "1.5rem",
                  textIndent: "-1rem",
                  ...(mainIssueset.is_archived ? {fontStyle: "italic"} : {}),
                }}
              >
                {text}
              </div>
            );
          })}
        </div>
      );
    });
  };

  getIssueDescription = issue => {
    const issuePlugin = Issues[issue.issue_type.toLowerCase()];
    try {
      const result = issuePlugin.issueDescription(issue, this.props.topics, {
        roles: this.props.roles,
      });
      return result;
    } catch {
      return null;
    }
  };

  getFilteredContractTypes = (issue, issuesetsById) => {
    const {
      issuesetFilteredItemsIds,
      showAllIssuesets,
      showClientIssuesets,
    } = this.state;
    const showAll =
      !issuesetFilteredItemsIds ||
      issuesetFilteredItemsIds.length === 0 ||
      showAllIssuesets;
    const contractTypesToSHow = showAll
      ? issue.contract_types
      : issuesetUtils.filterContractTypesByIssuesetIds(
          issue.contract_types,
          issuesetFilteredItemsIds,
        );
    if (!showClientIssuesets) {
      const contractTypesWithoutClientIssuesets = issuesetUtils.filterOutClientIssuesetFromContractTypes(
        contractTypesToSHow,
        issuesetsById,
      );
      return contractTypesWithoutClientIssuesets;
    }
    return contractTypesToSHow;
  };

  getSelectedIssues() {
    return this.state.selectedIssueIds.map(issueId =>
      this.props.issues.find(issue => issue.id === issueId),
    );
  }

  setFilterText = filterText => {
    localStorage.setItem("issueFilterText", filterText);
    this.applyFilterText(filterText);
  };

  applyStateFilterText = dontClearAddedIssuesIds => {
    this.applyFilterText(this.state.filter, dontClearAddedIssuesIds);
  };

  applyFilterText = (filterText, dontClearAddedIssuesIds) => {
    const addedIssuesIdsNotInFilteredIssues = {...this.state.addedIssuesIds};
    const filteredIssues = this.getFilteredIssues(filterText);
    const updates = {filter: filterText, filteredIssues};
    if (
      dontClearAddedIssuesIds &&
      Object.keys(addedIssuesIdsNotInFilteredIssues).length > 0
    ) {
      for (const filteredIssue of filteredIssues) {
        if (addedIssuesIdsNotInFilteredIssues[filteredIssue.id]) {
          addedIssuesIdsNotInFilteredIssues[filteredIssue.id];
        }
      }
      if (Object.keys(addedIssuesIdsNotInFilteredIssues).length > 0) {
        const addedIssues = this.props.issues.filter(
          issue => addedIssuesIdsNotInFilteredIssues[issue.id],
        );
        updates.filteredIssues = [...addedIssues, ...filteredIssues];
      }
    } else if (!dontClearAddedIssuesIds) {
      updates.addedIssuesIds = {};
    }
    this.setState(() => updates);
  };

  onTriggerShowCheckbox = checkboxLocalStorageName => () => {
    this.setState(prevState => {
      const stateName =
        showTopPanelCheckboxStateToLocalStorageMap[checkboxLocalStorageName];
      const newCheckboxValue = !prevState[stateName];
      localStorage.setItem(checkboxLocalStorageName, newCheckboxValue);
      return {[stateName]: newCheckboxValue};
    }, this.applyStateFilterText);
  };

  getFilteredIssues = filterText => {
    const {displayedIssuesetId} = this.state;
    const issuesetsById = issuesetUtils.getIssuesetsById(
      this.props.contractTypesById,
    );
    const currentIssuesetItem = issuesetsById[displayedIssuesetId];
    let filtered = this.props.issues
      .map(issue => ({
        ...calculateIssueOverrides(issue, currentIssuesetItem, true),
        className: issueClasses[issue.issue_class_id].name || "Unknown",
      }))
      .filter(this.filterIssue.bind(this, getListFilterRegexes(filterText)));
    if (!this.state.showArchivedIssues) {
      filtered = filtered.filter(issue => issue.is_archived === false);
    }
    return filtered;
  };

  filterIssue(filterTexts, issue) {
    const {issuesetFilteredItemsIds} = this.state;
    if (issuesetFilteredItemsIds.length > 0) {
      let isIssueFilteredOut = true;
      issue.contract_types.forEach(ct => {
        ct.issuesets.forEach(is => {
          if (issuesetFilteredItemsIds.includes(is.id)) {
            isIssueFilteredOut = false;
          }
        });
      });
      if (isIssueFilteredOut) {
        return false;
      }
    }
    if (filterTexts) {
      const filterType = this.getFilterType();
      return filterTexts.every(filterText => {
        const fieldNames = filterType.useAllFields
          ? filterTypes
              .filter(({fieldName}) => fieldName)
              .map(({fieldName}) => fieldName)
          : [filterType.fieldName];
        const fieldValues = fieldNames.map(fieldName => {
          let fieldValue = this.getIssueFieldValue(issue, fieldName);
          if (_.isNumber(fieldValue)) {
            fieldValue = fieldValue.toString();
          }
          if (_.isString(fieldValue)) {
            fieldValue = fieldValue.toLowerCase();
          }
          return fieldValue;
        });
        for (const fieldValue of fieldValues) {
          if (filterText.test(fieldValue)) {
            return true;
          }
        }
        return false;
      });
    }
    return true;
  }

  getIssueFieldValue = (issue, fieldName) => {
    switch (fieldName) {
      case "description":
        return this.getIssueDescription(issue) || "";
      default:
        return issue[fieldName];
    }
  };

  getFilterLabelText(matches) {
    if (matches === this.props.issues.length) {
      return `${matches} issues in total`;
    }
    return `${matches} match${matches !== 1 ? "es" : ""}`;
  }

  onIssuesetFilterConfirm = issuesetFilteredItemsIds => {
    this.setState(
      () => ({issuesetFilteredItemsIds}),
      () => {
        localStorage.setItem(
          `filteredIssuesetIds_${this.props.organisationId}`,
          JSON.stringify(issuesetFilteredItemsIds),
        );
        this.applyStateFilterText();
      },
    );
  };

  updateIssuesDisplayLogic = () => {
    const {selectedIssueIds = []} = this.state;
    const issueIds =
      selectedIssueIds.length > 0
        ? selectedIssueIds
        : this.props.issues.map(issue => issue.id);
    this.props.updateDisplayLogic(issueIds);
  };

  updateIssueIsStar = (issueId, issueLastEdited, issueIsStar) => () => {
    this.props.updateIssue(issueId, issueLastEdited, {is_star: !issueIsStar});
  };

  addIssue = async newIssue => {
    const responseData = await this.props.addIssue(newIssue);
    if (
      responseData &&
      responseData.value &&
      Array.isArray(responseData.value)
    ) {
      this.setState(() => ({
        addedIssuesIds: responseData.value.reduce((result, issue) => {
          result[issue.id] = true;
          return result;
        }, {}),
      }));
    }
  };
}

function filterOutClientIssuesets(issuesetsById) {
  const filteredIssuesetsById = {};
  Object.keys(issuesetsById).forEach(issuesetId => {
    const issueset = issuesetsById[issuesetId];
    if (!issueset.remote_client_id) {
      filteredIssuesetsById[issuesetId] = issueset;
    }
  });
  return filteredIssuesetsById;
}

function constructIssuesetItemWithProperName(is) {
  if (!is) {
    return {};
  }
  return {
    id: is.id,
    name: `${is.contract_type_name} - ${is.name}${
      is.remote_client_name ? ` - ${is.remote_client_name}` : ""
    }`,
  };
}

IssueList.propTypes = {
  organisationId: PropTypes.number.isRequired,
  issues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default IssueList;
export {IssueList};
