import React from "react";
import _ from "underscore";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import Permissioner from "utils/permissioner";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import localStorage from "utils/local_storage";

import CircularProgress from "@material-ui/core/CircularProgress";

import requestor from "requestor";

import TopicAnalysisComponent from "../components/topic_analysis";

import updateTopicAction from "modules/documents/actions/topic_update";
import updateTopicClassifierAction from "modules/documents/actions/topic_classifier_update";
import createTopicClassifierAction from "modules/documents/actions/topic_classifier_create";
import createClassifierRunBatchAction from "modules/documents/actions/classifier_run_batch_create";
import createRegexClassifierRunBatchAction from "modules/documents/actions/classifier_run_batch_create_regex";
import lockTopicClassifierAction from "modules/documents/actions/topic_classifier_lock";
import redeployTopicClassifierAction from "modules/documents/actions/topic_classifier_redeploy";
import topicUsesSetEmptyAction from "modules/documents/actions/topic_uses_set_empty";
import documentClauseTopicAdd from "modules/documents/actions/document_clause_topic_add";
import documentClauseTopicConfirm from "modules/documents/actions/document_clause_topic_confirm";
import documentClauseTopicRemove from "modules/documents/actions/document_clause_topic_remove";
import documentClauseUnconfirmedTopicRemove from "modules/documents/actions/document_clause_unconfirmed_topics_remove";
import documentClauseTopicsReorder from "modules/documents/actions/document_clause_topics_reorder";
import documentsClausepartUpdate from "../../../modules/documents/actions/document_clausepart_update";
import clausesTopicAdd from "modules/documents/actions/clauses_topic_add";
import clausesTopicRemove from "modules/documents/actions/clauses_topic_remove";
import clausesTopicConfirm from "modules/documents/actions/clauses_topic_confirm";
import logsFetchAction from "modules/logs/actions/logs_fetch";
import logsClearAction from "modules/logs/actions/logs_clear";
import logPageLoadTime from "modules/logs/actions/page_load_time_log";
import topicFetchAction from "modules/documents/actions/topic_fetch";
import topicStatsFetchAction from "modules/documents/actions/topic_stats_fetch";
import topicParameterValuesUpdate from "modules/documents/actions/topicparameter_actual_values_update";
import documentsClausepartsTopicsUpdate from "modules/documents/actions/documents_clauseparts_topics_update";
import topicRegexClassifierStatsFetch from "modules/documents/actions/topic_regex_classifier_stats_fetch";
import topicRegexClassifierStatsClear from "modules/documents/actions/topic_regex_classifier_stats_clear";
import topicClassifierJobInfoFetchAction from "modules/documents/actions/topic_classifier_job_info_fetch";
import topicClassifierJobLogsFetchAction from "modules/documents/actions/topic_classifier_job_logs_fetch";
import clauseTopicLogsFetchAction from "modules/documents/actions/clause_topic_logs_fetch";

import getHotProjectsParams from "utils/get_hot_projects_params";
import byId from "common/utils/by_id";

function getSelectedContractTypes(organisationId) {
  const usagesFilterRaw = localStorage.getItem(
    `filteredContractTypeIds_${organisationId}`,
  );
  const usagesFilter = usagesFilterRaw ? JSON.parse(usagesFilterRaw) : [];
  return usagesFilter;
}

class TopicAnalysisContainer extends React.Component {
  componentDidMount() {
    const settingsChanged = localStorage.getItem("TopicAnalysisRegexChange");
    if (settingsChanged && settingsChanged !== "0") {
      localStorage.setItem("TopicAnalysisRegexChange", "0");
      const {pathname} = this.props.location;
      const separator = pathname.indexOf("?") === -1 ? "?" : "&";
      this.props.push(`${pathname}${separator}fix=true`);
    }
    this.startTime = new Date().valueOf();
    this.fetchTopic();
  }

  fetchTopic() {
    const {organisationId, topicId} = this.props.params;
    const params = {
      usages_fetch_limit: 0,
      omit_param_stats: true,
      ...getHotProjectsParams(),
      contract_types: JSON.stringify(getSelectedContractTypes(organisationId)),
    };
    this.initialFetchParams = params;
    this.props.topicFetch(organisationId, topicId, params);
  }

  render() {
    if (!this.shouldRenderContainer()) {
      return (
        <CircularProgress
          style={{
            position: "absolute",
            top: "45%",
            left: "50%",
          }}
        />
      );
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }

    const result = this.renderComponent();
    if (!this.endTime) {
      this.endTime = new Date().valueOf();
      const {params} = this.props;
      this.props.logPageLoadTime(
        params.organisationId,
        "topic_analysis",
        this.startTime,
        this.endTime,
        {
          topic_id: params.topicId,
          ...this.initialFetchParams,
        },
      );
    }
    return result;
  }

  hasEnterPermission() {
    return (
      this.permissioner.isAdmin() ||
      this.permissioner.hasPermission("view-topic-detail")
    );
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.topic,
      this.props.classifiers,
      this.props.trainingModes,
      this.props.topics,
      this.props.topicsById,
      this.props.topicCategories,
      this.props.topicTags,
      this.props.templateModules,
      this.props.user,
      this.props.contractTypes,
      this.props.projects,
      this.props.roles,
    ]);
  }

  renderComponent() {
    setTitle(`Topic ${this.props.topic.name}`);
    const categoriesById = byId(this.props.topicCategories);
    const contractTypesById = byId(this.props.contractTypes);
    return (
      <TopicAnalysisComponent
        {...this.props}
        trainingModes={[{id: -1, name: "default"}, ...this.props.trainingModes]}
        topicCategoriesById={categoriesById}
        organisationId={this.props.params.organisationId}
        onTopicClassifierUpdated={this.updateTopicClassifier}
        onTopicClassifierCreated={this.createTopicClassifier}
        rerunClassifier={this.rerunClassifier}
        onLockSettings={this.lockSettings}
        onRedeployClassifier={this.redeployClassifier}
        onUsageRemoved={this.removeUsage}
        onTopicConfirmed={this.topicConfirmed}
        onTopicUpdated={this.onTopicUpdated}
        onExistingTopicAdded={this.existingTopicAdded}
        onNewTopicAdded={this.newTopicAdded}
        onTopicRemoved={this.topicRemoved}
        onUnconfirmedTopicsRemoved={this.unconfirmedTopicsRemoved}
        onTopicsReordered={this.topicsReordered}
        contractTypesById={contractTypesById}
        addTopicToClauses={this.addTopicToClauses}
        removeTopicFromClauses={this.removeTopicFromClauses}
        confirmTopicInClauses={this.confirmTopicInClauses}
        onTopicparameterValuesUpdate={this.onTopicparameterValuesUpdate}
        fetchTopicAnalysisLogs={this.fetchTopicAnalysisLogs}
        getJobInformation={this.getJobInformation}
        getJobLogs={this.getJobLogs}
        setRegexWasChanged={this.setRegexWasChanged}
        clausepartsTopicsUpdated={this.clausepartsTopicsUpdated}
        getTopicRegexClassifierStats={this.getTopicRegexClassifierStats}
        testClause={this.testClause}
        getClauseLogs={this.getClauseLogs}
        updateClausepartIsBadlyParsed={this.updateClausepartIsBadlyParsed}
      />
    );
  }

  lockSettings = async (classifierId, configurationId, lastEdited) => {
    const {organisationId, topicId} = this.props.params;
    await this.props.lockTopicClassifierAction(
      organisationId,
      topicId,
      classifierId,
      configurationId,
      lastEdited,
    );
  };
  redeployClassifier = async (
    classifierId,
    configurationId,
    topicTrainingNodeId,
  ) => {
    const {organisationId, topicId} = this.props.params;
    await this.props.redeployTopicClassifierAction(
      organisationId,
      topicId,
      classifierId,
      configurationId,
      topicTrainingNodeId,
    );
  };

  createTopicClassifier = classifierId => {
    const {organisationId, topicId} = this.props.params;
    return this.props.createTopicClassifierAction(
      organisationId,
      topicId,
      classifierId,
    );
  };

  removeUsage = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
  ) => {
    const {organisationId, topicId} = this.props.params;
    this.props.documentClauseTopicRemove(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clausepartLastEdited,
      parseInt(topicId, 10),
    );
  };

  existingTopicAdded = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicId,
  ) =>
    this.props.documentClauseTopicAdd(
      this.props.params.organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      {id: topicId},
    );

  topicConfirmed = async (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicId,
  ) =>
    await this.props.documentClauseTopicConfirm(
      this.props.params.organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topicId,
    );

  rerunClassifier = (
    selectedRegex,
    trainingModeId,
    datasetModeId,
    configurationId,
    configurationName,
    topicTrainingNodeId,
    selectedContractTypeIds,
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
  ) => {
    const {organisationId, topicId} = this.props.params;
    if (configurationName === "regex" && !clausepartId) {
      return this.props.createRegexClassifierRunBatchAction(
        organisationId,
        trainingModeId,
        datasetModeId,
        topicId,
        configurationId,
        topicTrainingNodeId,
        projectId &&
          clausepartId && [
            {
              project_id: projectId,
              document_id: documentId,
              section_id: sectionId,
              clause_id: clauseId,
              clausepart_id: clausepartId,
            },
          ],
        selectedRegex && selectedRegex.length > 0 ? selectedRegex : null,
        {
          ...getHotProjectsParams(),
          contract_type_ids: JSON.stringify(selectedContractTypeIds),
        },
      );
    }
    return this.props.createClassifierRunBatchAction(
      organisationId,
      trainingModeId,
      datasetModeId,
      topicId,
      configurationId,
      topicTrainingNodeId,
      projectId &&
        clausepartId && [
          {
            project_id: projectId,
            document_id: documentId,
            section_id: sectionId,
            clause_id: clauseId,
            clausepart_id: clausepartId,
          },
        ],
      getHotProjectsParams(),
      selectedContractTypeIds,
    );
  };

  addTopicToClauses = (clausepartIds, topicId) => {
    const {organisationId, topicId: defaultTopicId} = this.props.params;
    return this.props.clausesTopicAdd(
      organisationId,
      topicId ?? defaultTopicId,
      {clausepartIds},
    );
  };

  removeTopicFromClauses = clausepartIds => {
    const {organisationId, topicId} = this.props.params;
    return this.props.clausesTopicRemove(organisationId, topicId, {
      clausepartIds,
    });
  };

  confirmTopicInClauses = clausepartIds => {
    const {organisationId, topicId} = this.props.params;
    return this.props.clausesTopicConfirm(organisationId, topicId, {
      clausepartIds,
    });
  };
  updateTopicClassifier = async (classifier, data) => {
    const {organisationId, topicId} = this.props.params;
    const topicIdInt = parseInt(topicId, 10);
    const newProp = this.props.classifiers.find(
      c => c.configuration_id === classifier.configuration_id,
    );
    const baseClassifier = this.props.classifiers.find(c => {
      if (newProp.id === 1) {
        return newProp.configuration_id === c.configuration_id;
      }
      return c.id === newProp.id;
    });
    localStorage.setItem("TopicAnalysisRegexChange", "0");
    await this.props.updateTopicClassifierAction(
      organisationId,
      topicIdInt,
      classifier.id,
      classifier.configuration_id,
      {...data, last_edited: baseClassifier.last_edited},
    );
  };

  updateClausepartIsBadlyParsed = (
    projectId,
    documentId,
    clauseId,
    id,
    isBadlyParsed,
  ) => {
    const {organisationId} = this.props.params;
    this.props.documentsClausepartUpdate(
      organisationId,
      projectId,
      documentId,
      clauseId,
      id,
      isBadlyParsed,
    );
  };

  setRegexWasChanged = () => {
    localStorage.setItem("TopicAnalysisRegexChange", "1");
  };

  newTopicAdded = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicData,
  ) =>
    this.props.documentClauseTopicAdd(
      this.props.params.organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topicData,
    );

  topicRemoved = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicId,
  ) =>
    this.props.documentClauseTopicRemove(
      this.props.params.organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topicId,
    );

  topicsReordered = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topics,
  ) =>
    this.props.documentClauseTopicsReorder(
      this.props.params.organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topics,
    );

  unconfirmedTopicsRemoved = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
  ) =>
    this.props.documentClauseUnconfirmedTopicRemove(
      this.props.params.organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
    );

  /* eslint-enable no-invalid-this */
  onTopicUpdated = data => {
    const {organisationId, topicId} = this.props.params;
    const topicIdInt = parseInt(topicId, 10);

    this.props.updateTopicAction(organisationId, topicIdInt, {
      ...data,
      last_edited: this.props.topic.last_edited,
    });
  };

  onTopicparameterValuesUpdate = (
    projectId,
    documentId,
    clauseId,
    clausepartId,
    topicId,
    data,
    topicType,
  ) => {
    const {organisationId} = this.props.params;
    this.props.onTopicparameterValuesUpdate(
      organisationId,
      projectId,
      documentId,
      clauseId,
      clausepartId,
      topicId,
      data,
      topicType,
    );
  };

  fetchTopicAnalysisLogs = () => {
    const {organisationId, topicId} = this.props.params;
    this.props.fetchLogs(organisationId, `topic_id=${topicId}&&analysis=true`);
  };

  getJobInformation = (
    classifierName,
    configurationId,
    topicTrainingNodeId,
  ) => {
    const {organisationId, topicId} = this.props.params;
    this.props.fetchJobInformation(
      organisationId,
      topicId,
      classifierName,
      configurationId,
      topicTrainingNodeId,
    );
  };

  getJobLogs = (classifierName, configurationId, topicTrainingNodeId) => {
    const {organisationId, topicId} = this.props.params;
    this.props.fetchJobLogs(
      organisationId,
      topicId,
      classifierName,
      configurationId,
      topicTrainingNodeId,
    );
  };

  getClauseLogs = clausepartId => {
    const {organisationId, topicId} = this.props.params;
    return this.props.getClauseLogs(organisationId, topicId, clausepartId);
  };

  clausepartsTopicsUpdated = data => {
    if (data) {
      const {organisationId} = this.props.params;
      this.props.documentsClausepartsTopicsUpdate(organisationId, data);
    }
  };

  getTopicRegexClassifierStats = (contractTypeIds, regex, regex_type) => {
    const {organisationId, topicId} = this.props.params;
    return this.props.getTopicRegexClassifierStats(
      organisationId,
      topicId,
      {
        ...getHotProjectsParams(),
        contract_types: JSON.stringify(contractTypeIds),
      },
      {regex, regex_type},
    );
  };

  testClause = (configurationId, topicTrainingNodeId, text) => {
    const {organisationId, topicId} = this.props.params;
    return this.props.createClassifierRunBatchAction(
      organisationId,
      -1,
      -1,
      topicId,
      configurationId,
      topicTrainingNodeId,
      text,
    );
  };
}

function select(state, props) {
  return {
    params: _.mapObject(props.params, key => parseInt(key, 10)),
    projects: state.projects,
    topic: state.topic,
    roles: state.roles,
    classifiers: state.classifiers,
    trainingModes: state.trainingModes,
    datasetModes: state.datasetModes,
    topics: state.topics,
    topicsById:
      state.topics && state.topics !== UNINITIALISED
        ? byId(state.topics)
        : UNINITIALISED,
    topicCategories: state.topicCategories,
    topicTags: state.topicTags,
    templateModules: state.templateModules,
    user: state.user,
    contractTypes: state.contract_types,
    logs: state.logs === UNINITIALISED ? null : state.logs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateTopicAction: updateTopicAction(requestor),
        createTopicClassifierAction: createTopicClassifierAction(requestor),
        updateTopicClassifierAction: updateTopicClassifierAction(requestor),
        createClassifierRunBatchAction: createClassifierRunBatchAction(
          requestor,
        ),
        createRegexClassifierRunBatchAction: createRegexClassifierRunBatchAction(
          requestor,
        ),
        lockTopicClassifierAction: lockTopicClassifierAction(requestor),
        redeployTopicClassifierAction: redeployTopicClassifierAction(requestor),
        documentClauseTopicAdd: documentClauseTopicAdd(requestor),
        documentClauseTopicConfirm: documentClauseTopicConfirm(requestor),
        documentClauseTopicRemove: documentClauseTopicRemove(requestor),
        documentClauseUnconfirmedTopicRemove: documentClauseUnconfirmedTopicRemove(
          requestor,
        ),
        documentsClausepartUpdate: documentsClausepartUpdate(requestor),
        documentClauseTopicsReorder: documentClauseTopicsReorder(requestor),
        clausesTopicAdd: clausesTopicAdd(requestor),
        clausesTopicRemove: clausesTopicRemove(requestor),
        clausesTopicConfirm: clausesTopicConfirm(requestor),
        fetchLogs: logsFetchAction(requestor),
        fetchJobInformation: topicClassifierJobInfoFetchAction(requestor),
        fetchJobLogs: topicClassifierJobLogsFetchAction(requestor),
        getClauseLogs: clauseTopicLogsFetchAction(requestor),
        clearLogs: logsClearAction,
        logPageLoadTime: logPageLoadTime(requestor),
        onTopicparameterValuesUpdate: topicParameterValuesUpdate(requestor),
        topicFetch: topicFetchAction(requestor),
        topicStatsFetch: topicStatsFetchAction(requestor),
        documentsClausepartsTopicsUpdate: documentsClausepartsTopicsUpdate(
          requestor,
        ),
        getTopicRegexClassifierStats: topicRegexClassifierStatsFetch(requestor),
        clearTopicRegexClassifierStats: topicRegexClassifierStatsClear,
        setEmptyTopicUses: topicUsesSetEmptyAction,
        push,
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(TopicAnalysisContainer);
export const Component = TopicAnalysisContainer;
