import requestor from "requestor";
import BaseRoute from "utils/base_route";
import TopicAnalysisMatrix from "./containers/topic_analysis_matrix";

import getRouteIds from "utils/get_route_ids";
import topicsScoresFetchAction from "modules/documents/actions/topics_scores_fetch";
import topicsClearAction from "modules/documents/actions/topics_clear";
import classifiersFetchAction from "modules/documents/actions/classifiers_fetch";
import trainingModesFetchAction from "modules/documents/actions/training_modes_fetch";
import datasetModesFetchAction from "modules/documents/actions/dataset_modes_fetch";

import localStorage from "utils/local_storage";

export default class TopicAnalysisMatrixRoute extends BaseRoute {
  constructor(store) {
    super("topic_analysis_matrix", store);
    this.path = "analysis_matrix";
  }
  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, TopicAnalysisMatrix);
  }

  fetchActiveData(state) {
    this.fetchTopics(state);
    this.fetchClassifiers(state);
    this.fetchTrainingModes(state);
    this.fetchDatasetModes(state);
  }

  fetchTopics(state) {
    const {organisationId} = getRouteIds(state.router);
    const filteredContractTypeIds = JSON.parse(
      localStorage.getItem(`filteredContractTypeIds_${organisationId}`),
    );
    this.store.dispatch(
      topicsScoresFetchAction(requestor)(
        organisationId,
        filteredContractTypeIds ? {filteredContractTypeIds} : {},
      ),
    );
  }

  fetchClassifiers(state) {
    const {organisationId} = getRouteIds(state.router);
    this.store.dispatch(classifiersFetchAction(requestor)(organisationId));
  }

  fetchTrainingModes(state) {
    const {organisationId} = getRouteIds(state.router);
    this.store.dispatch(trainingModesFetchAction(requestor)(organisationId));
  }

  fetchDatasetModes(state) {
    const {organisationId} = getRouteIds(state.router);
    this.store.dispatch(datasetModesFetchAction(requestor)(organisationId));
  }

  clearActiveData(state) {
    const {organisationId} = state.params;
    this.store.dispatch(topicsClearAction(organisationId));
  }
}
