import {range} from "lodash";

type Issue = {
  name: string;
};

function getRelatedIssues(issue: Issue, issues: Issue[]) {
  const topMostParent = getTopMostParent(issue, issues);
  return issues.filter(
    ({name}) =>
      name.startsWith(topMostParent) &&
      // We decide here NOT to consider issues as being related to themselves
      name !== issue.name,
  );
}

const removeMutexes = (name: Issue["name"]) =>
  name.match(/(.*?)(\[.*\])?$/)?.[1];

export const getTopMostParent = (
  issue: Issue,
  issues: Issue[],
): string | undefined => {
  // Substrings of the name of @param issue, ordered from shortest to longest
  const substrings: string[] = range(0, issue.name.length + 1).map(i =>
    issue.name.slice(0, i),
  );

  const issuesWithMutexesRemoved = issues
    .map(({name}) => name)
    .map(removeMutexes);

  /**
   * The FIRST (i.e. SHORTEST) substring of @param issue.name present in
   * @constant issuesWithMutexesRemoved is our "top-most" parent.
   */
  return substrings.find(substring =>
    issuesWithMutexesRemoved.includes(substring),
  );
};

export default getRelatedIssues;
