import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../constants/action_types";

/* eslint-disable max-params */
export default createReduxPromiseAction(
  ActionTypes.CLAUSEPART_COMMENT_DELETE,
  (organisationId, clausepartCommentId) =>
    requestor
      .delete(
        `${basePath}/organisation/${organisationId}` +
          `/clausepart_comment/${clausepartCommentId}`,
        {withCredentials: true},
      )
      .then(response => response.data),
);
