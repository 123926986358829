import React from "react";

import ArrowRightIcon from "material-ui/svg-icons/navigation/chevron-right";
import ArrowDownIcon from "material-ui/svg-icons/navigation/expand-more";

const styles = {
  rootDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  expandIcon: {
    marginTop: "12px",
  },
  headerText: {
    marginBottom: 5,
    userSelect: "none",
  },
};

function CollapsibleHeader(props) {
  const {
    headerText,
    isCollapsed,
    onHeaderClick,
    rootDivStyles,
    expandIconStyles,
    headerTextStyles,
  } = props;

  const expandIconStyle = {
    ...styles.expandIcon,
    ...(expandIconStyles ? expandIconStyles : {}),
  };
  return (
    <div
      onClick={onHeaderClick}
      style={{
        ...styles.rootDiv,
        ...(rootDivStyles ? rootDivStyles : {}),
      }}
    >
      {isCollapsed ? (
        <ArrowRightIcon style={expandIconStyle} />
      ) : (
        <ArrowDownIcon style={expandIconStyle} />
      )}
      <h3
        style={{
          ...styles.headerText,
          ...(headerTextStyles ? headerTextStyles : {}),
        }}
      >
        {headerText}
      </h3>
      {props.children}
    </div>
  );
}

export default CollapsibleHeader;
