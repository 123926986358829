import React from "react";
import _ from "underscore";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

import TopicSelector from "common_components/topic_selector";

class TopicEditorDialog extends React.Component {
  render() {
    const {editedUsageId, topic} = this.props;
    if (!editedUsageId) {
      return null;
    }
    const usage =
      topic.usage.find(topicUsage => topicUsage.id === editedUsageId) ||
      topic.fp.find(topicUsage => topicUsage.id === editedUsageId);
    const {
      section_id: sectionId,
      clause_id: clauseId,
      clausepart_id: clausepartId,
      clausepart_last_edited: clausepartLastEdited,
      document_id: documentId,
      project_id: projectId,
      other_topics: otherTopics,
      clausepart_text: text,
      clausepart_reference: reference,
      roles,
    } = usage;

    const args = [projectId, documentId, sectionId, clauseId];

    return (
      <Dialog
        title="Edit clause topics"
        onDismiss={this.props.onTopicEditorDialogDismiss}
        open={Boolean(editedUsageId)}
        actions={[
          <FlatButton
            key="go-back-button"
            label="Go back"
            secondary={true}
            onClick={this.props.onTopicEditorDialogDismiss}
          />,
        ]}
        bodyStyle={{overflowY: "inherit"}}
      >
        <div style={{fontSize: "14px", marginBottom: "1rem"}}>
          <span style={{fontWeight: "600"}}>{reference}</span>
          <span>{text}</span>
        </div>
        <TopicSelector
          organisationId={this.props.organisationId}
          clause={{
            id: clausepartId,
            clause_id: clauseId,
            document_id: documentId,
            project_id: projectId,
            last_edited: clausepartLastEdited,
            topics: (otherTopics || []).map(
              item =>
                item.topic_id === topic.id ? {...item, hide: true} : item,
            ),
            roles,
          }}
          topics={this.props.topics}
          topicsById={this.props.topicsById}
          topicCategories={this.props.topicCategories}
          topicCategoriesById={this.props.topicCategoriesById}
          topicTags={this.props.topicTags}
          contractTypesById={this.props.contractTypesById}
          onExistingTopicAdded={this.onExistingTopicAdded(...args)}
          onNewTopicAdded={this.onNewTopicAdded(...args)}
          onTopicRemoved={this.onTopicRemoved(...args)}
          onTopicsReordered={this.onTopicsReordered(...args)}
          onUnconfirmedTopicsRemoved={this.onUnconfirmedTopicsRemoved(...args)}
          onTopicConfirmed={this.onTopicConfirmed(...args)}
          onTopicparameterValuesUpdate={this.props.onTopicparameterValuesUpdate}
          clausepartsTopicsUpdated={this.props.clausepartsTopicsUpdated}
        />
      </Dialog>
    );
  }

  onExistingTopicAdded = _.memoize(
    (projectId, documentId, sectionId, clauseId) => (...args) => {
      return this.props.onExistingTopicAdded(
        projectId,
        documentId,
        sectionId,
        clauseId,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onNewTopicAdded = _.memoize(
    (projectId, documentId, sectionId, clauseId) => (...args) => {
      return this.props.onNewTopicAdded(
        projectId,
        documentId,
        sectionId,
        clauseId,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onTopicRemoved = _.memoize(
    (projectId, documentId, sectionId, clauseId) => (...args) => {
      return this.props.onTopicRemoved(
        projectId,
        documentId,
        sectionId,
        clauseId,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onUnconfirmedTopicsRemoved = _.memoize(
    (projectId, documentId, sectionId, clauseId) => (...args) => {
      return this.props.onUnconfirmedTopicsRemoved(
        projectId,
        documentId,
        sectionId,
        clauseId,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onTopicConfirmed = _.memoize(
    (projectId, documentId, sectionId, clauseId) => (...args) => {
      return this.props.onTopicConfirmed(
        projectId,
        documentId,
        sectionId,
        clauseId,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onTopicsReordered = _.memoize(
    (projectId, documentId, sectionId, clauseId) => (...args) => {
      return this.props.onTopicsReordered(
        projectId,
        documentId,
        sectionId,
        clauseId,
        ...args,
      );
    },
    (...args) => JSON.stringify([...args]),
  );
}

export default TopicEditorDialog;
