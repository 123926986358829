import BaseRoute from "utils/base_route";

import ContractTypeList from "./containers/contract_type_list";

export default class ContractTypeListRoute extends BaseRoute {
  constructor(store) {
    super("list", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, ContractTypeList);
  }
}
