import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {WorkflowInfo} from "common/flowmaster/types/workflow";

export type WorkflowAddAction = {
  type: WorkflowsActionTypes["WORKFLOW_ADD"]["SUCCESS"];
  payload: WorkflowInfo;
};

export default function addWorkflow(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOW_ADD,
    (organisationId: OrganisationId, workflow: WorkflowInfo) => {
      return requestor
        .post(`${basePath}/organisation/${organisationId}/workflow`, workflow, {
          withCredentials: true,
        })
        .then(response => {
          return response.data;
        });
    },
  );
}
