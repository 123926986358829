import React from "react";

import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import Toolbar from "common_components/toolbar";

function UserDetailToolbar(props) {
  const {isUserNew} = props;
  const Icon = isUserNew ? PersonAddIcon : PersonIcon;
  return (
    <Toolbar
      icon={<Icon />}
      title={isUserNew ? "Create New User" : "User Details"}
    />
  );
}

export default UserDetailToolbar;
