import React from "react";
import _ from "underscore";

import RefreshIndicator from "material-ui/RefreshIndicator";

const styles = {
  refreshIndicator: {
    position: "relative",
    display: "flex",
  },
};

class RefreshButtonIndicator extends React.Component {
  render() {
    const {props} = this;
    const {requestPending, clickHandler} = props;
    function onButtonClick(e) {
      e.stopPropagation();
      clickHandler();
    }
    return (
      <div
        style={{
          position: "relative",
          margin: "0 1rem",
          ...(props.containerStyle ? props.containerStyle : {}),
        }}
        {..._.omit(props, ["containerStyle", "requestPending", "clickHandler"])}
      >
        <RefreshIndicator
          percentage={85}
          size={28}
          color={props.color || "#03A9F4"}
          status={requestPending ? "loading" : "ready"}
          top={0}
          left={0}
          style={{
            ...styles.refreshIndicator,
            cursor: requestPending ? "unset" : "pointer",
          }}
          onClick={requestPending ? undefined : onButtonClick}
        />
      </div>
    );
  }
}

export default RefreshButtonIndicator;
