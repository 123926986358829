import applyOverrideRulesChanges from "common/utils/issues/apply_override_rules_changes";

function applyRulesChangesToRules(issue) {
  if (!issue) {
    return issue;
  }
  const issueOverrideValues = issue.override_values || {};
  for (const masterId of Object.keys(issueOverrideValues)) {
    const masterOverride = issueOverrideValues[masterId];
    for (const clientId of Object.keys(masterOverride)) {
      const clientOverride = masterOverride[clientId];
      const templateOverride = masterOverride.master || {};
      if (clientOverride && clientOverride.rules_changes) {
        const newRules = applyOverrideRulesChanges(
          issue.rules,
          templateOverride.rules_changes,
          clientOverride.rules_changes,
        );
        clientOverride.issue = {
          rules: newRules,
          issue_type: issue.issue_type,
          trigger_display: issue.trigger_display,
        };
      }
    }
  }
}

export default applyRulesChangesToRules;
