import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

import {buildQuery} from "utils/uri";

export default function createClassifierRunBatch(requestor) {
  return createReduxPromiseAction(
    ActionTypes.CLASSIFIER_RUN_BATCH_ADD_REGEX,
    (
      organisationId,
      trainingModeId,
      datasetModeId,
      topicId,
      configurationId,
      topicTrainingNodeId,
      clausepartIds,
      selectedRegex,
      params,
    ) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}/classifier/regex/run/batch${buildQuery(
            params,
          )}`,
          {
            training_mode_id: trainingModeId,
            dataset_mode_id: datasetModeId,
            topic_ids: topicId,
            configuration_id: configurationId,
            topic_trainingnode_id: topicTrainingNodeId,
            clausepart_ids: clausepartIds,
            selected_regex: selectedRegex,
          },
          {withCredentials: true},
        )
        .then(response => ({
          ...response.data,
          selected_regex: selectedRegex,
        })),
  );
}
