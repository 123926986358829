import basePath from "base_path";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes, {UsergroupActionTypes} from "../constants/action_types";
import {UsergroupUser} from "common/types/usergroup";

export type FetchUsergroupUsersAction = {
  type: UsergroupActionTypes["USERGROUP_USERS_FETCH"]["SUCCESS"];
  payload: {usergroupId: number; users: UsergroupUser[]};
};

export default createReduxPromiseAction(
  ActionTypes.USERGROUP_USERS_FETCH,
  (organisationId: number, usergroupId: number) =>
    requestor
      .get(
        `${basePath}/organisation/${organisationId}/usergroup/${usergroupId}/user`,
        {withCredentials: true},
      )
      .then(response => ({usergroupId, users: response.data})),
);
