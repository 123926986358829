export default function renderDuration(_duration) {
  const duration = new Date(_duration).valueOf();
  if (duration > 60 * 1000) {
    return `${Math.round(duration / (60 * 1000))} mins`;
  }
  if (duration > 1000) {
    return `${Math.round(duration / 1000)} secs`;
  }
  return `${duration} ms`;
}
