import basePath from "base_path";
import ActionTypes, {SearcherActionTypes} from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";

import {OrganisationId} from "common/types/organisation";

import EmbeddingTopic from "../types/embedding_topic";

export type EmbeddingTopicsFetchAction = {
  type: SearcherActionTypes["EMBEDDING_TOPICS_FETCH"]["SUCCESS"];
  payload: EmbeddingTopic[];
};

export default function initDocument(requestor) {
  return createReduxPromiseAction(
    ActionTypes.EMBEDDING_TOPICS_FETCH,
    (organisationId: OrganisationId) => {
      return requestor
        .get(`${basePath}/organisation/${organisationId}/topic/embedding`, {
          withCredentials: true,
        })
        .then(response => response.data);
    },
  );
}
