import _ from "lodash";

const group_clause_bank_items = data => {
  return _.sortBy(
    Object.values(
      _.groupBy(data, item => item.issue_name.replace(/\[.+\]/, "")),
    ),
    item => item[0].issue_name,
  );
};

export default group_clause_bank_items;
