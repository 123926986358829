import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {buildQuery} from "utils/uri";

export default function fetchTopicClassifiersStats(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPIC_REGEX_CLASSIFIER_STATS_FETCH,
    (organisationId, topicId, params, data) =>
      requestor
        .put(
          `${basePath}/organisation/${organisationId}/topic/${topicId}/regex_classifier_stats${buildQuery(
            params,
          )}`,
          data,
          {
            withCredentials: true,
          },
        )
        .then(response => response.data),
  );
}
