import sessionStorage from "utils/session_storage";

function getHotProjectsParams() {
  return {
    use_hot_projects:
      typeof sessionStorage.getItem("use_hot_projects") === "boolean"
        ? sessionStorage.getItem("use_hot_projects")
        : true,
    projects_used: sessionStorage.getItem("projects_used")
      ? JSON.stringify(sessionStorage.getItem("projects_used"))
      : "[]",
  };
}

export default getHotProjectsParams;
