import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import radium from "radium";
import Button from "@material-ui/core/Button";

import Topics from "./topics";

import keyedObjectPropType from "utils/keyed_object_prop_type";
import cleanReference from "utils/clauses/clean_reference";
import renderNode from "../utils/render_node";
import calculateNodeSummary from "../utils/calculate_node_summary";
import calculateNodeLevel from "../utils/calculate_node_level";
import calculateTopics from "utils/topic/calculate_topics";

const styles = {
  row: {
    display: "flex",
    margin: "1em 0",
    padding: "1em 0 0 1em",
    position: "relative",
  },
  deleted: {
    color: "#f00",
  },
  topicSelector: {
    display: "inline-block",
    minWidth: "15em",
    width: "30%",
  },
  classicationPending: {
    color: "transparent",
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "default",
    ":hover": {
      color: "#ccc",
      transition: "color 100ms linear",
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  spanBlock: {
    display: "inline-block",
    minWidth: "4em",
  },
  levelStyle: {
    color: "#eee",
    fontSize: "0.75em",
    borderTop: "1px solid white",
    float: "right",
    clear: "both",
    width: "30%",
    textAlign: "center",
    cursor: "pointer",
    height: "1.5em",
    lineHeight: "1.5em",
    position: "relative",
    opacity: 0.8,
  },
  topicWrapper: {
    position: "absolute",
    bottom: "100%",
    right: "0px",
    width: "100%",
  },
};

export class DocumentClauseItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLevel: "default",
      selectedPath: "",
      showLevels: false,
    };
  }

  render() {
    return (
      <>
        <div style={styles.wrapper}>
          {this.props.closed ? (
            <>
              <Button
                onClick={() => this.props.openClause(this.props.clause.id)}
              >
                OPEN CLAUSE
              </Button>
              <Button
                onClick={() => this.props.openAllClauses(this.props.sectionId)}
              >
                OPEN ALL IN SECTION
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => this.props.closeClause(this.props.clause.id)}
              >
                CLOSE CLAUSE
              </Button>
              <Button
                onClick={() =>
                  this.props.closeOtherClauses(
                    this.props.sectionId,
                    this.props.clause.id,
                  )
                }
              >
                CLOSE OTHERS IN SECTION
              </Button>
            </>
          )}
          <Button
            onClick={() => this.props.closeAllClauses(this.props.sectionId)}
          >
            CLOSE ALL IN SECTION
          </Button>
        </div>
        {!this.props.closed && (
          <div
            className={"document-clause-item"}
            style={[
              styles.row,
              ...(this.props.is_deletion ? [styles.deleted] : []),
            ]}
            onMouseOver={this.props.onHover}
          >
            {this.renderReference()}
            {this.renderClauseparts()}
            {this.renderTopics()}
          </div>
        )}
      </>
    );
  }

  renderReference() {
    const {
      clause: {reference, full_reference: fullReference},
      heading,
      changes,
    } = this.props;
    const showReference = heading && heading.reference === reference;
    const change = changes.find(
      _change =>
        _change.new_reference === fullReference &&
        (_change.type === "clause_reference_alteration" ||
          _change.type === "clause_addition"),
    );
    if (change) {
      return (
        <span
          className="reference"
          style={{
            ...styles.spanBlock,
            visibility: showReference ? "hidden" : "visible",
          }}
          onClick={this.onReferenceClickHandler}
        >
          <span className="new-reference" style={{color: "#0f0"}}>
            {reference}
          </span>
          <span className="old-reference" style={{color: "#f00"}}>
            {change.old_display_reference}
          </span>
        </span>
      );
    }
    return (
      <span
        className="reference"
        style={{
          ...styles.spanBlock,
          visibility: showReference ? "hidden" : "visible",
        }}
        onClick={this.onReferenceClickHandler}
      >
        {cleanReference(reference)}
      </span>
    );
  }

  renderClauseparts() {
    const {clause, changes, isInteractive} = this.props;
    const {nodes, reference, full_reference: fullReference} = clause;
    const {selectedLevel, selectedPath} = this.state;
    const change = changes.find(
      _change =>
        _change.new_reference === fullReference &&
        _change.type === "clause_addition",
    );

    return (
      <span
        className={`clauseparts ${change ? "new-clause" : ""}`}
        style={{
          width: this.state.selectedLevel === "all" ? "70%" : "100%",
          ...(change ? {color: "#0f0"} : {}),
        }}
      >
        {this.renderTopicSummary(nodes)}
        {renderNode(
          nodes,
          null,
          {
            ...this.props,
            selectedLevel,
            selectedPath,
            isInteractive,
            onTopicsHover: this.onTopicsHovered,
            onReferenceClick: this.onReferenceClick,
            lacksTopics: true,
            clauseId: clause.id,
          },
          reference,
        )}
      </span>
    );
  }

  renderTopicSummary(nodes) {
    if (!this.state.showLevels) {
      return null;
    }
    const selectedStyle = {
      ...styles.levelStyle,
      backgroundColor: "#999",
    };
    const summary = this.calculateNodeSummary(nodes);
    const allTopics = this.calculateAllTopics(nodes);
    const keys = Object.keys(summary)
      .map(key => parseInt(key, 10))
      .sort(this.sortKeys);
    if (keys.length === 1 || this.state.selectedLevel === "default") {
      return null;
    }
    return (
      <div className="topic-summary" style={styles.topicWrapper}>
        {
          <div
            key="all"
            className="level"
            style={
              this.state.selectedLevel === "all"
                ? selectedStyle
                : styles.levelStyle
            }
            onClick={this.onTopicLevelSelected("all")}
          >
            All: <span>{allTopics.length}</span>
          </div>
        }
        {keys.map(key => {
          let keyText;
          if (key < 0) {
            keyText = "Leaf";
          } else if (key === 0) {
            keyText = "Root";
          } else {
            keyText = `Level ${key + 1}`;
          }
          return (
            <div
              key={key}
              className="level"
              style={
                this.state.selectedLevel === key
                  ? selectedStyle
                  : styles.levelStyle
              }
              onClick={this.onTopicLevelSelected(key)}
            >
              {keyText}: <span>{summary[key]}</span>
            </div>
          );
        })}
      </div>
    );
  }

  calculateNodeSummary(nodes) {
    return calculateNodeSummary(nodes);
  }

  sortKeys(a, b) {
    if (a === -1) {
      return 1;
    }
    if (b === -1) {
      return -1;
    }
    if (a === b) {
      return 0;
    }
    return a > b ? 1 : -1;
  }

  renderTopics() {
    if (this.state.selectedLevel !== "all") {
      return <span />;
    }
    const topics = _.chain(this.calculateAllTopics(this.props.clause.nodes))
      .map(topicHolder => ({...topicHolder.topic, path: topicHolder.path}))
      .sortBy("path")
      .value();
    return (
      <Topics
        className="topic-viewer"
        {...this.props}
        selectedLevel={this.state.selectedLevel}
        is_read_only={true}
        clause={{
          ...this.props.clause,
          topics,
          render_all: true,
        }}
        style={{
          width: "30%",
          minWidth: "15em",
        }}
        onHover={this.onTopicsHovered}
        onTopicHover={this.onTopicHovered}
        onTopicClick={this.onTopicClicked}
      />
    );
  }

  calculateAllTopics(nodes) {
    const topics = [];
    function getTopics(node) {
      if (node.topics) {
        node.topics.forEach(topic => {
          topics.push({
            path: node.path,
            topic: {
              id: `${node.path}_${topic.topic_id}`,
              ...topic,
            },
          });
        });
      }
      if (node.clauseNodes) {
        node.clauseNodes.forEach(child => getTopics(child));
      }
    }
    getTopics(nodes);

    if (this.props.showMasks) {
      const {topicMasks: masks, topicsById} = this.props;
      const clauseTopics = topics.map(topic => ({
        ...topic.topic,
        path: topic.path,
      }));
      return calculateTopics(clauseTopics, true, masks, topicsById).map(
        topic => {
          return {
            id: topic.topic_id ? topic.topic_id : `m_${topic.topic_mask_id}`,
            path: topic.path,
            topic,
          };
        },
      );
    }
    return topics;
  }

  componentDidMount() {
    this.scrollIntoView();
  }

  componentDidUpdate(prevProps) {
    this.scrollIntoView();
    if (this.props.isInteractive !== prevProps.isInteractive) {
      this.setState({selectedPath: "", showLevels: false});
    }
  }

  scrollIntoView() {
    if (this.props.clause.reference !== this.props.highlightedClause) {
      return;
    }
    this.doScroll();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.props.clause, nextProps.clause) ||
      this.props.topics !== nextProps.topics ||
      this.props.documentIssues !== nextProps.documentIssues ||
      this.state.showLevels !== nextState.showLevels ||
      this.state.selectedLevel !== nextState.selectedLevel ||
      this.state.selectedPath !== nextState.selectedPath ||
      this.props.isInteractive !== nextProps.isInteractive ||
      this.props.showMasks !== nextProps.showMasks ||
      this.props.readOnlyModeOn !== nextProps.readOnlyModeOn ||
      this.props.closed !== nextProps.closed
    );
  }

  doScroll(node = ReactDOM.findDOMNode(this)) {
    if (node.scrollIntoViewIfNeeded) {
      node.scrollIntoViewIfNeeded();
    } else if (node.scrollIntoView) {
      node.scrollIntoView();
    }
  }

  onTopicLevelSelected = _.memoize(
    level => () => {
      this.setState({selectedPath: "", selectedLevel: level});
    },
    (...args) => JSON.stringify([...args]),
  );
  onTopicsHovered = () => {
    this.setState({showLevels: true});
  };
  onTopicHovered = path => {
    this.setState({selectedPath: path});
  };
  onTopicClicked = path => {
    const node = this.getNodeByPath(path);
    this.setState({selectedLevel: calculateNodeLevel(node)});
  };
  onReferenceClickHandler = () => {
    this.onReferenceClick("root");
  };
  onReferenceClick = path => {
    const node = this.getNodeByPath(path);
    this.setState({selectedLevel: calculateNodeLevel(node)});
  };

  getNodeByPath(path, nodes = this.props.clause.nodes) {
    const indexes = _.rest(path.split("["))
      .map(str => str.substring(0, str.length - 1))
      .map(str => parseInt(str, 10));
    const selectedNode = indexes.reduce(
      (node, index) => node.clauseNodes[index],
      nodes,
    );
    return selectedNode;
  }
}

DocumentClauseItem.propTypes = {
  organisationId: PropTypes.number.isRequired,
  clause: PropTypes.shape({
    id: PropTypes.number.isRequired,
    reference: PropTypes.string.isRequired,
    clauseParts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        load_state: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        last_edited: PropTypes.string.isRequired,
        topics: PropTypes.arrayOf(
          PropTypes.shape({
            topic_id: PropTypes.number.isRequired,
            is_confirmed: PropTypes.bool.isRequired,
            topicparameters: PropTypes.arrayOf(
              PropTypes.shape({
                topicparameter_id: PropTypes.number.isRequired,
                values: PropTypes.array.isRequired,
              }),
            ),
          }),
        ),
      }),
    ),
  }),
  associatedIssues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  definitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      term: PropTypes.string.isRequired,
      meaning: PropTypes.string.isRequired,
    }),
  ),
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      new_section_id: PropTypes.number.isRequired,
      new_clause_id: PropTypes.number.isRequired,
    }),
  ),
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicsById: keyedObjectPropType(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategoriesById: keyedObjectPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  topicTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onExistingTopicAdded: PropTypes.func.isRequired,
  onNewTopicAdded: PropTypes.func.isRequired,
  onTopicRemoved: PropTypes.func.isRequired,
  onUnconfirmedTopicsRemoved: PropTypes.func.isRequired,
  onTopicConfirmed: PropTypes.func.isRequired,
  onTopicsReordered: PropTypes.func.isRequired,
  onSplitClause: PropTypes.func.isRequired,
  onRemoveSplits: PropTypes.func.isRequired,
};

export default radium(DocumentClauseItem);
