import {DataLocation} from "common/flowmaster/types/data_location";
import {DataStore} from "common/flowmaster/types/data_store";
import {TaskArgument} from "common/flowmaster/types/task_config";
import {Prompt} from "common/flowmaster/types/workflow";
import getValue from "flowmaster/util/get_value";

const getValueBasedOnActionsVisible = (
  arg: TaskArgument,
  prompts: Prompt[],
  datastore: DataStore,
  showInternalActions: boolean,
) => {
  const {value} = arg;
  if (
    value &&
    typeof value === "object" &&
    "source_type" in value &&
    !showInternalActions
  ) {
    if (value.source_type === "none") {
      return arg;
    } else if (value.source_type === "prompt") {
      const promptValue =
        prompts.find(prompt => prompt.id === value.key)?.text ??
        value.key.toString();
      return {...arg, value: promptValue};
    } else {
      const calculatedValue = getValue(
        value as DataLocation<string | number>,
        datastore as DataStore,
      );
      try {
        return {...arg, value: JSON.parse(calculatedValue.toString())};
      } catch {
        return {...arg, value: calculatedValue};
      }
    }
  }
  return arg;
};

export default getValueBasedOnActionsVisible;
