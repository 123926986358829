import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function initClassifiers(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPIC_CLASSIFIERS_FETCH,
    (organisationId, topicId) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}/topic/${topicId}/classifier`,
          {withCredentials: true},
        )
        .then(response => [...response.data]),
  );
}
