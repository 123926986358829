import React from "react";
import _ from "underscore";

export default class SelectedResult extends React.Component {
  render() {
    const {
      settings,
      date,
      result,
      training_mode_id,
      dataset_mode_id,
      last_edited,
    } = this.props.result;
    return (
      <fieldset>
        <legend>Result - {this.props.result.origin}</legend>
        <fieldset>
          <legend>Settings</legend>
          <table>
            <tbody>
              {Object.keys(settings || {}).map(key => {
                const value = settings[key];
                return (
                  <tr key={key}>
                    <td style={{verticalAlign: "top"}}>{key}</td>
                    <td>{this.renderValue(value)}</td>
                  </tr>
                );
              })}
              <tr>
                <td>Training mode</td>
                <td>
                  {
                    (
                      this.props.trainingModes.find(
                        item => item.id === training_mode_id,
                      ) || {}
                    ).name
                  }
                </td>
              </tr>
              <tr>
                <td>Dataset mode</td>
                <td>
                  {
                    (
                      this.props.datasetModes.find(
                        item => item.id === dataset_mode_id,
                      ) || {}
                    ).name
                  }
                </td>
              </tr>
              <tr>
                <td>Saved</td>
                <td>{last_edited}</td>
              </tr>
            </tbody>
          </table>
        </fieldset>
        <fieldset>
          <legend>Result</legend>
          <table>
            <tbody>
              <tr key="date">
                <td style={{verticalAlign: "top"}}>Date</td>
                <td>{date}</td>
              </tr>
              {Object.keys(result || {}).map(key => {
                const value = result[key];
                return (
                  <tr key={key}>
                    <td style={{verticalAlign: "top"}}>{key}</td>
                    <td>{this.renderValue(value)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </fieldset>
      </fieldset>
    );
  }
  renderValue(value) {
    if (Array.isArray(value)) {
      return (
        <table>
          {typeof value[0] === "object" && (
            <thead>
              <tr>
                {(() => {
                  const keys = Object.keys(value[0]);
                  keys.sort();
                  return keys;
                })().map(key => <th key={key}>{key}</th>)}
              </tr>
            </thead>
          )}
          <tbody>
            {_.first(value, 10).map((el, index) => (
              <tr key={index}>{this.renderValue(el)}</tr>
            ))}
          </tbody>
        </table>
      );
    }
    if (value === null) {
      return "";
    }
    if (typeof value === "object") {
      const keys = Object.keys(value);
      keys.sort();
      return keys.map(key => {
        return <td key={key}>{this.renderValue(value[key])}</td>;
      });
    }
    if (typeof value === "number") {
      if (Math.floor(value) === value) {
        return value;
      }
      return value.toFixed(2);
    }
    return JSON.stringify(value);
  }
}
