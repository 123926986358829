// These colors are crudely chosen to contrast sufficiently with a white
// background to be legible, while also contrasting sufficiently with one
// another to be mutually discriminable
export const greens = [
  "#00C300",
  "#166316",
  "#1B8532",
  "#157437",
  "#33A433",
  "#537D0F",
  "#5D7500",
  "#73A901",
];

const styles = {
  common: {
    borderBottomStyle: "solid",
    borderBottomWidth: "3px",
    borderRadius: "2px",
    paddingBottom: "1px",
    lineHeight: "24px",
  },
  match_white_0: {borderBottomColor: greens[0]},
  match_white_1: {borderBottomColor: greens[1]},
  match_white_2: {borderBottomColor: greens[2]},
  match_white_3: {borderBottomColor: greens[2]},
  match_white_4: {borderBottomColor: greens[3]},
  match_white_5: {borderBottomColor: greens[4]},
  match_white_6: {borderBottomColor: greens[5]},
  match_white_7: {borderBottomColor: greens[6]},
  match_black_0: {borderBottomColor: "#ff0000"},
  match_black_1: {borderBottomColor: "#f16868"},
  match_black_2: {borderBottomColor: "#de5e4f"},
  match_black_3: {borderBottomColor: "#ef4c6f"},
  match_black_4: {borderBottomColor: "#af2a47"},
  match_black_5: {borderBottomColor: "#ff0038"},
  match_black_6: {borderBottomColor: "#ec4e0d"},
  match_black_7: {borderBottomColor: "#ad3200"},
  withoutTopic: {borderBottomColor: "#ff5555"},
};

export default styles;
