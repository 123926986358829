import React from "react";
import {LlmRunInfo} from "common/flowmaster/types/llm_run";
import styled from "styled-components";

type Props = {
  llmRuns: LlmRunInfo[];
};

const Container = styled.div`
  display: inline-block;
`;
const Legend = styled.legend`
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 0.5em;
  background-color: #fefefe;
`;
const ColHeader = styled.th`
  text-align: right;
`;
const RowHeader = styled.th`
  font-weight: 200;
  font-style: italic;
  text-align: right;
`;
const Cell = styled.td`
  text-align: right;
`;
const FieldSet = styled.fieldset`
  background-color: white;
`;
const Table = styled.table`
  border-spacing: 2em 0.25em;
`;

export default function Summary({llmRuns}: Props) {
  const avgCost = calculateAvg(llmRuns, "cost");
  const avgTokens = calculateAvg(llmRuns, "total_tokens");
  const totalCost = calculateTotal(llmRuns, "cost");
  const totalTokens = calculateTotal(llmRuns, "total_tokens");
  const {item: maxItem} = calculateMaxRun(llmRuns);
  const {
    item: maxCostItem,
    costPerToken: maxCostPerToken,
  } = calculateMaxCostPerToken(llmRuns);
  return (
    <Container>
      <FieldSet style={{backgroundColor: "white"}}>
        <Legend>
          <span style={{fontWeight: "bold"}}>Stats</span>
          <span style={{marginLeft: "0.5em", fontStyle: "italic"}}>
            - {llmRuns.length} Run{llmRuns.length === 1 ? "" : "s"}
          </span>
        </Legend>
        {llmRuns.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th></th>
                <ColHeader>Cost</ColHeader>
                <ColHeader>Tokens</ColHeader>
                <ColHeader>
                  Cost /<br /> 1M Tokens
                </ColHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <RowHeader>Average</RowHeader>
                <Cell>${avgCost.toFixed(2)}</Cell>
                <Cell>{avgTokens.toFixed(0)}</Cell>
                <Cell>${((avgCost / avgTokens) * 1e6).toFixed(2)}</Cell>
              </tr>
              <tr>
                <RowHeader
                  title={`id: ${maxItem?.id}`}
                  style={{textDecoration: "dashed underline #888"}}
                >
                  Max
                </RowHeader>
                <Cell>${maxItem?.cost.toFixed(2)}</Cell>
                <Cell>{maxItem?.total_tokens.toFixed(0)}</Cell>
                <Cell>
                  ${((maxItem?.cost / maxItem?.total_tokens) * 1e6).toFixed(2)}
                </Cell>
              </tr>
              <tr>
                <RowHeader>Total</RowHeader>
                <Cell>${totalCost.toFixed(2)}</Cell>
                <Cell>{totalTokens.toFixed(0)}</Cell>
                <Cell
                  title={`id: ${maxCostItem?.id}`}
                  style={{textDecoration: "dashed underline #888"}}
                >
                  ${(maxCostPerToken * 1e6).toFixed(2)}
                </Cell>
              </tr>
            </tbody>
          </Table>
        )}
      </FieldSet>
    </Container>
  );
}

function calculateTotal(llmRuns, key) {
  return llmRuns.reduce((sum, item) => sum + item[key], 0);
}
function calculateAvg(llmRuns, key) {
  const viableRuns = llmRuns.filter(item => item[key]);
  return (
    viableRuns.reduce((sum, item) => sum + item[key], 0) / viableRuns.length
  );
}
function calculateMaxRun(llmRuns) {
  const viableRuns = llmRuns.filter(item => item.total_tokens);
  return viableRuns.reduce(
    (max, item) => (item.cost > max.cost ? {item, cost: item.cost} : max),
    {cost: 0, item: null},
  );
}

function calculateMaxCostPerToken(llmRuns) {
  const viableRuns = llmRuns.filter(item => item.total_tokens);
  return viableRuns.reduce(
    (max, item) => {
      const costPerToken = item.cost / item.total_tokens;
      return costPerToken > max.costPerToken ? {item, costPerToken} : max;
    },
    {costPerToken: 0, item: null},
  );
}
