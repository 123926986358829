import _ from "underscore";
import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import CircularProgress from "material-ui/CircularProgress";

import Permissioner from "utils/permissioner";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import byId from "utils/by_id_memo";
import requestor from "requestor";

import DocumentDetailComponent from "../components/document_detail";

import generateTopicMasks from "utils/topic/generate_topic_masks";
import getRouteQuery from "utils/get_route_query";
import sortIssues from "utils/issues/sort_issues";
import getClauseAtoms from "common/utils/clauses/get_clause_atoms";
import groupDocuments from "common/utils/document/group";
import clauseChangeConstructor from "../utils/clause_change_constructor";

import documentFetchAction from "modules/documents/actions/document_fetch";
import updateDocumentIssue from "modules/documents/actions/document_issue_update";
import correctDocumentIssueManually from "modules/documents/actions/document_issue_correct_manually";
import generateDocumentIssueLlmPrompt from "modules/documents/actions/document_issue_generate_prompt";
import generateDocumentLlmPrompt from "modules/documents/actions/document_generate_prompt";
import fetchDocumentConversations from "modules/documents/actions/document_conversations_fetch";
import fetchDocumentConversationItems from "modules/documents/actions/document_conversation_items_fetch";
import fetchDocumentIssueConversation from "modules/documents/actions/document_issue_conversation_fetch";
import clearDocumentIssueConversation from "modules/documents/actions/document_issue_conversation_clear";
import updateDocumentIssues from "modules/documents/actions/document_issues_update";
import revertAllIssues from "modules/documents/actions/document_state_revert";
import documentUpdate from "modules/documents/actions/document_update";
import downloadDocument from "modules/documents/actions/document_download";
import alterDocumentClausepart from "modules/documents/actions/document_clausepart_alter";
import addDocumentClausepart from "modules/documents/actions/document_clausepart_add";
import deleteDocumentClauseparts from "modules/documents/actions/document_clauseparts_delete";
import emailReport from "modules/documents/actions/document_email_report";
import documentDataFetch from "modules/documents/actions/document_data_fetch";
import correctDocumentIssue from "modules/documents/actions/document_issue_correct";
import revertDocumentIssue from "modules/documents/actions/document_issue_revert";
import refreshDocumentIssues from "modules/documents/actions/document_issues_refresh";
import documentUploadToAPI from "modules/documents/actions/document_upload_to_api";
import revertDocumentClausepartChanges from "modules/documents/actions/document_clausepart_changes_revert";
import revertClausepartDeletions from "modules/documents/actions/document_clausepart_deletions_revert";
import revertClausepartAddition from "modules/documents/actions/document_clausepart_addition_revert";
import deleteClause from "modules/documents/actions/document_clause_delete";
import revertClause from "modules/documents/actions/document_clause_revert";
import addDefinition from "modules/documents/actions/document_definition/document_definition_add";
import deleteDefinition from "modules/documents/actions/document_definition/document_definition_delete";
import revertDefinitionDeletion from "modules/documents/actions/document_definition/document_definition_deletion_revert";
import revertDefinitionAddition from "modules/documents/actions/document_definition/document_definition_addition_revert";
import addDocumentHeading from "modules/documents/actions/document_heading/document_heading_add";
import alterDocumentHeading from "modules/documents/actions/document_heading/document_heading_alter";
import deleteDocumentHeading from "modules/documents/actions/document_heading/document_heading_delete";
import revertDocumentHeadingChanges from "modules/documents/actions/document_heading/document_heading_changes_revert";
import addClause from "modules/documents/actions/document_clause_add";
import updateClause from "modules/documents/actions/document_clause_update";
import updateAddedClausepart from "modules/documents/actions/document_clausepart_added_update";
import documentTextReplace from "modules/documents/actions/document_text_replace";
import addClausepartComment from "modules/documents/actions/clausepart_comment_add";
import deleteClausepartComment from "modules/documents/actions/clausepart_comment_delete";
import updateClausepartComment from "modules/documents/actions/clausepart_comment_update";
import searchClearAction from "modules/search/actions/search_clear";
import searchAction from "modules/search/actions/search";
import searchByIssueAction from "modules/search/actions/search_by_issue";
import searchByIssueClearAction from "modules/search/actions/search_by_issue_clear";
import logsFetchAction from "modules/logs/actions/logs_fetch";
import updateDefinitionGroupAction from "modules/documents/actions/definition_group/definition_group_update";
import fetchIssueComparisonDataAction from "modules/documents/actions/issue_comparison_data_fetch";
import documentIssuesFindRun from "modules/documents/actions/document_issues_find_run";
import documentRolesReprocess from "modules/documents/actions/role/document_roles_reprocess";
import logPageLoadTime from "modules/logs/actions/page_load_time_log";
import fetchLlmStatuses from "modules/llm_status/actions/fetch";

import {
  getStoredChecklistState,
  storeChecklistState,
} from "utils/issuesets/checklist_state";

import getDefaultIssuesetId from "../utils/issuesets/get_default_issueset_id";
import getApplicableIssuesetIds from "utils/issuesets/get_applicable_issueset_ids";
import getIssuesetId from "../utils/get_issueset_id";

import {reset as resetGlobalStore} from "common/utils/global_store";
import {Link} from "react-router";
import documentPrerequisiteWorkflowRun from "modules/documents/actions/document_prerequisite_workflow_run";
import fetchPrerequisiteWorkflows from "modules/documents/actions/prerequisite_workflows_fetch";

const DOCUMENT_CLAUSEPARTS_LIMIT = 800;
const LLM_STATUS_FETCH_INTERVAL_MS = 5 * 60 * 1000;

function getDocumentClauseAtoms(documentLastEdited, documentClauses) {
  return getClauseAtoms(documentClauses);
}

const getClauseAtomsMemo = _.memoize(
  getDocumentClauseAtoms,
  documentLastEdited => documentLastEdited,
);

const styles = {
  documentError: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
};

class DocumentDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    resetGlobalStore();
    this.customEventListeners = {};
    this.startTime = new Date().valueOf();
    this.state = {};
    this.llmStatusInterval = null;
  }

  static getDerivedStateFromProps(props, state) {
    const shouldRender = DocumentDetailContainer.shouldRenderContainer(props);
    if (shouldRender && !state.firstRender) {
      return DocumentDetailContainer.initialiseState(props);
    }
    return null;
  }

  static initialiseState(props) {
    const storedViewMode = getStoredChecklistState(
      "documentDetailViewMode",
      props.document.id,
    );
    const viewMode = storedViewMode || "checklist";

    const applicableIssuesetIds = getApplicableIssuesetIds(
      props.document,
      props.project,
      props.contractTypesById,
    );

    const defaultIssuesetId = getDefaultIssuesetId(
      props.document,
      props.project,
      props.contractTypesById,
      applicableIssuesetIds,
    );

    const storedIssueset = getStoredChecklistState(
      "documentDetailIssueset",
      props.document.id,
    );

    return {
      firstRender: true,
      viewMode,
      selectedChecklistSectionId: null,
      currentIssueset: getIssuesetId(
        viewMode,
        props.project.document_type.id,
        defaultIssuesetId,
        storedIssueset,
      ),
    };
  }

  componentDidUpdate({organisation: previousOrganisation}) {
    if (previousOrganisation.use_llm === this.props.organisation.use_llm) {
      return;
    }

    if (!this.props.organisation.use_llm) {
      return;
    }
    this.fetchLlmStatuses();

    this.llmStatusInterval = setInterval(
      this.fetchLlmStatuses,
      LLM_STATUS_FETCH_INTERVAL_MS,
    );
  }

  componentWillUnmount() {
    clearInterval(this.llmStatusInterval);
  }

  fetchLlmStatuses = () => {
    this.props.fetchLlmStatuses();
  };

  render() {
    if (!DocumentDetailContainer.shouldRenderContainer(this.props)) {
      if (this.props.document?.deleted) {
        return (
          <div style={styles.documentError}>
            <p>This document does not exist and may have been deleted.</p>
            <p>
              {"Contact Lexical Labs at "}
              <Link to="#" onClick={this.mailTo} onlyActiveOnIndex>
                support@lexicallabs.com
              </Link>
              {" if you think this is in error."}
            </p>
          </div>
        );
      } else {
        return (
          <CircularProgress
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
            }}
          />
        );
      }
    }

    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    const result = this.renderComponent();
    if (!this.endTime) {
      this.endTime = new Date().valueOf();
      const {params} = this.props;
      this.props.logPageLoadTime(
        params.organisationId,
        "document_detail",
        this.startTime,
        this.endTime,
        {
          document_id: params.documentId,
          project_id: params.projectId,
          url: this.props?.location?.pathname,
        },
      );
    }
    return result;
  }

  hasEnterPermission() {
    return (
      this.permissioner.hasPermission("get-document-detail") &&
      this.permissioner.canViewProject(this.props.project)
    );
  }

  static shouldRenderContainer(props) {
    return (
      isInitialised([
        props.document,
        props.documents,
        props.documentClauses,
        props.documentChanges,
        props.documentDefinitions,
        props.documentHeadings,
        props.documentSections,
        props.documentIssues,
        props.topicMasks,
        props.topics,
        props.topicsById,
        props.topicCategories,
        props.topicTags,
        props.user,
        props.contractTypesById,
        props.definitionGroups,
        props.roles,
      ]) &&
      DocumentDetailContainer.idsMatch(
        props.document.id,
        [props.documentIssues],
        "document_id",
      )
    );
  }

  renderComponent() {
    return this.renderDocumentDetailContainer();
  }

  renderDocumentDetailContainer() {
    setTitle(`${this.props.document.name}`);
    const categoriesById = byId("topic_categories", this.props.topicCategories);
    const {
      params: {organisationId},
      documentIssues: {issues, updateCount, manualUpdateCount},
      queryParams: {clause},
      issueComparisonData,
    } = this.props;
    const documentClauseparts = getClauseAtomsMemo(
      this.props.document.last_edited,
      this.props.documentClauses,
    );
    return (
      <DocumentDetailComponent
        {...this.props}
        isDocumentLong={documentClauseparts.length > DOCUMENT_CLAUSEPARTS_LIMIT}
        organisationId={organisationId}
        topicCategoriesById={categoriesById}
        highlightedClause={clause}
        documentIssues={sortIssues(issues, this.props.documentClauses)}
        documentIssuesUpdateCount={updateCount}
        documentIssuesManualUpdateCount={manualUpdateCount}
        documentClauseparts={documentClauseparts}
        updateDocumentIssue={this.updateDocumentIssue}
        correctDocumentIssueManually={this.correctDocumentIssueManually}
        generateDocumentIssueLlmPrompt={this.generateDocumentIssueLlmPrompt}
        generateDocumentLlmPrompt={this.generateDocumentLlmPrompt}
        fetchDocumentConversations={this.fetchDocumentConversations}
        fetchDocumentConversationItems={this.fetchDocumentConversationItems}
        fetchDocumentIssueConversation={this.fetchDocumentIssueConversation}
        clearDocumentIssueConversation={this.clearDocumentIssueConversation}
        updateDocumentIssues={this.updateDocumentIssues}
        updateDocument={this.updateDocument}
        updateOtherDocument={this.updateOtherDocument}
        downloadDocument={this.downloadDocument}
        sendEmailReport={this.emailReport}
        uploadDocumentToAPI={this.uploadDocumentToAPI}
        currentIssueset={this.state.currentIssueset}
        storeCurrentIssueset={this.updateCurrentIssueset}
        viewMode={this.state.viewMode}
        onViewModeChange={this.onViewModeChange}
        hasEditDocumentTextPermission={this.checkEditDocumentTextPermission()}
        hasEditPdfDocumentTextPermission={this.checkEditPdfDocumentTextPermission()}
        hasCanViewHiddenIssuesPermission={this.checkCanViewHiddenIssues()}
        hasCanGetWorkflowsPermission={this.checkCanGetWorkflows()}
        alterDocumentClausepart={this.alterDocumentClausepart}
        fetchDocumentData={this.fetchDocumentData}
        addDocumentClausepart={this.addDocumentClausepart}
        deleteDocumentClauseparts={this.deleteDocumentClauseparts}
        correctDocumentIssue={this.correctDocumentIssue}
        revertDocumentIssue={this.revertDocumentIssue}
        refreshDocumentIssues={this.refreshDocumentIssues}
        revertDocumentClausepartChanges={this.revertDocumentClausepartChanges}
        revertClausepartDeletions={this.revertClausepartDeletions}
        revertClausepartAddition={this.revertClausepartAddition}
        revertDefinitionDeletion={this.revertDefinitionDeletion}
        revertDefinitionAddition={this.revertDefinitionAddition}
        deleteClause={this.deleteClause}
        revertClause={this.revertClause}
        addDefinition={this.addDefinition}
        deleteDefinition={this.deleteDefinition}
        addDocumentHeadinga={this.addDocumentHeadinga}
        alterDocumentHeading={this.alterDocumentHeading}
        deleteDocumentHeading={this.deleteDocumentHeading}
        revertDocumentHeadingChanges={this.revertDocumentHeadingChanges}
        addClause={this.addClause}
        updateClause={this.updateClause}
        updateAddedClausepart={this.updateAddedClausepart}
        documentTextReplace={this.documentTextReplace}
        goToAnalystView={this.goToAnalystView}
        viewSummaryPage={this.viewSummaryPage}
        goToDocumentList={this.goToDocumentList}
        addClausepartComment={this.addClausepartComment}
        updateClausepartComment={this.updateClausepartComment}
        deleteClausepartComment={this.deleteClausepartComment}
        hasViewClausepartCommentsPermission={this.permissioner.hasPermission(
          "view-clausepart-comment",
        )}
        hasEditClausepartCommentPermission={this.permissioner.hasPermission(
          "edit-clausepart-comment",
        )}
        hasDeleteClausepartCommentPermission={this.permissioner.hasPermission(
          "delete-clausepart-comment",
        )}
        search={this.search}
        searchByIssue={this.searchByIssue}
        addCustomEventListener={this.addCustomEventListener}
        removeCustomEventListener={this.removeCustomEventListener}
        triggerCustomEvent={this.triggerCustomEvent}
        updateDefinitionGroup={this.updateDefinitionGroup}
        fetchIssueComparisonData={this.fetchIssueComparisonData}
        issueComparisonData={issueComparisonData}
        onDocumentIssuesFindRun={this.onDocumentIssuesFindRun}
        onDocumentPrerequisiteWorkflowRun={
          this.onDocumentPrerequisiteWorkflowRun
        }
        onDocumentFetch={() => {
          if (this.props.params) {
            const {organisationId, projectId, documentId} = this.props.params;
            if (organisationId && projectId && documentId) {
              return this.props.fetchDocument(
                organisationId,
                projectId,
                documentId,
              );
            }
          }
        }}
        reprocessDocumentRoles={this.reprocessDocumentRoles}
        setSelectedChecklistSectionId={this.setSelectedChecklistSectionId}
        selectedChecklistSectionId={this.state.selectedChecklistSectionId}
        fetchPrerequisiteWorkflows={this.fetchPrerequisiteWorkflows}
      />
    );
  }

  setSelectedChecklistSectionId = selectedChecklistSectionId => {
    this.setState({selectedChecklistSectionId});
  };

  updateCurrentIssueset = (documentId, currentIssueset) => {
    storeChecklistState("documentDetailIssueset")(documentId, currentIssueset);
    this.setState({currentIssueset});
  };

  onViewModeChange = viewMode => {
    storeChecklistState("documentDetailViewMode")(viewMode);
    this.setState({viewMode});
  };

  addCustomEventListener = (name, handler) => {
    if (!_.has(this.customEventListeners, name)) {
      this.customEventListeners[name] = [];
    }
    this.customEventListeners[name].push(handler);
  };

  removeCustomEventListener = (name, handler) => {
    if (_.has(this.customEventListeners, name)) {
      const index = this.customEventListeners[name].indexOf(handler);
      if (index > -1) {
        this.customEventListeners[name].splice(index, 1);
      }
      if (!this.customEventListeners[name].length) {
        delete this.customEventListeners[name];
      }
    }
  };

  triggerCustomEvent = (name, ...args) => {
    if (_.has(this.customEventListeners, name)) {
      this.customEventListeners[name].forEach(handler => {
        handler(...args);
      });
    }
  };

  static idsMatch(id, objects, key) {
    return objects.every(object => object[key] === id);
  }

  /* eslint-disable no-invalid-this */
  goToAnalystView = () => {
    const path = this.props.router.location.pathname;
    this.props.router.push(path.replace("/detail", ""));
  };
  viewSummaryPage = () => {
    const path = this.props.router.location.pathname;
    this.props.router.push(path.replace("/detail", "/comparison"));
  };

  /* eslint-enable no-invalid-this */
  updateDocumentIssue = (issue, updates, options) => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    return this.props.updateDocumentIssue(
      organisationId,
      projectId,
      documentId,
      issue.document_issue_id,
      issue.last_edited,
      updates,
      options,
    );
  };

  correctDocumentIssueManually = (issue, updates, options) => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    return this.props.correctDocumentIssueManually(
      organisationId,
      projectId,
      documentId,
      issue.document_issue_id,
      issue.last_edited,
      updates,
      options,
    );
  };

  generateDocumentIssueLlmPrompt = (
    issueId,
    issuesetMasterId,
    issuesetClientId,
    prompt,
    conversationType,
    conversationId,
    previousPromptId,
  ) => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    return this.props.generateDocumentIssueLlmPrompt(
      organisationId,
      projectId,
      documentId,
      issueId,
      issuesetMasterId,
      issuesetClientId,
      prompt,
      conversationType,
      conversationId,
      previousPromptId,
    );
  };

  generateDocumentLlmPrompt = (
    prompt,
    conversationType,
    conversationId,
    previousPromptId,
  ) => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    return this.props.generateDocumentLlmPrompt(
      organisationId,
      projectId,
      documentId,
      prompt,
      conversationType,
      conversationId,
      previousPromptId,
    );
  };
  fetchDocumentConversations = () => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    return this.props.fetchDocumentConversations(
      organisationId,
      projectId,
      documentId,
    );
  };
  fetchDocumentConversationItems = (
    conversationId,
    conversationType,
    previousItemId,
  ) => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    return this.props.fetchDocumentConversationItems(
      organisationId,
      projectId,
      documentId,
      conversationId,
      conversationType,
      previousItemId,
    );
  };
  fetchDocumentIssueConversation = (
    issueId,
    conversationId,
    conversationType,
    issuesetMasterId,
    issuesetRemoteClientId,
  ) => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    return this.props.fetchDocumentIssueConversation(
      organisationId,
      projectId,
      documentId,
      issueId,
      conversationId,
      conversationType,
      issuesetMasterId,
      issuesetRemoteClientId,
    );
  };

  clearDocumentIssueConversation = issueId => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    return this.props.clearDocumentIssueConversation(
      organisationId,
      projectId,
      documentId,
      issueId,
    );
  };

  updateDocumentIssues = (issues, updates, options) => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    return this.props.updateDocumentIssues(
      organisationId,
      projectId,
      documentId,
      issues,
      updates,
      options,
    );
  };
  updateDocument = async data => {
    const {organisationId, projectId, documentId} = this.props.params;
    return (
      await this.props.documentUpdate(
        organisationId,
        projectId,
        documentId,
        {
          ...data,
          last_edited: this.props.document.last_edited,
        },
        false,
      )
    ).value;
  };

  refreshDocumentIssues = async lastUpdated => {
    const {organisationId, projectId, documentId} = this.props.params;
    return (
      await this.props.refreshDocumentIssues(
        organisationId,
        projectId,
        documentId,
        lastUpdated,
      )
    )?.value;
  };

  updateOtherDocument = (documentId, lastEdited, data) => {
    const {organisationId, projectId} = this.props.params;
    this.props.documentUpdate(
      organisationId,
      projectId,
      documentId,
      {
        ...data,
        last_edited: lastEdited,
      },
      false,
    );
  };

  downloadDocument = _.memoize(
    (
      withChanges = false,
      withMarkup = false,
      withComments = false,
      withoutExtension = false,
    ) => async fileIndex => {
      const {organisationId, projectId, documentId} = this.props.params;
      const result = await this.props.downloadDocument(
        organisationId,
        projectId,
        documentId,
        fileIndex - 1,
        this.props.document.concat_filenames[fileIndex - 1],
        withChanges,
        withMarkup,
        withComments,
        withoutExtension,
      );
      return result;
    },
    (...argv) => JSON.stringify(argv),
  );

  emailReport = (issuesetId, data) => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.emailReport(
      organisationId,
      projectId,
      documentId,
      issuesetId,
      "checklist",
      data,
    );
  };

  uploadDocumentToAPI = () => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.documentUploadToAPI(organisationId, projectId, documentId);
  };

  checkEditDocumentTextPermission = () =>
    this.permissioner.hasPermission("edit-document-text");
  checkEditPdfDocumentTextPermission = () =>
    this.permissioner.hasPermission("edit-pdf-document-text");
  checkCanViewHiddenIssues = () =>
    this.permissioner.hasPermission("can-view-hidden-issues");
  checkCanGetWorkflows = () => this.permissioner.hasPermission("get-workflows");

  alterDocumentClausepart = (sectionId, clauseId, clausepart, text) => {
    const {organisationId, projectId, documentId} = this.props.params;
    const {id: clausepartId, last_edited: lastEdited} = clausepart;
    this.props.alterDocumentClausepart(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      lastEdited,
      text,
    );
  };

  addDocumentClausepart = (
    sectionId,
    clauseId,
    path,
    order,
    contents,
    lastEdited,
  ) => {
    const {
      params: {organisationId},
    } = this.props;
    const {addDocumentClausepart, project, document} = this.props;
    addDocumentClausepart(
      organisationId,
      project.id,
      document.id,
      sectionId,
      clauseId,
      path,
      order,
      contents,
      lastEdited,
    );
  };

  deleteDocumentClauseparts = (
    sectionId,
    clauseId,
    path,
    lastEdited,
    clausepartsToDelete,
    heading,
  ) => {
    const {organisationId, documentId} = this.props.params;
    const {deleteDocumentClauseparts, project, document} = this.props;
    const deletionChanges = clausepartsToDelete.map(clausepart =>
      clauseChangeConstructor["clausepart_deletion"](
        document.id,
        sectionId,
        clauseId,
        clausepart,
      ),
    );
    let headingData;
    if (heading) {
      headingData = {};
      headingData.id = heading.id;
      headingData.last_edited = heading.last_edited;
      headingData.deletionChange = clauseChangeConstructor[
        "clauseheading_deletion"
      ](documentId, heading);
    }
    deleteDocumentClauseparts(
      organisationId,
      project.id,
      document.id,
      sectionId,
      clauseId,
      path,
      lastEdited,
      deletionChanges,
      headingData,
    );
  };

  fetchDocumentData = type => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.documentDataFetch(organisationId, projectId, documentId, type);
  };

  correctDocumentIssue = (documentIssue, dataPath) => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.correctDocumentIssue(
      organisationId,
      projectId,
      documentId,
      documentIssue.document_issue_id,
      dataPath, // for the case corrections will be overriden
      documentIssue.last_edited,
    );
  };

  revertDocumentIssue = (documentIssue, dataPath) => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.revertDocumentIssue(
      organisationId,
      projectId,
      documentId,
      documentIssue.document_issue_id,
      dataPath,
      documentIssue.last_edited,
    );
  };

  revertDocumentClausepartChanges = (sectionId, clauseId, clausepart) => {
    const {
      params: {organisationId, projectId, documentId},
      revertDocumentClausepartChanges,
    } = this.props;
    const {id: clausepartId, last_edited: clausepartLastEdited} = clausepart;
    revertDocumentClausepartChanges(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clausepartLastEdited,
    );
  };

  revertClausepartDeletions = (sectionId, clauseId, clausepartIds, heading) => {
    const {
      params: {organisationId, projectId, documentId},
      revertClausepartDeletions,
    } = this.props;
    let headingData;
    if (heading) {
      headingData = {};
      headingData.id = heading.id;
      headingData.last_edited = heading.last_edited;
    }
    revertClausepartDeletions(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartIds,
      headingData,
    );
  };

  revertClausepartAddition = (sectionId, clauseId, clausepart) => {
    const {
      params: {organisationId, projectId, documentId},
      revertClausepartAddition,
    } = this.props;
    const clause = this.props.documentClauses[sectionId].find(
      c => c.id === clauseId,
    );
    const {last_edited: clauseLastEdited} = clause;
    const {id: clausepartId} = clausepart;
    revertClausepartAddition(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
    );
  };

  deleteClause = _.memoize(
    (sectionId, clauseId) => () => {
      const {
        params: {organisationId, projectId, documentId},
        deleteClause,
      } = this.props;
      const clause = this.props.documentClauses[sectionId].find(
        c => c.id === clauseId,
      );
      const {last_edited: clauseLastEdited} = clause;
      const deletionChange = clauseChangeConstructor["clause_deletion"](
        documentId,
        clause,
      );
      deleteClause(
        organisationId,
        projectId,
        documentId,
        sectionId,
        clauseId,
        clauseLastEdited,
        deletionChange,
      );
    },
    (...args) => JSON.stringify(args),
  );

  revertClause = _.memoize(
    (sectionId, clauseId) => () => {
      const {
        params: {organisationId, projectId, documentId},
        revertClause,
      } = this.props;
      const clause = this.props.documentClauses[sectionId].find(
        c => c.id === clauseId,
      );
      const {last_edited: clauseLastEdited} = clause;
      revertClause(
        organisationId,
        projectId,
        documentId,
        sectionId,
        clauseId,
        clauseLastEdited,
      );
    },
    (...args) => JSON.stringify(args),
  );

  alterDocumentHeading = (heading, text) => {
    const {
      params: {organisationId, projectId, documentId},
      alterDocumentHeading,
    } = this.props;
    const {
      id: headingId,
      section_id: sectionId,
      last_edited: headingLastEdited,
    } = heading;
    alterDocumentHeading(
      organisationId,
      projectId,
      documentId,
      sectionId,
      headingId,
      headingLastEdited,
      text,
    );
  };

  deleteDocumentHeading = heading => {
    const {
      params: {organisationId, projectId, documentId},
      deleteDocumentHeading,
    } = this.props;
    const {
      id: headingId,
      section_id: sectionId,
      last_edited: headingLastEdited,
    } = heading;
    const deletionChange = clauseChangeConstructor["clauseheading_deletion"](
      documentId,
      heading,
    );
    deleteDocumentHeading(
      organisationId,
      projectId,
      documentId,
      sectionId,
      headingId,
      headingLastEdited,
      deletionChange,
    );
  };

  revertDocumentHeadingChanges = heading => {
    const {
      params: {organisationId, projectId, documentId},
      revertDocumentHeadingChanges,
    } = this.props;
    const {
      id: headingId,
      section_id: sectionId,
      last_edited: headingLastEdited,
    } = heading;
    revertDocumentHeadingChanges(
      organisationId,
      projectId,
      documentId,
      sectionId,
      headingId,
      headingLastEdited,
    );
  };

  addClause = (clause, position, contents, headingText) => {
    const {
      params: {organisationId, projectId, documentId},
      addClause,
      document: {last_edited: lastEdited},
    } = this.props;
    const {id: clauseId, section_id: sectionId} = clause;
    addClause(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      position,
      contents,
      headingText,
      lastEdited,
    );
  };

  updateClause = (clause, contents) => {
    const {
      params: {organisationId, projectId, documentId},
      updateClause,
    } = this.props;
    const {
      id: clauseId,
      section_id: sectionId,
      last_edited: lastEdited,
    } = clause;
    updateClause(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      contents,
      lastEdited,
    );
  };

  updateAddedClausepart = (clause, clausepart, contents) => {
    const {
      params: {organisationId, projectId, documentId},
      updateAddedClausepart,
    } = this.props;
    const {id: clauseId, section_id: sectionId} = clause;
    const {id: clausepartId, last_edited: lastEdited} = clausepart;
    updateAddedClausepart(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      contents,
      lastEdited,
    );
  };

  documentTextReplace = (find, replace) => {
    const {organisationId, projectId, documentId} = this.props.params;
    return this.props.documentTextReplace(
      organisationId,
      projectId,
      documentId,
      find,
      replace,
    );
  };

  addDocumentHeading = (sectionId, reference, text) => {
    const {
      params: {organisationId, projectId, documentId},
      addDocumentHeading,
    } = this.props;
    addDocumentHeading(
      organisationId,
      projectId,
      documentId,
      sectionId,
      reference,
      text,
    );
  };
  addDefinition = (documentClause, path, term, meaning) => {
    const {
      params: {organisationId, projectId, documentId},
      addDefinition,
    } = this.props;
    addDefinition(
      organisationId,
      projectId,
      documentId,
      documentClause.section_id,
      documentClause.id,
      path,
      term,
      meaning,
      documentClause.last_edited,
    );
  };

  deleteDefinition = (documentClause, term) => {
    const {
      params: {organisationId, projectId, documentId},
      deleteDefinition,
    } = this.props;
    const deletionChange = clauseChangeConstructor["definition_deletion"](
      documentId,
      documentClause,
      term,
    );
    deleteDefinition(
      organisationId,
      projectId,
      documentId,
      documentClause.section_id,
      documentClause.id,
      term,
      documentClause.last_edited,
      deletionChange,
    );
  };
  revertDefinitionDeletion = (clause, change) => {
    const {
      params: {organisationId, projectId, documentId},
      revertDefinitionDeletion,
    } = this.props;
    const {
      id: clauseId,
      section_id: sectionId,
      last_edited: clauseLastEdited,
    } = clause;
    const {new_reference: term} = change;
    revertDefinitionDeletion(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      term,
      clauseLastEdited,
    );
  };

  revertDefinitionAddition = (clause, change) => {
    const {
      params: {organisationId, projectId, documentId},
      revertDefinitionAddition,
    } = this.props;
    const {
      id: clauseId,
      section_id: sectionId,
      last_edited: clauseLastEdited,
    } = clause;
    const {
      new_clausepart_id: termClausepartId,
      new_meaning_clausepart_id: meaningClausepartId,
    } = change;
    revertDefinitionAddition(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      termClausepartId,
      meaningClausepartId,
      clauseLastEdited,
    );
  };

  addClausepartComment = (clauseId, clausepartId, text, isExternal) => {
    if (text) {
      const {
        params: {organisationId, projectId, documentId},
        addClausepartComment,
      } = this.props;
      addClausepartComment(
        organisationId,
        projectId,
        documentId,
        clauseId,
        clausepartId,
        text,
        isExternal,
      );
    }
  };

  deleteClausepartComment = _.memoize(clausepartCommentId => () => {
    const {
      params: {organisationId},
      deleteClausepartComment,
    } = this.props;
    deleteClausepartComment(organisationId, clausepartCommentId);
  });

  updateClausepartComment = _.memoize(
    clausepartComment => params => {
      const {
        params: {organisationId, projectId, documentId},
        updateClausepartComment,
      } = this.props;
      updateClausepartComment(
        organisationId,
        projectId,
        documentId,
        clausepartComment.id,
        params,
        clausepartComment.last_edited,
      );
    },
    (...argv) => JSON.stringify(argv),
  );

  onDocumentIssuesFindRun = documentId => {
    const {
      params: {organisationId, projectId},
    } = this.props;
    this.props.documentIssuesFindRun(organisationId, projectId, documentId);
  };

  onDocumentPrerequisiteWorkflowRun = (documentId, workflowInstanceId) => {
    const {
      params: {organisationId, projectId},
    } = this.props;
    this.props.documentPrerequisiteWorkflowRun(
      organisationId,
      projectId,
      documentId,
      workflowInstanceId,
    );
  };

  search = (queryValue, searchOptions = null) => {
    if (!queryValue.length) {
      this.props.searchClear();
    } else {
      const {
        params: {projectId},
      } = this.props;

      const filterList = [`project=${projectId}`];
      if (!searchOptions || !searchOptions.ignoreContractType) {
        filterList.push(
          `contract_type=${this.props.document.contract_type.id}`,
        );
      }
      if (searchOptions && _.isString(searchOptions.searchType)) {
        filterList.push(`search_type=${searchOptions.searchType}`);
      }
      if (searchOptions && searchOptions.issuesetPath) {
        filterList.push(`issueset_path=${searchOptions.issuesetPath}`);
      }
      if (searchOptions && searchOptions.exactMatch) {
        filterList.push("exact_match=true");
      }
      this.props.search(
        this.props.params.organisationId,
        "text",
        queryValue,
        filterList.length ? filterList.join("&") : null,
      );
    }
  };

  searchByIssue = (projectId, issueId, issuesetPath) => {
    this.props.searchByIssue(
      this.props.params.organisationId,
      projectId,
      issueId,
      issuesetPath,
    );
  };

  updateDefinitionGroup = (definitionGroup, data) => {
    const {
      params: {organisationId},
    } = this.props;
    this.props.updateDefinitionGroup(
      organisationId,
      definitionGroup.id,
      {
        ...data,
        last_edited: definitionGroup.last_edited,
      },
      definitionGroup.last_edited,
    );
  };

  fetchPrerequisiteWorkflows = () => {
    const {
      params: {organisationId, projectId},
    } = this.props;
    return this.props.fetchPrerequisiteWorkflows(organisationId, projectId);
  };

  fetchIssueComparisonData = docId => {
    const {
      params: {organisationId, projectId},
    } = this.props;
    return this.props.fetchIssueComparisonData(
      organisationId,
      projectId,
      docId,
    );
  };

  reprocessDocumentRoles = (processManualRoles = false) => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    this.props.documentRolesReprocess(
      organisationId,
      projectId,
      documentId,
      processManualRoles,
    );
  };

  mailTo = () => {
    window.location = "mailto:support@lexicallabs.com";
  };
}

function select(state, props) {
  const topicsById =
    state.topics && state.topics !== UNINITIALISED
      ? byId("topics", state.topics)
      : UNINITIALISED;
  let topicMasks = UNINITIALISED;
  let topicMasksById = UNINITIALISED;

  if (
    topicsById !== UNINITIALISED &&
    state.topicMasks &&
    state.topicMasks !== UNINITIALISED
  ) {
    const unpackedResults = generateTopicMasks(state.topicMasks, topicsById);
    topicMasks = unpackedResults.topicMasks;
    topicMasksById = unpackedResults.topicMasksById;
  }

  let contractTypesById;
  if (state.contract_types !== UNINITIALISED) {
    contractTypesById = byId("contract_types", state.contract_types);
  }
  let definitionsByReference = UNINITIALISED;
  if (state.documentDefinitions) {
    definitionsByReference = byId(
      "document_definitions",
      state.documentDefinitions,
      "reference",
    );
  }
  const documents =
    state.documents === UNINITIALISED ? null : getDocuments(state.documents);
  return {
    queryParams: getRouteQuery(state.router),
    params: _.mapObject(props.params, id => parseInt(id, 10)),
    document:
      state.documents && state.documents !== UNINITIALISED
        ? {
            ...byId("documents", documents)[props.params.documentId],
            ...state.document,
          }
        : {},
    documents,
    documentClauses: state.documentClauses.clauses,
    documentChanges: state.documentChanges,
    documentDefinitions: state.documentDefinitions,
    documentHeadings: state.documentHeadings,
    documentSections: state.documentSections,
    documentIssues: state.documentIssues,
    issueComparisonData:
      state.issueComparisonData === UNINITIALISED
        ? undefined
        : state.issueComparisonData,
    topicMasks,
    topicCategories: state.topicCategories,
    topicTags: state.topicTags,
    topics: state.topics,
    organisation: state.organisation,
    project: state.project,
    topicsById,
    topicMasksById,
    definitionsByReference,
    user: state.user,
    contractTypes: state.contract_types,
    contractTypesById,
    searchResults: state.search.results,
    documentStates:
      state.documentStates === UNINITIALISED ? [] : state.documentStates,
    documentLogs: state.logs === UNINITIALISED ? null : state.logs,
    searchByIssueResults:
      state.search_by_issue.results === UNINITIALISED
        ? null
        : state.search_by_issue.results,
    definitionGroups: state.definitionGroups,
    parties: state.parties,
    roles: state.roles,
    reports: state.reports,
    llmStatuses: state.llmStatuses,
    prerequisiteWorkflows: state.prerequisiteWorkflows,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      downloadDocument: downloadDocument(requestor),
      emailReport: emailReport(requestor),
      updateDocumentIssue: updateDocumentIssue(requestor),
      correctDocumentIssueManually: correctDocumentIssueManually(requestor),
      generateDocumentIssueLlmPrompt: generateDocumentIssueLlmPrompt(requestor),
      generateDocumentLlmPrompt: generateDocumentLlmPrompt(requestor),
      fetchDocumentConversations: fetchDocumentConversations(requestor),
      fetchDocumentConversationItems: fetchDocumentConversationItems(requestor),
      fetchDocumentIssueConversation: fetchDocumentIssueConversation(requestor),
      clearDocumentIssueConversation: clearDocumentIssueConversation(requestor),
      updateDocumentIssues: updateDocumentIssues(requestor),
      documentUpdate: documentUpdate(requestor),
      revertAllIssues: revertAllIssues(requestor),
      documentDataFetch: documentDataFetch(requestor),
      documentUploadToAPI: documentUploadToAPI(requestor),
      fetchIssueComparisonData: fetchIssueComparisonDataAction(requestor),
      fetchDocument: documentFetchAction(requestor),
      documentIssuesFindRun: documentIssuesFindRun(requestor),
      documentPrerequisiteWorkflowRun: documentPrerequisiteWorkflowRun(
        requestor,
      ),
      alterDocumentClausepart,
      addDocumentClausepart,
      correctDocumentIssue,
      revertDocumentIssue,
      refreshDocumentIssues,
      deleteDocumentClauseparts,
      revertDocumentClausepartChanges,
      revertClausepartDeletions,
      revertClausepartAddition,
      deleteClause,
      revertClause,
      addDefinition,
      deleteDefinition,
      revertDefinitionDeletion,
      revertDefinitionAddition,
      addDocumentHeading,
      alterDocumentHeading,
      deleteDocumentHeading,
      revertDocumentHeadingChanges,
      addClause,
      updateClause,
      updateAddedClausepart,
      documentTextReplace,
      addClausepartComment,
      deleteClausepartComment,
      updateClausepartComment,
      searchClear: searchClearAction,
      search: searchAction,
      fetchLogs: logsFetchAction(requestor),
      searchByIssue: searchByIssueAction,
      searchByIssueClear: searchByIssueClearAction,
      updateDefinitionGroup: updateDefinitionGroupAction(requestor),
      documentRolesReprocess: documentRolesReprocess(requestor),
      logPageLoadTime: logPageLoadTime(requestor),
      fetchLlmStatuses: fetchLlmStatuses(requestor),
      fetchPrerequisiteWorkflows: fetchPrerequisiteWorkflows(requestor),
    },
    dispatch,
  );
}

function getDocuments(docs) {
  const grouped = groupDocuments(docs);
  let all = [];
  grouped.forEach(doc => {
    all.push(doc);
    if (doc.isGroupParent) {
      all = all.concat(doc.revisions);
    }
  });
  return all;
}

export default connect(select, mapDispatchToProps)(DocumentDetailContainer);
export const Component = DocumentDetailContainer;
