import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import Permissioner from "utils/permissioner";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import byId from "common/utils/by_id";

import requestor from "requestor";
import addTopic from "../../../modules/documents/actions/topic_list_topic_add";

import TopicList from "../components/topic_list";

import updateTopicsAction from "modules/documents/actions/topics_update";
import topicsInfoFetchAction from "modules/documents/actions/topics_info_fetch";
import updateTopicAction from "modules/documents/actions/topic_update";

class Topics extends React.Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return (
      this.permissioner.isAdmin() ||
      this.permissioner.hasPermission("list-topics")
    );
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.topics,
      this.props.categories,
      this.props.tags,
      this.props.contractTypes,
      this.props.user,
    ]);
  }

  renderComponent() {
    return (
      <React.Fragment>
        {this.renderTopicList()}
        {this.props.children}
      </React.Fragment>
    );
  }

  renderTopicList() {
    setTitle("Topics List");
    const contractTypesById = byId(this.props.contractTypes);
    const categoriesById = byId(this.props.categories);
    const tagsById = byId(this.props.tags);
    return (
      <TopicList
        topics={this.props.topics}
        categories={this.props.categories}
        categoriesById={categoriesById}
        tags={this.props.tags}
        tagsById={tagsById}
        organisationId={parseInt(this.props.params.organisationId, 10)}
        onTopicsUpdated={this.updateTopics}
        toggleView={this.toggleView}
        contractTypesById={contractTypesById}
        topicsById={this.props.topicsById}
        fetchTopicsInfo={this.fetchTopicsInfo}
        onTopicUpdate={this.onTopicUpdate}
        addNewTopic={this.addNewTopic}
      />
    );
  }

  updateTopics = data => {
    const {organisationId} = this.props.params;
    this.props.updateTopicsAction(organisationId, data);
  };
  toggleView = path => {
    const fullPath = this.props.router.location.pathname;
    const basePath = fullPath.substring(0, fullPath.lastIndexOf("/"));
    this.props.router.push(`${basePath}/${path}`);
  };
  fetchTopicsInfo = () => {
    const {organisationId} = this.props.params;
    this.props.topicsInfoFetchAction(organisationId);
  };
  onTopicUpdate = (topicId, data) => {
    const topic = this.props.topics.find(topic => topic.id === topicId);
    const {organisationId} = this.props.params;
    if (topic) {
      this.props.updateTopicAction(organisationId, topicId, {
        ...data,
        last_edited: topic.last_edited,
      });
    }
  };

  addNewTopic = (
    topicName,
    isSubstantive,
    isEmbedding,
    topiccategoryId,
    contractTypeId,
  ) => {
    const {organisationId} = this.props.params;
    return this.props.addTopic(
      organisationId,
      topicName,
      isSubstantive,
      isEmbedding,
      topiccategoryId,
      contractTypeId,
    );
  };
}

function select(state) {
  const topicsById =
    state.topics && state.topics !== UNINITIALISED
      ? byId(state.topics)
      : UNINITIALISED;

  return {
    documentClauses: state.documentClauses,
    topics: state.topics,
    categories: state.topicCategories,
    tags: state.topicTags,
    contractTypes: state.contract_types,
    user: state.user,
    topicsById,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        addTopic: addTopic(requestor),
        updateTopicsAction: updateTopicsAction(requestor),
        topicsInfoFetchAction: topicsInfoFetchAction(requestor),
        updateTopicAction: updateTopicAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(Topics);
export const Component = Topics;
