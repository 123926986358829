import React from "react";

import TextField from "material-ui/TextField";
import CloseIcon from "material-ui/svg-icons/navigation/close";

const styles = {
  clearTextIcon: {
    cursor: "pointer",
    color: "#757575",
    position: "relative",
    top: "42px",
    height: "18px",
    width: "18px",
  },
};

class SearchByTextControl extends React.Component {
  state = {textSearch: ""};

  render() {
    const {allUsagesCount, searchUsagesCount} = this.props;
    return (
      <div style={{display: "flex", position: "relative"}}>
        <TextField
          floatingLabelText="Search"
          value={this.state.textSearch}
          onChange={this.onTextSearchChange}
          onBlur={this.onTextSearchBlur}
          onKeyPress={this.onKeyPress}
        />
        <div
          style={{
            color: "rgb(158, 158, 158)",
            fontSize: "12px",
            position: "absolute",
            right: "25px",
            top: "18px",
          }}
        >
          {`${searchUsagesCount || "-"} / ${allUsagesCount || "-"}`}
        </div>
        <CloseIcon
          onClick={this.onTextSearchClear}
          style={styles.clearTextIcon}
        />
      </div>
    );
  }

  onTextSearchChange = e => {
    const newValue = e.target.value;
    this.setState(() => ({textSearch: newValue}));
  };

  onKeyPress = e => {
    if (e.key === "Enter") {
      this.onTextSearchBlur();
    }
  };

  onTextSearchBlur = () => {
    this.onTextSearch(this.state.textSearch);
  };

  onTextSearchClear = () =>
    this.setState(() => ({textSearch: ""}), () => this.onTextSearch(""));

  onTextSearch = newValue => {
    if (!this.props.onSearch) {
      return;
    }
    this.props.onSearch(newValue.toLowerCase());
  };
}

export default SearchByTextControl;
