/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/require-returns */
import {useEffect, useState} from "react";

/**
 * This hook is used to enable a convenient pattern of manual synchronisation
 * for a local state and a controlled value supplied by the parent. This is
 * useful in cases where the parent is known to exhibit an unacceptably slow
 * render in response to certain user inputs, such as fast typing in text
 * fields.
 */
const usePartiallyControlledState = <T>(
  // WARN: If this is an object, make sure to memoize it if necessary to avoid
  // undesired clobbering of the local state.
  controlledValue: T,
  synchronise: (localValue: T) => void,
) => {
  const [state, setState] = useState(controlledValue);

  // This effect is used to synchronise the local state with the controlled
  // value, if it changes. This is taken as an implicit signal that the
  // application state has changed at a high level which should be reflected
  // here.
  // NOTE: This policy of inferring this condition only when the controlled
  // value changes is not ideal because it fails to handle a predictable case
  // where the parent effectively wants to "reset" the local state of this
  // component to the same state it had before local, unsynchronised changes
  // were made. This is impossible through this API except through some
  // workaround like "switching it off and on again", providing a meaningless
  // intermediate value just to ensure that the local state is overwritten.
  useEffect(() => {
    setState(controlledValue);
  }, [controlledValue]);

  return [state, setState, () => synchronise(state)] as const;
};

export default usePartiallyControlledState;
