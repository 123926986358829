import React, {useState, useEffect, useRef} from "react";
import TextField from "@material-ui/core/TextField";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // NOTE: this is called (as a cleanup fn) when this is called a second time
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

interface Props {
  value: number;
  label: string;
  step: number;
  debounceInterval?: number;
  onChange: (value: number) => void;
}

function NumberTextField({
  value: initialValue,
  label,
  step,
  debounceInterval,
  onChange,
}: Props) {
  const [value, setValue] = useState(initialValue);
  const debouncedValue = useDebounce(value, debounceInterval ?? 1000);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      onChange(debouncedValue);
    }
  }, [debouncedValue]);

  const handleChange = event => {
    setValue(event.target.value);
  };
  return (
    <TextField
      type="number"
      label={label}
      inputProps={{step}}
      value={value}
      onChange={handleChange}
    />
  );
}

export default NumberTextField;
