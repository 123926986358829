import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {
  WorkflowId,
  WorkflowInstance,
  WorkflowInstanceId,
} from "common/flowmaster/types/workflow";

export type WorkflowPrerequisiteRemoveAction = {
  type: WorkflowsActionTypes["WORKFLOW_PREREQUISITE_REMOVE"]["SUCCESS"];
  payload: {workflow_id: WorkflowId; prerequisites: WorkflowInstance[]};
};

export default function removeWorkflowPrerequisite(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOW_PREREQUISITE_REMOVE,
    (
      organisationId: OrganisationId,
      workflowId: WorkflowId,
      workflowPrerequisiteId: WorkflowInstanceId,
    ) => {
      return requestor
        .delete(
          `${basePath}/organisation/${organisationId}/workflow/${workflowId}/prerequisite/${workflowPrerequisiteId}`,
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
