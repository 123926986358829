import React from "react";
import Done from "material-ui/svg-icons/action/done";
import Help from "material-ui/svg-icons/action/help";
import Clear from "material-ui/svg-icons/content/clear";

export default ({
  paramValue,
  positiveIcon: posIcon,
  negativeIcon: negIcon,
  ...props
}) => {
  const PositiveIcon = posIcon || Done;
  const NegativeIcon = negIcon || Clear;
  const Component =
    paramValue === true
      ? PositiveIcon
      : paramValue === false ? NegativeIcon : Help;
  return <Component {...props} />;
};
