import React from "react";

import ModulesListItem from "./modules_list_item";

const styles = {
  keyField: {
    display: "inline-block",
    width: "30%",
    marginLeft: 20,
    lineHeight: "50px",
  },
  valueField: {
    display: "inline-block",
    width: "55%",
    marginLeft: 20,
    lineHeight: "50px",
  },
  moduleRow: {
    borderBottom: "1px solid #ccc",
  },
};

const ModulesList = props => (
  <div>
    {props.templateModules.length > 0 && (
      <div
        style={Object.assign({}, styles.moduleRow, {
          display: "flex",
          flexDirection: "row",
          minHeight: 50,
        })}
      >
        <div style={Object.assign({}, styles.keyField, {fontWeight: "bold"})}>
          Key
        </div>
        <div style={Object.assign({}, styles.valueField, {fontWeight: "bold"})}>
          Value
        </div>
      </div>
    )}
    {props.templateModules
      .sort((module1, module2) => {
        if (module1.key < module2.key) {
          return -1;
        }
        if (module1.key > module2.key) {
          return 1;
        }
        return 0;
      })
      .map(module => (
        <ModulesListItem
          key={`module-${module.id}`}
          module={module}
          editId={props.editId}
          triggerEdit={props.triggerEdit}
          onCancel={props.onCancel}
          onSave={props.onSave}
          getModuleTopics={props.getModuleTopics}
          topicsById={props.topicsById}
          params={props.params}
          templateModules={props.templateModules}
        />
      ))}
  </div>
);

export default ModulesList;
