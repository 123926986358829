import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export type FilterOptions = {
  search_term?: string;
  user_id?: number;
};

export default function initDocument(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENTS_FETCH,
    (organisationId: number, projectId: number | string) => {
      return requestor
        .get(
          `${basePath}/organisation/${organisationId}/project/${projectId}/document/llm_state`,
          {withCredentials: true},
        )
        .then(response => ({
          ...response.data,
          projectId: parseInt(projectId as string, 10),
        }));
    },
  );
}
