import React, {FC, useMemo} from "react";
import {Grid} from "@material-ui/core";
import _ from "underscore";

import PropertyEditor from "./property_editor";
import AddItem from "../../add_item";

import {SchemaProperty} from "common/flowmaster/types/schema";
import {
  ActionDefinition,
  WorkflowTask,
} from "common/flowmaster/types/task_config";
import {
  Prompt,
  WorkflowContext,
  WorkflowInputs,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

interface PropertiesEditorProps {
  onChange: (properties: Record<string, SchemaProperty>) => void;
  properties?: Record<string, SchemaProperty>;
  isNested?: boolean;
  priorTasks: WorkflowTask[];
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  organisationId: OrganisationId;
}

const PropertiesEditor: FC<PropertiesEditorProps> = ({
  onChange,
  properties = {},
  isNested = false,
  priorTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  organisationId,
}) => {
  const handleRemoveProperty = (propertyName: string) => {
    const remainingProperties = _.omit(properties, propertyName);
    onChange(remainingProperties);
  };

  const onUpdate = (
    oldName: string,
    name: string,
    property: SchemaProperty,
  ) => {
    const updatedProps = {...properties};
    delete updatedProps[oldName];
    updatedProps[name] = property;
    onChange(updatedProps);
  };

  const onCreate = (name: string, property: SchemaProperty) => {
    if (!name.trim() || properties[name]) {
      return;
    }
    const newProperties = {
      ...properties,
      [name]: property,
    };
    onChange(newProperties);
  };

  const sortedProperties = useMemo(() => {
    return Object.entries(properties).sort((a, b) => a[0].localeCompare(b[0]));
  }, [properties]);

  return (
    <Grid container direction="column">
      {sortedProperties.map(([name, prop]) => (
        <PropertyEditor
          key={name}
          name={name}
          property={prop}
          onUpdate={(newName, property) => onUpdate(name, newName, property)}
          onRemove={() => handleRemoveProperty(name)}
          isNested={isNested}
          priorTasks={priorTasks}
          context={context}
          inputs={inputs}
          prompts={prompts}
          actionDefinitions={actionDefinitions}
          organisationId={organisationId}
        />
      ))}
      <Grid item style={{marginTop: "10px"}}>
        <AddItem
          onAdd={name => onCreate(name, {description: "", type: "string"})}
          label="New property name"
        />
      </Grid>
    </Grid>
  );
};

export default PropertiesEditor;
