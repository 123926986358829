import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

/* eslint-disable max-params */
export default function splitClause(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_CLAUSE_TOPIC_TOGGLE_EXACT_MATCH,
    (
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      topicId,
      exactMatchId,
      clauseTopicLastEdited,
    ) => {
      let request;
      const path =
        `${basePath}/organisation/${organisationId}` +
        `/project/${projectId}` +
        `/document/${documentId}/clause/${clauseId}/` +
        `clausepart/${clausepartId}/topic/${topicId}/exact_match`;
      if (!exactMatchId) {
        request = requestor.post(
          path,
          {
            last_edited: clauseTopicLastEdited,
          },
          {withCredentials: true},
        );
      } else {
        request = requestor["delete"](`${path}/${exactMatchId}`, {
          withCredentials: true,
          data: {
            last_edited: clauseTopicLastEdited,
          },
        });
      }
      return request.then(response => ({
        clause_id: clauseId,
        clausepart_id: clausepartId,
        topic_id: topicId,
        section_id: sectionId,
        ...response.data,
      }));
    },
  );
}
