import React from "react";
import _ from "underscore";

import {Link} from "react-router";
import Divider from "@material-ui/core/Divider";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const styles = {
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 14px",
    fontSize: 15,
    fontWeight: 400,
    border: "1px solid #e0e0e0",
    cursor: "pointer",
    color: "#232e38",
  },
  bodyContainer: {
    padding: "12px 14px",
    border: "1px solid #e0e0e0",
    borderTop: "none",
  },
  reference: {
    display: "inline-block",
    paddingRight: "0.5em",
    color: "#232e38",
    opacity: 0.5,
    fontSize: 14,
  },
  text: {
    display: "inline",
    color: "#232e38",
    opacity: 0.8,
    fontSize: 14,
  },
};

function ApplicableClauses(props) {
  const [isCollapsed, updateIsCollapsed] = React.useState(true);
  function triggerIsCollapsed() {
    updateIsCollapsed(!isCollapsed);
  }
  const {clauses: baseClauses} = props;

  if (!baseClauses || baseClauses.length === 0) {
    return null;
  }

  const clauses = _.sortBy(
    baseClauses,
    clause => `${clause.sort_reference}.${clause.reference}`,
  );

  function renderHeader() {
    const CollapseIcon = isCollapsed
      ? KeyboardArrowDownIcon
      : KeyboardArrowUpIcon;
    return (
      <div style={styles.headerContainer} onClick={triggerIsCollapsed}>
        <div>{`${clauses.length} Clause${clauses.length > 1 ? "s" : ""}`}</div>
        <CollapseIcon style={{height: 24, width: 24}} />
      </div>
    );
  }

  function getClauseLink(clause) {
    const {organisationId, projectId, documentId} = props;
    const reference = clause.reference ?? "";
    const refDotIndex = reference.indexOf(".");
    const clauseReference =
      refDotIndex > 0 ? reference.substring(0, refDotIndex) : reference;
    return (
      `/organisation/${organisationId}/project/${projectId}` +
      `/document/${documentId}/detail?clause=${clauseReference}`
    );
  }

  function renderBody() {
    if (isCollapsed) {
      return null;
    }
    return (
      <div style={styles.bodyContainer}>
        {clauses.map((clause, clauseIndex) => (
          <div key={`report-detail-applicable-clause-${clauseIndex}`}>
            <div style={styles.reference}>{clause.reference}</div>
            <Link
              className="document-link"
              to={getClauseLink(clause)}
              target="_blank"
              style={{textDecoration: "none"}}
            >
              <div style={styles.text}>{clause.partial_text}</div>
            </Link>
            {clauseIndex !== clauses.length - 1 && (
              <Divider style={{margin: "16px 0px"}} />
            )}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div style={{marginTop: 16}}>
      {renderHeader()}
      {renderBody()}
    </div>
  );
}

export default ApplicableClauses;
