import {makeUiOrderId} from "./make_ui_order_id";

export default function addUiOrderIds(issue) {
  const newIssue = JSON.parse(JSON.stringify(issue));
  addId(newIssue, "");
  return newIssue;
}

function addId(issue, path) {
  if (path === "") {
    issue.ui_order_id = "root";
  } else if (!issue.ui_order_id) {
    issue.ui_order_id = path;
  }
  if (issue.rules && issue.rules.issues && issue.rules.issues.length > 0) {
    issue.rules.issues.forEach((value, index) => {
      const childPath = makeUiOrderId(path, index);
      addId(issue.rules.issues[index], childPath);
    });
  }
}
