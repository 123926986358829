import basePath from "base_path";
import ActionTypes from "../../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function addDefinitionGroup(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.DEFINITION_GROUP_ADD,
    (organisationId, newDefinitionGroupData) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/definition_group/new`,
          newDefinitionGroupData,
          {withCredentials: true},
        )
        .then(response => ({
          ...response.data,
          ...newDefinitionGroupData,
        }))
        .catch(err => err.data);
    },
  );
  return action;
}
