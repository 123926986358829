import basePath from "base_path";
import requestor from "requestor";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default createReduxPromiseAction(
  ActionTypes.ISSUESET_UPDATE,
  (organisationId, contractTypeId, issuesetId, updates) =>
    requestor
      .patch(
        `${basePath}/organisation/${organisationId}` +
          `/contract_type/${contractTypeId}/issueset/${issuesetId}`,
        updates,
        {withCredentials: true},
      )
      .then(resp => resp.data),
);
