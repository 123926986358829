import React, {useEffect, useState} from "react";
import ReactSelect from "react-select";
import _ from "lodash";

import TextField from "@material-ui/core/TextField";
import {SketchPicker} from "react-color";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import IconButton from "material-ui/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import IconMenu from "common_components/icon_menu";
import ReactSelectLabel from "common_components/react_select_label";
import Aliases from "./aliases";
import usePrevious from "utils/hooks/use_previous";
import getColorByBgColor from "utils/get_color_by_bg_color";

const styles = {
  colorFill: {
    color: "#0000008a",
  },
  colorPicker: {
    display: "block",
    position: "absolute",
    top: "50px",
    right: "60px",
  },
};

export default function RoleDetailComponent(props) {
  const {role, roles, contractTypes} = props;

  // name logic
  const [nameError, setNameError] = useState("");

  const [isShowColorPicker, setIsShowColorPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#fff");
  const [textColor, setTextColor] = useState({color: "#000"});

  useEffect(() => {
    if (role.color) {
      setBackgroundColor(role.color);
      const textColor = getColorByBgColor(role.color);
      setTextColor({color: textColor});
    }
  }, []);

  const onNameUpdate = e => {
    const newName = e.target.value;
    if (newName === role.name) {
      return;
    }
    if (!newName) {
      return setNameError("You must enter a name");
    }
    const roleNameExists = Boolean(
      roles.find(role => role.name.toLowerCase() === newName.toLowerCase()),
    );
    if (roleNameExists) {
      return setNameError("Name already exists");
    }
    setNameError("");
    props.onRoleUpdate({name: newName});
  };
  const handleColorChange = (color) => {
    setBackgroundColor(color.hex);
    const textColor = getColorByBgColor(color.hex);
    setTextColor({color: textColor});
  };
  const openColorPicker = () => setIsShowColorPicker(true);
  const setRoleColor = () => {
    setIsShowColorPicker(false);
    props.onRoleUpdate({color: backgroundColor});
  };

  // delete logic
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);
  const showDeleteDialog = () => setIsDeleteDialogShown(true);
  const hideDeleteDialog = () => setIsDeleteDialogShown(false);

  // contract types logic
  const [stateRoleContractTypes, updateStateRoleContractTypes] = useState([
    ...(role.contract_types || []),
  ]);

  const stateRoleContractTypesPrev = usePrevious([...stateRoleContractTypes]);

  function contractTypesAdded(contractTypes) {
    props.onRoleUpdate({added_contract_types: contractTypes});
  }

  function contractTypesRemoved(contractTypes) {
    props.onRoleUpdate({removed_contract_types: contractTypes});
  }

  useEffect(
    () => {
      if (
        stateRoleContractTypesPrev &&
        stateRoleContractTypes &&
        !_.isEqual(stateRoleContractTypes, stateRoleContractTypesPrev)
      ) {
        if (stateRoleContractTypes.length > stateRoleContractTypesPrev.length) {
          const addedContractTypes = stateRoleContractTypes
            .filter(ct => !stateRoleContractTypesPrev.includes(ct))
            .map(ctId => {
              const contractType = contractTypes.find(ct => ct.id === ctId);
              return {contract_type_id: ctId, name: contractType.name};
            });
          contractTypesAdded(addedContractTypes);
        } else if (
          stateRoleContractTypesPrev.length > stateRoleContractTypes.length
        ) {
          const removedContractTypes = stateRoleContractTypesPrev
            .filter(ct => !stateRoleContractTypes.includes(ct))
            .map(ctId => {
              const contractType = contractTypes.find(ct => ct.id === ctId);
              return {contract_type_id: ctId, name: contractType.name};
            });
          contractTypesRemoved(removedContractTypes);
        }
      }
    },
    [stateRoleContractTypes],
  );

  function onStateContractTypesUpdate(newValues) {
    updateStateRoleContractTypes(newValues.map(role => role.id));
  }

  // aliases logic
  function onAliasesUpdate(aliases) {
    props.onRoleUpdate({aliases});
  }

  function toggleIsMutual() {
    props.onRoleUpdate({
      is_mutual: !role.is_mutual,
    });
  }

  // function

  return (
    <>
      <div className="app-toolbar">
        <TextField
          fullWidth
          helperText={nameError}
          error={Boolean(nameError)}
          defaultValue={role.name}
          onBlur={onNameUpdate}
          style={{backgroundColor}}
          inputProps={{style: textColor}}
        />
        <Button
          onClick={props.redirectToRoleList}
          style={{flexShrink: "0", margin: "0.5rem"}}
        >
          Roles
        </Button>
        <Tooltip
          title="Set color for the role"
          placement={"top"}
          arrow
        >
          <IconButton
            color="primary"
            style={styles.colorFill}
            onClick={openColorPicker}
          >
            <FormatColorFillIcon />
          </IconButton>
        </Tooltip>
        {isShowColorPicker ? (
          <ClickAwayListener onClickAway={setRoleColor}>
            <div style={styles.colorPicker}>
              <SketchPicker onChange={handleColorChange} color={backgroundColor}/>
            </div>
          </ClickAwayListener>
        ) : null}
        <IconMenu options={[{label: "Delete", handler: showDeleteDialog}]} />
      </div>
      <div style={{padding: "1.5rem"}}>
        <div style={{zIndex: 1, paddingBottom: "1rem"}}>
          <ReactSelectLabel>Contract Types</ReactSelectLabel>
          <ReactSelect
            className="contractTypes"
            valueKey="id"
            labelKey="name"
            multi={true}
            removeSelected={true}
            clearable={false}
            backspaceRemoves={false}
            deleteRemoves={false}
            closeOnSelect={false}
            allowCreate={false}
            value={getCurrentContractTypesValue(
              stateRoleContractTypes,
              contractTypes,
            )}
            options={getAllContractTypes(contractTypes)}
            onChange={onStateContractTypesUpdate}
            wrapperStyle={{width: "100%"}}
            menuContainerStyle={{zIndex: 99}}
          />
        </div>
        <Aliases
          aliases={role.aliases}
          isMutual={role.is_mutual}
          onAliasesUpdate={onAliasesUpdate}
          toggleIsMutual={toggleIsMutual}
        />
      </div>

      <Dialog fullWidth onClose={hideDeleteDialog} open={isDeleteDialogShown}>
        <DialogTitle>Confirm Role Deletion</DialogTitle>
        <DialogContentText style={{padding: "0 24px"}}>
          Are you sure you want to delete this Role?
        </DialogContentText>
        <DialogActions>
          <Button onClick={hideDeleteDialog}>Cancel</Button>
          <Button color="primary" onClick={props.onRoleRemove}>
            Delete Role
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function getCurrentContractTypesValue(roleContractTypes, contractTypes) {
  return roleContractTypes.map(ctId => {
    const contractType = contractTypes.find(ct => ct.id === ctId);
    return {id: contractType.id, name: contractType.name};
  });
}

function getAllContractTypes(contractTypes) {
  return contractTypes.map(ct => ({id: ct.id, name: ct.name}));
}
