import React, {useState, useRef, useEffect} from "react";
import _ from "lodash";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import WorkIcon from "@material-ui/icons/Work";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import * as colors from "material-ui/styles/colors";

import CheckboxBasic from "common_components/inputs/checkbox_basic";
import HotProjectChip from "common_components/project/hot_project_chip";
import byId from "common/utils/by_id";
import sessionStorage from "utils/session_storage";
import ContractTypes from "./contract_types";

const styles = {
  mainContainer: {
    display: "flex",
    height: 46,
    position: "absolute",
    right: 360,
    top: 74,
    zIndex: 9999,
    alignItems: "center",
  },
  menuRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: "0px 10px",
    userSelect: "none",
    minWidth: 180,
  },
  blueColor: "#2196f3",
  contractTypeBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 10px",
    cursor: "pointer",
  },
  workIconBlock: {
    display: "flex",
    alignItems: "center",
  },
  checkboxBlock: {
    padding: "6px 0px",
    borderBottom: "1px solid rgb(224, 224, 224)",
    display: "flex",
  },
  checkboxWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
  },
  hotProjectWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "calc(100% - 2em)",
  },
  projectNameBlock: {
    width: "calc(100% - 3em)",
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  coldBlock: {
    width: 30,
    flexShrink: 0,
    visibility: "hidden",
  },
  docCountBlock: {
    color: "#bdbdbd",
    textAlign: "right",
  },
  greyColor: "#757575",
  lightGreyColor: "#9AA0A7",
  darkGreyColor: "#444444",
  clearIcon: {
    cursor: "pointer",
    color: "#616161",
  },
  menuListBlock: {
    maxHeight: 356,
    overflow: "auto",
  },
  selectedProjects: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 280,
    width: "100%",
    fontSize: 14,
  },
  contractTypesBlock: {
    borderRadius: 0,
  },
  tableWrapper: {
    borderCollapse: "collapse",
    fontSize: "14px",
    color: colors.grey800,
    marginLeft: "15px",
  },
};

function HotProjectSelector(props) {
  const {
    projects: baseProjects,
    topic,
    contractTypesById,
    organisationId,
    showContractType,
    refetchTopic,
    initSelectedContractTypeIds,
  } = props;
  const [haveChanges, updateHaveChanges] = useState(false);
  const [open, setOpen] = useState(false);
  const [projectNameFilter, updateProjectNameFilter] = useState("");
  const [openContractType, setOpenContractType] = useState(false);
  const [selectedContractTypeIds, setSelectedContractTypeIds] = useState(null);

  const projects = baseProjects.filter(project => !project.is_archived);
  const projectsById = byId(projects);
  const anchorRef = useRef(null);
  const anchorContractTypeRef = useRef(null);

  useEffect(() => {
    setSelectedContractTypeIds(initSelectedContractTypeIds);
  }, []);

  const updateSessionStorageProjectsUsed = newProjectsUsed => {
    sessionStorage.setItem(
      "projects_used",
      Object.keys(newProjectsUsed).map(item => parseInt(item, 10)),
    );
  };

  const updateSessionStorageUseHotProjects = newValue => {
    sessionStorage.setItem("use_hot_projects", newValue);
  };

  const constructProjectName = name => {
    const slashArr = name.split("/");
    if (slashArr.length > 3) {
      const left = slashArr[0].trim().slice(0, 11);
      const right = slashArr[slashArr.length - 1].trim().slice(0, 11);
      return [left, "...", right].join(" / ");
    }
    return name;
  };

  const updateSelectedContractTypes = selectedContractTypeIds => {
    setSelectedContractTypeIds(selectedContractTypeIds);
    refetchTopic(selectedContractTypeIds);
  };

  const runPostHandlerIfHaveChanges = () => {
    if (open && props.onCloseHandler && haveChanges) {
      props.onCloseHandler();
    }
    updateHaveChanges(false);
  };

  const handleOpenToggle = () => {
    runPostHandlerIfHaveChanges();
    setOpen(() => !open);
    setOpenContractType(false);
  };

  const handleOpenContractTypeToggle = () => {
    setOpenContractType(() => !openContractType);
    setOpen(false);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    runPostHandlerIfHaveChanges();
    setOpen(false);
  };

  const handleContractTypeClose = () => {
    setOpenContractType(false);
  };

  const [selectedProjects, updateSelectedProjects] = useState(
    (sessionStorage.getItem("projects_used") || []).reduce((result, item) => {
      result[item] = true;
      return result;
    }, {}),
  );

  const [selectAllHot, updateSelectAllHot] = useState(
    typeof sessionStorage.getItem("use_hot_projects") === "boolean"
      ? sessionStorage.getItem("use_hot_projects")
      : true,
  );

  const triggerProjectSelect = id => () => {
    const isProjectHot = projectsById[id].is_hot;
    if (isProjectHot) {
      if (selectAllHot) {
        const allOtherHotProjectIds = projects.reduce((result, project) => {
          if (project.is_hot && project.id !== id) {
            result[project.id] = true;
          }
          return result;
        }, {});

        const selectedColdProjects = Object.keys(selectedProjects).reduce(
          (result, projectId) => {
            const project = projectsById[projectId];
            if (selectedProjects[projectId] && !project.is_hot) {
              result[projectId] = true;
            }
            return result;
          },
          {},
        );

        const projectsToUpdate = {
          ...allOtherHotProjectIds,
          ...selectedColdProjects,
        };

        updateSelectAllHot(false);
        updateSessionStorageUseHotProjects(false);

        updateSelectedProjects(projectsToUpdate);
        updateSessionStorageProjectsUsed(projectsToUpdate);
      } else {
        const unseledtedHotProjectsArr = projects.reduce((result, project) => {
          if (project.is_hot && !selectedProjects[project.id]) {
            result.push(project.id);
          }
          return result;
        }, []);

        const selectedColdProjectsObj = Object.keys(selectedProjects).reduce(
          (result, projectId) => {
            if (
              !projectsById[projectId].is_hot &&
              selectedProjects[projectId]
            ) {
              result[projectId] = true;
            }

            return result;
          },
          {},
        );

        if (
          unseledtedHotProjectsArr.length === 1 &&
          unseledtedHotProjectsArr[0] === id
        ) {
          updateSelectAllHot(true);
          updateSessionStorageUseHotProjects(true);

          updateSelectedProjects(selectedColdProjectsObj);
          updateSessionStorageProjectsUsed(selectedColdProjectsObj);
        } else {
          const newSelectedProjects = {...selectedProjects};
          if (selectedProjects[id]) {
            delete newSelectedProjects[id];
          } else {
            newSelectedProjects[id] = true;
          }

          updateSessionStorageProjectsUsed(newSelectedProjects);
          updateSelectedProjects(newSelectedProjects);
        }
      }
    } else {
      const newSelectedProjects = {...selectedProjects};
      if (selectedProjects[id]) {
        delete newSelectedProjects[id];
      } else {
        newSelectedProjects[id] = true;
      }

      updateSessionStorageProjectsUsed(newSelectedProjects);
      updateSelectedProjects(newSelectedProjects);
    }
    updateHaveChanges(true);
  };

  const triggerSelectAllHot = () => {
    updateSessionStorageUseHotProjects(!selectAllHot);
    updateHaveChanges(true);
    if (!selectAllHot) {
      const newSelectedProjects = Object.keys(selectedProjects).reduce(
        (result, projectId) => {
          const project = projectsById[projectId];
          if (!project.is_hot) {
            result[projectId] = true;
          }
          return result;
        },
        {},
      );

      updateSelectedProjects(newSelectedProjects);
      updateSessionStorageProjectsUsed(newSelectedProjects);
    }
    updateSelectAllHot(!selectAllHot);
  };

  const areAllColdSelected = projects.reduce(
    (result, project) =>
      !result
        ? result
        : !project.is_hot ? Boolean(selectedProjects[project.id]) : result,
    true,
  );

  const triggerSelectAllCold = () => {
    updateHaveChanges(true);
    const newSelectedProjects = {...selectedProjects};
    if (areAllColdSelected) {
      projects.forEach(project => {
        if (!project.is_hot) {
          delete newSelectedProjects[project.id];
        }
      });
    } else {
      projects.forEach(project => {
        if (!project.is_hot) {
          newSelectedProjects[project.id] = true;
        }
      });
    }
    updateSessionStorageProjectsUsed(newSelectedProjects);
    updateSelectedProjects(newSelectedProjects);
  };

  const onProjectNameFilterChange = e =>
    updateProjectNameFilter(e.target.value);
  const onProjectNameFilterClear = () => updateProjectNameFilter("");

  const shownProjects = projectNameFilter
    ? projects.filter(
        project =>
          project.name
            .toLowerCase()
            .indexOf(projectNameFilter.toLowerCase()) !== -1,
      )
    : projects;

  return (
    <div style={styles.mainContainer}>
      <div
        ref={anchorRef}
        onClick={handleOpenToggle}
        style={{
          ...styles.menuRoot,
          background: open ? "#eeeeee" : "initial",
        }}
      >
        <div style={styles.workIconBlock}>
          <WorkIcon
            style={{
              marginRight: 8,
              color: open ? styles.darkGreyColor : styles.lightGreyColor,
            }}
          />
          <div>
            {constructSelectorMessage(projects, selectedProjects, selectAllHot)}
          </div>
        </div>
        {open ? (
          <ArrowDropUpIcon style={{color: styles.darkGreyColor}} />
        ) : (
          <ArrowDropDownIcon style={{color: styles.lightGreyColor}} />
        )}
      </div>
      {props.showContractType ? (
        <div
          ref={anchorContractTypeRef}
          style={{
            ...styles.contractTypeBlock,
            background: openContractType ? "#eeeeee" : "initial",
          }}
          onClick={handleOpenContractTypeToggle}
        >
          <div>Contract Type</div>
          {openContractType ? (
            <ArrowDropUpIcon style={{color: styles.darkGreyColor}} />
          ) : (
            <ArrowDropDownIcon style={{color: styles.lightGreyColor}} />
          )}
        </div>
      ) : null}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        disablePortal={true}
        placement="bottom-start"
        style={{zIndex: 1}}
        modifiers={{preventOverflow: {enabled: false}}}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper style={{borderRadius: 0, minWidth: 360}}>
            <div>
              <TextField
                value={projectNameFilter}
                onChange={onProjectNameFilterChange}
                placeholder="Project Name"
                fullWidth
                InputProps={{
                  style: {padding: "4px 6px"},
                  endAdornment: (
                    <InputAdornment position="end">
                      <ClearIcon
                        onClick={onProjectNameFilterClear}
                        style={styles.clearIcon}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div style={styles.checkboxBlock}>
              <CheckboxBasic
                label="Select All Hot"
                checked={selectAllHot}
                onCheck={triggerSelectAllHot}
                iconStyles={{
                  width: 20,
                  height: 20,
                  color: selectAllHot ? styles.blueColor : styles.greyColor,
                }}
                labelStyles={{
                  fontSize: 14,
                  color: selectAllHot ? styles.blueColor : styles.greyColor,
                }}
              />
              <CheckboxBasic
                label="Select All Cold"
                checked={areAllColdSelected}
                onCheck={triggerSelectAllCold}
                iconStyles={{
                  width: 20,
                  height: 20,
                  color: areAllColdSelected
                    ? styles.blueColor
                    : styles.greyColor,
                }}
                labelStyles={{
                  fontSize: 14,
                  color: areAllColdSelected
                    ? styles.blueColor
                    : styles.greyColor,
                }}
              />
            </div>
            <MenuList style={styles.menuListBlock}>
              {_.sortBy(shownProjects, project =>
                project.name.toLowerCase(),
              ).map(project => (
                <MenuItem
                  key={project.id}
                  style={{padding: 0}}
                  onClick={triggerProjectSelect(project.id)}
                >
                  <div style={styles.checkboxWrapper}>
                    <CheckboxBasic
                      checked={
                        project.is_hot
                          ? selectAllHot || selectedProjects[project.id]
                          : selectedProjects[project.id]
                      }
                      iconStyles={{
                        width: 20,
                        height: 20,
                        color: project.is_hot
                          ? selectAllHot
                            ? styles.greyColor
                            : selectedProjects[project.id]
                              ? styles.blueColor
                              : styles.greyColor
                          : selectedProjects[project.id]
                            ? styles.blueColor
                            : styles.greyColor,
                      }}
                      labelStyles={{
                        fontSize: 14,
                        color: selectAllHot
                          ? styles.greyColor
                          : selectedProjects[project.id]
                            ? styles.blueColor
                            : styles.greyColor,
                      }}
                    />
                    <div style={styles.hotProjectWrapper}>
                      <div
                        style={{
                          ...styles.selectedProjects,
                          color: selectedProjects[project.id]
                            ? styles.blueColor
                            : styles.greyColor,
                        }}
                        title={project.name}
                      >
                        <div style={styles.projectNameBlock}>
                          <span>{constructProjectName(project.name)}</span>
                        </div>
                        &nbsp;
                        <span style={styles.docCountBlock}>
                          ({project.documents_count})
                        </span>
                      </div>
                      {project.is_hot ? (
                        <HotProjectChip />
                      ) : (
                        <div style={styles.coldBlock}>Cold</div>
                      )}
                    </div>
                  </div>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
      {showContractType ? (
        <Popper
          open={openContractType}
          anchorEl={anchorContractTypeRef.current}
          disablePortal={true}
          placement="bottom-start"
          style={{zIndex: 1}}
          modifiers={{preventOverflow: {enabled: false}}}
        >
          <ClickAwayListener onClickAway={handleContractTypeClose}>
            <Paper style={styles.contractTypesBlock}>
              <ContractTypes
                selectedContractTypeIds={selectedContractTypeIds}
                contractTypesById={contractTypesById}
                topic={topic}
                organisationId={organisationId}
                updateSelectedContractTypes={updateSelectedContractTypes}
              />
            </Paper>
          </ClickAwayListener>
        </Popper>
      ) : null}
    </div>
  );
}

function constructSelectorMessage(projects, selectedProjects, selectAllHot) {
  const {coldSelected, hotSelected} = projects.reduce(
    (result, project) => {
      if (selectedProjects[project.id]) {
        if (project.is_hot) {
          result.hotSelected += 1;
        } else {
          result.coldSelected += 1;
        }
      }
      return result;
    },
    {coldSelected: 0, hotSelected: 0},
  );
  return `${
    selectAllHot ? "All" : hotSelected === 0 ? "No" : hotSelected
  } Hot & ${coldSelected} Cold`;
}

export default HotProjectSelector;
