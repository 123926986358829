import React, {useState} from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";

import ConfirmDialog from "common_components/confirm_dialog";

import Expando from "./expando";
import ActionArgs from "./action_args";

import {
  Workflow,
  WorkflowContext,
  WorkflowInputs,
  Prompt,
} from "common/flowmaster/types/workflow";
import {
  WorkflowTask,
  ActionDefinition,
  TaskArgument,
} from "common/flowmaster/types/task_config";
import {DataLocation} from "common/flowmaster/types/data_location";
import {OrganisationId} from "common/types/organisation";
import {Topic} from "common/types/topic";

const ParentMenuItem = styled(MenuItem)`
  font-weight: bold;
`;
const ChildMenuItem = styled(MenuItem)`
  margin-left: 1em;
`;

interface TaskProps {
  task: WorkflowTask;
  onUpdateItem: (data: Record<string, unknown>) => void;
  onDeleteItem: () => void;
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  priorTasks: WorkflowTask[];
  futureTasks: WorkflowTask[];
  workflows: Workflow[];
  organisationId: OrganisationId;
  embeddingTopics: Topic[];
  forceOpen?: boolean;
}

interface TaskEditorProps extends Omit<TaskProps, "onUpdateItem"> {
  onUpdateArg: (index: number, value: TaskArgument) => void;
  onUpdateName: (name: string) => void;
  onUpdateAction: (newAction: string) => void;
}

const Task: React.FC<TaskProps> = ({
  task,
  onUpdateItem,
  onDeleteItem,
  context,
  inputs,
  prompts,
  actionDefinitions,
  workflows,
  priorTasks,
  futureTasks,
  organisationId,
  embeddingTopics,
  forceOpen,
}) => {
  const [localTask, setLocalTask] = useState<WorkflowTask>(task);

  const handleArgUpdate = (index: number, value: TaskArgument) => {
    setLocalTask(prevTask => {
      const updatedTask = {
        ...prevTask,
        args: prevTask.args.map((arg, i) => (i === index ? value : arg)),
      };
      onUpdateItem(updatedTask);
      return updatedTask;
    });
  };

  const handleNameUpdate = (name: string) => {
    setLocalTask(prevTask => {
      const updatedTask = {...prevTask, name};
      onUpdateItem(updatedTask);
      return updatedTask;
    });
  };

  const handleActionUpdate = (newAction: string) => {
    setLocalTask(prevTask => {
      const newArgs = initialiseArgs(newAction, actionDefinitions);
      if (newArgs) {
        const updatedTask = {
          ...prevTask,
          action: newAction,
          args: newArgs as TaskArgument[],
        };
        onUpdateItem(updatedTask as Record<string, unknown>);
        return updatedTask;
      }
      return prevTask;
    });
  };

  return (
    <Expando beginExpanded={forceOpen}>
      <TaskSummary task={localTask} futureTasks={futureTasks} />
      <TaskEditor
        task={localTask}
        onUpdateArg={handleArgUpdate}
        onUpdateName={handleNameUpdate}
        onUpdateAction={handleActionUpdate}
        onDeleteItem={onDeleteItem}
        actionDefinitions={actionDefinitions}
        workflows={workflows}
        priorTasks={priorTasks}
        futureTasks={futureTasks}
        context={context}
        inputs={inputs}
        prompts={prompts}
        organisationId={organisationId}
        embeddingTopics={embeddingTopics}
      />
    </Expando>
  );
};

interface TaskSummaryProps {
  task: WorkflowTask;
  futureTasks: WorkflowTask[];
}
function TaskSummary({task, futureTasks}: TaskSummaryProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        style={{display: "flex", flexDirection: "row", alignItems: "center"}}
      >
        <Chip style={{marginRight: "0.5em"}} size="small" label={task.id} />
        <span>{task.name}</span>
      </div>
      <UsedBy task={task} futureTasks={futureTasks} />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  helpTooltip: {
    maxWidth: "50em",
  },
}));
const TaskEditor: React.FC<TaskEditorProps> = ({
  task,
  onUpdateArg,
  onUpdateName,
  onUpdateAction,
  onDeleteItem,
  context,
  inputs,
  prompts,
  actionDefinitions,
  workflows,
  priorTasks,
  futureTasks,
  organisationId,
  embeddingTopics,
}) => {
  const [showingDeleteDialog, showDeleteDialog] = useState<boolean>(false);
  const [name, setName] = useState<string>(task.name);

  const selectedActionDefinition = actionDefinitions.find(
    actionDefinition => actionDefinition.key === task.action,
  );

  const classes = useStyles();
  return (
    <div style={{width: "100%"}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
          <Chip style={{marginRight: "0.5em"}} size="small" label={task.id} />
          <TextField
            style={{width: "25em"}}
            value={name}
            label="Name"
            onChange={event => setName(event.target.value)}
            onBlur={() => onUpdateName(name)}
          />
          <FormControl>
            <InputLabel>Action</InputLabel>
            <Select
              value={task.action}
              onChange={event => onUpdateAction(event.target.value as string)}
            >
              {generateMenuItems(actionDefinitions)}
            </Select>
          </FormControl>
          {selectedActionDefinition ? (
            <Tooltip
              classes={{tooltip: classes.helpTooltip}}
              leaveDelay={10 * 1000}
              title={
                <div style={{fontSize: "1.5em"}}>
                  <pre style={{whiteSpace: "pre-wrap", lineHeight: "1.2"}}>
                    {selectedActionDefinition.help}
                  </pre>
                </div>
              }
            >
              <HelpIcon style={{marginLeft: "1em", color: "#888"}} />
            </Tooltip>
          ) : null}
        </div>
        <UsedBy task={task} futureTasks={futureTasks} />
      </div>

      <fieldset style={{margin: "0.5em 0"}}>
        <legend style={{fontStyle: "italic", color: "#555"}}>Arguments</legend>
        {selectedActionDefinition?.args?.map((argDefinition, index) => {
          return (
            <div
              key={index}
              style={{
                backgroundColor: "#bbb",
                margin: "0.5em",
                padding: "0.5em",
              }}
            >
              {renderActionArgument(
                argDefinition,
                task.args[index],
                index,
                value => onUpdateArg(index, value),
                priorTasks,
                futureTasks,
                context,
                inputs,
                prompts,
                actionDefinitions,
                workflows,
                organisationId,
                embeddingTopics,
              )}
            </div>
          );
        })}
      </fieldset>
      <div style={{display: "flex", justifyContent: "flex-end"}}>
        <Button variant="contained" onClick={() => showDeleteDialog(true)}>
          Delete
        </Button>
      </div>
      <ConfirmDialog
        open={showingDeleteDialog}
        onSuccess={() => onDeleteItem()}
        onClose={() => showDeleteDialog(false)}
        title="Delete Input"
        description="Are you sure you want to delete this task?"
        okButtonCaption="Yes"
        cancelButtonCaption="No"
      />
    </div>
  );
};

function isDataLocationString(value: unknown): value is DataLocation<string> {
  if (typeof value !== "object" || value === null) {
    return false;
  }
  if ("source_type" in value && value.source_type === "task") {
    return true;
  }
  return false;
}

function isDataLocationTask<T>(task, dataLocation: DataLocation<T>): boolean {
  if (dataLocation?.source_type === "task") {
    return dataLocation?.task === task.id;
  }
  return false;
}

function UsedBy({task, futureTasks}: TaskSummaryProps) {
  const usedBy = futureTasks.filter(
    item =>
      Array.isArray(item?.args) &&
      item?.args?.find(arg => {
        return (
          arg?.value &&
          typeof arg?.value === "object" &&
          (("source_type" in arg.value &&
            isDataLocationString(arg.value) &&
            isDataLocationTask(task, arg?.value)) ||
            Object.values(arg.value).find(item =>
              isDataLocationTask(task, item),
            ) ||
            ("inputValues" in arg.value &&
              Object.values(arg.value?.inputValues).find(item =>
                isDataLocationTask(task, item),
              )))
        );
      }),
  );

  return usedBy.length ? (
    <span
      style={{
        marginLeft: "1em",
        fontStyle: "italic",
        color: "#666",
      }}
    >
      Used by: {usedBy.map(item => `"${item.name}"`).join(", ")}
    </span>
  ) : null;
}

function initialiseArgs(action: string, actionDefinitions: ActionDefinition[]) {
  const actionDefinition = actionDefinitions.find(({key}) => key === action);
  const val = actionDefinition?.args?.map(arg => ActionArgs[arg.type].default);
  return val;
}

function renderActionArgument(
  argDefinition,
  argValue,
  index: number,
  onUpdateItem,
  priorTasks,
  futureTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  workflows,
  organisationId,
  embeddingTopics,
) {
  return (
    <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
      <div
        style={{
          marginRight: "1em",
          flexShrink: 0,
          minWidth: "8em",
          marginTop: "1em",
          fontVariant: "small-caps",
        }}
      >
        {argDefinition.label}
      </div>
      <div style={{width: "100%"}}>
        {renderValue(
          argDefinition,
          argValue,
          index,
          onUpdateItem,
          priorTasks,
          futureTasks,
          context,
          inputs,
          prompts,
          actionDefinitions,
          workflows,
          organisationId,
          embeddingTopics,
        )}
      </div>
    </div>
  );
}

function generateMenuItems(actionDefinitions) {
  const groupedActionDefinitions = actionDefinitions.reduce(
    (groups, action) => {
      groups[action.group] = groups[action.group] ?? [];
      groups[action.group].push(action);
      return groups;
    },
    {},
  );
  const menuItems = Object.keys(groupedActionDefinitions)
    .sort((a, b) => {
      const isAExternal = a.startsWith("External");
      const isBExternal = b.startsWith("External");

      if (isAExternal && !isBExternal) {
        return -1; // a comes before b
      } else if (!isAExternal && isBExternal) {
        return 1; // b comes before a
      } else if (isAExternal && isBExternal) {
        return a.localeCompare(b); // both are external, sort alphabetically
      } else {
        return a.localeCompare(b); // both are non-external, sort alphabetically
      }
    })
    .map(group => [
      <ParentMenuItem key={group} value={group}>
        {group}
      </ParentMenuItem>,

      ...groupedActionDefinitions[group]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(action => (
          <ChildMenuItem key={action.key} value={action.key}>
            {action.name}
          </ChildMenuItem>
        )),
    ])
    .flat();
  return menuItems;
}

function renderValue(
  argDefinition,
  argValue,
  index: number,
  onUpdateItem,
  priorTasks,
  futureTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  workflows,
  organisationId,
  embeddingTopics,
) {
  const {Component} = ActionArgs[argDefinition.type];
  if (Component) {
    return (
      <Component
        key={`${argDefinition.key}_${index}`}
        {...{
          argDefinition,
          argValue,
          onUpdateItem,
          priorTasks,
          futureTasks,
          context,
          inputs,
          prompts,
          actionDefinitions,
          workflows,
          organisationId,
          embeddingTopics,
        }}
      />
    );
  }
}

export default Task;
