import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import requestor from "requestor";

import ResetPasswordComponent from "../components/reset_password";
import resetPassword from "modules/user/actions/user_password_reset";

class ResetPassword extends React.Component {
  render() {
    return (
      <ResetPasswordComponent
        onPasswordReset={this.onPasswordReset}
        redirectToLogin={this.redirectToLogin}
      />
    );
  }
  onPasswordReset = password => {
    return this.props.resetPassword(password, this.props.params.resetToken);
  };
  redirectToLogin = () => this.props.router.push("/login");
}

function select() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetPassword: resetPassword(requestor),
    },
    dispatch,
  );
}

export default connect(select, mapDispatchToProps)(ResetPassword);
