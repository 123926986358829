import _ from "underscore";
import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";
import {buildQuery} from "utils/uri";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {LlmRunInfo, LlmRunFilters} from "common/flowmaster/types/llm_run";

export type GetLlmRunsAction = {
  type: WorkflowsActionTypes["LLM_RUNS_FETCH"]["SUCCESS"];
  payload: LlmRunInfo[];
  action: (
    organisationId: OrganisationId,
    filters: LlmRunFilters,
    max_runs: number,
  ) => LlmRunInfo[];
};

function pickFilter(value) {
  return Array.isArray(value) ? value.length > 0 : Boolean(value);
}

export default function getLlmRuns(requestor) {
  return createReduxPromiseAction(
    ActionTypes.LLM_RUNS_FETCH,
    (
      organisationId: OrganisationId,
      filters: LlmRunFilters,
      maxRuns: number,
    ): LlmRunInfo[] => {
      const query = buildQuery({
        ..._.pick({...filters}, pickFilter),
        max_runs: maxRuns,
      });
      return requestor
        .get(`${basePath}/organisation/${organisationId}/llm_run${query}`, {
          withCredentials: true,
        })
        .then(response => {
          return response.data;
        });
    },
  );
}
