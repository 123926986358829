import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function documentPrerequisiteWorkflowRun(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PREREQUISITE_WORKFLOW_RUN,
    (organisationId, projectId, documentId, workflowInstanceId) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}` +
            `/document/${documentId}` +
            `/workflow_instance/${workflowInstanceId}`,
          {},
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
