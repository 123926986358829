import React from "react";

import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default function Settings({tableMode, setTableMode}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "1em",
        borderBottom: "1px solid #ccc",
      }}
    >
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Combined</Grid>
          <Grid item>
            <Switch
              color="primary"
              checked={tableMode === "separate"}
              onChange={() =>
                setTableMode(tableMode === "separate" ? "combined" : "separate")
              }
            />
          </Grid>
          <Grid item>Separate</Grid>
        </Grid>
      </Typography>
    </div>
  );
}
