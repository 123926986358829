import requestor from "requestor";

import BaseRoute from "utils/base_route";

import ChildRenderer from "common_components/child_renderer";

import ConfigDetail from "../config_detail";

import permissionGroupsFetchAction from "modules/permissions/actions/permission_groups_fetch";

export default class ConfigRoute extends BaseRoute {
  constructor(store) {
    super("config", store);
  }

  createChildRoutes() {
    return [new ConfigDetail(this.store)];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData() {
    this.fetchPermissionGroups();
  }

  fetchPermissionGroups() {
    this.store.dispatch(permissionGroupsFetchAction(requestor)());
  }
}
