const defaultErrorText = "Mismatched curly braces - should be triple";

export default (text, defaultValue = null, errorText = defaultErrorText) => {
  if (typeof text === "string") {
    try {
      validate(text);
    } catch (error) {
      return error.message || errorText;
    }
  }

  return defaultValue;
};

const braceOpens = {
  "}": "{",
  ")": "(",
  "]": "[",
};
const braces = ["{", "}", "(", ")", "[", "]"];
function isOpen(brace) {
  return brace === "{" || brace === "[" || brace === "(";
}

function validate(expression) {
  const stack = [];
  let position = 0;

  while (position < expression.length) {
    const symbol = expression[position];
    if (braces.find(brace => brace === symbol)) {
      if (isOpen(symbol)) {
        stack.push(symbol);
      } else {
        const prevBrace = stack.pop();
        if (prevBrace !== braceOpens[symbol]) {
          throw new Error(
            `Closing brace ${symbol} in position ${position} is missing opening brace`,
          );
        }
      }
    }
    position += 1;
  }

  if (!((expression.split(/"/).length - 1) % 2 === 0)) {
    throw new Error("Quote is not closed");
  }

  if (stack.length > 0) {
    throw new Error(`Braces ${stack.join(", ")} were not closed`);
  }
}
