import generateTemplate from "../../../../utils/generate_template_string";

const getRegexWithModules = (reText, modules, values) => {
  let modulesValues = values || [];
  if (!values && modules) {
    modulesValues = modules.reduce((acc, module) => {
      acc[module.key] = module.value[0];
      return acc;
    }, {});
  }
  return generateTemplate(reText, modulesValues);
};

export default getRegexWithModules;
