import React, {useState} from "react";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";

function TabPanel(props) {
  const {children, value, index} = props;
  return (
    <Paper
      style={{
        flexGrow: value === index ? 1 : 0,
        overflow: "auto",
      }}
      elevation={1}
      square
    >
      {value === index && children}
    </Paper>
  );
}

function TopicAnalysisTabs(props) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      // change tab to Classifiers
      props.clearLogs();
    }
    setValue(newValue);
  };

  return (
    <>
      <AppBar
        position="static"
        color="default"
        style={{display: "flex", flexDirection: "row", alignItems: "center"}}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          style={{flexGrow: 1}}
        >
          <Tab label="Classifiers" />
          <Tab label="Logs" />
        </Tabs>
        <div className="tabs-toolbar" />
      </AppBar>
      <TabPanel value={value} index={0}>
        {props.classifiersTab}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {props.logsTab}
      </TabPanel>
    </>
  );
}

export default TopicAnalysisTabs;
