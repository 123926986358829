import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {buildQuery} from "utils/uri";

export default function fetchTopicParameterStats(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPIC_PARAMETER_STATS_FETCH,
    (organisationId, topicId, params) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}/topic/${topicId}/parameter/stats${buildQuery(
            params,
          )}`,
          {
            withCredentials: true,
          },
        )
        .then(response => response.data),
  );
}
