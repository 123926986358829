import React from "react";

import ReportsTable from "./reports_table";
import ReportsSummary from "./summary";

import getReportIssueStats from "../utils/reports/get_issue_stats";
import CircularProgress from "@material-ui/core/CircularProgress";

import UNINITIALISED from "utils/uninitialised";

const styles = {
  noReportsMessage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontSize: "22px",
    fontWeight: "500",
  },
};

function Reports(props) {
  React.useState(() => {
    props.fetchReports();
  }, []);

  if (props.reports === UNINITIALISED || !props.reports) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (props.reports.reports.length === 0) {
    return <div style={styles.noReportsMessage}>No reports found.</div>;
  }

  return (
    <div
      style={{
        backgroundColor: "#FAFBFD",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {props.reports?.settings?.hideSummary ? null : (
        <ReportsSummary
          data={getReportIssueStats(
            props.reports.reports,
            props.projectId,
            props.issueset,
            props.showRagScore,
          )}
        />
      )}
      <ReportsTable
        reports={props.reports}
        projectId={props.projectId}
        fetchReports={props.fetchReports}
        organisationId={props.organisationId}
        issueset={props.issueset}
        user={props.user}
        showReportDetail={props.showReportDetail}
        showRagScore={props.showRagScore}
      />
    </div>
  );
}

export default Reports;
