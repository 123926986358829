import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {WorkflowInfo} from "common/flowmaster/types/workflow";

export type GetWorkflowsAction = {
  type: WorkflowsActionTypes["WORKFLOWS_FETCH"]["SUCCESS"];
  payload: WorkflowInfo[];
};

export default function getWorkflows(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOWS_FETCH,
    (organisationId: OrganisationId) => {
      return requestor
        .get(`${basePath}/organisation/${organisationId}/workflow`, {
          withCredentials: true,
        })
        .then(response => {
          return response.data;
        });
    },
  );
}
