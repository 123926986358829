import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function topicsConfrimAll(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_TOPICS_CONFIRM_ALL,
    (organisationId, projectId, documentId) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}/project/${projectId}/document/${documentId}/confirm-topics`,
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
