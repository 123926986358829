import React from "react";
import {TextField} from "@material-ui/core";

import {WorkflowInput} from "common/flowmaster/types/workflow";
import {CSSProperties} from "@material-ui/core/styles/withStyles";

interface StringInputEditorProps {
  input: WorkflowInput;
  value: string;
  handleInputChange: (e: React.ChangeEvent<{value: string}>) => void;
  handleInputBlur: () => void;
  style?: CSSProperties;
}
const StringInputEditor: React.FC<StringInputEditorProps> = ({
  input,
  value,
  handleInputChange,
  handleInputBlur,
  style,
}) => {
  return (
    <TextField
      multiline
      key={input.id}
      label={input.label}
      value={value}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      style={style}
    />
  );
};

export default {
  label: "String",
  component: StringInputEditor,
};
