import _ from "underscore";
import generateTemplate from "utils/generate_template_string";

function getMatchLocation(text, props, selectedRegex) {
  const {templateModules, regexClassifier: classifier} = props;
  const modulesValues = templateModules.reduce((acc, module) => {
    acc[module.key] = module.value[0];
    return acc;
  }, {});
  if (!classifier || !classifier.value || !classifier.value.whitelist) {
    return [];
  }
  const matches = [];
  classifier.value.whitelist.forEach((reText, index) => {
    try {
      if (!reText || reText.length === 0) {
        return;
      }
      const regexWithModules = generateTemplate(reText, modulesValues);
      const regex = new RegExp(regexWithModules, "g");
      {
        let match;
        while ((match = regex.exec(text.replace(/\n/g, " ")))) {
          matches.push({
            ...match,
            type: "white",
            cIndex: index,
            reText,
            regexWithModules,
          });
          if (matches.length > 100) {
            break;
          }
        }
      }
    } catch {
      // ignore bad regexes
    }
  });
  if (classifier.value.blacklist) {
    classifier.value.blacklist.forEach((reText, index) => {
      try {
        if (!reText || reText.length === 0) {
          return;
        }
        const regexWithModules = generateTemplate(reText, modulesValues);
        const regex = new RegExp(regexWithModules, "g");
        {
          let match;
          while ((match = regex.exec(text.replace(/\n/g, " ")))) {
            matches.push({
              ...match,
              type: "black",
              cIndex: index,
              reText,
              regexWithModules,
            });
            if (matches.length > 100) {
              break;
            }
          }
        }
      } catch {
        // ignore bad regexes
      }
    });
  }
  let result = _.sortBy(matches, match => match.index);
  if (selectedRegex) {
    const selectedRegexMatchIndex = result.findIndex(
      resultItem => resultItem.reText === selectedRegex,
    );
    if (selectedRegexMatchIndex !== -1) {
      const selectedItem = result[selectedRegexMatchIndex];
      const remainingItems = result.filter(
        (item, itemIndex) => itemIndex !== selectedRegexMatchIndex,
      );
      result = [selectedItem].concat(remainingItems);
    }
  }
  return result;
}

export default getMatchLocation;
