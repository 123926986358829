import _ from "underscore";
import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

import rateLimit, {getStandardTimeout} from "utils/rate_limit";

function doSave(requestor, organisationId, taskId, roleId, type, data) {
  return requestor
    .put(
      `${basePath}/organisation/${organisationId}/task/${taskId}/role/${roleId}`,
      data,
      {withCredentials: true},
    )
    .then(response => ({
      type,
      role_id: roleId,
      ...data,
      ...response.data,
    }));
}

const save = rateLimit(doSave, getStandardTimeout());

const singleKeys = ["last_edited", "party_id"];
const multiKeys = ["last_edited", "party_data"];

const keys = {
  single: singleKeys,
  multi: multiKeys,
  "multi-check": multiKeys,
};

export default function updateTask(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.TASK_ROLE_UPDATE,
    (organisationId, taskId, roleId, data) => {
      if (!data.last_edited) {
        throw new Error("data must contain a last_edited key");
      }
      if (!data.type) {
        throw new Error("data must contain a type key");
      }

      return save(
        requestor,
        organisationId,
        taskId,
        roleId,
        data.type,
        _.pick(data, keys[data.type]),
      );
    },
  );

  return action;
}
