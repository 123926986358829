import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Permissioner from "utils/permissioner";

import TextField from "material-ui/TextField";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";

import * as colors from "material-ui/styles/colors";

const styles = {
  row: {
    borderBottom: `1px solid ${colors.grey300}`,
  },
  cell: {
    verticalAlign: "middle",
    textAlign: "center",
  },
  nameCell: {
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
  },
  nameBlock: {
    cursor: "pointer",
    height: 48,
    display: "flex",
    alignItems: "center",
  },
  editIconBlock: {
    display: "flex",
    alignItems: "center",
    width: 18,
  },
  editIcon: {
    width: 18,
    height: 18,
    fill: colors.grey500,
    cursor: "pointer",
  },
};

class PermissionGroupsItem extends React.Component {
  state = {
    isItemHovered: false,
    isNameEditable: false,
    nameError: "",
  };

  render() {
    const {group, usedPermissionsLength} = this.props;

    return (
      <tr
        style={{
          ...styles.row,
          ...(this.props.isLastItem && {border: "none"}),
        }}
        onMouseEnter={this.onItemEnter}
        onMouseLeave={this.onItemLeave}
      >
        <td style={styles.cell}>{group.id}</td>
        <td style={{...styles.cell, ...styles.nameCell}}>
          <div style={{flexGrow: 1}}>{this.renderName()}</div>
          <div style={{...styles.editIconBlock}}>{this.renderEditIcon()}</div>
        </td>
        <td style={styles.cell}>{usedPermissionsLength}</td>
        <td style={styles.cell}>
          {moment(group.creation_date).format("D MMM YYYY, HH:mm:ss")}
        </td>
      </tr>
    );
  }

  renderName() {
    const {group} = this.props;

    if (!this.state.isNameEditable) {
      return (
        <div
          style={{
            ...styles.nameBlock,
            color: this.state.isItemHovered ? colors.blue500 : "#000",
            textDecoration: this.state.isItemHovered ? "underline" : "none",
          }}
          onClick={() => this.props.onShowManageDialog(group.id)}
        >
          {group.name}
        </div>
      );
    }

    return (
      <TextField
        autoFocus
        hintText="Group name"
        defaultValue={group.name}
        fullWidth={true}
        errorText={this.state.nameError}
        onChange={event => this.handleOnChangeName(event)}
        onBlur={event => this.handleOnBlurName(event)}
      />
    );
  }

  handleOnChangeName(event) {
    const name = event.target.value.replace(/^\s{1,}|\s{1,}$/g, "");
    if (this.props.isUsedGroupName(name)) {
      this.setState({nameError: "This name is already used"});
    } else if (this.state.nameError) {
      this.setState({nameError: ""});
    }
  }

  handleOnBlurName(event) {
    const {group} = this.props;
    const name = event.target.value.replace(/^\s{1,}|\s{1,}$/g, "");
    if (name.length && !this.props.isUsedGroupName(name)) {
      group.name = name;
      this.props.updatePermissionGroup(group.id, name);
    }
    this.setState({isNameEditable: false, nameError: ""});
  }

  renderEditIcon() {
    if (
      !this.state.isItemHovered ||
      this.state.isNameEditable ||
      !this.hasPermission("update-permission-group")
    ) {
      return null;
    }
    return (
      <EditIcon
        style={styles.editIcon}
        onClick={() => this.setState({isNameEditable: true})}
      />
    );
  }

  onItemEnter = () => this.setState(() => ({isItemHovered: true}));
  onItemLeave = () => this.setState(() => ({isItemHovered: false}));

  hasPermission = permission => {
    return new Permissioner(this.props.user).hasPermission(permission);
  };
}

PermissionGroupsItem.propTypes = {
  user: PropTypes.object.isRequired,
  group: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    creation_date: PropTypes.string.isRequired,
  }).isRequired,
  usedPermissionsLength: PropTypes.number.isRequired,
  isUsedGroupName: PropTypes.func.isRequired,
  onShowManageDialog: PropTypes.func.isRequired,
  isLastItem: PropTypes.bool.isRequired,
  updatePermissionGroup: PropTypes.func.isRequired,
};

export default PermissionGroupsItem;
