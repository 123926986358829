import React, {useState} from "react";
import styled from "styled-components";

import Fieldset from "./styled/fieldset";
import Legend from "./styled/legend";
import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";
import {IconButton} from "@material-ui/core";
import {ExpandMore, ExpandLess, MoreHoriz} from "@material-ui/icons";

interface ErrorSummaryProps {
  llmRun: EnhancedLlmRun;
}

const Container = styled(Fieldset)`
  padding-left: 0;
  padding-top: 0;
`;

export default function ErrorSummary({llmRun}: ErrorSummaryProps) {
  const {errors} = llmRun.data;
  const [expanded, setExpanded] = useState<boolean>(!llmRun.is_complete);
  const [showAll, setShowAll] = useState<boolean>(false);
  // Note: Using a textarea here as normal monospace fonts had wrapping bugs
  return (
    <Container
      style={
        expanded
          ? {}
          : {borderLeft: "none", borderRight: "none", borderBottom: "none"}
      }
    >
      <Legend>
        Errors ({errors.length}){" "}
        <IconButton
          size="small"
          onClick={() => {
            setExpanded(!expanded);
            setShowAll(false);
          }}
        >
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Legend>
      {expanded && (
        <div style={{padding: "1em"}}>
          {errors
            .reverse()
            .slice(0, showAll ? -1 : 1)
            .map((error, index) => (
              <div
                key={index}
                style={{
                  border: "1px solid #aaa",
                  margin: "1em 0",
                  borderRadius: "1em",
                  padding: "1em",
                }}
              >
                <textarea
                  rows={20}
                  style={{
                    fontFamily: "monospace",
                    color: "red",
                    whiteSpace: "pre-wrap",
                    width: "100%",
                    border: "none",
                    background: "transparent",
                  }}
                >
                  {error}
                </textarea>
              </div>
            ))}
        </div>
      )}
      {expanded && errors.length > 1 && !showAll && (
        <IconButton onClick={() => setShowAll(true)}>
          <MoreHoriz />
        </IconButton>
      )}
    </Container>
  );
}
