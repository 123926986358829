import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import ReactSelect from "react-select";

import {Link} from "react-router";

import LinkIcon from "@material-ui/icons/Link";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HelpIcon from "@material-ui/icons/Help";

import HorizontalRow from "../horizontal_row";
import ReactSelectLabel from "common_components/react_select_label";
import issuesetUtils from "common/utils/issues/issueset_utils";
import byId from "common/utils/by_id";
import constants from "../constants";
import {hasPermission} from "utils/permissioner";
import {getUserDefaultProjectId} from "../project";

const checklistDisplayItems = [
  {label: "Fully Closed", value: "fully_closed"},
  {label: "Open Issue Groups", value: "open_issue_groups"},
];

const styles = {
  fieldset: {
    marginTop: "20px",
    marginRight: "2em",
    minWidth: "20em",
  },
};

function ProjectGeneralTab(props) {
  const {
    propsProject,
    stateProject,
    canViewEverything,
    user,
    organisation,
    documentTypes,
    contractTypes,

    userDefaultProjectId,
    onStateProjectUpdate,
    onProjectKeyChange,
    onUserDefaultProjectIdUpdate,
    hasProjectItemChanged,

    renderProjectEmail,
    renderDefaultContractTypeSelector,

    createCheckbox,
    createField,
    createSelectField,
  } = props;

  function onDocumentTypeUpdate(e) {
    const newId = e.target.value;
    onStateProjectUpdate({
      ...stateProject,
      document_type_id: newId,
      document_type: {
        ...stateProject.document_type,
        id: newId,
      },
    });
  }

  function renderDocumentTypeSelector() {
    const hasChanged =
      stateProject.document_type.id !== propsProject.document_type.id;
    return createSelectField(
      "Document type",
      stateProject.document_type.id,
      stateProject.document_type.id && stateProject.documents_count > 0,
      onDocumentTypeUpdate,
      documentTypes,
      hasChanged,
    );
  }

  function getAllIssuesets() {
    const projectCt = contractTypes.filter(
      ct => ct.id === stateProject.default_contract_type.id,
    );
    const allIssuesets = issuesetUtils
      .getIssuesetsPresentInContractTypes(projectCt)
      .map(issueset => ({
        ...issueset,
        name: issueset.remote_client_name
          ? `${issueset.name} - ${issueset.remote_client_name}`
          : issueset.name,
      }));
    return allIssuesets;
  }

  function getCurrentIssuesetValue() {
    const allIssuesets = getAllIssuesets();
    const issuesetsById = byId(allIssuesets);
    const currentValue = stateProject.issuesets.reduce((accum, issuesetId) => {
      const issueset = issuesetsById[issuesetId];
      if (issueset) {
        accum.push({id: issuesetId, name: issueset.name});
      }
      return accum;
    }, []);
    return currentValue;
  }

  function onIssuesetsUpdate(newValues) {
    const newIssuesets = newValues.map(issueset => issueset.id);
    onProjectKeyChange("issuesets", newIssuesets);
  }

  function renderIssuesetSelector() {
    return (
      <div style={{zIndex: 1}}>
        <ReactSelectLabel>Issuesets</ReactSelectLabel>
        <ReactSelect
          className="issuesets"
          valueKey="id"
          labelKey="name"
          multi={true}
          removeSelected={true}
          clearable={false}
          backspaceRemoves={false}
          deleteRemoves={false}
          closeOnSelect={false}
          value={getCurrentIssuesetValue()}
          allowCreate={false}
          options={getAllIssuesets()}
          onChange={onIssuesetsUpdate}
          wrapperStyle={{width: "100%"}}
          style={{
            background: hasProjectItemChanged("issuesets")
              ? constants.changedKeyColor
              : "inherit",
          }}
          menuContainerStyle={{zIndex: 99}}
        />
      </div>
    );
  }

  function onDefaultIssuesetUpdate(e) {
    onProjectKeyChange("default_issueset_id", e.target.value || null);
  }

  function renderDefaultIssuesetSelector() {
    const currentIssuesetValue = getCurrentIssuesetValue();
    const disabled = !currentIssuesetValue || currentIssuesetValue.length === 0;
    const menuItems = [{id: "", name: "Not Set"}, ...currentIssuesetValue];
    const hasChanged =
      stateProject.default_issueset_id !== propsProject.default_issueset_id;
    const value = currentIssuesetValue.find(
      ({id}) => id === stateProject.default_issueset_id,
    )
      ? stateProject.default_issueset_id
      : "";
    return createSelectField(
      "Default Issueset",
      value,
      disabled,
      onDefaultIssuesetUpdate,
      menuItems,
      hasChanged,
      {marginTop: "8px", marginBottom: "4px"},
    );
  }

  function onChecklistDisplayChange(e) {
    onProjectKeyChange("checklist_display", e.target.value);
  }

  function renderChecklistDisplaySelector() {
    const menuItems = checklistDisplayItems.map(item => ({
      id: item.value,
      name: item.label,
    }));
    return createSelectField(
      "Checklist Display",
      stateProject.checklist_display,
      stateProject.document_type.id === 4,
      onChecklistDisplayChange,
      menuItems,
      hasProjectItemChanged("checklist_display"),
      {marginTop: "8px", marginBottom: "4px"},
    );
  }

  function onIsDefaultCheck() {
    onUserDefaultProjectIdUpdate(propsProject.id);
  }

  function renderIsDefaultCheckbox() {
    const {id: projectId} = propsProject;
    const defaultProjectId = getUserDefaultProjectId(user, organisation);
    const hasChanged = userDefaultProjectId !== defaultProjectId;
    const isProjectDefaultForUser = hasChanged
      ? true
      : defaultProjectId === projectId;

    return (
      <FormControlLabel
        style={{
          marginTop: "4px",
          marginLeft: "0px",
          background: hasChanged ? constants.changedKeyColor : "inherit",
          borderRadius: "2px",
          display: "block",
        }}
        control={
          <Checkbox
            color="primary"
            onChange={onIsDefaultCheck}
            checked={isProjectDefaultForUser}
            disabled={isProjectDefaultForUser}
            size="small"
          />
        }
        label={
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "20em",
            }}
          >
            Is Default
            <Tooltip
              title="When emailed in, documents will automatically go to this folder"
              placement="top-start"
              arrow
            >
              <HelpIcon style={{marginLeft: "1em", color: "#aaa"}} />
            </Tooltip>
          </span>
        }
      />
    );
  }

  function renderTemplateDocumentLink() {
    const {template_document_link: templateDocumentLink} = stateProject;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {createField("template_document_link", "Template Document Link")}
        {templateDocumentLink && (
          <Link
            to={{pathname: templateDocumentLink}}
            style={{textDecoration: "none", margin: "10px 0px 0px 8px"}}
            onlyActiveOnIndex
          >
            <LinkIcon style={{color: "#616161"}} />
          </Link>
        )}
      </div>
    );
  }

  return (
    <div style={{padding: "2rem"}}>
      {renderProjectEmail()}
      {createField("name", "Project Name")}
      {createField("description")}
      <HorizontalRow>
        {renderDocumentTypeSelector()}
        {renderDefaultContractTypeSelector()}
      </HorizontalRow>
      {renderIssuesetSelector()}
      <HorizontalRow>
        {renderDefaultIssuesetSelector()}
        {stateProject.document_type.id !== 4 &&
          renderChecklistDisplaySelector()}
      </HorizontalRow>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <fieldset style={styles.fieldset}>
          <legend>Uploading</legend>
          {(hasPermission("organisation-admin", user) || canViewEverything) &&
            createCheckbox(
              "Is Organisation Default",
              "is_default",
              stateProject.is_default,
              'When emailed in, documents will automatically go to this folder for all users without override in "Is default".',
            )}
          {renderIsDefaultCheckbox()}
          {createCheckbox(
            "Needs Review?",
            "is_review_required",
            false,
            "When uploaded, document will not be immediately visible by end users. Instead, admin will grant access.",
          )}
          {createCheckbox(
            "Select checklist on upload",
            "select_checklist_on_upload",
            false,
            "When uploading, users can choose which of the checklists to run on the document.",
          )}
          {createCheckbox(
            "Show Review State Selector",
            "show_state_selector",
            false,
            "Allow user to view / change document review state.",
          )}
        </fieldset>

        <fieldset style={styles.fieldset}>
          <legend>Visibliity</legend>
          {createCheckbox(
            "Is Viewable by Admins Only",
            "admin_only",
            false,
            "Project can only be viewed by admin.",
          )}
          {createCheckbox(
            "Is Private?",
            "is_private",
            false,
            'Project is hidden on project list when "Hide private" is selected.',
          )}
        </fieldset>

        <fieldset style={styles.fieldset}>
          <legend>Issues</legend>
          {createCheckbox(
            "Show issues in checklist",
            "show_issues_in_checklist",
            false,
            "Instead of showing RAG colours, issues have checkboxes instead if triggered.",
          )}
          {createCheckbox(
            "Show thumbs up/down",
            "verify_issues",
            false,
            "Users can give an issue a thumbs up/down on the issue detail panel.",
          )}
          {createCheckbox(
            "Show extra issue states",
            "resolve_issues",
            false,
            "Users can resolve/ignore issues.",
          )}
          {createCheckbox(
            "Highlight clauses without topics",
            "show_clauses_without_topics",
            false,
            "Show issues that have no classification in a separate section at the bottom of the checklist.",
          )}
          {createCheckbox(
            "Review Tracking",
            "review_tracking_on",
            false,
            "Track when issues are opened and enable escalation.",
          )}
          {createCheckbox(
            "Hide State Changes",
            "hide_state_changes",
            false,
            "Allow users to change RAG state of issues.",
          )}
          {createCheckbox(
            "Inline Review on",
            "show_clause_buttons",
            false,
            "Show issues inline in document.",
          )}
          {createCheckbox(
            "Show Rag Score",
            "show_rag_score",
            false,
            "Show a percentage score based on issue's rag values.",
          )}
          {canViewEverything &&
            createCheckbox(
              "Edit all issue values",
              "edit_all_issue_values",
              false,
              "In rag report editor, show all issue fields, not just ones used in rag report.",
            )}
          {createCheckbox(
            "Per schedule analysis",
            "should_show_per_schedule_analysis",
            false,
            "Show dropdown, allowing users to view issues on a per schedule basis.",
          )}
          {createCheckbox(
            "Show parties in Word Addin",
            "show_parties_in_addin",
            false,
            "Allows users to edit roles for parties in the word editor.",
          )}
          {createCheckbox(
            "Force party confirmation",
            "force_party_confirmation",
            false,
            "Force the user to confirm the parties in the document before reviewing the checklist.",
          )}
          {createCheckbox(
            "Show LLM Response",
            "show_llm_response",
            false,
            "Show LLM Generated text for issue.",
          )}
          {createCheckbox(
            "Show How Issue Works",
            "show_how_issue_works",
            false,
            "Hide 'How Issue Works' section in checklist.",
          )}
          {createCheckbox(
            "Roll up grey issue state",
            "roll_up_grey_state",
            false,
            "If issue group is green, but has grey issues show an indicator.",
          )}
        </fieldset>

        <fieldset style={styles.fieldset}>
          <legend>Search</legend>
          {createCheckbox(
            "Holds Clause Templates",
            "holds_clause_templates",
            false,
            "When doing searches, documents from this project come up at top of search results.",
          )}
          {createCheckbox(
            "Hide from search",
            "hide_from_search",
            false,
            "If selected, documents in this folder are hidden from search results.",
          )}
        </fieldset>

        <fieldset style={styles.fieldset}>
          <legend>Backend</legend>
          {createCheckbox(
            "Fix documents during upload",
            "use_fixer",
            false,
            '(Now stable!) Apply "fixer" to documents during upload process (may slighly change output).',
          )}
          {createCheckbox(
            "Use V2 Classifier",
            "use_v2_classifier",
            false,
            "(NOT stable) Classify documents by topic, not clause.",
          )}
          {createCheckbox(
            "Use Dev Classifiers",
            "use_dev_classifiers",
            false,
            "Use classifiers marked as in dev mode.",
          )}
          {createCheckbox(
            "Use V2 Document Parser",
            "use_v2_document_parser",
            false,
            "(Now stable!) Apply new queued parsing backend.",
          )}
          {createCheckbox(
            "Use Incremental Parser",
            "incremental_parse",
            false,
            "Parse a document in multiple queue stages (testing)",
          )}
          {createCheckbox(
            "Read PDF Tables",
            "read_pdf_tables",
            false,
            "When extracting documents from PDFs attempt to extract tables.",
          )}
          {createCheckbox(
            "Read as plain text",
            "read_plain",
            false,
            "When extracting data from documents, ignore complex numbering",
          )}
          {createCheckbox(
            "Enable LLMs",
            "enable_llms",
            false,
            "Runs LLM issues",
          )}
        </fieldset>

        <fieldset style={styles.fieldset}>
          <legend>Analysis</legend>
          {createCheckbox(
            "Is Project Hot",
            "is_hot",
            false,
            "Do documents show up in normal legal engineering analysis.",
          )}
        </fieldset>
      </div>
      {canViewEverything && renderTemplateDocumentLink()}
      {createField("external_clause_library", "External clause library")}
    </div>
  );
}

export default ProjectGeneralTab;
