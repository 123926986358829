import ProjectAddContainer from "./containers/project_add";
import requestor from "requestor";

import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";
import contractTypesClearAction from "modules/documents/actions/contract_types_clear";

import documentTypesFetchAction from "modules/documents/actions/document_types_fetch";
import documentTypesClearAction from "modules/documents/actions/document_types_clear";

import BaseRoute from "utils/base_route";

export default class Project extends BaseRoute {
  constructor(store) {
    super("add", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, ProjectAddContainer);
  }

  fetchData(state) {
    this.fetchContractTypes(state);
    this.fetchDocumentTypes(state);
  }

  fetchContractTypes(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(contractTypesFetchAction(requestor)(organisationId));
  }

  fetchDocumentTypes(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(documentTypesFetchAction(requestor)(organisationId));
  }

  clearActiveData() {
    this.store.dispatch(contractTypesClearAction());
    this.store.dispatch(documentTypesClearAction());
  }
}
