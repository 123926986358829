import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  paper: ({value, index}) => ({
    flexGrow: value === index ? 1 : 0,
    overflow: "auto",
  }),
});

function TabPanel(props) {
  const classes = useStyles(props);
  const {children, value, index} = props;
  return (
    <Paper className={classes.paper} elevation={1} square>
      {value === index && children}
    </Paper>
  );
}

export default TabPanel;
