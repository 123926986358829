function getIdsErrorText(value, ids) {
  const missingIds = getMissingIds(value, ids);
  if (missingIds.length > 0) {
    const isPlural = missingIds.length > 1;
    return `Id${isPlural ? "s" : ""} ${missingIds.join(", ")} do${
      isPlural ? "" : "es"
    } not match topic or parameters`;
  }
  return "";
}

export function getMissingIds(value, ids) {
  if (!value || !ids || ids.length === 0) {
    return [];
  }
  const testRegex = /\[(\d{2,})\]/gi;
  const valueIds = [];
  let match;
  while ((match = testRegex.exec(value)) !== null) {
    const matchId = match[1] ? parseInt(match[1], 10) : null;
    valueIds.push(matchId);
  }

  const idsById = ids.reduce((result, id) => {
    result[id] = true;
    return result;
  }, {});

  const valueIdsNotPresentInIds = [];
  valueIds.forEach(valueId => {
    if (!idsById[valueId]) {
      valueIdsNotPresentInIds.push(valueId);
    }
  });
  return valueIdsNotPresentInIds;
}

export default getIdsErrorText;
