import _ from "underscore";
import React from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";

import Select, {
  getTopicLink,
  getParameterLink,
} from "common_components/select_with_link";

import filterByWord from "utils/issues/editor/filter_by_word";

const styles = {
  typeSelector: {
    fontSize: "1em",
    padding: "0.5em",
    marginBottom: "0.5em",
  },
  selector: {
    width: "30em",
  },
  priorityField: {
    width: "3em",
  },
  cell: {
    padding: "0.5em",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
  },
  dialogContentText: {
    textAlign: "center",
    textOverflow: "ellipsis",
  },
  dialogBtnBlock: {
    margin: "10px 0px",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },
};

export default function displaySettings({
  organisationId,
  issue,
  topics,
  onIssueUpdated,
}) {
  const {clause_priority: clausePriorities} = issue;

  const maxPriority =
    Math.max(...clausePriorities.map(priority => priority.priority)) + 1;
  const [newRow, setNewRow] = React.useState(null);

  const [deletingRow, setDeletingRow] = React.useState(null);

  function addPriority() {
    setNewRow({
      value: {type: "topic"},
      priority: maxPriority,
      is_high_priority: false,
    });
  }

  function saveNewPriority() {
    onIssueUpdated({
      clause_priority: sortPriorities([...clausePriorities, newRow]),
    });
    setNewRow(null);
  }

  function saveChange(index, newValue) {
    const newClausePriorities = [...clausePriorities];
    newClausePriorities[index] = {
      ...newClausePriorities[index],
      ...newValue,
    };
    onIssueUpdated({clause_priority: sortPriorities(newClausePriorities)});
  }

  function sortPriorities(clausePriorities) {
    return _.sortBy(
      clausePriorities,
      clausePriority => clausePriority.priority,
    );
  }

  function saveValueChange(index, newValue) {
    saveChange(index, {
      value: {...(clausePriorities[index]?.value ?? {}), ...newValue},
    });
  }

  function setPriority(index, _priority) {
    const priority = parseInt(_priority, 10);
    if (index === -1) {
      setNewRow({...newRow, priority});
    } else {
      saveChange(index, {priority});
    }
  }

  function setValueType(index, type) {
    if (index === -1) {
      setNewRow({...newRow, value: {...newRow.value, type}});
    } else {
      saveValueChange(index, {type});
    }
  }

  function setValueTopicId(index, topicId) {
    if (index === -1) {
      setNewRow({
        ...newRow,
        value: {...newRow.value, topic_id: topicId, parameter_id: null},
      });
    } else {
      saveValueChange(index, {topic_id: topicId, parameter_id: null});
    }
  }

  function setValueParameterId(index, parameterId) {
    if (index === -1) {
      setNewRow({
        ...newRow,
        value: {...newRow.value, parameter_id: parameterId},
      });
    } else {
      saveValueChange(index, {parameter_id: parameterId});
    }
  }

  function setDefinitionGroup(index, definitionGroup) {
    if (index === -1) {
      setNewRow({
        ...newRow,
        value: {...newRow.value, definition_group: definitionGroup},
      });
    } else {
      saveValueChange(index, {definition_group: definitionGroup});
    }
  }

  function setIsHighPriority(index, isHighPriority) {
    if (index === -1) {
      setNewRow({
        ...newRow,
        is_high_priority: isHighPriority,
      });
    } else {
      saveChange(index, {is_high_priority: isHighPriority});
    }
  }

  function removeItem(index) {
    if (index === -1) {
      setNewRow(null);
    } else {
      setDeletingRow(index);
    }
  }
  function removeItemConfirmed(index) {
    const newClausePriorities = clausePriorities.filter(
      (value, itemIndex) => itemIndex !== index,
    );
    onIssueUpdated({clause_priority: newClausePriorities});
    setDeletingRow(null);
  }

  function renderRemovePrompt() {
    function handleClose() {
      setDeletingRow(null);
    }

    return (
      <Dialog open={(deletingRow ?? -1) >= 0} onClose={handleClose}>
        <DialogTitle style={styles.dialogTitle}>Row deletion</DialogTitle>
        <DialogContent>
          <DialogContentText style={styles.dialogContentText}>
            Are you sure you want to delete this value?
          </DialogContentText>
        </DialogContent>
        <div style={styles.dialogBtnBlock}>
          <Button
            onClick={() => removeItemConfirmed(deletingRow)}
            variant="outlined"
            color="primary"
          >
            Yes
          </Button>
          <Button onClick={handleClose} variant="outlined" color="primary">
            No
          </Button>
        </div>
      </Dialog>
    );
  }

  return (
    <div style={{width: "40em"}}>
      {renderRemovePrompt()}
      {renderPriorityTable(
        addPriority,
        saveNewPriority,
        newRow,
        setPriority,
        setValueType,
        setValueTopicId,
        setValueParameterId,
        setDefinitionGroup,
        setIsHighPriority,
        removeItem,
        organisationId,
        topics,
        clausePriorities,
      )}
    </div>
  );
}

function renderPriorityTable(
  addPriority,
  saveNewPriority,
  newRow,
  setPriority,
  setValueType,
  setValueTopicId,
  setValueParameterId,
  setDefinitionGroup,
  setIsHighPriority,
  removeItem,
  organisationId,
  topics,
  clausePriorities = [{}],
) {
  return (
    <>
      <table>
        {clausePriorities.map((priority, index) =>
          renderPriority(
            priority,
            index,
            setPriority,
            setValueType,
            setValueTopicId,
            setValueParameterId,
            setDefinitionGroup,
            setIsHighPriority,
            removeItem,
            organisationId,
            topics,
          ),
        )}
        {renderPriority(
          newRow,
          -1,
          setPriority,
          setValueType,
          setValueTopicId,
          setValueParameterId,
          setDefinitionGroup,
          setIsHighPriority,
          removeItem,
          organisationId,
          topics,
        )}
      </table>
      {newRow ? (
        <Button variant="contained" color="primary" onClick={saveNewPriority}>
          Save new priority
        </Button>
      ) : (
        <Button variant="contained" color="primary" onClick={addPriority}>
          Add priority
        </Button>
      )}
    </>
  );
}

function renderPriority(
  priority,
  index,
  setPriority,
  setValueType,
  setValueTopicId,
  setValueParameterId,
  setDefinitionGroup,
  setIsHighPriority,
  removeItem,
  organisationId,
  topics,
) {
  if (!priority) {
    return null;
  }
  return (
    <tr
      style={{
        backgroundColor:
          index === -1 ? "#FFE" : index % 2 === 1 ? "#FFF" : "#FAFAFA",
      }}
    >
      <td style={styles.cell}>
        {renderValueSelector(
          priority.value,
          index,
          organisationId,
          topics,
          setValueType,
          setValueTopicId,
          setValueParameterId,
          setDefinitionGroup,
        )}
      </td>
      <td style={styles.cell}>
        <TextField
          type="number"
          label="Priority"
          value={priority.priority}
          style={styles.priorityField}
          onChange={event => setPriority(index, event.target.value)}
        />
      </td>
      <td style={styles.cell}>
        <FormControlLabel
          label="Is High Priority"
          control={
            <Switch
              checked={priority.is_high_priority}
              onChange={event => setIsHighPriority(index, event.target.checked)}
            />
          }
        />
      </td>
      <td style={styles.cell}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => removeItem(index)}
        >
          {index >= 0 ? "Remove" : "Cancel"}
        </Button>
      </td>
    </tr>
  );
}

function renderValueSelector(
  value,
  index,
  organisationId,
  topics,
  setValueType,
  setValueTopicId,
  setValueParameterId,
  setDefinitionGroup,
) {
  return (
    <div>
      {renderTypeSelector(value, index, setValueType)}
      {renderValue(
        value,
        index,
        organisationId,
        topics,
        setValueTopicId,
        setValueParameterId,
        setDefinitionGroup,
      )}
    </div>
  );
}

function renderTypeSelector(value, index, setValueType) {
  return (
    <select
      style={styles.typeSelector}
      value={value?.type}
      onChange={event => setValueType(index, event.target.value)}
    >
      <option value="topic">Topic</option>
      <option value="parameter">Topic + Parameter</option>
      <option value="definition_group">Definition Group</option>
    </select>
  );
}

function renderValue(
  value,
  index,
  organisationId,
  topics,
  setValueTopicId,
  setValueParameterId,
  setDefinitionGroup,
) {
  switch (value?.type) {
    case "topic":
      return renderTopicSelector(
        value,
        index,
        organisationId,
        topics,
        setValueTopicId,
      );
    case "parameter":
      return renderParameterSelector(
        value,
        index,
        organisationId,
        topics,
        setValueTopicId,
        setValueParameterId,
      );
    case "definition_group":
      return renderDefinitionGroupSelector(value, index, setDefinitionGroup);
    default:
      return null;
  }
}

function renderTopicSelector(
  value,
  index,
  organisationId,
  topics,
  setValueTopicId,
) {
  return (
    <Select
      style={styles.selector}
      className="topic"
      multi={false}
      value={value.topic_id}
      options={
        topics
          ? topics.map(topic => ({
              label: topic.name,
              value: topic.id,
            }))
          : []
      }
      onChange={option => setValueTopicId(index, option.value)}
      clearable={false}
      disabled={false}
      filterOption={filterByWord}
      link={getTopicLink(organisationId, value.topic_id)}
      shouldTopicSelectBeHighlighted={false}
    />
  );
}

function renderParameterSelector(
  value,
  index,
  organisationId,
  topics,
  setValueTopicId,
  setValueParameterId,
) {
  const currentTopic = topics.find(topic => topic.id === value.topic_id);
  return (
    <>
      {renderTopicSelector(
        value,
        index,
        organisationId,
        topics,
        setValueTopicId,
      )}
      {currentTopic && (
        <Select
          style={styles.selector}
          className="parameter"
          multi={false}
          value={value.parameter_id}
          options={
            currentTopic &&
            currentTopic.parameters.map(parameter => ({
              label: parameter.name,
              value: parameter.id,
            }))
          }
          onChange={option => setValueParameterId(index, option.value)}
          clearable={false}
          disabled={false}
          filterOption={filterByWord}
          link={getParameterLink(
            organisationId,
            value.topic_id,
            value.parameter_id,
          )}
        />
      )}
    </>
  );
}

function renderDefinitionGroupSelector(value, index, setDefinitionGroup) {
  return (
    <div>
      <TextField
        value={value.definition_group}
        onChange={event => setDefinitionGroup(index, event.target.value)}
      />
    </div>
  );
}
