import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function updateTemplateModule(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.TEMPLATE_MODULE_UPDATE,
    data => {
      return requestor
        .put(
          `${basePath}/organisation/${data.organisationId}/template_module/${
            data.id
          }`,
          data,
          {
            withCredentials: true,
          },
        )
        .then(response => response.data)
        .catch(err => err.data);
    },
  );
  return action;
}
