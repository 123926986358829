import requestor from "requestor";

import ChildRenderer from "common_components/child_renderer";

import BaseRoute from "utils/base_route";

import topicCategoriesFetchAction from "modules/documents/actions/topic_categories_fetch";

import TopicCategoryList from "routes/topiccategory_list";

export default class TopicCategoriesRoute extends BaseRoute {
  constructor(store) {
    super("topiccategory", store);
  }

  createChildRoutes() {
    return [new TopicCategoryList(this.store)];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchTopicCategories(state);
  }

  fetchTopicCategories(state) {
    const {organisationId} = state.params;
    this.store.dispatch(topicCategoriesFetchAction(requestor)(organisationId));
  }
}
