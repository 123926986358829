import getIssueUiOrderIds from "./get_issue_ui_order_ids";

export default function updateIssuePositions(
  issue,
  issuePositions,
  defaultValue = true,
) {
  return getIssueUiOrderIds(issue).reduce((acc, path) => {
    const value =
      typeof issuePositions[path] === "undefined"
        ? defaultValue
        : issuePositions[path];
    return {...acc, [path]: value};
  }, {});
}
