import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function lockTopicClassifierSettings(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPIC_CLASSIFIER_JOB_INFO_FETCH,
    (
      organisationId,
      topicId,
      classifierName,
      configurationId,
      topicTrainingNodeId,
    ) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}` +
            `/topic/${topicId}/classifier/${classifierName}/configuration/` +
            `${configurationId}/training_status/${topicTrainingNodeId}`,
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
