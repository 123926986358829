import React from "react";
import {Link} from "react-router";
import {get} from "lodash";

import DescriptionIcon from "@material-ui/icons/Description";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PublishIcon from "@material-ui/icons/Publish";
import SettingsIcon from "@material-ui/icons/Settings";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import AddIcon from "@material-ui/icons/Add";
import {PaginationItem} from "@material-ui/lab";
import Permissioner from "utils/permissioner";
import HotProjectChip from "common_components/project/hot_project_chip";
import Toolbar from "common_components/toolbar";
import {
  projectPath,
  organisationPath,
  setParamsToPath,
} from "routes/navigation";
import RouterHoverLink from "common_components/router_hover_link";
import getSegmentedProjectName from "utils/projects/get_segmented_project_name";
import ProjectSelector from "routes/document_detail/components/toolbar/project_selector";
import SearchField from "common_components/search_field";
import IconToggle from "common_components/icon_toggle";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const styles = {
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  projectNameParentPath: {
    fontSize: 10,
    color: "#90969b",
    lineHeight: 1,
    marginTop: 0,
    position: "absolute",
    top: 14,
  },
  projectNameLastSegment: {
    color: "inherit",
    textDecoration: "none",
  },
  icon: {
    color: "#90969b",
    margin: "0 4px",
  },
  title: {
    marginRight: 10,
  },
  results: {
    color: "#90969b",
  },
  pagination: {
    paddingLeft: "0.75rem",
    display: "flex",
    columnGap: "0.2rem",
  },
};

type DocumentListToolbarProps = {
  searchText: string;
  onChangeSearchText: (newValue: string) => void;
  onlyShowMyDocuments: boolean;
  onToggleOnlyShowMyDocuments: () => void;
  pagination?: {
    documentsCount: number;
    page: number;
    onClickPagePrevious: () => void;
    onClickPageNext: () => void;
  };
};

export default class DocumentListToolbar extends React.Component<DocumentListToolbarProps> {
  render() {
    const {
      searchText,
      onChangeSearchText,
      onlyShowMyDocuments,
      onToggleOnlyShowMyDocuments,
    } = this.props;
    return (
      <Toolbar
        icon={<DescriptionIcon />}
        title={this.renderTitle()}
        children={
          <>
            <IconToggle
              Icon={AccountCircleIcon}
              tooltip="Only show my documents"
              enabled={onlyShowMyDocuments}
              onToggle={onToggleOnlyShowMyDocuments}
            />
            <SearchField
              autoFocus
              searchText={searchText}
              onChangeSearchText={onChangeSearchText}
              title="Search"
            />

            {this.renderAddDocumentsButton()}
          </>
        }
        rightChildren={this.renderSettingsButton()}
        childrenStyles={{marginLeft: "auto"}}
      />
    );
  }

  renderTitle() {
    const {
      organisationId,
      project,
      organisation,
      user,
      pagination,
    } = this.props;
    const {parentPath, lastSegment} = getSegmentedProjectName(project.name);
    return (
      <div style={styles.titleContainer}>
        <div style={styles.titleContainer}>
          {organisation &&
            organisation.is_central_admin &&
            user.is_admin &&
            project.is_hot && <HotProjectChip />}
          {parentPath && !this.props.user.is_admin && (
            <div style={styles.projectNameParentPath}>{parentPath}</div>
          )}
          {this.props.user.is_admin ? (
            <ProjectSelector
              autoOpen
              projectId={project.id}
              hideLeftIcon={true}
            />
          ) : (
            <RouterHoverLink
              to={setParamsToPath(
                {organisationId},
                `${organisationPath}/project/list`,
              )}
              style={styles.projectNameLastSegment}
            >
              {lastSegment}
            </RouterHoverLink>
          )}
        </div>
        <ArrowRightIcon style={styles.icon} />
        <div style={styles.title}>Documents</div>

        {pagination !== undefined &&
          (() => {
            const {
              documentsCount,
              page,
              onClickPagePrevious,
              onClickPageNext,
            } = pagination;
            const pageSize = 50;

            const fromCounts = page === 1 ? 1 : pageSize * (page - 1) + 1;
            const toCounts =
              fromCounts -
              1 +
              Math.min(pageSize, documentsCount - fromCounts + 1);
            return (
              <>
                <div style={styles.results}>
                  {documentsCount > 0
                    ? `${fromCounts}-${toCounts} of ${documentsCount} documents`
                    : "0 documents"}
                </div>
                <div style={styles.pagination}>
                  {[
                    {
                      type: "previous",
                      disabled: page === 1,
                      onClick: onClickPagePrevious,
                    },
                    {
                      type: "next",
                      disabled: documentsCount <= toCounts,
                      onClick: onClickPageNext,
                    },
                  ].map(item => (
                    <PaginationItem
                      type={item.type}
                      onClick={item.onClick}
                      disabled={item.disabled}
                      key={item.type}
                      size="small"
                      variant="outlined"
                      shape="rounded"
                    />
                  ))}
                </div>
              </>
            );
          })()}
      </div>
    );
  }

  renderAddDocumentsButton() {
    let label;
    let handler;
    let Icon;

    if (get(this.props, "project.document_type.id") === 5) {
      if (!hasPermission("create-document", this.props.user)) {
        return null;
      } else if (this.props.documents.length > 0) {
        label = "Create New Contract";
        handler = this.props.toggleDocumentCreateView;
        Icon = AddIcon;
      } else if (
        this.props.documents.length === 0 &&
        this.props.hasUploadPermission
      ) {
        label = "Upload";
        handler = this.props.onAddDocumentClick;
        Icon = PublishIcon;
      }
    } else if (this.props.uploaderToShow) {
      label = "Upload";
      handler = this.props.closeUploaded;
      Icon = PublishIcon;
    } else {
      if (this.props.hasUploadPermission) {
        label = "Upload";
        handler = this.props.onAddDocumentClick;
        Icon = PublishIcon;
      }
    }

    if (!label) {
      return null;
    }

    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<Icon />}
        onClick={handler}
        disabled={!this.props.hasUploadPermission}
      >
        {label}
      </Button>
    );
  }

  renderSettingsButton() {
    if (!hasPermission("update-project", this.props.user)) {
      return null;
    }
    const {organisationId, projectId} = this.props;
    const pathname = setParamsToPath(
      {organisationId, projectId},
      `${projectPath}/detail`,
    );
    return (
      <Link to={pathname} onlyActiveOnIndex>
        <IconButton style={{color: "#91979c"}}>
          <SettingsIcon />
        </IconButton>
      </Link>
    );
  }
}

function hasPermission(permission, user) {
  return new Permissioner(user).hasPermission(permission);
}
