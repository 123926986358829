import {
  Workflow,
  WorkflowId,
  WorkflowInputs,
} from "common/flowmaster/types/workflow";

export const getCopiedWorkflowInputValues = (
  workflows: Workflow[],
  workflowToCopy: WorkflowId,
  currentInputs: WorkflowInputs,
  inputsToCopy: Record<string, string>,
  selectedWorkflow: Workflow,
) => {
  const workflowToCopyFrom = workflows.find(wf => wf.id === workflowToCopy);

  const workflowToCopyFromInputs = workflowToCopyFrom?.inputs.values ?? [];

  const updates = {...currentInputs};

  workflowToCopyFromInputs.forEach(inputToCopy => {
    const {id, name} = inputToCopy;

    const inputToUpdate = selectedWorkflow.inputs.values.find(
      val => val.name === name,
    );

    if (
      inputToUpdate &&
      !currentInputs[inputToUpdate.id]?.trim() &&
      inputsToCopy[id]?.trim()
    ) {
      updates[inputToUpdate.id] = inputsToCopy[id];
    }
  });

  return updates;
};

export default getCopiedWorkflowInputValues;
