import React from "react";
import requestor from "requestor";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import Permissioner from "utils/permissioner";
import setTitle from "utils/set_title";

import DefinitionGroupDetailComponent from "../components/definition_group_detail";

import updateDefinitionGroupAction from "modules/documents/actions/definition_group/definition_group_update";
import removeDefinitionGroupAction from "modules/documents/actions/definition_group/definition_group_remove";

function DefinitionGroupDetailContainer(props) {
  const shouldRenderContainer = isInitialised([
    props.definitionGroups,
    props.definitionGroup,
    props.user,
    props.contractTypes,
  ]);

  if (!shouldRenderContainer || !props.user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const permissioner = new Permissioner(props.user);

  if (
    !permissioner.hasPermission("update-definition-group") ||
    !permissioner.isAdmin()
  ) {
    return permissioner.getNoPermissionMessage();
  }

  const {definitionGroup, definitionGroups} = props;
  const {organisationId} = props.params;

  function onDefinitionGroupUpdate(data) {
    props.updateDefinitionGroup(
      organisationId,
      definitionGroup.id,
      {
        ...data,
        last_edited: definitionGroup.last_edited,
      },
      definitionGroup.last_edited,
    );
  }

  function redirectToDefinitionGroupsList() {
    props.dispatch(
      push(`/organisation/${organisationId}/definition_group/list`),
    );
  }

  function onDefinitionGroupRemove() {
    props.removeDefinitionGroup(
      organisationId,
      definitionGroup.id,
      definitionGroup.last_edited,
    );
    redirectToDefinitionGroupsList();
  }

  setTitle("Definition Group Detail");
  return (
    <DefinitionGroupDetailComponent
      definitionGroup={definitionGroup}
      definitionGroups={definitionGroups}
      onDefinitionGroupUpdate={onDefinitionGroupUpdate}
      onDefinitionGroupRemove={onDefinitionGroupRemove}
      redirectToDefinitionGroupsList={redirectToDefinitionGroupsList}
      contractTypes={props.contractTypes}
    />
  );
}

function select(state, props) {
  if (!state.definitionGroups) {
    return {};
  }
  const definitionGroupId = parseInt(props.params.definitionGroupId, 10);
  return {
    definitionGroup:
      state.definitionGroups !== UNINITIALISED &&
      state.definitionGroups.find(
        definitionGroup => definitionGroup.id === definitionGroupId,
      ),
    params: props.params,
    router: state.router,
    definitionGroups: state.definitionGroups,
    user: state.user,
    contractTypes: state.contract_types,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateDefinitionGroup: updateDefinitionGroupAction(requestor),
        removeDefinitionGroup: removeDefinitionGroupAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(
  DefinitionGroupDetailContainer,
);
