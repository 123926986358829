function constructProjectUploadEmail(organisationUploadEmail, projectName) {
  if (organisationUploadEmail.indexOf("@") === -1) {
    return null;
  }
  const projectEmail = organisationUploadEmail.split("@");
  projectEmail.splice(
    1,
    0,
    `+${projectName
      .split(" ")
      .join("_")
      .toLowerCase()}@`,
  );
  return projectEmail.join("");
}

export default constructProjectUploadEmail;
