import BaseRoute from "utils/base_route";
import fetchAnalytics from "modules/analytics/actions/fetch";
import clearAnalytics from "modules/analytics/actions/clear";
import Container from "./containers";

const ANALYTICS_PERIOD_IN_DAYS = 61;

export default class AnalyticsRoute extends BaseRoute {
  constructor(store) {
    super("analytics", store);
  }

  getComponents(location, cb) {
    cb(null, Container);
  }

  onEnter(props) {
    this.fetchAnalytics(props);
  }

  clearActiveData() {
    this.store.dispatch(clearAnalytics());
  }

  fetchAnalytics() {
    this.store.dispatch(fetchAnalytics(ANALYTICS_PERIOD_IN_DAYS));
  }
}
