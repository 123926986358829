import React, {useState} from "react";

import PrevPageIcon from "@material-ui/icons/ChevronLeft";
import NextPageIcon from "@material-ui/icons/ChevronRight";
import ShowAllIcon from "@material-ui/icons/Error";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import RefreshButtonIndicator from "./refresh_button_indicator";

import TopicUsage from "./topic_usage";
import CollapsibleHeader from "common_components/collapsible_header";
import makeComponentCollapsible from "common_components/hocs/make_component_collapsible";
import TopicUsageBadlyParsedDialog from "../../topic_analysis/components/topic_usage_badly_parsed_dialog";

import localStorage from "utils/local_storage";

const styles = {
  rootContainer: {
    display: "flex",
    flexDirection: "column",
    alignItem: "center",
    margin: "2rem 0",
  },
  caption: {
    fontWeight: "bold",
    fontSize: "1.5em",
    margin: "0 auto 1em auto",
    display: "flex",
  },
  pageIcon: {
    transition: "unset",
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },
  noUsages: {
    margin: "0 auto",
    fontSize: "24px",
    color: "gray",
  },
  otherTopicsHeading: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  otherTopicsIcon: {
    marginTop: 2,
  },
};

function TopicUsageList(props) {
  const {
    isConfirmed,
    hasConfirming,
    className,
    usages,
    showingParameterEditor,
    updateClausepartIsBadlyParsed,
  } = props;

  const [hideOtherParameters, updateHideOtherParameters] = useState(
    localStorage.getItem("topicDetailHideOtherParameters") === "true",
  );
  const [hideOtherTopics, updateHideOtherTopics] = useState(true);
  const [badlyParsedData, setBadlyParsedData] = useState(null);
  const [isBadlyParsedIds, setIsBadlyParsedIds] = useState([]);

  const triggerUpdateHideOtherParameters = () => {
    const newValue = !hideOtherParameters;
    localStorage.setItem("topicDetailHideOtherParameters", newValue);
    updateHideOtherParameters(newValue);
  };

  const triggerUpdateHideOtherTopics = () =>
    updateHideOtherTopics(!hideOtherTopics);

  const handleBadlyParsedModalOpen = (
    id,
    clause_id,
    document_id,
    project_id,
  ) => {
    setBadlyParsedData({id, clause_id, document_id, project_id});
  };

  const handleBadlyParsedModalClose = () => setBadlyParsedData(null);

  const markClauseIsBadlyParsed = () => {
    if (badlyParsedData) {
      const {id, clause_id, document_id, project_id} = badlyParsedData;
      setIsBadlyParsedIds([...isBadlyParsedIds, id]);
      updateClausepartIsBadlyParsed(
        project_id,
        document_id,
        clause_id,
        id,
        true,
      );
    }
    handleBadlyParsedModalClose();
  };

  function renderPagesControl() {
    const {
      currentPage,
      totalPages,
      usages,
      areAllUsagesShown,
      showingParameterEditor,
    } = props;
    if (usages.length === 0) {
      return null;
    }
    return (
      <div style={{display: "flex", alignItems: "center", margin: "0 auto"}}>
        <PrevPageIcon
          onClick={props.showPreviousUsagesPage}
          style={{
            ...styles.pageIcon,
            visibility:
              currentPage !== 1 && !areAllUsagesShown ? "visible" : "hidden",
          }}
        />
        <div style={{textDecoration: "underline", fontWeight: 600}}>
          {areAllUsagesShown
            ? "ALL PROBLEMS SHOWN"
            : `PAGE ${currentPage} OF ${totalPages}`}
        </div>
        <NextPageIcon
          onClick={props.showNextUsagesPage}
          style={{
            ...styles.pageIcon,
            visibility:
              currentPage < totalPages && !areAllUsagesShown
                ? "visible"
                : "hidden",
          }}
        />
        <div
          title={
            areAllUsagesShown ? "Show Problems in Pages" : "Show All Problems"
          }
          onClick={
            areAllUsagesShown
              ? props.onAbortShowAllUsages
              : props.onShowAllUsages
          }
          style={{
            visibility: showingParameterEditor ? "visible" : "hidden",
            cursor: "pointer",
          }}
        >
          <ShowAllIcon
            style={{color: areAllUsagesShown ? "#424242" : "#9E9E9E"}}
          />
        </div>
      </div>
    );
  }

  function renderListBody() {
    const HideOtherParametersIcon = hideOtherParameters
      ? ExpandMoreIcon
      : ExpandLessIcon;

    const HideOtherTopicsIcon = hideOtherTopics
      ? ExpandMoreIcon
      : ExpandLessIcon;

    return (
      <>
        {renderPagesControl()}
        {!usages || usages.length === 0 ? (
          <div style={styles.noUsages}>No Usages Available</div>
        ) : (
          <table style={{borderCollapse: "collapse"}} className={className}>
            <thead>
              <tr>
                <th style={{width: "12rem"}}>Topic Data</th>
                {showingParameterEditor && (
                  <th style={{width: "6rem"}}>State</th>
                )}
                <th style={{width: "64%"}}>Text</th>
                <th style={{width: "6rem"}}>
                  <div
                    onClick={
                      showingParameterEditor
                        ? triggerUpdateHideOtherParameters
                        : undefined
                    }
                    style={styles.otherTopicsHeading}
                  >
                    <span>Parameters</span>
                    {showingParameterEditor && (
                      <div
                        title={`${
                          hideOtherParameters ? "Show" : "Hide"
                        } Other Parameters`}
                      >
                        <HideOtherParametersIcon
                          style={styles.otherTopicsIcon}
                        />
                      </div>
                    )}
                  </div>
                </th>
                <th
                  style={{
                    maxWidth: "30%",
                    minWidth: "10rem",
                  }}
                >
                  <div
                    onClick={triggerUpdateHideOtherTopics}
                    style={styles.otherTopicsHeading}
                  >
                    <span>Other topics</span>
                    <div
                      title={`${
                        hideOtherTopics ? "Show" : "Hide"
                      } Other Topics`}
                    >
                      <HideOtherTopicsIcon style={styles.otherTopicsIcon} />
                    </div>
                  </div>
                </th>
                <th key="action-buttons" style={{width: "1em"}} />
                {props.usesToSelect && (
                  <th key="uses-to-select-checkbox" style={{width: "1em"}} />
                )}
              </tr>
            </thead>
            <tbody>
              {usages.map(usage => (
                <TopicUsage
                  {...props}
                  key={usage.id}
                  usage={usage}
                  needsConfirming={!isConfirmed}
                  hasConfirming={hasConfirming}
                  onUsageRemoved={(...args) =>
                    props.onUsageRemoved(usage.id, ...args)
                  }
                  hideOtherParameters={hideOtherParameters}
                  hideOtherTopics={hideOtherTopics}
                  handleBadlyParsedModalOpen={handleBadlyParsedModalOpen}
                  isBadlyParsedIds={isBadlyParsedIds}
                />
              ))}
            </tbody>
          </table>
        )}
        {renderPagesControl()}
        <TopicUsageBadlyParsedDialog
          isOpen={Boolean(badlyParsedData)}
          modalClose={handleBadlyParsedModalClose}
          notConfirm={handleBadlyParsedModalClose}
          confirm={markClauseIsBadlyParsed}
        />
        <hr style={{width: "99%"}} />
      </>
    );
  }

  return (
    <div style={styles.rootContainer}>
      <CollapsibleHeader
        headerText="Clauses"
        isCollapsed={props.isCollapsed}
        onHeaderClick={props.switchIsCollapsed}
        rootDivStyles={{marginBottom: "1rem", justifyContent: "center"}}
        headerTextStyles={{fontSize: "1.5em"}}
      >
        <div title="Reload parameter classification data">
          <RefreshButtonIndicator
            requestPending={props.isTopicDataRequestPending}
            clickHandler={props.refreshTopicData}
            containerStyle={{marginTop: "12px"}}
          />
        </div>
      </CollapsibleHeader>
      {!props.isCollapsed && renderListBody()}
    </div>
  );
}

export default makeComponentCollapsible(TopicUsageList);
