import React from "react";
import requestor from "requestor";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import UserList from "../components/user_list";

import deleteOrganisationUserAction from "modules/organisation/actions/organisation_user_delete";

class Users extends React.Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }

    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return (
      this.permissioner.isAdmin() ||
      this.permissioner.hasPermission("get-users")
    );
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.organisation,
      this.props.user,
      this.props.projects,
    ]);
  }

  renderComponent() {
    setTitle("Users List");
    const {props} = this;
    const users = props.user.is_admin
      ? props.organisation.users
      : (props.organisation.users || []).filter(
          user => !user.username.endsWith("lexicallabs.com"),
        );

    return (
      <UserList
        user={props.user}
        users={users}
        projects={props.projects}
        permissionGroups={props.permissionGroups}
        onUserAdd={this.onUserAdd}
        onUserDelete={this.onUserDelete}
        organisation={this.props.organisation}
      />
    );
  }

  onUserAdd = () => {
    const {organisationId} = this.props.params;
    this.props.dispatch(push(`/organisation/${organisationId}/user/new`));
  };

  onUserDelete = userId => {
    const {organisationId} = this.props.params;
    this.props.deleteOrganisationUser(organisationId, userId);
  };

  hasPermission = permission => {
    return this.permissioner.hasPermission(permission);
  };
}

function select(state, props) {
  return {
    params: props.params,
    organisation: state.organisation,
    router: state.router,
    user: state.user,
    projects: state.projects,
    permissionGroups: state.permission_groups,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        deleteOrganisationUser: deleteOrganisationUserAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(Users);
