import ComparisonSummary from "./containers/comparison_summary";

import reportsClearAction from "modules/reports/actions/clear";
import reportsFetchAction from "modules/reports/actions/fetch";
import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";
import documentsFetchAction from "modules/documents/actions/documents_fetch";
import {parseQuery} from "utils/uri";
import requestor from "requestor";

import BaseRoute from "utils/base_route";

export default class ComparisonSummaryRoute extends BaseRoute {
  constructor(store) {
    super(":documentId/comparison", store);
  }
  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, ComparisonSummary);
  }
  fetchActiveData(state) {
    const {
      location: {search},
      params: {organisationId, projectId, documentId},
    } = state.router;
    const queryParams = parseQuery(search);
    const comparisonDocumentId =
      "comparison_document" in queryParams
        ? parseInt(queryParams.comparison_document, 10)
        : undefined;

    this.store.dispatch(contractTypesFetchAction(requestor)(organisationId));

    this.store.dispatch(
      reportsFetchAction(organisationId, projectId, {
        documents: [documentId, comparisonDocumentId].filter(Boolean),
      }),
    );
    this.fetchDocuments(organisationId, projectId);
  }

  fetchDocuments(organisationId, projectId) {
    if (projectId) {
      this.store.dispatch(
        documentsFetchAction(requestor)(organisationId, projectId),
      );
    }
  }

  clearActiveData() {
    this.store.dispatch(reportsClearAction());
  }
}
