import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  WorkflowTask,
  ActionDefinition,
  TextMapArgument,
  StringArgument,
} from "common/flowmaster/types/task_config";
import {DataLocation} from "common/flowmaster/types/data_location";
import {
  WorkflowContext,
  WorkflowInputs,
  Prompt,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

import ContainedTextField from "../../contained_text_field";
import {StringArgEditor} from "../string";

interface TextMapEntryEditorProps {
  itemKey: string;
  itemValue: DataLocation<string>;
  argValue: TextMapArgument;
  priorTasks: WorkflowTask[];
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  onUpdateItem: (value: TextMapArgument) => void;
  organisationId: OrganisationId;
}

const TextMapEntryEditor: React.FC<TextMapEntryEditorProps> = ({
  itemKey,
  itemValue,
  argValue,
  priorTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  onUpdateItem,
  organisationId,
}) => {
  const [error, setError] = useState<string>("");
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid",
        padding: "1em",
        margin: "1em 0",
      }}
    >
      <div style={{width: "10em", marginRight: "0.5em"}}>
        <ContainedTextField
          label="Key"
          errorMessage={error}
          initialValue={itemKey}
          onUpdate={text => {
            if (!argValue.value[text]) {
              const newValue = {...argValue.value};
              delete newValue[itemKey];
              onUpdateItem({
                ...argValue,
                value: {
                  ...newValue,
                  [text]: itemValue,
                },
              });
              setError("");
            } else if (text !== itemKey) {
              setError("This key already exists");
            }
          }}
        />
      </div>
      <StringArgEditor
        argValue={{value: itemValue}}
        priorTasks={priorTasks}
        context={context}
        inputs={inputs}
        prompts={prompts}
        actionDefinitions={actionDefinitions}
        onUpdateItem={({value}: StringArgument) => {
          onUpdateItem({
            ...argValue,
            value: {
              ...argValue.value,
              [itemKey]: value,
            },
          });
        }}
        organisationId={organisationId}
      />
      <IconButton
        onClick={() => {
          const value = {...argValue.value};
          delete value[itemKey];
          onUpdateItem({...argValue, value});
        }}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

export default TextMapEntryEditor;
