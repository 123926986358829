export default function getIssueIndexes(initialIssue) {
  const indexes = {};
  function addIndex(issue) {
    if ((issue?.rules?.issues?.length ?? 0) > 0) {
      issue.rules.issues.forEach((newIssue, index) => {
        indexes[newIssue.ui_order_id] = index;
        addIndex(newIssue);
      });
    }
    // Handle single child cases (ie NOTs)
    if (issue?.rules?.rules) {
      indexes[`${issue?.ui_order_id}.0`] = 0;
      addIndex(issue?.rules);
    }
  }
  addIndex(initialIssue);
  return indexes;
}
