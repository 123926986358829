import BaseRoute from "utils/base_route";

import RoleList from "./containers/role_list";

export default class RoleListRoute extends BaseRoute {
  constructor(store) {
    super("list", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, RoleList);
  }
}
