import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../constants/action_types";

export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_CLAUSEPART_ALTER,
  (
    organisationId,
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
    text,
  ) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/section/${sectionId}/clause/${clauseId}/` +
          `clausepart/${clausepartId}/alter`,
        {
          clausepart_last_edited: clausepartLastEdited,
          text,
        },
        {withCredentials: true},
      )
      .then(response => response.data),
);
