import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import DeleteIcon from "@material-ui/icons/Delete";

import {Table, Tr, Th, Td} from "common_components/table";
import UserListToolbar from "./user_list_toolbar";
import Permissioner from "utils/permissioner";
import ConfirmDialog from "common_components/confirm_dialog";

import "./user_list.css";

const styles = {
  mainContainer: {
    padding: "1rem",
  },
  headerCell: {
    fontWeight: 600,
    fontSize: 15,
  },
  rowHover: {
    backgroundColor: "#efeff1",
  },
};

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShownDeleteUserDialog: false,
      deleteUserId: null,
      sortColumnIndex: 0,
      sortDirection: "asc",
    };
  }

  render() {
    const {users, projects} = this.props;
    return (
      <div style={styles.mainContainer}>
        <UserListToolbar
          onUserAdd={this.props.onUserAdd}
          hasPermissionToAdd={
            this.hasPermission("add-organisation-user") || this.props.is_admin
          }
        />
        <Table
          hasStickyHeader={true}
          onSort={(sortColumnIndex, sortDirection) => {
            this.setState({sortColumnIndex, sortDirection});
          }}
        >
          <thead>
            <tr>
              <Th style={{width: "20%"}}>Email</Th>
              <Th style={{width: "10%"}}>First Name</Th>
              <Th style={{width: "10%"}}>Last Name</Th>
              <Th style={{width: "10%"}}>Creation Date</Th>
              <Th style={{width: "10%"}}>Last Logged In</Th>
              <Th style={{width: "7%"}}>Password Expiry</Th>
              <Th style={{width: "14%"}}>Default Project</Th>
              {this.hasPermission("can-view-user-role") && (
                <Th style={{width: "7%"}}>Permission Group</Th>
              )}
              <Th style={{width: "4%"}}>Is locked</Th>
              {this.hasPermission("can-delete-user") && (
                <Th style={{width: "5%"}} />
              )}
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => {
              const foundProject = (projects || []).find(
                project => project.id === user.default_project_id,
              );
              const onDeleteUser = e => {
                e.stopPropagation();
                e.preventDefault();
                this.setState({
                  isShownDeleteUserDialog: true,
                  deleteUserId: user.id,
                });
              };

              return (
                <Tr
                  key={index}
                  hoverStyle={styles.rowHover}
                  linkPath={`/organisation/${this.props.organisation.id}/user/${
                    user.id
                  }`}
                >
                  <Td key="email">{user.username}</Td>
                  <Td key="first-name">{user.first_name}</Td>
                  <Td key="last-name">{user.last_name}</Td>
                  <Td key="creation-date" sortText={user.registration_date}>
                    {user.registration_date &&
                      moment(user.registration_date).format(
                        "D MMM YYYY, HH:mm:ss ",
                      )}
                  </Td>
                  <Td key="last-logged-in" sortText={user.last_logged_in}>
                    {user.last_logged_in &&
                      moment(user.last_logged_in).format(
                        "D MMM YYYY, HH:mm:ss ",
                      )}
                  </Td>
                  <Td key="password-expiry" sortText={user.password_expiry}>
                    {user.password_expiry &&
                      moment(user.password_expiry).format("D MMM YYYY")}
                  </Td>
                  <Td key="default-project">
                    {foundProject ? foundProject.name : projects[0].name}
                  </Td>
                  {this.hasPermission("can-view-user-role") && (
                    <Td key="permission-group">
                      {this.getPermissionGroupName(user.permission_group_id)}
                    </Td>
                  )}
                  <Td key="is-locked">{user.is_locked ? "Yes" : ""}</Td>
                  {(this.hasPermission("can-delete-user") ||
                    this.props.user.is_admin) && (
                    <Td key="can-delete-user">
                      {this.props.user.id !== user.id &&
                        !user.is_admin && <DeleteIcon onClick={onDeleteUser} />}
                    </Td>
                  )}
                </Tr>
              );
            })}
          </tbody>
        </Table>
        <ConfirmDialog
          open={this.state.isShownDeleteUserDialog}
          onSuccess={() => {
            this.props.onUserDelete(this.state.deleteUserId);
            this.setState({deleteUserId: null});
          }}
          onClose={() => this.setState({isShownDeleteUserDialog: false})}
          title="Delete User"
          description="Are you sure you want to delete this user?"
          okButtonCaption="Delete"
        />
      </div>
    );
  }
  hasPermission = permission => {
    return new Permissioner(this.props.user).hasPermission(permission);
  };

  getPermissionGroupName = id => {
    const {permissionGroups: groups} = this.props;
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      if (group.id === id) {
        return group.name;
      }
    }
    return null;
  };
}

UserList.propTypes = {
  users: PropTypes.array.isRequired,
  onUserAdd: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  permissionGroups: PropTypes.array.isRequired,
};

export default UserList;
