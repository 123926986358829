import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";

import ActionTypes from "../constants/action_types";
import readTask from "../utils/read_task";

export default function initProject(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PROJECT_TASKS_FETCH,
    (organisationId, projectId) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}/project/${projectId}/task`,
          {withCredentials: true},
        )
        .then(response => response.data.map(task => readTask(task))),
  );
}
