import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function permissionGroupUpdate(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PERMISSION_GROUP_UPDATE,
    (permissionGroupId, name) =>
      requestor
        .put(
          `${basePath}/permission_group/${permissionGroupId}/`,
          {name},
          {withCredentials: true},
        )
        .then(() => ({
          permissionGroupId,
          name,
        }))
        .catch(err => err.data),
  );
}
