import React from "react";
import _ from "underscore";

const styles = {
  heading: {
    fontWeight: 600,
    fontSize: "12px",
    margin: 0,
  },
  descriptionFirstLine: {
    fontSize: "10px",
    fontWeight: 400,
    margin: "0.5em",
    textIndent: 0,
  },
  descriptionLine: {
    fontSize: "10px",
    fontWeight: 100,
    margin: "0.5em",
  },
  list: {
    margin: 0,
    paddingLeft: "1em",
  },
  listElement: {
    fontSize: "10px",
    fontWeight: 100,
    margin: "0.5em",
    textIndent: 0,
  },
};

export default function render(props) {
  const {showInfoId, topics} = props;
  if (!showInfoId) {
    return null;
  }

  const topic = topics.find(topic => topic.id === showInfoId);
  if (!topic) {
    return null;
  }
  const {description} = topic;
  const descriptionLines = getLines(description.description);
  const examplesLines = getLines(description.examples);
  const counterExamplesLines = getLines(description.counter_examples);
  return (
    <div
      style={{
        position: "absolute",
        right: "100%",
        width: "20em",
        zIndex: 999999,
        backgroundColor: "#fff",
        padding: 10,
        border: "1px solid #d9d9d9",
      }}
    >
      <div>
        <p style={styles.descriptionFirstLine}>{_.first(descriptionLines)}</p>
        {_.rest(descriptionLines).map((line, i) => (
          <p key={`decsription-${i}`} style={styles.descriptionLine}>
            {line}
          </p>
        ))}
      </div>
      <div>
        <h3 style={styles.heading}>Application</h3>
        <ul style={styles.list}>
          <li style={styles.listElement}>
            {capitalise(description.application)}
          </li>
        </ul>
      </div>
      <div>
        <h3 style={styles.heading}>Related Topics</h3>
        <ul style={styles.list}>
          {setTopicNames(description.related_topics, props.topicsById).map(
            (line, i) => (
              <li
                key={description.related_topics[i]}
                style={styles.listElement}
              >
                {line}
              </li>
            ),
          )}
        </ul>
      </div>
      <div>
        <h3 style={styles.heading}>Examples</h3>
        <ul style={styles.list}>
          {examplesLines.map((line, i) => (
            <li key={`examples-${i}`} style={styles.listElement}>
              {line}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h3 style={styles.heading}>Counter Examples</h3>
        <ul style={styles.list}>
          {counterExamplesLines.map((line, i) => (
            <li key={`counter-examples-${i}`} style={styles.listElement}>
              {line}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function capitalise(text) {
  return text ? text.charAt(0).toUpperCase() + text.substring(1) : "";
}

function getLines(text) {
  if (!text) {
    return [];
  }

  return text
    .split("\n")
    .map(line => line.trim())
    .filter(line => line.length > 0);
}

function setTopicNames(topicIds, topicsById) {
  if (!topicIds) {
    return [];
  }

  const names = topicIds.reduce((namesAcc, id) => {
    const topic = topicsById[id];
    if (topic) {
      return namesAcc.concat(topic.name);
    }

    return namesAcc;
  }, []);

  return names;
}
