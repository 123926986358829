import _ from "underscore";
import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Permissioner from "utils/permissioner";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import byId from "common/utils/by_id";
import requestor from "requestor";

import documentFetch from "modules/documents/actions/document_fetch";
import documentUpdate from "modules/documents/actions/document_update";
import documentTopicsFetch from "modules/documents/actions/document_topics_fetch";
import documentClauseTopicAdd from "modules/documents/actions/document_clause_topic_add";
import documentClauseTopicRemove from "modules/documents/actions/document_clause_topic_remove";
import documentsClausepartsTopicsUpdate from "modules/documents/actions/documents_clauseparts_topics_update";
import documentsClausepartUpdate from "modules/documents/actions/document_clausepart_update";
import documentClauseTopicConfirm from "modules/documents/actions/document_clause_topic_confirm";
import documentClauseUnconfirmedTopicsRemove from "modules/documents/actions/document_clause_unconfirmed_topics_remove";
import documentClauseTopicsReorder from "modules/documents/actions/document_clause_topics_reorder";
import documentClauseTopicToggleExactMatch from "modules/documents/actions/document_clause_topic_toggle_exact_match";
import clauseSplit from "modules/documents/actions/document_clause_split";
import clauseRemoveSplits from "modules/documents/actions/document_clause_remove_splits";
import documentTopicsConfirmAll from "modules/documents/actions/document_topics_confirm_all";
import documentIssuesConfirmAll from "modules/documents/actions/document_issues_confirm_all";
import topicParameterValuesUpdate from "modules/documents/actions/topicparameter_actual_values_update";
import documentIssuesFindRun from "modules/documents/actions/document_issues_find_run";
import documentClausepartsRegexMatchesFetch from "modules/documents/actions/document_clauseparts_regex_matches_fetch";
import logPageLoadTime from "modules/logs/actions/page_load_time_log";

import DocumentClausesComponent from "../components/document_clauses";

import calculateTopicIdsFromMasks from "../utils/calculate_topic_ids_from_masks";

import getRouteQuery from "utils/get_route_query";
import getClauseAtoms from "common/utils/clauses/get_clause_atoms";

function getDocumentClauseAtoms(documentLastEdited, documentClauses) {
  return getClauseAtoms(documentClauses);
}

const getClauseAtomsMemo = _.memoize(
  getDocumentClauseAtoms,
  documentLastEdited => documentLastEdited,
);

class DocumentClausesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.startTime = new Date().valueOf();
    this.state = {};
  }
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    const result = this.renderComponent();
    if (!this.endTime) {
      this.endTime = new Date().valueOf();
      const {params} = this.props;
      this.props.logPageLoadTime(
        params.organisationId,
        "document_clauses",
        this.startTime,
        this.endTime,
        {
          document_id: params.documentId,
          project_id: params.projectId,
          url: this.props?.location?.pathname,
          read_only: localStorage.getItem("documentClausesReadOnlyModeOn"),
        },
      );
    }
    return result;
  }

  hasEnterPermission() {
    return (
      this.permissioner.isAdmin() ||
      (this.permissioner.hasPermission("add-topic") &&
        this.permissioner.canViewProject(this.props.project))
    );
  }

  shouldRenderContainer() {
    return (
      isInitialised([
        this.props.document,
        this.props.documentClauses,
        this.props.documentChanges,
        this.props.documentDefinitions,
        this.props.documentHeadings,
        this.props.documentSections,
        this.props.documentIssues,
        this.props.topicMasks,
        this.props.topics,
        this.props.topicCategories,
        this.props.topicTags,
        this.props.contractTypes,
        this.props.user,
        this.props.roles,
      ]) &&
      this.idsMatch(
        this.props.document.id,
        [this.props.documentIssues],
        "document_id",
      )
    );
  }

  renderComponent() {
    setTitle(`${this.props.document.name}`);
    const categoriesById = byId(this.props.topicCategories);
    const contractTypesById = byId(this.props.contractTypes);
    const {
      params: {organisationId},
      documentIssues: {issues},
      queryParams: {clause},
      topics,
    } = this.props;
    const topicsById = byId(topics);
    const topicMasks = this.props.topicMasks.map(mask => ({
      ...mask,
      parameters: [].concat(
        ...calculateTopicIdsFromMasks(mask.topic_ids).map(
          topicId => topicsById[topicId].parameters,
        ),
      ),
    }));
    const topicMasksById = byId(topicMasks);
    const documentClauseparts = getClauseAtomsMemo(
      this.props.document.last_edited,
      this.props.documentClauses,
    );
    return (
      <DocumentClausesComponent
        {...this.props}
        organisationId={organisationId}
        topicCategoriesById={categoriesById}
        highlightedClause={clause}
        toggleView={this.toggleView}
        onDocumentUpdated={this.documentUpdated}
        onExistingTopicAdded={this.existingTopicAdded}
        onNewTopicAdded={this.newTopicAdded}
        clausepartsTopicsUpdated={this.clausepartsTopicsUpdated}
        onTopicRemoved={this.topicRemoved}
        onUnconfirmedTopicsRemoved={this.unconfirmedTopicsRemoved}
        onTopicsReordered={this.topicsReordered}
        onTopicConfirmed={this.topicConfirmed}
        onSplitClause={this.splitClause}
        onRemoveSplits={this.removeSplits}
        onToggleExactMatch={this.toggleExactMatch}
        documentIssues={issues.filter(issue => !issue.section_id)}
        confirmAllTopics={this.confirmAllTopics}
        confirmAllIssues={this.confirmAllIssues}
        contractTypesById={contractTypesById}
        topicsById={topicsById}
        topicMasks={topicMasks}
        topicMasksById={topicMasksById}
        documentClauseparts={documentClauseparts}
        onTopicparameterValuesUpdate={this.onTopicparameterValuesUpdate}
        onDocumentIssuesFindRun={this.onDocumentIssuesFindRun}
        refreshDocumentAndTopicsData={this.refreshDocumentAndTopicsData}
        updateClausepartIsBadlyParsed={this.updateClausepartIsBadlyParsed}
        documentClausepartsRegexMatchesFetch={
          this.documentClausepartsRegexMatchesFetch
        }
      />
    );
  }

  idsMatch(id, objects, key) {
    return objects.every(object => object[key] === id);
  }

  componentDidUpdate() {
    if (
      this.state &&
      this.state.updatedWhileSaving &&
      !this.props.document.updateInProgress
    ) {
      const {organisationId, projectId, documentId} = this.props.params;
      this.props.documentUpdate(
        organisationId,
        projectId,
        documentId,
        {
          last_edited: this.props.document.last_edited,
          ...this.state.updatedWhileSaving,
        },
        false,
      );
      this.setState({updatedWhileSaving: null});
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.topicUpdates !== nextProps.topicUpdates) {
      return this.props.topicUpdates === UNINITIALISED;
    }
    return true;
  }

  toggleView = () => {
    const path = this.props.router.location.pathname;
    this.props.router.push(`${path}/detail`);
  };

  documentUpdated = data => {
    const {organisationId, projectId, documentId} = this.props.params;
    if (this.props.document.updateInProgress) {
      this.setState({updatedWhileSaving: data});
    }
    this.props.documentUpdate(
      organisationId,
      projectId,
      parseInt(documentId, 10),
      {last_edited: this.props.document.last_edited, ...data},
      this.props.document.updateInProgress,
    );
  };

  clausepartsTopicsUpdated = data => {
    if (data) {
      const {organisationId} = this.props.params;
      this.props.documentsClausepartsTopicsUpdate(organisationId, data);
    }
  };

  updateClausepartIsBadlyParsed = (clauseId, id, isBadlyParsed) => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.documentsClausepartUpdate(
      organisationId,
      projectId,
      documentId,
      clauseId,
      id,
      isBadlyParsed,
    );
  };

  existingTopicAdded = (
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicId,
  ) => {
    const {organisationId, projectId, documentId} = this.props.params;
    return this.props.documentClauseTopicAdd(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      {id: topicId},
    );
  };

  newTopicAdded = (
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicData,
  ) => {
    const {organisationId, projectId, documentId} = this.props.params;
    return this.props.documentClauseTopicAdd(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topicData,
    );
  };

  topicRemoved = (
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicId,
  ) => {
    const {organisationId, projectId, documentId} = this.props.params;
    return this.props.documentClauseTopicRemove(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topicId,
    );
  };

  topicConfirmed = async (
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicId,
  ) => {
    const {organisationId, projectId, documentId} = this.props.params;
    await this.props.documentClauseTopicConfirm(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topicId,
    );
  };

  unconfirmedTopicsRemoved = (
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
  ) => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.documentClauseUnconfirmedTopicsRemove(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
    );
  };

  topicsReordered = (
    sectionId,
    clauseId,
    clausepartId,
    clauseLastEdited,
    topicsOrder,
  ) => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.documentClauseTopicsReorder(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topicsOrder,
    );
  };

  splitClause = (
    sectionId,
    clauseId,
    clauseLastEdited,
    clausepartId,
    startText,
    endText,
  ) => {
    const {organisationId, projectId, documentId} = this.props.params;
    const documentLastEdited = this.props.document.last_edited;
    this.props.clauseSplit(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      documentLastEdited,
      startText,
      endText,
    );
  };

  removeSplits = (sectionId, clauseId, clauseLastEdited, clausepartId) => {
    const {organisationId, projectId, documentId} = this.props.params;
    const documentLastEdited = this.props.document.last_edited;
    this.props.removeSplits(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      documentLastEdited,
    );
  };

  confirmAllTopics = () => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.documentTopicsConfirmAll(organisationId, projectId, documentId);
  };

  confirmAllIssues = () => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.documentIssuesConfirmAll(organisationId, projectId, documentId);
  };

  toggleExactMatch = (
    sectionId,
    clauseId,
    clausepartId,
    topicId,
    exactMatchId,
    clauseTopicLastEdited,
  ) => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.toggleExactMatch(
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      topicId,
      exactMatchId,
      clauseTopicLastEdited,
    );
  };

  onTopicparameterValuesUpdate = (
    projectId,
    documentId,
    clauseId,
    clausepartId,
    topicId,
    data,
    topicType,
  ) => {
    const {organisationId} = this.props.params;
    this.props.onTopicparameterValuesUpdate(
      organisationId,
      projectId,
      documentId,
      clauseId,
      clausepartId,
      topicId,
      data,
      topicType,
    );
  };

  onDocumentIssuesFindRun = () => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.documentIssuesFindRun(organisationId, projectId, documentId);
  };

  fetchDocument = () => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.documentFetch(organisationId, projectId, documentId);
  };

  fetchDocumentTopics = () => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.documentTopicsFetch(organisationId, projectId, documentId);
  };

  refreshDocumentAndTopicsData = () => {
    this.fetchDocument();
    this.fetchDocumentTopics();
  };

  documentClausepartsRegexMatchesFetch = () => {
    const {organisationId, projectId, documentId} = this.props.params;
    return this.props.documentClausepartsRegexMatchesFetch(
      organisationId,
      projectId,
      documentId,
    );
  };
}

function select(state, props) {
  return {
    project: state.project,
    queryParams: getRouteQuery(state.router),
    params: _.mapObject(props.params, id => parseInt(id, 10)),
    document: state.documents
      ? {...state.document, ...byId(state.documents)[props.params.documentId]}
      : {},
    documentClauses: state.documentClauses.clauses,
    documentChanges: state.documentChanges,
    documentDefinitions: state.documentDefinitions,
    documentHeadings: state.documentHeadings,
    documentSections: state.documentSections,
    documentIssues: state.documentIssues,
    topicMasks: state.topicMasks,
    topicCategories: state.topicCategories,
    topicTags: state.topicTags,
    topics: state.topics,
    user: state.user,
    contractTypes: state.contract_types,
    roles: state.roles,
    topicUpdates: state.topicUpdates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        documentFetch: documentFetch(requestor),
        documentUpdate: documentUpdate(requestor),
        documentTopicsFetch: documentTopicsFetch(requestor),
        documentClauseTopicAdd: documentClauseTopicAdd(requestor),
        documentClauseTopicRemove: documentClauseTopicRemove(requestor),
        documentsClausepartsTopicsUpdate: documentsClausepartsTopicsUpdate(
          requestor,
        ),
        documentsClausepartUpdate: documentsClausepartUpdate(requestor),
        documentClauseTopicConfirm: documentClauseTopicConfirm(requestor),
        documentClauseTopicsReorder: documentClauseTopicsReorder(requestor),
        documentClauseUnconfirmedTopicsRemove: documentClauseUnconfirmedTopicsRemove(
          requestor,
        ),
        clauseSplit: clauseSplit(requestor),
        removeSplits: clauseRemoveSplits(requestor),
        toggleExactMatch: documentClauseTopicToggleExactMatch(requestor),
        documentTopicsConfirmAll: documentTopicsConfirmAll(requestor),
        onTopicparameterValuesUpdate: topicParameterValuesUpdate(requestor),
        documentIssuesFindRun: documentIssuesFindRun(requestor),
        documentIssuesConfirmAll,
        documentClausepartsRegexMatchesFetch: documentClausepartsRegexMatchesFetch(
          requestor,
        ),
        logPageLoadTime: logPageLoadTime(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(DocumentClausesContainer);
export const Component = DocumentClausesContainer;
