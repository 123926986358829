import ChildRenderer from "common_components/child_renderer";

import BaseRoute from "utils/base_route";

import LlmRunList from "routes/llm_run/llm_run_list";
import LlmRunDetail from "routes/llm_run/llm_run_detail";

export default class LlmRunBase extends BaseRoute {
  constructor(store) {
    super("llm_run", store);
  }

  createChildRoutes() {
    return [new LlmRunList(this.store), new LlmRunDetail(this.store)];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData() {}

  clearActiveData() {}
}
