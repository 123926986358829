import BaseRoute from "utils/base_route";

import UserDetailContainer from "./containers/user_detail";

export default class UserDetail extends BaseRoute {
  constructor(store) {
    super(":userId", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, UserDetailContainer);
  }
}
