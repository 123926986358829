import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function updateDocumentIssue(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_CREATE,
    (organisationId, projectId, data) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}/document`,
          data,
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
