import React from "react";
import styled from "styled-components";

import Fieldset from "./styled/fieldset";
import Legend from "./styled/legend";
import calculateDuration from "../../utils/calculate_duration";
import renderDuration from "../../utils/render_duration";
import getStatus from "routes/llm_run/utils/get_status";

import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";
import {ActionOutput} from "common/flowmaster/types/workflow";

interface Props {
  llmRun: EnhancedLlmRun;
}

const Container = styled(Fieldset)`
  padding-left: 1em;
`;

const KeyValues = styled.table`
  margin-right: 1em;
`;

const RowHeading = styled.th`
  text-align: right;
`;

const Matrix = styled.table`
  background-color: #eee;
  border-spacing: 0.25em;
  padding: 0.5em;
`;

function getMeta(item: string | ActionOutput, key: string): number {
  return ((item as ActionOutput)?.meta as Record<string, unknown>)?.[
    key
  ] as number;
}

export default function Inputs({llmRun}: Props) {
  const taskData = llmRun.data.data_store.task.data;
  const tasks = Object.entries(
    llmRun.data.data_store.task.indexes,
  ).map(([id, index]) => [id, taskData[index]]);
  const totalCost = tasks.reduce(
    (total, [, item]) => total + (getMeta(item, "cost") ?? 0),
    0,
  );
  const totalTokens = tasks.reduce(
    (total, [, item]) => total + (getMeta(item, "total_tokens") ?? 0),
    0,
  );
  const maxCost = tasks.reduce(
    (best, [id, item]) => {
      const cost = getMeta(item, "cost");
      if (cost > best.max) {
        return {max: cost, id, item};
      }
      return best;
    },
    {max: 0, id: null, item: null},
  );
  const maxTokens = tasks.reduce(
    (best, [id, item]) => {
      const totalTokens = getMeta(item, "total_tokens");
      if (totalTokens > best.max) {
        return {max: totalTokens, id, item};
      }
      return best;
    },
    {max: 0, id: null, item: null},
  );
  const status = getStatus(llmRun);
  const {next_task: nextTask} = llmRun.data;
  const nextTaskText =
    status === "In progress" && nextTask !== null ? (
      <span style={{fontSize: "0.75em"}}>
        {" (Next task: "}
        <span style={{fontFamily: "monospace"}}>
          {llmRun.tasks.find(t => t.id === nextTask.id)?.name}
        </span>
        )
      </span>
    ) : (
      ""
    );
  return (
    <Container>
      <Legend>Summary</Legend>
      <div style={{display: "flex"}}>
        <KeyValues>
          <tbody>
            <tr>
              <RowHeading>Run ID:</RowHeading>
              <td style={{fontFamily: "monospace"}}> {llmRun.run_id}</td>
            </tr>
            <tr>
              <RowHeading>Status:</RowHeading>
              <td>
                {getStatus(llmRun)}
                {nextTaskText}
              </td>
            </tr>
            <tr>
              <RowHeading>Reason:</RowHeading>
              <td> {llmRun.creation_reason}</td>
            </tr>
            <tr>
              <RowHeading>Started:</RowHeading>
              <td> {llmRun.creation_date}</td>
            </tr>
            <tr>
              <RowHeading>Duration: </RowHeading>
              <td>
                {renderDuration(
                  calculateDuration(
                    llmRun.creation_date,
                    llmRun.data.states_performed?.at(-1)?.end_time,
                  ),
                )}
              </td>
            </tr>
          </tbody>
        </KeyValues>
        <Matrix>
          <thead>
            <tr>
              <th></th>
              <th>Cost</th>
              <th>Tokens</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Total: </th>
              <td>${totalCost}</td>
              <td>{totalTokens}</td>
            </tr>
            <tr>
              <th>Max: </th>
              <td title={`id = ${maxCost.id}`}>${maxCost.max}</td>
              <td title={`id = ${maxTokens.id}`}>{maxTokens.max}</td>
            </tr>
          </tbody>
        </Matrix>
      </div>
    </Container>
  );
}
