import requestor from "requestor";

import ChildRenderer from "common_components/child_renderer";

import BaseRoute from "utils/base_route";

import rolesFetchAction from "modules/documents/actions/role/roles_fetch";
import rolesClearAction from "modules/documents/actions/role/roles_clear";

import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";
import contractTypesClearAction from "modules/documents/actions/contract_types_clear";

import RoleGroupList from "routes/role/role_list";
import RoleDetail from "routes/role/role_detail";

export default class RolesBase extends BaseRoute {
  constructor(store) {
    super("role", store);
  }

  createChildRoutes() {
    return [new RoleGroupList(this.store), new RoleDetail(this.store)];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchContractTypes(state);
  }

  fetchContractTypes(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(rolesFetchAction(requestor)(organisationId));
    dispatch(contractTypesFetchAction(requestor)(organisationId));
  }

  clearActiveData(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(rolesClearAction(organisationId));
    dispatch(contractTypesClearAction(organisationId));
  }
}
