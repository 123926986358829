import React from "react";
import LoadingIcon from "common_components/loading_circular";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import * as colors from "@material-ui/core/colors";

const styles = {
  statsBarContainer: {
    fontSize: "12px",
    color: colors.grey[500],
    lineHeight: "26px",
    marginLeft: "0.5rem",
    display: "flex",
  },
  statsBarFirstColumn: {
    display: "flex",
    justifyContent: "flex-end",
    width: "1.6rem",
    flexShrink: "0",
    marginLeft: "0px",
    paddingRight: "4px",
    borderRight: "1px solid lightgray",
  },
  counterBase: {
    display: "flex",
    justifyContent: "flex-end",
    width: "1.6rem",
    flexShrink: "0",
    marginLeft: "6px",
  },
};

const ClassifierOptionStatusBar = ({
  name,
  hideStats,
  counters,
  isLoadingStats,
  isHovered,
  getStatsHandler,
}) => {
  return (
    <div style={styles.statsBarContainer}>
      <div style={styles.statsBarFirstColumn}>
        {name === "whitelist"
          ? hideStats ? "-" : counters.confirmed + counters.unconfirmed
          : hideStats ? "-" : counters.fp}
      </div>
      <div style={{...styles.counterBase}}>
        {hideStats ? "-" : counters.confirmed}
      </div>
      <div style={{...styles.counterBase}}>
        {hideStats ? "-" : counters.unconfirmed}
      </div>
      <div style={{...styles.counterBase}}>{hideStats ? "-" : counters.fp}</div>
      <div style={{...styles.counterBase}}>
        {hideStats ? "-" : counters.missing_matches}
      </div>
      <div>
        {isLoadingStats ? (
          <div style={{width: 36}}>
            <LoadingIcon style={{zoom: 0.4}} />
          </div>
        ) : (
          <div
            title="Fetch Regex Stats"
            style={{
              visibility: isHovered ? "visible" : "hidden",
              marginLeft: 12,
              cursor: "pointer",
            }}
          >
            <ShowChartIcon onClick={getStatsHandler} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassifierOptionStatusBar;
