import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function runDocumentIssuesUpdate(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_ISSUES_UPDATE_RUN,
    (organisationId, issueIds, options) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}/update-document-issues`,
          {issues: issueIds, options},
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
