function getRealTimeScoresByContractType(usages, topicContractTypeIds) {
  const result = usages.reduce(
    (state, use) => {
      if (!state[use.contract_type_id]) {
        state[use.contract_type_id] = {
          ctp: 0,
          cfn: 0,
          cfp: 0,
          ctn: 0,
          pnc: 0,
          fnc: 0,
          not_confirmed: 0,
        };
      }
      if (!use.is_confirmed) {
        state[use.contract_type_id].not_confirmed += 1;
        state["_all"].not_confirmed += 1;
      }
      if (use.hasRegexMatch && use.has_topic && use.is_confirmed) {
        state[use.contract_type_id].ctp += 1;
        state["_all"].ctp += 1;
      } else if (use.hasRegexMatch && !use.has_topic) {
        state[use.contract_type_id].cfp += 1;
        state["_all"].cfp += 1;
      } else if (use.hasRegexMatch && use.has_topic && !use.is_confirmed) {
        state[use.contract_type_id].pnc += 1;
        state["_all"].pnc += 1;
      } else if (!use.hasRegexMatch && use.has_topic && use.is_confirmed) {
        state[use.contract_type_id].cfn += 1;
        state["_all"].cfn += 1;
      } else if (!use.hasRegexMatch && use.has_topic && !use.is_confirmed) {
        state[use.contract_type_id].fnc += 1;
        state["_all"].fnc += 1;
      } else {
        state[use.contract_type_id].ctn += 1;
        state["_all"].ctn += 1;
      }
      return state;
    },
    {_all: {ctp: 0, cfn: 0, cfp: 0, ctn: 0, pnc: 0, fnc: 0, not_confirmed: 0}},
  );
  topicContractTypeIds.forEach(ctId => {
    if (!result[ctId]) {
      result[ctId] = {
        ctp: 0,
        cfn: 0,
        cfp: 0,
        ctn: 0,
        pnc: 0,
        fnc: 0,
        not_confirmed: 0,
      };
    }
  });
  return result;
}

export default getRealTimeScoresByContractType;
