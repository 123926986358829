import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function addTopicToClause(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENTS_CLAUSEPARTS_TOPICS_UDPATE,
    (organisationId, clauseparts) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}/clauseparts-topics`,
          {clauseparts},
          {
            withCredentials: true,
          },
        )
        .then(response => response.data),
  );
}
