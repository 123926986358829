const replaceMatch = (obj, match) => {
  if (match) {
    return obj[match.slice(2, -1)] || "";
  }
  return "";
};

const generateTemplate = (str, obj) =>
  str.replace(/\$\{.+?\}/g, match => replaceMatch(obj, match));

export default generateTemplate;
