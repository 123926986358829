import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";

import keyedObjectPropType from "utils/keyed_object_prop_type";

import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";

import TopicList from "./topic_list";

export class SortTopicDialog extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      order: [],
      topicOrder: _.chain(props.clauseTopics || [])
        .filter(item => item.is_confirmed)
        .sortBy(item => (item.topic_order === 0 ? Infinity : item.topic_order))
        .value(),
    };
  }

  render() {
    const {onDismiss} = this.props;
    const actions = [
      <FlatButton
        label="Cancel"
        key="sort-topic-dialog-cancel-button"
        secondary={true}
        onClick={onDismiss}
      />,
      <FlatButton
        label="Save"
        key="sort-topic-dialog-save-button"
        primary={true}
        onClick={this.saveOrder}
        className="save-button"
      />,
    ];
    return (
      <Dialog
        title="Sort topics"
        onDismiss={onDismiss}
        open={true}
        actions={actions}
        style={{
          top: "3em",
        }}
        bodyStyle={{
          color: "#000",
        }}
      >
        <TopicList
          {...this.props}
          topicOrder={this.state.topicOrder}
          onChange={this.changeTopicOrder}
        />
      </Dialog>
    );
  }

  /* eslint-disable no-invalid-this */
  changeTopicOrder = topicOrder => this.setState({topicOrder});

  saveOrder = () => {
    this.props.onSave(this.state.topicOrder.map(topic => topic.topic_id));
  };
  /* eslint-enable no-invalid-this */
}

SortTopicDialog.propTypes = {
  clauseTopics: PropTypes.arrayOf(
    PropTypes.shape({
      topic_id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  topicsById: keyedObjectPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  onDismiss: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default SortTopicDialog;
