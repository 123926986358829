import React from "react";
import requestor from "requestor";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import byId from "common/utils/by_id";

import TopicParametersComponent from "../components/topic_parameters";

import topicParametersFetchAction from "modules/documents/actions/topic_parameters_fetch";
import topicParameterUpdateAction from "modules/documents/actions/topicparameter_update";

class TopicParameters extends React.Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return this.permissioner.isAdmin();
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.topicParameters,
      this.props.contractTypes,
      this.props.user,
    ]);
  }

  renderComponent() {
    setTitle("Topic Parameters");
    const contractTypesById = byId(this.props.contractTypes);
    return (
      <TopicParametersComponent
        organisationId={parseInt(this.props.params.organisationId, 10)}
        contractTypesById={contractTypesById}
        topicParameters={this.props.topicParameters}
        toggleView={this.toggleView}
        fetchTopicparameters={this.fetchTopicparameters}
        onTopicparameterUpdate={this.onTopicparameterUpdate}
      />
    );
  }

  toggleView = path => {
    const fullPath = this.props.router.location.pathname;
    const basePath = fullPath.substring(0, fullPath.lastIndexOf("/"));
    this.props.router.push(`${basePath}/${path}`);
  };

  fetchTopicparameters = () => {
    const organisationId = parseInt(this.props.params.organisationId, 10);
    this.props.topicParametersFetchAction(organisationId);
  };

  onTopicparameterUpdate = (topicparameterId, data) => {
    const organisationId = parseInt(this.props.params.organisationId, 10);
    const topicparameter = this.props.topicParameters.find(
      tp => tp.topicparameter_id === topicparameterId,
    );
    return this.props.topicParameterUpdateAction(
      organisationId,
      topicparameter.topic_id,
      topicparameterId,
      {
        ...data,
        last_edited: topicparameter.last_edited,
      },
    );
  };
}

function select(state) {
  return {
    topicParameters: state.topic_parameters,
    contractTypes: state.contract_types,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        topicParametersFetchAction: topicParametersFetchAction(requestor),
        topicParameterUpdateAction: topicParameterUpdateAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(TopicParameters);
export const Component = TopicParameters;
