import Tooltip from "@material-ui/core/Tooltip";
import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";

import {Toolbar, ToolbarGroup, ToolbarTitle} from "material-ui/Toolbar";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";

import sessionStorage from "utils/session_storage";
import ClientOrganisationsItem from "./client_organisations_item";
import ClientDialog from "./client_dialog";
import SyncClientIssuesetDialog from "./sync_client_issueset_dialog";
import ToolbarCheckbox from "common_components/toolbar/checkbox";

class ClientOrganisationsComponent extends React.Component {
  state = {
    dialogClientId: null,
    isDialogShown: false,
    isSyncDialogShown: false,
    syncDialogData: null,
    showArchived: sessionStorage.getItem("showArchived"),
  };

  showDialog = (dialogClientId = null) => {
    this.setState({
      isDialogShown: true,
      dialogClientId,
    });
  };

  hideDialog = () => {
    this.setState({
      isDialogShown: false,
      dialogClientId: null,
    });
  };

  showSyncDialog = data => {
    this.hideDialog();
    this.setState({
      isSyncDialogShown: true,
      syncDialogData: data,
    });
  };

  hideSyncDialog = () => {
    this.setState({
      dialogClientId: this.state.syncDialogData.clientId,
      isDialogShown: true,
      isSyncDialogShown: false,
      syncDialogData: null,
    });
  };

  render() {
    const {state} = this;
    return (
      <div className="app-page">
        <Toolbar className="app-toolbar">
          <ToolbarGroup key={0}>
            <ToolbarTitle text="Client Organisations" />
          </ToolbarGroup>
          <ToolbarGroup
            key={1}
            style={{
              height: "40px",
            }}
          >
            <ToolbarCheckbox
              checked={state.showArchived}
              label="Show archived"
              onCheck={this.onCheckShowArchived}
            />
            <Tooltip title="Add organisation" placement="top" arrow>
              <FloatingActionButton mini={true} onClick={this.showDialog}>
                <ContentAdd />
              </FloatingActionButton>
            </Tooltip>
          </ToolbarGroup>
        </Toolbar>
        <div className="app-body" style={{padding: "1em 2em"}}>
          <table style={{borderCollapse: "collapse", width: "100%"}}>
            <thead>
              <tr style={{height: "2.2em"}}>
                <th style={{width: "2em"}}>Id</th>
                <th style={{width: "20em"}}>Name</th>
                <th style={{width: "20em"}}>Creation Date</th>
                <th style={{width: "20em"}}>Earliest Sync Date</th>
                <th style={{width: "20em"}}>Latest Sync Date</th>
                {state.showArchived && (
                  <th style={{width: "10em"}}>Is archived</th>
                )}
                <th style={{width: "4em"}} />
              </tr>
            </thead>
            <tbody>{this.renderClientOrganisationsItems()}</tbody>
          </table>
        </div>
        <ClientDialog
          user={this.props.user}
          clientData={this.getDialogClientData()}
          usedNames={this.props.clients.map(client => client.name)}
          permissionGroups={this.props.permissionGroups}
          contractTypes={this.props.contractTypes}
          isShown={this.state.isDialogShown}
          onHide={this.hideDialog}
          onSendClientData={this.sendClientData}
          organisationId={this.props.organisationId}
          onSyncDialogShow={this.showSyncDialog}
          checkIsArchivedHandler={this.props.checkIsArchivedHandler}
          checkIssuesetIsArchived={this.props.checkIssuesetIsArchived}
          updateIssuesetName={this.props.updateIssuesetName}
        />
        <SyncClientIssuesetDialog
          data={this.state.syncDialogData}
          isShown={this.state.isSyncDialogShown}
          onHide={this.hideSyncDialog}
          onSyncIssueset={this.props.onSyncClientIssueset}
          loadSyncListOfChanges={this.props.loadSyncListOfChanges}
        />
      </div>
    );
  }

  renderClientOrganisationsItems() {
    const {props, state} = this;
    const filteredClients = state.showArchived
      ? props.clients
      : props.clients.filter(({is_archived: isArchived}) => !isArchived);
    return filteredClients.map((client, index) => (
      <ClientOrganisationsItem
        key={client.id}
        client={client}
        isLastItem={index === props.clients.length - 1}
        showArchived={state.showArchived}
        onShowDialog={this.showDialog}
        onCheckIsArchived={props.checkIsArchivedHandler(client)}
      />
    ));
  }

  getDialogClientData = () => {
    return {
      name: "",
      contract_types: [],
      default_permission_group_id: this.props.organisation
        .default_permission_group_id,
      ...this.props.clients.find(
        client => client.id === this.state.dialogClientId,
      ),
    };
  };

  sendClientData = data => {
    const clientId = data.id;
    const sendData = _.omit(data, "id");
    if (!clientId) {
      this.props.addClient(sendData);
    } else {
      this.props.updateClient(clientId, sendData);
    }
  };

  onCheckShowArchived = () =>
    this.setState(
      state => ({showArchived: !state.showArchived}),
      () => sessionStorage.setItem("showArchived", this.state.showArchived),
    );
}

ClientOrganisationsComponent.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      creation_date: PropTypes.string.isRequired,
      contract_types: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          master_id: PropTypes.string.isRequired,
          issuesets: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
              master_id: PropTypes.string.isRequired,
              sample_issue_id: PropTypes.number,
              sample_project_id: PropTypes.number,
              sample_document_id: PropTypes.number,
            }),
          ).isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  permissionGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  contractTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      master_id: PropTypes.string.isRequired,
      issuesets: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          master_id: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  addClient: PropTypes.func.isRequired,
  updateClient: PropTypes.func.isRequired,
  organisationId: PropTypes.number.isRequired,
  onSyncClientIssueset: PropTypes.func.isRequired,
  loadSyncListOfChanges: PropTypes.func.isRequired,
};

export default ClientOrganisationsComponent;
export {ClientOrganisationsComponent};
