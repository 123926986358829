import BaseRoute from "utils/base_route";

import ProjectRoute from "routes/project/project_base";
import ProjectTaskRoute from "routes/project_tasks";
import DocumentsBaseRoute from "routes/documents_base";

import ChildRenderer from "common_components/child_renderer";

export default class Base extends BaseRoute {
  constructor(store) {
    super("project", store);
  }

  createChildRoutes() {
    return [
      new ProjectRoute(this.store),
      new ProjectTaskRoute(this.store),
      new DocumentsBaseRoute(this.store),
    ];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }
}
