import getIssueUiOrderIds from "./get_issue_ui_order_ids";

export default function getIssuePositions(issue, defaultValue = true) {
  const issuePaths = getIssueUiOrderIds(issue);
  return issuePaths.reduce(
    (issuePositions, ui_order_id) => ({
      ...issuePositions,
      [ui_order_id]: defaultValue,
    }),
    {},
  );
}
