import {makeUiOrderId} from "./make_ui_order_id";

export default function swapNodes(rules, path, firstIndex, secondIndex) {
  const newTree = JSON.parse(JSON.stringify({rules}));
  if (rules) {
    let node = newTree;
    if (path && path !== "root") {
      const indexes = path.split(".");
      let ui_order_id = "";
      const condition = issue => issue.ui_order_id === ui_order_id;
      for (
        let i = 0;
        i < indexes.length && node && node.rules && node.rules.issues;
        i += 1
      ) {
        const index = indexes[i];
        ui_order_id = makeUiOrderId(ui_order_id, index);
        node = node.rules.issues.find(condition);
      }
    }
    const index1 = parseInt(firstIndex, 10);
    const index2 = parseInt(secondIndex, 10);
    if (
      node &&
      node.rules &&
      node.rules.issues &&
      typeof node.rules.issues[index1] !== "undefined" &&
      typeof node.rules.issues[index2] !== "undefined"
    ) {
      const temp = node.rules.issues[index1];
      node.rules.issues[index1] = node.rules.issues[index2];
      node.rules.issues[index2] = temp;
    }
  }
  return newTree.rules;
}
