import requestor from "requestor";

import BaseRoute from "utils/base_route";
import getRouteIds from "utils/get_route_ids";

import ProjectTasks from "./containers/project_tasks";
import ProjectTaskDetailRoute from "routes/project_task_detail";

import projectChangeTransition from "modules/projects/transitions/project_change";

import projectTasksFetchAction from "modules/tasks/actions/project_tasks_fetch";
import taskroleFetchAction from "modules/tasks/actions/taskrole_fetch";

export default class Project extends BaseRoute {
  constructor(store) {
    super(":projectId/task", store);
  }

  setupTransitions() {
    this.setupProjectChangeTransition();
  }

  setupProjectChangeTransition() {
    projectChangeTransition(this.store).subscribe(props => {
      this.fetchData(props);
    });
  }

  createChildRoutes() {
    return [new ProjectTaskDetailRoute(this.store)];
  }

  getComponents(location, cb) {
    cb(null, ProjectTasks);
  }

  fetchActiveData(props) {
    this.fetchProjectTasks(props);
    this.fetchTaskroles(props);
  }

  fetchProjectTasks(props) {
    const {organisationId, projectId} = getRouteIds(props.router);
    if (projectId) {
      this.store.dispatch(
        projectTasksFetchAction(requestor)(organisationId, projectId),
      );
    }
  }

  fetchTaskroles(props) {
    const {organisationId, projectId} = getRouteIds(props.router);
    if (projectId) {
      this.store.dispatch(taskroleFetchAction(requestor)(organisationId));
    }
  }
}
