import _ from "underscore";
import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";

export default createReduxPromiseAction(
  ActionTypes.SIGNUP_ADD,
  (firstName, lastName, companyName, email, password) =>
    requestor
      .post(
        `${basePath}/auth/signup`,
        {
          first_name: firstName,
          last_name: lastName,
          email,
          company_name: companyName,
          password,
        },
        {withCredentials: true},
      )
      .then(response => response.data)
      .catch(response => Promise.reject(_.pick(response, "data"))),
);
