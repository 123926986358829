import basePath from "base_path";

import ActionTypes from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";

import rateLimit, {getStandardTimeout} from "utils/rate_limit";
import {createChangeTimeWrapper} from "utils/ensure_fresh_update";

function doSave(
  requestor,
  organisationId,
  topicCategoryId,
  lastEdited,
  data,
  changeTime,
) {
  return requestor
    .patch(
      `${basePath}/organisation/${organisationId}` +
        `/topiccategory/${topicCategoryId}`,
      {...data, last_edited: lastEdited},
      {withCredentials: true},
    )
    .then(response => ({
      ...createChangeTimeWrapper(changeTime),
      ...response.data,
    }));
}

const save = rateLimit(doSave, getStandardTimeout());

export default function updateTopicCategory(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.TOPIC_CATEGORY_UPDATE,
    (organisationId, topicCategoryId, lastEdited, data, changeTime) => {
      return save(
        requestor,
        organisationId,
        topicCategoryId,
        lastEdited,
        data,
        changeTime,
      );
    },
    (organisationId, topicCategoryId, lastEdited, data, changeTime) => ({
      ...createChangeTimeWrapper(changeTime),
      id: topicCategoryId,
      last_edited: lastEdited,
      ...data,
    }),
  );

  return action;
}
