import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../constants/action_types";

/* eslint-disable max-params */
export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_CLAUSEPART_CHANGES_REVERT,
  (
    organisationId,
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
  ) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/section/${sectionId}/clause/${clauseId}` +
          `/clausepart/${clausepartId}/revert`,
        {last_edited: clausepartLastEdited},
        {withCredentials: true},
      )
      .then(response => response.data),
);
