import _ from "lodash";
import React, {useState} from "react";
import {IconButton, TextField} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";

import WorkflowSelector from "common_components/issue_editor/components/workflow_selector";
import Prerequisite from "./prerequisite";
import PrerequisiteSelector from "./prerequisite_selector";
import Fieldset from "../styled/fieldset";
import Legend from "../styled/legend";

import {
  Workflow,
  WorkflowId,
  WorkflowInputId,
  WorkflowInstanceDetail,
  WorkflowInstanceId,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

interface PrerequisitesProps {
  organisationId: OrganisationId;
  prerequisites: WorkflowInstanceDetail[];
  existingPrerequisites: WorkflowInstanceDetail[];
  workflows: Workflow[];
  addPrerequisite: (
    selectedWorkflow?: WorkflowId,
    workflowInstanceName?: string,
    workflowInstanceId?: WorkflowInstanceId,
  ) => void;
  updatePrerequisiteInputValue: (
    id: WorkflowInstanceId,
    inputId: WorkflowInputId,
    value: string,
  ) => void;
  removePrerequisite: (id: WorkflowInstanceId) => void;
  updateWorkflowInstanceName: (
    id: WorkflowInstanceId,
    instanceName: string,
  ) => void;
}

const Prerequisites: React.FC<PrerequisitesProps> = ({
  organisationId,
  prerequisites,
  existingPrerequisites,
  workflows,
  addPrerequisite,
  updatePrerequisiteInputValue,
  removePrerequisite,
  updateWorkflowInstanceName,
}) => {
  const [showAddNewSelector, setShowAddNewSelector] = useState(false);
  const [showNewPrerequisiteForm, setShowNewPrerequisiteForm] = useState(false);
  const [newPrerequisiteName, setNewPrerequisiteName] = useState("");

  const [selectedWorkflowId, setSelectedWorkflowId] = useState<WorkflowId>(-1);

  const formattedPrereqs = existingPrerequisites
    .filter(prereq => !prerequisites.some(wf => wf.id === prereq.id))
    .map(prereq => ({
      ...prereq,
      name: !_.isEmpty(prereq.inputs)
        ? prereq.name ?? `${prereq.workflow_name} (ID: ${prereq.id})`
        : `${prereq.workflow_name} (ID: ${prereq.id})`,
    }));

  const handleAddClick = () => {
    setShowAddNewSelector(true);
  };

  const handlePrerequisiteSelection = (
    workflowInstanceId: WorkflowInstanceId,
  ) => {
    addPrerequisite(undefined, undefined, workflowInstanceId);
    setShowAddNewSelector(false);
  };

  const handleNewPrerequisite = () => {
    setShowAddNewSelector(false);
    setShowNewPrerequisiteForm(true);
  };

  const handleSaveNewPrerequisite = () => {
    if (selectedWorkflowId) {
      addPrerequisite(selectedWorkflowId, newPrerequisiteName);
      setShowNewPrerequisiteForm(false);
      setNewPrerequisiteName("");
      setSelectedWorkflowId(-1);
    }
  };

  const showWorkflowInstanceName = !_.isEmpty(
    workflows.find(wf => wf.id === selectedWorkflowId)?.inputs.values,
  );

  return (
    <Fieldset>
      <Legend>Prerequisite Workflows</Legend>
      <div style={{padding: "12px"}}>
        {_.sortBy(prerequisites, "id").map(prerequisite => (
          <Prerequisite
            key={prerequisite.id}
            organisationId={organisationId}
            prerequisite={prerequisite}
            workflows={workflows}
            updatePrerequisiteInputValue={(inputId, value) =>
              updatePrerequisiteInputValue(prerequisite.id, inputId, value)
            }
            removePrerequisite={() => removePrerequisite(prerequisite.id)}
            updatWorkflowInstanceName={(instanceName: string) =>
              updateWorkflowInstanceName(prerequisite.id, instanceName)
            }
          />
        ))}
        {showAddNewSelector && (
          <div style={{marginLeft: "1em"}}>
            <PrerequisiteSelector
              organisationId={organisationId}
              selectedWorkflowId={-1}
              updateselectedWorkflowId={handlePrerequisiteSelection}
              workflows={formattedPrereqs}
              onAddNew={handleNewPrerequisite}
            />
          </div>
        )}
        {showNewPrerequisiteForm && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              gap: "1em",
              marginLeft: "1em",
            }}
          >
            <WorkflowSelector
              organisationId={organisationId}
              selectedWorkflowId={selectedWorkflowId}
              updateselectedWorkflowId={setSelectedWorkflowId}
              workflows={workflows}
            />
            {showWorkflowInstanceName && (
              <TextField
                style={{width: "15em"}}
                value={newPrerequisiteName}
                onChange={e => setNewPrerequisiteName(e.target.value)}
                placeholder="Prerequisite name"
              />
            )}
            <IconButton
              style={{alignSelf: "flex-end"}}
              onClick={handleSaveNewPrerequisite}
            >
              <SaveIcon />
            </IconButton>
          </div>
        )}
        {!showAddNewSelector && !showNewPrerequisiteForm && (
          <IconButton onClick={handleAddClick}>
            <AddIcon />
          </IconButton>
        )}
      </div>
    </Fieldset>
  );
};

export default Prerequisites;
