import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";

import keyedObjectPropType from "utils/keyed_object_prop_type";

import DocumentIssueItem from "./document_issue_item";
import EmailDialog from "./email_dialog";
import FlatButton from "material-ui/FlatButton";

import SquareIconButton from "common_components/buttons/square_icon_button.js";
import reasonDescriber from "common/utils/issues/reason/describer";
import padClausepartId from "common/utils/pad_clausepart_id";
import isIssueAlert from "common/utils/issues/is_issue_alert";

export default class DocumentIssueList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showEmail: false, showMenu: false};
  }
  render() {
    if (!this.props.documentIssues || this.props.documentIssues.length === 0) {
      return <div />;
    }
    return (
      <div
        style={{
          backgroundColor: "#fdd",
          padding: "1em",
          borderRadius: "0.25em",
        }}
      >
        <div
          className="title"
          style={{display: "flex", justifyContent: "space-between"}}
        >
          <div />
          <div>
            <div
              style={{
                fontSize: "2em",
                fontWeight: "bold",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              Issues
            </div>
            <FlatButton
              className="create-email"
              label="Create email"
              onClick={this.createEmail}
              style={{bottom: "5px"}}
            />
          </div>
          <SquareIconButton
            onButtonClick={this.triggerShowMenu}
            open={this.state.showMenu}
            style={{border: "1px solid #616161"}}
          />
        </div>
        {this.state.showMenu && <div>{this.renderList()}</div>}
        {this.renderEmail()}
      </div>
    );
  }

  renderList() {
    const {documentClauses, topicsById} = this.props;

    return _.chain(this.props.documentIssues)
      .filter(issue => isIssueAlert(issue))
      .sortBy(issue => {
        const describer = new reasonDescriber(
          documentClauses,
          topicsById,
          issue,
        );
        const sortedClauses = _.sortBy(
          describer.clauseparts,
          clausepart =>
            clausepart.clauseId ? padClausepartId(clausepart.clauseId) : "zz",
        );
        const sortReference =
          sortedClauses.length > 0 ? sortedClauses[0].clauseId : "zz";
        if (!issue.email_template || issue.issue_class_id === 2) {
          return `z_${sortReference}`;
        }
        return sortReference;
      })
      .map(issue => (
        <DocumentIssueItem
          documentIssue={issue}
          {...this.props}
          key={issue.id}
        />
      ))
      .value();
  }

  renderEmail() {
    if (this.state.showEmail) {
      return (
        <EmailDialog
          {...this.props}
          handleClose={this.handleEmailDialogClose}
        />
      );
    }
    return null;
  }

  /* eslint-disable no-invalid-this */
  createEmail = () => {
    this.setState({showEmail: true});
  };

  handleEmailDialogClose = () => {
    this.setState({showEmail: false});
  };

  triggerShowMenu = () =>
    this.setState(prevState => ({showMenu: !prevState.showMenu}));
  /* eslint-enable no-invalid-this */
}

DocumentIssueList.propTypes = {
  documentIssues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  documentClauses: keyedObjectPropType(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        reference: PropTypes.string.isRequired,
      }),
    ),
  ),
  topicsById: keyedObjectPropType(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};
