import _ from "underscore";
import sessionStorage from "utils/session_storage";
import getIssuePositions from "./get_issue_positions";
import updateIssuePositions from "./update_issue_positions";

export default class IssuePositionsHelper {
  constructor(masterIssueId) {
    this.masterIssueId = masterIssueId;
    const storageValue = sessionStorage.getItem("issuePositions") || {};
    this._issuePositions = storageValue[masterIssueId];
  }

  get issuePositions() {
    return this._issuePositions.positions;
  }

  set issuePositions(positions) {
    this._issuePositions.positions = positions;
    this.save();
  }

  getIssuePositionsFromMasterId() {
    const storageValue = sessionStorage.getItem("issuePositions") || {};
    const issuePositions = storageValue[this.masterIssueId] || {};
    return issuePositions.positions;
  }

  updateMasterIssueId = masterIssueId => {
    this.masterIssueId = masterIssueId;
  };

  updateIfShould(issueId, issue) {
    let positions = getIssuePositions(issue);

    if (!this._issuePositions || issueId !== this._issuePositions.issueId) {
      const storageValue = sessionStorage.getItem("issuePositions") || {};
      positions =
        (storageValue[this.masterIssueId] || {}).positions || positions;
      this._issuePositions = {
        issueId,
        positions,
      };
      this.save();
      return positions;
    }

    if (
      !_.isEqual(
        Object.keys(positions),
        Object.keys(this._issuePositions.positions),
      )
    ) {
      const updatedPositions = updateIssuePositions(
        issue,
        this._issuePositions.positions,
      );
      this._issuePositions.positions = updatedPositions;
      this.save();
    }

    return this._issuePositions.positions;
  }

  save() {
    const storageValue = sessionStorage.getItem("issuePositions") || {};
    const newValue = {
      ...storageValue,
      [this.masterIssueId]: this._issuePositions,
    };
    sessionStorage.setItem("issuePositions", newValue);
  }
}
