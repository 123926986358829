import basePath from "base_path";
import ActionTypes, {SearcherActionTypes} from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";

import {OrganisationId} from "common/types/organisation";
import {ProjectId} from "common/types/project";
import {DocumentId, ClausepartId} from "common/types/document";
import AlgorithmSetting from "../types/algorithm_setting";

export interface QueryResult {
  id: ClausepartId;
  partial_text: string;
  scores: Record<string, number>;
}

export type QueryRunAction = {
  type: SearcherActionTypes["SEARCHER_QUERY_RUN"]["SUCCESS"];
  payload: QueryResult[];
};

export default function initDocument(requestor) {
  return createReduxPromiseAction(
    ActionTypes.SEARCHER_QUERY_RUN,
    (
      organisationId: OrganisationId,
      projectId: ProjectId,
      documentId: DocumentId,
      query: string,
      algorithms: AlgorithmSetting[],
    ) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/searcher`,
          {document_id: documentId, query, algorithms},
          {withCredentials: true},
        )
        .then(response => response.data);
    },
  );
}
