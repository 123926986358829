import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import Chip from "material-ui/Chip";
import {ListItem} from "material-ui/List";
import IconButton from "material-ui/IconButton";
import ContentAddCircle from "material-ui/svg-icons/content/add-circle";
import ActionDelete from "material-ui/svg-icons/action/delete";
import Column from "./Column";

import * as colors from "material-ui/styles/colors";

const styles = {
  dialogContent: {
    position: "absolute",
    left: "50%",
    top: "30%",
    transform: "translate(-50%, -30%)",
  },
  dialogBody: {
    paddingBottom: 0,
  },
  title: {
    display: "flex",
    alignItems: "center",
    height: 76,
    boxSizing: "border-box",
  },
  titleH3: {
    margin: "0px 10px 0 0",
  },
  content: {
    position: "relative",
    height: 768,
  },
  row: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 24,
    left: 0,
    overflow: "hidden",
    border: `1px solid ${colors.grey300}`,
    display: "flex",
    flexWrap: "wrap",
  },
  item: {
    cursor: "default",
  },
};

class ManagePermissionGroupDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {contentHeight: 0};
  }

  componentDidMount() {
    this.detectContentHeight();
    window.addEventListener("resize", this.detectContentHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.detectContentHeight);
  }

  detectContentHeight = () => {
    const clientHeight = window.innerHeight;
    const titleHeight = styles.title.height;
    this.setState({contentHeight: clientHeight - 2 * 64 - titleHeight});
  };

  render() {
    const group = this.props.group || {name: ""};
    return (
      <Dialog
        modal={false}
        open={this.props.manageDialogShown}
        contentStyle={styles.dialogContent}
        bodyStyle={styles.dialogBody}
        onRequestClose={this.props.onHideManageDialog}
        title={this.renderTitle(group)}
      >
        {this.renderContent(group)}
      </Dialog>
    );
  }

  renderTitle(group) {
    return (
      <div style={styles.title}>
        <h3 style={styles.titleH3}>Set permissions</h3>
        <Chip>{group.name}</Chip>
      </div>
    );
  }

  renderContent(group) {
    return (
      <div style={{...styles.content, height: this.state.contentHeight}}>
        <div style={styles.row}>
          {[
            {
              title: "Available permissions",
              items: this.renderAvailablePermissionItems(group),
            },
            {
              title: "Used permissions",
              items: this.renderUsedPermissionItems(group),
            },
          ].map(({title, items}) => (
            <div key={title} style={{height: "100%", width: "50%"}}>
              <Column title={title}>{items}</Column>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderAvailablePermissionItems(group) {
    return this.props.availablePermissions.map(permission => (
      <ListItem
        key={permission.id}
        primaryText={permission.name}
        hoverColor={"#fff"}
        disabled={true}
        style={styles.item}
        rightIconButton={
          <IconButton
            onClick={() =>
              this.props.addPermissionGroupPermission(group.id, permission.id)
            }
          >
            <ContentAddCircle color={colors.grey400} />
          </IconButton>
        }
      />
    ));
  }

  renderUsedPermissionItems(group) {
    return this.props.usedPermissions.map(permission => (
      <ListItem
        key={permission.id}
        primaryText={permission.name}
        hoverColor={"#fff"}
        disabled={true}
        style={styles.item}
        rightIconButton={
          <IconButton
            onClick={() =>
              this.props.deletePermissionGroupPermission(
                group.id,
                permission.id,
              )
            }
          >
            <ActionDelete color={colors.grey400} />
          </IconButton>
        }
      />
    ));
  }
}

ManagePermissionGroupDialog.propTypes = {
  manageDialogShown: PropTypes.bool.isRequired,
  onHideManageDialog: PropTypes.func.isRequired,
  availablePermissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  usedPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  addPermissionGroupPermission: PropTypes.func.isRequired,
  deletePermissionGroupPermission: PropTypes.func.isRequired,
};

export default ManagePermissionGroupDialog;
