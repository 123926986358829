import React from "react";
import _ from "underscore";
import {get} from "lodash";
import {Link} from "react-router";

import AutoComplete from "material-ui/AutoComplete";
import MenuItem from "material-ui/MenuItem";
import * as colors from "material-ui/styles/colors";
import IconButton from "material-ui/IconButton";
import IconMenu from "material-ui/IconMenu";
import CheckBox from "material-ui/svg-icons/toggle/check-box";
import CheckBoxBlank from "material-ui/svg-icons/toggle/check-box-outline-blank";
import SettingsIcon from "material-ui/svg-icons/action/settings";
import LinkIcon from "material-ui/svg-icons/content/link";
import ReplayIcon from "@material-ui/icons/Replay";

import AutocompleteClearable from "common_components/autocomplete_clearable";
import SquareIconButton from "common_components/buttons/square_icon_button.js";

import constants from "./constants";
import issuesetUtils from "common/utils/issues/issueset_utils";
import UNINITIALISED from "utils/uninitialised";

const styles = {
  rootDiv: {
    borderBottom: "1px solid lightgray",
    padding: "0.5rem",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 0.5rem",
  },
  title: {
    color: colors.grey800,
    fontWeight: "500",
    fontSize: "18px",
  },
  documentName: {
    fontSize: "12px",
    color: colors.grey500,
    wordBreak: "break-all",
    marginLeft: "0.5rem",
    width: "85%",
    position: "relative",
    top: "-0.5rem",
    overflow: "hidden",
  },
  contractTypeName: {
    marginLeft: 16,
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.3)",
    lineHeight: "12px",
    position: "relative",
    top: "-6px",
  },
  dropDown: {
    width: "100%",
  },
  dropDownMenu: {
    maxWidth: "24rem",
  },
  reactSelectLabel: {
    position: "absolute",
    top: "-5px",
    left: "22px",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  documentLinkIdentifier: {
    color: colors.blue500,
    fontSize: "14px",
    fontWeight: "600",
    position: "relative",
  },
  jobStatus: {
    fontSize: ".7em",
    display: "flex",
  },
  reloadIcon: {
    marginLeft: ".5em",
    color: "rgb(117, 117, 117)",
    cursor: "pointer",
  },
};
class DocumentSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.reloadTimeoutId = null;
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.projects && this.props.projects) ||
      prevProps.selectedProject !== this.props.selectedProject ||
      !_.isEqual(prevProps.documents, this.props.documents) ||
      prevProps.selectedDocument !== this.props.selectedDocument
    ) {
      this.setState(() => this.getInitialState());
    }
    const docJobsAmountPath = "findIssueJobsAmount.documentJobsAmount";
    const documentJobsAmountPrev = get(prevProps, docJobsAmountPath, 0);
    const documentJobsAmount = get(this.props, docJobsAmountPath, 0);
    if (documentJobsAmountPrev > 0 && documentJobsAmount === 0) {
      this.onReloadDocument();
    }
  }

  componentWillUnmount() {
    if (this.reloadTimeoutId) {
      clearTimeout(this.reloadTimeoutId);
    }
  }

  getInitialState = () => {
    const currentProject = this.props.selectedProject
      ? (this.props.projects || []).find(
          project => project.id === this.props.selectedProject,
        )
      : null;
    const currentDocument = this.props.selectedDocument
      ? (this.props.documents || []).find(
          doc => doc.id === this.props.selectedDocument,
        )
      : null;
    return {
      projectInputText: currentProject ? currentProject.name : "",
      documentInputText: currentDocument ? currentDocument.name : "",
      prevProjectInputText: "",
      prevDocumentInputText: "",
    };
  };

  onInputTextChange = _.memoize(
    itemName => text => this.setState(() => ({[itemName]: text})),
    (...args) => JSON.stringify([...args]),
  );

  onInputTextClear = _.memoize(
    itemName => () =>
      this.setState(
        () => {
          const newStateValues = {
            [itemName]: "",
          };
          if (itemName === "projectInputText") {
            newStateValues["documentInputText"] = "";
          }
          return newStateValues;
        },
        () => {
          if (itemName === "projectInputText") {
            this.props.clearProjectAndDocument();
          } else {
            this.props.clearDocument();
          }
        },
      ),
    (...args) => JSON.stringify([...args]),
  );

  render() {
    const {open} = this.props;
    return (
      <div
        style={{
          ...styles.rootDiv,
          height: `${
            open
              ? constants.documentSelectorHeightOpen
              : constants.documentSelectorHeightClosed
          }rem`,
        }}
      >
        <div style={styles.header}>
          <div style={styles.title}>
            {`Select${
              !open && this.props.selectedDocument ? "ed" : ""
            } Document`}
          </div>
          <SquareIconButton onButtonClick={this.props.onMenuOpen} open={open} />
        </div>
        {this.renderDocumentName()}
        {this.renderMenu()}
      </div>
    );
  }

  renderDocumentName = () => {
    if (this.props.open || !this.props.selectedDocument) {
      return null;
    }
    const foundDocument = (this.props.documents || []).find(
      doc => doc.id === this.props.selectedDocument,
    );
    const documentName = foundDocument && foundDocument.name;
    if (documentName) {
      return (
        <div style={styles.documentName}>
          <div style={{width: "90rem"}}>
            <a href={this.getDocumentLink(foundDocument)} target="_blank">
              {documentName}
            </a>
          </div>
        </div>
      );
    }
  };
  getDocumentLink(foundDocument, isDocumentDetailLink) {
    return [
      `/organisation/${this.props.organisationId}`,
      `/project/${this.props.selectedProject}`,
      `/document/${foundDocument.id}${isDocumentDetailLink ? "/detail" : ""}`,
    ].join("");
  }

  documentMatchesIssueset(selectedIssueset, document, project) {
    if (!document || !project) {
      return null;
    }
    if (document.issuesets && document.issuesets.length) {
      return document.issuesets.find(issueset => issueset === selectedIssueset);
    }
    if (project.issuesets && project.issuesets.length) {
      return project.issuesets.find(issueset => issueset === selectedIssueset);
    }
    return true;
  }
  renderMenu = () => {
    if (!this.props.open) {
      return null;
    }
    const foundDocument = (this.props.documents || []).find(
      doc => doc.id === this.props.selectedDocument,
    );
    const projects = this.getProjects();
    const documents = this.getDocuments();
    return (
      <div>
        <AutocompleteClearable
          name="project"
          filter={AutoComplete.caseInsensitiveFilter}
          floatingLabelText="Project"
          onNewRequest={this.props.onProjectChange}
          dataSource={this.getProjects()}
          dataSourceConfig={{text: "name", value: "id"}}
          openOnFocus={true}
          searchText={this.state.projectInputText}
          onUpdateInput={this.onInputTextChange("projectInputText")}
          onInputTextClear={this.onInputTextClear("projectInputText")}
          style={{marginLeft: "1rem", marginRight: "0.5rem"}}
          listStyle={{maxHeight: "20rem", overflowY: "auto", width: "22rem"}}
          onFocus={this.onProjectInputFocus}
          onBlur={this.onProjectBlur}
        />
        <AutocompleteClearable
          name="document"
          filter={AutoComplete.caseInsensitiveFilter}
          floatingLabelText={`Document (${
            documents === UNINITIALISED ? "Loading...." : documents.length
          })`}
          onNewRequest={this.props.onDocumentChange}
          dataSource={
            documents === UNINITIALISED
              ? []
              : documents.map(document => {
                  const docMatchesIssueset = this.documentMatchesIssueset(
                    this.props.currentIssueset,
                    documents.find(doc => doc.id === document.id),
                    projects.find(
                      proj => proj.id === this.props.selectedProject,
                    ),
                  );
                  return {
                    id: document.id,
                    text: document.name,
                    value: (
                      <MenuItem
                        innerDivStyle={{
                          color: docMatchesIssueset ? "#000" : "#666",
                        }}
                        primaryText={document.name}
                        secondaryText={
                          docMatchesIssueset ? null : (
                            <span
                              style={{
                                color: "#f00",
                                fontSize: 9,
                                fontWeight: "bold",
                                zIndex: 10,
                                position: "relative",
                                backgroundColor: "#fffc",
                                padding: "0 1em",
                              }}
                            >
                              Lacks issueset
                            </span>
                          )
                        }
                      />
                    ),
                  };
                })
          }
          dataSourceConfig={{text: "text", value: "value"}}
          openOnFocus={true}
          searchText={this.state.documentInputText}
          onUpdateInput={this.onInputTextChange("documentInputText")}
          onInputTextClear={this.onInputTextClear("documentInputText")}
          style={{marginLeft: "1rem", marginRight: "0.5rem"}}
          listStyle={{maxHeight: "20rem", overflowY: "auto", width: "22rem"}}
          onFocus={this.onDocumentInputFocus}
          onBlur={this.onDocumentBlur}
        />
        {foundDocument && foundDocument.contract_type ? (
          <div style={styles.contractTypeName}>
            {`Contract type: ${foundDocument.contract_type.name} (id: ${foundDocument.contract_type.id})`}
          </div>
        ) : null}
        <div
          style={{
            ...styles.buttonsContainer,
            top: foundDocument ? -12 : 0,
          }}
        >
          {this.renderDocumentLinks(foundDocument)}
          {this.renderIconMenu(foundDocument)}
          <div style={styles.jobStatus}>
            <div style={{marginRight: "3px", alignSelf: "center"}}>Jobs</div>
            <div>
              <table>
                <thead>
                  <tr>
                    <td />
                    <td>Issue</td>
                    <td>Param</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{textAlign: "right"}} title="Outstanding jobs">
                      Total
                    </td>
                    <td>{this.props.findIssueJobsAmount.jobsAmount}</td>
                    <td>
                      {this.props.classifyTopicparameterJobsAmount.jobsAmount}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{textAlign: "right"}}
                      title="Outstanding jobs in relation to this issue"
                    >
                      Issue
                    </td>
                    <td>{this.props.findIssueJobsAmount.issueJobsAmount}</td>
                    <td>N / A</td>
                  </tr>
                  <tr>
                    <td
                      style={{textAlign: "right"}}
                      title="Outstanding jobs in relation to this document"
                    >
                      Document
                    </td>
                    <td>{this.props.findIssueJobsAmount.documentJobsAmount}</td>
                    <td>
                      {
                        this.props.classifyTopicparameterJobsAmount
                          .documentJobsAmount
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <ReplayIcon
            style={styles.reloadIcon}
            onClick={this.props.updateFindIssueJobsAmount}
          />
        </div>
      </div>
    );
  };

  renderDocumentLinks = foundDocument => {
    if (!foundDocument) {
      return null;
    }
    const linkDataItems = [
      {
        path: this.getDocumentLink(foundDocument),
        identifier: "A",
        identifierStyle: {
          left: "-3px",
        },
      },
      {
        path: this.getDocumentLink(foundDocument, true),
        identifier: "L",
        identifierStyle: {
          left: "-1px",
        },
      },
    ];
    return linkDataItems.map((dataItem, index) => (
      <a
        key={`${dataItem.identifier}-${index}`}
        style={{textDecoration: "none", marginRight: "4px"}}
        href={dataItem.path}
        target="_blank"
      >
        <LinkIcon
          style={{position: "relative", top: "2px"}}
          color={colors.grey600}
        />
        <span
          style={{
            ...styles.documentLinkIdentifier,
            ...dataItem.identifierStyle,
          }}
        >
          {dataItem.identifier}
        </span>
      </a>
    ));
  };

  renderIconMenu = foundDocument => {
    if (!foundDocument) {
      return null;
    }
    const lastEdited =
      foundDocument && new Date(foundDocument.issues_last_edited);
    return (
      <IconMenu
        iconButtonElement={
          <IconButton iconStyle={{color: colors.grey600}}>
            <SettingsIcon />
          </IconButton>
        }
        anchorOrigin={{horizontal: "left", vertical: "top"}}
        targetOrigin={{horizontal: "left", vertical: "top"}}
      >
        <MenuItem
          key="collapse-issues"
          onClick={this.props.collapseIssues}
          primaryText="Collapse Issues"
          leftIcon={
            this.props.areIssuesCollapsed ? <CheckBox /> : <CheckBoxBlank />
          }
        />
        <MenuItem
          key="show-issue-correctness-panel"
          onClick={this.props.showIssueCorrectnessPanel}
          primaryText="Show Issue Correctness Panel"
          leftIcon={
            this.props.isIssueCorrectnessPanelShown ? (
              <CheckBox />
            ) : (
              <CheckBoxBlank />
            )
          }
        />
        <MenuItem
          key="update-document-issues"
          onClick={this.onUpdateDocumentIssues}
          primaryText={
            <div style={{marginLeft: "56px", lineHeight: 1.3}}>
              Reprocess Document Issues{" "}
              {foundDocument ? (
                <div style={{fontSize: "0.7em", fontStyle: "italic"}}>
                  Last updated: {lastEdited.toLocaleDateString()}{" "}
                  {lastEdited.toLocaleTimeString()}
                </div>
              ) : null}
            </div>
          }
        />
        <MenuItem
          key="update-document-issue"
          onClick={this.onReprocessDocumentIssue}
          primaryText={
            <div style={{marginLeft: "56px", lineHeight: 3}}>
              Reprocess Current Issue
            </div>
          }
        />
        <MenuItem
          key="show-display-name"
          onClick={this.props.switchShowDisplayName}
          primaryText="Show Display Name"
          leftIcon={
            this.props.showDisplayName ? <CheckBox /> : <CheckBoxBlank />
          }
        />
        <MenuItem
          key="show-archived-issues"
          onClick={this.props.switchShowArchivedIssues}
          primaryText="Show Archived Issues"
          leftIcon={
            this.props.showArchivedIssues ? <CheckBox /> : <CheckBoxBlank />
          }
        />
        <MenuItem
          key="reload-document"
          onClick={this.onReloadDocument}
          primaryText="Reload Document"
          leftIcon={<ReplayIcon style={{color: "#757575"}} />}
        />
        <MenuItem
          key="llm-runs"
          primaryText={
            <Link
              style={{color: "black", textDecoration: "none"}}
              to={`/organisation/${this.props.organisationId}/llm_run/list?issue=${this.props.issue.id}&project=${this.props.selectedProject}&document=${foundDocument.id}`}
            >
              View LLM Runs
            </Link>
          }
          leftIcon={<LinkIcon style={{color: "#757575"}} />}
        />
      </IconMenu>
    );
  };

  onUpdateDocumentIssues = () => {
    const {onReloadDocument} = this;
    this.props.updateDocumentIssues();
    this.reloadTimeoutId = setTimeout(onReloadDocument, 5000);
  };

  onReprocessDocumentIssue = () => {
    const {onReloadDocument} = this;
    this.props.reprocessDocumentIssue();
    this.reloadTimeoutId = setTimeout(onReloadDocument, 5000);
  };

  onReloadDocument = () => {
    const {selectedProject, selectedDocument} = this.props;
    this.props.onFetchDocument(selectedProject, selectedDocument);
  };

  getProjects = () => {
    const {contract_types: issueContractTypes} = this.props.issue;
    const issueIssuesetIds = issuesetUtils.getIssuesetIdsPresentInContractTypes(
      issueContractTypes,
    );
    const contractTypesIds = issueContractTypes.map(ct => ct.contract_type_id);
    const projects = _.chain(this.props.projects)
      .filter(project => {
        return (
          ((project.issuesets.length === 0 &&
            _.intersection(contractTypesIds, [
              project.default_contract_type.id,
            ]) > 0) ||
            _.intersection(project.issuesets, issueIssuesetIds).length > 0) &&
          project.documents_count > 0
        );
      })
      .sortBy(project => project.name)
      .value();
    return projects;
  };

  getDocuments = () => {
    const documents = this.props.documents;
    if (documents === UNINITIALISED || documents === null) {
      return UNINITIALISED;
    }
    const foundProject = (this.props.projects || []).find(
      project => project.id === this.props.selectedProject,
    );
    const selectedProjectCtId =
      foundProject &&
      foundProject.default_contract_type &&
      foundProject.default_contract_type.id;
    return _.chain(documents || [])
      .filter(doc => doc.contract_type_id === selectedProjectCtId)
      .sortBy(doc => -doc.creation_date)
      .value();
  };

  onProjectInputFocus = () =>
    this.setState(prevState => ({
      projectInputText: "",
      prevProjectInputText: prevState.projectInputText,
    }));
  onDocumentInputFocus = () =>
    this.setState(prevState => ({
      documentInputText: "",
      prevDocumentInputText: prevState.documentInputText,
    }));

  onProjectBlur = e => {
    const newValue = e.target.value;
    if (!newValue.trim() && this.state.prevProjectInputText) {
      return this.setState(prevState => ({
        projectInputText: prevState.prevProjectInputText,
        prevProjectInputText: "",
      }));
    }
  };
  onDocumentBlur = e => {
    const {value: newValue} = e.target;
    if (!newValue.trim() && this.state.prevDocumentInputText) {
      return this.setState(prevState => ({
        documentInputText: prevState.prevDocumentInputText,
        prevDocumentInputText: "",
      }));
    }
  };
}

export default DocumentSelector;
