import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function templateModuleCreate(requestor) {
  return createReduxPromiseAction(ActionTypes.TEMPLATE_MODULE_CREATE, data =>
    requestor
      .post(
        `${basePath}/organisation/${data.organisationId}/template_module`,
        data,
        {
          withCredentials: true,
        },
      )
      .then(response => {
        return response.data;
      }),
  );
}
