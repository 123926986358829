import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router";
import moment from "moment";

import IconButton from "material-ui/IconButton";
import ActionOpenInNew from "material-ui/svg-icons/action/open-in-new";
import Checkbox from "common_components/checkbox";

import * as colors from "material-ui/styles/colors";

const styles = {
  row: {
    borderBottom: `1px solid ${colors.grey300}`,
  },
  cell: {
    verticalAlign: "middle",
    textAlign: "center",
  },
  iconButton: {
    height: 40,
    width: 40,
    padding: 8,
  },
};

class ValueFinder {
  constructor(initialValue, checker) {
    this.value = null;
    this.checker = checker;
  }

  check(newValue) {
    if (!this.value && newValue) {
      this.value = new Date(newValue);
    }
    if (typeof this.checker === "function" && this.value && newValue) {
      this.value = this.checker(
        new Date(this.value).getTime(),
        new Date(newValue).getTime(),
      );
    } else {
      this.value = newValue;
    }
  }
  get() {
    return this.value;
  }
}

class ClientOrganisationsItem extends React.Component {
  state = {isItemHovered: false};

  render() {
    const {
      client,
      isLastItem,
      onShowDialog,
      showArchived,
      onCheckIsArchived,
    } = this.props;
    const {minSyncDate, maxSyncDate} = this.getSyncDates(client);
    const {is_archived: isArchived} = client;

    return (
      <tr
        style={{
          ...styles.row,
          ...(isLastItem && {border: "none"}),
          ...(isArchived ? {color: colors.grey400} : {}),
        }}
        onMouseEnter={this.onItemEnter}
        onMouseLeave={this.onItemLeave}
      >
        <td style={styles.cell}>{client.id}</td>
        <td style={{...styles.cell, textAlign: "left"}}>
          <span
            style={{
              color: isArchived
                ? colors.grey400
                : this.state.isItemHovered ? colors.blue500 : "#000",
              textDecoration: this.state.isItemHovered ? "underline" : "none",
              cursor: "pointer",
            }}
            onClick={() => onShowDialog(client.id)}
          >
            {client.name}
          </span>
        </td>
        <td style={styles.cell}>{this.formatDate(client.creation_date)}</td>
        <td style={styles.cell}>{this.formatDate(minSyncDate)}</td>
        <td style={styles.cell}>{this.formatDate(maxSyncDate)}</td>
        {showArchived && (
          <td style={styles.cell}>
            <Checkbox
              checked={client.is_archived}
              onCheck={onCheckIsArchived}
            />
          </td>
        )}
        <td>
          <Link
            to={client.client_db_id ?
              `https://app.lexicallabs.com/organisation/${client.client_db_id}` :
              `/organisation/${client.id}`
            }
            target="_blank"
            style={{
              color: this.state.isItemHovered ? colors.blue500 : "#000",
              textDecoration: this.state.isItemHovered ? "underline" : "none",
              cursor: "pointer",
            }}
          >
            <IconButton style={styles.iconButton}>
              <ActionOpenInNew
                color={
                  this.state.isItemHovered ? colors.blue500 : colors.grey400
                }
              />
            </IconButton>
          </Link>
        </td>
      </tr>
    );
  }

  onItemEnter = () => this.setState(() => ({isItemHovered: true}));
  onItemLeave = () => this.setState(() => ({isItemHovered: false}));

  formatDate(date) {
    return date ? moment(date).format("D MMM YYYY, HH:mm:ss") : "";
  }

  getSyncDates(client) {
    const minSyncFinder = new ValueFinder("", Math.min);
    const maxSyncFinder = new ValueFinder("", Math.max);
    if (client) {
      const {contract_types: contractTypes} = client;
      if (contractTypes && contractTypes.length > 0) {
        for (const contractType of contractTypes) {
          if (contractType) {
            const {issuesets} = contractType;
            if (issuesets && issuesets.length > 0) {
              for (const issueset of issuesets) {
                if (issueset) {
                  const {last_sync_dates: lastSyncDates} = issueset;
                  if (lastSyncDates) {
                    const {topics, whole} = lastSyncDates;
                    if (topics) {
                      minSyncFinder.check(topics);
                      maxSyncFinder.check(topics);
                    }
                    if (whole) {
                      minSyncFinder.check(whole);
                      maxSyncFinder.check(whole);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return {minSyncDate: minSyncFinder.get(), maxSyncDate: maxSyncFinder.get()};
  }
}

ClientOrganisationsItem.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    creation_date: PropTypes.string.isRequired,
  }).isRequired,
  isLastItem: PropTypes.bool.isRequired,
  onShowDialog: PropTypes.func.isRequired,
};

export default ClientOrganisationsItem;
