import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../constants/action_types";
import convertContentsToText from "common/utils/contents/convert_contents_to_text";

/* eslint-disable max-params */
export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_CLAUSEPART_ADD,
  (
    organisationId,
    projectId,
    documentId,
    sectionId,
    clauseId,
    path,
    contents,
    lastEdited,
  ) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/section/${sectionId}/clause/${clauseId}/` +
          "clausepart/add",
        {
          last_edited: lastEdited,
          path,
          text: convertContentsToText(contents),
          contents,
        },
        {withCredentials: true},
      )
      .then(response => response.data),
);
