import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import convertContentsToText from "common/utils/contents/convert_contents_to_text";
import ActionTypes from "../../constants/action_types";

/* eslint-disable max-params */
export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_DEFINITION_ADD,
  (
    organisationId,
    projectId,
    documentId,
    sectionId,
    clauseId,
    path,
    term,
    meaning,
    lastEdited,
  ) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/section/${sectionId}/clause/${clauseId}/definition`,
        {
          path,
          term,
          meaning_contents: meaning,
          meaning_text: convertContentsToText(meaning),
          last_edited: lastEdited,
        },
        {withCredentials: true},
      )
      .then(response => response.data),
);
