import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function addTopicToClause(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_CLAUSE_TOPIC_ADD,
    (
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clausepartLastEdited,
      data,
    ) =>
      requestor
        .post(
          [
            `${basePath}/organisation/${organisationId}/project/${projectId}`,
            `/document/${documentId}/clause/${clauseId}/`,
            `clausepart/${clausepartId}/topic`,
          ].join(""),
          {
            ...data,
            clausepart_last_edited: clausepartLastEdited,
          },
          {
            withCredentials: true,
            ...(data.id ? {params: {id: data.id}} : {}),
          },
        )
        .then(response => ({
          project_id: projectId,
          document_id: documentId,
          clause_id: clauseId,
          clausepart_id: clausepartId,
          section_id: sectionId,
          ...(data.name && !data.id ? {name: data.name} : {}),
          ...(data.topiccategory_id
            ? {topiccategory_id: data.topiccategory_id}
            : {}),
          ...(data.topiccategory_name && !data.topiccategory_id
            ? {topiccategory_name: data.topiccategory_name}
            : {}),
          ...(data.tags ? {tags: data.tags} : {}),
          ...response.data,
          isTopicNew: !data.id,
        })),
  );
}
