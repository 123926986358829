import React, {useState, useEffect} from "react";
import _ from "lodash";

import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import {AliasesList} from "routes/role/role_detail/components/aliases";
import IconButton from "material-ui/IconButton";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import ClearIcon from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import ListItemHoverable from "common_components/project/project_settings/list_item_hoverable";
import {SketchPicker} from "react-color";
import getColorByBgColor from "../../../../../utils/get_color_by_bg_color";

const styles = {
  aliasesBlock: {
    marginLeft: 18,
    marginTop: 10,
    fontSize: "1.1em",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    gap: 15,
  },
  colorFill: {
    color: "#0000008a",
  },
  colorPicker: {
    display: "block",
    position: "absolute",
    zIndex: 1,
  },
  wrapper: {
    padding: "2rem",
    display: "flex",
  },
  rolesBlock: {
    borderRight: "1px solid #9e9e9e",
    width: 250,
  },
  listItem: {
    wordBreak: "break-all",
    marginLeft: 12,
  },
};

function getInitialBackgroundColor(projectRoles, selectedId) {
  return (
    projectRoles.find(projectRole => projectRole.id === selectedId)?.color ??
    null
  );
}

function RolesTab({projectRoles, onProjectKeyChange, roles}) {
  const [selectedRoleId, onSelectedRoleIdUpdate] = useState(
    roles?.[0]?.id ?? null,
  );
  const [aliases, setAliases] = useState([]);
  const [isShowColorPicker, setIsShowColorPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(
    getInitialBackgroundColor(roles, projectRoles, selectedRoleId),
  );
  const [textColor, setTextColor] = useState({color: "#000000"});

  const defaultBackgroundColor =
    roles.find(role => role.id === selectedRoleId)?.color ?? "#ffffff";

  useEffect(() => {
    if (selectedRoleId !== null) {
      const role =
        (projectRoles || []).find(role => role.id === selectedRoleId) || [];
      if (role?.aliases) {
        setAliases(role.aliases);
      } else {
        setAliases([]);
      }

      if (role?.color || role.color === null) {
        setBackgroundColor(role.color);
        const textColor = getColorByBgColor(
          role.color ?? defaultBackgroundColor,
        );
        setTextColor({color: textColor});
      } else {
        setBackgroundColor(null);
        setTextColor({color: "#000000"});
      }
    }
  }, [selectedRoleId]);

  useEffect(() => {
    onAliasesUpdate(aliases);
  }, [backgroundColor]);

  const handleColorChange = color => {
    const textColor = getColorByBgColor(color.hex);
    setBackgroundColor(color.hex);
    setTextColor({color: textColor});
  };
  const openColorPicker = () => setIsShowColorPicker(true);
  const clearColor = () => {
    const newDefaultColor = defaultBackgroundColor;
    const textColor = getColorByBgColor(newDefaultColor.hex);
    setBackgroundColor(null);
    setTextColor({color: textColor});
  };
  const onRoleSelect = roleId => () => {
    onSelectedRoleIdUpdate(roleId);
    setBackgroundColor(getInitialBackgroundColor(roles, projectRoles, roleId));
  };
  const onAliasesUpdate = aliases => {
    const newRoles = _.cloneDeep(projectRoles || []);
    const aliasesIndex = (projectRoles || []).findIndex(
      role => role.id === selectedRoleId,
    );
    const newRole = {id: selectedRoleId, aliases, color: backgroundColor};
    if (aliasesIndex !== -1) {
      newRoles[aliasesIndex] = newRole;
    } else {
      newRoles.push(newRole);
    }
    onProjectKeyChange("roles", newRoles);
  };

  const setRoleColor = () => {
    setIsShowColorPicker(false);
    onAliasesUpdate(aliases);
  };

  const renderRolesList = () => {
    return (
      <div style={styles.rolesBlock}>
        <List dense={true}>
          {roles.map((role, roleIndex) => (
            <ListItemHoverable
              key={roleIndex}
              isFirstInList={roleIndex === 0}
              isLastInList={roleIndex === roles.length - 1}
              onClick={onRoleSelect(role.id)}
              selected={role.id === selectedRoleId}
            >
              <ListItemText primary={role.name} style={styles.listItem} />
            </ListItemHoverable>
          ))}
        </List>
      </div>
    );
  };

  const renderAliases = () => {
    return (
      <div>
        <div style={styles.aliasesBlock}>
          <span
            style={{
              backgroundColor: backgroundColor ?? defaultBackgroundColor,
              ...textColor,
            }}
          >
            Set color for the role
          </span>
          <div style={{position: "relative"}}>
            <Tooltip title="Set color for the role" placement={"top"} arrow>
              <IconButton
                color="primary"
                style={styles.colorFill}
                onClick={openColorPicker}
              >
                <FormatColorFillIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Clear icon color" placement={"top"} arrow>
              <IconButton
                color="primary"
                style={styles.colorFill}
                onClick={clearColor}
                disabled={backgroundColor === null}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
            {isShowColorPicker ? (
              <ClickAwayListener onClickAway={setRoleColor}>
                <div style={styles.colorPicker}>
                  <SketchPicker
                    onChange={handleColorChange}
                    color={
                      backgroundColor ?? defaultBackgroundColor ?? "#ffffff"
                    }
                  />
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
        </div>
        <div style={styles.aliasesBlock}>Aliases</div>
        <AliasesList aliases={aliases} onAliasesUpdate={onAliasesUpdate} />
      </div>
    );
  };

  return (
    <div style={styles.wrapper}>
      {renderRolesList()}
      {renderAliases()}
    </div>
  );
}

export default RolesTab;
