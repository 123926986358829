import React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

class SplitDialogue extends React.Component {
  render() {
    const {startText, endText} = this.props;
    const {onDismiss} = this.props;
    const actions = [
      <FlatButton
        label="Cancel"
        key="split-dialog-cancel-button"
        className="cancel"
        secondary={true}
        onClick={onDismiss}
      />,
      <FlatButton
        label="Split Clause"
        key="split-dialog-split-clause-button"
        primary={true}
        onClick={this.onSplitClause}
        className="save-button"
      />,
      <FlatButton
        label="Remove Splits"
        key="split-dialog-remove-splits-button"
        primary={true}
        onClick={this.onRemoveSplits}
        className="remove-splits-button"
      />,
    ];

    return (
      <Dialog
        title="Split Clause"
        className="dialog"
        open={true}
        actions={actions}
        onDismiss={onDismiss}
      >
        <span style={{color: "red"}}>{startText}</span>
        <span style={{color: "green"}}>{endText}</span>
      </Dialog>
    );
  }

  /* eslint-disable no-invalid-this */
  onSplitClause = () => {
    const {startText, endText} = this.props;
    this.props.onSplitClause(startText, endText);
  };
  onRemoveSplits = () => {
    this.props.onRemoveSplits();
  };
  /* eslint-enable no-invalid-this */
}

export default SplitDialogue;
