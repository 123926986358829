import _ from "underscore";
import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import CircularProgress from "material-ui/CircularProgress";

import Permissioner from "utils/permissioner";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import byId from "utils/by_id_memo";
import requestor from "requestor";

import DocumentReport from "../components/document_report";

import generateTopicMasks from "utils/topic/generate_topic_masks";
import getRouteQuery from "utils/get_route_query";
import sortIssues from "utils/issues/sort_issues";
import getClauseAtoms from "common/utils/clauses/get_clause_atoms";
import groupDocuments from "common/utils/document/group";
import clauseChangeConstructor from "../../document_detail/utils/clause_change_constructor";

import documentUpdate from "modules/documents/actions/document_update";
import updateDocumentIssue from "modules/documents/actions/document_issue_update";
import correctDocumentIssueManually from "modules/documents/actions/document_issue_correct_manually";
import updateDocumentIssues from "modules/documents/actions/document_issues_update";
import logPageLoadTime from "modules/logs/actions/page_load_time_log";
import {getStoredChecklistState} from "utils/issuesets/checklist_state";

import getDefaultIssuesetId from "../../document_detail/utils/issuesets/get_default_issueset_id";
import getApplicableIssuesetIds from "utils/issuesets/get_applicable_issueset_ids";
import getIssuesetId from "../../document_detail/utils/get_issueset_id";

function getDocumentClauseAtoms(documentLastEdited, documentClauses) {
  return getClauseAtoms(documentClauses);
}

const getClauseAtomsMemo = _.memoize(
  getDocumentClauseAtoms,
  documentLastEdited => documentLastEdited,
);

class DocumentReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.customEventListeners = {};
    this.startTime = new Date().valueOf();
    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    const shouldRender = DocumentReportContainer.shouldRenderContainer(props);
    if (shouldRender && !state.firstRender) {
      return DocumentReportContainer.initialiseState(props);
    }
    return null;
  }

  static initialiseState(props) {
    const storedViewMode = getStoredChecklistState(
      "documentDetailViewMode",
      props.document.id,
    );
    const viewMode = storedViewMode || "checklist";

    const applicableIssuesetIds = getApplicableIssuesetIds(
      props.document,
      props.project,
      props.contractTypesById,
    );

    const defaultIssuesetId = getDefaultIssuesetId(
      props.document,
      props.project,
      props.contractTypesById,
      applicableIssuesetIds,
    );

    const storedIssueset = getStoredChecklistState(
      "documentDetailIssueset",
      props.document.id,
    );

    return {
      firstRender: true,
      viewMode,
      selectedChecklistSectionId: null,
      currentIssueset: getIssuesetId(
        viewMode,
        props.project.document_type.id,
        defaultIssuesetId,
        storedIssueset,
      ),
    };
  }

  render() {
    if (!DocumentReportContainer.shouldRenderContainer(this.props)) {
      return (
        <CircularProgress
          style={{
            position: "absolute",
            top: "45%",
            left: "50%",
          }}
        />
      );
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    const result = this.renderComponent();
    if (!this.endTime) {
      this.endTime = new Date().valueOf();
      const {params} = this.props;
      this.props.logPageLoadTime(
        params.organisationId,
        "document_report",
        this.startTime,
        this.endTime,
        {
          document_id: params.documentId,
          project_id: params.projectId,
          url: this.props?.location?.pathname,
        },
      );
    }
    return result;
  }

  hasEnterPermission() {
    return (
      this.permissioner.hasPermission("get-document-detail") &&
      this.permissioner.canViewProject(this.props.project)
    );
  }

  static shouldRenderContainer(props) {
    return (
      isInitialised([
        props.document,
        props.documents,
        props.documentClauses,
        props.documentChanges,
        props.documentDefinitions,
        props.documentHeadings,
        props.documentSections,
        props.documentIssues,
        props.topicMasks,
        props.topics,
        props.topicsById,
        props.topicCategories,
        props.topicTags,
        props.user,
        props.contractTypesById,
        props.definitionGroups,
        props.roles,
      ]) &&
      DocumentReportContainer.idsMatch(
        props.document.id,
        [props.documentIssues],
        "document_id",
      )
    );
  }

  renderComponent() {
    return this.renderDocumentDetailContainer();
  }

  renderDocumentDetailContainer() {
    setTitle(`${this.props.document.name}`);
    const categoriesById = byId("topic_categories", this.props.topicCategories);
    const {
      params: {organisationId},
      documentIssues: {issues, updateCount, manualUpdateCount},
      queryParams: {clause},
      issueComparisonData,
    } = this.props;
    const documentClauseparts = getClauseAtomsMemo(
      this.props.document.last_edited,
      this.props.documentClauses,
    );
    return (
      <DocumentReport
        {...this.props}
        organisationId={organisationId}
        topicCategoriesById={categoriesById}
        highlightedClause={clause}
        documentIssues={sortIssues(issues, this.props.documentClauses)}
        documentIssuesUpdateCount={updateCount}
        documentIssuesManualUpdateCount={manualUpdateCount}
        documentClauseparts={documentClauseparts}
        updateDocument={this.updateDocument}
        updateDocumentIssue={this.updateDocumentIssue}
        correctDocumentIssueManually={this.correctDocumentIssueManually}
        updateDocumentIssues={this.updateDocumentIssues}
        currentIssueset={this.state.currentIssueset}
        issueComparisonData={issueComparisonData}
      />
    );
  }

  static idsMatch(id, objects, key) {
    return objects.every(object => object[key] === id);
  }

  updateDocumentIssue = (issue, updates, options) => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    return this.props.updateDocumentIssue(
      organisationId,
      projectId,
      documentId,
      issue.document_issue_id,
      issue.last_edited,
      updates,
      options,
    );
  };

  correctDocumentIssueManually = (issue, updates, options) => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    return this.props.correctDocumentIssueManually(
      organisationId,
      projectId,
      documentId,
      issue.document_issue_id,
      issue.last_edited,
      updates,
      options,
    );
  };

  updateDocumentIssues = (issues, updates, options) => {
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    return this.props.updateDocumentIssues(
      organisationId,
      projectId,
      documentId,
      issues,
      updates,
      options,
    );
  };

  updateDocument = data => {
    const {organisationId, projectId, documentId} = this.props.params;
    this.props.documentUpdate(
      organisationId,
      projectId,
      documentId,
      {
        ...data,
        last_edited: this.props.document.last_edited,
      },
      false,
    );
  };

  deleteClause = _.memoize(
    (sectionId, clauseId) => () => {
      const {
        params: {organisationId, projectId, documentId},
        deleteClause,
      } = this.props;
      const clause = this.props.documentClauses[sectionId].find(
        c => c.id === clauseId,
      );
      const {last_edited: clauseLastEdited} = clause;
      const deletionChange = clauseChangeConstructor["clause_deletion"](
        documentId,
        clause,
      );
      deleteClause(
        organisationId,
        projectId,
        documentId,
        sectionId,
        clauseId,
        clauseLastEdited,
        deletionChange,
      );
    },
    (...args) => JSON.stringify(args),
  );

  revertClause = _.memoize(
    (sectionId, clauseId) => () => {
      const {
        params: {organisationId, projectId, documentId},
        revertClause,
      } = this.props;
      const clause = this.props.documentClauses[sectionId].find(
        c => c.id === clauseId,
      );
      const {last_edited: clauseLastEdited} = clause;
      revertClause(
        organisationId,
        projectId,
        documentId,
        sectionId,
        clauseId,
        clauseLastEdited,
      );
    },
    (...args) => JSON.stringify(args),
  );

  search = (queryValue, searchOptions = null) => {
    if (!queryValue.length) {
      this.props.searchClear();
    } else {
      const {
        params: {projectId},
      } = this.props;

      const filterList = [`project=${projectId}`];
      if (!searchOptions || !searchOptions.ignoreContractType) {
        filterList.push(
          `contract_type=${this.props.document.contract_type.id}`,
        );
      }
      if (searchOptions && _.isString(searchOptions.searchType)) {
        filterList.push(`search_type=${searchOptions.searchType}`);
      }
      if (searchOptions && searchOptions.issuesetPath) {
        filterList.push(`issueset_path=${searchOptions.issuesetPath}`);
      }
      if (searchOptions && searchOptions.exactMatch) {
        filterList.push("exact_match=true");
      }
      this.props.search(
        this.props.params.organisationId,
        "text",
        queryValue,
        filterList.length ? filterList.join("&") : null,
      );
    }
  };
}

function select(state, props) {
  const topicsById =
    state.topics && state.topics !== UNINITIALISED
      ? byId("topics", state.topics)
      : UNINITIALISED;
  let topicMasks = UNINITIALISED;
  let topicMasksById = UNINITIALISED;

  if (
    topicsById !== UNINITIALISED &&
    state.topicMasks &&
    state.topicMasks !== UNINITIALISED
  ) {
    const unpackedResults = generateTopicMasks(state.topicMasks, topicsById);
    topicMasks = unpackedResults.topicMasks;
    topicMasksById = unpackedResults.topicMasksById;
  }

  let contractTypesById;
  if (state.contract_types !== UNINITIALISED) {
    contractTypesById = byId("contract_types", state.contract_types);
  }
  let definitionsByReference = UNINITIALISED;
  if (state.documentDefinitions) {
    definitionsByReference = byId(
      "document_definitions",
      state.documentDefinitions,
      "reference",
    );
  }
  const documents =
    state.documents === UNINITIALISED ? null : getDocuments(state.documents);
  return {
    queryParams: getRouteQuery(state.router),
    params: _.mapObject(props.params, id => parseInt(id, 10)),
    document:
      state.documents && state.documents !== UNINITIALISED
        ? {
            ...byId("documents", documents)[props.params.documentId],
            ...state.document,
          }
        : {},
    documents,
    documentClauses: state.documentClauses.clauses,
    documentChanges: state.documentChanges,
    documentDefinitions: state.documentDefinitions,
    documentHeadings: state.documentHeadings,
    documentSections: state.documentSections,
    documentIssues: state.documentIssues,
    issueComparisonData:
      state.issueComparisonData === UNINITIALISED
        ? undefined
        : state.issueComparisonData,
    topicMasks,
    topicCategories: state.topicCategories,
    topicTags: state.topicTags,
    topics: state.topics,
    organisation: state.organisation,
    project: state.project,
    topicsById,
    topicMasksById,
    definitionsByReference,
    user: state.user,
    contractTypes: state.contract_types,
    contractTypesById,
    searchResults: state.search.results,
    documentStates:
      state.documentStates === UNINITIALISED ? [] : state.documentStates,
    documentLogs: state.logs === UNINITIALISED ? null : state.logs,
    searchByIssueResults:
      state.search_by_issue.results === UNINITIALISED
        ? null
        : state.search_by_issue.results,
    definitionGroups: state.definitionGroups,
    parties: state.parties,
    roles: state.roles,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateDocumentIssue: updateDocumentIssue(requestor),
      correctDocumentIssueManually: correctDocumentIssueManually(requestor),
      updateDocumentIssues: updateDocumentIssues(requestor),
      documentUpdate: documentUpdate(requestor),
      logPageLoadTime: logPageLoadTime(requestor),
    },
    dispatch,
  );
}

function getDocuments(docs) {
  const grouped = groupDocuments(docs);
  let all = [];
  grouped.forEach(doc => {
    all.push(doc);
    if (doc.isGroupParent) {
      all = all.concat(doc.revisions);
    }
  });
  return all;
}

export default connect(select, mapDispatchToProps)(DocumentReportContainer);
export const Component = DocumentReportContainer;
