import React from "react";

import moment from "moment";

import ArrowDropdown from "material-ui/svg-icons/navigation/arrow-drop-down";
import ArrowDropup from "material-ui/svg-icons/navigation/arrow-drop-up";
import AddIcon from "material-ui/svg-icons/content/add";
import RemoveIcon from "material-ui/svg-icons/content/remove";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";

import getRegexDiffs from "common/utils/diffs/get_regex_diffs";
import getDefaultDiffs from "common/utils/diffs/get_default_diffs";

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";

const styles = {
  main: {
    margin: "1rem",
    width: "70%",
    textAlign: "right",
  },
  noLogsPanel: {
    margin: "1rem",
    width: "70%",
    height: "198px",
    border: "1px solid #888888",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "32px",
    fontWeight: "400",
    color: "#BDBDBD",
  },
  regexKey: {
    minWidth: "6rem",
    fontWeight: "700",
  },
  regexText: {
    width: "95%",
    wordWrap: "break-word",
    whiteSpace: "normal",
  },
  dropdownArrow: {
    position: "relative",
    top: "2px",
  },
  showDiffsButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  expandButton: {
    position: "absolute",
    top: "-8px",
    left: 0,
  },
  addedColor: "#76ff76",
  removedColor: "#ff8484",
  baseColor: "inherit",
};
class TopicParameterLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      isShowDiffs: false,
      isBodyExpanded: false,
    };
  }

  render() {
    if (!this.props.logs || this.props.logs.length === 0) {
      return <div style={styles.noLogsPanel}>No history saved</div>;
    }
    const {isBodyExpanded} = this.state;
    return (
      <div style={styles.main}>
        <Table
          height={isBodyExpanded ? "unset" : "165px"}
          fixedHeader
          selectable={false}
          multiSelectable={false}
          wrapperStyle={{
            width: "100%",
            display: "inline-block",
            border: "1px solid #888888",
            overflow: "hidden",
          }}
          bodyStyle={{maxHeight: isBodyExpanded ? "800px" : "unset"}}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          >
            <TableRow style={{height: 30}}>
              <TableHeaderColumn
                colSpan="3"
                style={{
                  textAlign: "center",
                  color: "#000",
                  height: 30,
                  position: "relative",
                }}
              >
                <IconButton
                  style={styles.expandButton}
                  onClick={this.triggerExpandBody}
                >
                  {isBodyExpanded ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
                <div>Topic Parameter Changes</div>
                <FlatButton
                  primary={true}
                  label={this.state.isShowDiffs ? "Hide diffs" : "Show diffs"}
                  onClick={this.triggerDiffs}
                  style={styles.showDiffsButton}
                />
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {this.props.logs.reduce((acc, log, logIndex) => {
              const res = acc.concat(this.renderLogRow(log, logIndex === 0));
              if (this.isSelectedLog(log.id)) {
                return res.concat(this.renderValueRow(log));
              }
              return res;
            }, [])}
          </TableBody>
        </Table>
      </div>
    );
  }

  triggerDiffs = () =>
    this.setState(prevState => ({isShowDiffs: !prevState.isShowDiffs}));

  triggerExpandBody = () =>
    this.setState(prevState => ({isBodyExpanded: !prevState.isBodyExpanded}));

  renderLogRow = (log, isCurrent) => {
    return (
      <TableRow
        style={{height: 30, cursor: "pointer"}}
        onMouseUp={() => this.onRowClick(log.id)}
        key={log.id}
      >
        <TableRowColumn style={{height: 30}}>
          {moment(log.time).format("lll")}
          {isCurrent ? " (Current Classifier)" : null}
        </TableRowColumn>
        <TableRowColumn style={{height: 30}}>
          {log.parameter_type}
        </TableRowColumn>
        <TableRowColumn style={{height: 30, position: "relative"}}>
          <div style={{display: "flex", alignItems: "center"}}>
            <div>{log.username}</div>
            {this.renderRowIcon(log)}
          </div>
        </TableRowColumn>
      </TableRow>
    );
  };

  renderValueRow = log => {
    if (!this.isContainLogData(log)) {
      return null;
    }
    const {parameter_type, value} = log;
    return (
      <TableRow hoverable={false} key={`data-${log.id}`}>
        <TableRowColumn colSpan="3" style={{position: "relative"}}>
          {!this.state.isShowDiffs
            ? this.renderLogValue(parameter_type, value)
            : this.renderDiffValue(parameter_type, value)}
        </TableRowColumn>
      </TableRow>
    );
  };

  renderLogValue = (type, value, color = styles.baseColor) => {
    if (type === "regex") {
      return (
        <div>
          {value.map((item, index) => (
            <div
              key={`${item.value}-${index}`}
              style={{display: "flex", margin: "0.2rem 0"}}
            >
              <div style={styles.regexKey}>
                <span style={{backgroundColor: color}}>{item.value}</span>
              </div>
              {item.pattern.map((regex, patternIndex) => (
                <div key={`${regex}-${patternIndex}`} style={styles.regexText}>
                  <span style={{backgroundColor: color}}>{regex}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }
    return (
      <div>
        {Object.keys(value).map((item, index) => (
          <div
            key={`${value[item]}-${index}`}
            style={{display: "flex", margin: "0.2rem 0"}}
          >
            <div style={styles.regexKey}>
              <span style={{backgroundColor: color}}>{item}</span>
            </div>
            <div style={styles.regexText}>
              {Array.isArray(value[item]) ? (
                value[item].map(it => (
                  <div
                    key={`${item}-${it.pre}-${it.post}`}
                    style={{marginBottom: "4px", display: "flex"}}
                  >
                    <div style={{width: "50%"}}>
                      <u>pre:</u>
                      {it.pre}
                    </div>
                    <div>
                      <u>post:</u>
                      {it.post}
                    </div>
                  </div>
                ))
              ) : (
                <span style={{backgroundColor: color}}>
                  {value[item].toString()}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  renderDiffValue = (type, value) => {
    const {parameter: baseParameter} = this.props;
    if (type !== baseParameter.parameter_type) {
      return [
        this.renderLogValue(type, value, styles.removedColor),
        this.renderLogValue(
          baseParameter.parameter_type,
          baseParameter.value,
          styles.addedColor,
        ),
      ];
    }
    let renderData = [];
    if (baseParameter.parameter_type === "regex") {
      renderData = getRegexDiffs(baseParameter.value, value);
    } else {
      renderData = getDefaultDiffs(baseParameter.value, value);
    }

    return (
      <div>
        {renderData.map((item, index) => (
          <div
            key={`${item.label}-${index}`}
            style={{display: "flex", margin: "0.2rem 0"}}
          >
            <div style={styles.regexKey}>
              <span>{item.label}</span>
            </div>
            <div style={styles.regexText}>
              {item.value.map((part, itemIndex) => {
                if (part.pre || part.post) {
                  return (
                    <div
                      key={`${item.label}-${itemIndex}`}
                      style={{marginBottom: "4px", display: "flex"}}
                    >
                      <div style={{width: "50%"}}>
                        <u>pre:</u>
                        {this.renderPartItem(part.pre)}
                      </div>
                      <div>
                        <u>post:</u>
                        {this.renderPartItem(part.post)}
                      </div>
                    </div>
                  );
                }
                return this.renderDiffItem(part, itemIndex);
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  renderPartItem = partItem => {
    return partItem.map((diffItem, index) =>
      this.renderDiffItem(diffItem, index),
    );
  };

  renderDiffItem = (part, index) => {
    if (part.added) {
      return (
        <span
          key={`${part.value}-${index}`}
          style={{background: styles.addedColor}}
        >
          {part.value}
        </span>
      );
    } else if (part.removed) {
      return (
        <span
          key={`${part.value}-${index}`}
          style={{background: styles.removedColor}}
        >
          {part.value}
        </span>
      );
    }
    return <span key={`${part.value}-${index}`}>{part.value}</span>;
  };

  renderRowIcon = log => {
    if (!this.isContainLogData(log)) {
      return null;
    }

    return (
      <div style={styles.dropdownArrow}>
        {this.isSelectedLog(log.id) ? (
          <ArrowDropup color="rgb(128,128,128)" />
        ) : (
          <ArrowDropdown color="rgb(128,128,128)" />
        )}
      </div>
    );
  };

  isContainLogData = log => log.value;

  onRowClick = id => {
    if (this.isSelectedLog(id)) {
      this.deselectLog(id);
    } else {
      this.selectLog(id);
    }
  };

  isSelectedLog = id => {
    return this.state.selected.find(selectedId => selectedId === id);
  };

  selectLog = id => {
    this.setState({selected: [...this.state.selected, id]});
  };

  deselectLog = id => {
    this.setState({
      selected: this.state.selected.filter(prevId => prevId !== id),
    });
  };
}

export default TopicParameterLogs;
