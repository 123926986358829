import requestor from "requestor";
import BaseRoute from "utils/base_route";

import permissionsFetchAction from "modules/permissions/actions/permissions_fetch";
import permissionGroupsFetchAction from "modules/permissions/actions/permission_groups_fetch";
import permissionGroupPermissionsFetchAction from "modules/permissions/actions/permission_group_permissions_fetch";
import Permissions from "./containers/permissions";

export default class PermissionsRoute extends BaseRoute {
  constructor(store) {
    super("permissions", store);
  }

  getComponents(location, cb) {
    cb(null, Permissions);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData() {
    this.fetchPermissions();
    this.fetchPermissionGroups();
    this.fetchPermissionGroupPermissions();
  }

  fetchPermissions() {
    this.store.dispatch(permissionsFetchAction(requestor)());
  }

  fetchPermissionGroups() {
    this.store.dispatch(permissionGroupsFetchAction(requestor)());
  }

  fetchPermissionGroupPermissions() {
    this.store.dispatch(permissionGroupPermissionsFetchAction(requestor)());
  }
}
