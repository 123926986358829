import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function confirmTopicInClause(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_CLAUSE_TOPICS_REORDER,
    (
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      topicOrder,
    ) =>
      requestor
        .put(
          `${basePath}/organisation/${organisationId}/project/${projectId}` +
            `/document/${documentId}/clause/${clauseId}/` +
            `clausepart/${clausepartId}/topic/reorder`,
          {last_edited: clauseLastEdited, topics: topicOrder},
          {withCredentials: true},
        )
        .then(response => ({
          clause_id: clauseId,
          clausepart_id: clausepartId,
          section_id: sectionId,
          ...response.data,
        })),
  );
}
