import React from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";

import SetDocumentTypeMenu from "./menus/set_document_type_menu";
import SetContractTypeMenu from "./menus/set_contract_type_menu";
import FormUploadMenu from "./menus/form_upload_menu";

const styles = {
  actions: {
    zIndex: 0,
  },
};

class PreUploadDialog extends React.Component {
  state = {selectedItemId: ""};
  selectItem = selectedItemId => this.setState(() => ({selectedItemId}));
  render() {
    return (
      <Dialog
        title={this.getDialogTitle()}
        className="dialog"
        onDismiss={this.props.onDismiss}
        open={true}
        actions={this.getActions()}
        bodyStyle={{overflowY: "inherit"}}
        contentStyle={styles.dialogContent}
      >
        <div style={{maxHeight: "24rem", overflow: "auto"}}>
          {this.renderDialogContent()}
        </div>
      </Dialog>
    );
  }
  getDialogTitle = () => {
    switch (this.props.dialogType) {
      case "set_document_type":
        return "Please specify which documents would you like to upload in this project";
      case "set_default_contract_type":
        return "Please set contract type for this project";
      default:
        return "What kind of document do you want to upload?";
    }
  };
  getActions = () => {
    return [
      <FlatButton
        label="Cancel"
        key="preupload-dialog-cancel-button"
        secondary={true}
        onClick={this.props.onDismiss}
        style={styles.actions}
      />,
      <FlatButton
        label="Confirm"
        key="preupload-dialog-confirm-button"
        primary={true}
        onClick={this.getDialogConfirmAction()}
        style={styles.actions}
      />,
    ];
  };
  getDialogConfirmAction = () => {
    switch (this.props.dialogType) {
      case "set_document_type":
        return this.setDocumentType;
      case "set_default_contract_type":
        return this.setContractType;
      case "form": {
        const selection = this.state.selectedItemId;
        if (selection === "form") {
          return this.props.showFormUploader;
        } else if (selection === "form_template") {
          return this.props.showFormTemplateUploader;
        }
        return null;
      }
      default:
        return null;
    }
  };

  renderDialogContent = () => {
    switch (this.props.dialogType) {
      case "set_document_type":
        return (
          <SetDocumentTypeMenu
            documentTypes={this.props.documentTypes}
            selectItem={this.selectItem}
            selectedItemId={this.state.selectedItemId}
          />
        );
      case "set_default_contract_type":
        return (
          <SetContractTypeMenu
            contractTypes={this.props.contractTypes}
            selectItem={this.selectItem}
            selectedItemId={this.state.selectedItemId}
          />
        );
      case "form":
        return (
          <FormUploadMenu
            project={this.props.project}
            selectItem={this.selectItem}
            selectedItemId={this.state.selectedItemId}
            onFormTemplateSelected={this.props.onFormTemplateSelected}
            formTemplates={this.props.formTemplates}
            selectedFormTemplateId={this.props.selectedFormTemplateId}
          />
        );
      default:
        return null;
    }
  };

  setDocumentType = () => {
    this.props.setDocumentType(this.state.selectedItemId);
  };
  setContractType = () => {
    this.props.setContractType(this.state.selectedItemId);
  };
}

export default PreUploadDialog;
