import React, {FC} from "react";
import EnumEditor from "./enum_editor";
import {SchemaEnums, SchemaProperty} from "common/flowmaster/types/schema";
import PropertiesEditor from "./properties_editor";
import ItemsEditor from "./items_editor";
import {
  ActionDefinition,
  WorkflowTask,
} from "common/flowmaster/types/task_config";
import {
  Prompt,
  WorkflowContext,
  WorkflowInputs,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

const TypeDetail: FC<{
  property: SchemaProperty;
  name?: string;
  isEnum: boolean;
  onEnumUpdate?: (values: SchemaEnums) => void;
  onUpdate: (name: string, property: SchemaProperty) => void;
  priorTasks: WorkflowTask[];
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  organisationId: OrganisationId;
}> = ({
  property,
  name = "",
  isEnum,
  onUpdate,
  onEnumUpdate = () => {},
  priorTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  organisationId,
}) => {
  if (isEnum) {
    return (
      <EnumEditor
        type={property.type as "string" | "number"}
        values={property.enum || []}
        onUpdate={onEnumUpdate}
      />
    );
  } else {
    switch (property.type) {
      case "object":
        return (
          <PropertiesEditor
            onChange={newProps =>
              onUpdate(name, {...property, properties: newProps})
            }
            properties={property.properties || {}}
            isNested={true}
            priorTasks={priorTasks}
            context={context}
            inputs={inputs}
            prompts={prompts}
            actionDefinitions={actionDefinitions}
            organisationId={organisationId}
          />
        );
      case "array":
        return (
          <ItemsEditor
            items={property.items || {type: "string"}}
            onUpdateItems={items => onUpdate(name, {...property, items})}
            priorTasks={priorTasks}
            context={context}
            inputs={inputs}
            prompts={prompts}
            actionDefinitions={actionDefinitions}
            organisationId={organisationId}
          />
        );
      default:
        return null;
    }
  }
};

export default TypeDetail;
