import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function deleteOrganisationUser(requestor) {
  return createReduxPromiseAction(
    ActionTypes.ORGANISATION_USER_DELETE,
    (organisationId, userId) => {
      return requestor
        .delete(
          `${basePath}/organisation/${organisationId}/config/users/${userId}`,
          {withCredentials: true},
        )
        .then(() => ({userId}));
    },
  );
}
