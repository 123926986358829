import React from "react";
import _ from "underscore";
import requestor from "requestor";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import DefinitionGroupListComponent from "../components/definition_group_list";

import addDefinitionGroupAction from "modules/documents/actions/definition_group/definition_group_add";

import byId from "common/utils/by_id";

function DefinitionGroupListContainer(props) {
  const shouldRenderContainer = isInitialised([
    props.definitionGroups,
    props.user,
    props.contractTypes,
  ]);
  if (!shouldRenderContainer || !props.user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const permissioner = new Permissioner(props.user);
  if (
    !permissioner.hasPermission("list-definition-groups") ||
    !permissioner.isAdmin()
  ) {
    return permissioner.getNoPermissionMessage();
  }

  const {organisationId} = props.params;

  function addDefinitionGroup(data) {
    props.addDefinitionGroup(organisationId, data);
  }

  function onDefinitionGroupDetail(definitionGroupId) {
    if (this.hasPermission("update-definition-group")) {
      this.props.dispatch(
        push(
          `/organisation/${organisationId}/definition_group/detail/${definitionGroupId}`,
        ),
      );
    }
  }

  setTitle("Definition Group List");
  return (
    <DefinitionGroupListComponent
      organisationId={organisationId}
      definitionGroups={_.sortBy(props.definitionGroups, dg => dg.name)}
      contractTypesById={byId(props.contractTypes)}
      addDefinitionGroup={addDefinitionGroup}
      onDefinitionGroupDetail={onDefinitionGroupDetail}
      user={props.user}
    />
  );
}

function select(state, props) {
  return {
    params: props.params,
    organisation: state.organisation,
    router: state.router,
    user: state.user,
    definitionGroups: state.definitionGroups,
    contractTypes: state.contract_types,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        addDefinitionGroup: addDefinitionGroupAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(
  DefinitionGroupListContainer,
);
