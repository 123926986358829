import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function clientAdd(requestor) {
  return createReduxPromiseAction(
    ActionTypes.CLIENT_ADD,
    (organisationId, data) =>
      requestor
        .post(`${basePath}/organisation/${organisationId}/client`, data, {
          withCredentials: true,
        })
        .then(response => response.data)
        .catch(err => err.data),
  );
}
