import React, {useEffect, useState} from "react";
import ReactSelect from "react-select";
import _ from "lodash";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import IconMenu from "common_components/icon_menu";
import ReactSelectLabel from "common_components/react_select_label";
import Synonyms from "./synonyms";
import usePrevious from "utils/hooks/use_previous";

export default function DefinitionGroupDetailComponent(props) {
  const {definitionGroup, definitionGroups, contractTypes} = props;

  // name logic
  const [nameError, setNameError] = useState("");

  const onNameUpdate = e => {
    const newName = e.target.value;
    if (newName === definitionGroup.name) {
      return;
    }
    if (!newName) {
      return setNameError("You must enter a name");
    }
    const definitionGroupNameExists = Boolean(
      definitionGroups.find(
        dg => dg.name.toLowerCase() === newName.toLowerCase(),
      ),
    );
    if (definitionGroupNameExists) {
      return setNameError("Name already exists");
    }
    setNameError("");
    props.onDefinitionGroupUpdate({name: newName});
  };

  // delete logic
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState(false);
  const showDeleteDialog = () => setIsDeleteDialogShown(true);
  const hideDeleteDialog = () => setIsDeleteDialogShown(false);

  // contract types logic
  const [
    stateDefinitionGroupContractTypes,
    updateStateDefinitionGroupContractTypes,
  ] = useState([...(definitionGroup.contract_types || [])]);

  const stateDefinitionGroupContractTypesPrev = usePrevious([
    ...stateDefinitionGroupContractTypes,
  ]);

  function contractTypesAdded(contractTypes) {
    props.onDefinitionGroupUpdate({added_contract_types: contractTypes});
  }

  function contractTypesRemoved(contractTypes) {
    props.onDefinitionGroupUpdate({removed_contract_types: contractTypes});
  }

  useEffect(
    () => {
      if (
        stateDefinitionGroupContractTypesPrev &&
        stateDefinitionGroupContractTypes &&
        !_.isEqual(
          stateDefinitionGroupContractTypes,
          stateDefinitionGroupContractTypesPrev,
        )
      ) {
        if (
          stateDefinitionGroupContractTypes.length >
          stateDefinitionGroupContractTypesPrev.length
        ) {
          const addedContractTypes = stateDefinitionGroupContractTypes
            .filter(ct => !stateDefinitionGroupContractTypesPrev.includes(ct))
            .map(ctId => {
              const contractType = contractTypes.find(ct => ct.id === ctId);
              return {contract_type_id: ctId, name: contractType.name};
            });
          contractTypesAdded(addedContractTypes);
        } else if (
          stateDefinitionGroupContractTypesPrev.length >
          stateDefinitionGroupContractTypes.length
        ) {
          const removedContractTypes = stateDefinitionGroupContractTypesPrev
            .filter(ct => !stateDefinitionGroupContractTypes.includes(ct))
            .map(ctId => {
              const contractType = contractTypes.find(ct => ct.id === ctId);
              return {contract_type_id: ctId, name: contractType.name};
            });
          contractTypesRemoved(removedContractTypes);
        }
      }
    },
    [stateDefinitionGroupContractTypes],
  );

  function onStateContractTypesUpdate(newValues) {
    updateStateDefinitionGroupContractTypes(newValues.map(dg => dg.id));
  }

  // synonyms logic
  function onSynonymsUpdate(synonyms) {
    props.onDefinitionGroupUpdate({synonyms});
  }
  function toggleUseLowercase() {
    props.onDefinitionGroupUpdate({
      use_lowercase: !definitionGroup.use_lowercase,
    });
  }

  return (
    <>
      <div className="app-toolbar">
        <TextField
          fullWidth
          helperText={nameError}
          error={Boolean(nameError)}
          defaultValue={definitionGroup.name}
          onBlur={onNameUpdate}
        />
        <Button
          onClick={props.redirectToDefinitionGroupsList}
          style={{flexShrink: "0", margin: "0.5rem"}}
        >
          Definition Groups
        </Button>
        <IconMenu options={[{label: "Delete", handler: showDeleteDialog}]} />
      </div>
      <div style={{padding: "1.5rem"}}>
        <div style={{zIndex: 1, paddingBottom: "1rem"}}>
          <ReactSelectLabel>Contract Types</ReactSelectLabel>
          <ReactSelect
            className="contractTypes"
            valueKey="id"
            labelKey="name"
            multi={true}
            removeSelected={true}
            clearable={false}
            backspaceRemoves={false}
            deleteRemoves={false}
            closeOnSelect={false}
            allowCreate={false}
            value={getCurrentContractTypesValue(
              stateDefinitionGroupContractTypes,
              contractTypes,
            )}
            options={getAllContractTypes(contractTypes)}
            onChange={onStateContractTypesUpdate}
            wrapperStyle={{width: "100%"}}
            menuContainerStyle={{zIndex: 99}}
          />
        </div>
        <Synonyms
          synonyms={definitionGroup.synonyms}
          onSynonymsUpdate={onSynonymsUpdate}
          toggleUseLowercase={toggleUseLowercase}
          definitionGroup={definitionGroup}
        />
      </div>
      <Dialog fullWidth onClose={hideDeleteDialog} open={isDeleteDialogShown}>
        <DialogTitle>Confirm Definition Group Deletion</DialogTitle>
        <DialogContentText style={{padding: "0 24px"}}>
          Are you sure you want to delete this Definition Group?
        </DialogContentText>
        <DialogActions>
          <Button onClick={hideDeleteDialog}>Cancel</Button>
          <Button color="primary" onClick={props.onDefinitionGroupRemove}>
            Delete Definition Group
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function getCurrentContractTypesValue(
  definitionGroupContractTypes,
  contractTypes,
) {
  return definitionGroupContractTypes.map(ctId => {
    const contractType = contractTypes.find(ct => ct.id === ctId);
    return {id: contractType.id, name: contractType.name};
  });
}

function getAllContractTypes(contractTypes) {
  return contractTypes.map(ct => ({id: ct.id, name: ct.name}));
}
