import _ from "underscore";

function hasSingleTemplate(issue) {
  const issuesets = _.flatten(issue.contract_types.map(ct => ct.issuesets));
  return (
    issuesets.filter(issueset => !issueset.is_duplicate_on_master).length <= 1
  );
}

export default hasSingleTemplate;
