import React, {useState} from "react";
import {Link} from "react-router";
import moment from "moment";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreIcon from "@material-ui/icons/MoreVert";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import ListFilter from "common_components/list_filter";
import {Table, Tr, Th, Td} from "common_components/table";

import getListFilterRegexes from "utils/get_list_filter_regexes";
import CreateDefinitionGroupDialog from "./create_definition_group_dialog";

const styles = {
  inner: {
    padding: "1em 2em",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
};

const filterTypes = [
  {title: "Synonyms", fieldName: "synonyms", isDefault: true},
  {title: "All Types", useAllFields: true},
  {title: "Name", fieldName: "name"},
];

function getFilteredDefinitionGroups(
  definitionGroups,
  filterString,
  filterType,
) {
  if (!filterString || filterString.length < 2) {
    return definitionGroups;
  }
  const filterTexts = getListFilterRegexes(`"${filterString}"`);
  if (!filterTexts) {
    return definitionGroups;
  }
  const filterTypeObj = filterTypes.find(ft => ft.title === filterType);
  const fieldNames = filterTypeObj.useAllFields
    ? filterTypes
        .filter(({fieldName}) => fieldName)
        .map(({fieldName}) => fieldName)
    : [filterTypeObj.fieldName];
  return definitionGroups.filter(dg => {
    return filterTexts.every(filterText => {
      let fieldValues = [];
      fieldNames.forEach(fieldName => {
        if (fieldName === "synonyms") {
          fieldValues = [...fieldValues, ...dg.synonyms];
        } else {
          fieldValues.push(dg[fieldName]);
        }
      });

      for (const fieldValue of fieldValues) {
        if (filterText.test(fieldValue.toString().toLowerCase())) {
          return true;
        }
      }
      return false;
    });
  });
}

function DefinitionGroupListComponent(props) {
  const {contractTypesById, definitionGroups} = props;

  // top right menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // show synonyms
  const [shouldShowSynonyms, updateShouldShowSynonyms] = useState(false);
  function triggerShowSynonyms() {
    updateShouldShowSynonyms(!shouldShowSynonyms);
    handleClose();
  }

  // create dialog
  const [isCreateDefinitionGroupDialogShown, setIsDialogShown] = useState(
    false,
  );
  const onShowCreateDialog = () => setIsDialogShown(true);
  const onHideCreateDialog = () => setIsDialogShown(false);

  // filter
  const [filterString, updateFilterString] = useState("");
  function setFilterText(filterString) {
    updateFilterString(filterString);
  }

  const [filterType, updateFilterType] = useState("Synonyms");
  function onFilterTypeChange(value) {
    updateFilterType(value);
  }

  const filteredDefinitionGroups = getFilteredDefinitionGroups(
    definitionGroups,
    filterString,
    filterType,
  );
  return (
    <div className="app-page">
      <div className="app-toolbar">
        <Typography style={{flexGrow: 1}} color="textSecondary" variant="h6">
          Definition Groups
        </Typography>
        <ListFilter
          filterString={filterString}
          onFilterStringChange={setFilterText}
          itemsCount={definitionGroups.length}
          matchesCount={filteredDefinitionGroups.length}
          filterTypes={filterTypes}
          filterTypeTitle={filterType}
          onFilterTypeChange={onFilterTypeChange}
        />
        <Fab
          style={{marginLeft: 40}}
          size="small"
          color="primary"
          onClick={onShowCreateDialog}
        >
          <AddIcon />
        </Fab>
        <>
          <MoreIcon
            onClick={handleMenu}
            style={{marginLeft: 20, cursor: "pointer"}}
          />
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={triggerShowSynonyms}>
              <ListItemIcon>
                {shouldShowSynonyms ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )}
              </ListItemIcon>
              Show Synonyms
            </MenuItem>
          </Menu>
        </>
      </div>
      <div className="app-body" style={styles.inner}>
        <Table sortColumnIndex={0} hasStickyHeader={true}>
          <thead>
            <tr>
              <Th
                style={{width: "2%"}}
                containerStyle={{justifyContent: "center"}}
              >
                Id
              </Th>
              <Th style={{width: "20%"}}>Name</Th>
              <Th style={{width: "15%"}}>Creation Date</Th>
              <Th style={{width: "5%"}}># Synonyms</Th>
              <Th style={{width: shouldShowSynonyms ? "30%" : "60%"}}>
                Contract Types
              </Th>
              {shouldShowSynonyms && <Th style={{width: "30%"}}>Synonyms</Th>}
            </tr>
          </thead>
          <tbody>
            {filteredDefinitionGroups.map((definitionGroup, index) => (
              <Tr key={`definition-group-${index}`}>
                <Td containerStyle={{alignItems: "center"}}>
                  {definitionGroup.id}
                </Td>
                <Td
                  handleChildren={(children, thisTd) => {
                    const pathname = `/organisation/${
                      props.organisationId
                    }/definition_group/${definitionGroup.id}/detail`;
                    return (
                      <Link
                        to={{pathname}}
                        style={{
                          color: thisTd.props.isRowHovered
                            ? "#2196f3"
                            : "#798087",
                          textDecoration: thisTd.props.isRowHovered
                            ? "underline"
                            : "none",
                        }}
                      >
                        {children}
                      </Link>
                    );
                  }}
                >
                  {definitionGroup.name}
                </Td>
                <Td
                  sortText={moment(definitionGroup.creation_date).format("x")}
                >
                  {moment(definitionGroup.creation_date).format(
                    "DD/MM/YYYY - h:mm A",
                  )}
                </Td>
                <Td containerStyle={{alignItems: "center"}}>
                  {definitionGroup.synonyms.length}
                </Td>
                <Td>
                  {definitionGroup.contract_types.map(ctId => {
                    const ct = contractTypesById[ctId];
                    return <div key={ct.id}>{ct.name}</div>;
                  })}
                </Td>
                {shouldShowSynonyms && (
                  <Td>
                    {definitionGroup.synonyms.map((synonym, idx) => {
                      return <div key={`${synonym}-${idx}`}>{synonym}</div>;
                    })}
                  </Td>
                )}
              </Tr>
            ))}
          </tbody>
        </Table>
      </div>
      <CreateDefinitionGroupDialog
        definitionGroups={props.definitionGroups}
        onHideCreateDialog={onHideCreateDialog}
        createDialogShown={isCreateDefinitionGroupDialogShown}
        addDefinitionGroup={props.addDefinitionGroup}
      />
    </div>
  );
}

export default DefinitionGroupListComponent;
