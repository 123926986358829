import React from "react";

const styles = {
  fieldsetBase: {
    border: "1px solid rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    paddingRight: "10px",
  },
  fieldsetLegend: {
    color: "rgba(0, 0, 0, 0.7)",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
};

function SettingsFieldset(props) {
  return (
    <fieldset style={styles.fieldsetBase}>
      <legend style={styles.fieldsetLegend}>{props.legend}</legend>
      {props.children}
    </fieldset>
  );
}

export default SettingsFieldset;
