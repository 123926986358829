import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import convertContentsToText from "common/utils/contents/convert_contents_to_text";
import ActionTypes from "../constants/action_types";

/* eslint-disable max-params */
export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_CLAUSE_UPDATE,
  (
    organisationId,
    projectId,
    documentId,
    sectionId,
    clauseId,
    contents,
    lastEdited,
  ) =>
    requestor
      .put(
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/section/${sectionId}/clause/${clauseId}`,
        {
          contents,
          text: convertContentsToText(contents),
          last_edited: lastEdited,
        },
        {withCredentials: true},
      )
      .then(response => response.data),
);
