import * as TopicParameters from "plugins/topicparameters";

function findActualValue(value, actualValues, parameterType) {
  const result = {
    value: null,
    index: -1,
  };
  actualValues.forEach((actualValue, index) => {
    if (result.value) {
      return;
    }
    // eslint-disable-next-line import/namespace
    if (TopicParameters[parameterType].isEqual(actualValue, value)) {
      result.value = actualValue;
      result.index = index;
    }
  });
  return result;
}

export default findActualValue;
