import React from "react";
import PropTypes from "prop-types";

import SearchIcon from "material-ui/svg-icons/action/search";
import TextField from "material-ui/TextField";
import CircularProgress from "material-ui/CircularProgress";
import * as colors from "material-ui/styles/colors";

import LogsRow from "./log_row";

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from "material-ui/Table";

import {Toolbar, ToolbarGroup, ToolbarTitle} from "material-ui/Toolbar";

const styles = {
  headerColumn: {
    height: "unset",
    fontSize: "16px",
    fontWeight: "700",
    color: "rgba(0, 0, 0, 0.87)",
  },
  searchBlock: {
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: "8px",
    color: "#9aa0a7",
  },
  tableWrapper: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  circularProgress: {
    position: "absolute",
    top: "45%",
    left: "50%",
  },
  noResultsFound: {
    height: "13rem",
    lineHeight: "13rem",
    fontSize: "28px",
    color: colors.grey500,
    textAlign: "center",
  },
};

class LogsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      prevSearchValue: "",
    };
  }

  render() {
    const {logs} = this.props;
    const isNotEmptyResults = Array.isArray(logs) && logs.length > 0;
    const isProcessing =
      this.props.processing ||
      (!Array.isArray(logs) && this.state.searchValue !== "");
    return (
      <div className="app-page">
        <Toolbar className="app-toolbar">
          <ToolbarGroup key={0}>
            <ToolbarTitle text="Logs" />
          </ToolbarGroup>
          <ToolbarGroup key={1}>
            <div style={styles.searchBlock}>
              <SearchIcon style={styles.searchIcon} />
              <TextField
                name="logs-search"
                placeholder="Type here to search"
                value={this.state.searchValue}
                onChange={this.onSearchChange}
                onBlur={this.onSearch}
                onKeyPress={this.onKeyPress}
                underlineShow={true}
                autoFocus
              />
            </div>
          </ToolbarGroup>
        </Toolbar>
        <div className="app-body">
          <Table
            fixedHeader={true}
            fixedFooter={false}
            selectable={false}
            multiSelectable={false}
            wrapperStyle={styles.tableWrapper}
          >
            <TableHeader
              displaySelectAll={false}
              adjustForCheckbox={false}
              enableSelectAll={false}
            >
              <TableRow>
                <TableHeaderColumn style={styles.headerColumn}>
                  Date
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.headerColumn}>
                  Event Type
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.headerColumn}>
                  User Name
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.headerColumn}>
                  Link
                </TableHeaderColumn>
                <TableHeaderColumn style={styles.headerColumn}>
                  Extra Data
                </TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {isProcessing ? (
                <CircularProgress style={styles.circularProgress} />
              ) : isNotEmptyResults ? (
                this.props.logs.map(log => <LogsRow key={log.id} log={log} />)
              ) : (
                <div style={styles.noResultsFound}>No Results Found</div>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }

  onSearchChange = (e, searchValue) => this.setState(() => ({searchValue}));

  onKeyPress = e => {
    if (e.key === "Enter") {
      this.onSearch();
    }
  };

  onSearch = () => {
    const {searchValue, prevSearchValue} = this.state;
    if (
      (searchValue.length >= 3 || searchValue.length === 0) &&
      prevSearchValue !== searchValue
    ) {
      this.setState(
        () => ({prevSearchValue: searchValue}),
        () => this.props.search(searchValue),
      );
    }
  };
}

LogsList.propTypes = {
  logs: PropTypes.array.isRequired,
};

export default LogsList;
