import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../../constants/action_types";

/* eslint-disable max-params */
/* eslint-disable @typescript-eslint/no-unused-vars */
export default createReduxPromiseAction(ActionTypes.DOCUMENT_HEADING_REMOVE, (
  organisationId,
  projectId,
  documentId,
  sectionId,
  headingId,
  headingLastEdited,
  deletionChange, // used in action request
) =>
  requestor
    .delete(
      `${basePath}/organisation/${organisationId}/project/${projectId}` +
        `/document/${documentId}/section/${sectionId}/heading/${headingId}/`,
      {
        withCredentials: true,
        data: {
          heading_last_edited: headingLastEdited,
        },
      },
    )
    .then(response => response.data),
);
