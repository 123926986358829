import basePath from "base_path";
import ActionTypes from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function fetchPrerequisiteWorkflows(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PREREQUISITE_WORKFLOWS_FETCH,
    (organisationId: number, projectId: number | string) => {
      return requestor
        .get(
          `${basePath}/organisation/${organisationId}/project/${projectId}/prerequisite`,
          {withCredentials: true},
        )
        .then(response => response.data);
    },
  );
}
