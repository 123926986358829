import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

// import {isInitialised} from "utils/uninitialised";
// import Permissioner from "utils/permissioner";
import setTitle from "utils/set_title";
import signupAction from "modules/organisation/actions/signup";
import SignupComponent from "../components/signup";

class Signup extends Component {
  render() {
    return this.renderComponent();
  }

  renderComponent() {
    setTitle("Signup");
    return (
      <SignupComponent
        signup={this.signup}
        signupError={
          this.props.user.error === "signup_fail" &&
          this.props.user.errorMessage
        }
      />
    );
  }

  signup = (...args) => {
    this.props.signup(...args);
  };
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signup: signupAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
