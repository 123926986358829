import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../constants/action_types";

/* eslint-disable max-params */
export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_CLAUSE_REVERT,
  (organisationId, projectId, documentId, sectionId, clauseId, lastEdited) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/section/${sectionId}/clause/${clauseId}/revert`,
        {
          last_edited: lastEdited,
        },
        {
          withCredentials: true,
        },
      )
      .then(response => ({...response.data, sectionId, clauseId})),
);
