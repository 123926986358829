import React from "react";

import AlgorithmSelector from "./algorithm_selector";

import {serialiseAlgorithm} from "../../../util/serialise_algorithm";

import {TopicId} from "common/types/document";
import EmbeddingTopic from "modules/searcher/types/embedding_topic";

interface Props {
  algorithmState: Record<string, boolean>;
  setAlgorithmState: (algorithm: string, value: boolean) => void;
  embeddingTopics: EmbeddingTopic[];
  availableEmbeddings?: TopicId[];
}

export default function AlgorithmSelectors({
  algorithmState,
  embeddingTopics,
  setAlgorithmState,
  availableEmbeddings,
}: Props) {
  return (
    <fieldset>
      <legend>Algorithms</legend>
      <div style={{display: "flex", gap: "1em"}}>
        {[
          <AlgorithmSelector
            key="text"
            label="Text"
            value="text"
            checked={algorithmState?.text}
            onChange={(value: boolean) => setAlgorithmState("text", value)}
            disabled={false}
          />,
          ...(Array.isArray(embeddingTopics) ? embeddingTopics : []).map(
            embeddingTopic => {
              const key = serialiseAlgorithm({
                type: "embedding",
                options: {
                  topic_id: embeddingTopic.id,
                },
              });
              return (
                <AlgorithmSelector
                  key={key}
                  label={`Embedding: ${embeddingTopic.name}`}
                  value={key}
                  checked={algorithmState?.[key]}
                  onChange={(value: boolean) => setAlgorithmState(key, value)}
                  disabled={
                    !(availableEmbeddings ?? []).find(
                      topicId => topicId === embeddingTopic.id,
                    )
                  }
                />
              );
            },
          ),
        ]}
      </div>
    </fieldset>
  );
}
