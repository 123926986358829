import React from "react";

import DocumentSelector from "./document_selector";
import QueryBuilder from "./query_builder";

import {OrganisationId} from "common/types/organisation";
import {ProjectId, Project} from "common/types/project";
import {DocumentId} from "common/types/document";
import {Document} from "../../types";
import EmbeddingTopic from "modules/searcher/types/embedding_topic";

interface Props {
  organisationId: OrganisationId;
  projectId: ProjectId;
  projects: Project[];
  selectProject: (projectId: ProjectId) => void;

  documentIds: null | DocumentId[];
  documents: Document[];
  selectDocuments: (documentIds: DocumentId[]) => void;

  query: string;
  runQuery: () => void;
  setQuery: (query: string) => void;

  embeddingTopics: EmbeddingTopic[];
  algorithmState: Record<string, boolean>;
  setAlgorithmState: (algorithm: string, value: boolean) => void;
}

export default function Settings({
  organisationId,
  projectId,
  projects,
  selectProject,
  documentIds,
  documents,
  selectDocuments,
  query,
  setQuery,
  runQuery,
  embeddingTopics,
  algorithmState,
  setAlgorithmState,
}: Props) {
  return (
    <div
      style={{
        padding: "2em",
        display: "grid",
        gap: "1em",
        backgroundColor: "#eee",
      }}
    >
      <DocumentSelector
        organisationId={organisationId}
        projectId={projectId}
        projects={projects}
        selectProject={selectProject}
        documentIds={documentIds}
        documents={documents}
        selectDocuments={selectDocuments}
      />
      <QueryBuilder
        query={query}
        setQuery={setQuery}
        runQuery={runQuery}
        embeddingTopics={embeddingTopics}
        algorithmState={algorithmState}
        setAlgorithmState={setAlgorithmState}
        availableEmbeddings={Object.keys(
          (Array.isArray(documents) ? documents : [])
            .filter(document =>
              (documentIds ?? []).find(
                documentId => document.id === documentId,
              ),
            )
            .reduce((topics, doc) => {
              return {
                ...topics,
                ...Object.fromEntries(doc.embedding_topics.map(id => [id, 1])),
              };
            }, {}),
        ).map(id => parseInt(id, 10))}
      />
    </div>
  );
}
