import React from "react";
import {bindActionCreators} from "redux";

import Permissioner from "utils/permissioner";
import {connect} from "react-redux";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";

import projectUpdateAction from "modules/projects/actions/project_update";
import projectDeleteAction from "modules/projects/actions/project_delete";
import updateOrganisationUserAction from "modules/organisation/actions/organisation_user_update";
import fetchOrganisationAccesstagsAction from "modules/accesstags/actions/fetch";
import projectAddAccesstagAction from "modules/projects/actions/project_add_accesstag";
import projectCreateAccesstagAction from "modules/projects/actions/project_create_accesstag";
import projectRemoveAccesstagAction from "modules/projects/actions/project_remove_accesstag";

import ProjectComponent from "../components/project";
import requestor from "requestor";

import byId from "common/utils/by_id";
import getPermittedDocumentTypes from "common/utils/get_permitted_document_types";
import {LOADING} from "common/types/util";

class Project extends React.Component {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return this.permissioner.hasPermission("get-project-detail");
  }

  shouldRenderContainer() {
    return (
      isInitialised([
        this.props.project,
        this.props.projectroles,
        this.props.organisation,
        this.props.contractTypes,
        this.props.documentTypes,
        this.props.user,
        this.props.roles,
        this.props.issues,
      ]) && this.props.user["logged-in"] !== "unknown"
    );
  }

  renderComponent() {
    return this.renderProjectt();
  }

  renderProjectt() {
    setTitle(`${this.props.project.name}`);
    const contractTypes = this.props.user.is_admin
      ? this.props.contractTypes
      : this.props.contractTypes.filter(
          ct =>
            ct.is_visible ||
            ct.id === this.props.project.default_contract_type.id,
        );
    const roles = this.props.project.default_contract_type?.id
      ? this.props.roles.filter(
          role =>
            role.contract_types.indexOf(
              this.props.project.default_contract_type.id,
            ) !== -1,
        )
      : this.props.roles;
    return (
      <ProjectComponent
        project={this.props.project}
        projectroles={this.props.projectroles}
        lastProject={this.props.lastProject}
        onIsDefaultChanged={this.updateUserDefaultProject}
        onProjectDeleted={this.deleteProject}
        onProjectUpdate={this.onProjectUpdate}
        user={this.props.user}
        organisation={this.props.organisation}
        contractTypesById={byId(contractTypes)}
        contractTypes={contractTypes}
        documentTypes={getPermittedDocumentTypes(
          this.props.documentTypes,
          this.permissioner,
        )}
        roles={roles}
        issues={this.props.issues}
        organisationAccesstags={this.props.accesstags}
        onEnterAccessTab={() => this.fetchOrganisationAccesstags()}
        addAccesstagToProject={accesstag =>
          this.props.projectAddAccesstagAction(
            this.props.organisation.id,
            this.props.project.id,
            accesstag,
          )
        }
        createAccesstag={accesstag =>
          this.props
            .projectCreateAccesstagAction(
              this.props.organisation.id,
              this.props.project.id,
              accesstag,
            )
            // Allow the default Redux error handling to display the error
            .catch(() => {})
        }
        removeAccesstag={accesstag =>
          this.props.projectRemoveAccesstagAction(
            this.props.organisation.id,
            this.props.project.id,
            accesstag,
          )
        }
      />
    );
  }

  fetchOrganisationAccesstags() {
    this.props.fetchOrganisationAccesstags(this.props.organisation.id);
  }

  updateUserDefaultProject = async () => {
    await this.props.updateOrganisationUser(
      this.props.organisation.id,
      this.props.user.id,
      {default_project_id: parseInt(this.props.params.projectId, 10)},
    );
  };

  onProjectUpdate = data => {
    const {organisationId, projectId} = this.props.params;
    return this.props.projectUpdateAction(organisationId, projectId, {
      ...data,
      last_edited: this.props.project.last_edited,
    });
  };

  deleteProject = () => {
    const {organisationId, projectId} = this.props.params;
    this.props.projectDeleteAction(
      organisationId,
      projectId,
      this.props.project.last_edited,
    );
  };
}

function select(state) {
  return {
    project: state.project,
    lastProject: state.projects && state.projects.length === 1,
    projectroles: state.projectroles,
    router: state.router,
    user: state.user,
    organisation: state.organisation,
    contractTypes: state.contract_types,
    documentTypes: state.document_types,
    roles: state.roles,
    issues: state.issues,
    accesstags: state.accesstags !== UNINITIALISED ? state.accesstags : LOADING,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      projectAddAccesstagAction: projectAddAccesstagAction(requestor),
      projectCreateAccesstagAction: projectCreateAccesstagAction(requestor),
      projectRemoveAccesstagAction: projectRemoveAccesstagAction(requestor),
      projectUpdateAction: projectUpdateAction(requestor),
      projectDeleteAction: projectDeleteAction(requestor),
      updateOrganisationUser: updateOrganisationUserAction(requestor),
      fetchOrganisationAccesstags: fetchOrganisationAccesstagsAction,
    },
    dispatch,
  );
}

export default connect(select, mapDispatchToProps)(Project);
export const Component = Project;
