import React from "react";
import _ from "underscore";

import IconButton from "material-ui/IconButton";
import ListIcon from "material-ui/svg-icons/av/playlist-add-check";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";

import * as colors from "material-ui/styles/colors";

import ChecklistPanel from "./checklist_panel/";

import ResizeLeftPanelButton from "common_components/buttons/resize_left_panel_button";
import Tooltip from "common_components/tooltip";
import byId from "common/utils/by_id";
import groupIssuesForChecklist from "common/utils/issues/group_issues_for_checklist";
import addSubissueDataToGroupedIssues from "common/utils/issues/add_subissue_data_to_grouped_issues";
import localStorage from "utils/local_storage";
import getStateOnViewModeChange from "../../../document_detail/utils/get_state_on_view_mode_change";
import getApplicableIssuesetIds from "utils/issuesets/get_applicable_issueset_ids";
import getDefaultIssuesetId from "utils/issuesets/get_default_issueset_id";
import getCurrentIssuesetItem from "utils/issuesets/get_current_issueset_item";
import constructIssueDetailData from "utils/issues/construct_issue_detail_data";
import onIssueCheckboxCheck from "../../../document_detail/utils/issue/on_issue_checkbox_check";
import IssueDetail from "common_containers/issue_detail";
import {correctDocumentIssueManually} from "../../../document_detail/components/document_detail";
import getIssues from "utils/issues/get_issues_for_checklist";
import calculateIssueOverrides from "utils/issues/calculate_issue_overrides";
import hasSingleTemplate from "utils/issues/has_single_template";

const styles = {
  navigationBarContainer: {
    height: "auto",
    display: "flex",
    flexDirection: "row",
  },
  leftBar: {
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid lightgray",
  },
  iconContainer: {
    margin: "8px 4px",
  },
  iconStyle: {
    color: "#696969",
  },
  addIssueDialog: {
    select: {
      width: "30em",
      margin: "auto 1em",
    },
    buttons: {
      margin: "1em auto",
    },
    button: {
      margin: "auto .5em",
    },
  },
};

const elements = [
  {
    component: <ListIcon />,
    title: "Checklist",
  },
];

const filterOptions = createFilterOptions({
  stringify: option => `${option.name} ${option.id} ${option.master_id}`,
});

class LeftHandMenu extends React.Component {
  constructor(props) {
    super(props);
    const baseStateValues = {
      selectedProject: null,
      selectedDocument: null,
      currentIssueset: null,
      isLeftHandMenuOpen: false,
      currentTab: null,
      showDisplayName: true,
      showArchivedIssues: false,
      shouldShowAddIssueDialog: false,
      issueToAdd: null,
    };
    if (props.getLocationSearchParams) {
      const searchParams = props.getLocationSearchParams();
      const {
        project: projectId,
        document: documentId,
        issueset: issuesetId,
        open_issue_list: openIssueList,
        auto_select_first_document: autoSelectFirstDocument,
      } = searchParams;
      baseStateValues.selectedProject = projectId;
      baseStateValues.selectedDocument = documentId;
      baseStateValues.currentIssueset = issuesetId;
      baseStateValues.isLeftHandMenuOpen = openIssueList;
      baseStateValues.shouldSelectFirstDocument = autoSelectFirstDocument;
      if (openIssueList) {
        baseStateValues.currentTab = "Checklist";
      }
    }

    this.state = {
      ...baseStateValues,
      isDocumentFetchRequestPending: false,
      isDocumentSelectorOpen: true,
      areIssuesCollapsed: localStorage.getItem("areIssuesCollapsed")
        ? localStorage.getItem("areIssuesCollapsed") === "true"
        : false,
      isIssueCorrectnessPanelShown: localStorage.getItem(
        "isIssueCorrectnessPanelShown",
      )
        ? localStorage.getItem("isIssueCorrectnessPanelShown") === "true"
        : true,
      panelData: null,
      viewMode: "checklist",
      findIssueJobsAmount: {
        documentJobsAmount: 0,
        issueJobsAmount: 0,
        jobsAmount: 0,
      },
      classifyTopicparameterJobsAmount: {
        documentJobsAmount: 0,
        issueJobsAmount: 0,
        jobsAmount: 0,
      },
      selectedChecklistSectionId: null,
    };
    this.correctDocumentIssueManually = correctDocumentIssueManually.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    // overridableFields and proposedChanges are kept in state of the parent
    // component. Thus when they change children components are also rerendered.
    // Therefore we specifically pass these state values as props and dont update
    // component if they change. (Original problem: checklist rerender on every
    // change in template input fields which slows dows the UI)
    if (
      !_.isEqual(this.props.overridableFields, nextProps.overridableFields) ||
      !_.isEqual(this.props.proposedChanges, nextProps.proposedChanges)
    ) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    const {selectedProject, selectedDocument} = this.state;
    localStorage.setItem("areIssuesCollapsed", true);
    this.setState(() => ({areIssuesCollapsed: true}));
    if (selectedProject) {
      this.props.fetchDocuments(selectedProject);
    }
    if (selectedDocument) {
      this.onFetchDocument(selectedProject, selectedDocument);
    }
    this.findIssueJobsAmountInterval = setInterval(
      this.updateFindIssueJobsAmount,
      30000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.updateFindIssueJobsAmount);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(prevProps.issue, this.props.issue) ||
      prevState.selectedDocument !== this.state.selectedDocument
    ) {
      setTimeout(this.updateFindIssueJobsAmount, 2000);
    }

    if (!prevProps.documentClauses && this.props.documentClauses) {
      this.setState(() => ({isDocumentFetchRequestPending: false}));
    }

    if (
      !prevProps.documents &&
      this.props.documents &&
      this.state.shouldSelectFirstDocument &&
      this.state.selectedProject
    ) {
      const projectId = this.state.selectedProject;
      const documentId = this.props.documents[this.props.documents.length - 1]
        .id;
      this.setState(
        () => ({selectedDocument: documentId}),
        () => this.onFetchDocument(projectId, documentId),
      );
    }

    const withLocationLogic =
      this.props.getLocationSearchParams && this.props.pushLocationSearchParams;
    let searchParams;

    if (withLocationLogic) {
      searchParams = this.props.getLocationSearchParams();
      const updatedSearchParams = {
        auto_select_first_document: null,
      };
      const variableNames = {
        isLeftHandMenuOpen: "open_issue_list",
        selectedProject: "project",
        selectedDocument: "document",
        currentIssueset: "issueset",
      };
      for (const stateName in variableNames) {
        const searchName = variableNames[stateName];
        if (prevState[stateName] !== this.state[stateName]) {
          updatedSearchParams[searchName] = this.state[stateName];
        }
      }
      if (prevState.panelData !== this.state.panelData) {
        updatedSearchParams["open_issue_detail"] = Boolean(
          this.state.panelData,
        );
      }
      if (Object.keys(updatedSearchParams).length) {
        this.props.pushLocationSearchParams(updatedSearchParams);
      } else {
        this.applyLocationSearchParams();
      }
      if (
        !prevProps.documentClauses &&
        this.props.documentClauses &&
        searchParams.open_issue_detail
      ) {
        const {groupedIssuesWithSubissues: groups} = this.getIssuesData();
        const {
          id: issueId,
          name: issueName,
          display_name: issueDisplayName,
        } = this.props.issue;
        if (groups) {
          const group =
            groups.find(
              group =>
                (issueDisplayName && issueDisplayName.startsWith(group.name)) ||
                issueName.startsWith(group.name),
            ) || {};
          const issue = (group.item || []).find(issue => issue.id === issueId);
          if (issue) {
            this.showIssueDetail(issue);
          }
        }
      }
    }

    if (prevProps.document === null && this.props.document !== null) {
      if (!withLocationLogic || !searchParams.issueset) {
        const project = this.getProjectBySelectedProject();
        const defaultIssuesetId = getDefaultIssuesetId(
          this.getDocumentBySelectedDocument(),
          project,
          this.props.contractTypesById,
        );
        const {issuesetsById = {}} = this.props;
        const defaultIssueset = issuesetsById[defaultIssuesetId] || {
          issues: [],
        };
        const isIssueInDefaultIssueset = defaultIssueset.issues.find(
          issueId => this.props.issue.id === issueId,
        );
        let currentIssueset = defaultIssuesetId;
        if (!isIssueInDefaultIssueset) {
          const applicableIssuesetIds = getApplicableIssuesetIds(
            this.props.document,
            project,
            this.props.contractTypesById,
            {
              get_issuesets_from_contract_types: true,
            },
          );

          currentIssueset =
            applicableIssuesetIds.find(applicIssuesetId => {
              const issueset = this.props.issuesetsById[applicIssuesetId];
              return issueset.issues.find(issuesetIssueId => {
                if (issuesetIssueId === this.props.issue.id) {
                  return true;
                }
                return false;
              });
            }) || defaultIssuesetId;
        }

        this.setState(() => ({currentIssueset}));
      }
      if (
        this.state.panelData &&
        this.state.panelData.type === "issue_detail"
      ) {
        return this.updatePanelData();
      }
    }

    if (this.state.panelData && this.state.panelData.type === "issue_detail") {
      const {
        currentIssueset: currentIssuesetPrev,
        selectedChecklistSectionId: selectedChecklistSectionIdPrev,
      } = prevState;
      const {
        currentIssueset: currentIssuesetNow,
        selectedChecklistSectionId: selectedChecklistSectionIdNew,
      } = this.state;
      if (
        currentIssuesetPrev !== currentIssuesetNow ||
        selectedChecklistSectionIdPrev !== selectedChecklistSectionIdNew
      ) {
        return this.updatePanelData();
      }

      const {issue: currentIssue} = this.props;
      const {issue: prevIssue} = prevProps;
      if (
        !_.isEqual(prevIssue, currentIssue) ||
        !_.isEqual(this.props.documentIssues, prevProps.documentIssues)
      ) {
        return this.updatePanelData();
      }
    }

    if (
      this.state.selectedProject &&
      prevState.selectedProject &&
      this.state.selectedProject !== prevState.selectedProject &&
      this.state.currentIssueset
    ) {
      const project = this.getProjectBySelectedProject();
      if (
        !(project.issuesets || []).find(
          issuesetId => issuesetId === this.state.currentIssueset,
        )
      ) {
        this.setState(() => ({currentIssueset: null}));
      }
    }
  }

  updatePanelData() {
    const {panelData} = this.state;
    if (!this.props.documentIssues || !panelData) {
      return;
    }
    const {selectedChecklistSectionId} = this.state;
    const {currentIssuesetItem} = this.getIssuesData();
    const documentIssue = this.props.documentIssues.find(
      docIssue =>
        docIssue.id === panelData.data.issue.id &&
        (selectedChecklistSectionId
          ? docIssue.section_id === selectedChecklistSectionId
          : !docIssue.section_id),
    );
    const newIssue = calculateIssueOverrides(
      documentIssue,
      currentIssuesetItem,
      false,
      true,
    );
    return this.updateIssueDetail(newIssue);
  }

  applyLocationSearchParams = () => {
    const searchParams = this.props.getLocationSearchParams();
    const {open_issue_list, project, document, issueset} = searchParams;
    if (open_issue_list !== undefined) {
      switch (open_issue_list) {
        case true:
          this.setAndShowFirstTab();
          break;
        case false:
          if (this.state.isLeftHandMenuOpen) {
            this.hideCurrentTab();
          }
          break;
      }
    }
    if (project && project !== this.state.selectedProject) {
      this.setState(
        () => ({selectedProject: project}),
        () => {
          this.props.clearDocument();
          this.props.fetchDocuments(project);
        },
      );
    }
    if (document && this.props.documents) {
      if (document !== this.state.selectedDocument) {
        this.setState(
          () => ({selectedDocument: document}),
          () => this.onFetchDocument(project, document),
        );
      }
    }
    if (issueset && issueset !== this.state.currentIssueset) {
      this.setState(() => ({currentIssueset: issueset}));
    }
  };

  render() {
    return (
      <div style={styles.navigationBarContainer}>
        <div style={styles.leftBar}>
          {elements.map((element, index) => (
            <Tooltip
              key={index}
              tooltipContent={element.title}
              tooltipContainerStyle={{
                left: "3rem",
              }}
              tooltipContentStyle={{
                fontSize: "12px",
                margin: "0 0.3rem",
              }}
            >
              <IconButton
                iconStyle={{
                  ...styles.iconStyle,
                  ...(this.state.currentTab === element.title
                    ? {
                        color: colors.blue600,
                      }
                    : {}),
                }}
                onClick={this.setCurrentTab(element.title)}
                style={{
                  ...styles.iconContainer,
                  ...(this.state.currentTab === element.title
                    ? {
                        backgroundColor: colors.grey300,
                        borderRadius: "4px",
                      }
                    : {}),
                }}
              >
                {element.component}
              </IconButton>
            </Tooltip>
          ))}
        </div>
        {this.state.isLeftHandMenuOpen && (
          <div style={{display: "flex"}}>
            {this.renderLeftPanel()}
            {this.renderRightPanel()}
          </div>
        )}
        <ResizeLeftPanelButton
          triggerTabShow={this.triggerTabShow}
          isTabShown={this.state.isLeftHandMenuOpen}
          setAndShowFirstTab={this.setAndShowFirstTab}
          currentTab={this.state.currentTab}
        />
      </div>
    );
  }

  renderLeftPanel = () => {
    switch (this.state.currentTab) {
      case "Checklist": {
        const {panelData} = this.state;
        const issueDetailData =
          panelData && panelData.type === "issue_detail" && panelData.data;
        const {
          issues,
          groupedIssuesWithSubissues,
          currentIssuesetItem,
        } = this.getIssuesData();
        const project = this.getProjectBySelectedProject();
        return (
          <>
            <ChecklistPanel
              organisationId={this.props.organisationId}
              issue={this.props.issue}
              projects={this.props.projects}
              project={this.getProjectBySelectedProject()}
              organisation={this.props.organisation}
              documents={this.props.documents}
              user={this.props.user}
              document={this.getDocumentBySelectedDocument()}
              issues={issues}
              documentIssues={this.props.documentIssues}
              groupedIssues={groupedIssuesWithSubissues}
              documentClauses={this.props.documentClauses}
              documentSections={this.props.documentSections}
              topicsById={this.props.topicsById}
              contractTypesById={this.props.contractTypesById}
              issuesById={this.props.issuesById}
              isDocumentSelectorOpen={this.state.isDocumentSelectorOpen}
              triggerDocumentSelectorOpen={this.triggerDocumentSelectorOpen}
              onProjectChange={this.onProjectSelect}
              selectedProject={this.state.selectedProject}
              selectedDocument={this.state.selectedDocument}
              onDocumentChange={this.onDocumentSelect}
              unselectProjectAndDocument={this.unselectProjectAndDocument}
              unselectDocument={this.unselectDocument}
              areIssuesCollapsed={this.state.areIssuesCollapsed}
              collapseIssues={this.collapseIssues}
              isIssueCorrectnessPanelShown={
                this.state.isIssueCorrectnessPanelShown
              }
              showIssueCorrectnessPanel={this.showIssueCorrectnessPanel}
              viewMode={this.state.viewMode}
              onViewModeChange={this.onViewModeChange}
              currentIssueset={this.state.currentIssueset}
              currentIssuesetItem={currentIssuesetItem}
              onCurrentIssuesetChange={this.onCurrentIssuesetChange}
              showIssueDetail={this.showIssueDetail}
              issueDetailData={issueDetailData}
              updateIssueDetail={this.updateIssueDetail}
              location={this.props.location}
              pushLocationSearchParams={this.props.pushLocationSearchParams}
              updateDocumentIssue={this.props.updateDocumentIssue}
              onIssueCheckboxCheck={onIssueCheckboxCheck}
              showIssue={this.showIssue}
              updateDocumentIssues={this.updateDocumentIssues}
              reprocessDocumentIssue={this.reprocessDocumentIssue}
              isDocumentFetchRequestPending={
                this.state.isDocumentFetchRequestPending
              }
              editLevel={this.props.editLevel}
              showDisplayName={this.state.showDisplayName}
              showArchivedIssues={this.state.showArchivedIssues}
              switchShowDisplayName={this.switchShowDisplayName}
              switchShowArchivedIssues={this.switchShowArchivedIssues}
              displayHiddenIssues={this.props.displayHiddenIssues}
              showAddIssueDialog={this.showAddIssueDialog(true)}
              documentClauseparts={this.props.documentClauseparts}
              selectedReport={getReportType(project)}
              correctDocumentIssueManually={this.correctDocumentIssueManually}
              findIssueJobsAmount={this.state.findIssueJobsAmount}
              classifyTopicparameterJobsAmount={
                this.state.classifyTopicparameterJobsAmount
              }
              updateFindIssueJobsAmount={this.updateFindIssueJobsAmount}
              onFetchDocument={this.onFetchDocument}
              selectedChecklistSectionId={this.state.selectedChecklistSectionId}
              onSelectedChecklistSectionIdChange={
                this.onSelectedChecklistSectionIdChange
              }
            />
            {this.renderAddIssueDialog()}
          </>
        );
      }
      default:
        return null;
    }
  };

  renderAddIssueDialog = () => {
    const issues = Object.values(this.props.issuesById).filter(
      ({is_archived}) => !is_archived,
    );
    return (
      <Dialog
        onClose={this.showAddIssueDialog(false)}
        aria-labelledby="add-issue-dialog"
        open={this.state.shouldShowAddIssueDialog}
      >
        <DialogTitle id="add-issue-dialog">Add issue to checklist</DialogTitle>
        <Autocomplete
          id="add-issue-dialog-select"
          options={_.sortBy(issues, "name")}
          getOptionLabel={issue => `${issue.name} (${issue.id})`}
          value={this.state.issueToAdd}
          onChange={this.handleAddIssue}
          style={styles.addIssueDialog.select}
          filterOptions={filterOptions}
          renderInput={params => (
            <TextField
              {...params}
              style={{width: "100%"}}
              label="Issue"
              variant="outlined"
              autoFocus={true}
            />
          )}
        />
        <div style={styles.addIssueDialog.buttons}>
          <Button
            variant="contained"
            color="primary"
            style={styles.addIssueDialog.button}
            onClick={this.addIssue}
          >
            Add
          </Button>
          <Button
            variant="contained"
            style={styles.addIssueDialog.button}
            onClick={this.showAddIssueDialog(false)}
          >
            Cancel
          </Button>
        </div>
      </Dialog>
    );
  };

  handleAddIssue = (event, issueToAdd) => {
    this.setState({issueToAdd});
  };

  addIssue = () => {
    const {props, state} = this;
    const {issueToAdd} = state;
    if (issueToAdd) {
      this.setState(
        () => ({issueToAdd: null, shouldShowAddIssueDialog: false}),
        async () => {
          await props.addIssuesetToIssue(
            state.selectedProject,
            state.currentIssueset,
            issueToAdd.id,
            state.selectedDocument,
          );
          await this.showIssue(issueToAdd);
        },
      );
    }
  };

  showIssue = issue => {
    if (this.state.panelData && this.state.panelData.type === "issue_detail") {
      const panelData = this.constructIssueDetailPanelData(issue);
      this.setState(
        () => ({panelData}),
        () => this.props.showIssue(issue),
      );
    }
    this.props.showIssue(issue);
  };

  renderRightPanel = () => {
    const {panelData, isDocumentFetchRequestPending} = this.state;
    if (!panelData || isDocumentFetchRequestPending) {
      return null;
    }
    const project = this.getProjectBySelectedProject();
    const {currentIssuesetItem} = this.getIssuesData();

    const defaultIssuesetId = getDefaultIssuesetId(
      this.getDocumentBySelectedDocument(),
      project,
      this.props.contractTypesById,
    );
    switch (panelData.type) {
      case "issue_detail": {
        if (!this.props.document) {
          return null;
        }
        return (
          <IssueDetail
            project={project}
            updateIssueDetail={this.updateIssueDetail}
            clearPanelData={this.clearPanelData}
            onIssueCheckboxCheck={() => null}
            isIssueCorrectnessPanelShown={
              this.state.isIssueCorrectnessPanelShown
            }
            currentIssueset={this.state.currentIssueset}
            currentIssuesetItem={currentIssuesetItem}
            selectedReport={getReportType(project)}
            defaultIssuesetId={defaultIssuesetId}
            {...{
              ...this.props,
              ...panelData.data,
            }}
            correctDocumentIssueManually={this.correctDocumentIssueManually}
            hideDocumentRelatedItems={true}
            style={{
              width: "26rem",
              flexShrink: 0,
            }}
            verifyIssues={project && project.verify_issues}
            setAddClausesToReportDocumentIssueId={
              this.setAddClausesToReportDocumentIssueId
            }
            force_show_how_issue_works={true}
            preventMemo
            fileIndex={0}
            fileNames={this.props.document.concat_filenames}
          />
        );
      }
      default:
        return null;
    }
  };
  setAddClausesToReportDocumentIssueId = () => {};

  setCurrentTab = tabName => () => {
    if (this.state.currentTab === tabName) {
      return this.hideCurrentTab();
    }
    return this.setState(() => ({
      currentTab: tabName,
      isLeftHandMenuOpen: true,
    }));
  };

  setAndShowFirstTab = () => {
    if (!this.state.currentTab) {
      this.setState(() => ({
        isLeftHandMenuOpen: true,
        currentTab: "Checklist",
      }));
    }
  };

  hideCurrentTab = () =>
    this.setState(() => ({
      currentTab: null,
      isLeftHandMenuOpen: false,
    }));

  triggerTabShow = () => {
    this.setState(prevState => ({
      isLeftHandMenuOpen: !prevState.isLeftHandMenuOpen,
    }));
  };

  onCurrentIssuesetChange = (event, index, currentIssueset) => {
    const paramIssueset = this.props.issuesetsById[currentIssueset];
    const hasSingleTemplateValue = hasSingleTemplate(this.props.issue);
    const newEditLevel =
      paramIssueset && paramIssueset.is_duplicate_on_master
        ? "client"
        : hasSingleTemplateValue
        ? "base"
        : "template";
    this.setState(() => ({currentIssueset}));
    if (newEditLevel !== this.props.editLevel) {
      this.props.pushLocationSearchParams({edit_level: newEditLevel});
    }
  };

  onViewModeChange = (event, index, viewMode) => {
    const project = this.props.projects.find(
      project => project.id === this.state.selectedProject,
    );
    const defaultIssuesetId = getDefaultIssuesetId(
      this.getDocumentBySelectedDocument(),
      project,
      this.props.contractTypesById,
    );
    // get_issuesets_from_contract_types determines what issuesets we show in
    // checklist issueset selector:
    // true - all issuesets from project's default contract type
    // false/undefined - selected in project's issuesets (if not blank)
    const applicableIssuesetIds = getApplicableIssuesetIds(
      this.props.document,
      project,
      this.props.contractTypesById,
      {
        get_issuesets_from_contract_types: true,
      },
    );
    const newStateItems = getStateOnViewModeChange(
      viewMode,
      applicableIssuesetIds,
      project.document_type_id,
      this.state.currentIssueset,
      defaultIssuesetId,
      this.props.contractTypesById,
    );
    this.setState(() => newStateItems);
  };

  collapseIssues = () => {
    const areIssuesCollapsed = !this.state.areIssuesCollapsed;
    localStorage.setItem("areIssuesCollapsed", areIssuesCollapsed);
    this.setState(() => ({areIssuesCollapsed}));
  };

  showIssueCorrectnessPanel = () => {
    const isIssueCorrectnessPanelShown = !this.state
      .isIssueCorrectnessPanelShown;
    localStorage.setItem(
      "isIssueCorrectnessPanelShown",
      isIssueCorrectnessPanelShown,
    );
    this.setState(() => ({isIssueCorrectnessPanelShown}));
  };

  triggerDocumentSelectorOpen = () =>
    this.setState(prevState => ({
      isDocumentSelectorOpen: !prevState.isDocumentSelectorOpen,
    }));

  onProjectSelect = async project => {
    const {selectedDocument: selectedDocumentId} = this.state;
    const {id: selectedProjectId} = project;
    await this.setState(() => ({
      selectedProject: selectedProjectId,
      selectedDocument: null,
      panelData: null,
      // currentIssueset: null, // this keeps selected issueset when project changes
    }));
    if (selectedDocumentId) {
      await this.props.clearDocument();
    }
    await this.props.clearDocuments();
    return this.props.fetchDocuments(selectedProjectId);
  };

  onDocumentSelect = document => {
    const {selectedProject: selectedProjectId} = this.state;
    const {id: selectedDocumentId} = document;
    this.setState(
      () => ({
        selectedDocument: selectedDocumentId,
        isDocumentFetchRequestPending: true,
      }),
      () => this.onFetchDocument(selectedProjectId, selectedDocumentId),
    );
  };

  onFetchDocument = async (selectedProject, selectedDocument) => {
    if (!selectedProject || !selectedDocument) {
      return;
    }
    await this.props.clearDocument();
    this.setState(
      () => ({isDocumentFetchRequestPending: true}),
      () => this.props.fetchDocument(selectedProject, selectedDocument),
    );
  };

  updateFindIssueJobsAmount = async () => {
    const {props, state} = this;
    if (_.isFunction(props.getFindIssueJobsAmount) && state.selectedDocument) {
      const {
        findIssueJobsAmount,
        classifyTopicparameterJobsAmount,
      } = await props.getFindIssueJobsAmount(
        state.selectedProject,
        state.selectedDocument,
        props.issue.id,
      );
      if (
        !_.isEqual(findIssueJobsAmount, state.findIssueJobsAmount) ||
        !_.isEqual(
          classifyTopicparameterJobsAmount,
          state.classifyTopicparameterJobsAmount,
        )
      ) {
        this.setState(() => ({
          findIssueJobsAmount,
          classifyTopicparameterJobsAmount,
        }));
      }
    }
  };

  unselectProjectAndDocument = () => {
    this.setState(
      () => ({
        selectedProject: null,
        selectedDocument: null,
        panelData: null,
        currentIssueset: null,
      }),
      () => this.props.clearDocument(),
    );
  };

  unselectDocument = () => {
    this.setState(
      () => ({
        selectedDocument: null,
        panelData: null,
        currentIssueset: null,
      }),
      () => this.props.clearDocument(),
    );
  };

  updateDocumentIssues = () => {
    if (!this.state.selectedProject || !this.state.selectedDocument) {
      return;
    }
    this.props.updateDocumentIssues(
      this.state.selectedProject,
      this.state.selectedDocument,
    );
  };
  reprocessDocumentIssue = () => {
    if (!this.state.selectedProject || !this.state.selectedDocument) {
      return;
    }
    this.props.reprocessDocumentIssue(
      this.state.selectedProject,
      this.state.selectedDocument,
    );
  };

  showIssueDetail = issue => {
    const panelData = this.constructIssueDetailPanelData(issue);
    this.setState(() => ({panelData}));
  };

  constructIssueDetailPanelData = issue => {
    if (!this.props.documentClauses) {
      return null;
    }
    const data = constructIssueDetailData(
      issue,
      this.state.currentIssueset,
      this.props.documentClauses,
      this.props.topicCategories,
      this.props.documentSections,
      this.props.topicsById,
      this.props.positiveReasonData,
      this.props.documentClauseparts,
      this.props.document.parties,
      this.state.selectedChecklistSectionId,
      true,
    );
    data.issue = {
      ...data.issue,
      document_id: this.state.selectedDocument,
      project_id: this.state.selectedProject,
    };
    if (data) {
      return {
        type: "issue_detail",
        data,
      };
    }
    return null;
  };

  updateIssueDetail = issue => {
    const {panelData} = this.state;
    if (panelData && panelData.type === "issue_detail") {
      this.showIssueDetail(issue);
    }
  };

  clearPanelData = () => this.setState(() => ({panelData: null}));

  getProjectBySelectedProject = () => {
    return this.props.projects.find(
      project => project.id === this.state.selectedProject,
    );
  };

  getDocumentBySelectedDocument = () => {
    const dateFields = [
      "classification_start",
      "clausepart_last_edited",
      "issues_last_edited",
      "last_edited",
    ];
    const document = this.props.document || {};
    const documentsDocument =
      byId(this.props.documents)[this.state.selectedDocument] || {};
    const latestDates = dateFields.reduce((accum, field) => {
      accum[field] = takeLatest(document[field], documentsDocument[field]);
      return accum;
    }, {});

    return {
      ...this.props.document,
      ...documentsDocument,
      ...latestDates,
    };
  };

  getIssuesData = () => {
    const project = this.getProjectBySelectedProject();
    const document = this.getDocumentBySelectedDocument();
    if (!project || !document) {
      return {issues: [], groupedIssuesWithSubissues: {}};
    }
    const applicableIssuesetIds = getApplicableIssuesetIds(
      document,
      project,
      this.props.contractTypesById,
      {
        get_issuesets_from_contract_types: true,
      },
    );
    const currentIssuesetItem = getCurrentIssuesetItem(
      this.state.currentIssueset,
      document,
      this.props.contractTypesById,
    );
    const useMasterName = !currentIssuesetItem;
    const issues = getIssues(
      applicableIssuesetIds,
      this.props.documentIssues || [],
      currentIssuesetItem,
      this.state.areIssuesCollapsed,
      useMasterName,
      this.props.displayHiddenIssues,
      this.state.showArchivedIssues,
      false, // dontFilterIssuesByIssuesets
      this.state.selectedChecklistSectionId,
      true,
    ).map(issue => {
      const name =
        this.state.showDisplayName && issue.display_name
          ? issue.display_name
          : issue.true_name;
      return {
        ...issue,
        document_issue_last_edited: issue.last_edited,
        project_id: project.id,
        document_id: document.id,
        name: this.state.areIssuesCollapsed
          ? name.replace(/\[.*\]$/, "")
          : name,
      };
    });

    const groupedIssues = groupIssuesForChecklist(issues);
    const groupedIssuesWithSubissues = addSubissueDataToGroupedIssues(
      groupedIssues,
      true,
    );
    return {issues, groupedIssuesWithSubissues, currentIssuesetItem};
  };

  switchShowDisplayName = () =>
    this.setState(prevState => ({
      showDisplayName: !prevState.showDisplayName,
    }));

  switchShowArchivedIssues = () =>
    this.setState(prevState => ({
      showArchivedIssues: !prevState.showArchivedIssues,
    }));

  isLeftHandMenuOpen = () => this.state.isLeftHandMenuOpen;

  getIssueGroups = () => this.getIssuesData().groupedIssuesWithSubissues;

  isOpened = () => this.state.isLeftHandMenuOpen;

  showAddIssueDialog = _.memoize(visible => () =>
    this.setState({shouldShowAddIssueDialog: visible}),
  );

  onSelectedChecklistSectionIdChange = (
    event,
    index,
    selectedChecklistSectionId,
  ) => this.setState(() => ({selectedChecklistSectionId}));
}

function getReportType(project) {
  const isRagPresent = ((project && project.report_settings) || []).find(
    report => report.type === "rag_report",
  );
  return isRagPresent ? "rag_report" : "email";
}

function takeLatest(date1, date2) {
  if (!date1 && date2) {
    return date2;
  } else if (date1 && !date2) {
    return date1;
  } else if (!date1 && !date2) {
    return "";
  }
  const date1Obj = new Date(date1);
  const date2Obj = new Date(date2);

  return date1Obj > date2Obj ? date1 : date2;
}

export default LeftHandMenu;
