import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import LlmRunDetailComponent from "../components/llm_run_detail";

import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";
import {ActionDefinitionWithType} from "common/flowmaster/types/task_config";
import {Prompt} from "common/flowmaster/types/workflow";
import {User} from "common/types/user";
import {OrganisationId} from "common/types/organisation";

type Props = {
  llmRun: EnhancedLlmRun;
  actionDefinitions: ActionDefinitionWithType[];
  prompts: Prompt[];
  user: User;
  organisation: {id: OrganisationId};
  params: {
    organisationId: string;
  };
};

function LlmRunDetailContainer(props: Props) {
  const shouldRenderContainer = isInitialised([
    props.llmRun,
    props.actionDefinitions,
    props.prompts,
    props.user,
  ]);

  const [showInternalActions, setShowInternalActions] = useState(false);
  const [showActionDetail, setShowActionDetail] = useState(false);
  useEffect(() => {
    setShowInternalActions(props.user.is_developer);
    setShowActionDetail(props.user.is_developer);
  }, [props.user]);

  const handleToggleShowInternalActions = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowInternalActions(event.target.checked);
  };
  const handleToggleShowActionDetail = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowActionDetail(event.target.checked);
  };

  if (!shouldRenderContainer || !props.user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const permissioner = new Permissioner(props.user);
  if (
    !(permissioner.hasPermission("get-workflows") || permissioner.isAdmin())
  ) {
    return permissioner.getNoPermissionMessage();
  }

  setTitle(`LLM Run Detail: ${props.llmRun.data.id}`);
  return (
    <LlmRunDetailComponent
      organisationId={props.organisation.id}
      llmRun={props.llmRun}
      actionDefinitions={props.actionDefinitions}
      showInternalActions={showInternalActions}
      onInternalActionsToggle={handleToggleShowInternalActions}
      showActionDetail={showActionDetail}
      onShowActionDetailToggle={handleToggleShowActionDetail}
      prompts={props.prompts}
    />
  );
}

function select(state, props) {
  return {
    params: props.params,
    organisation: state.organisation,
    router: state.router,
    user: state.user,
    llmRun: state.llmRun,
    actionDefinitions: state.actionDefinitions,
    prompts: state.prompts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch),
  };
}

export default connect(select, mapDispatchToProps)(LlmRunDetailContainer);
