const fieldData = [
  {
    key: "clause_reference",
    name: "Clause Reference",
    type: "string",
    defaultValue: "",
    header: true,
  },
  {
    key: "executed_authorised_under",
    name: "Executed Authorised Under",
    type: "taskref",
    defaultValue: -1,
  },
  {
    key: "required_form",
    name: "Required Form",
    type: "enum",
    options: ["draft", "agreed form", "executed"],
  },
  {
    key: "signing_requirements",
    name: "Signing Requirements",
    type: "enum",
    options: [
      "signed but undated",
      "signed and dated",
      "signed, dated and notarised",
      "signed, dated, notarised and apostilled",
    ],
  },
  {
    key: "originality",
    name: "Originality",
    type: "enum",
    options: ["original", "certified copy", "copy"],
  },
  {
    key: "physicality",
    name: "Physicality",
    type: "enum",
    options: ["physical", "electronic"],
  },
  {
    key: "number_required",
    name: "Number required",
    type: "int",
    min: 1,
    defaultValue: 1,
  },
];

export default fieldData;
