import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function updateOrganisation(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.ORGANISATION_USER_UPDATE,
    (organisationId, userId, organisationUserUpdates) => {
      return requestor
        .put(
          `${basePath}/organisation/${organisationId}/config/users/${userId}`,
          organisationUserUpdates,
          {withCredentials: true},
        )
        .then(response => ({
          ...response.data,
          ...organisationUserUpdates,
          organisationId,
        }))
        .catch(err => err.data);
    },
  );
  return action;
}
