import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";

import TextField from "material-ui/TextField";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";

export class EnumField extends React.Component {
  render() {
    const {field} = this.props;
    const options = field.options.map(option => (
      <MenuItem value={option} key={option} primaryText={option} />
    ));
    return (
      <SelectField
        className="form-control"
        floatingLabelText={field.name}
        value={this.props.value || field.options[0]}
        style={{
          display: "block",
          width: "100%",
        }}
        onChange={this.handleOnChange}
      >
        {options}
      </SelectField>
    );
  }

  /* eslint-disable no-invalid-this */
  handleOnChange = (event, key, value) => {
    this.props.onChangeCustomValue(this.props.field.key, value);
  };
  /* eslint-enable no-invalid-this */
}

export class IntField extends React.Component {
  render() {
    const {value: existingValue, field} = this.props;
    const value =
      existingValue !== undefined ? existingValue : field.defaultValue;
    return (
      <TextField
        type="number"
        className="form-control"
        floatingLabelText={field.name}
        value={value}
        style={{
          display: "block",
          width: "100%",
        }}
        onChange={this.handleOnChange}
      />
    );
  }

  /* eslint-disable no-invalid-this */
  handleOnChange = event => {
    this.props.onChangeCustomValue(
      this.props.field.key,
      parseInt(event.target.value, 10),
    );
  };
  /* eslint-enable no-invalid-this */
}

export class StringField extends React.Component {
  render() {
    const {value: existingValue, field} = this.props;
    const value =
      existingValue !== undefined ? existingValue : field.defaultValue;
    return (
      <TextField
        type="text"
        floatingLabelText={field.name}
        className="form-control"
        value={value}
        style={{
          display: "block",
          width: "100%",
        }}
        onChange={this.handleOnChange}
      />
    );
  }

  /* eslint-disable no-invalid-this */
  handleOnChange = event => {
    this.props.onChangeCustomValue(this.props.field.key, event.target.value);
  };
  /* eslint-enable no-invalid-this */
}

export class TaskRefField extends React.Component {
  render() {
    const options = [
      <MenuItem value={-1} key={-1} primaryText="None" />,
    ].concat(
      _.chain(this.props.tasks)
        .filter(task => task.id !== this.props.taskId)
        .map(task => (
          <MenuItem value={task.id} key={task.id} primaryText={task.name} />
        ))
        .value(),
    );
    return (
      <SelectField
        className="form-control"
        floatingLabelText={this.props.field.name}
        value={this.props.value || -1}
        style={{
          display: "block",
          width: "100%",
        }}
        onChange={this.handleOnChange}
      >
        {options}
      </SelectField>
    );
  }

  /* eslint-disable no-invalid-this */
  handleOnChange = (event, key, value) => {
    this.props.onChangeCustomValue(this.props.field.key, parseInt(value, 10));
  };
  /* eslint-enable no-invalid-this */
}

export class CustomValueField extends React.Component {
  render() {
    const ValueSelector = this.props.valueSelector;
    return (
      <div style={{margin: "1em 0"}}>
        <ValueSelector className="value-selector" {...this.props} />
      </div>
    );
  }
}

class CustomValueSelector extends React.Component {
  render() {
    return <div className="selectors">{this.renderSelectors()}</div>;
  }

  renderSelectors() {
    return _.chain(this.props.customValueFields)
      .filter(field =>
        _.all(
          this.props.filters,
          (filterValue, filterName) =>
            field[filterName] === filterValue ||
            Boolean(field[filterName]) === filterValue,
        ),
      )
      .map((field, index) => this.renderSelector(field, index))
      .value();
  }

  renderSelector(field, counter) {
    const props = {
      ...this.props,
      key: field.key,
      value: this.props.customValues[field.key],
      fieldId: `custom-field-${counter + 1}`,
      field,
    };
    props.valueSelector = this.renderValueSelector(field, props);
    return <CustomValueField {...props} className={`selector ${field.key}`} />;
  }

  renderValueSelector(field) {
    switch (field.type) {
      case "enum":
        return EnumField;
      case "int":
        return IntField;
      case "string":
        return StringField;
      case "taskref":
        return TaskRefField;
    }
    return StringField;
  }
}

CustomValueSelector.propTypes = {
  customValueFields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  customValues: PropTypes.object,
  filters: PropTypes.object,
  taskId: PropTypes.number.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onChangeCustomValue: PropTypes.func.isRequired,
};

export default CustomValueSelector;
