import React, {useState} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Select from "react-select";

import keyedObjectPropType from "utils/keyed_object_prop_type";
import usePrevious from "utils/hooks/use_previous";

import IssueDetailTabs from "./issue_detail_tabs";
import LeftHandMenu from "./left_hand_menu";
import Display from "./display_settings";
import IssueUsage from "./issue_usage";
import IssueDetailOverrides from "./issue_detail_overrides";
import IssueDetailLogs from "./issue_detail_logs";
import ParameterFilters from "./parameter_filters";
import InternalNotes from "./internal_notes";
import hasSingleTemplate from "utils/issues/has_single_template";
import Permissioner from "utils/permissioner";

import Paper from "material-ui/Paper";
import Snackbar from "material-ui/Snackbar";
import OldTextField from "material-ui/TextField";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import {Toolbar, ToolbarGroup} from "material-ui/Toolbar";
import IconMenu from "material-ui/IconMenu";
import IconButton from "material-ui/IconButton";
import MoreIcon from "material-ui/svg-icons/navigation/more-vert";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import * as colors from "material-ui/styles/colors";
import {ListItem} from "material-ui/List";
import Checkbox from "material-ui/Checkbox";
import Chip from "material-ui/Chip";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Checkbox as ChecboxV4} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import ClearIcon from "material-ui/svg-icons/content/clear";
import DoneIcon from "material-ui/svg-icons/action/done";

import {IssueIcon, NoIssueIcon} from "constants/icons";
import IssuesetInfoWidget from "common_components/issueset_info_widget";
import ReactSelectLabel from "common_components/react_select_label";
import IssueEditor from "common_components/issue_editor";
import IssueRelatedSearches from "./issue_related_searches";
import ParentSettingsPanel from "./parent_settings_panel";
import ChildSettingsPanel from "./child_settings_panel";

import getErrorIfHasMismatchedCurlyBraces from "utils/get_error_if_has_mismatched_curly_braces";
import getPositiveReasonData from "common/utils/issues/reason/get_positive_reason_data";
import logger from "common/utils/logger";
import setNestedObjects from "common/utils/setNestedObjects";
import deleteNestedObjectsIfEmpty from "common/utils/deleteNestedObjectsIfEmpty";
import getClauseAtoms from "common/utils/clauses/get_clause_atoms";

import OverridableIssueFields from "../../../utils/overridable_issue_fields";
import getIdsErrorText from "./util/get_ids_error_text";
import createIssuesetOverrideData from "utils/issues/create_issueset_override_data";
import getRelatedIssues from "routes/issue_detail/components/util/get_related_issues";
import {UpdateIssueData} from "modules/documents/types";
import {Issueset} from "common/types/issueset";
import Editor from "common_components/editor";
import ContractTypes from "common_components/project/contract_types";

const allowBulkUpdate = false;
const greenColor = "green";
const redColor = "#C40233";

const styles = {
  rootDiv: {
    display: "flex",
    justifyContent: "flex-start",
    flexGrow: 1,
    overflow: "hidden",
    height: "100%",
  },
  optionControl: {
    width: "16rem",
    zIndex: "0",
  },
  cardHeader: {
    height: "2.5rem",
    lineHeight: "2.4rem",
    paddingLeft: "2rem",
    background: colors.yellow600,
    fontSize: "17px",
    fontWeight: 500,
  },
  correction: {
    div: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "baseline",
      margin: "1rem 2rem 1rem 5.5rem",
    },
    textField: {
      width: "100%",
      marginBottom: "1rem",
    },
  },
  levelSelectorItem: {
    fontSize: "10px",
  },

  getEditLevelBackgroundStyles: (editLevel, isDoNotSync) => {
    const result = {};
    if (isDoNotSync) {
      result.paddingBottom = "1em";
      result.backgroundColor = "#8d84e8";
      return result;
    }
    if (editLevel) {
      result.paddingBottom = "1em";
      switch (editLevel) {
        case "base":
          result.backgroundColor = "#fff0d9";
          break;
        case "client":
          result.backgroundColor = "#d9ffe1";
          break;
        default:
          break;
      }
    }
    return result;
  },
  optionsFieldset: {
    marginLeft: "4rem",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
    paddingRight: "10px",
    width: "40%",
  },
  optionsFieldsetLegend: {
    color: "rgba(0, 0, 0, 0.7)",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
  optionsFieldsetLegendIconBase: {
    marginRight: "2px",
    width: "16px",
    height: "16px",
  },
  updateDisplayLogicButton: {
    display: "flex",
    marginRight: "4rem",
    marginLeft: "auto",
  },
};

const templateVariables = [
  {
    key: "reason_template",
    name: "Summary: Rule fired (can use issue.reason)",
    negative: true,
    markdownEnabled: true,
  },
  {
    key: "detailed_reason",
    name: "Detail: Rule fired (can use issue.reason)",
    negative: true,
    markdownEnabled: true,
  },
  {
    key: "positive_reason",
    name: "Summary: Rule not fired (cannot use issue.reason)",
    positive: true,
    markdownEnabled: true,
  },
  {
    key: "detailed_positive_reason",
    name: "Detail: Rule not fired (cannot use issue.reason)",
    positive: true,
    markdownEnabled: true,
  },
  {
    key: "about_description",
    name: "Checklist description",
    markdownEnabled: true,
  },
  {key: "email_template", name: "Email template", markdownEnabled: false},
  {key: "standard_language", name: "Standard language", markdownEnabled: true},
  {key: "description", name: "Report description", markdownEnabled: false},
  {
    key: "guidance",
    name: "Guidance: Rule fired (can use issue.reason)",
    negative: true,
    markdownEnabled: true,
  },
  {
    key: "positive_guidance",
    name: "Guidance: Rule not fired (cannot use issue.reason)",
    positive: true,
    markdownEnabled: true,
  },
  {
    key: "required_change",
    name: "Required Change",
    markdownEnabled: false,
  },
  {
    key: "required_change_not_triggered",
    name: "Required Change (Not Triggered)",
    markdownEnabled: false,
  },
  {
    key: "fallback_guidance",
    name: "Fallback & Escalation",
    markdownEnabled: true,
  },
  {
    key: "checklist_definitions",
    name: "Checklist Definitions",
    markdownEnabled: true,
  },
  {
    key: "common_definitions",
    name: "Common Definitions",
    markdownEnabled: false,
  },
  {
    key: "llm_prompt",
    name: "LLM Prompt",
    markdownEnabled: false,
  },
];

function filterTopicsByIssueContractTypes(issue, topics) {
  if (!issue || !issue.contract_types || !topics) {
    return [];
  }
  const issueContractTypeIds = issue.contract_types.map(
    ct => ct.contract_type_id,
  );
  return topics.filter(topic => {
    const topicContractTypeIds = (topic.contract_types || []).map(
      ct => ct.contract_type_id,
    );
    // issue contract types should be a subset of topic contract types
    return areIssueCtsInTopicCts(issueContractTypeIds, topicContractTypeIds);
  });
}

const classStyles = {
  templateEditorContainer: {
    width: "100%",
    marginLeft: "36px",
    marginRight: "36px",
  },
  templateEditor: {
    width: "100%",
    "&.notoverridden": {
      "& textarea": {
        color: colors.grey400,
      },
    },
    "& .MuiFormControl-root": {
      marginRight: "10px",
      width: "100%",
    },
    "& textarea": {
      // zIndex: 0,
      display: "block",
      width: "100%",
      flexGrow: 1,
    },
  },
};

function areIssueCtsInTopicCts(issueContractTypeIds, topicContractTypeIds) {
  return (
    _.intersection(issueContractTypeIds, topicContractTypeIds).length ===
    issueContractTypeIds.length
  );
}

function getClauseAtomsPerDocument(
  documentId,
  documentLastEdited,
  documentClauses,
) {
  return getClauseAtoms(documentClauses);
}

const getClauseAtomsMemo = _.memoize(
  getClauseAtomsPerDocument,
  (docId, docLastEdited) => `${docId}.${docLastEdited}`,
);

type Props = {
  onIssueUpdated: (data: UpdateIssueData) => void;
  onIssuesUpdated: (data: UpdateIssueData[]) => void;
};

class IssueDetail extends OverridableIssueFields<Props> {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteDialogue: false,
      nameError: "",
      selectedIssueset: null,
      overridableFields: this.getOverridableFields(),
      shouldUseProposedChanges: false,
      clampFields: true,
      proposedChanges: this.constructStateProposedChanges(),
      isIssueOverridesHidden: Boolean(
        JSON.parse(localStorage.getItem("isIssueOverridesHidden")),
      ),
      editLevel: "base",
      updateDisplayLogicDisabled: !props.issue.needs_display_logic_update,
    };
    this.permissioner = new Permissioner(this.props.user);
    this.renderOverridenIssuesets = this.renderOverridenIssuesets.bind(this);
    this.getOverridableFieldData = this.getOverridableFieldData.bind(this);
    this.prevValues = {};
  }

  getOverridableFields = () => {
    const {issue} = this.props;
    const issuesets = _.chain(issue.contract_types)
      .map(contractType => {
        return contractType.issuesets.map(({id: issuesetId}) => {
          return this.props.issuesetsById[issuesetId];
        });
      })
      .flatten()
      .value();
    const overridden = {};
    _.forEach(issue.override_values, (list, masterId) => {
      _.forEach(list, (values, key) => {
        const issueset = issuesets.find(_issueset => {
          if (_issueset.master_id === masterId) {
            if (key === "master") {
              if (!_issueset.is_duplicate_on_master) {
                return true;
              }
            } else if (parseInt(key, 10) === _issueset.remote_client_id) {
              return true;
            }
          }
          return false;
        });
        if (issueset) {
          if (!_.has(overridden, masterId)) {
            overridden[masterId] = {};
          }
          overridden[masterId][key] = values;
        }
      });
    });
    issuesets.forEach(issueset => {
      if (!_.has(overridden, issueset.master_id)) {
        overridden[issueset.master_id] = {};
      }
      const group = overridden[issueset.master_id];
      const clientId = issueset.remote_client_id || "master";
      if (!_.has(group, clientId)) {
        group[clientId] = {};
      }
      const hasValue = issueset.template_definitions ?? false;
      if (hasValue) {
        group[clientId].checklist_definitions = issueset.template_definitions;
      }
    });
    return {
      main: {
        ..._.chain(templateVariables)
          .map(item => [item.key, issue[item.key]])
          .fromPairs()
          .value(),
        ..._.pick(issue, [
          "display_name",
          "is_archived",
          "issue_class_id",
          "issue_order",
          "rules",
          "styling",
          "display_topics",
          "additional_applicable_clause_topics",
          "related_topics",
          "correction_settings",
          "open_subissues_when",
          "show_if_triggered_only",
          "hide_subissue_when_parent",
          "empty_parent_applicable_clauses",
          "related_searches",
          "non_triggered_display_topics",
          "non_triggered_additional_applicable_clause_topics",
          "non_triggered_related_topics",
          "parent_settings",
          "parameter_filters",
          "internal_notes",
          "prioritize_non_issue_mutex",
          "do_not_sync",
          "common_definitions",
          "llm_prompt",
          "use_parameter_filter_clauses",
        ]),
      },
      overridden,
    };
  };

  constructStateProposedChanges = _proposedChanges => {
    const proposedChanges =
      _proposedChanges || this.props.issue.proposed_changes || {};
    return {
      main: proposedChanges.main,
      overridden: _.omit(proposedChanges, ["main"]),
    };
  };

  constructDbProposedChanges = _stateProposedChanges => {
    const stateProposedChanges =
      _stateProposedChanges || this.state.proposedChanges;
    return {
      main: stateProposedChanges.main,
      ...stateProposedChanges.overridden,
    };
  };

  onOverridableIssueFieldUpdate(...args) {
    if (
      args[0] &&
      args[0].overrideValueData &&
      args[0].overrideValueData.fieldName === "checklist_definitions"
    ) {
      this.onIssuesetUpdate(args[0].overrideValueData.fieldValue);
    } else {
      this.onIssueUpdate(...args);
    }
  }

  componentDidMount() {
    this.applyLocationSearchParams();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.search !== this.props.location.search ||
      prevProps.issue.id !== this.props.issue.id ||
      !_.isEqual(
        prevProps.issue.contract_types,
        this.props.issue.contract_types,
      )
    ) {
      this.applyLocationSearchParams();
      this.setState(() => ({
        overridableFields: this.getOverridableFields(),
        proposedChanges: this.constructStateProposedChanges(),
      }));
    }
    const {issue: prevIssue} = prevProps;
    const {issue: currentIssue} = this.props;
    if (
      prevIssue &&
      currentIssue &&
      prevIssue.id &&
      currentIssue.id &&
      prevIssue.id !== currentIssue.id
    ) {
      this.issueNameRef.input.value = currentIssue.name;
      this.setState(() => ({
        overridableFields: this.getOverridableFields(),
        proposedChanges: this.constructStateProposedChanges(),
      }));
    }
    if (
      prevIssue &&
      currentIssue &&
      prevIssue.needs_display_logic_update &&
      currentIssue.needs_display_logic_update &&
      prevIssue.last_edited !== currentIssue.last_edited
    ) {
      this.setState(() => ({updateDisplayLogicDisabled: false}));
    }

    if (
      prevIssue &&
      currentIssue &&
      !prevIssue.needs_display_logic_update &&
      currentIssue.needs_display_logic_update
    ) {
      this.setState(() => ({updateDisplayLogicDisabled: false}));
    }
  }

  applyLocationSearchParams = () => {
    const {getLocationSearchParams, projects, contractTypesById} = this.props;
    const {
      project: projectId,
      issueset: issuesetId,
      edit_level: _editLevel,
    } = getLocationSearchParams();
    if (projectId && issuesetId) {
      const project = projects.find(({id}) => id === projectId);
      const defaultContract =
        contractTypesById[project.default_contract_type.id];
      const paramIssueset = defaultContract.issuesets.find(
        ({id}) => id === issuesetId,
      );
      if (!paramIssueset) {
        return this.setState(() => ({
          selectedIssueset: null,
          editLevel: "base",
        }));
      }
      const hasSingleTemplateValue = hasSingleTemplate(this.props.issue);
      let editLevel;
      if (
        _editLevel === "client" &&
        paramIssueset &&
        !paramIssueset.is_duplicate_on_master
      ) {
        editLevel = hasSingleTemplateValue ? "base" : "template";
      } else if (_editLevel) {
        editLevel = _editLevel;
      } else {
        editLevel =
          paramIssueset && paramIssueset.is_duplicate_on_master
            ? "client"
            : hasSingleTemplateValue
            ? "base"
            : "template";
      }
      const selectedIssueset =
        editLevel === "base"
          ? null
          : editLevel === "template"
          ? defaultContract.issuesets.find(
              is =>
                is.is_duplicate_on_master === false &&
                is.master_id === paramIssueset.master_id,
            )
          : paramIssueset;

      const actualIssueset =
        editLevel === "base" || editLevel === "template" ? paramIssueset : null;

      this.setState({
        editLevel,
        selectedIssueset,
        actualIssueset,
      });
    } else {
      this.setState({selectedIssueset: null, editLevel: "base"});
    }
  };

  render() {
    const {issue, documentClauses, document} = this.props;
    const {editLevel} = this.state;
    const isDuplicateOnMaster =
      _.get(this.state, "actualIssueset.is_duplicate_on_master", false) ||
      _.get(this.state, "selectedIssueset.is_duplicate_on_master", false);
    const issueset =
      this.state.actualIssueset || this.state.selectedIssueset || {};
    const documentClauseparts = getClauseAtomsMemo(
      _.get(document, "id", null),
      _.get(document, "last_edited", null),
      documentClauses || {},
    );
    const isInHotIssueset = issue.contract_types.find(ct =>
      ct.issuesets.find(is => is.is_hot),
    );
    return (
      <div style={styles.rootDiv}>
        <LeftHandMenu
          ref={this.createLeftHandMenuRef}
          issue={issue}
          projects={this.props.projects}
          organisation={this.props.organisation}
          fetchDocuments={this.props.fetchDocuments}
          documents={this.props.documents}
          fetchDocument={this.props.fetchDocument}
          clearDocument={this.props.clearDocument}
          clearDocuments={this.props.clearDocuments}
          user={this.props.user}
          organisationId={this.props.organisationId}
          document={this.props.document}
          documentIssues={this.constructDocumentIssues()}
          updateDocumentIssues={this.props.updateDocumentIssues}
          reprocessDocumentIssue={this.props.reprocessDocumentIssue}
          documentClauses={this.props.documentClauses}
          documentClauseparts={documentClauseparts}
          documentDefinitions={this.props.documentDefinitions}
          documentChanges={this.props.documentChanges}
          documentSections={this.props.documentSections}
          baseDocumentHeadings={this.props.documentHeadings}
          topicsById={this.props.topicsById}
          topicCategories={this.props.topicCategories}
          positiveReasonData={getPositiveReasonData(this.props.documentClauses)}
          contractTypesById={this.props.contractTypesById}
          issuesetsById={this.props.issuesetsById}
          issuesById={this.props.issuesById}
          location={this.props.location}
          getLocationSearchParams={this.props.getLocationSearchParams}
          pushLocationSearchParams={this.props.pushLocationSearchParams}
          updateDocumentIssue={this.updateDocumentIssue}
          correctDocumentIssueManually={this.props.correctDocumentIssueManually}
          generateDocumentIssueLlmPrompt={
            this.props.generateDocumentIssueLlmPrompt
          }
          fetchDocumentIssueConversation={
            this.props.fetchDocumentIssueConversation
          }
          clearDocumentIssueConversation={
            this.props.clearDocumentIssueConversation
          }
          showIssue={this.props.showIssue}
          editLevel={editLevel}
          displayHiddenIssues={true}
          addIssuesetToIssue={this.props.addIssuesetToIssue}
          searchByIssueResults={this.props.searchByIssueResults}
          searchByIssue={this.props.searchByIssue}
          searchByIssueClear={this.props.searchByIssueClear}
          getFindIssueJobsAmount={this.props.getFindIssueJobsAmount}
          overridableFields={this.state.overridableFields}
          proposedChanges={this.state.proposedChanges}
          definitionGroups={this.props.definitionGroups}
        />
        <div
          style={{
            overflow: "hidden",
            flexGrow: 1,
            display: "flex",
          }}
        >
          <Paper
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Toolbar className="app-toolbar">
              <ToolbarGroup key={0} style={{width: "100%"}}>
                {issue.is_archived && (
                  <Chip
                    style={{
                      background: "#fff",
                      marginRight: 10,
                    }}
                  >
                    Archived
                  </Chip>
                )}
                <div
                  style={{width: "100%", display: "flex", alignItems: "center"}}
                >
                  {this.renderInfoIconsPanel(isInHotIssueset)}
                  <OldTextField
                    type="text"
                    className="name"
                    defaultValue={issue.name}
                    onBlur={this.onNameUpdate}
                    errorText={
                      this.state.nameError ||
                      this.getFieldValue("display_name").value
                    }
                    errorStyle={this.state.nameError ? {} : {color: "#aaa"}}
                    style={{width: "100%", marginLeft: 8}}
                    name="topic-name"
                    disabled={editLevel !== "base"}
                    ref={this.createIssueNameRef}
                  />
                </div>
                <FlatButton
                  label={"Issues"}
                  style={{flexShrink: "0", margin: "0.5rem"}}
                  onClick={this.props.redirectToIssueList}
                />
                <SelectField
                  floatingLabelText={
                    <span style={{overflowY: "hidden", whiteSpace: "nowrap"}}>
                      {getSelectFieldLabelText(issueset)}
                    </span>
                  }
                  value={editLevel}
                  style={{
                    width: "15rem",
                    flexShrink: "0",
                    margin: "0.5rem",
                    display: !issue.contract_types.length && "none",
                  }}
                  onChange={this.editLevelChanged}
                  disabled={!issueset.name}
                >
                  <MenuItem value="base" primaryText="Base" />
                  {this.isSelectedChecklistIsAssignedToSelectedIssue(
                    issueset,
                    issue.contract_types,
                  ) && [
                    <MenuItem
                      key="template"
                      value="template"
                      primaryText="Template"
                      secondaryText={
                        <span style={styles.levelSelectorItem}>
                          {issueset.name}
                        </span>
                      }
                    />,
                    <MenuItem
                      key="client"
                      value="client"
                      primaryText="Client"
                      secondaryText={
                        <span style={styles.levelSelectorItem}>
                          {issueset.remote_client_name}
                        </span>
                      }
                    />,
                  ]}
                </SelectField>
                <IconMenu
                  iconButtonElement={
                    <IconButton>
                      <MoreIcon />
                    </IconButton>
                  }
                  iconStyle={{padding: 0}}
                  style={{width: "2rem"}}
                >
                  <MenuItem onClick={this.showDeleteDialogue}>Delete</MenuItem>
                  <MenuItem
                    disabled={editLevel !== "base"}
                    onClick={this.handleIssueDuplication}
                  >
                    Duplicate Issue
                  </MenuItem>
                  {!isDuplicateOnMaster && (
                    <ListItem
                      primaryText="Hide overrides"
                      leftCheckbox={
                        <Checkbox
                          checked={this.state.isIssueOverridesHidden}
                          onCheck={(event, value) => {
                            this.setState({isIssueOverridesHidden: value});
                            localStorage.setItem(
                              "isIssueOverridesHidden",
                              value,
                            );
                          }}
                        />
                      }
                    />
                  )}
                  <ListItem
                    primaryText="Is Archived"
                    leftCheckbox={
                      <Checkbox
                        disabled={isInHotIssueset && !issue.is_archived}
                        checked={issue.is_archived}
                        onCheck={(event, value) =>
                          this.onIssueUpdate({
                            is_archived: value,
                          })
                        }
                      />
                    }
                  />
                  <MenuItem onClick={this.props.onDocumentIssuesUpdateRun}>
                    Reprocess Document Issues
                  </MenuItem>
                </IconMenu>
              </ToolbarGroup>
            </Toolbar>
            <IssueDetailTabs
              generalTab={this.renderGeneralTab()}
              displayTab={this.renderDisplayTab()}
              usageTab={this.renderUsageTab()}
              overridesTab={this.renderOverridesTab()}
              logsTab={this.renderLogsTab()}
              location={this.props.location}
            />
          </Paper>
        </div>
        <Snackbar
          open={Boolean(issue.reprocessingComplete)}
          autoHideDuration={4000}
          message="Reprocessing Complete"
          contentStyle={{display: "flex", justifyContent: "center"}}
        />
      </div>
    );
  }

  isSelectedChecklistIsAssignedToSelectedIssue(
    selectedIssueset: Issueset,
    issueContractTypes: ContractTypes[],
  ) {
    return issueContractTypes.some(contractType =>
      contractType.issuesets.some(
        issueset => issueset.id === selectedIssueset.id,
      ),
    );
  }

  renderInfoIconsPanel = isInHotIssueset => {
    const {issue} = this.props;

    const isInStarIssueset = issue.contract_types.find(ct =>
      ct.issuesets.find(is => is.is_star),
    );
    return (
      <IssuesetInfoWidget
        isInStarIssueset={isInStarIssueset}
        isStarIssue={issue.is_star}
        isHot={isInHotIssueset}
        updateIssueIsStar={this.updateIssueIsStar}
      />
    );
  };

  renderGeneralTab = () => {
    const {issue, classes, projects, getLocationSearchParams} = this.props;
    const overriddenIssuesetNames = this.constructOverriddenIssuesetNames();
    const {editLevel, shouldUseProposedChanges, clampFields} = this.state;
    const canEdit =
      (this.permissioner.hasPermission("edit-base") && editLevel === "base") ||
      this.permissioner.isAdmin() ||
      (editLevel !== "base" &&
        this.permissioner.hasPermission("can-edit-issue-override-rules")) ||
      hasSingleTemplate(issue);

    const applicableTopics = filterTopicsByIssueContractTypes(
      issue,
      this.props.topics,
    );
    const issueTemplateSpecificIds = this.getIssueTemplateSpecificIds();
    const isDoNotSync = this.getOverridableFieldData("Checkbox", "do_not_sync");

    const {project: projectId} = getLocationSearchParams();
    const project = projects.find(proj => proj.id === projectId);

    const llmsEnabled = project?.enable_llms ?? true;

    return (
      <div>
        <div
          style={{
            ...styles.getEditLevelBackgroundStyles(
              editLevel,
              isDoNotSync.value,
            ),
            position: "relative",
          }}
        >
          <IssueEditor
            issue={issue}
            currentIssueset={
              this.state.selectedIssueset ?? this.state.actualIssueset
            }
            relatedIssues={
              this.props.documentIssues
                ? getRelatedIssues(issue, this.props.documentIssues)
                : undefined
            }
            topics={this.props.topics}
            topicsById={this.props.topicsById}
            contractTypesById={this.props.contractTypesById}
            organisationId={this.props.organisationId}
            onIssueUpdate={this.onIssueUpdate}
            onIssuesUpdate={this.onIssuesUpdate}
            selectedIssueset={this.state.selectedIssueset}
            actualIssueset={this.state.actualIssueset}
            pushLocationSearchParams={this.props.pushLocationSearchParams}
            editLevel={editLevel}
            disabled={!canEdit}
            roles={this.props.roles}
            doNotSyncHandler={this.renderPrioritizeNonIssueMutex}
            llmsEnabled={llmsEnabled}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {this.renderPrioritizeNonIssueMutex(
              "prioritize_non_issue_mutex",
              "Prioritize Non Issue Mutex",
            )}
            {allowBulkUpdate && (
              <Button
                variant="contained"
                color="primary"
                style={styles.updateDisplayLogicButton}
                onClick={this.onUpdateDisplayLogic}
                disabled={this.state.updateDisplayLogicDisabled}
              >
                Update Display Clauses
              </Button>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 4rem 1rem 0rem",
            }}
          >
            {this.renderTriggeredIssueSpecificOptions(
              canEdit,
              applicableTopics,
            )}
            {this.renderNonTriggeredIssueSpecificOptions(
              canEdit,
              applicableTopics,
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent:
                issue.issue_type === "EMPTY_PARENT"
                  ? "space-between"
                  : "flex-end",
              margin: "1rem 4rem",
            }}
          >
            {issue.issue_type === "EMPTY_PARENT" && (
              <ParentSettingsPanel
                {...this.getParentSettingsProps()}
                renderOverridenIssuesets={this.renderOverridenIssuesets}
              />
            )}
            <ChildSettingsPanel
              issue={issue}
              getOverridableFieldData={this.getOverridableFieldData}
              renderOverridenIssuesets={this.renderOverridenIssuesets}
              canEdit={canEdit}
            />
          </div>

          {this.renderUseParameterFiltersClausesCheckbox()}
          {this.renderParameterFilters()}
          {this.renderInternalNotes()}
          <Paper style={{margin: "2rem 2rem 1rem 2rem"}}>
            <div
              className="app-toolbar"
              style={{
                ...styles.cardHeader,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Templates
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={shouldUseProposedChanges}
                      onChange={this.triggerShouldUseProposedChanges}
                    />
                  }
                  label="Suggest Changes"
                  labelPlacement="start"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={clampFields}
                      onChange={this.setClampFields}
                    />
                  }
                  label="Clamp Fields"
                  labelPlacement="start"
                />
                <FlatButton
                  style={{
                    margin: "0 0.5em",
                    padding: "0 0.5em",
                    color: "#777",
                    fontStyle: "italic",
                  }}
                  onClick={this.switchText}
                  title="Switch Rule Fired and Not Fired Text"
                  disabled={!canEdit}
                >
                  Switch
                </FlatButton>
              </div>
            </div>
            <div
              style={{
                padding: "1rem 2rem",
                background: shouldUseProposedChanges ? "#f1f8e9" : "initial",
              }}
            >
              {templateVariables.map(item => {
                const fieldData = this.getOverridableFieldData(
                  "TextField",
                  item.key,
                  {}, // fieldMethods
                  undefined, // emptyValue
                  true, // usePropposedChanges
                );
                const domElement = this[`${item.key}-ref`];
                const isActive =
                  domElement === document.activeElement ||
                  (domElement &&
                    domElement.nextSibling === document.activeElement);
                const curlyBracesErrorText = getErrorIfHasMismatchedCurlyBraces(
                  fieldData.value,
                );
                const idsErrorText = getIdsErrorText(
                  fieldData.value,
                  issueTemplateSpecificIds,
                );
                return (
                  <div
                    key={item.key}
                    style={{
                      paddingBottom: "1rem",
                      borderBottom: "1px solid #eee",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {(item.positive || item.negative) &&
                        (item.positive === issue.trigger_display ||
                          !item.negative === issue.trigger_display) && (
                          <NoIssueIcon style={{color: greenColor, width: 24}} />
                        )}
                      {(item.positive || item.negative) &&
                        (item.negative === issue.trigger_display ||
                          !item.positive === issue.trigger_display) && (
                          <IssueIcon style={{color: redColor, width: 24}} />
                        )}
                      {!(item.positive || item.negative) && (
                        <IssueIcon style={{color: "transparent", width: 24}} />
                      )}
                      <FormControl
                        className={classes.templateEditorContainer}
                        style={{marginRight: 0}}
                      >
                        {item.markdownEnabled ? (
                          <div style={{paddingTop: "16px"}}>
                            <Editor
                              onChange={value =>
                                fieldData.props.onChange({
                                  target: {value},
                                })
                              }
                              onBlur={fieldData.props.onBlur}
                              value={fieldData.value}
                              label={item.name}
                              disabled={
                                !canEdit ||
                                (item.key === "checklist_definitions" &&
                                  this.state.editLevel === "base")
                              }
                            />
                          </div>
                        ) : (
                          <TextField
                            key={item.key}
                            type="text"
                            // floatingLabelFixed={true}
                            label={item.name}
                            multiline
                            rowsMax={
                              clampFields ? (isActive ? 30 : 4) : undefined
                            }
                            {..._.omit(fieldData.props, [
                              "textareaStyle",
                              "inputStyle",
                            ])}
                            className={`${classes.templateEditor} ${fieldData.props.className}`}
                            disabled={
                              !canEdit ||
                              (item.key === "checklist_definitions" &&
                                this.state.editLevel === "base")
                            }
                            inputRef={el => {
                              this[`${item.key}-ref`] = el;
                            }}
                            onFocus={() => {
                              this.forceUpdate();
                            }}
                          />
                        )}
                        {curlyBracesErrorText && (
                          <FormHelperText>
                            {curlyBracesErrorText}
                          </FormHelperText>
                        )}
                        {idsErrorText && (
                          <FormHelperText>{idsErrorText}</FormHelperText>
                        )}
                      </FormControl>
                      {shouldUseProposedChanges ? (
                        <IconButton {...fieldData.confirmProps}>
                          <DoneIcon />
                        </IconButton>
                      ) : null}
                      {editLevel !== "base" || shouldUseProposedChanges ? (
                        <IconButton {...fieldData.clearProps}>
                          <ClearIcon />
                        </IconButton>
                      ) : null}
                    </div>
                    {this.renderOverridenIssuesets(item.key, {
                      containerStyle: {
                        marginLeft: 58,
                        marginRight: 37,
                      },
                    })}
                    {this.renderProposedValues(
                      item.key,
                      overriddenIssuesetNames,
                    )}
                    {shouldUseProposedChanges &&
                      fieldData.overrideValue !== fieldData.value &&
                      this.renderValuesDiff(
                        fieldData.overrideValue,
                        fieldData.value,
                      )}
                  </div>
                );
              })}
            </div>
          </Paper>
          {this.renderCorrections(canEdit)}
          {this.renderIssueRelatedSearches(canEdit)}
        </div>
        <Dialog
          className="delete-dialogue"
          title="Confirm issue deletion"
          open={this.state.showDeleteDialogue}
          modal={true}
          actions={[
            <FlatButton
              label="Cancel"
              key="issue-delete-dialog-cancel-button"
              secondary={true}
              keyboardFocused={true}
              onClick={this.hideDeleteDialogue}
            />,
            <FlatButton
              label="Delete Issue"
              key="issue-delete-dialog-confirm-button"
              primary={true}
              onClick={this.deleteIssue}
            />,
          ]}
        >
          Are you sure you want to delete this Issue?
        </Dialog>
      </div>
    );
  };

  renderDisplayTab() {
    return (
      <Display
        organisationId={this.props.organisationId}
        issue={this.props.issue}
        topics={this.props.topics}
        onIssueUpdated={this.props.onIssueUpdated}
      />
    );
  }

  renderPrioritizeNonIssueMutex = (fieldName, label) => {
    const fieldData = this.getOverridableFieldData("Checkbox", fieldName);
    const {props: fieldDataProps = {}} = fieldData;

    return (
      <div style={{marginLeft: "4rem"}}>
        <FormControlLabel
          control={
            <ChecboxV4
              checked={fieldDataProps.value || false}
              onChange={fieldDataProps.onChange}
              color="primary"
              style={{width: "auto"}}
            />
          }
          label={label}
        />
        {this.renderOverridenIssuesets(fieldName, {
          containerStyle: {marginLeft: 0},
        })}
      </div>
    );
  };

  renderUseParameterFiltersClausesCheckbox = () => {
    const fieldName = "use_parameter_filter_clauses";
    const fieldData = this.getOverridableFieldData("Checkbox", fieldName);
    const {props: fieldDataProps = {}} = fieldData;
    return (
      <div style={{marginLeft: "2rem"}}>
        <div style={{display: "flex", alignItems: "center"}}>
          <FormControlLabel
            control={
              <ChecboxV4
                checked={fieldDataProps.value}
                onChange={fieldDataProps.onChange}
                color="primary"
                style={{width: "auto"}}
              />
            }
            label="Use custom applicable clauses"
            style={{marginRight: 4}}
          />
          <div
            title={
              "When selected, applicable clauses are taken from parameter filters and additional applicable clause topics only, unless display topics are selected"
            }
          >
            <InfoIcon
              style={{width: 18, height: 18, color: "#9e9e9e", marginTop: 4}}
            />
          </div>
        </div>
        {this.renderOverridenIssuesets(fieldName, {
          containerStyle: {marginLeft: 0},
        })}
      </div>
    );
  };

  renderParameterFilters = () => {
    const fieldName = "parameter_filters";
    const fieldData = this.getOverridableFieldData("CustomField", fieldName);
    const {
      props: fieldDataProps = {},
      clearProps: fieldDataClearProps = {},
    } = fieldData;

    return (
      <ParameterFilters
        editLevel={this.state.editLevel}
        topics={this.props.topics}
        topicsById={this.props.topicsById}
        parameterFilters={fieldDataProps.value}
        updateParameterFilters={fieldDataProps.onChange}
        clearProps={fieldDataClearProps}
        overriddenValues={this.renderOverridenIssuesets(fieldName, {
          containerStyle: {paddingBottom: "2rem"},
        })}
      />
    );
  };

  renderInternalNotes = () => {
    const fieldName = "internal_notes";
    const fieldData = this.getOverridableFieldData(
      "CustomField",
      fieldName,
      undefined,
      {},
    );
    const {
      props: fieldDataProps = {},
      clearProps: fieldDataClearProps = {},
    } = fieldData;

    return (
      <InternalNotes
        internalNotes={fieldDataProps.value}
        updateInternalNotes={fieldDataProps.onChange}
        isClearDisabled={fieldDataClearProps.disabled}
        onClear={fieldDataClearProps.onClick}
        overriddenValues={this.renderOverridenIssuesets(fieldName, {
          containerStyle: {paddingBottom: "2rem"},
        })}
      />
    );
  };

  renderUsageTab = () => {
    return (
      <IssueUsage
        issue={this.props.issue}
        topicsById={this.props.topicsById}
        organisationId={this.props.organisationId}
        updateDocumentIssue={this.updateDocumentIssue}
        resetShouldBeIssue={this.props.resetShouldBeIssue}
        resetIsCorrectlyApplied={this.props.resetIsCorrectlyApplied}
        selectedIssueset={this.state.selectedIssueset}
        showDocumentIssue={this.showDocumentIssue}
        fetchIssueUsages={this.props.fetchIssueUsages}
      />
    );
  };

  renderOverridesTab = () => {
    return (
      <IssueDetailOverrides
        overridableFields={this.state.overridableFields}
        contractTypesById={this.props.contractTypesById}
        issue={this.props.issue}
        onOverride={(dataPath, fieldName, fieldValue, dataPathShown) => {
          const overridableFields = _.cloneDeep(this.state.overridableFields);
          if (!_.has(overridableFields.overridden, dataPath)) {
            setNestedObjects(overridableFields.overridden, dataPath);
          }
          _.set(
            _.get(overridableFields.overridden, dataPath),
            fieldName,
            fieldValue,
          );
          this.setState({overridableFields});
          this.onOverridableIssueFieldUpdate({
            overrideValueData: {
              dataPath,
              fieldName,
              fieldValue,
              dataPathShown,
            },
          });
        }}
        onClear={(dataPath, fieldName) => {
          const overridableFields = _.cloneDeep(this.state.overridableFields);
          const values = _.get(overridableFields.overridden, dataPath);
          if (values) {
            delete values[fieldName];
            deleteNestedObjectsIfEmpty(overridableFields.overridden, dataPath);
            this.setState({overridableFields});
            this.onOverridableIssueFieldUpdate({
              overrideValueData: {
                dataPath,
                fieldName,
                fieldValue: null,
              },
            });
          }
        }}
      />
    );
  };

  renderLogsTab = () => {
    return (
      <IssueDetailLogs
        fetchIssueLogs={this.props.fetchIssueLogs}
        addLogComment={this.props.addLogComment}
        logs={this.props.logs}
        issue={this.props.issue}
        contractTypes={this.props.contractTypes}
        document={this.props.document}
      />
    );
  };

  constructDocumentIssues = () => {
    const {documentIssues} = this.props;
    if (!documentIssues) {
      return;
    }
    if (this.state.shouldUseProposedChanges) {
      const issueset =
        this.state.actualIssueset || this.state.selectedIssueset || {};
      const issuesetPath = `${issueset.master_id}.${
        issueset.is_duplicate_on_master ? issueset.remote_client_id : "master"
      }`;
      return documentIssues.map(docIssue => {
        const {
          proposed_changes: proposedChangesBase,
          override_values: overrideValuesBase,
        } = docIssue;

        let newDocIssue = docIssue;
        const baseProposedChange = _.get(proposedChangesBase, issuesetPath, {});
        const cleanProposedChange = _(baseProposedChange)
          .omit(_.isUndefined)
          .omit(_.isNull)
          .value();
        if (Object.keys(cleanProposedChange).length > 0) {
          const newOverrideValues = overrideValuesBase
            ? _.cloneDeep(overrideValuesBase)
            : {};
          const baseOverrideValue = _.get(overrideValuesBase, issuesetPath, {});
          const newOverrideValue = {
            ...baseOverrideValue,
            ...cleanProposedChange,
          };
          if (!_.has(newOverrideValues, issuesetPath)) {
            setNestedObjects(newOverrideValues, issuesetPath);
          }
          _.set(newOverrideValues, issuesetPath, newOverrideValue);
          newDocIssue = {
            ...docIssue,
            override_values: newOverrideValues,
          };
        }
        return newDocIssue;
      });
    }
    return documentIssues;
  };

  getParentSettingsProps = () => {
    const overrides = this.getOverridableFieldData(
      "CustomField",
      "parent_settings",
    );

    return {
      parentSettings: overrides.value,
      parentSettingsBase: overrides.baseValue,
      onIssueUpdate: overrides.props.onChange,
      isOverrideMode: overrides.isOverrideMode,
    };
  };

  editLevelChanged = (event, index, editLevel) => {
    let selectedIssueset;
    let actualIssueset;

    const currentIssueset =
      this.state.selectedIssueset || this.state.actualIssueset;
    const {contractTypesById} = this.props;
    const contractType = contractTypesById[currentIssueset.contract_type_id];
    if (editLevel === "base") {
      selectedIssueset = null;
      actualIssueset = currentIssueset;
    } else if (editLevel === "template") {
      selectedIssueset = !currentIssueset.is_duplicate_on_master
        ? currentIssueset
        : contractType.issuesets.find(
            issueset =>
              !issueset.is_duplicate_on_master &&
              issueset.master_id === currentIssueset.master_id,
          );
      actualIssueset = currentIssueset;
    } else if (editLevel === "client") {
      selectedIssueset = this.state.actualIssueset || currentIssueset;
      actualIssueset = null;
    }
    this.setState({editLevel, selectedIssueset, actualIssueset});
    this.props.pushLocationSearchParams({edit_level: editLevel});
  };

  updateDocumentIssue = (issue, updates, _options = {}) => {
    const options = {..._options};
    const currentIssuesetItem =
      this.state.selectedIssueset || this.state.actualIssueset;

    if (currentIssuesetItem) {
      createIssuesetOverrideData(currentIssuesetItem, options, issue, updates);
    }

    return this.props.updateDocumentIssue(issue, updates, options);
  };

  renderIssueRelatedSearches = canEdit => {
    const overrides = this.getOverridableFieldData(
      "CustomField",
      "related_searches",
    );
    const relatedSearches = (overrides.props && overrides.props.value) || [];
    return (
      <IssueRelatedSearches
        relatedSearches={relatedSearches}
        onChange={overrides.props.onChange}
        disabled={!canEdit}
      />
    );
  };

  createSelectField = (
    keyName,
    title,
    fieldType,
    _options,
    canEdit,
    markNonApplicableTopicNames,
  ) => {
    const overrides = this.getOverridableFieldData(
      fieldType,
      keyName,
      undefined,
    );
    const currentValues = this.getTopicValues(
      overrides,
      markNonApplicableTopicNames,
    );
    this.prevValues[keyName] = _.cloneDeep(overrides.value);
    let options = _options;
    if (currentValues.find(value => value.id === "none")) {
      options = [];
    }
    return (
      <>
        <ReactSelectLabel>{title}</ReactSelectLabel>
        <div style={{display: "flex"}}>
          <StatefulSelect
            keyName={keyName}
            value={currentValues}
            options={options}
            canEdit={canEdit}
            onBlur={this.onTopicValuesUpdate(overrides.props.onChange, keyName)}
          />
          {this.state.editLevel !== "base" && (
            <IconButton {...overrides.clearProps} style={{top: "-8px"}}>
              <ClearIcon />
            </IconButton>
          )}
        </div>
        {this.renderOverridenIssuesets(keyName, {
          containerStyle: {
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 20,
          },
        })}
      </>
    );
  };

  getDisplayTopicOptions = () => {
    const baseTopics = this.props.issue.referenced_topics
      .map(id => this.props.topicsById[id])
      .filter(id => id);
    return [...baseTopics, {name: "None", id: "none"}];
  };

  renderTriggeredIssueSpecificOptions = (canEdit, applicableTopics) => {
    return (
      <fieldset style={styles.optionsFieldset}>
        <legend style={styles.optionsFieldsetLegend}>
          {this.getOptionsFieldsetLegentIcon(true)}
          <span>Triggered</span>
        </legend>
        {this.createSelectField(
          "display_topics",
          "Display Topics",
          "CustomField",
          this.getDisplayTopicOptions(),
          canEdit,
          false,
        )}
        {this.createSelectField(
          "additional_applicable_clause_topics",
          "Additional Applicable Clause Topics",
          "CustomField",
          applicableTopics,
          canEdit,
          true,
        )}
        {this.createSelectField(
          "related_topics",
          "Related Topics",
          "CustomField",
          applicableTopics,
          canEdit,
          true,
        )}
      </fieldset>
    );
  };

  renderNonTriggeredIssueSpecificOptions = (canEdit, applicableTopics) => {
    return (
      <fieldset style={styles.optionsFieldset}>
        <legend style={styles.optionsFieldsetLegend}>
          {this.getOptionsFieldsetLegentIcon(false)}
          <div>Non Triggered</div>
        </legend>
        {this.createSelectField(
          "non_triggered_display_topics",
          "Display Topics",
          "CustomField",
          this.getDisplayTopicOptions(),
          canEdit,
          false,
        )}
        {this.createSelectField(
          "non_triggered_additional_applicable_clause_topics",
          "Additional Applicable Clause Topics",
          "CustomField",
          applicableTopics,
          canEdit,
          true,
        )}
        {this.createSelectField(
          "non_triggered_related_topics",
          "Related Topics",
          "CustomField",
          applicableTopics,
          canEdit,
          true,
        )}
      </fieldset>
    );
  };

  getOptionsFieldsetLegentIcon = triggered => {
    const {trigger_display: triggerDisplay} = this.props.issue;
    const shouldShowAlert =
      (triggerDisplay && triggered) || (!triggered && !triggerDisplay);
    return shouldShowAlert ? (
      <IssueIcon
        style={{...styles.optionsFieldsetLegendIconBase, color: redColor}}
      />
    ) : (
      <NoIssueIcon
        style={{...styles.optionsFieldsetLegendIconBase, color: greenColor}}
      />
    );
  };

  getTopicValues = (overrides, markNonApplicableTopicNames) => {
    const issueContractTypeIds = this.props.issue.contract_types.map(
      ct => ct.contract_type_id,
    );
    const overridesValue = overrides.value || [];
    if (overridesValue.find(topicId => topicId === "none")) {
      return [{id: "none", name: "None"}];
    }
    return overridesValue.reduce((accum, topicId) => {
      const topic = this.props.topicsById[topicId];
      if (topic) {
        const topicContractTypeIds = topic.contract_types.map(
          ct => ct.contract_type_id,
        );
        accum.push({
          id: topic.id,
          name:
            !markNonApplicableTopicNames ||
            areIssueCtsInTopicCts(issueContractTypeIds, topicContractTypeIds, 1)
              ? topic.name
              : `!! ${topic.name}`,
        });
      } else {
        logger.error(
          `Topic (id: ${topicId}) can't be found. Most likely it was deleted.`,
        );
      }
      return accum;
    }, []);
  };

  onTopicValuesUpdate = (onChange, keyName) => _newValues => {
    const prevValues = this.prevValues[keyName];
    let newValues = _newValues.map(topic => topic.id);
    if (
      keyName.indexOf("display_topics") !== -1 &&
      newValues.length === 0 &&
      Boolean(prevValues.find(item => item === "none"))
    ) {
      newValues = prevValues.filter(item => item !== "none");
    }
    onChange(newValues);
  };

  handleIssueDuplication = async () => {
    const res = await this.props.duplicateIssue();
    const duplicatedIssueId = res.value[0].id;
    window.open(
      `/organisation/${this.props.organisationId}/issue/${duplicatedIssueId}${this.props.location.search}`,
      "_blank",
    );
  };

  renderCorrections(canEdit) {
    const matchingIssues = getMatchingIssues(
      this.props.issue,
      this.props.correctionIssueTypes,
    );
    if (matchingIssues.length) {
      return (
        <Paper style={{margin: "2rem 2rem 1rem 2rem"}}>
          <div
            className="app-toolbar"
            style={{
              ...styles.cardHeader,
              justifyContent: "normal",
            }}
          >
            Corrections
          </div>
          {matchingIssues.map((issue, index) =>
            this.renderCorrection(issue, index, canEdit),
          )}
        </Paper>
      );
    }
    return null;
  }

  renderCorrection = (issue, index, canEdit) => {
    const fieldData = this.getOverridableFieldData(
      "TextField",
      "correction_settings",
      {
        getValueFrom: (fieldName, fields) => _.get(fields, fieldName),
        setValueTo: (fieldName, fields, value) => {
          if (value === null) {
            _.set(fields, fieldName, value);
          } else {
            _.set(fields, `${fieldName}[0].value`, value);
          }
        },
        handleLoadedValue: value => _.get(value, "[0].value") || "",
      },
    );
    return (
      <div style={styles.correction.div} key={`correction-${index}`}>
        <div style={{display: "flex", width: "100%", alignItems: "center"}}>
          <OldTextField
            type="text"
            floatingLabelText={`Correction value - ${issue.issue_type}`}
            style={styles.correction.textField}
            {...fieldData.props}
            disabled={!canEdit}
          />
          {this.state.editLevel !== "base" && (
            <IconButton
              {...fieldData.clearProps}
              {...(canEdit ? {} : {disabled: true})}
            >
              <ClearIcon />
            </IconButton>
          )}
        </div>
        {this.renderOverridenIssuesets(
          "correction_settings",
          {
            containerStyle: {
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 20,
            },
          },
          value => _.get(value, "[0].value"),
        )}
      </div>
    );
  };

  constructOverriddenIssuesetNames = () => {
    const result = {};
    this.props.contractTypes.forEach(({issuesets, name: contractTypeName}) => {
      (issuesets || []).forEach(issueset => {
        const {
          master_id: masterId,
          is_duplicate_on_master: isDuplicateOnMaster,
          remote_client_id: remoteCliendId,
          remote_client_name: remoteClientName,
          client_issueset_name: clientIssuesetName,
        } = issueset;
        if (!result[masterId]) {
          result[masterId] = {};
        }
        result[masterId][
          isDuplicateOnMaster ? remoteCliendId : "master"
        ] = isDuplicateOnMaster
          ? `${remoteClientName} - ${clientIssuesetName}`
          : contractTypeName;
      });
    });
    return result;
  };

  renderProposedValues = (fieldName, overriddenIssuesetNames) => {
    const result = [];

    const {main = {}, overridden = {}} = this.state.proposedChanges;
    if (main[fieldName]) {
      result.push({id: "main", title: "Base", value: main[fieldName]});
    }

    // handle overrides
    for (const masterId in overridden) {
      const masterOverride = overridden[masterId];
      for (const clientId in masterOverride) {
        const clientOverride = masterOverride[clientId];
        if (clientOverride[fieldName]) {
          result.push({
            title: _.get(overriddenIssuesetNames, `${masterId}.${clientId}`),
            value: clientOverride[fieldName],
          });
        }
      }
    }

    const props = {
      containerStyle: {
        marginLeft: 58,
        marginRight: 37,
      },
    };
    return this.renderOverrideChips(result, fieldName, props, null, true);
  };

  onNameUpdate = e => {
    const newName = e.target.value;
    if (!newName) {
      return this.setState(() => ({nameError: "You must enter a name"}));
    }
    if (this.nameAlreadyExists(newName) && newName !== this.props.issue.name) {
      return this.setState(() => ({
        nameError: "Please specify different issue name",
      }));
    }
    if (newName === this.props.issue.name) {
      return this.setState(() => ({nameError: ""}));
    }

    return this.setState(
      () => ({nameError: ""}),
      () => this.props.onIssueUpdated({name: newName}),
    );
  };

  switchText = () => {
    const switchMap = {
      reason_template: "positive_reason",
      detailed_reason: "detailed_positive_reason",
      guidance: "positive_guidance",
    };
    const {issue} = this.props;
    const {overridableFields} = this.state;
    const {isActive: isOverrideMode, dataPath} = this.getOverrideModeData();
    if (isOverrideMode) {
      const existingOverrides = _.get(overridableFields.overridden, dataPath);
      const switchedValues = getSwitchedValues(switchMap, existingOverrides);
      if (Object.keys(switchedValues).length > 0) {
        const newOverridesForIssueset = {
          ...existingOverrides,
          ...switchedValues,
        };
        const newOverridableFields = _.cloneDeep(overridableFields);
        _.set(
          newOverridableFields.overridden,
          dataPath,
          newOverridesForIssueset,
        );
        this.props.onIssueUpdated({
          override_values: newOverridableFields.overridden,
        });
        this.setState(() => ({overridableFields: newOverridableFields}));
      }
    } else {
      const switchedValues = getSwitchedValues(switchMap, issue);
      this.props.onIssueUpdated(switchedValues);
      this.setState(() => ({
        overridableFields: {
          ...overridableFields,
          main: {
            ...overridableFields.main,
            ...switchedValues,
          },
        },
      }));
    }
  };

  onIssueUpdate = (data: Omit<UpdateIssueData, "id" | "last_edited">) => {
    return this.props.onIssueUpdated({
      id: this.props.issue.id,
      last_edited: this.props.issue.last_edited,
      ...data,
    });
  };

  onIssuesUpdate = (data: Array<Omit<UpdateIssueData, "last_edited">>) => {
    return this.props.onIssuesUpdated(
      data.map(datum => ({
        last_edited: this.props.issuesById[datum.id].last_edited,
        ...datum,
      })),
    );
  };

  onIssuesetUpdate = data => {
    const currentIssueset =
      this.state.selectedIssueset || this.state.actualIssueset;
    const {contract_type_id, id, last_edited} = currentIssueset;
    return this.props.onIssuesetUpdate(contract_type_id, id, {
      template_definitions: data,
      last_edited,
    });
  };

  isTemplateField = fieldName => {
    const templateFields = templateVariables.map(item => item.key);
    return Boolean(templateFields.find(field => field === fieldName));
  };

  showDeleteDialogue = () => this.setState({showDeleteDialogue: true});
  hideDeleteDialogue = () => this.setState({showDeleteDialogue: false});

  deleteIssue = () => {
    const {issue} = this.props;
    this.setState(
      () => ({showDeleteDialogue: false}),
      () => {
        this.props.onIssueDeleted(issue.id, issue.last_edited);
        this.redirectAfterIssueDeletion(issue.id);
      },
    );
  };

  redirectAfterIssueDeletion = issueId => {
    const {leftHandMenuRef} = this;
    if (leftHandMenuRef && leftHandMenuRef.isLeftHandMenuOpen()) {
      const issueGroups = leftHandMenuRef.getIssueGroups();
      if (issueGroups && issueGroups.length > 0) {
        const issues = issueGroups
          .map(issueGroup => issueGroup.item)
          .reduce((acc, issueList) => [...acc, ...issueList], []);
        let firstIssue = issues.shift();
        while (firstIssue && firstIssue.id === issueId) {
          firstIssue = issues.shift();
        }
        if (firstIssue) {
          return this.props.showIssue(firstIssue);
        }
      }
    }
    this.props.redirectToIssueList();
  };

  nameAlreadyExists = name => {
    const existingNames = this.props.issues.map(issue =>
      issue.name.toLowerCase(),
    );
    return existingNames.includes(name.toLowerCase());
  };

  showDocumentIssue = async newLocation => {
    if (this.props.location.search === newLocation.search) {
      return;
    }
    const leftHandMenu = this.leftHandMenuRef;
    if (leftHandMenu) {
      await leftHandMenu.clearPanelData();
      this.props.pushHistoryLink(newLocation);
    }
  };

  onUpdateDisplayLogic = () => {
    this.setState(() => ({updateDisplayLogicDisabled: true}));
    this.props.updateDisplayLogic();
  };

  getIssueTemplateSpecificIds = () => {
    const {
      referenced_topics: referencedTopics,
      referenced_parameters: referencedParameters,
    } = this.props.issue;

    const additionalApplicableClausesTopics = this.getOverridableFieldData(
      "CustomField",
      "additional_applicable_clause_topics",
      undefined,
      [],
    );
    const nonTriggeredAdditionalApplicableClausesTopics = this.getOverridableFieldData(
      "CustomField",
      "non_triggered_additional_applicable_clause_topics",
      undefined,
      [],
    );
    return [
      ...(referencedTopics || []),
      ...(referencedParameters || []),
      ...(additionalApplicableClausesTopics.value || []),
      ...(nonTriggeredAdditionalApplicableClausesTopics.value || []),
    ];
  };

  createLeftHandMenuRef = node => (this.leftHandMenuRef = node);
  createIssueNameRef = node => (this.issueNameRef = node);

  triggerShouldUseProposedChanges = () =>
    this.setState(prevState => ({
      shouldUseProposedChanges: !prevState.shouldUseProposedChanges,
    }));
  setClampFields = () =>
    this.setState(prevState => ({
      clampFields: !prevState.clampFields,
    }));

  updateIssueIsStar = () => {
    this.onIssueUpdate({is_star: !this.props.issue.is_star});
  };
}

function getMatchingIssues(issue, types) {
  const isMatch = types.find(issueType => issueType === issue.issue_type);
  if (isMatch) {
    return [issue];
  }
  if (issue.rules.issues) {
    const children = issue.rules.issues.filter(issue => {
      const matchingIssues = getMatchingIssues(issue, types);
      return matchingIssues.length > 0;
    });
    return _.flatten(children);
  }
  return [];
}

function getSwitchedValues(keysMap, source) {
  const newValues = {};
  Object.keys(keysMap).forEach(leftKey => {
    const rightKey = keysMap[leftKey];
    const leftValue = source[leftKey];
    const rightValue = source[rightKey];
    if (leftValue || rightValue) {
      newValues[leftKey] = rightValue || "";
      newValues[rightKey] = leftValue || "";
    }
  });
  return newValues;
}

function getSelectFieldLabelText(issueset) {
  if (issueset && issueset.name) {
    return `Edit level - ${issueset.name} ${
      issueset.is_duplicate_on_master ? `- ${issueset.remote_client_name}` : ""
    }`;
  }
  return "Edit level";
}

function StatefulSelect(props) {
  const {keyName, value: baseValue, options, onBlur, canEdit} = props;
  const prevBaseValue = usePrevious(baseValue);
  const [value, updateValue] = useState(baseValue);

  React.useEffect(() => {
    if (baseValue && !_.isEqual(prevBaseValue, baseValue)) {
      updateValue(baseValue);
    }
  }, [baseValue]);

  function onSelectBlur() {
    onBlur(value);
  }

  function onChange2(newValues) {
    updateValue(newValues);
  }

  return (
    <Select
      className={keyName}
      valueKey="id"
      labelKey="name"
      value={value}
      options={options}
      multi={true}
      removeSelected={true}
      clearable={false}
      backspaceRemoves={false}
      deleteRemoves={false}
      closeOnSelect={false}
      allowCreate={false}
      onChange={onChange2}
      wrapperStyle={{width: "100%"}}
      menuContainerStyle={{zIndex: 99}}
      disabled={!canEdit}
      onBlur={onSelectBlur}
    />
  );
}

IssueDetail.propTypes = {
  organisationId: PropTypes.number.isRequired,
  issue: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    issue_type: PropTypes.string.isRequired,
    rules: PropTypes.object.isRequired,
  }).isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicsById: keyedObjectPropType(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onIssueUpdated: PropTypes.func.isRequired,
  resetShouldBeIssue: PropTypes.func.isRequired,
  resetIsCorrectlyApplied: PropTypes.func.isRequired,
};

export default withStyles(classStyles)(IssueDetail);
