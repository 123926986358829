import BaseRoute from "utils/base_route";

import UserList from "./containers/user_list";

export default class UserListRoute extends BaseRoute {
  constructor(store) {
    super("list", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, UserList);
  }
}
