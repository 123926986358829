import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function logsFetch(requestor) {
  return createReduxPromiseAction(
    ActionTypes.LOG_COMMENT_ADD,
    (organisationId, logId, text) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/log/${logId}/comment`,
          {text},
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
