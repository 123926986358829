import React from "react";
import {FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";

import {TaskDataLocationObject} from "common/flowmaster/types/data_location";
import {
  WorkflowTask,
  ActionDefinition,
} from "common/flowmaster/types/task_config";

interface ValueEditorProps {
  location: TaskDataLocationObject;
  priorTasks: WorkflowTask[];
  actionDefinitions: ActionDefinition[];
  onUpdateItem: (value: TaskDataLocationObject) => void;
}

const TaskValueEditor: React.FC<ValueEditorProps> = ({
  location,
  priorTasks,
  actionDefinitions,
  onUpdateItem,
}) => {
  const selectedTask = priorTasks.find(task => task.id === location.task);
  const actionDefinition =
    selectedTask &&
    actionDefinitions.find(
      actionDefinition => actionDefinition.key === selectedTask.action,
    );
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="task-selector-label">Key</InputLabel>
      <Select
        labelId="task-selector-label"
        value={location.key}
        onChange={event =>
          onUpdateItem({...location, key: event.target.value as string})
        }
        label="Value"
      >
        {Object.entries(actionDefinition?.result ?? {}).map(([key, type]) => (
          <MenuItem value={key} key={key}>
            {key} ({type})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TaskValueEditor;
