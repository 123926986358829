import React from "react";
import styled from "styled-components";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {push} from "react-router-redux";
import requestor from "requestor";

import Permissioner from "utils/permissioner";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";

import CircularProgress from "material-ui/CircularProgress";

import errorClearAction from "modules/user/actions/error_clear";
import reportsFetchAction from "modules/reports/actions/fetch";
import documentReportDetailFetchAction from "modules/reports/actions/document_report_fetch";

import {set} from "utils/organisation_store";
import ComparisonSummary, {RagState} from "../components/ComparisonSummary";
import {Document} from "common/types/document";
import {replaceUrlParam} from "routes/navigation";
import {buildQuery, parseQuery} from "utils/uri";
import {Report} from "../types";
import {LOADING} from "common/types/util";
import ReportDetail from "routes/document_list/components/report_detail";
import byId from "common/utils/by_id";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  box-sizing: border-box;
`;

type ComparisonSummaryContainerProps = {
  reports: {
    reports: Report[];
    settings: {
      groupByHeadings: boolean;
      used_issue_names: string[];
      rag_score_thresholds: Record<RagState, number>;
      used_issueset_master_id: string;
    };
  };
  documents: Array<Document & {creation_date: string; group_id: string}>;
  params: Record<"organisationId" | "projectId" | "documentId", string>;
  project: {
    bulk_report_settings: {rag_score_thresholds: Record<RagState, number>};
  };
  contractTypes: unknown;
  // TODO: Annotate
  error: unknown;
  user: unknown;
  clearError: unknown;
  fetchReports: unknown;
  fetchDocumentReportDetail: (
    organisationId: string,
    projectId: string,
    documentId: string,
  ) => unknown;
  dispatch: (args: unknown) => unknown;
  // Router props
  router: unknown;
  location: {search: string; pathname: string};
};

type ComparisonSummaryContainerState = {
  reportDetailSidebar: {isOpen: false} | {isOpen: true; documentId: number};
};

class ComparisonSummaryContainer extends React.Component<
  ComparisonSummaryContainerProps,
  ComparisonSummaryContainerState
> {
  permissioner: Permissioner;

  state: ComparisonSummaryContainerState = {
    reportDetailSidebar: {isOpen: false as const},
  };

  componentDidUpdate(prevProps: ComparisonSummaryContainerProps) {
    if (
      prevProps &&
      // Re-fetch reports for documents if either the query or route parameters
      // change
      (this.props.location.search !== prevProps.location.search ||
        this.props.location.pathname !== prevProps.location.pathname)
    ) {
      this.fetchReports();
    }
  }

  componentDidMount() {
    const {
      params: {organisationId, projectId},
    } = this.props;
    set(organisationId, "projectId", projectId);
  }

  openReportsDetailSidebar(documentId: number) {
    const {
      params: {organisationId, projectId},
    } = this.props;
    this.props.fetchDocumentReportDetail(
      organisationId,
      projectId,
      documentId.toString(),
    );

    this.setState({reportDetailSidebar: {isOpen: true, documentId}});
  }

  render() {
    const {
      reports: {reports},
    } = this.props;

    const {documentId, comparisonDocumentId} = this.getParamsState();
    const baseDocument = reports?.find(({id}) => id === documentId);

    if (!this.shouldRenderContainer() || !baseDocument) {
      if (this.props.error) {
        return this.renderError();
      }
      return (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }

    const comparisonDocument =
      comparisonDocumentId === undefined
        ? undefined
        : reports.find(({id}) => id === comparisonDocumentId) ??
          // If a comparison document ID is selected, but not found in the
          // reports data, it probably means we're waiting for the reports API
          // call to finish.
          LOADING;

    // const deriveRagState = (issue: ReportIssue): RagState => {
    //   if (!issue.is_alert) {
    //     return "green";
    //   }
    //   if (issue.issue_class_id === 3) {
    //     return "amber";
    //   }
    //   return "red";
    // };

    const {
      params: {organisationId, projectId},
    } = this.props;

    const mapDocumentToComparisonSummaryDocument = (document: Report) => ({
      name: document.name,
      id: document.id,
      issues: document.issues,
      link: `/organisation/${organisationId}/project/${projectId}/document/${document.id}/detail`,
      group_id: document.group_id,
    });

    if (!baseDocument) {
      throw new Error("Unhandled null value for base document");
    }

    const {rag_score_thresholds} = this.props.project.bulk_report_settings;

    return (
      <Container>
        {this.state.reportDetailSidebar.isOpen &&
          this.renderReportDetail(this.state.reportDetailSidebar.documentId)}

        <ComparisonSummary
          onClickColumn={documentId =>
            this.openReportsDetailSidebar(documentId)
          }
          rag_score_thresholds={
            rag_score_thresholds ?? {
              red: 0.25,
              amber: 0.5,
              green: 0.75,
            }
          }
          baseDocument={mapDocumentToComparisonSummaryDocument(baseDocument)}
          comparisonDocument={
            comparisonDocument !== undefined && comparisonDocument !== LOADING
              ? mapDocumentToComparisonSummaryDocument(comparisonDocument)
              : comparisonDocument
          }
          documents={this.props.documents.map(document => ({
            id: document.id,
            name: document.name,
            date: new Date(document.creation_date),
            group_id: document.group_id,
          }))}
          onChangeSelectedDocument={(changedDocument, id) => {
            const {
              dispatch,
              location: {pathname},
            } = this.props;
            if (changedDocument === "base") {
              // WARN: This method of updating the route parameter seems to
              // hard reload the page. Probably undesirable. Hopefully possible
              // to avoid if required.
              // We store the base document ID in a route parameter...
              replaceUrlParam(this.props.router, "documentId", id);
            }
            if (changedDocument === "comparison") {
              // ...and the comparison document in a query parameter.
              dispatch(push(pathname + buildQuery({comparison_document: id})));
            }
          }}
        />
      </Container>
    );
  }

  renderReportDetail(documentId: number) {
    const {
      reports: {reports, settings: report_settings},
    } = this.props;

    const usedReport = reports.find(report => report.id === documentId);

    if (!usedReport) {
      return null;
    }

    const contractTypesById = byId(this.props.contractTypes);

    const {
      params: {organisationId, projectId},
    } = this.props;

    return (
      <ReportDetail
        settings={report_settings}
        // TODO: need to get selectedReport from settings
        selectedReport="rag_report"
        documentName={usedReport.name}
        parameters={usedReport.parameters}
        documentIssues={usedReport.report_issues}
        ragScoreLevels={usedReport.rag_score_levels}
        closeReportDetail={() =>
          this.setState({reportDetailSidebar: {isOpen: false}})
        }
        selectedDocumentId={documentId}
        documentClauses={this.props.documentClauses}
        topicsById={this.props.topicsById}
        project={this.props.project}
        contractTypesById={contractTypesById}
        issueset={this.getReportIssueset(contractTypesById)}
        documentId={usedReport.id}
        projectId={projectId}
        organisationId={organisationId}
        document={usedReport}
        disableMarginForComparisonSummaryPage
      />
    );
  }

  // TODO: Duplicated in document_list. Extract somewhere?
  getReportIssueset(contractTypesById) {
    const issuesetMasterId = this.props.reports?.settings
      ?.used_issueset_master_id;
    let reportIssueset = null;
    Object.keys(contractTypesById).find(ctId => {
      const foundIssueset = contractTypesById[ctId].issuesets.find(
        issueset => issueset.master_id === issuesetMasterId,
      );
      if (foundIssueset) {
        reportIssueset = foundIssueset;
        return true;
      }
      return false;
    });
    return reportIssueset;
  }
  hasEnterPermission() {
    const permission = "show-comparison-summary";
    return this.permissioner.hasPermission(permission);
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.reports,
      this.props.documents,
      this.props.project,
      this.props.contractTypes,
    ]);
  }

  renderError() {
    const {props} = this;
    const fetchHandlers = {
      REPORTS_FETCH: this.fetchReports,
    };
    const fetchHandler = fetchHandlers[props.error.type];

    let errorMessage = [
      <span key="error-0">Comparison could not be loaded.&nbsp;</span>,
    ];

    if (fetchHandler) {
      /* eslint-disable no-inner-declarations */
      async function onRetry() {
        await props.clearError();
        fetchHandler();
      }

      errorMessage = [
        ...errorMessage,
        <span
          key="error-1"
          style={{
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={onRetry}
        >
          Try again?
        </span>,
      ];
    }

    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Roboto, sans-serif",
          color: "#424242",
        }}
      >
        {errorMessage}
      </div>
    );
  }

  // We store the state of which base and comparison documents are selected in
  // URL parameters and query parameters, respectively. Rather than duplicating
  // this state in React and manually synchronising it, we retrieve it directly
  // from the URL here.
  getParamsState() {
    const {
      location: {search},
      params,
    } = this.props;
    const queryParams = parseQuery(search);

    const documentId = parseInt(params.documentId, 10);
    const comparisonDocumentId =
      "comparison_document" in queryParams
        ? parseInt(queryParams.comparison_document, 10)
        : undefined;

    return {documentId, comparisonDocumentId};
  }

  renderComponent() {}

  fetchReports = () => {
    const {organisationId, projectId} = this.props.params;

    const {documentId, comparisonDocumentId} = this.getParamsState();

    // TODO: We should probably implement a manual loading state to give
    // instant feedback for this call.
    this.props.fetchReports(organisationId, projectId, {
      documents: [documentId, comparisonDocumentId].filter(Boolean),
    });
  };
}

function select(state) {
  return {
    error: state.error === UNINITIALISED ? null : state.error,
    reports: state.reports,
    user: state.user,
    documents: state.documents,
    project: state.project,
    contractTypes: state.contract_types,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        clearError: errorClearAction,
        fetchReports: reportsFetchAction,
        fetchDocumentReportDetail: documentReportDetailFetchAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(
  select,
  mapDispatchToProps,
)(withRouter(ComparisonSummaryContainer));
export const Component = ComparisonSummaryContainer;
