import React from "react";
import {FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";

import {ContextDataLocationObject} from "common/flowmaster/types/data_location";
import {WorkflowContext} from "common/flowmaster/types/workflow";

interface ValueEditorProps {
  location: ContextDataLocationObject;
  context: WorkflowContext;
  onUpdateItem: (value: ContextDataLocationObject) => void;
}

const ContextValueEditor: React.FC<ValueEditorProps> = ({
  location,
  context,
  onUpdateItem,
}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="context-value-selector-label">Key</InputLabel>
      <Select
        labelId="context-value-selector-label"
        value={location.key}
        onChange={event =>
          onUpdateItem({...location, key: event.target.value as number})
        }
        label="Value"
      >
        {context.values.map(item => (
          <MenuItem value={item.name} key={item.name}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ContextValueEditor;
