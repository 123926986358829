import React from "react";
import {Link} from "react-router";

import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Button from "@material-ui/core/Button";

function LinkButton({
  buttonProps = {},
  linkProps = {},
  pathname,
  hideIcon,
  children,
}) {
  return (
    <Link
      to={{pathname}}
      {...linkProps}
      style={{
        textDecoration: "none",
        flexShrink: 0,
        ...(linkProps.style ? linkProps.style : {}),
      }}
    >
      <Button
        variant="contained"
        endIcon={!hideIcon && <ArrowRightIcon />}
        {...buttonProps}
      >
        {children}
      </Button>
    </Link>
  );
}

export default LinkButton;
