import React from "react";
import _ from "underscore";
import requestor from "requestor";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import PromptListComponent from "../components/prompt_list";

import addPromptAction from "modules/prompts/actions/prompt_add";

function PromptListContainer(props) {
  const shouldRenderContainer = isInitialised([props.prompts, props.user]);
  if (!shouldRenderContainer || !props.user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const permissioner = new Permissioner(props.user);
  if (!permissioner.hasPermission("get-prompts") || !permissioner.isAdmin()) {
    return permissioner.getNoPermissionMessage();
  }

  const {organisationId} = props.params;

  function addPrompt(data) {
    props.addPrompt(organisationId, data);
  }

  setTitle("Prompt List");
  return (
    <PromptListComponent
      organisationId={organisationId}
      prompts={_.sortBy(props.prompts, prompt => prompt.name)}
      addPrompt={addPrompt}
      user={props.user}
    />
  );
}

function select(state, props) {
  return {
    params: props.params,
    organisation: state.organisation,
    router: state.router,
    user: state.user,
    prompts: state.prompts,
    contractTypes: state.contract_types,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        addPrompt: addPromptAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(PromptListContainer);
