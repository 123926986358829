import _ from "underscore";

import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import ReactSelectLabel from "common_components/react_select_label";

import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";

const selectorStyle = {
  margin: "1em 0",
};

export class RoleSingleSelector extends React.Component {
  render() {
    const {selectedParty, taskrole} = this.props;
    return (
      <div style={selectorStyle}>
        <SelectField
          className="select"
          floatingLabelText={taskrole.name}
          value={(selectedParty && selectedParty.party_id) || -1}
          onChange={this.changeParty}
          style={{
            display: "block",
            width: "100%",
          }}
        >
          {this.renderOptions()}
        </SelectField>
      </div>
    );
  }

  /* eslint-disable no-invalid-this */
  changeParty = (event, key, value) => {
    this.props.onUpdatePartyRole(this.props.taskrole.id, value);
  };
  /* eslint-enable no-invalid-this */

  renderOptions() {
    return [<MenuItem value={-1} key={-1} primaryText="None" />].concat(
      _.chain(this.props.parties)
        .sortBy(party => party.name)
        .map(party => (
          <MenuItem
            value={party.id}
            key={party.id}
            primaryText={`${party.name} - ${party.projectrole_name}`}
          />
        ))
        .value(),
    );
  }
}

export class Option extends React.Component {
  render() {
    const obj = this.props.option;
    return (
      <div {...this.props} onClick={this.props.mouseDown}>
        {obj.label} - {obj.role}
      </div>
    );
  }
}

export class RoleMultiSelector extends React.Component {
  render() {
    return (
      <div
        style={{
          ...selectorStyle,
          width: "100%",
        }}
      >
        <ReactSelectLabel className="label">
          {this.props.taskrole.name}
        </ReactSelectLabel>
        <Select
          className="select"
          multi={true}
          value={this.getValue()}
          options={this.getOptions()}
          onChange={this.changeParty}
          optionComponent={Option}
        />
      </div>
    );
  }

  getValue() {
    return (
      this.props.selectedParty &&
      this.props.selectedParty.party_data.map(party => party.id)
    );
  }

  getOptions() {
    return _.chain(this.props.parties)
      .sortBy(party => party.name)
      .map(party => ({
        value: party.id,
        label: party.name,
        role: party.projectrole_name,
      }))
      .value();
  }

  /* eslint-disable no-invalid-this */
  changeParty = parties => {
    const partyData = (parties || []).map(party => party.value);
    this.props.onUpdatePartyRole(this.props.taskrole.id, partyData);
  };
  /* eslint-enable no-invalid-this */
}

class RoleSelector extends React.Component {
  render() {
    return <div className="selectors">{this.renderSelectors()}</div>;
  }

  renderSelectors() {
    return this.props.taskroles.map(taskrole => {
      const value = _.findWhere(this.props.activeRoles, {id: taskrole.id});
      switch (taskrole.type) {
        case "single":
          return (
            <RoleSingleSelector
              className="selector"
              key={taskrole.id}
              selectedParty={value}
              taskrole={taskrole}
              parties={this.props.parties}
              onUpdatePartyRole={this.props.onUpdatePartyRole}
            />
          );
        case "multi":
        case "multi-check":
          return (
            <RoleMultiSelector
              className="selector"
              key={taskrole.id}
              selectedParty={value}
              taskrole={taskrole}
              parties={this.props.parties}
              onUpdatePartyRole={this.props.onUpdatePartyRole}
            />
          );
      }
      return null;
    });
  }
}

RoleSelector.propTypes = {
  activeRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      party_id: PropTypes.number,
      party_data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  taskroles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  parties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onUpdatePartyRole: PropTypes.func.isRequired,
};

export default RoleSelector;
