import Documents from "./containers/document_list";
import requestor from "requestor";

import documentTypesFetchAction from "modules/documents/actions/document_types_fetch";
import documentTypesClearAction from "modules/documents/actions/document_types_clear";

import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";
import contractTypesClearAction from "modules/documents/actions/contract_types_clear";

import reportsClearAction from "modules/reports/actions/clear";

import documentStateFetchAction from "modules/documents/actions/document_state/fetch";
import documentStateClearAction from "modules/documents/actions/document_state/clear";

import partiesFetchAction from "modules/projects/actions/parties_fetch";

import BaseRoute from "utils/base_route";

export default class DocumentsRoute extends BaseRoute {
  constructor(store) {
    super("list", store);
  }
  getChildRoutes(state, cb) {
    cb(null, []);
  }
  getComponents(location, cb) {
    cb(null, Documents);
  }
  fetchActiveData(state) {
    this.fetchDocumentTypes(state);
    this.fetchContractTypes(state);
    this.fetchParties(state);
    this.fetchDocumentState(state);
  }

  fetchDocumentTypes(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(documentTypesFetchAction(requestor)(organisationId));
  }

  fetchContractTypes(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(contractTypesFetchAction(requestor)(organisationId));
  }

  fetchParties(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(partiesFetchAction(requestor)(organisationId));
  }

  fetchDocumentState(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(documentStateFetchAction(organisationId));
  }

  clearActiveData() {
    this.store.dispatch(documentTypesClearAction());
    this.store.dispatch(contractTypesClearAction());
    this.store.dispatch(reportsClearAction());
    this.store.dispatch(documentStateClearAction());
  }
}
