import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function reclassifyDocument(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_UPDATE_LOAD_STATE,
    (organisationId, projectId, documentId) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}/document/${documentId}/update_state`,
          {},
          {withCredentials: true},
        )
        .then(response => ({id: documentId, ...response.data})),
  );
}
