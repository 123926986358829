import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {Link} from "react-router";

import TextField from "@material-ui/core/TextField";

import styles from "./styles";

export default class Td extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
    };

    if (props.isEditable) {
      this.state.isEditModeEnabled = false;
    }
  }

  render() {
    const props = _.omit(this.props, [
      ..._.keys(Td.propTypes),
      "children",
      "style",
      "onMouseEnter",
      "onMouseLeave",
    ]);
    const style = {
      ...styles.bodyCell,
      ...this.props.style,
      ...(this.state.isHovered && this.props.hoverStyle),
    };
    const {isEditable} = this.props;
    let children = this.props.handleChildren(this.props.children, this);
    if (isEditable && this.state.isEditModeEnabled) {
      const defaultValue = children;
      children = (
        <TextField
          defaultValue={defaultValue}
          onKeyPress={event => {
            if (event.key === "Enter") {
              event.target.blur();
            }
          }}
          onBlur={event => {
            const {value} = event.target;
            if (value !== defaultValue) {
              this.props.onEdit(value);
            }
            this.setState({isEditModeEnabled: false});
          }}
          autoFocus
          multiline
          InputProps={{
            style: {
              fontSize: "inherit",
            },
          }}
        />
      );
    }
    const content = (
      <div
        style={{
          ...styles.bodyCellContainer,
          ...this.props.containerStyle,
        }}
        {...isEditable &&
          !this.state.isEditModeEnabled && {
            onDoubleClick: () => this.setState({isEditModeEnabled: true}),
          }}
      >
        {children}
      </div>
    );
    return (
      <td
        style={style}
        onMouseEnter={event => {
          if (_.isFunction(this.props.onMouseEnter)) {
            this.props.onMouseEnter(event);
          }
          this.setState({isHovered: true});
        }}
        onMouseLeave={event => {
          if (_.isFunction(this.props.onMouseLeave)) {
            this.props.onMouseLeave(event);
          }
          this.setState({isHovered: false});
        }}
        {...props}
      >
        {!this.state.isEditModeEnabled && this.props.linkPath
          ? this.wrapCellContentIntoLink(content, this.props.linkPath)
          : content}
      </td>
    );
  }

  wrapCellContentIntoLink = (content, pathname) => {
    if (!content || !pathname) {
      return null;
    }
    return (
      <Link to={{pathname}} style={styles.linkCell}>
        {content}
      </Link>
    );
  };
}

Td.defaultProps = {
  sortText: null,
  isRowHovered: false,
  hoverStyle: {},
  handleChildren: children => children,
  isEditable: false,
  onEdit: () => {},
};

Td.propTypes = {
  sortText: PropTypes.string,
  isRowHovered: PropTypes.bool.isRequired,
  hoverStyle: PropTypes.object.isRequired,
  handleChildren: PropTypes.func.isRequired,
  linkPath: PropTypes.string,
  containerStyle: PropTypes.object,
  isEditable: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
};
