import React from "react";
import _ from "underscore";

import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import * as colors from "@material-ui/core/colors";

import Parameters from "./parameters";
import byId from "common/utils/by_id";
import * as TopicParameters from "plugins/topicparameters";
import FixedTooltip from "common_components/fixed_tooltip";

const styles = {
  main: {
    padding: "3px",
    position: "relative",
  },
  topic: {},
  name: {
    fontSize: "0.8em",
    textAlign: "center",
  },
  parents: {
    textAlign: "center",
    lineHeight: "0.6em",
  },
  segment: {
    fontStyle: "italic",
    fontSize: "0.6em",
    display: "inline-block",
    margin: "0 0.2em",
  },
  category: {
    fontSize: "0.6em",
    fontStyle: "italic",
    color: "#666",
    textAlign: "center",
    width: "100%",
  },
  parametersButton: {
    display: "inline-block",
    margin: "0 auto",
    width: "2.6rem",
    padding: "0 2px",
    border: "1px solid grey",
    borderRadius: "5px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    textAlign: "center",
  },
  dialogContent: {
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 14px 45px, rgba(0, 0, 0, 0.22) 0px 10px 18px",
    backgroundColor: "white",
    boxSizing: "border-box",
    padding: "12px 28px",
    zIndex: 2,
  },
  dialogBody: {
    maxHeight: "24rem",
    overflow: "auto",
  },
  dialogHeading: {
    margin: "0px",
    padding: "24px 24px 20px",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 22,
    lineHeight: "32px",
    fontWeight: 400,
  },
  parameterRow: {
    borderBottom: `1px solid ${colors.grey[300]}`,
  },
  dialogActionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "12px",
  },
};

export class TopicSummary extends React.Component {
  state = {
    parameterData: null,
  };

  componentDidUpdate() {
    // The dialog blocks scrolling - this undoes it.
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = "";
  }

  render() {
    const {topic} = this.props;
    const {value: topicId} = topic;
    const topicSections = topic.name.split("/");
    const topicText = topicSections[topicSections.length - 1];
    const parents = (
      <div style={styles.parents}>
        {topicSections.slice(0, -1).map((segment, index) => (
          <span className="segment" style={styles.segment} key={index}>
            {`${segment}/`}
          </span>
        ))}
      </div>
    );
    const name = <div style={styles.name}>{topicText}</div>;
    return (
      <div
        className="topic-summary"
        style={{
          ...styles.main,
          ...(this.props.isRemoved
            ? this.props.topicSummaryContainerStyles
            : {}),
        }}
        onClick={this.props.onClick}
      >
        <div className="name" style={styles.topic}>
          {parents}
          <FixedTooltip content={`Topic id: ${topicId}`}>
            <span style={{display: "block"}}>{name}</span>
          </FixedTooltip>
        </div>
        <div style={styles.category}>{topic.category}</div>
        <div style={{display: "flex", flexDirection: "column"}}>
          <Parameters
            topic={topic}
            parameters={topic.parameters}
            parameterValues={topic.parameterValues}
            updateTopicparameterActualValues={
              this.updateTopicparameterActualValues
            }
            onParameterClick={this.onParameterClick}
            clause={this.props.clause}
            showingParameterId={this.props.showingParameterId}
            hideOtherParameters={this.props.hideOtherParameters}
          />
          {this.renderParametersButton(topic, topicText)}
        </div>
        {this.renderDialog()}
      </div>
    );
  }

  renderParametersButton = topic => {
    const {parameters, parameterValues} = topic;
    if (
      !this.props.showParametersButton ||
      !parameters ||
      parameters.length === 0
    ) {
      return null;
    }
    /* eslint-disable no-param-reassign*/
    const areAllParamValuesEmpty = parameterValues.reduce(
      (result, parameterValue) => {
        const {values, locked_values, actual_values} = parameterValue;
        if (
          (values && values.length !== 0) ||
          (locked_values && locked_values.length !== 0) ||
          (actual_values && actual_values.length !== 0)
        ) {
          result = false;
        }
        return result;
      },
      true,
    );
    /* eslint-enable no-param-reassign*/
    if (!areAllParamValuesEmpty || !this.props.onTopicparameterValuesUpdate) {
      return null;
    }
    return (
      <div
        className="EEEE"
        style={styles.parametersButton}
        onClick={this.onParameterClick}
      >
        Edit
      </div>
    );
  };

  renderDialog = () => {
    const {parameterData} = this.state;
    if (!parameterData) {
      return null;
    }

    const {parameters, roles} = parameterData;
    const parameterValues = JSON.parse(
      JSON.stringify(parameterData.parameterValues),
    );
    const actions = [
      <Button
        key="save-parameters-dialog-cancel-button"
        onMouseDown={this.hideTopicParameterDialog}
        color="primary"
      >
        BACK
      </Button>,
    ];
    if (this.props.onTopicparameterValuesUpdate) {
      actions.push(
        <Button
          key="save-parameters-dialog-save-button"
          onMouseDown={this.onSaveTopicparameterValues}
          color="primary"
        >
          SAVE
        </Button>,
      );
    }
    const rolesWithDescriptions = roles.concat(
      this.props.roles
        .filter(propRole => !roles.find(role => propRole.id === role.role_id))
        .map(propRole => ({
          role_id: propRole.id,
          role_name: propRole.name,
          isUnavailable: true,
        })),
    );
    return (
      <Popper
        className="edit-parameters-dialog"
        open={Boolean(parameterData)}
        style={styles.dialogContent}
        onClick={e => e.stopPropagation()}
      >
        <div style={styles.dialogBody}>
          <h3 style={styles.dialogHeading}>{`${
            parameterData.topicName
          } - Parameters`}</h3>
          <table style={{borderCollapse: "collapse", margin: "0 auto"}}>
            <thead>
              <tr style={{height: "2rem"}}>
                <th style={{width: "5%"}} />
                <th style={{width: "35%"}}>Name</th>
                <th style={{width: "15%"}}>Type</th>
                <th style={{width: "45%"}}>Values</th>
              </tr>
            </thead>
            <tbody>
              {parameters.map((parameter, index) => (
                <tr key={parameter.name} style={styles.parameterRow}>
                  <td>{`${index + 1}`}</td>
                  <td>{parameter.name}</td>
                  <td>{parameter.parameter_type}</td>
                  <td style={{textAlign: "center"}}>
                    {this.renderParameterValues(
                      parameter,
                      parameterValues,
                      rolesWithDescriptions,
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={styles.dialogActionsContainer}>{actions}</div>
      </Popper>
    );
  };

  renderParameterValues = (parameter, parameterValues, roles) => {
    const result = parameterValues.find(
      pv => pv.topicparameter_id === parameter.id,
    );
    let values;
    if (result) {
      values = result.actual_values || result.locked_values || result.values;
    }
    // eslint-disable-next-line import/namespace
    const ValueEditor = TopicParameters[parameter.parameter_type].valueEditor;
    return (
      <ValueEditor
        ref={this.createValueEditorRef(parameter.id)}
        key={`${parameter.id}`}
        values={values}
        parameter={parameter}
        roles={roles}
      />
    );
  };

  showTopicParameterDialog = parameterData =>
    this.setState(() => ({parameterData}));
  hideTopicParameterDialog = () => this.setState(() => ({parameterData: null}));

  onSaveTopicparameterValues = () => {
    const {parameterData} = this.state;
    const parameterValuesByTopicparameterId = byId(
      parameterData.parameterValues,
      "topicparameter_id",
    );
    const data = {};
    data.topicparameterUpdates = parameterData.parameters.reduce(
      (acc, parameter) => {
        const baseValues =
          parameterValuesByTopicparameterId[parameter.id] &&
          parameterValuesByTopicparameterId[parameter.id].actual_values;
        const editorValues = this[`valueEditor${parameter.id}`].state.values;
        if (editorValues) {
          const editorValuesWithoutBlanks = editorValues.filter(val => val);
          acc[parameter.id] = {
            clause_topic_topicparameter_id: baseValues
              ? parameterValuesByTopicparameterId[parameter.id]
                  .clause_topic_topicparameter_id
              : null,
            values: [...editorValuesWithoutBlanks],
            valuesChanged: haveValuesChanged(
              baseValues,
              editorValuesWithoutBlanks,
            ),
          };
        }
        return acc;
      },
      {},
    );
    data.clausepart_last_edited = parameterData.clausepart_last_edited;
    this.hideTopicParameterDialog();
    this.updateTopicparameterActualValues(parameterData.topic_id, data);
  };

  updateTopicparameterActualValues = (topicId, data) => {
    const {project_id, document_id, clause_id, id} = this.props.clause;
    return this.props.onTopicparameterValuesUpdate(
      project_id,
      document_id,
      clause_id,
      id,
      topicId,
      data,
    );
  };

  onParameterClick = e => {
    e.stopPropagation();
    const {topic, clause} = this.props;
    this.showTopicParameterDialog({
      parameters: topic.parameters,
      parameterValues: topic.parameterValues,
      topicName: topic.name,
      topic_id: topic.value,
      is_confirmed: topic.is_confirmed,
      clausepart_last_edited: clause.last_edited,
      roles: clause.roles || this.props.roles,
    });
  };

  createValueEditorRef = parameterId => node =>
    (this[`valueEditor${parameterId}`] = node);
}

function haveValuesChanged(baseValues, editorValues) {
  if (!baseValues || baseValues.length !== editorValues.length) {
    return true;
  }
  if (baseValues.length === 0 || editorValues.length === 0) {
    return false;
  }
  if (typeof baseValues[0] === "object") {
    let hasChanged = false;
    for (let i = 0; i < baseValues.length; i++) {
      if (!_.isMatch(baseValues[i], editorValues[i])) {
        hasChanged = true;
        break;
      }
    }
    return hasChanged;
  }
  if (typeof baseValues[0] === "string") {
    return (
      _.difference(baseValues, editorValues).length !== 0 ||
      _.difference(editorValues, baseValues).length !== 0
    );
  }
}

export default TopicSummary;
