import requestor from "requestor";
import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../../constants/action_types";

/* eslint-disable max-params */
export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_HEADING_CHANGES_REVERT,
  (
    organisationId,
    projectId,
    documentId,
    sectionId,
    headingId,
    headingLastEdited,
  ) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/section/${sectionId}/heading/${headingId}/revert`,
        {heading_last_edited: headingLastEdited},
        {withCredentials: true},
      )
      .then(response => response.data),
);
