import React from "react";
import styled from "styled-components";
import {Link} from "react-router";

import Summary from "./summary";
import Context from "./context";
import Inputs from "./inputs";
import Output from "./output";
import Actions from "./actions";

import Checkbox from "@material-ui/core/Checkbox";
import {FormControlLabel} from "@material-ui/core";
import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";
import {ActionDefinitionWithType} from "common/flowmaster/types/task_config";
import {Prompt} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";
import ErrorSummary from "./error_summary";

const Container = styled.div`
  padding: 1em;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const WorkflowName = styled.h2`
  flex: 1;
  text-align: center;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const PlainLink = styled(Link)`
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: underline #ddd;
  }
`;

const BackLink = styled(PlainLink)`
  font-size: 1.2em;
  margin: 0 1em;
`;

type Props = {
  organisationId: OrganisationId;
  llmRun: EnhancedLlmRun;
  actionDefinitions: ActionDefinitionWithType[];
  prompts: Prompt[];
  showInternalActions: boolean;
  onInternalActionsToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showActionDetail: boolean;
  onShowActionDetailToggle: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
};

export default function llmRunDetailComponent({
  organisationId,
  llmRun,
  actionDefinitions,
  prompts,
  showInternalActions,
  onInternalActionsToggle,
  showActionDetail,
  onShowActionDetailToggle,
}: Props) {
  return (
    <Container>
      <Header>
        <TitleSection>
          <h1>Workflow</h1>
          <WorkflowName>
            <PlainLink
              to={`/organisation/${organisationId}/workflow/${llmRun.data.workflow_id}/detail`}
            >
              {llmRun.workflow_name}
            </PlainLink>
          </WorkflowName>
        </TitleSection>
        <HeaderRight>
          <FormControlLabel
            control={
              <Checkbox
                checked={showInternalActions}
                onChange={onInternalActionsToggle}
                color="primary"
              />
            }
            label="Show internal actions"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showActionDetail}
                onChange={onShowActionDetailToggle}
                color="primary"
              />
            }
            label="Show action detail"
          />
          <BackLink
            to={location =>
              location.pathname.replace(/([^/]+?\/detail)/, "list")
            }
          >
            Back
          </BackLink>
        </HeaderRight>
      </Header>
      <Summary llmRun={llmRun} />
      {llmRun.data?.errors?.length > 0 && <ErrorSummary llmRun={llmRun} />}
      <Context organisationId={organisationId} llmRun={llmRun} />
      <Inputs llmRun={llmRun} />
      <Output llmRun={llmRun} />
      <Actions
        llmRun={llmRun}
        actionDefinitions={actionDefinitions}
        prompts={prompts}
        showInternalActions={showInternalActions}
        showActionDetail={showActionDetail}
      />
    </Container>
  );
}
