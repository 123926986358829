import requestor from "requestor";

import ChildRenderer from "common_components/child_renderer";

import BaseRoute from "utils/base_route";

import issuesFetchAction from "modules/documents/actions/issues_info_fetch";
import topicsFetchAction from "modules/documents/actions/topics_fetch";
import topicCategoriesFetchAction from "modules/documents/actions/topic_categories_fetch";
import topicTagsFetchAction from "modules/documents/actions/topic_tags_fetch";
import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";
import rolesFetchAction from "modules/documents/actions/role/roles_fetch";
import getWorkflowsAction from "modules/flowmaster/actions/workflows_fetch";

import IssuesList from "routes/issue_list";
import IssuesDetail from "routes/issue_detail";

export default class IssuesRoute extends BaseRoute {
  constructor(store) {
    super("issue", store);
  }

  createChildRoutes() {
    return [new IssuesList(this.store), new IssuesDetail(this.store)];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchIssues(state);
    this.fetchTopics(state);
    this.fetchTopicCategories(state);
    this.fetchTopicTags(state);
    this.fetchContractTypes(state);
    this.fetchRoles(state);
    this.fetchWorkflows(state);
  }

  fetchIssues(state) {
    const {organisationId} = state.params;
    this.store.dispatch(issuesFetchAction(requestor)(organisationId));
  }

  fetchTopics(state) {
    const {organisationId} = state.params;
    this.store.dispatch(topicsFetchAction(requestor)(organisationId));
  }

  fetchTopicCategories(state) {
    const {organisationId} = state.params;
    this.store.dispatch(topicCategoriesFetchAction(requestor)(organisationId));
  }

  fetchTopicTags(state) {
    const {organisationId} = state.params;
    this.store.dispatch(topicTagsFetchAction(requestor)(organisationId));
  }

  fetchContractTypes(state) {
    const {organisationId} = state.params;
    this.store.dispatch(contractTypesFetchAction(requestor)(organisationId));
  }

  fetchRoles(state) {
    const {organisationId} = state.params;
    this.store.dispatch(rolesFetchAction(requestor)(organisationId));
  }

  fetchWorkflows(state) {
    const {organisationId} = state.params;
    this.store.dispatch(getWorkflowsAction(requestor)(organisationId));
  }
}
