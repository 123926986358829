import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function clauseBankFetch(requestor) {
  return createReduxPromiseAction(
    ActionTypes.CLAUSE_BANK_FETCH,
    organisationId => {
      return requestor
        .get(`${basePath}/organisation/${organisationId}/clause_bank`, {
          withCredentials: true,
        })
        .then(response => {
          return response.data;
        });
    },
  );
}
