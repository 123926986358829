import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "./Dialog";

type InputDialogProps = {
  input: {
    label: string;
    value: string;
    onChange: (value: string) => void;
  };
  onClose?: () => void;
  onSave?: () => void;
  title: string;
};

const InputDialog = ({onClose, onSave, title, input}: InputDialogProps) => (
  <Dialog
    onClose={onClose}
    title={title}
    buttons={[
      {text: "Cancel", onClick: onClose},
      {text: "Save", onClick: onSave},
    ]}
  >
    <TextField
      label={input.label}
      value={input.value}
      onChange={event => input.onChange(event.target.value)}
    />
  </Dialog>
);

export default InputDialog;
