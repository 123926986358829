import React, {FC} from "react";
import {FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";

interface TypeSelectorProps {
  value: string;
  onChange: (value: string) => void;
}

const TypeSelector: FC<TypeSelectorProps> = ({value, onChange}) => {
  return (
    <FormControl fullWidth style={{margin: "10px 0px"}}>
      <InputLabel>Type</InputLabel>
      <Select
        value={value}
        onChange={e => onChange(e.target.value as string)}
        label="Type"
      >
        <MenuItem value="string">String</MenuItem>
        <MenuItem value="number">Number</MenuItem>
        <MenuItem value="object">Object</MenuItem>
        <MenuItem value="array">Array</MenuItem>
      </Select>
    </FormControl>
  );
};

export default TypeSelector;
