import * as jsDiff from "diff";
import _ from "underscore";

function getDefaultDiffs(baseValues, logValues) {
  return Object.keys(logValues).map(key => ({
    label: key,
    value: Array.isArray(logValues[key])
      ? diffArrays(logValues[key], baseValues[key])
      : typeof logValues[key] === "boolean"
        ? jsDiff.diffWords(
            logValues[key].toString(),
            baseValues[key].toString(),
          )
        : jsDiff.diffChars(
            logValues[key].toString(),
            baseValues[key].toString(),
          ),
  }));
}

function diffArrays(log, base) {
  if (typeof log[0] === "object" || typeof base[0] === "object") {
    let largerArray = log;
    let smallerArray = base;
    let isLogLargerArray = true;
    if (log.length < base.length) {
      largerArray = base;
      smallerArray = log;
      isLogLargerArray = false;
    }
    const resultDiffs = largerArray.map((largerArrayItem, index) => {
      const smallerArrayItem = smallerArray[index] || {pre: "", post: ""};
      if (isLogLargerArray) {
        return {
          pre: jsDiff.diffChars(largerArrayItem.pre, smallerArrayItem.pre),
          post: jsDiff.diffChars(largerArrayItem.post, smallerArrayItem.post),
        };
      }
      return {
        pre: jsDiff.diffChars(smallerArrayItem.pre, largerArrayItem.pre),
        post: jsDiff.diffChars(smallerArrayItem.post, largerArrayItem.post),
      };
    });
    return resultDiffs;
  }
  return jsDiff.diffWords(
    _.sortBy(log, item => item).join(" "),
    _.sortBy(base, item => item).join(" "),
  );
}

export default getDefaultDiffs;
