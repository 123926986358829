import ProjectList from "./containers/project_list";
import requestor from "requestor";

import contractTypesFetchAction from "modules/documents/actions/contract_types_fetch";
import contractTypesClearAction from "modules/documents/actions/contract_types_clear";

import BaseRoute from "utils/base_route";

export default class ProjectListRoute extends BaseRoute {
  constructor(store) {
    super("list", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, ProjectList);
  }

  fetchActiveData(state) {
    this.fetchContractTypes(state);
  }

  fetchContractTypes(state) {
    const {organisationId} = state.router.params;
    this.store.dispatch(contractTypesFetchAction(requestor)(organisationId));
  }

  clearActiveData() {
    this.store.dispatch(contractTypesClearAction());
  }
}
