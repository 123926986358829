import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";

export default function flushAssistants(requestor) {
  return createReduxPromiseAction(
    ActionTypes.FLUSH_ASSISTANTS,
    (organisationId: OrganisationId) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/flush_all_assistants`,
          {},
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
