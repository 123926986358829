import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import radium from "radium";

import keyedObjectPropType from "utils/keyed_object_prop_type";

import {DocumentClauseItem} from "./document_clause_item";
import renderNode from "../utils/render_node";
import calculateNodeSummary from "../utils/calculate_node_summary";

const styles = {
  container: {
    flexGrow: 1,
  },
  tbody: {
    borderCollapse: "separate",
    borderSpacing: "0.5em",
    width: "100%",
  },
  row: {
    padding: "1em",
  },
  term: {
    fontWeight: "bold",
    minWidth: "10em",
    verticalAlign: "top",
  },
  meaning: {},
};

export class DefinitionItem extends React.Component {
  render() {
    const {
      definition,
      reference,
      selectedLevel,
      selectedPath,
      isInteractive,
    } = this.props;
    if (!definition.clauseNodes) {
      return this.renderOldDefinition(definition);
    }
    const text = definition.clauseNodes[0].text;
    return (
      <tr style={styles.row}>
        <td className="term" style={styles.term}>
          {text}
        </td>
        <td className="meaning" style={styles.meaning}>
          {renderNode(
            {...definition.clauseNodes[1]},
            null,
            {
              ...this.props,
              renderPath: `${text}_def`,
              selectedLevel,
              selectedPath,
              isInteractive,
              lacksTopics: true,
            },
            reference,
          )}
        </td>
      </tr>
    );
  }

  renderOldDefinition(definition) {
    const {term, meaning} = definition;
    return (
      <tr style={styles.row} key={term}>
        <td className="term" style={styles.term}>
          {term}
        </td>
        <td className="meaning" style={styles.meaning}>
          {meaning}
        </td>
      </tr>
    );
  }
}

export class DefinitionClauseItem extends DocumentClauseItem {
  renderClauseparts() {
    const {nodes} = this.props.clause;
    return (
      <div style={[styles.container]}>
        {this.renderTopicSummary(nodes)}
        <span className="preamble">{this.getPreamble(nodes)}</span>
        <table style={[styles.tbody]}>
          <thead>
            <tr>
              <th>Term</th>
              <th>Meaning</th>
            </tr>
          </thead>
          <tbody className="definition-list">{this.renderDefinitions()}</tbody>
        </table>
      </div>
    );
  }

  calculateNodeSummary(nodes) {
    // Need to put in this correction, because otherwise
    // calculateNodeLevel applies a correction to child atoms.
    const clauseNodes = nodes.clauseNodes;
    clauseNodes[1].clauseNodes = clauseNodes[1].clauseNodes.map(node => ({
      ...node,
      type: "List",
    }));
    const fixedNodes = {...nodes, clauseNodes};
    return _.omit(calculateNodeSummary(fixedNodes), [1, 2]);
  }

  getPreamble(nodes) {
    if (nodes.clauseNodes) {
      return nodes.clauseNodes[0].text;
    }
    return nodes.text;
  }

  renderDefinitions() {
    const {clause} = this.props;

    if (clause.nodes.clauseNodes) {
      return (clause.nodes.clauseNodes[1].clauseNodes || []).map(node =>
        this.renderDefinition(node),
      );
    }
    return this.props.definitions.map(definition =>
      this.renderDefinition(definition),
    );
  }

  renderDefinition(definition) {
    const {selectedLevel, selectedPath} = this.state;
    const {isInteractive} = this.props;
    return (
      <DefinitionItem
        definition={definition}
        {...{
          ...this.props,
          onTopicsHover: this.onTopicsHovered,
          onReferenceClick: this.onReferenceClick,
          lacksTopics: true,
        }}
        key={definition.listOrder || definition.term}
        reference={this.props.clause.reference}
        selectedLevel={selectedLevel}
        selectedPath={selectedPath}
        isInteractive={isInteractive}
      />
    );
  }
}

DefinitionClauseItem.propTypes = {
  clause: PropTypes.shape({
    id: PropTypes.number.isRequired,
    reference: PropTypes.string.isRequired,
    nodes: PropTypes.object,
  }),
  definitions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      term: PropTypes.string.isRequired,
      meaning: PropTypes.string.isRequired,
    }),
  ),
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      new_section_id: PropTypes.number.isRequired,
      new_clause_id: PropTypes.number.isRequired,
    }),
  ),
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicsById: keyedObjectPropType(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      topiccategory_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  topicCategoriesById: keyedObjectPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ),
  topicTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onExistingTopicAdded: PropTypes.func.isRequired,
  onNewTopicAdded: PropTypes.func.isRequired,
  onTopicRemoved: PropTypes.func.isRequired,
  onTopicConfirmed: PropTypes.func.isRequired,
  onUnconfirmedTopicsRemoved: PropTypes.func.isRequired,
  onSplitClause: PropTypes.func.isRequired,
  onRemoveSplits: PropTypes.func.isRequired,
  onTopicsReordered: PropTypes.func.isRequired,
};

export default radium(DefinitionClauseItem);
