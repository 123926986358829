import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";

type ToolbarProps = {
  title: string;
  /**
   * Optional callback invoked when add icon is clicked on toolbar. If not
   * provided, add icon is not rendered.
   */
  onClickAddIcon?: () => void;
};

const Toolbar = ({title, onClickAddIcon}: ToolbarProps) => (
  <div className="app-toolbar">
    <Typography style={{flexGrow: 1}} color="textSecondary" variant="h6">
      {title}
    </Typography>
    {onClickAddIcon && (
      <Fab size="small" color="primary" onClick={onClickAddIcon}>
        <AddIcon />
      </Fab>
    )}
  </div>
);

export default Toolbar;
