import _ from "underscore";
/* eslint-disable no-unused-vars */
import React from "react";
/* eslint-enable no-unused-vars */
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";

import requestor from "requestor";
import topicCategoryUpdate from "modules/documents/actions/topic_category_update";

import TopicCategoryListComponent from "../components/topiccategory_list";

import UpdatingComponent from "utils/updating_component";

class TopicCategoryList extends UpdatingComponent {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return this.permissioner.isAdmin();
  }

  shouldRenderContainer() {
    return isInitialised([this.props.topiccategories, this.props.user]);
  }

  renderComponent() {
    return (
      <React.Fragment>
        {this.renderTopicCategoryList()}
        {this.props.children}
      </React.Fragment>
    );
  }

  renderTopicCategoryList() {
    setTitle("Topic Category List");
    return (
      <TopicCategoryListComponent
        topiccategories={this.props.topiccategories}
        onTopicCategoryUpdated={this.updateTopicCategory}
        organisationId={parseInt(this.props.params.organisationId, 10)}
      />
    );
  }

  getUpdatingProp(type, data) {
    return this.props.topiccategories.find(category => category.id === data[0]);
  }

  updateHandler(args) {
    const {organisationId} = this.props.params;
    const [topicCategoryId, , data] = args;
    this.props.topicCategoryUpdate(
      organisationId,
      topicCategoryId,
      this.getUpdatingProp("UPDATE", args).last_edited,
      data,
    );
  }

  /* eslint-disable no-invalid-this */

  updateTopicCategory = (...data) => {
    this.performUpdate(data);
  };
  /* eslint-enable no-invalid-this */
}

function select(state, props) {
  return {
    params: _.mapObject(props.params, key => parseInt(key, 10)),
    topiccategories: state.topicCategories,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        topicCategoryUpdate: topicCategoryUpdate(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(TopicCategoryList);
export const Component = TopicCategoryList;
