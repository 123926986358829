export default {
  name: "",
  description: "",
  document_type_id: null,
  is_default: false,
  is_review_required: false,
  show_issues_in_checklist: false,
  verify_issues: true,
  show_clauses_without_topics: false,
  holds_clause_templates: false,
  hide_from_search: false,
  default_contract_type: {},
  document_type: {
    id: null,
    name: null,
  },
  documents_count: 0,
  issuesets: [],
  select_checklist_on_upload: false,
  is_private: false,
  template_document_link: null,
  report_settings: [],
  checklist_display: "fully_closed",
  template_settings: "{}",
  resolve_issues: true,
  edit_all_issue_values: false,
  force_party_confirmation: false,
  default_issueset_id: null,
  use_fixer: true,
  use_v2_document_parser: true,
  incremental_parse: true,
  show_how_issue_works: true,
  show_state_selector: true,
  read_pdf_tables: true,
  bulk_report_settings: null,
  hide_state_changes: false,
  show_clause_buttons: false,
  parties: [],
  external_clause_library: null,
  enable_llms: false,
};
