import React from "react";

import {FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";
import {ContextId, WorkflowContext} from "common/flowmaster/types/workflow";

interface ContextProps {
  selectedContext: ContextId;
  items: WorkflowContext[];
  onUpdate: (id: ContextId) => void;
}

const Context: React.FC<ContextProps> = ({
  selectedContext,
  items,
  onUpdate,
}) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="context-selector-label">Context</InputLabel>
      <Select
        labelId="context-selector-label"
        value={selectedContext}
        onChange={event => onUpdate(event.target.value as number)}
        label="Context"
      >
        {items.map(item => (
          <MenuItem value={item.id} key={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Context;
