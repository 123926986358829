import React from "react";

import ProjectSelector from "./project_selector";
import DocumentSelector from "./document_selector";

import {OrganisationId} from "common/types/organisation";
import {ProjectId, Project} from "common/types/project";
import {DocumentId} from "common/types/document";
import {Document} from "../../../types";

interface Props {
  organisationId: OrganisationId;
  projectId: ProjectId;
  projects: Project[];
  selectProject: (projectId: ProjectId) => void;
  documentIds: null | DocumentId[];
  documents: Document[];
  selectDocuments: (documentIds: DocumentId[]) => void;
}

export default function DocumentSelectorContainer({
  organisationId,
  projectId,
  projects,
  selectProject,
  documentIds,
  documents,
  selectDocuments,
}: Props) {
  return (
    <div style={{display: "flex", gap: "1em"}}>
      <ProjectSelector
        organisationId={organisationId}
        projectId={projectId}
        projects={projects}
        selectProject={selectProject}
      />
      <DocumentSelector
        organisationId={organisationId}
        projectId={projectId}
        documentIds={documentIds}
        documents={documents}
        selectDocuments={selectDocuments}
      />
    </div>
  );
}
