import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function lockTopicClassifierSettings(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPIC_CLASSIFIER_LOCK,
    (organisationId, topicId, classifierId, configurationId, lastEdited) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}` +
            `/topic/${topicId}/classifier/${classifierId}/configuration/` +
            `${configurationId}/lock`,
          {
            last_edited: lastEdited,
          },
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
