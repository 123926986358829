import moment from "moment";

import React from "react";
import PropTypes from "prop-types";

import Card from "material-ui/Card";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";

class Comment extends React.Component {
  render() {
    const {text, username, creation_date: date} = this.props.comment;
    return (
      <Card
        style={{
          padding: "1em",
          margin: "0.5em 0",
          boxShadow:
            "0 0px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.01)",
        }}
      >
        <div className="text">{text}</div>
        <div
          style={{
            fontSize: "0.75em",
            fontStyle: "italic",
            textAlign: "right",
          }}
        >
          By <span className="username">{username}</span> on&nbsp;
          <span className="time">
            {moment(date).format("YYYY-MM-DD hh:mm a")}
          </span>
        </div>
      </Card>
    );
  }
}

class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {newCommentText: ""};
  }

  render() {
    return (
      <Card
        style={{
          padding: "2em",
        }}
      >
        <div>{this.renderComments()}</div>
        <TextField
          type="text"
          multiLine={true}
          floatingLabelText="New Comment"
          className="new-comment"
          value={this.state.newCommentText}
          onChange={this.handleChange}
          style={{
            display: "block",
            width: "100%",
          }}
        />
        <RaisedButton
          className="create-comment"
          primary={true}
          onClick={this.createComment}
          style={{
            margin: "1em",
            height: "3em",
            width: "10em",
          }}
          labelStyle={{
            top: "1em",
          }}
          label="Add Comment"
        />
      </Card>
    );
  }

  renderComments() {
    return this.props.comments.map(comment => (
      <Comment key={comment.id} comment={comment} />
    ));
  }

  /* eslint-disable no-invalid-this */
  handleChange = event => {
    this.setState({
      newCommentText: event.target.value,
    });
  };

  createComment = () => {
    this.props.onCommentCreated(this.state.newCommentText);
    this.setState({newCommentText: ""});
  };
  /* eslint-enable no-invalid-this */
}

Comments.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onCommentCreated: PropTypes.func.isRequired,
};

export default Comments;
export {Comment};
