import calculateDocumentRagScore from "utils/reports/calculate_document_rag_score";
import calculateDocumentRagLevelColor from "utils/reports/calculate_document_rag_level_color";

export default function getReportIssueStats(
  reports,
  projectId,
  issueset,
  useRagScore,
) {
  if (projectId === 267) {
    return {
      low: 12,
      moderate: 3,
      high: 5,
    };
  }
  const colors = {red: 0, amber: 0, green: 0};
  reports.forEach(({color, issues, rag_score_levels: ragScoreLevels}) => {
    if (useRagScore) {
      colors[color] += 1;
    } else {
      const documentRagScore = calculateDocumentRagScore(issues, issueset);
      const documentRagLevelColor = calculateDocumentRagLevelColor(
        documentRagScore,
        ragScoreLevels,
      );
      colors[documentRagLevelColor] += 1;
    }
  });
  return {
    low: colors.green,
    moderate: colors.amber,
    high: colors.red,
  };
}
