import _ from "underscore";
import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {buildQuery} from "utils/uri";

export default function fetchTopic(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPIC_FETCH,
    (organisationId, topicId, params) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}/topic/${topicId}${buildQuery(
            params,
          )}`,
          {
            withCredentials: true,
          },
        )
        .then(response => ({
          ...response.data,
          ..._.pick(params, ["omit_param_stats"]),
        })),
  );
}
