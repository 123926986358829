import React from "react";
import {useDrag, useDrop, DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import AddItem from "./add_item";
import WorkflowInputComponent from "../components/input";
import Fieldset from "./styled/fieldset";
import Legend from "./styled/legend";

import {WorkflowInputId, WorkflowInput} from "common/flowmaster/types/workflow";

interface InputsProps {
  items: WorkflowInput[];
  onAdd: (name: string) => void;
  onUpdate: (id: WorkflowInputId, values: WorkflowInput) => void;
  onDelete: (id: WorkflowInputId) => void;
  onReorder: (id: WorkflowInputId, newOrder: number) => void;
}

const ItemTypes = {
  INPUT: "input",
};

const Inputs: React.FC<InputsProps> = ({
  items,
  onUpdate,
  onAdd,
  onDelete,
  onReorder,
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Fieldset>
        <Legend>Inputs</Legend>
        <div>
          {items.map(input => (
            <DraggableInput
              key={input.id}
              input={input}
              onUpdate={onUpdate}
              onDelete={onDelete}
              onReorder={onReorder}
            />
          ))}
        </div>
        <AddItem onAdd={onAdd} label="Input Name" />
      </Fieldset>
    </DndProvider>
  );
};

interface DraggedInput {
  id: number;
  type: string;
}

const DraggableInput = ({input, onUpdate, onDelete, onReorder}) => {
  const [draggedInput, drag] = useDrag({
    item: {id: input.id, type: ItemTypes.INPUT},
    collect: monitor => ({
      id: input.id,
      isDragging: Boolean(monitor.isDragging()),
    }),
  });
  const [, drop] = useDrop({
    accept: ItemTypes.INPUT,
    drop: (droppedInput: DraggedInput) => {
      onReorder(droppedInput.id, input.order);
    },
  });

  return (
    <div
      ref={node => drag(drop(node))}
      style={{
        opacity: draggedInput.isDragging ? 0.5 : 1,
        margin: "10px",
        padding: "10px",
        backgroundColor: "lightgray",
        cursor: "move",
      }}
    >
      <WorkflowInputComponent
        input={input}
        onUpdateItem={updates => onUpdate(input.id, updates)}
        onDeleteItem={() => onDelete(input.id)}
      />
    </div>
  );
};

export default Inputs;
