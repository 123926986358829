import React from "react";
import _ from "underscore";
import Select from "react-select";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

import issuesetUtils from "common/utils/issues/issueset_utils";

const styles = {
  actions: {
    zIndex: 0,
  },
  dialogContent: {
    position: "absolute",
    left: "50%",
    top: "30%",
    transform: "translate(-50%, -30%)",
  },
};

class DuplicateIssueDialog extends React.Component {
  state = {issuesets: []};
  render() {
    const {issues} = this.props;
    return (
      <Dialog
        title={`Duplicating ${issues.length} issue${
          issues.length > 1 ? "s" : ""
        }`}
        className="dialog"
        onDismiss={this.props.onDismiss}
        open={true}
        actions={this.getActions()}
        bodyStyle={{overflowY: "inherit"}}
        contentStyle={styles.dialogContent}
      >
        <div style={{marginBottom: "0.5rem"}}>
          Select which issuesets to duplicate into:
        </div>
        <Select
          className="issuesets"
          valueKey="id"
          labelKey="name"
          multi={true}
          removeSelected={true}
          clearable={true}
          backspaceRemoves={false}
          deleteRemoves={false}
          closeOnSelect={false}
          value={this.state.issuesets}
          allowCreate={false}
          options={this.getIssuesetOptions()}
          onChange={this.onIssuesetsChange}
          wrapperStyle={{width: "100%"}}
        />
      </Dialog>
    );
  }

  getActions = () => {
    return [
      <FlatButton
        label="Cancel"
        key="duplicate-issue-dialog-cancel-button"
        secondary={true}
        onClick={this.props.onDismiss}
        style={styles.actions}
      />,
      <FlatButton
        label="Duplicate"
        key="duplicate-issue-dialog-duplicate-button"
        primary={true}
        onClick={this.onDuplicate}
        className="duplicate-button"
        style={styles.actions}
      />,
    ];
  };

  getIssuesetOptions = () => {
    const issuesetsById = issuesetUtils.getIssuesetsById(
      this.props.contractTypesById,
    );
    const issuesetIds = Object.keys(issuesetsById).map(issuesetId =>
      parseInt(issuesetId, 10),
    );
    return issuesetUtils.constructIssuesetSelectorItems(
      issuesetIds,
      issuesetsById,
    );
  };

  onIssuesetsChange = issuesets => this.setState(() => ({issuesets}));

  onDuplicate = () => {
    const {issuesets} = this.state;
    const {issuesetFilteredItemsIds, onIssuesetFilterConfirm} = this.props;
    const duplication_issuesets = [];
    if (issuesets.length > 0) {
      issuesets.forEach(is => duplication_issuesets.push(is.id));
    }
    const data = {
      duplicate_issues: this.props.issues.map(is => is.id),
      duplication_issuesets,
    };
    if (issuesetFilteredItemsIds && issuesetFilteredItemsIds.length !== 0) {
      const filteredIssuesets = _.uniq([
        ...issuesetFilteredItemsIds,
        ...duplication_issuesets,
      ]);
      onIssuesetFilterConfirm(filteredIssuesets);
    }
    this.props.addIssue(data);
    this.props.onDismiss();
  };
}

export default DuplicateIssueDialog;
