import React from "react";
import PropTypes from "prop-types";

import {Toolbar, ToolbarGroup, ToolbarTitle} from "material-ui/Toolbar";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";

import PermissionGroupsItem from "./permission_groups_item";
import CreatePermissionGroupDialog from "./create_permission_group_dialog";
import ManagePermissionGroupDialog from "./manage_permission_group_dialog";

const styles = {
  inner: {
    padding: "1em 2em",
  },
};

class PermissionsComponent extends React.Component {
  state = {createDialogShown: false, manageDialogShown: false, groupId: null};

  onShowManageDialog = groupId => {
    this.setState(() => ({
      manageDialogShown: true,
      groupId,
    }));
  };

  onHideManageDialog = () => {
    this.setState(() => ({
      manageDialogShown: false,
      groupId: null,
    }));
  };

  onShowCreateDialog = () => this.setState(() => ({createDialogShown: true}));
  onHideCreateDialog = () => this.setState(() => ({createDialogShown: false}));

  render() {
    return (
      <div className="app-page">
        <Toolbar className="app-toolbar">
          <ToolbarGroup>
            <ToolbarTitle text="Permissions" />
          </ToolbarGroup>
          <ToolbarGroup>
            <FloatingActionButton mini={true} onClick={this.onShowCreateDialog}>
              <ContentAdd />
            </FloatingActionButton>
          </ToolbarGroup>
        </Toolbar>
        <div className="app-body" style={styles.inner}>
          <table style={{borderCollapse: "collapse", width: "100%"}}>
            <thead>
              <tr style={{height: "2.2em"}}>
                <th style={{width: "2em"}}>Id</th>
                <th style={{width: "20em"}}>Name</th>
                <th style={{width: "3em", whiteSpace: "nowrap"}}>
                  # Permission
                </th>
                <th style={{width: "20em"}}>Creation Date</th>
              </tr>
            </thead>
            <tbody>{this.renderPermissionGroupsItems()}</tbody>
          </table>
        </div>
        <CreatePermissionGroupDialog
          usedNames={this.props.permission_groups.map(group => group.name)}
          onHideCreateDialog={this.onHideCreateDialog}
          createDialogShown={this.state.createDialogShown}
          addPermissionGroup={this.props.addPermissionGroup}
        />
        <ManagePermissionGroupDialog
          manageDialogShown={this.state.manageDialogShown}
          onHideManageDialog={this.onHideManageDialog}
          group={this.selectedGroup()}
          availablePermissions={this.availablePermissions()}
          usedPermissions={this.usedPermissions()}
          addPermissionGroupPermission={this.props.addPermissionGroupPermission}
          deletePermissionGroupPermission={
            this.props.deletePermissionGroupPermission
          }
        />
      </div>
    );
  }

  renderPermissionGroupsItems() {
    const {permission_groups} = this.props;
    return permission_groups.map((group, index) => (
      <PermissionGroupsItem
        key={`${group.id}-${index}`}
        group={group}
        usedPermissionsLength={this.usedPermissionIds(group.id).length}
        user={this.props.user}
        isUsedGroupName={this.isUsedGroupName}
        onShowManageDialog={this.onShowManageDialog}
        isLastItem={index === permission_groups.length - 1}
        updatePermissionGroup={this.props.updatePermissionGroup}
      />
    ));
  }

  isUsedGroupName = name => {
    return this.props.permission_groups.map(group => group.name).includes(name);
  };

  selectedGroup = () => {
    if (this.state.groupId) {
      const {permission_groups} = this.props;
      for (let i = 0; i < permission_groups.length; i++) {
        const group = permission_groups[i];
        if (group.id === this.state.groupId) {
          return group;
        }
      }
    }
    return null;
  };

  usedPermissionIds = _groupId => {
    const list = [];
    const groupId = _groupId || this.state.groupId;
    if (groupId) {
      const rows = this.props.permission_group_permissions;
      rows.forEach(({permission_group_id, permission_id}) => {
        if (permission_group_id === groupId) {
          list.push(permission_id);
        }
      });
    }
    return list;
  };

  availablePermissions = () => {
    return this.filterPermissions(false);
  };

  usedPermissions = () => {
    return this.filterPermissions();
  };

  filterPermissions = (isIncluded = true) => {
    const ids = this.usedPermissionIds();
    return this.props.permissions.filter(({id}) => {
      return ids.includes(id) === isIncluded;
    });
  };
}

PermissionsComponent.propTypes = {
  user: PropTypes.object.isRequired,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  permission_groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      creation_date: PropTypes.string.isRequired,
    }),
  ).isRequired,
  permission_group_permissions: PropTypes.arrayOf(
    PropTypes.shape({
      permission_group_id: PropTypes.number.isRequired,
      permission_id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  addPermissionGroup: PropTypes.func.isRequired,
  updatePermissionGroup: PropTypes.func.isRequired,
  addPermissionGroupPermission: PropTypes.func.isRequired,
  deletePermissionGroupPermission: PropTypes.func.isRequired,
};

export default PermissionsComponent;
export {PermissionsComponent};
