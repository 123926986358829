import React from "react";

import ArrowRightIcon from "material-ui/svg-icons/hardware/keyboard-arrow-right";
import ArrowLeftIcon from "material-ui/svg-icons/hardware/keyboard-arrow-left";

import * as colors from "material-ui/styles/colors";

const styles = {
  main: {
    display: "flex",
    alignItems: "center",
    cursor: "col-resize",
    transition: "border .5s",
  },
  icon: {
    height: "24px",
    width: "24px",
    color: colors.blue500,
    transition: "none",
  },
};

class ResizeLeftPanelButton extends React.Component {
  state = {isShown: false};

  showButton = () => this.setState(() => ({isShown: true}));
  hideButton = () => this.setState(() => ({isShown: false}));

  triggerTab = () => {
    if (!this.props.currentTab) {
      return this.props.setAndShowFirstTab();
    }
    return this.props.triggerTabShow();
  };
  render() {
    return (
      <div
        style={{
          ...styles.main,
          borderLeft: this.state.isShown
            ? "3px solid #2196F3"
            : "3px solid white",
        }}
        onMouseEnter={this.showButton}
        onMouseLeave={this.hideButton}
        onClick={this.triggerTab}
      >
        {this.props.isTabShown ? (
          <ArrowLeftIcon
            style={{
              ...styles.icon,
              visibility: this.state.isShown ? "visible" : "hidden",
            }}
          />
        ) : (
          <ArrowRightIcon
            style={{
              ...styles.icon,
              visibility: this.state.isShown ? "visible" : "hidden",
            }}
          />
        )}
      </div>
    );
  }
}

export default ResizeLeftPanelButton;
