import React from "react";
import _ from "lodash";

import Paper from "material-ui/Paper";
import CircularProgress from "material-ui/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import * as colors from "material-ui/styles/colors";

import DocumentSelector from "./document_selector";
import Checklist from "common_components/checklist";
import MenuHeading from "common_components/menu_heading";
import HeaderTitle from "common_components/checklist/header_title";
import RefreshIssuesButton from "common_components/document/refresh_issues_button";
import SectionSelector from "common_components/checklist/section_selector";

import getPositiveReasonData from "common/utils/issues/reason/get_positive_reason_data";
import hasSingleTemplate from "utils/issues/has_single_template";

import {filterOutstanding} from "utils/issues/filter_issues";
import issuesetUtils from "common/utils/issues/issueset_utils";

const styles = {
  rootDiv: {
    display: "flex",
    flexDirection: "column",
    width: "26rem",
    zIndex: "0",
    height: "100%",
    flexGrow: 1,
    flexShrink: 0,
  },
  goToMasterButton: {
    background: "#2096f3",
    color: "#fff",
    borderRadius: 3,
    fontWeight: 300,
    fontSize: 13,
    padding: "1px 6px",
    cursor: "pointer",
  },
  topMenu: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  rightPanel: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: "#232e38",
  },
  checklistAddIssueIcon: {
    marginLeft: ".3em",
    color: "rgb(167, 171, 175)",
    cursor: "pointer",
  },
  menuHeadingBlock: {
    flexShrink: 0,
    borderBottom: "1px solid #4f575f",
    color: "#fff",
  },
};

class ChecklistPanel extends React.Component {
  render() {
    return (
      <Paper style={styles.rootDiv}>
        <DocumentSelector
          contractTypesById={this.props.contractTypesById}
          showAllIssuesets={true}
          organisationId={this.props.organisationId}
          open={this.props.isDocumentSelectorOpen}
          onMenuOpen={this.props.triggerDocumentSelectorOpen}
          issue={this.props.issue}
          projects={this.props.projects}
          onProjectChange={this.props.onProjectChange}
          selectedProject={this.props.selectedProject}
          documents={this.props.documents}
          selectedDocument={this.props.selectedDocument}
          onDocumentChange={this.props.onDocumentChange}
          onSelectDocument={this.props.onSelectDocument}
          areIssuesCollapsed={this.props.areIssuesCollapsed}
          collapseIssues={this.props.collapseIssues}
          currentIssueset={this.props.currentIssueset}
          isIssueCorrectnessPanelShown={this.props.isIssueCorrectnessPanelShown}
          showIssueCorrectnessPanel={this.props.showIssueCorrectnessPanel}
          clearProjectAndDocument={this.props.unselectProjectAndDocument}
          clearDocument={this.props.unselectDocument}
          updateDocumentIssues={this.props.updateDocumentIssues}
          reprocessDocumentIssue={this.props.reprocessDocumentIssue}
          showDisplayName={this.props.showDisplayName}
          showArchivedIssues={this.props.showArchivedIssues}
          switchShowDisplayName={this.props.switchShowDisplayName}
          switchShowArchivedIssues={this.props.switchShowArchivedIssues}
          findIssueJobsAmount={this.props.findIssueJobsAmount}
          classifyTopicparameterJobsAmount={
            this.props.classifyTopicparameterJobsAmount
          }
          updateFindIssueJobsAmount={this.props.updateFindIssueJobsAmount}
          onFetchDocument={this.props.onFetchDocument}
        />
        {this.renderChecklist()}
      </Paper>
    );
  }

  renderChecklist = () => {
    if (this.props.isDocumentFetchRequestPending) {
      return (
        <div
          style={{
            height: "30rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={25} thickness={2.5} />
        </div>
      );
    }
    if (
      !this.props.selectedProject ||
      !this.props.selectedDocument ||
      !this.props.project ||
      !this.props.document ||
      !this.props.documentClauses ||
      !this.props.issues
    ) {
      return;
    }
    const {
      document,
      project,
      documentClauses,
      topicsById,
      areIssuesCollapsed,
      currentIssueset,
      viewMode,
      issues,
      groupedIssues,
    } = this.props;
    const outstandingIssues = issues
      .map(issue => {
        const reasons = filterOutstanding(issue);
        return [issue, reasons];
      })
      .filter(([, reasons]) => reasons.length > 0);
    const showIssuesInChecklist = (project || {}).show_issues_in_checklist;
    const positiveReasonData = getPositiveReasonData(documentClauses);
    let currentIssuesetItem;
    if (currentIssueset && project && this.props.contractTypesById) {
      const defaultContract = this.props.contractTypesById[
        project.default_contract_type.id
      ];
      currentIssuesetItem = defaultContract.issuesets.find(
        ({id}) => id === currentIssueset,
      );
    }
    const isDuplicateOnMaster = _.get(
      currentIssuesetItem,
      "is_duplicate_on_master",
      false,
    );
    return (
      <div style={styles.topMenu}>
        <div style={styles.rightPanel}>
          <MenuHeading
            containerStyle={styles.menuHeadingBlock}
            bottom={this.getBottomPanel(
              isDuplicateOnMaster,
              currentIssuesetItem,
            )}
            right={
              <AddIcon
                onClick={this.props.showAddIssueDialog}
                style={styles.checklistAddIssueIcon}
              />
            }
          >
            {this.props.user.is_admin && (
              <RefreshIssuesButton
                document={document}
                project={project}
                runDocumentIssuesFind={this.props.updateDocumentIssues}
                styles={{marginRight: 4}}
              />
            )}
            <HeaderTitle
              useAllIssuesets={true}
              hideFrontPage={true}
              project={project}
              projects={this.props.projects}
              onProjectChange={this.props.onProjectChange}
              onDocumentChange={this.props.onDocumentChange}
              document={document}
              viewMode={viewMode}
              onViewModeChange={this.props.onViewModeChange}
              currentIssueset={currentIssueset}
              onCurrentIssuesetChange={this.props.onCurrentIssuesetChange}
              contractTypesById={this.props.contractTypesById}
              showSelectChecklistDialog={null}
              getIssuesetsFromContractTypes={true}
              isIssuesetContractTypeNameHidden={false}
              user={this.props.user}
              issue={this.props.issue}
            />
          </MenuHeading>
          {project.should_show_per_schedule_analysis && (
            <SectionSelector
              isFlipChecklistIcons={false}
              sections={this.props.documentSections}
              selectedChecklistSectionId={this.props.selectedChecklistSectionId}
              onSelectedChecklistSectionIdChange={
                this.props.onSelectedChecklistSectionIdChange
              }
              document={document}
              issues={this.props.documentIssues}
              runDocumentIssuesFind={this.props.updateDocumentIssues}
            />
          )}
          <div
            style={{
              overflow: "auto",
              flexGrow: 1,
            }}
          >
            <Checklist
              user={this.props.user}
              project={project}
              issues={issues}
              groupedIssues={groupedIssues}
              outstandingIssues={outstandingIssues}
              areIssuesCollapsed={areIssuesCollapsed}
              unselectIssue={() => null}
              updateDocumentIssue={this.props.updateDocumentIssue}
              documentClauses={documentClauses}
              documentClauseparts={this.props.documentClauseparts}
              topicsById={topicsById}
              document={document}
              issueDetailData={this.props.issueDetailData}
              updateIssueDetail={this.props.updateIssueDetail}
              showIssuesInChecklist={showIssuesInChecklist}
              viewMode={viewMode}
              showClausesWithoutTopics={false}
              positiveReasonData={positiveReasonData}
              onIssueCheckboxCheck={this.props.onIssueCheckboxCheck}
              currentIssueset={currentIssueset}
              currentIssuesetItem={this.props.currentIssuesetItem}
              location={this.props.location}
              openGroupsWithIssuesByDefault={true}
              issueIdToHighlight={this.props.issue.id}
              showIssue={this.props.showIssueDetail}
              showIssueDetail={this.props.showIssue}
              displayHiddenIssues={this.props.displayHiddenIssues}
              masterIssuesetAmount={this.props.masterIssuesetAmount}
              contractTypesById={this.props.contractTypesById}
              issuesById={this.props.issuesById}
              selectedReport={this.props.selectedReport}
              correctDocumentIssueManually={
                this.props.correctDocumentIssueManually
              }
              preventMemo={true}
            />
          </div>
        </div>
      </div>
    );
  };

  getBottomPanel = (isDuplicateOnMaster, currentIssuesetItem) => {
    const panelItems = [];
    if (isDuplicateOnMaster) {
      panelItems.push(
        <div key="go-to-master-button" style={{paddingLeft: 52}}>
          <span
            style={styles.goToMasterButton}
            onClick={this.onGoToMaster(currentIssuesetItem)}
          >
            Go to Master
          </span>
        </div>,
      );
    }
    const issuesetsById = issuesetUtils.getIssuesetsById(
      this.props.contractTypesById,
    );
    const currentIssueset = issuesetsById[this.props.currentIssueset];
    const totalIssues =
      currentIssueset &&
      currentIssueset.issues &&
      currentIssueset.issues.length;

    const issuesStats = this.getGroupedIssuesStats(this.props.groupedIssues);
    panelItems.push(
      <div
        key="issues-counts"
        style={{marginLeft: "auto", color: colors.grey600}}
      >
        {`# ${totalIssues} (${issuesStats.visible} + ${issuesStats.hidden})`}
      </div>,
    );
    if (panelItems.length > 0) {
      return <div style={{display: "flex"}}>{panelItems}</div>;
    }
  };

  onGoToMaster = currentIssuesetItem => () => {
    const contractType = this.props.contractTypesById[
      currentIssuesetItem.contract_type_id
    ];
    const masterIssueset = contractType.issuesets.find(
      issueset =>
        issueset.is_duplicate_on_master === false &&
        issueset.master_id === currentIssuesetItem.master_id,
    );
    if (masterIssueset) {
      this.props.pushLocationSearchParams({
        issueset: masterIssueset.id,
        ...(this.props.editLevel === "client"
          ? {
              edit_level: hasSingleTemplate(this.props.issue)
                ? "base"
                : "template",
            }
          : {}),
      });
    }
  };

  getGroupedIssuesStats = groupedIssues => {
    const stats = {visible: 0, hidden: 0};
    groupedIssues.forEach(group => {
      group.item.forEach(issue => {
        if (issue.subissueData && issue.subissueData.subissues) {
          issue.subissueData.subissues.forEach(subissue => {
            this.updateStats(subissue, stats);
          });
        }
        if (issue.underlyingIssues) {
          issue.underlyingIssues.forEach(subissue => {
            this.updateStats(subissue, stats);
          });
        } else {
          this.updateStats(issue, stats);
        }
      });
    });
    return stats;
  };

  updateStats(issue, stats) {
    if (issue.shouldBeHidden) {
      stats.hidden += 1;
    } else {
      stats.visible += 1;
    }
  }
}

export default ChecklistPanel;
