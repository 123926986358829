import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import styles from "./styles";

export default class Th extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  render() {
    const {isSortable, isSorted, sortDirection, onSort} = this.props;
    const props = _.omit(this.props, [
      ..._.keys(Th.propTypes),
      "children",
      "style",
      "onMouseEnter",
      "onMouseLeave",
    ]);
    const style = {
      ...styles.headerCell,
      ...(this.props.isSticky && styles.stickyHeaderCell),
      ...this.props.style,
      ...(this.state.isHovered && this.props.hoverStyle),
    };
    const shouldShowArrow = isSortable && isSorted;
    return (
      <th
        style={style}
        onMouseEnter={event => {
          if (_.isFunction(this.props.onMouseEnter)) {
            this.props.onMouseEnter(event);
          }
          this.setState({isHovered: true});
        }}
        onMouseLeave={event => {
          if (_.isFunction(this.props.onMouseLeave)) {
            this.props.onMouseLeave(event);
          }
          this.setState({isHovered: false});
        }}
        {...props}
      >
        <div
          style={{
            ...styles.headerCellContainer,
            ...(this.props.headerCellStyle ? this.props.headerCellStyle : {}),
            ...this.props.containerStyle,
          }}
        >
          <div
            style={{
              ...styles.headerCellInner,
              ...(isSortable && styles.headerCellInnerSortable),
            }}
            {...isSortable && {
              onClick: onSort,
            }}
          >
            {this.props.handleChildren(this.props.children, this)}
            {shouldShowArrow && this.renderArrow(sortDirection)}
          </div>
        </div>
      </th>
    );
  }

  renderArrow(
    direction = "asc",
    triangleWidth = 6,
    triangleHeight = 5,
    lineWidth = 2,
    lineHeight = 9,
  ) {
    const {primary1Color} = this.context.muiTheme.palette;
    const width = triangleWidth;
    const height = triangleHeight + lineHeight;
    return (
      <svg
        viewBox={`0 0 ${width} ${height}`}
        fill={primary1Color}
        style={{
          width,
          height,
          marginLeft: 5,
        }}
      >
        <path
          d={(() => {
            switch (direction) {
              case "asc":
                return [
                  `M0 ${triangleHeight}`,
                  `L${triangleWidth / 2} 0`,
                  `L${triangleWidth} ${triangleHeight}`,
                  `L${triangleWidth / 2 + lineWidth / 2} ${triangleHeight}`,
                  `L${triangleWidth / 2 + lineWidth / 2} ${height}`,
                  `L${triangleWidth / 2 - lineWidth / 2} ${height}`,
                  `L${triangleWidth / 2 - lineWidth / 2} ${triangleHeight}`,
                  "Z",
                ].join(" ");
              case "desc":
                return [
                  `M${triangleWidth} ${lineHeight}`,
                  `L${triangleWidth / 2} ${height}`,
                  `L0 ${lineHeight}`,
                  `L${triangleWidth / 2 - lineWidth / 2} ${lineHeight}`,
                  `L${triangleWidth / 2 - lineWidth / 2} 0`,
                  `L${triangleWidth / 2 + lineWidth / 2} 0`,
                  `L${triangleWidth / 2 + lineWidth / 2} ${lineHeight}`,
                  "Z",
                ].join(" ");
            }
          })()}
        />
      </svg>
    );
  }
}

Th.contextTypes = {
  muiTheme: PropTypes.object.isRequired,
};

Th.defaultProps = {
  isRowHovered: false,
  hoverStyle: {},
  handleChildren: children => children,
  isSortable: null,
  isSorted: false,
  sortDirection: "asc",
  onSort: () => {},
  isSticky: false,
  headerCellStyle: {},
};

Th.propTypes = {
  isRowHovered: PropTypes.bool.isRequired,
  hoverStyle: PropTypes.object.isRequired,
  handleChildren: PropTypes.func.isRequired,
  isSortable: PropTypes.bool,
  isSorted: PropTypes.bool.isRequired,
  sortDirection: PropTypes.oneOf(["asc", "desc"]),
  onSort: PropTypes.func.isRequired,
  isSticky: PropTypes.bool.isRequired,
  headerCellStyle: PropTypes.object,
  containerStyle: PropTypes.object,
};
