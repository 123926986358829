import romanNumerals from "roman-numerals";

export default function(counterType, index) {
  let result;
  switch (counterType) {
    case "LOWER_LETTER":
    case "UPPER_LETTER":
      result = String.fromCharCode(97 + index);
      break;
    case "LOWER_ROMAN":
    case "UPPER_ROMAN":
      result = romanNumerals.toRoman(index + 1).toLowerCase();
      break;
    case "DECIMAL":
    default:
      result = (index + 1).toString();
  }
  if (counterType && counterType.substring(0, 5) === "UPPER") {
    return result.toUpperCase();
  }
  return result;
}
