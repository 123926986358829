import React from "react";

import {IssueIcon} from "constants/icons";

export default function ErrorIcon({user, document}) {
  if (user.is_admin && document.parse_diff_length > 0) {
    return (
      <IssueIcon
        title={`${document.parse_diff_length} characters are in error`}
        style={{
          color: "#f00",
          marginLeft: -2,
          marginRight: 12,
        }}
      />
    );
  }
  return <div style={{width: "34px"}} />;
}
