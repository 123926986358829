import React from "react";
import PropTypes from "prop-types";
import radium from "radium";

import RightIcon from "material-ui/svg-icons/navigation/chevron-right";

import IconButton from "material-ui/IconButton";
import {Link} from "react-router";

import FixedTooltip from "common_components/fixed_tooltip";
import TopicSummary from "./topic_summary";
import getHasMatchBorderStyle from "./utils/get_has_match_border_style";
import isCorrect from "./utils/is_correct";

const styles = {
  item: {
    display: "flex",
  },
  note: {
    fontSize: "0.8em",
    backgroundColor: "rgba(255, 78, 0, 0.08)",
  },
  link: {
    borderLeft: "1px solid rgba(0, 126, 255, 0.24)",
    verticalAlign: "middle",
    ":hover": {
      backgroundColor: "#d8eafd",
    },

    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};

function TopicSelectItem(props) {
  const {option} = props;
  function onClick() {
    props.onClick(option);
  }
  const isTopicCorrect = isCorrect(option.clauseTopic.has_match, false);
  const content = (
    <div
      style={{
        ...styles.item,
        ...(option.topic.startsWith("NOTE:") ? styles.note : {}),
        ...getHasMatchBorderStyle(isTopicCorrect, false),
      }}
    >
      <TopicSummary
        topic={{
          name: option.topic,
          ...option,
        }}
        onClick={onClick}
        showParametersButton={true}
        clause={props.clause}
        onTopicparameterValuesUpdate={props.onTopicparameterValuesUpdate}
        roles={props.roles}
      />
      <span style={styles.link}>
        {typeof option.value === "number" ? (
          <Link
            to={{
              pathname: `/organisation/${props.organisationId}/topic/${
                option.value
              }/detail`,
            }}
            style={{textDecoration: "none"}}
          >
            <IconButton
              className="go-to-topic"
              style={{
                height: "0.5em",
                width: "20px",
                padding: 0,
                color: "red",
                marginTop: "-2px",
                verticalAlign: "top",
                zIndex: 0,
              }}
            >
              <RightIcon color="rgba(0, 126, 255, 1)" />
            </IconButton>
          </Link>
        ) : null}
      </span>
    </div>
  );
  return isTopicCorrect === undefined ? (
    content
  ) : (
    <FixedTooltip
      content={
        isTopicCorrect
          ? "Topic is correctly applied"
          : "Topic has been incorrectly applied"
      }
    >
      {content}
    </FixedTooltip>
  );
}

TopicSelectItem.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    topic: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }),
  organisationId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default radium(TopicSelectItem);
