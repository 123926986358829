import requestor from "requestor";

import BaseRoute from "utils/base_route";
import getRouteIds from "utils/get_route_ids";

import logsFetchAction from "modules/logs/actions/logs_fetch";
import searchClearAction from "modules/search/actions/search_clear";

import LogsContainer from "./containers/logs";

export default class Logs extends BaseRoute {
  constructor(store) {
    super("logs", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, LogsContainer);
  }

  fetchActiveData(props) {
    this.fetchLogs(props);
  }

  fetchLogs(props) {
    const {organisationId} = getRouteIds(props.router);
    if (organisationId) {
      this.store.dispatch(logsFetchAction(requestor)(organisationId));
    }
  }

  clearActiveData() {
    this.clearSearch();
  }

  clearSearch() {
    this.store.dispatch(searchClearAction());
  }
}
