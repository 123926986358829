import React, {useState, useEffect} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import RaisedButton from "material-ui/RaisedButton";
import {FlushAssistantsResponse} from "../containers/workflow_list";

type Props = {
  open: boolean;
  onClose: () => void;
  onFlushAssistants: () => Promise<FlushAssistantsResponse>;
};

const FlushAssistantsDialog = ({open, onClose, onFlushAssistants}: Props) => {
  const [isDeletingAssistants, setIsDeletingAssistants] = useState(false);
  const [deleteSummary, setDeleteSummary] = useState<
    FlushAssistantsResponse["value"] | null
  >(null);

  useEffect(() => {
    if (open) {
      setDeleteSummary(null);
      setIsDeletingAssistants(false);
    }
  }, [open]);

  const handleFlushAssistants = async () => {
    setIsDeletingAssistants(true);
    const response = await onFlushAssistants();
    setIsDeletingAssistants(false);
    setDeleteSummary(response.value);
  };

  const renderLoading = () => (
    <div style={{display: "flex", alignItems: "center", overflow: "hidden"}}>
      <CircularProgress />
      <span style={{marginLeft: "1em"}}>Deleting all assistants...</span>
    </div>
  );

  const renderSummary = () => (
    <div>
      <p>Deleted Assistants: {deleteSummary?.deleted_assistants}</p>
      <p>Deleted files: {deleteSummary?.deleted_files}</p>
      <p>Deleted vector stores: {deleteSummary?.deleted_vector_stores}</p>
      {deleteSummary?.errors && deleteSummary.errors.length > 0 && (
        <div>
          <h3>Errors:</h3>
          <ul>
            {deleteSummary.errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  const renderConfirmation = () => (
    <p>Are you sure you want to delete all assistants and associated data?</p>
  );

  const renderDialogActions = () => {
    if (isDeletingAssistants || deleteSummary) {
      return (
        <RaisedButton
          onClick={onClose}
          color="primary"
          disabled={isDeletingAssistants}
        >
          Close
        </RaisedButton>
      );
    }

    return (
      <>
        <RaisedButton
          secondary={true}
          style={{margin: "0 .3em"}}
          onClick={onClose}
          color="primary"
          disabled={isDeletingAssistants}
        >
          No
        </RaisedButton>
        <RaisedButton
          primary={true}
          style={{margin: "0 .3em"}}
          onClick={handleFlushAssistants}
          color="primary"
        >
          Yes
        </RaisedButton>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={!isDeletingAssistants ? onClose : undefined}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Flush All Assistants</DialogTitle>
      <DialogContent>
        {isDeletingAssistants
          ? renderLoading()
          : deleteSummary
          ? renderSummary()
          : renderConfirmation()}
      </DialogContent>
      <DialogActions>{renderDialogActions()}</DialogActions>
    </Dialog>
  );
};

export default FlushAssistantsDialog;
