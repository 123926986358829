import _ from "underscore";
import requestor from "requestor";
import React, {useState, useEffect} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import CircularProgress from "@material-ui/core/CircularProgress";

import documentsFetchAction from "modules/searcher/actions/documents_fetch";
import documentsClearAction from "modules/documents/actions/documents_clear";
import runQueryAction from "modules/searcher/actions/query_run";
import clearQueryAction from "modules/searcher/actions/query_clear";

import {parseQuery, buildQuery} from "utils/uri";
import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import SearcherComponent from "../components/searcher";
import {
  serialiseAlgorithm,
  deserialiseAlgorithm,
} from "../util/serialise_algorithm";

import {RouterState, InjectedRouter} from "react-router/lib/Router";
import {User} from "common/types/user";
import {OrganisationId, Organisation} from "common/types/organisation";
import {ProjectId, Project} from "common/types/project";
import {DocumentId} from "common/types/document";

import {Document, SearchResult, runQuery} from "../types";
import AlgorithmSetting from "modules/searcher/types/algorithm_setting";
import EmbeddingTopic from "modules/searcher/types/embedding_topic";

interface Props {
  user: User;
  router: RouterState & InjectedRouter;
  location: Location;
  params: {
    organisationId: string;
  };
  dispatch: (args: unknown) => unknown;
  organisation: Organisation;
  projects: Project[];
  documents: Document[];
  fetchDocuments: (
    organisationId: OrganisationId,
    projectId: ProjectId,
  ) => void;
  clearDocuments: (
    organisationId: OrganisationId,
    projectId: ProjectId,
  ) => void;

  searchResults: SearchResult[];
  embeddingTopics: EmbeddingTopic[];

  runQuery: runQuery;
  clearQuery: () => void;
}

function getDefaultProject(projects: Project[]): ProjectId {
  return Array.isArray(projects)
    ? projects?.find(project => project.is_default)?.id ?? -1
    : -1;
}

function getAlgorithmList(
  algorithmState: Record<string, boolean>,
  embeddingTopics: EmbeddingTopic[],
): AlgorithmSetting[] {
  return Object.entries(algorithmState)
    .filter(([, on]) => on)
    .map(([item]) => {
      const algorithmWithoutName = deserialiseAlgorithm(item);
      if (algorithmWithoutName.type === "embedding") {
        const topicId = parseInt(
          algorithmWithoutName.options.topic_id as string,
          10,
        );
        const embeddingTopic = embeddingTopics.find(
          topic => topic.id === topicId,
        );
        return {
          ...algorithmWithoutName,
          options: {
            ...algorithmWithoutName.options,
            topic_name: embeddingTopic?.name,
          },
        };
      }
      return algorithmWithoutName;
    });
}

function readDocumentIds(queryParams) {
  return Array.isArray(queryParams.document)
    ? queryParams.document.map(documentId => parseInt(documentId, 10))
    : [parseInt(queryParams.document, 10)];
}

function SearcherContainer({
  user,
  router,
  projects,
  documents,
  fetchDocuments,
  clearDocuments,
  runQuery,
  clearQuery,
  searchResults,
  embeddingTopics,
  location,
  dispatch,
}: Props) {
  const organisationId = parseInt(router.params.organisationId, 10);
  const queryParams = parseQuery(router.location.search);
  const [projectId, setProjectId] = useState<ProjectId>(
    queryParams.project
      ? parseInt(queryParams.project, 10)
      : getDefaultProject(projects),
  );
  const [documentIds, setDocumentIds] = useState<DocumentId[] | null>(
    readDocumentIds(queryParams) ?? null,
  );
  const [query, setQuery] = useState(queryParams.query || "");
  const [algorithmState, setAlgorithmState] = useState<Record<string, boolean>>(
    queryParams.algorithm
      ? Array.isArray(queryParams.algorithm)
        ? Object.fromEntries(queryParams.algorithm.map(item => [item, true]))
        : {[queryParams.algorithm]: true}
      : {text: true},
  );
  const [algorithmsUsed, setAlgorithmsUsed] = useState<string[] | null>(null);
  const [queryRunning, setQueryRunning] = useState(false);

  const updateValue = (key, value) => {
    const {pathname, search} = location;
    const params = parseQuery(search);

    const newSearch = buildQuery(
      value ? {...params, [key]: value} : _.omit(params, key),
    );
    if (search !== newSearch) {
      dispatch(push(pathname + newSearch));
    }
  };

  useEffect(() => {
    if (projectId === -1 && projects.length) {
      const newProjectId = getDefaultProject(projects);
      setProjectId(newProjectId);
      updateValue("project", newProjectId);
    }
  }, [projects?.[0]?.id]);
  useEffect(() => {
    if (projectId > 0) {
      clearDocuments(organisationId, projectId);
      fetchDocuments(organisationId, projectId);
    }
  }, [projectId]);
  useEffect(() => {
    if (documents?.[0]?.id) {
      const urlDocumentIds = readDocumentIds(queryParams);
      const urlDocumentMatches = urlDocumentIds?.length
        ? documents
            .filter(document =>
              urlDocumentIds.find(urlDocId => urlDocId === document.id),
            )
            .map(document => document.id)
        : [];
      const newDocumentIds: DocumentId[] = urlDocumentMatches.length
        ? urlDocumentMatches
        : [
            documents.find(document => document.embedding_topics.length)?.id ??
              documents?.[0]?.id,
          ];
      if (newDocumentIds.length) {
        setDocumentIds(newDocumentIds);
        updateValue("document", newDocumentIds);
      }
    }
  }, [documents?.[0]?.id]);

  const permissioner = new Permissioner(user);
  if (
    !(permissioner.hasPermission("get-workflows") || permissioner.isAdmin())
  ) {
    return permissioner.getNoPermissionMessage();
  }

  setTitle("Searcher");

  const shouldRenderContainer = isInitialised([user]);
  if (!shouldRenderContainer || !user.permissions) {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const setProjectIdHandler = projectId => {
    setProjectId(projectId);
    updateValue("project", projectId);
  };

  const setDocumentIdsHandler = documentIds => {
    setDocumentIds(documentIds);
    updateValue("document", documentIds);
  };

  const setQueryHandler = query => {
    setQuery(query);
    updateValue("query", query);
  };

  const runQueryHandler = async () => {
    clearQuery();
    const algorithmList = getAlgorithmList(algorithmState, embeddingTopics);
    setAlgorithmsUsed(
      algorithmList.map(algorithm => serialiseAlgorithm(algorithm)),
    );
    setQueryRunning(true);
    await runQuery(
      organisationId,
      projectId,
      documentIds,
      query,
      getAlgorithmList(algorithmState, embeddingTopics),
    );
    setQueryRunning(false);
  };

  const setAlgorithmStateHandler = (key: string, value: boolean) => {
    const algorithm = deserialiseAlgorithm(key);
    const keyWithoutNames = serialiseAlgorithm({
      ...algorithm,
      options: Object.fromEntries(
        Object.entries(algorithm.options).filter(([key]) => !key.match(/name/)),
      ),
    });
    const newState = {...algorithmState, [keyWithoutNames]: value};
    setAlgorithmState(newState);
    updateValue(
      "algorithm",
      Object.entries(newState)
        .filter(([, on]) => on)
        .map(([key]) => key),
    );
  };

  return (
    <SearcherComponent
      organisationId={organisationId}
      projectId={projectId}
      projects={projects}
      selectProject={setProjectIdHandler}
      documentIds={documentIds}
      documents={documents}
      selectDocuments={setDocumentIdsHandler}
      query={query}
      setQuery={setQueryHandler}
      runQuery={runQueryHandler}
      searchResults={searchResults}
      algorithmsUsed={algorithmsUsed}
      queryRunning={queryRunning}
      embeddingTopics={embeddingTopics}
      algorithmState={algorithmState}
      setAlgorithmState={setAlgorithmStateHandler}
    />
  );
}

function select(state, props) {
  return {
    params: props.params,
    organisation: state.organisation,
    projects: state.projects,
    documents: state.documents,
    searchResults: state.searcher,
    embeddingTopics: state.embeddingTopics,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        fetchDocuments: documentsFetchAction(requestor),
        clearDocuments: documentsClearAction,
        runQuery: runQueryAction(requestor),
        clearQuery: clearQueryAction(),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(SearcherContainer);
