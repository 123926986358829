import basePath from "base_path";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes, {UsergroupActionTypes} from "../constants/action_types";
import {Usergroup} from "common/types/usergroup";

export type DeleteUsergroupAction = {
  type: UsergroupActionTypes["USERGROUP_DELETE"]["SUCCESS"];
  payload: Pick<Usergroup, "id">;
};

export default createReduxPromiseAction(
  ActionTypes.USERGROUP_DELETE,
  (organisationId: number, usergroupId: number) =>
    requestor
      .delete(
        `${basePath}/organisation/${organisationId}/usergroup/${usergroupId}`,
        {withCredentials: true},
      )
      .then(() => ({id: usergroupId})),
);
