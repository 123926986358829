import React from "react";

function HotProjectChip() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0px 6px",
        margin: "0px 4px",
        color: "white",
        background: "#ef5350",
        textDecoration: "underline",
        textDecorationColor: "#ef5350",
        fontSize: 12,
        borderRadius: 16,
        height: 15,
      }}
    >
      Hot
    </div>
  );
}

export default HotProjectChip;
