import basePath from "base_path";
import ActionTypes from "../../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import requestor from "requestor";

export default createReduxPromiseAction(
  ActionTypes.ISSUESET_ADD_TO_ISSUE,
  (organisationId, projectId, issuesetId, issueId, documentId) => {
    return requestor
      .post(
        `${basePath}/organisation/${organisationId}/project/${projectId}` +
          `/document/${documentId}/issue/${issueId}`,
        {issueset_id: issuesetId},
        {withCredentials: true},
      )
      .then(response => response.data)
      .catch(err => err.data);
  },
);
