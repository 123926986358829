import React, {Component} from "react";
import TextField from "material-ui/TextField";
import Select from "react-select";
import _ from "underscore";

import ReactSelectLabel from "common_components/react_select_label";
import TopicSelector from "common_components/topic_selector";

const styles = {
  heading: {
    fontWeight: "bold",
    fontSize: "1.5em",
    marginBottom: "0.3em",
    textAlign: "center",
  },
  textField: {
    width: "100%",
  },
};

const applicationOptions = [
  {value: "positive", label: "positive"},
  {value: "negative", label: "negative"},
  {value: "positive / negative", label: "positive / negative"},
];

class TopicDescription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      examples: "",
      counter_examples: "",
      description: "",
    };
  }

  onTextFieldChange = name => e => {
    this.setState({[name]: e.target.value});
  };

  componentDidMount() {
    const {
      examples,
      counter_examples,
      description,
    } = this.props.topic.description;

    this.setState({examples, counter_examples, description});
  }

  renderTopicSelectors = () => {
    const {topic} = this.props;

    const topics = topic.description.related_topics
      ? this.props.topics.reduce((descriptionTopics, currentTopic) => {
          if (
            topic.description.related_topics.find(
              topicId => topicId === currentTopic.id,
            )
          ) {
            return descriptionTopics.concat(currentTopic);
          }

          return descriptionTopics;
        }, [])
      : [];

    return (
      <TopicSelector
        organisationId={this.props.organisationId}
        topics={this.props.topics}
        topicsById={this.props.topicsById}
        topicCategories={this.props.topicCategories}
        topicCategoriesById={this.props.topicCategoriesById}
        topicTags={this.props.topicTags}
        clause={{
          id: null,
          last_edited: null,
          topics: topics.map(item => ({
            ...item,
            topic_id: item.id,
            is_confirmed: true,
          })),
        }}
        onExistingTopicAdded={this.onExistingTopicAdded()}
        onTopicRemoved={this.onTopicRemoved()}
        onNewTopicAdded={() => null}
        onTopicsReordered={() => null}
        onUnconfirmedTopicsRemoved={() => null}
        onTopicConfirmed={() => null}
        contractTypesById={this.props.contractTypesById}
      />
    );
  };

  onUsageRemoved = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
  ) => () => {
    this.props.onUsageRemoved(
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clausepartLastEdited,
    );
  };

  onUsageConfirmed = (
    projectId,
    documentId,
    sectionId,
    clauseId,
    clausepartId,
    clausepartLastEdited,
  ) => () => {
    this.props.onUsageConfirmed(
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clausepartLastEdited,
    );
  };

  onExistingTopicAdded = _.memoize(
    () => (...args) => {
      const related_topics = Array.isArray(
        this.props.topic.description.related_topics,
      )
        ? this.props.topic.description.related_topics
        : [];

      return this.props.onTopicUpdated({
        ...this.props.topic,
        description: {
          ...this.props.topic.description,
          related_topics: related_topics.concat(args[2]),
        },
      });
    },
    (...args) => JSON.stringify([...args]),
  );

  onTopicRemoved = _.memoize(
    () => (...args) => {
      const related_topics = Array.isArray(
        this.props.topic.description.related_topics,
      )
        ? this.props.topic.description.related_topics
        : [];

      return this.props.onTopicUpdated({
        ...this.props.topic,
        description: {
          ...this.props.topic.description,
          related_topics: related_topics.filter(topicId => topicId !== args[2]),
        },
      });
    },
    (...args) => JSON.stringify([...args]),
  );

  onDescriptionChange = _.memoize(
    () => e => {
      this.props.onUpdateTopicValue(
        ["description", "description"],
        e.target.value,
        {description: {...this.props.topic.description}},
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onExamplesChange = _.memoize(
    () => e => {
      this.props.onUpdateTopicValue(
        ["description", "examples"],
        e.target.value,
        {description: {...this.props.topic.description}},
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onCounterExamplesChange = _.memoize(
    () => e => {
      this.props.onUpdateTopicValue(
        ["description", "counter_examples"],
        e.target.value,
        {description: {...this.props.topic.description}},
      );
    },
    (...args) => JSON.stringify([...args]),
  );

  onApplicationChange = _.memoize(
    () => e => {
      this.props.onUpdateTopicValue(["description", "application"], e.value);
    },
    (...args) => JSON.stringify([...args]),
  );

  render() {
    return (
      <div>
        <h4 style={styles.heading}>Description</h4>

        <TextField
          hintText="Add Description"
          floatingLabelText="Description"
          multiLine={true}
          style={styles.textField}
          value={this.state.description}
          onBlur={this.onDescriptionChange()}
          onChange={this.onTextFieldChange("description")}
        />

        <div style={{marginBottom: "1em"}}>
          <ReactSelectLabel>Category</ReactSelectLabel>
          <Select
            className="category"
            value={this.props.topic.description.application}
            allowCreate={true}
            options={applicationOptions}
            onChange={this.onApplicationChange()}
            menuContainerStyle={{
              zIndex: 99999,
            }}
          />
        </div>

        <div style={{marginBottom: "1em"}}>
          <ReactSelectLabel>Related Topics</ReactSelectLabel>
          {this.renderTopicSelectors()}
        </div>

        <TextField
          hintText="Add Examples Text"
          floatingLabelText="Examples"
          multiLine={true}
          style={styles.textField}
          value={this.state.examples}
          onBlur={this.onExamplesChange()}
          onChange={this.onTextFieldChange("examples")}
        />

        <TextField
          hintText="Add Counter-Examples Text"
          floatingLabelText="Counter-Examples"
          multiLine={true}
          style={styles.textField}
          value={this.state.counter_examples}
          onBlur={this.onCounterExamplesChange()}
          onChange={this.onTextFieldChange("counter_examples")}
        />
      </div>
    );
  }
}

export default TopicDescription;
