import requestor from "requestor";

import BaseRoute from "utils/base_route";
import getRouteIds from "utils/get_route_ids";

import ChildRenderer from "common_components/child_renderer";

import projectFetchAction from "modules/projects/actions/project_fetch";
import formTemplatesFetchAction from "modules/documents/actions/form_templates_fetch";
import rolesFetchAction from "modules/documents/actions/role/roles_fetch";

import projectClearAction from "modules/projects/actions/project_clear";
import documentClearAction from "modules/documents/actions/document_clear";
import formTemplatesClearAction from "modules/documents/actions/form_templates_clear";
import rolesClearAction from "modules/documents/actions/role/roles_clear";

import DocumentList from "routes/document_list";
import DocumentClauses from "routes/document_clauses";
import DocumentDetail from "routes/document_detail";
import DocumentReport from "routes/document_report";
import DocumentCreate from "routes/document_create";
import ComparisonSummaryRoute from "routes/comparison_summary";

export default class DocumentsRoute extends BaseRoute {
  constructor(store) {
    super(":projectId/document", store);
  }

  createChildRoutes() {
    return [
      new DocumentList(this.store),
      new DocumentCreate(this.store),
      new DocumentClauses(this.store),
      new DocumentDetail(this.store),
      new DocumentReport(this.store),
      new ComparisonSummaryRoute(this.store),
    ];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }

  fetchActiveData(props) {
    this.fetchProject(props);
    this.fetchFormTemplates(props);
    this.fetchRoles(props);
  }

  fetchProject(props) {
    const {organisationId, projectId} = getRouteIds(props.router);
    if (projectId) {
      this.store.dispatch(
        projectFetchAction(requestor)(organisationId, projectId),
      );
    }
  }

  fetchFormTemplates(props) {
    const {organisationId, projectId} = getRouteIds(props.router);
    if (projectId) {
      this.store.dispatch(
        formTemplatesFetchAction(requestor)(organisationId, projectId),
      );
    }
  }

  fetchRoles(props) {
    const {organisationId} = getRouteIds(props.router);
    this.store.dispatch(rolesFetchAction(requestor)(organisationId));
  }

  clearActiveData(props) {
    this.clearProject(props);
    this.clearDocuments(props);
    this.clearFormTemplates(props);
    this.clearRoles();
  }

  clearProject() {
    this.store.dispatch(projectClearAction());
  }

  clearDocuments() {
    this.store.dispatch(documentClearAction());
  }

  clearFormTemplates() {
    this.store.dispatch(formTemplatesClearAction());
  }

  clearRoles() {
    this.store.dispatch(rolesClearAction());
  }
}
