import basePath from "base_path";
import createReduxPromiseAction from "utils/create_redux_promise_action";

import ActionTypes from "../constants/action_types";
import readTask from "../utils/read_task";

export default function(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TASK_FETCH,
    (organisationId, taskId) => {
      return requestor
        .get(`${basePath}/organisation/${organisationId}/task/${taskId}`, {
          withCredentials: true,
        })
        .then(response => readTask(response.data));
    },
  );
}
