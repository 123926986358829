import basePath from "base_path";
import ActionTypes, {WorkflowsActionTypes} from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {
  WorkflowId,
  WorkflowInstance,
  WorkflowInstanceId,
} from "common/flowmaster/types/workflow";

export type WorkflowPrerequisiteAddAction = {
  type: WorkflowsActionTypes["WORKFLOW_PREREQUISITE_ADD"]["SUCCESS"];
  payload: {workflow_id: WorkflowId; prerequisites: WorkflowInstance[]};
};

export default function addWorkflowPrerequisite(requestor) {
  return createReduxPromiseAction(
    ActionTypes.WORKFLOW_PREREQUISITE_ADD,
    (
      organisationId: OrganisationId,
      workflowId: WorkflowId,
      workflowInstanceName?: string,
      selectedWorkflowId?: WorkflowId,
      workflowInstanceId?: WorkflowInstanceId,
    ) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/workflow/${workflowId}/prerequisite`,
          {
            ...(workflowInstanceId && {
              workflowInstanceId,
            }),
            ...(selectedWorkflowId && {
              selectedWorkflowId,
            }),
            workflowInstanceName,
          },
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        });
    },
  );
}
