import React from "react";
import {Link} from "react-router";
import PropTypes from "prop-types";

import LinkIcon from "material-ui/svg-icons/content/link";

import getIssueNameWithIssuesets from "../utils/get_issue_name_with_issuesets";
import keyedObjectPropType from "utils/keyed_object_prop_type";
import getIssueTemplatedText from "common/utils/issues/get_issue_templated_text";
import {generalIssueClasses} from "modules/documents/constants/issue_classes";

export default class DocumentIssueItem extends React.Component {
  render() {
    const {
      documentIssue,
      documentClauses,
      topicsById,
      organisationId,
      positiveReasonData,
      documentClauseparts,
      currentIssuesetItem,
      document,
    } = this.props;
    const isImportantIssue =
      generalIssueClasses.indexOf(documentIssue.issue_class_id) !== -1;
    return (
      <div
        className={isImportantIssue ? "main-issue" : "minor-issue"}
        style={{
          padding: "0.5em 0.5em",
          margin: 0,
          backgroundColor: isImportantIssue ? "inherit" : "#ffd",
        }}
      >
        <div style={{display: "flex"}}>
          <Link
            to={{
              pathname: `/organisation/${organisationId}/issue/${
                documentIssue.id
              }`,
            }}
            style={{textDecoration: "none"}}
            onlyActiveOnIndex
          >
            <LinkIcon />
          </Link>
          <div
            className="name"
            style={{fontWeight: "bold", marginTop: "2px", marginLeft: "4px"}}
          >
            {getIssueNameWithIssuesets(documentIssue)}
          </div>
        </div>
        <ul
          className="reason"
          style={{
            fontSize: "0.7em",
            fontStyle: "italic",
            marginTop: "0.5em",
            marginBottom: "0em",
          }}
        >
          {getIssueTemplatedText(
            documentIssue,
            currentIssuesetItem,
            "reason_template",
            documentClauses,
            topicsById,
            positiveReasonData,
            documentClauseparts,
            document?.parties ?? [],
          ).map((str, idx) => <li key={idx}>{str}</li>)}
        </ul>
      </div>
    );
  }
}

DocumentIssueItem.propTypes = {
  documentIssue: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  documentClauses: keyedObjectPropType(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        reference: PropTypes.string.isRequired,
      }),
    ),
  ),
  topicsById: keyedObjectPropType(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};
