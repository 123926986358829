import _ from "underscore";
import doesLevelMatch from "../utils/does_level_match";
import calculateTopics from "utils/topic/calculate_topics";
import React from "react";

import TopicSelector from "common_components/topic_selector";
import TopicSelectItem from "common_components/topic_selector/topic_select_item";
import UnconfirmedItem from "common_components/topic_selector/unconfirmed_item";
import TopicSelectItemRemoved from "common_components/topic_selector/topic_select_item_removed";

import * as Parameters from "plugins/topicparameters";

const styles = {
  topic: {
    color: "#007eff",
    borderWidth: "1px",
    borderStyle: "solid",
    margin: "5px",
    display: "inline-block",
    float: "left",
    backgroundColor: "rgba(0, 126, 255, 0.08)",
  },
  note: {
    fontSize: "0.9em",
    backgroundColor: "rgba(255, 126, 0, 0.08)",
  },
  classicationPending: {
    color: "transparent",
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "default",
    ":hover": {
      color: "#ccc",
      transition: "color 100ms linear",
    },
  },
  removedTopicsContainer: {
    backgroundColor: "white",
    border: "1px solid lightgray",
    borderRadius: "4px",
    margin: 0,
    padding: 0,
  },
  removedTopicsLegend: {
    fontSize: "0.65em",
    color: "gray",
    marginLeft: "10px",
  },

  simpleTopicsGroup: {
    width: "20em",
    fontSize: "0.7em",
    border: "1px solid black",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  simpleTopic: {
    borderBottom: "1px solid #777",
    padding: "0.5em",
    flexGrow: 1,
  },
  simpleTopicName: {
    textIndent: "-1em",
    paddingLeft: "1em",
  },
  simpleTopicParameter: {
    marginLeft: "2em",
    color: "#444",
  },
};

export default class Topics extends React.Component {
  render() {
    const {conjunctionType, topics, type} = this.props.clause;
    const {selectedLevel} = this.props;
    if (!doesLevelMatch(selectedLevel, this.props.clause, this.props.parent)) {
      return null;
    }

    if (
      (!conjunctionType ||
        conjunctionType === "NONE" ||
        type.endsWith("List")) &&
      topics
    ) {
      return this.renderTopicSelector();
    }
    return (
      <span
        key="topic-selector"
        className="topicSelector empty-topic-selector"
        style={this.props.style}
      />
    );
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.isInteractive === nextProps.isInteractive ||
      this.props.showBasicTopicList !== nextProps.showBasicTopicList
    );
  }

  renderTopicSelector() {
    const {clause, showMasks, topicMasks, topicsById} = this.props;
    const {topics, load_state: loadState} = clause;
    if (loadState === 0) {
      return (
        <span
          key="topic-selector"
          className="topicSelector"
          style={styles.classicationPending}
        >
          Classification pending
        </span>
      );
    }
    const allTopics = calculateTopics(
      topics,
      showMasks,
      topicMasks,
      topicsById,
    );

    function distinctTopicsByIsDeleted(topics) {
      const removedTopics = [];
      const notRemovedTopics = [];
      topics.forEach(topic => {
        if (topic.is_deleted && topic.match_source_id === 2) {
          removedTopics.push(topic);
        } else if (!topic.is_deleted) {
          notRemovedTopics.push(topic);
        }
      });
      return {removedTopics, notRemovedTopics};
    }

    const {removedTopics, notRemovedTopics} = distinctTopicsByIsDeleted(
      allTopics,
    );

    if (this.props.showBasicTopicList) {
      return this.renderReadOnlyTopics(notRemovedTopics);
      // return this.renderTopicsViewer(this.props);
    }

    return (
      <div
        onMouseOver={this.props.onHover}
        style={{width: "30%"}}
        onClick={this.props.onShowBasicTopicList}
      >
        <TopicSelector
          {...this.props}
          clause={{
            ...clause,
            topics: notRemovedTopics,
            clause_id: this.props.clauseId,
            document_id: this.props.params.documentId,
            project_id: this.props.params.projectId,
            roles: this.props.document.roles,
          }}
          disabled={this.props.is_deletion}
          onItemClicked={this.onToggleExactMatch}
          onTopicparameterValuesUpdate={this.props.onTopicparameterValuesUpdate}
          topicType="other"
        />
        {removedTopics &&
          removedTopics.length > 0 &&
          this.renderRemovedTopics(removedTopics)}
      </div>
    );
  }

  renderReadOnlyTopics(notRemovedTopics) {
    return (
      <div
        style={styles.simpleTopicsGroup}
        onClick={this.props.onShowBasicTopicList}
      >
        {notRemovedTopics.map((topic, index) =>
          this.renderReadOnlyTopic(topic, index),
        )}
      </div>
    );
  }

  renderReadOnlyTopic(clauseTopic, index) {
    const topic = this.props.topicsById[clauseTopic.topic_id];
    if (!topic) {
      return;
    }
    return (
      <div
        style={{
          backgroundColor: clauseTopic.is_confirmed
            ? index % 2 === 0 ? "#f1f8ff" : "#e2f0ff"
            : index % 2 === 0 ? "#fff2e0" : "#ffe9cb",
          ...styles.simpleTopic,
        }}
      >
        <div key={clauseTopic.topic_id} style={styles.sismpleTopicName}>
          {topic.name}
        </div>
        {clauseTopic.topicparameters.map(param => {
          const values =
            param.actual_values || param.locked_values || param.values || [];
          if (values.length) {
            return (
              <div
                style={styles.simpleTopicParameter}
                key={param.topicparameter_id}
              >
                {param.name} -{" "}
                {values
                  .map(
                    paramValue =>
                      // eslint-disable-next-line import/namespace
                      Parameters[param.parameter_type]
                        ? // eslint-disable-next-line import/namespace
                          Parameters[param.parameter_type].valueRenderer(
                            param,
                            paramValue,
                          )
                        : null,
                  )
                  .join(", ")}
              </div>
            );
          }
          return undefined;
        })}
      </div>
    );
  }

  renderRemovedTopics = removedTopics => {
    const removedTopicsRawData = removedTopics
      .filter(topic => this.props.topicsById[topic.topic_id])
      .map(topic => {
        const clauseTopic = this.props.topicsById[topic.topic_id];
        return {
          organisationId: this.props.organisationId,
          option: {
            clauseTopic:
              topic.has_match !== undefined ? {has_match: topic.has_match} : {},
            value: topic.topic_id,
            topic: clauseTopic.name,
            parameters: topic.topicparameters,
            parameterValues: (clauseTopic && clauseTopic.parameters) || [],
            category: this.props.topicCategoriesById[
              clauseTopic.topiccategory_id
            ].name,
          },
        };
      });
    return (
      <fieldset style={styles.removedTopicsContainer}>
        <legend style={styles.removedTopicsLegend}>Removed Topics</legend>
        {removedTopicsRawData.map(removedTopic => (
          <TopicSelectItemRemoved
            key={removedTopic.option.value}
            organisationId={removedTopic.organisationId}
            option={removedTopic.option}
          />
        ))}
      </fieldset>
    );
  };

  renderTopicsViewer() {
    return (
      <div
        key="topic-selector"
        className="topicSelector read-only"
        onMouseOver={this.props.onHover}
        style={this.props.style}
      >
        {this.renderTopicItems()}
      </div>
    );
  }

  renderTopicItems() {
    const {topicsById, topicMasksById} = this.props;
    const topicsByNote = _.groupBy(this.props.clause.topics, clauseTopic => {
      const topic =
        topicsById[clauseTopic.topic_id] ||
        topicMasksById[clauseTopic.topic_mask_id];
      const isNote = topic.name.startsWith("NOTE:");
      return isNote;
    });
    const topics = [
      ...(topicsByNote[false] || []),
      ...(topicsByNote[true] || []),
    ];
    return topics.map(clauseTopic => this.renderTopicItem(clauseTopic));
  }

  renderTopicItem(clauseTopic) {
    const {topicsById, topicCategoriesById, topicMasksById} = this.props;
    const topic =
      topicsById[clauseTopic.topic_id] ||
      topicMasksById[clauseTopic.topic_mask_id];
    if (clauseTopic.is_confirmed) {
      const className = [
        "confirmed readonly-topic",
        `matchsource-${clauseTopic.match_source_id}`,
        clauseTopic.exact_match_id ? "exact-match" : "",
      ].join(" ");
      const isNote = topic.name.startsWith("NOTE:");
      const style = {...styles.topic, ...(isNote ? styles.note : {})};
      return (
        <div
          className={className}
          key={clauseTopic.id || `m_${clauseTopic.topic_mask_id}`}
          style={style}
          onMouseOver={this.onTopicHover(clauseTopic.path)}
        >
          <TopicSelectItem
            {...this.props}
            option={{
              ...topic,
              value: clauseTopic.topic_id,
              category: topicCategoriesById[topic.topiccategory_id].name,
              topic: topic.name,
              parameters: topic.parameters,
              parameterValues: clauseTopic.topicparameters,
            }}
            onClick={this.onTopicClick(clauseTopic.path)}
          />
        </div>
      );
    }
    return (
      <div
        className="unconfirmed readonly-topic"
        key={clauseTopic.id}
        style={{
          float: "left",
        }}
        onMouseOver={this.onTopicHover(clauseTopic.path)}
        onClick={this.onTopicClick(clauseTopic.path)}
      >
        <UnconfirmedItem
          {...this.props}
          topic={topic}
          preventInteraction={true}
        />
      </div>
    );
  }
  onTopicHover = _.memoize(
    path => () => {
      this.props.onTopicHover(path);
    },
    (...args) => JSON.stringify([...args]),
  );
  onTopicClick = _.memoize(
    path => () => {
      this.props.onTopicClick(path);
    },
    (...args) => JSON.stringify([...args]),
  );
  onToggleExactMatch = option => {
    if (!option.clauseTopic.original_exact_match_id) {
      const {
        exact_match_id: exactMatchId,
        last_edited: clauseTopicLastEdited,
      } = option.clauseTopic;
      const {
        id: clausepartId,
        // last_edited: clausepartLastEdited,
      } = this.props.clause;
      const topicId = option.value;
      this.props.onToggleExactMatch(
        clausepartId,
        topicId,
        exactMatchId,
        clauseTopicLastEdited,
      );
    }
  };
}
