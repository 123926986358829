import React from "react";
import PropTypes from "prop-types";

import RightIcon from "material-ui/svg-icons/navigation/chevron-right";

import IconButton from "material-ui/IconButton";
import {Link} from "react-router";

import TopicSummary from "./topic_summary";
import FixedTooltip from "common_components/fixed_tooltip";
import getHasMatchBorderStyle from "./utils/get_has_match_border_style";
import isCorrect from "./utils/is_correct";

const styles = {
  baseContainer: {
    display: "inline-block",
    margin: "3px",
    borderRadius: "2px",
    color: "#007eff",
    backgroundColor: "#ebf5ff",
  },
  note: {
    fontSize: "0.8em",
    backgroundColor: "rgba(255, 78, 0, 0.08)",
  },
  link: {
    borderLeft: "1px solid rgba(0, 126, 255, 0.24)",
    verticalAlign: "middle",
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
  },
  topicSummaryContainer: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "lightgray",
    backgroundColor: "#ffe0b2",
    borderRightColor: "rgba(105, 105, 105, 0.12)",
    borderTopLeftRadius: "2px",
    borderBottomLeftRadius: "2px",
    borderRightWidth: "0.5px",
    color: "rgb(128,128,128)",
    borderRight: "none",
  },
  iconButton: {
    height: "0.5em",
    width: "20px",
    padding: 0,
    marginTop: "-2px",
    verticalAlign: "top",
    zIndex: 0,
  },
};

function getBorderColor(matchSourceId) {
  // taken from main.css
  switch (matchSourceId) {
    case 1:
      return "#fdb056";
    case 2:
      return "#aaf";
    case 3:
      return "#ada";
    default:
      return "#ebf5ff";
  }
}

function ConfirmedItem(props) {
  const {option} = props;
  const isTopicCorrect = isCorrect(option.clauseTopic.has_match, false);

  const content = (
    <div
      style={{
        ...styles.baseContainer,
        border: `2px solid ${getBorderColor(
          option.clauseTopic.match_source_id,
        )}`,
      }}
    >
      <div
        style={{
          display: "flex",
          ...(option.topic.startsWith("NOTE:") ? styles.note : {}),
          ...getHasMatchBorderStyle(isTopicCorrect, true),
        }}
      >
        <TopicSummary
          topic={{
            name: option.topic,
            ...option,
          }}
          clause={props.clause}
          topicSummaryContainerStyles={styles.topicSummaryContainer}
          onTopicparameterValuesUpdate={props.onTopicparameterValuesUpdate}
          showParametersButton={true}
          roles={props.roles}
        />
        <span style={styles.link}>
          {typeof option.value === "number" ? (
            <Link
              to={{
                pathname: `/organisation/${props.organisationId}/topic/${
                  option.value
                }/detail`,
              }}
              style={{textDecoration: "none"}}
            >
              <IconButton className="go-to-topic" style={styles.iconButton}>
                <RightIcon color="rgb(128,128,128)" />
              </IconButton>
            </Link>
          ) : null}
        </span>
      </div>
    </div>
  );
  return isTopicCorrect === undefined ? (
    content
  ) : (
    <FixedTooltip
      content={
        isTopicCorrect
          ? "Topic is correctly aplied"
          : "Topic has been incorrectly applied"
      }
    >
      {content}
    </FixedTooltip>
  );
}

ConfirmedItem.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    topic: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }),
  clause: PropTypes.object.isRequired,
  organisationId: PropTypes.number.isRequired,
  onTopicparameterValuesUpdate: PropTypes.func,
};

export default ConfirmedItem;
