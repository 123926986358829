import React from "react";
import _ from "underscore";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";

import IssueEditor from "common_components/issue_editor";

const styles = {
  actions: {
    zIndex: 0,
  },
  dialogContent: {
    position: "absolute",
    left: "50%",
    top: "30%",
    transform: "translate(-50%, -30%)",
    maxWidth: "1200px",
  },
  optionControl: {
    marginLeft: "auto",
    marginRight: "4rem",
    width: "16rem",
    zIndex: "0",
  },
};

const subissueOpenTypes = {
  always: "Always",
  issue_is_triggered: "Issue is triggered",
  issue_is_not_triggered: "Issue is not triggered",
};

const textfieldInputs = [
  "reason_template",
  "detailed_reason",
  "positive_reason",
  "detailed_positive_reason",
  "about_description",
  "email_template",
  "standard_language",
  "guidance",
  "positive_guidance",
];

function initState() {
  const state = {
    name: "New Issue",
    nameError: "",
    open_subissues_when: "issue_is_triggered",
    show_if_triggered_only: "always",
    hide_subissue_when_parent: "never",
    empty_parent_applicable_clauses: "all_clauses",
  };
  textfieldInputs.forEach(textfieldInput => {
    state[textfieldInput] = "";
  });
  return state;
}

class CreateIssueDialog extends React.Component {
  state = initState();

  render() {
    return (
      <Dialog
        title="Create new issue"
        className="dialog"
        onDismiss={this.props.onDismiss}
        open={true}
        actions={this.getActions()}
        bodyStyle={{overflowY: "auto"}}
        contentStyle={styles.dialogContent}
      >
        <TextField
          type="text"
          floatingLabelText="Issue Name"
          errorText={this.state.nameError}
          value={this.state.name}
          style={{width: "100%"}}
          inputStyle={{padding: "0 0.5em"}}
          onChange={this.changeIssueName}
          onBlur={this.validateName}
        />
        <IssueEditor
          ref={this.createIssueEditorRef}
          topics={this.props.topics}
          topicsById={this.props.topicsById}
          contractTypesById={this.props.contractTypesById}
          hideTriggeringLogicButtons={true}
          organisationId={this.props.organisationId}
          initialIssue={{
            issue_class_id: 1,
            issue_order: "",
            trigger_display: true,
            issue_type: "TOPIC_NOT_PRESENT",
            rules: {topic_id: null},
            contract_types: [],
            display_name: null,
            override_values: {},
            styling: {summary: {}},
          }}
          roles={this.props.roles}
        />
        <div style={{display: "flex", flexDirection: "column"}}>
          <SelectField
            value={this.state.open_subissues_when}
            onChange={this.updateOpenSubissuesWhen}
            style={styles.optionControl}
            floatingLabelText="Subissues should be open when"
          >
            {Object.keys(subissueOpenTypes).map(sot => (
              <MenuItem
                key={sot}
                value={sot}
                primaryText={subissueOpenTypes[sot]}
              />
            ))}
          </SelectField>
          <SelectField
            style={styles.optionControl}
            value={this.state.show_if_triggered_only}
            floatingLabelText="When to show"
            onChange={this.updateShowIfTriggeredOnly}
          >
            <MenuItem value="always" primaryText="Always" />
            <MenuItem value="ifTriggered" primaryText="If triggered" />
            <MenuItem value="ifNotTriggered" primaryText="If not triggered" />
          </SelectField>
          <SelectField
            style={styles.optionControl}
            value={this.state.hide_subissue_when_parent}
            floatingLabelText="Hide subissue when parent"
            onChange={this.updateHideSubissueWhenParent}
          >
            <MenuItem value="never" primaryText="Never" />
            <MenuItem value="ifTriggered" primaryText="Alert" />
            <MenuItem value="ifNotTriggered" primaryText="Non-alert" />
          </SelectField>
          <SelectField
            style={styles.optionControl}
            value={this.state.empty_parent_applicable_clauses}
            floatingLabelText="Empty Parent Applicable Clauses"
            onChange={this.updateEmptyParentApplicableClauses}
          >
            <MenuItem value="all_clauses" primaryText="All Clauses" />
            <MenuItem
              value="triggered_clauses_only"
              primaryText="Triggered Clauses Only"
            />
          </SelectField>
        </div>
        {textfieldInputs.map(item => (
          <TextField
            key={item}
            type="text"
            className={item}
            value={this.state[item]}
            style={{
              marginLeft: "36px",
              marginRight: "36px",
              zIndex: 0,
              display: "block",
              width: "auto",
            }}
            floatingLabelText={item.split("_").join(" ")}
            multiLine
            rowsMax={4}
            onChange={this.onItemUpdate(item)}
          />
        ))}
      </Dialog>
    );
  }

  getActions = () => {
    return [
      <FlatButton
        label="Cancel"
        key="create-issue-dialog-cancel-button"
        secondary={true}
        onClick={this.props.onDismiss}
        style={styles.actions}
      />,
      <FlatButton
        label="Create Issue"
        key="create-issue-dialog-confirm-button"
        primary={true}
        onClick={this.addNewIssueHandler}
        className="save-button"
        style={styles.actions}
      />,
    ];
  };

  changeIssueName = e => this.setState({name: e.target.value});

  validateName = e => {
    if (e.target.value.length === 0) {
      this.setState(() => ({nameError: "You must set the issue name"}));
    } else {
      this.setState(() => ({nameError: ""}));
    }
  };

  updateOpenSubissuesWhen = (e, i, value) =>
    this.setState(() => ({open_subissues_when: value}));

  updateShowIfTriggeredOnly = (e, i, value) =>
    this.setState(() => ({
      show_if_triggered_only: value,
    }));

  updateHideSubissueWhenParent = (e, i, value) =>
    this.setState(() => ({
      hide_subissue_when_parent: value,
    }));

  updateEmptyParentApplicableClauses = (e, i, value) =>
    this.setState(() => ({
      empty_parent_applicable_clauses: value,
    }));

  onItemUpdate = _.memoize(itemName => e => {
    const updatedValue = e.target.value;
    this.setState(() => ({[itemName]: updatedValue}));
  });

  addNewIssueHandler = async () => {
    const {name} = this.state;
    if (name.toLowerCase() === "new issue" || this.nameAlreadyExists(name)) {
      return this.setState(() => ({
        nameError: "Please specify different issue name",
      }));
    }
    const editorValues = await this.issueEditorRef.getValue();

    if (
      name.length === 0 ||
      !editorValues ||
      Object.keys(editorValues.problems).length > 0
    ) {
      return undefined;
    }
    const issueToCreate = {
      name,
      ..._.pick(this.state, [
        "display_name",
        "open_subissues_when",
        "show_if_triggered_only",
        "hide_subissue_when_parent",
        "empty_parent_applicable_clauses",
        ...textfieldInputs,
      ]),
      ...editorValues.issue,
    };
    this.props.addIssue(issueToCreate);
    this.props.onDismiss();
  };

  nameAlreadyExists = name => {
    const existingNames = this.props.issues.map(issue =>
      issue.name.toLowerCase(),
    );
    return existingNames.includes(name.toLowerCase());
  };

  createIssueEditorRef = node => (this.issueEditorRef = node);
}

export default CreateIssueDialog;
