import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "66rem",
    margin: "0 auto",
  },
}));

function Wrapper({children}) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

export default Wrapper;
