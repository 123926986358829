import React from "react";
import styled from "styled-components";

import Row, {CellData} from "./Row";

const Container = styled.table`
  width: 100%;
  height: 100%;
`;

const HeaderRow = styled.tr`
  height: 4rem;
`;

const StyledRow = styled(Row)`
  height: 2rem;
`;

type RowData = CellData[];

type TableProps = {
  rows: RowData[];
  headers: Array<{title: string; style?: React.CSSProperties}>;
};

const Table = ({headers, rows}: TableProps) => (
  <Container>
    <thead>
      <HeaderRow>
        {headers.map(header => (
          <th key={header.title} style={header.style}>
            {header.title}
          </th>
        ))}
      </HeaderRow>
    </thead>
    <tbody>
      {rows.map(row => (
        <StyledRow key={row.map(({data}) => data).join(",")} cells={row} />
      ))}
    </tbody>
  </Container>
);

export default Table;
