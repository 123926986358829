import basePath from "base_path";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes, {
  AccessControlEntryActionTypes,
} from "../constants/action_types";
import {AccessControlEntry} from "common/types/accesscontrolentry";

export type FetchAccessControlEntriesAction = {
  type: AccessControlEntryActionTypes["ACCESS_CONTROL_ENTRIES_FETCH"]["SUCCESS"];
  payload: AccessControlEntry[];
};

export default createReduxPromiseAction(
  ActionTypes.ACCESS_CONTROL_ENTRIES_FETCH,
  (organisationId: number) =>
    requestor
      .get(`${basePath}/organisation/${organisationId}/accesscontrolentry`, {
        withCredentials: true,
      })
      .then(response => response.data),
);
