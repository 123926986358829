import React, {useState} from "react";
import {cloneDeep} from "lodash";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

const styles = {
  cardHeader: {
    height: "2.5rem",
    lineHeight: "2.4rem",
    paddingLeft: "2rem",
    fontSize: "17px",
    fontWeight: 600,
    justifyContent: "normal",
  },
};

function CompanyDetail(props) {
  const {companyDetail: propsCompanyDetail} = props;
  const [stateCompanyDetail, onStateCompanyDetailChange] = useState(
    cloneDeep(propsCompanyDetail),
  );

  function onKeyChange(key, value) {
    onStateCompanyDetailChange({
      ...stateCompanyDetail,
      [key]: value,
    });
  }

  function onCompanyDetailSave() {
    props.onCompanyDetailUpdate(stateCompanyDetail);
  }

  function createField(key, hintText) {
    const onFieldChange = e => onKeyChange(key, e.target.value);
    return (
      <TextField
        key={key}
        label={hintText}
        multiline={true}
        onChange={onFieldChange}
        value={stateCompanyDetail[key] || ""}
        onBlur={onCompanyDetailSave}
        style={{
          marginTop: "4px",
          width: "100%",
          borderRadius: "2px",
        }}
      />
    );
  }

  const items = [
    {key: "company_name", label: "Company Name"},
    {key: "company_number", label: "Company Number"},
    {key: "address", label: "Address"},
  ];

  return (
    <Paper elevation={1} square style={{marginTop: "1rem"}}>
      <div className="app-toolbar" style={styles.cardHeader}>
        Company Detail
      </div>
      <div style={{padding: "2rem"}}>
        {items.map(item => createField(item.key, item.label))}
      </div>
    </Paper>
  );
}

export default CompanyDetail;
