import React, {useState, useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "material-ui/CircularProgress";
import PlusIcon from "@material-ui/icons/AddCircle";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import {Project} from "common/types/project";
import {GenerateProjectLlmPrompt, FetchConversations} from "../../types";
import {Conversation} from "common/types/conversation";

export default function queryDocStore({
  project,
  generateProjectLlmPrompt,
  fetchConversations,
}: {
  project: Project & {conversations?: Conversation[]};
  generateProjectLlmPrompt: GenerateProjectLlmPrompt;
  fetchConversations: FetchConversations;
}) {
  const [query, setQuery] = useState("");
  const [selectedConversationId, setSelectedConversationId] = useState<
    number | null
  >(null);
  const selectedConversation = project?.conversations?.find(
    ({id}) => id === selectedConversationId,
  );
  const sending = project?.conversations?.some(({id}) => id === -1);

  useEffect(() => {
    if (!project.conversations) {
      fetchConversations();
    }
  }, [project.conversations]);

  function setTextValue(event: React.ChangeEvent<HTMLInputElement>) {
    if (!sending) {
      setQuery(event.target.value);
    }
  }
  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.keyCode === 13 && !event.shiftKey && !event.ctrlKey) {
      const userQuery = query;
      setQuery("");
      const conversationId = selectedConversation?.id ?? null;
      const previousItemId = selectedConversation?.items?.at(-1)?.id ?? null;
      generateProjectLlmPrompt(userQuery, conversationId, previousItemId);
    }
  }

  // HACK: This is an ugly way to coordinate between state changes in Redux and
  // the state we have locally here (the selected conversation index). Better
  // to avoid this kind of coupling altogether by managing all strongly
  // correlated states in a common scope. This is difficult if you insist on
  // putting some states in a global scope, because the only solution then
  // would be to make ALL states global by association. This mix and match is
  // the worst case scenario where you have the bug-proneness of both global
  // states AND having strongly correlated states in different places.
  useEffect(() => {
    if (sending) {
      setSelectedConversationId(-1);
    }
  }, [sending]);

  useEffect(() => {
    // If we've finished sending (implied by having previously selected the placeholder conversation with id -1)
    // we should infer the ID of the newly created conversation and select it.
    if (!sending && selectedConversationId === -1) {
      // It's probably the highest ID, since they're currently sequential.
      const probableIdOfNewConversation =
        project.conversations?.reduce((max, {id}) => Math.max(max, id), 0) ??
        null;
      setSelectedConversationId(probableIdOfNewConversation);
    }
  }, [sending, selectedConversationId]);

  function renderConversation(conversation: Conversation) {
    if (!conversation) {
      return null;
    }
    const {items} = conversation;
    return (
      <div style={{marginBottom: "1em"}}>
        {items
          .filter(chat => chat.role !== "system")
          .map(chat => renderMessage(chat))}
      </div>
    );
  }

  function renderMessage(message) {
    return (
      <div
        style={
          message.role === "assistant"
            ? {
                paddingLeft: "4px",
                marginLeft: "2px",
                borderLeft: "2px solid #42a5f5",
              }
            : {}
        }
      >
        <ReactMarkdown children={message.text} remarkPlugins={[remarkGfm]} />
      </div>
    );
  }

  function renderEditor() {
    if (sending) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    }
    return (
      <div>
        <TextField
          autoFocus
          label="Query"
          multiline={true}
          onKeyDown={onKeyDown}
          onChange={setTextValue}
          value={query}
          style={{width: "100%"}}
        />
      </div>
    );
  }

  function renderConversationSelector(
    conversations: Conversation[] | undefined,
  ) {
    if (!conversations) {
      return null;
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: "rgb(230, 230, 230)",
          padding: "1rem 0rem 0rem 1rem",
          rowGap: "1rem",
          width: "20rem",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            display: "flex",
            columnGap: "0.5rem",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "1rem",
          }}
        >
          <div style={{fontWeight: "bold"}}>Query History</div>
          <div
            title="Make a new query"
            style={{
              padding: "0.25rem",
              borderRadius: "0.5rem",
              backgroundColor: "rgb(253, 253, 253)",
              border: "0.2rem solid rgb(253, 253, 253)",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => setSelectedConversationId(null)}
          >
            <PlusIcon style={{color: "#747474"}} />
          </div>
        </div>

        <div
          style={{
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
            padding: "0rem 1rem 1rem 0rem",
          }}
        >
          {conversations
            .sort(
              (a, b) =>
                // first sort by whether the conversation id is -1 (placeholder)
                // then sort by creation date. We can't rely on the locally
                // generated creation date because the server clock is
                // unreliable.
                (b.id === -1 ? 1 : 0) - (a.id === -1 ? 1 : 0) ||
                new Date(b.items[0].creation_date).getTime() -
                  new Date(a.items[0].creation_date).getTime(),
            )
            .map(renderConversationItem)}
        </div>
      </div>
    );
  }

  function renderConversationItem(conversation: Conversation) {
    const firstMessage = conversation.items.find(({role}) => role === "user");
    if (!firstMessage) {
      return null;
    }
    return (
      <div
        key={conversation.id}
        onClick={() => setSelectedConversationId(conversation.id)}
        style={{
          cursor: "pointer",
          padding: "0.6rem",
          borderRadius: "0.5rem",
          fontFamily: "Roboto",
          backgroundColor: "#fdfdfd",
          border: `0.3rem solid ${
            selectedConversationId === conversation.id
              ? "rgb(188, 217, 240)"
              : "transparent"
          }`,
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "0.85rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingBottom: "0.2rem",
          }}
        >
          {firstMessage.text}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "rgb(83, 83, 83)",
            fontSize: "0.7rem",
          }}
        >
          {new Date(firstMessage.creation_date).toLocaleDateString()}
        </div>
      </div>
    );
  }

  function renderSelectedConversation() {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          padding: "0rem 1rem 1rem",
          margin: "0 auto",
          maxWidth: "40rem",
        }}
      >
        <div
          style={{
            width: "100%",
            flexGrow: 1,
            overflowY: "auto",
            paddingRight: "1rem",
          }}
        >
          {!selectedConversation ? (
            sending ? null : (
              <div
                style={{
                  fontStyle: "italics",
                  fontSize: "0.8em",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                Select a conversation or type to start a new one
              </div>
            )
          ) : (
            renderConversation(selectedConversation)
          )}
        </div>
        {renderEditor()}
      </div>
    );
  }

  return (
    <div style={{display: "flex", height: "100%"}}>
      {renderConversationSelector(project.conversations)}
      {renderSelectedConversation()}
    </div>
  );
}
