import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function runDocumentIssuesFind(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_ISSUES_FIND_RUN,
    (organisationId, projectId, documentId, issueId) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}` +
            `/document/${documentId}` +
            "/find-issues/",
          {issue_ids: [issueId]},
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}
