import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";

import ReportIssueGroup from "./report_issue_group";
import ReportIssueItem from "./report_issue_item";
import ReportParameter from "./report_parameter";

import commonStyles from "./common_styles";
import getIssueLevel from "../utils/reports/get_issue_level";

import {isInitialised} from "utils/uninitialised";
import getIssuesData from "utils/issues/get_issues_data";
import getIssueColorValue from "utils/issues/get_issue_color_value";
import calculateDocumentRagScore from "utils/reports/calculate_document_rag_score";
import calculateDocumentRagLevelColor from "utils/reports/calculate_document_rag_level_color";
import ragLevelsPerColor from "utils/reports/rag_levels_per_color";

const styles = {
  ...commonStyles,
  mainContainer: {
    position: "fixed",
    right: 0,
    width: "40%",
    top: 0,
    backgroundColor: "white",
    boxShadow:
      "0 1px 10px 0 rgba(0, 0, 0, 0.17), 0 1px 15px 0 rgba(0, 0, 0, 0.04)",
    zIndex: 2,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 24,
    margin: 24,
    borderBottom: "1px solid #BDBDBD",
  },
  documentName: {
    fontSize: 27,
    fontWeight: 600,
    wordBreak: "break-all",
  },
  closeIcon: {
    cursor: "pointer",
    height: 28,
    width: 28,
    marginLeft: 28,
  },
};

function addColorToIssue(issue, issueset) {
  const issueColor = getIssueLevel(issue, issueset).issueLevel;
  return {
    ...issue,
    issueColor,
  };
}

function ReportDetail(props) {
  function shouldRenderBody() {
    return isInitialised([props.project, props.issueset, props.documentIssues]);
  }

  const mainContainerStyles = {
    ...styles.mainContainer,
    // TODO: Ideally this layout would not be coupled to specific parent
    // dimensions
    marginTop: props.disableMarginForComparisonSummaryPage ? 0 : "146px",

    height: props.disableMarginForComparisonSummaryPage
      ? "100vh"
      : "calc(100vh - 146px)",
  };

  if (!shouldRenderBody()) {
    return (
      <div style={mainContainerStyles}>
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      </div>
    );
  }

  const {documentClauses, settings} = props;
  const {groupByHeadings, showIssueGroupHeadings} = settings;

  function renderParameters() {
    const {parameters} = props;
    if (!parameters || parameters.length === 0) {
      return <div />;
    }
    return (
      <div style={{opacity: "0.6"}}>
        {parameters.map((parameter, index) => (
          <ReportParameter key={index} parameter={parameter} />
        ))}
      </div>
    );
  }

  function renderOverallRisk() {
    const documentRagScore = calculateDocumentRagScore(
      props.documentIssues,
      props.issueset,
    );
    const documentRagLevelColor = calculateDocumentRagLevelColor(
      documentRagScore,
      props.ragScoreLevels,
    );
    return (
      <div style={{display: "flex"}}>
        <div>Overall Risk:</div>
        <div
          style={{
            margin: "0px 8px",
            fontWeight: 600,
            textTransform: "capitalize",
          }}
        >
          {ragLevelsPerColor[documentRagLevelColor]}
        </div>
        <div
          style={{
            ...styles.circle,
            background: getIssueColorValue(documentRagLevelColor),
          }}
        />
      </div>
    );
  }

  // constructing common props for issues
  const baseProps = {
    documentClauses,
    project: props.project,
    selectedReport: props.selectedReport,
    issueset: props.issueset,
    documentId: props.documentId,
    projectId: props.projectId,
    organisationId: props.organisationId,
  };

  function renderUngroupedIssues() {
    return props.documentIssues.map((issue, index) => (
      <ReportIssueItem
        key={index}
        issue={addColorToIssue(issue, props.issueset)}
        {...baseProps}
      />
    ));
  }

  function renderGroupedIssues() {
    const {groupedIssues} = getIssuesData(
      props,
      props.issueset,
      true, // areIssuesCollapsed
      true, // dontFilterIssuesByIssuesets,
    );

    return groupedIssues.map((item, index) =>
      item.isGroup ? (
        <ReportIssueGroup key={index} group={item} {...baseProps} />
      ) : (
        <ReportIssueItem
          key={index}
          issue={addColorToIssue(item.item[0], props.issueset)}
          {...baseProps}
        />
      ),
    );
  }
  return (
    <div style={mainContainerStyles}>
      <div style={styles.headerContainer}>
        <div style={styles.documentName}>{props.documentName}</div>
        <CloseIcon style={styles.closeIcon} onClick={props.closeReportDetail} />
      </div>
      <div style={{overflow: "auto", padding: "0px 10px"}}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0px 14px 56px 14px",
            paddingBottom: 14,
            borderBottom: styles.headerContainer.borderBottom,
          }}
        >
          {renderParameters()}
          {renderOverallRisk()}
        </div>
        {groupByHeadings || showIssueGroupHeadings
          ? renderGroupedIssues()
          : renderUngroupedIssues()}
      </div>
    </div>
  );
}

export default ReportDetail;
