import issuesetUtils from "common/utils/issues/issueset_utils";

function getIssueNameWithIssuesets(issue) {
  const issuesetNames = issuesetUtils
    .getIssuesetsPresentInContractTypes(issue.contract_types)
    .map(is => is.name);
  return `${issuesetNames.join(", ")} - ${issue.name}`;
}

export default getIssueNameWithIssuesets;
