import React from "react";
import _ from "underscore";

import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import {Toolbar, ToolbarTitle} from "material-ui/Toolbar";
import Checkbox from "material-ui/Checkbox";

import IssueEditor from "common_components/issue_editor";
import sharedStart from "utils/shared_start";
import escapeRegex from "utils/escape_regex";
import issuesetUtils from "common/utils/issues/issueset_utils";

const styles = {
  actions: {
    zIndex: 0,
  },
  dialogContent: {
    position: "absolute",
    left: "50%",
    top: "30%",
    transform: "translate(-50%, -30%)",
  },
};

class BulkEditorDialog extends React.Component {
  state = {name: "New Issue", nameError: ""};

  constructor(props) {
    super(props);
    this.props = props;
    this.issue = this.makeBulkEditorIssue();
    this.state = {
      isArchived: this.issue.is_archived,
      name: this.issue.name,
      initialName: this.issue.name,
      initialDisplayName: this.issue.display_name,
    };
  }

  render() {
    const toolbarText = `Bulk Editing ${this.props.issues.length} Issues`;
    return (
      <Dialog
        className="dialog"
        onDismiss={this.props.onDismiss}
        open={true}
        actions={this.getActions()}
        bodyStyle={{overflowY: "inherit"}}
        contentStyle={styles.dialogContent}
      >
        <Toolbar>
          <ToolbarTitle text={toolbarText} />
        </Toolbar>
        <div
          style={{display: "flex", alignItems: "center", margin: "15px -3px 0"}}
        >
          <div>
            <Checkbox
              checked={this.state.isArchived}
              onCheck={(event, value) => this.changeIsArchived(value)}
            />
          </div>
          <div
            style={{color: "rgba(0, 0, 0, 0.87)", cursor: "pointer"}}
            onClick={() => this.changeIsArchived(!this.state.isArchived)}
          >
            Is Archived
          </div>
        </div>
        <TextField
          className="issue-name"
          value={this.state.name}
          floatingLabelText={"Common Prefix"}
          onChange={this.changeIssueName}
          style={{width: "100%"}}
          onBlur={this.validateName}
          errorText={this.state.nameError}
        />
        <IssueEditor
          ref={this.createBulkIssueEditorRef}
          initialIssue={this.issue}
          contractTypesById={this.props.contractTypesById}
          inputsToUse={["contract_type", "class"]}
          organisationId={this.props.organisationId}
          isBulk={true}
        />
      </Dialog>
    );
  }

  getActions = () => {
    return [
      <FlatButton
        key="cancel-button"
        label="Cancel"
        secondary={true}
        onClick={this.props.onDismiss}
        style={styles.actions}
      />,
      <FlatButton
        key="bulk-edit-button"
        label="Bulk Edit"
        primary={true}
        onClick={this.updateIssuesInBulkHandler}
        className="save-button"
        style={styles.actions}
      />,
    ];
  };

  makeBulkEditorIssue() {
    const issues = this.props.issues;
    const commonIssueIsArchivedFlag = this.getCommonIssueIsArchivedFlag(issues);
    const commonNamePrefix = this.getCommonNamePrefix(issues, "name");
    const commonDisplayNamePrefix = this.getCommonNamePrefix(
      issues,
      "display_name",
    );
    const commonContractTypes = issuesetUtils.constructContractTypesByIssuesets(
      this.getCommonIssuesets(issues),
      issuesetUtils.getIssuesetsById(this.props.contractTypesById),
    );
    const commonIssueClassId = this.getCommonIssueClassId(issues);
    const issue = {
      id: -1,
      is_archived: commonIssueIsArchivedFlag,
      name: commonNamePrefix,
      display_name: commonDisplayNamePrefix,
      contract_types: commonContractTypes,
      issue_class_id: commonIssueClassId,
    };
    return issue;
  }

  getCommonIssueIsArchivedFlag(issues) {
    const issueIsArchivedFlag = _.chain(issues)
      .map(issue => issue.is_archived)
      .uniq()
      .value();
    return issueIsArchivedFlag.length === 1 ? issueIsArchivedFlag[0] : false;
  }

  getCommonNamePrefix(issues, itemName) {
    const names = _.map(issues, item => item[itemName]);
    return sharedStart(names);
  }

  getCommonIssuesets(issues) {
    const issuesets = [];
    issues.forEach(issue => {
      const issueIssuesets = issuesetUtils.getIssuesetIdsPresentInContractTypes(
        issue.contract_types,
      );
      issuesets.push(issueIssuesets);
    });
    return _.intersection(...issuesets);
  }

  getCommonIssueClassId(issues) {
    const issueClassIds = _.chain(issues)
      .map(issue => issue.issue_class_id)
      .uniq()
      .value();
    return issueClassIds.length === 1 ? issueClassIds[0] : null;
  }

  changeIsArchived = value => this.setState({isArchived: value});
  changeIssueName = e => this.setState({name: e.target.value});

  validateName = e => {
    if (e.target.value.length === 0 && this.props.issues.length === 1) {
      return this.setState(() => ({
        nameError: "You must set the name for single issue selection",
      }));
    }
    return this.setState(() => ({nameError: ""}));
  };

  updateIssuesInBulkHandler = async () => {
    const editorValues = await this.bulkIssueEditorRef.getValue();
    if (this.state.nameError || Object.keys(editorValues.problems).length > 0) {
      return;
    }
    this.updateIssuesInBulk(this.props.issues, {
      ...editorValues.issue,
      is_archived: this.state.isArchived,
      name: this.state.name,
    });
  };

  updateIssuesInBulk(issues, newIssue) {
    const updates = this.calculateUpdates(issues, newIssue);
    if (
      _.find(
        updates,
        issue => Object.keys(_.omit(issue, ["id", "last_edited"])).length > 0,
      )
    ) {
      this.props.updateIssues(updates);
    }
    this.props.onDismiss();
  }

  calculateUpdates(issues, newIssue) {
    return _.object(
      issues.map(issue => {
        const data = {};
        data.is_archived = newIssue.is_archived;

        if (newIssue.name !== this.state.initialName) {
          const name =
            issue.name &&
            issue.name.replace(
              new RegExp(`^${escapeRegex(this.state.initialName)}`),
              newIssue.name,
            );
          data.name = name;
        }

        if (newIssue.display_name !== this.state.initialDisplayName) {
          const display_name =
            issue.display_name &&
            issue.display_name.replace(
              new RegExp(`^${escapeRegex(this.state.initialDisplayName)}`),
              newIssue.display_name,
            );
          data.display_name = display_name;
        }

        // issuesets processing
        const issuesetsById = issuesetUtils.getIssuesetsById(
          this.props.contractTypesById,
        );
        const commonIssuesets = this.getCommonIssuesets(issues);
        const currentIssuesets = issuesetUtils.getIssuesetIdsPresentInContractTypes(
          issue.contract_types,
        );
        const newIssuesets = issuesetUtils.getIssuesetIdsPresentInContractTypes(
          newIssue.contract_types,
        );
        const addedIssuesets = _.difference(newIssuesets, currentIssuesets);
        const removedIssuesets = _.difference(commonIssuesets, newIssuesets);
        if (addedIssuesets.length > 0) {
          data.added_issuesets = addedIssuesets.map(
            issuesetId => issuesetsById[issuesetId],
          );
        }
        if (removedIssuesets.length > 0) {
          data.removed_issuesets = removedIssuesets.map(
            issuesetId => issuesetsById[issuesetId],
          );
        }

        // issue_class_id processing
        if (newIssue.issue_class_id) {
          data.issue_class_id = newIssue.issue_class_id;
        }

        data.last_edited = issue.last_edited;
        return [issue.id, data];
      }),
    );
  }

  createBulkIssueEditorRef = node => (this.bulkIssueEditorRef = node);
}

export default BulkEditorDialog;
