import BaseRoute from "utils/base_route";

import requestor from "requestor";
import llmRunFetchAction from "modules/flowmaster/actions/llm_run_fetch";
import actionDefinitionsFetchAction from "modules/flowmaster/actions/action_definitions_fetch";
import promptsFetchAction from "modules/prompts/actions/prompts_fetch";
import llmRunClearAction from "modules/flowmaster/actions/llm_run_clear";

import LlmRunDetailContainer from "./containers/llm_run_detail";

export default class LlmRunDetailRoute extends BaseRoute {
  constructor(store) {
    super(":llmRunId/detail", store);
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, LlmRunDetailContainer);
  }

  onEnter(state) {
    this.fetchData(state);
  }

  fetchData(state) {
    this.fetchLlmRun(state);
    this.fetchActionDefinitions(state);
    this.fetchPrompts(state);
  }

  fetchLlmRun(state) {
    const {organisationId, llmRunId} = state.params;
    const {dispatch} = this.store;
    dispatch(llmRunFetchAction(requestor)(organisationId, llmRunId));
  }

  fetchActionDefinitions(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(actionDefinitionsFetchAction(requestor)(organisationId));
  }

  fetchPrompts(state) {
    const {organisationId} = state.params;
    const {dispatch} = this.store;
    dispatch(promptsFetchAction(requestor)(organisationId));
  }

  clearActiveData() {
    const {dispatch} = this.store;
    dispatch(llmRunClearAction());
  }
}
