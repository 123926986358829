import basePath from "base_path";

import ActionTypes from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";

/* eslint-disable @typescript-eslint/no-unused-vars */
export default function updateTopicClassifier(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.TOPIC_CLASSIFIER_UPDATE,
    (
      organisationId,
      topicId,
      classifierId,
      configurationId,
      data,
      changeTime,
    ) => {
      return requestor
        .patch(
          `${basePath}/organisation/${organisationId}/topic/${topicId}` +
            `/classifier/${classifierId}/configuration/${configurationId}`,
          data,
          {withCredentials: true},
        )
        .then(response => response.data);
    },
    (
      organisationId,
      topicId,
      classifierId,
      configurationId,
      data,
      changeTime,
    ) => ({
      classifier_id: classifierId,
      topic_id: topicId,
      configuration_id: configurationId,
      ...data,
    }),
  );

  return action;
}
