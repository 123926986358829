import React from "react";
import {Link} from "react-router";

import {List, ListItem} from "material-ui/List";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import CloseIconButton from "common_components/buttons/close_icon_button";

import ModulesForm from "./modules_form";

const styles = {
  keyField: {
    display: "inline-block",
    width: "30%",
    marginLeft: 20,
    lineHeight: "50px",
  },
  valueField: {
    display: "inline-block",
    width: "55%",
    marginLeft: 20,
    lineHeight: "50px",
  },
  buttons: {
    minWidth: 215,
    marginLeft: 20,
    position: "relative",
  },
  actionButton: {
    position: "absolute",
    bottom: 7,
    right: 0,
  },
  moduleRow: {
    borderBottom: "1px solid #ccc",
  },
  topicsPaper: {
    width: "30%",
    margin: "10px",
    padding: "10px",
    position: "relative",
  },
  topicItem: {
    padding: "4px",
    fontSize: "12px",
  },
  closeIconButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
  },
};

class ModulesListItem extends React.Component {
  state = {
    isTopicsCardShown: false,
    topicsData: null,
  };
  render() {
    const {module} = this.props;
    if (this.props.editId === module.id) {
      return (
        <div style={styles.moduleRow}>
          <ModulesForm
            onSave={value => this.props.onSave({...value, id: module.id})}
            onCancel={this.props.onCancel}
            module={module}
            style={{minHeight: "70px"}}
            templateModules={this.props.templateModules}
            currentModuleId={module.id}
          />
          <div style={{display: "flex", flexDirection: "row"}}>
            <div
              style={Object.assign({}, styles.keyField, {
                color: "#ccc",
                lineHeight: "40px",
              })}
            >
              {module.key}
            </div>
            <div
              style={Object.assign({}, styles.valueField, {
                color: "#ccc",
                lineHeight: "40px",
              })}
            >
              {module.value[0]}
            </div>
            <div style={styles.buttons} />
          </div>
        </div>
      );
    }
    return (
      <div style={styles.moduleRow}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            minHeight: 50,
          }}
        >
          <div style={styles.keyField}>{module.key}</div>
          <div style={styles.valueField}>{module.value[0]}</div>
          <div style={styles.buttons}>
            <RaisedButton
              style={styles.actionButton}
              onClick={() => this.props.triggerEdit(module.id)}
            >
              Edit
            </RaisedButton>
            <RaisedButton
              onClick={
                this.state.isTopicsCardShown
                  ? this.onHideTopics
                  : this.onShowTopics
              }
              style={{
                ...styles.actionButton,
                right: "100px",
                padding: "0 10px",
              }}
            >
              {this.state.isTopicsCardShown ? "Hide Topics" : "Show Topics"}
            </RaisedButton>
          </div>
        </div>
        {this.state.isTopicsCardShown &&
          this.state.topicsData &&
          this.renderTopicsCard()}
      </div>
    );
  }
  onShowTopics = async () => {
    if (this.state.topicsData) {
      return this.setState(() => ({isTopicsCardShown: true}));
    }
    const {topicsById} = this.props;
    const result = await this.props.getModuleTopics(this.props.module.id);
    const topicsData = result.value.map(topic => topicsById[topic.topic_id]);
    return this.setState(() => ({
      isTopicsCardShown: true,
      topicsData,
    }));
  };
  onHideTopics = () => {
    this.setState(() => ({isTopicsCardShown: false}));
  };
  renderTopicsCard = () => {
    return (
      <Paper style={styles.topicsPaper}>
        <div>
          <div style={{textAlign: "center"}}>Module Topics</div>
          <CloseIconButton
            onButtonClick={this.onHideTopics}
            size="16"
            buttonStyles={styles.closeIconButton}
          />
          {this.state.topicsData.length === 0 ? (
            <div style={{fontSize: "12px", padding: "4px"}}>
              {" "}
              No topics found{" "}
            </div>
          ) : (
            <List>
              {this.state.topicsData.map(topic => (
                <Link
                  key={topic.id}
                  to={{
                    pathname: `/organisation/${
                      this.props.params.organisationId
                    }/topic/${topic.id}/analysis`,
                  }}
                  style={{textDecoration: "none"}}
                >
                  <ListItem
                    primaryText={topic.name}
                    innerDivStyle={styles.topicItem}
                  />
                </Link>
              ))}
            </List>
          )}
        </div>
      </Paper>
    );
  };
}

export default ModulesListItem;
