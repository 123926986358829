import React from "react";
import {Provider} from "react-redux";
import {Router} from "react-router";
import {push} from "react-router-redux";

import AppRoute from "routes/app";
import appRouteCreator from "utils/routing/app_route_creator";
import {initAnalytics} from "utils/analytics";
import "./main.css";
import generateAppSettings from "./generateAppSettings";
import {withSentry} from "utils/sentry_utils";

const {store, history} = generateAppSettings();

const environment = process.env.NODE_ENV;
const GOOGLE_ANALYTICS_ID =
  process.env[`REACT_APP_GOOGLE_ANALYTICS_ID_${environment.toUpperCase()}`];
const VERSION = process.env.REACT_APP_VERSION;
const appRoute = appRouteCreator(store, AppRoute);
let firstTime = true;
class Root extends React.Component {
  componentDidMount() {
    initAnalytics(GOOGLE_ANALYTICS_ID, VERSION, window);
  }

  render() {
    if (firstTime) {
      const {pathname, search} = window.location;
      store.dispatch(push({pathname, search}));
      firstTime = false;
    }

    return (
        <Provider store={store}>
          <Router history={history} onUpdate={updateAppCue}>{appRoute}</Router>
        </Provider>
    );
  }
}

function updateAppCue() {
  window.Appcues && window.Appcues.page();
}

export default withSentry(Root);
