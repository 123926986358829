import calculateNodeLevel from "./calculate_node_level";

export default function(_parentNode) {
  if (_parentNode && _parentNode.type.endsWith("Atom")) {
    const values = {};
    values[-1] = _parentNode.topics.length;
    return values;
  }
  const topicSum = {};

  function getNodeTopics(node, parentNode) {
    const {topics, clauseNodes} = node;
    const level = calculateNodeLevel(node, parentNode);
    if (!topicSum[level]) {
      topicSum[level] = 0;
    }
    if (topics) {
      topicSum[level] += topics.length;
      if (node.is_terminal && level !== -1) {
        if (!topicSum[-1]) {
          topicSum[-1] = 0;
        }
        topicSum[-1] += topics.length;
      }
    }

    if (clauseNodes) {
      /* eslint-disable no-use-before-define */
      getTopics(clauseNodes, node);
      /* eslint-enable no-use-before-define */
    }
  }
  function getTopics(nodes, parentNode) {
    nodes.forEach(node => getNodeTopics(node, parentNode));
  }
  getTopics([_parentNode]);
  return topicSum;
}
