import ResetPasswordContainer from "./containers/reset_password";
import Header from "./components/header";

export default class ResetPassword {
  constructor() {
    this.path = "set-new-password/:resetToken";
  }

  getChildRoutes(state, cb) {
    cb(null, []);
  }

  getComponents(location, cb) {
    cb(null, {main: ResetPasswordContainer, header: Header});
  }
}
