import calculateNodeLevel from "./calculate_node_level";

export default function(selectedLevel, clause, parentNode) {
  const isHeaderNode =
    parentNode &&
    parentNode.type.endsWith("Node") &&
    clause.type.endsWith("Atom") &&
    clause.level === parentNode.level;
  if (
    selectedLevel === calculateNodeLevel(clause, parentNode) ||
    (selectedLevel === -1 && clause.is_terminal) ||
    (selectedLevel === "all" && clause.render_all) ||
    (selectedLevel === "default" && (isHeaderNode || clause.is_terminal))
  ) {
    return true;
  }
  return false;
}
