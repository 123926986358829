import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

/* eslint-disable max-params */
export default function splitClause(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_CLAUSE_SPLIT,
    (
      organisationId,
      projectId,
      documentId,
      sectionId,
      clauseId,
      clausepartId,
      clauseLastEdited,
      documentLastEdited,
      start,
      end,
    ) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}/project/${projectId}` +
            `/document/${documentId}/clause/${clauseId}/` +
            `clausepart/${clausepartId}/split`,
          {
            clause_last_edited: clauseLastEdited,
            document_last_edited: documentLastEdited,
            start,
            end,
          },
          {withCredentials: true},
        )
        .then(response => ({
          clause_id: clauseId,
          clausepart_id: clausepartId,
          section_id: sectionId,
          ...response.data,
        })),
  );
}
