import ChildRenderer from "common_components/child_renderer";

import BaseRoute from "utils/base_route";

import ProjectList from "routes/project/project_list";
import ProjectDetail from "routes/project/project_detail";
import ProjectAdd from "routes/project/project_detail/project_add";

export default class ProjectBase extends BaseRoute {
  constructor(store) {
    super("", store);
  }

  createChildRoutes() {
    return [
      new ProjectList(this.store),
      new ProjectDetail(this.store),
      new ProjectAdd(this.store),
    ];
  }

  getComponents(location, cb) {
    cb(null, ChildRenderer);
  }
}
