import _ from "underscore";
/* eslint-disable no-unused-vars */
import React from "react";
/* eslint-enable no-unused-vars */

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import Permissioner from "utils/permissioner";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import setTitle from "utils/set_title";
import path from "common/utils/path";

import basePath from "base_path";
import requestor from "requestor";

import IssueDetailComponent from "../components/issue_detail";

import addIssueAction from "modules/documents/actions/issue_add";
import updateIssueAction from "modules/documents/actions/issue_update";
import updateIssuesAction from "modules/documents/actions/issues_update";
import deleteIssueAction from "modules/documents/actions/issue_delete";
import updateDocumentIssueAction from "modules/documents/actions/document_issue_update";
import correctDocumentIssueManuallyAction from "modules/documents/actions/document_issue_correct_manually";
import generateDocumentIssueLlmPromptAction from "modules/documents/actions/document_issue_generate_prompt";
import fetchDocumentIssueConversationAction from "modules/documents/actions/document_issue_conversation_fetch";
import clearDocumentIssueConversationAction from "modules/documents/actions/document_issue_conversation_clear";
import resetDocumentIssues from "modules/documents/actions/document_issues_reset";
import issueUsagesFetchAction from "modules/documents/actions/issue_usages_fetch";
import documentsFetchAction from "modules/documents/actions/documents_fetch";
import documentFetchAction from "modules/documents/actions/document_fetch";
import documentClearAction from "modules/documents/actions/document_clear";
import documentsClearAction from "modules/documents/actions/documents_clear";
import documentIssuesFindRunAction from "modules/documents/actions/document_issues_find_run";
import documentIssueFindRunAction from "modules/documents/actions/document_issue_find_run";
import documentIssuesUpdateRunAction from "modules/documents/actions/document_issues_update_run";
import logsFetchAction from "modules/logs/actions/logs_fetch";
import logCommentFetchAction from "modules/logs/actions/log_comment_add";
import addIssuesetToIssue from "modules/documents/actions/issueset/issueset_add_to_issue";
import searchByIssueAction from "modules/search/actions/search_by_issue";
import searchByIssueClearAction from "modules/search/actions/search_by_issue_clear";
import definitionGroupsFetchAction from "modules/documents/actions/definition_group/definition_groups_fetch";
import CORRECTION_ISSUE_TYPES from "common/correction_issue_types";
import updateIssuesetAction from "modules/documents/actions/issueset/issueset_update";

import byId from "common/utils/by_id";
import UpdatingComponent from "utils/updating_component";
import {parseQuery, buildQuery} from "utils/uri";
import issuesetUtils from "common/utils/issues/issueset_utils";
import logger from "common/utils/logger";
import applyRulesChangesToRules from "./apply_rules_changes_to_rules";

import {UpdateIssueData} from "modules/documents/types";

type UpdateType = "Singular" | "Bulk";

type Props = {
  updateIssueAction: ReturnType<typeof updateIssueAction>;
  updateIssuesAction: ReturnType<typeof updateIssuesAction>;
};

class IssueDetailContainer extends UpdatingComponent<Props> {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return (
      this.permissioner.isAdmin() ||
      this.permissioner.hasPermission("view-issue-detail")
    );
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.issue,
      this.props.issues,
      this.props.topics,
      this.props.topicsById,
      this.props.contractTypes,
      this.props.user,
      this.props.projects,
      this.props.topicCategories,
      this.props.roles,
      this.props.organisation,
    ]);
  }

  renderComponent() {
    setTitle(`${this.props.issue.name}`);
    applyRulesChangesToRules(this.props.issue);
    const contractTypesById = byId(this.props.contractTypes);
    const issuesetsById = issuesetUtils.getIssuesetsById(contractTypesById);
    return (
      <IssueDetailComponent
        {...this.props}
        organisationId={this.props.params.organisationId}
        onIssueUpdated={this.updateIssue}
        onIssuesUpdated={this.updateIssues}
        onIssueDeleted={this.deleteIssue}
        contractTypesById={contractTypesById}
        issuesetsById={issuesetsById}
        issuesById={byId(this.props.issues)}
        updateDocumentIssue={this.updateDocumentIssue}
        correctDocumentIssueManually={this.correctDocumentIssueManually}
        generateDocumentIssueLlmPrompt={this.generateDocumentIssueLlmPrompt}
        fetchDocumentIssueConversation={this.fetchDocumentIssueConversation}
        clearDocumentIssueConversation={this.clearDocumentIssueConversation}
        redirectToIssueList={this.redirectToIssueList}
        duplicateIssue={this.duplicateIssue}
        resetShouldBeIssue={this.resetShouldBeIssue}
        resetIsCorrectlyApplied={this.resetIsCorrectlyApplied}
        fetchDocuments={this.fetchDocuments}
        updateDocumentIssues={this.updateDocumentIssues}
        reprocessDocumentIssue={this.reprocessDocumentIssue}
        onDocumentIssuesUpdateRun={this.onDocumentIssuesUpdateRun}
        documents={this.props.documents}
        fetchDocument={this.fetchDocument}
        correctionIssueTypes={CORRECTION_ISSUE_TYPES}
        saveCorrection={this.saveCorrection}
        getLocationSearchParams={this.getLocationSearchParams}
        pushLocationSearchParams={this.pushLocationSearchParams}
        showIssue={this.showIssue}
        searchByIssue={this.searchByIssue}
        pushHistoryLink={this.pushHistoryLink}
        addIssuesetToIssue={this.addIssuesetToIssue}
        fetchIssueLogs={this.fetchIssueLogs}
        fetchIssueUsages={this.fetchIssueUsages}
        addLogComment={this.addLogComment}
        updateDisplayLogic={this.updateDisplayLogic}
        getFindIssueJobsAmount={this.getFindIssueJobsAmount}
        onIssuesetUpdate={this.onUpdateIssueset}
      />
    );
  }

  getUpdatingProp(type: UpdateType) {
    return type === "Singular" ? this.props.issue : this.props.issues;
  }

  // TODO: Replace issue with issues, call updateIssuesAction if more than one
  updateHandler(data: UpdateIssueData | UpdateIssueData[]) {
    const {organisationId, issueId} = this.props.params;

    const issueIdInt = parseInt(issueId, 10);

    return Array.isArray(data)
      ? this.props.updateIssuesAction(
          organisationId,
          data.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {}),
        )
      : this.props.updateIssueAction(
          organisationId,
          issueIdInt,
          this.props.issue.last_edited,
          data,
        );
  }

  updateIssue = (data: UpdateIssueData) => {
    if (this.props.document) {
      data.updated_document_id = this.props.document.id;
    }

    return this.performUpdate(data, "Singular" as UpdateType);
  };

  updateIssues = (data: UpdateIssueData[]) => {
    if (this.props.document) {
      data.updated_document_id = this.props.document.id;
    }

    return this.performUpdate(data, "Bulk" as UpdateType);
  };

  deleteIssue = () => {
    const {organisationId} = this.props.params;
    const {id, last_edited: lastEdited} = this.props.issue;
    this.props.deleteIssueAction(organisationId, id, lastEdited);
  };

  duplicateIssue = async () => {
    const {organisationId} = this.props.params;
    const data = {duplicate_issues: [this.props.issue.id]};
    const result = await this.props.addIssueAction(organisationId, data);
    return result;
  };
  updateDocumentIssue = (documentIssue, updates) => {
    const {
      project_id: projectId,
      document_id: documentId,
      document_issue_id: documentIssueId,
    } = documentIssue;
    const lastEdited =
      documentIssue.document_issue_last_edited || documentIssue.last_edited;
    return this.props.updateDocumentIssue(
      this.props.params.organisationId,
      projectId,
      documentId,
      documentIssueId,
      lastEdited,
      updates,
    );
  };

  correctDocumentIssueManually = (documentIssue, updates) => {
    const {
      project_id: projectId,
      document_id: documentId,
      document_issue_id: documentIssueId,
    } = documentIssue;
    const lastEdited = documentIssue.last_edited;
    return this.props.correctDocumentIssueManually(
      this.props.params.organisationId,
      projectId,
      documentId,
      documentIssueId,
      lastEdited,
      updates,
    );
  };

  generateDocumentIssueLlmPrompt = (
    issueId,
    issuesetMasterId,
    issuesetClientId,
    prompt,
    conversationType,
    conversationId,
    previousPromptId,
  ) => {
    const {project_id: projectId, id: documentId} = this.props.document;
    const {
      params: {organisationId},
    } = this.props;
    return this.props.generateDocumentIssueLlmPrompt(
      organisationId,
      projectId,
      documentId,
      issueId,
      issuesetMasterId,
      issuesetClientId,
      prompt,
      conversationType,
      conversationId,
      previousPromptId,
    );
  };

  fetchDocumentIssueConversation = (
    issueId,
    conversationId,
    conversationType,
    issuesetMasterId,
    issuesetRemoteClientId,
  ) => {
    const {project_id: projectId, id: documentId} = this.props.document;
    const {
      params: {organisationId},
    } = this.props;
    return this.props.fetchDocumentIssueConversation(
      organisationId,
      projectId,
      documentId,
      issueId,
      conversationId,
      conversationType,
      issuesetMasterId,
      issuesetRemoteClientId,
    );
  };

  clearDocumentIssueConversation = issueId => {
    const {project_id: projectId, id: documentId} = this.props.document;
    const {
      params: {organisationId},
    } = this.props;
    return this.props.clearDocumentIssueConversation(
      organisationId,
      projectId,
      documentId,
      issueId,
    );
  };
  redirectToIssueList = () => {
    this.props.dispatch(
      push(`/organisation/${this.props.params.organisationId}/issue/list`),
    );
  };

  resetShouldBeIssue = () => {
    this.props.resetDocumentIssues(
      this.props.params.organisationId,
      this.props.issue.id,
      {should_be_issue: null},
    );
  };

  resetIsCorrectlyApplied = () => {
    this.props.resetDocumentIssues(
      this.props.params.organisationId,
      this.props.issue.id,
      {is_correctly_applied: null},
    );
  };

  fetchDocuments = async projectId => {
    if (!this.props.definitionGroups) {
      await this.props.fetchDefinitionGroups(this.props.params.organisationId);
    }
    return this.props.fetchDocuments(
      this.props.params.organisationId,
      projectId,
    );
  };

  fetchDocument = (projectId, documentId) => {
    this.props.fetchDocument(
      this.props.params.organisationId,
      projectId,
      documentId,
    );
  };

  updateDocumentIssues = (projectId, documentId) => {
    const {organisationId} = this.props.params;
    this.props.documentIssuesFindRun(organisationId, projectId, documentId);
  };

  reprocessDocumentIssue = (projectId, documentId) => {
    const {organisationId, issueId} = this.props.params;
    this.props.documentIssueFindRun(
      organisationId,
      projectId,
      documentId,
      issueId,
    );
  };

  onDocumentIssuesUpdateRun = () => {
    const {organisationId, issueId} = this.props.params;
    this.props.documentIssuesUpdateRun(organisationId, [issueId], {});
  };

  saveCorrection = _.memoize(
    () => () => {},
    (...argv) => JSON.stringify(argv),
  );

  getLocationSearchParams = () => {
    const searchParams = parseQuery(this.props.location.search);
    for (const name in searchParams) {
      const value = searchParams[name];
      switch (name) {
        case "open_issue_list":
        case "open_issue_detail":
        case "auto_select_first_document":
          searchParams[name] = value === "true";
          break;
        case "edit_level":
          searchParams[name] = value;
          break;
        case "project":
        case "document":
        case "issueset":
          searchParams[name] = parseInt(value, 10);
          break;
      }
    }
    return searchParams;
  };

  pushLocationSearchParams = params => {
    const {pathname, search} = this.props.location;
    const searchParams = parseQuery(search);
    for (const name in params) {
      const value = params[name];
      if (value === null) {
        delete searchParams[name];
      } else {
        searchParams[name] = value;
      }
    }
    const newSearch = buildQuery(searchParams);
    if (newSearch !== search) {
      this.props.dispatch(push(pathname + newSearch));
    }
  };

  showIssue = issue => {
    const {id: issueId} = issue;
    const {pathname} = this.props.location;
    const newPathname = pathname.replace(/issue\/\d+/, `issue/${issueId}`);
    this.props.dispatch(
      push({
        ...this.props.location,
        pathname: newPathname,
      }),
    );
    if (this.props.logs) {
      this.fetchIssueLogs(issueId);
    }
  };

  pushHistoryLink = newLocationObj => {
    this.props.dispatch(
      push({
        ...this.props.location,
        ...newLocationObj,
      }),
    );
  };

  fetchIssueLogs = issueId => {
    const {organisationId} = this.props.params;
    const issueIdToFetch = issueId || this.props.params.issueId;
    this.props.fetchLogs(organisationId, `issue_id=${issueIdToFetch}`);
  };

  fetchIssueUsages = () => {
    const {organisationId} = this.props.params;
    const issueId = this.props.params.issueId;
    this.props.fetchUsages(organisationId, issueId);
  };

  addLogComment = (logId, text) => {
    const {organisationId} = this.props.params;
    this.props.addLogComment(
      organisationId,
      logId,
      text,
      this.props.user.username,
    );
  };

  addIssuesetToIssue = (projectId, issuesetId, issueId, documentId) =>
    this.props.addIssuesetToIssue(
      this.props.params.organisationId,
      projectId,
      issuesetId,
      issueId,
      documentId,
    );

  updateDisplayLogic = () => {
    this.props.documentIssuesUpdateRun(
      this.props.params.organisationId,
      [this.props.issue.id],
      {should_bypass_find_issues_api_call: true, single_issue_update: true},
    );
  };

  searchByIssue = (projectId, issueId, issuesetPath) => {
    this.props.searchByIssue(
      this.props.params.organisationId,
      projectId,
      issueId,
      issuesetPath,
    );
  };

  getFindIssueJobsAmount = (projectId, documentId, issueId) => {
    const {organisationId} = this.props.params;
    return requestor
      .get(
        `${basePath}/organisation/${organisationId}/project/${projectId}/document/${documentId}/issue/${issueId}/find_issue_jobs_amount`,
        {withCredentials: true},
      )
      .then(response => path(response, ["data"]))
      .catch(() => {
        logger.verbose("Finding issuses jobs amount failed");
      });
  };

  onUpdateIssueset = (contractTypeId, issuesetId, value) => {
    this.props.updateIssueset(
      this.props.params.organisationId,
      contractTypeId,
      issuesetId,
      value,
    );
  };
}

function select(state, props) {
  const issueId = parseInt(props.params.issueId, 10);
  return {
    issue: state.issue,
    params: {
      issueId,
      ..._.mapObject(_.omit(props.params, "issueId"), id => parseInt(id, 10)),
    },
    issues: state.issues,
    topics: state.topics,
    topicsById:
      state.topics && state.topics !== UNINITIALISED
        ? byId(state.topics)
        : UNINITIALISED,
    topicCategories: state.topicCategories,
    contractTypes: state.contract_types,
    router: state.router,
    user: state.user,
    organisation: state.organisation,
    projects: getItem(state.projects),
    document: getItem(state.document),
    documents: getItem(state.documents),
    documentClauses: getItem(state.documentClauses, ["clauses"]),
    documentIssues: getItem(state.documentIssues, ["issues"]),
    documentSections: getItem(state.documentSections),
    documentChanges: getItem(state.documentChanges),
    documentDefinitions: getItem(state.documentDefinitions),
    documentHeadings: getItem(state.documentHeadings),
    definitionGroups: getItem(state.definitionGroups),
    logs: getItem(state.logs),
    searchByIssueResults:
      state.search_by_issue.results === UNINITIALISED
        ? null
        : state.search_by_issue.results,
    roles: getItem(state.roles),
  };
}

function getItem(obj, itemPath) {
  let value = obj === UNINITIALISED ? null : obj;
  if (value && itemPath) {
    value = _.propertyOf(value)(itemPath);
  }
  return value;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        addIssueAction: addIssueAction(requestor),
        updateIssueAction: updateIssueAction(requestor),
        updateIssuesAction: updateIssuesAction(requestor),
        deleteIssueAction: deleteIssueAction(requestor),
        updateDocumentIssue: updateDocumentIssueAction(requestor),
        correctDocumentIssueManually: correctDocumentIssueManuallyAction(
          requestor,
        ),
        generateDocumentIssueLlmPrompt: generateDocumentIssueLlmPromptAction(
          requestor,
        ),
        fetchDocumentIssueConversation: fetchDocumentIssueConversationAction(
          requestor,
        ),
        clearDocumentIssueConversation: clearDocumentIssueConversationAction(
          requestor,
        ),
        fetchDefinitionGroups: definitionGroupsFetchAction(requestor),
        fetchDocuments: documentsFetchAction(requestor),
        fetchUsages: issueUsagesFetchAction(requestor),
        fetchDocument: documentFetchAction(requestor),
        fetchLogs: logsFetchAction(requestor),
        addLogComment: logCommentFetchAction(requestor),
        documentIssuesFindRun: documentIssuesFindRunAction(requestor),
        documentIssueFindRun: documentIssueFindRunAction(requestor),
        documentIssuesUpdateRun: documentIssuesUpdateRunAction(requestor),
        clearDocument: documentClearAction,
        clearDocuments: documentsClearAction,
        resetDocumentIssues,
        addIssuesetToIssue,
        searchByIssue: searchByIssueAction,
        searchByIssueClear: searchByIssueClearAction,
        updateIssueset: updateIssuesetAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(IssueDetailContainer);
export const Component = IssueDetailContainer;
