import React from "react";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {push} from "react-router-redux";

import setTitle from "utils/set_title";
import Permissioner from "utils/permissioner";
import {isInitialised} from "utils/uninitialised";

import requestor from "requestor";

import UserDetailComponent from "../components/user_detail";

import updateOrganisationUserAction from "modules/organisation/actions/organisation_user_update";
import addOrganisationUserAction from "modules/organisation/actions/organisation_user_add";
import unlockUserAction from "modules/organisation/actions/organisation_user_unlock";

import UpdatingComponent from "utils/updating_component";

class UserDetailContainer extends UpdatingComponent {
  render() {
    if (!this.shouldRenderContainer()) {
      return <div />;
    }
    this.permissioner = new Permissioner(this.props.user);
    if (!this.hasEnterPermission()) {
      return this.permissioner.getNoPermissionMessage();
    }
    return this.renderComponent();
  }

  hasEnterPermission() {
    return this.permissioner.hasPermission(
      this.props.params && this.props.params.userId === "new"
        ? "add-organisation-user"
        : "update-user",
    );
  }

  shouldRenderContainer() {
    return isInitialised([
      this.props.organisationUser,
      this.props.projects,
      this.props.user,
      this.props.organisation,
    ]);
  }

  renderComponent() {
    const {
      username,
      first_name,
      last_name,
      default_project_id,
      permission_group_id,
      is_locked,
    } = this.props.organisationUser;

    setTitle(
      this.props.params && this.props.params.userId === "new"
        ? "Create New User"
        : `Update ${username}`,
    );
    return (
      <div className="app-container">
        <UserDetailComponent
          params={this.props.params}
          organisation={this.props.organisation}
          onOrganisationUserUpdate={this.props.updateOrganisationUser}
          onOrganisationUserAdd={this.props.addOrganisationUser}
          onUnlockUser={this.unlockUser}
          username={username || ""}
          first_name={first_name || ""}
          last_name={last_name || ""}
          is_locked={is_locked}
          default_project_id={default_project_id || null}
          permission_group_id={permission_group_id || null}
          onRedirectBack={this.redirectBack}
          projects={this.props.projects}
          user={this.props.user}
          permissionGroups={this.props.permissionGroups}
          defaultPermissionGroupId={this.props.defaultPermissionGroupId}
        />
      </div>
    );
  }

  unlockUser = () => {
    this.props.unlockUser(
      this.props.params.organisationId,
      this.props.organisationUser.id,
      this.props.organisationUser.last_edited,
    );
  };

  redirectBack = () => {
    this.props.dispatch(
      push(`/organisation/${this.props.params.organisationId}/user/list`),
    );
  };
}

function select(state, props) {
  if (!state.organisation.users) {
    return {};
  }
  const rawOrganisationUserId = props.params.userId;
  const organisationUserId =
    rawOrganisationUserId === "new"
      ? "new"
      : parseInt(rawOrganisationUserId, 10);

  return {
    organisationUser:
      organisationUserId === "new"
        ? {
            username: "",
            first_name: "",
            last_name: "",
          }
        : state.organisation.users.find(user => user.id === organisationUserId),
    organisation: state.organisation,
    params: props.params,
    router: state.router,
    projects: state.projects,
    user: state.user,
    permissionGroups: state.permission_groups,
    defaultPermissionGroupId: state.organisation.default_permission_group_id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        updateOrganisationUser: updateOrganisationUserAction(requestor),
        addOrganisationUser: addOrganisationUserAction(requestor),
        unlockUser: unlockUserAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(UserDetailContainer);
