import React, {FC} from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import TypeSelector from "./type_selector";
import ContainedTextField from "../../contained_text_field";
import TypeDetail from "./type_detail";
import isPossibleEnumType from "./util/is_possible_enum_type";
import {StringArgEditor} from "../string";

import {SchemaProperty} from "common/flowmaster/types/schema";
import {
  ActionDefinition,
  WorkflowTask,
} from "common/flowmaster/types/task_config";
import {
  Prompt,
  WorkflowContext,
  WorkflowInputs,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

interface PropertyEditorProps {
  newProperty?: boolean;
  name?: string;
  property?: SchemaProperty;
  onUpdate?: (name: string, property: SchemaProperty) => void;
  onCreate?: (name: string, property: SchemaProperty) => void;
  onRemove?: () => void;
  isNested?: boolean;

  priorTasks: WorkflowTask[];
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  organisationId: OrganisationId;
}

const useStyles = makeStyles({
  container: (props: {isNested: boolean}) => ({
    backgroundColor: "#cccccc",
    margin: props.isNested ? ".3em 0" : ".6em 0",
    padding: props.isNested ? "5px 15px" : "15px 20px",
    borderRadius: "4px",
    border: props.isNested ? "2px solid #696969" : "none",
  }),
  inputContainer: {
    display: "flex",
    alignItems: "center",
  },
});

const PropertyEditor: FC<PropertyEditorProps> = ({
  name = "",
  property = {description: "", type: "string"},
  onUpdate = () => {},
  onRemove = () => {},
  isNested = false,
  priorTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  organisationId,
}) => {
  const classes = useStyles({isNested});
  const isEnum = Boolean(property.enum);

  const handleTypeChange = (type: SchemaProperty["type"]) => {
    const updatedProperty: SchemaProperty = {
      ...property,
      type,
      description: property.description,
    };

    if (type === "object") {
      updatedProperty.properties = property.properties || {};
      delete updatedProperty.items;
    } else if (type === "array") {
      updatedProperty.items = property.items || {type: "string"};
      delete updatedProperty.properties;
    } else {
      delete updatedProperty.properties;
      delete updatedProperty.items;
    }

    if (!isPossibleEnumType(type)) {
      delete updatedProperty.enum;
    } else if (isEnum) {
      updatedProperty.enum = [];
    }

    onUpdate(name, updatedProperty);
  };

  const toggleEnum = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedProperty = {...property};
    if (event.target.checked && !updatedProperty.enum) {
      updatedProperty.enum = [];
    } else {
      delete updatedProperty.enum;
    }
    onUpdate(name, updatedProperty);
  };

  const onEnumUpdate = (enumValues: SchemaProperty["enum"]) => {
    onUpdate(name, {
      ...property,
      enum: enumValues,
    });
  };

  return (
    <Grid
      item
      key={name}
      container
      alignItems="center"
      className={classes.container}
      spacing={1}
    >
      <Grid item xs={3}>
        <ContainedTextField
          label="Property Name"
          initialValue={name}
          onUpdate={updatedName => onUpdate(updatedName, property)}
        />
      </Grid>
      <Grid item xs={4}>
        <StringArgEditor
          argValue={{
            value:
              typeof property.description === "object"
                ? property.description
                : {
                    source_type: "literal",
                    value: property.description || "",
                  },
          }}
          onUpdateItem={newArgValue => {
            if (newArgValue.value.source_type === "literal") {
              const literalValue = newArgValue.value.value;
              onUpdate(name, {...property, description: literalValue});
            } else {
              onUpdate(name, {
                ...property,
                description: newArgValue.value,
              });
            }
          }}
          priorTasks={priorTasks}
          context={context}
          inputs={inputs}
          prompts={prompts}
          actionDefinitions={actionDefinitions}
          organisationId={organisationId}
        />
      </Grid>
      <Grid item xs={3}>
        <div className={classes.inputContainer}>
          <TypeSelector value={property.type} onChange={handleTypeChange} />
          {isPossibleEnumType(property.type) && (
            <FormControlLabel
              style={{marginLeft: "0.5em"}}
              control={<Checkbox checked={isEnum} onChange={toggleEnum} />}
              label="Enum"
            />
          )}
        </div>
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={() => onRemove()}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      <TypeDetail
        name={name}
        property={property}
        isEnum={isEnum}
        onUpdate={onUpdate}
        onEnumUpdate={onEnumUpdate}
        priorTasks={priorTasks}
        context={context}
        inputs={inputs}
        prompts={prompts}
        actionDefinitions={actionDefinitions}
        organisationId={organisationId}
      />
    </Grid>
  );
};

export default PropertyEditor;
