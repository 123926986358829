import _ from "underscore";
import getUsageMatches from "utils/topic/get_usage_matches";
import HEADINGS_SEPARATOR from "common/headings_separator";
import getText from "utils/clauses/get_text";

import getMatchLocation from "./get_match_location";
import styles from "../const/clausepart_styles";

function getClausepartRegexList(props, usage, selectedRegex, shouldLog) {
  const {regexClassifier} = props;
  const {
    clausepart_partial_text: partialText,
    definitionAndHeadingsText,
    originalDefinitionAndHeadingsText,
  } = usage;

  const text = getText(definitionAndHeadingsText, regexClassifier);
  const matches = getMatchLocation(text, props, selectedRegex, shouldLog);

  const showHeadingsMatches = !regexClassifier.omit_headings;
  const headingsText =
    definitionAndHeadingsText.lastIndexOf(HEADINGS_SEPARATOR) > 0 &&
    !showHeadingsMatches
      ? definitionAndHeadingsText.substring(
          0,
          definitionAndHeadingsText.lastIndexOf(HEADINGS_SEPARATOR) + 2,
        )
      : "";

  const showDefinitionMatches = !regexClassifier.omit_definitions;
  const showDefinitionIndicatorsMatches = !regexClassifier.omit_definition_indicator;
  const definitionText =
    definitionAndHeadingsText.indexOf("||") > 0 && !showDefinitionMatches
      ? (showDefinitionIndicatorsMatches
          ? originalDefinitionAndHeadingsText
          : definitionAndHeadingsText
        ).substring(definitionAndHeadingsText.indexOf("||"))
      : "";

  const start = showHeadingsMatches ? 0 : text.indexOf(partialText);
  const end = showDefinitionMatches
    ? text.length
    : start +
      (showHeadingsMatches ? text.indexOf(partialText) - 1 : 0) +
      partialText.length +
      (showDefinitionIndicatorsMatches && !showDefinitionMatches
        ? definitionAndHeadingsText.length
        : 0);

  const endStr = text.substring(end);
  const startStr = text.substring(0, start);
  const clausepartMatchData = {
    start,
    end,
    endStr,
    startStr,
    matches,
    headingsText,
    definitionText,
    text,
  };

  if (end === text.length) {
    const mainTextRegexList = getRegexMatches(
      text,
      matches,
      0,
      regexClassifier,
    );
    const regexList = processRegexList(mainTextRegexList, matches);
    return {regexList, clausepartMatchData};
  }

  if (startStr.length === 0) {
    const mainTextRegexList = getRegexMatches(
      partialText,
      matches,
      0,
      regexClassifier,
    );
    const postTextRegexList = getRegexMatches(
      endStr,
      matches,
      partialText.length,
      regexClassifier,
    );
    const regexList = processRegexList(
      [...mainTextRegexList, ...postTextRegexList],
      matches,
    );
    return {regexList, clausepartMatchData};
  }

  if (endStr.length === 0) {
    const preTextRegexList = getRegexMatches(
      startStr,
      matches,
      0,
      regexClassifier,
    );
    const mainTextRegexList = getRegexMatches(
      partialText,
      matches,
      startStr.length,
      regexClassifier,
    );
    const regexList = processRegexList(
      [...preTextRegexList, ...mainTextRegexList],
      matches,
    );
    return {regexList, clausepartMatchData};
  }

  const preTextRegexList = getRegexMatches(
    startStr,
    matches,
    0,
    regexClassifier,
  );
  const mainTextRegexList = getRegexMatches(
    partialText,
    matches,
    startStr.length,
    regexClassifier,
  );
  const postTextRegexList = getRegexMatches(
    endStr,
    matches,
    startStr.length + partialText.length,
    regexClassifier,
  );
  const regexList = processRegexList(
    [...preTextRegexList, ...mainTextRegexList, ...postTextRegexList],
    matches,
  );
  return {regexList, clausepartMatchData};
}

function processRegexList(regexList, matches) {
  const mainMatches = _.uniq(regexList, item => item.regexText);

  const otherMatches = matches
    .filter(
      match =>
        !regexList.find(regexItem => regexItem.regexText === match.reText),
    )
    .map(match => ({
      index: match.cIndex,
      regexText: match.regexWithModules,
      style: {},
    }));
  return [...mainMatches, ...otherMatches];
}

function getRegexMatches(text, matches, startOffset = 0, classifier) {
  if (!matches || matches.length === 0) {
    return [];
  }
  const listWithMatches = getUsageMatches(text, matches, startOffset);
  const result = [];

  listWithMatches.list.forEach(el => {
    if (el.match) {
      result.push({
        index: el.match.cIndex,
        regexText: classifier.value[`${el.match.type}list`][el.match.cIndex],
        style: el.style && styles[el.style],
      });
    }
  });
  return result;
}

const memoizedGetClausepartRegexList = _.memoize(
  getClausepartRegexList,
  (props, usage, selectedRegex) => {
    const baseValue = `${usage.document_id}.${usage.clausepart_id}`;
    const classifiersStr = JSON.stringify(props.classifiers);
    return selectedRegex
      ? `${classifiersStr}${baseValue}.${selectedRegex}`
      : `${classifiersStr}${baseValue}`;
  },
);

export default function(props, usage, selectedRegex) {
  if (selectedRegex) {
    return memoizedGetClausepartRegexList(props, usage, selectedRegex);
  }
  return getClausepartRegexList(props, usage, selectedRegex);
}
