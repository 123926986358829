import React from "react";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import FlatButton from "material-ui/FlatButton";
import BackIcon from "material-ui/svg-icons/navigation/arrow-back";
import * as colors from "material-ui/styles/colors";

import UploadMenuItem from "./upload_menu_item";

class FormUploadMenu extends React.Component {
  state = {isFormTemplateSelectorShown: false};

  render() {
    return this.state.isFormTemplateSelectorShown
      ? this.renderFormTemplateSelection()
      : this.renderUploadOptions();
  }

  renderUploadOptions = () => {
    const {selectedItemId, selectItem} = this.props;
    const {
      name: defaultContractTypeName,
    } = this.props.project.default_contract_type;
    return (
      <div>
        <UploadMenuItem
          id="form"
          selectedItemId={selectedItemId}
          selectItem={this.selectFormItem}
        >
          <div>
            <div
              style={{
                fontSize: "24px",
              }}
            >
              <span style={{fontWeight: 800}}>Form</span>
              {defaultContractTypeName && (
                <span
                  style={{
                    fontSize: "20px",
                    color: colors.grey500,
                    fontWeight: 400,
                  }}
                >{` (${defaultContractTypeName})`}</span>
              )}
            </div>
          </div>
        </UploadMenuItem>
        <UploadMenuItem
          id="form_template"
          selectedItemId={selectedItemId}
          selectItem={selectItem}
        >
          <div style={{color: colors.grey500}}>Form Template</div>
        </UploadMenuItem>
      </div>
    );
  };

  renderFormTemplateSelection = () => {
    return (
      <div>
        <div style={{marginLeft: "1rem"}}>
          Please select form template to compare with your form
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
          <SelectField
            floatingLabelText="Form Template"
            onChange={this.props.onFormTemplateSelected}
            value={this.props.selectedFormTemplateId}
            labelStyle={{color: colors.grey700}}
            style={{marginLeft: "2rem"}}
          >
            {this.props.formTemplates.map(formTemplate => (
              <MenuItem
                key={formTemplate.id}
                value={formTemplate.id}
                primaryText={formTemplate.name}
              />
            ))}
          </SelectField>
          <FlatButton
            icon={<BackIcon />}
            label="Go Back"
            onClick={this.onGoBack}
            style={{
              width: "8rem",
              position: "absolute",
              bottom: "0.5rem",
              color: colors.grey500,
            }}
          />
        </div>
      </div>
    );
  };

  showFormTemplateSelector = () =>
    this.setState(() => ({isFormTemplateSelectorShown: true}));

  selectFormItem = selectedItemId =>
    this.setState(
      () => this.showFormTemplateSelector(),
      () => this.props.selectItem(selectedItemId),
    );

  onGoBack = () =>
    this.setState(
      () => ({isFormTemplateSelectorShown: false}),
      () => this.props.selectItem(null),
    );
}

export default FormUploadMenu;
