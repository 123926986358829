import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function deleteIssue(requestor) {
  return createReduxPromiseAction(
    ActionTypes.ISSUE_REMOVE,
    (organisationId, issueId, lastEdited) =>
      requestor["delete"](
        `${basePath}/organisation/${organisationId}/issue/${issueId}`,
        {withCredentials: true, data: {last_edited: lastEdited}},
      ).then(response => response.data),
  );
}
