import React, {useState, useEffect} from "react";
import {
  Button,
  TextField,
  Switch,
  Chip,
  MenuItem,
  Select,
  FormControlLabel,
} from "@material-ui/core";

import ConfirmDialog from "common_components/confirm_dialog";
import * as InputEditors from "common_components/flowmaster/input_editors";
import Expando from "./expando";
import {llmModels} from "modules/flowmaster/constants/models";

import {WorkflowInput, WorkflowInputId} from "common/flowmaster/types/workflow";

const styles = {
  defaultValueContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "1em",
    flex: 1,
  },
};

interface InputsProps {
  input: WorkflowInput;
  onUpdateItem: (input: Record<string, string>) => void;
  onDeleteItem: (workflowInputId: WorkflowInputId) => void;
}

const Input: React.FC<InputsProps> = ({input, onUpdateItem, onDeleteItem}) => {
  return (
    <Expando key={input.id}>
      <InputSummary input={input} />
      <InputEditor
        input={input}
        onUpdateItem={onUpdateItem}
        onDeleteItem={onDeleteItem}
      />
    </Expando>
  );
};

function InputSummary({input}) {
  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <Chip style={{marginRight: "0.5em"}} size="small" label={input.id} />
      {input.name}
    </div>
  );
}
function InputEditor({input, onUpdateItem, onDeleteItem}) {
  const [showingDeleteDialog, showDeleteDialog] = useState<boolean>(false);
  const [name, setName] = useState(input.name);
  const [label, setLabel] = useState(input.label);
  const [localDefaultValue, setLocalDefaultValue] = useState(
    input.default_value,
  );

  useEffect(() => {
    setLocalDefaultValue(input.default_value);
  }, [input.default_value]);

  const handleDefaultValueChange = (
    event: React.ChangeEvent<{value: string}>,
  ) => {
    if (input.type === "llm_model") {
      onUpdateItem({default_value: event.target.value});
    } else {
      setLocalDefaultValue(event.target.value);
    }
  };

  const handleDefaultValueBlur = () => {
    if (input.type === "string") {
      onUpdateItem({default_value: localDefaultValue});
    }
  };

  const handleTypeChange = (event: React.ChangeEvent<{value: string}>) => {
    const newType = event.target.value;
    onUpdateItem({
      type: newType,
      default_value: newType === "llm_model" ? llmModels[0].key : "",
    });
  };

  const renderDefaultValueInput = () => {
    if (!input.has_default) {
      return null;
    }

    const Component = InputEditors[input.type].component;

    const value =
      input.type === "string" ? localDefaultValue ?? "" : input.default_value;

    return (
      <div style={styles.defaultValueContainer}>
        <Component
          input={input}
          value={value}
          handleInputChange={handleDefaultValueChange}
          handleInputBlur={handleDefaultValueBlur}
          style={{width: "30em", marginBottom: "1em"}}
        />
      </div>
    );
  };

  const modelInputMenuItems = Object.entries(InputEditors).map(
    ([key, editor]) => (
      <MenuItem key={key} value={key}>
        {editor.label}
      </MenuItem>
    ),
  );

  return (
    <div>
      <div style={{display: "flex", alignItems: "center"}}>
        <Chip style={{marginRight: "0.5em"}} size="small" label={input.id} />
        <TextField
          value={name}
          label="Name"
          onChange={event => setName(event.target.value)}
          onBlur={() => onUpdateItem({name})}
          style={{width: "100%"}}
        />
        <Select
          value={input.type}
          onChange={handleTypeChange}
          style={{minWidth: "10em", alignSelf: "flex-end", marginLeft: "1em"}}
        >
          {modelInputMenuItems}
        </Select>
      </div>
      <TextField
        value={label}
        label="Label"
        onChange={event => setLabel(event.target.value)}
        onBlur={() => onUpdateItem({label})}
        style={{width: "100%"}}
      />
      <div style={{marginTop: "10px", display: "flex", alignItems: "center"}}>
        <FormControlLabel
          label="Has Default?"
          control={
            <Switch
              checked={input.has_default}
              onChange={() => {
                onUpdateItem({has_default: !input.has_default});
              }}
            />
          }
        />
        <FormControlLabel
          label="Is Optional?"
          control={
            <Switch
              checked={input.is_optional}
              onChange={() => {
                onUpdateItem({is_optional: !input.is_optional});
              }}
            />
          }
        />
        {renderDefaultValueInput()}
      </div>
      <Button variant="contained" onClick={() => showDeleteDialog(true)}>
        Delete
      </Button>
      <ConfirmDialog
        open={showingDeleteDialog}
        onSuccess={() => onDeleteItem()}
        onClose={() => showDeleteDialog(false)}
        title="Delete Input"
        description="Are you sure you want to delete this input?"
        okButtonCaption="Yes"
        cancelButtonCaption="No"
      />
    </div>
  );
}

export default Input;
