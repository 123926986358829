export default function getTopicParameterValues(report, topicParameterId) {
  const {parameters} = report;
  const values = [];
  if (parameters && parameters.length > 0) {
    const parameter = parameters.find(
      param => param && param.id === topicParameterId,
    );
    if (parameter && parameter.values) {
      parameter.values.forEach(arr => {
        if (arr && arr.length > 0) {
          arr.forEach(value => {
            if (value) {
              values.push(value);
            }
          });
        }
      });
    }
  }
  return values;
}
