import React from "react";

import IconButton from "material-ui/IconButton";
import CloseIcon from "material-ui/svg-icons/navigation/close";

function getPixelSize(size) {
  return size ? `${size}px` : "14px";
}

function CloseIconButton(props) {
  const {onButtonClick, size, buttonStyles, iconStyles} = props;
  return (
    <IconButton
      style={{
        ...{
          padding: 0,
          zIndex: 0,
          height: getPixelSize(size),
          width: getPixelSize(size),
        },
        ...buttonStyles,
      }}
      iconStyle={{
        height: getPixelSize(size),
        width: getPixelSize(size),
        ...iconStyles,
      }}
      onClick={onButtonClick}
    >
      <CloseIcon />
    </IconButton>
  );
}

export default CloseIconButton;
