import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";

type Props = {
  addWorkflow: (name: string) => void;
};

export default function WorkflowCreator({addWorkflow}: Props) {
  const [showWorkflowAdder, setShowWorkflowAdder] = useState(false);
  const [name, setName] = useState("");
  if (!showWorkflowAdder) {
    return (
      <button onClick={() => setShowWorkflowAdder(true)}>Add Workflow</button>
    );
  }

  function workflowAdded() {
    setShowWorkflowAdder(false);
    addWorkflow(name);
    setName("");
  }
  return (
    <div>
      <TextField
        label="Workflow Name"
        value={name}
        onChange={event => setName(event.target.value)}
      />
      <IconButton onClick={workflowAdded} disabled={name.length === 0}>
        <SaveIcon />
      </IconButton>
    </div>
  );
}
