import React from "react";

import {StringArgEditor} from "../string";
import {ModelSelectInputEditor} from "../model_select";
import {llmModels} from "modules/flowmaster/constants/models";

import {OrganisationId} from "common/types/organisation";
import {
  WorkflowTask,
  ActionDefinition,
} from "common/flowmaster/types/task_config";
import {DataLocation} from "common/flowmaster/types/data_location";
import {
  WorkflowContext,
  WorkflowInputs,
  WorkflowInput,
  Prompt,
} from "common/flowmaster/types/workflow";

interface InputValueEditorProps {
  input: WorkflowInput;
  value: DataLocation<string>;
  priorTasks: WorkflowTask[];
  context: WorkflowContext;
  inputs: WorkflowInputs;
  prompts: Prompt[];
  actionDefinitions: ActionDefinition[];
  onUpdateItem: (value: DataLocation<string>) => void;
  organisationId: OrganisationId;
  useListItem: boolean;
}

const InputValueEditor: React.FC<InputValueEditorProps> = ({
  input,
  value,
  priorTasks,
  context,
  inputs,
  prompts,
  actionDefinitions,
  onUpdateItem,
  organisationId,
  useListItem,
}) => {
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid",
        padding: "1em",
        margin: "1em 0",
      }}
    >
      {input.name}
      {input.type === "llm_model" ? (
        <ModelSelectInputEditor
          argDefinition={{
            key: "model",
            label: "Model",
            options: llmModels,
            type: "model_select",
          }}
          argValue={{value} ?? {}}
          onUpdateItem={value => onUpdateItem(value.value)}
          inputs={inputs}
        />
      ) : (
        <StringArgEditor
          argValue={{value} ?? {}}
          priorTasks={priorTasks}
          context={context}
          inputs={inputs}
          prompts={prompts}
          actionDefinitions={actionDefinitions}
          onUpdateItem={value => onUpdateItem(value.value)}
          organisationId={organisationId}
          useListItem={useListItem}
        />
      )}
    </div>
  );
};

export default InputValueEditor;
