const colors = {
  "#ff0000": "red",
  "#ff8800": "amber",
  "#00c853": "green",
  "#aaa": "grey",
};

const text = {
  red: "high",
  amber: "moderate",
  green: "low",
  grey: "N/A",
};

export default function getIssueLevel(issue) {
  const color = issue.icon_color;
  const issueLevel = colors[color];
  return {
    issueLevel,
    issueText: text[issueLevel],
  };
}
